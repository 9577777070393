import { Action } from '@ngrx/store';
import { Entity } from './payment-option-info.reducer';

export enum PaymentOptionInfoActionTypes {
  LoadPaymentOptionInfo = '[PaymentOptionInfo] Load PaymentOptionInfo',
  PaymentOptionInfoLoaded = '[PaymentOptionInfo] PaymentOptionInfo Loaded',
  PaymentOptionInfoLoadError = '[PaymentOptionInfo] PaymentOptionInfo Load Error'
}

export class LoadPaymentOptionInfo implements Action {
  readonly type = PaymentOptionInfoActionTypes.LoadPaymentOptionInfo;
}

export class PaymentOptionInfoLoadError implements Action {
  readonly type = PaymentOptionInfoActionTypes.PaymentOptionInfoLoadError;
  constructor(public payload: any) {}
}

export class PaymentOptionInfoLoaded implements Action {
  readonly type = PaymentOptionInfoActionTypes.PaymentOptionInfoLoaded;
  constructor(public payload: any) {}
}

export type PaymentOptionInfoAction =
  | LoadPaymentOptionInfo
  | PaymentOptionInfoLoaded
  | PaymentOptionInfoLoadError;

export const fromPaymentOptionInfoActions = {
  LoadPaymentOptionInfo,
  PaymentOptionInfoLoaded,
  PaymentOptionInfoLoadError
};
