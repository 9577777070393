/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface V2PluginsBody1 { 
    /**
     * WordPress.org plugin directory slug.
     */
    slug: string;
    /**
     * The plugin activation status.
     */
    status?: Array<V2PluginsBody1.StatusEnum>;
}
export namespace V2PluginsBody1 {
    export type StatusEnum = 'inactive' | 'active';
    export const StatusEnum = {
        Inactive: 'inactive' as StatusEnum,
        Active: 'active' as StatusEnum
    };
}