import {
  CustomerTravellerAction,
  CustomerTravellerActionTypes
} from './customer-traveller.actions';
import {Observable} from "rxjs";
import {CustomerTravellerDTO} from "@gtd/api-services/customer-service";

export const CUSTOMERTRAVELLER_FEATURE_KEY = 'customerTraveller';

/**
 * Interface for the 'CustomerTraveller' data used in
 *  - CustomerTravellerState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface ResultUpdate {
  type: String;
  lists: Array<CustomerTravellerDTO>;
}

export interface CustomerTravellerState {
  result: CustomerTravellerDTO; // list of CustomerTraveller; analogous to a sql normalized table
  resultAll: ResultUpdate;
  resultUpdate: ResultUpdate;
  resultRemove: any; // list of CustomerTraveller; analogous to a sql normalized table
  selectedId?: string | number; // which CustomerTraveller record has been selected
  loaded: boolean; // has the CustomerTraveller list been loaded
  error?: any; // last none error (if any)
}

export interface CustomerTravellerPartialState {
  readonly [CUSTOMERTRAVELLER_FEATURE_KEY]: CustomerTravellerState;
}

export const initialState: CustomerTravellerState = {
  result: null,
  resultAll: null,
  resultUpdate: null,
  resultRemove: null,
  loaded: false
};

export function reducer(
  state: CustomerTravellerState = initialState,
  action: CustomerTravellerAction
): CustomerTravellerState {
  switch (action.type) {
    case CustomerTravellerActionTypes.CustomerTravellerLoadAllError: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
    case CustomerTravellerActionTypes.CustomerTravellerLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
    case CustomerTravellerActionTypes.CustomerTravellerUpdated: {
      state = {
        ...state,
        resultUpdate: {
          type: 'Update',
          lists: action.payload
        },
        loaded: true
      };
      break;
    }
    case CustomerTravellerActionTypes.CustomerTravellerUpdateDetroy: {
      state = initialState;
      break;
    }
    case CustomerTravellerActionTypes.CustomerTravellerRemoved: {
      state = {
        ...state,
        resultRemove: action.payload,
        loaded: true
      };
      break;
    }
    case CustomerTravellerActionTypes.CustomerTravellerLoadedAll: {
      state = {
        ...state,
        resultAll: {
          type: 'LoadAll',
          lists: action.payload
        },
        loaded: true
      };
      break;
    }
  }
  return state;
}
