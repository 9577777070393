import {
  ComboSearchInformationAction,
  ComboSearchInformationActionTypes
} from './combo-search-information.actions';
import { HotelMetaKeyword } from '@gtd/b2c-client';

export const COMBO_SEARCH_INFOMARTION_FEATURE_KEY = 'comboVJSearchInformation';

/**
 * Interface for the 'ComboSearchInformation' data used in
 *  - ComboSearchInformationState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface ComboSearchInformationState {
  list: HotelMetaKeyword[]; // list of ComboSearchInformation; analogous to a sql normalized table
  selectedId?: string | number; // which ComboSearchInformation record has been selected
  loaded: boolean; // has the ComboSearchInformation list been loaded
  error?: any; // last none error (if any)
}

export interface ComboSearchInformationPartialState {
  readonly [COMBO_SEARCH_INFOMARTION_FEATURE_KEY]: ComboSearchInformationState;
}

export const initialState: ComboSearchInformationState = {
  list: [],
  loaded: false
};

export function SearchInformationReducer(
  state: ComboSearchInformationState = initialState,
  action: ComboSearchInformationAction
): ComboSearchInformationState {
  switch (action.type) {
    case ComboSearchInformationActionTypes.ComboVJSearchInformation: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
