import {
  DeleteNotificationAction,
  DeleteNotificationActionTypes
} from './delete-notification.actions';

export const DELETENOTIFICATION_FEATURE_KEY = 'deleteNotification';

/**
 * Interface for the 'DeleteNotification' data used in
 *  - DeleteNotificationState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface DeleteNotificationState {
  list: any; // list of DeleteNotification; analogous to a sql normalized table
  selectedId?: string | number; // which DeleteNotification record has been selected
  loaded: boolean; // has the DeleteNotification list been loaded
  error?: any; // last none error (if any)
}

export interface DeleteNotificationPartialState {
  readonly [DELETENOTIFICATION_FEATURE_KEY]: DeleteNotificationState;
}

export const initialState: DeleteNotificationState = {
  list: null,
  loaded: false
};

export function deleteNotificationReducer(
  state: DeleteNotificationState = initialState,
  action: DeleteNotificationAction
): DeleteNotificationState {
  switch (action.type) {
    case DeleteNotificationActionTypes.DeleteNotificationLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
