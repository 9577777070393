import { FarerulesAction, FarerulesActionTypes } from './farerules.actions';
import { AirFareRuleRS } from '@gtd/b2c-client';

export const FARERULES_FEATURE_KEY = 'comboVJFarerules';

/**
 * Interface for the 'Farerules' data used in
 *  - FarerulesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FarerulesState {
  result: AirFareRuleRS; // list of Farerules; analogous to a sql normalized table
  selectedId?: string | number; // which Farerules record has been selected
  loaded: boolean; // has the Farerules list been loaded
  error?: any; // last none error (if any)
}

export interface FarerulesPartialState {
  readonly [FARERULES_FEATURE_KEY]: FarerulesState;
}

export const initialState: FarerulesState = {
  result: null,
  loaded: false
};

export function farerulesReducer(
  state: FarerulesState = initialState,
  action: FarerulesAction
): FarerulesState {
  switch (action.type) {
    case FarerulesActionTypes.ComboVJFarerulesLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
