import { notificationsQuery } from './state-notification/+get-all/notifications.selectors';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { NotificationsFacade } from './state-notification/+get-all/notifications.facade';
import { ShortProfileFacade } from '../../../../api-services/customer-service/src/lib/stage/short-profile/short-profile.facade';
import { Subscription } from 'rxjs';
import { MatDialog, MatPaginatorIntl, PageEvent } from '@angular/material';
import { CountUnreadFacade } from './state-notification/+count-unread/count-unread.facade';
import { MarkAsUnreadFacade } from './state-notification/+mark-as-unread/mark-as-unread.facade';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { fadeIn, fadeInOut } from '@gtd/helpers';
import {
  MessageItem,
  MessageItemResourceService
} from '@gtd/api-services/customer-service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { AccountFacade } from '../../../../b2c-client/src/lib/stage/account/account.facade';
import { NotifyUserMessageResourceService } from '@gtd/api-services/utilitysrv';

@Component({
  selector: 'gtd-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [fadeIn, fadeInOut]
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notificationFormGroup: FormGroup;

  subscriptions = new Subscription();
  length = 0;
  pageLength = 0;
  pageSize = 15;
  loaded$ = this.notificationsFacade.loaded$;
  loadedMarkAsUnread$ = this.markAsUnreadFacade.loaded$;
  allNotifications$: any = this.notificationsFacade.allNotifications$;
  allCountUnread$: any = this.countUnreadFacade.allCountUnread$;
  pageSizeOptions: number[] = [10, 15, 25, 100];
  pageEvent: PageEvent;
  profileId: any;
  isActionMessageId: number;
  isLoadingMark: number;
  sortReaded: boolean;
  notifications: any;

  selectItem: Array<MessageItem>;
  selectedAll: boolean;

  constructor(
    private notificationsFacade: NotificationsFacade,
    private shortProfileFacade: ShortProfileFacade,
    private countUnreadFacade: CountUnreadFacade,
    private markAsUnreadFacade: MarkAsUnreadFacade,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private messageItemResourceService: MessageItemResourceService,
    private accountFacade: AccountFacade,
    private fb: FormBuilder,
    private router: Router,
    private notifyUserMessageResourceService: NotifyUserMessageResourceService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.notificationFormGroup = this.fb.group({
      selectAll: new FormControl(false)
    });
    this.activatedRoute.queryParams.subscribe(param => {
      if (param) {
        this.pageSize = param.size ? param.size : 15;
        this.pageLength = param.page ? param.page : 0;
      }
    });
    this.subscriptions.add(
      this.accountFacade.getAccountLogged$.subscribe(selectedAccount => {
        if (selectedAccount && selectedAccount.userRefCode) {
          this.profileId = selectedAccount.userRefCode;
          this.notificationsFacade.loadAll({
            userRefCode: selectedAccount.userRefCode,
            page: this.pageLength,
            size: this.pageSize,
            sort: ['schedule,desc']
          });
          //this.accountFacade.getAccountLogged$.subscribe(selectedAccount => {
          //  if (selectedAccount && selectedAccount.userRefCode) {
          //    this.approvalInvoiceResourceService
          //      .getBusinessByUserRefCodeAndStatusUsingGET(
          //        selectedAccount.userRefCode
          //      )
          //      .subscribe((res: Array<BusinessDTO>) => {
          //        this.listInformationCompanies = res;
          //      });
        }
      })
    );
  }

  ngOnInit() {
    this.subscriptions.add(
      this.notificationsFacade.allNotifications$.subscribe(allNotifications => {
        if (allNotifications && allNotifications.totalElements) {
          this.notifications = allNotifications.content;
          this.length = allNotifications.totalElements;
          this.pageEvent = {
            length: this.length,
            pageIndex: this.pageLength,
            pageSize: this.pageSize
          };
          this.notificationFormGroup = this.fb.group({
            selectAll: new FormControl(false),
            id: this.fb.array(
              this.notifications.map(s => this.fb.control(false))
            )
          });
          this.notificationFormGroup.controls['id'].valueChanges.subscribe(
            valueChange => {
              const listIds = [];
              valueChange.forEach((listId, index) => {
                if (listId) {
                  listIds.push({
                    status: 1,
                    userId: this.profileId,
                    messageId: this.notifications[index].id
                  });
                }
              });
              this.selectItem = listIds;
            }
          );
        }
      })
    );
    this.subscriptions.add(
      this.shortProfileFacade.getShortProfile$.subscribe(shortProfile => {
        if (shortProfile) {
          this.countUnreadFacade.countUnreadMsgUsingGET(
            shortProfile.requesterCode
          );
        }
      })
    );
  }

  selectAll() {
    this.selectedAll = !this.selectedAll;
    const formArray = this.notificationFormGroup.get('id') as FormArray;
    this.notifications.forEach((x, index) => {
      if (x.senderMethod !== 'BROADCAST') {
        formArray.controls[index].setValue(this.selectedAll);
      }
    });
  }

  loadPagination(event) {
    if (isPlatformBrowser(this.platformId)) {
      this.notificationsFacade.loadAll({
        userRefCode: this.profileId,
        page: event.pageIndex,
        size: event.pageSize
      });
      window.scrollTo(0, 0);
      this.router.navigate(['/user/notifications'], {
        queryParams: {
          page: event.pageIndex,
          size: event.pageSize
        }
      });
      return event;
    }
  }

  markAsUnreadSelected() {
    if (this.selectItem) this.markAsUnread(this.selectItem);
  }

  markAsUnread(notifications: Array<any>, singleItem?: boolean) {
    this.isLoadingMark = singleItem ? notifications[0].id : null;
    this.isActionMessageId = singleItem ? notifications[0].id : null;
    const listItems = new Array<number>();
    notifications.forEach(notification => {
      listItems.push(singleItem ? notification.id : notification.messageId);
    });
    this.notifyUserMessageResourceService
      .markReadNotifyUserMessageUsingPUT(listItems, this.profileId)
      .subscribe(
        markReadItem => {
          this.isLoadingMark = null;
          if (singleItem) {
            switch (notifications[0].messageType) {
              case 'CONFIRMED_HOLD':
                this.redirectMessageType(notifications[0]);
                break;
              case 'CONFIRMED_TICKET':
                this.redirectMessageType(notifications[0]);
                break;
              case 'COMMIT_CONFIRM':
                this.redirectMessageType(notifications[0]);
                break;
              case 'PAYMENT_REMIND':
                this.redirectMessageType(notifications[0]);
                break;
              case 'MARKETING':
                if (notifications[0].redirectUrl) {
                  if (
                    notifications[0].redirectUrl.indexOf('content?contentId') >
                    -1
                  ) {
                    this.router.navigate(['content'], {
                      queryParams: {
                        contentId: `02${notifications[0].id}`
                      }
                      // 02 is prefix of notification
                    });
                  } else {
                    const newWindow = window.open('', '_blank');
                    newWindow.location.href = notifications[0].redirectUrl;
                  }
                }
                break;
              default:
                if (notifications.length > 0 && notifications[0].redirectUrl) {
                  const newWindow = window.open('', '_blank');
                  newWindow.location.href = notifications[0].redirectUrl;
                }
                break;
            }
          } else {
            window.location.reload();
          }
        },
        error1 => {
          console.error(error1);
        }
      );
  }

  redirectMessageType(notification) {
    if (notification.redirectUrl && notification.refCode) {
      this.router.navigate(['/user/booking/detail'], {
        queryParams: {
          booking_number: notification.refCode
        }
      });
    }
  }

  removeSelected() {
    if (this.selectItem) {
      const stringId = [];
      this.selectItem.forEach(item => {
        stringId.push(item.messageId);
      });
      let listId = JSON.stringify(stringId).replace('[', '');
      listId = listId.replace(']', '');
      const listIdNb = listId.split(',');
      this.removeNotification(listIdNb);
    }
  }

  removeNotification(messageId: Array<any>, singleItem?: boolean) {
    if (this.profileId) {
      this.isLoadingMark = singleItem ? messageId[0] : null;
      const dialogRef = this.dialog.open(DialogMessageComponent, {
        data: {
          btn: [
            { btnText: 'global.close', btnColor: 'none' },
            { btnText: 'user.delete', btnColor: 'warn', action: 'Delete' }
          ],
          content: 'user.message-question-remove-notification',
          heading: 'user.question-remove-notification',
          messageType: 'warn'
        },
        disableClose: true,
        panelClass: 'dialog-do-confirm',
        position: {
          top: '150px'
        }
      });
      dialogRef.afterClosed().subscribe(actionDialog => {
        if (actionDialog.action === 'Delete') {
          this.notifyUserMessageResourceService
            .deleteNotifyUserMessageUsingPUT(messageId, this.profileId)
            .subscribe(deleteNotification => {
              window.location.reload();
            });
        } else {
          this.isLoadingMark = 0;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export const getDutchPaginatorIntl = () => {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = '';
  return paginatorIntl;
};
