import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  BookingInfo,
  BookingTransactionInfo,
  GroupPricedItinerary
} from '@gtd/b2c-client';

@Component({
  selector: 'gtd-flight-booking-info',
  templateUrl: './flight-booking-info.component.html',
  styleUrls: ['./flight-booking-info.component.scss']
})
export class FlightBookingInfoComponent implements OnInit {
  @Output() emitAdditionalRequestType: EventEmitter<
    string
  > = new EventEmitter();
  @Input() groupPricedItineraries: Array<GroupPricedItinerary>;
  @Input() bookingNumber: string;
  @Input() bookingInfo: BookingInfo;
  @Input() totalAdditionalRequests: number;

  additionalRequestActions = [];
  appName = localStorage.getItem('appName');
  isShowAdditionalRequestButton: boolean = false;

  constructor() {}

  ngOnInit() {
    if (this.bookingInfo.supplierType === 'AIR') {
      this.additionalRequestActions = [
        {
          code: 'AIR_ADD_PACKAGE',
          name: 'booking.buy-signed-luggage-for-ticket'
        },
        {
          code: 'AIR_ADD_INFORMATION',
          name: 'booking.change-information-on-ticket'
        },
        {
          code: 'AIR_DELETE_BOOKING',
          name: 'booking.refund-cancellation-ticket'
        },
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    } else if (this.bookingInfo.supplierType === 'HOTEL') {
      this.additionalRequestActions = [
        {
          code: 'HOTEL_ADD_PERSON',
          name: 'booking.more-people'
        },
        {
          code: 'HOTEL_EDIT_BOOKING',
          name: 'booking.change-room-information'
        },
        {
          code: 'HOTEL_CHANGE_ROOM',
          name: 'booking.change-room'
        },
        {
          code: 'HOTEL_DELETE_BOOKING',
          name: 'booking.refund-cancellation-room'
        },
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    }
    if (this.bookingInfo.departureDate) {
      if (
        new Date().getTime() <
          new Date(this.bookingInfo.departureDate).getTime() &&
        this.appName !== 'B2B2C'
      ) {
        this.isShowAdditionalRequestButton = true;
      }
    }
  }
}
@Pipe({
  name: 'getPassengerNameRecord'
})
export class GetPassengerNameRecord implements PipeTransform {
  transform(bookingInfo: BookingInfo, type: number): string {
    let bookingTransactionInfos = new Array<BookingTransactionInfo>();
    switch (type) {
      case 0:
        bookingTransactionInfos = bookingInfo.transactionInfos.filter(
          trans => trans.originLocationCode === bookingInfo.fromLocationCode
        );
        break;
      case 1:
        bookingTransactionInfos = bookingInfo.transactionInfos.filter(
          trans => trans.originLocationCode === bookingInfo.toLocationCode
        );
        break;
    }
    if (bookingTransactionInfos && bookingTransactionInfos.length > 0) {
      return bookingTransactionInfos[0].passengerNameRecord;
    }
    return '';
  }
}
