import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { CustomerTravellerPartialState } from './customer-traveller.reducer';
import { customerTravellerQuery } from './customer-traveller.selectors';
import {
  CustomerTravellerUpdateDetroy,
  LoadAllCustomerTraveller,
  LoadCustomerTraveller, RemoveCustomerTraveller,
  UpdateCustomerTraveller
} from './customer-traveller.actions';
import {CustomerTravellerDTO} from "@gtd/api-services/customer-service";

@Injectable()
export class CustomerTravellerFacade {
  loaded$ = this.store.pipe(select(customerTravellerQuery.getLoaded));

  allCustomerTraveller$ = this.store.pipe(
    select(customerTravellerQuery.getAllCustomerTraveller)
  );
  selectListCustomerTraveller$ = this.store.pipe(
    select(customerTravellerQuery.getListCustomerTraveller)
  );
  selectedCustomerTraveller$ = this.store.pipe(
    select(customerTravellerQuery.getCustomerTraveller)
  );

  selectedUpdateCustomerTraveller$ = this.store.pipe(
    select(customerTravellerQuery.getUpdateCustomerTraveller)
  );

  selectedRemoveCustomerTraveller$ = this.store.pipe(
    select(customerTravellerQuery.getRemoveCustomerTraveller)
  );



  constructor(private store: Store<CustomerTravellerPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadAllCustomerTraveller());
  }

  getCustomerTravellerUsingGet(id) {
    this.store.dispatch(new LoadCustomerTraveller(id));
  }

  customerTravellerUpdateDetroy() {
    this.store.dispatch(new CustomerTravellerUpdateDetroy());
  }

  updateCustomerTravellerUsingPut(customerTravellerDTOS: Array<CustomerTravellerDTO>) {
    this.store.dispatch(new UpdateCustomerTraveller(customerTravellerDTOS));
  }

  removeCustomerTravellerUsingDelete(idTraveller: number) {
    this.store.dispatch(new RemoveCustomerTraveller(idTraveller));
  }
}
