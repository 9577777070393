/**
 * customersrv API
 * customersrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MessageForPrivateUserReq { 
    messageContent?: string;
    messageExcerpt?: string;
    messageTitle?: string;
    messageType?: MessageForPrivateUserReq.MessageTypeEnum;
    redirectUrl?: string;
    sendNow?: boolean;
    tag?: string;
    time?: Date;
    userId?: number;
}
export namespace MessageForPrivateUserReq {
    export type MessageTypeEnum = 'PRIVATE' | 'BROADCAST' | 'PAYMENT_PROMPT' | 'CHECKIN_PROMPT' | 'CONFIRMED_HOLD' | 'CONFIRMED_TICKET' | 'OTHER';
    export const MessageTypeEnum = {
        PRIVATE: 'PRIVATE' as MessageTypeEnum,
        BROADCAST: 'BROADCAST' as MessageTypeEnum,
        PAYMENTPROMPT: 'PAYMENT_PROMPT' as MessageTypeEnum,
        CHECKINPROMPT: 'CHECKIN_PROMPT' as MessageTypeEnum,
        CONFIRMEDHOLD: 'CONFIRMED_HOLD' as MessageTypeEnum,
        CONFIRMEDTICKET: 'CONFIRMED_TICKET' as MessageTypeEnum,
        OTHER: 'OTHER' as MessageTypeEnum
    };
}
