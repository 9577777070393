import { Action } from '@ngrx/store';
import { AirLowFareSearchResultVM } from '@gtd/b2c-client';
import { LowfareSearchQueryParam } from '@gtd/helpers';

export enum FlightSearchActionTypes {
  ComboVJLoadFlightSearch = '[ComboVJ_FlightSearch] Load FlightSearch',
  ComboVJLoadFlightIntSearch = '[ComboVJ_FlightSearch] Load LoadFlightIntSearch',
  ComboVJFlightSearchLoaded = '[ComboVJ_FlightSearch] FlightSearch Loaded',
  ComboVJFlightSearchLoadError = '[ComboVJ_FlightSearch] FlightSearch Load Error',
  ComboVJFlightSearchReset = '[ComboVJ_FlightSearch] FlightSearch Reset'
}

export class ComboVJLoadFlightSearch implements Action {
  readonly type = FlightSearchActionTypes.ComboVJLoadFlightSearch;

  constructor(public payload: LowfareSearchQueryParam) {}
}

export class ComboVJLoadFlightIntSearch implements Action {
  readonly type = FlightSearchActionTypes.ComboVJLoadFlightIntSearch;

  constructor(public payload: LowfareSearchQueryParam) {}
}

export class ComboVJFlightSearchLoadError implements Action {
  readonly type = FlightSearchActionTypes.ComboVJFlightSearchLoadError;

  constructor(public payload: any) {}
}

export class ComboVJFlightSearchLoaded implements Action {
  readonly type = FlightSearchActionTypes.ComboVJFlightSearchLoaded;

  constructor(public payload: AirLowFareSearchResultVM) {}
}

export class ComboVJFlightSearchReset implements Action {
  readonly type = FlightSearchActionTypes.ComboVJFlightSearchReset;
}

export type FlightSearchAction =
  | ComboVJLoadFlightSearch
  | ComboVJLoadFlightIntSearch
  | ComboVJFlightSearchLoaded
  | ComboVJFlightSearchLoadError
  | ComboVJFlightSearchReset;

export const fromFlightSearchActions = {
  ComboVJLoadFlightSearch,
  ComboVJLoadFlightIntSearch,
  ComboVJFlightSearchLoaded,
  ComboVJFlightSearchLoadError,
  ComboVJFlightSearchReset
};
