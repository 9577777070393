import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightSearchResultComponent } from './flight-search-result.component';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatTabsModule,
  MatTooltipModule
} from '@angular/material';
import { ComboRoutingModule } from '../../combovj.module.routing';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlightSearchModule } from './flight-search/flight-search.module';
import { TranslateModule } from '@ngx-translate/core';
import {
  CabinToBranchMapperPipe,
  FilterPanelComponent
} from './filter-panel/filter-panel.component';
import { CheckboxFilterComponent } from './checkbox-filter/checkbox-filter.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FlightResultItemComponent } from './flight-result-item/flight-result-item.component';
import { FlightSortPanelComponent } from './flight-sort-panel/flight-sort-panel.component';
import { BrandFilterComponent } from './brand-filter/brand-filter.component';
import { BrandItemComponent } from './brand-filter/brand-item/brand-item.component';
import { ResultSummaryComponent } from './result-summary/result-summary.component';
import { String2BranchMapperPipe } from './brand-filter/brand-item/string2-branch-mapper.pipe';
import { ComboFormSearchModule } from '../../combo-form-search/combo-form-search.module';
import { FlightSearchInfoComponent } from './flight-search-info/flight-search-info.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight,
  faChevronDown,
  faInfo,
  faInfoCircle,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipeModule } from '@gtd/components/date-pipe';
import { AirlinesModule } from '@gtd/components/airlines';
import { LoadingIframeSearchInfoComponent } from './flight-search-info/loading-iframe-search-info/loading-iframe-search-info.component';
import { LoadingIframeFilterPanelComponent } from './filter-panel/loading-iframe-filter-panel/loading-iframe-filter-panel.component';
import { DiffPriceIntModule } from '@gtd/components/diff-price';
import { DepartureFlightItemComponent } from './flight-search-info/departure-flight-item/departure-flight-item.component';
import { DialogMessageModule } from '@gtd/components/dialog-message';
import { PageErrorApiModule } from '@gtd/components/page-error-api';
import { BreadcrumbBarModule } from '@gtd/components/breadcrumb-bar';
import { CustomPipeModule } from '../pipe/custom-pipe.module';
import { FlightConfirmDialogComponent } from './flight-confirm-dialog/flight-confirm-dialog.component';
import { FlightBookingItemComponent } from './flight-confirm-dialog/flight-booking-item/flight-booking-item.component';

library.add(faArrowRight, faChevronDown, faQuestionCircle, faInfo);

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatExpansionModule,
    ComboRoutingModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatRadioModule,
    FontAwesomeModule,
    SwiperModule,
    MatTabsModule,
    MatRippleModule,
    MatChipsModule,
    MatDialogModule,
    MatTooltipModule,
    FlightSearchModule,
    MatButtonToggleModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    ComboFormSearchModule,
    TranslateModule,
    MatDividerModule,
    MatMenuModule,
    MatSelectModule,
    ReactiveFormsModule,
    DatePipeModule,
    AirlinesModule,
    MatProgressBarModule,
    FormsModule,
    DiffPriceIntModule,
    DialogMessageModule,
    PageErrorApiModule,
    BreadcrumbBarModule,
    CustomPipeModule
  ],
  declarations: [
    FlightSearchResultComponent,
    CabinToBranchMapperPipe,
    FilterPanelComponent,
    CheckboxFilterComponent,
    PaginationComponent,
    FlightResultItemComponent,
    FlightSortPanelComponent,
    BrandFilterComponent,
    BrandItemComponent,
    ResultSummaryComponent,
    String2BranchMapperPipe,
    FlightSearchInfoComponent,
    LoadingIframeSearchInfoComponent,
    LoadingIframeFilterPanelComponent,
    DepartureFlightItemComponent,
    FlightConfirmDialogComponent,
    FlightBookingItemComponent
  ],
  entryComponents: [FlightConfirmDialogComponent]
})
export class FlightSearchResultModule {}
