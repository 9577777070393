import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SOCIALAUTH_FEATURE_KEY, SocialAuthState } from './social-auth.reducer';
import {AuthenticateState} from "../../../authenticate/+state/authenticate.reducer";

// Lookup the 'SocialAuth' feature state managed by NgRx
const getSocialAuthState = createFeatureSelector<SocialAuthState>(
  SOCIALAUTH_FEATURE_KEY
);

const getLoaded = createSelector(
  getSocialAuthState,
  (state: SocialAuthState) => state.loaded
);
const getError = createSelector(
  getSocialAuthState,
  (state: SocialAuthState) => state.errorMessage
);

const getAllSocialAuth = createSelector(
  getSocialAuthState,
  getLoaded,
  (state: SocialAuthState, isLoaded) => {
    return isLoaded ? state : null;
  }
);

const selectUserState = createSelector(
  getSocialAuthState,
  (state: AuthenticateState) => state
);

const getSelectedId = createSelector(
  getSocialAuthState,
  (state: SocialAuthState) => state.user.username
);
const getSelectedSocialAuth = createSelector(
  getAllSocialAuth,
  getSelectedId,
  (socialAuth, id) => {
    return socialAuth ? Object.assign({}, socialAuth) : undefined;
  }
);

export const socialAuthQuery = {
  getLoaded,
  getError,
  getAllSocialAuth,
  selectUserState,
  getSelectedSocialAuth
};
