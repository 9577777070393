import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
