import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FilterAvailableFacade } from '../../../../state/filter-available/filter-available.facade';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { FilterPanelService } from '../filter-panel.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'gtd-filter-by-hotel-name',
  templateUrl: './filter-by-hotel-name.component.html',
  styleUrls: ['./filter-by-hotel-name.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterByHotelNameComponent),
      multi: true
    }
  ]
})
export class FilterByHotelNameComponent
  implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy {
  @ViewChild('something', { static: false }) something: ElementRef;
  source: any;
  submit = false;
  formHotelName: FormGroup;
  submit$ = this._filterPanelService.submit$;
  unsubscribe$ = new Subject();

  constructor(
    private filterAvailableFacade: FilterAvailableFacade,
    private _filterPanelService: FilterPanelService
  ) {
    this.formHotelName = new FormGroup({
      hotelName: new FormControl()
    });
  }

  ngOnInit() {
    this._filterPanelService.submit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(submit => {
        if (submit) {
          this.filterAvailableFacade.changeFilterHotelName(
            this.formHotelName.controls['hotelName'].value
          );
        }
      });
  }

  ngAfterViewInit(): void {
    this.source = fromEvent(this.something.nativeElement, 'keyup');
    this.source.pipe(debounceTime(1000)).subscribe(something => {
      if (this._filterPanelService.isDesktop()) {
        this.filterAvailableFacade.changeFilterHotelName(
          something.target.value
        );
      }
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    if (obj) {
      this.formHotelName.controls['hotelName'].setValue(obj);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
