/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingCode } from './bookingCode';
import { Info } from './info';
import { OTPServiceRes } from './oTPServiceRes';


export interface UpdateBookingStatusRS {
    bookingCode?: BookingCode;
    cancellationStatus?: UpdateBookingStatusRS.CancellationStatusEnum;
    duration?: number;
    errors?: Array<Error>;
    infos?: Array<Info>;
    isChangedPNR?: boolean;
    isSuccess?: boolean;
    issuedStatus?: UpdateBookingStatusRS.IssuedStatusEnum;
    otpServiceRes?: OTPServiceRes;
    passengerNameRecords?: Array<string>;
    paymentStatus?: UpdateBookingStatusRS.PaymentStatusEnum;
    paymentType?: UpdateBookingStatusRS.PaymentTypeEnum;
    status?: UpdateBookingStatusRS.StatusEnum;
    success?: boolean;
    textMessage?: string;
}
export namespace UpdateBookingStatusRS {
    export type CancellationStatusEnum = 'CANCEL_UNKNOWN' | 'CANCEL_PENALTY_MISMATCH' | 'CANCEL_WAITING_CONFIRM' | 'CANCEL_CONFIRMED' | 'CANCEL_EXPIRED';
    export const CancellationStatusEnum = {
        UNKNOWN: 'CANCEL_UNKNOWN' as CancellationStatusEnum,
        PENALTYMISMATCH: 'CANCEL_PENALTY_MISMATCH' as CancellationStatusEnum,
        WAITINGCONFIRM: 'CANCEL_WAITING_CONFIRM' as CancellationStatusEnum,
        CONFIRMED: 'CANCEL_CONFIRMED' as CancellationStatusEnum,
        EXPIRED: 'CANCEL_EXPIRED' as CancellationStatusEnum
    };
    export type IssuedStatusEnum = 'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED';
    export const IssuedStatusEnum = {
        PENDING: 'PENDING' as IssuedStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as IssuedStatusEnum,
        SUCCEEDED: 'SUCCEEDED' as IssuedStatusEnum,
        FAILED: 'FAILED' as IssuedStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as IssuedStatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as IssuedStatusEnum
    };
    export type PaymentStatusEnum = 'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'PENDING';
    export const PaymentStatusEnum = {
        SUCCEEDED: 'SUCCEEDED' as PaymentStatusEnum,
        FAILED: 'FAILED' as PaymentStatusEnum,
        REFUNDED: 'REFUNDED' as PaymentStatusEnum,
        PENDING: 'PENDING' as PaymentStatusEnum
    };
    export type PaymentTypeEnum = 'BALANCE' | 'CREDIT' | 'ATM_DEBIT' | 'VNPAYQR' | 'VIETTELPAY' | 'MOMO' | 'ZALO' | 'AIRPAY' | 'PAYOO' | 'CASH' | 'TRANSFER' | 'PARTNER' | 'OTHER';
    export const PaymentTypeEnum = {
        BALANCE: 'BALANCE' as PaymentTypeEnum,
        CREDIT: 'CREDIT' as PaymentTypeEnum,
        ATMDEBIT: 'ATM_DEBIT' as PaymentTypeEnum,
        VNPAYQR: 'VNPAYQR' as PaymentTypeEnum,
        VIETTELPAY: 'VIETTELPAY' as PaymentTypeEnum,
        MOMO: 'MOMO' as PaymentTypeEnum,
        ZALO: 'ZALO' as PaymentTypeEnum,
        AIRPAY: 'AIRPAY' as PaymentTypeEnum,
        PAYOO: 'PAYOO' as PaymentTypeEnum,
        CASH: 'CASH' as PaymentTypeEnum,
        TRANSFER: 'TRANSFER' as PaymentTypeEnum,
        PARTNER: 'PARTNER' as PaymentTypeEnum,
        OTHER: 'OTHER' as PaymentTypeEnum
    };
    export type StatusEnum = 'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'TICKET_ON_PROCESS';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as StatusEnum,
        BOOKED: 'BOOKED' as StatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        TENTATIVE: 'TENTATIVE' as StatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as StatusEnum
    };
}
