/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MediaIdBody { 
    /**
     * The date the post was published, in the site's timezone.
     */
    date?: Date;
    /**
     * The date the post was published, as GMT.
     */
    dateGmt?: Date;
    /**
     * An alphanumeric identifier for the post unique to its type.
     */
    slug?: string;
    /**
     * A named status for the post.
     */
    status?: Array<MediaIdBody.StatusEnum>;
    /**
     * The title for the post.
     */
    title?: string;
    /**
     * The ID for the author of the post.
     */
    author?: string;
    /**
     * Whether or not comments are open on the post.
     */
    commentStatus?: Array<MediaIdBody.CommentStatusEnum>;
    /**
     * Whether or not the post can be pinged.
     */
    pingStatus?: Array<MediaIdBody.PingStatusEnum>;
    /**
     * Meta fields.
     */
    meta?: string;
    /**
     * The theme file to use to display the post.
     */
    template?: string;
    /**
     * Expose advanced custom fields.
     */
    acf?: string;
    /**
     * Alternative text to display when attachment is not displayed.
     */
    altText?: string;
    /**
     * The attachment caption.
     */
    caption?: string;
    /**
     * The attachment description.
     */
    description?: string;
    /**
     * The ID for the associated post of the attachment.
     */
    post?: string;
}
export namespace MediaIdBody {
    export type StatusEnum = 'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled';
    export const StatusEnum = {
        Publish: 'publish' as StatusEnum,
        Future: 'future' as StatusEnum,
        Draft: 'draft' as StatusEnum,
        Pending: 'pending' as StatusEnum,
        Private: 'private' as StatusEnum,
        AcfDisabled: 'acf-disabled' as StatusEnum
    };
    export type CommentStatusEnum = 'open' | 'closed';
    export const CommentStatusEnum = {
        Open: 'open' as CommentStatusEnum,
        Closed: 'closed' as CommentStatusEnum
    };
    export type PingStatusEnum = 'open' | 'closed';
    export const PingStatusEnum = {
        Open: 'open' as PingStatusEnum,
        Closed: 'closed' as PingStatusEnum
    };
}