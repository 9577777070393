import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightSearchResultModule } from './search-result/flight/flight-search-result.module';
import { HotelSearchResultModule } from './search-result/hotel/search-result/hotel-search-result.module';
import { HotelGuardService } from './hotel-guard.service';
import { HotelDetailModule } from './search-result/hotel/hotel-detail/hotel-detail.module';
import { ComboConfirmModule } from './combo-confirm/combo-confirm.module';

@NgModule({
  imports: [
    CommonModule,
    FlightSearchResultModule,
    HotelSearchResultModule,
    HotelDetailModule,
    ComboConfirmModule
  ],
  providers: [HotelGuardService]
})
export class ComboVjModule {}
