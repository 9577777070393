import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageErrorComponent } from './page-error.component';

@NgModule({
  imports: [CommonModule],
  exports: [
    PageErrorComponent
  ],
  declarations: [PageErrorComponent]
})
export class PageErrorApiModule {}
