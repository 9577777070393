import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {ProfilePartialState} from './profile.reducer';
import {LoadProfile, ProfileActionTypes, ProfileLoadError, ProfileUpdateError, UpdateProfile} from './profile.actions';
import {map} from "rxjs/operators";
import {CustomerResourceService} from "@gtd/b2c-client";
import {CustomerProfileResourceService} from "@gtd/api-services/customer-service";

@Injectable()
export class ProfileEffects {
  @Effect() loadProfile$ = this.dataPersistence.fetch(
    ProfileActionTypes.LoadProfile,
    {
      run: (action: LoadProfile, state: ProfilePartialState) => {
        return this.customerResourceService.getCustomerProfileByIdUsingGET(action.payload).pipe(
          map(update => ({
            type: ProfileActionTypes.ProfileLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadProfile, error) => {
        console.error('Error', error);
        return new ProfileLoadError(error);
      }
    }
  );

  @Effect() updateProfile$ = this.dataPersistence.fetch(
    ProfileActionTypes.UpdateProfile,
    {
      run: (action: UpdateProfile, state: ProfilePartialState) => {
        return this.customerProfileResourceService.updateCustomerProfileUsingPUT(action.payload).pipe(
          map(update => ({
            type: ProfileActionTypes.ProfileUpdated,
            payload: update
          }))
        );
      },

      onError: (action: UpdateProfile, error) => {
        console.error('Error', error);
        return new ProfileUpdateError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private customerResourceService: CustomerResourceService,
    private customerProfileResourceService: CustomerProfileResourceService,
    private dataPersistence: DataPersistence<ProfilePartialState>
  ) {}
}
