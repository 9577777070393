/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ConfigAdjustPrice { 
    adjustType?: ConfigAdjustPrice.AdjustTypeEnum;
    adjustValue?: number;
    isNonAllotment?: boolean;
    level?: number;
    rateOption?: ConfigAdjustPrice.RateOptionEnum;
    sourceKey?: string;
}
export namespace ConfigAdjustPrice {
    export type AdjustTypeEnum = 'AMOUNT' | 'PERCENT';
    export const AdjustTypeEnum = {
        AMOUNT: 'AMOUNT' as AdjustTypeEnum,
        PERCENT: 'PERCENT' as AdjustTypeEnum
    };
    export type RateOptionEnum = 'TA' | 'TA_PACKAGE' | 'OTA';
    export const RateOptionEnum = {
        TA: 'TA' as RateOptionEnum,
        TAPACKAGE: 'TA_PACKAGE' as RateOptionEnum,
        OTA: 'OTA' as RateOptionEnum
    };
}
