import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialog, MatSnackBar} from "@angular/material";
import {LibraryAvatarComponent} from "./library-avatar/library-avatar.component";
import {AvatarFacade} from "../../avatar/+state/avatar.facade";
import {CustomerAvatarDTO} from "@gtd/api-services/customer-service";
import {LibraryAvatarFacade} from "./library-avatar/+state/library-avatar.facade";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {AccountFacade} from "../../../../../../b2c-client/src/lib/stage/account/account.facade";
import {Subscription} from "rxjs";
import {GetUserProfileFacade} from "@gtd/api-services/b2b-client";
import {AgentAvatarResourceService} from "@gtd/api-services/agentsrv";

@Component({
  selector: 'gtd-update-avatar',
  templateUrl: './update-avatar.component.html',
  styleUrls: ['./update-avatar.component.scss']
})
export class UpdateAvatarComponent implements OnInit, OnDestroy {
  @ViewChild('imageAvatar', {static: false}) imageAvatar: ElementRef;

  customerAvatarDTO: CustomerAvatarDTO;
  formAvatar?: FormGroup;
  avatarImg: string;
  userLoggedData: any;
  subscriptions = new Subscription();
  appName = localStorage.getItem('appName');
  userProfile : any;

  constructor(
    private formBuilder: FormBuilder,
    private avatarFacade: AvatarFacade,
    public dialog: MatDialog,
    public accountFacade: AccountFacade,
    private libraryAvatarFacade: LibraryAvatarFacade,
    private _snackBar: MatSnackBar,
    private getUserProfileFacade: GetUserProfileFacade,
    private agentAvatarResourceService: AgentAvatarResourceService
  ) {
    this.createForm();
    this.subscriptions.add(
      this.accountFacade.getAccountLogged$.subscribe(dataAccount => {
        if(dataAccount !== null) {
          this.userLoggedData = dataAccount;
        }
      })
    )
    this.subscriptions.add(
      this.avatarFacade.selectAvatar$.subscribe(avatar => {
        if(avatar && avatar.avatarImage) {
          this.customerAvatarDTO = avatar;
          this.avatarImg = 'data:' + avatar.avatarImageContentType + ';base64,' + avatar.avatarImage;
        }
      })
    )
    this.subscriptions.add(
      this.getUserProfileFacade.allGetUserProfile$.subscribe(userProfile => {
        if(userProfile && userProfile.id) {
          this.userProfile = userProfile;
        }
      })
    )
  }

  ngOnInit() {
    if(this.appName === 'B2C') {
      if(!this.avatarImg && this.userLoggedData && this.userLoggedData.imageUrl) {
        this.avatarImg = this.userLoggedData.imageUrl;
      }
    }
    if(this.appName === 'B2B') {
      this.subscriptions.add(
        this.getUserProfileFacade.allGetUserProfile$.subscribe(userProfile => {
          if(userProfile && userProfile.id) {
            this.agentAvatarResourceService.findAvatarsByProfileIdUsingGET(userProfile.id).subscribe(getAvatart => {
              const getAvatarTerm = getAvatart[getAvatart.length-1];
              this.avatarImg = 'data:' + getAvatarTerm.avatarImageContentType + ';base64,' + getAvatarTerm.avatarImage;
            })
          }
        })
      )
    }

    this.subscriptions.add(
      this.libraryAvatarFacade.allLibraryAvatar$.subscribe(updateAvatar => {
        if(updateAvatar) {
          this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.update-success', icon: 'check'}, panelClass: 'snackbar-success', verticalPosition: "top", duration: 2000});
          this.dialog.closeAll();
          this.avatarFacade.getAvatarUser(updateAvatar.profileId);
          this.libraryAvatarFacade.resetAllUpdateAvatar();
        }
      }, error1 => {
        this.libraryAvatarFacade.resetAllUpdateAvatar();
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submitForm() {

  }
  openDialog(data: any): void {
    const dialogRef = this.dialog.open(LibraryAvatarComponent, {
      width: '500px',
      position: {
        top: '100px'
      },
      disableClose: true,
      direction: 'ltr',
      data: {
        customerAvatarDTO: this.customerAvatarDTO,
        dataFile: data,
        userProfile: this.userProfile
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll();
      this.formAvatar.reset();
      this.resetFormAvatar();
    });
  }
  updateAvatar(imageInput: any) {
    // const file: File = imageInput.files[0];
    // const reader = new FileReader();
    // reader.addEventListener('load', (event: any) => {
    //   const imgNew = event.target.result.split(";base64,")[1];
    //   this.formAvatar.patchValue({
    //     avatar: imgNew
    //   })
    // });
    if (imageInput.target.files && imageInput.target.files[0]) {
      this.openDialog(imageInput);
    }

    // reader.readAsDataURL(file);

  }
  resetFormAvatar() {
    this.imageAvatar.nativeElement.value = '';
  }

  private createForm() {
    this.formAvatar = this.formBuilder.group({
      avatar: ['', Validators.required]
    });
  }
}
