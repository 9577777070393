import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomerTravellerDTO, CustomerTravellerMemberCardDTO} from "@gtd/api-services/customer-service";
import {Subscription} from "rxjs";
import {CustomerResourceService, LookupDTO} from "@gtd/b2c-client";
import {LookupFacade} from "@gtd/meta";
import {CustomerTravellerFacade} from "../../customer-traveller/+state/customer-traveller.facade";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {DialogMessageComponent} from "@gtd/components/dialog-message";
import {MatDialog, MatSnackBar} from "@angular/material";

@Component({
  selector: 'gtd-member-card-update',
  templateUrl: './member-card-update.component.html',
  styleUrls: ['./member-card-update.component.scss']
})
export class MemberCardUpdateComponent implements OnInit, OnDestroy {
  memberCardForm?: FormGroup;
  customerTravellers: CustomerTravellerDTO;
  numberOfMemberCard: number;
  subscriptions = new Subscription();
  lookups: Array<LookupDTO>;
  submitted: boolean;
  isLoading: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private lookupFacade: LookupFacade,
    private customerTravellerFacade: CustomerTravellerFacade,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private customerResourceService: CustomerResourceService,
  ) {
    this.lookupFacade.loadAll({domainName: 'air_member_card', objectName: '', page: 0, size: 1000});
    this.memberCardForm = this.formBuilder.group({
      memberCards: new FormArray([])
    });
  }

  get f() { return this.memberCardForm.controls; }
  get t() { return this.f.memberCards as FormArray; }


  ngOnInit() {
    this.subscriptions.add(
      this.lookupFacade.allLookup$.subscribe(lookups => {
        this.lookups = lookups;
      })
    )
    this.subscriptions.add(
      this.customerTravellerFacade.selectedCustomerTraveller$.subscribe(results => {
        if(this.lookups && results && results.memberCards.length > 0) {
          this.t.clear();
          results.memberCards.forEach(data => {
            this.t.push(this.formBuilder.group({
              id: [data.id],
              curCardType: [data.cardType],
              curCardNumber: [data.cardNumber],
            }));
          })
        }
        this.customerTravellers = results;
      })
    )
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submitForm() {
    this.submitted = true;
    if(this.memberCardForm.valid) {
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.updating-information', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"});
      const memberCardDTOS = new Array<CustomerTravellerMemberCardDTO>();
      this.memberCardForm.value.memberCards.forEach(memberCard => {
        memberCardDTOS.push({
          id: memberCard.id,
          cardType: memberCard.curCardType,
          cardNumber: memberCard.curCardNumber,
          customerCode: this.customerTravellers && this.customerTravellers.customerCode? this.customerTravellers.customerCode: null,
          travellerId: this.customerTravellers && this.customerTravellers.id? this.customerTravellers.id: null
        })
      });
      this.customerTravellers.memberCards = memberCardDTOS;
      this.customerTravellerFacade.updateCustomerTravellerUsingPut([this.customerTravellers]);

      this.subscriptions.add(
        this.customerTravellerFacade.selectedUpdateCustomerTraveller$.subscribe( resultUpdate => {
          if(resultUpdate && resultUpdate.type === 'Update') {
            if (resultUpdate.lists) {
              setTimeout(()=> {
                this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.update-success', icon: 'check'}, panelClass: 'snackbar-success', verticalPosition: "top", duration: 2000});
                this.submitted = false;
              }, 2000);
            } else {
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [
                    {btnText: 'global.close'}
                  ],
                  content: 'user.system-error-message',
                  heading: 'user.error-message',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true
              });
              this.submitted = false;
            }
          }
        })
      )
    } else {
      this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.check-information'}, panelClass: 'snackbar-warning', verticalPosition: "top", duration: 2000});
    }
    this.isLoading = false;
  }

  addItem() {
    this.submitted = false;
    this.t.push(this.formBuilder.group({
      id: ['', Validators.nullValidator(null)],
      curCardType: ['', [Validators.required]],
      curCardNumber: ['', [Validators.required]],
    }));
    this.numberOfMemberCard = this.t.length;
  }
  removeItem(i:number, memberCard: any) {
    if(memberCard.value && memberCard.value.id) {
      let dialogRef = this.dialog.open(DialogMessageComponent, {
        data: {
          btn: [
            {btnColor: 'default', btnText: 'global.close'},
            {btnColor: 'success', btnText: 'user.delete-member-card', action: 'DELETE'},
          ],
          content: 'user.delete-member-card-message',
          heading: 'user.delete-member-card-heading',
          dataTranslate: memberCard.value.curCardType + ' ('+memberCard.value.curCardNumber+')',
          messageType: 'success'
        },
        panelClass: 'dialog-do-confirm',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(actionClose => {
        if(actionClose && actionClose.action === "DELETE") {
          this.customerResourceService.deleteCustomerTravellerMemberCardUsingDELETE(memberCard.value.id).subscribe(memberCardDelete => {
            this.t.removeAt(i);
            this.numberOfMemberCard = this.t.length;
          });
        }
      })
    } else {
      this.t.removeAt(i);
      this.numberOfMemberCard = this.t.length;
    }
    // this.t.removeAt(i);
    // this.numberOfMemberCard = this.t.length;
  }
}
