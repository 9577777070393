/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TourAttributeValue } from './tourAttributeValue';


export interface TourAttributeMapping { 
    id?: number;
    name?: string;
    refId?: number;
    type?: TourAttributeMapping.TypeEnum;
    values?: Array<TourAttributeValue>;
}
export namespace TourAttributeMapping {
    export type TypeEnum = 'ACTIVITY' | 'RATE_PLAN';
    export const TypeEnum = {
        ACTIVITY: 'ACTIVITY' as TypeEnum,
        RATEPLAN: 'RATE_PLAN' as TypeEnum
    };
}
