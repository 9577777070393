import { Action } from '@ngrx/store';
import { AirLowFareFilterVM, AirLowFareSearchRS } from '@gtd/b2c-client';

export interface FilterFlightsAvailable {
  filter?: AirLowFareFilterVM;
  includeEquivfare: boolean;
  page?: number;
  size?: number;
  sort?: Array<string>;
}

export enum AvailableFlightsActionTypes {
  LoadAvailableFlights = '[AvailableFlights] Load AvailableFlights',
  AvailableFlightsLoaded = '[AvailableFlights] AvailableFlights Loaded',
  AvailableFlightsLoadError = '[AvailableFlights] AvailableFlights Load Error',
  ResetAvailableFlights = '[AvailableFlights] Reset AvailableFlights',
  SelectAvailableFlights = '[AvailableFlights] Select AvailableFlights',
  LoadInternationnalFlights = '[AvailableFlights] Load InternationalFlights',
  InternationalFlightsLoaded = '[AvailableFlights] InternationalFlights Loaded',
}

export class LoadAvailableFlights implements Action {
  readonly type = AvailableFlightsActionTypes.LoadAvailableFlights;

  constructor(public payload: FilterFlightsAvailable) {
    }
}
export class LoadInternationnalFlights implements Action {
  readonly type = AvailableFlightsActionTypes.LoadInternationnalFlights;

  constructor(public payload: any) {
  }
}

export class AvailableFlightsLoadError implements Action {
  readonly type = AvailableFlightsActionTypes.AvailableFlightsLoadError;

  constructor(public payload: any) {
    }
}

export class AvailableFlightsLoaded implements Action {
  readonly type = AvailableFlightsActionTypes.AvailableFlightsLoaded;

  constructor(public payload: AirLowFareSearchRS) {}
}
export class InternationalFlightsLoaded implements Action {
  readonly type = AvailableFlightsActionTypes.InternationalFlightsLoaded;

  constructor(public payload: AirLowFareSearchRS) {}
}

export class SelectAvailableFlights implements Action {
  readonly type = AvailableFlightsActionTypes.SelectAvailableFlights;

  constructor(public payload: string) {}
}

export class ResetAvailableFlights implements Action {
  readonly type = AvailableFlightsActionTypes.ResetAvailableFlights;
}

export type AvailableFlightsAction =
  | LoadAvailableFlights
  | AvailableFlightsLoaded
  | AvailableFlightsLoadError
  | ResetAvailableFlights
  | LoadInternationnalFlights
  | InternationalFlightsLoaded
  | SelectAvailableFlights;

export const fromAvailableFlightsActions = {
  LoadAvailableFlights,
  AvailableFlightsLoaded,
  AvailableFlightsLoadError,
  ResetAvailableFlights,
  SelectAvailableFlights,
  LoadInternationnalFlights,
  InternationalFlightsLoaded
};
