import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryImageSize,
  NgxGalleryOptions
} from 'ngx-gallery';

@Component({
  selector: 'gtd-popup-info-item-room',
  templateUrl: './popup-info-item-room.component.html',
  styleUrls: ['./popup-info-item-room.component.scss']
})
export class PopupInfoItemRoomComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  amenitiesGroup;
  constructor(
    public dialogRef: MatDialogRef<PopupInfoItemRoomComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      images: Array<any>;
      index: number;
      descriptions: Array<any>;
      contents: Array<any>;
      title: string;
    }
  ) {
    this.galleryOptions = [
      {
        breakpoint: 991,
        thumbnailsColumns: 10,
        thumbnailsMoveSize: 8
      },
      {
        breakpoint: 800,
        thumbnailsColumns: 6,
        thumbnailsMoveSize: 4
      },
      {
        breakpoint: 400,
        thumbnailsColumns: 4,
        thumbnailsMoveSize: 2
      },
      {
        width: '100%',
        height: '100%',
        preview: false,
        thumbnailsColumns: 10,
        thumbnailsPercent: 8,
        imageAnimation: NgxGalleryAnimation.Slide,
        arrowNextIcon: 'next',
        arrowPrevIcon: 'prev',
        lazyLoading: true,
        imageSwipe: true,
        imagePercent: 100,
        imageSize: NgxGalleryImageSize.Contain,
        startIndex: data.index,
        thumbnailsSwipe: true,
        thumbnailsAutoHide: true,
        thumbnailsMoveSize: 10
      }
    ];
    this.galleryImages = this.mappingGallery(this.data.images);
  }

  ngOnInit() {
    this.amenitiesGroup = this.data.contents.reduce(function(arr, cur) {
      arr[cur.group] = arr[cur.group] || [];
      arr[cur.group].push(cur);
      return arr;
    }, Object.create(null));
  }

  closePopup() {
    this.dialogRef.close();
  }

  mappingGallery(images: Array<any>): NgxGalleryImage[] {
    return images.map((image: any) => {
      return {
        big: image.link,
        small: image.link.replace('_z', '_e'),
        medium: image.link.replace('_z', '_w')
      };
    });
  }
}
