/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AgentTO {
  accountId?: number;
  agencyCode?: string;
  agencyId?: number;
  agencyName?: string;
  agentCode?: string;
  agentName?: string;
  avatar?: string;
  avatarContentType?: string;
  defaultCustomerId?: number;

  avatarId?: number;
  balance?: number;
  branchCode?: string;
  branchId?: number;
  branchName?: string;
  confirmPasswd?: string;
  credit?: number;
  currentLoginUser?: boolean;
  defaultAgencyCode?: string;
  defaultAgent?: boolean;
  displayRoles?: string;
  email?: string;
  extNumber?: string;
  id?: number;
  loginId?: number;
  loginUsername?: string;
  mobile?: string;
  orgCode?: string;
  passwd?: string;
  phoneNumber?: string;
  roleNames?: string;
  roles?: Array<string>;
  status?: AgentTO.StatusEnum;
  dob: Date;
  firstName: string;
  lastName: string;
  gender: string;
}
export namespace AgentTO {
  export type StatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'OTHER';
  export const StatusEnum = {
    ACTIVATED: 'ACTIVATED' as StatusEnum,
    DEACTIVATED: 'DEACTIVATED' as StatusEnum,
    OTHER: 'OTHER' as StatusEnum
  };
}
