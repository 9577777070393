import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router, UrlSerializer } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { HotelSearchService } from './hotel-search.service';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { HotelMetaKeyword, SearchKeywordElement } from '@gtd/b2c-client';
import { Subscription } from 'rxjs';
import { AutocompleteSearchKeywordFacade } from '../../state/autocomplete-search-keyword/autocomplete-search-keyword.facade';

@Component({
  selector: 'gtd-new-hotel-form-search',
  templateUrl: './hotel-form-search.component.html',
  styleUrls: ['./hotel-form-search.component.scss']
})
export class NewHotelFormSearchComponent implements OnInit, OnDestroy {
  @Input() pageName: string;
  @Input() hotelLocation?: string;

  hotelSearchForm: FormGroup;
  submited: boolean;
  startDate = new Date();
  defaultFromDate;
  defaultToDate;
  getDataSearchHotel;
  roomArray: any;
  roomsSearch: any;
  subscriptions = new Subscription();
  historySearchKeyword: SearchKeywordElement[];
  filterStars: number[] = [];
  appName: string = localStorage.getItem('appName');

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private serializer: UrlSerializer,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private autocompleteSearchKeywordFacade: AutocompleteSearchKeywordFacade,
    private hotelSearchService: HotelSearchService
  ) {
    this.getDataSearchHotel = JSON.parse(
      localStorage.getItem('dataSearchHotel')
    );
    if (
      this.getDataSearchHotel !== null &&
      this.compareDates(this.getDataSearchHotel.fromDate, this.startDate) === 1
    ) {
      this.defaultFromDate = new Date(this.getDataSearchHotel.fromDate);
      this.defaultToDate = new Date(this.getDataSearchHotel.toDate);
    } else {
      this.defaultFromDate = new Date(
        new Date().setDate(new Date().getDate() + 10)
      );
      this.defaultToDate = new Date(
        new Date().setDate(new Date().getDate() + 17)
      );
    }
    this.hotelSearchForm = new FormGroup({
      location: new FormControl(
        this.getDataSearchHotel === null
          ? []
          : this.getDataSearchHotel.locationName,
        Validators.required
      ),
      rooms: new FormGroup({
        room: new FormArray([])
      }),
      datePicker: new FormGroup({
        fromDate: new FormControl(this.defaultFromDate, Validators.required),
        toDate: new FormControl(this.defaultToDate, Validators.required)
      })
    });
    this.roomArray = this.hotelSearchForm.controls['rooms'].value;
  }

  ngOnInit() {
    let roomsArrs = [];
    if (this.getDataSearchHotel !== null) {
      roomsArrs = JSON.parse(this.getDataSearchHotel.rooms);
    } else {
      roomsArrs = [
        {
          adultQuantity: [2],
          childQuantity: [0],
          childrenAges: []
        }
      ];
    }
    roomsArrs.forEach(roomArr => {
      this.rooms.push(
        this.fb.group({
          adultQuantity: roomArr.adultQuantity,
          childQuantity: roomArr.childQuantity,
          childrenAges: [roomArr.childrenAges]
        })
      );
    });

    this.subscriptions.add(
      this.autocompleteSearchKeywordFacade.allHotelSearchKeyword$.subscribe(
        historySearchKeyword => {
          this.historySearchKeyword = historySearchKeyword;
        }
      )
    );
  }
  displayErrorMessage(formControl: FormControl) {
    return formControl.hasError('required') ? 'newface.errors.required-to' : '';
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  compareDates(date1: Date, date2: Date): number {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;
    if (d1 > d2) return 1;
    if (d1 < d2) return -1;
  }

  get rooms(): FormArray {
    return this.hotelSearchForm.controls['rooms'].get('room') as FormArray;
  }
  roomsValid(roomsData) {
    this.roomsSearch = roomsData.rooms;
    if (!roomsData.valid) {
      this.hotelSearchForm.controls['rooms'].setErrors({
        rooms: roomsData.valid
      });
    } else {
      this.hotelSearchForm.controls['rooms'].setErrors(null);
    }
  }

  updateFilterStars(newFilterStars: number[]) {
    this.filterStars = newFilterStars;
  }

  submit() {
    this.submited = true;
    if (this.hotelSearchForm.valid) {
      // this._gaTrackingService.track('H_Search_TapSearchButton');
      this.setLocalStorageLocation();
      let data = {
        locationCode: this.hotelSearchForm.value.location.searchCode,
        searchType: this.hotelSearchForm.value.location.searchType,
        supplier: this.hotelSearchForm.value.location.supplier,
        locationName: this.hotelSearchForm.value.location.name,
        fromDate: moment(this.hotelSearchForm.value.datePicker.fromDate).format(
          'YYYY-MM-DD'
        ),
        toDate: moment(this.hotelSearchForm.value.datePicker.toDate).format(
          'YYYY-MM-DD'
        ),
        rooms: JSON.stringify(this.roomsSearch.room),
        filterStars: this.filterStars.length > 0 ? this.filterStars : null
      };
      localStorage.setItem('dataSearchHotel', JSON.stringify(data));
      if (this.hotelSearchForm.value.location.searchType === 'AUTO') {
        this.autocompleteSearchKeywordFacade.addSearchKeywordHotel([
          this.hotelSearchForm.value.location
        ]);
      }
      setTimeout(() => {
        if (this.pageName === 'search-result') {
          const url = this.router.createUrlTree(['/hotel'], {
            queryParams: {
              params: btoa(encodeURIComponent(JSON.stringify(data)))
            }
          });
          let newWindow = window.open('', '_self');
          newWindow.location.href = decodeURIComponent(
            this.serializer.serialize(url)
          );
        } else {
          this.router.navigate(
            ['hotels'],
            {
              queryParams: {
                params: btoa(encodeURIComponent(JSON.stringify(data)))
              }
            }
          );
        }
      }, 100);
    }
    // if (this.hotelSearchForm.controls['rooms'].invalid) {
    //   this._snackBar.openFromComponent(SnackbarComponent, {
    //     data: {
    //       message: 'search.hotel.room-invalid',
    //       icon: 'exclamation-triangle'
    //     },
    //     panelClass: 'snackbar-warning',
    //     verticalPosition: 'top',
    //     duration: 2000
    //   });
    // }
  }

  setLocalStorageLocation() {
    if (this.historySearchKeyword && this.historySearchKeyword.length > 0) {
      let localStorageLocations = this.hotelSearchService.getAllLocalStorageLocation(
        'location'
      );

      let newFrom = new Array<HotelMetaKeyword>();
      if (localStorageLocations) {
        let from = localStorageLocations.filter(
          destination =>
            destination.searchCode === this.historySearchKeyword[0].searchCode
        );
        if (from.length > 0) {
          let index = localStorageLocations.indexOf(from[0]);
          localStorageLocations.splice(index, 1);
          localStorageLocations.unshift(this.historySearchKeyword[0]);
          newFrom = localStorageLocations;
        } else {
          localStorageLocations.unshift(this.historySearchKeyword[0]);
          newFrom = localStorageLocations;
        }
      } else {
        newFrom = this.historySearchKeyword;
      }
      if (newFrom && newFrom.length > 0) {
        localStorage.setItem('location', JSON.stringify(newFrom));
      }
    }
  }

  clickDisable() {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: 'global.functionality-development',
        icon: 'exclamation-triangle'
      },
      panelClass: 'snackbar-warning',
      verticalPosition: 'top',
      duration: 2000
    });
  }
}
