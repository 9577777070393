import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ReportReferralPartialState } from './report-referral.reducer';
import { reportReferralQuery } from './report-referral.selectors';
import { LoadReportReferral } from './report-referral.actions';

@Injectable()
export class ReportReferralFacade {
  loaded$ = this.store.pipe(select(reportReferralQuery.getLoaded));
  allReportReferral$ = this.store.pipe(
    select(reportReferralQuery.getAllReportReferral)
  );
  selectedReportReferral$ = this.store.pipe(
    select(reportReferralQuery.getSelectedReportReferral)
  );

  constructor(private store: Store<ReportReferralPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadReportReferral());
  }
}
