/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PluginsPluginBody { 
    /**
     * Scope under which the request is made; determines fields present in response.
     */
    context?: Array<PluginsPluginBody.ContextEnum>;
    /**
     * The plugin activation status.
     */
    status?: Array<PluginsPluginBody.StatusEnum>;
}
export namespace PluginsPluginBody {
    export type ContextEnum = 'view' | 'embed' | 'edit';
    export const ContextEnum = {
        View: 'view' as ContextEnum,
        Embed: 'embed' as ContextEnum,
        Edit: 'edit' as ContextEnum
    };
    export type StatusEnum = 'inactive' | 'active';
    export const StatusEnum = {
        Inactive: 'inactive' as StatusEnum,
        Active: 'active' as StatusEnum
    };
}