import { Injectable, Optional } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Observable } from 'rxjs/Observable';
import { Configuration } from '../configuration';
import {concatMap, map} from "rxjs/operators";

@Injectable()
export class ContentService {
  protected basePath = 'https://cms.gotadi.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }


  public getCMSContentUsingGET(
    slug: string,
    categories: number
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (slug !== undefined && slug !== null) {
      queryParameters = queryParameters.set('slug', <any>slug);
    }

    if (categories !== undefined && categories !== null) {
      queryParameters = queryParameters.set('categories', <any>categories);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<any>(`${this.basePath}/wp-json/wp/v2/posts`, {
      params: queryParameters,
      withCredentials: false,
      headers: headers
    });
  }

  public getCMSContentListUsingGET(
    search: string,
    categories?: number,
    page?: number,
    size?: number
  ): Observable<HttpResponse<any>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }

    if (categories !== undefined && categories !== null) {
      queryParameters = queryParameters.set('categories', <any>categories);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('per_page', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<any>(`${this.basePath}/wp-json/wp/v2/posts`, {
      params: queryParameters,
      withCredentials: false,
      observe: 'response',
      headers: headers
    });
  }

  public searchCMSContentListUsingGET(
    search: string,
    categories?: number,
    page?: number,
    size?: number
  ): Observable<HttpResponse<any>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }

    if (categories !== undefined && categories !== null) {
      queryParameters = queryParameters.set('categories', <any>categories);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('per_page', <any>size);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<any>(`${this.basePath}/wp-json/wp/v2/search`, {
      params: queryParameters,
      withCredentials: false,
      observe: 'response',
      headers: headers
    });
  }

  callApisSequentially(
    search: string,
    categories?: number,
    page?: number,
    size?: number
  ): Observable<any> {
    return this.getCMSContentListUsingGET(search, categories, page, size).pipe(
      concatMap(api1Data =>
        this.searchCMSContentListUsingGET(search, categories, page, size).pipe(
          map(api2Data => {
            let dataBody = api2Data.body.filter(itemApi2 => {
              const existsInApi1 = api1Data.body.some(itemApi1 => itemApi1.id === itemApi2.id);
              return existsInApi1;
            }).map(itemApi2 => {
              const foundItemApi1 = api1Data.body.find(itemApi1 => itemApi1.id === itemApi2.id);
              return foundItemApi1 || itemApi2;
            });
            return ({
              headers: api1Data.headers,
              body: dataBody
            })
          })
        )
      )
    );
  }

  public getCMSListCategoriesUsingGET(
    post_type: string,
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (post_type !== undefined && post_type !== null) {
      queryParameters = queryParameters.set('post_type', <any>post_type);
    }
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<any>(`${this.basePath}/wp-json/wp/v2/categories`, {
      params: queryParameters,
      withCredentials: false,
      headers: headers
    });
  }

  public getDetailContentUsingGET(id: number): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<any>(
      `${this.basePath}/wp-json/wp/v2/posts/${id}`,
      {
        params: queryParameters,
        withCredentials: false,
        headers: headers
      }
    );
  }
}
