import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { GetInviteCodePartialState } from './get-invite-code.reducer';
import { getInviteCodeQuery } from './get-invite-code.selectors';
import { LoadGetInviteCode } from './get-invite-code.actions';

@Injectable()
export class GetInviteCodeFacade {
  loaded$ = this.store.pipe(select(getInviteCodeQuery.getLoaded));
  allGetInviteCode$ = this.store.pipe(
    select(getInviteCodeQuery.getAllGetInviteCode)
  );
  selectedGetInviteCode$ = this.store.pipe(
    select(getInviteCodeQuery.getSelectedGetInviteCode)
  );

  constructor(private store: Store<GetInviteCodePartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadGetInviteCode());
  }
}
