import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {FinalBookingDetailPartialState} from './final-booking-detail.reducer';
import {
  FinalBookingDetailActionTypes,
  FinalBookingDetailLoadError,
  LoadFinalBookingDetail
} from './final-booking-detail.actions';
import {ProductResourceService} from "@gtd/b2c-client";
import {map} from "rxjs/operators";

@Injectable()
export class FinalBookingDetailEffects {
  @Effect() loadFinalBookingDetail$ = this.dataPersistence.fetch(
    FinalBookingDetailActionTypes.LoadFinalBookingDetail, {
      run: (action: LoadFinalBookingDetail, state: FinalBookingDetailPartialState) => {
        return this.productResourceService
          .getFinalBookingDetailUsingGET(action.payload).pipe(
            map(result => ({
              type: FinalBookingDetailActionTypes.FinalBookingDetailLoaded,
              payload: result
            }))
          );
      },
      onError: (action: LoadFinalBookingDetail, error) => {
        console.error('Error', error);
        return new FinalBookingDetailLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private productResourceService: ProductResourceService,
    private dataPersistence: DataPersistence<FinalBookingDetailPartialState>
  ) {}
}
