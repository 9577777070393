import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-booking-detail-loading',
  templateUrl: './booking-detail-loading.component.html',
  styleUrls: ['./booking-detail-loading.component.scss']
})
export class BookingDetailLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
