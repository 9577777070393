import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AgencyResourceService } from './api/agencyResource.service';
import { AgentAvatarResourceService } from './api/agentAvatarResource.service';
import { AgentCustomerMemberCardResourceService } from './api/agentCustomerMemberCardResource.service';
import { AgentCustomerResourceService } from './api/agentCustomerResource.service';
import { AgentProfileResourceService } from './api/agentProfileResource.service';
import { AgentScheduleResourceService } from './api/agentScheduleResource.service';
import { BranchResourceService } from './api/branchResource.service';
import { CareerResourceService } from './api/careerResource.service';
import { ClientMappingResourceService } from './api/clientMappingResource.service';
import { LableResourceService } from './api/lableResource.service';
import { LinkedAccountResourceService } from './api/linkedAccountResource.service';
import { MigrateDataResourceService } from './api/migrateDataResource.service';
import { ProfileInfoResourceService } from './api/profileInfoResource.service';
import { ReconciliationGeneralMappingResourceService } from './api/reconciliationGeneralMappingResource.service';
import { ReconciliationGeneralTypeResourceService } from './api/reconciliationGeneralTypeResource.service';
import { ReferralResourceService } from "./api/referralResource.service";

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AgencyResourceService,
    AgentAvatarResourceService,
    AgentCustomerMemberCardResourceService,
    AgentCustomerResourceService,
    AgentProfileResourceService,
    AgentScheduleResourceService,
    BranchResourceService,
    CareerResourceService,
    ClientMappingResourceService,
    LableResourceService,
    LinkedAccountResourceService,
    MigrateDataResourceService,
    ProfileInfoResourceService,
    ReconciliationGeneralMappingResourceService,
    ReconciliationGeneralTypeResourceService,
    ReferralResourceService
  ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('B2cApiClientModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
