import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { MustMatch } from '@gtd/helpers';
import { AccountResourceService } from '@gtd/b2c-client';
import set = Reflect.set;
import { PixelTrackingService } from '@gtd/pixel-tracking';
import { GaTrackingService } from '@gtd/ga-tracking';

@Component({
  selector: 'gtd-email-register',
  templateUrl: './email-register.component.html',
  styleUrls: ['./email-register.component.scss']
})
export class EmailRegisterComponent implements OnInit {
  hide = true;
  isLoading: boolean;
  registerForm?: FormGroup;
  subscriptions = new Subscription();
  hasReferralCode = false;
  @Input() referralCode: boolean;
  @Input() referralCodeInvalid = false;
  redirectParamEncode;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private accountResourceService: AccountResourceService,
    private activatedRoute: ActivatedRoute,
    private _pixelTrackingService: PixelTrackingService,
    private _gaTrackingService: GaTrackingService
  ) {
    this.createForm();
    this.activatedRoute.queryParams.subscribe(param => {
      if (param && param.params) {
        this.redirectParamEncode = param.params
      }
    });
  }
  ngOnInit() {
    setTimeout(() => {
      this.activatedRoute.params.subscribe(params => {
        if (params && params.id) {
          this.hasReferralCode = true;
          this.registerForm.controls['referencedBy'].setValue(params.id);
        }
      });
    });
  }

  submitForm() {
    this.isLoading = true;
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: { message: 'user.registering', loading: true },
      panelClass: 'snackbar-loading',
      verticalPosition: 'top'
    });
    this.accountResourceService
      .registerAccountUsingPOST({
        login: this.registerForm.get('email').value,
        email: this.registerForm.get('email').value,
        password: this.registerForm.get('password').value,
        firstName: this.registerForm.get('firstName').value,
        lastName: this.registerForm.get('lastName').value,
        referencedBy: this.registerForm.get('referencedBy').value
      })
      .subscribe(
        register => {
          if (register.success) {
            // this.filumAnalytics.identifyUser({
            //   user_id: this.registerForm.get('email').value,
            //   email: this.registerForm.get('email').value,
            //   first_name: this.registerForm.get('firstName').value,
            //   last_name: this.registerForm.get('firstName').value,
            //   created_at: new Date()
            // });
            // this.filumAnalytics.track('New User Signed Up', {
            //   email: this.registerForm.get('email').value,
            //   activated: 0,
            //   created_at: new Date()
            // });

            this._pixelTrackingService.track('CompleteRegistration');
            this._gaTrackingService.track('register_success');

            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                content: 'user.check-email-confirm',
                dataTranslate: this.registerForm.get('email').value,
                heading: 'user.register-success',
                icon: 'check',
                messageType: 'success'
              },
              panelClass: 'dialog-message',
              position: {
                top: '200px'
              },
              disableClose: true
            });
            this.router.navigate(['/auth/login'], {queryParams: {params: this.redirectParamEncode}});
          } else {
            if (register.error && register.error.code === 1001) {
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [{ btnText: 'global.close' }],
                  content: 'user.reference-code-error-message',
                  heading: 'user.reference-code-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true
              });
            } else {
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [{ btnText: 'global.close' }],
                  content: 'user.system-error-message',
                  heading: 'user.system-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true
              });
            }
          }
          this.isLoading = false;
          this._snackBar.dismiss();
        },
        error1 => {
          console.log(error1);
          if (
            error1.error &&
            error1.error.error &&
            error1.error.error.code === 101
          ) {
            this.registerForm.controls['email'].setErrors(['alreadyExist']);
          } else if (
            error1.error &&
            error1.error.error &&
            error1.error.error.code === 1001
          ) {
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                content: 'user.reference-code-error-message',
                heading: 'user.reference-code-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          } else {
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                content: 'user.system-error-message',
                heading: 'user.system-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          }
          this.isLoading = false;
          this._snackBar.dismiss();
        }
      );
  }

  getErrorMessage() {
    return this.registerForm.controls['email'].hasError('required')
      ? 'user.email-can-not-be-empty'
      : this.registerForm.controls['email'].errors !== null &&
        this.registerForm.controls['email'].errors[0] === 'alreadyExist'
      ? 'user.email-exist'
      : this.registerForm.controls['email'].hasError('email')
      ? 'user.invalid-email'
      : '';
  }

  getErrorPasswordMessage() {
    return this.registerForm.controls['password'].hasError('required')
      ? 'user.password-not-empty'
      : this.registerForm.controls['password'].hasError('minlength')
      ? 'user.password-minlength-error'
      : '';
  }

  clearRef() {
    this.registerForm.controls['referencedBy'].setValue('');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createForm() {
    this.registerForm = this.formBuilder.group(
      {
        lastName: ['', Validators.required],
        firstName: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(30),
            Validators.pattern('^(?!\\S*\\s).{8,15}$')
          ]
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.pattern('^(?!\\S*\\s).{8,15}$')
          ]
        ],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$'
            )
          ]
        ],
        referencedBy: ['']
      },
      {
        validators: MustMatch('password', 'confirmPassword')
      }
    );
  }
}
