import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'gtd-hotel-filter',
  templateUrl: './hotel-filter.component.html',
  styleUrls: ['./hotel-filter.component.scss']
})
export class HotelFilterComponent implements OnInit {
  hotelFilterForm: FormGroup;
  listValue;
  allHotelFilterProperty = [
    {
      label: 'Hủy miễn phí',
      value: 'filter1'
    },
    {
      label: 'Trả tiền sau',
      value: 'filter2'
    },
    {
      label: 'Không cần thẻ tín dụng',
      value: 'filter3'
    }
  ];
  constructor(private fb: FormBuilder) {
    this.hotelFilterForm = this.fb.group({
      roomFilter: this.fb.array([])
    });
  }

  ngOnInit() {
    const filters = (<FormArray>(
      this.hotelFilterForm.get('roomFilter')
    )) as FormArray;
    this.allHotelFilterProperty.forEach(filter => {
      filters.push(new FormControl(false));
    });
    this.hotelFilterForm
      .get('roomFilter')
      .valueChanges.subscribe(valueChange => {
        this.listValue = [];
        valueChange.forEach((listValue, index) => {
          if (listValue) {
            this.listValue.push(this.allHotelFilterProperty[index].value);
          }
        });
      });
  }
}
