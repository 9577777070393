import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  COMBO_SEARCH_INFOMARTION_FEATURE_KEY,
  ComboSearchInformationState
} from './combo-search-information.reducer';

// Lookup the 'ComboSearchInformation' feature state managed by NgRx
const getComboSearchInformationState = createFeatureSelector<
  ComboSearchInformationState
>(COMBO_SEARCH_INFOMARTION_FEATURE_KEY);

const getLoaded = createSelector(
  getComboSearchInformationState,
  (state: ComboSearchInformationState) => state.loaded
);
const getError = createSelector(
  getComboSearchInformationState,
  (state: ComboSearchInformationState) => state.error
);

const getAllComboSearchInformation = createSelector(
  getComboSearchInformationState,
  getLoaded,
  (state: ComboSearchInformationState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getComboSearchInformationState,
  (state: ComboSearchInformationState) => state.selectedId
);
const getSelectedComboSearchInformation = createSelector(
  getAllComboSearchInformation,
  getSelectedId,
  (ComboSearchInformation, id) => {
    const result = ComboSearchInformation.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const ComboSearchInformationQuery = {
  getLoaded,
  getError,
  getAllComboSearchInformation,
  getSelectedComboSearchInformation
};
