import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'gtd-form-passengers',
  templateUrl: './form-passengers.component.html',
  styleUrls: ['./form-passengers.component.scss']
})
export class FormPassengersComponent implements OnInit {
  @Input() arrayName: string;
  @Input() roomArray: any;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  public rooms: FormGroup;

  minAdult = 1;
  maxAdult = 8;
  minChild = 0;
  maxChild = 4;
  minInfant = 0;
  maxInfant = 9;

  totalAdult: number = 2;
  totalChild: number = 0;
  totalInfant: number = 0;

  maxPerson: number = 9;
  sumPerson: number = 0;
  counter = Array;

  constructor(private _fb: FormBuilder) {
    this.rooms = new FormGroup({
      room: this._fb.array([])
    });
  }

  ngOnInit() {
    this.rooms.valueChanges.subscribe(valueChane => {
      if (valueChane) {
        this.sumPerson = valueChane.room.reduce((acc, cur) => {
          return acc + cur.adultQuantity + cur.childQuantity;
        }, 0);
      }
      if (valueChane && valueChane.room) {
        this.totalAdult = this.formArr.value.reduce(
          (prev, next) => prev + +next.adultQuantity,
          0
        );
        this.totalChild = this.formArr.value.reduce(
          (prev, next) => prev + +next.childQuantity,
          0
        );
        this.totalInfant = this.formArr.value.reduce(
          (prev, next) => prev + +next.infantCount,
          0
        );
        this.formArr.value.forEach((item, index) => {
          if (item.childQuantity > item.childrenAges.length) {
            this.addAgesField(index, 'childrenAges');
          }
          if (item.childQuantity < item.childrenAges.length) {
            this.removeAgesField(
              index,
              item.childrenAges.length - 1,
              'childrenAges'
            );
          }
          if (item.infantCount > item.infantAges.length) {
            this.addAgesField(index, 'infantAges');
          }
          if (item.infantCount < item.infantAges.length) {
            this.removeAgesField(
              index,
              item.infantAges.length - 1,
              'infantAges'
            );
          }
        });
        this.changed.emit({
          rooms: this.rooms.value,
          valid: this.rooms.valid
        });
      }
      if (this.totalInfant > this.totalAdult) {
        this.refreshInfantCount();
      }
    });
    if (this.roomArray) {
      this.roomArray.forEach(roomArr => {
        this.room.push(
          this._fb.group({
            adultQuantity: roomArr.adultQuantity,
            childQuantity: roomArr.childQuantity,
            childrenAges: this.setRooms(roomArr.childrenAges),
            infantCount: roomArr.infantCount,
            infantAges: this.setInfantRooms(roomArr.infantAges)
          })
        );
      });
    }
  }

  createAgesArr(first: number, last: number) {
    const agesArr = [];
    for (let i = first; i < last + 1; i++) {
      agesArr.push(i);
    }
    return agesArr;
  }
  refreshInfantCount() {
    // 	this.rooms.map(i => {

    //   }).patchValue({
    //     infantCount: 0
    //   });
    const newRoomValue = this.room.value.map(item => {
      return { ...item, infantCount: 0, infantAges: [] };
    });
    this.room.patchValue(newRoomValue);
  }
  removeAgesField(index: number, itemAge: number, type) {
    const control = (<FormArray>this.rooms.controls['room'])
      .at(index)
      .get(type) as FormArray;
    // const infantControl = (<FormArray>this.rooms.controls['room'])
    //   .at(index)
    //   .get('infantAges') as FormArray;
    control.removeAt(itemAge);
    // infantControl.removeAt(itemAge);
  }

  addAgesField(index: number, type: any, value?: number) {
    const control = (<FormArray>this.rooms.controls['room'])
      .at(index)
      .get(type) as FormArray;
    control.push(new FormControl(value, Validators.required));
    // const intantControl = (<FormArray>this.rooms.controls['room'])
    //   .at(index)
    //   .get('infantAges') as FormArray;
    // intantControl.push(new FormControl(value, Validators.required));
  }

  get formArr() {
    return this.rooms.get('room') as FormArray;
  }

  initItemRows() {
    return this._fb.group({
      adultQuantity: [1],
      childQuantity: [0],
      childrenAges: this._fb.array([new FormControl('', Validators.required)]),
      infantCount: [0],
      infantAges: this._fb.array([new FormControl('', Validators.required)])
    });
  }

  addRoom() {
    this.formArr.push(this.initItemRows());
  }

  addRoomParent() {
    this.room.push(new FormControl('', Validators.required));
  }
  removeRoomParent(index: number) {
    this.room.removeAt(index);
  }

  get room(): FormArray {
    return this.rooms.get('room') as FormArray;
  }

  deleteRoom(index: number) {
    this.formArr.removeAt(index);
    // this.removeRoomParent(index);
  }

  setRooms(childrenAges) {
    let arr = new FormArray([]);
    if (childrenAges) {
      childrenAges.forEach(childrenAge => {
        arr.push(new FormControl(childrenAge));
      });
    }
    return arr;
  }
  setInfantRooms(infantAges) {
    let arr = new FormArray([]);
    if (infantAges) {
      infantAges.forEach(infantAge => {
        arr.push(new FormControl(infantAge));
      });
    }
    return arr;
  }
}
