import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { MatDialog } from '@angular/material';
import { PopupGalleryComponent } from './popup-gallery/popup-gallery.component';

@Component({
  selector: 'gtd-hotel-slider-gallery',
  templateUrl: './hotel-slider-gallery.component.html',
  styleUrls: ['./hotel-slider-gallery.component.scss']
})
export class HotelSliderGalleryComponent implements OnInit {
  @Input() images: Array<any>;
  @ViewChild('galleries', { read: DragScrollComponent, static: false })
  gallery: DragScrollComponent;

  leftNavDisabled: boolean;
  rightNavDisabled: boolean;
  isChange: boolean;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  openDialogGallery(images: Array<any>, index: number) {
    this.dialog.open(PopupGalleryComponent, {
      data: {
        images: images,
        index: index
      },
      position: {
        top: '0px',
        left: '0px'
      },
      panelClass: 'popupGallery'
    });
  }

  moveLeft() {
    this.gallery.moveLeft();
  }

  moveRight() {
    this.gallery.moveRight();
  }

  onIndexChanged(idx) {
    this.isChange = true;
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    if (this.isChange) this.rightNavDisabled = reachesRightBound;
  }
}
