import { Component, OnInit } from '@angular/core';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { MatSnackBar } from '@angular/material';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'gtd-subscribe-input',
  templateUrl: './subscribe-input.component.html',
  styleUrls: ['./subscribe-input.component.scss']
})
export class SubscribeInputComponent implements OnInit {
  subscribeForm: FormGroup;
  submited: boolean;
  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public httpClient: HttpClient
  ) {
    this.createForm();
  }

  ngOnInit() {}
  createForm() {
    this.subscribeForm = this._formBuilder.group({
      subscribe: new FormControl('', [Validators.required, Validators.email])
    });
  }

  subscribes() {
    this.submited = true;
    if (this.subscribeForm.invalid) {
      return;
    }
    this.httpClient
      .post('https://cms.gotadi.com/wp-json/jwt-auth/v1/token', {
        username: 'rest_api',
        password: '$SDE$HDsqbM57vRwf6123ZAQ!xa'
      })
      .subscribe((user: any) => {
        if (user && user.token) {
          const header = {
            headers: new HttpHeaders().set(
              'Authorization',
              `Bearer ${user.token}`
            )
          };

          this.httpClient
            .post(
              'https://cms.gotadi.com/wp-json/wp/v2/gtd_subscriber',
              {
                title: this.subscribeForm.get('subscribe').value
              },
              header
            )
            .subscribe(value => {
              if (value) {
                this.subscribeForm.reset();
                this.submited = false;
                this._snackBar.openFromComponent(SnackbarComponent, {
                  data: {
                    message: 'newface.subscribe-success',
                    icon: 'check'
                  },
                  panelClass: 'snackbar-success',
                  verticalPosition: 'top',
                  duration: 2000
                });
              }
            });
        }
      });
  }
}
