import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { SearchBestRatesPartialState } from './search-best-rates.reducer';
import {
  LoadSearchBestRates,
  SearchBestRatesActionTypes,
  SearchBestRatesLoadError
} from './search-best-rates.actions';
import { map } from 'rxjs/operators';
import { HotelResourceV3Service } from '@gtd/b2c-client';

@Injectable()
export class SearchBestRatesEffects {
  @Effect() loadSearchBestRates$ = this.dataPersistence.fetch(
    SearchBestRatesActionTypes.LoadSearchBestRates,
    {
      run: (
        action: LoadSearchBestRates,
        state: SearchBestRatesPartialState
      ) => {
        this.hotelV3ResourceService.defaultHeaders = this.hotelV3ResourceService.defaultHeaders.set(
          'sales-env',
          ''
        );
        return this.hotelV3ResourceService
          .searchBestRatesUsingGET(
            action.payload.searchCode,
            action.payload.searchType,
            action.payload.language,
            action.payload.currency,
            action.payload.checkIn,
            action.payload.checkOut,
            action.payload.paxInfos,
            action.payload.searchId,
            action.payload.supplier,
            action.payload.targetSupplier,
            action.payload.filterHotelName,
            action.payload.filterHotelCategories,
            action.payload.filterBreakfastIncluded,
            action.payload.filterFromPrice,
            action.payload.filterToPrice,
            action.payload.filterFromDistance,
            action.payload.filterToDistance,
            action.payload.filterStarRating,
            action.payload.filterFromGuestRating,
            action.payload.filterToGuestRating,
            action.payload.filterPropertyAmenities,
            action.payload.filterRoomAmenities,
            action.payload.filterRateAmenities,
            action.payload.filterRoomViews,
            action.payload.filterThemes,
            action.payload.filterMealPlans,
            action.payload.filterBedTypes,
            action.payload.filterGeoDistanceLat,
            action.payload.filterGeoDistanceLon,
            action.payload.filterGeoDistanceMeters,
            action.payload.sortField,
            action.payload.sortOrder,
            action.payload.pageNumber,
            action.payload.pageSize
          )
          .pipe(
            map(payload => ({
              type: SearchBestRatesActionTypes.SearchBestRatesLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadSearchBestRates, error) => {
        console.error('Error', error);
        return new SearchBestRatesLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private hotelV3ResourceService: HotelResourceV3Service,
    private dataPersistence: DataPersistence<SearchBestRatesPartialState>
  ) {}
}
