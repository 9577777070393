import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ResetPasswordStatePartialState } from './reset-password-state.reducer';
import { resetPasswordStateQuery } from './reset-password-state.selectors';
import { LoadResetPasswordState } from './reset-password-state.actions';
import {KeyAndPasswordVM} from "@gtd/b2c-client";

@Injectable()
export class ResetPasswordStateFacade {
  loaded$ = this.store.pipe(select(resetPasswordStateQuery.getLoaded));
  allResetPasswordState$ = this.store.pipe(
    select(resetPasswordStateQuery.getAllResetPasswordState)
  );
  selectedResetPasswordState$ = this.store.pipe(
    select(resetPasswordStateQuery.getSelectedResetPasswordState)
  );

  constructor(private store: Store<ResetPasswordStatePartialState>) {}

  resetPassword(keyAndPasswordVM: KeyAndPasswordVM) {
    this.store.dispatch(new LoadResetPasswordState(keyAndPasswordVM));
  }
}
