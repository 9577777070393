import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AIRPORT_FEATURE_KEY, AirportState } from './airport.reducer';

// Lookup the 'Airport' feature state managed by NgRx
const getAirportState = createFeatureSelector<AirportState>(
  AIRPORT_FEATURE_KEY
);

const getLoaded = createSelector(
  getAirportState,
  (state: AirportState) => state.loaded
);
const getError = createSelector(
  getAirportState,
  (state: AirportState) => state.error
);

const getAllAirport = createSelector(
  getAirportState,
  getLoaded,
  (state: AirportState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getAirportState,
  (state: AirportState) => state.selectedId
);
const getSelectedAirport = createSelector(
  getAllAirport,
  getSelectedId,
  (airport, id) => {
    const result = airport.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const airportQuery = {
  getLoaded,
  getError,
  getAllAirport,
  getSelectedAirport
};
