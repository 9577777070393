import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AFFILIATES_FEATURE_KEY, AffiliatesState } from './affiliates.reducer';

// Lookup the 'Affilates' feature state managed by NgRx
const getAffilatesState = createFeatureSelector<AffiliatesState>(
  AFFILIATES_FEATURE_KEY
);

const getLoaded = createSelector(
  getAffilatesState,
  (state: AffiliatesState) => state.loaded
);
const getError = createSelector(
  getAffilatesState,
  (state: AffiliatesState) => state.error
);

const getAllAffilates = createSelector(
  getAffilatesState,
  getLoaded,
  (state: AffiliatesState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getAffilatesState,
  (state: AffiliatesState) => state.selectedId
);
const getSelectedAffilates = createSelector(
  getAllAffilates,
  getSelectedId,
  (affilates, id) => {
    return affilates ? Object.assign({}, affilates) : undefined;
  }
);

export const affilatesQuery = {
  getLoaded,
  getError,
  getAllAffilates,
  getSelectedAffilates
};
