import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'customDate'
})
export class DatePipeComponent extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    enum Day {
      'Monday' = 'T2',
      'Tuesday' = 'T3',
      'Wednesday' = 'T4',
      'Thursday' = 'T5',
      'Friday' = 'T6',
      'Saturday' = 'T7',
      'Sunday' = 'CN'
    }
    const time = super.transform(value, 'HH:mm', 'UTC');
    const day = Day[super.transform(value, 'EEEE', 'UTC')];
    const date = super.transform(value, 'dd/MM/yyyy', 'UTC');
    return `${time} ${day}, ${date}`;
  }
}
