import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { GetInviteCodePartialState } from './get-invite-code.reducer';
import {
  LoadGetInviteCode,
  GetInviteCodeLoadError,
  GetInviteCodeActionTypes
} from './get-invite-code.actions';
import {map} from "rxjs/operators";
import {ReferralResourceService} from "../../lib";

@Injectable()
export class GetInviteCodeEffects {
  @Effect() loadGetInviteCode$ = this.dataPersistence.fetch(
    GetInviteCodeActionTypes.LoadGetInviteCode,
    {
      run: (action: LoadGetInviteCode, state: GetInviteCodePartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.referralResourceService.getInviteCodeUsingGET().pipe(
          map(result => ({
            type: GetInviteCodeActionTypes.GetInviteCodeLoaded,
            payload: result,
          }))
        );
      },

      onError: (action: LoadGetInviteCode, error) => {
        console.error('Error', error);
        return new GetInviteCodeLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private referralResourceService: ReferralResourceService,
    private dataPersistence: DataPersistence<GetInviteCodePartialState>
  ) {}
}
