import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  CUSTOMERTRAVELLER_FEATURE_KEY,
  CustomerTravellerState
} from './customer-traveller.reducer';
import {CustomerTravellerDTO} from "@gtd/api-services/customer-service";
import * as moment from "moment";
import {RemoveStringAccented} from "@gtd/helpers";

// Lookup the 'CustomerTraveller' feature state managed by NgRx
const getCustomerTravellerState = createFeatureSelector<CustomerTravellerState>(
  CUSTOMERTRAVELLER_FEATURE_KEY
);

const getLoaded = createSelector(
  getCustomerTravellerState,
  (state: CustomerTravellerState) => state.loaded
);
const getError = createSelector(
  getCustomerTravellerState,
  (state: CustomerTravellerState) => state.error
);

const getAllCustomerTraveller = createSelector(
  getCustomerTravellerState,
  getLoaded,
  (state: CustomerTravellerState, isLoaded) => {
    return isLoaded ? state.resultAll : null;
  }
);
const getListCustomerTraveller = createSelector(
  getCustomerTravellerState,
  getLoaded,
  (state: CustomerTravellerState, isLoaded) => {
    return isLoaded && state.resultAll ? Mapping.prototype.mappingAirport(state.resultAll.lists) : null;
  }
);
const getCustomerTraveller = createSelector(
  getCustomerTravellerState,
  getLoaded,
  (state: CustomerTravellerState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getCustomerTravellerState,
  (state: CustomerTravellerState) => state.selectedId
);
const getSelectedCustomerTraveller = createSelector(
  getAllCustomerTraveller,
  getSelectedId,
  (customerTraveller, id) => {
    return customerTraveller ? Object.assign(CustomerTravellerDTO, customerTraveller) : undefined;
  }
);


const getUpdateCustomerTraveller = createSelector(
  getCustomerTravellerState,
  getLoaded,
  (state: CustomerTravellerState, isLoaded) => {
    return isLoaded ? state.resultUpdate : null;
  }
);

const getRemoveCustomerTraveller = createSelector(
  getCustomerTravellerState,
  getLoaded,
  (state: CustomerTravellerState, isLoaded) => {
    return isLoaded ? state.resultRemove : null;
  }
);

export const customerTravellerQuery = {
  getLoaded,
  getError,
  getAllCustomerTraveller,
  getListCustomerTraveller,
  getCustomerTraveller,
  getUpdateCustomerTraveller,
  getSelectedCustomerTraveller,
  getRemoveCustomerTraveller
};

export class Mapping {
  mappingAirport(listPassengers: Array<CustomerTravellerDTO>): CustomerTravellerArrangeByAlphabet[] {
    return this.mappingCustomerTraveller(listPassengers);
  }

  mappingCustomerTraveller(listPassengers: Array<CustomerTravellerDTO>) {
    let customerTravellerMapping: CustomerTravellerArrangeByAlphabet[] = [];
    this.setLetterArray(listPassengers, customerTravellerMapping);
    for (var i = 0; i < listPassengers.length; i++) {
      for (var j = 0; j < customerTravellerMapping.length; j++) {
        let name = this.getNameOnly(listPassengers[i].firstName);
        if (name) {
          let characterHeaderOfCustomerTravellerMapping = customerTravellerMapping[j].characterHeader.toLowerCase();
          let characterHeaderOfListPassengers = this.getNameOnly(listPassengers[i].firstName).toLowerCase().slice(0, 1);
          if (characterHeaderOfCustomerTravellerMapping === characterHeaderOfListPassengers) {
            listPassengers[i].adultType = this.adultTypeCustomerTraveller(listPassengers[i].dob);
            listPassengers[i].firstName = RemoveStringAccented(listPassengers[i].firstName);
            listPassengers[i].surName = RemoveStringAccented(listPassengers[i].surName);
            customerTravellerMapping[j].customerTravellerArr.push(listPassengers[i])
          }
        }
      }
    }
    return customerTravellerMapping;
  }

  setLetterArray(listPassengers: Array<CustomerTravellerDTO>, customerTravellerMapping: CustomerTravellerArrangeByAlphabet[]) {
    let letters = [];
    for (var i = 0; i < listPassengers.length; i++) {
      let name = this.getNameOnly(listPassengers[i].firstName);
      if (name) {
        letters.push(name.slice(0, 1).toUpperCase());
      }
    }
    let sortedLetters = letters.filter(
      (letter, i) => letters.indexOf(letter) == i
    );

    sortedLetters.sort(function (a: string, b: string) {
      if (a > b)
        return 1;
      else return -1;
    })

    for (var i = 0; i < sortedLetters.length; i++) {
      var customerTraveller: CustomerTravellerArrangeByAlphabet = {
        characterHeader: sortedLetters[i],
        customerTravellerArr: []
      }
      customerTravellerMapping.push(customerTraveller);
    }
  }

  getNameOnly(firstName: string) {
    if (firstName) {
      return firstName.split(' ').slice(-1).join('');
    }
  }

  adultTypeCustomerTraveller(dateOfBirth: Date): CustomerTravellerDTO.AdultTypeEnum {
    let toDayDate = new Date();
    let countAgeByMonths = moment(toDayDate).diff(moment(dateOfBirth), 'month', true);

    let ageOfCustomerTraveller = (countAgeByMonths - (countAgeByMonths % 12)) / 12;
    if (ageOfCustomerTraveller >= 12) {
      return CustomerTravellerDTO.AdultTypeEnum.ADT;
    } else if (ageOfCustomerTraveller >= 2 && ageOfCustomerTraveller < 12) {
      return CustomerTravellerDTO.AdultTypeEnum.CHD;
    } else if (ageOfCustomerTraveller < 2) {
      return CustomerTravellerDTO.AdultTypeEnum.INF;
    }

    return CustomerTravellerDTO.AdultTypeEnum.ADT;
  }
}


export interface CustomerTravellerArrangeByAlphabet {
  characterHeader: string;
  customerTravellerArr: Array<CustomerTravellerDTO>;
}
