import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-hotel-detail-loading',
  templateUrl: './hotel-detail-loading.component.html',
  styleUrls: ['./hotel-detail-loading.component.scss']
})
export class HotelDetailLoadingComponent implements OnInit {
  counter = Array;

  constructor() { }

  ngOnInit() {
  }

}
