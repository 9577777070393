import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@env/environment';
import { BASE_PATH } from './variables';
import { ApprovalInvoiceResourceService } from './api/approvalInvoiceResource.service';
import { NotifyUserMessageResourceService } from './api/notifyUserMessageResource.service';
import { NotifyMessageResourceService } from './api/notifyMessageResource.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.utilitysrv },
    ApprovalInvoiceResourceService,
    NotifyUserMessageResourceService,
    NotifyMessageResourceService
  ]
})
export class UtilitysrvModule {}
