import { Action } from '@ngrx/store';
import { PromotionDTO } from '../../lib/model/promotionDTO';
import { Entity } from './promotion-filter-by-voucher.reducer';

export enum PromotionFilterByVoucherActionTypes {
  LoadPromotionFilterByVoucher = '[PromotionFilterByVoucher] Load PromotionFilterByVoucher',
  PromotionFilterByVoucherLoaded = '[PromotionFilterByVoucher] PromotionFilterByVoucher Loaded',
  PromotionFilterByVoucherLoadError = '[PromotionFilterByVoucher] PromotionFilterByVoucher Load Error'
}

export class LoadPromotionFilterByVoucher implements Action {
  readonly type =
    PromotionFilterByVoucherActionTypes.LoadPromotionFilterByVoucher;
  constructor(public payload: PromotionRQ) {}
}

export class PromotionFilterByVoucherLoadError implements Action {
  readonly type =
    PromotionFilterByVoucherActionTypes.PromotionFilterByVoucherLoadError;
  constructor(public payload: any) {}
}

export class PromotionFilterByVoucherLoaded implements Action {
  readonly type =
    PromotionFilterByVoucherActionTypes.PromotionFilterByVoucherLoaded;
  constructor(public payload: PromotionDTO[]) {}
}

export type PromotionFilterByVoucherAction =
  | LoadPromotionFilterByVoucher
  | PromotionFilterByVoucherLoaded
  | PromotionFilterByVoucherLoadError;

export const fromPromotionFilterByVoucherActions = {
  LoadPromotionFilterByVoucher,
  PromotionFilterByVoucherLoaded,
  PromotionFilterByVoucherLoadError
};
export interface PromotionRQ {
  productTypes?: Array<string>;
  voucherCode?: string;
  promotionStatus?:
    | 'PUBLISHING'
    | 'PENDING'
    | 'TERMINATED'
    | 'FINISHED'
    | 'DRAFT';
}
