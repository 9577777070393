import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {
  initialState as shortProfileInitialState, reducerShort,
  SHORTPROFILE_FEATURE_KEY
} from "./stage/short-profile/short-profile.reducer";
import {EffectsModule} from "@ngrx/effects";
import {ShortProfileEffects} from "./stage/short-profile/short-profile.effects";
import {BASE_PATH, CustomerResourceService} from "@gtd/b2c-client";
import {environment} from "@env/environment";
import {ShortProfileFacade} from "./stage/short-profile/short-profile.facade";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(SHORTPROFILE_FEATURE_KEY, reducerShort, {
      initialState: shortProfileInitialState
    }),
    EffectsModule.forFeature([ShortProfileEffects])
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2cClient },
    CustomerResourceService,
    ShortProfileFacade
  ]
})
export class CustomerServiceModule {}
