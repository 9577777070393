import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {NxModule} from '@nrwl/angular';

import {
  initialState as finalBookingDetailInitialState,
  FINALBOOKINGDETAIL_FEATURE_KEY,
  finalBookingDetailReducer
} from './final-booking-detail/final-booking-detail.reducer';
import {FinalBookingDetailEffects} from './final-booking-detail/final-booking-detail.effects';
import {FinalBookingDetailFacade} from './final-booking-detail/final-booking-detail.facade';
import {BASE_PATH, ProductResourceService} from "@gtd/b2c-client";
import { environment } from '@env/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(FINALBOOKINGDETAIL_FEATURE_KEY, finalBookingDetailReducer, {
      initialState: finalBookingDetailInitialState
    }),
    EffectsModule.forFeature([FinalBookingDetailEffects]),
  ],
  providers: [{ provide: BASE_PATH, useValue: environment.api.b2cClient }, FinalBookingDetailFacade, ProductResourceService]
})
export class StateBookingResultModule {}
