import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TOURSEARCHACTIVITYDETAIL_FEATURE_KEY,
  TourSearchActivityDetailState
} from './tour-search-activity-detail.reducer';

// Lookup the 'TourSearchActivityDetail' feature state managed by NgRx
const getTourSearchActivityDetailState = createFeatureSelector<
  TourSearchActivityDetailState
>(TOURSEARCHACTIVITYDETAIL_FEATURE_KEY);

const getLoaded = createSelector(
  getTourSearchActivityDetailState,
  (state: TourSearchActivityDetailState) => state.loaded
);
const getError = createSelector(
  getTourSearchActivityDetailState,
  (state: TourSearchActivityDetailState) => state.error
);

const getAllTourSearchActivityDetail = createSelector(
  getTourSearchActivityDetailState,
  getLoaded,
  (state: TourSearchActivityDetailState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getTourSearchActivityDetailState,
  (state: TourSearchActivityDetailState) => state.selectedId
);
const getSelectedTourSearchActivityDetail = createSelector(
  getAllTourSearchActivityDetail,
  getSelectedId,
  (tourSearchActivityDetail, id) => {
    // const result = tourSearchActivityDetail.find(it => it['id'] === id);
    return tourSearchActivityDetail
      ? Object.assign({}, tourSearchActivityDetail)
      : undefined;
  }
);

export const tourSearchActivityDetailQuery = {
  getLoaded,
  getError,
  getAllTourSearchActivityDetail,
  getSelectedTourSearchActivityDetail
};
