import * as CryptoJS from 'crypto-js';
import { LowfareSearchQueryParam } from './interfaces';

export function encryptSearchAPI(queryParam: LowfareSearchQueryParam) {
  const strInput =
    queryParam.originCode +
    queryParam.destinationCode +
    queryParam.departureDate +
    queryParam.returntureDate +
    queryParam.cabinClass +
    queryParam.routeType +
    queryParam.adutsQtt +
    queryParam.childrenQtt +
    queryParam.infantsQtt +
    queryParam.page +
    queryParam.size +
    queryParam.time;
  return CryptoJS.HmacSHA256(strInput, 'Gotadi').toString(CryptoJS.enc.Base64);
}
