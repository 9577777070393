import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {LookupPartialState} from './lookup.reducer';
import {LoadLookup, LookupActionTypes, LookupLoadError} from './lookup.actions';
import {map} from "rxjs/operators";
import {LookupResourceService} from "@gtd/b2c-client";

@Injectable()
export class LookupEffects {
  @Effect() loadLookup$ = this.dataPersistence.fetch(
    LookupActionTypes.LoadLookup,
    {
      run: (action: LoadLookup, state: LookupPartialState) => {
        return this.lookupResourceService.searchLookupsUsingGET(
            action.payload.domainName,
            action.payload.objectName,
            action.payload.page,
            action.payload.size,
            action.payload.sort,
            action.payload.observe,
            action.payload.reportProgress
          ).pipe(
          map(update => ({
            type: LookupActionTypes.LookupLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadLookup, error) => {
        console.error('Error', error);
        return new LookupLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private lookupResourceService: LookupResourceService,
    private dataPersistence: DataPersistence<LookupPartialState>
  ) {}
}
