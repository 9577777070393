import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { GaTrackingService } from '@gtd/ga-tracking';
@Component({
  selector: 'gtd-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  linkFanpage: string;
  skypeChat: string;
  constructor(private _gaTrackingService: GaTrackingService) {}

  ngOnInit() {
    this.linkFanpage = environment.socialConfig.fanpageUrl;
    this.skypeChat = environment.socialConfig.skypeChat;
  }

  track(eventName: string) {
    this._gaTrackingService.track(eventName);
  }
}
