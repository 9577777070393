/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TravelConditionDTO {
  conditionGroup?: string;
  createdBy?: string;
  createdDate?: Date;
  deleted?: boolean;
  id?: number;
  maxValue?: string;
  minValue?: string;
  policyId?: number;
  policyType?: TravelConditionDTO.PolicyTypeEnum;
  productAttribute?: TravelConditionDTO.ProductAttributeEnum;
  supplierType?: TravelConditionDTO.SupplierTypeEnum;
  unitType?: string;
  updatedBy?: string;
  updatedDate?: Date;
  value?: string;
}
export namespace TravelConditionDTO {
  export type PolicyTypeEnum = 'EXCEPTION' | 'BUDGET' | 'ADDITIONAL';
  export const PolicyTypeEnum = {
    EXCEPTION: 'EXCEPTION' as PolicyTypeEnum,
    BUDGET: 'BUDGET' as PolicyTypeEnum,
    ADDITIONAL: 'ADDITIONAL' as PolicyTypeEnum
  };
  export type ProductAttributeEnum =
    | 'TYPE'
    | 'TOTAL_LIMIT'
    | 'FLIGHT_CLASS'
    | 'FLIGHT_DURATION'
    | 'FLIGHT_ORIGIN'
    | 'FLIGHT_DESTINATION'
    | 'PAYMENT_AMOUNT'
    | 'LOCATION'
    | 'BOOKING_WINDOW'
    | 'HOTEL_STAR';
  export const ProductAttributeEnum = {
    TYPE: 'TYPE' as ProductAttributeEnum,
    TOTALLIMIT: 'TOTAL_LIMIT' as ProductAttributeEnum,
    FLIGHTCLASS: 'FLIGHT_CLASS' as ProductAttributeEnum,
    FLIGHTDURATION: 'FLIGHT_DURATION' as ProductAttributeEnum,
    FLIGHTORIGIN: 'FLIGHT_ORIGIN' as ProductAttributeEnum,
    FLIGHTDESTINATION: 'FLIGHT_DESTINATION' as ProductAttributeEnum,
    PAYMENTAMOUNT: 'PAYMENT_AMOUNT' as ProductAttributeEnum,
    LOCATION: 'LOCATION' as ProductAttributeEnum,
    BOOKINGWINDOW: 'BOOKING_WINDOW' as ProductAttributeEnum,
    HOTELSTAR: 'HOTEL_STAR' as ProductAttributeEnum
  };
  export type SupplierTypeEnum =
    | 'AIR'
    | 'HOTEL'
    | 'COMBO'
    | 'TOURS'
    | 'TRAIN'
    | 'SHIP'
    | 'OTHER';
  export const SupplierTypeEnum = {
    AIR: 'AIR' as SupplierTypeEnum,
    HOTEL: 'HOTEL' as SupplierTypeEnum,
    COMBO: 'COMBO' as SupplierTypeEnum,
    TOURS: 'TOURS' as SupplierTypeEnum,
    TRAIN: 'TRAIN' as SupplierTypeEnum,
    SHIP: 'SHIP' as SupplierTypeEnum,
    OTHER: 'OTHER' as SupplierTypeEnum
  };
}
