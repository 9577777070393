import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FarerulesPartialState } from './farerules.reducer';
import { farerulesQuery } from './farerules.selectors';
import { ComboVJLoadFarerules } from './farerules.actions';
import { AirFareRuleRQ } from '@gtd/b2c-client';

@Injectable()
export class FarerulesFacade {
  loadedFarerules$ = this.store.pipe(select(farerulesQuery.getLoaded));
  allFarerules$ = this.store.pipe(select(farerulesQuery.getAllFarerules));
  selectedFarerules$ = this.store.pipe(
    select(farerulesQuery.getSelectedFarerules)
  );

  constructor(private store: Store<FarerulesPartialState>) {}

  loadAll(airFareRuleRQ: AirFareRuleRQ, language: string) {
    this.store.dispatch(new ComboVJLoadFarerules(airFareRuleRQ, language));
  }
}
