import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GETINVITECODE_FEATURE_KEY,
  GetInviteCodeState
} from './get-invite-code.reducer';

// Lookup the 'GetInviteCode' feature state managed by NgRx
const getGetInviteCodeState = createFeatureSelector<GetInviteCodeState>(
  GETINVITECODE_FEATURE_KEY
);

const getLoaded = createSelector(
  getGetInviteCodeState,
  (state: GetInviteCodeState) => state.loaded
);
const getError = createSelector(
  getGetInviteCodeState,
  (state: GetInviteCodeState) => state.error
);

const getAllGetInviteCode = createSelector(
  getGetInviteCodeState,
  getLoaded,
  (state: GetInviteCodeState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getGetInviteCodeState,
  (state: GetInviteCodeState) => state.selectedId
);
const getSelectedGetInviteCode = createSelector(
  getAllGetInviteCode,
  getSelectedId,
  (getInviteCode, id) => {
    return getInviteCode ? Object.assign({}, getInviteCode) : undefined;
  }
);

export const getInviteCodeQuery = {
  getLoaded,
  getError,
  getAllGetInviteCode,
  getSelectedGetInviteCode
};
