import { Component, Input, OnInit } from '@angular/core';
import { PropertyAllRate } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-regulations-policies',
  templateUrl: './regulations-policies.component.html',
  styleUrls: ['./regulations-policies.component.scss']
})
export class RegulationsPoliciesComponent implements OnInit {
  @Input() label: string;
  @Input() type: string;
  @Input() hotelDetails: PropertyAllRate;
  panelOpenState: boolean = true;

  constructor() {}

  ngOnInit() {}
}
