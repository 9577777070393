import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {COUNTRY_FEATURE_KEY, initialState as countryInitialState, reducer} from './+state/country.reducer';
import {CountryEffects} from './+state/country.effects';
import {CountryFacade} from './+state/country.facade';
import {NxModule} from '@nrwl/angular';
import {environment} from "@env/environment";
import {BASE_PATH, CountryCodeResourceService} from "@gtd/meta-client";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(COUNTRY_FEATURE_KEY, reducer, {
      initialState: countryInitialState
    }),
    EffectsModule.forFeature([CountryEffects])
  ],
  providers: [{provide: BASE_PATH, useValue: environment.api.metaService}, CountryFacade, CountryCodeResourceService]
})
export class CountryModule {}
