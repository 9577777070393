import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '@env/environment';
import { BASE_PATH, TourResourceService } from '@gtd/b2c-client';
import * as fromTourFilterOptions from './tour-filter-options/tour-filter-options.reducer';
import { TourFilterOptionsEffects } from './tour-filter-options/tour-filter-options.effects';
import { TourFilterOptionsFacade } from './tour-filter-options/tour-filter-options.facade';
import * as fromTourUnitPrice from './tour-unit-price/tour-unit-price.reducer';
import { TourUnitPriceEffects } from './tour-unit-price/tour-unit-price.effects';
import { TourUnitPriceFacade } from './tour-unit-price/tour-unit-price.facade';
import * as fromTourSearchByKeyword from './tour-search-by-keyword/tour-search-by-keyword.reducer';
import { TourSearchByKeywordEffects } from './tour-search-by-keyword/tour-search-by-keyword.effects';
import { TourSearchByKeywordFacade } from './tour-search-by-keyword/tour-search-by-keyword.facade';
import * as fromTourSearchActivityDetail from './tour-search-activity-detail/tour-search-activity-detail.reducer';
import { TourSearchActivityDetailEffects } from './tour-search-activity-detail/tour-search-activity-detail.effects';
import { TourSearchActivityDetailFacade } from './tour-search-activity-detail/tour-search-activity-detail.facade';
import * as fromTourSearchActivityAvailable from './tour-search-activity-available/tour-search-activity-available.reducer';
import { TourSearchActivityAvailableEffects } from './tour-search-activity-available/tour-search-activity-available.effects';
import { TourSearchActivityAvailableFacade } from './tour-search-activity-available/tour-search-activity-available.facade';
import * as fromTourFilterAvailable from './tour-filter-available/tour-filter-available.reducer';
import { TourFilterAvailableFacade } from './tour-filter-available/tour-filter-available.facade';
import * as fromTourCreateDraftBooking from './tour-create-draft-booking/tour-create-draft-booking.reducer';
import { TourCreateDraftBookingEffects } from './tour-create-draft-booking/tour-create-draft-booking.effects';
import { TourCreateDraftBookingFacade } from './tour-create-draft-booking/tour-create-draft-booking.facade';
import * as fromTourAddBookingTraveller from './tour-add-booking-traveller/tour-add-booking-traveller.reducer';
import { TourAddBookingTravellerEffects } from './tour-add-booking-traveller/tour-add-booking-traveller.effects';
import { TourAddBookingTravellerFacade } from './tour-add-booking-traveller/tour-add-booking-traveller.facade';
import * as fromTourActivityBooking from './tour-activity-booking/tour-activity-booking.reducer';
import { TourActivityBookingFacade } from './tour-activity-booking/tour-activity-booking.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromTourFilterOptions.TOURFILTEROPTIONS_FEATURE_KEY,
      fromTourFilterOptions.reducer
    ),
    EffectsModule.forFeature([TourFilterOptionsEffects]),
    StoreModule.forFeature(
      fromTourUnitPrice.TOURUNITPRICE_FEATURE_KEY,
      fromTourUnitPrice.reducer
    ),
    EffectsModule.forFeature([TourUnitPriceEffects]),
    StoreModule.forFeature(
      fromTourSearchByKeyword.TOURSEARCHBYKEYWORD_FEATURE_KEY,
      fromTourSearchByKeyword.reducer
    ),
    EffectsModule.forFeature([TourSearchByKeywordEffects]),
    StoreModule.forFeature(
      fromTourSearchActivityDetail.TOURSEARCHACTIVITYDETAIL_FEATURE_KEY,
      fromTourSearchActivityDetail.reducer
    ),
    EffectsModule.forFeature([TourSearchActivityDetailEffects]),
    StoreModule.forFeature(
      fromTourSearchActivityAvailable.TOURSEARCHACTIVITYAVAILABLE_FEATURE_KEY,
      fromTourSearchActivityAvailable.reducer
    ),
    EffectsModule.forFeature([TourSearchActivityAvailableEffects]),
    StoreModule.forFeature(
      fromTourFilterAvailable.TOURFILTERAVAILABLE_FEATURE_KEY,
      fromTourFilterAvailable.reducer
    ),
    StoreModule.forFeature(
      fromTourCreateDraftBooking.TOURCREATEDRAFTBOOKING_FEATURE_KEY,
      fromTourCreateDraftBooking.reducer
    ),
    EffectsModule.forFeature([TourCreateDraftBookingEffects]),
    StoreModule.forFeature(
      fromTourAddBookingTraveller.TOURADDBOOKINGTRAVELLER_FEATURE_KEY,
      fromTourAddBookingTraveller.reducer
    ),
    EffectsModule.forFeature([TourAddBookingTravellerEffects]),
    StoreModule.forFeature(
      fromTourActivityBooking.TOURACTIVITYBOOKING_FEATURE_KEY,
      fromTourActivityBooking.reducer
    )
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2cClient },
    TourResourceService,
    TourFilterOptionsFacade,
    TourUnitPriceFacade,
    TourSearchByKeywordFacade,
    TourSearchActivityDetailFacade,
    TourSearchActivityAvailableFacade,
    TourFilterAvailableFacade,
    TourCreateDraftBookingFacade,
    TourAddBookingTravellerFacade,
    TourActivityBookingFacade
  ]
})
export class TourStoreModule {}
