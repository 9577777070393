import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchResultModule} from "./search-result/search-result.module";
import {HotelDetailModule} from "./hotel-detail/hotel-detail.module";

@NgModule({
  imports: [
    CommonModule,
    SearchResultModule,
    HotelDetailModule,

  ]
})
export class HotelModule {}
