/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExtraService } from './extraService';
import { PassengerName } from './passengerName';
import { Passport } from './passport';
import { SpecialServiceRequest } from './specialServiceRequest';


export interface AirTraveler { 
    dateOfBirth?: Date;
    email?: string;
    eticket?: string;
    extraServicesRequest?: Array<ExtraService>;
    frequentFlyerNumber?: string;
    frequentFlyerType?: string;
    gender?: AirTraveler.GenderEnum;
    idx?: number;
    passengerId?: number;
    passengerName?: PassengerName;
    passengerType?: AirTraveler.PassengerTypeEnum;
    passport?: Passport;
    phone1?: string;
    phone2?: string;
    productCode?: string;
    specialServiceRequest?: SpecialServiceRequest;
    transCode?: string;
}
export namespace AirTraveler {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'BOY' | 'GIRL' | 'INF';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        BOY: 'BOY' as GenderEnum,
        GIRL: 'GIRL' as GenderEnum,
        INF: 'INF' as GenderEnum
    };
    export type PassengerTypeEnum = 'ADT' | 'CHD' | 'INF';
    export const PassengerTypeEnum = {
        ADT: 'ADT' as PassengerTypeEnum,
        CHD: 'CHD' as PassengerTypeEnum,
        INF: 'INF' as PassengerTypeEnum
    };
}
