import { Action } from '@ngrx/store';
import { FilterFinancial } from './transaction-financial.reducer';
import {AgencyFinancialDTO} from "../../";
import {HttpResponse} from "@angular/common/http";

export enum TransactionFinancialActionTypes {
  LoadTransactionFinancial = '[TransactionFinancial] Load TransactionFinancial',
  DestroyTransactionFinancial = '[TransactionFinancial] Destroy TransactionFinancial',
  TransactionFinancialLoaded = '[TransactionFinancial] TransactionFinancial Loaded',
  TransactionFinancialLoadError = '[TransactionFinancial] TransactionFinancial Load Error'
}

export class LoadTransactionFinancial implements Action {
  readonly type = TransactionFinancialActionTypes.LoadTransactionFinancial;
  constructor(public payload: FilterFinancial) {}
}


export class DestroyTransactionFinancial implements Action {
  readonly type = TransactionFinancialActionTypes.DestroyTransactionFinancial;
}

export class TransactionFinancialLoadError implements Action {
  readonly type = TransactionFinancialActionTypes.TransactionFinancialLoadError;
  constructor(public payload: any) {}
}

export class TransactionFinancialLoaded implements Action {
  readonly type = TransactionFinancialActionTypes.TransactionFinancialLoaded;
  constructor(public payload: HttpResponse<Array<AgencyFinancialDTO>>) {}
}

export type TransactionFinancialAction =
  | LoadTransactionFinancial
  | TransactionFinancialLoaded
  | DestroyTransactionFinancial
  | TransactionFinancialLoadError;

export const fromTransactionFinancialActions = {
  LoadTransactionFinancial,
  TransactionFinancialLoaded,
  DestroyTransactionFinancial,
  TransactionFinancialLoadError
};
