import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FILTEROPTIONS_FEATURE_KEY,
  FilterOptionsState
} from './filter-options.reducer';

// Lookup the 'FilterOptions' feature state managed by NgRx
const getFilterOptionsState = createFeatureSelector<FilterOptionsState>(
  FILTEROPTIONS_FEATURE_KEY
);

const getLoaded = createSelector(
  getFilterOptionsState,
  (state: FilterOptionsState) => state.loaded
);
const getError = createSelector(
  getFilterOptionsState,
  (state: FilterOptionsState) => state.error
);

const getAllFilterOptions = createSelector(
  getFilterOptionsState,
  getLoaded,
  (state: FilterOptionsState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getFilterOptionsState,
  (state: FilterOptionsState) => state.selectedId
);
const getSelectedFilterOptions = createSelector(
  getAllFilterOptions,
  getSelectedId,
  (filterOptions, id) => {
    return filterOptions ? Object.assign({}, filterOptions) : undefined;
  }
);

export const filterOptionsQuery = {
  getLoaded,
  getError,
  getAllFilterOptions,
  getSelectedFilterOptions
};
