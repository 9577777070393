import {
  CreateDraftBookingActivityPayload,
  OtaResultOfBooking
} from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './tour-create-draft-booking.reducer';

export enum TourCreateDraftBookingActionTypes {
  LoadTourCreateDraftBooking = '[TourCreateDraftBooking] Load TourCreateDraftBooking',
  ResetTourCreateDraftBooking = '[TourCreateDraftBooking] Reset TourCreateDraftBooking',
  TourCreateDraftBookingLoaded = '[TourCreateDraftBooking] TourCreateDraftBooking Loaded',
  TourCreateDraftBookingLoadError = '[TourCreateDraftBooking] TourCreateDraftBooking Load Error'
}

export class LoadTourCreateDraftBooking implements Action {
  readonly type = TourCreateDraftBookingActionTypes.LoadTourCreateDraftBooking;
  constructor(public payload: CreateDraftBookingActivityPayload) {}
}

export class ResetTourCreateDraftBooking implements Action {
  readonly type = TourCreateDraftBookingActionTypes.ResetTourCreateDraftBooking;
}

export class TourCreateDraftBookingLoadError implements Action {
  readonly type =
    TourCreateDraftBookingActionTypes.TourCreateDraftBookingLoadError;
  constructor(public payload: any) {}
}

export class TourCreateDraftBookingLoaded implements Action {
  readonly type =
    TourCreateDraftBookingActionTypes.TourCreateDraftBookingLoaded;
  constructor(public payload: OtaResultOfBooking) {}
}

export type TourCreateDraftBookingAction =
  | LoadTourCreateDraftBooking
  | TourCreateDraftBookingLoaded
  | TourCreateDraftBookingLoadError
  | ResetTourCreateDraftBooking;

export const fromTourCreateDraftBookingActions = {
  LoadTourCreateDraftBooking,
  TourCreateDraftBookingLoaded,
  TourCreateDraftBookingLoadError,
  ResetTourCreateDraftBooking
};
