import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language = new BehaviorSubject<string>('vi');
  getLanguage = this.language.asObservable();

  constructor() {}

  setLanguage(language: string) {
    this.language.next(language);
  }
}
