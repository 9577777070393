import { CountryAction, CountryActionTypes } from './country.actions';
import {CountryCodeDTO} from "@gtd/meta-client";

export const COUNTRY_FEATURE_KEY = 'country';

/**
 * Interface for the 'Country' data used in
 *  - CountryState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}
export interface CountryCode {
  page?: number,
  size?: number,
  sort?: Array<string>
}

export interface CountryState {
  list: Array<CountryCodeDTO>; // list of Country; analogous to a sql normalized table
  selectedId?: string | number; // which Country record has been selected
  loaded: boolean; // has the Country list been loaded
  error?: any; // last none error (if any)
}

export interface CountryPartialState {
  readonly [COUNTRY_FEATURE_KEY]: CountryState;
}

export const initialState: CountryState = {
  list: [],
  loaded: false
};

export function reducer(
  state: CountryState = initialState,
  action: CountryAction
): CountryState {
  switch (action.type) {
    case CountryActionTypes.CountryLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
