import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  Optional,
  PLATFORM_ID
} from '@angular/core';
import { BannerTypeService } from '@gtd/api-services/newface-banner';
import { VIEWER } from '@gtd/utilities';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { environment } from '@env/environment';

@Component({
  selector: 'gtd-hot-promotion',
  templateUrl: './hot-promotion.component.html',
  styleUrls: ['./hot-promotion.component.scss']
})
export class HotPromotionComponent implements OnInit {
  config: SwiperConfigInterface;
  bannerList: any[];
  constructor(
    public bannerTypeService: BannerTypeService,
    @Optional() @Inject(VIEWER) private viewer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.config = {
      spaceBetween: 15,
      // lazy: true,
      navigation: {
        nextEl: '.hot-promotion .swiper-next',
        prevEl: '.hot-promotion .swiper-prev'
      },
      slidesPerView: 3.2,
      loop: true,
      breakpoints: {
        991: {
          slidesPerView: 2
        }
      }
    };
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && this.viewer !== 'bot') {
      this.bannerTypeService
        .wpV2BannerTypeGet('74', environment.production ? '76' : '100')
        .subscribe(banners => {
          this.bannerList = banners;
        });
    }
  }
}
