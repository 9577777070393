import { OtaResultOfUpdateBookingStatusRS } from '@gtd/b2c-client';
import {
  TourAddBookingTravellerAction,
  TourAddBookingTravellerActionTypes
} from './tour-add-booking-traveller.actions';

export const TOURADDBOOKINGTRAVELLER_FEATURE_KEY = 'tourAddBookingTraveller';

/**
 * Interface for the 'TourAddBookingTraveller' data used in
 *  - TourAddBookingTravellerState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface TourAddBookingTravellerState {
  list: OtaResultOfUpdateBookingStatusRS; // list of TourAddBookingTraveller; analogous to a sql normalized table
  selectedId?: string | number; // which TourAddBookingTraveller record has been selected
  loaded: boolean; // has the TourAddBookingTraveller list been loaded
  error?: any; // last none error (if any)
}

export interface TourAddBookingTravellerPartialState {
  readonly [TOURADDBOOKINGTRAVELLER_FEATURE_KEY]: TourAddBookingTravellerState;
}

export const initialState: TourAddBookingTravellerState = {
  list: null,
  loaded: false
};

export function reducer(
  state: TourAddBookingTravellerState = initialState,
  action: TourAddBookingTravellerAction
): TourAddBookingTravellerState {
  switch (action.type) {
    case TourAddBookingTravellerActionTypes.ResetTourAddBookingTraveller: {
      state = {
        ...initialState
      };
      break;
    }
    case TourAddBookingTravellerActionTypes.TourAddBookingTravellerLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
