import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {
  FilterAvailablePartialState,
  FilterAvailableState
} from './filter-available.reducer';
import { filterAvailableQuery } from './filter-available.selectors';
import {
  ComboVJChangeFilterRoomAmenities,
  ComboVJChangeFilterPropertyAmenities,
  ComboVJChangeFilterRoomViews,
  ComboVJChangeFilterThemes,
  ComboVJChangeFilterMealPlans,
  ComboVJChangeFilterBreakfastIncluded,
  ComboVJChangeFilterGuestRating,
  ComboVJChangeFilterHotelCategories,
  ComboVJChangeFilterHotelName,
  ComboVJChangeFilterLanguage,
  ComboVJChangeFilterPrice,
  ComboVJChangeFilterStarRating,
  ComboVJChangeHotelFilter,
  ComboVJChangePageNumber,
  ComboVJChangePageSize,
  ComboVJChangeSort,
  ComboVJResetHotelFilter
} from './filter-available.actions';

@Injectable()
export class FilterAvailableFacade {
  currentFilter$ = this.store.pipe(
    select(filterAvailableQuery.getAllCurrentFilter)
  );
  priceFilter$ = this.store.pipe(
    select(filterAvailableQuery.getCurrentFilterPrice)
  );
  // currentAmenitiesFilter$ = this.store.pipe(
  //   select(filterAvailableQuery.getCurrentAmenitiesFilter)
  // );

  constructor(private store: Store<FilterAvailablePartialState>) {}

  resetFilter() {
    this.store.dispatch(new ComboVJResetHotelFilter());
  }

  changeHotelFilter(filterAvailableState: FilterAvailableState) {
    this.store.dispatch(new ComboVJChangeHotelFilter(filterAvailableState));
  }

  changeFilterHotelName(hotelName: string) {
    this.store.dispatch(new ComboVJChangeFilterHotelName(hotelName));
  }

  changeFilterHotelCategories(hotelCategories: Array<string>) {
    this.store.dispatch(
      new ComboVJChangeFilterHotelCategories(hotelCategories)
    );
  }

  changeFilterBreakfastIncluded(breakfastIncluded: boolean) {
    this.store.dispatch(
      new ComboVJChangeFilterBreakfastIncluded(breakfastIncluded)
    );
  }

  changeFilterPrice(fromPrice: number, toPrice: number) {
    this.store.dispatch(
      new ComboVJChangeFilterPrice({ fromPrice: fromPrice, toPrice: toPrice })
    );
  }

  changeFilterStarRating(starRating: Array<string>) {
    this.store.dispatch(
      new ComboVJChangeFilterStarRating(starRating)
    );
  }

  changeFilterGuestRating(from: number, to: number) {
    this.store.dispatch(
      new ComboVJChangeFilterGuestRating({ from: from, to: to })
    );
  }

  changeFilterRoomAmenities(filterRoomAmenities: Array<string>) {
    this.store.dispatch(
      new ComboVJChangeFilterRoomAmenities(filterRoomAmenities)
    );
  }

  changeFilterPropertyAmenities(filterPropertyAmenities: Array<string>) {
    this.store.dispatch(
      new ComboVJChangeFilterPropertyAmenities(filterPropertyAmenities)
    );
  }

  changeFilterRoomViews(filterRoomViews: Array<string>) {
    this.store.dispatch(new ComboVJChangeFilterRoomViews(filterRoomViews));
  }

  changeFilterThemes(filterThemes: Array<string>) {
    this.store.dispatch(new ComboVJChangeFilterThemes(filterThemes));
  }

  changeFilterMealPlans(filterMealPlans: Array<string>) {
    this.store.dispatch(new ComboVJChangeFilterMealPlans(filterMealPlans));
  }

  changeFilterLanguage(language: 'vi' | 'en') {
    this.store.dispatch(new ComboVJChangeFilterLanguage(language));
  }

  changeSort(
    sortField: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder: 'ASC' | 'DESC'
  ) {
    this.store.dispatch(
      new ComboVJChangeSort({ sortField: sortField, sortOrder: sortOrder })
    );
  }

  changePageNumber(pageNumber: number) {
    this.store.dispatch(new ComboVJChangePageNumber(pageNumber));
  }

  changePageSize(pageSize: number) {
    this.store.dispatch(new ComboVJChangePageSize(pageSize));
  }
}
