import { Action } from '@ngrx/store';
import { Entity } from './hotel.reducer';
import {
  HotelMetaKeyword,
  OtaResultOfListOfHotelMetaKeyword
} from '@gtd/b2c-client';
import { HotelMetaContentDTO } from '@gtd/meta-client';

export enum HotelActionTypes {
  LoadHotel = '[Hotel] Load Hotel',
  HotelLoaded = '[Hotel] Hotel Loaded',
  HotelLoadError = '[Hotel] Hotel Load Error'
}

export class LoadHotel implements Action {
  readonly type = HotelActionTypes.LoadHotel;
  constructor(public payload: any) {}
}

export class HotelLoadError implements Action {
  readonly type = HotelActionTypes.HotelLoadError;
  constructor(public payload: any) {}
}

export class HotelLoaded implements Action {
  readonly type = HotelActionTypes.HotelLoaded;
  constructor(public payload: HotelMetaKeyword[]) {}
}

export type HotelAction = LoadHotel | HotelLoaded | HotelLoadError;

export const fromHotelActions = {
  LoadHotel,
  HotelLoaded,
  HotelLoadError
};
