import {
  GroupItineraryAction,
  GroupItineraryActionTypes
} from './group-itinerary.actions';
import {AirGroupPricedItineraryRS} from "@gtd/b2c-client";
import {AvailableFlightsActionTypes} from "../../flight-cache-search/+state/available-flights.actions";

export const GROUPITINERARY_FEATURE_KEY = 'groupItinerary';

/**
 * Interface for the 'GroupItinerary' data used in
 *  - GroupItineraryState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface GroupItineraryState {
  result: AirGroupPricedItineraryRS; // list of GroupItinerary; analogous to a sql normalized table
  selectedId?: string | number; // which GroupItinerary record has been selected
  loaded: boolean; // has the GroupItinerary list been loaded
  error?: any; // last none error (if any)
}

export interface GroupItineraryPartialState {
  readonly [GROUPITINERARY_FEATURE_KEY]: GroupItineraryState;
}

export const initialState: GroupItineraryState = {
  result: null,
  loaded: false
};

export function groupItineraryReducer(
  state: GroupItineraryState = initialState,
  action: GroupItineraryAction
): GroupItineraryState {
  switch (action.type) {
    case GroupItineraryActionTypes.GroupItineraryLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      }
      break;
    }
    case GroupItineraryActionTypes.ResetGroupItinerary: {
      initialState.loaded = true;
      state = initialState;
      break;
    }
    case GroupItineraryActionTypes.LoadGroupItinerary: {
      state = {
        ...state,
        loaded: false
      }
    }
  }
  return state;
}
