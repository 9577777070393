import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialUser
} from 'angularx-social-login';
import { SocialAuthFacade } from './social-auth/+state/social-auth.facade';
import { Subscription } from 'rxjs';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { AuthenticateService } from '../authenticate/authenticate.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { PixelTrackingService } from '@gtd/pixel-tracking';
import { AccountService } from '@gtd/b2c-client';
import { ShortProfileFacade } from '@gtd/api-services/customer-service';

@Component({
  selector: 'gtd-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit, OnDestroy {
  @Input() type: 'login' | 'register';
  isLoading = false;
  private user: SocialUser;
  private loggedIn: boolean;
  subscriptions = new Subscription();
  redirectParams;
  appName = localStorage.getItem('appName');
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private authenticationService: AuthenticateService,
    private _snackBar: MatSnackBar,
    private socialAuthFacade: SocialAuthFacade,
    public dialog: MatDialog,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private shortProfileFacade: ShortProfileFacade,
    private _pixelTrackingService: PixelTrackingService
  ) {
    this.activatedRoute.queryParams.subscribe(param => {
      if (param && param.params)
        this.redirectParams = JSON.parse(
          decodeURIComponent(atob(param.params))
        );
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.socialAuthFacade.selectedSocialAuth$.subscribe(result => {
        if (result.isAuthenticated !== null) {
          if (result.isAuthenticated === true) {
            //TODO test login social tracking filum
            // this.filumAnalytics.identifyUser({
            //   user_id: result.user.username,
            //   email: result.user.username,
            //   created_at: new Date()
            // });
            // this.filumAnalytics.track('New User Signed Up', {
            //   email: result.user.username,
            //   activated: 1,
            //   created_at: new Date()
            // });
            this.authenticationService.storeAuthenticationToken(result.user);
            this._snackBar.openFromComponent(SnackbarComponent, {
              data: { message: 'user.login-success', icon: 'check' },
              panelClass: 'snackbar-success',
              verticalPosition: 'top',
              duration: 2000
            });
            this.accountService.getAccountUsingGet().subscribe(_ => {
              if (this.appName != 'B2B') {
                this.shortProfileFacade.loadAll();
              }
            });
            if (this.redirectParams && this.redirectParams.urlRedirect) {
              window.open(this.redirectParams.urlRedirect, '_self');
            } else {
              this.router.navigate(['/']);
            }
          } else {
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                content: 'user.system-error-message',
                heading: 'user.system-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
            this.isLoading = false;
            this._snackBar.openFromComponent(SnackbarComponent, {
              data: {
                message: 'user.login-failed',
                icon: 'exclamation-triangle'
              },
              panelClass: 'snackbar-error',
              verticalPosition: 'top',
              duration: 2000
            });
          }
        }
        this.isLoading = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  signInWithGoogle(): void {
    this._pixelTrackingService.trackCustom('Login');
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(user => {
      if (user != null) {
        this.socialAuthFacade.signUpSocial({
          accessToken: user.authToken,
          displayName: user.name,
          email: user.email,
          imageUrl: user.photoUrl,
          profileUrl: user.photoUrl,
          providerId: user.provider,
          providerUserId: user.id,
          rememberMe: true
        });
      }
    });
  }

  signInWithFB(): void {
    this._pixelTrackingService.trackCustom('Login');
    this.isLoading = true;
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(user => {
      if (user != null) {
        this.socialAuthFacade.signUpSocial({
          accessToken: user.authToken,
          displayName: user.name,
          email: user.email,
          imageUrl: user.photoUrl,
          profileUrl: user.photoUrl,
          providerId: user.provider,
          providerUserId: user.id,
          rememberMe: true
        });
      }
    });
  }

  signOut(): void {
    this.authService.signOut();
  }
}
