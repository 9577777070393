import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SEARCHKEYWORD_FEATURE_KEY,
  SearchKeywordState
} from './search-keyword.reducer';

// Lookup the 'SearchKeyword' feature state managed by NgRx
const getSearchKeywordState = createFeatureSelector<SearchKeywordState>(
  SEARCHKEYWORD_FEATURE_KEY
);

const getLoaded = createSelector(
  getSearchKeywordState,
  (state: SearchKeywordState) => state.loaded
);
const getError = createSelector(
  getSearchKeywordState,
  (state: SearchKeywordState) => state.error
);

const getAllSearchKeyword = createSelector(
  getSearchKeywordState,
  getLoaded,
  (state: SearchKeywordState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getSearchKeywordState,
  (state: SearchKeywordState) => state.selectedId
);
const getSelectedSearchKeyword = createSelector(
  getAllSearchKeyword,
  getSelectedId,
  (searchKeyword, id) => {
    return searchKeyword ? Object.assign({}, searchKeyword) : undefined;
  }
);

export const searchKeywordQuery = {
  getLoaded,
  getError,
  getAllSearchKeyword,
  getSelectedSearchKeyword
};
