import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { DatepickerService, LunarDateExport } from '../datepicker.service';
import { ControlContainer } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomDatepickerI18n,
  TranslateDatePicker
} from '../date-format.directive';

@Component({
  selector: 'gtd-datepicker-only',
  templateUrl: './datepicker-only.component.html',
  styleUrls: ['./datepicker-only.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    TranslateDatePicker,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class DatepickerOnlyComponent implements OnInit {
  @Input() displayMonths: number;

  @Input() minDate: Date;
  @Input() maxDate: Date;

  @Input() defaultFromDate: Date;
  @Input() defaultToDate: Date;

  @Input() labelFormDate: string;
  @Input() labelToDate: string;

  @Input() type = '';
  @Input() isSeo = false;

  hoveredDate: NgbDate;
  fromDate: NgbDate;

  minNgbDate: NgbDate;
  maxNgbDate: NgbDate;
  forcus: 'to' | 'from';

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private datepickerService: DatepickerService,
    public controlContainer: ControlContainer,
    private translate: TranslateService,
    private translateDatePicker: TranslateDatePicker,
    private deviceService: DeviceDetectorService
  ) {
    setTimeout(() => {
      this.translate.onLangChange.subscribe(lang => {
        this.translateDatePicker.language = lang.lang;
      });
    });
  }

  ngOnInit() {
    this.displayMonths =
      this.deviceService.isDesktop() || this.deviceService.isTablet() ? 2 : 1;
    if (this.minDate) {
      this.minNgbDate = this.convertNgbDate(this.minDate);
    }
    if (this.maxDate) {
      this.maxNgbDate = this.convertNgbDate(this.maxDate);
    }
    if (this.defaultFromDate) {
      this.fromDate = this.convertNgbDate(this.defaultFromDate);
    }
  }

  onDateSelection(date: NgbDate) {
    this.fromDate = date;
    this.forcus = 'from';

    if (!this.type) {
      this.controlContainer.control.setValue({
        fromDate: new Date(
          Date.UTC(
            this.fromDate.year,
            this.fromDate.month - 1,
            this.fromDate.day
          )
        ),
        toDate: ''
      });
    } else {
      this.controlContainer.control
        .get('fromDate')
        .setValue(
          new Date(
            Date.UTC(
              this.fromDate.year,
              this.fromDate.month - 1,
              this.fromDate.day
            )
          )
        );
    }
  }

  convertNgbDate(date: Date): NgbDate {
    return this.calendar.getNext(
      {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        after(other: NgbDateStruct): boolean {
          return true;
        },
        before(other: NgbDateStruct): boolean {
          return true;
        },
        equals(other: NgbDateStruct): boolean {
          return true;
        }
      },
      'd',
      0
    );
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  getDateLunar(date: NgbDate): LunarDateExport {
    const offset = new Date().getTimezoneOffset();
    return this.datepickerService.convertSolar2Lunar(
      date.day,
      date.month,
      date.year,
      +7
    );
  }
}
