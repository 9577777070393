import {Injectable} from '@angular/core';

import {select, Store} from '@ngrx/store';

import {GroupItineraryPartialState} from './group-itinerary.reducer';
import {groupItineraryQuery} from './group-itinerary.selectors';
import {LoadGroupItinerary, ResetGroupItinerary} from './group-itinerary.actions';
import {AirLowFareFilterVM} from "@gtd/b2c-client";
import {ResetAvailableFlights} from "../../flight-cache-search/+state/available-flights.actions";

@Injectable()
export class GroupItineraryFacade {
  loaded$ = this.store.pipe(select(groupItineraryQuery.getLoaded));
  allGroupItinerary$ = this.store.pipe(
    select(groupItineraryQuery.getAllGroupItinerary)
  );

  allReturnFlightInt$ = this.store.pipe(
    select(groupItineraryQuery.getAllReturnFlightInt)
  );

  selectedGroupItinerary$ = this.store.pipe(
    select(groupItineraryQuery.getSelectedGroupItinerary)
  );

  constructor(private store: Store<GroupItineraryPartialState>) {}

  loadAll(groupItineraryReq: GroupItineraryReq) {
    this.store.dispatch(new LoadGroupItinerary(groupItineraryReq));
  }
  resetAll() {
    this.store.dispatch(new ResetGroupItinerary());
  }
}
export interface GroupItineraryReq {
  id: string,
  airSearchId: AirLowFareFilterVM,
  includeEquivfare?: boolean,
  page?: number,
  size?: number,
  sort?: Array<string>
}
