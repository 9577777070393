import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  COMBOCREATEDRAFTBOOKING_FEATURE_KEY,
  ComboCreateDraftBookingState
} from './combo-create-draft-booking.reducer';

// Lookup the 'ComboCreateDraftBooking' feature state managed by NgRx
const getComboCreateDraftBookingState = createFeatureSelector<
  ComboCreateDraftBookingState
>(COMBOCREATEDRAFTBOOKING_FEATURE_KEY);

const getLoaded = createSelector(
  getComboCreateDraftBookingState,
  (state: ComboCreateDraftBookingState) => state.loaded
);
const getError = createSelector(
  getComboCreateDraftBookingState,
  (state: ComboCreateDraftBookingState) => state.error
);

const getAllComboCreateDraftBooking = createSelector(
  getComboCreateDraftBookingState,
  getLoaded,
  (state: ComboCreateDraftBookingState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getComboCreateDraftBookingState,
  (state: ComboCreateDraftBookingState) => state.selectedId
);
const getSelectedComboCreateDraftBooking = createSelector(
  getAllComboCreateDraftBooking,
  getSelectedId,
  (ComboCreateDraftBooking, id) => {
    return ComboCreateDraftBooking
      ? Object.assign({}, ComboCreateDraftBooking)
      : undefined;
  }
);

export const ComboCreateDraftBookingQuery = {
  getLoaded,
  getError,
  getAllComboCreateDraftBooking,
  getSelectedComboCreateDraftBooking
};
