import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewIncreamentInputComponent } from './new-increament-input.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NewIncreamentInputComponent],
  exports: [NewIncreamentInputComponent]
})
export class NewIncreamentInputModule {}
