import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TRANSACTIONFINANCIAL_FEATURE_KEY,
  TransactionFinancialState
} from './transaction-financial.reducer';

// Lookup the 'TransactionFinancial' feature state managed by NgRx
const getTransactionFinancialState = createFeatureSelector<
  TransactionFinancialState
>(TRANSACTIONFINANCIAL_FEATURE_KEY);

const getLoaded = createSelector(
  getTransactionFinancialState,
  (state: TransactionFinancialState) => state.loaded
);
const getError = createSelector(
  getTransactionFinancialState,
  (state: TransactionFinancialState) => state.error
);

const getAllTransactionFinancial = createSelector(
  getTransactionFinancialState,
  getLoaded,
  (state: TransactionFinancialState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getTotalCount = createSelector(
  getTransactionFinancialState,
  getLoaded,
  (state: TransactionFinancialState, isLoaded) => {
    return isLoaded ? state.totalCount : null;
  }
);
const getSelectedId = createSelector(
  getTransactionFinancialState,
  (state: TransactionFinancialState) => state.selectedId
);
const getSelectedTransactionFinancial = createSelector(
  getAllTransactionFinancial,
  getSelectedId,
  (transactionFinancial, id) => {
    const result = transactionFinancial.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const transactionFinancialQuery = {
  getLoaded,
  getError,
  getAllTransactionFinancial,
  getSelectedTransactionFinancial,
  getTotalCount
};
