/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HotelFilter } from './hotelFilter';


export interface HotelLowFareFilterRQ { 
    filter?: HotelFilter;
    ibeSessionId?: string;
    isNewRequest?: boolean;
    newRequest?: boolean;
    requesterCode?: string;
    requesterType?: HotelLowFareFilterRQ.RequesterTypeEnum;
    searchId?: string;
    subSearchIds?: Array<string>;
}
export namespace HotelLowFareFilterRQ {
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
