import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AuthenticatePartialState } from './authenticate.reducer';
import { authenticateQuery } from './authenticate.selectors';
import {Login, Logout, ResetAll} from './authenticate.actions';

@Injectable()
export class AuthenticateFacade {
  loaded$ = this.store.pipe(select(authenticateQuery.getLoaded));
  allAuthenticate$ = this.store.pipe(
    select(authenticateQuery.getAllAuthenticate)
  );

  selectedAuthenticate$ = this.store.select(authenticateQuery.selectUserState);

  constructor(private store: Store<AuthenticatePartialState>) {}

  loginAction(query) {
    this.store.dispatch(new Login(query));
  }
  resetAllAuthenticate() {
    this.store.dispatch(new ResetAll());
  }
  logOutAction() {
    this.store.dispatch(new Logout());
  }
}
