import {Action} from '@ngrx/store';
import {HotelSearchReq} from "./search-best-rates.facade";
import {OtaResultOfSearchBestRatesResult} from "@gtd/b2c-client";

export enum SearchBestRatesActionTypes {
  LoadSearchBestRates = '[SearchBestRates] Load SearchBestRates',
  SearchBestRatesLoaded = '[SearchBestRates] SearchBestRates Loaded',
  SearchBestRatesLoadError = '[SearchBestRates] SearchBestRates Load Error',
  SearchBestRatesReset = '[SearchBestRates] SearchBestRates Reset'
}

export class LoadSearchBestRates implements Action {
  readonly type = SearchBestRatesActionTypes.LoadSearchBestRates;
  constructor(public payload: HotelSearchReq) {}
}

export class SearchBestRatesLoadError implements Action {
  readonly type = SearchBestRatesActionTypes.SearchBestRatesLoadError;
  constructor(public payload: any) {}
}

export class SearchBestRatesLoaded implements Action {
  readonly type = SearchBestRatesActionTypes.SearchBestRatesLoaded;
  constructor(public payload: OtaResultOfSearchBestRatesResult) {}
}

export class SearchBestRatesReset implements Action {
  readonly type = SearchBestRatesActionTypes.SearchBestRatesReset;
}

export type SearchBestRatesAction =
  | LoadSearchBestRates
  | SearchBestRatesLoaded
  | SearchBestRatesReset
  | SearchBestRatesLoadError;

export const fromSearchBestRatesActions = {
  LoadSearchBestRates,
  SearchBestRatesLoaded,
  SearchBestRatesReset,
  SearchBestRatesLoadError
};

