/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ManagedMechantVM { 
    merchantCode?: ManagedMechantVM.MerchantCodeEnum;
    phone?: string;
}
export namespace ManagedMechantVM {
    export type MerchantCodeEnum = 'TIKI' | 'GOTADI' | 'VIETTEL' | 'SEABANK' | 'LIENVIETBANK' | 'FSOFT';
    export const MerchantCodeEnum = {
        TIKI: 'TIKI' as MerchantCodeEnum,
        GOTADI: 'GOTADI' as MerchantCodeEnum,
        VIETTEL: 'VIETTEL' as MerchantCodeEnum,
        SEABANK: 'SEABANK' as MerchantCodeEnum,
        LIENVIETBANK: 'LIENVIETBANK' as MerchantCodeEnum,
        FSOFT: 'FSOFT' as MerchantCodeEnum
    };
}
