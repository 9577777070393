import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HotelDetailComponent, PercentPrice, RoundPipe} from './hotel-detail.component';
import {HotelSliderGalleryComponent} from './hotel-slider-gallery/hotel-slider-gallery.component';
import {DragScrollModule} from 'ngx-drag-scroll';
import {StateHotelModule} from '../../state/state-hotel.module';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  MatButtonModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRippleModule,
  MatTooltipModule
} from '@angular/material';
import {TabbarDetailComponent} from './tabbar-detail/tabbar-detail.component';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HotelInformationComponent, QuantityPassenger} from './hotel-information/hotel-information.component';
import {HotelRoomDetailComponent} from './hotel-room-detail/hotel-room-detail.component';
import {HotelSearchRoomComponent} from './hotel-room-detail/hotel-search-room/hotel-search-room.component';
import {ReactiveFormsModule} from '@angular/forms';
import {DatepickerModule} from '@gtd/components/datepicker';
import {HotelFormSearchModule} from '../hotel-form-search/hotel-form-search.module';
import {RoomDetailItemComponent} from './hotel-room-detail/room-detail-item/room-detail-item.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {RatePlanItemComponent} from './hotel-room-detail/room-detail-item/rate-plan-item/rate-plan-item.component';
import {HotelDescriptionComponent} from './hotel-description/hotel-description.component';
import {HotelAmenitiesComponent, KeysPipe} from './hotel-amenities/hotel-amenities.component';
import {RegulationsPoliciesComponent} from './regulations-policies/regulations-policies.component';
import {PopupGalleryComponent} from './hotel-slider-gallery/popup-gallery/popup-gallery.component';
import {NgxGalleryModule} from 'ngx-gallery';
import {
  PopupInfoItemRoomComponent
} from './hotel-room-detail/room-detail-item/popup-info-item-room/popup-info-item-room.component';
import {MapPanelDetailComponent} from './map-panel-detail/map-panel-detail.component';
import {AgmCoreModule} from '@agm/core';
import {HotelDetailLoadingComponent} from './hotel-detail-loading/hotel-detail-loading.component';
import {ImageLoaderModule} from '@gtd/directives/image-loader';
import {HotelNavbarComponent} from './hotel-navbar/hotel-navbar.component';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {CustomPipeModule} from 'libs/combovj/src/lib/search-result/pipe/custom-pipe.module';
import {
  PopupRequestFormComponent
} from './hotel-room-detail/room-detail-item/popup-request-form/popup-request-form.component';
import {ContactFormsService} from "@gtd/api-services/contentsrv";
import {SanitizeModule} from "@gtd/pipes/sanitize";

library.add(faChevronLeft, faChevronRight);

@NgModule({
  declarations: [
    HotelDetailComponent,
    HotelSliderGalleryComponent,
    TabbarDetailComponent,
    HotelInformationComponent,
    HotelRoomDetailComponent,
    HotelSearchRoomComponent,
    RoundPipe,
    PercentPrice,
    RoomDetailItemComponent,
    RatePlanItemComponent,
    HotelDescriptionComponent,
    HotelAmenitiesComponent,
    RegulationsPoliciesComponent,
    PopupGalleryComponent,
    PopupInfoItemRoomComponent,
    MapPanelDetailComponent,
    HotelDetailLoadingComponent,
    QuantityPassenger,
    HotelNavbarComponent,
    KeysPipe,
    PopupRequestFormComponent
  ],
  imports: [
    CommonModule,
    DragScrollModule,
    StateHotelModule,
    FontAwesomeModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    DatepickerModule,
    HotelFormSearchModule,
    SwiperModule,
    MatMenuModule,
    MatExpansionModule,
    MatTooltipModule,
    MatRippleModule,
    NgxGalleryModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCtgUEmt9xOzkgnBQsWM0tyNDooMrVgmFQ'
    }),
    MatDividerModule,
    ImageLoaderModule,
    CustomPipeModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    SanitizeModule
  ],
  exports: [HotelDetailComponent, PopupRequestFormComponent],
  entryComponents: [
    PopupGalleryComponent,
    PopupInfoItemRoomComponent,
    MapPanelDetailComponent,
    PopupRequestFormComponent
  ],
  providers: [ContactFormsService]
})
export class HotelDetailModule {}
