import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {DatePicker, DatepickerPartialState} from './datepicker.reducer';
import { datepickerQuery } from './datepicker.selectors';
import { LoadDatepicker } from './datepicker.actions';

@Injectable()
export class DatepickerFacade {
  loaded$ = this.store.pipe(select(datepickerQuery.getLoaded));
  allDatepicker$ = this.store.pipe(select(datepickerQuery.getAllDatepicker));
  selectedDatepicker$ = this.store.pipe(
    select(datepickerQuery.getSelectedDatepicker)
  );

  constructor(private store: Store<DatepickerPartialState>) {}

  setDatePicker(datePicker: DatePicker) {
    this.store.dispatch(new LoadDatepicker(datePicker));
  }
}
