import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {AuthenticatePartialState} from './authenticate.reducer';
import {AuthenticateActionTypes, Login, LoginFailure} from './authenticate.actions';
import {UserJwtControllerService} from "@gtd/b2c-client";
import {map} from "rxjs/operators";

@Injectable()
export class AuthenticateEffects {
  @Effect() loadAuthenticate$ = this.dataPersistence.fetch(
    AuthenticateActionTypes.LOGIN,
    {
      run: (action: Login, state: AuthenticatePartialState) => {
        return this.userJwtControllerService.authorizeUsingPOST(action.payload).pipe(
          map(update => ({
            type: AuthenticateActionTypes.LOGIN_SUCCESS,
            payload: {
              data: action.payload,
              token: update
            },
          }))
        );
      },
      onError: (action: Login, error) => {
        console.error('Error', error);
        return new LoginFailure(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<AuthenticatePartialState>,
    private userJwtControllerService: UserJwtControllerService
  ) {}
}
