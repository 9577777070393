/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface B2BMarkupFormulaValueReq { 
    agencyClass?: string;
    agentCode?: string;
    bookingType?: B2BMarkupFormulaValueReq.BookingTypeEnum;
    cooperationClass?: string;
    fromLocationCode?: string;
    ibeSessionId?: string;
    productClass?: string;
    productType?: B2BMarkupFormulaValueReq.ProductTypeEnum;
    requesterCode?: string;
    requesterType?: B2BMarkupFormulaValueReq.RequesterTypeEnum;
    supplierCode?: string;
    toLocationCode?: string;
}
export namespace B2BMarkupFormulaValueReq {
    export type BookingTypeEnum = 'DOME' | 'INTE';
    export const BookingTypeEnum = {
        DOME: 'DOME' as BookingTypeEnum,
        INTE: 'INTE' as BookingTypeEnum
    };
    export type ProductTypeEnum = 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR';
    export const ProductTypeEnum = {
        AIR: 'AIR' as ProductTypeEnum,
        HOTEL: 'HOTEL' as ProductTypeEnum,
        AIRCOMBO: 'AIRCOMBO' as ProductTypeEnum,
        HOTELCOMBO: 'HOTELCOMBO' as ProductTypeEnum,
        AIRCOMBOVIETJET: 'AIRCOMBOVIETJET' as ProductTypeEnum,
        TOUR: 'TOUR' as ProductTypeEnum
    };
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
