/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9.3
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PaymentOptionService {

    protected basePath = 'https://cms.gotadi.com/wp-json';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     *
     * @param context Scope under which the request is made; determines fields present in response.
     * @param page Current page of the collection.
     * @param perPage Maximum number of items to be returned in result set.
     * @param search Limit results to those matching a string.
     * @param after Limit response to posts published after a given ISO8601 compliant date.
     * @param modifiedAfter Limit response to posts modified after a given ISO8601 compliant date.
     * @param before Limit response to posts published before a given ISO8601 compliant date.
     * @param modifiedBefore Limit response to posts modified before a given ISO8601 compliant date.
     * @param exclude Ensure result set excludes specific IDs.
     * @param include Limit result set to specific IDs.
     * @param offset Offset the result set by a specific number of items.
     * @param order Order sort attribute ascending or descending.
     * @param orderby Sort collection by post attribute.
     * @param slug Limit result set to posts with one or more specific slugs.
     * @param status Limit result set to posts assigned one or more statuses.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wpV2PaymentOptionGet(context?: Array<'view' | 'embed' | 'edit'>, page?: string, perPage?: string, search?: string, after?: Date, modifiedAfter?: Date, before?: Date, modifiedBefore?: Date, exclude?: string, include?: string, offset?: string, order?: Array<'asc' | 'desc'>, orderby?: Array<'author' | 'date' | 'id' | 'include' | 'modified' | 'parent' | 'relevance' | 'slug' | 'include_slugs' | 'title'>, slug?: string, status?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wpV2PaymentOptionGet(context?: Array<'view' | 'embed' | 'edit'>, page?: string, perPage?: string, search?: string, after?: Date, modifiedAfter?: Date, before?: Date, modifiedBefore?: Date, exclude?: string, include?: string, offset?: string, order?: Array<'asc' | 'desc'>, orderby?: Array<'author' | 'date' | 'id' | 'include' | 'modified' | 'parent' | 'relevance' | 'slug' | 'include_slugs' | 'title'>, slug?: string, status?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wpV2PaymentOptionGet(context?: Array<'view' | 'embed' | 'edit'>, page?: string, perPage?: string, search?: string, after?: Date, modifiedAfter?: Date, before?: Date, modifiedBefore?: Date, exclude?: string, include?: string, offset?: string, order?: Array<'asc' | 'desc'>, orderby?: Array<'author' | 'date' | 'id' | 'include' | 'modified' | 'parent' | 'relevance' | 'slug' | 'include_slugs' | 'title'>, slug?: string, status?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wpV2PaymentOptionGet(context?: Array<'view' | 'embed' | 'edit'>, page?: string, perPage?: string, search?: string, after?: Date, modifiedAfter?: Date, before?: Date, modifiedBefore?: Date, exclude?: string, include?: string, offset?: string, order?: Array<'asc' | 'desc'>, orderby?: Array<'author' | 'date' | 'id' | 'include' | 'modified' | 'parent' | 'relevance' | 'slug' | 'include_slugs' | 'title'>, slug?: string, status?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (context) {
            context.forEach((element) => {
                queryParameters = queryParameters.append('context', <any>element);
            })
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (perPage !== undefined && perPage !== null) {
            queryParameters = queryParameters.set('per_page', <any>perPage);
        }
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (after !== undefined && after !== null) {
            queryParameters = queryParameters.set('after', <any>after.toISOString());
        }
        if (modifiedAfter !== undefined && modifiedAfter !== null) {
            queryParameters = queryParameters.set('modified_after', <any>modifiedAfter.toISOString());
        }
        if (before !== undefined && before !== null) {
            queryParameters = queryParameters.set('before', <any>before.toISOString());
        }
        if (modifiedBefore !== undefined && modifiedBefore !== null) {
            queryParameters = queryParameters.set('modified_before', <any>modifiedBefore.toISOString());
        }
        if (exclude !== undefined && exclude !== null) {
            queryParameters = queryParameters.set('exclude', <any>exclude);
        }
        if (include !== undefined && include !== null) {
            queryParameters = queryParameters.set('include', <any>include);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order) {
            order.forEach((element) => {
                queryParameters = queryParameters.append('order', <any>element);
            })
        }
        if (orderby) {
            orderby.forEach((element) => {
                queryParameters = queryParameters.append('orderby', <any>element);
            })
        }
        if (slug !== undefined && slug !== null) {
            queryParameters = queryParameters.set('slug', <any>slug);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
      headers.delete("gtd-client-tracking-device-id");

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.get<any>(`${this.basePath}/wp/v2/payment_option`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param parent The ID for the parent of the autosave.
     * @param context Scope under which the request is made; determines fields present in response.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wpV2PaymentOptionIdAutosavesGet(id: number, parent?: string, context?: Array<'view' | 'embed' | 'edit'>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wpV2PaymentOptionIdAutosavesGet(id: number, parent?: string, context?: Array<'view' | 'embed' | 'edit'>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wpV2PaymentOptionIdAutosavesGet(id: number, parent?: string, context?: Array<'view' | 'embed' | 'edit'>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wpV2PaymentOptionIdAutosavesGet(id: number, parent?: string, context?: Array<'view' | 'embed' | 'edit'>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling wpV2PaymentOptionIdAutosavesGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (parent !== undefined && parent !== null) {
            queryParameters = queryParameters.set('parent', <any>parent);
        }
        if (context) {
            context.forEach((element) => {
                queryParameters = queryParameters.append('context', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.get<any>(`${this.basePath}/wp/v2/payment_option/${encodeURIComponent(String(id))}/autosaves`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param parent The ID for the parent of the autosave.
     * @param date The date the post was published, in the site&#39;s timezone.
     * @param dateGmt The date the post was published, as GMT.
     * @param slug An alphanumeric identifier for the post unique to its type.
     * @param status A named status for the post.
     * @param password A password to protect access to the content and excerpt.
     * @param title The title for the post.
     * @param content The content for the post.
     * @param excerpt The excerpt for the post.
     * @param template The theme file to use to display the post.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wpV2PaymentOptionIdAutosavesPost(id: number, parent?: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wpV2PaymentOptionIdAutosavesPost(id: number, parent?: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wpV2PaymentOptionIdAutosavesPost(id: number, parent?: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wpV2PaymentOptionIdAutosavesPost(id: number, parent?: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling wpV2PaymentOptionIdAutosavesPost.');
        }











        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (parent !== undefined) {
            formParams = formParams.append('parent', <any>parent) || formParams;
        }
        if (date !== undefined) {
            formParams = formParams.append('date', <any>date) || formParams;
        }
        if (dateGmt !== undefined) {
            formParams = formParams.append('date_gmt', <any>dateGmt) || formParams;
        }
        if (slug !== undefined) {
            formParams = formParams.append('slug', <any>slug) || formParams;
        }
        if (status) {
            status.forEach((element) => {
                formParams = formParams.append('status', <any>element) || formParams;
            })
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) || formParams;
        }
        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) || formParams;
        }
        if (content !== undefined) {
            formParams = formParams.append('content', <any>content) || formParams;
        }
        if (excerpt !== undefined) {
            formParams = formParams.append('excerpt', <any>excerpt) || formParams;
        }
        if (template !== undefined) {
            formParams = formParams.append('template', <any>template) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/wp/v2/payment_option/${encodeURIComponent(String(id))}/autosaves`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id Unique identifier for the post.
     * @param force Whether to bypass Trash and force deletion.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wpV2PaymentOptionIdDelete(id: string, force?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wpV2PaymentOptionIdDelete(id: string, force?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wpV2PaymentOptionIdDelete(id: string, force?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wpV2PaymentOptionIdDelete(id: string, force?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling wpV2PaymentOptionIdDelete.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (force !== undefined && force !== null) {
            queryParameters = queryParameters.set('force', <any>force);
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/wp/v2/payment_option/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id Unique identifier for the post.
     * @param context Scope under which the request is made; determines fields present in response.
     * @param password The password for the post if it is password protected.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wpV2PaymentOptionIdGet(id: string, context?: Array<'view' | 'embed' | 'edit'>, password?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wpV2PaymentOptionIdGet(id: string, context?: Array<'view' | 'embed' | 'edit'>, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wpV2PaymentOptionIdGet(id: string, context?: Array<'view' | 'embed' | 'edit'>, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wpV2PaymentOptionIdGet(id: string, context?: Array<'view' | 'embed' | 'edit'>, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling wpV2PaymentOptionIdGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (context) {
            context.forEach((element) => {
                queryParameters = queryParameters.append('context', <any>element);
            })
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.get<any>(`${this.basePath}/wp/v2/payment_option/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id Unique identifier for the post.
     * @param date The date the post was published, in the site&#39;s timezone.
     * @param dateGmt The date the post was published, as GMT.
     * @param slug An alphanumeric identifier for the post unique to its type.
     * @param status A named status for the post.
     * @param password A password to protect access to the content and excerpt.
     * @param title The title for the post.
     * @param content The content for the post.
     * @param excerpt The excerpt for the post.
     * @param template The theme file to use to display the post.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wpV2PaymentOptionIdPatch(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wpV2PaymentOptionIdPatch(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wpV2PaymentOptionIdPatch(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wpV2PaymentOptionIdPatch(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling wpV2PaymentOptionIdPatch.');
        }










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date.toISOString());
        }
        if (dateGmt !== undefined && dateGmt !== null) {
            queryParameters = queryParameters.set('date_gmt', <any>dateGmt.toISOString());
        }
        if (slug !== undefined && slug !== null) {
            queryParameters = queryParameters.set('slug', <any>slug);
        }
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }
        if (content !== undefined && content !== null) {
            queryParameters = queryParameters.set('content', <any>content);
        }
        if (excerpt !== undefined && excerpt !== null) {
            queryParameters = queryParameters.set('excerpt', <any>excerpt);
        }
        if (template !== undefined && template !== null) {
            queryParameters = queryParameters.set('template', <any>template);
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.patch<any>(`${this.basePath}/wp/v2/payment_option/${encodeURIComponent(String(id))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id Unique identifier for the post.
     * @param date The date the post was published, in the site&#39;s timezone.
     * @param dateGmt The date the post was published, as GMT.
     * @param slug An alphanumeric identifier for the post unique to its type.
     * @param status A named status for the post.
     * @param password A password to protect access to the content and excerpt.
     * @param title The title for the post.
     * @param content The content for the post.
     * @param excerpt The excerpt for the post.
     * @param template The theme file to use to display the post.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wpV2PaymentOptionIdPost(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wpV2PaymentOptionIdPost(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wpV2PaymentOptionIdPost(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wpV2PaymentOptionIdPost(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling wpV2PaymentOptionIdPost.');
        }










        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (date !== undefined) {
            formParams = formParams.append('date', <any>date) || formParams;
        }
        if (dateGmt !== undefined) {
            formParams = formParams.append('date_gmt', <any>dateGmt) || formParams;
        }
        if (slug !== undefined) {
            formParams = formParams.append('slug', <any>slug) || formParams;
        }
        if (status) {
            status.forEach((element) => {
                formParams = formParams.append('status', <any>element) || formParams;
            })
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) || formParams;
        }
        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) || formParams;
        }
        if (content !== undefined) {
            formParams = formParams.append('content', <any>content) || formParams;
        }
        if (excerpt !== undefined) {
            formParams = formParams.append('excerpt', <any>excerpt) || formParams;
        }
        if (template !== undefined) {
            formParams = formParams.append('template', <any>template) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/wp/v2/payment_option/${encodeURIComponent(String(id))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id Unique identifier for the post.
     * @param date The date the post was published, in the site&#39;s timezone.
     * @param dateGmt The date the post was published, as GMT.
     * @param slug An alphanumeric identifier for the post unique to its type.
     * @param status A named status for the post.
     * @param password A password to protect access to the content and excerpt.
     * @param title The title for the post.
     * @param content The content for the post.
     * @param excerpt The excerpt for the post.
     * @param template The theme file to use to display the post.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wpV2PaymentOptionIdPut(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wpV2PaymentOptionIdPut(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wpV2PaymentOptionIdPut(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wpV2PaymentOptionIdPut(id: string, date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling wpV2PaymentOptionIdPut.');
        }










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date.toISOString());
        }
        if (dateGmt !== undefined && dateGmt !== null) {
            queryParameters = queryParameters.set('date_gmt', <any>dateGmt.toISOString());
        }
        if (slug !== undefined && slug !== null) {
            queryParameters = queryParameters.set('slug', <any>slug);
        }
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }
        if (content !== undefined && content !== null) {
            queryParameters = queryParameters.set('content', <any>content);
        }
        if (excerpt !== undefined && excerpt !== null) {
            queryParameters = queryParameters.set('excerpt', <any>excerpt);
        }
        if (template !== undefined && template !== null) {
            queryParameters = queryParameters.set('template', <any>template);
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.put<any>(`${this.basePath}/wp/v2/payment_option/${encodeURIComponent(String(id))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param parent The ID for the parent of the autosave.
     * @param id The ID for the autosave.
     * @param context Scope under which the request is made; determines fields present in response.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wpV2PaymentOptionParentGet(parent: string, id?: string, context?: Array<'view' | 'embed' | 'edit'>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wpV2PaymentOptionParentGet(parent: string, id?: string, context?: Array<'view' | 'embed' | 'edit'>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wpV2PaymentOptionParentGet(parent: string, id?: string, context?: Array<'view' | 'embed' | 'edit'>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wpV2PaymentOptionParentGet(parent: string, id?: string, context?: Array<'view' | 'embed' | 'edit'>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parent === null || parent === undefined) {
            throw new Error('Required parameter parent was null or undefined when calling wpV2PaymentOptionParentGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (context) {
            context.forEach((element) => {
                queryParameters = queryParameters.append('context', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.get<any>(`${this.basePath}/wp/v2/payment_option/${encodeURIComponent(String(parent))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param date The date the post was published, in the site&#39;s timezone.
     * @param dateGmt The date the post was published, as GMT.
     * @param slug An alphanumeric identifier for the post unique to its type.
     * @param status A named status for the post.
     * @param password A password to protect access to the content and excerpt.
     * @param title The title for the post.
     * @param content The content for the post.
     * @param excerpt The excerpt for the post.
     * @param template The theme file to use to display the post.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wpV2PaymentOptionPost(date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wpV2PaymentOptionPost(date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wpV2PaymentOptionPost(date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wpV2PaymentOptionPost(date?: Date, dateGmt?: Date, slug?: string, status?: Array<'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled'>, password?: string, title?: string, content?: string, excerpt?: string, template?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (date !== undefined) {
            formParams = formParams.append('date', <any>date) || formParams;
        }
        if (dateGmt !== undefined) {
            formParams = formParams.append('date_gmt', <any>dateGmt) || formParams;
        }
        if (slug !== undefined) {
            formParams = formParams.append('slug', <any>slug) || formParams;
        }
        if (status) {
            status.forEach((element) => {
                formParams = formParams.append('status', <any>element) || formParams;
            })
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) || formParams;
        }
        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) || formParams;
        }
        if (content !== undefined) {
            formParams = formParams.append('content', <any>content) || formParams;
        }
        if (excerpt !== undefined) {
            formParams = formParams.append('excerpt', <any>excerpt) || formParams;
        }
        if (template !== undefined) {
            formParams = formParams.append('template', <any>template) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/wp/v2/payment_option`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
