import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MustMatch, MustNotMatch} from "@gtd/helpers";
import {AccountResourceService} from "@gtd/b2c-client";
import {AccountResourceService as AccountResourceServiceB2B} from "@gtd/api-services/b2b-client";
import {MatSnackBar} from "@angular/material";
import {SnackbarComponent} from "@gtd/components/snackbar";

@Component({
  selector: 'gtd-change-password-profile',
  templateUrl: './change-password-profile.component.html',
  styleUrls: ['./change-password-profile.component.scss']
})
export class ChangePasswordProfileComponent implements OnInit {
  updatePassword: FormGroup;
  isLoading: boolean;
  appName = localStorage.getItem('appName');

  constructor(
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private accountResourceService: AccountResourceService,
    private accountResourceServiceB2B: AccountResourceServiceB2B
  ) {
    this.createForm();
  }

  ngOnInit() {
  }
  private createForm() {
    this.updatePassword = this.formBuilder.group({
      oldPassword: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    },
      {
        validators: [
          MustMatch('password', 'confirmPassword'),
          MustNotMatch('oldPassword', 'password')
        ]
      }
    )
  }
  submitForm() {
    if(this.updatePassword.valid) {
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent,
        {data: {message: 'user.updating-information', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"}
      );
      setTimeout(() => {
        if(this.appName == 'B2B') {
          this.accountResourceServiceB2B.verifyCurrentPasswordUsingPOST(
            this.updatePassword.controls['oldPassword'].value
          ).subscribe(verify => {
            this.isLoading = false;
            this.accountResourceServiceB2B.changePasswordUsingPOST(
              this.updatePassword.controls['password'].value
            ).subscribe(changePass => {
              let changePassResult = {
                success: true,
              }
              this.callBackSuccess(changePassResult);
            }, error1 => this.callBackError(error1))
          }, error1 => {
            this.isLoading = false;
            this._snackBar.openFromComponent(SnackbarComponent, {
              data: {message: 'user.incorrect-current-password', icon: 'exclamation-triangle'},
              panelClass: 'snackbar-error',
              verticalPosition: "top",
              duration: 3000
            });
          })
        } else {
          this.accountResourceService.changePasswordUsingPOST({
            newPassword: this.updatePassword.controls['password'].value,
            oldPassword: this.updatePassword.controls['oldPassword'].value,
          }).subscribe(
            changePassword => this.callBackSuccess(changePassword),
              error1 => this.callBackError(error1)
          )
        }

      }, 500)
    }
  }
  callBackSuccess(changePassword) {
    {
      this.isLoading = false;
      if(changePassword.success) {
        this._snackBar.openFromComponent(SnackbarComponent, {
          data: {message: 'user.update-success', icon: 'check'},
          panelClass: 'snackbar-success',
          verticalPosition: "top",
          duration: 2000
        });
        this.updatePassword.reset();
        Object.keys(this.updatePassword.controls).forEach(key => {
          this.updatePassword.get(key).setErrors(null) ;
        });
      } else {
        this._snackBar.openFromComponent(SnackbarComponent,
          {data: {message: 'user.update-error', icon: 'exclamation-triangle'}, panelClass: 'snackbar-error', verticalPosition: "top", duration: 3000}
        );
      }
    }
  }
  callBackError(error1) {
    {
      this.isLoading = false;
      let message = 'user.update-error';
      if(error1.error.error.code === 100) {
        message = 'user.incorrect-current-password';
      }
      this._snackBar.openFromComponent(SnackbarComponent,
        {data: {message: message, icon: 'exclamation-triangle'}, panelClass: 'snackbar-error', verticalPosition: "top", duration: 3000}
      );
    }
  }
}
