import {
  GetInviteCodeAction,
  GetInviteCodeActionTypes
} from './get-invite-code.actions';
import {OtaResultOfInviteCodeEntity} from "../../lib";

export const GETINVITECODE_FEATURE_KEY = 'getInviteCode';

/**
 * Interface for the 'GetInviteCode' data used in
 *  - GetInviteCodeState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */

export interface GetInviteCodeState {
  list: OtaResultOfInviteCodeEntity; // list of GetInviteCode; analogous to a sql normalized table
  selectedId?: string | number; // which GetInviteCode record has been selected
  loaded: boolean; // has the GetInviteCode list been loaded
  error?: any; // last none error (if any)
}

export interface GetInviteCodePartialState {
  readonly [GETINVITECODE_FEATURE_KEY]: GetInviteCodeState;
}

export const initialStateInviteCode: GetInviteCodeState = {
  list: null,
  loaded: false
};

export function reducerInviteCode(
  state: GetInviteCodeState = initialStateInviteCode,
  action: GetInviteCodeAction
): GetInviteCodeState {
  switch (action.type) {
    case GetInviteCodeActionTypes.GetInviteCodeLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
