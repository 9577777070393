import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewEncapsulation
} from '@angular/core';
import { fadeIn, slideInHorizontal, slideInVertical } from '@gtd/helpers';
import {
  AirItineraryInfo,
  FlightSegment,
  GroupPricedItinerary,
  PricedItinerary
} from '@gtd/b2c-client';
// import { GroupItineraryFacade } from '../../../../../../../flight-search/group-itinerary/+state/group-itinerary.facade';
import { GroupItineraryFacade } from '../../../../flight/flight-search/group-itinerary/+state/group-itinerary.facade';
import { Subscription } from 'rxjs';
import { FlightResultItemService } from '../../../../flight/flight-result-item/flight-result-item.service';
import { FarerulesFacade } from '../../../../flight/flight-search/farerules/+state/farerules.facade';
import { MatMenuTrigger } from '@angular/material';
import {
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { AvailableFlightsFacade } from '../../../../flight/flight-search/flight-cache-search/+state/available-flights.facade';
import { environment } from '@env/environment';
import { SelectItemSrvService } from '../../../../flight/select-item-srv.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ComboVJComboBookingFacade } from '../../../../../../state/combo-booking/combo-booking.facade';
import { Router } from '@angular/router';
import { ComboSelectedService } from '../../../../../combo-selected.service';

@Component({
  selector: 'gtd-flight-booking-item',
  templateUrl: './flight-booking-item.component.html',
  styleUrls: ['./flight-booking-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInHorizontal, slideInVertical, fadeIn],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class FlightBookingItemComponent implements OnInit, OnDestroy {
  @Input() formSearchResult?: any;
  @Input() groupPricedItineraries;
  @Input() searchId?: string;
  @Input() isCreateBooking: boolean;
  @Input() params;
  @Input() flightType: 'to' | 'from';
  @Input() disableButton: boolean;
  @Input() pricedItinerary;
  @Input() cabinClassOptions: Array<string>;

  itemFormGroup: FormGroup;
  flightSegments?: Array<FlightSegment>;
  subscriptions = new Subscription();

  panelOpenState: boolean;
  isCollapsed: boolean;
  isDetailJourney: boolean;
  submitLoadMore: boolean;
  submitLoadFareRule: boolean;
  fareSourceCode: string;

  allGroupItinerary$ = this.groupItineraryFacade.allGroupItinerary$;

  allFarerules$ = this.farerulesFacade.allFarerules$;
  itemFlightSelect: number;

  appName = environment.appName;

  constructor(
    private formBuilder: FormBuilder,
    private farerulesFacade: FarerulesFacade,
    private router: Router,
    private flightResultItemService: FlightResultItemService,
    private comboBookingFacade: ComboVJComboBookingFacade,
    private groupItineraryFacade: GroupItineraryFacade,
    private selectItemSrvService: SelectItemSrvService,
    private _comboSelectedService: ComboSelectedService
  ) {}
  ngOnInit() {
    this.subscriptions.add(
      this.selectItemSrvService.getIsLoadedChildren.subscribe(loaded => {
        if (loaded) {
          this.itemFlightSelect = loaded;
        }
      })
    );

    this.flightSegments = this.groupPricedItineraries.pricedItineraries[0].originDestinationOptions[0].flightSegments;
    this.flightResultItemService.getOpenPanel.subscribe(openPanel => {
      if (openPanel) {
        setTimeout(() => {
          this.isCollapsed = false;
        }, 50);
      }
    });
    this.subscriptions.add(
      this.groupItineraryFacade.allGroupItinerary$.subscribe(
        allGroupPriceItinerary => {
          if (allGroupPriceItinerary) {
            this.submitLoadMore = false;
          }
        }
      )
    );
    this.subscriptions.add(
      this.farerulesFacade.allFarerules$.subscribe(allFarerules => {
        if (allFarerules) {
          this.fareSourceCode = null;
          this.submitLoadFareRule = false;
        }
      })
    );
  }
  getFareRules(fareSourceCode: string) {
    const currentLanguage = localStorage.getItem('language');
    this.fareSourceCode = fareSourceCode;
    this.submitLoadFareRule = true;
    this.farerulesFacade.loadAll(
      {
        fareSourceCode: fareSourceCode,
        groupId: this.groupPricedItineraries.groupId,
        searchId: this.searchId
      },
      currentLanguage
    );
  }

  itineraryMore(flightDirection?: string) {
    this.isDetailJourney = false;
    if (!this.isCollapsed) {
      this.selectItemSrvService.setIsLoadedChildren(-1);
      this.submitLoadMore = true;
      this.flightResultItemService.setOpenPanel(true);
      let departureItinerary = null;
      if (
        this.formSearchResult &&
        this.formSearchResult.listSelected &&
        this.formSearchResult.listSelected.from
      ) {
        departureItinerary = {
          groupId: this.formSearchResult.listSelected.from.itemFlight.groupId,
          airlineCode: this.formSearchResult.listSelected.from.itemFlight
            .airline,
          fareSourceCode: this.formSearchResult.listSelected.from.itemFlight
            .pricedItineraries[0].airItineraryPricingInfo.fareSourceCode,
          supplierCode: this.formSearchResult.listSelected.from.itemFlight
            .airSupplier,
          searchId: this.formSearchResult.listSelected.from.searchId
        } as AirItineraryInfo;
      }

      this.groupItineraryFacade.loadAll({
        id: this.groupPricedItineraries.groupId,
        airSearchId: {
          searchId: this.searchId,
          departureItinerary:
            flightDirection === 'R' ? departureItinerary : null,
          filter: {
            stopOptions: [],
            airlineOptions: [],
            cabinClassOptions: this.cabinClassOptions,
            ticketPolicyOptions: [],
            departureDateTimeOptions: [],
            arrivalDateTimeOptions: [],
            departureDateTimeReturnOptions: [],
            arrivalDateTimeReturnOptions: [],
            flightType: this.groupPricedItineraries.flightType,
            groupId: this.groupPricedItineraries.groupId,
            loadMore: true,
            step: '1'
          }
        },
        page: 0,
        size: 1000,
        sort: ['departureDate', 'asc']
      });
      setTimeout(() => {
        this.isCollapsed = true;
      }, 100);
    } else {
      this.isCollapsed = false;
    }
  }
  resetFlight(type: 'from' | 'to') {
    const flightBooking = JSON.parse(localStorage.getItem('flightBooking'));

    this._comboSelectedService.removeFlight(
      type,
      flightBooking[type === 'from' ? 0 : 1].pricedItinerary
        .airItineraryPricingInfo.itinTotalFare.totalFare.amount
    );
    this.router.navigate(['/combo/flight'], {
      queryParams: {
        params: this.params
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

@Pipe({
  name: 'countPriceTicketByAdultType'
})
export class CountPriceTicketByAdultTypeComponent implements PipeTransform {
  transform(pricePerOnePassenger: number, quantities: number): number {
    return pricePerOnePassenger * quantities;
  }
}

@Pipe({
  name: 'countPriceTicketPerOnePassenger'
})
export class CountPriceTicketPerOnePassengerComponent implements PipeTransform {
  transform(priceTicket: number, quantities: number): number {
    return priceTicket / quantities;
  }
}

@Pipe({
  name: 'logoAirline'
})
export class LogoAirlineComponent implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === 'VN' || value === 'VJ' || value === 'QH' || value === 'BL')
      return environment.cdn.logoAirline + value + '.svg';
    else return environment.cdn.logoAirline + value + '.gif';
  }
}

@Pipe({
  name: 'durationFormat'
})
export class DurationFormatComponent implements PipeTransform {
  transform(millseconds: number, format?: any): any {
    return this.durationFilter(millseconds, format);
  }
  public durationFilter(millseconds, format) {
    let oneSecond = 1;
    let oneMinute = oneSecond * 1;
    let oneHour = oneMinute * 60;
    let oneDay = oneHour * 24;

    let seconds = Math.floor((millseconds % oneMinute) / oneSecond);
    let minutes = Math.floor((millseconds % oneHour) / oneMinute);
    let hours = Math.floor((millseconds % oneDay) / oneHour);
    let days = Math.floor(millseconds / oneDay);

    let timeString = '';

    if (format == 'short') {
      if (days !== 0) {
        timeString += days !== 1 ? days + 'd' : days + 'd';
      }
      if (hours !== 0) {
        timeString += hours !== 1 ? hours + 'h' : hours + 'h';
      }
      if (minutes !== 0) {
        timeString += minutes !== 1 ? minutes + 'm' : minutes + 'm';
      }
      return timeString;
    } else if (format == 'dayOnly') {
      if (days !== 0) {
        timeString += days !== 1 ? days : days;
      }
      return timeString;
    } else if (format == 'defvn') {
      if (days !== 0) {
        timeString += days !== 1 ? days + 'd' : days + 'd';
      }
      if (hours !== 0) {
        timeString += hours !== 1 ? hours + 'h' : hours + 'h';
      }
      if (minutes !== 0) {
        timeString += minutes !== 1 ? minutes + 'm' : minutes + 'm';
      }

      return timeString;
    } else {
      if (days !== 0) {
        timeString += days !== 1 ? days + ' ngày ' : days + ' ngày ';
      }
      if (hours !== 0) {
        timeString += hours !== 1 ? hours + ' giờ ' : hours + ' giờ ';
      }
      if (minutes !== 0) {
        timeString += minutes !== 1 ? minutes + ' phút ' : minutes + ' phút ';
      }
      return timeString;
    }
  }
}
@Pipe({
  name: 'transitTime'
})
export class TransitTimeComponent implements PipeTransform {
  transform(nextTime: Date, currentTime?: Date): any {
    return this.transitTime(currentTime, nextTime);
  }
  transitTime(currentTime, nextTime) {
    let one_Minute = 1000 * 60;
    let date1 = new Date(currentTime);
    let date2 = new Date(nextTime);
    let difference_ms = date2.getTime() - date1.getTime();
    const transitTime = DurationFormatComponent.prototype.durationFilter(
      Math.round(difference_ms / one_Minute),
      'short'
    );
    return transitTime;
  }
}
