import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourSuggestionComponent } from './tour-suggestion.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [TourSuggestionComponent],
  exports: [TourSuggestionComponent]
})
export class TourSuggestionModule {}
