import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {HotelCreateDraftBookingPartialState} from './hotel-create-draft-booking.reducer';
import {
  HotelCreateDraftBookingActionTypes,
  HotelCreateDraftBookingLoadError,
  LoadHotelCreateDraftBooking
} from './hotel-create-draft-booking.actions';
import {map} from "rxjs/operators";
import {HotelResourceV3Service} from "@gtd/b2c-client";

@Injectable()
export class HotelCreateDraftBookingEffects {
  @Effect() loadHotelCreateDraftBooking$ = this.dataPersistence.fetch(
    HotelCreateDraftBookingActionTypes.LoadHotelCreateDraftBooking,
    {
      run: (
        action: LoadHotelCreateDraftBooking,
        state: HotelCreateDraftBookingPartialState
      ) => {
        return this.hotelResourceV3Service.createDraftBookingUsingPOST2(action.payload).pipe(
          map(payload => ({
            type: HotelCreateDraftBookingActionTypes.HotelCreateDraftBookingLoaded,
            payload: payload
          }))
        );
      },

      onError: (action: LoadHotelCreateDraftBooking, error) => {
        console.error('Error', error);
        return new HotelCreateDraftBookingLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private hotelResourceV3Service: HotelResourceV3Service,
    private dataPersistence: DataPersistence<HotelCreateDraftBookingPartialState>
  ) {}
}
