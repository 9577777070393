import { Component, Input, OnInit } from '@angular/core';
import { TravelerInfo } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-hotel-passenger',
  templateUrl: './hotel-passenger.component.html',
  styleUrls: ['./hotel-passenger.component.scss']
})
export class HotelPassengerComponent implements OnInit {
  @Input() travelerInfo: TravelerInfo;
  @Input() type: 'HOTEL' | 'COMBO';
  constructor() {}

  ngOnInit() {}
}
