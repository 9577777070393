import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gtd-brand-item',
  templateUrl: './brand-item.component.html',
  styleUrls: ['./brand-item.component.scss']
})
export class BrandItemComponent implements OnInit {
  @Input() lowestPrices = 1900000;
  @Input() brandLogo =
    'https://firebasestorage.googleapis.com/v0/b/gotadi-2b7ce.appspot.com/o/vj.png?alt=media&token=f2be43a8-e751-4253-b08f-d9f63f6e725f';
  @Input() value = '';
  @Input() isSelected = false;

  @Output() selected: EventEmitter<string> = new EventEmitter();
  constructor() {}

  ngOnInit() {}
  branchClick() {
    this.selected.emit(this.value);
  }
}
