/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TourBrand } from './tourBrand';
import { TourCountry } from './tourCountry';
import { TourDestination } from './tourDestination';
import { TourLocation } from './tourLocation';


export interface TourActivity { 
    additionalInformation?: string;
    brand?: TourBrand;
    currency?: TourActivity.CurrencyEnum;
    destinations?: Array<TourDestination>;
    howToUser?: string;
    id?: number;
    importantInformation?: string;
    meetUpAddress?: string;
    meetUpCountry?: TourCountry;
    meetUpDestination?: TourDestination;
    meetUpInformation?: string;
    meetUpLocation?: TourLocation;
    name?: string;
    note?: string;
    overview?: string;
    policy?: string;
    status?: boolean;
    supplierId?: number;
}
export namespace TourActivity {
    export type CurrencyEnum = 'VND' | 'USD';
    export const CurrencyEnum = {
        VND: 'VND' as CurrencyEnum,
        USD: 'USD' as CurrencyEnum
    };
}
