import { Action } from '@ngrx/store';
import { Entity } from './count-unread.reducer';

export enum CountUnreadActionTypes {
  LoadCountUnread = '[CountUnread] Load CountUnread',
  CountUnreadLoaded = '[CountUnread] CountUnread Loaded',
  CountUnreadLoadError = '[CountUnread] CountUnread Load Error'
}

export class LoadCountUnread implements Action {
  readonly type = CountUnreadActionTypes.LoadCountUnread;
  constructor(public payload: string) {}
}

export class CountUnreadLoadError implements Action {
  readonly type = CountUnreadActionTypes.CountUnreadLoadError;
  constructor(public payload: any) {}
}

export class CountUnreadLoaded implements Action {
  readonly type = CountUnreadActionTypes.CountUnreadLoaded;
  constructor(public payload: any) {}
}

export type CountUnreadAction =
  | LoadCountUnread
  | CountUnreadLoaded
  | CountUnreadLoadError;

export const fromCountUnreadActions = {
  LoadCountUnread,
  CountUnreadLoaded,
  CountUnreadLoadError
};
