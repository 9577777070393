import {
  NotificationsAction,
  NotificationsActionTypes
} from './notifications.actions';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

/**
 * Interface for the 'Notifications' data used in
 *  - NotificationsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface NotificationsState {
  result: any; // list of Notifications; analogous to a sql normalized table
  selectedId?: string | number; // which Notifications record has been selected
  loaded: boolean; // has the Notifications list been loaded
  error?: any; // last none error (if any)
}

export interface NotificationsPartialState {
  readonly [NOTIFICATIONS_FEATURE_KEY]: NotificationsState;
}

export const initialState: NotificationsState = {
  result: null,
  loaded: false
};

export function notificationsReducer(
  state: NotificationsState = initialState,
  action: NotificationsAction
): NotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.NotificationsLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
