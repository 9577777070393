import {Component, Input, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {
  CustomerTravellerDTO,
  CustomerTravellerMemberCardDTO,
  CustomerTravellerResourceService
} from "@gtd/api-services/customer-service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ProfileFacade} from "../../profile/+state/profile.facade";
import {MatDialog, MatSelect, MatSnackBar} from "@angular/material";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {ReplaySubject, Subject, Subscription} from "rxjs";
import {CustomerTravellerFacade} from "../../customer-traveller/+state/customer-traveller.facade";
import {DialogMessageComponent} from "@gtd/components/dialog-message";
import {PassengerService} from "../passenger.service";
import {MemberCardComponent} from "../member-card/member-card.component";
import {CountryCodeDTO} from "@gtd/meta-client";
import {CountryFacade} from "@gtd/meta";
import {takeUntil} from "rxjs/operators";
import {AccountFacade} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-form-passenger',
  templateUrl: './form-passenger.component.html',
  styleUrls: ['./form-passenger.component.scss']
})
export class FormPassengerComponent implements OnInit, OnDestroy {
  @Input() passenger: CustomerTravellerDTO;
  @Input() indexPassenger: number;
  @Input() customerCode: string;
  @Input() travellerId: number;
  @Input() memberCardData: Array<CustomerTravellerMemberCardDTO>;

  @ViewChildren(MemberCardComponent) memberCard: MemberCardComponent;
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('nationalitySelect', { static: true }) nationalitySelect: MatSelect;

  formProfile?: FormGroup;
  isLoading = false;
  subscriptions = new Subscription();
  memberCardValid: boolean;
  memberCardDataUpdate: Array<CustomerTravellerMemberCardDTO>;

  protected countries: CountryCodeDTO[];
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountries: ReplaySubject<CountryCodeDTO[]> = new ReplaySubject<CountryCodeDTO[]>(1);

  protected _onDestroy = new Subject<void>();

  today = new Date();


  constructor(
    private accountFacade: AccountFacade,
    private profileFacade: ProfileFacade,
    private formBuilder: FormBuilder,
    private customerTravellerFacade: CustomerTravellerFacade,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private countryFacade: CountryFacade,
    private customerTravellerResourceService: CustomerTravellerResourceService,
    private passengerService: PassengerService
  ) {
    this.createForm();

  }

  ngOnInit() {
    if(this.passenger) {
      this.formProfile.patchValue({
        id: this.passenger.id,
        surName: this.passenger.surName,
        firstName: this.passenger.firstName,
        email: this.passenger.email,
        phoneNumber1: this.passenger.phoneNumber1,
        dob: this.passenger.dob,
        gender: this.passenger.gender? this.passenger.gender : null,
        address1: this.passenger.address1,
        country: this.passenger.country,
        customerCode: this.passenger.customerCode,
        profileId: this.passenger.profileId,
        isDefault: this.passenger.isDefault,
        documentType: this.passenger.documentType,
        documentNumber: this.passenger.documentNumber,
        nationality: this.passenger.nationality,
        expiredDate: this.passenger.expiredDate
      });
      this.memberCardDataUpdate = this.passenger.memberCards;
    }
    this.subscriptions.add(
      this.passengerService.getMemberCardValid.subscribe(memberCardValid => {
        this.memberCardValid = memberCardValid;
      })
    )
    this.subscriptions.add(
      this.passengerService.getMemberCardData.subscribe((memberCardData: Array<CustomerTravellerMemberCardDTO>) => {
        if(memberCardData.length > 0) {
          this.memberCardDataUpdate = memberCardData;
        }
      })
    )
    this.subscriptions.add(
      this.countryFacade.allCountry$.subscribe((countries: Array<CountryCodeDTO>) => {
        this.countries = countries;
        this.filteredCountries.next(countries.slice());
      })
    )
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });
  }

  protected filterCountries() {
    if (!this.countries) {
      return;
    }
    let search = this.countryFilterCtrl.value;
    if (!search && this.countries) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountries.next(
      this.countries.filter(country => country.name.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  submitForm(index: number) {
    this.passengerService.setParentSubmitForm(true);
    if(this.formProfile.valid && index === this.indexPassenger && this.memberCardValid !== false) {
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.updating-information', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"});
      this.customerTravellerResourceService.updateCustomerTravellerUsingPUT([{
        id: this.formProfile.get('id').value,
        customerCode: this.formProfile.get('customerCode').value,
        address1: this.formProfile.get('address1').value,
        firstName: this.formProfile.get('firstName').value,
        surName: this.formProfile.get('surName').value,
        dob: this.formProfile.get('dob').value,
        country: this.formProfile.get('country').value,
        phoneNumber1: this.formProfile.get('phoneNumber1').value,
        email: this.formProfile.get('email').value,
        profileId: this.formProfile.get('profileId').value,
        gender: this.formProfile.get('gender').value,
        memberCards: this.memberCardDataUpdate? this.memberCardDataUpdate : [],
        isDefault: this.formProfile.get('isDefault').value,
        documentType: this.formProfile.get('documentType').value,
        documentNumber: this.formProfile.get('documentNumber').value,
        nationality: this.formProfile.get('nationality').value,
        expiredDate: this.formProfile.get('expiredDate').value
      }]).subscribe(updateCustomer => {
        if(updateCustomer && updateCustomer.length > 0) {
          this.passenger.id = updateCustomer[0].id;
          this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.update-success', icon: 'check'}, panelClass: 'snackbar-success', verticalPosition: "top", duration: 2000});
          setTimeout(()=> {
            this.isLoading = false;
            window.location.reload();
          }, 500);
        } else {
          this.isLoading = false;
          this.dialog.open(DialogMessageComponent, {
            data: {
              btn: [
                {btnText: 'global.close'}
              ],
              content: 'user.system-error-message',
              heading: 'user.system-error',
              icon: 'exclamation-triangle',
              messageType: 'error'
            },
            panelClass: 'dialog-message',
            position: {
              top: "150px"
            },
            disableClose: true
          });
        }
      });
    } else {
      this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.check-information'}, panelClass: 'snackbar-warning', verticalPosition: "top", duration: 3000});
    }
  }

  private createForm() {
    this.formProfile = this.formBuilder.group({
      id: [''],
      customerCode: ['', Validators.required],
      profileId: ['', Validators.required],
      surName: ['', Validators.required],
      firstName: ['', Validators.required],
      email: ['', [Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$')]],
      phoneNumber1: ['', [Validators.pattern("^((\\+84-?)|0)?[0-9]{10}$")]],
      dob: [''],
      gender: [''],
      isDefault: [''],
      address1: [''],
      country: [''],
      memberCards: this.formBuilder.array([]),
      documentType: [''],
      documentNumber: [''],
      nationality: [''],
      expiredDate:[''],
    });
  }
}
