import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  templateGtd = new BehaviorSubject<string>('agent-template');
  getTemplateGtd = this.templateGtd.asObservable();

  constructor() { }

  setTemplateGtd(template: string) {
    this.templateGtd.next(template);
  }
}
