import { Component, Input, OnInit } from '@angular/core';
import { GroupBooking } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-flight-panel-price',
  templateUrl: './flight-panel-price.component.html',
  styleUrls: ['./flight-panel-price.component.scss']
})
export class FlightPanelPriceComponent implements OnInit {
  @Input() groupBooking: GroupBooking;
  policyBudget: number;
  userPay: number;
  cooperationClass = localStorage.getItem('cooperationClass');

  constructor() {}

  ngOnInit() {
    if (
      this.groupBooking.bookingInfo.paymentHistorys &&
      this.groupBooking.bookingInfo.paymentHistorys.length
    ) {
      const policy = this.groupBooking.bookingInfo.paymentHistorys.find(
        history => history.paymentType == 'BALANCE'
      );
      this.userPay = this.groupBooking.bookingInfo.paymentHistorys.find(
        history => history.paymentType != 'BALANCE'
      )
        ? this.groupBooking.bookingInfo.paymentHistorys.find(
            history => history.paymentType != 'BALANCE'
          ).amount
        : 0;
      this.policyBudget = policy.amount;
    }
  }
}
