import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterAvailableFacade } from '../../../../../../state/filter-available/filter-available.facade';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'gtd-filter-by-hotel-name',
  templateUrl: './filter-by-hotel-name.component.html',
  styleUrls: ['./filter-by-hotel-name.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterByHotelNameComponent),
      multi: true
    }
  ]
})
export class FilterByHotelNameComponent
  implements OnInit, ControlValueAccessor, AfterViewInit {
  @ViewChild('something', { static: false }) something: ElementRef;
  source: any;
  formHotelName: FormGroup;

  constructor(private filterAvailableFacade: FilterAvailableFacade) {
    this.formHotelName = new FormGroup({
      hotelName: new FormControl()
    });
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.source = fromEvent(this.something.nativeElement, 'keyup');
    this.source
      .pipe(debounceTime(1000))
      .subscribe(something =>
        this.filterAvailableFacade.changeFilterHotelName(something.target.value)
      );
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    if (obj) {
      this.formHotelName.controls['hotelName'].setValue(obj);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
