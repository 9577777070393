import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MustMatch } from '@gtd/helpers';
import {ResetPasswordStateFacade} from "./reset-password-state/+state/reset-password-state.facade";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogMessageComponent} from "@gtd/components/dialog-message";
import {MatDialog, MatSnackBar} from "@angular/material";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {Subscription} from "rxjs";

@Component({
  selector: 'gtd-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  hide = true;
  resetPasswordForm: FormGroup;
  keyReset: string;
  subscriptions = new Subscription();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private resetPasswordStateFacade: ResetPasswordStateFacade
  ) {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    }, {
      validators: MustMatch('password', 'confirmPassword')
    });
    this.activatedRoute.queryParams.subscribe(param => {
      this.keyReset = param.key;
    })
  }
  ngOnInit() {
    this.subscriptions.add(
      this.resetPasswordStateFacade.allResetPasswordState$.subscribe(resetPassword => {
        this._snackBar.dismiss();
        if(resetPassword) {
          if(resetPassword.success) {
            let dialogRef = this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [
                  {btnColor: 'success', btnText: 'global.homepage', action: 'Homepage'},
                  {btnColor: 'success', btnText: 'user.login', action: 'Login'}
                ],
                content: 'account.update-password-success-message',
                heading: 'user.update-success',
                messageType: 'success'
              },
              panelClass: 'dialog-do-confirm',
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(closeModal => {
              if(closeModal.action === 'Login') {
                this.router.navigate(['/auth/login']);
              }
              if(closeModal.action === 'Homepage') {
                this.router.navigate(['/']);
              }
            })
          } else {
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [
                  {btnText: 'global.close'}
                ],
                content: 'account.update-error-message',
                heading: 'account.update-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          }
        }
      })
    )
  }

  changePassword() {
    if(this.resetPasswordForm.valid && this.keyReset) {
      this._snackBar.openFromComponent(SnackbarComponent,
        {data: {message: 'user.updating-information', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"}
      );
      this.resetPasswordStateFacade.resetPassword({
        key: this.keyReset,
        newPassword: this.resetPasswordForm.controls['password'].value
      })
    } else {
      this.dialog.open(DialogMessageComponent, {
        data: {
          btn: [
            {btnText: 'global.close'}
          ],
          content: 'user.system-error-message',
          heading: 'user.system-error',
          icon: 'exclamation-triangle',
          messageType: 'error'
        },
        panelClass: 'dialog-message',
        disableClose: true
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
