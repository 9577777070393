import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  navChange = new BehaviorSubject<string>(null);
  getNavChange = this.navChange.asObservable();

  constructor() {}

  setNavChange(navChange: string) {
    this.navChange.next(navChange);
  }
}
