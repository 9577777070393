import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Pipe,
  PipeTransform,
  SimpleChanges
} from '@angular/core';
import { Amenity } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-hotel-amenities',
  templateUrl: './hotel-amenities.component.html',
  styleUrls: ['./hotel-amenities.component.scss']
})
export class HotelAmenitiesComponent implements OnInit, OnChanges {
  @Input() isScroll: boolean;
  @Input() amenities: Array<Amenity>;
  amenitiesGroup: any;
  panelOpenState: boolean = true;
  constructor() {}

  ngOnInit() {
    if (this.amenities) {
      this.amenitiesGroup = this.amenities.reduce(function(arr, cur:any) {
        arr[cur.group] = arr[cur.group] || [];
        arr[cur.group].push(cur);
        return arr;
      }, Object.create(null));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}
}
@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    let keys = [];
    for (let key in value) {
      keys.push(key);
    }
    return keys;
  }
}
