import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ClientButtonComponent } from './client-button.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MatIconModule,
  MatButtonModule,
  MatRippleModule,
  MatDialogModule
} from '@angular/material';
import { CovidDialogModule } from '@gtd/components/covid-dialog';
import { BASE_PATH, CannyTokenResourceService } from '@gtd/b2c-client';
import {
  B2bClientModule,
  CannyTokenB2bResourceService
} from '@gtd/api-services/b2b-client';
import { environment } from '@env/environment';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    B2bClientModule,
    DragDropModule,
    MatButtonModule,
    MatIconModule,
    MatRippleModule,
    MatDialogModule,
    TranslateModule,
    CovidDialogModule
  ],
  declarations: [ClientButtonComponent],
  exports: [ClientButtonComponent],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2cClient },
    CannyTokenResourceService,
    CannyTokenB2bResourceService
  ]
})
export class ClientButtonModule {}
