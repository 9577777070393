/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { CAEmployeeDetailTO } from '../model/cAEmployeeDetailTO';
import { CAEmployeeTO } from '../model/cAEmployeeTO';
import { ChangeTripStatusTO } from '../model/changeTripStatusTO';
import { CreditRuleDTO } from '../model/creditRuleDTO';
import { DepartmentDTO } from '../model/departmentDTO';
import { DepartmentTO } from '../model/departmentTO';
import { ItineraryDTO } from '../model/itineraryDTO';
import { KeyAndPasswordVM } from '../model/keyAndPasswordVM';
import { OtaResultOfCAEmployeeTO } from '../model/otaResultOfCAEmployeeTO';
import { OtaResultOfDepartmentTO } from '../model/otaResultOfDepartmentTO';
import { OtaResultOfListOfCAEmployeeDetailTO } from '../model/otaResultOfListOfCAEmployeeDetailTO';
import { OtaResultOfListOfDepartmentDTO } from '../model/otaResultOfListOfDepartmentDTO';
import { OtaResultOfListOfDepartmentLiteTO } from '../model/otaResultOfListOfDepartmentLiteTO';
import { OtaResultOfListOfEmployeeDTO } from '../model/otaResultOfListOfEmployeeDTO';
import { OtaResultOfTripDTO } from '../model/otaResultOfTripDTO';
import { RedirectView } from '../model/redirectView';
import { TripDTO } from '../model/tripDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CaResourceService {

    protected basePath = 'https://uat-b2b.gotadi.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changeTripStatus
     *
     * @param changeTripStatusTO changeTripStatusTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeTripStatusUsingPOST(changeTripStatusTO: ChangeTripStatusTO, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfTripDTO>;
    public changeTripStatusUsingPOST(changeTripStatusTO: ChangeTripStatusTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfTripDTO>>;
    public changeTripStatusUsingPOST(changeTripStatusTO: ChangeTripStatusTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfTripDTO>>;
    public changeTripStatusUsingPOST(changeTripStatusTO: ChangeTripStatusTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (changeTripStatusTO === null || changeTripStatusTO === undefined) {
            throw new Error('Required parameter changeTripStatusTO was null or undefined when calling changeTripStatusUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfTripDTO>(`${this.basePath}/api/ca/trip_status`,
            changeTripStatusTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createCreditRule
     *
     * @param creditRuleDTO creditRuleDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCreditRuleUsingPOST(creditRuleDTO: CreditRuleDTO, observe?: 'body', reportProgress?: boolean): Observable<CreditRuleDTO>;
    public createCreditRuleUsingPOST(creditRuleDTO: CreditRuleDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreditRuleDTO>>;
    public createCreditRuleUsingPOST(creditRuleDTO: CreditRuleDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreditRuleDTO>>;
    public createCreditRuleUsingPOST(creditRuleDTO: CreditRuleDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (creditRuleDTO === null || creditRuleDTO === undefined) {
            throw new Error('Required parameter creditRuleDTO was null or undefined when calling createCreditRuleUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CreditRuleDTO>(`${this.basePath}/api/ca/credit-rules`,
            creditRuleDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createDepartment
     *
     * @param department department
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDepartmentUsingPOST(department: DepartmentDTO, observe?: 'body', reportProgress?: boolean): Observable<DepartmentDTO>;
    public createDepartmentUsingPOST(department: DepartmentDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DepartmentDTO>>;
    public createDepartmentUsingPOST(department: DepartmentDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DepartmentDTO>>;
    public createDepartmentUsingPOST(department: DepartmentDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (department === null || department === undefined) {
            throw new Error('Required parameter department was null or undefined when calling createDepartmentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DepartmentDTO>(`${this.basePath}/api/ca/departments`,
            department,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createDepartmentWithRule
     *
     * @param department department
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDepartmentWithRuleUsingPOST(department: DepartmentTO, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfDepartmentTO>;
    public createDepartmentWithRuleUsingPOST(department: DepartmentTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfDepartmentTO>>;
    public createDepartmentWithRuleUsingPOST(department: DepartmentTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfDepartmentTO>>;
    public createDepartmentWithRuleUsingPOST(department: DepartmentTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (department === null || department === undefined) {
            throw new Error('Required parameter department was null or undefined when calling createDepartmentWithRuleUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfDepartmentTO>(`${this.basePath}/api/ca/department_with_rules`,
            department,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createEmployee
     *
     * @param employeeTO employeeTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEmployeeUsingPOST(employeeTO: CAEmployeeTO, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfCAEmployeeTO>;
    public createEmployeeUsingPOST(employeeTO: CAEmployeeTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfCAEmployeeTO>>;
    public createEmployeeUsingPOST(employeeTO: CAEmployeeTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfCAEmployeeTO>>;
    public createEmployeeUsingPOST(employeeTO: CAEmployeeTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (employeeTO === null || employeeTO === undefined) {
            throw new Error('Required parameter employeeTO was null or undefined when calling createEmployeeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfCAEmployeeTO>(`${this.basePath}/api/ca/employees`,
            employeeTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createItinerary
     *
     * @param itineraryDTO itineraryDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createItineraryUsingPOST(itineraryDTO: ItineraryDTO, observe?: 'body', reportProgress?: boolean): Observable<ItineraryDTO>;
    public createItineraryUsingPOST(itineraryDTO: ItineraryDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItineraryDTO>>;
    public createItineraryUsingPOST(itineraryDTO: ItineraryDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItineraryDTO>>;
    public createItineraryUsingPOST(itineraryDTO: ItineraryDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (itineraryDTO === null || itineraryDTO === undefined) {
            throw new Error('Required parameter itineraryDTO was null or undefined when calling createItineraryUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ItineraryDTO>(`${this.basePath}/api/ca/itineraries`,
            itineraryDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createTrip
     *
     * @param tripDTO tripDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTripUsingPOST(tripDTO: TripDTO, observe?: 'body', reportProgress?: boolean): Observable<TripDTO>;
    public createTripUsingPOST(tripDTO: TripDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TripDTO>>;
    public createTripUsingPOST(tripDTO: TripDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TripDTO>>;
    public createTripUsingPOST(tripDTO: TripDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tripDTO === null || tripDTO === undefined) {
            throw new Error('Required parameter tripDTO was null or undefined when calling createTripUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TripDTO>(`${this.basePath}/api/ca/trips`,
            tripDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteDepartment
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDepartmentUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDepartmentUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDepartmentUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDepartmentUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDepartmentUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/ca/departments/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * finishPasswordReset
     *
     * @param keyAndPassword keyAndPassword
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finishPasswordResetUsingPOST1(keyAndPassword: KeyAndPasswordVM, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public finishPasswordResetUsingPOST1(keyAndPassword: KeyAndPasswordVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public finishPasswordResetUsingPOST1(keyAndPassword: KeyAndPasswordVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public finishPasswordResetUsingPOST1(keyAndPassword: KeyAndPasswordVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (keyAndPassword === null || keyAndPassword === undefined) {
            throw new Error('Required parameter keyAndPassword was null or undefined when calling finishPasswordResetUsingPOST1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/ca/account/reset_password/finish`,
            keyAndPassword,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllDepartments
     *
     * @param id id
     * @param agencyId agencyId
     * @param departmentName departmentName
     * @param disDeptCode disDeptCode
     * @param departmentCode departmentCode
     * @param status status
     * @param costCenter costCenter
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDepartmentsUsingGET(id?: string, agencyId?: string, departmentName?: string, disDeptCode?: string, departmentCode?: string, status?: string, costCenter?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfDepartmentDTO>;
    public getAllDepartmentsUsingGET(id?: string, agencyId?: string, departmentName?: string, disDeptCode?: string, departmentCode?: string, status?: string, costCenter?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfDepartmentDTO>>;
    public getAllDepartmentsUsingGET(id?: string, agencyId?: string, departmentName?: string, disDeptCode?: string, departmentCode?: string, status?: string, costCenter?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfDepartmentDTO>>;
    public getAllDepartmentsUsingGET(id?: string, agencyId?: string, departmentName?: string, disDeptCode?: string, departmentCode?: string, status?: string, costCenter?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (agencyId !== undefined && agencyId !== null) {
            queryParameters = queryParameters.set('agencyId', <any>agencyId);
        }
        if (departmentName !== undefined && departmentName !== null) {
            queryParameters = queryParameters.set('departmentName', <any>departmentName);
        }
        if (disDeptCode !== undefined && disDeptCode !== null) {
            queryParameters = queryParameters.set('disDeptCode', <any>disDeptCode);
        }
        if (departmentCode !== undefined && departmentCode !== null) {
            queryParameters = queryParameters.set('departmentCode', <any>departmentCode);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (costCenter !== undefined && costCenter !== null) {
            queryParameters = queryParameters.set('costCenter', <any>costCenter);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfListOfDepartmentDTO>(`${this.basePath}/api/ca/departments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllEmployees
     *
     * @param id id
     * @param b2bUserId b2bUserId
     * @param employeeCode employeeCode
     * @param departmentId departmentId
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllEmployeesUsingGET(id?: string, b2bUserId?: string, employeeCode?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfEmployeeDTO>;
    public getAllEmployeesUsingGET(id?: string, b2bUserId?: string, employeeCode?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfEmployeeDTO>>;
    public getAllEmployeesUsingGET(id?: string, b2bUserId?: string, employeeCode?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfEmployeeDTO>>;
    public getAllEmployeesUsingGET(id?: string, b2bUserId?: string, employeeCode?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (b2bUserId !== undefined && b2bUserId !== null) {
            queryParameters = queryParameters.set('b2bUserId', <any>b2bUserId);
        }
        if (employeeCode !== undefined && employeeCode !== null) {
            queryParameters = queryParameters.set('employeeCode', <any>employeeCode);
        }
        if (departmentId !== undefined && departmentId !== null) {
            queryParameters = queryParameters.set('departmentId', <any>departmentId);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfListOfEmployeeDTO>(`${this.basePath}/api/ca/employees`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllEmployeesWithDetail
     *
     * @param id id
     * @param b2bUserId b2bUserId
     * @param activated activated
     * @param employeeCode employeeCode
     * @param name name
     * @param login login
     * @param departmentId departmentId
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllEmployeesWithDetailUsingGET(id?: string, b2bUserId?: string, activated?: string, employeeCode?: string, name?: string, login?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfCAEmployeeDetailTO>;
    public getAllEmployeesWithDetailUsingGET(id?: string, b2bUserId?: string, activated?: string, employeeCode?: string, name?: string, login?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfCAEmployeeDetailTO>>;
    public getAllEmployeesWithDetailUsingGET(id?: string, b2bUserId?: string, activated?: string, employeeCode?: string, name?: string, login?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfCAEmployeeDetailTO>>;
    public getAllEmployeesWithDetailUsingGET(id?: string, b2bUserId?: string, activated?: string, employeeCode?: string, name?: string, login?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (b2bUserId !== undefined && b2bUserId !== null) {
            queryParameters = queryParameters.set('b2bUserId', <any>b2bUserId);
        }
        if (activated !== undefined && activated !== null) {
            queryParameters = queryParameters.set('activated', <any>activated);
        }
        if (employeeCode !== undefined && employeeCode !== null) {
            queryParameters = queryParameters.set('employeeCode', <any>employeeCode);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (login !== undefined && login !== null) {
            queryParameters = queryParameters.set('login', <any>login);
        }
        if (departmentId !== undefined && departmentId !== null) {
            queryParameters = queryParameters.set('departmentId', <any>departmentId);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfListOfCAEmployeeDetailTO>(`${this.basePath}/api/ca/employees_with_detail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllItineraries
     *
     * @param id id
     * @param tripId tripId
     * @param bookingType bookingType
     * @param status status
     * @param departure departure
     * @param destination destination
     * @param checkInDate checkInDate
     * @param checkoutDate checkoutDate
     * @param classType classType
     * @param provider provider
     * @param location location
     * @param refNumber refNumber
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllItinerariesUsingGET(id?: string, tripId?: string, bookingType?: string, status?: string, departure?: string, destination?: string, checkInDate?: string, checkoutDate?: string, classType?: string, provider?: string, location?: string, refNumber?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<ItineraryDTO>>;
    public getAllItinerariesUsingGET(id?: string, tripId?: string, bookingType?: string, status?: string, departure?: string, destination?: string, checkInDate?: string, checkoutDate?: string, classType?: string, provider?: string, location?: string, refNumber?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItineraryDTO>>>;
    public getAllItinerariesUsingGET(id?: string, tripId?: string, bookingType?: string, status?: string, departure?: string, destination?: string, checkInDate?: string, checkoutDate?: string, classType?: string, provider?: string, location?: string, refNumber?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItineraryDTO>>>;
    public getAllItinerariesUsingGET(id?: string, tripId?: string, bookingType?: string, status?: string, departure?: string, destination?: string, checkInDate?: string, checkoutDate?: string, classType?: string, provider?: string, location?: string, refNumber?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (tripId !== undefined && tripId !== null) {
            queryParameters = queryParameters.set('tripId', <any>tripId);
        }
        if (bookingType !== undefined && bookingType !== null) {
            queryParameters = queryParameters.set('bookingType', <any>bookingType);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (departure !== undefined && departure !== null) {
            queryParameters = queryParameters.set('departure', <any>departure);
        }
        if (destination !== undefined && destination !== null) {
            queryParameters = queryParameters.set('destination', <any>destination);
        }
        if (checkInDate !== undefined && checkInDate !== null) {
            queryParameters = queryParameters.set('checkInDate', <any>checkInDate);
        }
        if (checkoutDate !== undefined && checkoutDate !== null) {
            queryParameters = queryParameters.set('checkoutDate', <any>checkoutDate);
        }
        if (classType !== undefined && classType !== null) {
            queryParameters = queryParameters.set('classType', <any>classType);
        }
        if (provider !== undefined && provider !== null) {
            queryParameters = queryParameters.set('provider', <any>provider);
        }
        if (location !== undefined && location !== null) {
            queryParameters = queryParameters.set('location', <any>location);
        }
        if (refNumber !== undefined && refNumber !== null) {
            queryParameters = queryParameters.set('refNumber', <any>refNumber);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ItineraryDTO>>(`${this.basePath}/api/ca/itineraries`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllTrips
     *
     * @param id id
     * @param name name
     * @param approverEmail approverEmail
     * @param description description
     * @param employeeId employeeId
     * @param departmentId departmentId
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTripsUsingGET(id?: string, name?: string, approverEmail?: string, description?: string, employeeId?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<TripDTO>>;
    public getAllTripsUsingGET(id?: string, name?: string, approverEmail?: string, description?: string, employeeId?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TripDTO>>>;
    public getAllTripsUsingGET(id?: string, name?: string, approverEmail?: string, description?: string, employeeId?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TripDTO>>>;
    public getAllTripsUsingGET(id?: string, name?: string, approverEmail?: string, description?: string, employeeId?: string, departmentId?: string, createdDateFrom?: string, createdDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (approverEmail !== undefined && approverEmail !== null) {
            queryParameters = queryParameters.set('approverEmail', <any>approverEmail);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (employeeId !== undefined && employeeId !== null) {
            queryParameters = queryParameters.set('employeeId', <any>employeeId);
        }
        if (departmentId !== undefined && departmentId !== null) {
            queryParameters = queryParameters.set('departmentId', <any>departmentId);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<TripDTO>>(`${this.basePath}/api/ca/trips`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getApproverMailList
     *
     * @param departmentId departmentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getApproverMailListUsingGET(departmentId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getApproverMailListUsingGET(departmentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getApproverMailListUsingGET(departmentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getApproverMailListUsingGET(departmentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (departmentId === null || departmentId === undefined) {
            throw new Error('Required parameter departmentId was null or undefined when calling getApproverMailListUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/ca/approver_list/${encodeURIComponent(String(departmentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCurrentEmployee
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentEmployeeUsingGET(observe?: 'body', reportProgress?: boolean): Observable<CAEmployeeDetailTO>;
    public getCurrentEmployeeUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CAEmployeeDetailTO>>;
    public getCurrentEmployeeUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CAEmployeeDetailTO>>;
    public getCurrentEmployeeUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CAEmployeeDetailTO>(`${this.basePath}/api/ca/current_employee`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getDepartment
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDepartmentUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<DepartmentDTO>;
    public getDepartmentUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DepartmentDTO>>;
    public getDepartmentUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DepartmentDTO>>;
    public getDepartmentUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDepartmentUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<DepartmentDTO>(`${this.basePath}/api/ca/departments/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getEmployee
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmployeeUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<CAEmployeeDetailTO>;
    public getEmployeeUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CAEmployeeDetailTO>>;
    public getEmployeeUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CAEmployeeDetailTO>>;
    public getEmployeeUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEmployeeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CAEmployeeDetailTO>(`${this.basePath}/api/ca/employees/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * redirect
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public redirectUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<RedirectView>;
    public redirectUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RedirectView>>;
    public redirectUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RedirectView>>;
    public redirectUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<RedirectView>(`${this.basePath}/api/ca/redirect`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * requestPasswordReset
     *
     * @param mail mail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestPasswordResetUsingPOST1(mail: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public requestPasswordResetUsingPOST1(mail: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public requestPasswordResetUsingPOST1(mail: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public requestPasswordResetUsingPOST1(mail: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mail === null || mail === undefined) {
            throw new Error('Required parameter mail was null or undefined when calling requestPasswordResetUsingPOST1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/ca/account/reset_password/init`,
            mail,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchDepartmentLite
     *
     * @param id id
     * @param agencyId agencyId
     * @param departmentName departmentName
     * @param disDeptCode disDeptCode
     * @param departmentCode departmentCode
     * @param status status
     * @param costCenter costCenter
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDepartmentLiteUsingGET(id?: string, agencyId?: string, departmentName?: string, disDeptCode?: string, departmentCode?: string, status?: string, costCenter?: string, createdDateFrom?: string, createdDateTo?: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfDepartmentLiteTO>;
    public searchDepartmentLiteUsingGET(id?: string, agencyId?: string, departmentName?: string, disDeptCode?: string, departmentCode?: string, status?: string, costCenter?: string, createdDateFrom?: string, createdDateTo?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfDepartmentLiteTO>>;
    public searchDepartmentLiteUsingGET(id?: string, agencyId?: string, departmentName?: string, disDeptCode?: string, departmentCode?: string, status?: string, costCenter?: string, createdDateFrom?: string, createdDateTo?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfDepartmentLiteTO>>;
    public searchDepartmentLiteUsingGET(id?: string, agencyId?: string, departmentName?: string, disDeptCode?: string, departmentCode?: string, status?: string, costCenter?: string, createdDateFrom?: string, createdDateTo?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (agencyId !== undefined && agencyId !== null) {
            queryParameters = queryParameters.set('agencyId', <any>agencyId);
        }
        if (departmentName !== undefined && departmentName !== null) {
            queryParameters = queryParameters.set('departmentName', <any>departmentName);
        }
        if (disDeptCode !== undefined && disDeptCode !== null) {
            queryParameters = queryParameters.set('disDeptCode', <any>disDeptCode);
        }
        if (departmentCode !== undefined && departmentCode !== null) {
            queryParameters = queryParameters.set('departmentCode', <any>departmentCode);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (costCenter !== undefined && costCenter !== null) {
            queryParameters = queryParameters.set('costCenter', <any>costCenter);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfListOfDepartmentLiteTO>(`${this.basePath}/api/ca/search_department_lite`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateDepartment
     *
     * @param department department
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDepartmentUsingPUT(department: DepartmentDTO, observe?: 'body', reportProgress?: boolean): Observable<DepartmentDTO>;
    public updateDepartmentUsingPUT(department: DepartmentDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DepartmentDTO>>;
    public updateDepartmentUsingPUT(department: DepartmentDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DepartmentDTO>>;
    public updateDepartmentUsingPUT(department: DepartmentDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (department === null || department === undefined) {
            throw new Error('Required parameter department was null or undefined when calling updateDepartmentUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DepartmentDTO>(`${this.basePath}/api/ca/departments`,
            department,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateDepartmentWithRule
     *
     * @param department department
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDepartmentWithRuleUsingPUT(department: DepartmentTO, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfDepartmentTO>;
    public updateDepartmentWithRuleUsingPUT(department: DepartmentTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfDepartmentTO>>;
    public updateDepartmentWithRuleUsingPUT(department: DepartmentTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfDepartmentTO>>;
    public updateDepartmentWithRuleUsingPUT(department: DepartmentTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (department === null || department === undefined) {
            throw new Error('Required parameter department was null or undefined when calling updateDepartmentWithRuleUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<OtaResultOfDepartmentTO>(`${this.basePath}/api/ca/department_with_rules`,
            department,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateEmployee
     *
     * @param employeeTO employeeTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEmployeeUsingPUT(employeeTO: CAEmployeeTO, observe?: 'body', reportProgress?: boolean): Observable<CAEmployeeTO>;
    public updateEmployeeUsingPUT(employeeTO: CAEmployeeTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CAEmployeeTO>>;
    public updateEmployeeUsingPUT(employeeTO: CAEmployeeTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CAEmployeeTO>>;
    public updateEmployeeUsingPUT(employeeTO: CAEmployeeTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (employeeTO === null || employeeTO === undefined) {
            throw new Error('Required parameter employeeTO was null or undefined when calling updateEmployeeUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CAEmployeeTO>(`${this.basePath}/api/ca/employees`,
            employeeTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateItinerary
     *
     * @param itineraryDTO itineraryDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateItineraryUsingPUT(itineraryDTO: ItineraryDTO, observe?: 'body', reportProgress?: boolean): Observable<ItineraryDTO>;
    public updateItineraryUsingPUT(itineraryDTO: ItineraryDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItineraryDTO>>;
    public updateItineraryUsingPUT(itineraryDTO: ItineraryDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItineraryDTO>>;
    public updateItineraryUsingPUT(itineraryDTO: ItineraryDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (itineraryDTO === null || itineraryDTO === undefined) {
            throw new Error('Required parameter itineraryDTO was null or undefined when calling updateItineraryUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ItineraryDTO>(`${this.basePath}/api/ca/itineraries`,
            itineraryDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateTrip
     *
     * @param tripDTO tripDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTripUsingPUT(tripDTO: TripDTO, observe?: 'body', reportProgress?: boolean): Observable<TripDTO>;
    public updateTripUsingPUT(tripDTO: TripDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TripDTO>>;
    public updateTripUsingPUT(tripDTO: TripDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TripDTO>>;
    public updateTripUsingPUT(tripDTO: TripDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tripDTO === null || tripDTO === undefined) {
            throw new Error('Required parameter tripDTO was null or undefined when calling updateTripUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<TripDTO>(`${this.basePath}/api/ca/trips`,
            tripDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
