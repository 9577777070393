import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShortProfileFacade} from "../../../../api-services/customer-service/src/lib/stage/short-profile/short-profile.facade";
import {ProfileFacade} from "./+state/profile.facade";
import {Subscription} from "rxjs";
import {CountryFacade} from "@gtd/meta";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'gtd-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  groupPannel = 0;
  subscriptions = new Subscription();
  appName = localStorage.getItem('appName');
  groupPanel = 0;

  constructor(
    private shortProfileFacade: ShortProfileFacade,
    private profileFacade: ProfileFacade,
    private activatedRoute: ActivatedRoute,
    private countryFacade: CountryFacade
  ) {
    this.activatedRoute.queryParams.subscribe(value => {
      if(value && value.groupPannel) {
        this.groupPannel = parseInt(value.groupPannel)
      }
    })
  }

  setGroup(index: number) {
    this.groupPannel = index;
  }
  ngOnInit() {
    this.countryFacade.loadAll({
      page: 0,
      size: 1000,
      sort: ['sortname,asc']
    });
    this.subscriptions.add(
      this.shortProfileFacade.getShortProfile$.subscribe(shortProfile => {
        if (shortProfile && shortProfile.profileId) {
          this.profileFacade.getProfileUsingGet(shortProfile.profileId);
        }
      })
    )
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
