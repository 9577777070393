import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {Observable, of} from 'rxjs';
import { AuthenticateFacade } from './+state/authenticate.facade';
import { SocialAuthFacade } from '../social-login/social-auth/+state/social-auth.facade';
import { isPlatformBrowser } from '@angular/common';

export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
}
const helper = new JwtHelperService();
const credentialsKey = 'credentials';
const credentialsKeyGuest = 'credentials-guest';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class CredentialsService {
  private _credentials: Credentials | null = null;
  private _loginName: string | null = null;

  constructor(
    private authenticateFacade: AuthenticateFacade,
    private socialAuthFacade: SocialAuthFacade,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        const savedCredentials =
          sessionStorage.getItem(credentialsKey) ||
          localStorage.getItem(credentialsKey);
        if (savedCredentials) {
          this._credentials = JSON.parse(savedCredentials);
          const loginName =
            sessionStorage.getItem('loginName') ||
            localStorage.getItem('loginName');
          if (loginName) {
            this._loginName = JSON.parse(loginName);
          }
        }
      }
    } catch (e) {}
  }

  authoritiesConstantsAdmin(): boolean {
    if (this._credentials != null) {
      const data = helper.decodeToken(this._credentials.token);
      if (data.auth.indexOf('ROLE_ADMIN') === 0) {
        return true;
      }
    }
    return false;
  }
  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  getNameLogged(): string {
    if (this._credentials != null) {
      this._loginName = this._credentials.username;
      return this._credentials.username;
    }
    return null;
  }
  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  get getLoginName(): string | null {
    return this._loginName;
  }

  logout(): Observable<boolean> {
    this.setCredentials();
    this.authenticateFacade.logOutAction();
    this.socialAuthFacade.logOutAction();
    return of(true);
  }
  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;
    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
      sessionStorage.removeItem(credentialsKeyGuest);
      localStorage.removeItem(credentialsKeyGuest);
      localStorage.removeItem('loginName');
    }
  }
}
