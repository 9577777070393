import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {TcbPolicyComponent} from "../tcb-policy/tcb-policy.component";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'gtd-tcb-form-customer',
  templateUrl: './tcb-form-customer.component.html',
  styleUrls: ['./tcb-form-customer.component.scss']
})
export class TcbFormCustomerComponent implements OnInit {

  techComCustomers: FormGroup;
  optionValid: boolean;
  isSubmit: boolean;
  optionSelected: any;
  isApproved: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TcbFormCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.techComCustomers = new FormGroup({
      buyerFirstName: new FormControl('', [Validators.required]),
      buyerLastName: new FormControl('', [Validators.required]),
      buyerMobile: new FormControl('', [Validators.required]),
      buyerIdCardNo: new FormControl('', [Validators.required]),
      recipientFullName: new FormControl({value: '', disabled: true}, [Validators.required]),
      recipientFirstName: new FormControl({value: '', disabled: true}, [Validators.required]),
      recipientLastName: new FormControl({value: '', disabled: true}, [Validators.required]),
      recipientPassport: new FormControl({value: '', disabled: true}, [Validators.required])
    });
  }

  ngOnInit() {
    if(this.data && this.data.inputInfoCtrl && this.data.inputInfoCtrl.contactInfo) {
      this.techComCustomers.patchValue({
        buyerFirstName: this.data.inputInfoCtrl.contactInfo.firstName,
        buyerLastName: this.data.inputInfoCtrl.contactInfo.lastName,
      });
      setTimeout(() => {
        if(this.data && this.data.dataPax && this.data.dataPax.data && this.data.dataPax.data.length) {
          this.techComCustomers.patchValue(this.data.dataPax.data[0]);
          let dataSelected = this.data.inputInfoCtrl.paxInfo.pax.filter(
            (pax: any) => pax.passport === this.data.dataPax.data[0].recipientPassport
          );
          this.optionSelected = dataSelected.length? (dataSelected[0]): null;
          if(this.optionSelected) {
            this.techComCustomers.patchValue({
              recipientFullName: dataSelected[0].lastName + ' ' + dataSelected[0].firstName
            });
          }
          this.optionValid = !!dataSelected.length;
        }
      }, 100)
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  openModalPolicy() {
    this.dialog.open(TcbPolicyComponent, {
      width: '744px',
      autoFocus: false,
      position: {
        top: '100px'
      },
    });
  }

  checkedApprovePolicy() {
    this.isApproved = !this.isApproved;
  }

  selectOption(value: any) {
    if (value) {
      this.optionValid = true;
      this.techComCustomers.patchValue({
        recipientFullName: value.lastName + ' ' + value.firstName,
        recipientFirstName: value.firstName,
        recipientLastName: value.lastName,
        recipientPassport: value.passport,
      });
      this.optionSelected = value;
    }
  }

  submitForm() {
    this.isSubmit = true;
    if(this.techComCustomers.valid && this.optionValid && this.isApproved) {
      this.dialogRef.close({customer: this.techComCustomers.getRawValue()})
    } else {
      this.techComCustomers.markAllAsTouched();
    }
  }
}
