/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BookingContactDTO { 
    address1?: string;
    address2?: string;
    bookingId?: number;
    bookingNumber: string;
    ccEmail?: string;
    city?: string;
    contactLevel?: BookingContactDTO.ContactLevelEnum;
    contactType?: BookingContactDTO.ContactTypeEnum;
    country?: string;
    dob?: Date;
    email?: string;
    firstName: string;
    gender?: BookingContactDTO.GenderEnum;
    id?: number;
    phoneCode1?: string;
    phoneCode2?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    postCode?: string;
    postalCode?: string;
    surName: string;
}
export namespace BookingContactDTO {
    export type ContactLevelEnum = 'PRIMARY' | 'SECONDARY' | 'OTHER';
    export const ContactLevelEnum = {
        PRIMARY: 'PRIMARY' as ContactLevelEnum,
        SECONDARY: 'SECONDARY' as ContactLevelEnum,
        OTHER: 'OTHER' as ContactLevelEnum
    };
    export type ContactTypeEnum = 'CUSTOMER' | 'AGENCY';
    export const ContactTypeEnum = {
        CUSTOMER: 'CUSTOMER' as ContactTypeEnum,
        AGENCY: 'AGENCY' as ContactTypeEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'BOY' | 'GIRL' | 'INF';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        BOY: 'BOY' as GenderEnum,
        GIRL: 'GIRL' as GenderEnum,
        INF: 'INF' as GenderEnum
    };
}
