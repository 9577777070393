import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component';
import { ProfileComponent } from './profile/profile.component';
import { PassengersComponent } from './passengers/passengers.component';
import { BookingsComponent } from './bookings/bookings.component';
import { BookingDetailComponent } from './bookings/booking-detail/booking-detail.component';
import { FlightDetailComponent } from './bookings/flight-detail/flight-detail.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SettingsComponent } from './settings/settings.component';
import { EditPassengerComponent } from './passengers/edit-passenger/edit-passenger.component';
import { HotelDetailComponent } from './bookings/hotel-detail/hotel-detail.component';
import { InvoiceHistoryComponent } from './invoice-history/invoice-history.component';
import { TransactionFinancialComponent } from './transaction-financial/transaction-financial.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { ReferralPolicyComponent } from './referrals/referral-policy/referral-policy.component';
import { ReferralCommissionsComponent } from './referrals/referral-commissions/referral-commissions.component';
import { ChangePasswordProfileComponent } from './profile/change-password-profile/change-password-profile.component';
import { AdditionalRequestManagementComponent } from './additional-request-management/additional-request-management.component';
import { AdditionalRequestHistoryComponent } from './bookings/booking-detail/additional-request-history/additional-request-history.component';

const routes: Routes = [
  {
    path: '',
    component: UserComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordProfileComponent
  },
  {
    path: 'passengers',
    component: PassengersComponent
  },
  {
    path: 'passenger/:id',
    component: EditPassengerComponent
  },
  {
    path: 'bookings',
    component: BookingsComponent
  },
  {
    path: 'booking/detail',
    component: BookingDetailComponent
  },
  {
    path: 'booking/detail/additional-history',
    component: AdditionalRequestHistoryComponent
  },
  {
    path: 'booking/flight-detail',
    component: FlightDetailComponent
  },
  {
    path: 'booking/hotel-detail',
    component: HotelDetailComponent
  },
  {
    path: 'additional-request',
    component: AdditionalRequestManagementComponent
  },
  {
    path: 'notifications',
    component: NotificationsComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
  {
    path: 'invoice-history',
    component: InvoiceHistoryComponent
  },
  {
    path: 'transaction-financial',
    component: TransactionFinancialComponent
  },
  {
    path: 'referrals',
    component: ReferralsComponent
  },
  {
    path: 'referrals/policy',
    component: ReferralPolicyComponent
  },
  {
    path: 'referrals/commissions',
    component: ReferralCommissionsComponent
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserModuleRouting {}
