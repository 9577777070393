import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BranchFilter} from '../brand-filter/brand-item/string2-branch-mapper.pipe';
import {MatCheckboxChange} from '@angular/material';

@Component({
  selector: 'gtd-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CheckboxFilterComponent implements OnInit {
  @Input() data: BranchFilter[] = [];
  @Input() checkedItems: string[] = [];
  @Input() rightInfo: boolean;
  @Input() translateField: boolean;
  @Output() changed: EventEmitter<string[]> = new EventEmitter<string[]>();


  checkedResults = this.checkedItems.slice();
  constructor() {}

  ngOnInit() {
  }

  checkCheckboxChecked(value) {
    return this.checkedItems.find(item => item === value);
  }

  onCheckboxChanged(event: MatCheckboxChange) {
    const checkedValue = event.source.value;
    const index = this.checkedResults.findIndex(item => item === checkedValue);
    if (index >= 0) {
      this.checkedResults.splice(index, 1);
    }
    if (event.checked) {
      this.checkedResults.push(checkedValue);
    }
  }
  resetChecked() {
    this.checkedResults = [];
    this.checkedItems = [];
    this.data.forEach(element => {
      element.checked = false;
    });
    setTimeout(()=> {
      this.changed.emit(this.checkedResults);
    },100)
  }
  applyChecked() {
    this.changed.emit(this.checkedResults);
  }
}
