import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {SearchBookingTransactionsPartialState, SearchParams} from './search-booking-transactions.reducer';
import { searchBookingTransactionsQuery } from './search-booking-transactions.selectors';
import {DestroyTransactions, LoadSearchBookingTransactions} from './search-booking-transactions.actions';

@Injectable()
export class SearchBookingTransactionsFacade {
  loaded$ = this.store.pipe(select(searchBookingTransactionsQuery.getLoaded));
  allSearchBookingTransactions$ = this.store.pipe(
    select(searchBookingTransactionsQuery.getAllSearchBookingTransactions)
  );
  selectedSearchBookingTransactions$ = this.store.pipe(
    select(searchBookingTransactionsQuery.getSelectedSearchBookingTransactions)
  );

  constructor(private store: Store<SearchBookingTransactionsPartialState>) {}

  loadAll(searchParams: SearchParams) {
    this.store.dispatch(new LoadSearchBookingTransactions(searchParams));
  }
  transactionDestroy() {
    this.store.dispatch(new DestroyTransactions());
  }
}
