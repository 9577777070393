/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PromotionDiscountReq { 
    airlineCodes?: Array<string>;
    airlinesCode?: string;
    bookingCode?: string;
    bookingNumber?: string;
    cardId?: string;
    cardNumber?: string;
    ibeSessionId?: string;
    metaData?: { [key: string]: any; };
    price?: number;
    productType?: string;
    promotionCode?: string;
    promotionId?: string;
    promotionType?: PromotionDiscountReq.PromotionTypeEnum;
    requesterCode?: string;
    requesterType?: PromotionDiscountReq.RequesterTypeEnum;
}
export namespace PromotionDiscountReq {
    export type PromotionTypeEnum = 'CREDIT_CARD' | 'AIRLINES_CODE' | 'VOUCHER' | 'NORMAL';
    export const PromotionTypeEnum = {
        CREDITCARD: 'CREDIT_CARD' as PromotionTypeEnum,
        AIRLINESCODE: 'AIRLINES_CODE' as PromotionTypeEnum,
        VOUCHER: 'VOUCHER' as PromotionTypeEnum,
        NORMAL: 'NORMAL' as PromotionTypeEnum
    };
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
