import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {CountryPartialState} from './country.reducer';
import {CountryActionTypes, CountryLoadError, LoadCountry} from './country.actions';
import {map} from "rxjs/operators";
import {CountryCodeResourceService} from "@gtd/meta-client";

@Injectable()
export class CountryEffects {
  @Effect() loadCountry$ = this.dataPersistence.fetch(
    CountryActionTypes.LoadCountry,
    {
      run: (action: LoadCountry, state: CountryPartialState) => {
        return this.countryCodeResourceService.getAllCountryCodesUsingGET(
          action.payload.page,
          action.payload.size,
          action.payload.sort
        ).pipe(
          map(update => ({
            type: CountryActionTypes.CountryLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadCountry, error) => {
        console.error('Error', error);
        return new CountryLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private countryCodeResourceService: CountryCodeResourceService,
    private dataPersistence: DataPersistence<CountryPartialState>
  ) {}
}
