import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'gtd-cancel-additional-request-dialog',
  templateUrl: './cancel-additional-request-dialog.component.html',
  styleUrls: ['./cancel-additional-request-dialog.component.scss']
})
export class CancelAdditionalRequestDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<CancelAdditionalRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public requestType: string
  ) {}

  ngOnInit() {}

  cancelRequest() {
    this.dialogRef.close({ action: 'cancel-request' });
  }

  closeDialog() {
    this.dialogRef.close({ action: '' });
  }
}
