import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSearchUserBooking from './search-user-booking/search-user-booking.reducer';
import { SearchUserBookingEffects } from './search-user-booking/search-user-booking.effects';
import { SearchUserBookingFacade } from './search-user-booking/search-user-booking.facade';
import {
  BASE_PATH,
  BookingResourceService,
  ProductBookingResourceService,
  TicketBookingResourceService
} from "./api-client";
import {environment} from "@env/environment";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromSearchUserBooking.SEARCHUSERBOOKING_FEATURE_KEY,
      fromSearchUserBooking.reducerSearchBooking
    ),
    EffectsModule.forFeature([SearchUserBookingEffects])
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.bookingService },
    SearchUserBookingFacade,
    BookingResourceService,
    TicketBookingResourceService,
    ProductBookingResourceService
  ]
})
export class BookingServiceModule {}
