import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { fadeIn, slideInHorizontal, slideInVertical } from '@gtd/helpers';
import {
  AirItineraryInfo,
  AirTicketsResourceService,
  FlightSegment,
  GroupPricedItinerary,
  PricedItinerary
} from '@gtd/b2c-client';
import { Subject, Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material';
import {
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { FarerulesFacade } from '../../flight-search/farerules/+state/farerules.facade';
import { FlightResultItemService } from '../../flight-result-item/flight-result-item.service';
import { GroupItineraryFacade } from '../../flight-search/group-itinerary/+state/group-itinerary.facade';
import { SelectItemSrvService } from '../../select-item-srv.service';
import { ComboVJComboBookingFacade } from '../../../../../state/combo-booking/combo-booking.facade';
import { FareRules } from '@gtd/api-services/b2b-client';

@Component({
  selector: 'gtd-flight-booking-item',
  templateUrl: './flight-booking-item.component.html',
  styleUrls: ['./flight-booking-item.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class FlightBookingItemComponent
  implements OnInit, OnDestroy, AfterContentChecked {
  @Input() groupPricedItinerary: GroupPricedItinerary;
  @Input() bookingNumber: string;
  @Input() bookingDirectionIndex: number;
  @Input() pricedItinerary: PricedItinerary;
  @Input() lowestPrice: number;
  @Input() searchId: string;
  flightSegments?: Array<FlightSegment>;
  allFarerules: FareRules[];
  subscriptions = new Subscription();
  isDetailJourney: boolean;
  panelOpenState: boolean;

  submitLoadMore: boolean = false;

  constructor(
    private cdref: ChangeDetectorRef,
    private farerulesFacade: FarerulesFacade,
    private airTicketsResourceService: AirTicketsResourceService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      if (this.groupPricedItinerary) {
        this.flightSegments = this.groupPricedItinerary.pricedItineraries[0].originDestinationOptions[0].flightSegments;
      }
    });

    this.subscriptions.add(
      this.farerulesFacade.allFarerules$.subscribe(allFarerules => {
        if (allFarerules) {
          this.allFarerules = allFarerules.fareRules;
          this.submitLoadMore = false;
        }
      })
    );
  }
  getFareRules() {
    this.submitLoadMore = true;
    const currentLanguage = localStorage.getItem('language');
    // this.fareSourceCode = fareSourceCode;
    this.farerulesFacade.loadAll(
      {
        fareSourceCode: this.pricedItinerary.airItineraryPricingInfo
          .fareSourceCode,
        groupId: this.groupPricedItinerary.groupId,
        searchId: this.searchId
      },
      currentLanguage
    );
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
