import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  TourSuggestSearchDTO,
  TourSuggestSearchPageDTO
} from '@gtd/b2c-client';
import { TourSearchByKeywordFacade } from '@gtd/extra/tour/data-access';
import { SlugifyPipe } from 'libs/combovj/src/lib/search-result/pipe/slug.pipe';

@Component({
  selector: 'gtd-tour-suggestion',
  templateUrl: './tour-suggestion.component.html',
  styleUrls: ['./tour-suggestion.component.scss'],
  providers: [SlugifyPipe]
})
export class TourSuggestionComponent implements OnInit {
  @Input() tourSuggestionList: TourSuggestSearchDTO[];
  @Output() tourSelectedListener = new EventEmitter<boolean>(false);
  constructor(
    public router: Router,
    private _slugifyPipe: SlugifyPipe,
    private _tourSearchByKeywordFacade: TourSearchByKeywordFacade
  ) {}

  ngOnInit() {}

  navigateToDetail(item: TourSuggestSearchDTO) {
    this.tourSelectedListener.emit(true);
    this._tourSearchByKeywordFacade.resetAll();
    if (item.searchType === 'DESTINATION') {
      this.router.navigate([
        `/vui-choi-giai-tri/diem-den/${this._slugifyPipe.transform(
          item.name[0].value
        )}-${item.searchCode}`
      ]);
    } else {
      this.router.navigate([
        `/vui-choi-giai-tri/chi-tiet/${this._slugifyPipe.transform(
          item.name[0].value
        )}-${item.searchCode}`
      ]);
    }
  }
}
