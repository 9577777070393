import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HotelNewTabService {
  private _hotelNewTabSubject$ = new Subject();
  private _hotelNewTab$ = this._hotelNewTabSubject$.asObservable();
  constructor() {}

  setTab(newTab) {
    this._hotelNewTabSubject$.next(newTab);
  }

  getTab() {
    return this._hotelNewTab$;
  }
}
