import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  PLATFORM_ID
} from '@angular/core';
import { BannerTypeService } from '@gtd/api-services/newface-banner';
import { GaTrackingService } from '@gtd/ga-tracking';
import { VIEWER } from '@gtd/utilities';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { fadeIn } from '@gtd/helpers';

@Component({
  selector: 'gtd-best-price-products',
  templateUrl: './best-price-products.component.html',
  styleUrls: ['./best-price-products.component.scss'],
  animations: [fadeIn]
})
export class BestPriceProductsComponent implements OnInit {
  @Input() categories$: Observable<any>;
  config = {
    slidesPerView: this.deviceService.isDesktop() ? 4 : 2,
    spaceBetween: 15,
    loop: true,
    loopedSlides: 10,
    // lazy: true,
    navigation: {
      nextEl: '.gtd-slider .swiper-next',
      prevEl: '.gtd-slider .swiper-prev'
    }
  };
  categories: any;
  bannerList: any = null;
  tabIndexSubject$ = new BehaviorSubject<number>(null);
  tabIndex$ = this.tabIndexSubject$.asObservable();
  constructor(
    public bannerTypeService: BannerTypeService,
    private deviceService: DeviceDetectorService,
    private _gaTrackingService: GaTrackingService,
    @Optional() @Inject(VIEWER) private viewer,
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && this.viewer !== 'bot') {
      this.categories$.subscribe((categories: any) => {
        if (categories && categories.length) {
          this.categories = categories.find(
            category => category.slug === 'san-pham-gia-tot'
          );
          if (this.categories) {
            this.categories['childs'] = categories.filter(
              category => category.parent === this.categories.id
            );
          }
          if (this.categories.childs.length) {
            this.categories.childs.sort(
              (a, b) => a.acf.priority - b.acf.priority
            );
            this.tabIndexSubject$.next(this.categories.childs[0].id);
          }
        }
      });
      this.tabIndex$.subscribe(id => {
        this.bannerList = null;
        this.bannerTypeService
          .wpV2BannerTypeGet('' + id, environment.production ? '76' : '100')
          .subscribe(banners => {
            const idx = this.categories.childs.findIndex(
              child => child.id === id
            );
            this.bannerList = banners;

            this.bannerList.sort((a, b) => a.acf.priority - b.acf.priority);

            this.bannerList = this.bannerList.slice(
              0,
              this.categories.childs[idx].acf.items_per_page
            );
          });
      });
    }
  }

  track(eventName: string) {
    this._gaTrackingService.track(eventName);
  }
}
