import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {LookupPartialState, LookupSearch} from './lookup.reducer';
import { lookupQuery } from './lookup.selectors';
import { LoadLookup } from './lookup.actions';

@Injectable()
export class LookupFacade {
  loaded$ = this.store.pipe(select(lookupQuery.getLoaded));
  allLookup$ = this.store.pipe(select(lookupQuery.getAllLookup));
  selectedLookup$ = this.store.pipe(select(lookupQuery.getSelectedLookup));

  constructor(private store: Store<LookupPartialState>) {}

  loadAll(lookupSearch: LookupSearch) {
    this.store.dispatch(new LoadLookup(lookupSearch));
  }
}
