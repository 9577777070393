import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DATEPICKER_FEATURE_KEY, DatepickerState } from './datepicker.reducer';

// Lookup the 'Datepicker' feature state managed by NgRx
const getDatepickerState = createFeatureSelector<DatepickerState>(
  DATEPICKER_FEATURE_KEY
);

const getLoaded = createSelector(
  getDatepickerState,
  (state: DatepickerState) => state.loaded
);
const getError = createSelector(
  getDatepickerState,
  (state: DatepickerState) => state.error
);

const getAllDatepicker = createSelector(
  getDatepickerState,
  getLoaded,
  (state: DatepickerState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getDatepickerState,
  (state: DatepickerState) => state.selectedId
);
const getSelectedDatepicker = createSelector(
  getAllDatepicker,
  getSelectedId,
  (datepicker, id) => {
    return datepicker ? Object.assign({}, datepicker) : undefined;
  }
);

export const datepickerQuery = {
  getLoaded,
  getError,
  getAllDatepicker,
  getSelectedDatepicker
};
