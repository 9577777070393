/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TourContentReferenceDTO } from './tourContentReferenceDTO';
import { TourDestinationDTO } from './tourDestinationDTO';
import { TourLocationDTO } from './tourLocationDTO';
import { TourTimingDTO } from './tourTimingDTO';

export interface TourActivityAvailableIntroDTO {
  amenities?: { [key: string]: TourContentReferenceDTO };
  basePrice?: number;
  categories?: { [key: string]: TourContentReferenceDTO };
  currency?: TourActivityAvailableIntroDTO.CurrencyEnum;
  destinations?: Array<TourDestinationDTO>;
  id?: string;
  imageUrl?: string;
  meetUpAddress?: string;
  meetUpLocation?: TourLocationDTO;
  name?: string;
  originPrice?: number;
  overview?: string;
  policy?: string;
  priceBeforePromo?: number;
  taxAndFees?: number;
  themes?: { [key: string]: TourContentReferenceDTO };
  timing?: TourTimingDTO;
  totalPrice?: number;
  unitPrice?: string;
  inventoryAvailable: boolean;
}
export namespace TourActivityAvailableIntroDTO {
  export type CurrencyEnum = 'VND' | 'USD';
  export const CurrencyEnum = {
    VND: 'VND' as CurrencyEnum,
    USD: 'USD' as CurrencyEnum
  };
}
