export * from './accountResource.service';
import { AccountResourceService } from './accountResource.service';
export * from './airTicketsResource.service';
import { AirTicketsResourceService } from './airTicketsResource.service';
export * from './additionalRequestResource.service';
import { AdditionalRequestResourceService } from './additionalRequestResource.service';
export * from './affiliateResource.service';
import { AffiliateResourceService } from './affiliateResource.service';
export * from './basePartnerResource.service';
import { BasePartnerResourceService } from './basePartnerResource.service';
export * from './cannyTokenResource.service';
import { CannyTokenResourceService } from './cannyTokenResource.service';
export * from './customerResource.service';
import { CustomerResourceService } from './customerResource.service';
export * from './fSoftResource.service';
import { FSoftResourceService } from './fSoftResource.service';
export * from './gatewayResource.service';
import { GatewayResourceService } from './gatewayResource.service';
export * from './gotadiOperation.service';
import { GotadiOperationService } from './gotadiOperation.service';
export * from './hotelInventoryFormResources.service';
import { HotelInventoryFormResourcesService } from './hotelInventoryFormResources.service';
export * from './hotelResource.service';
import { HotelResourceService } from './hotelResource.service';
export * from './hotelResourceV3.service';
import { HotelResourceV3Service } from './hotelResourceV3.service';
export * from './ibeInternalConfigResource.service';
import { IbeInternalConfigResourceService } from './ibeInternalConfigResource.service';
export * from './lienVietResource.service';
import { LienVietResourceService } from './lienVietResource.service';
export * from './lookupResource.service';
import { LookupResourceService } from './lookupResource.service';
export * from './migrationDataResource.service';
import { MigrationDataResourceService } from './migrationDataResource.service';
export * from './mobileTokenResource.service';
import { MobileTokenResourceService } from './mobileTokenResource.service';
export * from './paymentResources.service';
import { PaymentResourcesService } from './paymentResources.service';
export * from './paymentScheduledResource.service';
import { PaymentScheduledResourceService } from './paymentScheduledResource.service';
export * from './productResource.service';
import { ProductResourceService } from './productResource.service';
export * from './profileInfoResource.service';
import { ProfileInfoResourceService } from './profileInfoResource.service';
export * from './seABankResource.service';
import { SeABankResourceService } from './seABankResource.service';
export * from './socialCallbackController.service';
import { SocialCallbackControllerService } from './socialCallbackController.service';
export * from './socialController.service';
import { SocialControllerService } from './socialController.service';
export * from './tikiResource.service';
import { TikiResourceService } from './tikiResource.service';
export * from './userJwtController.service';
import { UserJwtControllerService } from './userJwtController.service';
export * from './userResource.service';
import { UserResourceService } from './userResource.service';
export * from './utilResource.service';
import { UtilResourceService } from './utilResource.service';
export * from './versionResource.service';
import { VersionResourceService } from './versionResource.service';
export * from './viettelResource.service';
import { ViettelResourceService } from './viettelResource.service';
import { ComboResourceService } from './comboResource.service';
export * from './comboResource.service';
import { TongCucDuLichResourceService } from './tongCucDuLichResource.service';
export * from './tongCucDuLichResource.service';
import { TourResourceService } from './tourResource.service';
export * from './tourResource.service';
import { ApprovalResourceService } from './approvalResource.service';
export * from './approvalResource.service';
import { ReportResourceService } from './reportResource.service';
export * from './reportResource.service';
import { InventoryResourcesService } from './inventoryResources.service';
export * from './inventoryResources.service';
export const APIS = [
  AccountResourceService,
  AirTicketsResourceService,
  AdditionalRequestResourceService,
  AffiliateResourceService,
  BasePartnerResourceService,
  CannyTokenResourceService,
  CustomerResourceService,
  FSoftResourceService,
  GatewayResourceService,
  GotadiOperationService,
  HotelInventoryFormResourcesService,
  HotelResourceService,
  HotelResourceV3Service,
  IbeInternalConfigResourceService,
  LienVietResourceService,
  LookupResourceService,
  MigrationDataResourceService,
  MobileTokenResourceService,
  PaymentResourcesService,
  PaymentScheduledResourceService,
  ProductResourceService,
  ProfileInfoResourceService,
  SeABankResourceService,
  SocialCallbackControllerService,
  SocialControllerService,
  TikiResourceService,
  UserJwtControllerService,
  UserResourceService,
  UtilResourceService,
  VersionResourceService,
  ViettelResourceService,
  ComboResourceService,
  TongCucDuLichResourceService,
  TourResourceService,
  ApprovalResourceService,
  ReportResourceService,
  InventoryResourcesService
];
