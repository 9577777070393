import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AVAILABLEFLIGHTS_FEATURE_KEY,
  AvailableFlightsState
} from './available-flights.reducer';

// Lookup the 'AvailableFlights' feature state managed by NgRx
const getAvailableFlightsState = createFeatureSelector<AvailableFlightsState>(
  AVAILABLEFLIGHTS_FEATURE_KEY
);

const getLoaded = createSelector(
  getAvailableFlightsState,
  (state: AvailableFlightsState) => state.loaded
);
const getError = createSelector(
  getAvailableFlightsState,
  (state: AvailableFlightsState) => state.error
);

const getAllAvailableFlights = createSelector(
  getAvailableFlightsState,
  getLoaded,
  (state: AvailableFlightsState, isLoaded) => {
    return isLoaded ? state.data : {};
  }
);
const getSelectedId = createSelector(
  getAvailableFlightsState,
  (state: AvailableFlightsState) => state.selectedId
);
const getSelectedAvailableFlights = createSelector(
  getAllAvailableFlights,
  getSelectedId,
  (availableFlights, id) => {
    let result;
    if (availableFlights.groupPricedItineraries) {
      result = availableFlights.groupPricedItineraries.find(
        it => it['groupId'] === id
      );
    }

    return result ? Object.assign({}, result) : undefined;
  }
);

export const availableFlightsQuery = {
  getLoaded,
  getError,
  getAllAvailableFlights,
  getSelectedAvailableFlights
};
