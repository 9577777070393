/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Booking { 
    additionalFee?: number;
    agencyCode?: string;
    agencyMarkupValue?: number;
    agentCode?: string;
    agentId?: number;
    agentName?: string;
    approvalInvoiceId?: number;
    baseFare?: number;
    bookBy?: string;
    bookByCode?: string;
    bookingCode?: string;
    bookingDate?: Date;
    bookingIssuedType?: Booking.BookingIssuedTypeEnum;
    bookingNote?: string;
    bookingNumber?: string;
    bookingType?: Booking.BookingTypeEnum;
    branchCode?: string;
    cancellationBy?: string;
    cancellationDate?: Date;
    cancellationFee?: number;
    cancellationNotes?: string;
    cancellationStatus?: Booking.CancellationStatusEnum;
    channelType?: Booking.ChannelTypeEnum;
    commissionValue?: number;
    cooperationClass?: Booking.CooperationClassEnum;
    createdBy?: string;
    createdDate?: Date;
    customerCode?: string;
    customerEmail?: string;
    customerFirstName?: string;
    customerId?: number;
    customerLastName?: string;
    customerPhoneNumber1?: string;
    customerPhoneNumber2?: string;
    departureDate?: Date;
    discountAmount?: number;
    discountDate?: Date;
    discountRedeemCode?: string;
    discountRedeemId?: string;
    discountTrackingCode?: string;
    discountVoucherCode?: string;
    discountVoucherName?: string;
    equivFare?: number;
    fromCity?: string;
    fromLocationCode?: string;
    fromLocationName?: string;
    id?: number;
    internalBookingNote?: string;
    invoiceStatus?: string;
    isDeleted?: boolean;
    issuedBy?: string;
    issuedByCode?: string;
    issuedDate?: Date;
    issuedStatus?: Booking.IssuedStatusEnum;
    markupValue?: number;
    orgCode: string;
    partnerOrderId?: string;
    partnerRequestId?: string;
    paymentBy?: string;
    paymentByCode?: string;
    paymentDate?: Date;
    paymentFee?: number;
    paymentRefNumber?: string;
    paymentStatus?: Booking.PaymentStatusEnum;
    paymentTotalAmount?: number;
    paymentType?: Booking.PaymentTypeEnum;
    promotionID?: Array<string>;
    reconciliationType?: Booking.ReconciliationTypeEnum;
    refundAmount?: number;
    refundBy?: string;
    refundByCode?: string;
    refundDate?: Date;
    refundNextVoidDate?: Date;
    returnDate?: Date;
    roundType?: string;
    saleChannel?: Booking.SaleChannelEnum;
    serviceTax?: number;
    sessionSearchId?: string;
    status?: Booking.StatusEnum;
    supplierType?: Booking.SupplierTypeEnum;
    tags?: string;
    taxAddress1?: string;
    taxAddress2?: string;
    taxCompanyName?: string;
    taxNumber?: string;
    taxPersonalInfoContact?: string;
    taxReceiptRequest?: boolean;
    timeToLive?: Date;
    timeToLiveExpired?: Date;
    toCity?: string;
    toLocationCode?: string;
    toLocationName?: string;
    totalFare?: number;
    totalSsrValue?: number;
    totalTax?: number;
    updatedBy?: string;
    updatedDate?: Date;
    vat?: number;
}
export namespace Booking {
    export type BookingIssuedTypeEnum = 'INSTANT_BOOKING' | 'CONFIRM_OFFLINE';
    export const BookingIssuedTypeEnum = {
        INSTANTBOOKING: 'INSTANT_BOOKING' as BookingIssuedTypeEnum,
        CONFIRMOFFLINE: 'CONFIRM_OFFLINE' as BookingIssuedTypeEnum
    };
    export type BookingTypeEnum = 'DOME' | 'INTE';
    export const BookingTypeEnum = {
        DOME: 'DOME' as BookingTypeEnum,
        INTE: 'INTE' as BookingTypeEnum
    };
    export type CancellationStatusEnum = 'CANCEL_UNKNOWN' | 'CANCEL_PENALTY_MISMATCH' | 'CANCEL_WAITING_CONFIRM' | 'CANCEL_CONFIRMED' | 'CANCEL_EXPIRED';
    export const CancellationStatusEnum = {
        UNKNOWN: 'CANCEL_UNKNOWN' as CancellationStatusEnum,
        PENALTYMISMATCH: 'CANCEL_PENALTY_MISMATCH' as CancellationStatusEnum,
        WAITINGCONFIRM: 'CANCEL_WAITING_CONFIRM' as CancellationStatusEnum,
        CONFIRMED: 'CANCEL_CONFIRMED' as CancellationStatusEnum,
        EXPIRED: 'CANCEL_EXPIRED' as CancellationStatusEnum
    };
    export type ChannelTypeEnum = 'ONLINE' | 'OFFLINE';
    export const ChannelTypeEnum = {
        ONLINE: 'ONLINE' as ChannelTypeEnum,
        OFFLINE: 'OFFLINE' as ChannelTypeEnum
    };
    export type CooperationClassEnum = 'ca' | 'sa' | 'cn' | 'ps' | 'nv' | 'bd' | 'b2b2b';
    export const CooperationClassEnum = {
        Ca: 'ca' as CooperationClassEnum,
        Sa: 'sa' as CooperationClassEnum,
        Cn: 'cn' as CooperationClassEnum,
        Ps: 'ps' as CooperationClassEnum,
        Nv: 'nv' as CooperationClassEnum,
        Bd: 'bd' as CooperationClassEnum,
        B2b2b: 'b2b2b' as CooperationClassEnum
    };
    export type IssuedStatusEnum = 'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED';
    export const IssuedStatusEnum = {
        PENDING: 'PENDING' as IssuedStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as IssuedStatusEnum,
        SUCCEEDED: 'SUCCEEDED' as IssuedStatusEnum,
        FAILED: 'FAILED' as IssuedStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as IssuedStatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as IssuedStatusEnum
    };
    export type PaymentStatusEnum = 'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'PENDING';
    export const PaymentStatusEnum = {
        SUCCEEDED: 'SUCCEEDED' as PaymentStatusEnum,
        FAILED: 'FAILED' as PaymentStatusEnum,
        REFUNDED: 'REFUNDED' as PaymentStatusEnum,
        PENDING: 'PENDING' as PaymentStatusEnum
    };
    export type PaymentTypeEnum = 'BALANCE' | 'VNPAY_CREDIT' | 'ATM_DEBIT' | 'KREDIVO' | 'AIRPAY' | 'VNPAYQR' | 'VIETTELPAY' | 'MOMO' | 'ZALO' | 'PAYOO' | 'CASH' | 'TRANSFER' | 'PARTNER' | 'VPBANK' | 'CREDIT' | 'OTHER';
    export const PaymentTypeEnum = {
        BALANCE: 'BALANCE' as PaymentTypeEnum,
        VNPAYCREDIT: 'VNPAY_CREDIT' as PaymentTypeEnum,
        ATMDEBIT: 'ATM_DEBIT' as PaymentTypeEnum,
        KREDIVO: 'KREDIVO' as PaymentTypeEnum,
        AIRPAY: 'AIRPAY' as PaymentTypeEnum,
        VNPAYQR: 'VNPAYQR' as PaymentTypeEnum,
        VIETTELPAY: 'VIETTELPAY' as PaymentTypeEnum,
        MOMO: 'MOMO' as PaymentTypeEnum,
        ZALO: 'ZALO' as PaymentTypeEnum,
        PAYOO: 'PAYOO' as PaymentTypeEnum,
        CASH: 'CASH' as PaymentTypeEnum,
        TRANSFER: 'TRANSFER' as PaymentTypeEnum,
        PARTNER: 'PARTNER' as PaymentTypeEnum,
        VPBANK: 'VPBANK' as PaymentTypeEnum,
        CREDIT: 'CREDIT' as PaymentTypeEnum,
        OTHER: 'OTHER' as PaymentTypeEnum
    };
    export type ReconciliationTypeEnum = 'NEW' | 'ALREADY_RECONCILIATION';
    export const ReconciliationTypeEnum = {
        NEW: 'NEW' as ReconciliationTypeEnum,
        ALREADYRECONCILIATION: 'ALREADY_RECONCILIATION' as ReconciliationTypeEnum
    };
    export type SaleChannelEnum = 'B2B' | 'B2C' | 'B2B2C' | 'B2C_WEB' | 'B2C_WEB_APP' | 'B2C_MOBILE';
    export const SaleChannelEnum = {
        B2B: 'B2B' as SaleChannelEnum,
        B2C: 'B2C' as SaleChannelEnum,
        B2B2C: 'B2B2C' as SaleChannelEnum,
        B2CWEB: 'B2C_WEB' as SaleChannelEnum,
        B2CWEBAPP: 'B2C_WEB_APP' as SaleChannelEnum,
        B2CMOBILE: 'B2C_MOBILE' as SaleChannelEnum
    };
    export type StatusEnum = 'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'TICKET_ON_PROCESS' | 'INACTIVE';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as StatusEnum,
        BOOKED: 'BOOKED' as StatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        TENTATIVE: 'TENTATIVE' as StatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type SupplierTypeEnum = 'AIR' | 'HOTEL' | 'COMBO' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const SupplierTypeEnum = {
        AIR: 'AIR' as SupplierTypeEnum,
        HOTEL: 'HOTEL' as SupplierTypeEnum,
        COMBO: 'COMBO' as SupplierTypeEnum,
        TOURS: 'TOURS' as SupplierTypeEnum,
        TRAIN: 'TRAIN' as SupplierTypeEnum,
        SHIP: 'SHIP' as SupplierTypeEnum,
        OTHER: 'OTHER' as SupplierTypeEnum
    };
}
