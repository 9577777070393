/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IdPostprocessBody { 
    action: Array<IdPostprocessBody.ActionEnum>;
}
export namespace IdPostprocessBody {
    export type ActionEnum = 'create-image-subsizes';
    export const ActionEnum = {
        CreateImageSubsizes: 'create-image-subsizes' as ActionEnum
    };
}