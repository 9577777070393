import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { TourAddBookingTravellerPartialState } from './tour-add-booking-traveller.reducer';
import {
  LoadTourAddBookingTraveller,
  TourAddBookingTravellerLoaded,
  TourAddBookingTravellerLoadError,
  TourAddBookingTravellerActionTypes
} from './tour-add-booking-traveller.actions';
import { TourResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class TourAddBookingTravellerEffects {
  @Effect() loadTourAddBookingTraveller$ = this.dataPersistence.fetch(
    TourAddBookingTravellerActionTypes.LoadTourAddBookingTraveller,
    {
      run: (
        action: LoadTourAddBookingTraveller,
        state: TourAddBookingTravellerPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.tourResourceService
          .addBookingTravellerUsingPOST4(action.payload)
          .pipe(
            map(payload => ({
              type:
                TourAddBookingTravellerActionTypes.TourAddBookingTravellerLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadTourAddBookingTraveller, error) => {
        console.error('Error', error);
        return new TourAddBookingTravellerLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<
      TourAddBookingTravellerPartialState
    >,
    private tourResourceService: TourResourceService
  ) {}
}
