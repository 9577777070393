import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'gtd-canny-feedback-dialog',
  templateUrl: './canny-feedback-dialog.component.html',
  styleUrls: ['./canny-feedback-dialog.component.scss']
})
export class CannyFeedbackDialogComponent implements AfterViewInit, OnInit {
  currentUrl: string = '';
  constructor(
    private dialogRef: MatDialogRef<CannyFeedbackDialogComponent>,
    private _renderer2: Renderer2,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
  }

  ngAfterViewInit() {
    let script1 = this._renderer2.createElement('script');
    script1.text = `
      !function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");
    `;
    this._renderer2.appendChild(this._document.body, script1);
    let script2 = this._renderer2.createElement('script');
    script2.text = `
      Canny('render', {
        boardToken: '97744133-78c7-bc9b-2501-94f4620f1990',
        basePath: '${this.currentUrl}',
        ssoToken: '${this.data.token}',
      });
    `;
    this._renderer2.appendChild(this._document.body, script2);
  }

  closeDialog() {
    this.dialogRef.close();
    this.router.navigateByUrl(this.currentUrl);
  }
}
