export * from './customerAvatarResource.service';
import { CustomerAvatarResourceService } from './customerAvatarResource.service';
export * from './customerProfileResource.service';
import { CustomerProfileResourceService } from './customerProfileResource.service';
export * from './customerTravellerMemberCardResource.service';
import { CustomerTravellerMemberCardResourceService } from './customerTravellerMemberCardResource.service';
export * from './customerTravellerResource.service';
import { CustomerTravellerResourceService } from './customerTravellerResource.service';
export * from './eventResource.service';
import { EventResourceService } from './eventResource.service';
export * from './messageItemResource.service';
import { MessageItemResourceService } from './messageItemResource.service';
export * from './messageResource.service';
import { MessageResourceService } from './messageResource.service';
export * from './migrateDataResource.service';
import { MigrateDataResourceService } from './migrateDataResource.service';
export * from './mobileTokenResource.service';
import { MobileTokenResourceService } from './mobileTokenResource.service';
export * from './profileInfoResource.service';
import { ProfileInfoResourceService } from './profileInfoResource.service';
export const APIS = [CustomerAvatarResourceService, CustomerProfileResourceService, CustomerTravellerMemberCardResourceService, CustomerTravellerResourceService, EventResourceService, MessageItemResourceService, MessageResourceService, MigrateDataResourceService, MobileTokenResourceService, ProfileInfoResourceService];
