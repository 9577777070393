import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTPagination_FEATURE_KEY,
  FlightPaginationState
} from './flight-pagination.reducer';

// Lookup the 'FlightPagination' feature state managed by NgRx
const getFlightPaginationState = createFeatureSelector<FlightPaginationState>(
  FLIGHTPagination_FEATURE_KEY
);

const getPagination = createSelector(
  getFlightPaginationState,
  (state: FlightPaginationState) => state
);

const getPaginationPage = createSelector(
  getFlightPaginationState,
  (state: FlightPaginationState) => state
);

const getPaginationSize = createSelector(
  getFlightPaginationState,
  (state: FlightPaginationState) => state
);

const getPaginationSort = createSelector(
  getFlightPaginationState,
  (state: FlightPaginationState) => state
);

export const flightPaginationQuery = {
  getPagination,
  getPaginationPage,
  getPaginationSize,
  getPaginationSort
};
