import {Action} from '@ngrx/store';
import {CreateDraftBookingHotelPayload, OtaResultOfBooking} from "@gtd/b2c-client";

export enum HotelCreateDraftBookingActionTypes {
  LoadHotelCreateDraftBooking = '[HotelCreateDraftBooking] Load HotelCreateDraftBooking',
  HotelCreateDraftBookingLoaded = '[HotelCreateDraftBooking] HotelCreateDraftBooking Loaded',
  HotelCreateDraftBookingLoadError = '[HotelCreateDraftBooking] HotelCreateDraftBooking Load Error'
}

export class LoadHotelCreateDraftBooking implements Action {
  readonly type =
    HotelCreateDraftBookingActionTypes.LoadHotelCreateDraftBooking;
  constructor(public payload: CreateDraftBookingHotelPayload) {}
}

export class HotelCreateDraftBookingLoadError implements Action {
  readonly type =
    HotelCreateDraftBookingActionTypes.HotelCreateDraftBookingLoadError;
  constructor(public payload: any) {}
}

export class HotelCreateDraftBookingLoaded implements Action {
  readonly type =
    HotelCreateDraftBookingActionTypes.HotelCreateDraftBookingLoaded;
  constructor(public payload: OtaResultOfBooking) {}
}

export type HotelCreateDraftBookingAction =
  | LoadHotelCreateDraftBooking
  | HotelCreateDraftBookingLoaded
  | HotelCreateDraftBookingLoadError;

export const fromHotelCreateDraftBookingActions = {
  LoadHotelCreateDraftBooking,
  HotelCreateDraftBookingLoaded,
  HotelCreateDraftBookingLoadError
};
