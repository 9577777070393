import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TOURFILTERAVAILABLE_FEATURE_KEY,
  TourFilterAvailableState
} from './tour-filter-available.reducer';

// Lookup the 'TourFilterAvailable' feature state managed by NgRx
const getTourFilterAvailableState = createFeatureSelector<
  TourFilterAvailableState
>(TOURFILTERAVAILABLE_FEATURE_KEY);

const getAllCurrentFilter = createSelector(
  getTourFilterAvailableState,
  (state: TourFilterAvailableState) => state
);

export const tourFilterAvailableQuery = { getAllCurrentFilter };
