import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '@gtd/components/snackbar';

@Component({
  selector: 'gtd-hotel-search-room',
  templateUrl: './hotel-search-room.component.html',
  styleUrls: ['./hotel-search-room.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HotelSearchRoomComponent),
      multi: true
    }
  ]
})
export class HotelSearchRoomComponent implements OnInit, ControlValueAccessor {
  @Input() roomsArrs: Array<HotelRoomsArray>;
  hotelSearchRoom: FormGroup;
  startDate = new Date();
  defaultFromDate: Date;
  defaultToDate: Date;
  roomsSearch: any;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {
    this.activatedRoute.queryParams.subscribe(param => {
      this._handleQueryFromParams(
        JSON.parse(decodeURIComponent(atob(param.params)))
      );
    });
    this.hotelSearchRoom = new FormGroup({
      rooms: new FormGroup({
        room: new FormArray([])
      }),
      datePicker: new FormGroup({
        fromDate: new FormControl(this.defaultFromDate, Validators.required),
        toDate: new FormControl(this.defaultToDate, Validators.required)
      })
    });
  }
  _handleQueryFromParams(params) {
    this.defaultFromDate = new Date(params.fromDate);
    this.defaultToDate = new Date(params.toDate);
  }
  ngOnInit() {
    if (this.roomsArrs) {
      this.roomsArrs.forEach(roomArr => {
        this.rooms.push(
          this.fb.group({
            adultQuantity: roomArr.adultQuantity,
            childQuantity: roomArr.childQuantity,
            childrenAges: [roomArr.childrenAges]
          })
        );
      });
    }
  }

  getFormRooms(event) {
    if (!event.valid) {
      this.hotelSearchRoom.controls['rooms'].setErrors({ room: false });
    } else {
      this.roomsSearch = event.rooms;
      this.hotelSearchRoom.controls['rooms'].setErrors(null);
    }
  }

  submit() {
    if (this.hotelSearchRoom.valid) {
      this.onChange({
        rooms: this.roomsSearch,
        datePicker: this.hotelSearchRoom.controls['datePicker'].value
      });
      this.onTouched();
    } else {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {
          message: 'search.hotel.room-invalid',
          icon: 'exclamation-triangle'
        },
        panelClass: 'snackbar-warning',
        verticalPosition: 'top',
        duration: 2000
      });
    }
  }
  get rooms(): FormArray {
    return this.hotelSearchRoom.controls['rooms'].get('room') as FormArray;
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  writeValue(obj: any): void {}
}
export interface HotelRoomsArray {
  adultQuantity: number;
  childQuantity: number;
  childrenAges: Array<number>;
}
