import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailPassengersComponent } from './detail-passengers.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material';
import { NewIncreamentInputModule } from '@gtd/components/new-increament-input';
import { TranslateModule } from '@ngx-translate/core';
import {
  GButtonModule,
  GInputModule,
  GTypographyModule
} from '@gotadi/design-system';
import { FlexModule } from '@angular/flex-layout';
import { ClickOutsideModule } from '@gtd/directives/click-outside';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    NewIncreamentInputModule,
    TranslateModule,
    GTypographyModule,
    GButtonModule,
    FlexModule,
    GInputModule,
    ClickOutsideModule
  ],
  declarations: [DetailPassengersComponent],
  exports: [DetailPassengersComponent]
})
export class DetailPassengersModule {}
