import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { TourAddBookingTravellerPartialState } from './tour-add-booking-traveller.reducer';
import { tourAddBookingTravellerQuery } from './tour-add-booking-traveller.selectors';
import {
  LoadTourAddBookingTraveller,
  ResetTourAddBookingTraveller
} from './tour-add-booking-traveller.actions';
import { BookingTravellerRQ } from '@gtd/b2c-client';

@Injectable()
export class TourAddBookingTravellerFacade {
  loaded$ = this.store.pipe(select(tourAddBookingTravellerQuery.getLoaded));
  allTourAddBookingTraveller$ = this.store.pipe(
    select(tourAddBookingTravellerQuery.getAllTourAddBookingTraveller)
  );
  selectedTourAddBookingTraveller$ = this.store.pipe(
    select(tourAddBookingTravellerQuery.getSelectedTourAddBookingTraveller)
  );

  constructor(private store: Store<TourAddBookingTravellerPartialState>) {}

  loadAll(payload: BookingTravellerRQ) {
    this.store.dispatch(new LoadTourAddBookingTraveller(payload));
  }

  reset() {
    this.store.dispatch(new ResetTourAddBookingTraveller());
  }
}
