import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {DATEPICKER_FEATURE_KEY, initialState as datepickerInitialState, reducer} from './+state/datepicker.reducer';
import {DatepickerEffects} from './+state/datepicker.effects';
import {DatepickerFacade} from './+state/datepicker.facade';
import {NxModule} from '@nrwl/angular';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(DATEPICKER_FEATURE_KEY, reducer, {
      initialState: datepickerInitialState
    }),
    EffectsModule.forFeature([DatepickerEffects])
  ],
  providers: [DatepickerFacade]
})
export class DatepickerStateModule {}
