import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  RESETPASSWORDSTATE_FEATURE_KEY,
  ResetPasswordStateState
} from './reset-password-state.reducer';

// Lookup the 'ResetPasswordState' feature state managed by NgRx
const getResetPasswordStateState = createFeatureSelector<
  ResetPasswordStateState
>(RESETPASSWORDSTATE_FEATURE_KEY);

const getLoaded = createSelector(
  getResetPasswordStateState,
  (state: ResetPasswordStateState) => state.loaded
);
const getError = createSelector(
  getResetPasswordStateState,
  (state: ResetPasswordStateState) => state.error
);

const getAllResetPasswordState = createSelector(
  getResetPasswordStateState,
  getLoaded,
  (state: ResetPasswordStateState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getResetPasswordStateState,
  (state: ResetPasswordStateState) => state.selectedId
);
const getSelectedResetPasswordState = createSelector(
  getAllResetPasswordState,
  getSelectedId,
  (resetPasswordState, id) => {
    return resetPasswordState ? Object.assign({}, resetPasswordState) : undefined;
  }
);

export const resetPasswordStateQuery = {
  getLoaded,
  getError,
  getAllResetPasswordState,
  getSelectedResetPasswordState
};
