import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'diffPrice'
})
export class DiffPriceComponent implements PipeTransform {
  transform(value: number, args?: number): number {
    let result;
    if (!args) {
      args = 0;
    }
    result = value - args;
    return result;
  }
}
