import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestPasswordStateFacade} from "./request-password-state/+state/request-password-state.facade";
import {Subscription} from "rxjs";
import {DialogMessageComponent} from "@gtd/components/dialog-message";
import {MatDialog} from "@angular/material";
import {RequestPasswordStateState} from "./request-password-state/+state/request-password-state.reducer";
import {Router} from "@angular/router";
import {AccountResourceService, ResponseEntity} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss']
})
export class RequestPasswordComponent implements OnInit, OnDestroy {
  requestPasswordForm : FormGroup;
  subscriptions = new Subscription();
  isLoading : boolean;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  constructor(
    private requestPasswordStateFacade: RequestPasswordStateFacade,
    private router: Router,
    public dialog: MatDialog,
    private accountResourceService: AccountResourceService
  ) {
    this.requestPasswordStateFacade.destroyRequestPassword();
    this.requestPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  ngOnInit() {

  }

  submitForm() {
    this.isLoading = true;
    this.markFormTouched(this.requestPasswordForm);
    this.accountResourceService.requestPasswordResetUsingPOST(this.requestPasswordForm.get('email').value).subscribe(requestNewPassword => {
      if(requestNewPassword && requestNewPassword.statusCode === ResponseEntity.StatusCodeEnum._200) {
        this.dialog.open(DialogMessageComponent ,{
          data : {
            btn: [
              {btnText: 'global.close'}
            ],
            content :'user.message-send-email-request-new-password',
            dataTranslate: this.requestPasswordForm.controls['email'].value,
            heading: 'user.heading-request-new-password',
            imgIcon: 'assets/icons/envelope.png',
            messageType: 'success'
          },
          panelClass: 'dialog-message',
          position: {
            top: '200px'
          },
          disableClose: true
        });
      } else {
        this.requestPasswordForm.controls['email'].setErrors(['notRegister']);
      }
    }, response => {
      if(response.status === 200) {
        this.dialog.open(DialogMessageComponent ,{
          data : {
            btn: [
              {btnText: 'global.close'}
            ],
            content :'user.message-send-email-request-new-password',
            dataTranslate: this.requestPasswordForm.controls['email'].value,
            heading: 'user.heading-request-new-password',
            imgIcon: 'assets/icons/envelope.png',
            messageType: 'success'
          },
          panelClass: 'dialog-message',
          position: {
            top: '200px'
          },
          disableClose: true
        });
      } else {
        this.requestPasswordForm.controls['email'].setErrors(['notRegister']);
      }
    });
  }

  getErrorMessage() {
    return this.requestPasswordForm.controls['email'].hasError('required')
      ? 'user.email-phone-can-not-be-empty'
      : this.requestPasswordForm.controls['email'].errors !== null && this.requestPasswordForm.controls['email'].errors[0]==='notRegister'
        ? 'user.email-phone-not-exist'
          : this.requestPasswordForm.controls['email'].hasError('pattern') && (this.requestPasswordForm.controls['email'].dirty || this.requestPasswordForm.controls['email'].touched) ? 'Please enter valid email address or phone number': ''
  }

  markFormTouched(group: FormGroup) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup) { control.markAsTouched(); this.markFormTouched(control); }
      else { control.markAsTouched(); };
    });
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
