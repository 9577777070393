import {Pipe, PipeTransform} from '@angular/core';
import {environment} from "@env/environment";

@Pipe({
  name: 'logoAirline'
})
export class LogoAirlineComponent implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === 'VN'|| value === 'VJ' || value === 'QH' || value === 'BL')
      return environment.cdn.logoAirline + value + '.svg';
    else
      return environment.cdn.logoAirline + value + '.gif';
  }
}
