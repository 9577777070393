import {Action} from '@ngrx/store';
import {BookingTags} from "@gtd/b2c-client";

export enum AffiliatesActionTypes {
  LoadAffiliates = '[Affiliates] Load Affiliates',
  AffiliatesLoaded = '[Affiliates] Affiliates Loaded',
  AffiliatesLoadError = '[Affiliates] Affiliates Load Error'
}

export class LoadAffiliates implements Action {
  readonly type = AffiliatesActionTypes.LoadAffiliates;
  constructor(public payload: string) {}
}

export class AffiliatesLoadError implements Action {
  readonly type = AffiliatesActionTypes.AffiliatesLoadError;
  constructor(public payload: any) {}
}

export class AffiliatesLoaded implements Action {
  readonly type = AffiliatesActionTypes.AffiliatesLoaded;
  constructor(public payload: BookingTags) {}
}

export type AffiliatesAction =
  | LoadAffiliates
  | AffiliatesLoaded
  | AffiliatesLoadError;

export const fromAffiliatesActions = {
  LoadAffiliates,
  AffiliatesLoaded,
  AffiliatesLoadError
};
