import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatMenuTrigger, MatSnackBar} from '@angular/material';
import {DatePipe} from '@angular/common';
import {MyBookingService} from "../../my-booking.service";
import {SearchUserBooking} from "@gtd/api-services/booking-service";
import * as moment from 'moment';
import {SearchParams} from "@gtd/api-services/b2b-client";


@Component({
  selector: 'gtd-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  @Input() supplierType: string;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  formFilter: FormGroup;
  endDate = new Date();
  dataFilters: SearchUserBooking;
  dataB2bFilters: SearchParams;

  bookingStatus = [
    {
      label: 'success',
      value: 'CONFIRMED'
    },
    {
      label: 'booked',
      value: 'BOOKED'
    },
    {
      label: 'ticketonprocess',
      value: 'TICKET_ON_PROCESS'
    },
    {
      label: 'failed',
      value: 'FAILED'
    },
    {
      label: 'expired',
      value: 'EXPIRED'
    }
  ];

  defaultToDate;
  defaultFromDate;
  appName = localStorage.getItem('appName');

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private myBookingService: MyBookingService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.myBookingService.getDestroyFilter.subscribe((destroyFilter) => {
      if (destroyFilter) {
        this.formFilter.reset();
      }
    });
    this.myBookingService.getDataFilters.subscribe((filters) => {
      this.dataFilters = filters;
      if (filters && filters.filter) {
        this.defaultToDate = filters.filter.fromDate? moment(filters.filter.fromDate, "DD-MM-YYYY").toDate(): null;
        this.defaultToDate = filters.filter.toDate? moment(filters.filter.toDate, "DD-MM-YYYY").toDate():  null;
        this.formFilter.setValue({
          bookingCode: filters.filter.bookingCode? filters.filter.bookingCode: '',
          bookingStatus: filters.filter.listBookingStatus && filters.filter.listBookingStatus.length > 0 ? filters.filter.listBookingStatus: [],
          status: null,
          datePicker: {
            fromDate: filters.filter.fromDate? this.convertDateToNgbDate(new Date(filters.filter.fromDate)): null,
            toDate: filters.filter.toDate? this.convertDateToNgbDate(new Date(filters.filter.toDate)): null,
          }
        });

      } else {
        this.formFilter.reset();
      }
    });
  }

  submitApply() {
    this.myBookingService.setDataFilters({
      ...this.dataFilters,
      filter: {
        bookingCode: this.formFilter.get('bookingCode').value,
        listBookingStatus: this.formFilter.get('bookingStatus').value
          ? this.formFilter.get('bookingStatus').value
          : null,
        fromDate: this.formFilter.controls['datePicker'].get('fromDate').value? this.datepipe.transform(
          this.formFilter.controls['datePicker'].get('fromDate').value,
          'dd-MM-yyyy'
        ): null,
        toDate: this.formFilter.controls['datePicker'].get('toDate').value? this.datepipe.transform(
          this.formFilter.controls['datePicker'].get('toDate').value,
          'dd-MM-yyyy'
        ): null,
      },
      filterId: Math.random(),
      skipSearch: false,
      page: 0,
      size: 15,
    });
    this.changed.emit("submit");
  }

  reset() {
    this.myBookingService.setDataFilters({
      currentPage: 0,
      totalCount: this.dataFilters.totalCount,
      supplierType: this.dataFilters.supplierType,
      skipSearch: true
    });
    this.createForm();
  }

  onChange(event) {
    const interests = (<FormArray>(
      this.formFilter.get('bookingStatus')
    )) as FormArray;
    if (event.checked) {
      interests.push(new FormControl(event.source.value));
    } else {
      const i = interests.controls.findIndex(
        x => x.value === event.source.value
      );
      interests.removeAt(i);
    }
  }

  private createForm() {
    this.formFilter = this.formBuilder.group({
      bookingCode: [null, Validators.pattern('^[a-zA-Z0-9]+$')],
      bookingStatus: this.formBuilder.array([]),
      status: [''],
      datePicker: new FormGroup({
        fromDate: new FormControl(''), // this.defaultFromDate
        toDate: new FormControl('') //this.defaultToDate
      })
    });
  }
  newdateMonthAgo() {
    let dateMonthAgo = new Date();
    dateMonthAgo.setMonth(dateMonthAgo.getMonth() - 1);
    return dateMonthAgo;
  }

  convertDateToNgbDate(date: Date) {
    const gbDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    return gbDate;
  }
}
