import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CredentialsService } from '@gtd/auth';
import { AvatarFacade, CountUnreadFacade, ProfileFacade } from '@gtd/user';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { MatDialog } from '@angular/material';
import {
  AccountFacade,
  AccountService,
  UtilResourceService
} from '@gtd/b2c-client';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GaTrackingService } from '@gtd/ga-tracking';
import { ShortProfileFacade } from '@gtd/api-services/customer-service';

@Component({
  selector: 'gtd-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isSticky: boolean;
  @Output() public sidenavToggle = new EventEmitter();
  @Input() showDownloadRequest = false;
  @Input() isHideSubBar = false;
  isLogged: boolean;
  userLoggedData: any;
  subscriptions = new Subscription();
  allCountUnread$: any = this.countUnreadFacade.allCountUnread$;
  allAvatar$ = this.avatarFacade.allAvatar$;

  name: number = 0;
  timeoutHandler;
  appName = localStorage.getItem('appName');
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private accountService: AccountService,
    private avatarFacade: AvatarFacade,
    private shortProfileFacade: ShortProfileFacade,
    private accountFacade: AccountFacade,
    private profileFacade: ProfileFacade,
    public dialog: MatDialog,
    private utilResourceService: UtilResourceService,
    private countUnreadFacade: CountUnreadFacade,
    private deviceService: DeviceDetectorService,
    private _gaTrackingService: GaTrackingService
  ) {
    if (this.credentialsService.isAuthenticated() === true) {
      this.isLogged = true;
      this.accountService.getAccountUsingGet().subscribe(_ => {
        if (this.appName != 'B2B') {
          this.shortProfileFacade.loadAll();
        }
      });
    }
    this.name = -1;
  }

  ngOnInit() {
    this.subscriptions.add(
      this.accountFacade.getAccountLogged$.subscribe(dataAccount => {
        if (dataAccount) {
          if (dataAccount.status && dataAccount.status === 417) {
            let dialogRef = this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [
                  { btnText: 'user.cancel', action: 'Cancel' },
                  {
                    btnColor: 'success',
                    btnText: 'user.re-login',
                    action: 'ReLogin'
                  }
                ],
                content: 'user.token-expired-message',
                heading: 'user.token-expired',
                messageType: 'success'
              },
              panelClass: 'dialog-do-confirm',
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(closeModal => {
              if (closeModal.action === 'ReLogin') {
                this.credentialsService.logout().subscribe(result => {
                  if (result) {
                    this.isLogged = false;
                    this.userLoggedData = null;
                    window.open(this.router.url, '_self');
                  }
                });
                setTimeout(() => {
                  let params =
                    this.router.url !== '/auth/login' &&
                    this.router.url !== '/auth/register'
                      ? btoa(
                          encodeURIComponent(
                            JSON.stringify({ urlRedirect: this.router.url })
                          )
                        )
                      : null;
                  this.router.navigate(['/auth/login'], {
                    queryParams: {
                      params: params
                    }
                  });
                });
              }
              if (closeModal.action === 'Cancel') {
                this.subscriptions.add(
                  this.credentialsService.logout().subscribe(result => {
                    if (result) {
                      this.isLogged = false;
                      this.userLoggedData = null;
                      window.open(this.router.url, '_self');
                    }
                  })
                );
              }
            });
          } else {
            this.isLogged = true;
            this.userLoggedData = dataAccount;
          }
        }
      })
    );
    this.subscriptions.add(
      this.shortProfileFacade.getShortProfile$.subscribe(shortProfile => {
        if (shortProfile) {
          this.avatarFacade.getAvatarUser(shortProfile.profileId);
          this.profileFacade.getProfileUsingGet(shortProfile.profileId);
          this.countUnreadFacade.countUnreadMsgUsingGET(
            shortProfile.requesterCode
          );
        }
      })
    );
  }

  onMenuToggle(trigger) {
    if (this.deviceService.isDesktop()) {
      trigger.openMenu();
    }
  }

  track(eventName: string) {
    this._gaTrackingService.track(eventName);
  }

  public mouseup() {
    if (this.timeoutHandler) {
      if (this.name === 0) {
        this.router.navigate(['/']);
      }
      setTimeout(() => {
        clearInterval(this.timeoutHandler);
        this.name = 0;
        this.timeoutHandler = null;
      });
    }
  }

  public mousedown() {
    this.timeoutHandler = setInterval(() => {
      this.name += 1;
      if (this.name === 2) {
        const rClientTracking = localStorage.getItem('client-tracking');
        this.dialog.open(DialogMessageComponent, {
          data: {
            btn: [{ btnText: 'global.close' }],
            content: 'Client ID: ' + rClientTracking,
            messageType: 'success'
          },
          panelClass: 'dialog-message',
          disableClose: true
        });
        this.utilResourceService.createSupportRequestUsingGET().subscribe();
      }
    }, 1000);
  }

  loggin() {
    let params =
      this.router.url !== '/auth/login' && this.router.url !== '/auth/register'
        ? btoa(
            encodeURIComponent(JSON.stringify({ urlRedirect: this.router.url }))
          )
        : null;
    this.router.navigate(['/auth/login'], {
      queryParams: {
        params: params
      }
    });
  }

  register() {
    let params =
      this.router.url !== '/auth/login' && this.router.url !== '/auth/register'
        ? btoa(
            encodeURIComponent(JSON.stringify({ urlRedirect: this.router.url }))
          )
        : null;
    this.router.navigate(['/auth/register'], {
      queryParams: {
        params: params
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  userLogout() {
    this.subscriptions.add(
      this.credentialsService.logout().subscribe(result => {
        if (result) {
          this.isLogged = false;
          this.userLoggedData = null;
          window.open(this.router.url, '_self');
        }
      })
    );
  }
  public onToggleSidenav = () => {
    this.sidenavToggle.emit(this.isLogged);
  };
}
