/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface RequesterInfo { 
    agencyCode?: string;
    agencyId?: number;
    branchCode?: string;
    expirationInMilis?: number;
    lang?: RequesterInfo.LangEnum;
    membershipClass?: string;
    orgCode?: string;
    profileId?: number;
    properties?: any;
    requesterCode?: string;
    requesterId?: string;
    requesterName?: string;
    requesterType?: RequesterInfo.RequesterTypeEnum;
}
export namespace RequesterInfo {
    export type LangEnum = 'VIETNAMESE' | 'ENGLISH';
    export const LangEnum = {
        VIETNAMESE: 'VIETNAMESE' as LangEnum,
        ENGLISH: 'ENGLISH' as LangEnum
    };
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
