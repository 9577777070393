/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AgentProfileDTO } from './agentProfileDTO';
import { LinkedAccountDTO } from './linkedAccountDTO';


export interface AgencyDTO { 
    address1?: string;
    address2?: string;
    agencyClass?: string;
    agencyCode?: string;
    agencyNumber?: string;
    agentAdminId?: number;
    agentAdminUsername?: string;
    agentProfiles?: Array<AgentProfileDTO>;
    branchIdOfParentAgency?: number;
    branchNameOfParentAgency?: string;
    cellPhone?: string;
    cityCode?: string;
    cityName?: string;
    contactPerson1?: string;
    contactPerson2?: string;
    cooperationClass?: AgencyDTO.CooperationClassEnum;
    countryCode?: string;
    countryName?: string;
    createdBy?: string;
    createdDate?: Date;
    email?: string;
    faxNumber?: string;
    fullName?: string;
    id?: number;
    linkedAccounts?: Array<LinkedAccountDTO>;
    note?: string;
    notify?: AgencyDTO.NotifyEnum;
    orgCode?: string;
    parentAgencyCode?: string;
    parentAgencyId?: number;
    phoneContact1?: string;
    phoneContact2?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    representativeMail?: string;
    representativeName?: string;
    representativePhone?: string;
    rootAgency?: boolean;
    sentMail?: number;
    shortName?: string;
    stateCode?: string;
    stateName?: string;
    status?: AgencyDTO.StatusEnum;
    subMail?: string;
    taxCode?: string;
    trackingCode?: string;
    updatedBy?: string;
    updatedDate?: Date;
    verify?: AgencyDTO.VerifyEnum;
}
export namespace AgencyDTO {
    export type CooperationClassEnum = 'ca' | 'sa' | 'cn' | 'ps' | 'nv' | 'bd' | 'b2b2b';
    export const CooperationClassEnum = {
        Ca: 'ca' as CooperationClassEnum,
        Sa: 'sa' as CooperationClassEnum,
        Cn: 'cn' as CooperationClassEnum,
        Ps: 'ps' as CooperationClassEnum,
        Nv: 'nv' as CooperationClassEnum,
        Bd: 'bd' as CooperationClassEnum,
        B2b2b: 'b2b2b' as CooperationClassEnum
    };
    export type NotifyEnum = 'ENABLE' | 'DISABLE';
    export const NotifyEnum = {
        ENABLE: 'ENABLE' as NotifyEnum,
        DISABLE: 'DISABLE' as NotifyEnum
    };
    export type StatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'OTHER';
    export const StatusEnum = {
        ACTIVATED: 'ACTIVATED' as StatusEnum,
        DEACTIVATED: 'DEACTIVATED' as StatusEnum,
        OTHER: 'OTHER' as StatusEnum
    };
    export type VerifyEnum = 'ENABLE' | 'DISABLE';
    export const VerifyEnum = {
        ENABLE: 'ENABLE' as VerifyEnum,
        DISABLE: 'DISABLE' as VerifyEnum
    };
}
