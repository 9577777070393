/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ApprovalProcessDTO } from '../model/approvalProcessDTO';
import { ApprovalProcessHumanFactorDTO } from '../model/approvalProcessHumanFactorDTO';
import { ApprovalProcessStats } from '../model/approvalProcessStats';
import { ApprovalProcessTravelerStats } from '../model/approvalProcessTravelerStats';
import { CheckApprovalProcessResponse } from '../model/checkApprovalProcessResponse';
import { OtaResultOfboolean } from '../model/otaResultOfboolean';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ApprovalProcessResourceService {

    protected basePath = 'https://10.7.71.51:8081';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changeApprovalRequestStatus
     * 
     * @param requestId requestId
     * @param requestStatus requestStatus
     * @param note note
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeApprovalRequestStatusUsingPUT(requestId: number, requestStatus: string, note?: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfboolean>;
    public changeApprovalRequestStatusUsingPUT(requestId: number, requestStatus: string, note?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfboolean>>;
    public changeApprovalRequestStatusUsingPUT(requestId: number, requestStatus: string, note?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfboolean>>;
    public changeApprovalRequestStatusUsingPUT(requestId: number, requestStatus: string, note?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling changeApprovalRequestStatusUsingPUT.');
        }

        if (requestStatus === null || requestStatus === undefined) {
            throw new Error('Required parameter requestStatus was null or undefined when calling changeApprovalRequestStatusUsingPUT.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (requestStatus !== undefined && requestStatus !== null) {
            queryParameters = queryParameters.set('requestStatus', <any>requestStatus);
        }
        if (note !== undefined && note !== null) {
            queryParameters = queryParameters.set('note', <any>note);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<OtaResultOfboolean>(`${this.basePath}/api/approval-process/status/${encodeURIComponent(String(requestId))}`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create
     * 
     * @param approvalProcessDTO approvalProcessDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUsingPOST(approvalProcessDTO: ApprovalProcessDTO, observe?: 'body', reportProgress?: boolean): Observable<ApprovalProcessDTO>;
    public createUsingPOST(approvalProcessDTO: ApprovalProcessDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalProcessDTO>>;
    public createUsingPOST(approvalProcessDTO: ApprovalProcessDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalProcessDTO>>;
    public createUsingPOST(approvalProcessDTO: ApprovalProcessDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalProcessDTO === null || approvalProcessDTO === undefined) {
            throw new Error('Required parameter approvalProcessDTO was null or undefined when calling createUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApprovalProcessDTO>(`${this.basePath}/api/approval-process/`,
            approvalProcessDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public deleteUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public deleteUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public deleteUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<boolean>(`${this.basePath}/api/approval-process/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * disable
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public disableUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public disableUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public disableUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public disableUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling disableUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/approval-process/disable/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * enable
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enableUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public enableUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public enableUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public enableUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling enableUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/approval-process/enable/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllByAgency
     * 
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllByAgencyUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<ApprovalProcessDTO>>;
    public getAllByAgencyUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApprovalProcessDTO>>>;
    public getAllByAgencyUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApprovalProcessDTO>>>;
    public getAllByAgencyUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ApprovalProcessDTO>>(`${this.basePath}/api/approval-process/find-by-agency-code`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getByTraveler
     * 
     * @param travelerCode travelerCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByTravelerUsingGET(travelerCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApprovalProcessDTO>>;
    public getByTravelerUsingGET(travelerCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApprovalProcessDTO>>>;
    public getByTravelerUsingGET(travelerCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApprovalProcessDTO>>>;
    public getByTravelerUsingGET(travelerCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (travelerCode === null || travelerCode === undefined) {
            throw new Error('Required parameter travelerCode was null or undefined when calling getByTravelerUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ApprovalProcessDTO>>(`${this.basePath}/api/approval-process/find-by-traveler/${encodeURIComponent(String(travelerCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getProcessStatistic
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProcessStatisticUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<ApprovalProcessStats>;
    public getProcessStatisticUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalProcessStats>>;
    public getProcessStatisticUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalProcessStats>>;
    public getProcessStatisticUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getProcessStatisticUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ApprovalProcessStats>(`${this.basePath}/api/approval-process/statistic`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTopByTraveler
     * 
     * @param travelerCode travelerCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTopByTravelerUsingGET(travelerCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CheckApprovalProcessResponse>>;
    public getTopByTravelerUsingGET(travelerCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CheckApprovalProcessResponse>>>;
    public getTopByTravelerUsingGET(travelerCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CheckApprovalProcessResponse>>>;
    public getTopByTravelerUsingGET(travelerCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (travelerCode === null || travelerCode === undefined) {
            throw new Error('Required parameter travelerCode was null or undefined when calling getTopByTravelerUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<CheckApprovalProcessResponse>>(`${this.basePath}/api/approval-process/find-top-by-traveler/${encodeURIComponent(String(travelerCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTravelerList
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTravelerListUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApprovalProcessHumanFactorDTO>>;
    public getTravelerListUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApprovalProcessHumanFactorDTO>>>;
    public getTravelerListUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApprovalProcessHumanFactorDTO>>>;
    public getTravelerListUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getTravelerListUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ApprovalProcessHumanFactorDTO>>(`${this.basePath}/api/approval-process/get-traveler-list/${encodeURIComponent(String(agencyCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getTravelerStatistic
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTravelerStatisticUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<ApprovalProcessTravelerStats>;
    public getTravelerStatisticUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalProcessTravelerStats>>;
    public getTravelerStatisticUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalProcessTravelerStats>>;
    public getTravelerStatisticUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getTravelerStatisticUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ApprovalProcessTravelerStats>(`${this.basePath}/api/approval-process/statistic/traveler`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<ApprovalProcessDTO>;
    public getUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalProcessDTO>>;
    public getUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalProcessDTO>>;
    public getUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ApprovalProcessDTO>(`${this.basePath}/api/approval-process/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchTravelerList
     * 
     * @param agencyCode agencyCode
     * @param keyword keyword
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTravelerListUsingGET(agencyCode: string, keyword: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApprovalProcessHumanFactorDTO>>;
    public searchTravelerListUsingGET(agencyCode: string, keyword: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApprovalProcessHumanFactorDTO>>>;
    public searchTravelerListUsingGET(agencyCode: string, keyword: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApprovalProcessHumanFactorDTO>>>;
    public searchTravelerListUsingGET(agencyCode: string, keyword: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling searchTravelerListUsingGET.');
        }

        if (keyword === null || keyword === undefined) {
            throw new Error('Required parameter keyword was null or undefined when calling searchTravelerListUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ApprovalProcessHumanFactorDTO>>(`${this.basePath}/api/approval-process/search-traveler/${encodeURIComponent(String(agencyCode))}/${encodeURIComponent(String(keyword))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update
     * 
     * @param approvalProcessDTO approvalProcessDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUsingPUT(approvalProcessDTO: ApprovalProcessDTO, observe?: 'body', reportProgress?: boolean): Observable<ApprovalProcessDTO>;
    public updateUsingPUT(approvalProcessDTO: ApprovalProcessDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalProcessDTO>>;
    public updateUsingPUT(approvalProcessDTO: ApprovalProcessDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalProcessDTO>>;
    public updateUsingPUT(approvalProcessDTO: ApprovalProcessDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalProcessDTO === null || approvalProcessDTO === undefined) {
            throw new Error('Required parameter approvalProcessDTO was null or undefined when calling updateUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApprovalProcessDTO>(`${this.basePath}/api/approval-process/`,
            approvalProcessDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
