/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AgencyFinancial } from '../model/agencyFinancial';
import { AgencyFinancialDTO } from '../model/agencyFinancialDTO';
import { AgencySumFinancialDTO } from '../model/agencySumFinancialDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AgencyFinancialResourceService {

    protected basePath = 'https://10.7.71.20:8588/b2b_gateway';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * exportAgencyTransactions
     * 
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportAgencyTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyFinancial>>;
    public exportAgencyTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyFinancial>>>;
    public exportAgencyTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyFinancial>>>;
    public exportAgencyTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyFinancial>>(`${this.basePath}/api/_export/agency-transactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportBookerTransactions
     * 
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportBookerTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyFinancial>>;
    public exportBookerTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyFinancial>>>;
    public exportBookerTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyFinancial>>>;
    public exportBookerTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyFinancial>>(`${this.basePath}/api/_export/booker-transactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getLatestAgencyFinancial
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLatestAgencyFinancialUsingGET(agencyCode?: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyFinancialDTO>;
    public getLatestAgencyFinancialUsingGET(agencyCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyFinancialDTO>>;
    public getLatestAgencyFinancialUsingGET(agencyCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyFinancialDTO>>;
    public getLatestAgencyFinancialUsingGET(agencyCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyFinancialDTO>(`${this.basePath}/api/_latest/agency-transactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getLatestBookerFinancial
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLatestBookerFinancialUsingGET(observe?: 'body', reportProgress?: boolean): Observable<AgencyFinancialDTO>;
    public getLatestBookerFinancialUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyFinancialDTO>>;
    public getLatestBookerFinancialUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyFinancialDTO>>;
    public getLatestBookerFinancialUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyFinancialDTO>(`${this.basePath}/api/_latest/booker-transactions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchAgencyTransactions
     * 
     * @param agencyCode agencyCode
     * @param branchCode branchCode
     * @param createdBy createdBy
     * @param bookingRefNumber bookingRefNumber
     * @param bookingNumber bookingNumber
     * @param transDateFrom transDateFrom
     * @param transDateTo transDateTo
     * @param amountFrom amountFrom
     * @param amountTo amountTo
     * @param transType transType
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchAgencyTransactionsUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, bookingNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: 'DEPOSIT' | 'DEDUCT' | 'CREDIT' | 'REFUND' | 'TRANSACTION' | 'TICKET_TRANSACTION' | 'HOTEL_TRANSACTION' | 'COMBO_TRANSACTION' | 'TOUR_TRANSACTION' | 'TRACKING_TRANSACTION' | 'OTHER', page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyFinancialDTO>>;
    public searchAgencyTransactionsUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, bookingNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: 'DEPOSIT' | 'DEDUCT' | 'CREDIT' | 'REFUND' | 'TRANSACTION' | 'TICKET_TRANSACTION' | 'HOTEL_TRANSACTION' | 'COMBO_TRANSACTION' | 'TOUR_TRANSACTION' | 'TRACKING_TRANSACTION' | 'OTHER', page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyFinancialDTO>>>;
    public searchAgencyTransactionsUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, bookingNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: 'DEPOSIT' | 'DEDUCT' | 'CREDIT' | 'REFUND' | 'TRANSACTION' | 'TICKET_TRANSACTION' | 'HOTEL_TRANSACTION' | 'COMBO_TRANSACTION' | 'TOUR_TRANSACTION' | 'TRACKING_TRANSACTION' | 'OTHER', page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyFinancialDTO>>>;
    public searchAgencyTransactionsUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, bookingNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: 'DEPOSIT' | 'DEDUCT' | 'CREDIT' | 'REFUND' | 'TRANSACTION' | 'TICKET_TRANSACTION' | 'HOTEL_TRANSACTION' | 'COMBO_TRANSACTION' | 'TOUR_TRANSACTION' | 'TRACKING_TRANSACTION' | 'OTHER', page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {














        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (branchCode !== undefined && branchCode !== null) {
            queryParameters = queryParameters.set('branchCode', <any>branchCode);
        }
        if (createdBy !== undefined && createdBy !== null) {
            queryParameters = queryParameters.set('createdBy', <any>createdBy);
        }
        if (bookingRefNumber !== undefined && bookingRefNumber !== null) {
            queryParameters = queryParameters.set('bookingRefNumber', <any>bookingRefNumber);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (transDateFrom !== undefined && transDateFrom !== null) {
            queryParameters = queryParameters.set('transDateFrom', <any>transDateFrom);
        }
        if (transDateTo !== undefined && transDateTo !== null) {
            queryParameters = queryParameters.set('transDateTo', <any>transDateTo);
        }
        if (amountFrom !== undefined && amountFrom !== null) {
            queryParameters = queryParameters.set('amountFrom', <any>amountFrom);
        }
        if (amountTo !== undefined && amountTo !== null) {
            queryParameters = queryParameters.set('amountTo', <any>amountTo);
        }
        if (transType !== undefined && transType !== null) {
            queryParameters = queryParameters.set('transType', <any>transType);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyFinancialDTO>>(`${this.basePath}/api/_search/agency-transactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchBookerTransactions
     * 
     * @param bookingRefNumber bookingRefNumber
     * @param transDateFrom transDateFrom
     * @param transDateTo transDateTo
     * @param amountFrom amountFrom
     * @param amountTo amountTo
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBookerTransactionsUsingGET(bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyFinancialDTO>>;
    public searchBookerTransactionsUsingGET(bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyFinancialDTO>>>;
    public searchBookerTransactionsUsingGET(bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyFinancialDTO>>>;
    public searchBookerTransactionsUsingGET(bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingRefNumber !== undefined && bookingRefNumber !== null) {
            queryParameters = queryParameters.set('bookingRefNumber', <any>bookingRefNumber);
        }
        if (transDateFrom !== undefined && transDateFrom !== null) {
            queryParameters = queryParameters.set('transDateFrom', <any>transDateFrom);
        }
        if (transDateTo !== undefined && transDateTo !== null) {
            queryParameters = queryParameters.set('transDateTo', <any>transDateTo);
        }
        if (amountFrom !== undefined && amountFrom !== null) {
            queryParameters = queryParameters.set('amountFrom', <any>amountFrom);
        }
        if (amountTo !== undefined && amountTo !== null) {
            queryParameters = queryParameters.set('amountTo', <any>amountTo);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyFinancialDTO>>(`${this.basePath}/api/_search/booker-transactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sumAgencyTransactions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sumAgencyTransactionsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<AgencySumFinancialDTO>;
    public sumAgencyTransactionsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencySumFinancialDTO>>;
    public sumAgencyTransactionsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencySumFinancialDTO>>;
    public sumAgencyTransactionsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencySumFinancialDTO>(`${this.basePath}/api/_sum/agency-transactions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
