import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbBar} from "./breadcrumbBar";
import {environment} from "@env/environment";

@Component({
  selector: 'gtd-breadcrumb-bar',
  templateUrl: './breadcrumb-bar.component.html',
  styleUrls: ['./breadcrumb-bar.component.scss']
})
export class BreadcrumbBarComponent implements OnInit {
  @Input() breadcrumbBars: BreadcrumbBar[];
  isDisplay: boolean;
  appName: string = localStorage.getItem('appName');
  constructor() { }

  ngOnInit() {
    if(this.appName === 'B2B2C') {
      this.isDisplay = true;
    }
  }

}
