import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {NxModule} from '@nrwl/angular';
import {
  BOOKINGDETAIL_FEATURE_KEY,
  initialState as bookingDetailInitialState,
  reducer
} from './+state/booking-detail.reducer';
import {BookingDetailEffects} from './+state/booking-detail.effects';
import {BookingDetailFacade} from './+state/booking-detail.facade';
import {environment} from "@env/environment";
import {BASE_PATH, ProductResourceService} from "@gtd/b2c-client";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(BOOKINGDETAIL_FEATURE_KEY, reducer, {
      initialState: bookingDetailInitialState
    }),
    EffectsModule.forFeature([BookingDetailEffects]),
  ],
  providers: [{provide: BASE_PATH, useValue: environment.api.b2cClient}, BookingDetailFacade, ProductResourceService]
})
export class DetailStateModule {}
