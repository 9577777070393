import { Component, OnInit } from '@angular/core';
import {CredentialsService} from "./authenticate/credentials.service";

@Component({
  selector: 'gtd-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(
    private credentialsService: CredentialsService,
  ) {
    this.credentialsService.logout().subscribe(result => {
      if (result) {
        console.log('Logout');
      }
    });
  }

  ngOnInit() {
  }

}
