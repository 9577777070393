import { Injectable } from '@angular/core';
import { EXCHANGE_VALUE } from '@gtd/hotels/data-access/contansts';

@Injectable({
  providedIn: 'root'
})
export class MilesFunctionService {
  constructor() {}

  caculateMiles(value: number, exchangeRate: number, totalPrice: number) {
    console.log(value, exchangeRate, totalPrice);
    return Math.floor(
      (totalPrice * (value / 100)) / (exchangeRate * EXCHANGE_VALUE)
    );
  }

  caculateCash(value: number, totalPrice: number) {
    return Math.floor((totalPrice * (100 - value)) / 100);
  }
}
