import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AirportPartialState } from './airport.reducer';
import { airportQuery } from './airport.selectors';
import { LoadAirport } from './airport.actions';

@Injectable()
export class AirportFacade {
  loaded$ = this.store.pipe(select(airportQuery.getLoaded));
  allAirport$ = this.store.pipe(select(airportQuery.getAllAirport));
  selectedAirport$ = this.store.pipe(select(airportQuery.getSelectedAirport));

  constructor(private store: Store<AirportPartialState>) {}

  loadAll(query, country) {
    this.store.dispatch(new LoadAirport({ query, country }));
  }
}
