import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { BannerPartialState } from './banner.reducer';
import {
  LoadBanner,
  BannerLoaded,
  BannerLoadError,
  BannerActionTypes
} from './banner.actions';
import { BannerTypeService } from '@gtd/api-services/newface-banner';
import { map } from 'rxjs/operators';

@Injectable()
export class BannerEffects {
  @Effect() loadBanner$ = this.dataPersistence.fetch(
    BannerActionTypes.LoadBanner,
    {
      run: (action: LoadBanner, state: BannerPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.bannerTypeService
          .wpV2BannerTypeGet(
            '' + action.payload.bannerId,
            '' + action.payload.deviceId
          )
          .pipe(
            map(payload => ({
              type: BannerActionTypes.BannerLoaded,
              payload: {
                type: action.payload.type,
                data: payload
              }
            }))
          );
        // return new BannerLoaded([]);
      },

      onError: (action: LoadBanner, error) => {
        console.error('Error', error);
        return new BannerLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<BannerPartialState>,
    private bannerTypeService: BannerTypeService
  ) {}
}
