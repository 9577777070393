/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PaymentTransReply { 
    amount?: number;
    decision?: string;
    gateway?: PaymentTransReply.GatewayEnum;
    id?: number;
    msgContent?: string;
    notes?: string;
    paymentByCode?: string;
    paymentByName?: string;
    paymentFee?: number;
    paymentRefCode?: string;
    paymentTransRequestId?: number;
    reasonCode?: string;
    reconciliationId?: string;
    requestId?: string;
    requestToken?: string;
    subscriptionId?: string;
    trackingCode?: string;
    transDate?: Date;
    transactionType?: string;
}
export namespace PaymentTransReply {
    export type GatewayEnum = 'CYBERSOURCE' | 'VNPAYQR' | 'MOMO' | 'NGANLUONG' | 'PAYOO' | 'ZALO';
    export const GatewayEnum = {
        CYBERSOURCE: 'CYBERSOURCE' as GatewayEnum,
        VNPAYQR: 'VNPAYQR' as GatewayEnum,
        MOMO: 'MOMO' as GatewayEnum,
        NGANLUONG: 'NGANLUONG' as GatewayEnum,
        PAYOO: 'PAYOO' as GatewayEnum,
        ZALO: 'ZALO' as GatewayEnum
    };
}
