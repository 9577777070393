import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'gtd-mobile-download-app',
  templateUrl: './mobile-download-app.component.html',
  styleUrls: ['./mobile-download-app.component.scss']
})
export class MobileDownloadAppComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<boolean>(false);
  link = '';
  constructor() {}

  ngOnInit() {
    this.link =
      this.getMobileOperatingSystem() === 'Android'
        ? environment.downloadApp.android
        : environment.downloadApp.ios;
  }
  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'unknown';
  }
}
