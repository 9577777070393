import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';
import { CovidDialogComponent } from '@gtd/components/covid-dialog';
import { CovidDialogService } from '@gtd/components/covid-dialog';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { BannerTypeService } from '@gtd/api-services/newface-banner';
import { PixelTrackingService } from '@gtd/pixel-tracking';

@Component({
  selector: 'gtd-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  comboActive$: Observable<any>;
  hotelPartners;
  currentSlugSubject$ = new BehaviorSubject<string>('');
  currentSlug$ = this.currentSlugSubject$.asObservable();
  categoriesSubject$ = new BehaviorSubject<any>(null);
  categories$ = this.categoriesSubject$.asObservable();
  utm_source = localStorage.getItem('utm_source');
  previousPageMeta = {
    title: '',
    desciption: '',
    ogTitle: '',
    ogDescription: '',
    ogUrl: '',
    ogType: '',
    ogImage: ''
  };
  routesMeta = [
    {
      slug: 've-may-bay',
      index: 0,
      title: 'Vé Máy Bay Giá Rẻ, Nhiều Khuyến Mãi Hấp Dẫn 2022 | Gotadi',
      description:
        'Đặt vé máy bay giá rẻ VietJet, Vietnam Airlines, Bamboo Airways, Pacific...Thanh toán linh hoạt. Đặt online ngay săn vé máy bay giá rẻ cực dễ dàng trên Gotadi.'
    },
    {
      slug: 'khach-san',
      index: 1,
      title: 'Đặt Phòng Khách Sạn Giá Rẻ, Nhiều Khuyến Mãi 2022 | Gotadi ',
      description:
        'Đặt phòng khách sạn resort giá rẻ. Tiết kiệm lên đến 15% - Nhiều khuyến mãi - Giá đúng - Không chi phí ẩn - Thanh toán đa dạng - An toàn - Hỗ trợ 24/7.'
    },
    {
      slug: 'combo-du-lich',
      index: 2,
      title:
        'Combo du lịch vé máy bay và khách sạn giá tốt, nhiều khuyến mãi | Gotadi',
      description:
        'Đặt combo du lịch vé máy bay và khách sạn giá rẻ, tiết kiệm lên đến 20% - Nhiều khuyến mãi - Thanh toán đa dạng - An toàn - Hỗ trợ 24/7. Đặt ngay trên Gotadi.com'
    },
    {
      slug: 'vui-choi-giai-tri',
      index: 3,
      title: 'Trải nghiệm những địa điểm du lịch, vui chơi phổ biến',
      description:
        'Đặt vé tham quan các địa điểm nổi tiếng, hoặc khám phá các tour du lịch hấp dẫn'
    }
  ];
  constructor(
    private deviceService: DeviceDetectorService,
    private activatedRoute: ActivatedRoute,
    private _covidDialogService: CovidDialogService,
    public dialog: MatDialog,
    public httpClient: HttpClient,
    private titleService: Title,
    private metaService: Meta,
    private _bannerTypeService: BannerTypeService,
    private _pixelTrackingService: PixelTrackingService,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    activatedRoute.queryParams.subscribe(param => {
      if (param && param.actions && param.actions === 'qr-code-download') {
        let deviceInfo = this.deviceService.getDeviceInfo();
        if (deviceInfo.os === 'Android') {
          window.open(environment.downloadApp.android);
        }
        if (
          deviceInfo.device === 'iPad' ||
          deviceInfo.device === 'iPhone' ||
          deviceInfo.os === 'iOS'
        ) {
          window.open(environment.downloadApp.ios);
        }
      }
    });
  }

  ngOnInit() {
    if(this.utm_source && this.utm_source === 'DHA') {
      localStorage.removeItem('utm_source')
    }
    this.setPreviousMeta();
    this.checkTabSelected();
    this.comboActive$ = this.httpClient.get(
      `${environment.api.b2cClient}/api/get-config`
    );
    this.http
      .get('https://cms.gotadi.com/wp-json/wp/v2/banner_taxonomy?per_page=100')
      .subscribe((categories: any) => {
        this.categoriesSubject$.next(categories);
      });
    this._bannerTypeService.wpV2BannerTypeGet('87').subscribe(banners => {
      this.hotelPartners = banners;
    });
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptions.add(
        this._covidDialogService.loadData().subscribe(res => {
          if (
            res &&
            res[0] &&
            res[0].lastModifiedDate !== localStorage.getItem('covid')
          ) {
            const dialogRef = this.dialog.open(CovidDialogComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                messageType: 'success',
                heading: res[0].title,
                content: res[0].description,
                dataNotifications: res[0].dataNotifications,
                time: res[0].lastModifiedDate
              },
              width: '100vw',
              maxWidth: '100vw',
              position: {
                bottom: '0'
              },
              autoFocus: false,
              panelClass: 'covid-warning-component',
              disableClose: true
            });
          }
        })
      );
    }
  }

  checkTabSelected() {
    this.activatedRoute.params.subscribe(params => {
      const currentRoute = params.slug
        ? this.routesMeta.find(route => route.slug === params.slug)
        : null;
      if (currentRoute) {
        this.currentSlugSubject$.next(currentRoute.slug);
        this.titleService.setTitle(currentRoute.title);
        this.metaService.updateTag({
          property: 'title',
          content: currentRoute.title
        });
        this.metaService.updateTag({
          name: 'description',
          content: currentRoute.description
        });
        this.metaService.updateTag({
          property: 'og:title',
          content: currentRoute.title
        });
        this.metaService.updateTag({
          property: 'og:description',
          content: currentRoute.description
        });
      }
    });
  }

  setPreviousMeta() {
    this.previousPageMeta.title = this.titleService.getTitle();
    this.previousPageMeta.desciption = this.metaService.getTag(
      "name='description'"
    ).content;
    this.previousPageMeta.ogTitle = this.metaService.getTag(
      "property='og:title'"
    ).content;
    this.previousPageMeta.ogDescription = this.metaService.getTag(
      "property='og:description'"
    ).content;
    this.previousPageMeta.ogType = this.metaService.getTag(
      "property='og:type'"
    ).content;
    this.previousPageMeta.ogImage = this.metaService.getTag(
      "property='og:image'"
    ).content;
    this.previousPageMeta.ogUrl = this.metaService.getTag(
      "property='og:url'"
    ).content;
  }

  ngOnDestroy() {
    this.titleService.setTitle(this.previousPageMeta.title);
    this.metaService.updateTag({
      name: 'description',
      content: this.previousPageMeta.desciption
    });
    this.metaService.updateTag({
      property: 'og:title',
      content: this.previousPageMeta.ogTitle
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: this.previousPageMeta.ogDescription
    });
    this.metaService.updateTag({
      property: 'og:type',
      content: this.previousPageMeta.ogType
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: this.previousPageMeta.ogImage
    });
    this.metaService.updateTag({
      property: 'og:url',
      content: this.previousPageMeta.ogUrl
    });
    this.categoriesSubject$.next(null);
    this.categoriesSubject$.complete();
    this.subscriptions.unsubscribe();
  }
}
