import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Btn, DialogMessage} from "../dialogMessage";
import {Observable, timer} from "rxjs";
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'gtd-dialog-otp-req',
  templateUrl: './dialog-otp-req.component.html',
  styleUrls: ['./dialog-otp-req.component.scss']
})
export class DialogOtpReqComponent implements OnInit {
  otp: string;
  counter$: Observable<number>;
  counter: number;
  count = 180;

  constructor(
    public dialogRef: MatDialogRef<DialogOtpReqComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogMessage
  ) {
    this.counter$ = timer(0,1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

  ngOnInit() {
    this.counter$.subscribe(counter => {
      this.counter = counter;
    })
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onActionClick(): void {
    this.dialogRef.close({action: 'Submit', otpCode: this.otp});
  }
  onActionResend(): void {
    this.dialogRef.close({action: 'Resend'});
  }
}
