import { LookupB2bAction, LookupB2bActionTypes } from './lookup-b2b.actions';
import {LookupDTO} from "@gtd/api-services/b2b-client";

export const LOOKUPB2B_FEATURE_KEY = 'lookupB2b';

/**
 * Interface for the 'LookupB2b' data used in
 *  - LookupB2bState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}
export interface LookupB2bSearch {
  domainName: string,
  objectName: string,
  lookupType?: string,
  page?: number,
  size?: number,
  sort?: Array<string>,
  observe?: 'body',
  reportProgress?: boolean
}

export interface LookupB2bState {
  list: Array<LookupDTO>; // list of LookupB2b; analogous to a sql normalized table
  selectedId?: string | number; // which LookupB2b record has been selected
  loaded: boolean; // has the LookupB2b list been loaded
  error?: any; // last none error (if any)
}

export interface LookupB2bPartialState {
  readonly [LOOKUPB2B_FEATURE_KEY]: LookupB2bState;
}

export const initialState: LookupB2bState = {
  list: [],
  loaded: false
};

export function lookupB2bReducer(
  state: LookupB2bState = initialState,
  action: LookupB2bAction
): LookupB2bState {
  switch (action.type) {
    case LookupB2bActionTypes.LookupB2bLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
