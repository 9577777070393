import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PROMOTIONFILTERBYVOUCHER_FEATURE_KEY,
  PromotionFilterByVoucherState
} from './promotion-filter-by-voucher.reducer';

// Lookup the 'PromotionFilterByVoucher' feature state managed by NgRx
const getPromotionFilterByVoucherState = createFeatureSelector<
  PromotionFilterByVoucherState
>(PROMOTIONFILTERBYVOUCHER_FEATURE_KEY);

const getLoaded = createSelector(
  getPromotionFilterByVoucherState,
  (state: PromotionFilterByVoucherState) => state.loaded
);
const getError = createSelector(
  getPromotionFilterByVoucherState,
  (state: PromotionFilterByVoucherState) => state.error
);

const getAllPromotionFilterByVoucher = createSelector(
  getPromotionFilterByVoucherState,
  getLoaded,
  (state: PromotionFilterByVoucherState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getPromotionFilterByVoucherState,
  (state: PromotionFilterByVoucherState) => state.selectedId
);
const getSelectedPromotionFilterByVoucher = createSelector(
  getAllPromotionFilterByVoucher,
  getSelectedId,
  (promotionFilterByVoucher, id) => {
    const result = promotionFilterByVoucher.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const promotionFilterByVoucherQuery = {
  getLoaded,
  getError,
  getAllPromotionFilterByVoucher,
  getSelectedPromotionFilterByVoucher
};
