/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MarkupDTO { 
    agencyCode?: string;
    applyToAgencyCode?: string;
    applyToAgencyName?: string;
    applyToCustomerClass?: MarkupDTO.ApplyToCustomerClassEnum;
    bookingType?: MarkupDTO.BookingTypeEnum;
    businessType?: MarkupDTO.BusinessTypeEnum;
    createdBy?: string;
    createdDate?: Date;
    defaultValue?: number;
    effectiveFrom?: Date;
    effectiveTo?: Date;
    exceptionCount?: number;
    id?: number;
    orgCode?: string;
    priority?: number;
    productType?: MarkupDTO.ProductTypeEnum;
    publishOn?: Date;
    status?: MarkupDTO.StatusEnum;
    type?: MarkupDTO.TypeEnum;
    updatedBy?: string;
    updatedDate?: Date;
    valueType?: MarkupDTO.ValueTypeEnum;
}
export namespace MarkupDTO {
    export type ApplyToCustomerClassEnum = 'BASIC' | 'SILVER' | 'GOLD' | 'PLATINUM';
    export const ApplyToCustomerClassEnum = {
        BASIC: 'BASIC' as ApplyToCustomerClassEnum,
        SILVER: 'SILVER' as ApplyToCustomerClassEnum,
        GOLD: 'GOLD' as ApplyToCustomerClassEnum,
        PLATINUM: 'PLATINUM' as ApplyToCustomerClassEnum
    };
    export type BookingTypeEnum = 'DOME' | 'INTE';
    export const BookingTypeEnum = {
        DOME: 'DOME' as BookingTypeEnum,
        INTE: 'INTE' as BookingTypeEnum
    };
    export type BusinessTypeEnum = 'B2C' | 'B2B';
    export const BusinessTypeEnum = {
        B2C: 'B2C' as BusinessTypeEnum,
        B2B: 'B2B' as BusinessTypeEnum
    };
    export type ProductTypeEnum = 'AIR' | 'HOTEL' | 'TOUR';
    export const ProductTypeEnum = {
        AIR: 'AIR' as ProductTypeEnum,
        HOTEL: 'HOTEL' as ProductTypeEnum,
        TOUR: 'TOUR' as ProductTypeEnum
    };
    export type StatusEnum = 'PUBLISHING' | 'PENDING' | 'RETIRED';
    export const StatusEnum = {
        PUBLISHING: 'PUBLISHING' as StatusEnum,
        PENDING: 'PENDING' as StatusEnum,
        RETIRED: 'RETIRED' as StatusEnum
    };
    export type TypeEnum = 'PER_PAX_PER_BOOKING' | 'PER_PAX_PER_SEGMENT' | 'PER_BOOKING';
    export const TypeEnum = {
        PAXPERBOOKING: 'PER_PAX_PER_BOOKING' as TypeEnum,
        PAXPERSEGMENT: 'PER_PAX_PER_SEGMENT' as TypeEnum,
        BOOKING: 'PER_BOOKING' as TypeEnum
    };
    export type ValueTypeEnum = 'FIXED' | 'PERCENT';
    export const ValueTypeEnum = {
        FIXED: 'FIXED' as ValueTypeEnum,
        PERCENT: 'PERCENT' as ValueTypeEnum
    };
}
