import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {ShellComponent} from './shell.component';
import {HttpClientModule} from '@angular/common/http';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookSquare,
  faGithub,
  faInstagram,
  faMediumM,
  faSkype,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

import {
  faAlignLeft,
  faBook,
  faCameraRetro,
  faCertificate,
  faGift,
  faHotel,
  faMobileAlt,
  faParachuteBox,
  faPlane,
  faReceipt,
  faSignOutAlt,
  faSortDown,
  faSuitcaseRolling,
  faUnlockAlt,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import {CopyRightComponent} from './footer/copy-right/copy-right.component';
import {PaymentMethodComponent} from './footer/payment-method/payment-method.component';
import {FooterImgComponent} from './footer/footer-img/footer-img.component';
import {LanguageChangeComponent} from './header/language-change/language-change.component';
import {
  MatBadgeModule,
  MatBottomSheetModule,
  MatCardModule,
  MatDividerModule,
  MatIconModule,
  MatRippleModule,
  MatSidenavModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MainNavbarComponent} from './main-navbar/main-navbar.component';
import {faAddressBook, faUser} from '@fortawesome/free-regular-svg-icons';
import {AvatarModule, ProfileModule, StateNotificationModule} from '@gtd/user';
import {SnackbarModule} from '@gtd/components/snackbar';
import {AuthenticateModule} from '@gtd/auth';
import {TranslateModule} from '@ngx-translate/core';
import {PopoverModule} from 'ngx-smart-popover';
import {BottomSheetDownAppComponent} from './bottom-sheet-down-app/bottom-sheet-down-app.component';
import {B2cClientModule, UserJwtControllerService, UtilResourceService} from '@gtd/b2c-client';
import {DialogMessageModule} from '@gtd/components/dialog-message';
import {ClientButtonModule} from '@gtd/components/client-button';
import {ScrollToTopModule} from '@gtd/scroll-to-top';
import {MobileDownloadAppComponent} from './mobile-download-app/mobile-download-app.component';
import {LazyLoadImagesModule} from '@gtd/directives/lazy-load-images';
import {CustomerServiceModule} from "@gtd/api-services/customer-service";
// import { BookingResultBottomSheetComponent } from '@gtd/booking-result'
library.add(faGithub, faMediumM, faTwitter, faInstagram, faYoutube);

library.add(
  faGithub,
  faMediumM,
  faTwitter,
  faInstagram,
  faYoutube,
  faPlane,
  faHotel,
  faSuitcaseRolling,
  faBook,
  faFacebookSquare,
  faSkype,
  faCertificate,
  faParachuteBox,
  faCameraRetro,
  faGift,
  faMobileAlt,
  faSortDown,
  faUser,
  faUsers,
  faAlignLeft,
  faUnlockAlt,
  faSignOutAlt,
  faAddressBook,
  faReceipt
);

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    FontAwesomeModule,
    MatTooltipModule,
    HttpClientModule,
    MatSidenavModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    MatMenuModule,
    AvatarModule,
    SnackbarModule,
    AuthenticateModule,
    TranslateModule,
    MatRippleModule,
    PopoverModule,
    MatBottomSheetModule,
    StateNotificationModule,
    MatBadgeModule,
    ProfileModule,
    DialogMessageModule,
    ClientButtonModule,
    ScrollToTopModule,
    LazyLoadImagesModule,
    CustomerServiceModule,
    B2cClientModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    ShellComponent,
    CopyRightComponent,
    PaymentMethodComponent,
    LanguageChangeComponent,
    FooterImgComponent,
    MainNavbarComponent,
    BottomSheetDownAppComponent,
    MobileDownloadAppComponent
  ],
  providers: [UserJwtControllerService, UtilResourceService],
  exports: [ShellComponent],
  entryComponents: [BottomSheetDownAppComponent]
})
export class ShellModule {}
