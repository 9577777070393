import {
  animate,
  group,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [style({ opacity: 0 }), animate(250)]),
  transition('* => void', [
    animate(
      250,
      style({
        opacity: 0
      })
    )
  ])
]);

export const fadeIn = trigger('fadeIn', [
  transition(':enter', [style({ opacity: 0 }), animate(500)]),
  transition(':leave', [style({ opacity: 0 }), animate(0)])
]);

export const slideInVertical = trigger('slideInVertical', [
  transition(':enter', [style({ height: 0 }), animate(250)]),
  transition(':leave', [style({ height: 0 }), animate(0)])
]);

export const slideInHorizontal = trigger('slideInHorizontal', [
  transition(':enter', [style({ width: 0 }), animate(250)]),
  transition(':leave', [style({ width: 0 }), animate(0)])
]);

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible'
      })
    ),
    state(
      'out',
      style({
        'max-height': '0px',
        opacity: '0',
        visibility: 'hidden'
      })
    ),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0'
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '0px'
          })
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden'
          })
        )
      ])
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible'
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '500px'
          })
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1'
          })
        )
      ])
    ])
  ])
];
