import {Injectable} from '@angular/core';
import {CredentialsService} from "./credentials.service";
import {LoginVM} from "@gtd/api-services/b2b-client";
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, of} from "rxjs";

const helper = new JwtHelperService();

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})

export class AuthenticateService {

  constructor(
    private credentialsService: CredentialsService,
  ) { }

  getToken(): string {
    if (localStorage.getItem('credentials')) {
      const credentials = JSON.parse(localStorage.getItem('credentials'));
      return credentials.token;
    }
    return '';
  }

  isLoggedIn() {
    const token = this.getToken();
    return token != null;
  }
  isAuthenticated() {
    return this.credentialsService.isAuthenticated();
  }

  isTokenExpired(token: string) {
    return helper.isTokenExpired(token);
  }

  login(context: LoginVM) {

  }

  storeAuthenticationToken(data: any) {
    const user = {
      username: data.username,
      token: data.token
    };
    this.credentialsService.setCredentials(user, data.remember);
  }

  get authoritiesConstantsOnlyAgent(): boolean {
    return this.credentialsService.authoritiesConstantsOnlyAgent();
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout() {
    // Customize credentials invalidation here
    // this.authenticateFacade.logOutAction();
    this.credentialsService.setCredentials();
    return true;
  }
  logoutObs(): Observable<boolean> {
    this.credentialsService.setCredentials();
    // this.authenticateFacade.logOutAction();
    return of(true);
  }
}
