import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { PaymentOptionInfoPartialState } from './payment-option-info.reducer';
import {
  LoadPaymentOptionInfo,
  PaymentOptionInfoLoadError,
  PaymentOptionInfoActionTypes
} from './payment-option-info.actions';
import {PaymentOptionService} from "../../lib/api/paymentOption.service";
import {map} from "rxjs/operators";

@Injectable()
export class PaymentOptionInfoEffects {
  @Effect() loadPaymentOptionInfo$ = this.dataPersistence.fetch(
    PaymentOptionInfoActionTypes.LoadPaymentOptionInfo,
    {
      run: (
        action: LoadPaymentOptionInfo,
        state: PaymentOptionInfoPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.paymentOptionService.wpV2PaymentOptionGet().pipe(
          map(result => ({
            type: PaymentOptionInfoActionTypes.PaymentOptionInfoLoaded,
            payload: result,
          }))
        );
      },

      onError: (action: LoadPaymentOptionInfo, error) => {
        console.error('Error', error);
        return new PaymentOptionInfoLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private paymentOptionService: PaymentOptionService,
    private dataPersistence: DataPersistence<PaymentOptionInfoPartialState>
  ) {}
}
