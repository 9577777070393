import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { CitiesPopularPartialState } from './cities-popular.reducer';
import { citiesPopularQuery } from './cities-popular.selectors';
import { LoadCitiesPopular } from './cities-popular.actions';

@Injectable()
export class CitiesPopularFacade {
  loaded$ = this.store.pipe(select(citiesPopularQuery.getLoaded));
  allCitiesPopular$ = this.store.pipe(
    select(citiesPopularQuery.getAllCitiesPopular)
  );
  allDomesticCities = this.store.pipe(
    select(citiesPopularQuery.getAllDomesticCitiesPopular)
  );
  selectedCitiesPopular$ = this.store.pipe(
    select(citiesPopularQuery.getSelectedCitiesPopular)
  );

  constructor(private store: Store<CitiesPopularPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadCitiesPopular());
  }
}
