import {Action} from '@ngrx/store';
import {CustomerAvatarDTO} from "@gtd/api-services/customer-service";

export enum LibraryAvatarActionTypes {
  LoadLibraryAvatar = '[LibraryAvatar] Load LibraryAvatar',
  LibraryAvatarLoaded = '[LibraryAvatar] LibraryAvatar Loaded',
  LibraryAvatarLoadError = '[LibraryAvatar] LibraryAvatar Load Error',
  LibraryAvatarResetAll = '[LibraryAvatar] LibraryAvatar Reset All'
}

export class LoadLibraryAvatar implements Action {
  readonly type = LibraryAvatarActionTypes.LoadLibraryAvatar;
  constructor(public payload: CustomerAvatarDTO) {}
}

export class LibraryAvatarLoadError implements Action {
  readonly type = LibraryAvatarActionTypes.LibraryAvatarLoadError;
  constructor(public payload: any) {}
}

export class LibraryAvatarLoaded implements Action {
  readonly type = LibraryAvatarActionTypes.LibraryAvatarLoaded;
  constructor(public payload: CustomerAvatarDTO) {}
}

export class ResetAllLibraryAvatar implements Action {
  readonly type = LibraryAvatarActionTypes.LibraryAvatarResetAll;
}

export type LibraryAvatarAction =
  | LoadLibraryAvatar
  | LibraryAvatarLoaded
  | ResetAllLibraryAvatar
  | LibraryAvatarLoadError;

export const fromLibraryAvatarActions = {
  LoadLibraryAvatar,
  LibraryAvatarLoaded,
  ResetAllLibraryAvatar,
  LibraryAvatarLoadError
};
