import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from '@env/environment';
import { GaTrackingService } from '@gtd/ga-tracking';

export enum FlightTypeSwitcherEnum {
  ONEWAY = 'ONEWAY',
  ROUNDTRIP = 'ROUNDTRIP'
}

@Component({
  selector: 'gtd-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitcherComponent),
      multi: true
    }
  ]
})
export class SwitcherComponent implements ControlValueAccessor, OnChanges {
  // Allow the input to be disabled, and when it is make it somewhat transparent.
  @Input() disabled = false;
  @Input() idx;
  @Input() roundType: FlightTypeSwitcherEnum;
  @Output() roundTypeChange: EventEmitter<any> = new EventEmitter<any>();
  selected: FlightTypeSwitcherEnum;
  getDataSearchFlight;
  bidingValue = FlightTypeSwitcherEnum;

  constructor(private _gaTrackingService: GaTrackingService) {}

  // Function to call when the switch changes.
  onChange = (value: any) => {};

  // Function to call when the input is touched (when switcher is clicked).
  onTouched = () => {};

  get value(): FlightTypeSwitcherEnum {
    return this.selected;
  }

  ngOnInit() {}
  // Allows Angular to register a function to call when the model (selected) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: FlightTypeSwitcherEnum): void {
    this.getDataSearchFlight = JSON.parse(
      localStorage.getItem('dataSearchFlight')
    );
    if (!this.getDataSearchFlight) {
      this.selected = value;
    } else {
      this.selected = this.getDataSearchFlight;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.roundTypeSelected && !changes.roundTypeSelected.firstChange) {
      this.changeValue(changes.roundTypeSelected.currentValue);
    }
  }

  changeValue(value: FlightTypeSwitcherEnum) {
    // this.selected = value;
    // this.onChange(value);
    this.roundTypeChange.emit(value);
    this._gaTrackingService.track(
      value === this.bidingValue.ONEWAY ? 'ticket_oneway' : 'ticket_return'
    );
  }
}
