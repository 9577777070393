/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ContactInformation } from './contactInformation';
import { TransactionInfo } from './transactionInfo';
import { Traveler } from './traveler';


export interface OfflineBookingRequest { 
    agencyCode?: string;
    agencyName?: string;
    bookerCode?: string;
    bookerName?: string;
    bookingDate?: string;
    bookingId?: string;
    bookingNote?: string;
    bookingType?: OfflineBookingRequest.BookingTypeEnum;
    contactInformation?: ContactInformation;
    departureDate?: string;
    fromLocationCode?: string;
    id?: number;
    issueDate?: string;
    paymentGateway?: string;
    returnDate?: string;
    status?: string;
    supplierType?: OfflineBookingRequest.SupplierTypeEnum;
    toLocationCode?: string;
    totalMarkup?: number;
    totalPaymentAmount?: number;
    transactionInfoList?: Array<TransactionInfo>;
    travelerList?: Array<Traveler>;
    tripType?: string;
    vat?: number;
}
export namespace OfflineBookingRequest {
    export type BookingTypeEnum = 'DOME' | 'INTE';
    export const BookingTypeEnum = {
        DOME: 'DOME' as BookingTypeEnum,
        INTE: 'INTE' as BookingTypeEnum
    };
    export type SupplierTypeEnum = 'AIR' | 'HOTEL' | 'COMBO' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const SupplierTypeEnum = {
        AIR: 'AIR' as SupplierTypeEnum,
        HOTEL: 'HOTEL' as SupplierTypeEnum,
        COMBO: 'COMBO' as SupplierTypeEnum,
        TOURS: 'TOURS' as SupplierTypeEnum,
        TRAIN: 'TRAIN' as SupplierTypeEnum,
        SHIP: 'SHIP' as SupplierTypeEnum,
        OTHER: 'OTHER' as SupplierTypeEnum
    };
}
