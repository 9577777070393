import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgenciesService {
  agencies = new BehaviorSubject<any>(null);
  getAgencies = this.agencies.asObservable();

  constructor() {}

  setAgencies(agencies: any) {
    this.agencies.next(agencies);
  }
}
