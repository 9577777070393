import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { SearchKeywordPartialState } from './search-keyword.reducer';
import {
  ComboVJLoadSearchKeyword,
  SearchKeywordActionTypes,
  ComboVJSearchKeywordLoadError
} from './search-keyword.actions';
import { HotelResourceV3Service } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class SearchKeywordEffects {
  @Effect() ComboVJloadSearchKeyword$ = this.dataPersistence.fetch(
    SearchKeywordActionTypes.ComboVJLoadSearchKeyword,
    {
      run: (
        action: ComboVJLoadSearchKeyword,
        state: SearchKeywordPartialState
      ) => {
        return this.hotelResourceV3Service
          .searchKeyWordUsingGET(
            action.payload.keyword,
            action.payload.language,
            action.payload.pageNumber,
            action.payload.pageSize
          )
          .pipe(
            map(payload => ({
              type: SearchKeywordActionTypes.ComboVJSearchKeywordLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: ComboVJLoadSearchKeyword, error) => {
        console.error('Error', error);
        return new ComboVJSearchKeywordLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private hotelResourceV3Service: HotelResourceV3Service,
    private dataPersistence: DataPersistence<SearchKeywordPartialState>
  ) {}
}
