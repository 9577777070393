import { Observable } from 'rxjs/Rx';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BreadcrumbBar } from '@gtd/components/breadcrumb-bar';
import { combineLatest, of, Subscription } from 'rxjs';
import { TransactionFinancialService } from './transaction-financial.service';
import {
  DEFAULT_PAGINATION_OFFSET,
  DEFAULT_PAGINATION_PAGE,
  fadeIn,
  fadeInOut
} from '@gtd/helpers';
import { MatSnackBar } from '@angular/material';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import * as moment from 'moment';
import {
  AgencyFinancialDTO,
  FilterFinancial,
  FilterVM,
  GetHeaderSummaryFacade,
  TransactionFinancialFacade
} from '@gtd/api-services/b2b-client';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AgenciesService } from '@gtd/services';
import { AgentAccountTransactionResourceService } from '@gtd/api-services/pricingsrv';
import { map, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'gtd-transaction-financial',
  templateUrl: './transaction-financial.component.html',
  styleUrls: ['./transaction-financial.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('600ms ease-out')),
      transition('default => rotated', animate('600ms ease-in'))
    ]),
    fadeIn,
    fadeInOut
  ]
})
export class TransactionFinancialComponent implements OnInit, OnDestroy {
  @Output() pageChange: EventEmitter<number>;

  appName = localStorage.getItem('appName');
  isLoading: boolean;
  reload = 'default';
  groupTransactions: GroupTransactions[] = null;
  filters: FilterFinancial;
  breadcrumbBars: BreadcrumbBar[] = [
    {
      name: 'global.homepage',
      isRouterLink: true,
      routerLink: '/'
    },
    {
      isRouterLink: false,
      name: 'navbar.financial'
    }
  ];
  numberFilterSelect: number;
  isLoadingSum: boolean;
  isOpenFilter: boolean;
  loaded$ = this.transactionFinancialFacade.loaded$;
  allTotalCount$ = this.transactionFinancialFacade.allTotalCount$;
  allTransactionFinancial$ = this.transactionFinancialFacade
    .allTransactionFinancial$;
  config: SwiperConfigInterface;
  configPagination: any;
  currentPage = 0;
  p = 1;
  sumTotalLoaded = false;
  sumTotalAgency$: Observable<any> = of(null);
  allGetHeaderSummary$ = this.getHeaderSummaryFacade.allGetHeaderSummary$;

  private subscription: Subscription = new Subscription();

  constructor(
    private _snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService,
    private transactionFinancialService: TransactionFinancialService,
    private transactionFinancialFacade: TransactionFinancialFacade,
    private agentAccountTransactionResourceService: AgentAccountTransactionResourceService,
    private agenciesService: AgenciesService,
    private getHeaderSummaryFacade: GetHeaderSummaryFacade
  ) {
    this.getHeaderSummaryFacade.loadAll();
  }

  ngOnInit() {
    this.agenciesService.getAgencies.subscribe(agencyInfo => {
      if (!agencyInfo) return;
      this.sumTotalAgency$ = this.agentAccountTransactionResourceService.sumTotalAgencyTransactionUsingGET(
        agencyInfo.agencyCode
      );
    });
    this.subscription.add(
      this.transactionFinancialFacade.allTransactionFinancial$
        .pipe(
          mergeMap(transactions =>
            this.sumTotalAgency$.pipe(
              map(sumTotal => {
                return { transactions, sumTotal };
              })
            )
          )
        )
        .subscribe(mergedData => {
          const { transactions, sumTotal } = mergedData;
          if (transactions && transactions.length > 0 && sumTotal) {
            this.isLoading = false;
            this.isOpenFilter = false;
            const transactionsGr = transactions.reduce(
              (groups, transaction) => {
                const date = moment(transaction.transDate).format('YYYY-MM-DD');
                const month = moment(transaction.transDate).format('YYYY-MM');
                if (!groups[month]) {
                  groups[month] = [];
                }
                groups[month].push(transaction);
                return groups;
              },
              {}
            );
            this.groupTransactions = Object.keys(transactionsGr).map(month => {
              return {
                month,
                transactions: transactionsGr[month],
                sumTotal: sumTotal.agentTransSumResList.find(
                  agentTransSum =>
                    moment(agentTransSum.transmonth).format('YYYY-MM') === month
                )
              };
            });
          } else {
            this.isLoading = false;
          }
        })
    );

    this.subscription.add(
      this.transactionFinancialFacade.allTotalCount$.subscribe(val => {
        this.configPagination = {
          id: 'foo',
          maxSize: this.deviceService.isDesktop() ? 8 : 5,
          itemsPerPage: DEFAULT_PAGINATION_OFFSET,
          currentPage: this.currentPage,
          totalItems: val
        };
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      })
    );

    this.subscription.add(
      this.transactionFinancialService.getDataFilters.subscribe(filters => {
        this.groupTransactions = [];
        if (filters) {
          this.filters = filters;
          this.isLoading = true;
          this.numberFilterSelect = this.getNumberFilter(filters.filter);
          this.transactionFinancialFacade.loadAll({
            filter: {
              agencyCode:
                filters.filter && filters.filter.agencyCode
                  ? filters.filter.agencyCode
                  : '',
              branchCode:
                filters.filter && filters.filter.branchCode
                  ? filters.filter.branchCode
                  : '',
              createdBy:
                filters.filter && filters.filter.createdBy
                  ? filters.filter.createdBy
                  : '',
              bookingRefNumber:
                filters.filter && filters.filter.bookingRefNumber
                  ? filters.filter.bookingRefNumber
                  : '',
              bookingNumber:
                filters.filter && filters.filter.bookingNumber
                  ? filters.filter.bookingNumber
                  : '',
              transDateFrom:
                filters.filter &&
                filters.filter.datePicker &&
                filters.filter.datePicker.fromDate
                  ? moment(filters.filter.datePicker.fromDate).format(
                      'DD/MM/YYYY'
                    )
                  : '',
              transDateTo:
                filters.filter &&
                filters.filter.datePicker &&
                filters.filter.datePicker.toDate
                  ? moment(filters.filter.datePicker.toDate).format(
                      'DD/MM/YYYY'
                    )
                  : '',
              amountFrom:
                filters.filter && filters.filter.amountFrom
                  ? filters.filter.amountFrom
                  : '',
              amountTo:
                filters.filter && filters.filter.amountTo
                  ? filters.filter.amountTo
                  : '',
              transType:
                filters.filter && filters.filter.transType
                  ? filters.filter.transType
                  : null
            },
            page: filters.page,
            size: filters.size,
            sort: filters.sort
          });
        } else {
          this.isLoading = true;
          this.transactionFinancialFacade.loadAll({
            page: DEFAULT_PAGINATION_PAGE,
            size: DEFAULT_PAGINATION_OFFSET
          });
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getNumberFilter(filters: FilterVM): number {
    let number = 0;
    if (filters) {
      if (filters.agencyCode) number++;
      if (filters.branchCode) number++;
      if (filters.createdBy) number++;
      if (filters.bookingRefNumber) number++;
      if (filters.transDateFrom) number++;
      if (filters.transDateTo) number++;
      if (filters.amountFrom) number++;
      if (filters.amountTo) number++;
      if (filters.transType) number++;
    }
    return number;
  }
  reloadBtn() {
    this.reload = this.reload === 'default' ? 'rotated' : 'default';
    this.numberFilterSelect = 0;
    this.transactionFinancialService.filterDestroy();
  }

  pageChanged(event: number) {
    this.isLoading = true;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.configPagination.currentPage = event;
    this.currentPage = event;
    if (event)
      this.transactionFinancialService.setDataFilters({
        ...this.filters,
        page: event - 1,
        size: DEFAULT_PAGINATION_OFFSET
      });
  }
  changeFilterSubmit(event: any) {
    this.isLoadingSum = true;
    this.configPagination.currentPage = 0;
  }
  btnUpdate() {
    this.transactionFinancialService.setDataFilters({
      ...this.filters,
      page: 0
    });
  }
}

export interface GroupTransactions {
  sumTotal: any;
  month: string;
  transactions: Array<AgencyFinancialDTO>;
}
