import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOOKUPB2B_FEATURE_KEY, LookupB2bState } from './lookup-b2b.reducer';

// Lookup the 'LookupB2b' feature state managed by NgRx
const getLookupB2bState = createFeatureSelector<LookupB2bState>(
  LOOKUPB2B_FEATURE_KEY
);

const getLoaded = createSelector(
  getLookupB2bState,
  (state: LookupB2bState) => state.loaded
);
const getError = createSelector(
  getLookupB2bState,
  (state: LookupB2bState) => state.error
);

const getAllLookupB2b = createSelector(
  getLookupB2bState,
  getLoaded,
  (state: LookupB2bState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getLookupB2bState,
  (state: LookupB2bState) => state.selectedId
);
const getSelectedLookupB2b = createSelector(
  getAllLookupB2b,
  getSelectedId,
  (lookupB2b, id) => {
    const result = lookupB2b.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const lookupB2bQuery = {
  getLoaded,
  getError,
  getAllLookupB2b,
  getSelectedLookupB2b
};
