import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  public unsubscribeSubject$ = new Subject<void>();
  public unsubscribe$ = this.unsubscribeSubject$.asObservable();
}
