import {
  FlightSearchAction,
  FlightSearchActionTypes
} from './flight-search.actions';
import { AirLowFareSearchResultVM } from '@gtd/b2c-client';

export const FLIGHTSEARCH_FEATURE_KEY = 'comboVJFlightSearch';

/**
 * Interface for the 'FlightSearch' data used in
 *  - FlightSearchState, and
 *  - flightSearchReducer
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FlightSearchState {
  data: AirLowFareSearchResultVM; // list of FlightSearch; analogous to a sql normalized table
  selectedId?: string | number; // which FlightSearch record has been selected
  loaded: boolean; // has the FlightSearch list been loaded
  error?: any; // last none error (if any)
}

export interface FlightSearchPartialState {
  readonly [FLIGHTSEARCH_FEATURE_KEY]: FlightSearchState;
}

export const initialState: FlightSearchState = {
  data: {},
  loaded: false
};

export function flightSearchReducer(
  state: FlightSearchState = initialState,
  action: FlightSearchAction
): FlightSearchState {
  switch (action.type) {
    case FlightSearchActionTypes.ComboVJFlightSearchLoaded: {
      state = {
        ...state,
        data: action.payload,
        loaded: true
      };
      break;
    }

    case FlightSearchActionTypes.ComboVJFlightSearchLoadError: {
      initialState.loaded = true;
      initialState.error = {
        code: action.payload.status,
        message: action.payload.message
      };
      state = initialState;
      break;
    }
    case FlightSearchActionTypes.ComboVJFlightSearchReset: {
      state = initialState;
      break;
    }
  }
  return state;
}
