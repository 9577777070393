/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PassengerName { 
    firstName?: string;
    lastName?: string;
    title?: PassengerName.TitleEnum;
}
export namespace PassengerName {
    export type TitleEnum = 'MALE' | 'FEMALE' | 'BOY' | 'GIRL' | 'INF';
    export const TitleEnum = {
        MALE: 'MALE' as TitleEnum,
        FEMALE: 'FEMALE' as TitleEnum,
        BOY: 'BOY' as TitleEnum,
        GIRL: 'GIRL' as TitleEnum,
        INF: 'INF' as TitleEnum
    };
}
