import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Observable } from 'rxjs/Observable';
import { Configuration } from '../configuration';

@Injectable()
export class TourContentService {
  protected basePath = 'https://www.gotadi.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }

  public getCMSContentUsingGET(search: string): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    queryParameters = queryParameters.set(
      'consumer_key',
      'ck_9e398b8572e3dd904af214f9a81ec77b01d0ff39'
    );
    queryParameters = queryParameters.set(
      'consumer_secret',
      'cs_635ee847a89cdac233718af2ae6b667b572dbc4d'
    );

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<any>(
      `${this.basePath}/tour/wp-json/wc/v3/products`,
      {
        params: queryParameters,
        withCredentials: false,
        headers: headers
      }
    );
  }
  public getDetailProductUsingGET(id: number): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    queryParameters = queryParameters.set(
      'consumer_key',
      'ck_9e398b8572e3dd904af214f9a81ec77b01d0ff39'
    );
    queryParameters = queryParameters.set(
      'consumer_secret',
      'cs_635ee847a89cdac233718af2ae6b667b572dbc4d'
    );

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<any>(
      `${this.basePath}/tour/wp-json/wc/v3/products/${id}`,
      {
        params: queryParameters,
        withCredentials: false,
        headers: headers
      }
    );
  }
  public getDetailPostUsingGET(id: number): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<any>(
      `${this.basePath}/tour/wp-json/wp/v2/posts/${id}`,
      {
        params: queryParameters,
        withCredentials: false,
        headers: headers
      }
    );
  }
}
