import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GETHEADERSUMMARY_FEATURE_KEY,
  GetHeaderSummaryState
} from './get-header-summary.reducer';

// Lookup the 'GetHeaderSummary' feature state managed by NgRx
const getGetHeaderSummaryState = createFeatureSelector<GetHeaderSummaryState>(
  GETHEADERSUMMARY_FEATURE_KEY
);

const getLoaded = createSelector(
  getGetHeaderSummaryState,
  (state: GetHeaderSummaryState) => state.loaded
);
const getError = createSelector(
  getGetHeaderSummaryState,
  (state: GetHeaderSummaryState) => state.error
);

const getAllGetHeaderSummary = createSelector(
  getGetHeaderSummaryState,
  getLoaded,
  (state: GetHeaderSummaryState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getGetHeaderSummaryState,
  (state: GetHeaderSummaryState) => state.selectedId
);
const getSelectedGetHeaderSummary = createSelector(
  getAllGetHeaderSummary,
  getSelectedId,
  (getHeaderSummary, id) => {
    return getHeaderSummary ? Object.assign({}, getHeaderSummary) : undefined;
  }
);

export const getHeaderSummaryQuery = {
  getLoaded,
  getError,
  getAllGetHeaderSummary,
  getSelectedGetHeaderSummary
};
