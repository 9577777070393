import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { GetHeaderSummaryPartialState } from './get-header-summary.reducer';
import {
  LoadGetHeaderSummary,
  GetHeaderSummaryLoadError,
  GetHeaderSummaryActionTypes
} from './get-header-summary.actions';
import {map} from "rxjs/operators";
import {DashboardResourceService} from "../../lib";

@Injectable()
export class GetHeaderSummaryEffects {
  @Effect() loadGetHeaderSummary$ = this.dataPersistence.fetch(
    GetHeaderSummaryActionTypes.LoadGetHeaderSummary,
    {
      run: (
        action: LoadGetHeaderSummary,
        state: GetHeaderSummaryPartialState
      ) => {
        return this.dashboardResourceService.getHeaderSummaryUsingGET().pipe(
          map(result => ({
            type: GetHeaderSummaryActionTypes.GetHeaderSummaryLoaded,
            payload: result,
          }))
        );
      },

      onError: (action: LoadGetHeaderSummary, error) => {
        console.error('Error', error);
        return new GetHeaderSummaryLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dashboardResourceService: DashboardResourceService,
    private dataPersistence: DataPersistence<GetHeaderSummaryPartialState>
  ) {}
}
