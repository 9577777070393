import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CredentialsService } from './credentials.service';
import { Observable, of } from 'rxjs';
import { AuthenticateFacade } from './+state/authenticate.facade';
import { LoginVM } from '@gtd/b2c-client';
import { isPlatformBrowser } from '@angular/common';

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  constructor(
    private credentialsService: CredentialsService,
    private authenticateFacade: AuthenticateFacade,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  getToken(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('token');
    }
  }

  isLoggedIn() {
    const token = this.getToken();
    return token != null;
  }
  isAuthenticated() {
    return this.credentialsService.isAuthenticated();
  }

  login(context: LoginVM) {
    this.authenticateFacade.loginAction(context);
  }

  storeAuthenticationToken(data: any) {
    const user = {
      username: data.username,
      token: data.token
    };
    this.credentialsService.setCredentials(user, data.remember);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout() {
    // Customize credentials invalidation here
    this.authenticateFacade.logOutAction();
    this.credentialsService.setCredentials();
    return true;
  }
}
