/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BookingNumbersRQ { 
    bookingNumbers?: Array<string>;
    ibeSessionId?: string;
    ignoreCache?: boolean;
    requesterCode?: string;
    requesterType?: BookingNumbersRQ.RequesterTypeEnum;
}
export namespace BookingNumbersRQ {
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
