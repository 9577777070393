import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { ComboSearchBestRatesFacade } from '../../../../../../state/search-best-rates/search-best-rates.facade';
import { fadeInOut } from '@gtd/helpers';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'gtd-open-sheet-swipe-hotel',
  templateUrl: './open-sheet-swipe-hotel.component.html',
  styleUrls: ['./open-sheet-swipe-hotel.component.scss'],
  animations: [fadeInOut]
})
export class OpenSheetSwipeHotelComponent implements OnInit {
  allSearchBestRates$ = this.searchBestRatesFacade.allSearchBestRates$;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<OpenSheetSwipeHotelComponent>,
    private searchBestRatesFacade: ComboSearchBestRatesFacade,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {}

  scrollToAmenities(el: HTMLElement) {
    if (isPlatformBrowser(this.platformId)) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  closeBottomSheet() {
    this._bottomSheetRef.dismiss();
  }
}
