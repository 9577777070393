import {
  TransactionFinancialAction,
  TransactionFinancialActionTypes
} from './transaction-financial.actions';
import { AgencyFinancialDTO } from '../../lib/model/models';

export const TRANSACTIONFINANCIAL_FEATURE_KEY = 'transactionFinancial';

/**
 * Interface for the 'TransactionFinancial' data used in
 *  - TransactionFinancialState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */
export interface FilterFinancial {
  filter?: FilterVM;
  page?: number;
  size?: number;
  sort?: Array<string>;
}
/* tslint:disable:no-empty-interface */
export interface FilterVM {
  agencyCode?: string;
  branchCode?: string;
  createdBy?: string;
  bookingRefNumber?: string;
  bookingNumber?: string;
  transDateFrom?: string;
  transDateTo?: string;
  datePicker?: any;
  amountFrom?: string;
  amountTo?: string;
  transType?:
    | 'DEPOSIT'
    | 'DEDUCT'
    | 'CREDIT'
    | 'REFUND'
    | 'TRANSACTION'
    | 'TICKET_TRANSACTION'
    | 'HOTEL_TRANSACTION'
    | 'COMBO_TRANSACTION'
    | 'TOUR_TRANSACTION'
    | 'TRACKING_TRANSACTION'
    | 'OTHER';
}
export interface Entity {}

export interface TransactionFinancialState {
  list: Array<AgencyFinancialDTO>; // list of TransactionFinancial; analogous to a sql normalized table
  selectedId?: string | number; // which TransactionFinancial record has been selected
  loaded: boolean; // has the TransactionFinancial list been loaded
  error?: any; // last none error (if any)
  totalCount?: any; // last none error (if any)
}

export interface TransactionFinancialPartialState {
  readonly [TRANSACTIONFINANCIAL_FEATURE_KEY]: TransactionFinancialState;
}

export const initialStateFinancial: TransactionFinancialState = {
  list: [],
  totalCount: null,
  loaded: false
};

export function reducerTransaction(
  state: TransactionFinancialState = initialStateFinancial,
  action: TransactionFinancialAction
): TransactionFinancialState {
  switch (action.type) {
    case TransactionFinancialActionTypes.TransactionFinancialLoaded: {
      state = {
        ...state,
        list: action.payload.body,
        loaded: true,
        totalCount: action.payload.headers.get('X-Total-Count')
      };
      break;
    }
    case TransactionFinancialActionTypes.DestroyTransactionFinancial: {
      state = initialStateFinancial;
      break;
    }
  }
  return state;
}
