/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { PageOfPromotionDTO } from '../model/pageOfPromotionDTO';
import { PromotionDTO } from '../model/promotionDTO';
import { PromotionPriorityDTO } from '../model/promotionPriorityDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PromotionResourceService {

    protected basePath = 'https://uat-b2b.gotadi.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changePriority
     *
     * @param promotionPriorityDTO promotionPriorityDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePriorityUsingPUT3(promotionPriorityDTO: PromotionPriorityDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public changePriorityUsingPUT3(promotionPriorityDTO: PromotionPriorityDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public changePriorityUsingPUT3(promotionPriorityDTO: PromotionPriorityDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public changePriorityUsingPUT3(promotionPriorityDTO: PromotionPriorityDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (promotionPriorityDTO === null || promotionPriorityDTO === undefined) {
            throw new Error('Required parameter promotionPriorityDTO was null or undefined when calling changePriorityUsingPUT3.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<any>>(`${this.basePath}/api/change-priority/promotion`,
            promotionPriorityDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * clone
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneUsingPUT(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public cloneUsingPUT(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public cloneUsingPUT(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public cloneUsingPUT(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling cloneUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<Array<any>>(`${this.basePath}/api/clone/promotion`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createPromotionCreditCard
     *
     * @param promotionDTO promotionDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPromotionCreditCardUsingPOST(promotionDTO: PromotionDTO, observe?: 'body', reportProgress?: boolean): Observable<PromotionDTO>;
    public createPromotionCreditCardUsingPOST(promotionDTO: PromotionDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionDTO>>;
    public createPromotionCreditCardUsingPOST(promotionDTO: PromotionDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionDTO>>;
    public createPromotionCreditCardUsingPOST(promotionDTO: PromotionDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (promotionDTO === null || promotionDTO === undefined) {
            throw new Error('Required parameter promotionDTO was null or undefined when calling createPromotionCreditCardUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PromotionDTO>(`${this.basePath}/api/promotion`,
            promotionDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPromotionCreditCard
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPromotionCreditCardUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<PromotionDTO>;
    public getPromotionCreditCardUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionDTO>>;
    public getPromotionCreditCardUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionDTO>>;
    public getPromotionCreditCardUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPromotionCreditCardUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PromotionDTO>(`${this.basePath}/api/promotion/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * publish
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishUsingPUT(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public publishUsingPUT(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public publishUsingPUT(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public publishUsingPUT(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling publishUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<Array<any>>(`${this.basePath}/api/publish/promotion`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjects
     *
     * @param status status
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param name name
     * @param description description
     * @param saleChannel saleChannel
     * @param productType productType
     * @param effectiveFrom effectiveFrom
     * @param effectiveTo effectiveTo
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectsUsingGET8(status?: string, createdDateFrom?: string, createdDateTo?: string, name?: string, description?: string, saleChannel?: string, productType?: string, effectiveFrom?: string, effectiveTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageOfPromotionDTO>;
    public searchObjectsUsingGET8(status?: string, createdDateFrom?: string, createdDateTo?: string, name?: string, description?: string, saleChannel?: string, productType?: string, effectiveFrom?: string, effectiveTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfPromotionDTO>>;
    public searchObjectsUsingGET8(status?: string, createdDateFrom?: string, createdDateTo?: string, name?: string, description?: string, saleChannel?: string, productType?: string, effectiveFrom?: string, effectiveTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfPromotionDTO>>;
    public searchObjectsUsingGET8(status?: string, createdDateFrom?: string, createdDateTo?: string, name?: string, description?: string, saleChannel?: string, productType?: string, effectiveFrom?: string, effectiveTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (saleChannel !== undefined && saleChannel !== null) {
            queryParameters = queryParameters.set('saleChannel', <any>saleChannel);
        }
        if (productType !== undefined && productType !== null) {
            queryParameters = queryParameters.set('productType', <any>productType);
        }
        if (effectiveFrom !== undefined && effectiveFrom !== null) {
            queryParameters = queryParameters.set('effectiveFrom', <any>effectiveFrom);
        }
        if (effectiveTo !== undefined && effectiveTo !== null) {
            queryParameters = queryParameters.set('effectiveTo', <any>effectiveTo);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PageOfPromotionDTO>(`${this.basePath}/api/_search/promotion`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * terminate
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public terminateUsingPUT(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public terminateUsingPUT(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public terminateUsingPUT(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public terminateUsingPUT(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling terminateUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<Array<any>>(`${this.basePath}/api/terminate/promotion`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updatePromotionCreditCard
     *
     * @param promotionDTO promotionDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePromotionCreditCardUsingPUT(promotionDTO: PromotionDTO, observe?: 'body', reportProgress?: boolean): Observable<PromotionDTO>;
    public updatePromotionCreditCardUsingPUT(promotionDTO: PromotionDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionDTO>>;
    public updatePromotionCreditCardUsingPUT(promotionDTO: PromotionDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionDTO>>;
    public updatePromotionCreditCardUsingPUT(promotionDTO: PromotionDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (promotionDTO === null || promotionDTO === undefined) {
            throw new Error('Required parameter promotionDTO was null or undefined when calling updatePromotionCreditCardUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PromotionDTO>(`${this.basePath}/api/promotion`,
            promotionDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
