/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TemplatepartsIdBody { 
    /**
     * Unique slug identifying the template.
     */
    slug?: string;
    /**
     * Theme identifier for the template.
     */
    theme?: string;
    /**
     * Type of template.
     */
    type?: string;
    /**
     * Content of template.
     */
    content?: string;
    /**
     * Title of template.
     */
    title?: string;
    /**
     * Description of template.
     */
    description?: string;
    /**
     * Status of template.
     */
    status?: Array<TemplatepartsIdBody.StatusEnum>;
    /**
     * The ID for the author of the template.
     */
    author?: string;
    /**
     * Where the template part is intended for use (header, footer, etc.)
     */
    area?: string;
    /**
     * Expose advanced custom fields.
     */
    acf?: string;
}
export namespace TemplatepartsIdBody {
    export type StatusEnum = 'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled';
    export const StatusEnum = {
        Publish: 'publish' as StatusEnum,
        Future: 'future' as StatusEnum,
        Draft: 'draft' as StatusEnum,
        Pending: 'pending' as StatusEnum,
        Private: 'private' as StatusEnum,
        AcfDisabled: 'acf-disabled' as StatusEnum
    };
}