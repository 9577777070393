import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'panelDate'
})
export class PanelDateComponent extends DatePipe implements PipeTransform {
  transform(value: any, format?: 'dd|dd/MM|dd/mm/yyyy|yyyy-MM-dd'): any {
    let dateSplit = value.split("-");
    if(dateSplit.length<2) {
      dateSplit = value.split("/");
    }
    if(dateSplit[0], dateSplit[1], dateSplit[2]) {
      let newDate = new Date();
      newDate.setFullYear(dateSplit[2], dateSplit[1], dateSplit[0]);
      return super.transform(newDate, format);
    }
    return null;
  }
}
