import { Pipe, PipeTransform } from '@angular/core';

export interface BranchFilter {
  brandLogo?: string;
  lowestPrices?: number;
  branchCode?: string;
  branchName?: string;
  checked: boolean;
}

@Pipe({
  name: 'string2BranchMapper'
})
export class String2BranchMapperPipe implements PipeTransform {
  transform(value: string[], args?: any): BranchFilter[] {
    const result: BranchFilter[] = [];
    value.forEach((item: string) => {
      const arr = item.split(':');
      const branchFilterItem: BranchFilter = {
        branchCode: arr[0],
        branchName: arr[1],
        lowestPrices: +arr[2],
        checked: false
      };
      switch (arr[0]) {
        case 'BL':
          branchFilterItem.brandLogo =
            'https://www.gotadi.com/content/images/jetstar_logo-897848bc9d.svg';
          break;
        case 'VJ':
          branchFilterItem.brandLogo =
            'https://www.gotadi.com/content/images/vietjet-4ead77cfeb.svg';
          break;
        case 'VN':
          branchFilterItem.brandLogo =
            'https://www.gotadi.com/content/images/VN-b907117ac7.png';
          break;
        case 'QH':
          branchFilterItem.brandLogo =
            'https://www.gotadi.com/content/images/QH-34ee27f96b.gif';
      }
      result.push(branchFilterItem);
    });
    return result;
  }
}
