import { OtaResultOfBooking } from '@gtd/b2c-client';
import {
  TourCreateDraftBookingAction,
  TourCreateDraftBookingActionTypes
} from './tour-create-draft-booking.actions';

export const TOURCREATEDRAFTBOOKING_FEATURE_KEY = 'tourCreateDraftBooking';

/**
 * Interface for the 'TourCreateDraftBooking' data used in
 *  - TourCreateDraftBookingState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface TourCreateDraftBookingState {
  list: OtaResultOfBooking; // list of TourCreateDraftBooking; analogous to a sql normalized table
  selectedId?: string | number; // which TourCreateDraftBooking record has been selected
  loaded: boolean; // has the TourCreateDraftBooking list been loaded
  error?: any; // last none error (if any)
}

export interface TourCreateDraftBookingPartialState {
  readonly [TOURCREATEDRAFTBOOKING_FEATURE_KEY]: TourCreateDraftBookingState;
}

export const initialState: TourCreateDraftBookingState = {
  list: null,
  loaded: false
};

export function reducer(
  state: TourCreateDraftBookingState = initialState,
  action: TourCreateDraftBookingAction
): TourCreateDraftBookingState {
  switch (action.type) {
    case TourCreateDraftBookingActionTypes.TourCreateDraftBookingLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case TourCreateDraftBookingActionTypes.TourCreateDraftBookingLoadError: {
      state = {
        ...state,
        error: action.payload,
        loaded: true
      };
      break;
    }
    case TourCreateDraftBookingActionTypes.ResetTourCreateDraftBooking: {
      state = {
        ...initialState
      };
      break;
    }
  }
  return state;
}
