import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';

@Component({
  selector: 'gtd-search-informations',
  templateUrl: './search-informations.component.html',
  styleUrls: ['./search-informations.component.scss']
})
export class SearchInformationsComponent implements OnInit {
  @Input() searchParams: any;
  isCollapsed: boolean;
  panelOpenState: boolean;

  constructor() { }

  ngOnInit() {
  }

}
@Pipe({
  name: 'quantity'
})
export class QuantityPassenger implements PipeTransform {
  transform(rooms: Array<any>, type: 'adult'|'child'): number {
    let totalQuantity = 0;
    switch (type) {
      case 'adult':
        totalQuantity = this.getAdult(rooms);
        break;
      case 'child':
        totalQuantity = this.getChild(rooms);
        break;
    }
    return totalQuantity;
  }
  getAdult(rooms: Array<any>): number {
    let adultQuantity = 0;
    rooms.forEach(room => {
      adultQuantity += room.adultQuantity;
    })
    return adultQuantity;
  }
  getChild(rooms: Array<any>): number {
    let childQuantity = 0;
    rooms.forEach(room => {
      childQuantity += room.childQuantity;
    })
    return childQuantity;
  }
}
