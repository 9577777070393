import {
  PromotionSectionStateAction,
  PromotionSectionStateActionTypes
} from './promotion-section-state.actions';
import {BannerDTO} from "@gtd/b2c-client";

export const PROMOTIONSECTIONSTATE_FEATURE_KEY = 'promotionSectionState';

/**
 * Interface for the 'PromotionSectionState' data used in
 *  - PromotionSectionStateState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}
export interface PromotionSectionStateState {
  result: Array<BannerDTO>; // list of PromotionSectionState; analogous to a sql normalized table
  selectedId?: string | number; // which PromotionSectionState record has been selected
  loaded: boolean; // has the PromotionSectionState list been loaded
  error?: any; // last none error (if any)
}

export interface PromotionSectionStatePartialState {
  readonly [PROMOTIONSECTIONSTATE_FEATURE_KEY]: PromotionSectionStateState;
}

export const initialState: PromotionSectionStateState = {
  result: null,
  loaded: false
};

export function reducer(
  state: PromotionSectionStateState = initialState,
  action: PromotionSectionStateAction
): PromotionSectionStateState {
  switch (action.type) {
    case PromotionSectionStateActionTypes.PromotionSectionStateLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
