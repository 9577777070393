/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OfflineBooking } from './offlineBooking';
import { OfflineBookingRequest } from './offlineBookingRequest';


export interface OfflineBookingRQ { 
    ibeSessionId?: string;
    offlineBooking?: OfflineBooking;
    offlineBookingRequest?: OfflineBookingRequest;
    requesterCode?: string;
    requesterType?: OfflineBookingRQ.RequesterTypeEnum;
}
export namespace OfflineBookingRQ {
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
