import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AvatarPartialState } from './avatar.reducer';
import {
  LoadAvatar,
  AvatarLoaded,
  AvatarLoadError,
  AvatarActionTypes
} from './avatar.actions';
import {map} from "rxjs/operators";
import {CustomerAvatarResourceService} from "@gtd/api-services/customer-service";

@Injectable()
export class AvatarEffects {
  @Effect() loadAvatar$ = this.dataPersistence.fetch(
    AvatarActionTypes.LoadAvatar,
    {
      run: (action: LoadAvatar, state: AvatarPartialState) => {
        return this.customerAvatarResourceService.getCustomerAvatarByProfileIdUsingGET(action.payload).pipe(
          map(update => ({
            type: AvatarActionTypes.AvatarLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadAvatar, error) => {
        console.error('Error', error);
        return new AvatarLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private customerAvatarResourceService: CustomerAvatarResourceService,
    private dataPersistence: DataPersistence<AvatarPartialState>
  ) {}
}
