import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AvailableFlightsPartialState } from './available-flights.reducer';
import { availableFlightsQuery } from './available-flights.selectors';
import {
  FilterFlightsAvailable,
  LoadAvailableFlights,
  LoadInternationnalFlights,
  ResetAvailableFlights,
  SelectAvailableFlights
} from './available-flights.actions';

@Injectable()
export class AvailableFlightsFacade {
  loaded$ = this.store.pipe(select(availableFlightsQuery.getLoaded));
  allAvailableFlights$ = this.store.pipe(
    select(availableFlightsQuery.getAllAvailableFlights)
  );
  selectedAvailableFlights$ = this.store.pipe(
    select(availableFlightsQuery.getSelectedAvailableFlights)
  );

  constructor(private store: Store<AvailableFlightsPartialState>) {}
   
  loadAll(filter: FilterFlightsAvailable) {
    this.store.dispatch(new LoadAvailableFlights(filter));
  }
  loadInternationalFlights(filter){
    this.store.dispatch(new LoadInternationnalFlights(filter))
  }
  selectedFlight(id: string) {
    this.store.dispatch(new SelectAvailableFlights(id));
  }

  resetAll() {
    this.store.dispatch(new ResetAvailableFlights());
  }
}
