import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { BannerPartialState } from './banner.reducer';
import { bannerQuery } from './banner.selectors';
import { LoadBanner } from './banner.actions';

@Injectable()
export class BannerFacade {
  loaded$ = this.store.pipe(select(bannerQuery.getLoaded));
  allBanner$ = this.store.pipe(select(bannerQuery.getAllBanner));
  selectedBanner$ = this.store.pipe(select(bannerQuery.getSelectedBanner));

  constructor(private store: Store<BannerPartialState>) {}

  loadAll(payload: { bannerId: number; deviceId: number; type: string }) {
    this.store.dispatch(new LoadBanner(payload));
  }
}
