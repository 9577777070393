import {Component, Input, OnInit} from '@angular/core';
import {environment} from "@env/environment";

@Component({
  selector: 'gtd-hotel-loading-item',
  templateUrl: './hotel-loading-item.component.html',
  styleUrls: ['./hotel-loading-item.component.scss']
})
export class HotelLoadingItemComponent implements OnInit {
  @Input() isLoading: boolean;
  appName = environment.appName;
  counter = Array;
  constructor() { }

  ngOnInit() {
  }

}
