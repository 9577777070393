/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AgencyFinancialDTO { 
    accountCode?: string;
    accountId?: number;
    accountName?: string;
    agencyCode?: string;
    agentCode?: string;
    amount?: number;
    balance?: number;
    bookingNumber?: string;
    bookingRefNumber?: string;
    branchCode?: string;
    createdBy?: string;
    createdDate?: Date;
    id?: number;
    itineraryNo?: string;
    lastBalance?: number;
    lastTransCode?: string;
    lastTransDate?: Date;
    paymentType?: string;
    reason?: string;
    receiptNo?: string;
    transCode?: string;
    transDate?: Date;
    transToken?: string;
    transType?: AgencyFinancialDTO.TransTypeEnum;
}
export namespace AgencyFinancialDTO {
    export type TransTypeEnum = 'DEPOSIT' | 'DEDUCT' | 'CREDIT' | 'REFUND' | 'TRANSACTION' | 'TICKET_TRANSACTION' | 'HOTEL_TRANSACTION' | 'TOUR_TRANSACTION' | 'TRACKING_TRANSACTION' | 'OTHER';
    export const TransTypeEnum = {
        DEPOSIT: 'DEPOSIT' as TransTypeEnum,
        DEDUCT: 'DEDUCT' as TransTypeEnum,
        CREDIT: 'CREDIT' as TransTypeEnum,
        REFUND: 'REFUND' as TransTypeEnum,
        TRANSACTION: 'TRANSACTION' as TransTypeEnum,
        TICKETTRANSACTION: 'TICKET_TRANSACTION' as TransTypeEnum,
        HOTELTRANSACTION: 'HOTEL_TRANSACTION' as TransTypeEnum,
        TOURTRANSACTION: 'TOUR_TRANSACTION' as TransTypeEnum,
        TRACKINGTRANSACTION: 'TRACKING_TRANSACTION' as TransTypeEnum,
        OTHER: 'OTHER' as TransTypeEnum
    };
}
