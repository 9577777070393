import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTSEARCH_FEATURE_KEY,
  FlightSearchState
} from './flight-search.reducer';

// Lookup the 'FlightSearch' feature state managed by NgRx
const getFlightSearchState = createFeatureSelector<FlightSearchState>(
  FLIGHTSEARCH_FEATURE_KEY
);

const getLoaded = createSelector(
  getFlightSearchState,
  (state: FlightSearchState) => state.loaded
);
const getError = createSelector(
  getFlightSearchState,
  (state: FlightSearchState) => state.error
);

const getAllFlightSearch = createSelector(
  getFlightSearchState,
  getLoaded,
  (state: FlightSearchState, isLoaded) => {
    return isLoaded ? state.data : {};
  }
);
const getSelectedId = createSelector(
  getFlightSearchState,
  (state: FlightSearchState) => state.selectedId
);
const getSelectedFlightSearch = createSelector(
  getAllFlightSearch,
  getSelectedId,
  (flightSearch, id) => {
    const result = flightSearch['groupPricedItineraries'].find(
      it => it['id'] === id
    );
    return result ? Object.assign({}, result) : undefined;
  }
);

export const flightSearchQuery = {
  getLoaded,
  getError,
  getAllFlightSearch,
  getSelectedFlightSearch
};
