/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { GroupBooking } from '../model/groupBooking';
import { OtaResultOfListOfTravelConditionDTO } from '../model/otaResultOfListOfTravelConditionDTO';
import { OtaResultOfListOfTravelPolicyDTO } from '../model/otaResultOfListOfTravelPolicyDTO';
import { OtaResultOfMapOfintAndboolean } from '../model/otaResultOfMapOfintAndboolean';
import { OtaResultOfTravelConditionDTO } from '../model/otaResultOfTravelConditionDTO';
import { OtaResultOfTravelPolicyDTO } from '../model/otaResultOfTravelPolicyDTO';
import { OtaResultOfTravelPolicyWrapper } from '../model/otaResultOfTravelPolicyWrapper';
import { PageOfTravelPolicyDTO } from '../model/pageOfTravelPolicyDTO';
import { PageOfTravelPolicyWrapper } from '../model/pageOfTravelPolicyWrapper';
import { TravelConditionDTO } from '../model/travelConditionDTO';
import { TravelPolicyDTO } from '../model/travelPolicyDTO';
import { TravelPolicyWrapper } from '../model/travelPolicyWrapper';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class TravelPolicyResourceService {
  protected basePath = 'https://10.7.71.50:8182';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * checkPolicy
   *
   * @param groupBooking groupBooking
   * @param policyId policyId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkPolicyUsingPOST(
    groupBooking: GroupBooking,
    policyId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfMapOfintAndboolean>;
  public checkPolicyUsingPOST(
    groupBooking: GroupBooking,
    policyId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfMapOfintAndboolean>>;
  public checkPolicyUsingPOST(
    groupBooking: GroupBooking,
    policyId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfMapOfintAndboolean>>;
  public checkPolicyUsingPOST(
    groupBooking: GroupBooking,
    policyId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (groupBooking === null || groupBooking === undefined) {
      throw new Error(
        'Required parameter groupBooking was null or undefined when calling checkPolicyUsingPOST.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling checkPolicyUsingPOST.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (policyId !== undefined && policyId !== null) {
      queryParameters = queryParameters.set('policyId', <any>policyId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<OtaResultOfMapOfintAndboolean>(
      `${this.basePath}/api/travel-policy/check-policy`,
      groupBooking,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * deleteCondition
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteConditionUsingDELETE(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteConditionUsingDELETE(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteConditionUsingDELETE(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteConditionUsingDELETE(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteConditionUsingDELETE.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.delete<any>(
      `${this.basePath}/api/travel-policy/travel-condition/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * deletePolicy
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePolicyUsingDELETE(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deletePolicyUsingDELETE(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deletePolicyUsingDELETE(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deletePolicyUsingDELETE(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deletePolicyUsingDELETE.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.delete<any>(
      `${this.basePath}/api/travel-policy/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * findPolicyById
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findPolicyByIdUsingGET(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfTravelPolicyWrapper>;
  public findPolicyByIdUsingGET(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfTravelPolicyWrapper>>;
  public findPolicyByIdUsingGET(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfTravelPolicyWrapper>>;
  public findPolicyByIdUsingGET(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling findPolicyByIdUsingGET.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfTravelPolicyWrapper>(
      `${this.basePath}/api/travel-policy/wrapper/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * findPolicy
   *
   * @param ids ids
   * @param agencyCodes agencyCodes
   * @param names names
   * @param travelers travelers
   * @param applyDateFrom applyDateFrom
   * @param applyDateTo applyDateTo
   * @param createdDateFrom createdDateFrom
   * @param createdDateTo createdDateTo
   * @param expiredDateFrom expiredDateFrom
   * @param expiredDateTo expiredDateTo
   * @param creatorCodes creatorCodes
   * @param enable enable
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findPolicyUsingGET(
    ids?: Array<string>,
    agencyCodes?: Array<string>,
    names?: Array<string>,
    travelers?: Array<string>,
    applyDateFrom?: Date,
    applyDateTo?: Date,
    createdDateFrom?: Date,
    createdDateTo?: Date,
    expiredDateFrom?: Date,
    expiredDateTo?: Date,
    creatorCodes?: Array<string>,
    enable?: boolean,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PageOfTravelPolicyDTO>;
  public findPolicyUsingGET(
    ids?: Array<string>,
    agencyCodes?: Array<string>,
    names?: Array<string>,
    travelers?: Array<string>,
    applyDateFrom?: Date,
    applyDateTo?: Date,
    createdDateFrom?: Date,
    createdDateTo?: Date,
    expiredDateFrom?: Date,
    expiredDateTo?: Date,
    creatorCodes?: Array<string>,
    enable?: boolean,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PageOfTravelPolicyDTO>>;
  public findPolicyUsingGET(
    ids?: Array<string>,
    agencyCodes?: Array<string>,
    names?: Array<string>,
    travelers?: Array<string>,
    applyDateFrom?: Date,
    applyDateTo?: Date,
    createdDateFrom?: Date,
    createdDateTo?: Date,
    expiredDateFrom?: Date,
    expiredDateTo?: Date,
    creatorCodes?: Array<string>,
    enable?: boolean,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PageOfTravelPolicyDTO>>;
  public findPolicyUsingGET(
    ids?: Array<string>,
    agencyCodes?: Array<string>,
    names?: Array<string>,
    travelers?: Array<string>,
    applyDateFrom?: Date,
    applyDateTo?: Date,
    createdDateFrom?: Date,
    createdDateTo?: Date,
    expiredDateFrom?: Date,
    expiredDateTo?: Date,
    creatorCodes?: Array<string>,
    enable?: boolean,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (ids) {
      ids.forEach(element => {
        queryParameters = queryParameters.append('ids', <any>element);
      });
    }
    if (agencyCodes) {
      agencyCodes.forEach(element => {
        queryParameters = queryParameters.append('agencyCodes', <any>element);
      });
    }
    if (names) {
      names.forEach(element => {
        queryParameters = queryParameters.append('names', <any>element);
      });
    }
    if (travelers) {
      travelers.forEach(element => {
        queryParameters = queryParameters.append('travelers', <any>element);
      });
    }
    if (applyDateFrom !== undefined && applyDateFrom !== null) {
      queryParameters = queryParameters.set('applyDateFrom', <any>(
        applyDateFrom.toISOString()
      ));
    }
    if (applyDateTo !== undefined && applyDateTo !== null) {
      queryParameters = queryParameters.set('applyDateTo', <any>(
        applyDateTo.toISOString()
      ));
    }
    if (createdDateFrom !== undefined && createdDateFrom !== null) {
      queryParameters = queryParameters.set('createdDateFrom', <any>(
        createdDateFrom.toISOString()
      ));
    }
    if (createdDateTo !== undefined && createdDateTo !== null) {
      queryParameters = queryParameters.set('createdDateTo', <any>(
        createdDateTo.toISOString()
      ));
    }
    if (expiredDateFrom !== undefined && expiredDateFrom !== null) {
      queryParameters = queryParameters.set('expiredDateFrom', <any>(
        expiredDateFrom.toISOString()
      ));
    }
    if (expiredDateTo !== undefined && expiredDateTo !== null) {
      queryParameters = queryParameters.set('expiredDateTo', <any>(
        expiredDateTo.toISOString()
      ));
    }
    if (creatorCodes) {
      creatorCodes.forEach(element => {
        queryParameters = queryParameters.append('creatorCodes', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters = queryParameters.set('enable', <any>enable);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<PageOfTravelPolicyDTO>(
      `${this.basePath}/api/travel-policy/find`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * findPolicyWithConditions
   *
   * @param ids ids
   * @param agencyCodes agencyCodes
   * @param names names
   * @param travelers travelers
   * @param applyDateFrom applyDateFrom
   * @param applyDateTo applyDateTo
   * @param createdDateFrom createdDateFrom
   * @param createdDateTo createdDateTo
   * @param expiredDateFrom expiredDateFrom
   * @param expiredDateTo expiredDateTo
   * @param creatorCodes creatorCodes
   * @param enable enable
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findPolicyWithConditionsUsingGET(
    ids?: Array<string>,
    agencyCodes?: Array<string>,
    names?: Array<string>,
    travelers?: Array<string>,
    applyDateFrom?: Date,
    applyDateTo?: Date,
    createdDateFrom?: Date,
    createdDateTo?: Date,
    expiredDateFrom?: Date,
    expiredDateTo?: Date,
    creatorCodes?: Array<string>,
    enable?: boolean,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PageOfTravelPolicyWrapper>;
  public findPolicyWithConditionsUsingGET(
    ids?: Array<string>,
    agencyCodes?: Array<string>,
    names?: Array<string>,
    travelers?: Array<string>,
    applyDateFrom?: Date,
    applyDateTo?: Date,
    createdDateFrom?: Date,
    createdDateTo?: Date,
    expiredDateFrom?: Date,
    expiredDateTo?: Date,
    creatorCodes?: Array<string>,
    enable?: boolean,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PageOfTravelPolicyWrapper>>;
  public findPolicyWithConditionsUsingGET(
    ids?: Array<string>,
    agencyCodes?: Array<string>,
    names?: Array<string>,
    travelers?: Array<string>,
    applyDateFrom?: Date,
    applyDateTo?: Date,
    createdDateFrom?: Date,
    createdDateTo?: Date,
    expiredDateFrom?: Date,
    expiredDateTo?: Date,
    creatorCodes?: Array<string>,
    enable?: boolean,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PageOfTravelPolicyWrapper>>;
  public findPolicyWithConditionsUsingGET(
    ids?: Array<string>,
    agencyCodes?: Array<string>,
    names?: Array<string>,
    travelers?: Array<string>,
    applyDateFrom?: Date,
    applyDateTo?: Date,
    createdDateFrom?: Date,
    createdDateTo?: Date,
    expiredDateFrom?: Date,
    expiredDateTo?: Date,
    creatorCodes?: Array<string>,
    enable?: boolean,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (ids) {
      ids.forEach(element => {
        queryParameters = queryParameters.append('ids', <any>element);
      });
    }
    if (agencyCodes) {
      agencyCodes.forEach(element => {
        queryParameters = queryParameters.append('agencyCodes', <any>element);
      });
    }
    if (names) {
      names.forEach(element => {
        queryParameters = queryParameters.append('names', <any>element);
      });
    }
    if (travelers) {
      travelers.forEach(element => {
        queryParameters = queryParameters.append('travelers', <any>element);
      });
    }
    if (applyDateFrom !== undefined && applyDateFrom !== null) {
      queryParameters = queryParameters.set('applyDateFrom', <any>(
        applyDateFrom.toISOString()
      ));
    }
    if (applyDateTo !== undefined && applyDateTo !== null) {
      queryParameters = queryParameters.set('applyDateTo', <any>(
        applyDateTo.toISOString()
      ));
    }
    if (createdDateFrom !== undefined && createdDateFrom !== null) {
      queryParameters = queryParameters.set('createdDateFrom', <any>(
        createdDateFrom.toISOString()
      ));
    }
    if (createdDateTo !== undefined && createdDateTo !== null) {
      queryParameters = queryParameters.set('createdDateTo', <any>(
        createdDateTo.toISOString()
      ));
    }
    if (expiredDateFrom !== undefined && expiredDateFrom !== null) {
      queryParameters = queryParameters.set('expiredDateFrom', <any>(
        expiredDateFrom.toISOString()
      ));
    }
    if (expiredDateTo !== undefined && expiredDateTo !== null) {
      queryParameters = queryParameters.set('expiredDateTo', <any>(
        expiredDateTo.toISOString()
      ));
    }
    if (creatorCodes) {
      creatorCodes.forEach(element => {
        queryParameters = queryParameters.append('creatorCodes', <any>element);
      });
    }
    if (enable !== undefined && enable !== null) {
      queryParameters = queryParameters.set('enable', <any>enable);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<PageOfTravelPolicyWrapper>(
      `${this.basePath}/api/travel-policy/wrapper-find`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getPolicyDetailWithConditions
   *
   * @param policyId policy_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPolicyDetailWithConditionsUsingGET(
    policyId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfTravelPolicyWrapper>;
  public getPolicyDetailWithConditionsUsingGET(
    policyId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfTravelPolicyWrapper>>;
  public getPolicyDetailWithConditionsUsingGET(
    policyId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfTravelPolicyWrapper>>;
  public getPolicyDetailWithConditionsUsingGET(
    policyId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling getPolicyDetailWithConditionsUsingGET.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfTravelPolicyWrapper>(
      `${this.basePath}/api/travel-policy/${encodeURIComponent(
        String(policyId)
      )}/travel-policy-detail`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * listCondition
   *
   * @param policyId policy_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listConditionUsingGET(
    policyId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfListOfTravelConditionDTO>;
  public listConditionUsingGET(
    policyId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfListOfTravelConditionDTO>>;
  public listConditionUsingGET(
    policyId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfListOfTravelConditionDTO>>;
  public listConditionUsingGET(
    policyId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling listConditionUsingGET.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfListOfTravelConditionDTO>(
      `${this.basePath}/api/travel-policy/${encodeURIComponent(
        String(policyId)
      )}/travel-condition`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * listPolicy
   *
   * @param agencyCode agencyCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listPolicyUsingGET(
    agencyCode?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfListOfTravelPolicyDTO>;
  public listPolicyUsingGET(
    agencyCode?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfListOfTravelPolicyDTO>>;
  public listPolicyUsingGET(
    agencyCode?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfListOfTravelPolicyDTO>>;
  public listPolicyUsingGET(
    agencyCode?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfListOfTravelPolicyDTO>(
      `${this.basePath}/api/travel-policy`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * pageQueryPolicy
   *
   * @param agencyCode agencyCode
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pageQueryPolicyUsingGET(
    agencyCode?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PageOfTravelPolicyDTO>;
  public pageQueryPolicyUsingGET(
    agencyCode?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PageOfTravelPolicyDTO>>;
  public pageQueryPolicyUsingGET(
    agencyCode?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PageOfTravelPolicyDTO>>;
  public pageQueryPolicyUsingGET(
    agencyCode?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<PageOfTravelPolicyDTO>(
      `${this.basePath}/api/travel-policy/page-query`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * pageQueryTravelPolicyWrapper
   *
   * @param agencyCode agencyCode
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public pageQueryTravelPolicyWrapperUsingGET(
    agencyCode?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PageOfTravelPolicyWrapper>;
  public pageQueryTravelPolicyWrapperUsingGET(
    agencyCode?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PageOfTravelPolicyWrapper>>;
  public pageQueryTravelPolicyWrapperUsingGET(
    agencyCode?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PageOfTravelPolicyWrapper>>;
  public pageQueryTravelPolicyWrapperUsingGET(
    agencyCode?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<PageOfTravelPolicyWrapper>(
      `${this.basePath}/api/travel-policy/page-query-TravelPolicyWrapper`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * saveAllPolicy
   *
   * @param travelPolicyDTO travelPolicyDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveAllPolicyUsingPOST(
    travelPolicyDTO: Array<TravelPolicyDTO>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfListOfTravelPolicyDTO>;
  public saveAllPolicyUsingPOST(
    travelPolicyDTO: Array<TravelPolicyDTO>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfListOfTravelPolicyDTO>>;
  public saveAllPolicyUsingPOST(
    travelPolicyDTO: Array<TravelPolicyDTO>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfListOfTravelPolicyDTO>>;
  public saveAllPolicyUsingPOST(
    travelPolicyDTO: Array<TravelPolicyDTO>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (travelPolicyDTO === null || travelPolicyDTO === undefined) {
      throw new Error(
        'Required parameter travelPolicyDTO was null or undefined when calling saveAllPolicyUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<OtaResultOfListOfTravelPolicyDTO>(
      `${this.basePath}/api/travel-policy/all`,
      travelPolicyDTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * saveCondition
   *
   * @param travelConditionDTO travelConditionDTO
   * @param policyId policy_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveConditionUsingPOST(
    travelConditionDTO: TravelConditionDTO,
    policyId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfTravelConditionDTO>;
  public saveConditionUsingPOST(
    travelConditionDTO: TravelConditionDTO,
    policyId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfTravelConditionDTO>>;
  public saveConditionUsingPOST(
    travelConditionDTO: TravelConditionDTO,
    policyId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfTravelConditionDTO>>;
  public saveConditionUsingPOST(
    travelConditionDTO: TravelConditionDTO,
    policyId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (travelConditionDTO === null || travelConditionDTO === undefined) {
      throw new Error(
        'Required parameter travelConditionDTO was null or undefined when calling saveConditionUsingPOST.'
      );
    }

    if (policyId === null || policyId === undefined) {
      throw new Error(
        'Required parameter policyId was null or undefined when calling saveConditionUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<OtaResultOfTravelConditionDTO>(
      `${this.basePath}/api/travel-policy/${encodeURIComponent(
        String(policyId)
      )}/travel-condition`,
      travelConditionDTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * savePolicy
   *
   * @param travelPolicyDTO travelPolicyDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public savePolicyUsingPOST(
    travelPolicyDTO: TravelPolicyDTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfTravelPolicyDTO>;
  public savePolicyUsingPOST(
    travelPolicyDTO: TravelPolicyDTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfTravelPolicyDTO>>;
  public savePolicyUsingPOST(
    travelPolicyDTO: TravelPolicyDTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfTravelPolicyDTO>>;
  public savePolicyUsingPOST(
    travelPolicyDTO: TravelPolicyDTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (travelPolicyDTO === null || travelPolicyDTO === undefined) {
      throw new Error(
        'Required parameter travelPolicyDTO was null or undefined when calling savePolicyUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<OtaResultOfTravelPolicyDTO>(
      `${this.basePath}/api/travel-policy`,
      travelPolicyDTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * savePolicyWithConditions
   *
   * @param travelPolicyWrapper travelPolicyWrapper
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public savePolicyWithConditionsUsingPOST(
    travelPolicyWrapper: TravelPolicyWrapper,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfTravelPolicyWrapper>;
  public savePolicyWithConditionsUsingPOST(
    travelPolicyWrapper: TravelPolicyWrapper,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfTravelPolicyWrapper>>;
  public savePolicyWithConditionsUsingPOST(
    travelPolicyWrapper: TravelPolicyWrapper,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfTravelPolicyWrapper>>;
  public savePolicyWithConditionsUsingPOST(
    travelPolicyWrapper: TravelPolicyWrapper,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (travelPolicyWrapper === null || travelPolicyWrapper === undefined) {
      throw new Error(
        'Required parameter travelPolicyWrapper was null or undefined when calling savePolicyWithConditionsUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<OtaResultOfTravelPolicyWrapper>(
      `${this.basePath}/api/travel-policy/savePolicyWithConditions`,
      travelPolicyWrapper,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * updateAllPolicy
   *
   * @param travelPolicyDTO travelPolicyDTO
   * @param agencyCode agencyCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAllPolicyUsingPUT(
    travelPolicyDTO: Array<TravelPolicyDTO>,
    agencyCode?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfListOfTravelPolicyDTO>;
  public updateAllPolicyUsingPUT(
    travelPolicyDTO: Array<TravelPolicyDTO>,
    agencyCode?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfListOfTravelPolicyDTO>>;
  public updateAllPolicyUsingPUT(
    travelPolicyDTO: Array<TravelPolicyDTO>,
    agencyCode?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfListOfTravelPolicyDTO>>;
  public updateAllPolicyUsingPUT(
    travelPolicyDTO: Array<TravelPolicyDTO>,
    agencyCode?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (travelPolicyDTO === null || travelPolicyDTO === undefined) {
      throw new Error(
        'Required parameter travelPolicyDTO was null or undefined when calling updateAllPolicyUsingPUT.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<OtaResultOfListOfTravelPolicyDTO>(
      `${this.basePath}/api/travel-policy/all`,
      travelPolicyDTO,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * updatePolicy
   *
   * @param travelPolicyDTO travelPolicyDTO
   * @param agencyCode agencyCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePolicyUsingPUT(
    travelPolicyDTO: TravelPolicyDTO,
    agencyCode?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfTravelPolicyDTO>;
  public updatePolicyUsingPUT(
    travelPolicyDTO: TravelPolicyDTO,
    agencyCode?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfTravelPolicyDTO>>;
  public updatePolicyUsingPUT(
    travelPolicyDTO: TravelPolicyDTO,
    agencyCode?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfTravelPolicyDTO>>;
  public updatePolicyUsingPUT(
    travelPolicyDTO: TravelPolicyDTO,
    agencyCode?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (travelPolicyDTO === null || travelPolicyDTO === undefined) {
      throw new Error(
        'Required parameter travelPolicyDTO was null or undefined when calling updatePolicyUsingPUT.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<OtaResultOfTravelPolicyDTO>(
      `${this.basePath}/api/travel-policy/`,
      travelPolicyDTO,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * findListPolicyByTraveler
   *
   * @param agencyCode agencyCode
   * @param traveler traveler
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findListPolicyByTravelerUsingGET(
    agencyCode?: string,
    traveler?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PageOfTravelPolicyDTO>;
  public findListPolicyByTravelerUsingGET(
    agencyCode?: string,
    traveler?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PageOfTravelPolicyDTO>>;
  public findListPolicyByTravelerUsingGET(
    agencyCode?: string,
    traveler?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PageOfTravelPolicyDTO>>;
  public findListPolicyByTravelerUsingGET(
    agencyCode?: string,
    traveler?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }
    if (traveler !== undefined && traveler !== null) {
      queryParameters = queryParameters.set('traveler', <any>traveler);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<PageOfTravelPolicyDTO>(
      `${this.basePath}/api/travel-policy/find-list-policy-by-traveler`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * findPolicyByTraveler
   *
   * @param agencyCode agencyCode
   * @param traveler traveler
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findPolicyByTravelerUsingGET(
    agencyCode?: string,
    traveler?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfTravelPolicyWrapper>;
  public findPolicyByTravelerUsingGET(
    agencyCode?: string,
    traveler?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfTravelPolicyWrapper>>;
  public findPolicyByTravelerUsingGET(
    agencyCode?: string,
    traveler?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfTravelPolicyWrapper>>;
  public findPolicyByTravelerUsingGET(
    agencyCode?: string,
    traveler?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }
    if (traveler !== undefined && traveler !== null) {
      queryParameters = queryParameters.set('traveler', <any>traveler);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfTravelPolicyWrapper>(
      `${this.basePath}/api/travel-policy/find-policy-by-traveler`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public checkOffer(
    travelerCodes: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public checkOffer(
    travelerCodes: string,
    agencyCode?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public checkOffer(
    travelerCodes: string,
    agencyCode?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public checkOffer(
    travelerCodes: string,
    agencyCode?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (travelerCodes === null || travelerCodes === undefined) {
      throw new Error(
        'Required parameter travelerCodes was null or undefined when calling updateAllPolicyUsingPUT.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (travelerCodes !== undefined && travelerCodes !== null) {
      queryParameters = queryParameters.set('travelerCodes', <any>(
        travelerCodes
      ));
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.get<any>(
      `${this.basePath}/api/travel-policy/check-approval-travelpolicy-offer`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
