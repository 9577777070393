import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {GetUserProfileFacade} from "@gtd/api-services/b2b-client";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {isPlatformBrowser} from "@angular/common";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'gtd-dialog-withdraw-approval',
  templateUrl: './dialog-withdraw-approval.component.html',
  styleUrls: ['./dialog-withdraw-approval.component.scss']
})
export class DialogWithdrawApprovalComponent implements OnInit {

  allGetUserProfile$ = this.getUserProfileFacade.allGetUserProfile$;
  formReject: FormGroup;

  constructor(
    private getUserProfileFacade: GetUserProfileFacade,
    public dialogRef: MatDialogRef<DialogWithdrawApprovalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.parent.postMessage({ action: 'scrollToTop' }, '*');
    }
    this.createForm();
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  actionWithdraw() {
    this.dialogRef.close({action: 'CANCELED', note: this.formReject.controls['note'].value});
  }

  private createForm() {
    this.formReject = this.formBuilder.group({
      note: [''],
    });
  }
}
