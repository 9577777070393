import {
  PolicyByBookingAction,
  PolicyByBookingActionTypes
} from './policy-by-booking.actions';
import {ListResponseOfInsurancePolicy, OtaResultOfListOfInsuranceDetail} from "@gtd/api-services/service-inventory";

export const POLICYBYBOOKING_FEATURE_KEY = 'policyByBooking';

/**
 * Interface for the 'PolicyByBooking' data used in
 *  - PolicyByBookingState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface PolicyByBookingState {
  list: OtaResultOfListOfInsuranceDetail; // list of PolicyByBooking; analogous to a sql normalized table
  selectedId?: string | number; // which PolicyByBooking record has been selected
  loaded: boolean; // has the PolicyByBooking list been loaded
  error?: any; // last none error (if any)
}

export interface PolicyByBookingPartialState {
  readonly [POLICYBYBOOKING_FEATURE_KEY]: PolicyByBookingState;
}

export const initialState: PolicyByBookingState = {
  list: null,
  loaded: false
};

export function policyByBookingReducer(
  state: PolicyByBookingState = initialState,
  action: PolicyByBookingAction
): PolicyByBookingState {
  switch (action.type) {
    case PolicyByBookingActionTypes.PolicyByBookingLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case PolicyByBookingActionTypes.PolicyByBookingLoadError: {
      initialState.loaded = true;
      state = initialState;
    }
  }
  return state;
}
