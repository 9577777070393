import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DecimalPipe} from '@angular/common';
import {NumberFormatDirective} from './number-format.directive';

const DIRECTIVES = [NumberFormatDirective];

@NgModule({
  imports: [CommonModule],
  declarations: [DIRECTIVES],
  exports: [DIRECTIVES],
  providers: [ CurrencyPipe, DecimalPipe ]
})
export class DirectivesNumberFormatFormModule {}
