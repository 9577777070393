/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApprovalProcessTraveler } from './approvalProcessTraveler';


export interface ApprovalProcessDTO { 
    agencyCode?: string;
    applyDate?: string;
    approver?: Array<string>;
    approverDetail?: Array<ApprovalProcessTraveler>;
    createdBy?: string;
    createdDate?: string;
    description?: string;
    expiredDate?: string;
    id?: number;
    isDeleted?: boolean;
    isEnable?: boolean;
    notify?: boolean;
    priority?: number;
    processName?: string;
    target?: ApprovalProcessDTO.TargetEnum;
    traveler?: Array<string>;
    travelerDetail?: Array<ApprovalProcessTraveler>;
    updatedBy?: string;
    updatedDate?: string;
}
export namespace ApprovalProcessDTO {
    export type TargetEnum = 'IN_POLICY' | 'OUT_POLICY' | 'ALL_BOOKING';
    export const TargetEnum = {
        INPOLICY: 'IN_POLICY' as TargetEnum,
        OUTPOLICY: 'OUT_POLICY' as TargetEnum,
        ALLBOOKING: 'ALL_BOOKING' as TargetEnum
    };
}
