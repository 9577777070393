import {
  Component,
  EventEmitter,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AirportFacade, CitiesPopularFacade } from '@gtd/meta';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { AirportDTO } from '@gtd/meta-client';
import { FlightSearchService } from '../flight-search.service';
import { HistorySearchKeywordFacade } from '../../../state/history-search-keyword/+state-sesstion/history-search-keyword.facade';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@gtd/services';

export interface InputField {
  stateCtrl: string;
}
@Component({
  selector: 'gtd-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    AirportFacade,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DestinationComponent),
      multi: true
    }
  ]
})
export class DestinationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() type: any;
  @Input() dataLocalStorages: AirportDTO;
  @Output() destinationChanged = new EventEmitter<any>(null);
  @Output() getChanged = new EventEmitter<boolean>(false);
  @ViewChild('inputRef', { static: false }) inputRef: ElementRef;
  form: FormGroup;
  focused = false;
  loaded$ = this.airportFacade.loaded$;

  allAirport$ = this.airportFacade.allAirport$;

  stateCtrl = new FormControl('');

  showInput = new FormControl();

  showResetButton = false;

  allAirportPopular$ = this.citiesPopularFacade.allCitiesPopular$;
  allDomesticCities$ = this.citiesPopularFacade.allDomesticCities;

  errorState = false;

  destination = null;

  airportDTOs: AirportDTO[];

  airportSearchLabel: string = 'airport-popular';
  currentLang: string = 'vi';
  defaultLocation: AirportDTO = {
    city: 'Há»“ ChÃ­ Minh',
    city2: 'Ho Chi Minh City',
    cityCode: 'SGN',
    code: 'SGN',
    country: 'Vietnam',
    country2: 'Viá»‡t Nam',
    countryCode: 'VN',
    name: 'SÃ¢n bay TÃ¢n SÆ¡n Nháº¥t',
    name2: 'Tansonnhat Intl'
  };

  constructor(
    public airportFacade: AirportFacade,
    private citiesPopularFacade: CitiesPopularFacade,
    private flightSearchService: FlightSearchService,
    private historySearchKeywordFacade: HistorySearchKeywordFacade,
    public fb: FormBuilder,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.languageService.getLanguage.subscribe(
      language => (this.currentLang = language)
    );
    this.form = fb.group({
      stateCtrl: ''
    });
    this.citiesPopularFacade.loadAll();
  }
  ngOnInit() {
    this.airportDTOs = this.flightSearchService.getAllLocalStorageLocation(
      this.type
    );
    this.stateCtrl.valueChanges.subscribe((data: any) => {
      this.loadAirport(data);
      if (data) {
        this.showResetButton = true;
        setTimeout(() => {
          this.airportSearchLabel = 'list-airport';
          this.airportDTOs = null;
          if (this.destination && this.destination.code) {
            this.onChange(this.destination.code);
            this.destinationChanged.emit(this.destination);
            this.onTouched();
          }
        });
      } else {
        this.showResetButton = false;
        this.airportDTOs = this.flightSearchService.getAllLocalStorageLocation(
          this.type
        );
        this.onChange('');
        this.onTouched();
      }
    });
    if (this.dataLocalStorages) {
      this.stateCtrl.setValue(this.dataLocalStorages);
      this.destination = this.dataLocalStorages;
      this.destinationChanged.emit(this.dataLocalStorages);
    } else if (this.type === 'from') {
      this.stateCtrl.setValue(this.defaultLocation);
      this.destination = this.defaultLocation;
      this.historySearchKeywordFacade.addSearchKeywordFlightFrom([
        this.defaultLocation
      ]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataLocalStorages && !changes.dataLocalStorages.firstChange) {
      this.destinationChanged.emit(this.dataLocalStorages);
      this.destination = changes.dataLocalStorages.currentValue;
      this.stateCtrl.setValue(changes.dataLocalStorages.currentValue);
      this.inputRef.nativeElement.value = this.displayValue(
        changes.dataLocalStorages.currentValue
      );
    }
  }

  loadAirport(data) {
    this.airportFacade.loadAll(data, '');
  }

  displayValue(destination) {
    return destination && destination.city && destination.code
      ? `${
          this.currentLang == 'vi' && destination.city2
            ? destination.city2
            : destination.city
        } (${destination.code})`
      : destination;
  }

  resetValue() {
    this.stateCtrl.reset();
  }
  closePanel() {
    return setTimeout(() => {
      this.focused = false;
    }, 100);
  }

  destinationSelected(destination: AirportDTO) {
    // this.showInput.setValue();
    if (this.type === 'from') {
      this.historySearchKeywordFacade.addSearchKeywordFlightFrom([destination]);
    }
    if (this.type === 'to') {
      this.historySearchKeywordFacade.addSearchKeywordFlightTo([destination]);
    }
    this.destination = destination;
    this.getChanged.emit(true);
    this.stateCtrl.setValue(destination);
    this.focused = false;
  }

  ngOnDestroy(): void {}

  @Input()
  get value(): InputField {
    let n = this.form.value;
    return n;
  }
  set value(sf: InputField) {
    setTimeout(() => {
      if (this.dataLocalStorages) {
        this.stateCtrl.setValue(this.dataLocalStorages);
        this.onChange(this.dataLocalStorages.code);
        this.destinationChanged.emit(this.dataLocalStorages);
        this.onTouched();
      }
    }, 100);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {}

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
