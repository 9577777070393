/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TourTranslationDTO { 
    language?: TourTranslationDTO.LanguageEnum;
    value?: string;
}
export namespace TourTranslationDTO {
    export type LanguageEnum = 'vi' | 'en';
    export const LanguageEnum = {
        Vi: 'vi' as LanguageEnum,
        En: 'en' as LanguageEnum
    };
}
