import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { UnsubscribeService } from '@gtd/hotels/data-access/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'gtd-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  providers: [UnsubscribeService, DatePipe]
})
export class SearchFormComponent implements OnInit {
  form: FormGroup;
  search$: Observable<any>;
  submited: boolean;
  unsubscribe$: Observable<any>;
  defaultFromDate: Date;
  defaultToDate: Date;
  defaultPassengers: any[];
  defaultLocation: any;
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private _unsubscribeService: UnsubscribeService,
    private datePipe: DatePipe
  ) {
    this.defaultPassengers = [
      {
        adultQuantity: 2,
        childQuantity: 0,
        childrenAges: []
      }
    ];
    this.defaultFromDate = new Date();
    this.defaultToDate = new Date(
      Date.UTC(
        this.defaultFromDate.getFullYear(),
        this.defaultFromDate.getMonth(),
        this.defaultFromDate.getDate() + 1
      )
    );

    const searchHistories = JSON.parse(localStorage.getItem('dataSearchHotel'));

    if (searchHistories !== null) {
      if (
        new Date(searchHistories.fromDate).getTime() >= new Date().getTime()
      ) {
        this.defaultFromDate = new Date(searchHistories.fromDate);
        this.defaultToDate = new Date(searchHistories.toDate);
      }
      this.defaultLocation = {
        name: searchHistories.locationName,
        propertyCount: null,
        locationCode: searchHistories.locationCode,
        searchCode: searchHistories.locationCode,
        locationName: searchHistories.locationName,
        searchType: searchHistories.searchType,
        supplier: searchHistories.supplier
      };
    }

    this.unsubscribe$ = this._unsubscribeService.unsubscribe$;
    this.form = this._formBuilder.group({
      location: new FormControl(this.defaultLocation, [Validators.required]),
      date: new FormControl(
        {
          fromDate: this.datePipe.transform(this.defaultFromDate, 'yyyy-MM-dd'),
          toDate: this.datePipe.transform(this.defaultToDate, 'yyyy-MM-dd')
        },
        [Validators.required]
      ),
      passenger: new FormControl(this.defaultPassengers, [Validators.required]),
      star: new FormControl('')
    });
  }

  ngOnInit() {}

  searchHotel() {
    this.submited = true;
    if (this.form.invalid) {
      return;
    }
    let searchParams;
    let roomsString = [];

    if (this.form.value.passenger.length > 0) {
      this.form.value.passenger.forEach(roomsArr => {
        let childrendString = '';
        if (roomsArr.childrenAges && roomsArr.childrenAges.length > 0) {
          roomsArr.childrenAges.forEach(childrend => {
            childrendString = childrendString
              ? childrendString + ',' + childrend
              : childrend;
          });
        }
        let room = childrendString
          ? roomsArr.adultQuantity + '-' + childrendString
          : roomsArr.adultQuantity;
        roomsString.push(room);
      });
    } else {
      roomsString.push(this.form.value.passenger);
    }
    searchParams = { ...this.form.value.location };
    searchParams.locationCode = this.form.value.location.searchCode;
    searchParams.locationName = this.form.value.location.name;
    searchParams.fromDate = this.form.value.date.fromDate;
    searchParams.toDate = this.form.value.date.toDate;
    searchParams.filterStars = [
      this.form.value.star.min,
      this.form.value.star.max
    ];
    searchParams.rooms = JSON.stringify(this.form.value.passenger);
    searchParams.roomsString = roomsString;
    searchParams.roomsArrs = this.form.value.room;

    let data = {
      locationCode: this.form.value.location.searchCode,
      searchType: this.form.value.location.searchType,
      supplier: this.form.value.location.supplier,
      locationName: this.form.value.location.name,
      fromDate: this.form.value.date.fromDate,
      toDate: this.form.value.date.toDate,
      rooms: JSON.stringify(this.form.value.passenger)
    };
    localStorage.setItem('dataSearchHotel', JSON.stringify(data));

    this.router.navigate(['/hotels'], {
      queryParams: {
        params: btoa(encodeURIComponent(JSON.stringify(searchParams)))
      }
    });
  }

  compareDates(date1: Date, date2: Date): number {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;
    if (d1 > d2) return 1;
    if (d1 < d2) return -1;
  }

  ngOnDestroy(): void {
    this._unsubscribeService.destroy();
  }
}
