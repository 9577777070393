import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TOURACTIVITYBOOKING_FEATURE_KEY,
  TourActivityBookingState
} from './tour-activity-booking.reducer';

// Lookup the 'TourActivityBooking' feature state managed by NgRx
const getTourActivityBookingState = createFeatureSelector<
  TourActivityBookingState
>(TOURACTIVITYBOOKING_FEATURE_KEY);

const getAllActivityInfo = createSelector(
  getTourActivityBookingState,
  (state: TourActivityBookingState) => state
);

const getPaxInfos = createSelector(
  getTourActivityBookingState,
  (state: TourActivityBookingState) => state.paxInfos
);

const getDate = createSelector(
  getTourActivityBookingState,
  (state: TourActivityBookingState) => state.date
);

const getRatePlanId = createSelector(
  getTourActivityBookingState,
  (state: TourActivityBookingState) => state.ratePlanId
);

const getActivityId = createSelector(
  getTourActivityBookingState,
  (state: TourActivityBookingState) => state.activityId
);

export const tourActivityBookingQuery = {
  getPaxInfos,
  getDate,
  getRatePlanId,
  getActivityId,
  getAllActivityInfo
};
