import {Action} from '@ngrx/store';
import {AgentProfileDTO} from "../../lib/index";

export enum LoginNameActionTypes {
  LoadLoginName = '[LoginName] Load LoginName',
  LoginNameLoaded = '[LoginName] LoginName Loaded',
  LoginNameLoadError = '[LoginName] LoginName Load Error'
}

export class LoadLoginName implements Action {
  readonly type = LoginNameActionTypes.LoadLoginName;
  constructor(public payload: string) {}
}

export class LoginNameLoadError implements Action {
  readonly type = LoginNameActionTypes.LoginNameLoadError;
  constructor(public payload: any) {}
}

export class LoginNameLoaded implements Action {
  readonly type = LoginNameActionTypes.LoginNameLoaded;
  constructor(public payload: AgentProfileDTO) {}
}

export type LoginNameAction =
  | LoadLoginName
  | LoginNameLoaded
  | LoginNameLoadError;

export const fromLoginNameActions = {
  LoadLoginName,
  LoginNameLoaded,
  LoginNameLoadError
};
