import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CountryCodeDTO} from "@gtd/meta-client";
import {MatDialog, MatSelect, MatSnackBar} from "@angular/material";
import {ReplaySubject, Subject, Subscription} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CountryFacade} from "@gtd/meta";
import {takeUntil} from "rxjs/operators";
import {CustomerTravellerFacade} from "../../customer-traveller/+state/customer-traveller.facade";
import {CustomerTravellerMemberCardDTO} from "@gtd/api-services/customer-service";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {DialogMessageComponent} from "@gtd/components/dialog-message";

@Component({
  selector: 'gtd-paper-information',
  templateUrl: './paper-information.component.html',
  styleUrls: ['./paper-information.component.scss']
})
export class PaperInformationComponent implements OnInit, OnDestroy {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  formProfile?: FormGroup;
  subscriptions = new Subscription();
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountries: ReplaySubject<CountryCodeDTO[]> = new ReplaySubject<CountryCodeDTO[]>(1);
  memberCardData: Array<CustomerTravellerMemberCardDTO>;
  isLoading = false;

  toDay = new Date();

  protected _onDestroy = new Subject<void>();

  protected countries: CountryCodeDTO[];

  constructor(
    private countryFacade: CountryFacade,
    private customerTravellerFacade: CustomerTravellerFacade,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.createForm();
    this.subscriptions.add(
      this.customerTravellerFacade.selectedCustomerTraveller$.subscribe(results => {
        if(results) {
          this.formProfile.patchValue({
            id: results.id,
            surName: results.surName,
            firstName: results.firstName,
            email: results.email,
            phoneNumber1: results.phoneNumber1,
            dob: results.dob,
            gender: results.gender? results.gender : null,
            address1: results.address1,
            country: results.country,
            customerCode: results.customerCode,
            profileId: results.profileId,
            documentType: results.documentType,
            documentNumber: results.documentNumber,
            nationality: results.nationality,
            expiredDate: results.expiredDate
          });
          this.memberCardData = results.memberCards;
        }
      })
    )
  }

  ngOnInit() {

    this.subscriptions.add(
      this.countryFacade.allCountry$.subscribe((countries: Array<CountryCodeDTO>) => {
        this.countries = countries;
        this.filteredCountries.next(countries.slice());
      })
    )

    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  submitForm() {
    this.isLoading = true;
    this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.updating-information', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"});
    this.customerTravellerFacade.updateCustomerTravellerUsingPut([{
      id: this.formProfile.get('id').value,
      customerCode: this.formProfile.get('customerCode').value,
      address1: this.formProfile.get('address1').value,
      firstName: this.formProfile.get('firstName').value,
      surName: this.formProfile.get('surName').value,
      dob: this.formProfile.get('dob').value,
      country: this.formProfile.get('country').value,
      phoneNumber1: this.formProfile.get('phoneNumber1').value,
      email: this.formProfile.get('email').value,
      profileId: this.formProfile.get('profileId').value,
      gender: this.formProfile.get('gender').value,
      documentType: this.formProfile.get('documentType').value,
      documentNumber: this.formProfile.get('documentNumber').value,
      nationality: this.formProfile.get('nationality').value,
      expiredDate: this.formProfile.get('expiredDate').value,
      memberCards: this.memberCardData? this.memberCardData : [],
      isDefault: true
    }]);
    this.subscriptions.add(
      this.customerTravellerFacade.selectedUpdateCustomerTraveller$.subscribe( resultUpdate => {
        if(resultUpdate && resultUpdate.type === 'Update') {
          if(resultUpdate.lists) {
            this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.update-success', icon: 'check'}, panelClass: 'snackbar-success', verticalPosition: "top", duration: 2000});
          } else {
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [
                  {btnText: 'global.close'}
                ],
                content: 'user.system-error-message',
                heading: 'user.error-message',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          }
        }
        this.isLoading = false;
      })
    )
  }

  protected filterCountries() {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.countryFilterCtrl.value;
    if (!search && this.countries) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCountries.next(
      this.countries.filter(country => country.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private createForm() {
    this.formProfile = this.formBuilder.group({
      id: ['', Validators.required],
      customerCode: ['', Validators.required],
      profileId: ['', Validators.required],
      surName: ['', Validators.required],
      firstName: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber1: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      address1: ['', Validators.nullValidator(null)],
      country: ['', Validators.nullValidator(null)],
      documentType: ['', Validators.nullValidator(null)],
      documentNumber: ['', Validators.nullValidator(null)],
      nationality: ['', Validators.nullValidator(null)],
      expiredDate: ['', Validators.nullValidator(null)]
    });
  }

}
