/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TourAttributeMappingDTO } from './tourAttributeMappingDTO';
import { TourContentReferenceDTO } from './tourContentReferenceDTO';
import { TourSchedulePriceAvailableDTO } from './tourSchedulePriceAvailableDTO';
import { TourTimingDTO } from './tourTimingDTO';

export interface TourRatePlanAvailableDTO {
  activityId?: number;
  allowPayNow?: boolean;
  amenities?: { [key: string]: TourContentReferenceDTO };
  attributes?: Array<TourAttributeMappingDTO>;
  cancelRefundableTiming?: TourTimingDTO;
  cutoff?: number;
  cutoffType?: TourRatePlanAvailableDTO.CutoffTypeEnum;
  exclusions?: Array<string>;
  hasInventory?: boolean;
  id?: number;
  inclusions?: Array<string>;
  mlou?: number;
  name?: string;
  refundable?: boolean;
  requiredTraveller?: boolean;
  reservation?: boolean;
  schedulePrices?: Array<TourSchedulePriceAvailableDTO>;
  status?: boolean;
  timing?: TourTimingDTO;
  inventoryAvailable: boolean;
  minQuantity: number;
}
export namespace TourRatePlanAvailableDTO {
  export type CutoffTypeEnum = 'DAY' | 'HOUR';
  export const CutoffTypeEnum = {
    DAY: 'DAY' as CutoffTypeEnum,
    HOUR: 'HOUR' as CutoffTypeEnum
  };
}
