/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SSRItem } from './sSRItem';


export interface SpecialServiceRequest { 
    mealPreference?: SpecialServiceRequest.MealPreferenceEnum;
    seatPreference?: SpecialServiceRequest.SeatPreferenceEnum;
    ssrItems?: Array<SSRItem>;
}
export namespace SpecialServiceRequest {
    export type MealPreferenceEnum = 'LOW_PROTEIN_MEAL' | 'ORIENTAL_MEAL' | 'HIGH_FIBER_MEAL' | 'LOW_PURIN_MEAL' | 'VEGETARIAN_JAIN_MEAL' | 'ASIAN_VEGETARIAN_MEAL' | 'BABY_MEAL' | 'OTHER';
    export const MealPreferenceEnum = {
        LOWPROTEINMEAL: 'LOW_PROTEIN_MEAL' as MealPreferenceEnum,
        ORIENTALMEAL: 'ORIENTAL_MEAL' as MealPreferenceEnum,
        HIGHFIBERMEAL: 'HIGH_FIBER_MEAL' as MealPreferenceEnum,
        LOWPURINMEAL: 'LOW_PURIN_MEAL' as MealPreferenceEnum,
        VEGETARIANJAINMEAL: 'VEGETARIAN_JAIN_MEAL' as MealPreferenceEnum,
        ASIANVEGETARIANMEAL: 'ASIAN_VEGETARIAN_MEAL' as MealPreferenceEnum,
        BABYMEAL: 'BABY_MEAL' as MealPreferenceEnum,
        OTHER: 'OTHER' as MealPreferenceEnum
    };
    export type SeatPreferenceEnum = 'WINDOW' | 'AISLE';
    export const SeatPreferenceEnum = {
        WINDOW: 'WINDOW' as SeatPreferenceEnum,
        AISLE: 'AISLE' as SeatPreferenceEnum
    };
}
