/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingInfo } from './bookingInfo';
import { GroupPricedItinerary } from './groupPricedItinerary';
import { HotelAvailability } from './hotelAvailability';
import { HotelProduct } from './hotelProduct';
import { HotelProductPayload } from './hotelProductPayload';
import { OfflineBooking } from './offlineBooking';
import { OfflineBookingRequest } from './offlineBookingRequest';
import { TourActivityBookingPayload } from './tourActivityBookingPayload';
import { TourActivityProduct } from './tourActivityProduct';
import { TravelerInfo } from './travelerInfo';

export interface GroupBooking {
  agencyCode?: string;
  agentCode?: string;
  bookingCode?: string;
  bookingDate?: Date;
  bookingInfo?: BookingInfo;
  bookingNumber?: string;
  bookingType?: string;
  branchCode?: string;
  cacheType?: GroupBooking.CacheTypeEnum;
  channelType?: string;
  customerCode?: string;
  groupPricedItineraries?: Array<GroupPricedItinerary>;
  hotelAvailability?: HotelAvailability;
  hotelProduct?: HotelProduct;
  hotelProductPayload?: HotelProductPayload;
  id?: string;
  isPerBookingType?: boolean;
  markupType?: string;
  offlineBooking?: OfflineBooking;
  offlineBookingRequest?: OfflineBookingRequest;
  orgCode?: string;
  saleChannel?: string;
  supplierType?: string;
  tourActivityBookingPayload?: TourActivityBookingPayload;
  tourActivityProduct?: TourActivityProduct;
  travelerInfo?: TravelerInfo;
  updatedDate?: Date;
  draftPayment: any;
}
export namespace GroupBooking {
  export type CacheTypeEnum =
    | 'USER_SESSION'
    | 'METADATA'
    | 'PRICING_MODEL'
    | 'TICKET'
    | 'HOTEL'
    | 'HOTEL_ROOM'
    | 'HOTEL_ROOM_LOOKED'
    | 'BOOKING'
    | 'PROMOTION'
    | 'PROPERTY'
    | 'LITE_AGENCY'
    | 'COMBO'
    | 'TOUR'
    | 'IBE_DATA_INFO'
    | 'IBE_PAYMENT_GATEWAY_DATA_INFO'
    | 'IBE_AGENCY_DATA_INFO'
    | 'IBE_AGENT_PROFILE_DATA_INFO'
    | 'IBE_EMAIL_TEMPLATE_DATA_INFO'
    | 'LOOKUP_REF_DATA'
    | 'AIRLINE_REF_DATA'
    | 'AIRPORT_REF_DATA'
    | 'DESTINATION_REF_DATA'
    | 'HOTEL_REF_DATA'
    | 'SUPPLIER_MAPPING_REF_DATA'
    | 'OTHER'
    | 'MD'
    | 'OBJECT';
  export const CacheTypeEnum = {
    USERSESSION: 'USER_SESSION' as CacheTypeEnum,
    METADATA: 'METADATA' as CacheTypeEnum,
    PRICINGMODEL: 'PRICING_MODEL' as CacheTypeEnum,
    TICKET: 'TICKET' as CacheTypeEnum,
    HOTEL: 'HOTEL' as CacheTypeEnum,
    HOTELROOM: 'HOTEL_ROOM' as CacheTypeEnum,
    HOTELROOMLOOKED: 'HOTEL_ROOM_LOOKED' as CacheTypeEnum,
    BOOKING: 'BOOKING' as CacheTypeEnum,
    PROMOTION: 'PROMOTION' as CacheTypeEnum,
    PROPERTY: 'PROPERTY' as CacheTypeEnum,
    LITEAGENCY: 'LITE_AGENCY' as CacheTypeEnum,
    COMBO: 'COMBO' as CacheTypeEnum,
    TOUR: 'TOUR' as CacheTypeEnum,
    IBEDATAINFO: 'IBE_DATA_INFO' as CacheTypeEnum,
    IBEPAYMENTGATEWAYDATAINFO: 'IBE_PAYMENT_GATEWAY_DATA_INFO' as CacheTypeEnum,
    IBEAGENCYDATAINFO: 'IBE_AGENCY_DATA_INFO' as CacheTypeEnum,
    IBEAGENTPROFILEDATAINFO: 'IBE_AGENT_PROFILE_DATA_INFO' as CacheTypeEnum,
    IBEEMAILTEMPLATEDATAINFO: 'IBE_EMAIL_TEMPLATE_DATA_INFO' as CacheTypeEnum,
    LOOKUPREFDATA: 'LOOKUP_REF_DATA' as CacheTypeEnum,
    AIRLINEREFDATA: 'AIRLINE_REF_DATA' as CacheTypeEnum,
    AIRPORTREFDATA: 'AIRPORT_REF_DATA' as CacheTypeEnum,
    DESTINATIONREFDATA: 'DESTINATION_REF_DATA' as CacheTypeEnum,
    HOTELREFDATA: 'HOTEL_REF_DATA' as CacheTypeEnum,
    SUPPLIERMAPPINGREFDATA: 'SUPPLIER_MAPPING_REF_DATA' as CacheTypeEnum,
    OTHER: 'OTHER' as CacheTypeEnum,
    MD: 'MD' as CacheTypeEnum,
    OBJECT: 'OBJECT' as CacheTypeEnum
  };
}
