import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FilterAvailableFacade} from "../../../../state/filter-available/filter-available.facade";

@Component({
  selector: 'gtd-filter-by-guest-rating',
  templateUrl: './filter-by-guest-rating.component.html',
  styleUrls: ['./filter-by-guest-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterByGuestRatingComponent),
      multi: true
    }
  ]
})
export class FilterByGuestRatingComponent implements OnInit, ControlValueAccessor {
  @Input() propertyFilters: Array<any>;
  @Input() label: string;
  ratingForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private filterAvailableFacade: FilterAvailableFacade) {
    this.ratingForm = this.formBuilder.group({
      rating:  this.formBuilder.array([])
    });
  }

  ngOnInit() {
    const stars = <FormArray>this.ratingForm.get('rating') as FormArray;
    this.propertyFilters.forEach(rating => {
      stars.push(new FormControl(false))
    })
  }

  checkboxRating(index: number) {
    setTimeout(() => {
      let nbCheck = this.ratingForm.controls['rating'].value.filter(star => star).length;
      if(nbCheck >= 1) {
        let start = this.ratingForm.controls['rating'].value.indexOf(true);
        let end = this.ratingForm.controls['rating'].value.lastIndexOf(true);
        for(let i = start; i <= end; i++) {
          this.ratingForm.controls['rating'].get([i]).setValue(true);
        }
        if(index === start || index === end || !this.ratingForm.controls['rating'].get([index]).value) {
          this.filterAvailableFacade.changeFilterGuestRating(Math.floor(this.propertyFilters[end].value), Math.ceil(this.propertyFilters[start].value));
        }
      } else {
        this.filterAvailableFacade.changeFilterGuestRating(null, null);
      }
    })
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    if(obj && obj.to && obj.from) {
      let start = this.propertyFilters.findIndex(x => x.value === obj.to);
      let end = this.propertyFilters.findIndex(x => x.value === obj.from);
      for(let i = start; i <= end; i++) {
        this.ratingForm.controls['rating'].get([i]).setValue(true);
      }
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
