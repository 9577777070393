import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[gtdUpperCaseInput]'
})
export class UpperCaseInputDirective {
  constructor(private ref: ElementRef) {}
  @HostListener('input', ['$event.target'])
  toUpperCase(input) {
    this.ref.nativeElement.value = input.value
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[đĐ]/g, m => m === 'đ' ? 'd' : 'D');
  }
}
