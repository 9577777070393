import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PopularPlacesPartialState } from './popular-places.reducer';
import { popularPlacesQuery } from './popular-places.selectors';
import { LoadPopularPlaces } from './popular-places.actions';

@Injectable()
export class PopularPlacesFacade {
  loaded$ = this.store.pipe(select(popularPlacesQuery.getLoaded));
  allPopularPlaces$ = this.store.pipe(
    select(popularPlacesQuery.getAllPopularPlaces)
  );
  selectedPopularPlaces$ = this.store.pipe(
    select(popularPlacesQuery.getSelectedPopularPlaces)
  );

  constructor(private store: Store<PopularPlacesPartialState>) {}

  getPopularDestination(name: string) {
    this.store.dispatch(new LoadPopularPlaces(name));
  }
}
