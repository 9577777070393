import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material';
import { NewUpdateSnackBarComponent } from './new-update-snack-bar/new-update-snack-bar.component';

@Injectable()
export class PromptUpdateService {
  constructor(private updates: SwUpdate, private snackbar: MatSnackBar) {
    updates.available.subscribe(() => {
      this.updates.activateUpdate().then(() => document.location.reload());
    });
  }

  openSnackBar() {
    const snack = this.snackbar.openFromComponent(NewUpdateSnackBarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'bg-white'
    });
    snack.onAction().subscribe(() => {
      this.updates.activateUpdate().then(() => document.location.reload());
    });
  }
}
