import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AvailableFlightsFacade } from '../flight-search/flight-cache-search/+state/available-flights.facade';

@Component({
  selector: 'gtd-result-summary',
  templateUrl: './result-summary.component.html',
  styleUrls: ['./result-summary.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: 0 }), animate(500)]),
      transition(':leave', [style({ opacity: 0 }), animate(0)])
    ])
  ]
})
export class ResultSummaryComponent implements OnInit {
  loaded$ = this.availableFlightsFacade.loaded$;

  constructor(public availableFlightsFacade: AvailableFlightsFacade) {}
  ngOnInit() {}
}
