import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';
import {map} from "rxjs/operators";

import {PromotionSectionStatePartialState} from './promotion-section-state.reducer';
import {
  LoadPromotionSectionState,
  PromotionSectionStateActionTypes,
  PromotionSectionStateLoadError
} from './promotion-section-state.actions';
import {UtilResourceService} from "@gtd/b2c-client";

@Injectable()
export class PromotionSectionStateEffects {
  @Effect() loadPromotionSectionState$ = this.dataPersistence.fetch(
    PromotionSectionStateActionTypes.LoadPromotionSectionState,
    {
      run: (action: LoadPromotionSectionState, state: PromotionSectionStatePartialState) => {
        return this.utilResourceService.getAllBannersUsingGET().pipe(
          map(update =>({
            type: PromotionSectionStateActionTypes.PromotionSectionStateLoaded,
            payload: update
          }))
        );
      },
      onError: (action: LoadPromotionSectionState, error) => {
        console.error('Error', error);
        return new PromotionSectionStateLoadError(error);
      }
    }
  );
  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<PromotionSectionStatePartialState>,
    private utilResourceService: UtilResourceService
  ) {}
}
