import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ShortProfilePartialState } from './short-profile.reducer';
import { shortProfileQuery } from './short-profile.selectors';
import { LoadShortProfile } from './short-profile.actions';

@Injectable()
export class ShortProfileFacade {
  loaded$ = this.store.pipe(select(shortProfileQuery.getLoaded));
  allShortProfile$ = this.store.pipe(
    select(shortProfileQuery.getAllShortProfile)
  );
  selectedShortProfile$ = this.store.pipe(
    select(shortProfileQuery.getSelectedShortProfile)
  );
  getShortProfile$ = this.store.pipe(
    select(shortProfileQuery.getShortProfile)
  );

  constructor(private store: Store<ShortProfilePartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadShortProfile());
  }
}
