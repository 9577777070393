/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApprovalProcessHumanFactorDTO } from './approvalProcessHumanFactorDTO';


export interface ApprovalRequestDTO { 
    agencyCode?: string;
    approvalBookingId?: number;
    approvalProcessId?: number;
    approver?: Array<number>;
    approverDetail?: Array<ApprovalProcessHumanFactorDTO>;
    createdBy?: string;
    createdDate?: string;
    decisionMaker?: string;
    decisionMakerDetail?: ApprovalProcessHumanFactorDTO;
    id?: number;
    note?: string;
    status?: ApprovalRequestDTO.StatusEnum;
    travelerCode?: string;
    travelerDetail?: ApprovalProcessHumanFactorDTO;
    updatedBy?: string;
    updatedDate?: string;
}
export namespace ApprovalRequestDTO {
    export type StatusEnum = 'REQUESTED' | 'APPROVED' | 'DECLINED' | 'EXPIRED' | 'CANCELED';
    export const StatusEnum = {
        REQUESTED: 'REQUESTED' as StatusEnum,
        APPROVED: 'APPROVED' as StatusEnum,
        DECLINED: 'DECLINED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum
    };
}
