/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Attribute } from './attribute';
import { Checkin } from './checkin';
import { Checkout } from './checkout';
import { HotelImage } from './hotelImage';
import { PropertyCategory } from './propertyCategory';
import { PropertyRoom } from './propertyRoom';
import { Rating } from './rating';

export interface HotelProduct {
  address?: Address;
  amenities?: Array<Attribute>;
  checkin?: Checkin;
  checkout?: Checkout;
  currency?: HotelProduct.CurrencyEnum;
  customerIp?: string;
  descriptions?: Array<Attribute>;
  fees?: Array<Attribute>;
  images?: Array<HotelImage>;
  inclusions?: Array<Attribute>;
  language?: HotelProduct.LanguageEnum;
  latitude?: number;
  longitude?: number;
  policies?: Array<Attribute>;
  productId?: string;
  propertyCategory?: PropertyCategory;
  propertyId?: string;
  propertyName?: string;
  rank?: number;
  rating?: Rating;
  rooms?: Array<PropertyRoom>;
  mealPlans?: Array<any>;
  searchId?: string;
  statistics?: Array<Attribute>;
  supplier?: HotelProduct.SupplierEnum;
  themes?: Array<Attribute>;
  tripId?: string;
  distanceToCenter: any;
}
export namespace HotelProduct {
  export type CurrencyEnum = 'VND' | 'USD';
  export const CurrencyEnum = {
    VND: 'VND' as CurrencyEnum,
    USD: 'USD' as CurrencyEnum
  };
  export type LanguageEnum = 'vi' | 'en';
  export const LanguageEnum = {
    Vi: 'vi' as LanguageEnum,
    En: 'en' as LanguageEnum
  };
  export type SupplierEnum =
    | 'EXPEDIA'
    | 'AXISROOM'
    | 'BEDLINKER'
    | 'AGODA'
    | 'VINPEARL';
  export const SupplierEnum = {
    EXPEDIA: 'EXPEDIA' as SupplierEnum,
    AXISROOM: 'AXISROOM' as SupplierEnum,
    BEDLINKER: 'BEDLINKER' as SupplierEnum,
    VINPEARL: 'VINPEARL' as SupplierEnum,
    AGODA: 'AGODA' as SupplierEnum
  };
}
