import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform
} from '@angular/core';
import { PropertyAllRate } from '@gtd/b2c-client';
import { MatDialog } from '@angular/material';
import { MapPanelDetailComponent } from '../map-panel-detail/map-panel-detail.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SlugifyPipe } from 'libs/combovj/src/lib/search-result/pipe/slug.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'gtd-hotel-information',
  templateUrl: './hotel-information.component.html',
  styleUrls: ['./hotel-information.component.scss'],
  providers: [SlugifyPipe]
})
export class HotelInformationComponent implements OnInit {
  @Input() hotelDetail: PropertyAllRate;
  counter = Array;
  @Output() scrollToSection: EventEmitter<any> = new EventEmitter<any>();

  numbItemAnimes: number;
  aroundArea: any;
  aroundAreaArr: string[];
  searchParams;
  roomsArr;
  constructor(
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.searchParams = JSON.parse(decodeURIComponent(atob(params.params)));
      this.roomsArr = JSON.parse(this.searchParams.paxInfos);
    });
  }

  ngOnInit() {
    this.numbItemAnimes = this.deviceService.isDesktop() ? 11 : 4;
    this.aroundArea = this.hotelDetail && this.hotelDetail.descriptions
      ? this.hotelDetail.descriptions.find(
          description => description.name === 'attractions'
        )
      : '';
    if (this.aroundArea) {
      this.aroundAreaArr = this.aroundArea.value.split('<br />');
    }
  }
  // scrollToAmenities() {
  //   this.changed.emit(true);
  // }

  sectionSelected(id: string) {
    this.scrollToSection.emit(id);
  }
  viewMapPanelDetail() {
    this.dialog.open(MapPanelDetailComponent, {
      data: {
        hotelDetail: this.hotelDetail
      },
      position: {
        top: '0px',
        left: '0px'
      },
      panelClass: 'popupMapPanel'
    });
  }

  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }
}

@Pipe({
  name: 'quantity'
})
export class QuantityPassenger implements PipeTransform {
  transform(rooms: Array<any>, type: 'adult' | 'child'): number {
    let totalQuantity = 0;
    switch (type) {
      case 'adult':
        totalQuantity = this.getAdult(rooms);
        break;
      case 'child':
        totalQuantity = this.getChild(rooms);
        break;
    }
    return totalQuantity;
  }
  getAdult(rooms: Array<any>): number {
    let adultQuantity = 0;
    rooms.forEach(room => {
      adultQuantity += room.adultQuantity;
    });
    return adultQuantity;
  }
  getChild(rooms: Array<any>): number {
    let childQuantity = 0;
    rooms.forEach(room => {
      childQuantity += room.childQuantity;
    });
    return childQuantity;
  }
}
