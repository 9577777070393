/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BookingDiscountInfoDTO { 
    bookingCode?: string;
    bookingNumber?: string;
    customerEmail?: string;
    discountAmount?: number;
    discountRedeemCode?: string;
    discountRedeemId?: string;
    discountTrackingCode?: string;
    discountVoucherCode?: string;
    paymentStatus?: BookingDiscountInfoDTO.PaymentStatusEnum;
}
export namespace BookingDiscountInfoDTO {
    export type PaymentStatusEnum = 'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'PENDING';
    export const PaymentStatusEnum = {
        SUCCEEDED: 'SUCCEEDED' as PaymentStatusEnum,
        FAILED: 'FAILED' as PaymentStatusEnum,
        REFUNDED: 'REFUNDED' as PaymentStatusEnum,
        PENDING: 'PENDING' as PaymentStatusEnum
    };
}
