import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourTravellerInformationComponent } from './tour-traveller-information.component';
import { MatCardModule } from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatCardModule, FontAwesomeModule, TranslateModule],
  declarations: [TourTravellerInformationComponent],
  exports: [TourTravellerInformationComponent]
})
export class TourTravellerInformationModule {}
