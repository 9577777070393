import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { LibraryAvatarPartialState } from './library-avatar.reducer';
import {
  LoadLibraryAvatar,
  LibraryAvatarLoaded,
  LibraryAvatarLoadError,
  LibraryAvatarActionTypes
} from './library-avatar.actions';
import {map} from "rxjs/operators";
import {CustomerAvatarResourceService} from "@gtd/api-services/customer-service";

@Injectable()
export class LibraryAvatarEffects {
  @Effect() loadLibraryAvatar$ = this.dataPersistence.fetch(
    LibraryAvatarActionTypes.LoadLibraryAvatar,
    {
      run: (action: LoadLibraryAvatar, state: LibraryAvatarPartialState) => {
        return this.customerAvatarResourceService.updateCustomerAvatarUsingPUT(action.payload).pipe(
          map(update => ({
            type: LibraryAvatarActionTypes.LibraryAvatarLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadLibraryAvatar, error) => {
        console.error('Error', error);
        return new LibraryAvatarLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private customerAvatarResourceService: CustomerAvatarResourceService,
    private dataPersistence: DataPersistence<LibraryAvatarPartialState>
  ) {}
}
