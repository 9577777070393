import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterPanelService {
  submitSubject$ = new BehaviorSubject<boolean>(false);
  submit$ = this.submitSubject$.asObservable();
  constructor(private _deviceDetectorService: DeviceDetectorService) {}

  setSubmit(submit: boolean) {
    this.submitSubject$.next(submit);
  }

  isDesktop() {
    return this._deviceDetectorService.isDesktop();
  }
}
