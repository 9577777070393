import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import { Options } from 'ng5-slider';
import { Subscription } from 'rxjs';
import { FilterAvailableFacade } from '../../../../state/filter-available/filter-available.facade';
import { ActivatedRoute } from '@angular/router';
import { FilterAvailableState } from '../../../../state/filter-available/filter-available.reducer';

@Component({
  selector: 'gtd-distance-panel',
  templateUrl: './distance-panel.component.html',
  styleUrls: ['./distance-panel.component.scss']
})
export class DistancePanelComponent implements OnInit, OnDestroy {
  @Input() filterPrices: Array<any>;
  @Input() searchParams: any;
  @Input() currentFilterPrices: FilterAvailableState;
  subscriptions = new Subscription();

  minApply: number;
  maxApply: number;
  value: number;
  highValue: number;
  valueChange: EventEmitter<any> = new EventEmitter<any>();
  highValueChange: EventEmitter<any> = new EventEmitter<any>();
  options: Options;
  numberChars = new RegExp('[^0-9]', 'g');

  constructor(
    private activatedRoute: ActivatedRoute,
    private filterAvailableFacade: FilterAvailableFacade
  ) {}

  private _handleQueryFromParams(param) {
    if (param.filterPrice) {
      this.minApply = Math.floor(param.filterPrice.fromPrice);
      this.maxApply = Math.floor(param.filterPrice.toPrice);
    }
  }

  ngOnInit() {
    if (this.filterPrices && this.filterPrices.length) {
      this.value = Math.floor(this.filterPrices[0].to);
      this.minApply = Math.floor(this.filterPrices[0].to);
      this.highValue = Math.ceil(this.filterPrices.slice(-1)[0].from);
      this.maxApply = Math.ceil(this.filterPrices.slice(-1)[0].from);
      this.options = this.makeOptions();
    }
    if (this.currentFilterPrices && this.currentFilterPrices.filterPrice) {
      this.value = Math.floor(this.currentFilterPrices.filterPrice.fromPrice);
      this.minApply = Math.floor(
        this.currentFilterPrices.filterPrice.fromPrice
      );
      this.maxApply = Math.ceil(this.currentFilterPrices.filterPrice.toPrice);
      this.highValue = Math.ceil(this.currentFilterPrices.filterPrice.toPrice);
    }
    this.valueChange.subscribe(value => {
      this.value = value;
    });
    this.highValueChange.subscribe(value => {
      this.highValue = value;
    });
    setTimeout(() => {
      this.activatedRoute.queryParams.subscribe(param => {
        this._handleQueryFromParams(
          JSON.parse(decodeURIComponent(atob(param.params)))
        );
      });
    });
  }

  resetValue() {
    let value = Math.floor(this.filterPrices[0].to);
    let highValue = Math.ceil(this.filterPrices.slice(-1)[0].from);
    this.valueChange.emit(this.parseNumber(value));
    this.highValueChange.emit(this.parseNumber(highValue));
    this.minApply = this.parseNumber(value);
    this.maxApply = this.parseNumber(highValue);
    this.filterAvailableFacade.changeFilterPrice(null, null);
  }

  applyFilter() {
    this.filterAvailableFacade.changeFilterDistance(
      this.value,
      this.highValue !== 5 ? this.highValue : null
    );
  }

  formPriceChange(event) {
    this.valueChange.emit(this.parseNumber(event.target.value));
    this.minApply = this.parseNumber(event.target.value);
  }
  toPriceChange(event) {
    this.highValueChange.emit(this.parseNumber(event.target.value));
    this.maxApply = this.parseNumber(event.target.value);
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  makeOptions() {
    return {
      animate: false, // Easier to see the single update
      floor: this.value,
      ceil: this.highValue,
      hideLimitLabels: true,
      hidePointerLabels: true,
      draggableRange: false
    };
  }

  parseNumber(value): number {
    let temp;
    if (typeof value === 'string') {
      temp = Number(value.replace(this.numberChars, ''));
    } else {
      temp = Number(value);
    }
    return temp;
  }
}
