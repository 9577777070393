import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BOOKINGDETAIL_FEATURE_KEY,
  BookingDetailState
} from './booking-detail.reducer';

// Lookup the 'BookingDetail' feature state managed by NgRx
const getBookingDetailState = createFeatureSelector<BookingDetailState>(
  BOOKINGDETAIL_FEATURE_KEY
);

const getLoaded = createSelector(
  getBookingDetailState,
  (state: BookingDetailState) => state.loaded
);
const getError = createSelector(
  getBookingDetailState,
  (state: BookingDetailState) => state.error
);

const getAllBookingDetail = createSelector(
  getBookingDetailState,
  getLoaded,
  (state: BookingDetailState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getBookingDetailState,
  (state: BookingDetailState) => state.selectedId
);
const getSelectedBookingDetail = createSelector(
  getAllBookingDetail,
  getSelectedId,
  (bookingDetail, id) => {
    return bookingDetail ? Object.assign({}, bookingDetail) : undefined;
  }
);

export const bookingDetailQuery = {
  getLoaded,
  getError,
  getAllBookingDetail,
  getSelectedBookingDetail
};
