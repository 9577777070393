import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { SocialAuthPartialState } from './social-auth.reducer';
import {
  LoadSocialAuth,
  SocialAuthLoaded,
  SocialAuthLoadError,
  SocialAuthActionTypes
} from './social-auth.actions';
import {map} from "rxjs/operators";
import {SocialCallbackControllerService} from "@gtd/b2c-client";

@Injectable()
export class SocialAuthEffects {
  @Effect() loadSocialAuth$ = this.dataPersistence.fetch(
    SocialAuthActionTypes.LoadSocialAuth,
    {
      run: (action: LoadSocialAuth, state: SocialAuthPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.socialCallbackControllerService.signUpSocialUsingPOST(action.payload).pipe(
          map(update => ({
            type: SocialAuthActionTypes.SocialAuthLoaded,
            payload: {
              data: action.payload,
              token: update
            }
          }))
        );
      },

      onError: (action: LoadSocialAuth, error) => {
        console.error('Error', error);
        return new SocialAuthLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private socialCallbackControllerService: SocialCallbackControllerService,
    private dataPersistence: DataPersistence<SocialAuthPartialState>
  ) {}
}
