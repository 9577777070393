/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ApprovalProcessLiteDTO { 
    agencyCode?: string;
    description?: string;
    id?: number;
    processName?: string;
    target?: ApprovalProcessLiteDTO.TargetEnum;
}
export namespace ApprovalProcessLiteDTO {
    export type TargetEnum = 'IN_POLICY' | 'OUT_POLICY' | 'ALL_BOOKING';
    export const TargetEnum = {
        INPOLICY: 'IN_POLICY' as TargetEnum,
        OUTPOLICY: 'OUT_POLICY' as TargetEnum,
        ALLBOOKING: 'ALL_BOOKING' as TargetEnum
    };
}
