import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomerProfileDTO, CustomerTravellerDTO} from "@gtd/api-services/customer-service";
import {CustomerTravellerFacade} from "../../customer-traveller/+state/customer-traveller.facade";
import {MatDialog, MatSnackBar} from "@angular/material";
import {ProfileFacade} from "../+state/profile.facade";
import {Subscription} from "rxjs";
import {ResultUpdate} from "../+state/profile.reducer";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {DialogMessageComponent} from "@gtd/components/dialog-message";

@Component({
  selector: 'gtd-billing-information',
  templateUrl: './billing-information.component.html',
  styleUrls: ['./billing-information.component.scss']
})
export class BillingInformationComponent implements OnInit, OnDestroy {
  billingForm?: FormGroup;
  customerTravellers: CustomerTravellerDTO;
  subscriptions = new Subscription();
  customerProfileDTO: CustomerProfileDTO;

  constructor(
    private formBuilder: FormBuilder,
    private customerTravellerFacade: CustomerTravellerFacade,
    private profileFacade: ProfileFacade,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.profileFacade.selectedProfile$.subscribe(dataProfile => {
        if(dataProfile && dataProfile.lists && dataProfile.type==='LoadAll') {
          this.billingForm.patchValue({
            taxCompanyName: dataProfile.lists.taxCompanyName,
            taxNumber: dataProfile.lists.taxNumber,
            taxAddress1: dataProfile.lists.taxAddress1,
          })
          this.customerProfileDTO = dataProfile.lists;
        }
      })
    )

  }

  submitForm() {
    if(this.billingForm.valid) {
      this.customerProfileDTO.taxCompanyName = this.billingForm.get('taxCompanyName').value;
      this.customerProfileDTO.taxNumber = this.billingForm.get('taxNumber').value;
      this.customerProfileDTO.taxAddress1 = this.billingForm.get('taxAddress1').value;
      this.profileFacade.updateCustomerProfileUsingPut(this.customerProfileDTO);
      this.subscriptions.add(
        this.profileFacade.selectUpdateCustomerProfile$.subscribe((resultUpdate: ResultUpdate) => {
          if(resultUpdate && resultUpdate.type === "Update") {
            if(resultUpdate.lists && resultUpdate.lists.id) {
              this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.update-success', icon: 'check'}, panelClass: 'snackbar-success', verticalPosition: "top", duration: 2000});
            } else {
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [
                    {btnText: 'global.close'}
                  ],
                  content: 'user.system-error-message',
                  heading: 'user.error-message',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                position: {
                  top: "150px"
                },
                disableClose: true
              });
            }
          }
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private createForm() {
    this.billingForm = this.formBuilder.group({
      taxCompanyName: ['', Validators.nullValidator(null)],
      taxNumber: ['', Validators.nullValidator(null)],
      taxAddress1: ['', Validators.nullValidator(null)],
    })
  }
}
