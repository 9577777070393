import {
  ActivateAccountAction,
  ActivateAccountActionTypes
} from './activate-account.actions';

export const ACTIVATEACCOUNT_FEATURE_KEY = 'activateAccount';

/**
 * Interface for the 'ActivateAccount' data used in
 *  - ActivateAccountState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}
export interface ActivateAccountResult {
  success?: boolean;
  error?: boolean;
  message?: string;
}

export interface ActivateAccountState {
  result: ActivateAccountResult; // list of ActivateAccount; analogous to a sql normalized table
  selectedId?: string | number; // which ActivateAccount record has been selected
  loaded: boolean; // has the ActivateAccount list been loaded
  error?: any; // last none error (if any)
}

export interface ActivateAccountPartialState {
  readonly [ACTIVATEACCOUNT_FEATURE_KEY]: ActivateAccountState;
}

export const initialState: ActivateAccountState = {
  result: null,
  loaded: false
};

export function activateAccountReducer(
  state: ActivateAccountState = initialState,
  action: ActivateAccountAction
): ActivateAccountState {
  switch (action.type) {
    case ActivateAccountActionTypes.ActivateAccountLoaded: {
      state = {
        ...state,
        result: {
          success: true,
          message: "Activate success!"
        },
        loaded: true
      };
      break;
    }
    case ActivateAccountActionTypes.ActivateAccountLoadError: {
      state = {
        ...state,
        result: {
          error: true,
          message: "Activate error!"
        },
        loaded: true
      };
      break;
    }
  }
  return state;
}
