import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { TourFilterOptionsPartialState } from './tour-filter-options.reducer';
import { tourFilterOptionsQuery } from './tour-filter-options.selectors';
import {
  LoadTourFilterOptions,
  ResetTourFilterOptions
} from './tour-filter-options.actions';

@Injectable()
export class TourFilterOptionsFacade {
  loaded$ = this.store.pipe(select(tourFilterOptionsQuery.getLoaded));
  allTourFilterOptions$ = this.store.pipe(
    select(tourFilterOptionsQuery.getAllTourFilterOptions)
  );
  selectedTourFilterOptions$ = this.store.pipe(
    select(tourFilterOptionsQuery.getSelectedTourFilterOptions)
  );

  constructor(private store: Store<TourFilterOptionsPartialState>) {}

  loadAll(payload: 'vi' | 'en') {
    this.store.dispatch(new LoadTourFilterOptions(payload));
  }

  resetAll() {
    this.store.dispatch(new ResetTourFilterOptions());
  }
}
