/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PromotionDTO { 
    airlinesCode?: Array<string>;
    bankCode?: Array<string>;
    bankName?: Array<string>;
    cardType?: Array<string>;
    createdBy?: string;
    createdDate?: Date;
    description?: string;
    discountAmount?: number;
    discountValueType?: PromotionDTO.DiscountValueTypeEnum;
    effectiveFrom?: Date;
    effectiveTo?: Date;
    endTime?: string;
    id?: number;
    lowerPrice?: number;
    name?: string;
    priority?: number;
    productType?: Array<string>;
    promotionOption?: PromotionDTO.PromotionOptionEnum;
    promotionSlot?: number;
    promotionType?: PromotionDTO.PromotionTypeEnum;
    saleChannel?: Array<string>;
    startTime?: string;
    status?: PromotionDTO.StatusEnum;
    updatedBy?: string;
    updatedDate?: Date;
    upperPrice?: number;
    usedSlot?: number;
}
export namespace PromotionDTO {
    export type DiscountValueTypeEnum = 'FIXED' | 'PERCENT';
    export const DiscountValueTypeEnum = {
        FIXED: 'FIXED' as DiscountValueTypeEnum,
        PERCENT: 'PERCENT' as DiscountValueTypeEnum
    };
    export type PromotionOptionEnum = 'ANCILLARY' | 'TOTAL';
    export const PromotionOptionEnum = {
        ANCILLARY: 'ANCILLARY' as PromotionOptionEnum,
        TOTAL: 'TOTAL' as PromotionOptionEnum
    };
    export type PromotionTypeEnum = 'CREDIT_CARD' | 'AIRLINES_CODE' | 'VOUCHER';
    export const PromotionTypeEnum = {
        CREDITCARD: 'CREDIT_CARD' as PromotionTypeEnum,
        AIRLINESCODE: 'AIRLINES_CODE' as PromotionTypeEnum,
        VOUCHER: 'VOUCHER' as PromotionTypeEnum
    };
    export type StatusEnum = 'PUBLISHING' | 'PENDING' | 'TERMINATED' | 'FINISHED' | 'DRAFT';
    export const StatusEnum = {
        PUBLISHING: 'PUBLISHING' as StatusEnum,
        PENDING: 'PENDING' as StatusEnum,
        TERMINATED: 'TERMINATED' as StatusEnum,
        FINISHED: 'FINISHED' as StatusEnum,
        DRAFT: 'DRAFT' as StatusEnum
    };
}
