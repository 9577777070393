import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import * as mapTypes from "@agm/core/services/google-maps-types";
import {Animation} from "@agm/core/directives/marker";
import {PropertyAllRate} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-map-panel-detail',
  templateUrl: './map-panel-detail.component.html',
  styleUrls: ['./map-panel-detail.component.scss']
})
export class MapPanelDetailComponent implements OnInit {
  marker: Marker;
  zoom: number = 18;
  counter = Array;

  constructor(
    public dialogRef: MatDialogRef<MapPanelDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {hotelDetail: PropertyAllRate}
  ) { }

  ngOnInit() {
  }

  closePopup() {
    this.dialogRef.close();
  }

  mathFloor(star: any) {
    return Math.floor(star)
  }
  isFloat(star) {
    return (star % 1 != 0);
  }
}
interface Marker {
  lat: number;
  lng: number;
  label?: string | mapTypes.MarkerLabel;
  icon?: {
    labelOrigin?: {
      x: number,
      y: number
    },
    url?: string,
  },
  animation?: Animation,
  contents?: any,
  urlDetail?: string,
  draggable: boolean
}
