/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface GTDMFRequest { 
    bookingID?: string;
    bookingStatus?: GTDMFRequest.BookingStatusEnum;
    etickets?: string;
    pnr?: string;
    referenceID?: string;
    signature?: string;
    totalAmount?: number;
    totalNETAmount?: number;
    ttl?: Date;
}
export namespace GTDMFRequest {
    export type BookingStatusEnum = 'Tentative' | 'Booked' | 'Confirmed' | 'Book Failed' | 'Payment Failed' | 'Issue Failed' | 'Booking On Process' | 'Ticket On Process';
    export const BookingStatusEnum = {
        Tentative: 'Tentative' as BookingStatusEnum,
        Booked: 'Booked' as BookingStatusEnum,
        Confirmed: 'Confirmed' as BookingStatusEnum,
        BookFailed: 'Book Failed' as BookingStatusEnum,
        PaymentFailed: 'Payment Failed' as BookingStatusEnum,
        IssueFailed: 'Issue Failed' as BookingStatusEnum,
        BookingOnProcess: 'Booking On Process' as BookingStatusEnum,
        TicketOnProcess: 'Ticket On Process' as BookingStatusEnum
    };
}
