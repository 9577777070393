import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { Observable } from 'rxjs/Observable';
import { Configuration } from '../configuration';

@Injectable()
export class NewsContentService {
  protected basePath = 'https://www.gotadi.com';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }

  public getCMSContentUsingGET(
    slug: string,
    categories: number
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (slug !== undefined && slug !== null) {
      queryParameters = queryParameters.set('search', <any>slug);
    }

    if (categories !== undefined && categories !== null) {
      queryParameters = queryParameters.set('categories', <any>categories);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<any>(
      `${this.basePath}/tour/wp-json/wp/v2/posts`,
      {
        params: queryParameters,
        withCredentials: false,
        headers: headers
      }
    );
  }
}
