import {
  ComboCreateDraftBookingAction,
  ComboCreateDraftBookingActionTypes
} from './combo-create-draft-booking.actions';
import {ComboDraftBookingRS, OtaResultOfBooking} from '@gtd/b2c-client';

export const COMBOCREATEDRAFTBOOKING_FEATURE_KEY =
  'comboVJComboCreateDraftBooking';

/**
 * Interface for the 'ComboCreateDraftBooking' data used in
 *  - ComboCreateDraftBookingState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface ComboCreateDraftBookingState {
  result: ComboDraftBookingRS; // list of ComboCreateDraftBooking; analogous to a sql normalized table
  selectedId?: string | number; // which ComboCreateDraftBooking record has been selected
  loaded: boolean; // has the ComboCreateDraftBooking list been loaded
  error?: any; // last none error (if any)
}

export interface ComboCreateDraftBookingPartialState {
  readonly [COMBOCREATEDRAFTBOOKING_FEATURE_KEY]: ComboCreateDraftBookingState;
}

export const initialState: ComboCreateDraftBookingState = {
  result: null,
  loaded: false
};

export function ComboCreateDraftBookingReducer(
  state: ComboCreateDraftBookingState = initialState,
  action: ComboCreateDraftBookingAction
): ComboCreateDraftBookingState {
  switch (action.type) {
    case ComboCreateDraftBookingActionTypes.ComboVJComboCreateDraftBookingLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
    case ComboCreateDraftBookingActionTypes.ComboVJResetComboCreateDraftBooking: {
      state = {
        ...initialState
      };
      break;
    }
    case ComboCreateDraftBookingActionTypes.ComboVJComboCreateDraftBookingLoadError: {
      state = {
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
