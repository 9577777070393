/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IdAutosavesBody1 { 
    /**
     * The ID for the parent of the autosave.
     */
    parent?: string;
    /**
     * The date the post was published, in the site's timezone.
     */
    date?: Date;
    /**
     * The date the post was published, as GMT.
     */
    dateGmt?: Date;
    /**
     * An alphanumeric identifier for the post unique to its type.
     */
    slug?: string;
    /**
     * A named status for the post.
     */
    status?: Array<IdAutosavesBody1.StatusEnum>;
    /**
     * A password to protect access to the content and excerpt.
     */
    password?: string;
    /**
     * The title for the post.
     */
    title?: string;
    /**
     * The content for the post.
     */
    content?: string;
    /**
     * The ID for the author of the post.
     */
    author?: string;
    /**
     * The excerpt for the post.
     */
    excerpt?: string;
    /**
     * The ID of the featured media for the post.
     */
    featuredMedia?: string;
    /**
     * Whether or not comments are open on the post.
     */
    commentStatus?: Array<IdAutosavesBody1.CommentStatusEnum>;
    /**
     * Whether or not the post can be pinged.
     */
    pingStatus?: Array<IdAutosavesBody1.PingStatusEnum>;
    /**
     * The format for the post.
     */
    format?: Array<IdAutosavesBody1.FormatEnum>;
    /**
     * Meta fields.
     */
    meta?: string;
    /**
     * Whether or not the post should be treated as sticky.
     */
    sticky?: string;
    /**
     * The theme file to use to display the post.
     */
    template?: string;
    /**
     * The terms assigned to the post in the category taxonomy.
     */
    categories?: string;
    /**
     * The terms assigned to the post in the post_tag taxonomy.
     */
    tags?: string;
    /**
     * Expose advanced custom fields.
     */
    acf?: string;
}
export namespace IdAutosavesBody1 {
    export type StatusEnum = 'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled';
    export const StatusEnum = {
        Publish: 'publish' as StatusEnum,
        Future: 'future' as StatusEnum,
        Draft: 'draft' as StatusEnum,
        Pending: 'pending' as StatusEnum,
        Private: 'private' as StatusEnum,
        AcfDisabled: 'acf-disabled' as StatusEnum
    };
    export type CommentStatusEnum = 'open' | 'closed';
    export const CommentStatusEnum = {
        Open: 'open' as CommentStatusEnum,
        Closed: 'closed' as CommentStatusEnum
    };
    export type PingStatusEnum = 'open' | 'closed';
    export const PingStatusEnum = {
        Open: 'open' as PingStatusEnum,
        Closed: 'closed' as PingStatusEnum
    };
    export type FormatEnum = 'standard' | 'aside' | 'chat' | 'gallery' | 'link' | 'image' | 'quote' | 'status' | 'video' | 'audio';
    export const FormatEnum = {
        Standard: 'standard' as FormatEnum,
        Aside: 'aside' as FormatEnum,
        Chat: 'chat' as FormatEnum,
        Gallery: 'gallery' as FormatEnum,
        Link: 'link' as FormatEnum,
        Image: 'image' as FormatEnum,
        Quote: 'quote' as FormatEnum,
        Status: 'status' as FormatEnum,
        Video: 'video' as FormatEnum,
        Audio: 'audio' as FormatEnum
    };
}