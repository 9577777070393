import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {GroupItineraryPartialState} from './group-itinerary.reducer';
import {GroupItineraryActionTypes, GroupItineraryLoadError, LoadGroupItinerary} from './group-itinerary.actions';
import {map} from "rxjs/operators";
import {AirTicketsResourceService} from "@gtd/b2c-client";

@Injectable()
export class GroupItineraryEffects {
  @Effect() loadGroupItinerary$ = this.dataPersistence.fetch(
    GroupItineraryActionTypes.LoadGroupItinerary,
    {
      run: (action: LoadGroupItinerary, state: GroupItineraryPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.airTicketsResourceService.getGroupPricedItineraryUsingPOST(
          action.payload.id,
          action.payload.airSearchId,
          action.payload.includeEquivfare,
          action.payload.page,
          action.payload.size,
          action.payload.sort
         ).pipe(
          map(update => ({
            type: GroupItineraryActionTypes.GroupItineraryLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadGroupItinerary, error) => {
        console.error('Error', error);
        return new GroupItineraryLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private airTicketsResourceService: AirTicketsResourceService,
    private dataPersistence: DataPersistence<GroupItineraryPartialState>
  ) {}
}
