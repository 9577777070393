import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourSearchComponent } from './tour-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { TourSuggestionModule } from '@gtd/extra/tour/ui/tour-suggestion';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    TourSuggestionModule,
    TranslateModule,
    MatButtonModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [TourSearchComponent],
  exports: [TourSearchComponent]
})
export class TourSearchModule {}
