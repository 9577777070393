import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { HotelPartialState } from './hotel.reducer';
import {
  LoadHotel,
  HotelLoaded,
  HotelLoadError,
  HotelActionTypes
} from './hotel.actions';

import { HotelResourceService } from '@gtd/b2c-client';

@Injectable()
export class HotelEffects {
  @Effect() loadHotel$ = this.dataPersistence.fetch(
    HotelActionTypes.LoadHotel,
    {
      run: (action: LoadHotel, state: HotelPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        if (action.payload.toString().length < 3) {
          return new HotelLoaded([]);
        }
        return this.hotelResourceService
          .searchHotelMetaKeywordUsingPOST(action.payload)
          .pipe(
            map(update => ({
              type: HotelActionTypes.HotelLoaded,
              payload: update.result
            }))
          );
      },

      onError: (action: LoadHotel, error) => {
        console.error('Error', error);
        return new HotelLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<HotelPartialState>,
    private hotelResourceService: HotelResourceService
  ) {}
}
