import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { min } from 'rxjs-compat/operator/min';
import {
  PaxInfo,
  TourActivityBookingAction,
  TourActivityBookingActionTypes
} from './tour-activity-booking.actions';

export const TOURACTIVITYBOOKING_FEATURE_KEY = 'tourActivityBooking';

/**
 * Interface for the 'TourActivityBooking' data used in
 *  - TourActivityBookingState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface TourActivityBookingState {
  paxInfos: PaxInfo[];
  date: Date;
  ratePlanId: number;
  activityId: number;
}

export interface TourActivityBookingPartialState {
  readonly [TOURACTIVITYBOOKING_FEATURE_KEY]: TourActivityBookingState;
}

export const initialState: TourActivityBookingState = {
  paxInfos: [],
  date: null,
  ratePlanId: null,
  activityId: null
};

export function reducer(
  state: TourActivityBookingState = initialState,
  action: TourActivityBookingAction
): TourActivityBookingState {
  switch (action.type) {
    case TourActivityBookingActionTypes.SetPaxInfos: {
      state = {
        ...state,
        paxInfos: Array.from(action.payload)
      };
      break;
    }
    case TourActivityBookingActionTypes.SetDate: {
      state = {
        ...state,
        date: action.payload
      };
      break;
    }
    case TourActivityBookingActionTypes.SetRatePlanId: {
      state = {
        ...state,
        ratePlanId: action.payload
      };
      break;
    }
    case TourActivityBookingActionTypes.SetActivityId: {
      state = {
        ...state,
        activityId: action.payload
      };
      break;
    }
    case TourActivityBookingActionTypes.ResetActivityId: {
      state = {
        ...state,
        activityId: null
      };
      break;
    }
    case TourActivityBookingActionTypes.ResetRatePlanId: {
      state = {
        ...state,
        ratePlanId: null
      };
      break;
    }
    case TourActivityBookingActionTypes.ResetPaxInfos: {
      state = {
        ...state,
        paxInfos: []
      };
      break;
    }
    case TourActivityBookingActionTypes.ResetTourActivity: {
      state = {
        ...initialState
      };
      break;
    }
  }
  return state;
}
