import {Injectable} from '@angular/core';
import {SearchKeywordElement} from "@gtd/b2c-client";

@Injectable({
  providedIn: 'root'
})
export class HotelSearchService {

  constructor() { }

  getAllLocalStorageLocation(type: string): SearchKeywordElement[] {
    let localStorageData = localStorage.getItem(type);
    if(localStorageData) {
      return JSON.parse(localStorageData);
    }
    return null;
  }
}
