import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {CountUnreadPartialState} from './count-unread.reducer';
import {CountUnreadActionTypes, CountUnreadLoadError, LoadCountUnread} from './count-unread.actions';
import {map} from "rxjs/operators";
import {NotifyUserMessageResourceService} from "@gtd/api-services/utilitysrv";

@Injectable()
export class CountUnreadEffects {
  @Effect() loadCountUnread$ = this.dataPersistence.fetch(
    CountUnreadActionTypes.LoadCountUnread,
    {
      run: (action: LoadCountUnread, state: CountUnreadPartialState) => {
        return this.notifyUserMessageResourceService.getCountUnreadNotifyMessUsingGET(action.payload).pipe(
          map(payload => ({
            type: CountUnreadActionTypes.CountUnreadLoaded,
            payload: payload
          }))
        );
      },

      onError: (action: LoadCountUnread, error) => {
        console.error('Error', error);
        return new CountUnreadLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private notifyUserMessageResourceService: NotifyUserMessageResourceService,
    private dataPersistence: DataPersistence<CountUnreadPartialState>
  ) {}
}
