import { Component, OnInit } from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {DeviceDetectorService} from "ngx-device-detector";
import {environment} from '@env/environment';

@Component({
  selector: 'gtd-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss']
})
export class PoliciesComponent implements OnInit {
  config: SwiperConfigInterface;

  policies = [
    {
      img: environment.cdn.cdnGotadi + '/web/icons/price.png',
      namePl: 'home.always-cheap',
      excerpt: 'home.air-hotel-domestic-International'
    },
    {
      img: environment.cdn.cdnGotadi + '/web/icons/support.png',
      namePl: 'home.dedicated-customer-service',
      excerpt: 'home.call-center'
    },
    {
      img: environment.cdn.cdnGotadi + '/web/icons/payment.png',
      namePl: 'home.diverse-payment-methods',
      excerpt: 'home.payment-easy-absolutely-safe'
    }
  ]

  constructor(private deviceService: DeviceDetectorService) {
    this.config = {
      slidesPerView: this.deviceService.isDesktop()? 3 : this.deviceService.isTablet()? 2 : this.deviceService.isMobile()? 1 : 3,
      spaceBetween: 30,
      lazy: true
    };
  }

  ngOnInit() {
  }

}
