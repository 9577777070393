import { Action } from '@ngrx/store';
import { AirItineraryFilterRS, AirLowFareFilterVM } from '@gtd/b2c-client';

export enum LowestPriceFlightsActionTypes {
  LoadLowestPriceFlights = '[LowestPriceFlights] Load LowestPriceFlights',
  LowestPriceFlightsLoaded = '[LowestPriceFlights] LowestPriceFlights Loaded',
  LowestPriceFlightsLoadError = '[LowestPriceFlights] LowestPriceFlights Load Error',
  ResetLowestPriceFlights = '[LowestPriceFlights] Reset LowestPriceFlights'
}

export class LoadLowestPriceFlights implements Action {
  readonly type = LowestPriceFlightsActionTypes.LoadLowestPriceFlights;

  constructor(public payload: AirLowFareFilterVM) {}
}

export class LowestPriceFlightsLoadError implements Action {
  readonly type = LowestPriceFlightsActionTypes.LowestPriceFlightsLoadError;

  constructor(public payload: any) {}
}

export class LowestPriceFlightsLoaded implements Action {
  readonly type = LowestPriceFlightsActionTypes.LowestPriceFlightsLoaded;

  constructor(public payload: AirItineraryFilterRS) {}
}

export class ResetLowestPriceFlights implements Action {
  readonly type = LowestPriceFlightsActionTypes.ResetLowestPriceFlights;
}

export type LowestPriceFlightsAction =
  | LoadLowestPriceFlights
  | LowestPriceFlightsLoaded
  | LowestPriceFlightsLoadError
  | ResetLowestPriceFlights;

export const fromLowestPriceFlightsActions = {
  LoadLowestPriceFlights,
  LowestPriceFlightsLoaded,
  LowestPriceFlightsLoadError,
  ResetLowestPriceFlights
};
