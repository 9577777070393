import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOGINNAME_FEATURE_KEY, LoginNameState } from './login-name.reducer';

// Lookup the 'LoginName' feature state managed by NgRx
const getLoginNameState = createFeatureSelector<LoginNameState>(
  LOGINNAME_FEATURE_KEY
);

const getLoaded = createSelector(
  getLoginNameState,
  (state: LoginNameState) => state.loaded
);
const getError = createSelector(
  getLoginNameState,
  (state: LoginNameState) => state.error
);

const getAllLoginName = createSelector(
  getLoginNameState,
  getLoaded,
  (state: LoginNameState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getLoginNameState,
  (state: LoginNameState) => state.selectedId
);
const getSelectedLoginName = createSelector(
  getAllLoginName,
  getSelectedId,
  (loginName, id) => {
    return loginName ? Object.assign({}, loginName) : undefined;
  }
);

export const loginNameQuery = {
  getLoaded,
  getError,
  getAllLoginName,
  getSelectedLoginName
};
