import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivateAccountFacade } from './activate-account-state/+state/activate-account.facade';
import { MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gtd-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
  allActivateAccount$ = this.accountFacade.allActivateAccount$;
  keyActivate: string;
  subscriptions = new Subscription();
  submit: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private accountFacade: ActivateAccountFacade
  ) {
    activatedRoute.queryParams.subscribe(param => {
      if (param.key) {
        this.keyActivate = param.key;
        accountFacade.activateAccount(param.key);
      }
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.allActivateAccount$.subscribe(allActivateAccount => {
        if (allActivateAccount) {
          if (allActivateAccount.success) {
            this._snackBar.openFromComponent(SnackbarComponent, {
              data: {
                message: 'account.activate-account-success',
                icon: 'check'
              },
              panelClass: 'snackbar-success',
              verticalPosition: 'top',
              duration: 2000
            });
          } else if (allActivateAccount.error && this.submit) {
            this._snackBar.openFromComponent(SnackbarComponent, {
              data: {
                message: 'account.activate-account-error',
                icon: 'exclamation-triangle'
              },
              panelClass: 'snackbar-error',
              verticalPosition: 'top',
              duration: 2000
            });
          }
        }
      })
    );
  }

  reactivate() {
    this.submit = true;
    if (this.keyActivate) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'account.activating-account', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
      setTimeout(() => {
        this.accountFacade.activateAccount(this.keyActivate);
      }, 2000);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
