/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AgencyMarkupFormula } from './agencyMarkupFormula';
import { Info } from './info';
import { MarkupFormula } from './markupFormula';


export interface ProductMarkupFormulaValueRes { 
    businessType?: ProductMarkupFormulaValueRes.BusinessTypeEnum;
    code?: string;
    duration?: number;
    errors?: Array<Error>;
    infos?: Array<Info>;
    isSuccess?: boolean;
    markupFormula?: MarkupFormula;
    parentAgencyMarkupFormulas?: Array<AgencyMarkupFormula>;
    productType?: ProductMarkupFormulaValueRes.ProductTypeEnum;
    success?: boolean;
    textMessage?: string;
}
export namespace ProductMarkupFormulaValueRes {
    export type BusinessTypeEnum = 'B2C' | 'B2B';
    export const BusinessTypeEnum = {
        B2C: 'B2C' as BusinessTypeEnum,
        B2B: 'B2B' as BusinessTypeEnum
    };
    export type ProductTypeEnum = 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR';
    export const ProductTypeEnum = {
        AIR: 'AIR' as ProductTypeEnum,
        HOTEL: 'HOTEL' as ProductTypeEnum,
        AIRCOMBO: 'AIRCOMBO' as ProductTypeEnum,
        HOTELCOMBO: 'HOTELCOMBO' as ProductTypeEnum,
        AIRCOMBOVIETJET: 'AIRCOMBOVIETJET' as ProductTypeEnum,
        TOUR: 'TOUR' as ProductTypeEnum
    };
}
