/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CardSubscription } from './cardSubscription';


export interface CardSubscriptionReq { 
    card?: CardSubscription;
    ibeSessionId?: string;
    paToken?: string;
    requesterCode?: string;
    requesterType?: CardSubscriptionReq.RequesterTypeEnum;
    trackingCode?: string;
    xid?: string;
}
export namespace CardSubscriptionReq {
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
