import {Action} from '@ngrx/store';
import {AirFareRuleRQ, AirFareRuleRS} from "@gtd/b2c-client";

export enum FarerulesActionTypes {
  LoadFarerules = '[Farerules] Load Farerules',
  FarerulesLoaded = '[Farerules] Farerules Loaded',
  FarerulesLoadError = '[Farerules] Farerules Load Error'
}

export class LoadFarerules implements Action {
  readonly type = FarerulesActionTypes.LoadFarerules;
  constructor(public payload: AirFareRuleRQ, public language: string) {}
}

export class FarerulesLoadError implements Action {
  readonly type = FarerulesActionTypes.FarerulesLoadError;
  constructor(public payload: any) {}
}

export class FarerulesLoaded implements Action {
  readonly type = FarerulesActionTypes.FarerulesLoaded;
  constructor(public payload: AirFareRuleRS) {}
}

export type FarerulesAction =
  | LoadFarerules
  | FarerulesLoaded
  | FarerulesLoadError;

export const fromFarerulesActions = {
  LoadFarerules,
  FarerulesLoaded,
  FarerulesLoadError
};
