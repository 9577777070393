import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {LoginNamePartialState} from './login-name.reducer';
import {LoadLoginName, LoginNameActionTypes, LoginNameLoadError} from './login-name.actions';
import {map} from "rxjs/operators";
import {AgentProfileResourceService} from "../../lib/index";

@Injectable()
export class LoginNameEffects {
  @Effect() loadLoginName$ = this.dataPersistence.fetch(
    LoginNameActionTypes.LoadLoginName,
    {
      run: (action: LoadLoginName, state: LoginNamePartialState) => {
        return this.agentProfileResourceService.retrieveProfileByLoginNameUsingPOST(action.payload).pipe(
            map(result => ({
              type: LoginNameActionTypes.LoginNameLoaded,
              payload: result
            }))
          );
      },

      onError: (action: LoadLoginName, error) => {
        console.error('Error', error);
        return new LoginNameLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private agentProfileResourceService: AgentProfileResourceService,
    private dataPersistence: DataPersistence<LoginNamePartialState>
  ) {}
}
