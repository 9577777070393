import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TOURFILTEROPTIONS_FEATURE_KEY,
  TourFilterOptionsState
} from './tour-filter-options.reducer';

// Lookup the 'TourFilterOptions' feature state managed by NgRx
const getTourFilterOptionsState = createFeatureSelector<TourFilterOptionsState>(
  TOURFILTEROPTIONS_FEATURE_KEY
);

const getLoaded = createSelector(
  getTourFilterOptionsState,
  (state: TourFilterOptionsState) => state.loaded
);
const getError = createSelector(
  getTourFilterOptionsState,
  (state: TourFilterOptionsState) => state.error
);

const getAllTourFilterOptions = createSelector(
  getTourFilterOptionsState,
  getLoaded,
  (state: TourFilterOptionsState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getTourFilterOptionsState,
  (state: TourFilterOptionsState) => state.selectedId
);
const getSelectedTourFilterOptions = createSelector(
  getAllTourFilterOptions,
  getSelectedId,
  (tourFilterOptions, id) => {
    // const result = tourFilterOptions.find(it => it['id'] === id);
    return tourFilterOptions ? Object.assign({}, tourFilterOptions) : undefined;
  }
);

export const tourFilterOptionsQuery = {
  getLoaded,
  getError,
  getAllTourFilterOptions,
  getSelectedTourFilterOptions
};
