import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FilterOptionsPartialState } from './filter-options.reducer';
import { filterOptionsQuery } from './filter-options.selectors';
import { ComboVJLoadFilterOptions } from './filter-options.actions';

@Injectable()
export class FilterOptionsFacade {
  loaded$ = this.store.pipe(select(filterOptionsQuery.getLoaded));
  allFilterOptions$ = this.store.pipe(
    select(filterOptionsQuery.getAllFilterOptions)
  );
  selectedFilterOptions$ = this.store.pipe(
    select(filterOptionsQuery.getSelectedFilterOptions)
  );

  constructor(private store: Store<FilterOptionsPartialState>) {}

  loadAll(language: 'vi' | 'en') {
    this.store.dispatch(new ComboVJLoadFilterOptions(language));
  }
}
