import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbDatepickerI18n,
  NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';
import { DatepickerService, LunarDateExport } from './datepicker.service';
import { HotelFunctionService } from '@gtd/hotels/data-access/services';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomDatepickerI18n,
  TranslateDatePicker
} from './date-format.directive';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'gtd-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    TranslateDatePicker,
    DatePipe,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements OnInit, OnChanges {
  @ViewChild('datepicker', { static: true }) datePicker: NgbInputDatepicker;
  @Input() submited: boolean;
  @Input() minDate: Date = new Date();
  @Input() maxDate: Date;
  @Input() defaultFromDate: string;
  @Input() defaultToDate: string;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  dateType: 'to' | 'from';
  minNgbDate: NgbDate;
  maxNgbDate: NgbDate;
  lang: any;
  numberOfDays: any;
  type: string = 'hotel';
  focus: string;
  constructor(
    public calendar: NgbCalendar,
    public datepickerService: DatepickerService,
    private translateDatePicker: TranslateDatePicker,
    public translate: TranslateService,
    private hotelFunctionService: HotelFunctionService,
    private datePipe: DatePipe
  ) {
    this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
      this.translateDatePicker.language = lang.lang;
    });
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.numberOfDays = this.hotelFunctionService.getDayGap(
      `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`,
      `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
    );
  }
  ngOnInit() {
    this.lang = localStorage.getItem('language');
    this.translateDatePicker.language = localStorage.getItem('language');

    if (this.minDate) {
      this.minNgbDate = this.convertNgbDate(this.minDate);
    }
    if (this.maxDate) {
      this.maxNgbDate = this.convertNgbDate(this.maxDate);
    } else {
      this.maxNgbDate = this.convertNgbDate(
        new Date(new Date().setDate(new Date().getDate() + 361))
      );
    }
  }

  convertNgbDate(date: Date): NgbDate {
    return this.calendar.getNext(
      {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        after(other: NgbDateStruct): boolean {
          return true;
        },
        before(other: NgbDateStruct): boolean {
          return true;
        },
        equals(other: NgbDateStruct): boolean {
          return true;
        }
      },
      'd',
      0
    );
  }
  updateDateData(dateType: 'from' | 'to', date: NgbDate) {
    if (dateType === 'from') {
      this.fromDate = date;
    } else {
      this.toDate = date;
    }
  }
  onDateSelection(date: NgbDate) {
    if (this.dateType === 'from') {
      if (!this.fromDate && !this.toDate) {
        this.updateDateData('from', date);
      } else if (
        this.fromDate &&
        !this.toDate &&
        (date.after(this.fromDate) || date.equals(this.fromDate))
      ) {
        this.updateDateData('to', date);
        this.datePicker.toggle();
      } else if (this.fromDate && !this.toDate && date.before(this.fromDate)) {
        this.updateDateData('from', date);
      } else if (
        date.after(this.toDate) ||
        (date.equals(this.toDate) && this.type === 'hotel')
      ) {
        this.focus = 'to';

        this.toDate = null;
        this.updateDateData('from', date);
      } else {
        this.updateDateData('from', date);
        this.datePicker.toggle();
      }
    } else {
      if (
        (!this.fromDate && !this.toDate) ||
        date.before(this.fromDate) ||
        (date.equals(this.fromDate) && this.type === 'hotel')
      ) {
        this.focus = 'to';

        this.toDate = null;
        this.updateDateData('from', date);
      } else {
        this.updateDateData('to', date);
        this.datePicker.toggle();
      }
    }
    if (this.fromDate && this.toDate) {
      this.numberOfDays = this.hotelFunctionService.getDayGap(
        `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`,
        `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
      );
    }

    this.onChange({
      fromDate: this.datePipe.transform(
        new Date(
          Date.UTC(
            this.fromDate.year,
            this.fromDate.month - 1,
            this.fromDate.day
          )
        ),
        'yyyy-MM-dd'
      ),
      toDate: this.toDate
        ? this.datePipe.transform(
            new Date(
              Date.UTC(this.toDate.year, this.toDate.month - 1, this.toDate.day)
            ),
            'yyyy-MM-dd'
          )
        : ''
    });
    this.onTouched();
  }
  // onDateSelection(date: NgbDate) {
  //   if (this.dateType === 'from') {
  //     this.fromDate = date;
  //   } else {
  //     this.toDate = date;
  //   }

  //   if (this.fromDate && this.toDate) {
  //     this.numberOfDays = this.hotelFunctionService.getDayGap(
  //       `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`,
  //       `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
  //     );
  //   }
  // }
  openDatepicker(type: 'from' | 'to') {
    this.dateType = type;
    this.focus = type;
  }

  checkPosition(date: NgbDate) {
    if (date.equals(this.fromDate) && date.equals(this.toDate)) {
      return 'both';
    } else if (date.equals(this.fromDate)) {
      return 'first';
    } else if (date.equals(this.toDate)) {
      return 'last';
    }
  }
  isValid(date: NgbDate) {
    return (
      date.before(this.maxNgbDate) ||
      date.after(this.minNgbDate) ||
      date.equals(this.maxNgbDate) ||
      date.equals(this.minNgbDate)
    );
  }
  isPreviousDate(date: NgbDate) {
    if (
      this.dateType === 'to' &&
      this.hoveredDate &&
      this.hoveredDate.after(this.toDate)
    )
      return date.equals(this.toDate);
  }
  isHovered(date: NgbDate) {
    if (this.dateType === 'to' && this.toDate) {
      return date.after(this.toDate) && date.before(this.hoveredDate);
    }
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }
  isLastHovered(date: NgbDate) {
    if (this.dateType === 'from' && date.after(this.toDate)) {
      return false;
    }
    if (this.dateType === 'to' && this.toDate) {
      return date.after(this.toDate) && date.equals(this.hoveredDate);
    }
    return date.after(this.fromDate) && date.equals(this.hoveredDate);
  }
  onDateItemClick(dateType: 'from' | 'to') {
    this.dateType = dateType;
  }
  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  getDateLunar(date: NgbDate): LunarDateExport {
    const offset = new Date().getTimezoneOffset();
    return this.datepickerService.convertSolar2Lunar(
      date.day,
      date.month,
      date.year,
      +7
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { defaultFromDate, defaultToDate } = changes;
    if (defaultFromDate) {
      this.fromDate = this.convertNgbDate(
        new Date(defaultFromDate.currentValue)
      );
    }
    if (defaultToDate) {
      this.toDate = this.convertNgbDate(new Date(defaultToDate.currentValue));
    }
    if (this.fromDate && this.toDate) {
      this.numberOfDays = this.hotelFunctionService.getDayGap(
        `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`,
        `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
      );
    }
  }

  writeValue(obj: any): void {
    if (obj) {
      // this.destination = obj;
      // this.hotelName.setValue(obj.locationName);
      // this.form.setValue(obj);
    }

    if (obj === null) {
      // this.form.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  onChange: any = () => {};
  onTouched: any = () => {};

  validate(_: FormControl) {
    return this.fromDate && this.toDate ? null : { date: { valid: false } };
  }
}
