import {
  PhoneResendOtpStateAction,
  PhoneResendOtpStateActionTypes
} from './phone-resend-otp-state.actions';

export const PHONERESENDOTPSTATE_FEATURE_KEY = 'phoneResendOtpState';

/**
 * Interface for the 'PhoneResendOtpState' data used in
 *  - PhoneResendOtpStateState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface PhoneResendOtpStateState {
  result: any; // list of PhoneResendOtpState; analogous to a sql normalized table
  selectedId?: string | number; // which PhoneResendOtpState record has been selected
  loaded: boolean; // has the PhoneResendOtpState list been loaded
  error?: any; // last none error (if any)
  status? : boolean;
  errorMessage?: string;
  errorCode?: number;
}

export interface PhoneResendOtpStatePartialState {
  readonly [PHONERESENDOTPSTATE_FEATURE_KEY]: PhoneResendOtpStateState;
}

export const initialState: PhoneResendOtpStateState = {
  result: null,
  loaded: false
};

export function reducerResendOtp(
  state: PhoneResendOtpStateState = initialState,
  action: PhoneResendOtpStateAction
): PhoneResendOtpStateState {
  switch (action.type) {
    case PhoneResendOtpStateActionTypes.PhoneResendOtpStateLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true,
        status: true
      };
      break;
    }

    case PhoneResendOtpStateActionTypes.PhoneResendOtpStateLoadError: {
      state = {
        ...state,
        result: action.payload,
        errorMessage : action.payload.error.error? action.payload.error.error.message: 'liu liu',
        errorCode: action.payload.error.error? action.payload.error.error.code: 4044,
        loaded: true,
        status: false
      };
      break;
    }
    case PhoneResendOtpStateActionTypes.LoadDestroyPhoneResendOtpState: {
      state = initialState;
      break;
    }
  }
  return state;
}
