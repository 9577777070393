import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FlightSearchPartialState } from './flight-search.reducer';
import {
  LoadFlightSearch,
  FlightSearchLoaded,
  FlightSearchLoadError,
  FlightSearchActionTypes,
  LoadFlightIntSearch
} from './flight-search.actions';

import { AirTicketsResourceService } from '@gtd/b2c-client';
import { map, takeUntil } from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';

@Injectable()
export class FlightSearchEffects {
  @Effect() loadFlightSearch$ = this.dataPersistence.fetch(
    FlightSearchActionTypes.LoadFlightSearch,
    {
      run: (action: LoadFlightSearch, state: FlightSearchPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.airTicketsResourceService
          .lowFareSearchAsyncUsingGET(
            action.payload.originCode,
            action.payload.destinationCode,
            action.payload.departureDate,
            action.payload.time,
            action.payload.key,
            action.payload.returntureDate,
            action.payload.cabinClass,
            action.payload.routeType,
            action.payload.adutsQtt,
            action.payload.childrenQtt,
            action.payload.infantsQtt,
            action.payload.suppliers,
            action.payload.includeEquivfare,
            '',
            action.payload.combo,
            false,
            action.payload.page,
            action.payload.size,
            action.payload.sort
          )
          .pipe(
            map(result => ({
              type: FlightSearchActionTypes.FlightSearchLoaded,
              payload: result
            })),
            takeUntil(this.subService.unsubscribe$)
          );
      },

      onError: (action: LoadFlightSearch, error) => {
        console.error('Error', error);
        return new FlightSearchLoadError(error);
      }
    }
  );

  @Effect() loadFlightIntSearch$ = this.dataPersistence.fetch(
    FlightSearchActionTypes.LoadFlightIntSearch,
    {
      run: (action: LoadFlightIntSearch, state: FlightSearchPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.airTicketsResourceService
          .lowFareSearchInternationalAsyncUsingGET(
            action.payload.originCode,
            action.payload.destinationCode,
            action.payload.departureDate,
            action.payload.time,
            action.payload.key,
            action.payload.returntureDate,
            action.payload.cabinClass,
            action.payload.routeType,
            action.payload.adutsQtt,
            action.payload.childrenQtt,
            action.payload.infantsQtt,
            action.payload.includeEquivfare,
            '',
            action.payload.page,
            action.payload.size,
            action.payload.sort
          )
          .pipe(
            map(result => ({
              type: FlightSearchActionTypes.FlightSearchLoaded,
              payload: result
            })),
            takeUntil(this.subService.unsubscribe$)
          );
      },

      onError: (action: LoadFlightIntSearch, error) => {
        console.error('Error', error);
        return new FlightSearchLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<FlightSearchPartialState>,
    private airTicketsResourceService: AirTicketsResourceService,
    private subService: SubscriptionService
  ) {}
}
