import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  AccountFacade,
  AdditionalRequestResourceService
} from '@gtd/b2c-client';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { fadeIn, fadeInOut } from '@gtd/helpers';
import { AdditionalRequestDetailComponent } from '../../../additional-request-detail/additional-request-detail.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GetUserProfileFacade } from '@gtd/api-services/b2b-client';

@Component({
  selector: 'gtd-additional-request-history',
  templateUrl: './additional-request-history.component.html',
  styleUrls: ['./additional-request-history.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('600ms ease-out')),
      transition('default => rotated', animate('600ms ease-in'))
    ]),
    fadeIn,
    fadeInOut
  ]
})
export class AdditionalRequestHistoryComponent implements OnInit {
  bookingNumber: string;
  isLoading = true;
  additionalRequests: any[] = [];
  additionalRequest: any = {};
  subscriptions = new Subscription();
  currentPage = 1;
  pageSize = 8;
  totalAdditionalRequests = 0;
  configPagination: any;
  requester = '';
  appName = localStorage.getItem('appName');

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountFacade: AccountFacade,
    private userProfileFacade: GetUserProfileFacade,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private additionalRequestResourceService: AdditionalRequestResourceService
  ) {}

  ngOnInit() {
    this.pageSize = this.deviceService.isDesktop() ? 8 : 5;
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(params => {
        this.bookingNumber = params.booking_number;
        if (this.appName === 'B2C') {
          this.accountFacade.getAccountLogged$.subscribe(selectedAccount => {
            if (selectedAccount && selectedAccount.email) {
              this.requester = selectedAccount.email;
              this.getAdditionalRequests();
            }
          });
        } else if (this.appName === 'B2B') {
          this.subscriptions.add(
            this.userProfileFacade.allGetUserProfile$.subscribe(userProfile => {
              if (userProfile && userProfile.loginUsername) {
                this.requester = userProfile.loginUsername;
                this.getAdditionalRequests();
              }
            })
          );
        }
      })
    );
  }

  getAdditionalRequests() {
    this.isLoading = false;
    this.additionalRequestResourceService
      .searchBookingAdditionalRequestsUsingGET(
        null,
        null,
        null,
        null,
        null,
        this.requester,
        this.bookingNumber,
        null,
        null,
        null,
        null,
        this.appName === 'B2B' ? 'B2B' : 'B2C_WEB',
        this.currentPage - 1,
        this.pageSize
      )
      .subscribe(
        resp => {
          this.isLoading = true;
          this.totalAdditionalRequests = resp.totalElements;
          this.additionalRequests = [];
          if (resp.content.length > 0) {
            this.additionalRequests = JSON.parse(JSON.stringify(resp.content));
            if (
              this.additionalRequest &&
              Object.keys(this.additionalRequest).length === 0 &&
              Object.getPrototypeOf(this.additionalRequest) === Object.prototype
            ) {
              this.additionalRequest = this.additionalRequests[0];
            }
            this.additionalRequests.forEach(item => {
              item.requestTypeName = '';
              switch (item.requestType) {
                case 'AIR_ADD_PACKAGE': {
                  item.requestTypeName =
                    'booking.buy-signed-luggage-for-ticket';
                  break;
                }
                case 'AIR_ADD_INFORMATION': {
                  item.requestTypeName = 'booking.change-information-on-ticket';
                  break;
                }
                case 'AIR_DELETE_BOOKING': {
                  item.requestTypeName = 'booking.refund-cancellation-ticket';
                  break;
                }
                case 'HOTEL_ADD_PERSON': {
                  item.requestTypeName = 'booking.more-people';
                  break;
                }
                case 'HOTEL_EDIT_BOOKING': {
                  item.requestTypeName = 'booking.change-room-information';
                  break;
                }
                case 'HOTEL_DELETE_BOOKING': {
                  item.requestTypeName = 'booking.refund-cancellation-room';
                  break;
                }
                case 'HOTEL_CHANGE_ROOM': {
                  item.requestTypeName = 'booking.change-room';
                  break;
                }
                default: {
                  item.requestTypeName = 'booking.other-requirement';
                  break;
                }
              }
            });
          }
        },
        () => {
          this.isLoading = true;
        }
      );
  }

  openAdditionalRequestDetailDialog(additonalRequest: any) {
    const dialogRef = this.dialog.open(AdditionalRequestDetailComponent, {
      data: {
        additionalRequestItem: additonalRequest
      },
      position: {
        right: '0',
        top: '0'
      },
      autoFocus: false,
      panelClass: [
        'animate__animated',
        'animate__slideInRight',
        'animate__faster',
        'additional-request-detail-dialog-panel-class'
      ],
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.action === 'cancel-request') {
        this.ngOnInit();
      }
    });
  }

  copyBookingNumber() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.bookingNumber;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openNotificationSnackBar(
      'additional-request.copy-booking-number',
      '',
      'snackbar-success'
    );
  }

  openNotificationSnackBar(message: string, icon: string, panelClass: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: icon
      },
      panelClass: panelClass,
      verticalPosition: 'top',
      duration: 2000
    });
  }

  pageChanged(event: number) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.currentPage = event;
    this.additionalRequests = [];
    this.getAdditionalRequests();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
