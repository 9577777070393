/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AirBookFlightRQ } from '../model/airBookFlightRQ';
import { AirBookedFareRuleRS } from '../model/airBookedFareRuleRS';
import { BookingCodeRS } from '../model/bookingCodeRS';
import { BookingPnrRQ } from '../model/bookingPnrRQ';
import { GroupBooking } from '../model/groupBooking';
import { HotelBookingRQ } from '../model/hotelBookingRQ';
import { ProductIssueStatusRes } from '../model/productIssueStatusRes';
import { ResponseEntity } from '../model/responseEntity';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProductResourceService {

    protected basePath = 'https://uat-b2b.gotadi.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * getBookingDetail
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingDetailUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<GroupBooking>;
    public getBookingDetailUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GroupBooking>>;
    public getBookingDetailUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GroupBooking>>;
    public getBookingDetailUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getBookingDetailUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GroupBooking>(`${this.basePath}/api/products/booking-detail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * hotelOrder
     *
     * @param hotelBookingRQ hotelBookingRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hotelOrderUsingPOST(hotelBookingRQ: HotelBookingRQ, observe?: 'body', reportProgress?: boolean): Observable<BookingCodeRS>;
    public hotelOrderUsingPOST(hotelBookingRQ: HotelBookingRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodeRS>>;
    public hotelOrderUsingPOST(hotelBookingRQ: HotelBookingRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodeRS>>;
    public hotelOrderUsingPOST(hotelBookingRQ: HotelBookingRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotelBookingRQ === null || hotelBookingRQ === undefined) {
            throw new Error('Required parameter hotelBookingRQ was null or undefined when calling hotelOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodeRS>(`${this.basePath}/api/products/hotel-order-issue`,
            hotelBookingRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieveAirFareRules
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveAirFareRulesUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<AirBookedFareRuleRS>;
    public retrieveAirFareRulesUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirBookedFareRuleRS>>;
    public retrieveAirFareRulesUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirBookedFareRuleRS>>;
    public retrieveAirFareRulesUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling retrieveAirFareRulesUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AirBookedFareRuleRS>(`${this.basePath}/api/products/air-farerules/${encodeURIComponent(String(bookingNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ticketOrder
     *
     * @param airTicketOrderRQ airTicketOrderRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ticketOrderUsingPOST(airTicketOrderRQ: AirBookFlightRQ, observe?: 'body', reportProgress?: boolean): Observable<ProductIssueStatusRes>;
    public ticketOrderUsingPOST(airTicketOrderRQ: AirBookFlightRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductIssueStatusRes>>;
    public ticketOrderUsingPOST(airTicketOrderRQ: AirBookFlightRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductIssueStatusRes>>;
    public ticketOrderUsingPOST(airTicketOrderRQ: AirBookFlightRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (airTicketOrderRQ === null || airTicketOrderRQ === undefined) {
            throw new Error('Required parameter airTicketOrderRQ was null or undefined when calling ticketOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductIssueStatusRes>(`${this.basePath}/api/products/air-tickets-issue`,
            airTicketOrderRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBookingPNR
     *
     * @param bookingNumber bookingNumber
     * @param bookingPnr bookingPnr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingPNRUsingPUT(bookingNumber: string, bookingPnr: BookingPnrRQ, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public updateBookingPNRUsingPUT(bookingNumber: string, bookingPnr: BookingPnrRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public updateBookingPNRUsingPUT(bookingNumber: string, bookingPnr: BookingPnrRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public updateBookingPNRUsingPUT(bookingNumber: string, bookingPnr: BookingPnrRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling updateBookingPNRUsingPUT.');
        }

        if (bookingPnr === null || bookingPnr === undefined) {
            throw new Error('Required parameter bookingPnr was null or undefined when calling updateBookingPNRUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResponseEntity>(`${this.basePath}/api/products/${encodeURIComponent(String(bookingNumber))}/pnr`,
            bookingPnr,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
