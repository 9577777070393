import { Action } from '@ngrx/store';
import { PromotionRedemptionReq } from '../../lib/model/promotionRedemptionReq';
import { PromotionRedemptionRes } from '../../lib/model/promotionRedemptionRes';
import { Entity } from './promotion-validate.reducer';

export enum PromotionValidateActionTypes {
  LoadPromotionValidate = '[PromotionValidate] Load PromotionValidate',
  PromotionValidateLoaded = '[PromotionValidate] PromotionValidate Loaded',
  PromotionValidateLoadError = '[PromotionValidate] PromotionValidate Load Error'
}

export class LoadPromotionValidate implements Action {
  readonly type = PromotionValidateActionTypes.LoadPromotionValidate;
  constructor(public payload: PromotionRedemptionReq) {}
}

export class PromotionValidateLoadError implements Action {
  readonly type = PromotionValidateActionTypes.PromotionValidateLoadError;
  constructor(public payload: any) {}
}

export class PromotionValidateLoaded implements Action {
  readonly type = PromotionValidateActionTypes.PromotionValidateLoaded;
  constructor(public payload: PromotionRedemptionRes) {}
}

export type PromotionValidateAction =
  | LoadPromotionValidate
  | PromotionValidateLoaded
  | PromotionValidateLoadError;

export const fromPromotionValidateActions = {
  LoadPromotionValidate,
  PromotionValidateLoaded,
  PromotionValidateLoadError
};
