/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingDTO } from './bookingDTO';


export interface BookingTransactionDTO { 
    agencyMarkupValue?: number;
    allowHold?: boolean;
    baseFare?: number;
    booking?: BookingDTO;
    bookingCode?: string;
    bookingDate?: Date;
    bookingDirection?: BookingTransactionDTO.BookingDirectionEnum;
    bookingId?: number;
    bookingNumber?: string;
    bookingRefNo?: string;
    carrierNo?: string;
    channelType?: BookingTransactionDTO.ChannelTypeEnum;
    checkIn?: Date;
    checkInProcessStatusNextDate?: Date;
    checkOut?: Date;
    commissionValue?: number;
    createdBy?: string;
    createdDate?: Date;
    destinationLocationCode?: string;
    destinationLocationName?: string;
    detail?: string;
    equivFare?: number;
    etickets?: string;
    fareCode?: string;
    id?: number;
    infantNetPrice?: number;
    issuedBy?: string;
    issuedDate?: Date;
    issuedStatus?: BookingTransactionDTO.IssuedStatusEnum;
    markupCode?: string;
    markupFormula?: string;
    markupKey?: string;
    markupType?: BookingTransactionDTO.MarkupTypeEnum;
    markupValue?: number;
    noAdult?: number;
    noChild?: number;
    noInfant?: number;
    noSegment?: number;
    onlyPayLater?: boolean;
    originLocationCode?: string;
    originLocationName?: string;
    passengerNameRecord?: string;
    paymentAmount?: number;
    productClass?: string;
    productCode?: string;
    productName?: string;
    productSeqNumber?: string;
    refundable?: boolean;
    saleChannel?: BookingTransactionDTO.SaleChannelEnum;
    serviceTax?: number;
    sessionGroupId?: string;
    sessionSearchId?: string;
    signature?: string;
    status?: BookingTransactionDTO.StatusEnum;
    supplierCode: string;
    supplierName: string;
    supplierType?: BookingTransactionDTO.SupplierTypeEnum;
    timeToLive?: Date;
    totalFare?: number;
    totalFareForMarkup?: number;
    totalSsrValue?: number;
    totalTax?: number;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace BookingTransactionDTO {
    export type BookingDirectionEnum = 'DEPARTURE' | 'RETURN' | 'TRIP';
    export const BookingDirectionEnum = {
        DEPARTURE: 'DEPARTURE' as BookingDirectionEnum,
        RETURN: 'RETURN' as BookingDirectionEnum,
        TRIP: 'TRIP' as BookingDirectionEnum
    };
    export type ChannelTypeEnum = 'ONLINE' | 'OFFLINE';
    export const ChannelTypeEnum = {
        ONLINE: 'ONLINE' as ChannelTypeEnum,
        OFFLINE: 'OFFLINE' as ChannelTypeEnum
    };
    export type IssuedStatusEnum = 'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED';
    export const IssuedStatusEnum = {
        PENDING: 'PENDING' as IssuedStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as IssuedStatusEnum,
        SUCCEEDED: 'SUCCEEDED' as IssuedStatusEnum,
        FAILED: 'FAILED' as IssuedStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as IssuedStatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as IssuedStatusEnum
    };
    export type MarkupTypeEnum = 'PER_PAX_PER_BOOKING' | 'PER_PAX_PER_SEGMENT' | 'PER_BOOKING';
    export const MarkupTypeEnum = {
        PAXPERBOOKING: 'PER_PAX_PER_BOOKING' as MarkupTypeEnum,
        PAXPERSEGMENT: 'PER_PAX_PER_SEGMENT' as MarkupTypeEnum,
        BOOKING: 'PER_BOOKING' as MarkupTypeEnum
    };
    export type SaleChannelEnum = 'B2B' | 'B2C' | 'B2B2C' | 'B2C_WEB' | 'B2C_WEB_APP' | 'B2C_MOBILE';
    export const SaleChannelEnum = {
        B2B: 'B2B' as SaleChannelEnum,
        B2C: 'B2C' as SaleChannelEnum,
        B2B2C: 'B2B2C' as SaleChannelEnum,
        B2CWEB: 'B2C_WEB' as SaleChannelEnum,
        B2CWEBAPP: 'B2C_WEB_APP' as SaleChannelEnum,
        B2CMOBILE: 'B2C_MOBILE' as SaleChannelEnum
    };
    export type StatusEnum = 'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'TICKET_ON_PROCESS' | 'INACTIVE';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as StatusEnum,
        BOOKED: 'BOOKED' as StatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        TENTATIVE: 'TENTATIVE' as StatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type SupplierTypeEnum = 'AIR' | 'HOTEL' | 'COMBO' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const SupplierTypeEnum = {
        AIR: 'AIR' as SupplierTypeEnum,
        HOTEL: 'HOTEL' as SupplierTypeEnum,
        COMBO: 'COMBO' as SupplierTypeEnum,
        TOURS: 'TOURS' as SupplierTypeEnum,
        TRAIN: 'TRAIN' as SupplierTypeEnum,
        SHIP: 'SHIP' as SupplierTypeEnum,
        OTHER: 'OTHER' as SupplierTypeEnum
    };
}
