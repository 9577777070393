/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingCode } from './bookingCode';
import { Info } from './info';
import { ProductDetail } from './productDetail';


export interface HotelDraftBookingRS { 
    bookingCode?: BookingCode;
    bookingType?: HotelDraftBookingRS.BookingTypeEnum;
    duration?: number;
    errors?: Array<Error>;
    infos?: Array<Info>;
    isSuccess?: boolean;
    markupType?: HotelDraftBookingRS.MarkupTypeEnum;
    product?: ProductDetail;
    success?: boolean;
    textMessage?: string;
}
export namespace HotelDraftBookingRS {
    export type BookingTypeEnum = 'DOME' | 'INTE';
    export const BookingTypeEnum = {
        DOME: 'DOME' as BookingTypeEnum,
        INTE: 'INTE' as BookingTypeEnum
    };
    export type MarkupTypeEnum = 'PER_PAX_PER_BOOKING' | 'PER_PAX_PER_SEGMENT' | 'PER_BOOKING';
    export const MarkupTypeEnum = {
        PAXPERBOOKING: 'PER_PAX_PER_BOOKING' as MarkupTypeEnum,
        PAXPERSEGMENT: 'PER_PAX_PER_SEGMENT' as MarkupTypeEnum,
        BOOKING: 'PER_BOOKING' as MarkupTypeEnum
    };
}
