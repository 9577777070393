import { Component, Input, OnInit } from '@angular/core';
import { PropertyAllRate } from '@gtd/b2c-client';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { SlugifyPipe } from '../../../pipe/slug.pipe';

@Component({
  selector: 'gtd-tabbar-detail',
  templateUrl: './tabbar-detail.component.html',
  styleUrls: ['./tabbar-detail.component.scss'],
  providers: [SlugifyPipe]
})
export class TabbarDetailComponent implements OnInit {
  @Input() propertyAllRate: PropertyAllRate;
  searchParams: any;
  locationFrom;
  locationTo;
  appName = localStorage.getItem('appName');

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _slugifyPipe: SlugifyPipe
  ) {
    this.activatedRoute.queryParams.subscribe(param => {
      this.searchParams = JSON.parse(decodeURIComponent(atob(param.params)));
    });
  }

  ngOnInit() {
    this.locationFrom = localStorage.getItem('comboFrom')
      ? JSON.parse(localStorage.getItem('comboFrom'))[0]
      : '';
    this.locationTo = localStorage.getItem('comboTo')
      ? JSON.parse(localStorage.getItem('comboTo'))[0]
      : '';
  }

  backSearchResult() {
    if (this.searchParams) {
      //   let data = {
      //     locationCode: this.searchParams.locationCode,
      //     searchType: this.searchParams.searchType,
      //     supplier: this.searchParams.supplier,
      //     locationName: this.searchParams.locationName,
      //     fromDate: this.searchParams.checkIn,
      //     toDate: this.searchParams.checkOut,
      //     rooms: this.searchParams.paxInfos
      //   };
      this.router.navigate(['/combo/hotel'], {
        queryParams: {
          params: btoa(encodeURIComponent(JSON.stringify(this.searchParams)))
        }
      });
    }
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    const location = document.location.origin;
    selBox.value =
      val === 'hotel'
        ? `${location}/khach-san/${this._slugifyPipe.transform(
            this.propertyAllRate.propertyName
          )}-${this.propertyAllRate.masterPropertyId}`
        : `${location}/khach-san/${this._slugifyPipe.transform(
            this.propertyAllRate.propertyName
          )}-${this.propertyAllRate.masterPropertyId}?from=${
            this.locationFrom.code
          }&to=${this.locationTo.code}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
