import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { AirportPartialState } from './airport.reducer';
import {
  LoadAirport,
  AirportLoaded,
  AirportLoadError,
  AirportActionTypes
} from './airport.actions';

import { AirportResourceService } from '@gtd/meta-client';

@Injectable()
export class AirportEffects {
  @Effect() loadAirport$ = this.dataPersistence.fetch(
    AirportActionTypes.LoadAirport,
    {
      run: (action: LoadAirport, state: AirportPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.airportResourceService
          .searchAirportsUsingGET(action.payload.query, action.payload.country)
          .pipe(
            map(updated => ({
              type: AirportActionTypes.AirportLoaded,
              payload: updated
            }))
          );
      },

      onError: (action: LoadAirport, error) => {
        console.error('Error', error);
        return new AirportLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<AirportPartialState>,
    private airportResourceService: AirportResourceService
  ) {}
}
