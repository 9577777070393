/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface HotelMetaKeyword { 
    code?: string;
    famous?: boolean;
    image?: string;
    name?: string;
    type?: HotelMetaKeyword.TypeEnum;
}
export namespace HotelMetaKeyword {
    export type TypeEnum = 'HOTEL' | 'DESTINATION';
    export const TypeEnum = {
        HOTEL: 'HOTEL' as TypeEnum,
        DESTINATION: 'DESTINATION' as TypeEnum
    };
}
