import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {LookupB2bPartialState, LookupB2bSearch} from './lookup-b2b.reducer';
import { lookupB2bQuery } from './lookup-b2b.selectors';
import { LoadLookupB2b } from './lookup-b2b.actions';

@Injectable()
export class LookupB2bFacade {
  loaded$ = this.store.pipe(select(lookupB2bQuery.getLoaded));
  allLookupB2b$ = this.store.pipe(select(lookupB2bQuery.getAllLookupB2b));
  selectedLookupB2b$ = this.store.pipe(
    select(lookupB2bQuery.getSelectedLookupB2b)
  );

  constructor(private store: Store<LookupB2bPartialState>) {}

  loadAll(lookupB2bSearch: LookupB2bSearch) {
    this.store.dispatch(new LoadLookupB2b(lookupB2bSearch));
  }
}
