import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CITIESPOPULAR_FEATURE_KEY,
  CitiesPopularState
} from './cities-popular.reducer';
import { AirportDTO, CityPopularObjectDTO } from '@gtd/meta-client';

// Lookup the 'CitiesPopular' feature state managed by NgRx
const getCitiesPopularState = createFeatureSelector<CitiesPopularState>(
  CITIESPOPULAR_FEATURE_KEY
);

const getLoaded = createSelector(
  getCitiesPopularState,
  (state: CitiesPopularState) => state.loaded
);
const getError = createSelector(
  getCitiesPopularState,
  (state: CitiesPopularState) => state.error
);

const getAllCitiesPopular = createSelector(
  getCitiesPopularState,
  getLoaded,
  (state: CitiesPopularState, isLoaded) => {
    return isLoaded
      ? Mapping.prototype.mappingAirport(state.list.internationalCities)
      : null;
  }
);

const getAllDomesticCitiesPopular = createSelector(
  getCitiesPopularState,
  getLoaded,
  (state: CitiesPopularState, isLoaded) => {
    return isLoaded
      ? Mapping.prototype.mappingAirport(state.list.domesticCities)
      : null;
  }
);

const getSelectedId = createSelector(
  getCitiesPopularState,
  (state: CitiesPopularState) => state.selectedId
);
const getSelectedCitiesPopular = createSelector(
  getAllCitiesPopular,
  getSelectedId,
  (citiesPopular, id) => {
    return citiesPopular ? Object.assign({}, citiesPopular) : undefined;
  }
);

export const citiesPopularQuery = {
  getLoaded,
  getError,
  getAllCitiesPopular,
  getSelectedCitiesPopular,
  getAllDomesticCitiesPopular
};

export class Mapping {
  mappingAirport(cityPopulars: Array<CityPopularObjectDTO>): AirportDTO[] {
    return cityPopulars.map((cityPopular: CityPopularObjectDTO) => {
      return {
        countryCode: cityPopular.countryCode,
        country: cityPopular.country,
        country2: cityPopular.country2,
        city: cityPopular.city,
        city2: cityPopular.city2,
        code: cityPopular.airportCode,
        cityCode: cityPopular.cityCode,
        name: cityPopular.airportName
      };
    });
  }
}
