import { Action } from '@ngrx/store';
import { SearchUserBooking } from './search-user-booking.reducer';
import {HttpResponse} from "@angular/common/http";
import {BookingDTO} from "../api-client/index";

export enum SearchUserBookingActionTypes {
  LoadSearchUserBooking = '[SearchUserBooking] Load SearchUserBooking',
  SearchUserBookingLoaded = '[SearchUserBooking] SearchUserBooking Loaded',
  SearchUserBookingLoadError = '[SearchUserBooking] SearchUserBooking Load Error'
}

export class LoadSearchUserBooking implements Action {
  readonly type = SearchUserBookingActionTypes.LoadSearchUserBooking;
  constructor(public payload: SearchUserBooking) {}
}

export class SearchUserBookingLoadError implements Action {
  readonly type = SearchUserBookingActionTypes.SearchUserBookingLoadError;
  constructor(public payload: any) {}
}

export class SearchUserBookingLoaded implements Action {
  readonly type = SearchUserBookingActionTypes.SearchUserBookingLoaded;
  constructor(public payload: HttpResponse<Array<BookingDTO>>) {}
}

export type SearchUserBookingAction =
  | LoadSearchUserBooking
  | SearchUserBookingLoaded
  | SearchUserBookingLoadError;

export const fromSearchUserBookingActions = {
  LoadSearchUserBooking,
  SearchUserBookingLoaded,
  SearchUserBookingLoadError
};
