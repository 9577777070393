import { SocialAuthAction, SocialAuthActionTypes } from './social-auth.actions';
import {User} from "../../../authenticate/models/user";
import {AuthenticateActionTypes} from "../../../authenticate/+state/authenticate.actions";

export const SOCIALAUTH_FEATURE_KEY = 'socialAuth';

/**
 * Interface for the 'SocialAuth' data used in
 *  - SocialAuthState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface SocialAuthState {
  isAuthenticated: boolean;
  user?: User | null;
  loaded: boolean;
  errorCode: string | null;
  errorMessage: string | null;
}

export interface SocialAuthPartialState {
  readonly [SOCIALAUTH_FEATURE_KEY]: SocialAuthState;
}

export const initialState: SocialAuthState = {
  isAuthenticated: null,
  loaded: false,
  user: null,
  errorCode: null,
  errorMessage: null
};

export function reducer(
  state: SocialAuthState = initialState,
  action: SocialAuthAction
): SocialAuthState {
  switch (action.type) {
    case SocialAuthActionTypes.SocialAuthLoaded: {
      state = {
        ...state,
        isAuthenticated: true,
        user: {
          token: action.payload.token.id_token,
          username: action.payload.data.email? action.payload.data.email: action.payload.data.providerUserId + '@' + 'facebook.com',
          remember: action.payload.data.rememberMe
        },
        loaded: true,
        errorMessage: null
      };
      break;
    }
    case SocialAuthActionTypes.LOGOUT: {
      return initialState;
    }
  }
  return state;
}
