import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';

import {AIRPORT_FEATURE_KEY, airportReducer, initialState as airportInitialState} from './+state/airport.reducer';
import {AirportEffects} from './+state/airport.effects';
import {AirportFacade} from './+state/airport.facade';

import {CITIESPOPULAR_FEATURE_KEY, citiesPopularReducer, initialState as citiesPopularInitialState} from './+cities-popular-state/cities-popular.reducer';
import {CitiesPopularEffects} from './+cities-popular-state/cities-popular.effects';
import {CitiesPopularFacade} from './+cities-popular-state/cities-popular.facade';


import {AirportResourceService, BASE_PATH} from '@gtd/meta-client';
import {environment} from '@env/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(AIRPORT_FEATURE_KEY, airportReducer, {
      initialState: airportInitialState
    }),
    EffectsModule.forFeature([AirportEffects]),

    CommonModule,
    StoreModule.forFeature(CITIESPOPULAR_FEATURE_KEY, citiesPopularReducer, {
      initialState: citiesPopularInitialState
    }),
    EffectsModule.forFeature([CitiesPopularEffects]),
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.metaService },
    AirportFacade,
    CitiesPopularFacade,
    AirportResourceService
  ]
})
export class AirportModule {}
