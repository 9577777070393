/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerTravellerMemberCardDTO } from './customerTravellerMemberCardDTO';


export interface CustomerTravellerDTO {
    address1?: string;
    address2?: string;
    adultType?: CustomerTravellerDTO.AdultTypeEnum;
    city?: string;
    companyAddress?: string;
    companyName?: string;
    companyTaxCode?: string;
    country?: string;
    customerCode: string;
    dob: Date;
    documentNumber?: string;
    documentType?: string;
    email?: string;
    expiredDate?: Date;
    firstName: string;
    gender?: CustomerTravellerDTO.GenderEnum;
    id?: number;
    isDefault?: boolean;
    issuedDate?: Date;
    issuingCountry?: string;
    memberCard?: boolean;
    memberCards?: Array<CustomerTravellerMemberCardDTO>;
    nationality?: string;
    nationalityName?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    phoneNumber3?: string;
    phoneNumber4?: string;
    phoneNumber5?: string;
    postalCode?: string;
    profileId?: number;
    surName: string;
}
export namespace CustomerTravellerDTO {
    export type AdultTypeEnum = 'ADT' | 'CHD' | 'INF';
    export const AdultTypeEnum = {
        ADT: 'ADT' as AdultTypeEnum,
        CHD: 'CHD' as AdultTypeEnum,
        INF: 'INF' as AdultTypeEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'BOY' | 'GIRL' | 'INF';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        BOY: 'BOY' as GenderEnum,
        GIRL: 'GIRL' as GenderEnum,
        INF: 'INF' as GenderEnum
    };
}
