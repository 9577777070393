import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelPartialState } from './hotel.reducer';
import { hotelQuery } from './hotel.selectors';
import { LoadHotel } from './hotel.actions';

@Injectable()
export class HotelFacade {
  loaded$ = this.store.pipe(select(hotelQuery.getLoaded));
  allHotel$ = this.store.pipe(select(hotelQuery.getAllHotel));
  selectedHotel$ = this.store.pipe(select(hotelQuery.getSelectedHotel));

  constructor(private store: Store<HotelPartialState>) {}

  loadAll(query) {
    this.store.dispatch(new LoadHotel(query));
  }
}
