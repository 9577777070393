import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { GroupBooking, Room } from '@gtd/b2c-client';
import { DialogPanelComponent } from '../hotel-panel-price/dialog-panel/dialog-panel.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'gtd-hotel-booking-info',
  templateUrl: './hotel-booking-info.component.html',
  styleUrls: ['./hotel-booking-info.component.scss']
})
export class HotelBookingInfoComponent implements OnInit {
  @Output() emitAdditionalRequestType: EventEmitter<
    string
  > = new EventEmitter();
  @Input() groupBooking: GroupBooking;
  @Input() totalAdditionalRequests: number;

  additionalRequestActions = [];
  appName = localStorage.getItem('appName');
  isShowAdditionalRequestButton: boolean = false;
  hotelDetail; //TODO TRANSLATE

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    if (this.groupBooking) {
      this.hotelDetail = this.groupBooking.bookingInfo.transactionInfos.find(
        trans => trans.supplierType === 'HOTEL'
      );
    }
    if (this.groupBooking.bookingInfo.departureDate) {
      if (
        new Date().getTime() <
          new Date(this.groupBooking.bookingInfo.departureDate).getTime() &&
        this.appName !== 'B2B2C'
      ) {
        this.isShowAdditionalRequestButton = true;
      }
    }
    if (this.groupBooking.bookingInfo.supplierType === 'HOTEL') {
      this.additionalRequestActions = [
        {
          code: 'HOTEL_ADD_PERSON',
          name: 'booking.more-people'
        },
        {
          code: 'HOTEL_EDIT_BOOKING',
          name: 'booking.change-room-information'
        },
        {
          code: 'HOTEL_CHANGE_ROOM',
          name: 'booking.change-room'
        },
        {
          code: 'HOTEL_DELETE_BOOKING',
          name: 'booking.refund-cancellation-room'
        },
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    }
  }

  openPopupPanelDetail() {
    this.dialog.open(DialogPanelComponent, {
      data: this.groupBooking,
      panelClass: 'dialog-panel',
      position: {
        top: '100px'
      }
    });
  }

  getPassengertotal(type) {
    let temp = 0;
    switch (type) {
      case 'child':
        for (let room of this.groupBooking.hotelAvailability.products[0]
          .rooms) {
          temp = temp + room.pax.childQuantity;
        }
        break;
      case 'adult':
        for (let room of this.groupBooking.hotelAvailability.products[0]
          .rooms) {
          temp = temp + room.pax.adultQuantity;
        }
        break;
    }
    return temp;
  }
}
