import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { DialogMessage } from './covid-dialog.model';
import { CovidDialogService } from './covid-dialog.service';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'gtd-covid-dialog',
  templateUrl: './covid-dialog.component.html',
  styleUrls: ['./covid-dialog.component.scss']
})
export class CovidDialogComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject();
  constructor(
    public dialogRef: MatDialogRef<CovidDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogMessage,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.parent.postMessage({ action: 'scrollToTop' }, '*');
    }
  }

  closeDialog(e?: string) {
    if (e === 'submit') {
      localStorage.setItem('covid', this.data.time);
    }
    this.dialogRef.close(e);
  }
  ngOnInit() {}
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
