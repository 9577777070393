import { Action } from '@ngrx/store';
import { OtaResultOfSearchKeyWordResult } from '@gtd/b2c-client';

export enum SearchKeywordActionTypes {
  ComboVJLoadSearchKeyword = '[ComboVJ_SearchKeyword] Load SearchKeyword',
  ComboVJSearchKeywordLoaded = '[ComboVJ_SearchKeyword] SearchKeyword Loaded',
  ComboVJSearchKeywordLoadError = '[ComboVJ_SearchKeyword] SearchKeyword Load Error'
}

export class ComboVJLoadSearchKeyword implements Action {
  readonly type = SearchKeywordActionTypes.ComboVJLoadSearchKeyword;
  constructor(
    public payload: {
      keyword: string;
      language: 'vi' | 'en';
      pageNumber?: number;
      pageSize?: number;
    }
  ) {}
}

export class ComboVJSearchKeywordLoadError implements Action {
  readonly type = SearchKeywordActionTypes.ComboVJSearchKeywordLoadError;
  constructor(public payload: any) {}
}

export class ComboVJSearchKeywordLoaded implements Action {
  readonly type = SearchKeywordActionTypes.ComboVJSearchKeywordLoaded;
  constructor(public payload: OtaResultOfSearchKeyWordResult) {}
}

export type SearchKeywordAction =
  | ComboVJLoadSearchKeyword
  | ComboVJSearchKeywordLoaded
  | ComboVJSearchKeywordLoadError;

export const fromSearchKeywordActions = {
  ComboVJLoadSearchKeyword,
  ComboVJSearchKeywordLoaded,
  ComboVJSearchKeywordLoadError
};
