import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router, UrlSerializer} from "@angular/router";
import {SearchBestRatesResult} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-hotel-search-result-item-map',
  templateUrl: './hotel-search-result-item-map.component.html',
  styleUrls: ['./hotel-search-result-item-map.component.scss']
})
export class HotelSearchResultItemMapComponent implements OnInit {
  @Input() hotelItems: SearchBestRatesResult;
  @Input() searchParams: any;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  counter = Array;

  constructor(private serializer: UrlSerializer, private router: Router) {

  }

  ngOnInit() {
  }

  viewDetailItem(item) {

    let params = {
      locationCode: this.searchParams.locationCode,
      locationName: this.searchParams.locationName,
      searchType: this.searchParams.searchType,
      roomsString: this.searchParams.roomsString,
      checkIn: this.searchParams.fromDate,
      checkOut: this.searchParams.toDate,
      searchId: this.hotelItems.searchId,
      propertyId: item.propertyId,
      supplier: item.supplier,
      paxInfos: this.searchParams.rooms
    }
    const url = this.router.createUrlTree(['/hotel/detail'], {
      queryParams: {
        params: btoa(encodeURIComponent(JSON.stringify(params)))
      }
    });
    let newWindow = window.open('', '_self');
    newWindow.location.href = decodeURIComponent(this.serializer.serialize(url));
  }

  hotelItemHover(itemHotel, index: number) {
    this.changed.emit({
      itemHotel: itemHotel,
      index: index,
      type: 'hover'
    });
  }
  hotelItemHoverOut(itemHotel, index: number) {
    this.changed.emit({
      itemHotel: itemHotel,
      index: index,
      type: 'out'
    });
  }

  mathFloor(star: any) {
    return Math.floor(star)
  }
  isFloat(star) {
    return (star % 1 != 0);
  }
}
