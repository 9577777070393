import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {FilterFinancial, TransactionFinancialPartialState} from './transaction-financial.reducer';
import { transactionFinancialQuery } from './transaction-financial.selectors';
import { LoadTransactionFinancial, DestroyTransactionFinancial } from './transaction-financial.actions';

@Injectable()
export class TransactionFinancialFacade {
  loaded$ = this.store.pipe(select(transactionFinancialQuery.getLoaded));
  allTransactionFinancial$ = this.store.pipe(
    select(transactionFinancialQuery.getAllTransactionFinancial)
  );
  allTotalCount$ = this.store.pipe(
    select(transactionFinancialQuery.getTotalCount)
  );
  selectedTransactionFinancial$ = this.store.pipe(
    select(transactionFinancialQuery.getSelectedTransactionFinancial)
  );

  constructor(private store: Store<TransactionFinancialPartialState>) {}

  loadAll(filterFinancial: FilterFinancial) {
    this.store.dispatch(new LoadTransactionFinancial(filterFinancial));
  }
  destroyFinancial() {
    this.store.dispatch(new DestroyTransactionFinancial());
  }
}
