import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { HotelCheckoutPartialState } from './hotel-checkout.reducer';
import {
  ComboVJLoadHotelCheckout,
  ComboVJHotelCheckoutLoaded,
  ComboVJHotelCheckoutLoadError,
  HotelCheckoutActionTypes
} from './hotel-checkout.actions';
import { map } from 'rxjs/operators';
import { HotelResourceV3Service } from '@gtd/b2c-client';

@Injectable()
export class HotelCheckoutEffects {
  @Effect() loadComboVJHotelCheckout$ = this.dataPersistence.fetch(
    HotelCheckoutActionTypes.ComboVJLoadHotelCheckout,
    {
      run: (
        action: ComboVJLoadHotelCheckout,
        state: HotelCheckoutPartialState
      ) => {
        this.hotelResourceV3Service.defaultHeaders = this.hotelResourceV3Service.defaultHeaders.set(
          'sales-env',
          'HOTEL_PACKAGE'
        );
        return this.hotelResourceV3Service
          .checkoutUsingPOST(action.payload)
          .pipe(
            map(payload => ({
              type: HotelCheckoutActionTypes.ComboVJHotelCheckoutLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: ComboVJLoadHotelCheckout, error) => {
        console.error('Error', error);
        return new ComboVJHotelCheckoutLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private hotelResourceV3Service: HotelResourceV3Service,
    private dataPersistence: DataPersistence<HotelCheckoutPartialState>
  ) {}
}
