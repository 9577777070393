import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FinalBookingDetailFacade } from '../state/booking-result/final-booking-detail/final-booking-detail.facade';
import { fadeInOut } from '@gtd/helpers';
import {BasePartnerResourceService, GroupBooking, ProductResourceService} from '@gtd/b2c-client';
import { Subscription } from 'rxjs';
import { PolicyByBookingFacade } from '../state/insurance-policy-by-booking/policy-by-booking/policy-by-booking.facade';
import { environment } from '@env/environment';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { MatBottomSheet, MatDialog, MatSnackBar } from '@angular/material';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BookingResultBottomSheetComponent } from './booking-result-bottom-sheet/booking-result-bottom-sheet.component';
import { isPlatformBrowser } from '@angular/common';
import { GaTrackingService } from '@gtd/ga-tracking';
import { PixelTrackingService } from '@gtd/pixel-tracking';
import { TourUnitPriceFacade } from '@gtd/extra/tour/data-access';
import { map, switchMap } from 'rxjs/operators';
import { BookingDTO } from '@gtd/api-services/booking-service';
import {FinalBookingDetailActionTypes} from "../state/booking-result/final-booking-detail/final-booking-detail.actions";


const ELEMENT_DATA: any[] = [
  {id: 1, name: 'Hydrogen'},
  {id: 2, name: 'Helium'},
  {id: 3, name: 'Lithium'},
];
@Component({
  selector: 'gtd-booking-result',
  templateUrl: './booking-result.component.html',
  styleUrls: ['./booking-result.component.scss'],
  animations: [fadeInOut]
})
export class BookingResultComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  supplierType: any;
  allFinalBookingDetail: GroupBooking;
  allPolicyByBooking$ = this.policyByBookingFacade.allPolicyByBooking$;
  finalBookingDetailFacadeLoaded$ = this.finalBookingDetailFacade.loaded$;
  getError$ = this.finalBookingDetailFacade.getError$;
  travelers = [];
  isLoading: boolean;
  partnerName: string;
  appName = localStorage.getItem('appName');
  allTourUnitPrice$ = this._tourUnitPriceFacade.allTourUnitPrice$;
  paxInfos$: any;

  isRetry: boolean;
  retryLoopCounter: number = 0;


  tcbDisplayedColumns: string[] = ['stt', 'name'];
  tcbDataSource = ELEMENT_DATA;


  constructor(
    private activatedRoute: ActivatedRoute,
    private policyByBookingFacade: PolicyByBookingFacade,
    private finalBookingDetailFacade: FinalBookingDetailFacade,
    private _bottomSheet: MatBottomSheet,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private basePartnerResourceService: BasePartnerResourceService,
    private deviceService: DeviceDetectorService,
    private _gaTrackingService: GaTrackingService,
    private _pixelTrackingService: PixelTrackingService,
    private _tourUnitPriceFacade: TourUnitPriceFacade,
    private productResourceService: ProductResourceService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      let partner_gotadi = JSON.parse(localStorage.getItem('partner_gotadi'));
      if (partner_gotadi && partner_gotadi.merchant_code) {
        this.partnerName = partner_gotadi.merchant_code;
      }
      this.activatedRoute.queryParams.subscribe(value => {
        if (value.bookingNumber) {
          this.finalBookingDetailFacade.loadAll(value.bookingNumber);
          this.policyByBookingFacade.loadAllPolicyByBooking(
            value.bookingNumber
          );
        }
      });
      this.paxInfos$ = this.finalBookingDetailFacade.allFinalBookingDetail$.pipe(
        switchMap(bookingDetail =>
          this.allTourUnitPrice$.pipe(
            map(unitPrice => {
              if (bookingDetail && unitPrice) {
                return bookingDetail.tourActivityBookingPayload.paxInfos.map(
                  pax => {
                    const paxDetail = unitPrice.result.find(
                      unit => pax.unitId === unit.id
                    );
                    return { ...pax, ...paxDetail };
                  }
                );
              }
            })
          )
        )
      );
      setTimeout(() => {
        this.subscriptions.add(
          this.finalBookingDetailFacade.allFinalBookingDetail$.subscribe(
            allFinalBookingDetail => {
              if (allFinalBookingDetail) {
                this.generateModelRs(allFinalBookingDetail);
              }
            }
          )
        );
      }, 500);

      if (this.deviceService.isMobile()) {
        this.finalBookingDetailFacadeLoaded$.subscribe(loaded => {
          if (loaded) {
            this._bottomSheet.open(BookingResultBottomSheetComponent);
          }
        });
      }
    }
  }

  generateModelRs(allFinalBookingDetail: GroupBooking) {
    if (allFinalBookingDetail.supplierType === 'TOURS') {
      this._tourUnitPriceFacade.loadAll({
        activityId:
        allFinalBookingDetail.tourActivityBookingPayload
          .activityId,
        language: 'vi'
      });
    }
    if (
      allFinalBookingDetail.supplierType === 'AIR'
      && allFinalBookingDetail.bookingInfo.paymentStatus === 'SUCCEEDED'
      && (allFinalBookingDetail.bookingInfo.supplierBookingStatus === 'TICKET_ON_PROCESS' || allFinalBookingDetail.bookingInfo.supplierBookingStatus === 'BOOKED')
    ) {
      this.isRetry = true;
      this.retryBookingTOP(allFinalBookingDetail.bookingInfo.bookingNumber);
    } else {
      this.isRetry = false;
    }
    this.allFinalBookingDetail = allFinalBookingDetail;
    this.supplierType = allFinalBookingDetail.supplierType;
    if (
      allFinalBookingDetail.bookingInfo.paymentStatus ===
      'SUCCEEDED' &&
      !sessionStorage.getItem(allFinalBookingDetail.bookingNumber)
    ) {
      this._gaTrackingService.track('payment_success', {
        transaction_id: allFinalBookingDetail.bookingNumber
      });
      this._gaTrackingService.track(
        this.supplierType === 'AIR'
          ? 'ticket_success'
          : this.supplierType === 'HOTEL'
            ? 'hotel_success'
            : 'combo_success',
        {
          transaction_id: allFinalBookingDetail.bookingNumber
        }
      );
      this._pixelTrackingService.track('Purchase', {
        currency: 'VND',
        value: allFinalBookingDetail.bookingInfo.totalFare
      });
      sessionStorage.setItem(
        allFinalBookingDetail.bookingNumber,
        'viewed'
      );
    }
    this.travelers =
      allFinalBookingDetail &&
      allFinalBookingDetail.travelerInfo &&
      allFinalBookingDetail.travelerInfo.airTravelers
        ? allFinalBookingDetail.travelerInfo.airTravelers.map(
          traveler => {
            const travelIdx = traveler.idx;
            return {
              ...traveler,
              personRepresentation: allFinalBookingDetail.bookingInfo.travelerInfos.find(
                item => item.orderIdx === travelIdx
              )
                ? allFinalBookingDetail.bookingInfo.travelerInfos.find(
                  item => item.orderIdx === travelIdx
                ).personRepresentation
                : false
            };
          }
        )
        : [];
  }
  retryBookingTOP(bookingNumber: string) {
    if(this.retryLoopCounter < 5) {
      this.retryLoopCounter++;
      setTimeout(() => {
        this.productResourceService
          .getFinalBookingDetailUsingGET(bookingNumber).subscribe(allFinalBookingDetail => this.generateModelRs(allFinalBookingDetail));
      }, 5000)
    } else {
      this.isRetry = false
    }
  }

  redirectPayment(bookingNumber: string) {
    if (this.appName === 'B2B2C') {
      const partner_gotadi = JSON.parse(localStorage.getItem('partner_gotadi'));
      if (
        (partner_gotadi.merchant_code === 'TCDL' ||
          partner_gotadi.merchant_code === 'SHNHN')
      ) {
        this.router.navigate(['/booking/payment'], {
          queryParams: {
            bookingNumber: bookingNumber
          }
        });
      } else {
        this._snackBar.openFromComponent(SnackbarComponent, {
          data: {
            message: 'booking-result.insurance.processing',
            loading: true
          },
          panelClass: 'snackbar-loading',
          verticalPosition: 'top'
        });
        this.isLoading = true;
        this.basePartnerResourceService
          .placeOrderUsingGET(bookingNumber)
          .subscribe(placeOrder => {
            if (placeOrder && placeOrder.isSuccess) {
              window.top.location.href = decodeURIComponent(placeOrder.result);
            } else {
              this.dialogMessageExpire();
            }
            this.isLoading = false;
            this._snackBar.dismiss();
          });
      }
    } else {
      if (
        this.allFinalBookingDetail.supplierType == BookingDTO.SupplierTypeEnum.HOTEL
        && this.appName === 'B2C'
      ) {
        this.router.navigate(['/checkouts/payment'], {
          queryParams: {
            bookingNumber: bookingNumber
          }
        });
      } else {
        this.router.navigate(['/booking/payment'], {
          queryParams: {
            bookingNumber: bookingNumber
          }
        });
      }
    }
  }

  dialogMessageExpire() {
    this.dialog.open(DialogMessageComponent, {
      data: {
        btn: [{ btnText: 'global.close' }],
        content: 'user.system-error-message',
        heading: 'user.system-error',
        icon: 'exclamation-triangle',
        messageType: 'error'
      },
      panelClass: 'dialog-message',
      disableClose: true,
      position: {
        top: '100px'
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
