import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {AffiliatesPartialState} from './affiliates.reducer';
import {AffiliatesActionTypes, AffiliatesLoadError, LoadAffiliates} from './affiliates.actions';
import {map} from "rxjs/operators";
import {AirTicketsResourceService} from "@gtd/b2c-client";

@Injectable()
export class AffiliatesEffects {
  @Effect() loadAffiliates$ = this.dataPersistence.fetch(
    AffiliatesActionTypes.LoadAffiliates,
    {
      run: (action: LoadAffiliates, state: AffiliatesPartialState) => {
        return this.airTicketsResourceService.getBookingTagsByNameUsingGET(action.payload).pipe(
          map(update =>({
            type: AffiliatesActionTypes.AffiliatesLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadAffiliates, error) => {
        console.error('Error', error);
        return new AffiliatesLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private airTicketsResourceService: AirTicketsResourceService,
    private dataPersistence: DataPersistence<AffiliatesPartialState>
  ) {}
}
