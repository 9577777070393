import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {PolicyByBookingPartialState} from './policy-by-booking.reducer';
import {LoadPolicyByBooking, PolicyByBookingActionTypes, PolicyByBookingLoadError} from './policy-by-booking.actions';
import {map} from "rxjs/operators";
import {ApiDelayInsuranceService} from "@gtd/api-services/service-inventory";
import {
  InsuranceApiResourceService
} from "../../../../../api-services/service-inventory/src/lib/api-client/api/insuranceApiResource.service";

@Injectable()
export class PolicyByBookingEffects {
  @Effect() loadPolicyByBooking$ = this.dataPersistence.fetch(
    PolicyByBookingActionTypes.LoadPolicyByBooking,
    {
      run: (
        action: LoadPolicyByBooking,
        state: PolicyByBookingPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.insuranceApiResourceService.getInsurancesByBookingUsingGET(action.payload).pipe(
          map(result => ({
            type: PolicyByBookingActionTypes.PolicyByBookingLoaded,
            payload: result
          }))
        );
      },

      onError: (action: LoadPolicyByBooking, error) => {
        console.error('Error', error);
        return new PolicyByBookingLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private insuranceApiResourceService: InsuranceApiResourceService,
    private dataPersistence: DataPersistence<PolicyByBookingPartialState>
  ) {}
}
