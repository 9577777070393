import { OtaResultOfTourActivityAvailableDTO } from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './tour-search-activity-detail.reducer';

export enum TourSearchActivityDetailActionTypes {
  LoadTourSearchActivityDetail = '[TourSearchActivityDetail] Load TourSearchActivityDetail',
  ResetTourSearchActivityDetail = '[TourSearchActivityDetail] Reset TourSearchActivityDetail',
  TourSearchActivityDetailLoaded = '[TourSearchActivityDetail] TourSearchActivityDetail Loaded',
  TourSearchActivityDetailLoadError = '[TourSearchActivityDetail] TourSearchActivityDetail Load Error'
}

export class ResetTourSearchActivityDetail implements Action {
  readonly type =
    TourSearchActivityDetailActionTypes.ResetTourSearchActivityDetail;
}

export class LoadTourSearchActivityDetail implements Action {
  readonly type =
    TourSearchActivityDetailActionTypes.LoadTourSearchActivityDetail;
  constructor(public payload: SearchActivityDetailRQ) {}
}

export class TourSearchActivityDetailLoadError implements Action {
  readonly type =
    TourSearchActivityDetailActionTypes.TourSearchActivityDetailLoadError;
  constructor(public payload: any) {}
}

export class TourSearchActivityDetailLoaded implements Action {
  readonly type =
    TourSearchActivityDetailActionTypes.TourSearchActivityDetailLoaded;
  constructor(public payload: OtaResultOfTourActivityAvailableDTO) {}
}

export type TourSearchActivityDetailAction =
  | LoadTourSearchActivityDetail
  | ResetTourSearchActivityDetail
  | TourSearchActivityDetailLoaded
  | TourSearchActivityDetailLoadError;

export const fromTourSearchActivityDetailActions = {
  ResetTourSearchActivityDetail,
  LoadTourSearchActivityDetail,
  TourSearchActivityDetailLoaded,
  TourSearchActivityDetailLoadError
};

export interface SearchActivityDetailRQ {
  activityId: number;
  language: 'vi' | 'en';
  zoneId: string;
}
