/**
 * customersrv API
 * customersrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CustomerProfileDTO { 
    branchCode: string;
    createdBy?: string;
    createdDate?: Date;
    customerClass?: CustomerProfileDTO.CustomerClassEnum;
    customerCode: string;
    defaultTravellerId?: number;
    id?: number;
    loginId: number;
    loginUsername: string;
    orgCode: string;
    status?: CustomerProfileDTO.StatusEnum;
    taxAddress1?: string;
    taxAddress2?: string;
    taxCompanyName?: string;
    taxNumber?: string;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace CustomerProfileDTO {
    export type CustomerClassEnum = 'GOLD' | 'SILVER' | 'PLATINUM' | 'BASIC' | 'OTHER';
    export const CustomerClassEnum = {
        GOLD: 'GOLD' as CustomerClassEnum,
        SILVER: 'SILVER' as CustomerClassEnum,
        PLATINUM: 'PLATINUM' as CustomerClassEnum,
        BASIC: 'BASIC' as CustomerClassEnum,
        OTHER: 'OTHER' as CustomerClassEnum
    };
    export type StatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'OTHER';
    export const StatusEnum = {
        ACTIVATED: 'ACTIVATED' as StatusEnum,
        DEACTIVATED: 'DEACTIVATED' as StatusEnum,
        OTHER: 'OTHER' as StatusEnum
    };
}
