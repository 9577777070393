import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { BookingDetailPartialState } from './booking-detail.reducer';
import { bookingDetailQuery } from './booking-detail.selectors';
import {LoadBookingDetail, ResetBookingDetail} from './booking-detail.actions';

@Injectable()
export class BookingDetailFacade {
  loaded$ = this.store.pipe(select(bookingDetailQuery.getLoaded));
  bookingDetail$ = this.store.pipe(
    select(bookingDetailQuery.getAllBookingDetail)
  );
  selectedBookingDetail$ = this.store.pipe(
    select(bookingDetailQuery.getSelectedBookingDetail)
  );

  constructor(private store: Store<BookingDetailPartialState>) {}

  getBookingByBookingNumber(bookingNumber: string) {
    this.store.dispatch(new LoadBookingDetail(bookingNumber));
  }

  resetBookingDetail() {
    this.store.dispatch(new ResetBookingDetail());
  }
}
