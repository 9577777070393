import {PopularPlacesAction, PopularPlacesActionTypes} from './popular-places.actions';
import {OtaResultOfPropertyDTO} from "@gtd/meta-client";

export const POPULARPLACES_FEATURE_KEY = 'popularPlaces';

/**
 * Interface for the 'PopularPlaces' data used in
 *  - PopularPlacesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */

export interface PopularPlacesState {
  result: OtaResultOfPropertyDTO; // list of PopularPlaces; analogous to a sql normalized table
  selectedId?: string | number; // which PopularPlaces record has been selected
  loaded: boolean; // has the PopularPlaces list been loaded
  error?: any; // last none error (if any)
}

export interface PopularPlacesPartialState {
  readonly [POPULARPLACES_FEATURE_KEY]: PopularPlacesState;
}

export const initialState: PopularPlacesState = {
  result: null,
  loaded: false
};

export function popularPlaceReducer(
  state: PopularPlacesState = initialState,
  action: PopularPlacesAction
): PopularPlacesState {
  switch (action.type) {
    case PopularPlacesActionTypes.PopularPlacesLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
