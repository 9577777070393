import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { TourSearchActivityDetailPartialState } from './tour-search-activity-detail.reducer';
import {
  LoadTourSearchActivityDetail,
  TourSearchActivityDetailLoaded,
  TourSearchActivityDetailLoadError,
  TourSearchActivityDetailActionTypes
} from './tour-search-activity-detail.actions';
import { TourResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class TourSearchActivityDetailEffects {
  @Effect() loadTourSearchActivityDetail$ = this.dataPersistence.fetch(
    TourSearchActivityDetailActionTypes.LoadTourSearchActivityDetail,
    {
      run: (
        action: LoadTourSearchActivityDetail,
        state: TourSearchActivityDetailPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...return this.tourResourceService
        return this.tourResourceService
          .searchActivityDetailUsingGET(
            action.payload.activityId,
            action.payload.language,
            action.payload.zoneId
          )
          .pipe(
            map(payload => ({
              type:
                TourSearchActivityDetailActionTypes.TourSearchActivityDetailLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadTourSearchActivityDetail, error) => {
        console.error('Error', error);
        return new TourSearchActivityDetailLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<
      TourSearchActivityDetailPartialState
    >,
    private tourResourceService: TourResourceService
  ) {}
}
