/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { OtaSearchResultOfListOfPromotionDTO } from '../model/otaSearchResultOfListOfPromotionDTO';
import { PromotionChangeRes } from '../model/promotionChangeRes';
import { PromotionDTO } from '../model/promotionDTO';
import { PromotionDiscountReq } from '../model/promotionDiscountReq';
import { PromotionDiscountRes } from '../model/promotionDiscountRes';
import { PromotionPriorityDTO } from '../model/promotionPriorityDTO';
import { PromotionRedemptionReq } from '../model/promotionRedemptionReq';
import { PromotionRedemptionRes } from '../model/promotionRedemptionRes';
import { PromotionReq } from '../model/promotionReq';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PromotionResourceService {

    protected basePath = 'https://10.7.71.20:8282';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changePriority
     * 
     * @param promotionPriorityDTO promotionPriorityDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePriorityUsingPOST(promotionPriorityDTO: PromotionPriorityDTO, observe?: 'body', reportProgress?: boolean): Observable<PromotionChangeRes>;
    public changePriorityUsingPOST(promotionPriorityDTO: PromotionPriorityDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionChangeRes>>;
    public changePriorityUsingPOST(promotionPriorityDTO: PromotionPriorityDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionChangeRes>>;
    public changePriorityUsingPOST(promotionPriorityDTO: PromotionPriorityDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (promotionPriorityDTO === null || promotionPriorityDTO === undefined) {
            throw new Error('Required parameter promotionPriorityDTO was null or undefined when calling changePriorityUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PromotionChangeRes>(`${this.basePath}/api/change-priority/promotion`,
            promotionPriorityDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * clone
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneUsingPOST(id: number, observe?: 'body', reportProgress?: boolean): Observable<PromotionChangeRes>;
    public cloneUsingPOST(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionChangeRes>>;
    public cloneUsingPOST(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionChangeRes>>;
    public cloneUsingPOST(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling cloneUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PromotionChangeRes>(`${this.basePath}/api/clone/promotion`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createPromotion
     * 
     * @param promotionDTO promotionDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPromotionUsingPOST(promotionDTO: PromotionDTO, observe?: 'body', reportProgress?: boolean): Observable<PromotionDTO>;
    public createPromotionUsingPOST(promotionDTO: PromotionDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionDTO>>;
    public createPromotionUsingPOST(promotionDTO: PromotionDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionDTO>>;
    public createPromotionUsingPOST(promotionDTO: PromotionDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (promotionDTO === null || promotionDTO === undefined) {
            throw new Error('Required parameter promotionDTO was null or undefined when calling createPromotionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PromotionDTO>(`${this.basePath}/api/promotion`,
            promotionDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createPromotionVoucher
     * 
     * @param promotionReq promotionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPromotionVoucherUsingPOST(promotionReq: PromotionReq, observe?: 'body', reportProgress?: boolean): Observable<PromotionDTO>;
    public createPromotionVoucherUsingPOST(promotionReq: PromotionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionDTO>>;
    public createPromotionVoucherUsingPOST(promotionReq: PromotionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionDTO>>;
    public createPromotionVoucherUsingPOST(promotionReq: PromotionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (promotionReq === null || promotionReq === undefined) {
            throw new Error('Required parameter promotionReq was null or undefined when calling createPromotionVoucherUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PromotionDTO>(`${this.basePath}/api/promotion-voucher`,
            promotionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findPromotionsByVoucherType
     * 
     * @param productTypes productTypes
     * @param voucherCode voucherCode
     * @param promotionStatus promotionStatus
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findPromotionsByVoucherTypeUsingGET(productTypes?: Array<string>, voucherCode?: string, promotionStatus?: 'PUBLISHING' | 'PENDING' | 'TERMINATED' | 'FINISHED' | 'DRAFT', observe?: 'body', reportProgress?: boolean): Observable<Array<PromotionDTO>>;
    public findPromotionsByVoucherTypeUsingGET(productTypes?: Array<string>, voucherCode?: string, promotionStatus?: 'PUBLISHING' | 'PENDING' | 'TERMINATED' | 'FINISHED' | 'DRAFT', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PromotionDTO>>>;
    public findPromotionsByVoucherTypeUsingGET(productTypes?: Array<string>, voucherCode?: string, promotionStatus?: 'PUBLISHING' | 'PENDING' | 'TERMINATED' | 'FINISHED' | 'DRAFT', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PromotionDTO>>>;
    public findPromotionsByVoucherTypeUsingGET(productTypes?: Array<string>, voucherCode?: string, promotionStatus?: 'PUBLISHING' | 'PENDING' | 'TERMINATED' | 'FINISHED' | 'DRAFT', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productTypes) {
            productTypes.forEach((element) => {
                queryParameters = queryParameters.append('productTypes', <any>element);
            })
        }
        if (voucherCode !== undefined && voucherCode !== null) {
            queryParameters = queryParameters.set('voucherCode', <any>voucherCode);
        }
        if (promotionStatus !== undefined && promotionStatus !== null) {
            queryParameters = queryParameters.set('promotionStatus', <any>promotionStatus);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<PromotionDTO>>(`${this.basePath}/api/promotion/filter-by-voucher`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPromotionList
     * 
     * @param ids ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPromotionListUsingGET(ids: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PromotionDTO>>;
    public getPromotionListUsingGET(ids: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PromotionDTO>>>;
    public getPromotionListUsingGET(ids: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PromotionDTO>>>;
    public getPromotionListUsingGET(ids: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ids === null || ids === undefined) {
            throw new Error('Required parameter ids was null or undefined when calling getPromotionListUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<PromotionDTO>>(`${this.basePath}/api/promotion/find`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPromotion
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPromotionUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<PromotionDTO>;
    public getPromotionUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionDTO>>;
    public getPromotionUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionDTO>>;
    public getPromotionUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPromotionUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PromotionDTO>(`${this.basePath}/api/promotion/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * promotionDiscount
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public promotionDiscountUsingPOST(request: PromotionDiscountReq, observe?: 'body', reportProgress?: boolean): Observable<PromotionDiscountRes>;
    public promotionDiscountUsingPOST(request: PromotionDiscountReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionDiscountRes>>;
    public promotionDiscountUsingPOST(request: PromotionDiscountReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionDiscountRes>>;
    public promotionDiscountUsingPOST(request: PromotionDiscountReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling promotionDiscountUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PromotionDiscountRes>(`${this.basePath}/api/promotion/card-credit/discount`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * promotionRedeem
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public promotionRedeemUsingPOST(request: PromotionRedemptionReq, observe?: 'body', reportProgress?: boolean): Observable<PromotionRedemptionRes>;
    public promotionRedeemUsingPOST(request: PromotionRedemptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionRedemptionRes>>;
    public promotionRedeemUsingPOST(request: PromotionRedemptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionRedemptionRes>>;
    public promotionRedeemUsingPOST(request: PromotionRedemptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling promotionRedeemUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PromotionRedemptionRes>(`${this.basePath}/api/promotion/redeem`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * promotionValidate
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public promotionValidateUsingPOST(request: PromotionRedemptionReq, observe?: 'body', reportProgress?: boolean): Observable<PromotionRedemptionRes>;
    public promotionValidateUsingPOST(request: PromotionRedemptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionRedemptionRes>>;
    public promotionValidateUsingPOST(request: PromotionRedemptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionRedemptionRes>>;
    public promotionValidateUsingPOST(request: PromotionRedemptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling promotionValidateUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PromotionRedemptionRes>(`${this.basePath}/api/promotion/validate`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * publish
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishUsingPOST(id: number, observe?: 'body', reportProgress?: boolean): Observable<PromotionChangeRes>;
    public publishUsingPOST(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionChangeRes>>;
    public publishUsingPOST(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionChangeRes>>;
    public publishUsingPOST(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling publishUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PromotionChangeRes>(`${this.basePath}/api/publish/promotion`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * rebuildCache
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rebuildCacheUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public rebuildCacheUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public rebuildCacheUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public rebuildCacheUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/rebuildCache/promotion`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchPromotion4B
     * 
     * @param status status
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param aliasName aliasName
     * @param name name
     * @param saleChannel saleChannel
     * @param productType productType
     * @param effectiveFrom effectiveFrom
     * @param effectiveTo effectiveTo
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPromotion4BUsingGET(status?: string, createdDateFrom?: string, createdDateTo?: string, aliasName?: string, name?: string, saleChannel?: string, productType?: string, effectiveFrom?: string, effectiveTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfListOfPromotionDTO>;
    public searchPromotion4BUsingGET(status?: string, createdDateFrom?: string, createdDateTo?: string, aliasName?: string, name?: string, saleChannel?: string, productType?: string, effectiveFrom?: string, effectiveTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfListOfPromotionDTO>>;
    public searchPromotion4BUsingGET(status?: string, createdDateFrom?: string, createdDateTo?: string, aliasName?: string, name?: string, saleChannel?: string, productType?: string, effectiveFrom?: string, effectiveTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfListOfPromotionDTO>>;
    public searchPromotion4BUsingGET(status?: string, createdDateFrom?: string, createdDateTo?: string, aliasName?: string, name?: string, saleChannel?: string, productType?: string, effectiveFrom?: string, effectiveTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (aliasName !== undefined && aliasName !== null) {
            queryParameters = queryParameters.set('aliasName', <any>aliasName);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (saleChannel !== undefined && saleChannel !== null) {
            queryParameters = queryParameters.set('saleChannel', <any>saleChannel);
        }
        if (productType !== undefined && productType !== null) {
            queryParameters = queryParameters.set('productType', <any>productType);
        }
        if (effectiveFrom !== undefined && effectiveFrom !== null) {
            queryParameters = queryParameters.set('effectiveFrom', <any>effectiveFrom);
        }
        if (effectiveTo !== undefined && effectiveTo !== null) {
            queryParameters = queryParameters.set('effectiveTo', <any>effectiveTo);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaSearchResultOfListOfPromotionDTO>(`${this.basePath}/api/_search/promotion-b2b`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchPromotion4C
     * 
     * @param name name
     * @param saleChannel saleChannel
     * @param productType productType
     * @param airlinesCode airlinesCode
     * @param cardNumberPrefix cardNumberPrefix
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPromotion4CUsingGET(name?: string, saleChannel?: string, productType?: string, airlinesCode?: string, cardNumberPrefix?: string, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfListOfPromotionDTO>;
    public searchPromotion4CUsingGET(name?: string, saleChannel?: string, productType?: string, airlinesCode?: string, cardNumberPrefix?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfListOfPromotionDTO>>;
    public searchPromotion4CUsingGET(name?: string, saleChannel?: string, productType?: string, airlinesCode?: string, cardNumberPrefix?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfListOfPromotionDTO>>;
    public searchPromotion4CUsingGET(name?: string, saleChannel?: string, productType?: string, airlinesCode?: string, cardNumberPrefix?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (saleChannel !== undefined && saleChannel !== null) {
            queryParameters = queryParameters.set('saleChannel', <any>saleChannel);
        }
        if (productType !== undefined && productType !== null) {
            queryParameters = queryParameters.set('productType', <any>productType);
        }
        if (airlinesCode !== undefined && airlinesCode !== null) {
            queryParameters = queryParameters.set('airlinesCode', <any>airlinesCode);
        }
        if (cardNumberPrefix !== undefined && cardNumberPrefix !== null) {
            queryParameters = queryParameters.set('cardNumberPrefix', <any>cardNumberPrefix);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaSearchResultOfListOfPromotionDTO>(`${this.basePath}/api/_search/promotion-b2c`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * terminate
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public terminateUsingPOST(id: number, observe?: 'body', reportProgress?: boolean): Observable<PromotionChangeRes>;
    public terminateUsingPOST(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionChangeRes>>;
    public terminateUsingPOST(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionChangeRes>>;
    public terminateUsingPOST(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling terminateUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PromotionChangeRes>(`${this.basePath}/api/terminate/promotion`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updatePromotionTrackingStatus
     * 
     * @param promotionID promotionID
     * @param bookingNumber bookingNumber
     * @param tag tag
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePromotionTrackingStatusUsingPUT(promotionID: Array<string>, bookingNumber: string, tag: string, status: 'SUCCEEDED' | 'PENDING' | 'CANCELED' | 'FAILED', observe?: 'body', reportProgress?: boolean): Observable<PromotionRedemptionRes>;
    public updatePromotionTrackingStatusUsingPUT(promotionID: Array<string>, bookingNumber: string, tag: string, status: 'SUCCEEDED' | 'PENDING' | 'CANCELED' | 'FAILED', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionRedemptionRes>>;
    public updatePromotionTrackingStatusUsingPUT(promotionID: Array<string>, bookingNumber: string, tag: string, status: 'SUCCEEDED' | 'PENDING' | 'CANCELED' | 'FAILED', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionRedemptionRes>>;
    public updatePromotionTrackingStatusUsingPUT(promotionID: Array<string>, bookingNumber: string, tag: string, status: 'SUCCEEDED' | 'PENDING' | 'CANCELED' | 'FAILED', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (promotionID === null || promotionID === undefined) {
            throw new Error('Required parameter promotionID was null or undefined when calling updatePromotionTrackingStatusUsingPUT.');
        }

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling updatePromotionTrackingStatusUsingPUT.');
        }

        if (tag === null || tag === undefined) {
            throw new Error('Required parameter tag was null or undefined when calling updatePromotionTrackingStatusUsingPUT.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updatePromotionTrackingStatusUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (promotionID) {
            promotionID.forEach((element) => {
                queryParameters = queryParameters.append('promotionID', <any>element);
            })
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<PromotionRedemptionRes>(`${this.basePath}/api/promotion/updateTrackingStatus`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updatePromotion
     * 
     * @param promotionDTO promotionDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePromotionUsingPUT(promotionDTO: PromotionDTO, observe?: 'body', reportProgress?: boolean): Observable<PromotionDTO>;
    public updatePromotionUsingPUT(promotionDTO: PromotionDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionDTO>>;
    public updatePromotionUsingPUT(promotionDTO: PromotionDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionDTO>>;
    public updatePromotionUsingPUT(promotionDTO: PromotionDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (promotionDTO === null || promotionDTO === undefined) {
            throw new Error('Required parameter promotionDTO was null or undefined when calling updatePromotionUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PromotionDTO>(`${this.basePath}/api/promotion`,
            promotionDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * voidPromotion
     * 
     * @param promotionID promotionID
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public voidPromotionUsingPOST(promotionID: number, bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<PromotionChangeRes>;
    public voidPromotionUsingPOST(promotionID: number, bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionChangeRes>>;
    public voidPromotionUsingPOST(promotionID: number, bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionChangeRes>>;
    public voidPromotionUsingPOST(promotionID: number, bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (promotionID === null || promotionID === undefined) {
            throw new Error('Required parameter promotionID was null or undefined when calling voidPromotionUsingPOST.');
        }

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling voidPromotionUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (promotionID !== undefined && promotionID !== null) {
            queryParameters = queryParameters.set('promotionID', <any>promotionID);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<PromotionChangeRes>(`${this.basePath}/api/void/promotion`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
