import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { PixelEventName, PixelEventProperties } from './pixel-tracking.model';
import { environment } from '@env/environment';

declare const fbq: any;

@Injectable({
  providedIn: 'root'
})
export class PixelTrackingService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  track(eventName: PixelEventName, properties?: PixelEventProperties): void {
    if (
      !isPlatformBrowser(this.platformId) ||
      localStorage.getItem('appName') !== 'B2C'
    ) {
      return;
    }

    if (properties) {
      fbq('track', eventName, properties);
    } else {
      fbq('track', eventName);
    }
  }
  trackCustom(
    eventName: PixelEventName,
    properties?: PixelEventProperties
  ): void {
    if (
      !isPlatformBrowser(this.platformId) ||
      localStorage.getItem('appName') !== 'B2C'
    ) {
      return;
    }
    if (properties) {
      fbq('trackCustom', eventName, properties);
    } else {
      fbq('trackCustom', eventName);
    }
  }
}
