import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { PopularPlacesPartialState } from './popular-places.reducer';
import {
  LoadPopularPlaces,
  PopularPlacesLoaded,
  PopularPlacesLoadError,
  PopularPlacesActionTypes
} from './popular-places.actions';
import {map} from "rxjs/operators";
import {InternalConfigResourceService} from "@gtd/meta-client";

@Injectable()
export class PopularPlacesEffects {
  @Effect() loadPopularPlaces$ = this.dataPersistence.fetch(
    PopularPlacesActionTypes.LoadPopularPlaces,
    {
      run: (action: LoadPopularPlaces, state: PopularPlacesPartialState) => {
        return this.internalConfigResourceService.getInternalConfigByNameUsingGET(action.payload)
          .pipe(
            map(updated => ({
              type: PopularPlacesActionTypes.PopularPlacesLoaded,
              payload: updated
            }))
          );
      },

      onError: (action: LoadPopularPlaces, error) => {
        console.error('Error', error);
        return new PopularPlacesLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private internalConfigResourceService: InternalConfigResourceService,
    private dataPersistence: DataPersistence<PopularPlacesPartialState>
  ) {}
}
