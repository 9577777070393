import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UnsubscribeService {
  unsubscribe$ = new Subject();
  constructor() {}

  destroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
