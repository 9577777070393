/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TourLocationDTO } from './tourLocationDTO';
import { TourTranslationDTO } from './tourTranslationDTO';


export interface TourSuggestSearchDTO { 
    activityVisiting?: Array<Array<TourTranslationDTO>>;
    address?: Array<TourTranslationDTO>;
    countryCode?: string;
    countryName?: Array<TourTranslationDTO>;
    location?: TourLocationDTO;
    name?: Array<TourTranslationDTO>;
    searchCode?: string;
    searchType?: TourSuggestSearchDTO.SearchTypeEnum;
    tags?: Array<string>;
}
export namespace TourSuggestSearchDTO {
    export type SearchTypeEnum = 'AUTO' | 'DESTINATION' | 'CATEGORY' | 'ACTIVITY' | 'CONTINENT' | 'COUNTRY' | 'PROVINCE_STATE' | 'HIGH_LEVEL_REGION' | 'MULTI_CITY_VICINITY' | 'CITY' | 'NEIGHBORHOOD' | 'AIRPORT' | 'POINT_OF_INTEREST' | 'TRAIN_STATION' | 'METRO_STATION' | 'HOTEL';
    export const SearchTypeEnum = {
        AUTO: 'AUTO' as SearchTypeEnum,
        DESTINATION: 'DESTINATION' as SearchTypeEnum,
        CATEGORY: 'CATEGORY' as SearchTypeEnum,
        ACTIVITY: 'ACTIVITY' as SearchTypeEnum,
        CONTINENT: 'CONTINENT' as SearchTypeEnum,
        COUNTRY: 'COUNTRY' as SearchTypeEnum,
        PROVINCESTATE: 'PROVINCE_STATE' as SearchTypeEnum,
        HIGHLEVELREGION: 'HIGH_LEVEL_REGION' as SearchTypeEnum,
        MULTICITYVICINITY: 'MULTI_CITY_VICINITY' as SearchTypeEnum,
        CITY: 'CITY' as SearchTypeEnum,
        NEIGHBORHOOD: 'NEIGHBORHOOD' as SearchTypeEnum,
        AIRPORT: 'AIRPORT' as SearchTypeEnum,
        POINTOFINTEREST: 'POINT_OF_INTEREST' as SearchTypeEnum,
        TRAINSTATION: 'TRAIN_STATION' as SearchTypeEnum,
        METROSTATION: 'METRO_STATION' as SearchTypeEnum,
        HOTEL: 'HOTEL' as SearchTypeEnum
    };
}
