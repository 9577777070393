import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HISTORYSEARCHKEYWORD_FEATURE_KEY,
  HistorySearchKeywordState
} from './history-search-keyword.reducer';

// Lookup the 'HistorySearchKeyword' feature state managed by NgRx
const getHistorySearchKeywordState = createFeatureSelector<
  HistorySearchKeywordState
>(HISTORYSEARCHKEYWORD_FEATURE_KEY);

const getLoaded = createSelector(
  getHistorySearchKeywordState,
  (state: HistorySearchKeywordState) => state.loaded
);
const getError = createSelector(
  getHistorySearchKeywordState,
  (state: HistorySearchKeywordState) => state.error
);

const getAllHistorySearchKeywordFlightFrom = createSelector(
  getHistorySearchKeywordState,
  getLoaded,
  (state: HistorySearchKeywordState, isLoaded) => {
    return isLoaded ? state.flightFrom : [];
  }
);

const getAllHistorySearchKeywordFlight = createSelector(
  getHistorySearchKeywordState,
  getLoaded,
  (state: HistorySearchKeywordState, isLoaded) => {
    return isLoaded ? {from: state.flightFrom, to: state.flightTo} : null;
  }
);

const getAllHistorySearchKeywordFlightTo = createSelector(
  getHistorySearchKeywordState,
  getLoaded,
  (state: HistorySearchKeywordState, isLoaded) => {
    return isLoaded ? state.flightTo : [];
  }
);

const getSelectedId = createSelector(
  getHistorySearchKeywordState,
  (state: HistorySearchKeywordState) => state.selectedId
);

const getSelectedHistorySearchKeywordFlightFrom = createSelector(
  getAllHistorySearchKeywordFlightFrom,
  getSelectedId,
  (historySearchKeyword, id) => {
    const result = historySearchKeyword.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

const getSelectedHistorySearchKeywordFlightTo = createSelector(
  getAllHistorySearchKeywordFlightTo,
  getSelectedId,
  (historySearchKeyword, id) => {
    const result = historySearchKeyword.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);


export const historySearchKeywordQuery = {
  getLoaded,
  getError,
  getAllHistorySearchKeywordFlight,
  getAllHistorySearchKeywordFlightFrom,
  getAllHistorySearchKeywordFlightTo,
  getSelectedHistorySearchKeywordFlightFrom,
  getSelectedHistorySearchKeywordFlightTo
};
