import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  REGISTERSTATE_FEATURE_KEY,
  RegisterStateState
} from './register-state.reducer';

// Lookup the 'RegisterState' feature state managed by NgRx
const getRegisterStateState = createFeatureSelector<RegisterStateState>(
  REGISTERSTATE_FEATURE_KEY
);

const getLoaded = createSelector(
  getRegisterStateState,
  (state: RegisterStateState) => state.loaded
);
const getError = createSelector(
  getRegisterStateState,
  (state: RegisterStateState) => state.status
);

const getAllRegisterState = createSelector(
  getRegisterStateState,
  getLoaded,
  (state: RegisterStateState, isLoaded) => {
    return isLoaded ? state : null;
  }
);
const getSelectedId = createSelector(
  getRegisterStateState,
  (state: RegisterStateState) => state.selectedId
);
const getSelectedRegisterState = createSelector(
  getAllRegisterState,
  getSelectedId,
  (registerState, id) => {
    return registerState ? Object.assign({}, registerState) : undefined;
  }
);

export const registerStateQuery = {
  getLoaded,
  getError,
  getAllRegisterState,
  getSelectedRegisterState
};
