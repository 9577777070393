import {
  Component,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AirTicketsResourceService,
  AirTraveler,
  BookingInfo,
  BookingTransactionInfo,
  FareRules,
  FlightSegment,
  GroupBooking,
  GroupPricedItinerary
} from '@gtd/b2c-client';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BookingDetailFacade } from '../booking-detail/detail-state/+state/booking-detail.facade';
import { BreadcrumbBar } from '@gtd/components/breadcrumb-bar';
import { environment } from '@env/environment';

@Component({
  selector: 'gtd-flight-detail',
  templateUrl: './flight-detail.component.html',
  styleUrls: ['./flight-detail.component.scss']
})
export class FlightDetailComponent implements OnInit, OnDestroy {
  bookingNumber: string;
  itemJourney: number;
  odo: number;
  subscriptions = new Subscription();
  flightSegments: Array<FlightSegment>;
  bookingInfo: BookingInfo;
  paymentType: any;
  passengerNameRecords: any;
  groupPricedItineraries: Array<GroupPricedItinerary>;
  travelerInfos: Array<AirTraveler>;
  appName = environment.appName;
  loaded$ = this.bookingDetailFacade.loaded$;
  allFarerules: FareRules[];
  submitLoadMore: boolean = false;

  breadcrumbBars: BreadcrumbBar[] = [
    {
      name: 'global.homepage',
      isRouterLink: true,
      routerLink: '/'
    },
    {
      isRouterLink: true,
      name: 'booking.booking-management',
      routerLink: '/user/bookings'
    },
    {
      isRouterLink: false,
      name: 'booking-detail.booking-detail'
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private bookingDetailFacade: BookingDetailFacade,
    private airTicketsResourceService: AirTicketsResourceService
  ) {
    this.route.queryParams.subscribe(params => {
      this.bookingNumber = params.booking_number;
      this.itemJourney = params.pi_id;
      this.odo = params.odo;
      if (this.bookingNumber && this.itemJourney) {
        this.bookingDetailFacade.getBookingByBookingNumber(this.bookingNumber);
      }
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.bookingDetailFacade.bookingDetail$.subscribe(
        (groupBooking: GroupBooking) => {
          if (groupBooking) {
            this.flightSegments =
              groupBooking.groupPricedItineraries[
                groupBooking.groupPricedItineraries[0].flightType ===
                'INTERNATIONAL'
                  ? 0
                  : this.itemJourney
              ].pricedItineraries[0].originDestinationOptions[
                this.odo
              ].flightSegments;
            this.bookingInfo = groupBooking.bookingInfo;
            this.paymentType = groupBooking.bookingInfo.paymentType;
            this.passengerNameRecords =
              groupBooking.bookingInfo.passengerNameRecords;
            this.groupPricedItineraries = groupBooking.groupPricedItineraries;
            // this.travelerInfos = groupBooking.travelerInfo.airTravelers;
          }
        }
      )
    );
  }
  changeFlightDetail() {
    let newPiId = +this.itemJourney === 0 ? 1 : 0;
    let newQuery = {
      booking_number: this.bookingNumber,
      pi_id: newPiId,
      odo:
        this.groupPricedItineraries[0].flightType === 'INTERNATIONAL'
          ? newPiId
          : 0
    };
    this.router.navigate(['/user/booking/flight-detail'], {
      queryParams: newQuery
    });
  }
  getFareRules() {
    this.submitLoadMore = true;
    if (this.bookingNumber) {
      this.airTicketsResourceService
        .retrieveAirFareRulesUsingGET(this.bookingNumber)
        .subscribe(allFarerules => {
          if (allFarerules) {
            this.submitLoadMore = false;
            this.allFarerules =
              allFarerules.bookedFareRules[this.itemJourney].fareRules;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  backClicked() {
    this.router.navigate(['/user/booking/detail'], {
      queryParams: {
        booking_number: this.bookingNumber
      }
    });
    // this._location.back();
  }
}
@Pipe({
  name: 'passengerNameRecord'
})
export class PassengerNameRecord implements PipeTransform {
  transform(
    bookingTransactionInfos: Array<BookingTransactionInfo>,
    item: number
  ): string {
    if (item == 0) {
      let bookingTransactionInfoTemp = bookingTransactionInfos.filter(
        bookingTransactionInfo =>
          bookingTransactionInfo.bookingDirection === 'DEPARTURE'
      );
      if (bookingTransactionInfoTemp && bookingTransactionInfoTemp.length > 0) {
        return bookingTransactionInfoTemp[0].passengerNameRecord;
      }
    }
    if (item == 1) {
      let bookingTransactionInfoTemp = bookingTransactionInfos.filter(
        bookingTransactionInfo =>
          bookingTransactionInfo.bookingDirection === 'RETURN'
      );
      if (bookingTransactionInfoTemp && bookingTransactionInfoTemp.length > 0) {
        return bookingTransactionInfoTemp[0].passengerNameRecord;
      }
    }
  }
}
