/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AirItineraryPricingInfo } from './airItineraryPricingInfo';
import { OriginDestinationOption } from './originDestinationOption';
import { SSRItem } from './sSRItem';


export interface PricedItinerary { 
    airItineraryPricingInfo?: AirItineraryPricingInfo;
    allowHold?: boolean;
    baggageItems?: Array<SSRItem>;
    cabinClassName?: PricedItinerary.CabinClassNameEnum;
    directionInd?: PricedItinerary.DirectionIndEnum;
    fightNo?: string;
    mealItems?: Array<SSRItem>;
    onlyPayLater?: boolean;
    originDestinationOptions?: Array<OriginDestinationOption>;
    passportMandatory?: boolean;
    refundable?: boolean;
    sequenceNumber?: string;
    ticketType?: PricedItinerary.TicketTypeEnum;
    validReturnCabinClasses?: Array<string>;
    validatingAirlineCode?: string;
    validatingAirlineName?: string;
}
export namespace PricedItinerary {
    export type CabinClassNameEnum = 'BUSINESS' | 'FIRST' | 'PREMIUM' | 'ECONOMY' | 'PROMO';
    export const CabinClassNameEnum = {
        BUSINESS: 'BUSINESS' as CabinClassNameEnum,
        FIRST: 'FIRST' as CabinClassNameEnum,
        PREMIUM: 'PREMIUM' as CabinClassNameEnum,
        ECONOMY: 'ECONOMY' as CabinClassNameEnum,
        PROMO: 'PROMO' as CabinClassNameEnum
    };
    export type DirectionIndEnum = 'DEPARTURE' | 'RETURN';
    export const DirectionIndEnum = {
        DEPARTURE: 'DEPARTURE' as DirectionIndEnum,
        RETURN: 'RETURN' as DirectionIndEnum
    };
    export type TicketTypeEnum = 'ETICKET' | 'OTHER';
    export const TicketTypeEnum = {
        ETICKET: 'ETICKET' as TicketTypeEnum,
        OTHER: 'OTHER' as TicketTypeEnum
    };
}
