import {Action} from '@ngrx/store';
import {CommonResponse, ManagedUserVM} from "@gtd/b2c-client";

export enum RegisterStateActionTypes {
  LoadRegisterState = '[RegisterAccount] Load RegisterAccount',
  RegisterStateLoaded = '[RegisterAccount] RegisterAccount Loaded',
  RegisterStateLoadError = '[RegisterAccount] RegisterAccount Load Error',
  RegisterStateLoadResetAll = '[RegisterAccount] RegisterAccount Reset All'
}

export class LoadRegisterState implements Action {
  readonly type = RegisterStateActionTypes.LoadRegisterState;
  constructor(public payload: ManagedUserVM) {}
}

export class RegisterStateLoadError implements Action {
  readonly type = RegisterStateActionTypes.RegisterStateLoadError;
  constructor(public payload: any) {}
}

export class RegisterStateLoaded implements Action {
  readonly type = RegisterStateActionTypes.RegisterStateLoaded;
  constructor(public payload: CommonResponse) {}
}

export class ResetAllRegisterState implements Action {
  readonly type = RegisterStateActionTypes.RegisterStateLoadResetAll;
}

export type RegisterStateAction =
  | LoadRegisterState
  | RegisterStateLoaded
  | ResetAllRegisterState
  | RegisterStateLoadError;

export const fromRegisterStateActions = {
  LoadRegisterState,
  RegisterStateLoaded,
  ResetAllRegisterState,
  RegisterStateLoadError
};
