import {Action} from '@ngrx/store';
import {AirportDTO} from "@gtd/meta-client";

export enum HistorySearchKeywordActionTypes {
  HistorySearchKeywordFlightFrom = '[HistorySearchKeyword] History Search Keyword Flight From',
  HistorySearchKeywordFlightTo = '[HistorySearchKeyword] History Search Keyword Flight To',
  HistorySearchKeywordHotel = '[HistorySearchKeyword] History Search Keyword Hotel',
}

export class HistorySearchKeywordFlightFrom implements Action {
  readonly type = HistorySearchKeywordActionTypes.HistorySearchKeywordFlightFrom;
  constructor(public payload: AirportDTO[]) {}
}

export class HistorySearchKeywordFlightTo implements Action {
  readonly type = HistorySearchKeywordActionTypes.HistorySearchKeywordFlightTo;
  constructor(public payload: AirportDTO[]) {}
}


export type HistorySearchKeywordAction =
  | HistorySearchKeywordFlightFrom
  | HistorySearchKeywordFlightTo;

export const fromHistorySearchKeywordActions = {
  HistorySearchKeywordFlightFrom,
  HistorySearchKeywordFlightTo
};
