import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { HotelCreateDraftBookingPartialState } from './hotel-create-draft-booking.reducer';
import {
  HotelCreateDraftBookingActionTypes,
  ComboVJHotelCreateDraftBookingLoadError,
  ComboVJLoadHotelCreateDraftBooking
} from './hotel-create-draft-booking.actions';
import { map } from 'rxjs/operators';
import { HotelResourceV3Service } from '@gtd/b2c-client';

@Injectable()
export class HotelCreateDraftBookingEffects {
  @Effect() loadComboVJHotelCreateDraftBooking$ = this.dataPersistence.fetch(
    HotelCreateDraftBookingActionTypes.ComboVJLoadHotelCreateDraftBooking,
    {
      run: (
        action: ComboVJLoadHotelCreateDraftBooking,
        state: HotelCreateDraftBookingPartialState
      ) => {
        return this.hotelResourceV3Service
          .createDraftBookingUsingPOST2(action.payload)
          .pipe(
            map(payload => ({
              type:
                HotelCreateDraftBookingActionTypes.ComboVJHotelCreateDraftBookingLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: ComboVJLoadHotelCreateDraftBooking, error) => {
        console.error('Error', error);
        return new ComboVJHotelCreateDraftBookingLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private hotelResourceV3Service: HotelResourceV3Service,
    private dataPersistence: DataPersistence<
      HotelCreateDraftBookingPartialState
    >
  ) {}
}
