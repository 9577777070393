import { Component, Input, OnInit } from '@angular/core';
import { TourActivityProduct } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-tour-booking-detail',
  templateUrl: './tour-booking-detail.component.html',
  styleUrls: ['./tour-booking-detail.component.scss']
})
export class TourBookingDetailComponent implements OnInit {
  @Input() tourDetail: TourActivityProduct;
  @Input() tourDepartureDate: Date;
  @Input() tourReturnDate: Date;
  @Input() tourPaxInfos: any;
  @Input() isMyBooking = false;
  @Input() bookingInfo: any;
  @Input() totalAdditionalRequests: number;

  additionalRequestActions = [];
  isShowAdditionalRequestButton: boolean = false;

  constructor() {}

  ngOnInit() {
    if (this.bookingInfo.departureDate) {
      if (
        new Date().getTime() <
        new Date(this.bookingInfo.departureDate).getTime()
      ) {
        this.isShowAdditionalRequestButton = true;
      }
    }
    if (this.bookingInfo.supplierType === 'TOURS') {
      this.additionalRequestActions = [
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    }
  }
}
