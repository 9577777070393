import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { GroupItineraryPartialState } from './group-itinerary.reducer';
import {
  GroupItineraryActionTypes,
  ComboVJGroupItineraryLoadError,
  ComboVJLoadGroupItinerary
} from './group-itinerary.actions';
import { map } from 'rxjs/operators';
import { AirTicketsResourceService } from '@gtd/b2c-client';

@Injectable()
export class GroupItineraryEffects {
  @Effect() ComboVJloadGroupItinerary$ = this.dataPersistence.fetch(
    GroupItineraryActionTypes.ComboVJLoadGroupItinerary,
    {
      run: (
        action: ComboVJLoadGroupItinerary,
        state: GroupItineraryPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.airTicketsResourceService
          .getGroupPricedItineraryUsingPOST(
            action.payload.id,
            action.payload.airSearchId,
            action.payload.includeEquivfare,
            action.payload.page,
            action.payload.size,
            action.payload.sort
          )
          .pipe(
            map(update => ({
              type: GroupItineraryActionTypes.ComboVJGroupItineraryLoaded,
              payload: update
            }))
          );
      },

      onError: (action: ComboVJLoadGroupItinerary, error) => {
        console.error('Error', error);
        return new ComboVJGroupItineraryLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private airTicketsResourceService: AirTicketsResourceService,
    private dataPersistence: DataPersistence<GroupItineraryPartialState>
  ) {}
}
