import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CredentialsService} from "@gtd/auth";
import {Router} from "@angular/router";
import {environment} from "@env/environment";

@Component({
  selector: 'gtd-member-section',
  templateUrl: './member-section.component.html',
  styleUrls: ['./member-section.component.scss'],
  encapsulation: environment.appName === 'B2B2C'? ViewEncapsulation.None: ViewEncapsulation.Emulated,
})
export class MemberSectionComponent implements OnInit {
  @Input() mainClass: string;
  @Input() isContainer: boolean;
  isLogged: boolean;
  appName = localStorage.getItem('appName');

  constructor(private router: Router, private credentialsService: CredentialsService) {
    if (this.credentialsService.isAuthenticated() === true) {
      this.isLogged = true;
    }
  }

  loggin() {
    let params = (this.router.url !== '/auth/login'  &&  this.router.url !== '/auth/register') ? btoa(encodeURIComponent(JSON.stringify({urlRedirect: this.router.url}))): null;
    this.router.navigate(['/auth/login'], {
      queryParams: {
        params: params
      }
    });
  }

  ngOnInit() {
  }

}
