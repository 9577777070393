/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BookingStatusDTO { 
    bookingCode?: string;
    bookingDate?: Date;
    bookingNumber?: string;
    bookingType?: BookingStatusDTO.BookingTypeEnum;
    channelType?: BookingStatusDTO.ChannelTypeEnum;
    deleted?: boolean;
    issuedDate?: Date;
    issuedStatus?: BookingStatusDTO.IssuedStatusEnum;
    paymentDate?: Date;
    paymentStatus?: BookingStatusDTO.PaymentStatusEnum;
    paymentType?: BookingStatusDTO.PaymentTypeEnum;
    saleChannel?: BookingStatusDTO.SaleChannelEnum;
    status?: BookingStatusDTO.StatusEnum;
    supplierBookingStatus?: BookingStatusDTO.SupplierBookingStatusEnum;
    supplierType?: BookingStatusDTO.SupplierTypeEnum;
    timeToLive?: Date;
}
export namespace BookingStatusDTO {
    export type BookingTypeEnum = 'DOME' | 'INTE';
    export const BookingTypeEnum = {
        DOME: 'DOME' as BookingTypeEnum,
        INTE: 'INTE' as BookingTypeEnum
    };
    export type ChannelTypeEnum = 'ONLINE' | 'OFFLINE';
    export const ChannelTypeEnum = {
        ONLINE: 'ONLINE' as ChannelTypeEnum,
        OFFLINE: 'OFFLINE' as ChannelTypeEnum
    };
    export type IssuedStatusEnum = 'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED';
    export const IssuedStatusEnum = {
        PENDING: 'PENDING' as IssuedStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as IssuedStatusEnum,
        SUCCEEDED: 'SUCCEEDED' as IssuedStatusEnum,
        FAILED: 'FAILED' as IssuedStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as IssuedStatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as IssuedStatusEnum
    };
    export type PaymentStatusEnum = 'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'PENDING';
    export const PaymentStatusEnum = {
        SUCCEEDED: 'SUCCEEDED' as PaymentStatusEnum,
        FAILED: 'FAILED' as PaymentStatusEnum,
        REFUNDED: 'REFUNDED' as PaymentStatusEnum,
        PENDING: 'PENDING' as PaymentStatusEnum
    };
    export type PaymentTypeEnum = 'TRANSFER' | 'CASH' | 'BALANCE' | 'ATM_DEBIT' | 'CREDIT' | 'OTHER';
    export const PaymentTypeEnum = {
        TRANSFER: 'TRANSFER' as PaymentTypeEnum,
        CASH: 'CASH' as PaymentTypeEnum,
        BALANCE: 'BALANCE' as PaymentTypeEnum,
        ATMDEBIT: 'ATM_DEBIT' as PaymentTypeEnum,
        CREDIT: 'CREDIT' as PaymentTypeEnum,
        OTHER: 'OTHER' as PaymentTypeEnum
    };
    export type SaleChannelEnum = 'B2B' | 'B2C' | 'B2B2C';
    export const SaleChannelEnum = {
        B2B: 'B2B' as SaleChannelEnum,
        B2C: 'B2C' as SaleChannelEnum,
        B2B2C: 'B2B2C' as SaleChannelEnum
    };
    export type StatusEnum = 'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as StatusEnum,
        BOOKED: 'BOOKED' as StatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        TENTATIVE: 'TENTATIVE' as StatusEnum
    };
    export type SupplierBookingStatusEnum = 'PENDING' | 'BOOKED' | 'CONFIRMED' | 'FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'BOOKING_ON_PROCESS' | 'TICKET_ON_PROCESS' | 'PARTLY_BOOKED' | 'PARTLY_CONFIRMED' | 'ISSUE_FAILED';
    export const SupplierBookingStatusEnum = {
        PENDING: 'PENDING' as SupplierBookingStatusEnum,
        BOOKED: 'BOOKED' as SupplierBookingStatusEnum,
        CONFIRMED: 'CONFIRMED' as SupplierBookingStatusEnum,
        FAILED: 'FAILED' as SupplierBookingStatusEnum,
        CANCELLED: 'CANCELLED' as SupplierBookingStatusEnum,
        EXPIRED: 'EXPIRED' as SupplierBookingStatusEnum,
        TENTATIVE: 'TENTATIVE' as SupplierBookingStatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as SupplierBookingStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as SupplierBookingStatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as SupplierBookingStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as SupplierBookingStatusEnum,
        ISSUEFAILED: 'ISSUE_FAILED' as SupplierBookingStatusEnum
    };
    export type SupplierTypeEnum = 'AIR' | 'HOTEL' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const SupplierTypeEnum = {
        AIR: 'AIR' as SupplierTypeEnum,
        HOTEL: 'HOTEL' as SupplierTypeEnum,
        TOURS: 'TOURS' as SupplierTypeEnum,
        TRAIN: 'TRAIN' as SupplierTypeEnum,
        SHIP: 'SHIP' as SupplierTypeEnum,
        OTHER: 'OTHER' as SupplierTypeEnum
    };
}
