import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor(@Inject('env') private _env: any) {}
  get(key: string) {
    return this._env[key];
  }
}
