import { Action } from '@ngrx/store';
import {LoginVM} from "@gtd/b2c-client";

export enum AuthenticateActionTypes {
  LOGIN = '[Authentication] Login',
  LOGIN_SUCCESS = '[Authentication] Login Success',
  LOGIN_FAILURE = '[Authentication] Login Failure',
  LOGOUT = '[Authentication] Logout',
  RESETALL = '[Authentication] Reset All',
}

export class Login implements Action {
  readonly type = AuthenticateActionTypes.LOGIN;
  constructor(public payload: LoginVM) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthenticateActionTypes.LOGIN_SUCCESS;
  constructor(public payload: any) {}
}

export class LoginFailure implements Action {
  readonly type = AuthenticateActionTypes.LOGIN_FAILURE;
  constructor(public payload: any) {}
}

export class Logout implements Action {
  readonly type = AuthenticateActionTypes.LOGOUT;
}

export class ResetAll implements Action {
  readonly type = AuthenticateActionTypes.RESETALL;
}

export type AuthenticateAction =
  | Login
  | LoginSuccess
  | LoginFailure
  | ResetAll
  | Logout;

export const fromAuthenticateActions = {
  Login,
  LoginSuccess,
  LoginFailure,
  ResetAll,
  Logout
};
