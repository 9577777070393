/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AgencyChannelDTO } from '../model/agencyChannelDTO';
import { ChangePasswordVM } from '../model/changePasswordVM';
import { CommonResponse } from '../model/commonResponse';
import { EmailJson } from '../model/emailJson';
import { KeyAndPasswordVM } from '../model/keyAndPasswordVM';
import { ManagedMechantVM } from '../model/managedMechantVM';
import { ManagedUserVM } from '../model/managedUserVM';
import { OtaResultOfPropertyDTO } from '../model/otaResultOfPropertyDTO';
import { RegisterPhoneNumberVM } from '../model/registerPhoneNumberVM';
import { ResponseEntity } from '../model/responseEntity';
import { UserDTO } from '../model/userDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AccountResourceService {

    protected basePath = 'https://10.7.71.20:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * activateAccountByMobileNumber
     *
     * @param registerPhoneNumberVM registerPhoneNumberVM
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public activateAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public activateAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public activateAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (registerPhoneNumberVM === null || registerPhoneNumberVM === undefined) {
            throw new Error('Required parameter registerPhoneNumberVM was null or undefined when calling activateAccountByMobileNumberUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/register/phone-number/activate`,
            registerPhoneNumberVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * activateAccount
     *
     * @param key key
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateAccountUsingGET(key: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public activateAccountUsingGET(key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public activateAccountUsingGET(key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public activateAccountUsingGET(key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling activateAccountUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (key !== undefined && key !== null) {
            queryParameters = queryParameters.set('key', <any>key);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/activate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changePassword
     *
     * @param changePasswordVM changePasswordVM
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePasswordUsingPOST(changePasswordVM: ChangePasswordVM, observe?: 'body', reportProgress?: boolean): Observable<CommonResponse>;
    public changePasswordUsingPOST(changePasswordVM: ChangePasswordVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommonResponse>>;
    public changePasswordUsingPOST(changePasswordVM: ChangePasswordVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommonResponse>>;
    public changePasswordUsingPOST(changePasswordVM: ChangePasswordVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (changePasswordVM === null || changePasswordVM === undefined) {
            throw new Error('Required parameter changePasswordVM was null or undefined when calling changePasswordUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommonResponse>(`${this.basePath}/api/account/change-password`,
            changePasswordVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * completeResetPasswdAccountByMobileNumber
     *
     * @param registerPhoneNumberVM registerPhoneNumberVM
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public completeResetPasswdAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public completeResetPasswdAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public completeResetPasswdAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public completeResetPasswdAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (registerPhoneNumberVM === null || registerPhoneNumberVM === undefined) {
            throw new Error('Required parameter registerPhoneNumberVM was null or undefined when calling completeResetPasswdAccountByMobileNumberUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/account/phone-number/reset-password/complete`,
            registerPhoneNumberVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * finishPasswordReset
     *
     * @param keyAndPassword keyAndPassword
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finishPasswordResetUsingPOST(keyAndPassword: KeyAndPasswordVM, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public finishPasswordResetUsingPOST(keyAndPassword: KeyAndPasswordVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public finishPasswordResetUsingPOST(keyAndPassword: KeyAndPasswordVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public finishPasswordResetUsingPOST(keyAndPassword: KeyAndPasswordVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (keyAndPassword === null || keyAndPassword === undefined) {
            throw new Error('Required parameter keyAndPassword was null or undefined when calling finishPasswordResetUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/account/reset-password/finish`,
            keyAndPassword,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAccount
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccountUsingGET(observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public getAccountUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public getAccountUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public getAccountUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ResponseEntity>(`${this.basePath}/api/account`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getChannelAgencyByCode
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelAgencyByCodeUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyChannelDTO>;
    public getChannelAgencyByCodeUsingGET(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyChannelDTO>>;
    public getChannelAgencyByCodeUsingGET(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyChannelDTO>>;
    public getChannelAgencyByCodeUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling getChannelAgencyByCodeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyChannelDTO>(`${this.basePath}/api/agencies/get-channels-by-code/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * initResetPasswdAccountByMobileNumber
     *
     * @param registerPhoneNumberVM registerPhoneNumberVM
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initResetPasswdAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public initResetPasswdAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public initResetPasswdAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public initResetPasswdAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (registerPhoneNumberVM === null || registerPhoneNumberVM === undefined) {
            throw new Error('Required parameter registerPhoneNumberVM was null or undefined when calling initResetPasswdAccountByMobileNumberUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/account/phone-number/reset-password/init`,
            registerPhoneNumberVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * isAuthenticated
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isAuthenticatedUsingGET(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public isAuthenticatedUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public isAuthenticatedUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public isAuthenticatedUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/authenticate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * logout
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logoutUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public logoutUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public logoutUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public logoutUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/api/account/log-out`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * registerAccountByMobileNumber
     *
     * @param registerPhoneNumberVM registerPhoneNumberVM
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public registerAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public registerAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public registerAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (registerPhoneNumberVM === null || registerPhoneNumberVM === undefined) {
            throw new Error('Required parameter registerPhoneNumberVM was null or undefined when calling registerAccountByMobileNumberUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/register/phone-number`,
            registerPhoneNumberVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * registerAccount
     *
     * @param managedUserVM managedUserVM
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerAccountUsingPOST(managedUserVM: ManagedUserVM, observe?: 'body', reportProgress?: boolean): Observable<CommonResponse>;
    public registerAccountUsingPOST(managedUserVM: ManagedUserVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommonResponse>>;
    public registerAccountUsingPOST(managedUserVM: ManagedUserVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommonResponse>>;
    public registerAccountUsingPOST(managedUserVM: ManagedUserVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (managedUserVM === null || managedUserVM === undefined) {
            throw new Error('Required parameter managedUserVM was null or undefined when calling registerAccountUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommonResponse>(`${this.basePath}/api/register`,
            managedUserVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * registerMerchantAccount
     *
     * @param managedMechantVM managedMechantVM
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerMerchantAccountUsingPOST(managedMechantVM: ManagedMechantVM, observe?: 'body', reportProgress?: boolean): Observable<CommonResponse>;
    public registerMerchantAccountUsingPOST(managedMechantVM: ManagedMechantVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommonResponse>>;
    public registerMerchantAccountUsingPOST(managedMechantVM: ManagedMechantVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommonResponse>>;
    public registerMerchantAccountUsingPOST(managedMechantVM: ManagedMechantVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (managedMechantVM === null || managedMechantVM === undefined) {
            throw new Error('Required parameter managedMechantVM was null or undefined when calling registerMerchantAccountUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommonResponse>(`${this.basePath}/api/merchant/register`,
            managedMechantVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * requestMobilePasswordReset
     *
     * @param emailJson emailJson
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestMobilePasswordResetUsingPOST(emailJson: EmailJson, observe?: 'body', reportProgress?: boolean): Observable<CommonResponse>;
    public requestMobilePasswordResetUsingPOST(emailJson: EmailJson, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommonResponse>>;
    public requestMobilePasswordResetUsingPOST(emailJson: EmailJson, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommonResponse>>;
    public requestMobilePasswordResetUsingPOST(emailJson: EmailJson, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (emailJson === null || emailJson === undefined) {
            throw new Error('Required parameter emailJson was null or undefined when calling requestMobilePasswordResetUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CommonResponse>(`${this.basePath}/api/account/reset-password-mobile/init`,
            emailJson,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * requestPasswordReset
     *
     * @param mail mail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestPasswordResetUsingPOST(mail: string, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public requestPasswordResetUsingPOST(mail: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public requestPasswordResetUsingPOST(mail: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public requestPasswordResetUsingPOST(mail: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mail === null || mail === undefined) {
            throw new Error('Required parameter mail was null or undefined when calling requestPasswordResetUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/api/account/reset-password/init`,
            mail,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * resendOtpAccountByMobileNumber
     *
     * @param registerPhoneNumberVM registerPhoneNumberVM
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendOtpAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resendOtpAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resendOtpAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resendOtpAccountByMobileNumberUsingPOST(registerPhoneNumberVM: RegisterPhoneNumberVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (registerPhoneNumberVM === null || registerPhoneNumberVM === undefined) {
            throw new Error('Required parameter registerPhoneNumberVM was null or undefined when calling resendOtpAccountByMobileNumberUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/register/phone-number/resend-otp`,
            registerPhoneNumberVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveAccount
     *
     * @param userDTO userDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveAccountUsingPOST(userDTO: UserDTO, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public saveAccountUsingPOST(userDTO: UserDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public saveAccountUsingPOST(userDTO: UserDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public saveAccountUsingPOST(userDTO: UserDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userDTO === null || userDTO === undefined) {
            throw new Error('Required parameter userDTO was null or undefined when calling saveAccountUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/api/account`,
            userDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateReferenceCode
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateReferenceCodeUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfPropertyDTO>;
    public validateReferenceCodeUsingGET(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfPropertyDTO>>;
    public validateReferenceCodeUsingGET(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfPropertyDTO>>;
    public validateReferenceCodeUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling validateReferenceCodeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfPropertyDTO>(`${this.basePath}/api/register/validate-reference-code/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
