import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SocialAuthEffects } from './+state/social-auth.effects';
import { SocialAuthFacade } from './+state/social-auth.facade';
import { NxModule } from '@nrwl/angular';
import {BASE_PATH, SocialCallbackControllerService} from "@gtd/b2c-client";
import {
  SOCIALAUTH_FEATURE_KEY,
  initialState as socialAuthInitialState,
  reducer
} from "./+state/social-auth.reducer";
import {environment} from "@env/environment";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(SOCIALAUTH_FEATURE_KEY, reducer, {
      initialState: socialAuthInitialState
    }),
    EffectsModule.forFeature([SocialAuthEffects])
  ],
  providers: [{provide: BASE_PATH, useValue: environment.api.b2cClient}, SocialAuthFacade, SocialCallbackControllerService]
})
export class SocialAuthModule {}
