import {
  ChangeHotelFilter,
  ChangeSort,
  FilterAvailableAction,
  FilterAvailableActionTypes
} from './filter-available.actions';

export const FILTERAVAILABLE_FEATURE_KEY = 'filterAvailable';

/**
 * Interface for the 'FilterAvailable' data used in
 *  - FilterAvailableState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FilterAvailableState {
  language: 'vi' | 'en';
  currency: 'VND' | 'USD';
  filterHotelName?: string;
  filterHotelCategories?: Array<string>;
  filterBreakfastIncluded?: boolean;
  filterPrice?: {
    fromPrice?: number;
    toPrice?: number;
  };
  filterDistance?: {
    fromDistance?: number;
    toDistance?: number;
  };
  filterStarRating?: Array<string>;
  filterGuestRating?: {
    from?: number;
    to?: number;
  };
  filterPropertyAmenities?: Array<string>;
  filterRoomAmenities?: Array<string>;
  filterRoomViews?: Array<string>;
  filterThemes?: Array<string>;
  filterMealPlans?: Array<string>;
  filterBedTypes?: Array<string>;
  filterRateAmenities?: Array<string>;
  sort?: {
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating';
    sortOrder?: 'ASC' | 'DESC';
  };
  pageNumber?: number;
  pageSize?: number;
}

export interface FilterAvailablePartialState {
  readonly [FILTERAVAILABLE_FEATURE_KEY]: FilterAvailableState;
}

export const initialState: FilterAvailableState = null;

export function filterAvailableReducer(
  state: FilterAvailableState = initialState,
  action: FilterAvailableAction
): FilterAvailableState {
  switch (action.type) {
    case FilterAvailableActionTypes.ResetHotelFilter: {
      state = { ...initialState };
      break;
    }
    case FilterAvailableActionTypes.ChangeHotelFilter: {
      state = action.payload;
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterHotelName: {
      state = {
        ...state,
        pageNumber: 0,
        filterHotelName: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterHotelCategories: {
      state = {
        ...state,
        pageNumber: 0,
        filterHotelCategories: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterBreakfastIncluded: {
      state = {
        ...state,
        pageNumber: 0,
        filterBreakfastIncluded: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterPrice: {
      state = {
        ...state,
        pageNumber: 0,
        filterPrice: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterDistance: {
      state = {
        ...state,
        pageNumber: 0,
        filterDistance: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterStarRating: {
      state = {
        ...state,
        pageNumber: 0,
        filterStarRating: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterGuestRating: {
      state = {
        ...state,
        pageNumber: 0,
        filterGuestRating: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterRoomAmenities: {
      state = {
        ...state,
        pageNumber: 0,
        filterRoomAmenities: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterPropertyAmenities: {
      state = {
        ...state,
        pageNumber: 0,
        filterPropertyAmenities: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterRateAmenities: {
      state = {
        ...state,
        pageNumber: 0,
        filterRateAmenities: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterRoomViews: {
      state = {
        ...state,
        pageNumber: 0,
        filterRoomViews: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterThemes: {
      state = {
        ...state,
        pageNumber: 0,
        filterThemes: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterMealPlans: {
      state = {
        ...state,
        pageNumber: 0,
        filterMealPlans: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterBedTypes: {
      state = {
        ...state,
        pageNumber: 0,
        filterBedTypes: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeSort: {
      state = {
        ...state,
        pageNumber: 0,
        sort: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ChangeFilterLanguage: {
      state = {
        ...state,
        pageNumber: 0,
        language: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ChangePageNumber: {
      state = {
        ...state,
        pageNumber: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ChangePageSize: {
      state = {
        ...state,
        pageSize: action.payload
      };
      break;
    }
  }
  return state;
}
