import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'gtd-filter-checkbox-label-text',
  templateUrl: './filter-checkbox-label-text.component.html',
  styleUrls: ['./filter-checkbox-label-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterCheckboxLabelTextComponent),
      multi: true
    }
  ]
})
export class FilterCheckboxLabelTextComponent
  implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() propertyFilters: Array<any>;
  @Input() label: string;
  filterForm: FormGroup;
  listValue: Array<string> = [];

  constructor() {
    this.filterForm = new FormGroup({
      filters: new FormArray([])
    });
  }

  ngOnInit() {
    const filters = (<FormArray>this.filterForm.get('filters')) as FormArray;
    this.propertyFilters.forEach(filter => {
      filters.push(new FormControl());
    });
    this.filterForm.controls['filters'].valueChanges.subscribe(valueChange => {
      this.listValue = [];
      valueChange.forEach((listValue, index) => {
        if (listValue) {
          this.listValue.push(this.propertyFilters[index].value);
        }
      });
      this.onChange(this.listValue);
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    if (obj && obj.length > 0) {
      this.filterForm.controls['filters'].setValue(obj);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngOnDestroy(): void {}
}
