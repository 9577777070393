import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AUTHENTICATE_FEATURE_KEY,
  AuthenticateState
} from './authenticate.reducer';

// Lookup the 'Authenticate' feature state managed by NgRx
const getAuthenticateState = createFeatureSelector<AuthenticateState>(
  AUTHENTICATE_FEATURE_KEY
);

const getLoaded = createSelector(
  getAuthenticateState,
  (state: AuthenticateState) => state.isAuthenticated
);


const getError = createSelector(
  getAuthenticateState,
  (state: AuthenticateState) => state.errorMessage
);

const getAllAuthenticate = createSelector(
  getAuthenticateState,
  getLoaded,
  (state: AuthenticateState, isLoaded) => {
    return isLoaded ? state.isAuthenticated : {};
  }
);


const selectUserState = createSelector(
  getAuthenticateState,
  (state: AuthenticateState) => state
);


const getSelectedAuthenticate = createSelector(
  getAllAuthenticate,
  selectUserState,
  (authenticate, id) => {
    return authenticate ? Object.assign({}, id) : undefined;
  }
);

export const authenticateQuery = {
  getLoaded,
  getError,
  getAllAuthenticate,
  selectUserState,
  getSelectedAuthenticate
};
