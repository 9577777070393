import {
  HistorySearchKeywordAction,
  HistorySearchKeywordActionTypes
} from './history-search-keyword.actions';
import {AirportDTO} from "@gtd/meta-client";
import {HotelMetaKeyword} from "@gtd/b2c-client";

export const HISTORYSEARCHKEYWORD_FEATURE_KEY = 'historySearchKeyword';

/**
 * Interface for the 'HistorySearchKeyword' data used in
 *  - HistorySearchKeywordState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */


export interface HistorySearchKeywordState {
  flightFrom?: AirportDTO[],
  flightTo?: AirportDTO[],
  selectedId?: string | number; // which HistorySearchKeyword record has been selected
  loaded: boolean; // has the HistorySearchKeyword list been loaded
  error?: any; // last none error (if any)
}

export interface HistorySearchKeywordPartialState {
  readonly [HISTORYSEARCHKEYWORD_FEATURE_KEY]: HistorySearchKeywordState;
}

export const initialState: HistorySearchKeywordState = {
  flightFrom: [],
  flightTo: [],
  loaded: false
};

export function historySearchKeywordReducer(
  state: HistorySearchKeywordState = initialState,
  action: HistorySearchKeywordAction
): HistorySearchKeywordState {
  switch (action.type) {
    case HistorySearchKeywordActionTypes.HistorySearchKeywordFlightFrom: {
      state = {
        ...state,
        flightFrom: action.payload,
        loaded: true
      };
      break;
    }
    case HistorySearchKeywordActionTypes.HistorySearchKeywordFlightTo: {
      state = {
        ...state,
        flightTo: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
