import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {PhoneRegisterStatePartialState} from './phone-register-state.reducer';
import {
  LoadPhoneRegisterState,
  PhoneRegisterStateActionTypes,
  PhoneRegisterStateLoadError,
  LoadPhoneOTPRegisterState,
  PhoneOTPRegisterStateLoadError
} from './phone-register-state.actions';
import {map} from "rxjs/operators";
import {AccountResourceService} from "@gtd/b2c-client";

@Injectable()
export class PhoneRegisterStateEffects {
  @Effect() loadPhoneRegisterState$ = this.dataPersistence.fetch(
    PhoneRegisterStateActionTypes.LoadPhoneRegisterState,
    {
      run: (action: LoadPhoneRegisterState, state: PhoneRegisterStatePartialState) => {
        return this.accountResourceService.registerAccountByMobileNumberUsingPOST(action.payload).pipe(
          map(update => ({
            type: PhoneRegisterStateActionTypes.PhoneRegisterStateLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadPhoneRegisterState, error) => {
        console.error('Error', error);
        return new PhoneRegisterStateLoadError(error);
      }
    }
  );

  @Effect() loadPhoneOTPRegisterState$ = this.dataPersistence.fetch(
    PhoneRegisterStateActionTypes.LoadPhoneOTPRegisterState,
    {
      run: (action: LoadPhoneOTPRegisterState, state: PhoneRegisterStatePartialState) => {
        return this.accountResourceService.activateAccountByMobileNumberUsingPOST(action.payload).pipe(
          map(update => ({
            type: PhoneRegisterStateActionTypes.PhoneOTPRegisterStateLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadPhoneOTPRegisterState, error) => {
        console.error('Error', error);
        return new PhoneOTPRegisterStateLoadError(error);
      }
    }
  );
  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<PhoneRegisterStatePartialState>,
    private accountResourceService: AccountResourceService
  ) {}
}
