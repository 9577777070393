import {Action} from '@ngrx/store';
import {ActivateAccountResult} from './activate-account.reducer';

export enum ActivateAccountActionTypes {
  LoadActivateAccount = '[ActivateAccount] Load ActivateAccount',
  ActivateAccountLoaded = '[ActivateAccount] ActivateAccount Loaded',
  ActivateAccountLoadError = '[ActivateAccount] ActivateAccount Load Error'
}

export class LoadActivateAccount implements Action {
  readonly type = ActivateAccountActionTypes.LoadActivateAccount;
  constructor(public payload: string) {}
}

export class ActivateAccountLoadError implements Action {
  readonly type = ActivateAccountActionTypes.ActivateAccountLoadError;
  constructor(public payload: any) {}
}

export class ActivateAccountLoaded implements Action {
  readonly type = ActivateAccountActionTypes.ActivateAccountLoaded;
  constructor(public payload: ActivateAccountResult) {}
}

export type ActivateAccountAction =
  | LoadActivateAccount
  | ActivateAccountLoaded
  | ActivateAccountLoadError;

export const fromActivateAccountActions = {
  LoadActivateAccount,
  ActivateAccountLoaded,
  ActivateAccountLoadError
};
