import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {FlightFilterPartialState, SelectDepartureItem} from './flight-filter.reducer';
import { flightFilterQuery } from './flight-filter.selectors';
import {
  ChangeAirlineOptions,
  ChangeArrivalDateTimeOptions,
  ChangeArrivalDateTimeReturnOptions,
  ChangeCabinClassOptions,
  ChangeDepartureDateTimeOptions,
  ChangeFlightPaginationPage,
  ChangeFlightPaginationSort,
  ChangeFlightType,
  ChangeSearchID,
  ChangeStep,
  ChangeStopOptions,
  ChangeTicketPolicyOptions,
  ResetFlightFilterRoundTrip,
  ResetFlightFilter, SelectDeparture
} from './flight-filter.actions';

@Injectable()
export class FlightFilterFacade {
  // loaded$ = this.store.pipe(select(flightFilterQuery.getLoaded));
  // allFlightFilter$ = this.store.pipe(
  //   select(flightFilterQuery.getAllFlightFilter)
  // );
  // selectedFlightFilter$ = this.store.pipe(
  //   select(flightFilterQuery.getSelectedFlightFilter)
  // );
  currentFilter$ = this.store.pipe(
    select(flightFilterQuery.getAllCurrentFilter)
  );

  selectedAirlineOptions$ = this.store.pipe(
    select(flightFilterQuery.getCurrentAirlineOptions)
  );

  selectedTicketPolicyOptions$ = this.store.pipe(
    select(flightFilterQuery.getCurrentTicketPolicyOptions)
  );

  selectedCabinClassOptions$ = this.store.pipe(
    select(flightFilterQuery.getCurrentCabinClassOptions)
  );

  selectedArrivalDateTimeOptions$ = this.store.pipe(
    select(flightFilterQuery.getCurrentArrivalDateTimeOptions)
  );

  selectedArrivalDateTimeReturnOptions$ = this.store.pipe(
    select(flightFilterQuery.getCurrentArrivalDateTimeReturnOptions)
  );

  currentSort$ = this.store.pipe(select(flightFilterQuery.getCurrentSort));

  searchID$ = this.store.pipe(select(flightFilterQuery.getSearchID));

  constructor(private store: Store<FlightFilterPartialState>) {}

  // loadAll() {
  //   this.store.dispatch(new LoadFlightFilter());
  // }

  resetFilter() {
    this.store.dispatch(new ResetFlightFilter());
  }

  resetFilterRoundTrip(searchID: string, flightType: string) {
    this.store.dispatch(new ResetFlightFilterRoundTrip({searchID: searchID, flightType: flightType}));
  }

  changeSearchID(searchID: string) {
    this.store.dispatch(new ChangeSearchID(searchID));
  }

  selectDeparture(selectDepartureItem: SelectDepartureItem) {
    this.store.dispatch(new SelectDeparture(selectDepartureItem));
  }

  changeAirlineOptions(selectedAirLine: string[]) {
    this.store.dispatch(new ChangeAirlineOptions(selectedAirLine));
  }

  changeArrivalDateTimeOptions(selectedArrivalDateTimeOptions: string[]) {
    this.store.dispatch(
      new ChangeArrivalDateTimeOptions(selectedArrivalDateTimeOptions)
    );
  }

  changeArrivalDateTimeReturnOptions(
    selectedArrivalDateTimeReturnOptions: string[]
  ) {
    this.store.dispatch(
      new ChangeArrivalDateTimeReturnOptions(
        selectedArrivalDateTimeReturnOptions
      )
    );
  }

  changeCabinClassOptions(selectedCabinClassOptions: string[]) {
    this.store.dispatch(new ChangeCabinClassOptions(selectedCabinClassOptions));
  }

  changeDepartureDateTimeOptions(selectedDepartureDateTimeOptions: string[]) {
    this.store.dispatch(
      new ChangeDepartureDateTimeOptions(selectedDepartureDateTimeOptions)
    );
  }

  changeFlightType(selectedFlightType: string) {
    this.store.dispatch(new ChangeFlightType(selectedFlightType));
  }

  changeStep(selectedStep: string) {
    this.store.dispatch(new ChangeStep(selectedStep));
  }

  changeStopOptions(selectedStopOptions: string[]) {
    this.store.dispatch(new ChangeStopOptions(selectedStopOptions));
  }

  changeTicketPolicyOptions(selectedTicketPolicyOptions: string[]) {
    this.store.dispatch(
      new ChangeTicketPolicyOptions(selectedTicketPolicyOptions)
    );
  }

  changeSort(sort: string[]) {
    this.store.dispatch(new ChangeFlightPaginationSort(sort));
  }

  changePage(page: number) {
    this.store.dispatch(new ChangeFlightPaginationPage(page));
  }
}
