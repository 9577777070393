import { Action } from '@ngrx/store';
import { ComboDraftBookingVM, OtaResultOfBooking } from '@gtd/b2c-client';

export enum ComboCreateDraftBookingActionTypes {
  ComboVJLoadComboCreateDraftBooking = '[ComboVJ_ComboCreateDraftBooking] Load ComboCreateDraftBooking',
  ComboVJResetComboCreateDraftBooking = '[ComboVJ_ComboCreateDraftBooking] Reset ComboCreateDraftBooking',
  ComboVJComboCreateDraftBookingLoaded = '[ComboVJ_ComboCreateDraftBooking] ComboCreateDraftBooking Loaded',
  ComboVJComboCreateDraftBookingLoadError = '[ComboVJ_ComboCreateDraftBooking] ComboCreateDraftBooking Load Error'
}

export class ComboVJLoadComboCreateDraftBooking implements Action {
  readonly type =
    ComboCreateDraftBookingActionTypes.ComboVJLoadComboCreateDraftBooking;
  constructor(public payload: any) {}
}
export class ComboVJResetComboCreateDraftBooking implements Action {
  readonly type =
    ComboCreateDraftBookingActionTypes.ComboVJResetComboCreateDraftBooking;
  constructor() {}
}

export class ComboVJComboCreateDraftBookingLoadError implements Action {
  readonly type =
    ComboCreateDraftBookingActionTypes.ComboVJComboCreateDraftBookingLoadError;
  constructor(public payload: any) {}
}

export class ComboVJComboCreateDraftBookingLoaded implements Action {
  readonly type =
    ComboCreateDraftBookingActionTypes.ComboVJComboCreateDraftBookingLoaded;
  constructor(public payload: any) {}
}

export type ComboCreateDraftBookingAction =
  | ComboVJLoadComboCreateDraftBooking
  | ComboVJResetComboCreateDraftBooking
  | ComboVJComboCreateDraftBookingLoaded
  | ComboVJComboCreateDraftBookingLoadError;

export const fromComboCreateDraftBookingActions = {
  ComboVJLoadComboCreateDraftBooking,
  ComboVJResetComboCreateDraftBooking,
  ComboVJComboCreateDraftBookingLoaded,
  ComboVJComboCreateDraftBookingLoadError
};
