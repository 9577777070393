/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CreditCardDTO { 
    agencyCode?: string;
    agentCode?: string;
    agentId?: number;
    billToCity?: string;
    billToCountry?: string;
    billToEmail?: string;
    billToFirstName?: string;
    billToLastName?: string;
    billToPhoneNumber?: string;
    billToPostalCode?: string;
    billToState?: string;
    billToStreet1?: string;
    billToStreet2?: string;
    cardNumber?: string;
    createdBy?: string;
    createdDate?: Date;
    customerCode?: string;
    customerId?: number;
    cvvNumber?: string;
    expireMonth?: number;
    expireYear?: number;
    fullName?: string;
    id?: number;
    notes?: string;
    orgCode?: string;
    ownerType?: CreditCardDTO.OwnerTypeEnum;
    paymentAuthorizationCode?: string;
    paymentAuthorizedDateTime?: string;
    paymentNetworkTransactionId?: string;
    paymentReceiptNumber?: string;
    paymentReconciliationId?: string;
    paymentReferenceCode?: string;
    paymentRequestId?: string;
    paymentRequestToken?: string;
    paymentSubscriptionId?: string;
    paymentTransReplyId?: number;
    type?: CreditCardDTO.TypeEnum;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace CreditCardDTO {
    export type OwnerTypeEnum = 'CUSTOMER' | 'AGENT' | 'AGENCY';
    export const OwnerTypeEnum = {
        CUSTOMER: 'CUSTOMER' as OwnerTypeEnum,
        AGENT: 'AGENT' as OwnerTypeEnum,
        AGENCY: 'AGENCY' as OwnerTypeEnum
    };
    export type TypeEnum = 'VISA' | 'MASTER' | 'JCB' | 'ATM_DEBIT' | 'OTHER';
    export const TypeEnum = {
        VISA: 'VISA' as TypeEnum,
        MASTER: 'MASTER' as TypeEnum,
        JCB: 'JCB' as TypeEnum,
        ATMDEBIT: 'ATM_DEBIT' as TypeEnum,
        OTHER: 'OTHER' as TypeEnum
    };
}
