/**
 * utilitysrv API
 * utilitysrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ApprovalInvoiceDTO } from '../model/approvalInvoiceDTO';
import { ApprovalInvoiceReq } from '../model/approvalInvoiceReq';
import { BusinessDTO } from '../model/businessDTO';
import { EInvoiceBookingRes } from '../model/eInvoiceBookingRes';
import { OtaResult } from '../model/otaResult';
import { OtaResultOfApprovalInvoiceDTO } from '../model/otaResultOfApprovalInvoiceDTO';
import { OtaResultOfApprovalSumMonthRes } from '../model/otaResultOfApprovalSumMonthRes';
import { OtaResultOfListOfApprovalInvoiceDTO } from '../model/otaResultOfListOfApprovalInvoiceDTO';
import { PageOfApprovalInvoiceDTO } from '../model/pageOfApprovalInvoiceDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ApprovalInvoiceResourceService {

    protected basePath = 'https://10.7.71.20:8588/utilitysrv';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * confirmIssueApprovalInvoice
     *
     * @param approvalId approvalId
     * @param isAccept isAccept
     * @param userName userName
     * @param message message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmIssueApprovalInvoiceUsingPOST(approvalId: number, isAccept: boolean, userName: string, message?: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfApprovalInvoiceDTO>;
    public confirmIssueApprovalInvoiceUsingPOST(approvalId: number, isAccept: boolean, userName: string, message?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfApprovalInvoiceDTO>>;
    public confirmIssueApprovalInvoiceUsingPOST(approvalId: number, isAccept: boolean, userName: string, message?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfApprovalInvoiceDTO>>;
    public confirmIssueApprovalInvoiceUsingPOST(approvalId: number, isAccept: boolean, userName: string, message?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling confirmIssueApprovalInvoiceUsingPOST.');
        }

        if (isAccept === null || isAccept === undefined) {
            throw new Error('Required parameter isAccept was null or undefined when calling confirmIssueApprovalInvoiceUsingPOST.');
        }

        if (userName === null || userName === undefined) {
            throw new Error('Required parameter userName was null or undefined when calling confirmIssueApprovalInvoiceUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalId !== undefined && approvalId !== null) {
            queryParameters = queryParameters.set('approvalId', <any>approvalId);
        }
        if (isAccept !== undefined && isAccept !== null) {
            queryParameters = queryParameters.set('isAccept', <any>isAccept);
        }
        if (message !== undefined && message !== null) {
            queryParameters = queryParameters.set('message', <any>message);
        }
        if (userName !== undefined && userName !== null) {
            queryParameters = queryParameters.set('userName', <any>userName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OtaResultOfApprovalInvoiceDTO>(`${this.basePath}/api/approval-invoices/approved-invoice`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * confirmIssueApprovalInvoices
     *
     * @param approvalIds approvalIds
     * @param isAccept isAccept
     * @param userName userName
     * @param message message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmIssueApprovalInvoicesUsingPOST(approvalIds: Array<number>, isAccept: boolean, userName: string, message?: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfApprovalInvoiceDTO>;
    public confirmIssueApprovalInvoicesUsingPOST(approvalIds: Array<number>, isAccept: boolean, userName: string, message?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfApprovalInvoiceDTO>>;
    public confirmIssueApprovalInvoicesUsingPOST(approvalIds: Array<number>, isAccept: boolean, userName: string, message?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfApprovalInvoiceDTO>>;
    public confirmIssueApprovalInvoicesUsingPOST(approvalIds: Array<number>, isAccept: boolean, userName: string, message?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalIds === null || approvalIds === undefined) {
            throw new Error('Required parameter approvalIds was null or undefined when calling confirmIssueApprovalInvoicesUsingPOST.');
        }

        if (isAccept === null || isAccept === undefined) {
            throw new Error('Required parameter isAccept was null or undefined when calling confirmIssueApprovalInvoicesUsingPOST.');
        }

        if (userName === null || userName === undefined) {
            throw new Error('Required parameter userName was null or undefined when calling confirmIssueApprovalInvoicesUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalIds) {
            approvalIds.forEach((element) => {
                queryParameters = queryParameters.append('approvalIds', <any>element);
            })
        }
        if (isAccept !== undefined && isAccept !== null) {
            queryParameters = queryParameters.set('isAccept', <any>isAccept);
        }
        if (message !== undefined && message !== null) {
            queryParameters = queryParameters.set('message', <any>message);
        }
        if (userName !== undefined && userName !== null) {
            queryParameters = queryParameters.set('userName', <any>userName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OtaResultOfListOfApprovalInvoiceDTO>(`${this.basePath}/api/approval-invoices/approved-invoices`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createApprovalInvoice
     *
     * @param approvalInvoiceDTO approvalInvoiceDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createApprovalInvoiceUsingPOST(approvalInvoiceDTO: ApprovalInvoiceDTO, observe?: 'body', reportProgress?: boolean): Observable<ApprovalInvoiceDTO>;
    public createApprovalInvoiceUsingPOST(approvalInvoiceDTO: ApprovalInvoiceDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalInvoiceDTO>>;
    public createApprovalInvoiceUsingPOST(approvalInvoiceDTO: ApprovalInvoiceDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalInvoiceDTO>>;
    public createApprovalInvoiceUsingPOST(approvalInvoiceDTO: ApprovalInvoiceDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalInvoiceDTO === null || approvalInvoiceDTO === undefined) {
            throw new Error('Required parameter approvalInvoiceDTO was null or undefined when calling createApprovalInvoiceUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApprovalInvoiceDTO>(`${this.basePath}/api/approval-invoices`,
            approvalInvoiceDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createEmptyApprovalInvoice
     *
     * @param approvalInvoiceReq approvalInvoiceReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createEmptyApprovalInvoiceUsingPOST(approvalInvoiceReq: ApprovalInvoiceReq, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfApprovalInvoiceDTO>;
    public createEmptyApprovalInvoiceUsingPOST(approvalInvoiceReq: ApprovalInvoiceReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfApprovalInvoiceDTO>>;
    public createEmptyApprovalInvoiceUsingPOST(approvalInvoiceReq: ApprovalInvoiceReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfApprovalInvoiceDTO>>;
    public createEmptyApprovalInvoiceUsingPOST(approvalInvoiceReq: ApprovalInvoiceReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalInvoiceReq === null || approvalInvoiceReq === undefined) {
            throw new Error('Required parameter approvalInvoiceReq was null or undefined when calling createEmptyApprovalInvoiceUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfApprovalInvoiceDTO>(`${this.basePath}/api/approval-invoices/pre-empty-invoices`,
            approvalInvoiceReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createPreApprovalInvoice
     *
     * @param approvalInvoiceReq approvalInvoiceReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPreApprovalInvoiceUsingPOST(approvalInvoiceReq: ApprovalInvoiceReq, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfApprovalInvoiceDTO>;
    public createPreApprovalInvoiceUsingPOST(approvalInvoiceReq: ApprovalInvoiceReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfApprovalInvoiceDTO>>;
    public createPreApprovalInvoiceUsingPOST(approvalInvoiceReq: ApprovalInvoiceReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfApprovalInvoiceDTO>>;
    public createPreApprovalInvoiceUsingPOST(approvalInvoiceReq: ApprovalInvoiceReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalInvoiceReq === null || approvalInvoiceReq === undefined) {
            throw new Error('Required parameter approvalInvoiceReq was null or undefined when calling createPreApprovalInvoiceUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfApprovalInvoiceDTO>(`${this.basePath}/api/approval-invoices/pre-invoices`,
            approvalInvoiceReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteApprovalInvoice
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteApprovalInvoiceUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteApprovalInvoiceUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteApprovalInvoiceUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteApprovalInvoiceUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteApprovalInvoiceUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/approval-invoices/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findApprovalInvoiceWithPaginationByUserRefCode
     *
     * @param approvedStatuses approvedStatuses
     * @param bookingNumber bookingNumber
     * @param businessName businessName
     * @param email email
     * @param fromDate 6/6/2021
     * @param fullName fullName
     * @param invoiceNo invoiceNo
     * @param isAdminIssue isAdminIssue
     * @param monthFromDate 6/2021
     * @param page Page number of the requested page
     * @param phoneNumber phoneNumber
     * @param pnr pnr
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param taxCode taxCode
     * @param toDate 6/6/2021
     * @param userRefCode userRefCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findApprovalInvoiceWithPaginationByUserRefCodeUsingGET(approvedStatuses?: Array<'NONE' | 'SUCCESS' | 'PENDING' | 'FAILED' | 'CREATED' | 'SIGNFAILED' | 'SIGNING' | 'CANCELLED' | 'WAITING'>, bookingNumber?: string, businessName?: string, email?: string, fromDate?: string, fullName?: string, invoiceNo?: string, isAdminIssue?: boolean, monthFromDate?: string, page?: number, phoneNumber?: string, pnr?: string, size?: number, sort?: Array<string>, taxCode?: string, toDate?: string, userRefCode?: string, observe?: 'body', reportProgress?: boolean): Observable<PageOfApprovalInvoiceDTO>;
    public findApprovalInvoiceWithPaginationByUserRefCodeUsingGET(approvedStatuses?: Array<'NONE' | 'SUCCESS' | 'PENDING' | 'FAILED' | 'CREATED' | 'SIGNFAILED' | 'SIGNING' | 'CANCELLED' | 'WAITING'>, bookingNumber?: string, businessName?: string, email?: string, fromDate?: string, fullName?: string, invoiceNo?: string, isAdminIssue?: boolean, monthFromDate?: string, page?: number, phoneNumber?: string, pnr?: string, size?: number, sort?: Array<string>, taxCode?: string, toDate?: string, userRefCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfApprovalInvoiceDTO>>;
    public findApprovalInvoiceWithPaginationByUserRefCodeUsingGET(approvedStatuses?: Array<'NONE' | 'SUCCESS' | 'PENDING' | 'FAILED' | 'CREATED' | 'SIGNFAILED' | 'SIGNING' | 'CANCELLED' | 'WAITING'>, bookingNumber?: string, businessName?: string, email?: string, fromDate?: string, fullName?: string, invoiceNo?: string, isAdminIssue?: boolean, monthFromDate?: string, page?: number, phoneNumber?: string, pnr?: string, size?: number, sort?: Array<string>, taxCode?: string, toDate?: string, userRefCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfApprovalInvoiceDTO>>;
    public findApprovalInvoiceWithPaginationByUserRefCodeUsingGET(approvedStatuses?: Array<'NONE' | 'SUCCESS' | 'PENDING' | 'FAILED' | 'CREATED' | 'SIGNFAILED' | 'SIGNING' | 'CANCELLED' | 'WAITING'>, bookingNumber?: string, businessName?: string, email?: string, fromDate?: string, fullName?: string, invoiceNo?: string, isAdminIssue?: boolean, monthFromDate?: string, page?: number, phoneNumber?: string, pnr?: string, size?: number, sort?: Array<string>, taxCode?: string, toDate?: string, userRefCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvedStatuses) {
            approvedStatuses.forEach((element) => {
                queryParameters = queryParameters.append('approvedStatuses', <any>element);
            })
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (businessName !== undefined && businessName !== null) {
            queryParameters = queryParameters.set('businessName', <any>businessName);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate);
        }
        if (fullName !== undefined && fullName !== null) {
            queryParameters = queryParameters.set('fullName', <any>fullName);
        }
        if (invoiceNo !== undefined && invoiceNo !== null) {
            queryParameters = queryParameters.set('invoiceNo', <any>invoiceNo);
        }
        if (isAdminIssue !== undefined && isAdminIssue !== null) {
            queryParameters = queryParameters.set('isAdminIssue', <any>isAdminIssue);
        }
        if (monthFromDate !== undefined && monthFromDate !== null) {
            queryParameters = queryParameters.set('monthFromDate', <any>monthFromDate);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }
        if (pnr !== undefined && pnr !== null) {
            queryParameters = queryParameters.set('pnr', <any>pnr);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }
        if (taxCode !== undefined && taxCode !== null) {
            queryParameters = queryParameters.set('taxCode', <any>taxCode);
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate);
        }
        if (userRefCode !== undefined && userRefCode !== null) {
            queryParameters = queryParameters.set('userRefCode', <any>userRefCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageOfApprovalInvoiceDTO>(`${this.basePath}/api/approval-invoices/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllApprovalInvoices
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllApprovalInvoicesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<ApprovalInvoiceDTO>>;
    public getAllApprovalInvoicesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApprovalInvoiceDTO>>>;
    public getAllApprovalInvoicesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApprovalInvoiceDTO>>>;
    public getAllApprovalInvoicesUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ApprovalInvoiceDTO>>(`${this.basePath}/api/approval-invoices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getApprovalInvoiceByBookingNumber
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getApprovalInvoiceByBookingNumberUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getApprovalInvoiceByBookingNumberUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getApprovalInvoiceByBookingNumberUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getApprovalInvoiceByBookingNumberUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getApprovalInvoiceByBookingNumberUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/approval-invoices/booking-number`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getApprovalInvoice
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getApprovalInvoiceUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<ApprovalInvoiceDTO>;
    public getApprovalInvoiceUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalInvoiceDTO>>;
    public getApprovalInvoiceUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalInvoiceDTO>>;
    public getApprovalInvoiceUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getApprovalInvoiceUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApprovalInvoiceDTO>(`${this.basePath}/api/approval-invoices/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAutoSignStatus
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAutoSignStatusUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getAutoSignStatusUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getAutoSignStatusUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getAutoSignStatusUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/approval-invoices/auto-sign-status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBusinessByUserRefCodeAndStatus
     *
     * @param userRefCode userRefCode
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBusinessByUserRefCodeAndStatusUsingGET(userRefCode: string, status?: 'NONE' | 'SUCCESS' | 'PENDING' | 'FAILED' | 'CREATED' | 'SIGNFAILED' | 'SIGNING' | 'CANCELLED' | 'WAITING', observe?: 'body', reportProgress?: boolean): Observable<Array<BusinessDTO>>;
    public getBusinessByUserRefCodeAndStatusUsingGET(userRefCode: string, status?: 'NONE' | 'SUCCESS' | 'PENDING' | 'FAILED' | 'CREATED' | 'SIGNFAILED' | 'SIGNING' | 'CANCELLED' | 'WAITING', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BusinessDTO>>>;
    public getBusinessByUserRefCodeAndStatusUsingGET(userRefCode: string, status?: 'NONE' | 'SUCCESS' | 'PENDING' | 'FAILED' | 'CREATED' | 'SIGNFAILED' | 'SIGNING' | 'CANCELLED' | 'WAITING', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BusinessDTO>>>;
    public getBusinessByUserRefCodeAndStatusUsingGET(userRefCode: string, status?: 'NONE' | 'SUCCESS' | 'PENDING' | 'FAILED' | 'CREATED' | 'SIGNFAILED' | 'SIGNING' | 'CANCELLED' | 'WAITING', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling getBusinessByUserRefCodeAndStatusUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (userRefCode !== undefined && userRefCode !== null) {
            queryParameters = queryParameters.set('userRefCode', <any>userRefCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<BusinessDTO>>(`${this.basePath}/api/approval-invoices/saved-business`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getEinvoiceBookingInfo
     *
     * @param approvalId approvalId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEinvoiceBookingInfoUsingGET(approvalId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<EInvoiceBookingRes>>;
    public getEinvoiceBookingInfoUsingGET(approvalId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EInvoiceBookingRes>>>;
    public getEinvoiceBookingInfoUsingGET(approvalId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EInvoiceBookingRes>>>;
    public getEinvoiceBookingInfoUsingGET(approvalId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling getEinvoiceBookingInfoUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<EInvoiceBookingRes>>(`${this.basePath}/api/approval-invoices/einvoice-booking-info/${encodeURIComponent(String(approvalId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getListBookingNumbersPendingByUserRefCode
     *
     * @param userRefCode userRefCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListBookingNumbersPendingByUserRefCodeUsingGET(userRefCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getListBookingNumbersPendingByUserRefCodeUsingGET(userRefCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getListBookingNumbersPendingByUserRefCodeUsingGET(userRefCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getListBookingNumbersPendingByUserRefCodeUsingGET(userRefCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling getListBookingNumbersPendingByUserRefCodeUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userRefCode !== undefined && userRefCode !== null) {
            queryParameters = queryParameters.set('userRefCode', <any>userRefCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/approval-invoices/booking-numbers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getNewGTInvoiceByApprovalId
     *
     * @param approvalId approvalId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNewGTInvoiceByApprovalIdUsingGET(approvalId: number, observe?: 'body', reportProgress?: boolean): Observable<OtaResult>;
    public getNewGTInvoiceByApprovalIdUsingGET(approvalId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResult>>;
    public getNewGTInvoiceByApprovalIdUsingGET(approvalId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResult>>;
    public getNewGTInvoiceByApprovalIdUsingGET(approvalId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling getNewGTInvoiceByApprovalIdUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalId !== undefined && approvalId !== null) {
            queryParameters = queryParameters.set('approvalId', <any>approvalId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OtaResult>(`${this.basePath}/api/approval-invoices/refesh-gtinvoice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPdfUrlEinvoice
     *
     * @param approvalId approvalId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfUrlEinvoiceUsingGET(approvalId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPdfUrlEinvoiceUsingGET(approvalId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPdfUrlEinvoiceUsingGET(approvalId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPdfUrlEinvoiceUsingGET(approvalId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling getPdfUrlEinvoiceUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/approval-invoices/einvoice-pdf-url/${encodeURIComponent(String(approvalId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * issueInvoicesGroupByUser
     *
     * @param bookingNumbers bookingNumbers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public issueInvoicesGroupByUserUsingGET(bookingNumbers: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaResult>;
    public issueInvoicesGroupByUserUsingGET(bookingNumbers: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResult>>;
    public issueInvoicesGroupByUserUsingGET(bookingNumbers: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResult>>;
    public issueInvoicesGroupByUserUsingGET(bookingNumbers: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumbers === null || bookingNumbers === undefined) {
            throw new Error('Required parameter bookingNumbers was null or undefined when calling issueInvoicesGroupByUserUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumbers) {
            bookingNumbers.forEach((element) => {
                queryParameters = queryParameters.append('bookingNumbers', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OtaResult>(`${this.basePath}/api/approval-invoices/issue-invoice-bookings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * reConfirmApprovalInvoice
     *
     * @param approvalId approvalId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reConfirmApprovalInvoiceUsingGET(approvalId: number, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfApprovalInvoiceDTO>;
    public reConfirmApprovalInvoiceUsingGET(approvalId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfApprovalInvoiceDTO>>;
    public reConfirmApprovalInvoiceUsingGET(approvalId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfApprovalInvoiceDTO>>;
    public reConfirmApprovalInvoiceUsingGET(approvalId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling reConfirmApprovalInvoiceUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OtaResultOfApprovalInvoiceDTO>(`${this.basePath}/api/approval-invoices/reconfirm-invoice/${encodeURIComponent(String(approvalId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * removeApprovalInvoicesCreated
     *
     * @param approvalIds approvalIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeApprovalInvoicesCreatedUsingDELETE(approvalIds?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<ApprovalInvoiceDTO>>;
    public removeApprovalInvoicesCreatedUsingDELETE(approvalIds?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApprovalInvoiceDTO>>>;
    public removeApprovalInvoicesCreatedUsingDELETE(approvalIds?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApprovalInvoiceDTO>>>;
    public removeApprovalInvoicesCreatedUsingDELETE(approvalIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalIds) {
            approvalIds.forEach((element) => {
                queryParameters = queryParameters.append('approvalIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Array<ApprovalInvoiceDTO>>(`${this.basePath}/api/approval-invoices/remove-invoices-created`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * resolvedInvoicesGroupByUser
     *
     * @param approvalId approvalId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resolvedInvoicesGroupByUserUsingGET(approvalId: number, observe?: 'body', reportProgress?: boolean): Observable<OtaResult>;
    public resolvedInvoicesGroupByUserUsingGET(approvalId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResult>>;
    public resolvedInvoicesGroupByUserUsingGET(approvalId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResult>>;
    public resolvedInvoicesGroupByUserUsingGET(approvalId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling resolvedInvoicesGroupByUserUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalId !== undefined && approvalId !== null) {
            queryParameters = queryParameters.set('approvalId', <any>approvalId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OtaResult>(`${this.basePath}/api/approval-invoices/resolved-invoice-by-id`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * scheduleCancelNotSignInvoice
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleCancelNotSignInvoiceUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<ApprovalInvoiceDTO>>;
    public scheduleCancelNotSignInvoiceUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApprovalInvoiceDTO>>>;
    public scheduleCancelNotSignInvoiceUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApprovalInvoiceDTO>>>;
    public scheduleCancelNotSignInvoiceUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ApprovalInvoiceDTO>>(`${this.basePath}/api/approval-invoices/schedule-cancel-created-invoices`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * scheduleRecheckSingingInvoices
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleRecheckSingingInvoicesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public scheduleRecheckSingingInvoicesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public scheduleRecheckSingingInvoicesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public scheduleRecheckSingingInvoicesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/approval-invoices/schedule-recheck-signing-invoices`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * signApprovalInvoice
     *
     * @param approvalIds approvalIds
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signApprovalInvoiceUsingGET(approvalIds: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<ApprovalInvoiceDTO>>;
    public signApprovalInvoiceUsingGET(approvalIds: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApprovalInvoiceDTO>>>;
    public signApprovalInvoiceUsingGET(approvalIds: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApprovalInvoiceDTO>>>;
    public signApprovalInvoiceUsingGET(approvalIds: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalIds === null || approvalIds === undefined) {
            throw new Error('Required parameter approvalIds was null or undefined when calling signApprovalInvoiceUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalIds) {
            approvalIds.forEach((element) => {
                queryParameters = queryParameters.append('approvalIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ApprovalInvoiceDTO>>(`${this.basePath}/api/approval-invoices/sign-invoices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sumaryApprovalByMonth
     *
     * @param userRefCode userRefCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sumaryApprovalByMonthUsingGET(userRefCode: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfApprovalSumMonthRes>;
    public sumaryApprovalByMonthUsingGET(userRefCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfApprovalSumMonthRes>>;
    public sumaryApprovalByMonthUsingGET(userRefCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfApprovalSumMonthRes>>;
    public sumaryApprovalByMonthUsingGET(userRefCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling sumaryApprovalByMonthUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userRefCode !== undefined && userRefCode !== null) {
            queryParameters = queryParameters.set('userRefCode', <any>userRefCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OtaResultOfApprovalSumMonthRes>(`${this.basePath}/api/approval-invoices/sumary-by-month`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateApprovalInvoice
     *
     * @param approvalInvoiceDTO approvalInvoiceDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateApprovalInvoiceUsingPUT(approvalInvoiceDTO: ApprovalInvoiceDTO, observe?: 'body', reportProgress?: boolean): Observable<ApprovalInvoiceDTO>;
    public updateApprovalInvoiceUsingPUT(approvalInvoiceDTO: ApprovalInvoiceDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalInvoiceDTO>>;
    public updateApprovalInvoiceUsingPUT(approvalInvoiceDTO: ApprovalInvoiceDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalInvoiceDTO>>;
    public updateApprovalInvoiceUsingPUT(approvalInvoiceDTO: ApprovalInvoiceDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalInvoiceDTO === null || approvalInvoiceDTO === undefined) {
            throw new Error('Required parameter approvalInvoiceDTO was null or undefined when calling updateApprovalInvoiceUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApprovalInvoiceDTO>(`${this.basePath}/api/approval-invoices`,
            approvalInvoiceDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBookingsWithApprovalId
     *
     * @param bookingNumbers bookingNumbers
     * @param approvalInvoiceId approvalInvoiceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingsWithApprovalIdUsingPOST(bookingNumbers: Array<string>, approvalInvoiceId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateBookingsWithApprovalIdUsingPOST(bookingNumbers: Array<string>, approvalInvoiceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateBookingsWithApprovalIdUsingPOST(bookingNumbers: Array<string>, approvalInvoiceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateBookingsWithApprovalIdUsingPOST(bookingNumbers: Array<string>, approvalInvoiceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumbers === null || bookingNumbers === undefined) {
            throw new Error('Required parameter bookingNumbers was null or undefined when calling updateBookingsWithApprovalIdUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalInvoiceId !== undefined && approvalInvoiceId !== null) {
            queryParameters = queryParameters.set('approvalInvoiceId', <any>approvalInvoiceId);
        }
        if (bookingNumbers) {
            bookingNumbers.forEach((element) => {
                queryParameters = queryParameters.append('bookingNumbers', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/approval-invoices/bookings-update-by-approval-invoice-id`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateGTInvoiceByApprovalId
     *
     * @param approvalId approvalId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGTInvoiceByApprovalIdUsingGET(approvalId: number, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfApprovalInvoiceDTO>;
    public updateGTInvoiceByApprovalIdUsingGET(approvalId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfApprovalInvoiceDTO>>;
    public updateGTInvoiceByApprovalIdUsingGET(approvalId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfApprovalInvoiceDTO>>;
    public updateGTInvoiceByApprovalIdUsingGET(approvalId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approvalId === null || approvalId === undefined) {
            throw new Error('Required parameter approvalId was null or undefined when calling updateGTInvoiceByApprovalIdUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalId !== undefined && approvalId !== null) {
            queryParameters = queryParameters.set('approvalId', <any>approvalId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OtaResultOfApprovalInvoiceDTO>(`${this.basePath}/api/approval-invoices/update-invoice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
