/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AirportInformation } from './airportInformation';
import { OperatingAirline } from './operatingAirline';
import { SeatsRemaining } from './seatsRemaining';
import { StopQuantityInfo } from './stopQuantityInfo';


export interface FlightSegment { 
    adultBaggage?: string;
    aircraft?: string;
    arrivalAirport?: AirportInformation;
    arrivalAirportLocationCode?: string;
    arrivalAirportLocationName?: string;
    arrivalCity?: string;
    arrivalDateTime?: Date;
    cabinClassCode?: string;
    cabinClassName?: FlightSegment.CabinClassNameEnum;
    cabinClassText?: string;
    childBaggage?: string;
    departureAirport?: AirportInformation;
    departureAirportLocationCode?: string;
    departureAirportLocationName?: string;
    departureCity?: string;
    departureDateTime?: Date;
    eticket?: boolean;
    fareBasicCode?: string;
    fareCode?: string;
    flightDirection?: string;
    flightNumber?: string;
    infantBaggage?: string;
    journeyDuration?: number;
    marketingAirlineCode?: string;
    marriageGroup?: string;
    mealCode?: string;
    operatingAirline?: OperatingAirline;
    resBookDesignCode?: string;
    seatsRemaining?: SeatsRemaining;
    stopQuantity?: number;
    stopQuantityInfo?: StopQuantityInfo;
    supplierFareKey?: string;
    supplierJourneyKey?: string;
}
export namespace FlightSegment {
    export type CabinClassNameEnum = 'PROMO' | 'ECONOMY' | 'PREMIUM' | 'BUSINESS' | 'FIRST';
    export const CabinClassNameEnum = {
        PROMO: 'PROMO' as CabinClassNameEnum,
        ECONOMY: 'ECONOMY' as CabinClassNameEnum,
        PREMIUM: 'PREMIUM' as CabinClassNameEnum,
        BUSINESS: 'BUSINESS' as CabinClassNameEnum,
        FIRST: 'FIRST' as CabinClassNameEnum
    };
}
