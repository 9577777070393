import {
  RequestPasswordStateAction,
  RequestPasswordStateActionTypes
} from './request-password-state.actions';

export const REQUESTPASSWORDSTATE_FEATURE_KEY = 'requestPasswordState';

/**
 * Interface for the 'RequestPasswordState' data used in
 *  - RequestPasswordStateState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface RequestPasswordStateState {
  result: any; // list of RequestPasswordState; analogous to a sql normalized table
  selectedId?: string | number; // which RequestPasswordState record has been selected
  loaded: boolean; // has the RequestPasswordState list been loaded
  error?: any; // last none error (if any)
  errorCode?: number;
}

export interface RequestPasswordStatePartialState {
  readonly [REQUESTPASSWORDSTATE_FEATURE_KEY]: RequestPasswordStateState;
}

export const initialState: RequestPasswordStateState = {
  result: null,
  loaded: false,
  errorCode : null
};

export function reducer(
  state: RequestPasswordStateState = initialState,
  action: RequestPasswordStateAction
): RequestPasswordStateState {
  switch (action.type) {
    case RequestPasswordStateActionTypes.RequestPasswordStateLoaded: {
      state = {
        ...state,
        result: action.payload,
        error: '',
        errorCode: 200,
        loaded: true
      };
      break;
    }
    case RequestPasswordStateActionTypes.RequestPasswordStateLoadError: {
      state = {
        ...state,
        result: action.payload,
        error: action.payload.error? action.payload.error: 'Error call API',
        loaded: true,
        errorCode : action.payload.status ? action.payload.status : 404
      };
      break;
    }
    case RequestPasswordStateActionTypes.PhoneRequestPasswordStateLoaded: {
      state = {
        ...state,
        result: action.payload,
        error: '',
        errorCode: 200,
        loaded: true
      };
      break;
    }
    case RequestPasswordStateActionTypes.PhoneRequestPasswordStateLoadError: {
      state = {
        ...state,
        result: action.payload,
        error: action.payload.error? action.payload.error: 'Error call API',
        loaded: true,
        errorCode : action.payload.status ? action.payload.status : 404
      };
      break;
    }
    case RequestPasswordStateActionTypes.LoadDestroyPasswordState: {
      state = initialState;
      break;
    }
  }
  return state;
}
