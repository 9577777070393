import { Component, Input, OnInit } from '@angular/core';
import {
  HotelSortAttributeEnum,
  slideInVertical,
  HotelSortTypeEnum
} from '@gtd/helpers';
import { FilterOptionsFacade } from '../../../../../state/filter-options/filter-options.facade';
import { FilterAvailableFacade } from '../../../../../state/filter-available/filter-available.facade';

@Component({
  selector: 'gtd-sort-panel',
  templateUrl: './sort-panel.component.html',
  styleUrls: ['./sort-panel.component.scss'],
  animations: [slideInVertical]
})
export class SortPanelComponent implements OnInit {
  @Input() searchParams: any;

  loadedFilter$ = this.filterOptionsFacade.loaded$;

  sortList: SortInterface[] = [
    {
      attribute: HotelSortAttributeEnum.ORDER,
      type: HotelSortTypeEnum.DESC,
      label: 'ORDER_DESC'
    },
    {
      attribute: HotelSortAttributeEnum.PRICE,
      type: HotelSortTypeEnum.ASC,
      label: 'PRICE_ASC'
    },
    {
      attribute: HotelSortAttributeEnum.PRICE,
      type: HotelSortTypeEnum.DESC,
      label: 'PRICE_DESC'
    },
    {
      attribute: HotelSortAttributeEnum.STARRATING,
      type: HotelSortTypeEnum.ASC,
      label: 'STARRATING_ASC'
    },
    {
      attribute: HotelSortAttributeEnum.STARRATING,
      type: HotelSortTypeEnum.DESC,
      label: 'STARRATING_DESC'
    },
    {
      attribute: HotelSortAttributeEnum.GUESTRATING,
      type: HotelSortTypeEnum.ASC,
      label: 'GUESTRATING_ASC'
    },
    {
      attribute: HotelSortAttributeEnum.GUESTRATING,
      type: HotelSortTypeEnum.DESC,
      label: 'GUESTRATING_DESC' //Thoi gian bay ngan
    }
  ];

  selected = this.sortList[0];

  constructor(
    private filterOptionsFacade: FilterOptionsFacade,
    private filterAvailableFacade: FilterAvailableFacade
  ) {}

  ngOnInit() {}

  sortClick(selectedKey: SortInterface) {
    this.selected = selectedKey;
    this.filterAvailableFacade.changeSort(
      selectedKey.attribute,
      selectedKey.type
    );
  }
}
export interface SortInterface {
  attribute: HotelSortAttributeEnum;
  type: HotelSortTypeEnum;
  label: string;
}
