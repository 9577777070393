/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CancelPenalty { 
    amount?: string;
    currency?: CancelPenalty.CurrencyEnum;
    description?: string;
    endDate?: string;
    nights?: string;
    percent?: string;
    startDate?: string;
    type?: string;
}
export namespace CancelPenalty {
    export type CurrencyEnum = 'VND' | 'USD';
    export const CurrencyEnum = {
        VND: 'VND' as CurrencyEnum,
        USD: 'USD' as CurrencyEnum
    };
}
