import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultComponent } from './search-result.component';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatTabsModule,
  MatTooltipModule
} from '@angular/material';
import { FlightRoutingModule } from '../flight.module.routing';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlightSearchModule } from './flight-search/flight-search.module';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import { CheckboxFilterComponent } from './checkbox-filter/checkbox-filter.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FlightResultItemComponent } from './flight-result-item/flight-result-item.component';
import { FlightSortPanelComponent } from './flight-sort-panel/flight-sort-panel.component';
import { BrandFilterComponent } from './brand-filter/brand-filter.component';
import { BrandItemComponent } from './brand-filter/brand-item/brand-item.component';
import { ResultSummaryComponent } from './result-summary/result-summary.component';
import { String2BranchMapperPipe } from './brand-filter/brand-item/string2-branch-mapper.pipe';
import { DurationPipe } from './pipe/duration/duration.pipe';
import { FlightFormSearchModule } from '../flight-form-search/flight-form-search.module';
import { FlightSearchInfoComponent } from './flight-search-info/flight-search-info.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MapCabinClassStringToBranchFilterClass } from './pipe/map-cabin-class-string-to-branch-filter-class/map-cabin-class-string-to-branch-filter-class.pipe';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowRight,
  faChevronDown,
  faInfo,
  faInfoCircle,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipeModule } from '@gtd/components/date-pipe';
import { AirlinesModule } from '@gtd/components/airlines';
import { LoadingIframeSearchInfoComponent } from './flight-search-info/loading-iframe-search-info/loading-iframe-search-info.component';
import { LoadingIframeFilterPanelComponent } from './filter-panel/loading-iframe-filter-panel/loading-iframe-filter-panel.component';
import { DiffPriceIntModule } from '@gtd/components/diff-price';
import { DepartureFlightItemComponent } from './flight-search-info/departure-flight-item/departure-flight-item.component';
import { DialogMessageModule } from '@gtd/components/dialog-message';
import { PageErrorApiModule } from '@gtd/components/page-error-api';
import { BreadcrumbBarModule } from '@gtd/components/breadcrumb-bar';

library.add(faArrowRight, faChevronDown, faQuestionCircle, faInfo);

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatExpansionModule,
    FlightRoutingModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatRadioModule,
    FontAwesomeModule,
    SwiperModule,
    MatTabsModule,
    MatRippleModule,
    MatChipsModule,
    MatDialogModule,
    MatTooltipModule,
    FlightSearchModule,
    MatButtonToggleModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    FlightFormSearchModule,
    TranslateModule,
    MatDividerModule,
    MatMenuModule,
    MatSelectModule,
    ReactiveFormsModule,
    DatePipeModule,
    AirlinesModule,
    MatProgressBarModule,
    FormsModule,
    DiffPriceIntModule,
    DialogMessageModule,
    PageErrorApiModule,
    BreadcrumbBarModule,
  ],
  declarations: [
    SearchResultComponent,
    FilterPanelComponent,
    CheckboxFilterComponent,
    PaginationComponent,
    FlightResultItemComponent,
    FlightSortPanelComponent,
    BrandFilterComponent,
    BrandItemComponent,
    ResultSummaryComponent,
    String2BranchMapperPipe,
    DurationPipe,
    MapCabinClassStringToBranchFilterClass,
    FlightSearchInfoComponent,
    LoadingIframeSearchInfoComponent,
    LoadingIframeFilterPanelComponent,
    DepartureFlightItemComponent
  ],
  entryComponents: []
})
export class SearchResultModule {}
