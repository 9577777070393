/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ItineraryDTO } from './itineraryDTO';


export interface TripDTO { 
    approverEmail: string;
    createdBy: string;
    createdDate: Date;
    departmentId?: number;
    departmentName?: string;
    description: string;
    disFromDate?: string;
    disToDate?: string;
    employeeId?: number;
    fromDate: Date;
    fromLocation: string;
    id?: number;
    itineraries?: Array<ItineraryDTO>;
    name: string;
    resolvedById?: number;
    status: TripDTO.StatusEnum;
    toDate: Date;
    toLocation: string;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace TripDTO {
    export type StatusEnum = 'DRAFT' | 'PENDING' | 'APPROVED' | 'DISAPPROVED';
    export const StatusEnum = {
        DRAFT: 'DRAFT' as StatusEnum,
        PENDING: 'PENDING' as StatusEnum,
        APPROVED: 'APPROVED' as StatusEnum,
        DISAPPROVED: 'DISAPPROVED' as StatusEnum
    };
}
