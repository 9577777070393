import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AccountPartialState } from './account.reducer';
import {
  LoadAccount,
  AccountLoadError,
  AccountActionTypes
} from './account.actions';
import {map} from "rxjs/operators";
import {AccountResourceService} from "../../b2c-api-gateway/api/accountResource.service";

@Injectable()
export class AccountEffects {
  @Effect() loadAccount$ = this.dataPersistence.fetch(
    AccountActionTypes.LoadAccount,
    {
      run: (action: LoadAccount, state: AccountPartialState) => {
        return this.accountResourceService.getAccountUsingGET().pipe(
          map(update => ({
            type: AccountActionTypes.AccountLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadAccount, error) => {
        console.error('Error', error);
        return new AccountLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private accountResourceService: AccountResourceService,
    private dataPersistence: DataPersistence<AccountPartialState>
  ) {}
}
