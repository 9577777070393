import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {
  ACTIVATEACCOUNT_FEATURE_KEY,
  activateAccountReducer,
  initialState as activateAccountStateInitialState
} from './+state/activate-account.reducer';
import {ActivateAccountEffects} from './+state/activate-account.effects';
import {ActivateAccountFacade} from './+state/activate-account.facade';
import {NxModule} from '@nrwl/angular';
import {AccountResourceService, BASE_PATH} from "@gtd/b2c-client";
import {environment} from "@env/environment";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(ACTIVATEACCOUNT_FEATURE_KEY, activateAccountReducer, {
      initialState: activateAccountStateInitialState
    }),
    EffectsModule.forFeature([ActivateAccountEffects])
  ],
  providers: [{provide: BASE_PATH, useValue: environment.api.b2cClient}, ActivateAccountFacade, AccountResourceService]
})
export class ActivateAccountStateModule {}
