import { OtaResultOfTourActivityAvailableIntroPageDTO } from '@gtd/b2c-client';
import {
  TourSearchActivityAvailableAction,
  TourSearchActivityAvailableActionTypes
} from './tour-search-activity-available.actions';

export const TOURSEARCHACTIVITYAVAILABLE_FEATURE_KEY =
  'tourSearchActivityAvailable';

/**
 * Interface for the 'TourSearchActivityAvailable' data used in
 *  - TourSearchActivityAvailableState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface TourSearchActivityAvailableState {
  list: OtaResultOfTourActivityAvailableIntroPageDTO; // list of TourSearchActivityAvailable; analogous to a sql normalized table
  selectedId?: string | number; // which TourSearchActivityAvailable record has been selected
  loaded: boolean; // has the TourSearchActivityAvailable list been loaded
  error?: any; // last none error (if any)
}

export interface TourSearchActivityAvailablePartialState {
  readonly [TOURSEARCHACTIVITYAVAILABLE_FEATURE_KEY]: TourSearchActivityAvailableState;
}

export const initialState: TourSearchActivityAvailableState = {
  list: null,
  loaded: false
};

export function reducer(
  state: TourSearchActivityAvailableState = initialState,
  action: TourSearchActivityAvailableAction
): TourSearchActivityAvailableState {
  switch (action.type) {
    case TourSearchActivityAvailableActionTypes.TourSearchActivityAvailableLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case TourSearchActivityAvailableActionTypes.ResetTourSearchActivityAvailable: {
      state = { ...initialState };
      break;
    }
    case TourSearchActivityAvailableActionTypes.TourSearchActivityAvailableLoadError: {
      state = { ...initialState, loaded: true };
      break;
    }
  }
  return state;
}
