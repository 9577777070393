import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FarerulesPartialState } from './farerules.reducer';
import {
  FarerulesActionTypes,
  ComboVJFarerulesLoadError,
  ComboVJLoadFarerules
} from './farerules.actions';
import { map } from 'rxjs/operators';
import { AirTicketsResourceService } from '@gtd/b2c-client';

@Injectable()
export class FarerulesEffects {
  @Effect() loadComboVJFarerules$ = this.dataPersistence.fetch(
    FarerulesActionTypes.ComboVJLoadFarerules,
    {
      run: (action: ComboVJLoadFarerules, state: FarerulesPartialState) => {
        return this.airTicketsResourceService
          .retrieveFareRulesUsingPOST(action.payload, action.language)
          .pipe(
            map(update => ({
              type: FarerulesActionTypes.ComboVJFarerulesLoaded,
              payload: update
            }))
          );
      },

      onError: (action: ComboVJLoadFarerules, error) => {
        console.error('Error', error);
        return new ComboVJFarerulesLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private airTicketsResourceService: AirTicketsResourceService,
    private dataPersistence: DataPersistence<FarerulesPartialState>
  ) {}
}
