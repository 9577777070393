import {
  LibraryAvatarAction,
  LibraryAvatarActionTypes
} from './library-avatar.actions';
import {CustomerAvatarDTO} from "@gtd/api-services/customer-service";

export const LIBRARYAVATAR_FEATURE_KEY = 'libraryAvatar';

/**
 * Interface for the 'LibraryAvatar' data used in
 *  - LibraryAvatarState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface LibraryAvatarState {
  result: CustomerAvatarDTO; // list of LibraryAvatar; analogous to a sql normalized table
  selectedId?: string | number; // which LibraryAvatar record has been selected
  loaded: boolean; // has the LibraryAvatar list been loaded
  error?: any; // last none error (if any)
}

export interface LibraryAvatarPartialState {
  readonly [LIBRARYAVATAR_FEATURE_KEY]: LibraryAvatarState;
}

export const initialState: LibraryAvatarState = {
  result: null,
  loaded: false
};

export function reducer(
  state: LibraryAvatarState = initialState,
  action: LibraryAvatarAction
): LibraryAvatarState {
  switch (action.type) {
    case LibraryAvatarActionTypes.LibraryAvatarLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
    case LibraryAvatarActionTypes.LibraryAvatarResetAll: {
      state = initialState;
      break;
    }
  }
  return state;
}
