/**
 * utilitysrv API
 * utilitysrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AgentInvoiceProfileDTO } from './agentInvoiceProfileDTO';
import { BusinessDTO } from './businessDTO';
import { EINVOICEDTO } from './eINVOICEDTO';

export interface ApprovalInvoiceDTO {
  agentInvoiceProfile?: AgentInvoiceProfileDTO;
  approvedDate?: Date;
  approvedStatus?: ApprovalInvoiceDTO.ApprovedStatusEnum;
  bookingNumbers?: Array<string>;
  business?: BusinessDTO;
  createdDate?: Date;
  customerEmail?: string;
  customerName?: string;
  customerPhoneNumber?: string;
  einvoice?: EINVOICEDTO;
  id?: number;
  isDeleted?: boolean;
  note?: string;
  reason?: string;
  sumPaymentAmount?: number;
}
export namespace ApprovalInvoiceDTO {
  export type ApprovedStatusEnum =
    | 'SUCCESS'
    | 'PENDING'
    | 'FAILED'
    | 'CREATED'
    | 'SIGNFAILED'
    | 'SIGNING'
    | 'CANCELLED'
    | 'WAITING';
  export const ApprovedStatusEnum = {
    SUCCESS: 'SUCCESS' as ApprovedStatusEnum,
    PENDING: 'PENDING' as ApprovedStatusEnum,
    FAILED: 'FAILED' as ApprovedStatusEnum,
    CREATED: 'CREATED' as ApprovedStatusEnum,
    SIGNFAILED: 'SIGNFAILED' as ApprovedStatusEnum,
    SIGNING: 'SIGNING' as ApprovedStatusEnum,
    CANCELLED: 'CANCELLED' as ApprovedStatusEnum
  };
}
