import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { BasePartnerResourceService } from '@gtd/b2c-client';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { SnackbarComponent } from '@gtd/components/snackbar';
import {
  TimeCountDown,
  TimeCountDownBetweenDirective
} from '@gtd/directives/time-count-down-between';

@Component({
  selector: 'gtd-combo-booking-item',
  templateUrl: './combo-booking-item.component.html',
  styleUrls: ['./combo-booking-item.component.scss']
})
export class ComboBookingItemComponent implements OnInit {
  @Output() emitAdditionalRequestType: EventEmitter<any> = new EventEmitter();
  @Input() booking: any;
  @Input() supplierType: string;
  hotelDetail;
  isLoading: boolean;
  totalCustomer: number;
  timeCountDown: TimeCountDown;
  additionalRequestActions = [];
  appName = localStorage.getItem('appName');
  isShowAdditionalRequestButton: boolean = false;

  constructor(
    private basePartnerResourceService: BasePartnerResourceService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private timeCountDownBetweenDirective: TimeCountDownBetweenDirective
  ) {}

  ngOnInit() {
    if (this.booking && this.booking.content) {
      this.hotelDetail = this.booking.content.groupBooking.items[0].hotelProduct;
      if (this.hotelDetail && this.hotelDetail.rooms.length > 0) {
        this.totalCustomer = this.hotelDetail.rooms[0].ratePlans[0].paxPrice.reduce(
          (acc, cur) => {
            return acc + cur.paxInfo.adultQuantity + cur.paxInfo.childQuantity;
          },
          0
        );
      }
    }
    if (this.booking.timeToLive) {
      this.timeCountDown = this.timeCountDownBetweenDirective.timeBetweenDates(
        this.booking.timeToLive
      );
    }
    if (this.booking.departureDate) {
      if (
        new Date().getTime() < new Date(this.booking.departureDate).getTime() &&
        this.appName !== 'B2B2C'
      ) {
        this.isShowAdditionalRequestButton = true;
      }
    }
    if (this.booking.supplierType === 'COMBO') {
      this.additionalRequestActions = [
        {
          code: 'AIR_ADD_PACKAGE',
          name: 'booking.buy-signed-luggage-for-ticket'
        },
        {
          code: 'AIR_ADD_INFORMATION',
          name: 'booking.change-information-on-ticket'
        },
        {
          code: 'HOTEL_ADD_PERSON',
          name: 'booking.more-people'
        },
        {
          code: 'HOTEL_EDIT_BOOKING',
          name: 'booking.change-room-information'
        },
        {
          code: 'HOTEL_CHANGE_ROOM',
          name: 'booking.change-room'
        },
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    }
  }

  onEmitAdditionalRequestType(code: string) {
    this.emitAdditionalRequestType.emit({
      bookingNumber: this.booking.bookingNumber,
      type: code
    });
  }

  redirectPayment(event: Event, bookingNumber: string) {
    event.stopPropagation();
    if (environment.appName === 'B2B2C') {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'booking-result.insurance.processing', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
      this.isLoading = true;
      this.basePartnerResourceService
        .placeOrderUsingGET(bookingNumber)
        .subscribe(placeOrder => {
          if (placeOrder && placeOrder.isSuccess) {
            window.top.location.href = decodeURIComponent(placeOrder.result);
          } else {
            this.dialogMessageExpire();
          }
          this.isLoading = false;
          this._snackBar.dismiss();
        });
    } else {
      this.router.navigate(['/booking/payment'], {
        queryParams: {
          bookingNumber: bookingNumber
        }
      });
    }
  }

  dialogMessageExpire() {
    this.dialog.open(DialogMessageComponent, {
      data: {
        btn: [{ btnText: 'global.close' }],
        content: 'user.system-error-message',
        heading: 'user.system-error',
        icon: 'exclamation-triangle',
        messageType: 'error'
      },
      panelClass: 'dialog-message',
      disableClose: true,
      position: {
        top: '100px'
      }
    });
  }
}
