import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { TourCreateDraftBookingPartialState } from './tour-create-draft-booking.reducer';
import {
  LoadTourCreateDraftBooking,
  TourCreateDraftBookingLoaded,
  TourCreateDraftBookingLoadError,
  TourCreateDraftBookingActionTypes
} from './tour-create-draft-booking.actions';
import { TourResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class TourCreateDraftBookingEffects {
  @Effect() loadTourCreateDraftBooking$ = this.dataPersistence.fetch(
    TourCreateDraftBookingActionTypes.LoadTourCreateDraftBooking,
    {
      run: (
        action: LoadTourCreateDraftBooking,
        state: TourCreateDraftBookingPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.tourResourceService
          .createDraftBookingUsingPOST4(action.payload)
          .pipe(
            map(payload => ({
              type:
                TourCreateDraftBookingActionTypes.TourCreateDraftBookingLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadTourCreateDraftBooking, error) => {
        console.error('Error', error);
        return new TourCreateDraftBookingLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<
      TourCreateDraftBookingPartialState
    >,
    private tourResourceService: TourResourceService
  ) {}
}
