import {Action} from '@ngrx/store';
import {CountryCode} from './country.reducer';
import {CountryCodeDTO} from "@gtd/meta-client";

export enum CountryActionTypes {
  LoadCountry = '[Country] Load Country',
  CountryLoaded = '[Country] Country Loaded',
  CountryLoadError = '[Country] Country Load Error'
}

export class LoadCountry implements Action {
  readonly type = CountryActionTypes.LoadCountry;
  constructor(public payload: CountryCode) {}
}

export class CountryLoadError implements Action {
  readonly type = CountryActionTypes.CountryLoadError;
  constructor(public payload: any) {}
}

export class CountryLoaded implements Action {
  readonly type = CountryActionTypes.CountryLoaded;
  constructor(public payload: Array<CountryCodeDTO>) {}
}

export type CountryAction = LoadCountry | CountryLoaded | CountryLoadError;

export const fromCountryActions = {
  LoadCountry,
  CountryLoaded,
  CountryLoadError
};
