import {Component, OnDestroy, OnInit, Pipe} from '@angular/core';
import {Subscription} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {fadeIn, fadeInOut} from "@gtd/helpers";
import {ReportReferralFacade} from "@gtd/api-services/agentsrv";
import * as moment from 'moment';

@Component({
  selector: 'gtd-referral-commissions',
  templateUrl: './referral-commissions.component.html',
  styleUrls: ['./referral-commissions.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('600ms ease-out')),
      transition('default => rotated', animate('600ms ease-in')),
    ]),
    fadeIn,
    fadeInOut
  ],
})
export class ReferralCommissionsComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  private subscription: Subscription = new Subscription();
  loaded$ = this.reportReferralFacade.loaded$;
  allReportReferral$ = this.reportReferralFacade.allReportReferral$;
  groupReportReferral: any[];

  constructor(
    private reportReferralFacade: ReportReferralFacade
  ) {
    reportReferralFacade.loadAll();
  }

  ngOnInit() {
    this.subscription.add(
      this.reportReferralFacade.allReportReferral$.subscribe(allReportReferral => {
        this.groupReportReferral = [];
        if(allReportReferral && allReportReferral.result && allReportReferral.result.length > 0) {
          this.isLoading = false;
          const transactionsGr = allReportReferral.result.reduce((groups, transaction) => {
            let newdate = transaction.bookingDate.split("/").reverse().join("-");
            const date = moment(new Date(newdate)).format('MM-YYYY');
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(transaction);
            return groups;
          }, {})
          this.groupReportReferral = Object.keys(transactionsGr).map((date) => {
            return {
              date,
              referrals: transactionsGr[date]
            };
          });
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
@Pipe({ name: 'totalCommission' })
export class TotalCommission {
  transform(groupReportReferral: any[]): number {
    let totalCommission = 0;
    groupReportReferral.forEach(referral => {
      totalCommission += referral.referralAmount;
    });
    return Math.round(totalCommission);
  }
}
