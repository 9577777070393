import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  PageOfApprovalInvoiceDTO,
  ApprovalInvoiceResourceService
} from '@gtd/api-services/utilitysrv';
import { ProfileFacade } from 'libs/user/src/lib/profile/+state/profile.facade';
import { MatTableDataSource } from '@angular/material/table';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';

export interface InvoiceListElement {
  invoiceDate: string;
  invoiceNumber: string;
  bookingNumber: string;
  totalPayment: string;
  sumPaymentAmount: number;
  status: string;
  approvalId: number;
  month: number;
  year: number;
}

export interface groupBy {
  month: string;
  isGroupBy: boolean;
}

@Component({
  selector: 'gtd-invoice-history',
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.scss']
})
export class InvoiceHistoryComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  userRefCode: string = '';
  displayedColumns: string[] = [
    'invoiceDate',
    'bookingNumber',
    'invoiceNumber',
    'totalPayment',
    'status'
  ];
  invoiceListData: (InvoiceListElement | groupBy)[] = [];
  dataSource: any;
  totalInvoice: number = 0;
  defaultToDate = new Date();
  defaultFromDate = new Date(
    Date.UTC(
      this.defaultToDate.getFullYear(),
      this.defaultToDate.getMonth() - 1,
      this.defaultToDate.getDate()
    )
  );
  currentPage: number = 0;
  totalPages: number = 0;
  formSearch = this.fb.group({
    month: [''],
    invoiceStatus: this.fb.array([]),
    datePicker: this.fb.group({
      fromDate: [this.defaultFromDate, Validators.required],
      toDate: [this.defaultToDate]
    })
  });
  invoiceStatus = [
    {
      label: 'invoice-success',
      value: 'SUCCESS'
    },
    {
      label: 'invoice-pending',
      value: 'PENDING'
    },
    {
      label: 'invoice-failed',
      value: 'FAILED'
    }
  ];
  months: string[] = [];
  searchInformation = {
    fromDateAsString: null,
    toDateAsString: null,
    monthFromDate: null,
    approvedStatus: null
  };
  selectedMonth: string = '';
  isApplyFilter: boolean = false;
  sumaryApprovalByMonth: any = {};

  constructor(
    private fb: FormBuilder,
    private approvalInvoiceResourceService: ApprovalInvoiceResourceService,
    private profileFacade: ProfileFacade
  ) {}

  ngOnInit() {
    for (let i = 2; i > -1; i--) {
      let date = new Date().setMonth(new Date().getMonth() - i);
      this.months.push(
        new Date(date).getMonth() + 1 + '/' + new Date(date).getFullYear()
      );
    }
    this.subscriptions.add(
      this.profileFacade.selectedProfile$.subscribe(dataProfile => {
        if (
          dataProfile &&
          dataProfile.lists &&
          dataProfile.lists.customerCode
        ) {
          this.userRefCode = dataProfile.lists.customerCode;
          this.getSumaryApprovalByMonth();
        }
      })
    );
  }

  getInvoiceHistoryData(page, fromDate, toDate, monthFromDate, approvedStatus) {
    this.subscriptions.add(
      this.approvalInvoiceResourceService
        .findApprovalInvoiceWithPaginationByUserRefCodeUsingGET(
          approvedStatus,
          null,
          null,
          null,
          fromDate,
          null,
          null,
          null,
          monthFromDate,
          page,
          null,
          null,
          null,
          ['createdDate,desc'],
          null,
          toDate,
          this.userRefCode
        )
        .subscribe((res: PageOfApprovalInvoiceDTO) => {
          this.totalPages = res.totalPages;
          if (res.content.length > 0) {
            let totalPayment = 0;
            res.content.forEach(element => {
              let createdDate = new Date(element.createdDate);
              let day =
                createdDate.getDate() < 10
                  ? '0' + createdDate.getDate()
                  : createdDate.getDate();
              let month =
                createdDate.getMonth() + 1 < 10
                  ? '0' + (createdDate.getMonth() + 1)
                  : createdDate.getMonth() + 1;
              let invoiceDate =
                day + '/' + month + '/' + createdDate.getFullYear();
              let invoiceStatus;
              switch (element.approvedStatus) {
                case 'SUCCESS':
                  invoiceStatus = 'user.invoice-success';
                  break;
                case 'PENDING':
                  invoiceStatus = 'user.invoice-pending';
                  break;
                case 'CREATED':
                  invoiceStatus = 'user.invoice-created';
                  break;
                case 'SIGNING':
                  invoiceStatus = 'user.invoice-signing';
                  break;
                case 'FAILED':
                  invoiceStatus = 'user.invoice-failed';
                  break;
                case 'SIGNFAILED':
                  invoiceStatus = 'user.invoice-signing-failed';
                  break;
                case 'CANCELLED':
                  invoiceStatus = 'user.invoice-cancelled';
                  break;
                case 'WAITING':
                  invoiceStatus = 'user.invoice-waiting';
                  break;
                default:
                  invoiceStatus = '';
                  break;
              }
              let invoiceNumber = element.einvoice ? element.einvoice.mtc : '';
              let approvalId = element.id;
              this.invoiceListData.push({
                invoiceDate: invoiceDate,
                invoiceNumber: invoiceNumber,
                bookingNumber: element.bookingNumbers[0],
                approvalId: approvalId,
                totalPayment: element.sumPaymentAmount
                  ? this.numberWithCommas(element.sumPaymentAmount)
                  : '0',
                sumPaymentAmount: element.sumPaymentAmount,
                status: invoiceStatus,
                month: createdDate.getMonth() + 1,
                year: createdDate.getFullYear()
              });
              totalPayment += element.sumPaymentAmount;
            });
          }
          this.dataSource = new MatTableDataSource<
            InvoiceListElement | groupBy
          >(this.groupBy('month', this.invoiceListData));
        })
    );
  }

  getRecord(row) {
    if (row.status === 'user.invoice-success') {
      this.subscriptions.add(
        this.approvalInvoiceResourceService
          .getPdfUrlEinvoiceUsingGET(row.approvalId)
          .subscribe((res: any) => {
            window.open(res.pdfUrl, '_blank');
          })
      );
    }
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  groupBy(column: string, data: any[]) {
    if (!column) return data;
    let collapsedGroups = [];
    const customReducer = (accumulator, currentValue) => {
      let currentGroup = currentValue[column];
      if (!accumulator[currentGroup])
        accumulator[currentGroup] = [
          {
            groupName: `${currentValue[column]}/${currentValue['year']}`,
            value: currentValue[column],
            isGroupBy: true,
            totalInvoice: 0,
            totalPayment: ''
          }
        ];
      accumulator[currentGroup].push(currentValue);
      return accumulator;
    };

    let groups = data.reduce(customReducer, {});
    this.sumaryApprovalByMonth.invoiceSumary.forEach(element => {
      element.month = new Date(element.invoiceMonth).getMonth() + 1;
    });
    for (const property in groups) {
      groups[
        property
      ][0].totalInvoice = this.sumaryApprovalByMonth.invoiceSumary.find(
        element => element.month === parseInt(property)
      ) ? this.sumaryApprovalByMonth.invoiceSumary.find(
        element => element.month === parseInt(property)
      ).invoiceMonthCount : 0;
      groups[
        property
      ][0].totalPayment = this.sumaryApprovalByMonth.invoiceSumary.find(
        element => element.month === parseInt(property)
      )
        ? this.numberWithCommas(
            this.sumaryApprovalByMonth.invoiceSumary.find(
              element => element.month === parseInt(property)
            ).invoiceTotalSumMonth
          )
        : 0;
    }
    let groupArray = Object.keys(groups).map(key => groups[key]);
    for (let k = 0; k < groupArray.length; k++) {
      for (let j = k + 1; j < groupArray.length; j++) {
        if (
          new Date(
            groupArray[k][0].groupName.split('/')[1],
            groupArray[k][0].groupName.split('/')[0]
          ).valueOf() -
            new Date(
              groupArray[j][0].groupName.split('/')[1],
              groupArray[j][0].groupName.split('/')[0]
            ).valueOf() <
          0
        ) {
          let temp = groupArray[k];
          groupArray[k] = groupArray[j];
          groupArray[j] = temp;
        }
      }
    }
    let flatList = groupArray.reduce((a, c) => {
      return a.concat(c);
    }, []);

    return flatList.filter(rawLine => {
      return (
        rawLine.isGroup ||
        collapsedGroups.every(group => rawLine[column] != group.value)
      );
    });
  }

  applySearch() {
    this.isApplyFilter = true;
    let fromDate = this.formSearch.controls['datePicker'].get('fromDate').value;
    let toDate = this.formSearch.controls['datePicker'].get('toDate').value;
    this.searchInformation.fromDateAsString =
      fromDate.getDate() +
      '/' +
      (fromDate.getMonth() + 1) +
      '/' +
      fromDate.getFullYear();
    this.searchInformation.toDateAsString =
      toDate.getDate() +
      '/' +
      (toDate.getMonth() + 1) +
      '/' +
      toDate.getFullYear();
    this.searchInformation.monthFromDate =
      this.formSearch.controls['month'].value !== ''
        ? this.formSearch.controls['month'].value
        : null;
    this.searchInformation.approvedStatus =
      this.formSearch.controls['invoiceStatus'].value.length > 0
        ? this.formSearch.controls['invoiceStatus'].value
        : null;

    this.currentPage = 0;
    this.invoiceListData = [];
    this.getInvoiceHistoryData(
      this.currentPage,
      this.searchInformation.fromDateAsString,
      this.searchInformation.toDateAsString,
      this.searchInformation.monthFromDate,
      this.searchInformation.approvedStatus
    );
  }

  filterByMonth(month) {
    this.selectedMonth = month;
    this.formSearch.patchValue({
      month: month
    });
  }

  onChangeEinvoiceStatus(event) {
    const interests = (<FormArray>(
      this.formSearch.get('invoiceStatus')
    )) as FormArray;
    if (event.checked) {
      interests.push(new FormControl(event.source.value));
    } else {
      interests.removeAt(
        interests.controls.findIndex(x => x.value === event.source.value)
      );
    }
  }

  numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  onTableScroll(event) {
    const tableViewHeight = event.target.offsetHeight;
    const tableScrollHeight = event.target.scrollHeight; // length of all table
    const scrollLocation = event.target.scrollTop; // how far user scrolled
    // If the user has scrolled within 10px of the bottom of table, add more data
    const buffer = 10;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.currentPage++;
      if (this.currentPage < this.totalPages) {
        if (this.isApplyFilter === false) {
          this.getInvoiceHistoryData(this.currentPage, null, null, null, null);
        } else {
          this.getInvoiceHistoryData(
            this.currentPage,
            this.searchInformation.fromDateAsString,
            this.searchInformation.toDateAsString,
            this.searchInformation.monthFromDate,
            this.searchInformation.approvedStatus
          );
        }
      }
    }
  }

  getSumaryApprovalByMonth() {
    this.approvalInvoiceResourceService
      .sumaryApprovalByMonthUsingGET(this.userRefCode)
      .subscribe(
        res => {
          if (true === res.success) {
            this.sumaryApprovalByMonth = res.result;
            this.totalInvoice = this.sumaryApprovalByMonth.totalInvoice;
          }
          this.getInvoiceHistoryData(
            this.currentPage,
            null,
            null,
            null,
            null
          );
        },
        () =>
          this.getInvoiceHistoryData(
            this.currentPage,
            null,
            null,
            null,
            null
          )
      );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
