import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerTravellerFacade } from '../customer-traveller/+state/customer-traveller.facade';
import { ProfileFacade } from '../profile/+state/profile.facade';
import { Observable, Subscription } from 'rxjs';
import { CustomerTravellerDTO } from '@gtd/api-services/customer-service';
import {
  DialogMessageComponent,
  DialogMessageService
} from '@gtd/components/dialog-message';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { environment } from '@env/environment';
import { BreadcrumbBar } from '@gtd/components/breadcrumb-bar';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { CustomerTravellerArrangeByAlphabet } from '../customer-traveller/+state/customer-traveller.selectors';
import { CustomerResourceService } from '@gtd/api-services/b2b-client';

@Component({
  selector: 'gtd-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.scss']
})
export class PassengersComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  searchForm: FormGroup;
  filteredOptions: Observable<CustomerTravellerArrangeByAlphabet[]>;
  listCustomerTraveller: Array<CustomerTravellerArrangeByAlphabet>;
  myProfileInformation: CustomerTravellerDTO;
  defaultCustomerTravellerId: number;
  appName = environment.appName;
  breadcrumbBars: BreadcrumbBar[] = [
    {
      name: 'global.homepage',
      isRouterLink: true,
      routerLink: '/'
    },
    {
      isRouterLink: false,
      name: 'account.passengers'
    }
  ];

  constructor(
    private router: Router,
    private dialogMessageService: DialogMessageService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private customerTravellerFacade: CustomerTravellerFacade,
    private profileFacade: ProfileFacade,
    private customerResourceService: CustomerResourceService
  ) {
    this.searchForm = new FormGroup({
      searchName: new FormControl('')
    });
    this.customerTravellerFacade.loadAll();
    this.subscriptions.add(
      this.profileFacade.selectedProfile$.subscribe(dataProfile => {
        if (
          dataProfile &&
          dataProfile.lists &&
          dataProfile.type === 'LoadAll'
        ) {
          this.defaultCustomerTravellerId = dataProfile.lists.defaultTravellerId;
          this.customerTravellerFacade.getCustomerTravellerUsingGet(
            this.defaultCustomerTravellerId
          );
        }
      })
    );
  }

  ngOnInit() {
    this.appName = localStorage.getItem('appName');
    this.filteredOptions = this.customerTravellerFacade.selectListCustomerTraveller$;
    this.subscriptions.add(
      this.customerTravellerFacade.selectListCustomerTraveller$.subscribe(
        results => {
          if (results) {
            this.listCustomerTraveller = results;
            this.filteredOptions = this.searchForm.controls[
              'searchName'
            ].valueChanges.pipe(
              startWith(''),
              map(value => this._filter(value))
            );
          }
        }
      )
    );
    this.subscriptions.add(
      this.customerTravellerFacade.selectedCustomerTraveller$.subscribe(
        results => {
          if (results) {
            this.myProfileInformation = results;
          }
        }
      )
    );
    this.subscriptions.add(
      this.customerTravellerFacade.selectedRemoveCustomerTraveller$.subscribe(
        removeCustomerTraveller => {
          if (
            removeCustomerTraveller &&
            removeCustomerTraveller.result === 'Deleted'
          ) {
            this.removePassengerCallBack();
          }
        }
      )
    );
  }
  removePassengerCallBack() {
    this.customerTravellerFacade.loadAll();
    setTimeout(() => {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {
          message: 'user.removed-passenger',
          icon: 'check'
        },
        panelClass: 'snackbar-success',
        verticalPosition: 'top',
        duration: 3000
      });
      this.dialog.closeAll();
      this.dialogMessageService.setIsLoadingDialog(false);
    }, 500);
  }
  private _filter(value: string): CustomerTravellerArrangeByAlphabet[] {
    const filterValue = value ? value.toLowerCase() : value;

    let temp = new Array<CustomerTravellerArrangeByAlphabet>();
    this.listCustomerTraveller.forEach(customerArr => {
      if (this.checkFilter(filterValue, customerArr)) {
        temp.push({
          characterHeader: customerArr.characterHeader,
          customerTravellerArr: customerArr.customerTravellerArr.filter(
            passenger =>
              this.getOnlyName(passenger.firstName)
                .toLowerCase()
                .includes(filterValue)
          )
        });
      }
    });

    return temp;
  }

  checkFilter(
    filterValue: string,
    customerTraveller: CustomerTravellerArrangeByAlphabet
  ) {
    let checkFilter: boolean = false;

    customerTraveller.customerTravellerArr.forEach(customerArr => {
      if (
        this.getOnlyName(customerArr.firstName)
          .toLowerCase()
          .includes(filterValue)
      ) {
        checkFilter = true;
      }
    });

    if (checkFilter) {
      return true;
    } else {
      return false;
    }
  }

  getOnlyName(firstName: string) {
    return firstName
      .split(' ')
      .slice(-1)
      .join('');
  }

  scrollToIndex(id: number) {
    document.getElementsByClassName('header')[id + 1].scrollIntoView({
      behavior: 'smooth'
    });
  }

  addPassenger() {
    this.router.navigate(['/user/passenger/add']);
  }

  removePassenger(event: Event, passenger: CustomerTravellerDTO) {
    event.stopPropagation();
    setTimeout(() => {
      if (passenger.id) {
        const dialogRef = this.dialog.open(DialogMessageComponent, {
          data: {
            btn: [
              { btnText: 'global.close', btnColor: 'none' },
              { btnText: 'user.delete', btnColor: 'warn', action: 'Delete' }
            ],
            content: 'user.message-question-remove-passenger',
            dataTranslate: passenger.surName + ' ' + passenger.firstName,
            heading: 'user.question-remove-passenger',
            messageType: 'warn'
          },
          disableClose: true,
          panelClass: 'dialog-do-confirm',
          position: {
            top: '150px'
          }
        });
        this.subscriptions.add(
          dialogRef.afterClosed().subscribe(actionDialog => {
            if (actionDialog.action === 'Delete') {
              this.dialogMessageService.setIsLoadingDialog(true);
              this._snackBar.openFromComponent(SnackbarComponent, {
                data: {
                  message: 'user.removing-passenger',
                  loading: true
                },
                panelClass: 'snackbar-loading',
                verticalPosition: 'top'
              });
              if (this.appName === 'B2B') {
                this.customerResourceService
                  .deleteCustomerUsingDELETE(passenger.id)
                  .subscribe(result => {
                    setTimeout(() => {
                      this.removePassengerCallBack();
                    }, 500);
                  });
              } else {
                this.customerTravellerFacade.removeCustomerTravellerUsingDelete(
                  passenger.id
                );
              }
              if (this.searchForm.controls['searchName'].value) {
                this.searchForm.controls['searchName'].reset();
              }
            }
          })
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.customerTravellerFacade.customerTravellerUpdateDetroy();
  }
}
