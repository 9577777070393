import {NgModule} from '@angular/core';

import {AccountResourceService} from './api/accountResource.service';
import {AdditionalRequestResourceService} from './api/additionalRequestResource.service';
import {AirTicketsResourceService} from './api/airTicketsResource.service';
import {BasePartnerResourceService} from './api/basePartnerResource.service';
import {CustomerResourceService} from './api/customerResource.service';
import {FSoftResourceService} from './api/fSoftResource.service';
import {GatewayResourceService} from './api/gatewayResource.service';
import {GotadiOperationService} from './api/gotadiOperation.service';
import {HotelInventoryFormResourcesService} from './api/hotelInventoryFormResources.service';
import {HotelResourceService} from './api/hotelResource.service';
import {HotelResourceV3Service} from './api/hotelResourceV3.service';
import {IbeInternalConfigResourceService} from './api/ibeInternalConfigResource.service';
import {LienVietResourceService} from './api/lienVietResource.service';
import {LookupResourceService} from './api/lookupResource.service';
import {MigrationDataResourceService} from './api/migrationDataResource.service';
import {MobileTokenResourceService} from './api/mobileTokenResource.service';
import {PaymentResourcesService} from './api/paymentResources.service';
import {PaymentScheduledResourceService} from './api/paymentScheduledResource.service';
import {ProductResourceService} from './api/productResource.service';
import {ProfileInfoResourceService} from './api/profileInfoResource.service';
import {SeABankResourceService} from './api/seABankResource.service';
import {SocialCallbackControllerService} from './api/socialCallbackController.service';
import {SocialControllerService} from './api/socialController.service';
import {TikiResourceService} from './api/tikiResource.service';
import {UserJwtControllerService} from './api/userJwtController.service';
import {UserResourceService} from './api/userResource.service';
import {UtilResourceService} from './api/utilResource.service';
import {VersionResourceService} from './api/versionResource.service';
import {ViettelResourceService} from './api/viettelResource.service';
import {ComboResourceService} from './api/comboResource.service';
import {TongCucDuLichResourceService} from './api/tongCucDuLichResource.service';
import {TourResourceService} from './api/tourResource.service';
import {BASE_PATH} from "./variables";
import {environment} from "@env/environment";
import {ApprovalResourceService} from "./api/approvalResource.service";
import {ReportResourceService} from "./api/reportResource.service";
import {InventoryResourcesService} from "./api/inventoryResources.service";


@NgModule({
  imports: [
  ],
  declarations: [],
  exports: [],
  providers: [
    {provide: BASE_PATH, useValue: environment.api.b2cClient},
    AccountResourceService,
    AdditionalRequestResourceService,
    AirTicketsResourceService,
    BasePartnerResourceService,
    CustomerResourceService,
    FSoftResourceService,
    GatewayResourceService,
    GotadiOperationService,
    HotelInventoryFormResourcesService,
    HotelResourceService,
    HotelResourceV3Service,
    IbeInternalConfigResourceService,
    LienVietResourceService,
    LookupResourceService,
    MigrationDataResourceService,
    MobileTokenResourceService,
    PaymentResourcesService,
    PaymentScheduledResourceService,
    ProductResourceService,
    ProfileInfoResourceService,
    SeABankResourceService,
    SocialCallbackControllerService,
    SocialControllerService,
    TikiResourceService,
    UserJwtControllerService,
    UserResourceService,
    UtilResourceService,
    VersionResourceService,
    ComboResourceService,
    ViettelResourceService,
    TongCucDuLichResourceService,
    ApprovalResourceService,
    TourResourceService,
    ReportResourceService,
    InventoryResourcesService
  ]
})
export class B2cApiClientModule {}
