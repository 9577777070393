import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TOURUNITPRICE_FEATURE_KEY,
  TourUnitPriceState
} from './tour-unit-price.reducer';

// Lookup the 'TourUnitPrice' feature state managed by NgRx
const getTourUnitPriceState = createFeatureSelector<TourUnitPriceState>(
  TOURUNITPRICE_FEATURE_KEY
);

const getLoaded = createSelector(
  getTourUnitPriceState,
  (state: TourUnitPriceState) => state.loaded
);
const getError = createSelector(
  getTourUnitPriceState,
  (state: TourUnitPriceState) => state.error
);

const getAllTourUnitPrice = createSelector(
  getTourUnitPriceState,
  getLoaded,
  (state: TourUnitPriceState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getTourUnitPriceState,
  (state: TourUnitPriceState) => state.selectedId
);
const getSelectedTourUnitPrice = createSelector(
  getAllTourUnitPrice,
  getSelectedId,
  (tourUnitPrice, id) => {
    // const result = tourUnitPrice.find(it => it['id'] === id);
    return tourUnitPrice ? Object.assign({}, tourUnitPrice) : undefined;
  }
);

export const tourUnitPriceQuery = {
  getLoaded,
  getError,
  getAllTourUnitPrice,
  getSelectedTourUnitPrice
};
