import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {LookupB2bPartialState} from './lookup-b2b.reducer';
import {LoadLookupB2b, LookupB2bActionTypes, LookupB2bLoadError} from './lookup-b2b.actions';
import {map} from "rxjs/operators";
import {LookupResourceService} from "@gtd/api-services/b2b-client";

@Injectable()
export class LookupB2bEffects {
  @Effect() loadLookupB2b$ = this.dataPersistence.fetch(
    LookupB2bActionTypes.LoadLookupB2b,
    {
      run: (action: LoadLookupB2b, state: LookupB2bPartialState) => {
        return this.lookupResourceService.searchLookupsUsingGET(
          action.payload.domainName,
          action.payload.objectName,
          action.payload.lookupType,
          action.payload.page,
          action.payload.size,
          action.payload.sort,
          action.payload.observe,
          action.payload.reportProgress
        ).pipe(
          map(update => ({
            type: LookupB2bActionTypes.LookupB2bLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadLookupB2b, error) => {
        console.error('Error', error);
        return new LookupB2bLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private lookupResourceService: LookupResourceService,
    private dataPersistence: DataPersistence<LookupB2bPartialState>
  ) {}
}
