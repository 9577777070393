import {Action} from '@ngrx/store';
import {OtaResultOfFilterOptionsResult} from "@gtd/b2c-client";

export enum FilterOptionsActionTypes {
  LoadFilterOptions = '[FilterOptions] Load FilterOptions',
  FilterOptionsLoaded = '[FilterOptions] FilterOptions Loaded',
  FilterOptionsLoadError = '[FilterOptions] FilterOptions Load Error'
}

export class LoadFilterOptions implements Action {
  readonly type = FilterOptionsActionTypes.LoadFilterOptions;
  constructor(public payload: 'vi' | 'en') {}
}

export class FilterOptionsLoadError implements Action {
  readonly type = FilterOptionsActionTypes.FilterOptionsLoadError;
  constructor(public payload: any) {}
}

export class FilterOptionsLoaded implements Action {
  readonly type = FilterOptionsActionTypes.FilterOptionsLoaded;
  constructor(public payload: OtaResultOfFilterOptionsResult) {}
}

export type FilterOptionsAction =
  | LoadFilterOptions
  | FilterOptionsLoaded
  | FilterOptionsLoadError;

export const fromFilterOptionsActions = {
  LoadFilterOptions,
  FilterOptionsLoaded,
  FilterOptionsLoadError
};
