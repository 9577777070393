import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { TransactionFinancialPartialState } from './transaction-financial.reducer';
import {
  LoadTransactionFinancial,
  TransactionFinancialActionTypes,
  TransactionFinancialLoadError
} from './transaction-financial.actions';
import { map } from 'rxjs/operators';
import { AgencyFinancialResourceService } from '../../lib/index';

@Injectable()
export class TransactionFinancialEffects {
  @Effect() loadTransactionFinancial$ = this.dataPersistence.fetch(
    TransactionFinancialActionTypes.LoadTransactionFinancial,
    {
      run: (
        action: LoadTransactionFinancial,
        state: TransactionFinancialPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.agencyFinancialResourceService
          .searchAgencyTransactionsUsingGET(
            action.payload.filter && action.payload.filter.agencyCode !== ''
              ? action.payload.filter.agencyCode
              : '',
            action.payload.filter && action.payload.filter.branchCode !== ''
              ? action.payload.filter.branchCode
              : '',
            action.payload.filter && action.payload.filter.createdBy !== ''
              ? action.payload.filter.createdBy
              : '',
            action.payload.filter &&
              action.payload.filter.bookingRefNumber !== ''
              ? action.payload.filter.bookingRefNumber
              : '',
            action.payload.filter && action.payload.filter.bookingNumber !== ''
              ? action.payload.filter.bookingNumber
              : '',
            action.payload.filter && action.payload.filter.transDateFrom !== ''
              ? action.payload.filter.transDateFrom
              : '',
            action.payload.filter && action.payload.filter.transDateTo !== ''
              ? action.payload.filter.transDateTo
              : '',
            action.payload.filter && action.payload.filter.amountFrom !== ''
              ? action.payload.filter.amountFrom
              : '',
            action.payload.filter && action.payload.filter.amountTo !== ''
              ? action.payload.filter.amountTo
              : '',
            action.payload.filter && action.payload.filter.transType !== null
              ? action.payload.filter.transType
              : null,
            action.payload.page,
            action.payload.size,
            action.payload.sort,
            'response'
          )
          .pipe(
            map(result => ({
              type: TransactionFinancialActionTypes.TransactionFinancialLoaded,
              payload: result
            }))
          );
      },
      onError: (action: LoadTransactionFinancial, error) => {
        console.error('Error', error);
        return new TransactionFinancialLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private agencyFinancialResourceService: AgencyFinancialResourceService,
    private dataPersistence: DataPersistence<TransactionFinancialPartialState>
  ) {}
}
