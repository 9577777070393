import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of } from 'rxjs';

export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
}
const helper = new JwtHelperService();
const credentialsKey = 'credentials';
const credentialsKeyGuest = 'credentials-guest';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class CredentialsService {
  private _credentials: Credentials | null = null;
  private _loginName: string | null = null;

  isLoggedIn = new BehaviorSubject<boolean>(null);
  getLoggedInUser = this.isLoggedIn.asObservable();
  isConstantsMis = new BehaviorSubject<boolean>(null);
  getConstantsMis = this.isConstantsMis.asObservable();
  isConstantsAdmin = new BehaviorSubject<boolean>(null);
  getConstantsAdmin = this.isConstantsAdmin.asObservable();

  setLoggedInUser(isLoggedIn: boolean) {
    this.isLoggedIn.next(isLoggedIn);
  }
  setConstantsMis(isConstantsMis: boolean) {
    this.isConstantsMis.next(isConstantsMis);
  }
  setConstantsAdmin(isConstantsAdmin: boolean) {
    this.isConstantsAdmin.next(isConstantsAdmin);
  }

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        const savedCredentials =
          sessionStorage.getItem(credentialsKey) ||
          localStorage.getItem(credentialsKey);
        if (savedCredentials) {
          this._credentials = JSON.parse(savedCredentials);
          const loginName =
            sessionStorage.getItem('loginName') ||
            localStorage.getItem('loginName');
          if (loginName) {
            this._loginName = JSON.parse(loginName);
          }
        }
      }
    } catch (e) {}
  }

  get authoritiesConstantsAdmin(): boolean {
    if (this._credentials != null) {
      const data = helper.decodeToken(this._credentials.token);
      if (
        data.auth.indexOf('ROLE_ADMIN') >= 0 ||
        data.auth.indexOf('ROLE_REPORT') >= 0 ||
        data.auth.indexOf('ROLE_MIS') >= 0
      ) {
        return true;
      }
    }
    return false;
  }
  get authoritiesConstantsMis(): boolean {
    if (this._credentials != null) {
      const data = helper.decodeToken(this._credentials.token);
      if (data.auth.indexOf('ROLE_MIS') >= 0) {
        return true;
      }
    }
    return false;
  }
  get enableApprovalCheckout(): boolean {
    if (this._credentials != null) {
      const data = helper.decodeToken(this._credentials.token);
      if(data && data.feature_setting) {
        const featureSetting = JSON.parse(data.feature_setting);
        return featureSetting.approveFeature
      }
    }
    return false;
  }
  get enableApproval(): boolean {
    if (this._credentials != null) {
      const data = helper.decodeToken(this._credentials.token);
      if(data && data.feature_setting) {
        const featureSetting = JSON.parse(data.feature_setting);
        return featureSetting.approveFeature && !featureSetting.skipApprove
      }
    }
    return false;
  }

  get isAgencyAdmin(): boolean {
    if (this._credentials != null) {
      const data = helper.decodeToken(this._credentials.token);
      if (data.auth.indexOf('ROLE_AGENCY_ADMIN') >= 0) {
        return true;
      }
    }
    return false;
  }
  get cooperationClass(): string {
    if (this._credentials != null) {
      const data = helper.decodeToken(this._credentials.token);
      return data.cooperation_class? data.cooperation_class : '';
    }
    return '';
  }
  authoritiesConstantsOnlyAgent(): boolean {
    if (this._credentials != null) {
      const data = helper.decodeToken(this._credentials.token);
      if (
        data.auth.indexOf('ROLE_AGENCY_ADMIN') >= 0 ||
        data.auth.indexOf('ROLE_B2B') >= 0
      ) {
        if(
          data.auth.indexOf('ROLE_ACCOUNTANT') < 0 &&
          data.auth.indexOf('ROLE_ADMIN') < 0 &&
          data.auth.indexOf('ROLE_REPORT') < 0
        ) {
          return true;
        }
      }
    }
    return false;
  }
  get authorityViewFinancialManagement(): boolean {
    if (this._credentials != null) {
      const data = helper.decodeToken(this._credentials.token);
      if (
        data.auth.indexOf('ROLE_ADMIN') >= 0 ||
        data.auth.indexOf('ROLE_AGENCY_ADMIN') >= 0 ||
        data.auth.indexOf('ROLE_ACCOUNTANT') >= 0 ||
        data.auth.indexOf('ROLE_REPORT') >= 0
      ) {
        return true;
      }
    }
    return false;
  }
  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  getNameLogged(): string {
    if (this._credentials != null) {
      return this._credentials.username;
    }
    return null;
  }
  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  get getLoginName(): string | null {
    return this._loginName;
  }

  logout(): Observable<boolean> {
    this.setCredentials();
    this.isLoggedIn.next(false);
    // this.authenticateFacade.logOutAction();
    return of(true);
  }
  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      this._credentials = credentials || null;
      if (credentials) {
        const storage = remember ? localStorage : sessionStorage;
        storage.setItem(credentialsKey, JSON.stringify(credentials));
        this.isLoggedIn.next(true);
        setTimeout(() => {
          let isConstantsMis = this.authoritiesConstantsMis;
          this.setConstantsMis(isConstantsMis);
          let isConstantsAdmin = this.authoritiesConstantsAdmin;
          this.setConstantsAdmin(isConstantsAdmin);
        }, 100)
      } else {
        sessionStorage.removeItem(credentialsKey);
        localStorage.removeItem(credentialsKey);
        sessionStorage.removeItem(credentialsKeyGuest);
        localStorage.removeItem(credentialsKeyGuest);
        localStorage.removeItem('loginName');
        this.isLoggedIn.next(false);
      }
    }
  }
}
