import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {environment} from "@env/environment";

export enum FlightTypeSwitcherEnum {
  ONEWAY = 'ONEWAY',
  ROUNDTRIP = 'ROUNDTRIP'
}

@Component({
  selector: 'gtd-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  encapsulation: environment.appName === 'B2B2C'? ViewEncapsulation.None: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitcherComponent),
      multi: true
    }
  ]
})
export class SwitcherComponent implements ControlValueAccessor {
  // Allow the input to be disabled, and when it is make it somewhat transparent.
  @Input() disabled = false;
  @Input() type;
  selected: FlightTypeSwitcherEnum;
  getDataSearchFlight;
  bidingValue = FlightTypeSwitcherEnum;

  // Function to call when the switch changes.
  onChange = (value: any) => {};

  // Function to call when the input is touched (when switcher is clicked).
  onTouched = () => {};

  get value(): FlightTypeSwitcherEnum {
   return this.selected;
  }

  ngOnInit(){
  }
  // Allows Angular to register a function to call when the model (selected) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: FlightTypeSwitcherEnum): void {
    this.getDataSearchFlight = JSON.parse(localStorage.getItem('dataSearchFlight'));
    if(this.getDataSearchFlight === null){
      this.selected = value;
    }
    else {
      this.selected = this.getDataSearchFlight.roundType;
    }
  }

  changeValue(value: FlightTypeSwitcherEnum) {
    this.selected = value;
    this.onChange(value);
  }
}
