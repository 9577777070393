import { DatePipe, registerLocaleData } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import localeVi from '@angular/common/locales/vi';

@Pipe({
  name: 'displayDateByCurrentLanguage'
})
export class DisplayDateByCurrentLanguagePipe implements PipeTransform {
  constructor() {
    registerLocaleData(localeVi, 'vi');
  }
  transform(
    date: Date | string,
    format: string = 'HH:mm EEEE, dd/MM/yyyy',
    timezone: string = null
  ) {
    let currentLanguage = 'en-US';
    if (localStorage.getItem('language') === 'vi') {
      currentLanguage = 'vi';
      registerLocaleData(localeVi, 'vi');
    }
    const datePipe: DatePipe = new DatePipe(currentLanguage);
    return datePipe.transform(date, format, timezone ? timezone : null);
  }
}
