import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(value: number): string {
    const oneSecond = 1;
    const oneMinute = oneSecond * 1;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;

    const minutes = Math.floor((value % oneHour) / oneMinute);
    const hours = Math.floor((value % oneDay) / oneHour);
    const days = Math.floor(value / oneDay);
    let timeString = '';
    if (days !== 0) {
      timeString += days !== 1 ? days + 'd ' : days + 'd ';
    }
    if (hours !== 0) {
      timeString += hours !== 1 ? hours + 'h ' : hours + 'h ';
    }
    if (minutes !== 0) {
      timeString += minutes !== 1 ? minutes + 'm ' : minutes + 'm ';
    }
    return timeString;
  }
}
