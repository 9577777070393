import {
  BookingDetailAction,
  BookingDetailActionTypes
} from './booking-detail.actions';
import { Booking, GroupBooking } from '@gtd/b2c-client';

export const BOOKINGDETAIL_FEATURE_KEY = 'bookingDetail';

/**
 * Interface for the 'BookingDetail' data used in
 *  - BookingDetailState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface BookingDetailState {
  result: GroupBooking; // list of BookingDetail; analogous to a sql normalized table
  selectedId?: string | number; // which BookingDetail record has been selected
  loaded: boolean; // has the BookingDetail list been loaded
  error?: any; // last none error (if any)
}

export interface BookingDetailPartialState {
  readonly [BOOKINGDETAIL_FEATURE_KEY]: BookingDetailState;
}

export const initialState: BookingDetailState = {
  result: null,
  loaded: false
};

export function reducer(
  state: BookingDetailState = initialState,
  action: BookingDetailAction
): BookingDetailState {
  switch (action.type) {
    case BookingDetailActionTypes.BookingDetailLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
    case BookingDetailActionTypes.ResetBookingDetail: {
      state = initialState;
      break;
    }
    case BookingDetailActionTypes.LoadBookingDetail: {
      state = initialState;
      break;
    }
  }
  return state;
}
