import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { TourFilterOptionsPartialState } from './tour-filter-options.reducer';
import {
  LoadTourFilterOptions,
  TourFilterOptionsLoaded,
  TourFilterOptionsLoadError,
  TourFilterOptionsActionTypes
} from './tour-filter-options.actions';
import { TourResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class TourFilterOptionsEffects {
  @Effect() loadTourFilterOptions$ = this.dataPersistence.fetch(
    TourFilterOptionsActionTypes.LoadTourFilterOptions,
    {
      run: (
        action: LoadTourFilterOptions,
        state: TourFilterOptionsPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.tourResourceService
          .getFilterOptionsUsingGET1(action.payload)
          .pipe(
            map(payload => ({
              type: TourFilterOptionsActionTypes.TourFilterOptionsLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadTourFilterOptions, error) => {
        console.error('Error', error);
        return new TourFilterOptionsLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<TourFilterOptionsPartialState>,
    private tourResourceService: TourResourceService
  ) {}
}
