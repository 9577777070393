/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AgencyCode { 
    code?: string;
    id?: number;
    orgCode?: string;
    parentCode?: string;
    parentId?: number;
    root?: boolean;
    type?: AgencyCode.TypeEnum;
}
export namespace AgencyCode {
    export type TypeEnum = 'AGENCY' | 'BOOKER' | 'BRANCH' | 'CUSTOMER';
    export const TypeEnum = {
        AGENCY: 'AGENCY' as TypeEnum,
        BOOKER: 'BOOKER' as TypeEnum,
        BRANCH: 'BRANCH' as TypeEnum,
        CUSTOMER: 'CUSTOMER' as TypeEnum
    };
}
