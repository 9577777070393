import { AffiliatesAction, AffiliatesActionTypes } from './affiliates.actions';
import {BookingTags} from "@gtd/b2c-client";

export const AFFILIATES_FEATURE_KEY = 'affiliates';

/**
 * Interface for the 'Affiliates' data used in
 *  - AffiliatesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface AffiliatesState {
  result: BookingTags; // list of Affiliates; analogous to a sql normalized table
  selectedId?: string | number; // which Affiliates record has been selected
  loaded: boolean; // has the Affiliates list been loaded
  error?: any; // last none error (if any)
}

export interface AffiliatesPartialState {
  readonly [AFFILIATES_FEATURE_KEY]: AffiliatesState;
}

export const initialState: AffiliatesState = {
  result: null,
  loaded: false
};

export function affiliatesReducer(
  state: AffiliatesState = initialState,
  action: AffiliatesAction
): AffiliatesState {
  switch (action.type) {
    case AffiliatesActionTypes.AffiliatesLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
