/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PriorityMarkupDTO { 
    applyToAgencyCode?: string;
    businessType?: PriorityMarkupDTO.BusinessTypeEnum;
    id?: number;
    increasePriority?: boolean;
    newIndex?: number;
    status?: PriorityMarkupDTO.StatusEnum;
}
export namespace PriorityMarkupDTO {
    export type BusinessTypeEnum = 'B2C' | 'B2B';
    export const BusinessTypeEnum = {
        B2C: 'B2C' as BusinessTypeEnum,
        B2B: 'B2B' as BusinessTypeEnum
    };
    export type StatusEnum = 'PUBLISHING' | 'PENDING' | 'RETIRED';
    export const StatusEnum = {
        PUBLISHING: 'PUBLISHING' as StatusEnum,
        PENDING: 'PENDING' as StatusEnum,
        RETIRED: 'RETIRED' as StatusEnum
    };
}
