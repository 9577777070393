import {Action} from '@ngrx/store';
import {KeyAndPasswordVM} from "@gtd/b2c-client";
import {ResultResetPassword} from "./reset-password-state.reducer";

export enum ResetPasswordStateActionTypes {
  LoadResetPasswordState = '[ResetPasswordState] Load ResetPasswordState',
  ResetPasswordStateLoaded = '[ResetPasswordState] ResetPasswordState Loaded',
  ResetPasswordStateLoadError = '[ResetPasswordState] ResetPasswordState Load Error'
}

export class LoadResetPasswordState implements Action {
  readonly type = ResetPasswordStateActionTypes.LoadResetPasswordState;
  constructor(public payload: KeyAndPasswordVM) {}

}

export class ResetPasswordStateLoadError implements Action {
  readonly type = ResetPasswordStateActionTypes.ResetPasswordStateLoadError;
  constructor(public payload: ResultResetPassword) {}
}

export class ResetPasswordStateLoaded implements Action {
  readonly type = ResetPasswordStateActionTypes.ResetPasswordStateLoaded;
  constructor(public payload: any) {}
}

export type ResetPasswordStateAction =
  | LoadResetPasswordState
  | ResetPasswordStateLoaded
  | ResetPasswordStateLoadError;

export const fromResetPasswordStateActions = {
  LoadResetPasswordState,
  ResetPasswordStateLoaded,
  ResetPasswordStateLoadError
};
