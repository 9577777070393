import {Component, OnInit} from '@angular/core';
import {environment} from '@env/environment';
import {Router} from "@angular/router";
import * as moment from 'moment';

@Component({
  selector: 'gtd-popular-destination',
  templateUrl: './popular-destination.component.html',
  styleUrls: ['./popular-destination.component.scss']
})
export class PopularDestinationComponent implements OnInit {
  detinations = [
    {
      thumbnail: environment.cdn.cdnGotadi + '/web/images/demo/phuquoc.jpg',
      title: 'Phú Quốc',
      locationName: 'Phú Quốc, Kiên Giang, Việt Nam',
      locationCode: '6141655',
      searchType: 'CITY'
    },
    {
      thumbnail: environment.cdn.cdnGotadi + '/web/images/demo/dalat.jpg',
      title: 'Đà Lạt',
      locationName: 'Đà Lạt, Lâm Đồng, Việt Nam',
      locationCode: '1030',
      searchType: "CITY"
    },
    {
      thumbnail: environment.cdn.cdnGotadi + '/web/images/demo/sapa.jpg',
      title: 'Sapa',
      locationName: 'Sa Pa, Lào Cai, Việt Nam',
      locationCode: '6053145',
      searchType: 'CITY'
    },
    {
      thumbnail: environment.cdn.cdnGotadi + '/web/images/demo/ninhbinh.jpg',
      title: 'Ninh Bình',
      locationName: 'Ninh Bình, Việt Nam',
      locationCode: '6257859',
      searchType: 'PROVINCE_STATE'
    },
    {
      thumbnail: environment.cdn.cdnGotadi + '/web/images/demo/quynhon.jpg',
      title: 'Quy Nhơn',
      locationName: 'Quy Nhơn, Bình Định, Việt Nam',
      locationCode: '6054450',
      searchType: 'MULTI_CITY_VICINITY'
    }
  ]
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  searchPopular(detination) {
    let today = new Date();
    let fromDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()+2));
    let toDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()+3));
    let params = {
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
      toDate: moment(toDate).format('YYYY-MM-DD'),
      locationCode: detination.locationCode,
      locationName: detination.locationName,
      rooms: '[{"adultQuantity":2,"childQuantity":0,"childrenAges":[]}]',
      searchType: detination.searchType,
      supplier: detination.supplier? detination.supplier : 'EXPEDIA'
    }
    this.router.navigate(['/hotel'], {
      queryParams: {
        params: btoa(encodeURIComponent(JSON.stringify(params)))
      }
    });
  }

}
