/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AirBookFlightRQ { 
    bookingCode?: string;
    bookingNumber?: string;
    bookingTransactionCodes?: Array<string>;
    clientMarkup?: number;
    ibeSessionId?: string;
    requestAction?: AirBookFlightRQ.RequestActionEnum;
    requesterCode?: string;
    requesterType?: AirBookFlightRQ.RequesterTypeEnum;
}
export namespace AirBookFlightRQ {
    export type RequestActionEnum = 'BOOK' | 'ISSUE' | 'REISSUE' | 'PAYNOW' | 'CANCEL' | 'UPDATE_IN_PROCESS_STATUS';
    export const RequestActionEnum = {
        BOOK: 'BOOK' as RequestActionEnum,
        ISSUE: 'ISSUE' as RequestActionEnum,
        REISSUE: 'REISSUE' as RequestActionEnum,
        PAYNOW: 'PAYNOW' as RequestActionEnum,
        CANCEL: 'CANCEL' as RequestActionEnum,
        UPDATEINPROCESSSTATUS: 'UPDATE_IN_PROCESS_STATUS' as RequestActionEnum
    };
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
