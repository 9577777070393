import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {
  HISTORYSEARCHKEYWORD_FEATURE_KEY,
  historySearchKeywordReducer,
  initialState as historySearchKeywordInitialState
} from './+state-sesstion/history-search-keyword.reducer';
import {HistorySearchKeywordFacade} from './+state-sesstion/history-search-keyword.facade';

import {NxModule} from '@nrwl/angular';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(
      HISTORYSEARCHKEYWORD_FEATURE_KEY,
      historySearchKeywordReducer,
      {
        initialState: historySearchKeywordInitialState
      }
    )
  ],
  providers: [HistorySearchKeywordFacade]
})
export class DataSearchFlightModule {}
