import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountFacade } from './stage/account/account.facade';
import { AccountResourceService, BASE_PATH } from './b2c-api-gateway';
import { environment } from '@env/environment';
import { StoreModule } from '@ngrx/store';
import {
  ACCOUNT_FEATURE_KEY,
  initialState as accountInitialState,
  reducerAccount
} from './stage/account/account.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AccountEffects } from './stage/account/account.effects';
import { AccountService } from './stage/account/account.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ACCOUNT_FEATURE_KEY, reducerAccount, {
      initialState: accountInitialState
    }),
    EffectsModule.forFeature([AccountEffects])
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2cClient },
    AccountFacade,
    AccountResourceService
  ]
})
export class B2cClientModule {}
