import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

// custom validator to check that two fields match
export function DobValidation(
  controlName: string,
  paxType: string,
  flightType: string,
): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors => {
    const control = formGroup.controls[controlName];

    if (control.errors && !control.errors.dobValidation) {
      return;
    }
    if (flightType === 'INTE' || paxType !== 'ADT') {
      control.setErrors({ dobValidation: true });
    } else {
      control.setErrors(null);
    }
  };
}
