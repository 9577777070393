import { PromotionDTO } from '../../lib/model/promotionDTO';
import {
  PromotionFilterByVoucherAction,
  PromotionFilterByVoucherActionTypes
} from './promotion-filter-by-voucher.actions';

export const PROMOTIONFILTERBYVOUCHER_FEATURE_KEY = 'promotionFilterByVoucher';

/**
 * Interface for the 'PromotionFilterByVoucher' data used in
 *  - PromotionFilterByVoucherState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface PromotionFilterByVoucherState {
  list: PromotionDTO[]; // list of PromotionFilterByVoucher; analogous to a sql normalized table
  selectedId?: string | number; // which PromotionFilterByVoucher record has been selected
  loaded: boolean; // has the PromotionFilterByVoucher list been loaded
  error?: any; // last none error (if any)
}

export interface PromotionFilterByVoucherPartialState {
  readonly [PROMOTIONFILTERBYVOUCHER_FEATURE_KEY]: PromotionFilterByVoucherState;
}

export const initialState: PromotionFilterByVoucherState = {
  list: [],
  loaded: false
};

export function reducer(
  state: PromotionFilterByVoucherState = initialState,
  action: PromotionFilterByVoucherAction
): PromotionFilterByVoucherState {
  switch (action.type) {
    case PromotionFilterByVoucherActionTypes.PromotionFilterByVoucherLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
