import {
  DestroyUserProfile,
  GetUserProfileAction,
  GetUserProfileActionTypes
} from './get-user-profile.actions';
import {UserProfileTO} from "../../lib/index";

export const GETUSERPROFILE_FEATURE_KEY = 'getUserProfile';

/**
 * Interface for the 'GetUserProfile' data used in
 *  - GetUserProfileState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */


export interface GetUserProfileState {
  list: any; // list of GetUserProfile; analogous to a sql normalized table
  selectedId?: string | number; // which GetUserProfile record has been selected
  loaded: boolean; // has the GetUserProfile list been loaded
  error?: any; // last none error (if any)
}

export interface GetUserProfilePartialState {
  readonly [GETUSERPROFILE_FEATURE_KEY]: GetUserProfileState;
}

export const initialState: GetUserProfileState = {
  list: null,
  loaded: false
};

export function reducerProfile(
  state: GetUserProfileState = initialState,
  action: GetUserProfileAction
): GetUserProfileState {
  switch (action.type) {
    case GetUserProfileActionTypes.GetUserProfileLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case GetUserProfileActionTypes.GetUserProfileLoadError: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case GetUserProfileActionTypes.DestroyUserProfile: {
      state = initialState;
      break;
    }
  }
  return state;
}
