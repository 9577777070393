import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CustomerAvatarResourceService } from './api/customerAvatarResource.service';
import { CustomerProfileResourceService } from './api/customerProfileResource.service';
import { CustomerTravellerMemberCardResourceService } from './api/customerTravellerMemberCardResource.service';
import { CustomerTravellerResourceService } from './api/customerTravellerResource.service';
import { EventResourceService } from './api/eventResource.service';
import { MessageItemResourceService } from './api/messageItemResource.service';
import { MessageResourceService } from './api/messageResource.service';
import { MigrateDataResourceService } from './api/migrateDataResource.service';
import { MobileTokenResourceService } from './api/mobileTokenResource.service';
import { ProfileInfoResourceService } from './api/profileInfoResource.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CustomerAvatarResourceService,
    CustomerProfileResourceService,
    CustomerTravellerMemberCardResourceService,
    CustomerTravellerResourceService,
    EventResourceService,
    MessageItemResourceService,
    MessageResourceService,
    MigrateDataResourceService,
    MobileTokenResourceService,
    ProfileInfoResourceService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('B2cApiClientModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
