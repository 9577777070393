import { Action } from '@ngrx/store';

export enum BannerActionTypes {
  LoadBanner = '[Banner] Load Banner',
  BannerLoaded = '[Banner] Banner Loaded',
  BannerLoadError = '[Banner] Banner Load Error'
}

export class LoadBanner implements Action {
  readonly type = BannerActionTypes.LoadBanner;
  constructor(
    public payload: { bannerId: number; deviceId: number; type: string }
  ) {}
}

export class BannerLoadError implements Action {
  readonly type = BannerActionTypes.BannerLoadError;
  constructor(public payload: any) {}
}

export class BannerLoaded implements Action {
  readonly type = BannerActionTypes.BannerLoaded;
  constructor(public payload: { type: string; data: any }) {}
}

export type BannerAction = LoadBanner | BannerLoaded | BannerLoadError;

export const fromBannerActions = {
  LoadBanner,
  BannerLoaded,
  BannerLoadError
};
