/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TourActivityPaxInfo } from './tourActivityPaxInfo';


export interface TourActivityBookingPayload { 
    activityId?: number;
    date?: string;
    language?: TourActivityBookingPayload.LanguageEnum;
    metadata?: { [key: string]: string; };
    paxInfos?: Array<TourActivityPaxInfo>;
    ratePlanId?: number;
}
export namespace TourActivityBookingPayload {
    export type LanguageEnum = 'vi' | 'en';
    export const LanguageEnum = {
        Vi: 'vi' as LanguageEnum,
        En: 'en' as LanguageEnum
    };
}
