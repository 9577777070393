import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DELETENOTIFICATION_FEATURE_KEY,
  DeleteNotificationState
} from './delete-notification.reducer';

// Lookup the 'DeleteNotification' feature state managed by NgRx
const getDeleteNotificationState = createFeatureSelector<
  DeleteNotificationState
>(DELETENOTIFICATION_FEATURE_KEY);

const getLoaded = createSelector(
  getDeleteNotificationState,
  (state: DeleteNotificationState) => state.loaded
);
const getError = createSelector(
  getDeleteNotificationState,
  (state: DeleteNotificationState) => state.error
);

const getAllDeleteNotification = createSelector(
  getDeleteNotificationState,
  getLoaded,
  (state: DeleteNotificationState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getDeleteNotificationState,
  (state: DeleteNotificationState) => state.selectedId
);
const getSelectedDeleteNotification = createSelector(
  getAllDeleteNotification,
  getSelectedId,
  (deleteNotification, id) => {
    return deleteNotification ? Object.assign({}, deleteNotification) : undefined;
  }
);

export const deleteNotificationQuery = {
  getLoaded,
  getError,
  getAllDeleteNotification,
  getSelectedDeleteNotification
};
