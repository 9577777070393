import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberNightPipe } from './number-night/number-night.pipe';
import { DurationPipe } from './duration/duration.pipe';
import { SlugifyPipe } from './slug.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [NumberNightPipe, DurationPipe, SlugifyPipe],
  entryComponents: [],
  exports: [NumberNightPipe, DurationPipe, SlugifyPipe],
  providers: []
})
export class CustomPipeModule {}
