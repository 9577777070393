import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {NxModule} from '@nrwl/angular';

import {
  initialState as popularPlaceInitialState,
  popularPlaceReducer,
  POPULARPLACES_FEATURE_KEY
} from './+popular-places-state/popular-places.reducer';
import {PopularPlacesEffects} from './+popular-places-state/popular-places.effects';
import {PopularPlacesFacade} from './+popular-places-state/popular-places.facade';
import {environment} from "@env/environment";
import {BASE_PATH, InternalConfigResourceService} from "@gtd/meta-client";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(POPULARPLACES_FEATURE_KEY, popularPlaceReducer, {
      initialState: popularPlaceInitialState
    }),
    EffectsModule.forFeature([PopularPlacesEffects])
  ],
  providers: [{ provide: BASE_PATH, useValue: environment.api.metaService }, PopularPlacesFacade, InternalConfigResourceService]
})
export class DestinationModule {}
