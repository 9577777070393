import {
  HotelCreateDraftBookingAction,
  HotelCreateDraftBookingActionTypes
} from './hotel-create-draft-booking.actions';
import { OtaResultOfBooking } from '@gtd/b2c-client';

export const HOTELCREATEDRAFTBOOKING_FEATURE_KEY =
  'comboVJHotelCreateDraftBooking';

/**
 * Interface for the 'HotelCreateDraftBooking' data used in
 *  - HotelCreateDraftBookingState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelCreateDraftBookingState {
  result: OtaResultOfBooking; // list of HotelCreateDraftBooking; analogous to a sql normalized table
  selectedId?: string | number; // which HotelCreateDraftBooking record has been selected
  loaded: boolean; // has the HotelCreateDraftBooking list been loaded
  error?: any; // last none error (if any)
}

export interface HotelCreateDraftBookingPartialState {
  readonly [HOTELCREATEDRAFTBOOKING_FEATURE_KEY]: HotelCreateDraftBookingState;
}

export const initialState: HotelCreateDraftBookingState = {
  result: null,
  loaded: false
};

export function hotelCreateDraftBookingReducer(
  state: HotelCreateDraftBookingState = initialState,
  action: HotelCreateDraftBookingAction
): HotelCreateDraftBookingState {
  switch (action.type) {
    case HotelCreateDraftBookingActionTypes.ComboVJHotelCreateDraftBookingLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
    case HotelCreateDraftBookingActionTypes.ComboVJHotelCreateDraftBookingLoadError: {
      state = {
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
