import { Component, Input, OnInit } from '@angular/core';
import { GroupBooking, TourActivityPaxInfo } from '@gtd/b2c-client';
import { PaxInfo, TourUnitPriceFacade } from '@gtd/extra/tour/data-access';

@Component({
  selector: 'gtd-tour-price-detail',
  templateUrl: './tour-price-detail.component.html',
  styleUrls: ['./tour-price-detail.component.scss']
})
export class TourPriceDetailComponent implements OnInit {
  @Input() groupBooking: GroupBooking;
  unitPriceDetail: any[];
  constructor(private _tourUnitPriceFacade: TourUnitPriceFacade) {}

  ngOnInit() {
    if (this.groupBooking && this.groupBooking.supplierType === 'TOURS') {
      this._tourUnitPriceFacade.loadAll({
        activityId: this.groupBooking.tourActivityBookingPayload.activityId,
        language: 'vi'
      });
      this.unitPriceDetail = this.groupBooking.tourActivityBookingPayload.paxInfos.map(
        unitPrice => ({
          ...unitPrice,
          totalPrice: this.groupBooking.tourActivityProduct.ratePlans[0].schedulePrices.find(
            schedulePrice => schedulePrice.unitPriceId === unitPrice.unitId
          ).totalPrice
        })
      );
    }
  }
}
