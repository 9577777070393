import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'gtd-search-informations',
  templateUrl: './search-informations.component.html',
  styleUrls: ['./search-informations.component.scss']
})
export class SearchInformationsComponent implements OnInit {
  @Input() flightBooking: any;
  @Input() searchParams: any;
  flightDirection = 'D';
  isCollapsed: boolean;
  panelOpenState: boolean;

  constructor() {}

  ngOnInit() {}
}
