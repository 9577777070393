import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ComboSearchInformationPartialState } from './combo-search-information.reducer';
import { ComboSearchInformationQuery } from './combo-search-information.selectors';
import { ComboVJSearchInformation } from './combo-search-information.actions';
import { HotelMetaKeyword } from '@gtd/b2c-client';

@Injectable()
export class ComboVJSearchInformationFacade {
  loaded$ = this.store.pipe(select(ComboSearchInformationQuery.getLoaded));
  allComboSearchInformation$ = this.store.pipe(
    select(ComboSearchInformationQuery.getAllComboSearchInformation)
  );
  selectedComboSearchInformation$ = this.store.pipe(
    select(ComboSearchInformationQuery.getSelectedComboSearchInformation)
  );

  constructor(private store: Store<ComboSearchInformationPartialState>) {}
  //TODO
  addSearchInformation(searchInformation: any) {
    this.store.dispatch(new ComboVJSearchInformation(searchInformation));
  }
}
