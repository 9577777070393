/**
 * GoCms API
 * Just another WordPress site
 *
 * OpenAPI spec version: 6.0.1
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {ContactDTO} from "../model/contactDTO";


@Injectable()
export class ContactFormsService {

    protected basePath = 'https://cms.gotadi.com/wp-json';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactForm7V1ContactFormsGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contactForm7V1ContactFormsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contactForm7V1ContactFormsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contactForm7V1ContactFormsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.get<any>(`${this.basePath}/contact-form-7/v1/contact-forms`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactForm7V1ContactFormsIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contactForm7V1ContactFormsIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contactForm7V1ContactFormsIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contactForm7V1ContactFormsIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contactForm7V1ContactFormsIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/contact-form-7/v1/contact-forms/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactForm7V1ContactFormsIdFeedbackPost(id: number, contactDTO: ContactDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contactForm7V1ContactFormsIdFeedbackPost(id: number, contactDTO: ContactDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contactForm7V1ContactFormsIdFeedbackPost(id: number, contactDTO: ContactDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contactForm7V1ContactFormsIdFeedbackPost(id: number, contactDTO: ContactDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contactForm7V1ContactFormsIdFeedbackPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

      const formData = new FormData();
      formData.append('yourName', contactDTO.yourName);
      formData.append('yourEmail', contactDTO.yourEmail);
      formData.append('hotelName', contactDTO.hotelName);
      if(contactDTO.yourPhone) formData.append('yourPhone', contactDTO.yourPhone);
      if(contactDTO.roomName) formData.append('roomName', contactDTO.roomName);
      if(contactDTO.checkIn) formData.append('checkIn', contactDTO.checkIn);
      if(contactDTO.checkOut) formData.append('checkOut', contactDTO.checkOut);
      if(contactDTO.note) formData.append('note', contactDTO.note);
      if(contactDTO.sequenceNumber) formData.append('sequenceNumber', contactDTO.sequenceNumber);
      if(contactDTO.originCity) formData.append('originCity', contactDTO.originCity);
      if(contactDTO.destinationCity) formData.append('destinationCity', contactDTO.destinationCity);

        return this.httpClient.post<any>(`${this.basePath}/contact-form-7/v1/contact-forms/${encodeURIComponent(String(id))}/feedback`,
          formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            },
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactForm7V1ContactFormsIdFeedbackSchemaGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contactForm7V1ContactFormsIdFeedbackSchemaGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contactForm7V1ContactFormsIdFeedbackSchemaGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contactForm7V1ContactFormsIdFeedbackSchemaGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contactForm7V1ContactFormsIdFeedbackSchemaGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.get<any>(`${this.basePath}/contact-form-7/v1/contact-forms/${encodeURIComponent(String(id))}/feedback/schema`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactForm7V1ContactFormsIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contactForm7V1ContactFormsIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contactForm7V1ContactFormsIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contactForm7V1ContactFormsIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contactForm7V1ContactFormsIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.get<any>(`${this.basePath}/contact-form-7/v1/contact-forms/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactForm7V1ContactFormsIdPatch(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contactForm7V1ContactFormsIdPatch(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contactForm7V1ContactFormsIdPatch(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contactForm7V1ContactFormsIdPatch(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contactForm7V1ContactFormsIdPatch.');
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.patch<any>(`${this.basePath}/contact-form-7/v1/contact-forms/${encodeURIComponent(String(id))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactForm7V1ContactFormsIdPost(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contactForm7V1ContactFormsIdPost(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contactForm7V1ContactFormsIdPost(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contactForm7V1ContactFormsIdPost(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contactForm7V1ContactFormsIdPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.post<any>(`${this.basePath}/contact-form-7/v1/contact-forms/${encodeURIComponent(String(id))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactForm7V1ContactFormsIdPut(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contactForm7V1ContactFormsIdPut(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contactForm7V1ContactFormsIdPut(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contactForm7V1ContactFormsIdPut(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contactForm7V1ContactFormsIdPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.put<any>(`${this.basePath}/contact-form-7/v1/contact-forms/${encodeURIComponent(String(id))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactForm7V1ContactFormsIdRefillGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contactForm7V1ContactFormsIdRefillGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contactForm7V1ContactFormsIdRefillGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contactForm7V1ContactFormsIdRefillGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contactForm7V1ContactFormsIdRefillGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.get<any>(`${this.basePath}/contact-form-7/v1/contact-forms/${encodeURIComponent(String(id))}/refill`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactForm7V1ContactFormsPost(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contactForm7V1ContactFormsPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contactForm7V1ContactFormsPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contactForm7V1ContactFormsPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (basic) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];

        return this.httpClient.post<any>(`${this.basePath}/contact-form-7/v1/contact-forms`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
