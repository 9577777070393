import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActionNav } from '../model/actionNav';
import { AccountFacade } from '../../../../b2c-client/src/lib/stage/account/account.facade';
import { AvatarFacade } from '../profile/avatar/+state/avatar.facade';
import { Subscription } from 'rxjs';
import {
  GetUserProfileFacade,
  UserProfileTO
} from '@gtd/api-services/b2b-client';
import { SidebarService } from './sidebar.service';
import { ActivatedRoute } from '@angular/router';
import { CredentialsService as CredentialsServiceB2B } from '@gtd/auth-b2b';

@Component({
  selector: 'gtd-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  actionNav: ActionNav[];
  @Input() nameActive: string;
  userLoggedData: any;
  avatarImg: string;
  childActive: string;
  subscriptions = new Subscription();
  appName = localStorage.getItem('appName');
  cooperationClass = localStorage.getItem('cooperationClass');
  userProfile: UserProfileTO;

  enableApproval = this.credentialsServiceB2B.enableApprovalCheckout;

  constructor(
    private accountFacade: AccountFacade,
    private avatarFacade: AvatarFacade,
    private getUserProfileFacade: GetUserProfileFacade,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private credentialsServiceB2B: CredentialsServiceB2B
  ) {
    route.queryParams.subscribe(queryParams => {
      if (queryParams['type']) {
        this.childActive = queryParams['type'];
      }
    });
    this.subscriptions.add(
      this.accountFacade.getAccountLogged$.subscribe(dataAccount => {
        if (dataAccount != null) {
          this.userLoggedData = dataAccount;
        }
      })
    );
    this.subscriptions.add(
      this.avatarFacade.selectAvatar$.subscribe(avatar => {
        if (avatar && avatar.avatarImage) {
          this.avatarImg =
            'data:' +
            avatar.avatarImageContentType +
            ';base64,' +
            avatar.avatarImage;
        }
      })
    );
  }

  ngOnInit() {
    if (this.appName === 'B2B') {
      this.subscriptions.add(
        this.getUserProfileFacade.allGetUserProfile$.subscribe(userProfile => {
          if (userProfile) {
            this.userProfile = userProfile;
          }
        })
      );
    }

    this.actionNav = [];
    if (
      !this.avatarImg &&
      this.userLoggedData &&
      this.userLoggedData.imageUrl
    ) {
      this.avatarImg = this.userLoggedData.imageUrl;
    }
    this.actionNav = [
      {
        active: this.nameActive === 'profile',
        title: 'profile',
        icon: this.appName !== 'B2C' ? 'user' : null,
        image: this.appName === 'B2C' ? 'account' : null,
        iconPrefix: 'far',
        excerpt: 'note-profile',
        routerLink: '/user/profile',
        hidden: this.appName === 'B2B2C',
        children: [
          {
            active: this.nameActive === 'profile',
            title: 'child-profile',
            routerLink: '/user/profile'
          },
          {
            active: this.nameActive === 'change-password',
            title: 'change-password',
            routerLink: '/user/change-password'
          }
        ]
      },
      {
        active: this.nameActive === 'bookingMn',
        title: 'booking-management',
        icon: this.appName !== 'B2C' ? 'align-left' : null,
        image: this.appName === 'B2C' ? 'list-booking' : null,
        iconPrefix: 'fas',
        excerpt: 'note-booking-management',
        routerLink: '/user/bookings',
        children: [
          {
            active: this.nameActive === 'AIR',
            title: 'AIR',
            routerLink: '/user/bookings',
            queryParams: {
              type: 'AIR'
            }
          },
          {
            active: this.nameActive === 'HOTEL',
            title: 'HOTEL',
            routerLink: '/user/bookings',
            queryParams: {
              type: 'HOTEL'
            }
          },
          {
            active: this.nameActive === 'COMBO',
            title: 'COMBO',
            routerLink: '/user/bookings',
            queryParams: {
              type: 'COMBO'
            }
          },
          {
            active: this.nameActive === 'TOURS',
            title: 'TOURS',
            routerLink: '/user/bookings',
            queryParams: {
              type: 'TOURS'
            }
          }
        ]
      },
      {
        active: this.nameActive === 'additionalRequest',
        title: 'additional-request',
        image: 'add-request',
        iconPrefix: 'far',
        excerpt: 'additional-request',
        routerLink: '/user/additional-request',
        hidden: this.appName === 'B2B2C'
      },
      {
        active: this.nameActive === 'financial',
        title: 'financial',
        icon: 'credit-card',
        iconPrefix: 'far',
        excerpt: 'financial-note',
        routerLink: '/user/transaction-financial',
        hidden: this.appName !== 'B2B'
      },
      {
        active: this.nameActive === 'passengers',
        title: 'passengers',
        icon: this.appName !== 'B2C' ? 'users' : null,
        image: this.appName === 'B2C' ? 'customer' : null,
        iconPrefix: 'fas',
        excerpt: 'note-passengers',
        routerLink: '/user/passengers',
        hidden: this.enableApproval,
        children: [
          {
            title: 'passengers',
            active: this.nameActive === 'passengers',
            routerLink: '/user/passengers'
          },
          {
            title: 'new-passenger',
            active: this.nameActive === 'new-passenger',
            routerLink: '/user/passenger/add'
          }
        ]
      },
      {
        active: this.nameActive === 'invoiceHistory',
        title: 'invoice-history',
        image: 'invoice',
        iconPrefix: 'fas',
        excerpt: 'note-invoice-history',
        routerLink: '/user/invoice-history',
        hidden: this.appName === 'B2B2C' || this.appName === 'B2B'
      },
      {
        active: this.nameActive === 'notifications',
        title: 'notification-management',
        image: 'notice',
        iconPrefix: 'far',
        excerpt: 'note-notification-management',
        routerLink: '/user/notifications',
        hidden: this.appName === 'B2B2C' || this.appName === 'B2B'
      },
      {
        active: this.nameActive === 'settings',
        title: 'setting',
        image: 'setting',
        iconPrefix: 'fas',
        excerpt: 'note-setting',
        routerLink: '/user/settings',
        hidden: this.appName === 'B2B2C' || this.appName === 'B2B'
      }
      // {
      //   active: this.nameActive === 'referrals',
      //   title: 'referrals',
      //   icon: 'handshake',
      //   iconPrefix: 'far',
      //   excerpt: 'referral-note',
      //   routerLink: '/user/referrals',
      //   hidden: this.appName !== 'B2B' || this.cooperationClass === 'ca',
      //   children: [
      //     {
      //       title: 'referral',
      //       active: this.nameActive === 'policy',
      //       routerLink: '/user/referrals/policy'
      //     },
      //     {
      //       title: 'transfer-referral',
      //       active: this.nameActive === 'referrals',
      //       routerLink: '/user/referrals'
      //     },
      //     {
      //       title: 'transfer-commission',
      //       active: this.nameActive === 'commissions',
      //       routerLink: '/user/referrals/commissions'
      //     }
      //   ]
      // }
    ];
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  navChange(nameActive: string) {
    this.sidebarService.setNavChange(nameActive);
  }
}
