import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpperCaseInputDirective} from './upper-case-input.directive';

const DIRECTIVES = [UpperCaseInputDirective];

@NgModule({
  imports: [CommonModule],
  declarations: [DIRECTIVES],
  exports: [DIRECTIVES]
})
export class UpperCaseInputModule {}
