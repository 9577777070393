import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {ResetPasswordStatePartialState} from './reset-password-state.reducer';
import {
  LoadResetPasswordState,
  ResetPasswordStateActionTypes,
  ResetPasswordStateLoadError
} from './reset-password-state.actions';
import {map} from "rxjs/operators";
import {AccountResourceService} from "@gtd/b2c-client";

@Injectable()
export class ResetPasswordStateEffects {
  @Effect() loadResetPasswordState$ = this.dataPersistence.fetch(
    ResetPasswordStateActionTypes.LoadResetPasswordState,
    {
      run: (
        action: LoadResetPasswordState,
        state: ResetPasswordStatePartialState
      ) => {
        return this.accountResourceService.finishPasswordResetUsingPOST(action.payload).pipe(
          map(update => ({
            type: ResetPasswordStateActionTypes.ResetPasswordStateLoaded,
            payload: update,
          }))
        );
      },

      onError: (action: LoadResetPasswordState, error) => {
        console.error('Error', error);
        return new ResetPasswordStateLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private accountResourceService: AccountResourceService,
    private dataPersistence: DataPersistence<ResetPasswordStatePartialState>
  ) {}
}
