import {
  SearchUserBookingAction,
  SearchUserBookingActionTypes
} from './search-user-booking.actions';
import {BookingDTO} from "../api-client/index";

export const SEARCHUSERBOOKING_FEATURE_KEY = 'searchUserBooking';

/**
 * Interface for the 'SearchUserBooking' data used in
 *  - SearchUserBookingState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */


export interface SearchUserBookingState {
  list: BookingDTO[]; // list of SearchUserBooking; analogous to a sql normalized table
  selectedId?: string | number; // which SearchUserBooking record has been selected
  loaded: boolean; // has the SearchUserBooking list been loaded
  error?: any; // last none error (if any)
  totalCount?: any; // last none error (if any)
}

export interface SearchUserBookingPartialState {
  readonly [SEARCHUSERBOOKING_FEATURE_KEY]: SearchUserBookingState;
}

export const initialState: SearchUserBookingState = {
  list: [],
  totalCount: null,
  loaded: false
};

export function reducerSearchBooking(
  state: SearchUserBookingState = initialState,
  action: SearchUserBookingAction
): SearchUserBookingState {
  switch (action.type) {
    case SearchUserBookingActionTypes.SearchUserBookingLoaded: {
      state = {
        ...state,
        list: action.payload.body,
        loaded: true,
        totalCount: action.payload.headers.get('X-Total-Count')
      };
      break;
    }
  }
  return state;
}

export interface SearchUserBooking {
  filter?: SearchBookingVM,
  supplierType: string,
  currentPage?: number,
  totalCount?: number,
  skipSearch?: boolean,
  filterId?: number,
  page?: number,
  size?: number,
  sort?: Array<string>
}
export interface SearchBookingVM {
  bookingCode?: string,
  bookingStatus?: string,
  fromLocationName?: string,
  toLocationName?: string,
  fromDate?: string,
  toDate?: string,
  listBookingStatus?: Array<string>,
  filterStatus?: boolean,
}
