/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TourUnitRatePlanDTO { 
    description?: string;
    descriptionType?: TourUnitRatePlanDTO.DescriptionTypeEnum;
    id?: number;
    maxAge?: number;
    maxHeightCm?: number;
    maxOccupancy?: number;
    minAge?: number;
    minHeightCm?: number;
    minOccupancy?: number;
    name?: string;
    primary?: boolean;
}
export namespace TourUnitRatePlanDTO {
    export type DescriptionTypeEnum = 'HEIGHT' | 'AGE' | 'BOTH';
    export const DescriptionTypeEnum = {
        HEIGHT: 'HEIGHT' as DescriptionTypeEnum,
        AGE: 'AGE' as DescriptionTypeEnum,
        BOTH: 'BOTH' as DescriptionTypeEnum
    };
}
