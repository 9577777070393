import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {GroupBooking} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-booking-result-hotel',
  templateUrl: './booking-result-hotel.component.html',
  styleUrls: ['./booking-result-hotel.component.scss']
})
export class BookingResultHotelComponent implements OnInit {
  @Input() allFinalBookingDetail: GroupBooking;
  counter = Array;

  constructor() { }

  ngOnInit() {
  }
  mathFloor(star: any) {
    return Math.floor(star)
  }
  isFloat(star) {
    return (star % 1 != 0);
  }
}
@Pipe({
  name: 'numberNight'
})
export class NumberNightResult implements PipeTransform {
  transform(departureDate: string, returnDate?: string): number {
    let numbDate = 0;
    if(departureDate && returnDate) {
      numbDate = this.datediff(this.parseDate(departureDate), this.parseDate(returnDate))
    }
    return numbDate;
  }
  parseDate(str) {
    let mdy = str.split('/');
    return new Date(mdy[2], mdy[0]-1, mdy[1]);
  }
  datediff(first, second) {
    return Math.round((second-first)/(1000*60*60*24));
  }
}
