/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { OtaResultOfListOfInsuranceDetail } from '../model/otaResultOfListOfInsuranceDetail';
import { OtaResultOfListOfSSRItemDTO } from '../model/otaResultOfListOfSSRItemDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InsuranceApiResourceService {

    protected basePath = 'https://10.7.71.20:7883';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * getInsurancePlans
     * 
     * @param departureDate departureDate
     * @param fromLocation fromLocation
     * @param toLocation toLocation
     * @param adult adult
     * @param bookingNumber bookingNumber
     * @param child child
     * @param infant infant
     * @param numberOfNonIns numberOfNonIns
     * @param planId planId
     * @param returnDate returnDate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInsurancePlansUsingGET1(departureDate: string, fromLocation: string, toLocation: string, adult?: number, bookingNumber?: string, child?: number, infant?: number, numberOfNonIns?: number, planId?: string, returnDate?: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfSSRItemDTO>;
    public getInsurancePlansUsingGET1(departureDate: string, fromLocation: string, toLocation: string, adult?: number, bookingNumber?: string, child?: number, infant?: number, numberOfNonIns?: number, planId?: string, returnDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfSSRItemDTO>>;
    public getInsurancePlansUsingGET1(departureDate: string, fromLocation: string, toLocation: string, adult?: number, bookingNumber?: string, child?: number, infant?: number, numberOfNonIns?: number, planId?: string, returnDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfSSRItemDTO>>;
    public getInsurancePlansUsingGET1(departureDate: string, fromLocation: string, toLocation: string, adult?: number, bookingNumber?: string, child?: number, infant?: number, numberOfNonIns?: number, planId?: string, returnDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (departureDate === null || departureDate === undefined) {
            throw new Error('Required parameter departureDate was null or undefined when calling getInsurancePlansUsingGET1.');
        }

        if (fromLocation === null || fromLocation === undefined) {
            throw new Error('Required parameter fromLocation was null or undefined when calling getInsurancePlansUsingGET1.');
        }

        if (toLocation === null || toLocation === undefined) {
            throw new Error('Required parameter toLocation was null or undefined when calling getInsurancePlansUsingGET1.');
        }








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (adult !== undefined && adult !== null) {
            queryParameters = queryParameters.set('adult', <any>adult);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (child !== undefined && child !== null) {
            queryParameters = queryParameters.set('child', <any>child);
        }
        if (departureDate !== undefined && departureDate !== null) {
            queryParameters = queryParameters.set('departureDate', <any>departureDate);
        }
        if (fromLocation !== undefined && fromLocation !== null) {
            queryParameters = queryParameters.set('fromLocation', <any>fromLocation);
        }
        if (infant !== undefined && infant !== null) {
            queryParameters = queryParameters.set('infant', <any>infant);
        }
        if (numberOfNonIns !== undefined && numberOfNonIns !== null) {
            queryParameters = queryParameters.set('numberOfNonIns', <any>numberOfNonIns);
        }
        if (planId !== undefined && planId !== null) {
            queryParameters = queryParameters.set('planId', <any>planId);
        }
        if (returnDate !== undefined && returnDate !== null) {
            queryParameters = queryParameters.set('returnDate', <any>returnDate);
        }
        if (toLocation !== undefined && toLocation !== null) {
            queryParameters = queryParameters.set('toLocation', <any>toLocation);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OtaResultOfListOfSSRItemDTO>(`${this.basePath}/api/insurance/insurance-plans`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getInsurancesByBooking
     * 
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInsurancesByBookingUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfInsuranceDetail>;
    public getInsurancesByBookingUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfInsuranceDetail>>;
    public getInsurancesByBookingUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfInsuranceDetail>>;
    public getInsurancesByBookingUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getInsurancesByBookingUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OtaResultOfListOfInsuranceDetail>(`${this.basePath}/api/insurance/insurance-detail/${encodeURIComponent(String(bookingNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
