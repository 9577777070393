/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MarkupExceptionDTO { 
    agencyCode?: string;
    applyToAgencyCode?: string;
    applyToAgencyId?: number;
    applyToAgencyName?: string;
    businessType?: MarkupExceptionDTO.BusinessTypeEnum;
    createdBy?: string;
    createdDate?: Date;
    customerClass?: MarkupExceptionDTO.CustomerClassEnum;
    defaultValue?: number;
    effectiveFrom?: Date;
    effectiveTo?: Date;
    id?: number;
    markupId?: number;
    orgCode?: string;
    priority?: number;
    productClass?: string;
    serviceProviderCode?: string;
    serviceProviderName?: string;
    type?: MarkupExceptionDTO.TypeEnum;
    updatedBy?: string;
    updatedDate?: Date;
    valueType?: MarkupExceptionDTO.ValueTypeEnum;
    zoneCode?: string;
}
export namespace MarkupExceptionDTO {
    export type BusinessTypeEnum = 'B2C' | 'B2B';
    export const BusinessTypeEnum = {
        B2C: 'B2C' as BusinessTypeEnum,
        B2B: 'B2B' as BusinessTypeEnum
    };
    export type CustomerClassEnum = 'BASIC' | 'SILVER' | 'GOLD' | 'PLATINUM';
    export const CustomerClassEnum = {
        BASIC: 'BASIC' as CustomerClassEnum,
        SILVER: 'SILVER' as CustomerClassEnum,
        GOLD: 'GOLD' as CustomerClassEnum,
        PLATINUM: 'PLATINUM' as CustomerClassEnum
    };
    export type TypeEnum = 'PER_PAX_PER_BOOKING' | 'PER_PAX_PER_SEGMENT' | 'PER_BOOKING';
    export const TypeEnum = {
        PAXPERBOOKING: 'PER_PAX_PER_BOOKING' as TypeEnum,
        PAXPERSEGMENT: 'PER_PAX_PER_SEGMENT' as TypeEnum,
        BOOKING: 'PER_BOOKING' as TypeEnum
    };
    export type ValueTypeEnum = 'FIXED' | 'PERCENT';
    export const ValueTypeEnum = {
        FIXED: 'FIXED' as ValueTypeEnum,
        PERCENT: 'PERCENT' as ValueTypeEnum
    };
}
