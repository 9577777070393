import {Action} from '@ngrx/store';
import {CustomerTravellerDTO} from "@gtd/api-services/customer-service";

export enum CustomerTravellerActionTypes {
  LoadCustomerTraveller = '[CustomerTraveller] Load CustomerTraveller',
  CustomerTravellerLoaded = '[CustomerTraveller] CustomerTraveller Loaded',
  CustomerTravellerLoadError = '[CustomerTraveller] CustomerTraveller Load Error',

  //Load All Customer Traveller
  LoadAllCustomerTraveller = '[CustomerTraveller] Load All CustomerTraveller',
  CustomerTravellerLoadedAll = '[CustomerTraveller] CustomerTraveller Loaded All',
  CustomerTravellerLoadAllError = '[CustomerTraveller] CustomerTraveller Load All Error',

  //Update Customer Traveller
  UpdateCustomerTraveller = '[CustomerTraveller] Update CustomerTraveller',
  CustomerTravellerUpdated = '[CustomerTraveller] CustomerTraveller Updated',
  CustomerTravellerUpdateError = '[CustomerTraveller] CustomerTraveller Update Error',
  CustomerTravellerUpdateDetroy = '[CustomerTraveller] CustomerTraveller Update Detroy',

  //Remove Customer Traveller
  RemoveCustomerTraveller = '[CustomerTraveller] Remove CustomerTraveller',
  CustomerTravellerRemoved = '[CustomerTraveller] CustomerTraveller removed',
  CustomerTravellerRemoveError = '[CustomerTraveller] CustomerTraveller remove error',
}

export class LoadCustomerTraveller implements Action {
  readonly type = CustomerTravellerActionTypes.LoadCustomerTraveller;
  constructor(public payload: any) {}
}

export class CustomerTravellerLoadError implements Action {
  readonly type = CustomerTravellerActionTypes.CustomerTravellerLoadError;
  constructor(public payload: any) {}
}

export class CustomerTravellerLoaded implements Action {
  readonly type = CustomerTravellerActionTypes.CustomerTravellerLoaded;
  constructor(public payload: CustomerTravellerDTO) {}
}

//Load All Customer Traveller

export class LoadAllCustomerTraveller implements Action {
  readonly type = CustomerTravellerActionTypes.LoadAllCustomerTraveller;
}

export class CustomerTravellerLoadAllError implements Action {
  readonly type = CustomerTravellerActionTypes.CustomerTravellerLoadAllError;
  constructor(public payload: any) {}
}

export class CustomerTravellerLoadedAll implements Action {
  readonly type = CustomerTravellerActionTypes.CustomerTravellerLoadedAll;
  constructor(public payload: Array<CustomerTravellerDTO>) {}
}

//Update Customer Traveller

export class UpdateCustomerTraveller implements Action {
  readonly type = CustomerTravellerActionTypes.UpdateCustomerTraveller;
  constructor(public payload: Array<CustomerTravellerDTO>) {}
}

export class CustomerTravellerUpdated implements Action {
  readonly type = CustomerTravellerActionTypes.CustomerTravellerUpdated;
  constructor(public payload: Array<CustomerTravellerDTO>) {}
}

export class CustomerTravellerUpdateError implements Action {
  readonly type = CustomerTravellerActionTypes.CustomerTravellerUpdateError;
  constructor(public payload: any) {}
}

export class CustomerTravellerUpdateDetroy implements Action {
  readonly type = CustomerTravellerActionTypes.CustomerTravellerUpdateDetroy;
}

// Remove customer traveller
export class RemoveCustomerTraveller implements Action {
  readonly type = CustomerTravellerActionTypes.RemoveCustomerTraveller;
  constructor(public payload: number) {}
}

export class CustomerTravellerRemoved implements Action {
  readonly type = CustomerTravellerActionTypes.CustomerTravellerRemoved;
  constructor(public payload: any) {}
}

export class CustomerTravellerRemoveError implements Action {
  readonly type = CustomerTravellerActionTypes.CustomerTravellerRemoveError;
  constructor(public payload: any) {}
}

export type CustomerTravellerAction =
  | LoadCustomerTraveller
  | CustomerTravellerLoaded
  | CustomerTravellerLoadError
  | LoadAllCustomerTraveller
  | CustomerTravellerLoadedAll
  | CustomerTravellerLoadAllError
  | UpdateCustomerTraveller
  | CustomerTravellerUpdated
  | CustomerTravellerUpdateError
  | CustomerTravellerUpdateDetroy
  | RemoveCustomerTraveller
  | CustomerTravellerRemoved
  | CustomerTravellerRemoveError;

export const fromCustomerTravellerActions = {
  LoadCustomerTraveller,
  CustomerTravellerLoaded,
  CustomerTravellerLoadError,
  LoadAllCustomerTraveller,
  CustomerTravellerLoadedAll,
  CustomerTravellerLoadAllError,
  UpdateCustomerTraveller,
  CustomerTravellerUpdated,
  CustomerTravellerUpdateError,
  CustomerTravellerUpdateDetroy,
  RemoveCustomerTraveller,
  CustomerTravellerRemoved,
  CustomerTravellerRemoveError,
};
