/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AirpayNotifyRequest } from '../model/airpayNotifyRequest';
import { CalculatorReq } from '../model/calculatorReq';
import { CalculatorRes } from '../model/calculatorRes';
import { CardSubscriptionReq } from '../model/cardSubscriptionReq';
import { CardSubscriptionRes } from '../model/cardSubscriptionRes';
import { IPNResponse } from '../model/iPNResponse';
import { OtaResultOfVoucher } from '../model/otaResultOfVoucher';
import { OtaResultOfstring } from '../model/otaResultOfstring';
import { PageOfPromotionDTO } from '../model/pageOfPromotionDTO';
import { PaymentBookingReq } from '../model/paymentBookingReq';
import { PaymentBookingReqV2 } from '../model/paymentBookingReqV2';
import { PaymentBookingRes } from '../model/paymentBookingRes';
import { PaymentFeeOptionReq } from '../model/paymentFeeOptionReq';
import { PaymentFeeOptionRes } from '../model/paymentFeeOptionRes';
import { PromotionDiscountReq } from '../model/promotionDiscountReq';
import { PromotionDiscountRes } from '../model/promotionDiscountRes';
import { PromotionRedemptionReq } from '../model/promotionRedemptionReq';
import { PromotionRedemptionRes } from '../model/promotionRedemptionRes';
import { ResponseEntity } from '../model/responseEntity';
import { VoidPromotionReq } from '../model/voidPromotionReq';
import { VoidPromotionRes } from '../model/voidPromotionRes';
import { VoidVoucherReq } from '../model/voidVoucherReq';
import { VoucherRedemptionReq } from '../model/voucherRedemptionReq';
import { VoucherRedemptionRes } from '../model/voucherRedemptionRes';
import { VoucherValidationReq } from '../model/voucherValidationReq';
import { VoucherValidationRes } from '../model/voucherValidationRes';
import { ZaloCallBackRequest } from '../model/zaloCallBackRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PaymentResourcesService {

    protected basePath = 'https://10.7.15.161:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * confirmMoMo
     *
     * @param partnerCode partnerCode
     * @param orderId orderId
     * @param orderInfo orderInfo
     * @param accessKey accessKey
     * @param amount amount
     * @param signature signature
     * @param extraData extraData
     * @param requestId requestId
     * @param errorCode errorCode
     * @param message message
     * @param localMessage localMessage
     * @param transId transId
     * @param orderType orderType
     * @param payType payType
     * @param responseTime responseTime
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmMoMoUsingPOST(partnerCode?: string, orderId?: string, orderInfo?: string, accessKey?: string, amount?: string, signature?: string, extraData?: string, requestId?: string, errorCode?: number, message?: string, localMessage?: string, transId?: string, orderType?: string, payType?: string, responseTime?: string, observe?: 'body', reportProgress?: boolean): Observable<IPNResponse>;
    public confirmMoMoUsingPOST(partnerCode?: string, orderId?: string, orderInfo?: string, accessKey?: string, amount?: string, signature?: string, extraData?: string, requestId?: string, errorCode?: number, message?: string, localMessage?: string, transId?: string, orderType?: string, payType?: string, responseTime?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IPNResponse>>;
    public confirmMoMoUsingPOST(partnerCode?: string, orderId?: string, orderInfo?: string, accessKey?: string, amount?: string, signature?: string, extraData?: string, requestId?: string, errorCode?: number, message?: string, localMessage?: string, transId?: string, orderType?: string, payType?: string, responseTime?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IPNResponse>>;
    public confirmMoMoUsingPOST(partnerCode?: string, orderId?: string, orderInfo?: string, accessKey?: string, amount?: string, signature?: string, extraData?: string, requestId?: string, errorCode?: number, message?: string, localMessage?: string, transId?: string, orderType?: string, payType?: string, responseTime?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (partnerCode !== undefined && partnerCode !== null) {
            queryParameters = queryParameters.set('partnerCode', <any>partnerCode);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (orderInfo !== undefined && orderInfo !== null) {
            queryParameters = queryParameters.set('orderInfo', <any>orderInfo);
        }
        if (accessKey !== undefined && accessKey !== null) {
            queryParameters = queryParameters.set('accessKey', <any>accessKey);
        }
        if (amount !== undefined && amount !== null) {
            queryParameters = queryParameters.set('amount', <any>amount);
        }
        if (signature !== undefined && signature !== null) {
            queryParameters = queryParameters.set('signature', <any>signature);
        }
        if (extraData !== undefined && extraData !== null) {
            queryParameters = queryParameters.set('extraData', <any>extraData);
        }
        if (requestId !== undefined && requestId !== null) {
            queryParameters = queryParameters.set('requestId', <any>requestId);
        }
        if (errorCode !== undefined && errorCode !== null) {
            queryParameters = queryParameters.set('errorCode', <any>errorCode);
        }
        if (message !== undefined && message !== null) {
            queryParameters = queryParameters.set('message', <any>message);
        }
        if (localMessage !== undefined && localMessage !== null) {
            queryParameters = queryParameters.set('localMessage', <any>localMessage);
        }
        if (transId !== undefined && transId !== null) {
            queryParameters = queryParameters.set('transId', <any>transId);
        }
        if (orderType !== undefined && orderType !== null) {
            queryParameters = queryParameters.set('orderType', <any>orderType);
        }
        if (payType !== undefined && payType !== null) {
            queryParameters = queryParameters.set('payType', <any>payType);
        }
        if (responseTime !== undefined && responseTime !== null) {
            queryParameters = queryParameters.set('responseTime', <any>responseTime);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<IPNResponse>(`${this.basePath}/api/payment/momo-notify`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * confirmPayoo
     *
     * @param notifyData NotifyData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmPayooUsingPOST(notifyData?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public confirmPayooUsingPOST(notifyData?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public confirmPayooUsingPOST(notifyData?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public confirmPayooUsingPOST(notifyData?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (notifyData !== undefined && notifyData !== null) {
            queryParameters = queryParameters.set('NotifyData', <any>notifyData);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<string>(`${this.basePath}/api/payment/payoo-notify`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * confirmZalo
     *
     * @param jsonStr jsonStr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmZaloUsingPOST(jsonStr: ZaloCallBackRequest, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public confirmZaloUsingPOST(jsonStr: ZaloCallBackRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public confirmZaloUsingPOST(jsonStr: ZaloCallBackRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public confirmZaloUsingPOST(jsonStr: ZaloCallBackRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (jsonStr === null || jsonStr === undefined) {
            throw new Error('Required parameter jsonStr was null or undefined when calling confirmZaloUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/payment/zalo-notify`,
            jsonStr,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createCardSubscription
     *
     * @param cardSubscriptionReq cardSubscriptionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCardSubscriptionUsingPOST(cardSubscriptionReq: CardSubscriptionReq, observe?: 'body', reportProgress?: boolean): Observable<CardSubscriptionRes>;
    public createCardSubscriptionUsingPOST(cardSubscriptionReq: CardSubscriptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CardSubscriptionRes>>;
    public createCardSubscriptionUsingPOST(cardSubscriptionReq: CardSubscriptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CardSubscriptionRes>>;
    public createCardSubscriptionUsingPOST(cardSubscriptionReq: CardSubscriptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cardSubscriptionReq === null || cardSubscriptionReq === undefined) {
            throw new Error('Required parameter cardSubscriptionReq was null or undefined when calling createCardSubscriptionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CardSubscriptionRes>(`${this.basePath}/api/payment/card-subscription`,
            cardSubscriptionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * discountPromotion
     *
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public discountPromotionUsingPOST(request: PromotionDiscountReq, observe?: 'body', reportProgress?: boolean): Observable<PromotionDiscountRes>;
    public discountPromotionUsingPOST(request: PromotionDiscountReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionDiscountRes>>;
    public discountPromotionUsingPOST(request: PromotionDiscountReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionDiscountRes>>;
    public discountPromotionUsingPOST(request: PromotionDiscountReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling discountPromotionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PromotionDiscountRes>(`${this.basePath}/api/payments/promotion/discount`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAirDebitPaymentOptions
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAirDebitPaymentOptionsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getAirDebitPaymentOptionsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getAirDebitPaymentOptionsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getAirDebitPaymentOptionsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/air-tickets/payment-debit-options`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAvailablePaymentType
     *
     * @param payOption payOption
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvailablePaymentTypeUsingGET(payOption?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getAvailablePaymentTypeUsingGET(payOption?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getAvailablePaymentTypeUsingGET(payOption?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getAvailablePaymentTypeUsingGET(payOption?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (payOption !== undefined && payOption !== null) {
            queryParameters = queryParameters.set('payOption', <any>payOption);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/payment/get-available-payment-type`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getLoanCalculatorKredivo
     *
     * @param calculatorReq calculatorReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLoanCalculatorKredivoUsingPOST(calculatorReq: CalculatorReq, observe?: 'body', reportProgress?: boolean): Observable<CalculatorRes>;
    public getLoanCalculatorKredivoUsingPOST(calculatorReq: CalculatorReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CalculatorRes>>;
    public getLoanCalculatorKredivoUsingPOST(calculatorReq: CalculatorReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CalculatorRes>>;
    public getLoanCalculatorKredivoUsingPOST(calculatorReq: CalculatorReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (calculatorReq === null || calculatorReq === undefined) {
            throw new Error('Required parameter calculatorReq was null or undefined when calling getLoanCalculatorKredivoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CalculatorRes>(`${this.basePath}/api/payments/kredivo/get-loan-calculator`,
            calculatorReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPaymentFeeOptions
     *
     * @param paymentFeeOptionReq paymentFeeOptionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentFeeOptionsUsingPOST(paymentFeeOptionReq: PaymentFeeOptionReq, observe?: 'body', reportProgress?: boolean): Observable<PaymentFeeOptionRes>;
    public getPaymentFeeOptionsUsingPOST(paymentFeeOptionReq: PaymentFeeOptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentFeeOptionRes>>;
    public getPaymentFeeOptionsUsingPOST(paymentFeeOptionReq: PaymentFeeOptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentFeeOptionRes>>;
    public getPaymentFeeOptionsUsingPOST(paymentFeeOptionReq: PaymentFeeOptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentFeeOptionReq === null || paymentFeeOptionReq === undefined) {
            throw new Error('Required parameter paymentFeeOptionReq was null or undefined when calling getPaymentFeeOptionsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PaymentFeeOptionRes>(`${this.basePath}/api/payments/payment-fee-options`,
            paymentFeeOptionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * notifyAirpay
     *
     * @param airpayNotifyRequest airpayNotifyRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notifyAirpayUsingPOST(airpayNotifyRequest: AirpayNotifyRequest, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public notifyAirpayUsingPOST(airpayNotifyRequest: AirpayNotifyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public notifyAirpayUsingPOST(airpayNotifyRequest: AirpayNotifyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public notifyAirpayUsingPOST(airpayNotifyRequest: AirpayNotifyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (airpayNotifyRequest === null || airpayNotifyRequest === undefined) {
            throw new Error('Required parameter airpayNotifyRequest was null or undefined when calling notifyAirpayUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/api/payment/airpay-notify`,
            airpayNotifyRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payByQrCodeProcessOrder
     *
     * @param vnpTmnCode vnp_TmnCode
     * @param vnpAmount vnp_Amount
     * @param vnpBankCode vnp_BankCode
     * @param vnpBankTranNo vnp_BankTranNo
     * @param vnpCardType vnp_CardType
     * @param vnpPayDate vnp_PayDate
     * @param vnpCurrCode vnp_CurrCode
     * @param vnpOrderInfo vnp_OrderInfo
     * @param vnpTransactionNo vnp_TransactionNo
     * @param vnpResponseCode vnp_ResponseCode
     * @param vnpTxnRef vnp_TxnRef
     * @param vnpSecureHashType vnp_SecureHashType
     * @param vnpSecureHash vnp_SecureHash
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payByQrCodeProcessOrderUsingGET(vnpTmnCode?: string, vnpAmount?: number, vnpBankCode?: string, vnpBankTranNo?: string, vnpCardType?: string, vnpPayDate?: number, vnpCurrCode?: string, vnpOrderInfo?: string, vnpTransactionNo?: number, vnpResponseCode?: string, vnpTxnRef?: string, vnpSecureHashType?: string, vnpSecureHash?: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public payByQrCodeProcessOrderUsingGET(vnpTmnCode?: string, vnpAmount?: number, vnpBankCode?: string, vnpBankTranNo?: string, vnpCardType?: string, vnpPayDate?: number, vnpCurrCode?: string, vnpOrderInfo?: string, vnpTransactionNo?: number, vnpResponseCode?: string, vnpTxnRef?: string, vnpSecureHashType?: string, vnpSecureHash?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public payByQrCodeProcessOrderUsingGET(vnpTmnCode?: string, vnpAmount?: number, vnpBankCode?: string, vnpBankTranNo?: string, vnpCardType?: string, vnpPayDate?: number, vnpCurrCode?: string, vnpOrderInfo?: string, vnpTransactionNo?: number, vnpResponseCode?: string, vnpTxnRef?: string, vnpSecureHashType?: string, vnpSecureHash?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public payByQrCodeProcessOrderUsingGET(vnpTmnCode?: string, vnpAmount?: number, vnpBankCode?: string, vnpBankTranNo?: string, vnpCardType?: string, vnpPayDate?: number, vnpCurrCode?: string, vnpOrderInfo?: string, vnpTransactionNo?: number, vnpResponseCode?: string, vnpTxnRef?: string, vnpSecureHashType?: string, vnpSecureHash?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {














        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (vnpTmnCode !== undefined && vnpTmnCode !== null) {
            queryParameters = queryParameters.set('vnp_TmnCode', <any>vnpTmnCode);
        }
        if (vnpAmount !== undefined && vnpAmount !== null) {
            queryParameters = queryParameters.set('vnp_Amount', <any>vnpAmount);
        }
        if (vnpBankCode !== undefined && vnpBankCode !== null) {
            queryParameters = queryParameters.set('vnp_BankCode', <any>vnpBankCode);
        }
        if (vnpBankTranNo !== undefined && vnpBankTranNo !== null) {
            queryParameters = queryParameters.set('vnp_BankTranNo', <any>vnpBankTranNo);
        }
        if (vnpCardType !== undefined && vnpCardType !== null) {
            queryParameters = queryParameters.set('vnp_CardType', <any>vnpCardType);
        }
        if (vnpPayDate !== undefined && vnpPayDate !== null) {
            queryParameters = queryParameters.set('vnp_PayDate', <any>vnpPayDate);
        }
        if (vnpCurrCode !== undefined && vnpCurrCode !== null) {
            queryParameters = queryParameters.set('vnp_CurrCode', <any>vnpCurrCode);
        }
        if (vnpOrderInfo !== undefined && vnpOrderInfo !== null) {
            queryParameters = queryParameters.set('vnp_OrderInfo', <any>vnpOrderInfo);
        }
        if (vnpTransactionNo !== undefined && vnpTransactionNo !== null) {
            queryParameters = queryParameters.set('vnp_TransactionNo', <any>vnpTransactionNo);
        }
        if (vnpResponseCode !== undefined && vnpResponseCode !== null) {
            queryParameters = queryParameters.set('vnp_ResponseCode', <any>vnpResponseCode);
        }
        if (vnpTxnRef !== undefined && vnpTxnRef !== null) {
            queryParameters = queryParameters.set('vnp_TxnRef', <any>vnpTxnRef);
        }
        if (vnpSecureHashType !== undefined && vnpSecureHashType !== null) {
            queryParameters = queryParameters.set('vnp_SecureHashType', <any>vnpSecureHashType);
        }
        if (vnpSecureHash !== undefined && vnpSecureHash !== null) {
            queryParameters = queryParameters.set('vnp_SecureHash', <any>vnpSecureHash);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<{ [key: string]: string; }>(`${this.basePath}/api/payment/pay-by-qrcode/process-order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * paymentBooking
     *
     * @param paymentBookingReq paymentBookingReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentBookingUsingPOST1(paymentBookingReq: PaymentBookingReq, observe?: 'body', reportProgress?: boolean): Observable<PaymentBookingRes>;
    public paymentBookingUsingPOST1(paymentBookingReq: PaymentBookingReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentBookingRes>>;
    public paymentBookingUsingPOST1(paymentBookingReq: PaymentBookingReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentBookingRes>>;
    public paymentBookingUsingPOST1(paymentBookingReq: PaymentBookingReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentBookingReq === null || paymentBookingReq === undefined) {
            throw new Error('Required parameter paymentBookingReq was null or undefined when calling paymentBookingUsingPOST1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PaymentBookingRes>(`${this.basePath}/api/air-tickets/payment-booking`,
            paymentBookingReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * paymentBooking
     *
     * @param paymentBookingReq paymentBookingReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentBookingUsingPOST2(paymentBookingReq: PaymentBookingReq, observe?: 'body', reportProgress?: boolean): Observable<PaymentBookingRes>;
    public paymentBookingUsingPOST2(paymentBookingReq: PaymentBookingReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentBookingRes>>;
    public paymentBookingUsingPOST2(paymentBookingReq: PaymentBookingReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentBookingRes>>;
    public paymentBookingUsingPOST2(paymentBookingReq: PaymentBookingReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentBookingReq === null || paymentBookingReq === undefined) {
            throw new Error('Required parameter paymentBookingReq was null or undefined when calling paymentBookingUsingPOST2.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PaymentBookingRes>(`${this.basePath}/api/hotel/payment-booking`,
            paymentBookingReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * paymentBooking
     *
     * @param paymentBookingReq paymentBookingReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentBookingUsingPOST3(paymentBookingReq: PaymentBookingReq, observe?: 'body', reportProgress?: boolean): Observable<PaymentBookingRes>;
    public paymentBookingUsingPOST3(paymentBookingReq: PaymentBookingReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentBookingRes>>;
    public paymentBookingUsingPOST3(paymentBookingReq: PaymentBookingReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentBookingRes>>;
    public paymentBookingUsingPOST3(paymentBookingReq: PaymentBookingReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentBookingReq === null || paymentBookingReq === undefined) {
            throw new Error('Required parameter paymentBookingReq was null or undefined when calling paymentBookingUsingPOST3.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PaymentBookingRes>(`${this.basePath}/api/payments/payment-booking`,
            paymentBookingReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * paymentBookingV2
     *
     * @param paymentBookingReqV2 paymentBookingReqV2
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentBookingV2UsingPOST(paymentBookingReqV2: PaymentBookingReqV2, observe?: 'body', reportProgress?: boolean): Observable<PaymentBookingRes>;
    public paymentBookingV2UsingPOST(paymentBookingReqV2: PaymentBookingReqV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentBookingRes>>;
    public paymentBookingV2UsingPOST(paymentBookingReqV2: PaymentBookingReqV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentBookingRes>>;
    public paymentBookingV2UsingPOST(paymentBookingReqV2: PaymentBookingReqV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentBookingReqV2 === null || paymentBookingReqV2 === undefined) {
            throw new Error('Required parameter paymentBookingReqV2 was null or undefined when calling paymentBookingV2UsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PaymentBookingRes>(`${this.basePath}/api/payment/pay`,
            paymentBookingReqV2,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * redeemPartner
     *
     * @param voucherRedemptionReq voucherRedemptionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public redeemPartnerUsingPOST(voucherRedemptionReq: VoucherRedemptionReq, observe?: 'body', reportProgress?: boolean): Observable<VoucherRedemptionRes>;
    public redeemPartnerUsingPOST(voucherRedemptionReq: VoucherRedemptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VoucherRedemptionRes>>;
    public redeemPartnerUsingPOST(voucherRedemptionReq: VoucherRedemptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VoucherRedemptionRes>>;
    public redeemPartnerUsingPOST(voucherRedemptionReq: VoucherRedemptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (voucherRedemptionReq === null || voucherRedemptionReq === undefined) {
            throw new Error('Required parameter voucherRedemptionReq was null or undefined when calling redeemPartnerUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VoucherRedemptionRes>(`${this.basePath}/api/payments/voucher/partner_redeem`,
            voucherRedemptionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * redeemPromotion
     *
     * @param voucherRedemptionReq voucherRedemptionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public redeemPromotionUsingPOST(voucherRedemptionReq: PromotionRedemptionReq, observe?: 'body', reportProgress?: boolean): Observable<PromotionRedemptionRes>;
    public redeemPromotionUsingPOST(voucherRedemptionReq: PromotionRedemptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionRedemptionRes>>;
    public redeemPromotionUsingPOST(voucherRedemptionReq: PromotionRedemptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionRedemptionRes>>;
    public redeemPromotionUsingPOST(voucherRedemptionReq: PromotionRedemptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (voucherRedemptionReq === null || voucherRedemptionReq === undefined) {
            throw new Error('Required parameter voucherRedemptionReq was null or undefined when calling redeemPromotionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PromotionRedemptionRes>(`${this.basePath}/api/payments/promotion/redeem`,
            voucherRedemptionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * redeemVoucher
     *
     * @param voucherRedemptionReq voucherRedemptionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public redeemVoucherUsingPOST(voucherRedemptionReq: VoucherRedemptionReq, observe?: 'body', reportProgress?: boolean): Observable<VoucherRedemptionRes>;
    public redeemVoucherUsingPOST(voucherRedemptionReq: VoucherRedemptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VoucherRedemptionRes>>;
    public redeemVoucherUsingPOST(voucherRedemptionReq: VoucherRedemptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VoucherRedemptionRes>>;
    public redeemVoucherUsingPOST(voucherRedemptionReq: VoucherRedemptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (voucherRedemptionReq === null || voucherRedemptionReq === undefined) {
            throw new Error('Required parameter voucherRedemptionReq was null or undefined when calling redeemVoucherUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VoucherRedemptionRes>(`${this.basePath}/api/payments/voucher/redeem`,
            voucherRedemptionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjects
     *
     * @param name name
     * @param saleChannel saleChannel
     * @param productType productType
     * @param airlinesCode airlinesCode
     * @param cardNumberPrefix cardNumberPrefix
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectsUsingGET(name?: string, saleChannel?: string, productType?: string, airlinesCode?: string, cardNumberPrefix?: string, observe?: 'body', reportProgress?: boolean): Observable<PageOfPromotionDTO>;
    public searchObjectsUsingGET(name?: string, saleChannel?: string, productType?: string, airlinesCode?: string, cardNumberPrefix?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfPromotionDTO>>;
    public searchObjectsUsingGET(name?: string, saleChannel?: string, productType?: string, airlinesCode?: string, cardNumberPrefix?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfPromotionDTO>>;
    public searchObjectsUsingGET(name?: string, saleChannel?: string, productType?: string, airlinesCode?: string, cardNumberPrefix?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (saleChannel !== undefined && saleChannel !== null) {
            queryParameters = queryParameters.set('saleChannel', <any>saleChannel);
        }
        if (productType !== undefined && productType !== null) {
            queryParameters = queryParameters.set('productType', <any>productType);
        }
        if (airlinesCode !== undefined && airlinesCode !== null) {
            queryParameters = queryParameters.set('airlinesCode', <any>airlinesCode);
        }
        if (cardNumberPrefix !== undefined && cardNumberPrefix !== null) {
            queryParameters = queryParameters.set('cardNumberPrefix', <any>cardNumberPrefix);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PageOfPromotionDTO>(`${this.basePath}/api/_search/promotion-credit-card`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * trackingVoucher
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trackingVoucherUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfVoucher>;
    public trackingVoucherUsingGET(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfVoucher>>;
    public trackingVoucherUsingGET(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfVoucher>>;
    public trackingVoucherUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling trackingVoucherUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfVoucher>(`${this.basePath}/api/payments/voucher/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateVoucher
     *
     * @param voucherValidationReq voucherValidationReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateVoucherUsingPOST(voucherValidationReq: VoucherValidationReq, observe?: 'body', reportProgress?: boolean): Observable<VoucherValidationRes>;
    public validateVoucherUsingPOST(voucherValidationReq: VoucherValidationReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VoucherValidationRes>>;
    public validateVoucherUsingPOST(voucherValidationReq: VoucherValidationReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VoucherValidationRes>>;
    public validateVoucherUsingPOST(voucherValidationReq: VoucherValidationReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (voucherValidationReq === null || voucherValidationReq === undefined) {
            throw new Error('Required parameter voucherValidationReq was null or undefined when calling validateVoucherUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VoucherValidationRes>(`${this.basePath}/api/payments/voucher/validate`,
            voucherValidationReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * voidPromotion
     *
     * @param voidPromotionReq voidPromotionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public voidPromotionUsingPOST(voidPromotionReq: VoidPromotionReq, observe?: 'body', reportProgress?: boolean): Observable<VoidPromotionRes>;
    public voidPromotionUsingPOST(voidPromotionReq: VoidPromotionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VoidPromotionRes>>;
    public voidPromotionUsingPOST(voidPromotionReq: VoidPromotionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VoidPromotionRes>>;
    public voidPromotionUsingPOST(voidPromotionReq: VoidPromotionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (voidPromotionReq === null || voidPromotionReq === undefined) {
            throw new Error('Required parameter voidPromotionReq was null or undefined when calling voidPromotionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VoidPromotionRes>(`${this.basePath}/api/payments/promotion/void`,
            voidPromotionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * voidVoucher
     *
     * @param voidVoucherReq voidVoucherReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public voidVoucherUsingPOST(voidVoucherReq: VoidVoucherReq, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public voidVoucherUsingPOST(voidVoucherReq: VoidVoucherReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public voidVoucherUsingPOST(voidVoucherReq: VoidVoucherReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public voidVoucherUsingPOST(voidVoucherReq: VoidVoucherReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (voidVoucherReq === null || voidVoucherReq === undefined) {
            throw new Error('Required parameter voidVoucherReq was null or undefined when calling voidVoucherUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfstring>(`${this.basePath}/api/payments/voucher/void`,
            voidVoucherReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
