import {
  AuthenticateAction,
  AuthenticateActionTypes
} from './authenticate.actions';
import {User} from "../models/user";

export const AUTHENTICATE_FEATURE_KEY = 'authenticate';

/**
 * Interface for the 'Authenticate' data used in
 *  - AuthenticateState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface AuthenticateState {
  isAuthenticated: boolean;
  user?: User | null;
  loaded: boolean;
  errorCode: string | null;
  errorMessage: string | null;
}

export interface AuthenticatePartialState {
  readonly [AUTHENTICATE_FEATURE_KEY]: AuthenticateState;
}

export const initialState: AuthenticateState = {
  isAuthenticated: null,
  loaded: false,
  user: null,
  errorCode: null,
  errorMessage: null
};

export function reducer(
  state: AuthenticateState = initialState,
  action: AuthenticateAction
): AuthenticateState {
  switch (action.type) {
    case AuthenticateActionTypes.LOGIN: {
      return  initialState;
      break;
    }
    case AuthenticateActionTypes.LOGIN_SUCCESS: {
      state = {
        ...state,
        isAuthenticated: true,
        user: {
          token: action.payload.token.id_token,
          username: action.payload.data.username,
          remember: action.payload.data.rememberMe
        },
        loaded: true,
        errorMessage: null
      };
      break;
    }
    case AuthenticateActionTypes.LOGIN_FAILURE: {
      state = {
        ...state,
        isAuthenticated: false,
        errorMessage: action.payload.error.error? action.payload.error.error.message: action.payload.error.message,
        errorCode: action.payload.error.error? action.payload.error.error.code: action.payload.error.status,
        loaded: true
      };
      break;
    }
    case AuthenticateActionTypes.RESETALL: {
      state = initialState;
      break;
    }
    case AuthenticateActionTypes.LOGOUT: {
      return initialState;
      break;
    }
  }
  return state;
}
