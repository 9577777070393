/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Info } from './info';


export interface PromotionRedemptionRes { 
    bookingCode?: string;
    bookingNumber?: string;
    discountAmount?: number;
    discountCampaignName?: string;
    duration?: number;
    errorCode?: string;
    errors?: Array<Error>;
    infos?: Array<Info>;
    isSuccess?: boolean;
    metaData?: { [key: string]: any; };
    promotionCode?: string;
    promotionId?: string;
    promotionType?: PromotionRedemptionRes.PromotionTypeEnum;
    redeemCode?: string;
    redeemId?: string;
    redeemValid?: boolean;
    redeemVoucherId?: string;
    success?: boolean;
    textMessage?: string;
    trackingCode?: string;
}
export namespace PromotionRedemptionRes {
    export type PromotionTypeEnum = 'CREDIT_CARD' | 'AIRLINES_CODE' | 'VOUCHER' | 'NORMAL';
    export const PromotionTypeEnum = {
        CREDITCARD: 'CREDIT_CARD' as PromotionTypeEnum,
        AIRLINESCODE: 'AIRLINES_CODE' as PromotionTypeEnum,
        VOUCHER: 'VOUCHER' as PromotionTypeEnum,
        NORMAL: 'NORMAL' as PromotionTypeEnum
    };
}
