import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthB2bComponent, SafeHtmlPipe} from './auth-b2b/auth-b2b.component';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTabsModule
} from "@angular/material";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DialogMessageModule} from "@gtd/components/dialog-message";
import {SnackbarModule} from "@gtd/components/snackbar";
import {TranslateModule} from "@ngx-translate/core";
import {AuthB2bModuleRouting} from "./auth-b2b.module.routing";
import {LoginComponent} from "./auth-b2b/login/login.component";
import {RegisterAgentComponent} from "./auth-b2b/register-agent/register-agent.component";
import {LookupB2bModule} from "@gtd/meta";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {
  AccountResourceService,
  AgencyResourceService,
  BASE_PATH,
  UserJwtControllerService
} from "@gtd/api-services/b2b-client";
import {AuthenticateService} from "./auth-b2b/authenticate/authenticate.service";
import {environment} from "@envB2b/environment";
import {RequestPasswordComponent} from './auth-b2b/request-password/request-password.component';
import {RegisterCaComponent} from './auth-b2b/register-ca/register-ca.component';
import {AgentsrvModule} from '@gtd/api-services/agentsrv';
import {RegisterComponent} from "./auth-b2b/register/register.component";
import {SwiperModule} from "ngx-swiper-wrapper";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import {GetPageBySlugService} from "@gtd/api-services/contentsrv";
import {TemplateService} from "@gtd/services";
import {B2cClientModule} from "@gtd/b2c-client";

library.add(faAngleLeft);

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        AuthB2bModuleRouting,
        MatButtonModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatDividerModule,
        DialogMessageModule,
        SnackbarModule,
        MatSnackBarModule,
        MatTabsModule,
        MatSelectModule,
        TranslateModule,
        MatDatepickerModule,
        MatRadioModule,
        LookupB2bModule,
        NgxMatSelectSearchModule,
        AgentsrvModule,
        SwiperModule,
        FlexModule,
        FlexLayoutModule,
      B2cClientModule
    ],
  declarations: [
    AuthB2bComponent,
    LoginComponent,
    RegisterComponent,
    RequestPasswordComponent,
    RegisterCaComponent,
    RegisterAgentComponent,
    SafeHtmlPipe
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.b2bSite },
    AgencyResourceService,
    AuthenticateService,
    UserJwtControllerService,
    AccountResourceService,
    GetPageBySlugService,
    TemplateService
  ]
})
export class AuthB2bModule {}
