import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourPriceDetailComponent } from './tour-price-detail.component';
import { TourStoreModule } from '@gtd/extra/tour/data-access';
import { MatCardModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    TourStoreModule,
    MatCardModule,
    TranslateModule,
    FlexLayoutModule
  ],
  declarations: [TourPriceDetailComponent],
  exports: [TourPriceDetailComponent]
})
export class TourPriceDetailModule {}
