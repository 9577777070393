import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {FarerulesPartialState} from './farerules.reducer';
import {FarerulesActionTypes, FarerulesLoadError, LoadFarerules} from './farerules.actions';
import {map} from "rxjs/operators";
import {AirTicketsResourceService} from "@gtd/b2c-client";

@Injectable()
export class FarerulesEffects {
  @Effect() loadFarerules$ = this.dataPersistence.fetch(
    FarerulesActionTypes.LoadFarerules,
    {
      run: (action: LoadFarerules, state: FarerulesPartialState) => {
        return this.airTicketsResourceService.retrieveFareRulesUsingPOST(
          action.payload,
          action.language
        ).pipe(
          map(update => ({
            type: FarerulesActionTypes.FarerulesLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadFarerules, error) => {
        console.error('Error', error);
        return new FarerulesLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private airTicketsResourceService: AirTicketsResourceService,
    private dataPersistence: DataPersistence<FarerulesPartialState>
  ) {}
}
