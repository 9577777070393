import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitize'
})
export class SanitizePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
}

@Pipe({ name: 'addressPipe' })
export class AddressPipe {
  transform(address: any): string {
    const { lineOne, lineTow, city } = address;
    return [lineOne, lineTow, city].filter(Boolean).join(', ');
  }
}
