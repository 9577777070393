import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  OnDestroy
} from '@angular/core';
import { LowestPriceFlightsFacade } from '../flight-search/flight-lowest-price/+state/lowest-price-flights.facade';
import { FlightFilterFacade } from '../flight-search/flight-filter/+state/flight-filter.facade';
import { fadeIn } from '@gtd/helpers';
import { environment } from '@env/environment';
import { AvailableFlightsFacade } from '../flight-search/flight-cache-search/+state/available-flights.facade';
import { Subscription } from 'rxjs/Rx';

@Component({
  selector: 'gtd-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated,
  animations: [fadeIn]
})
export class FilterPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disableAirline: boolean;
  @Input() isInternationalReturnFlight: boolean;

  conditionTypes: BranchFilter[] = [
    {
      branchCode: 'true',
      branchName: 'allowed-refund-cancellation',
      checked: false
    },
    {
      branchCode: 'false',
      branchName: 'no-refunds-cancellations',
      checked: false
    }
  ];

  timeFilterFrom: BranchFilter[] = [
    { branchCode: '-6', branchName: '00:00 - 06:00', checked: false },
    { branchCode: '+6-12', branchName: '06:00 - 12:00', checked: false },
    { branchCode: '+12-18', branchName: '12:00 - 18:00', checked: false },
    { branchCode: '+18', branchName: '18:00 - 24:00', checked: false }
  ];
  timeFilterTo: BranchFilter[] = [
    { branchCode: '-6', branchName: '00:00 - 06:00', checked: false },
    { branchCode: '+6-12', branchName: '06:00 - 12:00', checked: false },
    { branchCode: '+12-18', branchName: '12:00 - 18:00', checked: false },
    { branchCode: '+18', branchName: '18:00 - 24:00', checked: false }
  ];

  allLowestPriceFlights$ = this.lowestPriceFlightsFacade.allLowestPriceFlights$;
  loaded$ = this.lowestPriceFlightsFacade.loaded$;
  selectedAirlineOptions$ = this.flightFilterFacade.selectedAirlineOptions$;
  selectedCabinClassOptions$ = this.flightFilterFacade
    .selectedCabinClassOptions$;
  // selectedTicketPolicyOptions$ = this.flightFilterFacade.selectedTicketPolicyOptions$;
  selectedArrivalDateTimeReturnOptions$ = this.flightFilterFacade
    .selectedArrivalDateTimeReturnOptions$;
  selectedArrivalDateTimeOptions$ = this.flightFilterFacade
    .selectedArrivalDateTimeOptions$;
  airlineOptions: BranchFilter[] = [];
  cabinClassOptions: BranchFilter[] = [];
  private subscription: Subscription = new Subscription();

  constructor(
    private lowestPriceFlightsFacade: LowestPriceFlightsFacade,
    private flightFilterFacade: FlightFilterFacade,
    private availableFlightsFacade: AvailableFlightsFacade
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // Handling to get airline and cabin class for international return flight.
    if (
      changes.isInternationalReturnFlight !== undefined &&
      changes.isInternationalReturnFlight.currentValue === true
    ) {
      this.subscription.add(
        this.availableFlightsFacade.allAvailableFlights$.subscribe(data => {
          if (data.groupPricedItineraries) {
            const lowestPrices = [];
            this.cabinClassOptions = [];
            this.airlineOptions = [];
            data.groupPricedItineraries.forEach((item, index) => {
              lowestPrices.push(
                item.pricedItineraries[0].airItineraryPricingInfo.adultFare
                  .passengerFare.totalFare.amount
              );
              if (index === 0) {
                this.cabinClassOptions.push({
                  branchCode: item.pricedItineraries[0].cabinClassName,
                  branchName: item.pricedItineraries[0].cabinClassName.toLowerCase(),
                  checked: false
                });
              } else {
                for (let i = 0; i < this.cabinClassOptions.length; i++) {
                  if (
                    item.pricedItineraries[0].cabinClassName ===
                    this.cabinClassOptions[i].branchCode
                  )
                    break;
                  if (i === this.cabinClassOptions.length - 1) {
                    this.cabinClassOptions.push({
                      branchCode: item.pricedItineraries[0].cabinClassName,
                      branchName: item.pricedItineraries[0].cabinClassName.toLowerCase(),
                      checked: false
                    });
                  }
                }
              }
            });
            lowestPrices.sort((a, b) => a - b);

            this.airlineOptions.push({
              branchCode: data.groupPricedItineraries[0].airline,
              branchName: data.groupPricedItineraries[0].airlineName,
              lowestPrices: lowestPrices[0],
              checked: false
            });
          }
        })
      );
    }
  }

  onAirlineOptionsChanged(event: string[], menuTriggerAirlines) {
    menuTriggerAirlines.closeMenu();
    this.flightFilterFacade.changeAirlineOptions(event);
    if (this.isInternationalReturnFlight === true) {
      this.subscription.unsubscribe();
    }
  }

  onCabinClassOptionsChanged(event: string[], menuTriggerCabinClass) {
    menuTriggerCabinClass.closeMenu();
    this.flightFilterFacade.changeCabinClassOptions(event);
    if (this.isInternationalReturnFlight === true) {
      this.subscription.unsubscribe();
    }
  }

  onTicketPolicyOptionsChanged(event: string[], menuTriggerTicketPolicy) {
    menuTriggerTicketPolicy.closeMenu();
    this.flightFilterFacade.changeTicketPolicyOptions(event);
  }

  onArrivalDateTimeOptions(event: string[], menuTriggerTimeFrom) {
    menuTriggerTimeFrom.closeMenu();
    this.flightFilterFacade.changeDepartureDateTimeOptions(event);
  }

  onArrivalDateTimeReturnOptions(event: string[], menuTriggerTimeTo) {
    menuTriggerTimeTo.closeMenu();
    this.flightFilterFacade.changeArrivalDateTimeReturnOptions(event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
export interface BranchFilter {
  brandLogo?: string;
  lowestPrices?: number;
  branchCode?: string;
  branchName?: string;
  checked: boolean;
}
