import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTFILTER_FEATURE_KEY,
  FlightFilterState
} from './flight-filter.reducer';

// Lookup the 'FlightFilter' feature state managed by NgRx
const getFlightFilterState = createFeatureSelector<FlightFilterState>(
  FLIGHTFILTER_FEATURE_KEY
);

const getAllCurrentFilter = createSelector(
  getFlightFilterState,
  (state: FlightFilterState) => state
);

const getCurrentAirlineOptions = createSelector(
  getFlightFilterState,
  (state: FlightFilterState) => state.airlineOptions
);

const getCurrentTicketPolicyOptions = createSelector(
  getFlightFilterState,
  (state: FlightFilterState) => state.ticketPolicyOptions
);

const getCurrentCabinClassOptions = createSelector(
  getAllCurrentFilter,
  (state: FlightFilterState) => state.cabinClassOptions
);

const getCurrentSort = createSelector(
  getFlightFilterState,
  (state: FlightFilterState) => state.sort
);

const getCurrentArrivalDateTimeReturnOptions = createSelector(
  getFlightFilterState,
  (state: FlightFilterState) => state.arrivalDateTimeReturnOptions
);

const getCurrentArrivalDateTimeOptions = createSelector(
  getFlightFilterState,
  (state: FlightFilterState) => state.departureDateTimeOptions
);

const getSearchID = createSelector(
  getFlightFilterState,
  (state: FlightFilterState) => state.searchID
);

export const flightFilterQuery = {
  getAllCurrentFilter,
  getCurrentAirlineOptions,
  getCurrentTicketPolicyOptions,
  getCurrentCabinClassOptions,
  getCurrentSort,
  getCurrentArrivalDateTimeOptions,
  getCurrentArrivalDateTimeReturnOptions,
  getSearchID
};
