import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FinalBookingDetailPartialState } from './final-booking-detail.reducer';
import { finalBookingDetailQuery } from './final-booking-detail.selectors';
import { LoadFinalBookingDetail } from './final-booking-detail.actions';

@Injectable()
export class FinalBookingDetailFacade {
  loaded$ = this.store.pipe(select(finalBookingDetailQuery.getLoaded));
  getError$ = this.store.pipe(select(finalBookingDetailQuery.getError));
  allFinalBookingDetail$ = this.store.pipe(
    select(finalBookingDetailQuery.getAllFinalBookingDetail)
  );
  selectedFinalBookingDetail$ = this.store.pipe(
    select(finalBookingDetailQuery.getSelectedFinalBookingDetail)
  );

  constructor(private store: Store<FinalBookingDetailPartialState>) {}

  loadAll(bookingNumber: string) {
    this.store.dispatch(new LoadFinalBookingDetail(bookingNumber));
  }
}
