import {Directive, Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Directive({
  selector: '[gtdDateFormat]'
})
export class DateFormatDirective {

  constructor() { }

}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const I18N_VALUES = {
  en: {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  vi: {
    weekdays: ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'],
    months: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
  }
};

@Injectable()
export class TranslateDatePicker {
  language = 'vi';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: TranslateDatePicker) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this._i18n && this._i18n.language? I18N_VALUES[this._i18n.language].weekdays[weekday - 1]: I18N_VALUES['vi'].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return this._i18n && this._i18n.language? I18N_VALUES[this._i18n.language].months[month - 1]: I18N_VALUES['vi'].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return date.day + '/' + date.month + '/' + date.year;
  }
}

