import {Action} from '@ngrx/store';
import {CustomerProfileDTO} from "@gtd/api-services/customer-service";

export enum ProfileActionTypes {
  LoadProfile = '[Profile] Load Profile',
  ProfileLoaded = '[Profile] Profile Loaded',
  ProfileLoadError = '[Profile] Profile Load Error',

  //Update profile
  UpdateProfile = '[Profile] Update Profile',
  ProfileUpdated = '[Profile] Profile Updated',
  ProfileUpdateError = '[Profile] Profile Update Error'
}

export class LoadProfile implements Action {
  readonly type = ProfileActionTypes.LoadProfile;
  constructor(public payload: number) {}
}

export class ProfileLoadError implements Action {
  readonly type = ProfileActionTypes.ProfileLoadError;
  constructor(public payload: any) {}
}

export class ProfileLoaded implements Action {
  readonly type = ProfileActionTypes.ProfileLoaded;
  constructor(public payload: CustomerProfileDTO) {}
}

//Update profile
export class UpdateProfile implements Action {
  readonly type = ProfileActionTypes.UpdateProfile;
  constructor(public payload: CustomerProfileDTO) {}
}

export class ProfileUpdated implements Action {
  readonly type = ProfileActionTypes.ProfileUpdated;
  constructor(public payload: CustomerProfileDTO) {}
}

export class ProfileUpdateError implements Action {
  readonly type = ProfileActionTypes.ProfileUpdateError;
  constructor(public payload: any) {}
}


export type ProfileAction =
  LoadProfile
  | ProfileLoaded
  | ProfileLoadError
  | UpdateProfile
  | ProfileUpdated
  | ProfileUpdateError;

export const fromProfileActions = {
  LoadProfile,
  ProfileLoaded,
  ProfileLoadError,
  UpdateProfile,
  ProfileUpdated,
  ProfileUpdateError
};
