import {
  Component,
  Input,
  OnInit,
  Pipe,
  PipeTransform,
  ViewEncapsulation
} from '@angular/core';
import { LowestPriceFlightsFacade } from '../flight-search/flight-lowest-price/+state/lowest-price-flights.facade';
import { FlightFilterFacade } from '../flight-search/flight-filter/+state/flight-filter.facade';
import { fadeIn } from '@gtd/helpers';
import { environment } from '@env/environment';

@Component({
  selector: 'gtd-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated,
  animations: [fadeIn]
})
export class FilterPanelComponent implements OnInit {
  @Input() disableAirline: boolean;

  classTypes: BranchFilter[] = [
    { branchCode: 'PROMO', branchName: 'promo', checked: false },
    { branchCode: 'ECONOMY', branchName: 'economy', checked: false },
    { branchCode: 'PREMIUM', branchName: 'premium', checked: false },
    { branchCode: 'BUSINESS', branchName: 'business', checked: false },
    { branchCode: 'FIRST', branchName: 'first', checked: false }
  ];

  conditionTypes: BranchFilter[] = [
    {
      branchCode: 'true',
      branchName: 'allowed-refund-cancellation',
      checked: false
    },
    {
      branchCode: 'false',
      branchName: 'no-refunds-cancellations',
      checked: false
    }
  ];

  timeFilterFrom: BranchFilter[] = [
    { branchCode: '-6', branchName: '00:00 - 06:00', checked: false },
    { branchCode: '+6-12', branchName: '06:00 - 12:00', checked: false },
    { branchCode: '+12-18', branchName: '12:00 - 18:00', checked: false },
    { branchCode: '+18', branchName: '18:00 - 24:00', checked: false }
  ];
  timeFilterTo: BranchFilter[] = [
    { branchCode: '-6', branchName: '00:00 - 06:00', checked: false },
    { branchCode: '+6-12', branchName: '06:00 - 12:00', checked: false },
    { branchCode: '+12-18', branchName: '12:00 - 18:00', checked: false },
    { branchCode: '+18', branchName: '18:00 - 24:00', checked: false }
  ];

  allLowestPriceFlights$ = this.lowestPriceFlightsFacade.allLowestPriceFlights$;
  loaded$ = this.lowestPriceFlightsFacade.loaded$;
  selectedAirlineOptions$ = this.flightFilterFacade.selectedAirlineOptions$;
  selectedCabinClassOptions$ = this.flightFilterFacade
    .selectedCabinClassOptions$;
  selectedTicketPolicyOptions$ = this.flightFilterFacade
    .selectedTicketPolicyOptions$;
  selectedArrivalDateTimeReturnOptions$ = this.flightFilterFacade
    .selectedArrivalDateTimeReturnOptions$;
  selectedArrivalDateTimeOptions$ = this.flightFilterFacade
    .selectedArrivalDateTimeOptions$;

  constructor(
    private lowestPriceFlightsFacade: LowestPriceFlightsFacade,
    private flightFilterFacade: FlightFilterFacade
  ) {}

  ngOnInit() {}

  onAirlineOptionsChanged(event: string[], menuTriggerAirlines) {
    menuTriggerAirlines.closeMenu();
    this.flightFilterFacade.changeAirlineOptions(event);
  }

  onCabinClassOptionsChanged(event: string[], menuTriggerCabinClass) {
    menuTriggerCabinClass.closeMenu();
    this.flightFilterFacade.changeCabinClassOptions(event);
  }

  onTicketPolicyOptionsChanged(event: string[], menuTriggerTicketPolicy) {
    menuTriggerTicketPolicy.closeMenu();
    this.flightFilterFacade.changeTicketPolicyOptions(event);
  }

  onArrivalDateTimeOptions(event: string[], menuTriggerTimeFrom) {
    menuTriggerTimeFrom.closeMenu();
    this.flightFilterFacade.changeDepartureDateTimeOptions(event);
  }

  onArrivalDateTimeReturnOptions(event: string[], menuTriggerTimeTo) {
    menuTriggerTimeTo.closeMenu();
    this.flightFilterFacade.changeArrivalDateTimeReturnOptions(event);
  }
}
export interface BranchFilter {
  brandLogo?: string;
  lowestPrices?: number;
  branchCode?: string;
  branchName?: string;
  checked: boolean;
}

@Pipe({
  name: 'cabinToBranchMapper'
})
export class CabinToBranchMapperPipe implements PipeTransform {
  transform(value: string[], args?: any): BranchFilter[] {
    const result: BranchFilter[] = value.map(function(item) {
      return {
        branchCode: item,
        branchName: item.toLowerCase(),
        checked: false
      };
    });
    return result;
  }
}
