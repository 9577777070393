/**
 * utilitysrv API
 * utilitysrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface NotifyUserMessageDTO {
    createdBy?: string;
    createdDate?: Date;
    id?: number;
    /**
     * isDeleted
     */
    isDeleted?: boolean;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    messageId?: number;
    status?: NotifyUserMessageDTO.StatusEnum;
    userRefCode?: string;
}
export namespace NotifyUserMessageDTO {
    export type StatusEnum = 'UNREAD' | 'READ';
    export const StatusEnum = {
        UNREAD: 'UNREAD' as StatusEnum,
        READ: 'READ' as StatusEnum
    };
}
