export interface DialogMessage {
  messageType: 'error' | 'success' | 'warning' | 'green';
  heading?: string;
  resend?: boolean;
  dataTranslate?: string;
  content: string;
  icon?:
    | 'check-circle'
    | 'exclamation-triangle'
    | 'thumbs-up'
    | 'check'
    | 'exclamation-circle';
  imgIcon?: string;
  hideCloseBtn?: boolean;
  btn?: Btn[];
  alertType: string;
  description: string;
  alertMessage: string;
}
export class Btn {
  btnColor?: string;
  btnText?: string;
  btnLink?: string;
  btnReload?: boolean;
  action?: string;
}
