/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface RefundBookingDTO { 
    bookingNumber?: string;
    cancellationFee?: number;
    cancellationNotes?: string;
    note?: string;
    partnerOrderId?: string;
    paymentAmount?: number;
    paymentFee?: number;
    paymentRefNumber?: string;
    paymentStatus?: RefundBookingDTO.PaymentStatusEnum;
    paymentType?: RefundBookingDTO.PaymentTypeEnum;
    refundAmount?: number;
}
export namespace RefundBookingDTO {
    export type PaymentStatusEnum = 'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'PENDING';
    export const PaymentStatusEnum = {
        SUCCEEDED: 'SUCCEEDED' as PaymentStatusEnum,
        FAILED: 'FAILED' as PaymentStatusEnum,
        REFUNDED: 'REFUNDED' as PaymentStatusEnum,
        PENDING: 'PENDING' as PaymentStatusEnum
    };
    export type PaymentTypeEnum = 'BALANCE' | 'VNPAY_CREDIT' | 'ATM_DEBIT' | 'KREDIVO' | 'AIRPAY' | 'VNPAYQR' | 'VIETTELPAY' | 'MOMO' | 'ZALO' | 'PAYOO' | 'CASH' | 'TRANSFER' | 'PARTNER' | 'VPBANK' | 'CREDIT' | 'OTHER';
    export const PaymentTypeEnum = {
        BALANCE: 'BALANCE' as PaymentTypeEnum,
        VNPAYCREDIT: 'VNPAY_CREDIT' as PaymentTypeEnum,
        ATMDEBIT: 'ATM_DEBIT' as PaymentTypeEnum,
        KREDIVO: 'KREDIVO' as PaymentTypeEnum,
        AIRPAY: 'AIRPAY' as PaymentTypeEnum,
        VNPAYQR: 'VNPAYQR' as PaymentTypeEnum,
        VIETTELPAY: 'VIETTELPAY' as PaymentTypeEnum,
        MOMO: 'MOMO' as PaymentTypeEnum,
        ZALO: 'ZALO' as PaymentTypeEnum,
        PAYOO: 'PAYOO' as PaymentTypeEnum,
        CASH: 'CASH' as PaymentTypeEnum,
        TRANSFER: 'TRANSFER' as PaymentTypeEnum,
        PARTNER: 'PARTNER' as PaymentTypeEnum,
        VPBANK: 'VPBANK' as PaymentTypeEnum,
        CREDIT: 'CREDIT' as PaymentTypeEnum,
        OTHER: 'OTHER' as PaymentTypeEnum
    };
}
