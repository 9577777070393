import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FARERULES_FEATURE_KEY, FarerulesState } from './farerules.reducer';

// Lookup the 'Farerules' feature state managed by NgRx
const getFarerulesState = createFeatureSelector<FarerulesState>(
  FARERULES_FEATURE_KEY
);

const getLoaded = createSelector(
  getFarerulesState,
  (state: FarerulesState) => state.loaded
);
const getError = createSelector(
  getFarerulesState,
  (state: FarerulesState) => state.error
);

const getAllFarerules = createSelector(
  getFarerulesState,
  getLoaded,
  (state: FarerulesState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getFarerulesState,
  (state: FarerulesState) => state.selectedId
);
const getSelectedFarerules = createSelector(
  getAllFarerules,
  getSelectedId,
  (farerules, id) => {
    return farerules ? Object.assign({}, farerules) : undefined;
  }
);

export const farerulesQuery = {
  getLoaded,
  getError,
  getAllFarerules,
  getSelectedFarerules
};
