import {Action} from '@ngrx/store';
import {UserProfileTO} from "../../lib/index";

export enum GetUserProfileActionTypes {
  LoadGetUserProfile = '[GetUserProfile] Load GetUserProfile',
  DestroyUserProfile = '[GetUserProfile] Destroy UserProfile',
  GetUserProfileLoaded = '[GetUserProfile] GetUserProfile Loaded',
  GetUserProfileLoadError = '[GetUserProfile] GetUserProfile Load Error'
}

export class LoadGetUserProfile implements Action {
  readonly type = GetUserProfileActionTypes.LoadGetUserProfile;
  constructor(public payload?: boolean) {}
}
export class DestroyUserProfile implements Action {
  readonly type = GetUserProfileActionTypes.DestroyUserProfile;
}

export class GetUserProfileLoadError implements Action {
  readonly type = GetUserProfileActionTypes.GetUserProfileLoadError;
  constructor(public payload: any) {}
}

export class GetUserProfileLoaded implements Action {
  readonly type = GetUserProfileActionTypes.GetUserProfileLoaded;
  constructor(public payload: any) {}
}

export type GetUserProfileAction =
  | LoadGetUserProfile
  | GetUserProfileLoaded
  | DestroyUserProfile
  | GetUserProfileLoadError;

export const fromGetUserProfileActions = {
  LoadGetUserProfile,
  GetUserProfileLoaded,
  DestroyUserProfile,
  GetUserProfileLoadError
};
