/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FlightSegmentOffline } from './flightSegmentOffline';
import { Room } from './room';


export interface TransactionInfo { 
    adminFeeAdt?: number;
    adminFeeChd?: number;
    adminFeeInf?: number;
    adultQty?: number;
    arrivalLocationCode?: string;
    baseFare?: number;
    baseFareAdt?: number;
    baseFareChd?: number;
    baseFareInf?: number;
    bookingDirectionType?: TransactionInfo.BookingDirectionTypeEnum;
    checkInDate?: string;
    checkOutDate?: string;
    childQty?: number;
    departureLocationCode?: string;
    equivFare?: number;
    flightSegmentList?: Array<FlightSegmentOffline>;
    hotelAddress?: string;
    hotelCode?: string;
    hotelName?: string;
    index?: number;
    infantQty?: number;
    note?: string;
    numberOfNight?: number;
    pnr?: string;
    rooms?: Array<Room>;
    serviceTax?: number;
    serviceTaxAdt?: number;
    serviceTaxChd?: number;
    serviceTaxInf?: number;
    supplierCode?: string;
    supplierName?: string;
    supplierType?: TransactionInfo.SupplierTypeEnum;
    vatAdt?: number;
    vatChd?: number;
    vatInf?: number;
}
export namespace TransactionInfo {
    export type BookingDirectionTypeEnum = 'DEPARTURE' | 'RETURN' | 'TRIP';
    export const BookingDirectionTypeEnum = {
        DEPARTURE: 'DEPARTURE' as BookingDirectionTypeEnum,
        RETURN: 'RETURN' as BookingDirectionTypeEnum,
        TRIP: 'TRIP' as BookingDirectionTypeEnum
    };
    export type SupplierTypeEnum = 'AIR' | 'HOTEL' | 'COMBO' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const SupplierTypeEnum = {
        AIR: 'AIR' as SupplierTypeEnum,
        HOTEL: 'HOTEL' as SupplierTypeEnum,
        COMBO: 'COMBO' as SupplierTypeEnum,
        TOURS: 'TOURS' as SupplierTypeEnum,
        TRAIN: 'TRAIN' as SupplierTypeEnum,
        SHIP: 'SHIP' as SupplierTypeEnum,
        OTHER: 'OTHER' as SupplierTypeEnum
    };
}
