import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {QrcodePageComponent} from "./qrcode-page.component";
import {AuthModule} from "../../auth.module";
import {RouterModule, Routes} from "@angular/router";
import {MatButtonModule} from "@angular/material";
import {AccountResourceService} from "@gtd/b2c-client";
import {DialogMessageModule} from "@gtd/components/dialog-message";

const routes: Routes = [
  { path: '', component: QrcodePageComponent },
  { path: ':id', component: QrcodePageComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    AuthModule,
    MatButtonModule,

    DialogMessageModule
  ],
  providers: [AccountResourceService]
})
export class QrCodePageModule { }
