/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingTransactionStatusInfo } from './bookingTransactionStatusInfo';
import { BookingTravelerInfo } from './bookingTravelerInfo';


export interface BookingStatusRQ { 
    action?: BookingStatusRQ.ActionEnum;
    bookingInfos?: Array<BookingTransactionStatusInfo>;
    bookingNumber?: string;
    bookingTravelerInfos?: Array<BookingTravelerInfo>;
    cancellationFee?: number;
    ibeSessionId?: string;
    note?: string;
    requesterCode?: string;
    requesterType?: BookingStatusRQ.RequesterTypeEnum;
}
export namespace BookingStatusRQ {
    export type ActionEnum = 'BOOK' | 'ISSUE' | 'REISSUE' | 'PAYNOW' | 'CANCEL' | 'UPDATE_IN_PROCESS_STATUS' | 'REQUEST_CANCELLATION' | 'CONFIRM_CANCELLATION';
    export const ActionEnum = {
        BOOK: 'BOOK' as ActionEnum,
        ISSUE: 'ISSUE' as ActionEnum,
        REISSUE: 'REISSUE' as ActionEnum,
        PAYNOW: 'PAYNOW' as ActionEnum,
        CANCEL: 'CANCEL' as ActionEnum,
        UPDATEINPROCESSSTATUS: 'UPDATE_IN_PROCESS_STATUS' as ActionEnum,
        REQUESTCANCELLATION: 'REQUEST_CANCELLATION' as ActionEnum,
        CONFIRMCANCELLATION: 'CONFIRM_CANCELLATION' as ActionEnum
    };
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
