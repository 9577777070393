import { Component, Inject, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import {
  BookingInfo,
  BookingTransactionInfo,
  GroupBooking
} from '@gtd/b2c-client';

@Component({
  selector: 'gtd-booking-result-combo',
  templateUrl: './booking-result-combo.component.html',
  styleUrls: ['./booking-result-combo.component.scss']
})
export class BookingResultComboComponent implements OnInit {
  @Input() allFinalBookingDetail: GroupBooking;
  hotelDetail;
  airDetail;
  appName: string = '';
  constructor(@Inject('appName') appName: string) {
    if (appName) {
      this.appName = appName;
    }
  }

  counter = Array;

  ngOnInit() {
    this.hotelDetail = this.allFinalBookingDetail.bookingInfo.transactionInfos.find(
      info => info.supplierType === 'HOTEL'
    );
    this.airDetail = this.allFinalBookingDetail.bookingInfo.transactionInfos.find(
      info => info.supplierType === 'AIR'
    );
  }
  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }
}
@Pipe({
  name: 'getComboPassengerNameRecord'
})
export class GetComboPassengerNameRecord implements PipeTransform {
  transform(bookingInfo: BookingInfo, type: 'DEPARTURE' | 'RETURN'): string {
    const airBookingTransactionInfos: BookingTransactionInfo[] = bookingInfo.transactionInfos.filter(
      trans => trans.supplierType === 'AIR'
    );
    let bookingTransactionInfos = new Array<BookingTransactionInfo>();
    switch (type) {
      case 'DEPARTURE':
        bookingTransactionInfos = airBookingTransactionInfos.filter(
          trans => trans.originLocationCode === bookingInfo.fromLocationCode
        );
        break;
      case 'RETURN':
        bookingTransactionInfos = airBookingTransactionInfos.filter(
          trans => trans.originLocationCode === bookingInfo.toLocationCode
        );
        break;
    }
    if (bookingTransactionInfos && bookingTransactionInfos.length > 0) {
      return bookingTransactionInfos[0].passengerNameRecord;
    }
    return '';
  }
}
