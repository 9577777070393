import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faGifts,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatDividerModule, MatFormFieldModule,
  MatIconModule, MatInputModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { RouterModule } from '@angular/router';
import { DialogMessageComponent } from './dialog-message/dialog-message.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogOtpReqComponent } from './dialog-otp-req/dialog-otp-req.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { CannyFeedbackDialogComponent } from './canny-feedback-dialog/canny-feedback-dialog.component';
import { DialogWithdrawApprovalComponent } from './dialog-withdraw-approval/dialog-withdraw-approval.component';
import {ReactiveFormsModule} from "@angular/forms";

library.add(
  faExclamationTriangle,
  faCheckCircle,
  faThumbsUp,
  faCheck,
  faExclamationCircle,
  faQuestionCircle,
  faGifts
);

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatDialogModule,
    MatIconModule,
    FlexLayoutModule,
    RouterModule,
    TranslateModule,
    NgOtpInputModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  exports: [DialogMessageComponent, DialogOtpReqComponent, DialogWithdrawApprovalComponent],
  entryComponents: [
    CannyFeedbackDialogComponent,
    DialogMessageComponent,
    DialogOtpReqComponent,
    DialogWithdrawApprovalComponent
  ],
  providers: [DialogMessageComponent, DialogOtpReqComponent, DialogWithdrawApprovalComponent],
  declarations: [
    DialogMessageComponent,
    DialogOtpReqComponent,
    CannyFeedbackDialogComponent, DialogWithdrawApprovalComponent
  ]
})
export class DialogMessageModule {}
