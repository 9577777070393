/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BranchTO { 
    address1?: string;
    address2?: string;
    branchCode?: string;
    branchName?: string;
    branchType?: BranchTO.BranchTypeEnum;
    cityCode?: string;
    cityName?: string;
    contactPerson1?: string;
    contactPerson2?: string;
    contactPerson3?: string;
    contactPhoneNumber1?: string;
    contactPhoneNumber2?: string;
    contactPhoneNumber3?: string;
    countryCode?: string;
    countryName?: string;
    defaultBranch?: boolean;
    email?: string;
    faxNumber?: string;
    id?: number;
    note?: string;
    stateCode?: string;
    stateName?: string;
    taxCode?: string;
    workPhoneNumber1?: string;
    workPhoneNumber2?: string;
}
export namespace BranchTO {
    export type BranchTypeEnum = 'HEAD' | 'OFFICE' | 'BRANCH';
    export const BranchTypeEnum = {
        HEAD: 'HEAD' as BranchTypeEnum,
        OFFICE: 'OFFICE' as BranchTypeEnum,
        BRANCH: 'BRANCH' as BranchTypeEnum
    };
}
