import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  POLICYBYBOOKING_FEATURE_KEY,
  PolicyByBookingState
} from './policy-by-booking.reducer';

// Lookup the 'PolicyByBooking' feature state managed by NgRx
const getPolicyByBookingState = createFeatureSelector<PolicyByBookingState>(
  POLICYBYBOOKING_FEATURE_KEY
);

const getLoaded = createSelector(
  getPolicyByBookingState,
  (state: PolicyByBookingState) => state.loaded
);
const getError = createSelector(
  getPolicyByBookingState,
  (state: PolicyByBookingState) => state.error
);

const getAllPolicyByBooking = createSelector(
  getPolicyByBookingState,
  getLoaded,
  (state: PolicyByBookingState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getPolicyByBookingState,
  (state: PolicyByBookingState) => state.selectedId
);
const getSelectedPolicyByBooking = createSelector(
  getAllPolicyByBooking,
  getSelectedId,
  (policyByBooking, id) => {
    return policyByBooking ? Object.assign({}, policyByBooking) : undefined;
  }
);

export const policyByBookingQuery = {
  getLoaded,
  getError,
  getAllPolicyByBooking,
  getSelectedPolicyByBooking
};
