import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  OnInit,
  Optional,
  PLATFORM_ID
} from '@angular/core';
import { BannerTypeService } from '@gtd/api-services/newface-banner';
import { GaTrackingService } from '@gtd/ga-tracking';
import { VIEWER } from '@gtd/utilities';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

@Component({
  selector: 'gtd-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  config: SwiperConfigInterface;
  bannerList: any;
  deviceId = 76;
  isProd = false;
  constructor(
    private deviceService: DeviceDetectorService,
    private _gaTrackingService: GaTrackingService,
    public bannerTypeService: BannerTypeService,
    @Optional() @Inject(VIEWER) public viewer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.config = {
      slidesPerView:
        window.innerWidth > 1440
          ? Math.floor(window.innerWidth / 460)
          : window.innerWidth > 992
          ? 3
          : 2,
      spaceBetween: 15,
      loop: true,
      loopedSlides: 10,
      // lazy: true,
      navigation: {
        nextEl: '.gtd-slider .swiper-next',
        prevEl: '.gtd-slider .swiper-prev'
      }
    };
  }

  ngOnInit() {
    this.isProd = environment.production;
    if (!this.isProd) {
      this.deviceId = 100;
    }
    if (isPlatformBrowser(this.platformId) && this.viewer !== 'bot') {
      this.bannerTypeService
        .wpV2BannerTypeGet('56', '' + this.deviceId)
        .subscribe(banners => {
          this.bannerList = banners;
        });
    }
  }
  track(eventType: string) {
    this._gaTrackingService.track(eventType);
  }
}
