import { Action } from '@ngrx/store';
import {ReferralResponseOfobject} from "../../lib";

export enum GetReferralActionTypes {
  LoadGetReferral = '[GetReferral] Load GetReferral',
  DestroyReferral = '[GetReferral] Destroy GetReferral',
  GetReferralLoaded = '[GetReferral] GetReferral Loaded',
  GetReferralLoadError = '[GetReferral] GetReferral Load Error'
}

export class LoadGetReferral implements Action {
  readonly type = GetReferralActionTypes.LoadGetReferral;
}

export class DestroyReferral implements Action {
  readonly type = GetReferralActionTypes.DestroyReferral;
}

export class GetReferralLoadError implements Action {
  readonly type = GetReferralActionTypes.GetReferralLoadError;
  constructor(public payload: any) {}
}

export class GetReferralLoaded implements Action {
  readonly type = GetReferralActionTypes.GetReferralLoaded;
  constructor(public payload: ReferralResponseOfobject) {}
}

export type GetReferralAction =
  | LoadGetReferral
  | GetReferralLoaded
  | DestroyReferral
  | GetReferralLoadError;

export const fromGetReferralActions = {
  LoadGetReferral,
  GetReferralLoaded,
  DestroyReferral,
  GetReferralLoadError
};
