/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BookingServiceRequestDTO { 
    bookingDirection?: BookingServiceRequestDTO.BookingDirectionEnum;
    bookingId?: number;
    bookingNumber: string;
    bookingTransCode?: string;
    bookingTravelerId?: number;
    createdBy?: string;
    createdDate?: Date;
    fareCode: string;
    id?: number;
    serviceType?: BookingServiceRequestDTO.ServiceTypeEnum;
    ssrAmount?: number;
    ssrCode: string;
    ssrId: string;
    ssrName: string;
}
export namespace BookingServiceRequestDTO {
    export type BookingDirectionEnum = 'DEPARTURE' | 'RETURN' | 'TRIP';
    export const BookingDirectionEnum = {
        DEPARTURE: 'DEPARTURE' as BookingDirectionEnum,
        RETURN: 'RETURN' as BookingDirectionEnum,
        TRIP: 'TRIP' as BookingDirectionEnum
    };
    export type ServiceTypeEnum = 'BAGGAGE' | 'MEAL' | 'INSURANCE' | 'OTHER';
    export const ServiceTypeEnum = {
        BAGGAGE: 'BAGGAGE' as ServiceTypeEnum,
        MEAL: 'MEAL' as ServiceTypeEnum,
        INSURANCE: 'INSURANCE' as ServiceTypeEnum,
        OTHER: 'OTHER' as ServiceTypeEnum
    };
}
