import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from "@angular/material";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {CustomerAvatarDTO} from "@gtd/api-services/customer-service";
import {LibraryAvatarFacade} from "./+state/library-avatar.facade";
import {ShortProfileFacade} from "../../../../../../../api-services/customer-service/src/lib/stage/short-profile/short-profile.facade";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {Subscription} from "rxjs";
import {DialogMessageComponent} from "@gtd/components/dialog-message";
import {AgentAvatarResourceService} from "@gtd/api-services/agentsrv";

@Component({
  selector: 'gtd-library-avatar',
  templateUrl: './library-avatar.component.html',
  styleUrls: ['./library-avatar.component.scss']
})
export class LibraryAvatarComponent implements OnInit, OnDestroy {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageFile: any = '';
  loaderImg: boolean;
  isLoading: boolean;
  customerAvatarDTO: CustomerAvatarDTO;
  subscriptions = new Subscription();
  appName = localStorage.getItem('appName');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LibraryAvatarComponent>,
    public shortProfileFacade: ShortProfileFacade,
    private libraryAvatarFacade: LibraryAvatarFacade,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private agentAvatarResourceService: AgentAvatarResourceService
  ) {
    this.fileChangeEvent(data.dataFile);
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.loaderImg = false;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImageFile = event.file;
    this.loaderImg = true;
  }
  imageLoaded() {
    this.loaderImg = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.loaderImg = false;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateAvatar(imgData) {
    const dataType = imgData.split(";base64,")[0];
    const typeImg = dataType.split(":")[1];
    const imgNew = imgData.split(";base64,")[1];
    if(this.appName === 'B2C') {
      this.subscriptions.add(
        this.shortProfileFacade.getShortProfile$.subscribe(shortProfile => {
          this.isLoading = true;
          this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.updating-avatar', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"});
          this.customerAvatarDTO = new class implements CustomerAvatarDTO {
            avatarImage: string;
            avatarImageContentType: string;
            createdDate: Date;
            id: number;
            inUsed: boolean;
            profileId: number;
          }
          this.customerAvatarDTO.avatarImage = imgNew;
          this.customerAvatarDTO.avatarImageContentType = typeImg;
          this.customerAvatarDTO.inUsed = true;
          this.customerAvatarDTO.profileId = shortProfile.profileId;
          if(this.data.customerAvatarDTO && this.data.customerAvatarDTO.id) {
            this.customerAvatarDTO.id = this.data.customerAvatarDTO.id;
          }
          this.libraryAvatarFacade.updateAvatar(this.customerAvatarDTO);
        },error1 => {
          this.dialog.open(DialogMessageComponent, {
            data: {
              btn: [
                {btnText: 'global.close'}
              ],
              content: 'user.system-error-message',
              heading: 'user.error-message',
              icon: 'exclamation-triangle',
              messageType: 'error'
            },
            panelClass: 'dialog-message',
            disableClose: true
          });
        })
      )
    }
    if(this.appName === 'B2B') {
      const imageName = 'avatar-'+this.data.userProfile.id+'.png';
      const imageBlob = this.dataURItoBlob(imgNew, typeImg);
      const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.updating-avatar', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"});
      this.agentAvatarResourceService.createAgentAvatarUsingPOST(
        imageFile,
        typeImg,
        this.data.userProfile.id).subscribe(updateAvatar => {
        this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.update-success', icon: 'check'}, panelClass: 'snackbar-success', verticalPosition: "top", duration: 2000});
        this.dialog.closeAll();
        window.location.reload();
      },error1 => {
        this.dialog.open(DialogMessageComponent, {
          data: {
            btn: [
              {btnText: 'global.close'}
            ],
            content: 'user.system-error-message',
            heading: 'user.error-message',
            icon: 'exclamation-triangle',
            messageType: 'error'
          },
          panelClass: 'dialog-message',
          disableClose: true
        });
      })
    }
  }
  dataURItoBlob(dataURI, imgType) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: imgType });
    return blob;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
