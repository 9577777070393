/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface OSICode { 
    direction?: OSICode.DirectionEnum;
    osiCode?: string;
    tourCode?: string;
}
export namespace OSICode {
    export type DirectionEnum = 'DEPARTURE' | 'RETURN' | 'TRIP';
    export const DirectionEnum = {
        DEPARTURE: 'DEPARTURE' as DirectionEnum,
        RETURN: 'RETURN' as DirectionEnum,
        TRIP: 'TRIP' as DirectionEnum
    };
}
