/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AgencyDTO } from '../model/agencyDTO';
import { OtaResult } from '../model/otaResult';
import { OtaResultOfInviteCodeEntity } from '../model/otaResultOfInviteCodeEntity';
import { OtaResultOfobject } from '../model/otaResultOfobject';
import { ReferralResponseOfobject } from '../model/referralResponseOfobject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ReferralResourceService {

    protected basePath = 'https://uat-api.gotadi.com/b2c-web/agentsrv';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addReferalBy
     *
     * @param code code
     * @param phone phone
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addReferalByUsingGET(code: string, phone: string, email: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResult>;
    public addReferalByUsingGET(code: string, phone: string, email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResult>>;
    public addReferalByUsingGET(code: string, phone: string, email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResult>>;
    public addReferalByUsingGET(code: string, phone: string, email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling addReferalByUsingGET.');
        }

        if (phone === null || phone === undefined) {
            throw new Error('Required parameter phone was null or undefined when calling addReferalByUsingGET.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling addReferalByUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (phone !== undefined && phone !== null) {
            queryParameters = queryParameters.set('phone', <any>phone);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResult>(`${this.basePath}/api/add_referral_by`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * calculateReferral
     *
     * @param fromDate fromDate
     * @param toDate toDate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calculateReferralUsingGET(fromDate: string, toDate: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfobject>;
    public calculateReferralUsingGET(fromDate: string, toDate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfobject>>;
    public calculateReferralUsingGET(fromDate: string, toDate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfobject>>;
    public calculateReferralUsingGET(fromDate: string, toDate: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling calculateReferralUsingGET.');
        }

        if (toDate === null || toDate === undefined) {
            throw new Error('Required parameter toDate was null or undefined when calling calculateReferralUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate);
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfobject>(`${this.basePath}/api/calculate_referral`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * existInviteCode
     *
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public existInviteCodeUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResult>;
    public existInviteCodeUsingGET(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResult>>;
    public existInviteCodeUsingGET(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResult>>;
    public existInviteCodeUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling existInviteCodeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResult>(`${this.basePath}/api/exist_invite_code/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgencyBy
     *
     * @param agentId agentId
     * @param phone phone
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgencyByUsingGET(agentId: string, phone: string, email: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyDTO>;
    public getAgencyByUsingGET(agentId: string, phone: string, email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyDTO>>;
    public getAgencyByUsingGET(agentId: string, phone: string, email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyDTO>>;
    public getAgencyByUsingGET(agentId: string, phone: string, email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentId === null || agentId === undefined) {
            throw new Error('Required parameter agentId was null or undefined when calling getAgencyByUsingGET.');
        }

        if (phone === null || phone === undefined) {
            throw new Error('Required parameter phone was null or undefined when calling getAgencyByUsingGET.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getAgencyByUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agentId !== undefined && agentId !== null) {
            queryParameters = queryParameters.set('agentId', <any>agentId);
        }
        if (phone !== undefined && phone !== null) {
            queryParameters = queryParameters.set('phone', <any>phone);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyDTO>(`${this.basePath}/api/profile_by`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgency
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgencyUsingGET1(observe?: 'body', reportProgress?: boolean): Observable<AgencyDTO>;
    public getAgencyUsingGET1(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyDTO>>;
    public getAgencyUsingGET1(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyDTO>>;
    public getAgencyUsingGET1(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyDTO>(`${this.basePath}/api/profile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getInviteCode
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInviteCodeUsingGET(observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfInviteCodeEntity>;
    public getInviteCodeUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfInviteCodeEntity>>;
    public getInviteCodeUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfInviteCodeEntity>>;
    public getInviteCodeUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfInviteCodeEntity>(`${this.basePath}/api/my_invite_code`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getListAgency
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListAgencyUsingGET(observe?: 'body', reportProgress?: boolean): Observable<ReferralResponseOfobject>;
    public getListAgencyUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReferralResponseOfobject>>;
    public getListAgencyUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReferralResponseOfobject>>;
    public getListAgencyUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ReferralResponseOfobject>(`${this.basePath}/api/referrals`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getListReferralBy
     *
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListReferralByUsingGET(email: string, observe?: 'body', reportProgress?: boolean): Observable<ReferralResponseOfobject>;
    public getListReferralByUsingGET(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReferralResponseOfobject>>;
    public getListReferralByUsingGET(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReferralResponseOfobject>>;
    public getListReferralByUsingGET(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getListReferralByUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ReferralResponseOfobject>(`${this.basePath}/api/referrals_by`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * inviteCodeBy
     *
     * @param phone phone
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviteCodeByUsingGET(phone: string, email: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfInviteCodeEntity>;
    public inviteCodeByUsingGET(phone: string, email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfInviteCodeEntity>>;
    public inviteCodeByUsingGET(phone: string, email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfInviteCodeEntity>>;
    public inviteCodeByUsingGET(phone: string, email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (phone === null || phone === undefined) {
            throw new Error('Required parameter phone was null or undefined when calling inviteCodeByUsingGET.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling inviteCodeByUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (phone !== undefined && phone !== null) {
            queryParameters = queryParameters.set('phone', <any>phone);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfInviteCodeEntity>(`${this.basePath}/api/invite_code_by`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * referralFilter
     *
     * @param monthYear monthYear
     * @param fromDate fromDate
     * @param toDate toDate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public referralFilterUsingGET(monthYear: string, fromDate: string, toDate: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResult>;
    public referralFilterUsingGET(monthYear: string, fromDate: string, toDate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResult>>;
    public referralFilterUsingGET(monthYear: string, fromDate: string, toDate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResult>>;
    public referralFilterUsingGET(monthYear: string, fromDate: string, toDate: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (monthYear === null || monthYear === undefined) {
            throw new Error('Required parameter monthYear was null or undefined when calling referralFilterUsingGET.');
        }

        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling referralFilterUsingGET.');
        }

        if (toDate === null || toDate === undefined) {
            throw new Error('Required parameter toDate was null or undefined when calling referralFilterUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (monthYear !== undefined && monthYear !== null) {
            queryParameters = queryParameters.set('monthYear', <any>monthYear);
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate);
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResult>(`${this.basePath}/api/report/filter`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * reportReferralBy
     *
     * @param email email
     * @param fromDate fromDate
     * @param toDate toDate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportReferralByUsingGET(email: string, fromDate: string, toDate: string, observe?: 'body', reportProgress?: boolean): Observable<ReferralResponseOfobject>;
    public reportReferralByUsingGET(email: string, fromDate: string, toDate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReferralResponseOfobject>>;
    public reportReferralByUsingGET(email: string, fromDate: string, toDate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReferralResponseOfobject>>;
    public reportReferralByUsingGET(email: string, fromDate: string, toDate: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling reportReferralByUsingGET.');
        }

        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling reportReferralByUsingGET.');
        }

        if (toDate === null || toDate === undefined) {
            throw new Error('Required parameter toDate was null or undefined when calling reportReferralByUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate);
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ReferralResponseOfobject>(`${this.basePath}/api/report_referral_by`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * reportReferral
     *
     * @param fromDate fromDate
     * @param toDate toDate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportReferralUsingGET(fromDate?: string, toDate?: string, observe?: 'body', reportProgress?: boolean): Observable<ReferralResponseOfobject>;
    public reportReferralUsingGET(fromDate?: string, toDate?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReferralResponseOfobject>>;
    public reportReferralUsingGET(fromDate?: string, toDate?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReferralResponseOfobject>>;
    public reportReferralUsingGET(fromDate?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate);
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ReferralResponseOfobject>(`${this.basePath}/api/report_referral`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * report
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<OtaResult>;
    public reportUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResult>>;
    public reportUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResult>>;
    public reportUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OtaResult>(`${this.basePath}/api/report`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
