import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FlightSearchPartialState } from './flight-search.reducer';
import { flightSearchQuery } from './flight-search.selectors';
import {FlightSearchReset, LoadFlightIntSearch, LoadFlightSearch} from './flight-search.actions';
import { LowfareSearchQueryParam } from '@gtd/helpers';

@Injectable()
export class FlightSearchFacade {
  loaded$ = this.store.pipe(select(flightSearchQuery.getLoaded));
  allFlightSearch$ = this.store.pipe(
    select(flightSearchQuery.getAllFlightSearch)
  );
  selectedFlightSearch$ = this.store.pipe(
    select(flightSearchQuery.getSelectedFlightSearch)
  );

  getErrorFlightSearch$ = this.store.pipe(
    select(flightSearchQuery.getError)
  );

  constructor(private store: Store<FlightSearchPartialState>) {}

  loadAll(query: LowfareSearchQueryParam) {
    this.store.dispatch(new LoadFlightSearch(query));
  }

  loadIntAll(query: LowfareSearchQueryParam) {
    this.store.dispatch(new LoadFlightIntSearch(query));
  }

  resetAll() {
    this.store.dispatch(new FlightSearchReset());
  }
}
