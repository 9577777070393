import {
  ShortProfileAction,
  ShortProfileActionTypes
} from './short-profile.actions';
import {RequesterInfo} from "@gtd/b2c-client";

export const SHORTPROFILE_FEATURE_KEY = 'shortProfile';

/**
 * Interface for the 'ShortProfile' data used in
 *  - ShortProfileState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface ShortProfileState {
  result: RequesterInfo; // list of ShortProfile; analogous to a sql normalized table
  selectedId?: string | number; // which ShortProfile record has been selected
  loaded: boolean; // has the ShortProfile list been loaded
  error?: any; // last none error (if any)
}

export interface ShortProfilePartialState {
  readonly [SHORTPROFILE_FEATURE_KEY]: ShortProfileState;
}

export const initialState: ShortProfileState = {
  result: null,
  loaded: false
};

export function reducerShort(
  state: ShortProfileState = initialState,
  action: ShortProfileAction
): ShortProfileState {
  switch (action.type) {
    case ShortProfileActionTypes.ShortProfileLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
