/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AddVoucherReq } from '../model/addVoucherReq';
import { GTDCampaignRS } from '../model/gTDCampaignRS';
import { GTDVoucherResponse } from '../model/gTDVoucherResponse';
import { PromotionRedemptionRes } from '../model/promotionRedemptionRes';
import { VoidVoucherReq } from '../model/voidVoucherReq';
import { VoidVoucherRes } from '../model/voidVoucherRes';
import { VoucherRedemptionReq } from '../model/voucherRedemptionReq';
import { VoucherRedemptionRes } from '../model/voucherRedemptionRes';
import { VoucherValidationReq } from '../model/voucherValidationReq';
import { VoucherValidationRes } from '../model/voucherValidationRes';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VoucherResourceService {

    protected basePath = 'https://10.7.71.20:8282';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addVoucher
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addVoucherUsingPOST(request: AddVoucherReq, observe?: 'body', reportProgress?: boolean): Observable<GTDVoucherResponse>;
    public addVoucherUsingPOST(request: AddVoucherReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GTDVoucherResponse>>;
    public addVoucherUsingPOST(request: AddVoucherReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GTDVoucherResponse>>;
    public addVoucherUsingPOST(request: AddVoucherReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling addVoucherUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GTDVoucherResponse>(`${this.basePath}/api/voucher/create`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCampaign
     * 
     * @param campaign campaign
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCampaignUsingGET(campaign: string, observe?: 'body', reportProgress?: boolean): Observable<GTDCampaignRS>;
    public getCampaignUsingGET(campaign: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GTDCampaignRS>>;
    public getCampaignUsingGET(campaign: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GTDCampaignRS>>;
    public getCampaignUsingGET(campaign: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaign === null || campaign === undefined) {
            throw new Error('Required parameter campaign was null or undefined when calling getCampaignUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GTDCampaignRS>(`${this.basePath}/api/voucher/campaign/${encodeURIComponent(String(campaign))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getVoucher
     * 
     * @param code code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVoucherUsingGET(code: string, observe?: 'body', reportProgress?: boolean): Observable<GTDVoucherResponse>;
    public getVoucherUsingGET(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GTDVoucherResponse>>;
    public getVoucherUsingGET(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GTDVoucherResponse>>;
    public getVoucherUsingGET(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling getVoucherUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GTDVoucherResponse>(`${this.basePath}/api/voucher/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * redeemVoucher
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public redeemVoucherUsingPOST(request: VoucherRedemptionReq, observe?: 'body', reportProgress?: boolean): Observable<VoucherRedemptionRes>;
    public redeemVoucherUsingPOST(request: VoucherRedemptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VoucherRedemptionRes>>;
    public redeemVoucherUsingPOST(request: VoucherRedemptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VoucherRedemptionRes>>;
    public redeemVoucherUsingPOST(request: VoucherRedemptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling redeemVoucherUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VoucherRedemptionRes>(`${this.basePath}/api/voucher/redeem`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateVoucherTrackingStatus
     * 
     * @param bookingNumber bookingNumber
     * @param customerID customerID
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateVoucherTrackingStatusUsingPUT(bookingNumber: string, customerID: string, status: 'SUCCEEDED' | 'PENDING' | 'CANCELED' | 'FAILED', observe?: 'body', reportProgress?: boolean): Observable<PromotionRedemptionRes>;
    public updateVoucherTrackingStatusUsingPUT(bookingNumber: string, customerID: string, status: 'SUCCEEDED' | 'PENDING' | 'CANCELED' | 'FAILED', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PromotionRedemptionRes>>;
    public updateVoucherTrackingStatusUsingPUT(bookingNumber: string, customerID: string, status: 'SUCCEEDED' | 'PENDING' | 'CANCELED' | 'FAILED', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PromotionRedemptionRes>>;
    public updateVoucherTrackingStatusUsingPUT(bookingNumber: string, customerID: string, status: 'SUCCEEDED' | 'PENDING' | 'CANCELED' | 'FAILED', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling updateVoucherTrackingStatusUsingPUT.');
        }

        if (customerID === null || customerID === undefined) {
            throw new Error('Required parameter customerID was null or undefined when calling updateVoucherTrackingStatusUsingPUT.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling updateVoucherTrackingStatusUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (customerID !== undefined && customerID !== null) {
            queryParameters = queryParameters.set('customerID', <any>customerID);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<PromotionRedemptionRes>(`${this.basePath}/api/voucher/updateTrackingStatus`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateVoucher
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateVoucherUsingPOST(request: VoucherValidationReq, observe?: 'body', reportProgress?: boolean): Observable<VoucherValidationRes>;
    public validateVoucherUsingPOST(request: VoucherValidationReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VoucherValidationRes>>;
    public validateVoucherUsingPOST(request: VoucherValidationReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VoucherValidationRes>>;
    public validateVoucherUsingPOST(request: VoucherValidationReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling validateVoucherUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VoucherValidationRes>(`${this.basePath}/api/voucher/validate`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * voidemVoucher
     * 
     * @param request request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public voidemVoucherUsingPOST(request: VoidVoucherReq, observe?: 'body', reportProgress?: boolean): Observable<VoidVoucherRes>;
    public voidemVoucherUsingPOST(request: VoidVoucherReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VoidVoucherRes>>;
    public voidemVoucherUsingPOST(request: VoidVoucherReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VoidVoucherRes>>;
    public voidemVoucherUsingPOST(request: VoidVoucherReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling voidemVoucherUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VoidVoucherRes>(`${this.basePath}/api/voucher/void`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
