import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HOTELDETAIL_FEATURE_KEY,
  HotelDetailState
} from './hotel-detail.reducer';

// Lookup the 'HotelDetail' feature state managed by NgRx
const getHotelDetailState = createFeatureSelector<HotelDetailState>(
  HOTELDETAIL_FEATURE_KEY
);

const getLoaded = createSelector(
  getHotelDetailState,
  (state: HotelDetailState) => state.loaded
);
const getError = createSelector(
  getHotelDetailState,
  (state: HotelDetailState) => state.error
);

const getAllHotelDetail = createSelector(
  getHotelDetailState,
  getLoaded,
  (state: HotelDetailState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getHotelDetailState,
  (state: HotelDetailState) => state.selectedId
);
const getSelectedHotelDetail = createSelector(
  getAllHotelDetail,
  getSelectedId,
  (hotelDetail, id) => {
    return hotelDetail ? Object.assign({}, hotelDetail) : undefined;
  }
);

export const hotelDetailQuery = {
  getLoaded,
  getError,
  getAllHotelDetail,
  getSelectedHotelDetail
};
