/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingContentDTO } from './bookingContentDTO';

export interface BookingDTO {
  additionalFee?: number;
  agencyCode?: string;
  agencyMarkupValue?: number;
  agentCode?: string;
  agentId?: number;
  agentName?: string;
  baseFare?: number;
  bookBy?: string;
  bookByCode?: string;
  bookingCode?: string;
  bookingDate?: Date;
  bookingNumber?: string;
  bookingType?: BookingDTO.BookingTypeEnum;
  branchCode?: string;
  cancellationBy?: string;
  cancellationDate?: Date;
  cancellationFee?: number;
  cancellationNotes?: string;
  channelType?: BookingDTO.ChannelTypeEnum;
  commissionValue?: number;
  content?: BookingContentDTO;
  createdBy?: string;
  createdDate?: Date;
  customerCode?: string;
  customerEmail?: string;
  customerFirstName?: string;
  customerId?: number;
  customerLastName?: string;
  customerPhoneNumber1?: string;
  customerPhoneNumber2?: string;
  departureDate?: Date;
  discountAmount?: number;
  discountDate?: Date;
  discountRedeemCode?: string;
  discountRedeemId?: string;
  discountTrackingCode?: string;
  discountVoucherCode?: string;
  equivFare?: number;
  fromCity?: string;
  fromLocationCode?: string;
  fromLocationName?: string;
  id?: number;
  isDeleted?: boolean;
  issuedBy?: string;
  issuedByCode?: string;
  issuedDate?: Date;
  issuedStatus?: BookingDTO.IssuedStatusEnum;
  markupValue?: number;
  orgCode: string;
  partnerOrderId?: string;
  partnerRequestId?: string;
  paymentBy?: string;
  paymentByCode?: string;
  paymentDate?: Date;
  paymentFee?: number;
  paymentRefNumber?: string;
  paymentStatus?: BookingDTO.PaymentStatusEnum;
  paymentTotalAmount?: number;
  paymentType?: BookingDTO.PaymentTypeEnum;
  promotionID?: Array<string>;
  reconciliationType?: BookingDTO.ReconciliationTypeEnum;
  refundAmount?: number;
  refundBy?: string;
  refundByCode?: string;
  refundDate?: Date;
  refundNextVoidDate?: Date;
  returnDate?: Date;
  roundType?: string;
  saleChannel?: BookingDTO.SaleChannelEnum;
  serviceTax?: number;
  sessionSearchId?: string;
  status?: BookingDTO.StatusEnum;
  supplierBookingStatus?: BookingDTO.SupplierBookingStatusEnum;
  supplierType?: BookingDTO.SupplierTypeEnum;
  taxAddress1?: string;
  taxAddress2?: string;
  taxCompanyName?: string;
  taxNumber?: string;
  taxReceiptRequest?: boolean;
  timeToLive?: Date;
  toCity?: string;
  toLocationCode?: string;
  toLocationName?: string;
  totalFare?: number;
  totalSsrValue?: number;
  totalTax?: number;
  updatedBy?: string;
  updatedDate?: Date;
  vat?: number;
}
export namespace BookingDTO {
  export type BookingTypeEnum = 'DOME' | 'INTE';
  export const BookingTypeEnum = {
    DOME: 'DOME' as BookingTypeEnum,
    INTE: 'INTE' as BookingTypeEnum
  };
  export type ChannelTypeEnum = 'ONLINE' | 'OFFLINE';
  export const ChannelTypeEnum = {
    ONLINE: 'ONLINE' as ChannelTypeEnum,
    OFFLINE: 'OFFLINE' as ChannelTypeEnum
  };
  export type IssuedStatusEnum =
    | 'PENDING'
    | 'TICKET_ON_PROCESS'
    | 'SUCCEEDED'
    | 'FAILED'
    | 'PARTLY_CONFIRMED'
    | 'PARTLY_FAILED';
  export const IssuedStatusEnum = {
    PENDING: 'PENDING' as IssuedStatusEnum,
    TICKETONPROCESS: 'TICKET_ON_PROCESS' as IssuedStatusEnum,
    SUCCEEDED: 'SUCCEEDED' as IssuedStatusEnum,
    FAILED: 'FAILED' as IssuedStatusEnum,
    PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as IssuedStatusEnum,
    PARTLYFAILED: 'PARTLY_FAILED' as IssuedStatusEnum
  };
  export type PaymentStatusEnum =
    | 'SUCCEEDED'
    | 'FAILED'
    | 'REFUNDED'
    | 'PENDING';
  export const PaymentStatusEnum = {
    SUCCEEDED: 'SUCCEEDED' as PaymentStatusEnum,
    FAILED: 'FAILED' as PaymentStatusEnum,
    REFUNDED: 'REFUNDED' as PaymentStatusEnum,
    PENDING: 'PENDING' as PaymentStatusEnum
  };
  export type PaymentTypeEnum =
    | 'TRANSFER'
    | 'CASH'
    | 'BALANCE'
    | 'ATM_DEBIT'
    | 'CREDIT'
    | 'VNPAYQR'
    | 'MOMO'
    | 'VIETTELPAY'
    | 'PARTNER'
    | 'OTHER'
    | 'PAYOO'
    | 'ZALO';
  export const PaymentTypeEnum = {
    TRANSFER: 'TRANSFER' as PaymentTypeEnum,
    CASH: 'CASH' as PaymentTypeEnum,
    BALANCE: 'BALANCE' as PaymentTypeEnum,
    ATMDEBIT: 'ATM_DEBIT' as PaymentTypeEnum,
    CREDIT: 'CREDIT' as PaymentTypeEnum,
    VNPAYQR: 'VNPAYQR' as PaymentTypeEnum,
    MOMO: 'MOMO' as PaymentTypeEnum,
    VIETTELPAY: 'VIETTELPAY' as PaymentTypeEnum,
    PARTNER: 'PARTNER' as PaymentTypeEnum,
    OTHER: 'OTHER' as PaymentTypeEnum,
    PAYOO: 'PAYOO' as PaymentTypeEnum,
    ZALO: 'ZALO' as PaymentTypeEnum
  };
  export type ReconciliationTypeEnum = 'NEW' | 'ALREADY_RECONCILIATION';
  export const ReconciliationTypeEnum = {
    NEW: 'NEW' as ReconciliationTypeEnum,
    ALREADYRECONCILIATION: 'ALREADY_RECONCILIATION' as ReconciliationTypeEnum
  };
  export type SaleChannelEnum =
    | 'B2B'
    | 'B2C'
    | 'B2B2C'
    | 'B2C_WEB'
    | 'B2C_WEB_APP'
    | 'B2C_MOBILE';
  export const SaleChannelEnum = {
    B2B: 'B2B' as SaleChannelEnum,
    B2C: 'B2C' as SaleChannelEnum,
    B2B2C: 'B2B2C' as SaleChannelEnum,
    B2CWEB: 'B2C_WEB' as SaleChannelEnum,
    B2CWEBAPP: 'B2C_WEB_APP' as SaleChannelEnum,
    B2CMOBILE: 'B2C_MOBILE' as SaleChannelEnum
  };
  export type StatusEnum =
    | 'PENDING'
    | 'BOOKING_ON_PROCESS'
    | 'BOOKED'
    | 'PARTLY_BOOKED'
    | 'FAILED'
    | 'PARTLY_FAILED'
    | 'CANCELLED'
    | 'EXPIRED'
    | 'TENTATIVE';
  export const StatusEnum = {
    PENDING: 'PENDING' as StatusEnum,
    BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as StatusEnum,
    BOOKED: 'BOOKED' as StatusEnum,
    PARTLYBOOKED: 'PARTLY_BOOKED' as StatusEnum,
    FAILED: 'FAILED' as StatusEnum,
    PARTLYFAILED: 'PARTLY_FAILED' as StatusEnum,
    CANCELLED: 'CANCELLED' as StatusEnum,
    EXPIRED: 'EXPIRED' as StatusEnum,
    TENTATIVE: 'TENTATIVE' as StatusEnum
  };
  export type SupplierBookingStatusEnum =
    | 'PENDING'
    | 'BOOKED'
    | 'CONFIRMED'
    | 'FAILED'
    | 'CANCELLED'
    | 'EXPIRED'
    | 'TENTATIVE'
    | 'BOOKING_ON_PROCESS'
    | 'TICKET_ON_PROCESS'
    | 'PARTLY_BOOKED'
    | 'PARTLY_CONFIRMED'
    | 'ISSUE_FAILED';
  export const SupplierBookingStatusEnum = {
    PENDING: 'PENDING' as SupplierBookingStatusEnum,
    BOOKED: 'BOOKED' as SupplierBookingStatusEnum,
    CONFIRMED: 'CONFIRMED' as SupplierBookingStatusEnum,
    FAILED: 'FAILED' as SupplierBookingStatusEnum,
    CANCELLED: 'CANCELLED' as SupplierBookingStatusEnum,
    EXPIRED: 'EXPIRED' as SupplierBookingStatusEnum,
    TENTATIVE: 'TENTATIVE' as SupplierBookingStatusEnum,
    BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as SupplierBookingStatusEnum,
    TICKETONPROCESS: 'TICKET_ON_PROCESS' as SupplierBookingStatusEnum,
    PARTLYBOOKED: 'PARTLY_BOOKED' as SupplierBookingStatusEnum,
    PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as SupplierBookingStatusEnum,
    ISSUEFAILED: 'ISSUE_FAILED' as SupplierBookingStatusEnum
  };
  export type SupplierTypeEnum =
    | 'AIR'
    | 'HOTEL'
    | 'TOURS'
    | 'TRAIN'
    | 'SHIP'
    | 'COMBO'
    | 'OTHER';
  export const SupplierTypeEnum = {
    AIR: 'AIR' as SupplierTypeEnum,
    HOTEL: 'HOTEL' as SupplierTypeEnum,
    TOURS: 'TOURS' as SupplierTypeEnum,
    TRAIN: 'TRAIN' as SupplierTypeEnum,
    SHIP: 'SHIP' as SupplierTypeEnum,
    COMBO: 'COMBO' as SupplierTypeEnum,
    OTHER: 'OTHER' as SupplierTypeEnum
  };
}
