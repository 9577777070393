import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {
  initialState as promotionSectionInitialState,
  PROMOTIONSECTIONSTATE_FEATURE_KEY,
  reducer
} from './+state/promotion-section-state.reducer';
import {PromotionSectionStateEffects} from './+state/promotion-section-state.effects';
import {PromotionSectionStateFacade} from './+state/promotion-section-state.facade';
import {NxModule} from '@nrwl/angular';
import {BASE_PATH, UtilResourceService} from "@gtd/b2c-client";
import {environment} from "@env/environment";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(PROMOTIONSECTIONSTATE_FEATURE_KEY, reducer, {
      initialState: promotionSectionInitialState
    }),
    EffectsModule.forFeature([PromotionSectionStateEffects]),
  ],
  providers: [{ provide: BASE_PATH, useValue: environment.api.b2cClient }, PromotionSectionStateFacade, UtilResourceService]
})
export class PromotionSectionStateModule {}
