import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TourBookingDetailComponent } from './tour-booking-detail.component';
import {
  MatButtonModule,
  MatCardModule,
  MatDividerModule,
  MatMenuModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    TranslateModule,
    FlexLayoutModule,
    FontAwesomeModule,
    RouterModule,
    MatMenuModule
  ],
  declarations: [TourBookingDetailComponent],
  exports: [TourBookingDetailComponent]
})
export class TourBookingDetailModule {}
