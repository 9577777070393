/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ViettelResourceService {

    protected basePath = 'https://172.16.100.16:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * getPaymentResultCTT
     * 
     * @param billcode billcode
     * @param errorCode error_code
     * @param merchantCode merchant_code
     * @param orderId order_id
     * @param paymentStatus payment_status
     * @param transAmount trans_amount
     * @param checkSum check_sum
     * @param custMsisdn cust_msisdn
     * @param vtTransactionId vt_transaction_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentResultCTTUsingPOST(billcode: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, checkSum: string, custMsisdn?: string, vtTransactionId?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getPaymentResultCTTUsingPOST(billcode: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, checkSum: string, custMsisdn?: string, vtTransactionId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getPaymentResultCTTUsingPOST(billcode: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, checkSum: string, custMsisdn?: string, vtTransactionId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getPaymentResultCTTUsingPOST(billcode: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, checkSum: string, custMsisdn?: string, vtTransactionId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billcode === null || billcode === undefined) {
            throw new Error('Required parameter billcode was null or undefined when calling getPaymentResultCTTUsingPOST.');
        }

        if (errorCode === null || errorCode === undefined) {
            throw new Error('Required parameter errorCode was null or undefined when calling getPaymentResultCTTUsingPOST.');
        }

        if (merchantCode === null || merchantCode === undefined) {
            throw new Error('Required parameter merchantCode was null or undefined when calling getPaymentResultCTTUsingPOST.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getPaymentResultCTTUsingPOST.');
        }

        if (paymentStatus === null || paymentStatus === undefined) {
            throw new Error('Required parameter paymentStatus was null or undefined when calling getPaymentResultCTTUsingPOST.');
        }

        if (transAmount === null || transAmount === undefined) {
            throw new Error('Required parameter transAmount was null or undefined when calling getPaymentResultCTTUsingPOST.');
        }

        if (checkSum === null || checkSum === undefined) {
            throw new Error('Required parameter checkSum was null or undefined when calling getPaymentResultCTTUsingPOST.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billcode !== undefined && billcode !== null) {
            queryParameters = queryParameters.set('billcode', <any>billcode);
        }
        if (custMsisdn !== undefined && custMsisdn !== null) {
            queryParameters = queryParameters.set('cust_msisdn', <any>custMsisdn);
        }
        if (errorCode !== undefined && errorCode !== null) {
            queryParameters = queryParameters.set('error_code', <any>errorCode);
        }
        if (merchantCode !== undefined && merchantCode !== null) {
            queryParameters = queryParameters.set('merchant_code', <any>merchantCode);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('order_id', <any>orderId);
        }
        if (paymentStatus !== undefined && paymentStatus !== null) {
            queryParameters = queryParameters.set('payment_status', <any>paymentStatus);
        }
        if (transAmount !== undefined && transAmount !== null) {
            queryParameters = queryParameters.set('trans_amount', <any>transAmount);
        }
        if (vtTransactionId !== undefined && vtTransactionId !== null) {
            queryParameters = queryParameters.set('vt_transaction_id', <any>vtTransactionId);
        }
        if (checkSum !== undefined && checkSum !== null) {
            queryParameters = queryParameters.set('check_sum', <any>checkSum);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<string>(`${this.basePath}/api/viettel/ctt/booking/payment-result`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPaymentResultCTT
     * 
     * @param merchantCode merchant_code
     * @param orderId order_id
     * @param checkSum check_sum
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentResultCTTUsingPOST1(merchantCode: string, orderId: string, checkSum: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPaymentResultCTTUsingPOST1(merchantCode: string, orderId: string, checkSum: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPaymentResultCTTUsingPOST1(merchantCode: string, orderId: string, checkSum: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPaymentResultCTTUsingPOST1(merchantCode: string, orderId: string, checkSum: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (merchantCode === null || merchantCode === undefined) {
            throw new Error('Required parameter merchantCode was null or undefined when calling getPaymentResultCTTUsingPOST1.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getPaymentResultCTTUsingPOST1.');
        }

        if (checkSum === null || checkSum === undefined) {
            throw new Error('Required parameter checkSum was null or undefined when calling getPaymentResultCTTUsingPOST1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (merchantCode !== undefined && merchantCode !== null) {
            queryParameters = queryParameters.set('merchant_code', <any>merchantCode);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('order_id', <any>orderId);
        }
        if (checkSum !== undefined && checkSum !== null) {
            queryParameters = queryParameters.set('check_sum', <any>checkSum);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/viettel/ctt/booking/search`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPaymentResultTMDT
     * 
     * @param billcode billcode
     * @param errorCode error_code
     * @param merchantCode merchant_code
     * @param orderId order_id
     * @param paymentStatus payment_status
     * @param transAmount trans_amount
     * @param checkSum check_sum
     * @param custMsisdn cust_msisdn
     * @param vtTransactionId vt_transaction_id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentResultTMDTUsingPOST(billcode: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, checkSum: string, custMsisdn?: string, vtTransactionId?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getPaymentResultTMDTUsingPOST(billcode: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, checkSum: string, custMsisdn?: string, vtTransactionId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getPaymentResultTMDTUsingPOST(billcode: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, checkSum: string, custMsisdn?: string, vtTransactionId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getPaymentResultTMDTUsingPOST(billcode: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, checkSum: string, custMsisdn?: string, vtTransactionId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billcode === null || billcode === undefined) {
            throw new Error('Required parameter billcode was null or undefined when calling getPaymentResultTMDTUsingPOST.');
        }

        if (errorCode === null || errorCode === undefined) {
            throw new Error('Required parameter errorCode was null or undefined when calling getPaymentResultTMDTUsingPOST.');
        }

        if (merchantCode === null || merchantCode === undefined) {
            throw new Error('Required parameter merchantCode was null or undefined when calling getPaymentResultTMDTUsingPOST.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getPaymentResultTMDTUsingPOST.');
        }

        if (paymentStatus === null || paymentStatus === undefined) {
            throw new Error('Required parameter paymentStatus was null or undefined when calling getPaymentResultTMDTUsingPOST.');
        }

        if (transAmount === null || transAmount === undefined) {
            throw new Error('Required parameter transAmount was null or undefined when calling getPaymentResultTMDTUsingPOST.');
        }

        if (checkSum === null || checkSum === undefined) {
            throw new Error('Required parameter checkSum was null or undefined when calling getPaymentResultTMDTUsingPOST.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billcode !== undefined && billcode !== null) {
            queryParameters = queryParameters.set('billcode', <any>billcode);
        }
        if (custMsisdn !== undefined && custMsisdn !== null) {
            queryParameters = queryParameters.set('cust_msisdn', <any>custMsisdn);
        }
        if (errorCode !== undefined && errorCode !== null) {
            queryParameters = queryParameters.set('error_code', <any>errorCode);
        }
        if (merchantCode !== undefined && merchantCode !== null) {
            queryParameters = queryParameters.set('merchant_code', <any>merchantCode);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('order_id', <any>orderId);
        }
        if (paymentStatus !== undefined && paymentStatus !== null) {
            queryParameters = queryParameters.set('payment_status', <any>paymentStatus);
        }
        if (transAmount !== undefined && transAmount !== null) {
            queryParameters = queryParameters.set('trans_amount', <any>transAmount);
        }
        if (vtTransactionId !== undefined && vtTransactionId !== null) {
            queryParameters = queryParameters.set('vt_transaction_id', <any>vtTransactionId);
        }
        if (checkSum !== undefined && checkSum !== null) {
            queryParameters = queryParameters.set('check_sum', <any>checkSum);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<string>(`${this.basePath}/api/viettel/tmdt/booking/payment-result`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPaymentResultTMDT
     * 
     * @param merchantCode merchant_code
     * @param orderId order_id
     * @param checkSum check_sum
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPaymentResultTMDTUsingPOST1(merchantCode: string, orderId: string, checkSum: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPaymentResultTMDTUsingPOST1(merchantCode: string, orderId: string, checkSum: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPaymentResultTMDTUsingPOST1(merchantCode: string, orderId: string, checkSum: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPaymentResultTMDTUsingPOST1(merchantCode: string, orderId: string, checkSum: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (merchantCode === null || merchantCode === undefined) {
            throw new Error('Required parameter merchantCode was null or undefined when calling getPaymentResultTMDTUsingPOST1.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getPaymentResultTMDTUsingPOST1.');
        }

        if (checkSum === null || checkSum === undefined) {
            throw new Error('Required parameter checkSum was null or undefined when calling getPaymentResultTMDTUsingPOST1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (merchantCode !== undefined && merchantCode !== null) {
            queryParameters = queryParameters.set('merchant_code', <any>merchantCode);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('order_id', <any>orderId);
        }
        if (checkSum !== undefined && checkSum !== null) {
            queryParameters = queryParameters.set('check_sum', <any>checkSum);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/viettel/tmdt/booking/search`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getReqPlaceOrder
     * 
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReqPlaceOrderUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getReqPlaceOrderUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getReqPlaceOrderUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getReqPlaceOrderUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getReqPlaceOrderUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/viettel/tmdt/booking/get-request-place-order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onVTPaymentBookingCTT
     * 
     * @param billcode billcode
     * @param custMsisdn cust_msisdn
     * @param errorCode error_code
     * @param merchantCode merchant_code
     * @param orderId order_id
     * @param paymentStatus payment_status
     * @param transAmount trans_amount
     * @param vtTransactionId vt_transaction_id
     * @param checkSum check_sum
     * @param contentType Content-Type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onVTPaymentBookingCTTUsingPOST(billcode: string, custMsisdn: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, vtTransactionId: string, checkSum: string, contentType: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public onVTPaymentBookingCTTUsingPOST(billcode: string, custMsisdn: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, vtTransactionId: string, checkSum: string, contentType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public onVTPaymentBookingCTTUsingPOST(billcode: string, custMsisdn: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, vtTransactionId: string, checkSum: string, contentType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public onVTPaymentBookingCTTUsingPOST(billcode: string, custMsisdn: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, vtTransactionId: string, checkSum: string, contentType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billcode === null || billcode === undefined) {
            throw new Error('Required parameter billcode was null or undefined when calling onVTPaymentBookingCTTUsingPOST.');
        }

        if (custMsisdn === null || custMsisdn === undefined) {
            throw new Error('Required parameter custMsisdn was null or undefined when calling onVTPaymentBookingCTTUsingPOST.');
        }

        if (errorCode === null || errorCode === undefined) {
            throw new Error('Required parameter errorCode was null or undefined when calling onVTPaymentBookingCTTUsingPOST.');
        }

        if (merchantCode === null || merchantCode === undefined) {
            throw new Error('Required parameter merchantCode was null or undefined when calling onVTPaymentBookingCTTUsingPOST.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling onVTPaymentBookingCTTUsingPOST.');
        }

        if (paymentStatus === null || paymentStatus === undefined) {
            throw new Error('Required parameter paymentStatus was null or undefined when calling onVTPaymentBookingCTTUsingPOST.');
        }

        if (transAmount === null || transAmount === undefined) {
            throw new Error('Required parameter transAmount was null or undefined when calling onVTPaymentBookingCTTUsingPOST.');
        }

        if (vtTransactionId === null || vtTransactionId === undefined) {
            throw new Error('Required parameter vtTransactionId was null or undefined when calling onVTPaymentBookingCTTUsingPOST.');
        }

        if (checkSum === null || checkSum === undefined) {
            throw new Error('Required parameter checkSum was null or undefined when calling onVTPaymentBookingCTTUsingPOST.');
        }

        if (contentType === null || contentType === undefined) {
            throw new Error('Required parameter contentType was null or undefined when calling onVTPaymentBookingCTTUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billcode !== undefined && billcode !== null) {
            queryParameters = queryParameters.set('billcode', <any>billcode);
        }
        if (custMsisdn !== undefined && custMsisdn !== null) {
            queryParameters = queryParameters.set('cust_msisdn', <any>custMsisdn);
        }
        if (errorCode !== undefined && errorCode !== null) {
            queryParameters = queryParameters.set('error_code', <any>errorCode);
        }
        if (merchantCode !== undefined && merchantCode !== null) {
            queryParameters = queryParameters.set('merchant_code', <any>merchantCode);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('order_id', <any>orderId);
        }
        if (paymentStatus !== undefined && paymentStatus !== null) {
            queryParameters = queryParameters.set('payment_status', <any>paymentStatus);
        }
        if (transAmount !== undefined && transAmount !== null) {
            queryParameters = queryParameters.set('trans_amount', <any>transAmount);
        }
        if (vtTransactionId !== undefined && vtTransactionId !== null) {
            queryParameters = queryParameters.set('vt_transaction_id', <any>vtTransactionId);
        }
        if (checkSum !== undefined && checkSum !== null) {
            queryParameters = queryParameters.set('check_sum', <any>checkSum);
        }

        let headers = this.defaultHeaders;
        if (contentType !== undefined && contentType !== null) {
            headers = headers.set('Content-Type', String(contentType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/viettel/ctt/booking/payment`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onVTPaymentBookingTMDT
     * 
     * @param billcode billcode
     * @param custMsisdn cust_msisdn
     * @param errorCode error_code
     * @param merchantCode merchant_code
     * @param orderId order_id
     * @param paymentStatus payment_status
     * @param transAmount trans_amount
     * @param vtTransactionId vt_transaction_id
     * @param checkSum check_sum
     * @param contentType Content-Type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onVTPaymentBookingTMDTUsingPOST(billcode: string, custMsisdn: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, vtTransactionId: string, checkSum: string, contentType: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public onVTPaymentBookingTMDTUsingPOST(billcode: string, custMsisdn: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, vtTransactionId: string, checkSum: string, contentType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public onVTPaymentBookingTMDTUsingPOST(billcode: string, custMsisdn: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, vtTransactionId: string, checkSum: string, contentType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public onVTPaymentBookingTMDTUsingPOST(billcode: string, custMsisdn: string, errorCode: string, merchantCode: string, orderId: string, paymentStatus: number, transAmount: number, vtTransactionId: string, checkSum: string, contentType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billcode === null || billcode === undefined) {
            throw new Error('Required parameter billcode was null or undefined when calling onVTPaymentBookingTMDTUsingPOST.');
        }

        if (custMsisdn === null || custMsisdn === undefined) {
            throw new Error('Required parameter custMsisdn was null or undefined when calling onVTPaymentBookingTMDTUsingPOST.');
        }

        if (errorCode === null || errorCode === undefined) {
            throw new Error('Required parameter errorCode was null or undefined when calling onVTPaymentBookingTMDTUsingPOST.');
        }

        if (merchantCode === null || merchantCode === undefined) {
            throw new Error('Required parameter merchantCode was null or undefined when calling onVTPaymentBookingTMDTUsingPOST.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling onVTPaymentBookingTMDTUsingPOST.');
        }

        if (paymentStatus === null || paymentStatus === undefined) {
            throw new Error('Required parameter paymentStatus was null or undefined when calling onVTPaymentBookingTMDTUsingPOST.');
        }

        if (transAmount === null || transAmount === undefined) {
            throw new Error('Required parameter transAmount was null or undefined when calling onVTPaymentBookingTMDTUsingPOST.');
        }

        if (vtTransactionId === null || vtTransactionId === undefined) {
            throw new Error('Required parameter vtTransactionId was null or undefined when calling onVTPaymentBookingTMDTUsingPOST.');
        }

        if (checkSum === null || checkSum === undefined) {
            throw new Error('Required parameter checkSum was null or undefined when calling onVTPaymentBookingTMDTUsingPOST.');
        }

        if (contentType === null || contentType === undefined) {
            throw new Error('Required parameter contentType was null or undefined when calling onVTPaymentBookingTMDTUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billcode !== undefined && billcode !== null) {
            queryParameters = queryParameters.set('billcode', <any>billcode);
        }
        if (custMsisdn !== undefined && custMsisdn !== null) {
            queryParameters = queryParameters.set('cust_msisdn', <any>custMsisdn);
        }
        if (errorCode !== undefined && errorCode !== null) {
            queryParameters = queryParameters.set('error_code', <any>errorCode);
        }
        if (merchantCode !== undefined && merchantCode !== null) {
            queryParameters = queryParameters.set('merchant_code', <any>merchantCode);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('order_id', <any>orderId);
        }
        if (paymentStatus !== undefined && paymentStatus !== null) {
            queryParameters = queryParameters.set('payment_status', <any>paymentStatus);
        }
        if (transAmount !== undefined && transAmount !== null) {
            queryParameters = queryParameters.set('trans_amount', <any>transAmount);
        }
        if (vtTransactionId !== undefined && vtTransactionId !== null) {
            queryParameters = queryParameters.set('vt_transaction_id', <any>vtTransactionId);
        }
        if (checkSum !== undefined && checkSum !== null) {
            queryParameters = queryParameters.set('check_sum', <any>checkSum);
        }

        let headers = this.defaultHeaders;
        if (contentType !== undefined && contentType !== null) {
            headers = headers.set('Content-Type', String(contentType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/viettel/tmdt/booking/payment`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateBookingCTT
     * 
     * @param billcode billcode
     * @param merchantCode merchant_code
     * @param orderId order_id
     * @param checkSum check_sum
     * @param contentType Content-Type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateBookingCTTUsingPOST(billcode: string, merchantCode: string, orderId: string, checkSum: string, contentType: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public validateBookingCTTUsingPOST(billcode: string, merchantCode: string, orderId: string, checkSum: string, contentType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public validateBookingCTTUsingPOST(billcode: string, merchantCode: string, orderId: string, checkSum: string, contentType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public validateBookingCTTUsingPOST(billcode: string, merchantCode: string, orderId: string, checkSum: string, contentType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billcode === null || billcode === undefined) {
            throw new Error('Required parameter billcode was null or undefined when calling validateBookingCTTUsingPOST.');
        }

        if (merchantCode === null || merchantCode === undefined) {
            throw new Error('Required parameter merchantCode was null or undefined when calling validateBookingCTTUsingPOST.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling validateBookingCTTUsingPOST.');
        }

        if (checkSum === null || checkSum === undefined) {
            throw new Error('Required parameter checkSum was null or undefined when calling validateBookingCTTUsingPOST.');
        }

        if (contentType === null || contentType === undefined) {
            throw new Error('Required parameter contentType was null or undefined when calling validateBookingCTTUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billcode !== undefined && billcode !== null) {
            queryParameters = queryParameters.set('billcode', <any>billcode);
        }
        if (merchantCode !== undefined && merchantCode !== null) {
            queryParameters = queryParameters.set('merchant_code', <any>merchantCode);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('order_id', <any>orderId);
        }
        if (checkSum !== undefined && checkSum !== null) {
            queryParameters = queryParameters.set('check_sum', <any>checkSum);
        }

        let headers = this.defaultHeaders;
        if (contentType !== undefined && contentType !== null) {
            headers = headers.set('Content-Type', String(contentType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/viettel/ctt/booking/validate`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateBookingTMDT
     * 
     * @param billcode billcode
     * @param merchantCode merchant_code
     * @param orderId order_id
     * @param checkSum check_sum
     * @param contentType Content-Type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateBookingTMDTUsingPOST(billcode: string, merchantCode: string, orderId: string, checkSum: string, contentType: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public validateBookingTMDTUsingPOST(billcode: string, merchantCode: string, orderId: string, checkSum: string, contentType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public validateBookingTMDTUsingPOST(billcode: string, merchantCode: string, orderId: string, checkSum: string, contentType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public validateBookingTMDTUsingPOST(billcode: string, merchantCode: string, orderId: string, checkSum: string, contentType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (billcode === null || billcode === undefined) {
            throw new Error('Required parameter billcode was null or undefined when calling validateBookingTMDTUsingPOST.');
        }

        if (merchantCode === null || merchantCode === undefined) {
            throw new Error('Required parameter merchantCode was null or undefined when calling validateBookingTMDTUsingPOST.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling validateBookingTMDTUsingPOST.');
        }

        if (checkSum === null || checkSum === undefined) {
            throw new Error('Required parameter checkSum was null or undefined when calling validateBookingTMDTUsingPOST.');
        }

        if (contentType === null || contentType === undefined) {
            throw new Error('Required parameter contentType was null or undefined when calling validateBookingTMDTUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (billcode !== undefined && billcode !== null) {
            queryParameters = queryParameters.set('billcode', <any>billcode);
        }
        if (merchantCode !== undefined && merchantCode !== null) {
            queryParameters = queryParameters.set('merchant_code', <any>merchantCode);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('order_id', <any>orderId);
        }
        if (checkSum !== undefined && checkSum !== null) {
            queryParameters = queryParameters.set('check_sum', <any>checkSum);
        }

        let headers = this.defaultHeaders;
        if (contentType !== undefined && contentType !== null) {
            headers = headers.set('Content-Type', String(contentType));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/viettel/tmdt/booking/validate`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
