import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MustMatch2} from "@gtd/helpers";
import {
  AgentResourceService,
  AgentTO,
  CustomerResourceService,
  GetUserProfileFacade
} from "@gtd/api-services/b2b-client";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatSnackBar} from "@angular/material";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MY_FORMATS} from "@gtd/components/datepicker";
import {Subscription} from "rxjs";
import {SnackbarComponent} from "@gtd/components/snackbar";

@Component({
  selector: 'gtd-profile-update-b2b',
  templateUrl: './profile-update-b2b.component.html',
  styleUrls: ['./profile-update-b2b.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class ProfileUpdateB2bComponent implements OnInit {
  @Input() agencyCA: any;
  userProfile: any;
  formProfile?: FormGroup;
  appName = localStorage.getItem('appName');
  today = new Date();
  isLoading: boolean;
  agentProfile: AgentTO;
  subscriptions = new Subscription();
  constructor(
    private formBuilder: FormBuilder,
    private agentResourceService: AgentResourceService,
    private customerResourceService: CustomerResourceService,
    private getUserProfileFacade: GetUserProfileFacade,
    private _snackBar: MatSnackBar,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.getUserProfileFacade.allGetUserProfile$.subscribe(userProfile => {
        if (userProfile && userProfile.id) {
          this.userProfile = userProfile;
          this.agentResourceService.getAgentUsingGET(userProfile.id).subscribe(agent => {
            this.agentProfile = agent;
            this.formProfile.patchValue({
              id: agent.id,
              email: agent.email,
              phoneNumber1: agent.phoneNumber,
              agencyName: agent.agencyName,
              agentName: agent.agentName,
              agentCACode:
                userProfile.agencyCACodes.length &&
                userProfile.agencyCACodes[0].agentCACode
                  ? userProfile.agencyCACodes[0].agentCACode
                  : ''
            });
          })
          this.customerResourceService.searchObjectsUsingGET4(
            '', '', '',
            userProfile.email).subscribe(traveller => {
            if(traveller.content && traveller.content.length) {
              this.formProfile.patchValue({
                defaultCustomerId: traveller.content[0].id,
                lastName: traveller.content[0].lastName,
                firstName: traveller.content[0].firstName,
                dob: traveller.content[0].dob,
                gender: traveller.content[0].gender,
              })
            }
          })
        }
      })
    );
  }

  // TODO check duplicate traveller
  submitForm() {
    if (this.formProfile.valid && this.agentProfile && this.userProfile) {
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'user.updating-information', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
      this.agentResourceService.updateAgentUsingPUT({
        ...this.agentProfile,
        id: this.userProfile.id,
        defaultAgent: this.userProfile.defaultAgent,
        defaultCustomerId: this.formProfile.controls['defaultCustomerId'].value,
        phoneNumber: this.formProfile.controls['phoneNumber1'].value,
        dob: this.formProfile.controls['dob'].value,
        firstName: this.formProfile.controls['firstName'].value,
        lastName: this.formProfile.controls['lastName'].value,
        gender: this.formProfile.controls['gender'].value,
      }).subscribe(update => {
        this._snackBar.openFromComponent(SnackbarComponent, {
          data: { message: 'user.update-success', icon: 'check' },
          panelClass: 'snackbar-success',
          verticalPosition: 'top',
          duration: 2000
        });
        this.isLoading = false;
      })
    }
  }

  private createForm() {
    this.formProfile = this.formBuilder.group(
      {
        id: ['', Validators.required],
        defaultCustomerId: ['', Validators.required],
        customerCode: [''],
        profileId: [''],
        lastName: [''],
        firstName: [''],
        agencyName: [{ value: '', disabled: true }],
        agentName: [{ value: '', disabled: true }],
        email: [{ value: '', disabled: true }, Validators.email],
        phoneNumber1: ['', Validators.pattern('^[0-9]*$')],
        dob: [''],
        gender: [''],
        address1: [''],
        country: [''],
        documentType: [''],
        documentNumber: [''],
        nationality: [''],
        expiredDate: [''],
        agentCACode: [{ value: '', disabled: true }]
      },
      {
        validators: [
          MustMatch2(!!this.appName, 'lastName'),
          MustMatch2(!!this.appName, 'firstName'),
          MustMatch2(!!this.appName, 'profileId'),
          MustMatch2(!!this.appName, 'agentName')
        ]
      }
    );
  }
}
