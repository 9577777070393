import {FormGroup, ValidatorFn, ValidationErrors, FormArray} from '@angular/forms';

export function TcbValidateField(controlName: string): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control = formGroup.controls[controlName];
    const buyerMobileControl = formGroup.controls['buyerMobile'];
    const buyerIdCardNoControl = formGroup.controls['buyerIdCardNo'];
    const recipientPassportNoControl = formGroup.controls['recipientPassport'];

    // List of controls to validate
    const controlsToValidate = [buyerMobileControl, buyerIdCardNoControl, recipientPassportNoControl];

    // Exit early if any of the controls already have errors other than 'mustMatch'
    for (const ctrl of controlsToValidate) {
      if (ctrl.errors && !ctrl.errors.mustMatch) {
        return null;
      }
    }

    // Check if the primary control has a value
    if (control.value) {
      // Set 'mustMatch' error if the related control does not have a value
      controlsToValidate.forEach(ctrl => {
        if (!ctrl.value) {
          ctrl.setErrors({ mustMatch: true });
        }
      });
    } else {
      // Clear 'mustMatch' errors if the primary control does not have a value
      controlsToValidate.forEach(ctrl => {
        if (ctrl.errors && ctrl.errors.mustMatch) {
          delete ctrl.errors.mustMatch;
          if (Object.keys(ctrl.errors).length === 0) {
            ctrl.setErrors(null);
          }
        }
      });
    }

    return null;
  };
}

export function TcbValidateFieldMobile(controlName: string, attributes: FormArray): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control = formGroup.controls[controlName];
    console.log(attributes);
    let countMobile = 0;
    attributes.controls.forEach(
      attribute => {
        if(attribute.get('buyerMobile').value === control.value &&
          attribute.get('isEnable').value) {
          countMobile = countMobile+1;
        }
      }
    );
    console.log(countMobile);
    // Check if the primary control has a value
    if (countMobile > 1) {
      control.setErrors({ mustMatch: true });
    } else {
      control.setErrors(null);
    }

    return null;
  };
}
