import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { TourSearchActivityAvailablePartialState } from './tour-search-activity-available.reducer';
import { tourSearchActivityAvailableQuery } from './tour-search-activity-available.selectors';
import {
  LoadTourSearchActivityAvailable,
  ResetTourSearchActivityAvailable,
  TourSearchActivityAvailableRQ
} from './tour-search-activity-available.actions';

@Injectable()
export class TourSearchActivityAvailableFacade {
  loaded$ = this.store.pipe(select(tourSearchActivityAvailableQuery.getLoaded));
  allTourSearchActivityAvailable$ = this.store.pipe(
    select(tourSearchActivityAvailableQuery.getAllTourSearchActivityAvailable)
  );
  selectedTourSearchActivityAvailable$ = this.store.pipe(
    select(
      tourSearchActivityAvailableQuery.getSelectedTourSearchActivityAvailable
    )
  );

  constructor(private store: Store<TourSearchActivityAvailablePartialState>) {}

  loadAll(payload: TourSearchActivityAvailableRQ) {
    this.store.dispatch(new LoadTourSearchActivityAvailable(payload));
  }

  resetAll() {
    this.store.dispatch(new ResetTourSearchActivityAvailable());
  }
}
