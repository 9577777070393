import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation
} from '@angular/core';
import {
  DEFAULT_PAGINATION_OFFSET,
  encryptSearchAPI,
  LowfareSearchQueryParam
} from '@gtd/helpers';
import { Observable, Subscription } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { FlightSearchFacade } from './flight-search/flight-search/+state/flight-search.facade';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AvailableFlightsFacade } from './flight-search/flight-cache-search/+state/available-flights.facade';
import { LowestPriceFlightsFacade } from './flight-search/flight-lowest-price/+state/lowest-price-flights.facade';
import { FlightFilterFacade } from './flight-search/flight-filter/+state/flight-filter.facade';
import { FlightFilterState } from './flight-search/flight-filter/+state/flight-filter.reducer';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  AirItineraryInfo,
  AirRevalidateRS,
  AirTicketsResourceService,
  GroupPricedItinerary,
  ItineraryInfoVM,
  PricedItinerary,
  TicketDraftBookingRS
} from '@gtd/b2c-client';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { MatDialog, MatSnackBar } from '@angular/material';
import { GroupItineraryFacade } from './flight-search/group-itinerary/+state/group-itinerary.facade';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '@env/environment';
import { BreadcrumbBar } from '@gtd/components/breadcrumb-bar';
import { SelectItemSrvService } from './select-item-srv.service';
import { SubscriptionService } from './flight-search/flight-search/+state/subscription.service';
import { BehaviorSubject } from 'rxjs';
import { GaTrackingService } from '@gtd/ga-tracking';

@Component({
  selector: 'gtd-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class SearchResultComponent implements OnInit, OnDestroy {
  allAvailableFlights$ = this.availableFlightsFacade.allAvailableFlights$;
  loaded$ = this.availableFlightsFacade.loaded$;
  loadedFlightSearch$ = this.flightSearchFacade.loaded$;
  allFlightSearch$ = this.flightSearchFacade.allFlightSearch$;
  getErrorFlightSearch$ = this.flightSearchFacade.getErrorFlightSearch$;
  lowestPriceLoaded$ = this.lowestPriceFlightsFacade.loaded$;
  resetPageSelected = 0;
  currentFilter$ = this.flightFilterFacade.currentFilter$;
  formSearchResult?: FormGroup;
  loadingInternational: boolean;

  searchParams: LowfareSearchQueryParam = {};
  navigationSubscription: any;
  isCreateBooking: boolean;
  appName = localStorage.getItem('appName');

  itemFlightSelect: number;
  newTab: any = null;
  flightType: string;
  flightDirectionSubject$ = new BehaviorSubject<string>('D');
  flightDirection$ = this.flightDirectionSubject$.asObservable();
  flightInformationSubject$ = new BehaviorSubject<any>({});
  flightInformation$ = this.flightInformationSubject$.asObservable();
  groupId: string;
  isInternationalReturnFlight = false;
  private subscription: Subscription = new Subscription();

  away_airline: string;
  return_airline: string;

  breadcrumbBars: BreadcrumbBar[] = [
    {
      name: 'global.homepage',
      isRouterLink: true,
      routerLink: '/'
    },
    {
      isRouterLink: false,
      name: 'global.flight'
    }
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private flightSearchFacade: FlightSearchFacade,
    private activatedRoute: ActivatedRoute,
    private availableFlightsFacade: AvailableFlightsFacade,
    private lowestPriceFlightsFacade: LowestPriceFlightsFacade,
    private flightFilterFacade: FlightFilterFacade,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private airTicketsResourceService: AirTicketsResourceService,
    private groupItineraryFacade: GroupItineraryFacade,
    private _fb: FormBuilder,
    private deviceService: DeviceDetectorService,
    private selectItemSrvService: SelectItemSrvService,
    private subService: SubscriptionService,
    private _gaTrackingService: GaTrackingService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };

    this.navigationSubscription = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          this.router.navigated = false;
          window.scrollTo(0, 0);
        }
      }
    });
    this._listenLowFareSearch();
    this.createForm();
    this.startTimer();

    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(param => {
        this._handleQueryFromParams(param);
      })
    );
    this.subscription.add(
      this.allAvailableFlights$.subscribe(data => {
        if (
          data.groupPricedItineraries &&
          data.groupPricedItineraries.length > 0
        ) {
          this.flightInformationSubject$.next(data.groupPricedItineraries[0]);
        } else {
          this._gaTrackingService.track('F_SearchResult_Empty');
        }
      })
    );
    this.subscription.add(
      this.loaded$.subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          this.document.documentElement.scrollTo({
            top: 0
          });
        }
      })
    );

    this.subscription.add(
      this.lowestPriceFlightsFacade.allLowestPriceFlights$.subscribe(
        allLowestPriceFlights => {
          this.selectItemSrvService.unsetIsLoaded(-1);
          if (
            allLowestPriceFlights &&
            Object.keys(allLowestPriceFlights).length > 0
          ) {
            if (!allLowestPriceFlights.searchId) {
              this.availableFlightsFacade.resetAll();
            }
          }
        }
      )
    );

    this.subscription.add(
      this.selectItemSrvService.getIsLoaded.subscribe(loaded => {
        if (loaded) {
          this.itemFlightSelect = loaded;
        }
      })
    );
    this.subscription.add(
      this.groupItineraryFacade.allGroupItinerary$.subscribe(
        allGroupItinerary => {
          if (allGroupItinerary && allGroupItinerary.success) {
            this.selectItemSrvService.unsetIsLoaded(-1);
          }
        }
      )
    );

    this.subscription.add(
      this.formSearchResult.valueChanges.subscribe(value => {
        if (value && value.listSelected && value.listSelected.from) {
          this.away_airline = value.listSelected.from.itemFlight.airline;
          let itineraryInfoVM = new Array<ItineraryInfoVM>();
          itineraryInfoVM.push({
            fareSourceCode:
              value.listSelected.from.pricedItinerary.airItineraryPricingInfo
                .fareSourceCode,
            groupId: value.listSelected.from.itemFlight.groupId,
            searchId: value.listSelected.from.searchId
          });
          this.flightType = value.listSelected.from.itemFlight.flightType;
          this.groupId = value.listSelected.from.itemFlight.groupId;
          if (
            value.listSelected.roundType ===
              PricedItinerary.DirectionIndEnum.DEPARTURE &&
            this.searchParams.routeType === 'ROUNDTRIP'
          ) {
            this.flightDirectionSubject$.next('R');
            this.resetPageSelected = this.resetPageSelected + 1;
            this.flightFilterFacade.resetFilterRoundTrip(
              value.listSelected.from.searchId,
              value.listSelected.from.itemFlight.flightType
            );
            if (
              value.listSelected.from.itemFlight.flightType ===
              GroupPricedItinerary.FlightTypeEnum.DOMESTIC
            ) {
              this._listenLowFareSearch2({
                groupId: value.listSelected.from.itemFlight.groupId,
                airlineCode: value.listSelected.from.itemFlight.airline,
                fareSourceCode:
                  value.listSelected.from.pricedItinerary
                    .airItineraryPricingInfo.fareSourceCode,
                supplierCode: value.listSelected.from.itemFlight.airSupplier,
                searchId: value.listSelected.from.searchId
              });
            } else {
              this.loadingInternational = true;
            }
          } else {
            this.isCreateBooking = true;
            this._snackBar.openFromComponent(SnackbarComponent, {
              data: {
                message: 'snack-bar.initializing-journey',
                loading: true
              },
              panelClass: 'snackbar-loading',
              verticalPosition: 'top'
            });
            this.airTicketsResourceService
              .revalidateAirFareUsingPOST({
                fareSourceCode:
                  value.listSelected.from.pricedItinerary
                    .airItineraryPricingInfo.fareSourceCode,
                groupId: value.listSelected.from.itemFlight.groupId,
                searchId: value.listSelected.from.searchId
              })
              .subscribe((result: AirRevalidateRS) => {
                if (result.valid) {
                  if (this.searchParams.routeType === 'ROUNDTRIP') {
                    this.return_airline =
                      value.listSelected.to.itemFlight.airline;
                    this.airTicketsResourceService
                      .revalidateAirFareUsingPOST({
                        fareSourceCode:
                          value.listSelected.to.pricedItinerary
                            .airItineraryPricingInfo.fareSourceCode,
                        groupId: value.listSelected.to.itemFlight.groupId,
                        searchId: value.listSelected.to.searchId
                      })
                      .subscribe(revalidateAirFare => {
                        if (revalidateAirFare.valid) {
                          if (
                            value.listSelected.from.itemFlight.flightType ===
                            GroupPricedItinerary.FlightTypeEnum.DOMESTIC
                          ) {
                            itineraryInfoVM.push({
                              fareSourceCode:
                                value.listSelected.to.pricedItinerary
                                  .airItineraryPricingInfo.fareSourceCode,
                              groupId: value.listSelected.to.itemFlight.groupId,
                              searchId: value.listSelected.to.searchId
                            });
                          } else {
                            itineraryInfoVM = new Array<ItineraryInfoVM>();
                            itineraryInfoVM.push({
                              fareSourceCode:
                                value.listSelected.to.pricedItinerary
                                  .airItineraryPricingInfo.fareSourceCode,
                              groupId: value.listSelected.to.itemFlight.groupId,
                              searchId: value.listSelected.to.searchId
                            });
                          }
                          this.createDraftBooking(itineraryInfoVM);
                        } else {
                        }
                      });
                  } else {
                    this.createDraftBooking(itineraryInfoVM);
                  }
                } else {
                  this._gaTrackingService.track('', {
                    message: 'Thông tin về chuyến bay đã thay đổi'
                  });
                  this.dialog.open(DialogMessageComponent, {
                    data: {
                      btn: [
                        { btnText: 'booking-result.reload', btnReload: true }
                      ],
                      content: 'booking-result.message-error-validate-air-fare',
                      heading: 'booking-result.validate-air-fare-error',
                      icon: 'exclamation-triangle',
                      messageType: 'error'
                    },
                    panelClass: 'dialog-message',
                    disableClose: true
                  });
                }
                this.isCreateBooking = true;
              });
          }
        }
      })
    );
  }

  private createForm() {
    this.formSearchResult = this._fb.group({
      listSelected: new FormControl()
    });
  }

  startTimer() {
    this.subscription.add(
      Observable.interval(1000).subscribe(x => {
        if (x === 900) {
          this.dialog.open(DialogMessageComponent, {
            data: {
              btn: [{ btnText: 'booking-result.reload', btnReload: true }],
              content: 'booking-result.page-expired-message',
              heading: 'booking-result.page-expired',
              messageType: 'success',
              hideCloseBtn: true
            },
            panelClass: 'dialog-do-confirm',
            position: {
              top: '150px'
            },
            disableClose: true
          });
        }
      })
    );
  }

  createDraftBooking(itineraryInfos: Array<ItineraryInfoVM>) {
    this.airTicketsResourceService
      .createDraftBookingUsingPOST({ itineraryInfos: itineraryInfos })
      .subscribe(
        (draftBooking: TicketDraftBookingRS) => {
          if (draftBooking.success) {
            this._gaTrackingService.track('ticket_make_booking');
            this._gaTrackingService.track('make_booking');
            let properties = {
              booking_number: draftBooking.bookingCode.bookingNumber,
              flight_type: draftBooking.roundType,
              away_airline: this.away_airline,
              from_city: this.searchParams.originCode,
              to_city: this.searchParams.destinationCode,
              passenger_total:
                this.searchParams.adutsQtt +
                this.searchParams.childrenQtt +
                this.searchParams.infantsQtt,
              passenger_adult: this.searchParams.adutsQtt,
              passenger_child: this.searchParams.childrenQtt,
              passenger_infant: this.searchParams.infantsQtt,
              created_at: new Date()
            } as any;
            if (draftBooking.roundType === 'RoundTrip') {
              properties = {
                ...properties,
                return_airline: this.return_airline,
                return_from_city: this.searchParams.destinationCode,
                return_to_city: this.searchParams.originCode
              };
            }
            // this.filumAnalytics.track(
            //   'Flight Draft Booking Created',
            //   properties
            // );

            this.selectItemSrvService.unsetIsLoaded(-1);
            if (isPlatformBrowser(this.platformId)) {
              window.parent.postMessage({ action: 'scrollToTop' }, '*');
            }
            this._snackBar.dismiss();
            const url = this.router.serializeUrl(
              this.router.createUrlTree([
                `/checkout/flight?bookingNumber=${
                  draftBooking.bookingCode.bookingNumber
                }`
              ])
            );

            if (this.appName === 'B2B2C') {
              const newWindow = window.open('', '_self');
              newWindow.location.href = decodeURIComponent(url);
            } else {
              if (this.deviceService.isDesktop() === true) {
                this.newTab.location.href = decodeURIComponent(url);
              } else {
                this.router
                  .navigate(['/checkout/flight'], {
                    queryParams: {
                      bookingNumber: draftBooking.bookingCode.bookingNumber
                    }
                  })
                  .then(() => {});
              }
            }
          } else {
            if (this.newTab !== null) {
              this.newTab.close();
            }
            this._snackBar.dismiss();
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'booking-result.reload', btnReload: true }],
                content: 'booking-result.message-error-validate-air-fare',
                heading: 'booking-result.validate-air-fare-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          }
          this.isCreateBooking = false;
        },
        () => {
          if (this.newTab !== null) {
            this.newTab.close();
          }
          this._snackBar.dismiss();
          this.dialog.open(DialogMessageComponent, {
            data: {
              btn: [{ btnText: 'booking-result.reload', btnReload: true }],
              content: 'booking-result.message-error-validate-air-fare',
              heading: 'booking-result.validate-air-fare-error',
              icon: 'exclamation-triangle',
              messageType: 'error'
            },
            panelClass: 'dialog-message',
            disableClose: true
          });
        }
      );
  }

  private _handleQueryFromParams(param) {
    this.searchParams = {
      adutsQtt: param.adult,
      cabinClass: param.filterCabinClass ? param.filterCabinClass : 'E',
      childrenQtt: param.child,
      departureDate: param.fromDate,
      destinationCode: param.to,
      infantsQtt: param.infant,
      originCode: param.from,
      page: 0,
      returntureDate: param.toDate ? param.toDate : '',
      routeType: param.roundType,
      size: DEFAULT_PAGINATION_OFFSET,
      time: new Date().getTime().toString()
    };
    this.searchParams.key = encryptSearchAPI(this.searchParams);

    this.flightSearchFacade.loadAll(this.searchParams);
  }

  changeDepartureFlight(flightDirection: any) {
    this.flightDirectionSubject$.next(flightDirection);
    this.resetPageSelected = this.resetPageSelected + 1;

    this.isInternationalReturnFlight = false;
    if (this.flightType !== 'INTERNATIONAL') {
      this._listenLowFareSearch2(null, true);
    }
    this.formSearchResult.reset();
  }

  private _listenFilterState() {
    this.currentFilter$.subscribe((value: FlightFilterState) => {
      if (value && value.searchID) {
        this.flightDirection$.subscribe(flightDir => {
          if (
            this.groupId &&
            this.flightType === 'INTERNATIONAL' &&
            flightDir === 'R'
          ) {
            this.selectItemSrvService.unsetIsLoaded(-1);
            this.availableFlightsFacade.loadInternationalFlights({
              id: this.groupId,
              airSearchId: {
                searchId: value.searchID,
                filter: {
                  airlineOptions: value.airlineOptions,
                  arrivalDateTimeOptions: [],
                  arrivalDateTimeReturnOptions:
                    value.arrivalDateTimeReturnOptions,
                  cabinClassOptions:
                    value.cabinClassOptions &&
                    value.cabinClassOptions.length > 0
                      ? value.cabinClassOptions
                      : [],
                  departureDateTimeOptions: [],
                  departureDateTimeReturnOptions:
                    value.departureDateTimeOptions,
                  flightType: value.flightType,
                  groupId: this.groupId,
                  minPrice: value.minPrice,
                  priceItineraryId: value.priceItineraryId,
                  step: '2',
                  stopOptions: [],
                  ticketPolicyOptions: []
                },
                departureItinerary: {
                  fareSourceCode: this.formSearchResult.value.listSelected.from
                    .pricedItinerary.airItineraryPricingInfo.fareSourceCode
                }
              },
              page: value.page,
              size: value.size,
              sort: value.sort ? value.sort : ['stopOptions,asc']
            });
            this.isInternationalReturnFlight = true;
          } else {
            this.availableFlightsFacade.loadAll({
              filter: {
                searchId: value.searchID,
                filter: {
                  cabinClassOptions:
                    value.cabinClassOptions &&
                    value.cabinClassOptions.length > 0
                      ? value.cabinClassOptions
                      : [],
                  ticketPolicyOptions: value.ticketPolicyOptions,
                  airlineOptions: value.airlineOptions,
                  stopOptions: value.stopOptions,
                  step: value.departureItinerary ? '2' : value.step,
                  flightType: value.flightType,
                  departureDateTimeOptions: value.departureDateTimeOptions,
                  arrivalDateTimeReturnOptions:
                    value.arrivalDateTimeReturnOptions,
                  arrivalDateTimeOptions: value.arrivalDateTimeOptions,
                  priceItineraryId: value.priceItineraryId,
                  minPrice: value.minPrice,
                  loadMore: value.loadMore,
                  groupId: value.groupId,
                  departureDateTimeReturnOptions:
                    value.departureDateTimeReturnOptions
                },
                departureItinerary: value.departureItinerary
              },
              includeEquivfare: false,
              page: value.page,
              size: value.size,
              sort: value.sort
                ? value.sort
                : this.flightType === 'INTERNATIONAL'
                ? ['stopOptions,asc']
                : ['departureDate,asc']
            });
            this.isInternationalReturnFlight = false;
          }
        });
      }
    });
  }
  private _listenLowFareSearch() {
    this.subscription.add(
      this.allFlightSearch$.subscribe(data => {
        if (Object.keys(data).length > 0) {
          if (
            data.groupPricedItineraries &&
            data.groupPricedItineraries.length > 0
          ) {
            if (
              data.groupPricedItineraries[0].flightType ===
              GroupPricedItinerary.FlightTypeEnum.INTERNATIONAL
            ) {
              this.flightType =
                GroupPricedItinerary.FlightTypeEnum.INTERNATIONAL;
              this.flightFilterFacade.changeFlightType(
                GroupPricedItinerary.FlightTypeEnum.INTERNATIONAL
              );
            }
          }
          this.flightFilterFacade.changeSearchID(data.searchId);
          this._listenFilterState();
          this.lowestPriceFlightsFacade.loadAll({
            searchId: data.searchId,
            departureItinerary: null
          });
        }
      })
    );
  }
  private _listenLowFareSearch2(
    departureItinerary?: AirItineraryInfo,
    reset?: boolean
  ) {
    this.subscription.add(
      this.allFlightSearch$.subscribe(data => {
        if (Object.keys(data).length > 0) {
          this.flightFilterFacade.selectDeparture({
            searchID: reset ? data.searchId : data.returnSearchId,
            departureItinerary: departureItinerary
          });
          this.lowestPriceFlightsFacade.loadAll({
            searchId: reset ? data.searchId : data.returnSearchId,
            departureItinerary: departureItinerary
          });
        }
      })
    );
  }

  getNewTab(newTab: any) {
    this.newTab = newTab;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    this.flightDirectionSubject$.unsubscribe();
    this.flightInformationSubject$.unsubscribe();
    this.subService.unsubscribeSubject$.next();
    this.flightSearchFacade.resetAll();
    this.availableFlightsFacade.resetAll();
    this.lowestPriceFlightsFacade.resetAll();
    this.flightFilterFacade.resetFilter();
  }
}
