import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AffiliatesPartialState } from './affiliates.reducer';
import { affilatesQuery } from './affiliates.selectors';
import { LoadAffiliates } from './affiliates.actions';

@Injectable()
export class AffiliatesFacade {
  loaded$ = this.store.pipe(select(affilatesQuery.getLoaded));
  allAffilates$ = this.store.pipe(select(affilatesQuery.getAllAffilates));
  selectedAffilates$ = this.store.pipe(
    select(affilatesQuery.getSelectedAffilates)
  );

  constructor(private store: Store<AffiliatesPartialState>) {}

  getBookingTagsByName(aff_name: string) {
    this.store.dispatch(new LoadAffiliates(aff_name));
  }
}
