import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  LIBRARYAVATAR_FEATURE_KEY,
  LibraryAvatarState
} from './library-avatar.reducer';

// Lookup the 'LibraryAvatar' feature state managed by NgRx
const getLibraryAvatarState = createFeatureSelector<LibraryAvatarState>(
  LIBRARYAVATAR_FEATURE_KEY
);

const getLoaded = createSelector(
  getLibraryAvatarState,
  (state: LibraryAvatarState) => state.loaded
);
const getError = createSelector(
  getLibraryAvatarState,
  (state: LibraryAvatarState) => state.error
);

const getAllLibraryAvatar = createSelector(
  getLibraryAvatarState,
  getLoaded,
  (state: LibraryAvatarState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getLibraryAvatarState,
  (state: LibraryAvatarState) => state.selectedId
);
const getSelectedLibraryAvatar = createSelector(
  getAllLibraryAvatar,
  getSelectedId,
  (libraryAvatar, id) => {
    return libraryAvatar ? Object.assign(null, libraryAvatar) : undefined;
  }
);

export const libraryAvatarQuery = {
  getLoaded,
  getError,
  getAllLibraryAvatar,
  getSelectedLibraryAvatar
};
