import {Component, Input, OnInit} from '@angular/core';
import {BookingInfo} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-flight-booking-offline-info',
  templateUrl: './flight-booking-offline-info.component.html',
  styleUrls: ['./flight-booking-offline-info.component.scss']
})
export class FlightBookingOfflineInfoComponent implements OnInit {
  @Input() bookingInfo?: BookingInfo;

  constructor() { }

  ngOnInit() {
  }

}
