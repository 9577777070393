/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AgentProfileDTO } from './agentProfileDTO';
import { LinkedAccountDTO } from './linkedAccountDTO';
import { OTPServiceReq } from './oTPServiceReq';
import { OTPServiceRes } from './oTPServiceRes';


export interface AgencyTO { 
    accountId?: number;
    activeNow?: boolean;
    address1: string;
    address2?: string;
    agencyClass?: string;
    agencyCode?: string;
    agencyNumber?: string;
    agentAdminId?: number;
    agentAdminUsername?: string;
    agentProfiles?: Array<AgentProfileDTO>;
    avatar?: string;
    avatarContentType?: string;
    balance?: number;
    branchIdOfParentAgency?: number;
    branchNameOfParentAgency?: string;
    cash?: number;
    cellPhone?: string;
    chkEditable?: boolean;
    cityCode?: string;
    cityName?: string;
    commission?: number;
    confirmPasswd?: string;
    contactPerson1?: string;
    contactPerson2?: string;
    cooperationClass?: AgencyTO.CooperationClassEnum;
    countryCode?: string;
    countryName?: string;
    createdDate?: Date;
    credit?: number;
    currentAgency?: boolean;
    email?: string;
    faxNumber?: string;
    fullName?: string;
    id?: number;
    liability?: number;
    linkedAccounts?: Array<LinkedAccountDTO>;
    loginId?: number;
    note?: string;
    notify?: AgencyTO.NotifyEnum;
    orgCode?: string;
    otpServiceReq?: OTPServiceReq;
    otpServiceRes?: OTPServiceRes;
    passwd?: string;
    phoneContact1?: string;
    phoneContact2?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    purchasingPower?: number;
    representativeFirstName?: string;
    representativeLastName?: string;
    representativeMail?: string;
    representativeName?: string;
    representativePhone?: string;
    revenue?: number;
    revenueNoBalance?: number;
    rootAgency?: boolean;
    sentMail?: number;
    shortName?: string;
    stateCode?: string;
    stateName?: string;
    status?: AgencyTO.StatusEnum;
    subMail?: string;
    taxCode?: string;
    verify?: AgencyTO.VerifyEnum;
}
export namespace AgencyTO {
    export type CooperationClassEnum = 'ca' | 'sa' | 'cn' | 'ps' | 'nv' | 'bd' | 'b2b2b';
    export const CooperationClassEnum = {
        Ca: 'ca' as CooperationClassEnum,
        Sa: 'sa' as CooperationClassEnum,
        Cn: 'cn' as CooperationClassEnum,
        Ps: 'ps' as CooperationClassEnum,
        Nv: 'nv' as CooperationClassEnum,
        Bd: 'bd' as CooperationClassEnum,
        B2b2b: 'b2b2b' as CooperationClassEnum
    };
    export type NotifyEnum = 'ENABLE' | 'DISABLE';
    export const NotifyEnum = {
        ENABLE: 'ENABLE' as NotifyEnum,
        DISABLE: 'DISABLE' as NotifyEnum
    };
    export type StatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'OTHER';
    export const StatusEnum = {
        ACTIVATED: 'ACTIVATED' as StatusEnum,
        DEACTIVATED: 'DEACTIVATED' as StatusEnum,
        OTHER: 'OTHER' as StatusEnum
    };
    export type VerifyEnum = 'ENABLE' | 'DISABLE';
    export const VerifyEnum = {
        ENABLE: 'ENABLE' as VerifyEnum,
        DISABLE: 'DISABLE' as VerifyEnum
    };
}
