import {Injectable} from '@angular/core';
import {AccountFacade} from "./account.facade";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor(
    private accountFacade: AccountFacade
  ) {}

  getAccountUsingGet(): Observable<boolean> {
    this.accountFacade.getAccountUsingGet();
    return of(true);
  }
}
