/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AgencyBalance } from '../model/agencyBalance';
import { AgencyChannelDTO } from '../model/agencyChannelDTO';
import { AgencyRevenue } from '../model/agencyRevenue';
import { AgencyTO } from '../model/agencyTO';
import { CountAgency } from '../model/countAgency';
import { InputStreamResource } from '../model/inputStreamResource';
import { LiteAgency } from '../model/liteAgency';
import { OtaResultOfRegisterAgencyResultDTO } from '../model/otaResultOfRegisterAgencyResultDTO';
import { PageOfAgencyTO } from '../model/pageOfAgencyTO';
import { ResponseEntity } from '../model/responseEntity';
import { TopupInfo } from '../model/topupInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AgencyResourceService {

    protected basePath = 'https://uat-b2b.gotadi.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addTopupInfo
     *
     * @param topupInfo topupInfo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTopupInfoUsingPOST(topupInfo: TopupInfo, observe?: 'body', reportProgress?: boolean): Observable<TopupInfo>;
    public addTopupInfoUsingPOST(topupInfo: TopupInfo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TopupInfo>>;
    public addTopupInfoUsingPOST(topupInfo: TopupInfo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TopupInfo>>;
    public addTopupInfoUsingPOST(topupInfo: TopupInfo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (topupInfo === null || topupInfo === undefined) {
            throw new Error('Required parameter topupInfo was null or undefined when calling addTopupInfoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TopupInfo>(`${this.basePath}/api/agencies/topups`,
            topupInfo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * agencyBalance
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public agencyBalanceUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<AgencyBalance>;
    public agencyBalanceUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyBalance>>;
    public agencyBalanceUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyBalance>>;
    public agencyBalanceUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyBalance>(`${this.basePath}/api/agencies/balance`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * agencyRevenue
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public agencyRevenueUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<AgencyRevenue>;
    public agencyRevenueUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyRevenue>>;
    public agencyRevenueUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyRevenue>>;
    public agencyRevenueUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyRevenue>(`${this.basePath}/api/agencies/revenue`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changeStatus
     *
     * @param id id
     * @param agencyTO agencyTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeStatusUsingPUT(id: number, agencyTO: AgencyTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeStatusUsingPUT(id: number, agencyTO: AgencyTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeStatusUsingPUT(id: number, agencyTO: AgencyTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeStatusUsingPUT(id: number, agencyTO: AgencyTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling changeStatusUsingPUT.');
        }

        if (agencyTO === null || agencyTO === undefined) {
            throw new Error('Required parameter agencyTO was null or undefined when calling changeStatusUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/agencies/${encodeURIComponent(String(id))}/status`,
            agencyTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * countAgencies
     *
     * @param agencyStatus agencyStatus
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countAgenciesUsingGET(agencyStatus?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<CountAgency>>;
    public countAgenciesUsingGET(agencyStatus?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CountAgency>>>;
    public countAgenciesUsingGET(agencyStatus?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CountAgency>>>;
    public countAgenciesUsingGET(agencyStatus?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyStatus) {
            agencyStatus.forEach((element) => {
                queryParameters = queryParameters.append('agencyStatus', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<CountAgency>>(`${this.basePath}/api/agencies/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createAgency
     *
     * @param agencyTO agencyTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgencyUsingPOST(agencyTO: AgencyTO, observe?: 'body', reportProgress?: boolean): Observable<AgencyTO>;
    public createAgencyUsingPOST(agencyTO: AgencyTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyTO>>;
    public createAgencyUsingPOST(agencyTO: AgencyTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyTO>>;
    public createAgencyUsingPOST(agencyTO: AgencyTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyTO === null || agencyTO === undefined) {
            throw new Error('Required parameter agencyTO was null or undefined when calling createAgencyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AgencyTO>(`${this.basePath}/api/agencies`,
            agencyTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createAutoAgency
     *
     * @param agencyTO agencyTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAutoAgencyUsingPOST(agencyTO: AgencyTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createAutoAgencyUsingPOST(agencyTO: AgencyTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createAutoAgencyUsingPOST(agencyTO: AgencyTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createAutoAgencyUsingPOST(agencyTO: AgencyTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyTO === null || agencyTO === undefined) {
            throw new Error('Required parameter agencyTO was null or undefined when calling createAutoAgencyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/auto/agencies`,
            agencyTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteAgency
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgencyUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAgencyUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAgencyUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAgencyUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAgencyUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/agencies/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportAgencyInfo
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportAgencyInfoUsingGET(observe?: 'body', reportProgress?: boolean): Observable<InputStreamResource>;
    public exportAgencyInfoUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InputStreamResource>>;
    public exportAgencyInfoUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InputStreamResource>>;
    public exportAgencyInfoUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InputStreamResource>(`${this.basePath}/api/export/agencies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findAllSubAgencies
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllSubAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyTO>>;
    public findAllSubAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyTO>>>;
    public findAllSubAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyTO>>>;
    public findAllSubAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyTO>>(`${this.basePath}/api/agencies/all-subagency`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgencyByCode
     *
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgencyByCodeUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyTO>;
    public getAgencyByCodeUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyTO>>;
    public getAgencyByCodeUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyTO>>;
    public getAgencyByCodeUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getAgencyByCodeUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyTO>(`${this.basePath}/api/agencies/by-code`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgencyTopup
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgencyTopupUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<TopupInfo>;
    public getAgencyTopupUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TopupInfo>>;
    public getAgencyTopupUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TopupInfo>>;
    public getAgencyTopupUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAgencyTopupUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TopupInfo>(`${this.basePath}/api/agencies/topups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgency
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgencyUsingGET(id: number, skipAgentTO?: boolean, observe?: 'body', reportProgress?: boolean): Observable<AgencyTO>;
    public getAgencyUsingGET(id: number, skipAgentTO?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyTO>>;
    public getAgencyUsingGET(id: number, skipAgentTO?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyTO>>;
    public getAgencyUsingGET(id: number, skipAgentTO?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAgencyUsingGET.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (skipAgentTO !== undefined && skipAgentTO !== null) {
          queryParameters = queryParameters.set('skipAgentTO', <any>skipAgentTO);
        }
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyTO>(`${this.basePath}/api/agencies/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllAgencies
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyTO>>;
    public getAllAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyTO>>>;
    public getAllAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyTO>>>;
    public getAllAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyTO>>(`${this.basePath}/api/agencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllNotifyStatus
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllNotifyStatusUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getAllNotifyStatusUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getAllNotifyStatusUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getAllNotifyStatusUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/agencies/get-all-notify-status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllVerifyStatus
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllVerifyStatusUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getAllVerifyStatusUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getAllVerifyStatusUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getAllVerifyStatusUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/agencies/get-all-verify-status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getChannelAgency
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelAgencyUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyChannelDTO>>;
    public getChannelAgencyUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyChannelDTO>>>;
    public getChannelAgencyUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyChannelDTO>>>;
    public getChannelAgencyUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyChannelDTO>>(`${this.basePath}/api/agencies/get-channels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCurrentAgency
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCurrentAgencyUsingGET(observe?: 'body', reportProgress?: boolean): Observable<AgencyTO>;
    public getCurrentAgencyUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyTO>>;
    public getCurrentAgencyUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyTO>>;
    public getCurrentAgencyUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyTO>(`${this.basePath}/api/agencies/current-agency`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getFinancialAgencies
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFinancialAgenciesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyTO>>;
    public getFinancialAgenciesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyTO>>>;
    public getFinancialAgenciesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyTO>>>;
    public getFinancialAgenciesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyTO>>(`${this.basePath}/api/agencies/financial-agency`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getLiteAgencies
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLiteAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<LiteAgency>>;
    public getLiteAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LiteAgency>>>;
    public getLiteAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LiteAgency>>>;
    public getLiteAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<LiteAgency>>(`${this.basePath}/api/agencies/lite-agencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * populateAgencyMailTemplate
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public populateAgencyMailTemplateUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public populateAgencyMailTemplateUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public populateAgencyMailTemplateUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public populateAgencyMailTemplateUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/agencies/populate-agency-mail-template`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * reAssignRevenue
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reAssignRevenueUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reAssignRevenueUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reAssignRevenueUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reAssignRevenueUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/agencies/assign-revenue`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * registerAgency
     *
     * @param agencyTO agencyTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerAgencyUsingPOST(agencyTO: AgencyTO, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfRegisterAgencyResultDTO>;
    public registerAgencyUsingPOST(agencyTO: AgencyTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfRegisterAgencyResultDTO>>;
    public registerAgencyUsingPOST(agencyTO: AgencyTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfRegisterAgencyResultDTO>>;
    public registerAgencyUsingPOST(agencyTO: AgencyTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyTO === null || agencyTO === undefined) {
            throw new Error('Required parameter agencyTO was null or undefined when calling registerAgencyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfRegisterAgencyResultDTO>(`${this.basePath}/api/agencies/register`,
            agencyTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * reportFinancialInfo
     *
     * @param agencyName agencyName
     * @param email email
     * @param status status
     * @param phoneNumber phoneNumber
     * @param agencyClass agencyClass
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param agencyNumber agencyNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportFinancialInfoUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public reportFinancialInfoUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public reportFinancialInfoUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public reportFinancialInfoUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyName !== undefined && agencyName !== null) {
            queryParameters = queryParameters.set('agencyName', <any>agencyName);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }
        if (agencyClass !== undefined && agencyClass !== null) {
            queryParameters = queryParameters.set('agencyClass', <any>agencyClass);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (agencyNumber !== undefined && agencyNumber !== null) {
            queryParameters = queryParameters.set('agencyNumber', <any>agencyNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/report-finance/agencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * report
     *
     * @param agencyName agencyName
     * @param numberOfDayBackwards numberOfDayBackwards
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportUsingGET(agencyName?: string, numberOfDayBackwards?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyTO>>;
    public reportUsingGET(agencyName?: string, numberOfDayBackwards?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyTO>>>;
    public reportUsingGET(agencyName?: string, numberOfDayBackwards?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyTO>>>;
    public reportUsingGET(agencyName?: string, numberOfDayBackwards?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyName !== undefined && agencyName !== null) {
            queryParameters = queryParameters.set('agencyName', <any>agencyName);
        }
        if (numberOfDayBackwards !== undefined && numberOfDayBackwards !== null) {
            queryParameters = queryParameters.set('numberOfDayBackwards', <any>numberOfDayBackwards);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyTO>>(`${this.basePath}/api/report/agencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * resendEmailRegister
     *
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendEmailRegisterUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyTO>;
    public resendEmailRegisterUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyTO>>;
    public resendEmailRegisterUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyTO>>;
    public resendEmailRegisterUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling resendEmailRegisterUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyTO>(`${this.basePath}/api/agency/resend-email-register/${encodeURIComponent(String(agencyCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjects
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageOfAgencyTO>;
    public searchObjectsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfAgencyTO>>;
    public searchObjectsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfAgencyTO>>;
    public searchObjectsUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PageOfAgencyTO>(`${this.basePath}/api/_search/agencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sendRecentRegisteredAgency
     *
     * @param agencyName agencyName
     * @param email email
     * @param status status
     * @param phoneNumber phoneNumber
     * @param agencyClass agencyClass
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param agencyNumber agencyNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendRecentRegisteredAgencyUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public sendRecentRegisteredAgencyUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public sendRecentRegisteredAgencyUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public sendRecentRegisteredAgencyUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyName !== undefined && agencyName !== null) {
            queryParameters = queryParameters.set('agencyName', <any>agencyName);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }
        if (agencyClass !== undefined && agencyClass !== null) {
            queryParameters = queryParameters.set('agencyClass', <any>agencyClass);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (agencyNumber !== undefined && agencyNumber !== null) {
            queryParameters = queryParameters.set('agencyNumber', <any>agencyNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/send-recent-registered-agency/agencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * syncCacheLiteAgency
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public syncCacheLiteAgencyUsingGET(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public syncCacheLiteAgencyUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public syncCacheLiteAgencyUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public syncCacheLiteAgencyUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/agency/sync-lite-agency`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateAgency
     *
     * @param agencyTO agencyTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAgencyUsingPUT(agencyTO: AgencyTO, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public updateAgencyUsingPUT(agencyTO: AgencyTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public updateAgencyUsingPUT(agencyTO: AgencyTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public updateAgencyUsingPUT(agencyTO: AgencyTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyTO === null || agencyTO === undefined) {
            throw new Error('Required parameter agencyTO was null or undefined when calling updateAgencyUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResponseEntity>(`${this.basePath}/api/agencies`,
            agencyTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
