import {Action} from '@ngrx/store';
import {RegisterPhoneNumberVM} from "@gtd/b2c-client";

export enum PhoneRegisterStateActionTypes {
  LoadPhoneRegisterState = '[PhoneRegisterState] Load PhoneRegisterState',
  PhoneRegisterStateLoaded = '[PhoneRegisterState] PhoneRegisterState Loaded',
  PhoneRegisterStateLoadError = '[PhoneRegisterState] PhoneRegisterState Load Error',
  ResetAllPhoneRegisterState = '[PhoneRegisterAccount] PhoneRegisterAccount Reset All',
  LoadDestroyPhoneRegisterState = '[DestroyPhoneRegister] Load Destroy RegisterPhoneState',

  LoadPhoneOTPRegisterState = '[PhoneOTPRegisterState] Load PhoneOTPRegisterState',
  PhoneOTPRegisterStateLoaded = '[PhoneOTPRegisterState] PhoneOTPRegisterState Loaded',
  PhoneOTPRegisterStateLoadError = '[PhoneOTPRegisterState] PhoneOTPRegisterState Load Error',
  LoadDestroyPhoneOTPRegisterState = '[DestroyPhoneOTPRegister] Load Destroy RegisterPhoneOTPState'

}

export class LoadPhoneRegisterState implements Action {
  readonly type = PhoneRegisterStateActionTypes.LoadPhoneRegisterState;
  constructor(public payload: RegisterPhoneNumberVM) {}
}

export class PhoneRegisterStateLoadError implements Action {
  readonly type = PhoneRegisterStateActionTypes.PhoneRegisterStateLoadError;
  constructor(public payload: any) {}
}

export class PhoneRegisterStateLoaded implements Action {
  readonly type = PhoneRegisterStateActionTypes.PhoneRegisterStateLoaded;
  constructor(public payload: any) {}
}

export class LoadDestroyPhoneRegisterState implements Action {
  readonly type = PhoneRegisterStateActionTypes.LoadDestroyPhoneRegisterState;
}

export class ResetAllPhoneRegisterState implements Action {
  readonly type = PhoneRegisterStateActionTypes.ResetAllPhoneRegisterState;
}


export class LoadPhoneOTPRegisterState implements Action {
  readonly type = PhoneRegisterStateActionTypes.LoadPhoneOTPRegisterState;
  constructor(public payload: RegisterPhoneNumberVM) {}
}

export class PhoneOTPRegisterStateLoadError implements Action {
  readonly type = PhoneRegisterStateActionTypes.PhoneOTPRegisterStateLoadError;
  constructor(public payload: any) {}
}

export class PhoneOTPRegisterStateLoaded implements Action {
  readonly type = PhoneRegisterStateActionTypes.PhoneOTPRegisterStateLoaded;
  constructor(public payload: any) {}
}

export class LoadDestroyPhoneOTPRegisterState implements Action {
  readonly type = PhoneRegisterStateActionTypes.LoadDestroyPhoneOTPRegisterState;
}
export type PhoneRegisterStateAction =
  | LoadPhoneRegisterState
  | PhoneRegisterStateLoaded
  | PhoneRegisterStateLoadError
  | ResetAllPhoneRegisterState
  | LoadDestroyPhoneOTPRegisterState
  | LoadPhoneOTPRegisterState
  | PhoneOTPRegisterStateLoaded
  | PhoneOTPRegisterStateLoadError
  | LoadDestroyPhoneRegisterState;

export const fromPhoneRegisterStateActions = {
  LoadPhoneRegisterState,
  PhoneRegisterStateLoaded,
  PhoneRegisterStateLoadError,
  ResetAllPhoneRegisterState,
  LoadDestroyPhoneRegisterState,
  LoadPhoneOTPRegisterState,
  PhoneOTPRegisterStateLoaded,
  PhoneOTPRegisterStateLoadError,
  LoadDestroyPhoneOTPRegisterState
};
