import { Action } from '@ngrx/store';
import {CustomerAvatarDTO} from "@gtd/api-services/customer-service";

export enum AvatarActionTypes {
  LoadAvatar = '[Avatar] Load Avatar',
  AvatarLoaded = '[Avatar] Avatar Loaded',
  AvatarLoadError = '[Avatar] Avatar Load Error'
}

export class LoadAvatar implements Action {
  readonly type = AvatarActionTypes.LoadAvatar;
  constructor(public payload: number) {}
}

export class AvatarLoadError implements Action {
  readonly type = AvatarActionTypes.AvatarLoadError;
  constructor(public payload: CustomerAvatarDTO) {}
}

export class AvatarLoaded implements Action {
  readonly type = AvatarActionTypes.AvatarLoaded;
  constructor(public payload: CustomerAvatarDTO) {}
}

export type AvatarAction = LoadAvatar | AvatarLoaded | AvatarLoadError;

export const fromAvatarActions = {
  LoadAvatar,
  AvatarLoaded,
  AvatarLoadError
};
