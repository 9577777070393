import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { environment } from '@env/environment';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

@Component({
  selector: 'gtd-bottom-sheet-down-app',
  templateUrl: './bottom-sheet-down-app.component.html',
  styleUrls: ['./bottom-sheet-down-app.component.scss']
})
export class BottomSheetDownAppComponent implements OnInit {
  deviceInfo: DeviceInfo;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetDownAppComponent>,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  download() {
    this._bottomSheetRef.dismiss();
    // localStorage.setItem('action_download', 'true');
    let newWindow = window.open('', '_self');
    switch (this.deviceInfo.os) {
      case 'iOS':
        newWindow.location.href = decodeURIComponent(
          environment.downloadApp.ios
        );
        break;
      case 'Android':
        newWindow.location.href = decodeURIComponent(
          environment.downloadApp.android
        );
        break;
      default:
        break;
    }
  }

  close() {
    this._bottomSheetRef.dismiss();
    // localStorage.setItem('action_download', 'true');
  }
}
