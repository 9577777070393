import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {
  initialState as registerStateInitialState,
  reducer,
  REGISTERSTATE_FEATURE_KEY
} from './+state/register-state.reducer';
import {RegisterStateEffects} from './+state/register-state.effects';
import {RegisterStateFacade} from './+state/register-state.facade';
import {NxModule} from '@nrwl/angular';
import {AccountResourceService, BASE_PATH} from "@gtd/b2c-client";
import {environment} from "@env/environment";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(REGISTERSTATE_FEATURE_KEY, reducer, {
      initialState: registerStateInitialState
    }),
    EffectsModule.forFeature([RegisterStateEffects])
  ],
  providers: [{provide: BASE_PATH, useValue: environment.api.b2cClient}, RegisterStateFacade, AccountResourceService]
})
export class RegisterStateModule {}
