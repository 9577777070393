import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';
import { DatepickerService, LunarDateExport } from '../datepicker.service';
import { ControlContainer } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  CustomDatepickerI18n,
  TranslateDatePicker
} from '../date-format.directive';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gtd-tour-datepicker',
  templateUrl: './tour-datepicker.component.html',
  styleUrls: ['./tour-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    TranslateDatePicker,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class TourDatepickerComponent implements OnInit {
  @ViewChild('datepicker1', { static: true }) datePicker1: NgbInputDatepicker;
  @Input() displayMonths: number;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() defaultFromDate: Date;
  @Input() defaultToDate: Date;
  @Input() labelFormDate: string;
  @Input() labelToDate: string;
  @Input() bgWhite: boolean;
  @Input() sizeMd: boolean;
  @Input() type = 'flight';
  hoveredDate: NgbDate;
  date: NgbDate;
  minNgbDate: NgbDate;
  maxNgbDate: NgbDate;
  outsideDays: 'visible' | 'hidden';
  dateLabel = 'now';
  realDate: Date;
  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private datepickerService: DatepickerService,
    public controlContainer: ControlContainer,
    private translate: TranslateService,
    private translateDatePicker: TranslateDatePicker,
    private deviceService: DeviceDetectorService
  ) {
    this.translate.onLangChange.subscribe(lang => {
      this.translateDatePicker.language = lang.lang;
    });
  }

  ngOnInit() {
    this.displayMonths = 1;
    this.outsideDays =
      this.deviceService.isDesktop() || this.deviceService.isTablet()
        ? 'visible'
        : 'hidden';
    if (this.minDate) {
      this.minNgbDate = this.convertNgbDate(this.minDate);
    }
    if (this.maxDate) {
      this.maxNgbDate = this.convertNgbDate(this.maxDate);
    }
    if (this.defaultFromDate) {
      this.date = this.convertNgbDate(this.defaultFromDate);
    }
  }

  convertNgbDate(date: Date): NgbDate {
    return this.calendar.getNext(
      {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        after(other: NgbDateStruct): boolean {
          return true;
        },
        before(other: NgbDateStruct): boolean {
          return true;
        },
        equals(other: NgbDateStruct): boolean {
          return true;
        }
      },
      'd',
      0
    );
  }
  updateDateData(date: NgbDate) {
    this.date = date;
  }
  isActive(date: NgbDate) {
    if (date.equals(this.date)) {
      return true;
    }
    return false;
  }
  onDateSelection(date: NgbDate) {
    this.realDate = new Date(Date.UTC(date.year, date.month - 1, date.day));
    this.date = date;
    this.dateLabel = `${date.day}/${date.month}/${date.year}`;
    this.datePicker1.toggle();
    this.controlContainer.control.setValue({
      date: new Date(
        Date.UTC(this.date.year, this.date.month - 1, this.date.day)
      )
    });
  }
  //   checkPosition(date: NgbDate) {
  //     if (date.equals(this.fromDate) && date.equals(this.toDate)) {
  //       return 'both';
  //     } else if (date.equals(this.fromDate)) {
  //       return 'first';
  //     } else if (date.equals(this.toDate)) {
  //       return 'last';
  //     }
  //   }
  isBackDay(date: NgbDate) {
    if (this.hoveredDate && this.hoveredDate.after(this.date))
      return date.equals(this.date);
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  getDateLunar(date: NgbDate): LunarDateExport {
    const offset = new Date().getTimezoneOffset();
    return this.datepickerService.convertSolar2Lunar(
      date.day,
      date.month,
      date.year,
      +7
    );
  }

  newDateFormat(date: NgbDate): Date {
    let dateString = date.year + '-' + date.month + '-' + date.day;
    return new Date(dateString);
  }
}

//TODO custom date format
