import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { TourCreateDraftBookingPartialState } from './tour-create-draft-booking.reducer';
import { tourCreateDraftBookingQuery } from './tour-create-draft-booking.selectors';
import {
  LoadTourCreateDraftBooking,
  ResetTourCreateDraftBooking
} from './tour-create-draft-booking.actions';
import { CreateDraftBookingActivityPayload } from '@gtd/b2c-client';

@Injectable()
export class TourCreateDraftBookingFacade {
  loaded$ = this.store.pipe(select(tourCreateDraftBookingQuery.getLoaded));
  allTourCreateDraftBooking$ = this.store.pipe(
    select(tourCreateDraftBookingQuery.getAllTourCreateDraftBooking)
  );
  tourCreateDraftBookingError$ = this.store.pipe(
    select(tourCreateDraftBookingQuery.getError)
  );
  selectedTourCreateDraftBooking$ = this.store.pipe(
    select(tourCreateDraftBookingQuery.getSelectedTourCreateDraftBooking)
  );

  constructor(private store: Store<TourCreateDraftBookingPartialState>) {}

  loadAll(payload: CreateDraftBookingActivityPayload) {
    this.store.dispatch(new LoadTourCreateDraftBooking(payload));
  }

  reset() {
    this.store.dispatch(new ResetTourCreateDraftBooking());
  }
}
