import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LookupEffects } from './+state/lookup.effects';
import { LookupFacade } from './+state/lookup.facade';
import { NxModule } from '@nrwl/angular';
import {
  LOOKUP_FEATURE_KEY,
  initialState as lookupInitialState,
  reducer
} from "./+state/lookup.reducer";
import {BASE_PATH, LookupResourceService} from "@gtd/b2c-client";
import {environment} from "@env/environment";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(LOOKUP_FEATURE_KEY, reducer, {
      initialState: lookupInitialState
    }),
    EffectsModule.forFeature([LookupEffects])
  ],
  providers: [{provide: BASE_PATH, useValue: environment.api.b2cClient}, LookupFacade, LookupResourceService]
})
export class LookupModule {}
