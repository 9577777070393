import { NotifyUserMessageResourceService, UtilitysrvModule } from '@gtd/api-services/utilitysrv';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NotificationsEffects } from './+get-all/notifications.effects';
import { NotificationsFacade } from './+get-all/notifications.facade';
import { NxModule } from '@nrwl/angular';
import {
  BASE_PATH,
  MessageItemResourceService
} from '@gtd/api-services/customer-service';
import { environment } from '@env/environment';

import {
  initialState as notificationsInitialState,
  notificationsReducer,
  NOTIFICATIONS_FEATURE_KEY
} from './+get-all/notifications.reducer';

import {
  initialState as countUnreadInitialState,
  countUnreadReducer,
  COUNTUNREAD_FEATURE_KEY
} from './+count-unread/count-unread.reducer';
import { CountUnreadEffects } from './+count-unread/count-unread.effects';
import { CountUnreadFacade } from './+count-unread/count-unread.facade';

import {
  initialState as markAsUnreadInitialState,
  markAsUnreadReducer,
  MARKASUNREAD_FEATURE_KEY
} from './+mark-as-unread/mark-as-unread.reducer';
import { MarkAsUnreadEffects } from './+mark-as-unread/mark-as-unread.effects';
import { MarkAsUnreadFacade } from './+mark-as-unread/mark-as-unread.facade';

import {
  initialState as deleteNotificationInitialState,
  deleteNotificationReducer,
  DELETENOTIFICATION_FEATURE_KEY
} from './+delete-notification/delete-notification.reducer';
import { DeleteNotificationEffects } from './+delete-notification/delete-notification.effects';
import { DeleteNotificationFacade } from './+delete-notification/delete-notification.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UtilitysrvModule,
    NxModule.forRoot(),
    StoreModule.forFeature(NOTIFICATIONS_FEATURE_KEY, notificationsReducer, {
      initialState: notificationsInitialState
    }),
    StoreModule.forFeature(COUNTUNREAD_FEATURE_KEY, countUnreadReducer, {
      initialState: countUnreadInitialState
    }),
    StoreModule.forFeature(MARKASUNREAD_FEATURE_KEY, markAsUnreadReducer, {
      initialState: markAsUnreadInitialState
    }),
    StoreModule.forFeature(DELETENOTIFICATION_FEATURE_KEY, deleteNotificationReducer, {
      initialState: deleteNotificationInitialState
    }),
    EffectsModule.forFeature([
      NotificationsEffects,
      CountUnreadEffects,
      MarkAsUnreadEffects,
      DeleteNotificationEffects
    ])
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.customerService },
    NotificationsFacade,
    MessageItemResourceService,
    CountUnreadFacade,
    MarkAsUnreadFacade,
    DeleteNotificationFacade,
    NotifyUserMessageResourceService
  ]
})
export class StateNotificationModule {}
