/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MarkupZoneLocationDTO { 
    createdBy?: string;
    createdDate?: Date;
    description?: string;
    id?: number;
    locationCode?: string;
    markupZoneId?: number;
    name?: string;
    productType?: MarkupZoneLocationDTO.ProductTypeEnum;
    updatedBy?: string;
    updatedDate?: Date;
    zoneCode?: string;
}
export namespace MarkupZoneLocationDTO {
    export type ProductTypeEnum = 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR';
    export const ProductTypeEnum = {
        AIR: 'AIR' as ProductTypeEnum,
        HOTEL: 'HOTEL' as ProductTypeEnum,
        AIRCOMBO: 'AIRCOMBO' as ProductTypeEnum,
        HOTELCOMBO: 'HOTELCOMBO' as ProductTypeEnum,
        AIRCOMBOVIETJET: 'AIRCOMBOVIETJET' as ProductTypeEnum,
        TOUR: 'TOUR' as ProductTypeEnum
    };
}
