/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { BookingTravellerRQ } from '../model/bookingTravellerRQ';
import { CreateDraftBookingActivityPayload } from '../model/createDraftBookingActivityPayload';
import { OtaResultOfBooking } from '../model/otaResultOfBooking';
import { OtaResultOfListOfTourUnitRatePlanDTO } from '../model/otaResultOfListOfTourUnitRatePlanDTO';
import { OtaResultOfTourActivityAvailableDTO } from '../model/otaResultOfTourActivityAvailableDTO';
import { OtaResultOfTourActivityAvailableIntroPageDTO } from '../model/otaResultOfTourActivityAvailableIntroPageDTO';
import { OtaResultOfTourFilterOptionDTO } from '../model/otaResultOfTourFilterOptionDTO';
import { OtaResultOfTourSuggestSearchPageDTO } from '../model/otaResultOfTourSuggestSearchPageDTO';
import { OtaResultOfUpdateBookingStatusRS } from '../model/otaResultOfUpdateBookingStatusRS';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TourResourceService {

    protected basePath = 'https://10.7.71.50:8588/b2c_gateway';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addBookingTraveller
     * 
     * @param bookingTravellerRQ bookingTravellerRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addBookingTravellerUsingPOST4(bookingTravellerRQ: BookingTravellerRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfUpdateBookingStatusRS>;
    public addBookingTravellerUsingPOST4(bookingTravellerRQ: BookingTravellerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfUpdateBookingStatusRS>>;
    public addBookingTravellerUsingPOST4(bookingTravellerRQ: BookingTravellerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfUpdateBookingStatusRS>>;
    public addBookingTravellerUsingPOST4(bookingTravellerRQ: BookingTravellerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingTravellerRQ === null || bookingTravellerRQ === undefined) {
            throw new Error('Required parameter bookingTravellerRQ was null or undefined when calling addBookingTravellerUsingPOST4.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfUpdateBookingStatusRS>(`${this.basePath}/api/tour-activity/add-booking-traveller`,
            bookingTravellerRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createDraftBooking
     * 
     * @param payload payload
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDraftBookingUsingPOST4(payload: CreateDraftBookingActivityPayload, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfBooking>;
    public createDraftBookingUsingPOST4(payload: CreateDraftBookingActivityPayload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfBooking>>;
    public createDraftBookingUsingPOST4(payload: CreateDraftBookingActivityPayload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfBooking>>;
    public createDraftBookingUsingPOST4(payload: CreateDraftBookingActivityPayload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling createDraftBookingUsingPOST4.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfBooking>(`${this.basePath}/api/tour-activity/create-draft-booking`,
            payload,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getFilterOptions
     * 
     * @param language language
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilterOptionsUsingGET1(language: 'vi' | 'en', observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfTourFilterOptionDTO>;
    public getFilterOptionsUsingGET1(language: 'vi' | 'en', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfTourFilterOptionDTO>>;
    public getFilterOptionsUsingGET1(language: 'vi' | 'en', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfTourFilterOptionDTO>>;
    public getFilterOptionsUsingGET1(language: 'vi' | 'en', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getFilterOptionsUsingGET1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfTourFilterOptionDTO>(`${this.basePath}/api/tour-activity/filter-options`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getUnitPrices
     * 
     * @param activityId activityId
     * @param language language
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUnitPricesUsingGET(activityId: number, language: 'vi' | 'en', observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfTourUnitRatePlanDTO>;
    public getUnitPricesUsingGET(activityId: number, language: 'vi' | 'en', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfTourUnitRatePlanDTO>>;
    public getUnitPricesUsingGET(activityId: number, language: 'vi' | 'en', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfTourUnitRatePlanDTO>>;
    public getUnitPricesUsingGET(activityId: number, language: 'vi' | 'en', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling getUnitPricesUsingGET.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getUnitPricesUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (activityId !== undefined && activityId !== null) {
            queryParameters = queryParameters.set('activityId', <any>activityId);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfListOfTourUnitRatePlanDTO>(`${this.basePath}/api/tour-activity/unit-price`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchActivityAvailable
     * 
     * @param language language
     * @param searchCode searchCode
     * @param searchType searchType
     * @param categoryIds categoryIds
     * @param amenityIds amenityIds
     * @param themeIds themeIds
     * @param sortName sortName
     * @param sortOrder sortOrder
     * @param zoneId zoneId
     * @param saleChannel saleChannel
     * @param pageNumber pageNumber
     * @param pageSize Maximum value 250
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchActivityAvailableUsingGET(language: 'vi' | 'en', searchCode?: Array<string>, searchType?: 'AUTO' | 'DESTINATION' | 'CATEGORY' | 'ACTIVITY' | 'ALL' | 'KEYWORD', categoryIds?: Array<string>, amenityIds?: Array<string>, themeIds?: Array<string>, sortName?: string, sortOrder?: 'ASC' | 'DESC', zoneId?: string, saleChannel?: string, pageNumber?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfTourActivityAvailableIntroPageDTO>;
    public searchActivityAvailableUsingGET(language: 'vi' | 'en', searchCode?: Array<string>, searchType?: 'AUTO' | 'DESTINATION' | 'CATEGORY' | 'ACTIVITY' | 'ALL' | 'KEYWORD', categoryIds?: Array<string>, amenityIds?: Array<string>, themeIds?: Array<string>, sortName?: string, sortOrder?: 'ASC' | 'DESC', zoneId?: string, saleChannel?: string, pageNumber?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfTourActivityAvailableIntroPageDTO>>;
    public searchActivityAvailableUsingGET(language: 'vi' | 'en', searchCode?: Array<string>, searchType?: 'AUTO' | 'DESTINATION' | 'CATEGORY' | 'ACTIVITY' | 'ALL' | 'KEYWORD', categoryIds?: Array<string>, amenityIds?: Array<string>, themeIds?: Array<string>, sortName?: string, sortOrder?: 'ASC' | 'DESC', zoneId?: string, saleChannel?: string, pageNumber?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfTourActivityAvailableIntroPageDTO>>;
    public searchActivityAvailableUsingGET(language: 'vi' | 'en', searchCode?: Array<string>, searchType?: 'AUTO' | 'DESTINATION' | 'CATEGORY' | 'ACTIVITY' | 'ALL' | 'KEYWORD', categoryIds?: Array<string>, amenityIds?: Array<string>, themeIds?: Array<string>, sortName?: string, sortOrder?: 'ASC' | 'DESC', zoneId?: string, saleChannel?: string, pageNumber?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling searchActivityAvailableUsingGET.');
        }












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchCode) {
            searchCode.forEach((element) => {
                queryParameters = queryParameters.append('searchCode', <any>element);
            })
        }
        if (searchType !== undefined && searchType !== null) {
            queryParameters = queryParameters.set('searchType', <any>searchType);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }
        if (categoryIds) {
            categoryIds.forEach((element) => {
                queryParameters = queryParameters.append('categoryIds', <any>element);
            })
        }
        if (amenityIds) {
            amenityIds.forEach((element) => {
                queryParameters = queryParameters.append('amenityIds', <any>element);
            })
        }
        if (themeIds) {
            themeIds.forEach((element) => {
                queryParameters = queryParameters.append('themeIds', <any>element);
            })
        }
        if (sortName !== undefined && sortName !== null) {
            queryParameters = queryParameters.set('sortName', <any>sortName);
        }
        if (sortOrder !== undefined && sortOrder !== null) {
            queryParameters = queryParameters.set('sortOrder', <any>sortOrder);
        }
        if (zoneId !== undefined && zoneId !== null) {
            queryParameters = queryParameters.set('zoneId', <any>zoneId);
        }
        if (saleChannel !== undefined && saleChannel !== null) {
            queryParameters = queryParameters.set('saleChannel', <any>saleChannel);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('pageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfTourActivityAvailableIntroPageDTO>(`${this.basePath}/api/tour-activity/search-activity-available`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchActivityDetail
     * 
     * @param activityId activityId
     * @param language language
     * @param zoneId zoneId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchActivityDetailUsingGET(activityId: number, language: 'vi' | 'en', zoneId?: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfTourActivityAvailableDTO>;
    public searchActivityDetailUsingGET(activityId: number, language: 'vi' | 'en', zoneId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfTourActivityAvailableDTO>>;
    public searchActivityDetailUsingGET(activityId: number, language: 'vi' | 'en', zoneId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfTourActivityAvailableDTO>>;
    public searchActivityDetailUsingGET(activityId: number, language: 'vi' | 'en', zoneId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling searchActivityDetailUsingGET.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling searchActivityDetailUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (activityId !== undefined && activityId !== null) {
            queryParameters = queryParameters.set('activityId', <any>activityId);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }
        if (zoneId !== undefined && zoneId !== null) {
            queryParameters = queryParameters.set('zoneId', <any>zoneId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfTourActivityAvailableDTO>(`${this.basePath}/api/tour-activity/search-activity-detail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchKeyWord
     * 
     * @param keyword keyword
     * @param saleChannel saleChannel
     * @param pageNumber pageNumber
     * @param pageSize pageSize
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchKeyWordUsingGET1(keyword: string, saleChannel: string, pageNumber?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfTourSuggestSearchPageDTO>;
    public searchKeyWordUsingGET1(keyword: string, saleChannel: string, pageNumber?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfTourSuggestSearchPageDTO>>;
    public searchKeyWordUsingGET1(keyword: string, saleChannel: string, pageNumber?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfTourSuggestSearchPageDTO>>;
    public searchKeyWordUsingGET1(keyword: string, saleChannel: string, pageNumber?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (keyword === null || keyword === undefined) {
            throw new Error('Required parameter keyword was null or undefined when calling searchKeyWordUsingGET1.');
        }

        if (saleChannel === null || saleChannel === undefined) {
            throw new Error('Required parameter saleChannel was null or undefined when calling searchKeyWordUsingGET1.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (keyword !== undefined && keyword !== null) {
            queryParameters = queryParameters.set('keyword', <any>keyword);
        }
        if (saleChannel !== undefined && saleChannel !== null) {
            queryParameters = queryParameters.set('saleChannel', <any>saleChannel);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('pageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=UTF-8'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfTourSuggestSearchPageDTO>(`${this.basePath}/api/tour-activity/search-keyword`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
