import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'gtd-new-update-snack-bar',
  templateUrl: './new-update-snack-bar.component.html',
  styleUrls: ['./new-update-snack-bar.component.scss']
})
export class NewUpdateSnackBarComponent implements OnInit {
  constructor(public snackBarRef: MatSnackBarRef<NewUpdateSnackBarComponent>) {}

  ngOnInit() {}

  actionClick() {
    this.snackBarRef.closeWithAction();
  }
}
