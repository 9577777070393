import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinationAutocompleteComponent } from './destination-autocomplete.component';
import { GInputModule, GTypographyModule } from '@gotadi/design-system';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StateHotelModule } from '@gtd/hotel';
import { ClickOutsideModule } from '@gtd/directives/click-outside';
import { DestinationModule } from '@gtd/meta';
import {SanitizeModule} from "@gtd/pipes/sanitize";

@NgModule({
  imports: [
    CommonModule,
    GInputModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    StateHotelModule,
    ClickOutsideModule,
    GTypographyModule,
    DestinationModule,
    SanitizeModule
  ],
  declarations: [DestinationAutocompleteComponent],
  exports: [DestinationAutocompleteComponent]
})
export class DestinationAutocompleteModule {}
