import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TourSearchByKeywordFacade } from '@gtd/extra/tour/data-access';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'gtd-tour-search',
  templateUrl: './tour-search.component.html',
  styleUrls: ['./tour-search.component.scss']
})
export class TourSearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() hasAllCategoriesButton = true;
  @Input() noPadding = false;
  @Input() destinationName: string;
  @ViewChild('searchInput', { static: true }) searchElement: ElementRef;
  searchForm: FormGroup;
  tourSuggestion: any;
  showSuggestion = false;
  showResetButton = false;
  allTourSearchByKeyword$ = this._tourSearchByKeywordFacade
    .allTourSearchByKeyword$;
  allCategoriesSearch = new EventEmitter<boolean>();
  appName = localStorage.getItem('appName');

  constructor(
    private _tourSearchByKeywordFacade: TourSearchByKeywordFacade,
    public router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.searchForm = new FormGroup({
      search: new FormControl('')
    });
  }

  ngOnInit() {
    this.searchForm.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(value => {
        if (value.search && value.search.trim() !== '') {
          this._tourSearchByKeywordFacade.loadAll({
            keyword: value.search,
            saleChannel: this.appName
            // pageNumber: 0,
            // pageSize: 10
          });
        }
      });
    this._activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams.q) {
        this.searchForm.get('search').setValue(queryParams.q);
      }
    });
  }

  removeValue() {
    this.destinationName = '';
    this.searchForm.get('search').reset();
    this._tourSearchByKeywordFacade.resetAll();
    this.searchElement.nativeElement.focus();
  }

  allCategoriesListener() {
    this.allCategoriesSearch.emit(true);
    this.router.navigate(['/vui-choi-giai-tri/diem-den/tat-ca']);
  }

  searchKeyword() {
    this.router.navigate([`/vui-choi-giai-tri/diem-den/tim-kiem`], {
      queryParams: { q: this.searchForm.value.search }
    });
    this.showSuggestion = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.destinationName &&
      changes.destinationName.currentValue
    ) {
      this.searchForm
        .get('search')
        .patchValue(changes.destinationName.currentValue);
    }
  }

  ngOnDestroy(): void {
    this._tourSearchByKeywordFacade.resetAll();
  }

  resetForm() {
    this.searchForm.reset();
    this.showResetButton = false;
    this.tourSuggestion = null;
  }
}
