import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SEARCHUSERBOOKING_FEATURE_KEY,
  SearchUserBookingState
} from './search-user-booking.reducer';

// Lookup the 'SearchUserBooking' feature state managed by NgRx
const getSearchUserBookingState = createFeatureSelector<SearchUserBookingState>(
  SEARCHUSERBOOKING_FEATURE_KEY
);

const getLoaded = createSelector(
  getSearchUserBookingState,
  (state: SearchUserBookingState) => state.loaded
);
const getError = createSelector(
  getSearchUserBookingState,
  (state: SearchUserBookingState) => state.error
);

const getAllSearchUserBooking = createSelector(
  getSearchUserBookingState,
  getLoaded,
  (state: SearchUserBookingState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getTotalCount = createSelector(
  getSearchUserBookingState,
  getLoaded,
  (state: SearchUserBookingState, isLoaded) => {
    return isLoaded ? state.totalCount : null;
  }
);

const getSelectedId = createSelector(
  getSearchUserBookingState,
  (state: SearchUserBookingState) => state.selectedId
);
const getSelectedSearchUserBooking = createSelector(
  getAllSearchUserBooking,
  getSelectedId,
  (searchUserBooking, id) => {
    const result = searchUserBooking.find(it => it['id'] === id);
    return searchUserBooking ? Object.assign({}, searchUserBooking) : undefined;
  }
);


export const searchUserBookingQuery = {
  getLoaded,
  getError,
  getAllSearchUserBooking,
  getSelectedSearchUserBooking,
  getTotalCount
};
