import { AvatarAction, AvatarActionTypes } from './avatar.actions';
import {CustomerAvatarDTO} from "@gtd/api-services/customer-service";

export const AVATAR_FEATURE_KEY = 'avatar';

/**
 * Interface for the 'Avatar' data used in
 *  - AvatarState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface AvatarState {
  result: CustomerAvatarDTO; // list of Avatar; analogous to a sql normalized table
  selectedId?: string | number; // which Avatar record has been selected
  loaded: boolean; // has the Avatar list been loaded
  error?: any; // last none error (if any)
}

export interface AvatarPartialState {
  readonly [AVATAR_FEATURE_KEY]: AvatarState;
}

export const initialState: AvatarState = {
  result: null,
  loaded: false
};

export function reducer(
  state: AvatarState = initialState,
  action: AvatarAction
): AvatarState {
  switch (action.type) {
    case AvatarActionTypes.AvatarLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
