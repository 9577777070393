import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  CustomerTravellerDTO,
  CustomerTravellerMemberCardDTO,
  CustomerTravellerResourceService,
  ShortProfileFacade
} from "@gtd/api-services/customer-service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ProfileFacade} from "../../profile/+state/profile.facade";
import {MatDialog, MatSelect, MatSnackBar} from "@angular/material";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {ReplaySubject, Subject, Subscription} from "rxjs";
import {CustomerTravellerFacade} from "../../customer-traveller/+state/customer-traveller.facade";
import {DialogMessageComponent} from "@gtd/components/dialog-message";
import {PassengerService} from "../passenger.service";
import {CountryCodeDTO} from "@gtd/meta-client";
import {CountryFacade, LookupFacade} from "@gtd/meta";
import {takeUntil} from "rxjs/operators";
import {BreadcrumbBar} from "@gtd/components/breadcrumb-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountFacade, AccountResourceService, RequesterInfo} from "@gtd/b2c-client";
import {Location} from "@angular/common";
import {MustMatch2, RemoveStringAccented} from "@gtd/helpers";
import {AgentCustomerTO, CustomerResourceService} from "@gtd/api-services/b2b-client";
import * as moment from 'moment';

@Component({
  selector: 'gtd-edit-passenger',
  templateUrl: './edit-passenger.component.html',
  styleUrls: ['./edit-passenger.component.scss']
})
export class EditPassengerComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;
  @ViewChild('nationalitySelect', {static: true}) nationalitySelect: MatSelect;

  subscriptions = new Subscription();
  formProfile: FormGroup;
  isLoading = false;
  memberCardValid: boolean;
  memberCardDataUpdate: Array<CustomerTravellerMemberCardDTO>;
  passenger: CustomerTravellerDTO;
  passengerB2B: AgentCustomerTO;
  accountData: any;
  groupPanel = 0;
  shortProfile: RequesterInfo;
  pageType: 'Edit' | 'Add';
  today = new Date();

  appName = localStorage.getItem('appName');
  breadcrumbBars: BreadcrumbBar[] = [
    {
      name: 'global.homepage',
      isRouterLink: true,
      routerLink: '/'
    },
    {
      isRouterLink: false,
      name: 'account.passengers'
    }
  ]

  protected countries: CountryCodeDTO[];
  protected _onDestroy = new Subject<void>();
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountries: ReplaySubject<CountryCodeDTO[]> = new ReplaySubject<CountryCodeDTO[]>(1);

  constructor(
    private shortProfileFacade: ShortProfileFacade,
    private accountFacade: AccountFacade,
    private profileFacade: ProfileFacade,
    private lookupFacade: LookupFacade,
    private formBuilder: FormBuilder,
    private customerTravellerFacade: CustomerTravellerFacade,
    private _location: Location,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private countryFacade: CountryFacade,
    private customerTravellerResourceService: CustomerTravellerResourceService,
    private passengerService: PassengerService,
    private activatedRoute: ActivatedRoute,
    private accountResourceService: AccountResourceService,
    private customerResourceService: CustomerResourceService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe(param => {
        if (param.id && param.id !== 'add') {
          this.pageType = 'Edit';
          if(this.appName === "B2B") {
            this.customerResourceService.getCustomerUsingGET(param.id).subscribe(customer => {
              if (customer && customer.id) {
                this.passengerB2B = customer;
                this.formProfile.patchValue({
                  id: customer.id,
                  surName: RemoveStringAccented(customer.lastName),
                  firstName: RemoveStringAccented(customer.firstName),
                  email: customer.email,
                  phoneNumber1: customer.phoneNumber1,
                  dob: customer.dob? customer.dob : (customer.dateOfBirth? customer.dateOfBirth: null),
                  gender: customer.gender ? customer.gender : null,
                  address1: customer.address,
                  country: customer.country,
                  documentType: customer.documentType,
                  documentNumber: customer.passportNumber,
                  companyName: customer.companyName,
                  companyAddress: customer.companyAddress,
                  companyTaxCode: customer.companyTaxCode,
                  nationality: customer.nationality,
                  expiredDate: customer.expiredDate,
                  frequencyCards: customer.frequencyCards
                });
              }
            })
          } else {
            this.customerTravellerFacade.getCustomerTravellerUsingGet(param.id);
          }

        } else {
          this.createForm();
          this.pageType = 'Add';
        }
      })
    )
    this.countryFacade.loadAll({
      page: 0,
      size: 1000,
      sort: ['sortname,asc']
    })
    this.lookupFacade.loadAll({domainName: 'air_member_card', objectName: '', page: 0, size: 1000})
    this.createForm();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.shortProfileFacade.getShortProfile$.subscribe(shortProfile => {
        if (shortProfile) {
          this.shortProfile = shortProfile;
          this.formProfile.patchValue({
            customerCode: shortProfile.requesterCode,
            profileId: shortProfile.profileId,
          })
        }
      })
    )
    this.subscriptions.add(
      this.customerTravellerFacade.selectedUpdateCustomerTraveller$.subscribe(updateCustomer => {
        if(updateCustomer && updateCustomer.type === "Update") {
          this._snackBar.openFromComponent(SnackbarComponent, {
            data: {message: 'user.update-success', icon: 'check'},
            panelClass: 'snackbar-success',
            verticalPosition: "top",
            duration: 2000
          });
          this.router.navigate(['/user/passengers']);
        }
      })
    )
    this.subscriptions.add(
      this.passengerService.getMemberCardValid.subscribe(memberCardValid => {
        this.memberCardValid = memberCardValid;
      })
    )
    this.subscriptions.add(
      this.passengerService.getMemberCardData.subscribe((memberCardData: Array<CustomerTravellerMemberCardDTO>) => {
        if (memberCardData.length > 0) {
          this.memberCardDataUpdate = memberCardData;
        }
      })
    )
    this.subscriptions.add(
      this.countryFacade.allCountry$.subscribe((countries: Array<CountryCodeDTO>) => {
        this.countries = countries;
        this.filteredCountries.next(countries.slice());
      })
    )
    if(this.appName !== "B2B") {
      this.subscriptions.add(
        this.customerTravellerFacade.selectedCustomerTraveller$.subscribe(passenger => {
          if (passenger) {
            this.passenger = passenger;
            this.formProfile.patchValue({
              id: passenger.id,
              surName: RemoveStringAccented(passenger.surName),
              firstName: RemoveStringAccented(passenger.firstName),
              email: passenger.email,
              phoneNumber1: passenger.phoneNumber1,
              dob: passenger.dob,
              gender: passenger.gender ? passenger.gender : null,
              address1: passenger.address1,
              isDefault: passenger.isDefault,
              country: passenger.country,
              customerCode: this.shortProfile.requesterCode,
              profileId: this.shortProfile.profileId,
              documentType: passenger.documentType,
              documentNumber: passenger.documentNumber,
              nationality: passenger.nationality,
              memberCards: passenger.memberCards,
              expiredDate: passenger.expiredDate
            });
            this.memberCardDataUpdate = passenger.memberCards;
            if (this.passenger.isDefault) {
              this.today = new Date(this.today.getFullYear() - 12, this.today.getMonth(), this.today.getDate() + 1);
            }
          }
        })
      )
    }

    this.subscriptions.add(
      this.accountFacade.getAccountLogged$.subscribe(accountData => {
        if (accountData) {
          this.accountData = accountData;
        }
      })
    )
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  private createForm() {
    this.formProfile = this.formBuilder.group({
      id: [],
      customerCode: [''],
      profileId: [''],
      surName: ['', Validators.required],
      firstName: ['', Validators.required],
      email: ['', [Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$')]],
      phoneNumber1: ['', [Validators.pattern("^((\\+84-?)|0)?[0-9]{10}$")]],
      dob: [],
      gender: [],
      address1: [''],
      country: [''],
      isDefault: [''],
      memberCards: [''],
      frequencyCards: [],
      documentType: [''],
      documentNumber: [''],
      nationality: [''],
      expiredDate: [''],
      companyName: [''],
      companyAddress: [''],
      companyTaxCode: [''],
    }, {
      validators: [
        MustMatch2(!!this.appName, 'customerCode'),
        MustMatch2(!!this.appName, 'profileId'),
      ],
    });
  }

  protected filterCountries() {
    if (!this.countries) {
      return;
    }
    let search = this.countryFilterCtrl.value;
    if (!search && this.countries) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountries.next(
      this.countries.filter(country => country.name.toLowerCase().indexOf(search) > -1)
    );
  }

  setGroup(index: number) {
    this.groupPanel = index;
  }

  submitForm() {
    this.formProfile.markAllAsTouched();
    if (this.formProfile.valid) {
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {message: 'user.updating-information', loading: true},
        panelClass: 'snackbar-loading',
        verticalPosition: "top"
      });
      const dataTraveller = {
        id: this.formProfile.get('id').value,
        customerCode: this.formProfile.get('customerCode').value,
        address1: this.formProfile.get('address1').value,
        firstName: this.formProfile.get('firstName').value.toUpperCase(),
        surName: this.formProfile.get('surName').value.toUpperCase(),
        dob: this.formProfile.get('dob').value,
        country: this.formProfile.get('country').value,
        phoneNumber1: this.formProfile.get('phoneNumber1').value,
        email: this.formProfile.get('email').value,
        profileId: this.formProfile.get('profileId').value,
        gender: this.formProfile.get('gender').value,
        memberCards: this.formProfile.get('memberCards').value? this.formProfile.get('memberCards').value: (
          this.formProfile.get('frequencyCards').value? this.formProfile.get('frequencyCards').value: []
        ),
        documentType: this.formProfile.get('documentType').value,
        documentNumber: this.formProfile.get('documentNumber').value,
        nationality: this.formProfile.get('nationality').value,
        expiredDate: this.formProfile.get('expiredDate').value,
        isDefault: this.formProfile.get('isDefault').value,
      };
      this.customerTravellerFacade.updateCustomerTravellerUsingPut([dataTraveller]);
    } else {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {message: 'user.check-information'},
        panelClass: 'snackbar-warning',
        verticalPosition: "top",
        duration: 3000
      });
    }
  }

  callBackSubmitTraveller(updateCustomer) {
    {
      if (updateCustomer) {
        this._snackBar.openFromComponent(SnackbarComponent, {
          data: {message: 'user.update-success', icon: 'check'},
          panelClass: 'snackbar-success',
          verticalPosition: "top",
          duration: 2000
        });
        this.backClicked();
        setTimeout(_ => {
          if (updateCustomer.isDefault) {
            this.accountData.lastName = this.formProfile.get('surName').value.toUpperCase();
            this.accountData.firstName = this.formProfile.get('firstName').value.toUpperCase();
            this.accountData.email = this.formProfile.get('email').value;
            this.accountData.phoneNumber = this.formProfile.get('phoneNumber1').value;
            this.accountData.dob = this.formProfile.get('dob').value;
            this.accountResourceService.saveAccountUsingPOST(this.accountData).subscribe();
          }
          this.isLoading = false;
          this.router.navigate(['/user/passenger/' + updateCustomer[0].id]);
        }, 500)
      } else {
        this.isLoading = false;
        this.dialog.open(DialogMessageComponent, {
          data: {
            btn: [
              {btnText: 'global.close'}
            ],
            content: 'user.system-error-message',
            heading: 'user.system-error',
            icon: 'exclamation-triangle',
            messageType: 'error'
          },
          panelClass: 'dialog-message',
          position: {
            top: "150px"
          },
          disableClose: true
        });
      }
    }
  }

  createTravellerB2B() {
    const agentProfile = this.accountData.userRefCode.split("|");
    const dataTraveller = {
      id: this.formProfile.get('id').value,
      address: this.formProfile.get('address1').value,
      country: this.formProfile.get('country').value,
      dob: this.formProfile.get('dob').value,
      dateOfBirth: moment(this.formProfile.get('dob').value).format(
        'DD/MM/YYYY'
      ).toString(),
      frequencyCards: this.formProfile.get('frequencyCards').value,
      firstName: this.formProfile.get('firstName').value.toUpperCase(),
      lastName: this.formProfile.get('surName').value.toUpperCase(),
      phoneNumber1: this.formProfile.get('phoneNumber1').value,
      email: this.formProfile.get('email').value,
      gender: this.formProfile.get('gender').value,
      agencyCode: agentProfile[0],
      agentCode: this.accountData.userRefCode,
      documentType: this.formProfile.get('documentType').value,
      passportNumber: this.formProfile.get('documentNumber').value,
      nationality: this.formProfile.get('nationality').value,
      expiredDate: this.formProfile.get('expiredDate').value,
      expiredDateStr: this.formProfile.get('expiredDate').value? moment(this.formProfile.get('expiredDate').value).format(
        'DD/MM/YYYY'
      ).toString(): null,
      companyName: this.formProfile.get('companyName').value,
      companyAddress: this.formProfile.get('companyAddress').value,
      companyTaxCode: this.formProfile.get('companyTaxCode').value,
    } as AgentCustomerTO;
    if(this.pageType === 'Add') {
      this.customerResourceService.createCustomerUsingPOST(dataTraveller).subscribe(updateCustomer => this.callBackSubmitTravellerB2B(updateCustomer));
    } else {
      this.customerResourceService.updateCustomerUsingPUT(dataTraveller).subscribe(updateCustomer => this.callBackSubmitTravellerB2B(updateCustomer));
    }
  }

  callBackSubmitTravellerB2B(updateCustomer) {
    if (updateCustomer && updateCustomer.id) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {message: 'user.update-success', icon: 'check'},
        panelClass: 'snackbar-success',
        verticalPosition: "top",
        duration: 2000
      });
      this.isLoading = false;
      this.router.navigate(['/user/passenger/' + updateCustomer.id]);
    } else {
      this.isLoading = false;
      this.dialog.open(DialogMessageComponent, {
        data: {
          btn: [
            {btnText: 'global.close'}
          ],
          content: 'user.system-error-message',
          heading: 'user.system-error',
          icon: 'exclamation-triangle',
          messageType: 'error'
        },
        panelClass: 'dialog-message',
        position: {
          top: "150px"
        },
        disableClose: true
      });
    }
  }

  backClicked() {
    this._location.back();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.customerTravellerFacade.customerTravellerUpdateDetroy();
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
