import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DEFAULT_PAGINATION_OFFSET, fadeIn} from "@gtd/helpers";
import {AirLowFareSearchRS, GroupPricedItinerary} from "@gtd/b2c-client";
import {ActivatedRoute} from "@angular/router";
import {FlightSearchFacade} from "../flight-search/flight-search/+state/flight-search.facade";
import {Subscription} from "rxjs/Rx";

@Component({
  selector: 'gtd-flight-search-info',
  templateUrl: './flight-search-info.component.html',
  styleUrls: ['./flight-search-info.component.scss'],
  animations: [fadeIn]
})
export class FlightSearchInfoComponent implements OnInit {
  @Input() groupPricedItineraries: Array<GroupPricedItinerary>;
  @Input() formSearchResult: any;
  @Input() isLoading: boolean;
  @Input() availableFlightsFacade: AirLowFareSearchRS;
  @Input() flightDirection: string;
  @Input() flightInformation: any;
  panelOpenState: boolean;
  isCollapsed: boolean;
  loadedFlightSearch$ = this.flightSearchFacade.loaded$;
  searchParams: any;
  subscription: Subscription = new Subscription;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private flightSearchFacade: FlightSearchFacade
  ) {
    this.activatedRoute.queryParams.subscribe(param => {
      this._handleQueryFromParams(param);
    })
  }

  ngOnInit() {
  }

  private _handleQueryFromParams(param) {
    this.searchParams = {
      adutsQtt: param.adult,
      cabinClass: 'E',
      childrenQtt: param.child,
      departureDate: param.fromDate,
      destinationCode: param.to,
      infantsQtt: param.infant,
      originCode: param.from,
      page: 0,
      returntureDate: param.toDate? param.toDate: '',
      routeType: param.roundType,
      size: DEFAULT_PAGINATION_OFFSET,
      time: new Date().getTime().toString()
    };
  }

  resetFilter(flightDirection: any) {
    this.changed.emit(flightDirection);
  }
}
