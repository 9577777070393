import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  COMBO_BOOKING_FEATURE_KEY,
  ComboBookingState
} from './combo-booking.reducer';

// Lookup the 'ComboBooking' feature state managed by NgRx
const getComboBookingState = createFeatureSelector<ComboBookingState>(
  COMBO_BOOKING_FEATURE_KEY
);

const getLoaded = createSelector(
  getComboBookingState,
  (state: ComboBookingState) => state.loaded
);
const getError = createSelector(
  getComboBookingState,
  (state: ComboBookingState) => state.error
);

const getAllComboBooking = createSelector(
  getComboBookingState,
  getLoaded,
  (state: ComboBookingState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getComboBookingState,
  (state: ComboBookingState) => state.selectedId
);
const getSelectedComboBooking = createSelector(
  getAllComboBooking,
  getSelectedId,
  (ComboBooking, id) => {
    const result = ComboBooking.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const ComboBookingQuery = {
  getLoaded,
  getError,
  getAllComboBooking,
  getSelectedComboBooking
};
