import {
  ResetPasswordStateAction,
  ResetPasswordStateActionTypes
} from './reset-password-state.actions';

export const RESETPASSWORDSTATE_FEATURE_KEY = 'resetPasswordState';

/**
 * Interface for the 'ResetPasswordState' data used in
 *  - ResetPasswordStateState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface ResetPasswordStateState {
  result: ResultResetPassword; // list of ResetPasswordState; analogous to a sql normalized table
  selectedId?: string | number; // which ResetPasswordState record has been selected
  loaded: boolean; // has the ResetPasswordState list been loaded
  error?: any; // last none error (if any)
}
export interface ResultResetPassword {
  success?: boolean;
  error?: boolean;
  message?: string;
}

export interface ResetPasswordStatePartialState {
  readonly [RESETPASSWORDSTATE_FEATURE_KEY]: ResetPasswordStateState;
}

export const initialState: ResetPasswordStateState = {
  result: null,
  loaded: false
};

export function reducer(
  state: ResetPasswordStateState = initialState,
  action: ResetPasswordStateAction
): ResetPasswordStateState {
  switch (action.type) {
    case ResetPasswordStateActionTypes.ResetPasswordStateLoaded: {
      state = {
        ...state,
        result: {
          success: true,
          message: action.payload
        },
        loaded: true
      };
      break;
    }
    case ResetPasswordStateActionTypes.ResetPasswordStateLoadError: {
      state = {
        ...state,
        result: {
          error: true,
          message: "Reset password error!"
        },
        loaded: true
      };
      break;
    }
  }
  return state;
}
