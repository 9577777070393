import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PaymentOptionInfoPartialState } from './payment-option-info.reducer';
import { paymentOptionInfoQuery } from './payment-option-info.selectors';
import { LoadPaymentOptionInfo } from './payment-option-info.actions';

@Injectable()
export class PaymentOptionInfoFacade {
  loaded$ = this.store.pipe(select(paymentOptionInfoQuery.getLoaded));
  allPaymentOptionInfo$ = this.store.pipe(
    select(paymentOptionInfoQuery.getAllPaymentOptionInfo)
  );
  selectedPaymentOptionInfo$ = this.store.pipe(
    select(paymentOptionInfoQuery.getSelectedPaymentOptionInfo)
  );

  constructor(private store: Store<PaymentOptionInfoPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadPaymentOptionInfo());
  }
}
