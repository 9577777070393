import {Action} from '@ngrx/store';
import {RequesterInfo} from "@gtd/b2c-client";

export enum ShortProfileActionTypes {
  LoadShortProfile = '[ShortProfile] Load ShortProfile',
  ShortProfileLoaded = '[ShortProfile] ShortProfile Loaded',
  ShortProfileLoadError = '[ShortProfile] ShortProfile Load Error'
}

export class LoadShortProfile implements Action {
  readonly type = ShortProfileActionTypes.LoadShortProfile;
}

export class ShortProfileLoadError implements Action {
  readonly type = ShortProfileActionTypes.ShortProfileLoadError;
  constructor(public payload: any) {}
}

export class ShortProfileLoaded implements Action {
  readonly type = ShortProfileActionTypes.ShortProfileLoaded;
  constructor(public payload: RequesterInfo) {}
}

export type ShortProfileAction =
  | LoadShortProfile
  | ShortProfileLoaded
  | ShortProfileLoadError;

export const fromShortProfileActions = {
  LoadShortProfile,
  ShortProfileLoaded,
  ShortProfileLoadError
};
