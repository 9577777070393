import { Action } from '@ngrx/store';
import { AirLowFareSearchResultVM } from '@gtd/b2c-client';
import { LowfareSearchQueryParam } from '@gtd/helpers';

export enum FlightSearchActionTypes {
  LoadFlightSearch = '[FlightSearch] Load FlightSearch',
  LoadFlightIntSearch = '[FlightSearch] Load LoadFlightIntSearch',
  FlightSearchLoaded = '[FlightSearch] FlightSearch Loaded',
  FlightSearchLoadError = '[FlightSearch] FlightSearch Load Error',
  FlightSearchReset = '[FlightSearch] FlightSearch Reset'
}

export class LoadFlightSearch implements Action {
  readonly type = FlightSearchActionTypes.LoadFlightSearch;

  constructor(public payload: LowfareSearchQueryParam) {}
}

export class LoadFlightIntSearch implements Action {
  readonly type = FlightSearchActionTypes.LoadFlightIntSearch;

  constructor(public payload: LowfareSearchQueryParam) {}
}

export class FlightSearchLoadError implements Action {
  readonly type = FlightSearchActionTypes.FlightSearchLoadError;

  constructor(public payload: any) {}
}

export class FlightSearchLoaded implements Action {
  readonly type = FlightSearchActionTypes.FlightSearchLoaded;

  constructor(public payload: AirLowFareSearchResultVM) {}
}

export class FlightSearchReset implements Action {
  readonly type = FlightSearchActionTypes.FlightSearchReset;
}

export type FlightSearchAction =
  | LoadFlightSearch
  | LoadFlightIntSearch
  | FlightSearchLoaded
  | FlightSearchLoadError
  | FlightSearchReset;

export const fromFlightSearchActions = {
  LoadFlightSearch,
  LoadFlightIntSearch,
  FlightSearchLoaded,
  FlightSearchLoadError,
  FlightSearchReset
};
