import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { SearchKeywordElement } from '@gtd/b2c-client';
import { HotelFacade, PopularPlacesFacade } from '@gtd/meta';
import { AirportDTO } from '@gtd/meta-client';
import { AutocompleteSearchKeywordFacade } from 'libs/hotel/src/state/autocomplete-search-keyword/autocomplete-search-keyword.facade';
import { SearchKeywordFacade } from 'libs/hotel/src/state/search-keyword/search-keyword.facade';
import { Subject } from 'rxjs';
import { ComboSearchService } from '../combo-search.service';

export interface InputField {
  stateCtrl: string;
  hotelName: string;
}
@Component({
  selector: 'gtd-hotel-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    HotelFacade,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HotelDestinationComponent),
      multi: true
    }
  ]
})
export class HotelDestinationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() type: any;
  @Input() propertyName = '';
  @Input() newToLocation: any; // TODO
  @Input() dataLocalStorages?: AirportDTO;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputRef', { static: false, read: ElementRef })
  inputRef: ElementRef; /*create a view child*/

  form: FormGroup;
  focused = false;
  isShiftFocus: boolean;
  stateCtrl = new FormControl();
  hotelName = new FormControl();
  stateChanges = new Subject<void>();
  groupSearchLabel: string = 'destination-popular';
  destinationDTOs: any;
  destination: any;
  errorState = false;
  isContentPage = false;

  allSearchKeyword$ = this.searchKeywordFacade.allSearchKeyword$;
  allPopularPlaces$ = this.popularPlacesFacade.allPopularPlaces$;
  constructor(
    public fb: FormBuilder,
    private comboSearchService: ComboSearchService,
    private searchKeywordFacade: SearchKeywordFacade,
    private popularPlacesFacade: PopularPlacesFacade,
    private autocompleteSearchKeywordFacade: AutocompleteSearchKeywordFacade
  ) {}
  ngOnInit() {
    this.form = this.fb.group({
      stateCtrl: '',
      hotelName: ''
    });
    this.popularPlacesFacade.getPopularDestination('FAVOURITE_HOTEL_LOCATION');
    this.destinationDTOs = this.comboSearchService.getAllLocalStorageLocation(
      this.type
    );
    if (
      this.destinationDTOs &&
      this.destinationDTOs.location &&
      this.destinationDTOs.location.searchCode
    ) {
      this.stateCtrl.setValue(this.destinationDTOs.location);
    } else if (this.dataLocalStorages) {
      this.stateCtrl.setValue({
        name: this.dataLocalStorages.city,
        propertyCount: null,
        searchCode: this.dataLocalStorages.city,
        searchType: 'AUTO',
        supplier: 'EXPEDIA'
      });
    }
    this.stateCtrl.valueChanges.subscribe((data: any) => {
      if (typeof data === 'string') {
        this.searchKeywordFacade.loadAll(data, 'vi', 0, 15);
      }
      if (data || data !== '') {
        this.groupSearchLabel = 'list-destination';
        if (!data.searchCode) {
          let searchData = {
            name: data,
            propertyCount: null,
            searchCode: data,
            searchType: 'AUTO',
            supplier: 'EXPEDIA'
          };
          this.onChange(searchData);
          this.onTouched();
        } else {
          this.onChange(data);
          this.onTouched();
        }
      } else {
        this.onChange('');
        this.onTouched();
      }
    });
    this.newToLocation.subscribe(data => {
      if (data) {
        this.destination = data;
        this.stateCtrl.setValue(data);
        this.inputRef.nativeElement.value = this.displayFn(data);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {}

  destinationSelected(destination: any) {
    this.autocompleteSearchKeywordFacade.addSearchKeywordHotel([destination]);
    this.destination = destination;
    this.stateCtrl.setValue(destination);
    this.focused = false;
  }
  displayFn(hotel?: SearchKeywordElement) {
    return hotel && hotel.name ? `${hotel.name}` : hotel;
  }
  closePanel() {
    return setTimeout(() => {
      this.focused = false;
    }, 50);
  }
  get value(): InputField {
    let n = this.form.value;
    return n;
  }
  set value(sf: InputField) {
    // setTimeout(() => {
    //   this.setDefaultHotel(sf);
    // }, 100);
  }
  setDefaultHotel = (sf: InputField) => {
    // setTimeout(() => {
    //   let getHotelLocation = this.hotelSearchService.getAllLocalStorageLocation(
    //     this.type
    //   );
    //   if (getHotelLocation) {
    //     let hotelLocation = getHotelLocation.filter(
    //       code => code.name === sf.toLocaleString()
    //     );
    //     if (
    //       hotelLocation &&
    //       hotelLocation[0] &&
    //       hotelLocation[0].name === sf.toLocaleString()
    //     ) {
    //       this.stateCtrl.setValue(hotelLocation[0]);
    //       this.autocompleteSearchKeywordFacade.addSearchKeywordHotel(
    //         hotelLocation
    //       );
    //     }
    //   }
    //   this.stateChanges.next();
    // });
  };
  ngOnDestroy(): void {}

  @Input()
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
