import {Action} from '@ngrx/store';
import {LookupB2bSearch} from './lookup-b2b.reducer';
import {LookupDTO} from "@gtd/api-services/b2b-client";

export enum LookupB2bActionTypes {
  LoadLookupB2b = '[LookupB2b] Load LookupB2b',
  LookupB2bLoaded = '[LookupB2b] LookupB2b Loaded',
  LookupB2bLoadError = '[LookupB2b] LookupB2b Load Error'
}

export class LoadLookupB2b implements Action {
  readonly type = LookupB2bActionTypes.LoadLookupB2b;
  constructor(public payload: LookupB2bSearch) {}
}

export class LookupB2bLoadError implements Action {
  readonly type = LookupB2bActionTypes.LookupB2bLoadError;
  constructor(public payload: any) {}
}

export class LookupB2bLoaded implements Action {
  readonly type = LookupB2bActionTypes.LookupB2bLoaded;
  constructor(public payload: LookupDTO[]) {}
}

export type LookupB2bAction =
  | LoadLookupB2b
  | LookupB2bLoaded
  | LookupB2bLoadError;

export const fromLookupB2bActions = {
  LoadLookupB2b,
  LookupB2bLoaded,
  LookupB2bLoadError
};
