import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {
  AFFILIATES_FEATURE_KEY,
  affiliatesReducer,
  initialState as affiliatesInitialState
} from './get-booking-tags/affiliates.reducer';
import {AffiliatesEffects} from './get-booking-tags/affiliates.effects';
import {AffiliatesFacade} from './get-booking-tags/affiliates.facade';
import {NxModule} from '@nrwl/angular';
import {AirTicketsResourceService, BASE_PATH} from "@gtd/b2c-client";
import {environment} from "@env/environment";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(AFFILIATES_FEATURE_KEY, affiliatesReducer, {
      initialState: affiliatesInitialState
    }),
    EffectsModule.forFeature([AffiliatesEffects])
  ],
  providers: [{ provide: BASE_PATH, useValue: environment.api.b2cClient }, AffiliatesFacade, AirTicketsResourceService]
})
export class AffiliatesModule {}
