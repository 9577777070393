import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {CredentialsService} from "../auth-b2b/authenticate/credentials.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'gtd-auth-b2b',
  templateUrl: './auth-b2b.component.html',
  styleUrls: ['./auth-b2b.component.scss']
})
export class AuthB2bComponent implements OnInit {

  constructor(
    private credentialsService: CredentialsService,
  ) {
    this.credentialsService.logout().subscribe(result => {
      if (result) {
        console.log('GTD Auth');
      }
    });
  }

  ngOnInit() {
  }

}
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
