/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { BookingAdditionalRequestDTO } from '../model/bookingAdditionalRequestDTO';
import { PageOfBookingAdditionalRequestDTO } from '../model/pageOfBookingAdditionalRequestDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdditionalRequestResourceService {

    protected basePath = 'https://10.7.71.50:8588/b2c_gateway';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createAdditionalRequest
     * 
     * @param bookingAdditionalRequestDTO bookingAdditionalRequestDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAdditionalRequestUsingPOST(bookingAdditionalRequestDTO: BookingAdditionalRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<BookingAdditionalRequestDTO>;
    public createAdditionalRequestUsingPOST(bookingAdditionalRequestDTO: BookingAdditionalRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingAdditionalRequestDTO>>;
    public createAdditionalRequestUsingPOST(bookingAdditionalRequestDTO: BookingAdditionalRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingAdditionalRequestDTO>>;
    public createAdditionalRequestUsingPOST(bookingAdditionalRequestDTO: BookingAdditionalRequestDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingAdditionalRequestDTO === null || bookingAdditionalRequestDTO === undefined) {
            throw new Error('Required parameter bookingAdditionalRequestDTO was null or undefined when calling createAdditionalRequestUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingAdditionalRequestDTO>(`${this.basePath}/api/additional-requests`,
            bookingAdditionalRequestDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAdditionalRequestsByBookingNumber
     * 
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdditionalRequestsByBookingNumberUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BookingAdditionalRequestDTO>>;
    public getAdditionalRequestsByBookingNumberUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookingAdditionalRequestDTO>>>;
    public getAdditionalRequestsByBookingNumberUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookingAdditionalRequestDTO>>>;
    public getAdditionalRequestsByBookingNumberUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getAdditionalRequestsByBookingNumberUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BookingAdditionalRequestDTO>>(`${this.basePath}/api/additional-requests/_search/by-booking-number`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAdditionalRequestsByCreatedBy
     * 
     * @param createdBy createdBy
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdditionalRequestsByCreatedByUsingGET(createdBy: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BookingAdditionalRequestDTO>>;
    public getAdditionalRequestsByCreatedByUsingGET(createdBy: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookingAdditionalRequestDTO>>>;
    public getAdditionalRequestsByCreatedByUsingGET(createdBy: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookingAdditionalRequestDTO>>>;
    public getAdditionalRequestsByCreatedByUsingGET(createdBy: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (createdBy === null || createdBy === undefined) {
            throw new Error('Required parameter createdBy was null or undefined when calling getAdditionalRequestsByCreatedByUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (createdBy !== undefined && createdBy !== null) {
            queryParameters = queryParameters.set('createdBy', <any>createdBy);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BookingAdditionalRequestDTO>>(`${this.basePath}/api/additional-requests/_search/by-created-by`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchBookingAdditionalRequests
     * 
     * @param requestContent requestContent
     * @param requestType requestType
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param status status
     * @param createdBy createdBy
     * @param bookingNumber bookingNumber
     * @param passengerNameRecords passengerNameRecords
     * @param updatedBy updatedBy
     * @param updatedDateFrom updatedDateFrom
     * @param updatedDateTo updatedDateTo
     * @param saleChannel saleChannel
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBookingAdditionalRequestsUsingGET(requestContent?: string, requestType?: string, createdDateFrom?: string, createdDateTo?: string, status?: string, createdBy?: string, bookingNumber?: string, passengerNameRecords?: string, updatedBy?: string, updatedDateFrom?: string, updatedDateTo?: string, saleChannel?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageOfBookingAdditionalRequestDTO>;
    public searchBookingAdditionalRequestsUsingGET(requestContent?: string, requestType?: string, createdDateFrom?: string, createdDateTo?: string, status?: string, createdBy?: string, bookingNumber?: string, passengerNameRecords?: string, updatedBy?: string, updatedDateFrom?: string, updatedDateTo?: string, saleChannel?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfBookingAdditionalRequestDTO>>;
    public searchBookingAdditionalRequestsUsingGET(requestContent?: string, requestType?: string, createdDateFrom?: string, createdDateTo?: string, status?: string, createdBy?: string, bookingNumber?: string, passengerNameRecords?: string, updatedBy?: string, updatedDateFrom?: string, updatedDateTo?: string, saleChannel?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfBookingAdditionalRequestDTO>>;
    public searchBookingAdditionalRequestsUsingGET(requestContent?: string, requestType?: string, createdDateFrom?: string, createdDateTo?: string, status?: string, createdBy?: string, bookingNumber?: string, passengerNameRecords?: string, updatedBy?: string, updatedDateFrom?: string, updatedDateTo?: string, saleChannel?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (requestContent !== undefined && requestContent !== null) {
            queryParameters = queryParameters.set('requestContent', <any>requestContent);
        }
        if (requestType !== undefined && requestType !== null) {
            queryParameters = queryParameters.set('requestType', <any>requestType);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (createdBy !== undefined && createdBy !== null) {
            queryParameters = queryParameters.set('createdBy', <any>createdBy);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (passengerNameRecords !== undefined && passengerNameRecords !== null) {
            queryParameters = queryParameters.set('passengerNameRecords', <any>passengerNameRecords);
        }
        if (updatedBy !== undefined && updatedBy !== null) {
            queryParameters = queryParameters.set('updatedBy', <any>updatedBy);
        }
        if (updatedDateFrom !== undefined && updatedDateFrom !== null) {
            queryParameters = queryParameters.set('updatedDateFrom', <any>updatedDateFrom);
        }
        if (updatedDateTo !== undefined && updatedDateTo !== null) {
            queryParameters = queryParameters.set('updatedDateTo', <any>updatedDateTo);
        }
        if (saleChannel !== undefined && saleChannel !== null) {
            queryParameters = queryParameters.set('saleChannel', <any>saleChannel);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PageOfBookingAdditionalRequestDTO>(`${this.basePath}/api/additional-requests/_search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateAdditionalRequest
     * 
     * @param bookingAdditionalRequestDTO bookingAdditionalRequestDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAdditionalRequestUsingPUT(bookingAdditionalRequestDTO: BookingAdditionalRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<BookingAdditionalRequestDTO>;
    public updateAdditionalRequestUsingPUT(bookingAdditionalRequestDTO: BookingAdditionalRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingAdditionalRequestDTO>>;
    public updateAdditionalRequestUsingPUT(bookingAdditionalRequestDTO: BookingAdditionalRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingAdditionalRequestDTO>>;
    public updateAdditionalRequestUsingPUT(bookingAdditionalRequestDTO: BookingAdditionalRequestDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingAdditionalRequestDTO === null || bookingAdditionalRequestDTO === undefined) {
            throw new Error('Required parameter bookingAdditionalRequestDTO was null or undefined when calling updateAdditionalRequestUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BookingAdditionalRequestDTO>(`${this.basePath}/api/additional-requests`,
            bookingAdditionalRequestDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
