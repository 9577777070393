import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { RegisterStatePartialState } from './register-state.reducer';
import {
  LoadRegisterState,
  RegisterStateLoaded,
  RegisterStateLoadError,
  RegisterStateActionTypes
} from './register-state.actions';
import {map} from "rxjs/operators";
import {AccountResourceService} from "@gtd/b2c-client";

@Injectable()
export class RegisterStateEffects {
  @Effect() loadRegisterState$ = this.dataPersistence.fetch(
    RegisterStateActionTypes.LoadRegisterState,
    {
      run: (action: LoadRegisterState, state: RegisterStatePartialState) => {
        return this.accountResourceService.registerAccountUsingPOST(action.payload).pipe(
          map(update => ({
            type: RegisterStateActionTypes.RegisterStateLoaded,
            payload: action.payload
          }))
        );
      },

      onError: (action: LoadRegisterState, error) => {
        console.error('Error', error);
        return new RegisterStateLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private accountResourceService: AccountResourceService,
    private dataPersistence: DataPersistence<RegisterStatePartialState>
  ) {}
}
