/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface V2SettingsBody1 { 
    /**
     * Enable or disable blocks
     */
    structuredcontentSettingsApi?: string;
    /**
     * Site title.
     */
    title?: string;
    /**
     * Site tagline.
     */
    description?: string;
    /**
     * Site URL.
     */
    url?: string;
    /**
     * This address is used for admin purposes, like new user notification.
     */
    email?: string;
    /**
     * A city in the same timezone as you.
     */
    timezone?: string;
    /**
     * A date format for all date strings.
     */
    dateFormat?: string;
    /**
     * A time format for all time strings.
     */
    timeFormat?: string;
    /**
     * A day number of the week that the week should start on.
     */
    startOfWeek?: string;
    /**
     * WordPress locale code.
     */
    language?: string;
    /**
     * Convert emoticons like :-) and :-P to graphics on display.
     */
    useSmilies?: string;
    /**
     * Default post category.
     */
    defaultCategory?: string;
    /**
     * Default post format.
     */
    defaultPostFormat?: string;
    /**
     * Blog pages show at most.
     */
    postsPerPage?: string;
    /**
     * Allow link notifications from other blogs (pingbacks and trackbacks) on new articles.
     */
    defaultPingStatus?: Array<V2SettingsBody1.DefaultPingStatusEnum>;
    /**
     * Allow people to submit comments on new posts.
     */
    defaultCommentStatus?: Array<V2SettingsBody1.DefaultCommentStatusEnum>;
    /**
     * Site logo.
     */
    siteLogo?: string;
    /**
     * Site icon.
     */
    siteIcon?: string;
}
export namespace V2SettingsBody1 {
    export type DefaultPingStatusEnum = 'open' | 'closed';
    export const DefaultPingStatusEnum = {
        Open: 'open' as DefaultPingStatusEnum,
        Closed: 'closed' as DefaultPingStatusEnum
    };
    export type DefaultCommentStatusEnum = 'open' | 'closed';
    export const DefaultCommentStatusEnum = {
        Open: 'open' as DefaultCommentStatusEnum,
        Closed: 'closed' as DefaultCommentStatusEnum
    };
}