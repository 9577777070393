import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HOTEL_FEATURE_KEY, HotelState } from './hotel.reducer';

// Lookup the 'Hotel' feature state managed by NgRx
const getHotelState = createFeatureSelector<HotelState>(HOTEL_FEATURE_KEY);

const getLoaded = createSelector(
  getHotelState,
  (state: HotelState) => state
);
const getError = createSelector(
  getHotelState,
  (state: HotelState) => state.error
);

const getAllHotel = createSelector(
  getHotelState,
  getLoaded,
  (state: HotelState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getHotelState,
  (state: HotelState) => state.selectedId
);
const getSelectedHotel = createSelector(
  getAllHotel,
  getSelectedId,
  (hotel, id) => {
    const result = hotel.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const hotelQuery = {
  getLoaded,
  getError,
  getAllHotel,
  getSelectedHotel
};
