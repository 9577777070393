/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChangeBookingOwnerRQ } from '../model/changeBookingOwnerRQ';
import { OtaResultOfstring } from '../model/otaResultOfstring';
import { TicketDraftBookingRQ } from '../model/ticketDraftBookingRQ';
import { TicketDraftBookingRS } from '../model/ticketDraftBookingRS';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TicketBookingResourceService {

    protected basePath = 'https://localhost:8281';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changeBookingOwner
     *
     * @param changeBookingOwnerRQ changeBookingOwnerRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeBookingOwnerUsingPOST(changeBookingOwnerRQ: ChangeBookingOwnerRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public changeBookingOwnerUsingPOST(changeBookingOwnerRQ: ChangeBookingOwnerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public changeBookingOwnerUsingPOST(changeBookingOwnerRQ: ChangeBookingOwnerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public changeBookingOwnerUsingPOST(changeBookingOwnerRQ: ChangeBookingOwnerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (changeBookingOwnerRQ === null || changeBookingOwnerRQ === undefined) {
            throw new Error('Required parameter changeBookingOwnerRQ was null or undefined when calling changeBookingOwnerUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfstring>(`${this.basePath}/api/bookings/ticket/change-booking-owner`,
            changeBookingOwnerRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createTicketDraftBookingLite
     *
     * @param ticketDraftBooking ticketDraftBooking
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTicketDraftBookingLiteUsingPOST(ticketDraftBooking: TicketDraftBookingRQ, observe?: 'body', reportProgress?: boolean): Observable<TicketDraftBookingRS>;
    public createTicketDraftBookingLiteUsingPOST(ticketDraftBooking: TicketDraftBookingRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TicketDraftBookingRS>>;
    public createTicketDraftBookingLiteUsingPOST(ticketDraftBooking: TicketDraftBookingRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TicketDraftBookingRS>>;
    public createTicketDraftBookingLiteUsingPOST(ticketDraftBooking: TicketDraftBookingRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketDraftBooking === null || ticketDraftBooking === undefined) {
            throw new Error('Required parameter ticketDraftBooking was null or undefined when calling createTicketDraftBookingLiteUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TicketDraftBookingRS>(`${this.basePath}/api/bookings/ticket/draft-booking-lite`,
            ticketDraftBooking,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createTicketDraftBooking
     *
     * @param ticketDraftBooking ticketDraftBooking
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTicketDraftBookingUsingPOST(ticketDraftBooking: TicketDraftBookingRQ, observe?: 'body', reportProgress?: boolean): Observable<TicketDraftBookingRS>;
    public createTicketDraftBookingUsingPOST(ticketDraftBooking: TicketDraftBookingRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TicketDraftBookingRS>>;
    public createTicketDraftBookingUsingPOST(ticketDraftBooking: TicketDraftBookingRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TicketDraftBookingRS>>;
    public createTicketDraftBookingUsingPOST(ticketDraftBooking: TicketDraftBookingRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketDraftBooking === null || ticketDraftBooking === undefined) {
            throw new Error('Required parameter ticketDraftBooking was null or undefined when calling createTicketDraftBookingUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TicketDraftBookingRS>(`${this.basePath}/api/bookings/ticket/draft-booking`,
            ticketDraftBooking,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
