/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AgentCustomerDTO } from '../model/agentCustomerDTO';
import { AgentCustomerSummaryTO } from '../model/agentCustomerSummaryTO';
import { ImportCustomerDTO } from '../model/importCustomerDTO';
import { ImportCustomerSummary } from '../model/importCustomerSummary';
import { OtaSearchResultOfListOfAgentCustomerDTO } from '../model/otaSearchResultOfListOfAgentCustomerDTO';
import { OtaSearchResultOfListOfAgentCustomerTO } from '../model/otaSearchResultOfListOfAgentCustomerTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AgentCustomerResourceService {

    protected basePath = 'https://10.7.71.20:8182';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changeStatus
     *
     * @param id id
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeStatusUsingPUT1(id: number, status: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeStatusUsingPUT1(id: number, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeStatusUsingPUT1(id: number, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeStatusUsingPUT1(id: number, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling changeStatusUsingPUT1.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling changeStatusUsingPUT1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/agent-customers/${encodeURIComponent(String(id))}/status`,
            status,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createAgentCustomer
     *
     * @param agentCustomerDTO agentCustomerDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgentCustomerUsingPOST(agentCustomerDTO: AgentCustomerDTO, observe?: 'body', reportProgress?: boolean): Observable<AgentCustomerDTO>;
    public createAgentCustomerUsingPOST(agentCustomerDTO: AgentCustomerDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentCustomerDTO>>;
    public createAgentCustomerUsingPOST(agentCustomerDTO: AgentCustomerDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentCustomerDTO>>;
    public createAgentCustomerUsingPOST(agentCustomerDTO: AgentCustomerDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentCustomerDTO === null || agentCustomerDTO === undefined) {
            throw new Error('Required parameter agentCustomerDTO was null or undefined when calling createAgentCustomerUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AgentCustomerDTO>(`${this.basePath}/api/agent-customers`,
            agentCustomerDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteAgentCustomer
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgentCustomerUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAgentCustomerUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAgentCustomerUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAgentCustomerUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAgentCustomerUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/agent-customers/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteAgentCustomers
     *
     * @param ids ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgentCustomersUsingDELETE(ids: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAgentCustomersUsingDELETE(ids: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAgentCustomersUsingDELETE(ids: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAgentCustomersUsingDELETE(ids: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ids === null || ids === undefined) {
            throw new Error('Required parameter ids was null or undefined when calling deleteAgentCustomersUsingDELETE.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids) {
            ids.forEach((element) => {
                queryParameters = queryParameters.append('ids', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/agent-customers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportObjects
     *
     * @param customerName customerName
     * @param nickName nickName
     * @param phoneNumber phoneNumber
     * @param email email
     * @param event event
     * @param status status
     * @param agentCode agentCode
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportObjectsUsingGET(customerName?: string, nickName?: string, phoneNumber?: string, email?: string, event?: string, status?: string, agentCode?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfListOfAgentCustomerTO>;
    public exportObjectsUsingGET(customerName?: string, nickName?: string, phoneNumber?: string, email?: string, event?: string, status?: string, agentCode?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfListOfAgentCustomerTO>>;
    public exportObjectsUsingGET(customerName?: string, nickName?: string, phoneNumber?: string, email?: string, event?: string, status?: string, agentCode?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfListOfAgentCustomerTO>>;
    public exportObjectsUsingGET(customerName?: string, nickName?: string, phoneNumber?: string, email?: string, event?: string, status?: string, agentCode?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerName !== undefined && customerName !== null) {
            queryParameters = queryParameters.set('customerName', <any>customerName);
        }
        if (nickName !== undefined && nickName !== null) {
            queryParameters = queryParameters.set('nickName', <any>nickName);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (event !== undefined && event !== null) {
            queryParameters = queryParameters.set('event', <any>event);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (agentCode !== undefined && agentCode !== null) {
            queryParameters = queryParameters.set('agentCode', <any>agentCode);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaSearchResultOfListOfAgentCustomerTO>(`${this.basePath}/api/_export/agent-customers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentCustomerSummary
     *
     * @param agencyCode agencyCode
     * @param agentCode agentCode
     * @param daysInSummary daysInSummary
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentCustomerSummaryUsingGET(agencyCode?: string, agentCode?: string, daysInSummary?: number, observe?: 'body', reportProgress?: boolean): Observable<AgentCustomerSummaryTO>;
    public getAgentCustomerSummaryUsingGET(agencyCode?: string, agentCode?: string, daysInSummary?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentCustomerSummaryTO>>;
    public getAgentCustomerSummaryUsingGET(agencyCode?: string, agentCode?: string, daysInSummary?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentCustomerSummaryTO>>;
    public getAgentCustomerSummaryUsingGET(agencyCode?: string, agentCode?: string, daysInSummary?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (agentCode !== undefined && agentCode !== null) {
            queryParameters = queryParameters.set('agentCode', <any>agentCode);
        }
        if (daysInSummary !== undefined && daysInSummary !== null) {
            queryParameters = queryParameters.set('daysInSummary', <any>daysInSummary);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentCustomerSummaryTO>(`${this.basePath}/api/_summary_agent-customers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentCustomer
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentCustomerUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<AgentCustomerDTO>;
    public getAgentCustomerUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentCustomerDTO>>;
    public getAgentCustomerUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentCustomerDTO>>;
    public getAgentCustomerUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAgentCustomerUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentCustomerDTO>(`${this.basePath}/api/agent-customers/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllAgentCustomers
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAgentCustomersUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentCustomerDTO>>;
    public getAllAgentCustomersUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentCustomerDTO>>>;
    public getAllAgentCustomersUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentCustomerDTO>>>;
    public getAllAgentCustomersUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgentCustomerDTO>>(`${this.basePath}/api/agent-customers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * importAgentCustomer
     *
     * @param importCustomerList importCustomerList
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importAgentCustomerUsingPOST(importCustomerList: Array<ImportCustomerDTO>, observe?: 'body', reportProgress?: boolean): Observable<ImportCustomerSummary>;
    public importAgentCustomerUsingPOST(importCustomerList: Array<ImportCustomerDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportCustomerSummary>>;
    public importAgentCustomerUsingPOST(importCustomerList: Array<ImportCustomerDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportCustomerSummary>>;
    public importAgentCustomerUsingPOST(importCustomerList: Array<ImportCustomerDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (importCustomerList === null || importCustomerList === undefined) {
            throw new Error('Required parameter importCustomerList was null or undefined when calling importAgentCustomerUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ImportCustomerSummary>(`${this.basePath}/api/_import/agent-customers`,
            importCustomerList,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjects
     *
     * @param customerName customerName
     * @param nickName nickName
     * @param phoneNumber phoneNumber
     * @param email email
     * @param event event
     * @param status status
     * @param agentCode agentCode
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectsUsingGET1(customerName?: string, nickName?: string, phoneNumber?: string, email?: string, event?: string, status?: string, agentCode?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfListOfAgentCustomerDTO>;
    public searchObjectsUsingGET1(customerName?: string, nickName?: string, phoneNumber?: string, email?: string, event?: string, status?: string, agentCode?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfListOfAgentCustomerDTO>>;
    public searchObjectsUsingGET1(customerName?: string, nickName?: string, phoneNumber?: string, email?: string, event?: string, status?: string, agentCode?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfListOfAgentCustomerDTO>>;
    public searchObjectsUsingGET1(customerName?: string, nickName?: string, phoneNumber?: string, email?: string, event?: string, status?: string, agentCode?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerName !== undefined && customerName !== null) {
            queryParameters = queryParameters.set('customerName', <any>customerName);
        }
        if (nickName !== undefined && nickName !== null) {
            queryParameters = queryParameters.set('nickName', <any>nickName);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (event !== undefined && event !== null) {
            queryParameters = queryParameters.set('event', <any>event);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (agentCode !== undefined && agentCode !== null) {
            queryParameters = queryParameters.set('agentCode', <any>agentCode);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaSearchResultOfListOfAgentCustomerDTO>(`${this.basePath}/api/_search/agent-customers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateAgentCustomer
     *
     * @param agentCustomerDTO agentCustomerDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAgentCustomerUsingPUT(agentCustomerDTO: AgentCustomerDTO, observe?: 'body', reportProgress?: boolean): Observable<AgentCustomerDTO>;
    public updateAgentCustomerUsingPUT(agentCustomerDTO: AgentCustomerDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentCustomerDTO>>;
    public updateAgentCustomerUsingPUT(agentCustomerDTO: AgentCustomerDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentCustomerDTO>>;
    public updateAgentCustomerUsingPUT(agentCustomerDTO: AgentCustomerDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentCustomerDTO === null || agentCustomerDTO === undefined) {
            throw new Error('Required parameter agentCustomerDTO was null or undefined when calling updateAgentCustomerUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AgentCustomerDTO>(`${this.basePath}/api/agent-customers`,
            agentCustomerDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateAirCustomerTraveller
     *
     * @param customerTravellerDTOList customerTravellerDTOList
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAirCustomerTravellerUsingPUT(customerTravellerDTOList: Array<AgentCustomerDTO>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentCustomerDTO>>;
    public updateAirCustomerTravellerUsingPUT(customerTravellerDTOList: Array<AgentCustomerDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentCustomerDTO>>>;
    public updateAirCustomerTravellerUsingPUT(customerTravellerDTOList: Array<AgentCustomerDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentCustomerDTO>>>;
    public updateAirCustomerTravellerUsingPUT(customerTravellerDTOList: Array<AgentCustomerDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerTravellerDTOList === null || customerTravellerDTOList === undefined) {
            throw new Error('Required parameter customerTravellerDTOList was null or undefined when calling updateAirCustomerTravellerUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<AgentCustomerDTO>>(`${this.basePath}/api/air-customer-travellers-collection`,
            customerTravellerDTOList,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
