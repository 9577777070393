/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BookingCodeRS } from '../model/bookingCodeRS';
import { BookingTravellerRQ } from '../model/bookingTravellerRQ';
import { CheckHotelAvailabilityRS } from '../model/checkHotelAvailabilityRS';
import { HotelAvailabilityRS } from '../model/hotelAvailabilityRS';
import { HotelBookingRQ } from '../model/hotelBookingRQ';
import { HotelDraftBookingRS } from '../model/hotelDraftBookingRS';
import { HotelDraftBookingVM } from '../model/hotelDraftBookingVM';
import { HotelFilterRS } from '../model/hotelFilterRS';
import { HotelLowFareFilterRQ } from '../model/hotelLowFareFilterRQ';
import { HotelPopularPlacesRS } from '../model/hotelPopularPlacesRS';
import { HotelSearchRS } from '../model/hotelSearchRS';
import { OtaResultOfListOfHotelMetaKeyword } from '../model/otaResultOfListOfHotelMetaKeyword';
import { OtaSearchBaseRQ } from '../model/otaSearchBaseRQ';
import { Pax } from '../model/pax';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HotelResourceService {

    protected basePath = 'https://172.16.100.16:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addBookingTraveller
     *
     * @param bookingTraveller bookingTraveller
     * @param xHotelClientIp x-hotel-client-ip
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addBookingTravellerUsingPOST1(bookingTraveller: BookingTravellerRQ, xHotelClientIp?: string, observe?: 'body', reportProgress?: boolean): Observable<BookingCodeRS>;
    public addBookingTravellerUsingPOST1(bookingTraveller: BookingTravellerRQ, xHotelClientIp?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodeRS>>;
    public addBookingTravellerUsingPOST1(bookingTraveller: BookingTravellerRQ, xHotelClientIp?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodeRS>>;
    public addBookingTravellerUsingPOST1(bookingTraveller: BookingTravellerRQ, xHotelClientIp?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingTraveller === null || bookingTraveller === undefined) {
            throw new Error('Required parameter bookingTraveller was null or undefined when calling addBookingTravellerUsingPOST1.');
        }


        let headers = this.defaultHeaders;
        if (xHotelClientIp !== undefined && xHotelClientIp !== null) {
            headers = headers.set('x-hotel-client-ip', String(xHotelClientIp));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodeRS>(`${this.basePath}/api/hotel/add-booking-traveller`,
            bookingTraveller,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkHotelAvailability
     *
     * @param request request
     * @param xHotelClientIp x-hotel-client-ip
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkHotelAvailabilityUsingPOST(request: CheckHotelAvailabilityRS, xHotelClientIp?: string, observe?: 'body', reportProgress?: boolean): Observable<HotelAvailabilityRS>;
    public checkHotelAvailabilityUsingPOST(request: CheckHotelAvailabilityRS, xHotelClientIp?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HotelAvailabilityRS>>;
    public checkHotelAvailabilityUsingPOST(request: CheckHotelAvailabilityRS, xHotelClientIp?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HotelAvailabilityRS>>;
    public checkHotelAvailabilityUsingPOST(request: CheckHotelAvailabilityRS, xHotelClientIp?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling checkHotelAvailabilityUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (xHotelClientIp !== undefined && xHotelClientIp !== null) {
            headers = headers.set('x-hotel-client-ip', String(xHotelClientIp));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HotelAvailabilityRS>(`${this.basePath}/api/hotel/check-hotel-availability`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createDraftBooking
     *
     * @param hotelDraftBooking hotelDraftBooking
     * @param xHotelClientIp x-hotel-client-ip
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDraftBookingUsingPOST1(hotelDraftBooking: HotelDraftBookingVM, xHotelClientIp?: string, observe?: 'body', reportProgress?: boolean): Observable<HotelDraftBookingRS>;
    public createDraftBookingUsingPOST1(hotelDraftBooking: HotelDraftBookingVM, xHotelClientIp?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HotelDraftBookingRS>>;
    public createDraftBookingUsingPOST1(hotelDraftBooking: HotelDraftBookingVM, xHotelClientIp?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HotelDraftBookingRS>>;
    public createDraftBookingUsingPOST1(hotelDraftBooking: HotelDraftBookingVM, xHotelClientIp?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotelDraftBooking === null || hotelDraftBooking === undefined) {
            throw new Error('Required parameter hotelDraftBooking was null or undefined when calling createDraftBookingUsingPOST1.');
        }


        let headers = this.defaultHeaders;
        if (xHotelClientIp !== undefined && xHotelClientIp !== null) {
            headers = headers.set('x-hotel-client-ip', String(xHotelClientIp));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HotelDraftBookingRS>(`${this.basePath}/api/hotel/draft-booking`,
            hotelDraftBooking,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * filterHotelLowFare
     *
     * @param hotelLowFareFilterRQ hotelLowFareFilterRQ
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public filterHotelLowFareUsingPOST(hotelLowFareFilterRQ: HotelLowFareFilterRQ, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<HotelSearchRS>;
    public filterHotelLowFareUsingPOST(hotelLowFareFilterRQ: HotelLowFareFilterRQ, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HotelSearchRS>>;
    public filterHotelLowFareUsingPOST(hotelLowFareFilterRQ: HotelLowFareFilterRQ, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HotelSearchRS>>;
    public filterHotelLowFareUsingPOST(hotelLowFareFilterRQ: HotelLowFareFilterRQ, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotelLowFareFilterRQ === null || hotelLowFareFilterRQ === undefined) {
            throw new Error('Required parameter hotelLowFareFilterRQ was null or undefined when calling filterHotelLowFareUsingPOST.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HotelSearchRS>(`${this.basePath}/api/hotel/filter-availability`,
            hotelLowFareFilterRQ,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllPopularPlaces
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPopularPlacesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<HotelPopularPlacesRS>;
    public getAllPopularPlacesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HotelPopularPlacesRS>>;
    public getAllPopularPlacesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HotelPopularPlacesRS>>;
    public getAllPopularPlacesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<HotelPopularPlacesRS>(`${this.basePath}/api/mobile/hotel/popular-places`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getHotelFilterOptions
     *
     * @param searchId searchId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHotelFilterOptionsUsingPOST(searchId: OtaSearchBaseRQ, observe?: 'body', reportProgress?: boolean): Observable<HotelFilterRS>;
    public getHotelFilterOptionsUsingPOST(searchId: OtaSearchBaseRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HotelFilterRS>>;
    public getHotelFilterOptionsUsingPOST(searchId: OtaSearchBaseRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HotelFilterRS>>;
    public getHotelFilterOptionsUsingPOST(searchId: OtaSearchBaseRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (searchId === null || searchId === undefined) {
            throw new Error('Required parameter searchId was null or undefined when calling getHotelFilterOptionsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HotelFilterRS>(`${this.basePath}/api/hotel/filter-options`,
            searchId,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * hotelOrder
     *
     * @param hotelBookingRQ hotelBookingRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hotelOrderUsingPOST(hotelBookingRQ: HotelBookingRQ, observe?: 'body', reportProgress?: boolean): Observable<BookingCodeRS>;
    public hotelOrderUsingPOST(hotelBookingRQ: HotelBookingRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodeRS>>;
    public hotelOrderUsingPOST(hotelBookingRQ: HotelBookingRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodeRS>>;
    public hotelOrderUsingPOST(hotelBookingRQ: HotelBookingRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotelBookingRQ === null || hotelBookingRQ === undefined) {
            throw new Error('Required parameter hotelBookingRQ was null or undefined when calling hotelOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodeRS>(`${this.basePath}/api/hotel/order-hotel`,
            hotelBookingRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchAvailableHotelsForMobile
     *
     * @param hotelCode hotelCode is from searchHotelMetaKeyword method.
     * @param checkinDate Check-In Date format is MM-dd-YYYY
     * @param checkoutDate Check-Out Date format is MM-dd-YYYY
     * @param searchPax Pax of Aduts Quantity adn Children Age
     * @param supplierCode Pax of Aduts Quantity adn Children Age
     * @param xHotelClientIp x-hotel-client-ip
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchAvailableHotelsForMobileUsingPOST(hotelCode: string, checkinDate: string, checkoutDate: string, searchPax: Array<Pax>, supplierCode: string, xHotelClientIp?: string, observe?: 'body', reportProgress?: boolean): Observable<HotelAvailabilityRS>;
    public searchAvailableHotelsForMobileUsingPOST(hotelCode: string, checkinDate: string, checkoutDate: string, searchPax: Array<Pax>, supplierCode: string, xHotelClientIp?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HotelAvailabilityRS>>;
    public searchAvailableHotelsForMobileUsingPOST(hotelCode: string, checkinDate: string, checkoutDate: string, searchPax: Array<Pax>, supplierCode: string, xHotelClientIp?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HotelAvailabilityRS>>;
    public searchAvailableHotelsForMobileUsingPOST(hotelCode: string, checkinDate: string, checkoutDate: string, searchPax: Array<Pax>, supplierCode: string, xHotelClientIp?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotelCode === null || hotelCode === undefined) {
            throw new Error('Required parameter hotelCode was null or undefined when calling searchAvailableHotelsForMobileUsingPOST.');
        }

        if (checkinDate === null || checkinDate === undefined) {
            throw new Error('Required parameter checkinDate was null or undefined when calling searchAvailableHotelsForMobileUsingPOST.');
        }

        if (checkoutDate === null || checkoutDate === undefined) {
            throw new Error('Required parameter checkoutDate was null or undefined when calling searchAvailableHotelsForMobileUsingPOST.');
        }

        if (searchPax === null || searchPax === undefined) {
            throw new Error('Required parameter searchPax was null or undefined when calling searchAvailableHotelsForMobileUsingPOST.');
        }

        if (supplierCode === null || supplierCode === undefined) {
            throw new Error('Required parameter supplierCode was null or undefined when calling searchAvailableHotelsForMobileUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotelCode !== undefined && hotelCode !== null) {
            queryParameters = queryParameters.set('hotel_code', <any>hotelCode);
        }
        if (checkinDate !== undefined && checkinDate !== null) {
            queryParameters = queryParameters.set('checkin_date', <any>checkinDate);
        }
        if (checkoutDate !== undefined && checkoutDate !== null) {
            queryParameters = queryParameters.set('checkout_date', <any>checkoutDate);
        }
        if (supplierCode !== undefined && supplierCode !== null) {
            queryParameters = queryParameters.set('supplier_code', <any>supplierCode);
        }

        let headers = this.defaultHeaders;
        if (xHotelClientIp !== undefined && xHotelClientIp !== null) {
            headers = headers.set('x-hotel-client-ip', String(xHotelClientIp));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HotelAvailabilityRS>(`${this.basePath}/api/mobile/hotel/low-fare-search-async`,
            searchPax,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchAvailableHotels
     *
     * @param hotelCode hotelCode is from searchHotelMetaKeyword method.
     * @param checkinDate Check-In Date format is MM-dd-YYYY
     * @param checkoutDate Check-Out Date format is MM-dd-YYYY
     * @param searchPax Pax of Aduts Quantity adn Children Age
     * @param xHotelClientIp x-hotel-client-ip
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchAvailableHotelsUsingPOST(hotelCode: Array<string>, checkinDate: string, checkoutDate: string, searchPax: Array<Pax>, xHotelClientIp?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<HotelSearchRS>;
    public searchAvailableHotelsUsingPOST(hotelCode: Array<string>, checkinDate: string, checkoutDate: string, searchPax: Array<Pax>, xHotelClientIp?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HotelSearchRS>>;
    public searchAvailableHotelsUsingPOST(hotelCode: Array<string>, checkinDate: string, checkoutDate: string, searchPax: Array<Pax>, xHotelClientIp?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HotelSearchRS>>;
    public searchAvailableHotelsUsingPOST(hotelCode: Array<string>, checkinDate: string, checkoutDate: string, searchPax: Array<Pax>, xHotelClientIp?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hotelCode === null || hotelCode === undefined) {
            throw new Error('Required parameter hotelCode was null or undefined when calling searchAvailableHotelsUsingPOST.');
        }

        if (checkinDate === null || checkinDate === undefined) {
            throw new Error('Required parameter checkinDate was null or undefined when calling searchAvailableHotelsUsingPOST.');
        }

        if (checkoutDate === null || checkoutDate === undefined) {
            throw new Error('Required parameter checkoutDate was null or undefined when calling searchAvailableHotelsUsingPOST.');
        }

        if (searchPax === null || searchPax === undefined) {
            throw new Error('Required parameter searchPax was null or undefined when calling searchAvailableHotelsUsingPOST.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hotelCode) {
            hotelCode.forEach((element) => {
                queryParameters = queryParameters.append('hotel_code', <any>element);
            })
        }
        if (checkinDate !== undefined && checkinDate !== null) {
            queryParameters = queryParameters.set('checkin_date', <any>checkinDate);
        }
        if (checkoutDate !== undefined && checkoutDate !== null) {
            queryParameters = queryParameters.set('checkout_date', <any>checkoutDate);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (xHotelClientIp !== undefined && xHotelClientIp !== null) {
            headers = headers.set('x-hotel-client-ip', String(xHotelClientIp));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<HotelSearchRS>(`${this.basePath}/api/hotel/low-fare-search-async`,
            searchPax,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchHotelMetaKeyword
     *
     * @param keyword keyword
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchHotelMetaKeywordUsingPOST(keyword: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfHotelMetaKeyword>;
    public searchHotelMetaKeywordUsingPOST(keyword: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfHotelMetaKeyword>>;
    public searchHotelMetaKeywordUsingPOST(keyword: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfHotelMetaKeyword>>;
    public searchHotelMetaKeywordUsingPOST(keyword: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (keyword === null || keyword === undefined) {
            throw new Error('Required parameter keyword was null or undefined when calling searchHotelMetaKeywordUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (keyword !== undefined && keyword !== null) {
            queryParameters = queryParameters.set('keyword', <any>keyword);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OtaResultOfListOfHotelMetaKeyword>(`${this.basePath}/api/hotel/meta-keyword-search`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
