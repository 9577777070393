import {Action} from '@ngrx/store';
import {HotelDetailReq} from "./hotel-detail.facade";
import {OtaResultOfSearchAllRatesResult} from "@gtd/b2c-client";

export enum HotelDetailActionTypes {
  LoadHotelDetail = '[HotelDetail] Load HotelDetail',
  HotelDetailLoaded = '[HotelDetail] HotelDetail Loaded',
  HotelDetailLoadError = '[HotelDetail] HotelDetail Load Error',
  ResetHotelDetail = '[HotelDetail] HotelDetail Reset All'
}

export class LoadHotelDetail implements Action {
  readonly type = HotelDetailActionTypes.LoadHotelDetail;
  constructor(public payload: HotelDetailReq) {}
}

export class ResetHotelDetail implements Action {
  readonly type = HotelDetailActionTypes.ResetHotelDetail;
}

export class HotelDetailLoadError implements Action {
  readonly type = HotelDetailActionTypes.HotelDetailLoadError;
  constructor(public payload: any) {}
}

export class HotelDetailLoaded implements Action {
  readonly type = HotelDetailActionTypes.HotelDetailLoaded;
  constructor(public payload: OtaResultOfSearchAllRatesResult) {}
}

export type HotelDetailAction =
  | LoadHotelDetail
  | HotelDetailLoaded
  | ResetHotelDetail
  | HotelDetailLoadError;

export const fromHotelDetailActions = {
  LoadHotelDetail,
  HotelDetailLoaded,
  ResetHotelDetail,
  HotelDetailLoadError
};
