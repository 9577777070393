import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  HOTEL_FEATURE_KEY,
  initialState as hotelInitialState,
  hotelReducer
} from './+state/hotel.reducer';
import { HotelEffects } from './+state/hotel.effects';
import { HotelFacade } from './+state/hotel.facade';
import { HotelResourceService } from '@gtd/b2c-client';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(HOTEL_FEATURE_KEY, hotelReducer, {
      initialState: hotelInitialState
    }),
    EffectsModule.forFeature([HotelEffects])
  ],
  providers: [HotelFacade, HotelResourceService]
})
export class HotelModule {}
