import {Action} from '@ngrx/store';
import {MobileSocialAuth} from "@gtd/b2c-client";

export enum SocialAuthActionTypes {
  LoadSocialAuth = '[SocialAuth] Load SocialAuth',
  SocialAuthLoaded = '[SocialAuth] SocialAuth Loaded',
  LOGOUT = '[SocialAuth] Logout',
  SocialAuthLoadError = '[SocialAuth] SocialAuth Load Error'
}

export class LoadSocialAuth implements Action {
  readonly type = SocialAuthActionTypes.LoadSocialAuth;
  constructor(public payload: MobileSocialAuth) {}
}

export class SocialAuthLoadError implements Action {
  readonly type = SocialAuthActionTypes.SocialAuthLoadError;
  constructor(public payload: any) {}
}

export class SocialAuthLoaded implements Action {
  readonly type = SocialAuthActionTypes.SocialAuthLoaded;
  constructor(public payload: any) {}
}

export class Logout implements Action {
  readonly type = SocialAuthActionTypes.LOGOUT;
}

export type SocialAuthAction =
  | LoadSocialAuth
  | SocialAuthLoaded
  | SocialAuthLoadError
  | Logout;

export const fromSocialAuthActions = {
  LoadSocialAuth,
  SocialAuthLoaded,
  SocialAuthLoadError,
  Logout
};
