import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ComboBookingPartialState } from './combo-booking.reducer';
import { ComboBookingQuery } from './combo-booking.selectors';
import { ComboVJComboBooking } from './combo-booking.actions';
import { HotelMetaKeyword } from '@gtd/b2c-client';

@Injectable()
export class ComboVJComboBookingFacade {
  loaded$ = this.store.pipe(select(ComboBookingQuery.getLoaded));
  allComboBooking$ = this.store.pipe(
    select(ComboBookingQuery.getAllComboBooking)
  );
  selectedComboBooking$ = this.store.pipe(
    select(ComboBookingQuery.getSelectedComboBooking)
  );

  constructor(private store: Store<ComboBookingPartialState>) {}
  //TODO
  addFlight(searchInformation: any) {
    this.store.dispatch(new ComboVJComboBooking(searchInformation));
  }
}
