import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AirlineAlliancesResourceService } from './api/airlineAlliancesResource.service';
import { AirlineResourceService } from './api/airlineResource.service';
import { AirportResourceService } from './api/airportResource.service';
import { CityCodeResourceService } from './api/cityCodeResource.service';
import { CountryCodeResourceService } from './api/countryCodeResource.service';
import { DestinationMappingCodeResourceService } from './api/destinationMappingCodeResource.service';
import { DestinationMappingRegionResourceService } from './api/destinationMappingRegionResource.service';
import { DestinationMappingResourceService } from './api/destinationMappingResource.service';
import { ElasticsearchIndexResourceService } from './api/elasticsearchIndexResource.service';
import { EmailTemplateResourceService } from './api/emailTemplateResource.service';
import { HotelMappingCodeResourceService } from './api/hotelMappingCodeResource.service';
import { HotelMappingResourceService } from './api/hotelMappingResource.service';
import { HotelMetaContentResourceService } from './api/hotelMetaContentResource.service';
import { LookupResourceService } from './api/lookupResource.service';
import { MetaCacheDataResourceService } from './api/metaCacheDataResource.service';
import { ProfileInfoResourceService } from './api/profileInfoResource.service';
import { RegionResourceService } from './api/regionResource.service';
import { RouteResourceService } from './api/routeResource.service';
import { StateCodeResourceService } from './api/stateCodeResource.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AirlineAlliancesResourceService,
    AirlineResourceService,
    AirportResourceService,
    CityCodeResourceService,
    CountryCodeResourceService,
    DestinationMappingCodeResourceService,
    DestinationMappingRegionResourceService,
    DestinationMappingResourceService,
    ElasticsearchIndexResourceService,
    EmailTemplateResourceService,
    HotelMappingCodeResourceService,
    HotelMappingResourceService,
    HotelMetaContentResourceService,
    LookupResourceService,
    MetaCacheDataResourceService,
    ProfileInfoResourceService,
    RegionResourceService,
    RouteResourceService,
    StateCodeResourceService
  ]
})
export class MetaClientModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders {
    return {
      ngModule: MetaClientModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: MetaClientModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'MetaClientModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
