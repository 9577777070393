import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {AuthComponent} from './auth.component';
import {MatCardModule} from '@angular/material/card';
import {AuthRoutingModule} from './auth.module.routing';
import {LogoutComponent} from './logout/logout.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {RequestPasswordComponent} from './request-password/request-password.component';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';

import {library} from '@fortawesome/fontawesome-svg-core';

import {faArrowLeft, faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faGoogle, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule, MatDividerModule, MatSnackBarModule} from '@angular/material';
import {SocialLoginComponent} from './social-login/social-login.component';
import {AuthenticateModule} from "./authenticate/authenticate.module";
import {DialogMessageComponent, DialogMessageModule} from "@gtd/components/dialog-message";
import {SnackbarModule} from "@gtd/components/snackbar";
import {RegisterStateModule} from "./register/register-state/register-state.module";
import {AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule} from "angularx-social-login";
import {environment} from '@env/environment';
import {SocialAuthModule} from "./social-login/social-auth/social-auth.module";
import {RequestPasswordStateModule} from "./request-password/request-password-state/request-password-state.module";
import {ResetPasswordStateModule} from "./reset-password/reset-password-state/reset-password-state.module";
import {PhoneRegisterComponent} from './register/phone-register/phone-register.component';
import {PhoneRegisterStateModule} from "./register/phone-register/phone-register-state/phone-register-state.module";
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';

import {EmailRegisterComponent} from './register/email-register/email-register.component';
import {PhoneRegisterOtpComponent} from './register/phone-register/phone-register-otp/phone-register-otp.component';
import {MinuteSecondPipe} from './pipe/MinuteSecondPipe/minute-second.pipe';
import {CountryModule} from "@gtd/meta";
import {TranslateModule} from "@ngx-translate/core";
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import {ActivateAccountStateModule} from "./activate-account/activate-account-state/activate-account-state.module";
import {TimeCountDownBetweenModule} from "@gtd/directives/time-count-down-between";
import {QrcodePageComponent} from "./register/qr-code-page/qrcode-page.component";

library.add(faArrowLeft, faFacebook, faTwitter, faGoogle, faEye, faEyeSlash);


const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.socialConfig.googleOAuthClientId)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.socialConfig.facebookAppId)
  }
]);
export function provideConfig() {
  return config;
}

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    AuthRoutingModule,
    MatButtonModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    FormsModule,
    AuthenticateModule,
    ReactiveFormsModule,
    MatDividerModule,
    DialogMessageModule,
    SnackbarModule,
    MatSnackBarModule,
    RegisterStateModule,
    SocialLoginModule,
    SocialAuthModule,
    RequestPasswordStateModule,
    ResetPasswordStateModule,
    PhoneRegisterStateModule,
    MatTabsModule,
    MatSelectModule,
    CountryModule,
    TranslateModule,
    MatDatepickerModule,
    ActivateAccountStateModule,
    TimeCountDownBetweenModule,
  ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    AuthComponent,
    LogoutComponent,
    ResetPasswordComponent,
    RequestPasswordComponent,
    SocialLoginComponent,
    PhoneRegisterComponent,
    EmailRegisterComponent,
    PhoneRegisterOtpComponent,
    MinuteSecondPipe,
    ActivateAccountComponent,
    QrcodePageComponent
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    DialogMessageComponent
  ],
  exports: [RegisterComponent]
})
export class AuthModule {}
