import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { TourSearchByKeywordPartialState } from './tour-search-by-keyword.reducer';
import { tourSearchByKeywordQuery } from './tour-search-by-keyword.selectors';
import {
  LoadTourSearchByKeyword,
  ResetTourSearchByKeyword,
  TourSearchByKeywordRQ
} from './tour-search-by-keyword.actions';

@Injectable()
export class TourSearchByKeywordFacade {
  loaded$ = this.store.pipe(select(tourSearchByKeywordQuery.getLoaded));
  allTourSearchByKeyword$ = this.store.pipe(
    select(tourSearchByKeywordQuery.getAllTourSearchByKeyword)
  );
  selectedTourSearchByKeyword$ = this.store.pipe(
    select(tourSearchByKeywordQuery.getSelectedTourSearchByKeyword)
  );

  constructor(private store: Store<TourSearchByKeywordPartialState>) {}

  loadAll(payload: TourSearchByKeywordRQ) {
    this.store.dispatch(new LoadTourSearchByKeyword(payload));
  }

  resetAll() {
    this.store.dispatch(new ResetTourSearchByKeyword());
  }
}
