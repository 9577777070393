import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PAYMENTOPTIONINFO_FEATURE_KEY,
  PaymentOptionInfoState
} from './payment-option-info.reducer';

// Lookup the 'PaymentOptionInfo' feature state managed by NgRx
const getPaymentOptionInfoState = createFeatureSelector<PaymentOptionInfoState>(
  PAYMENTOPTIONINFO_FEATURE_KEY
);

const getLoaded = createSelector(
  getPaymentOptionInfoState,
  (state: PaymentOptionInfoState) => state.loaded
);
const getError = createSelector(
  getPaymentOptionInfoState,
  (state: PaymentOptionInfoState) => state.error
);

const getAllPaymentOptionInfo = createSelector(
  getPaymentOptionInfoState,
  getLoaded,
  (state: PaymentOptionInfoState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getPaymentOptionInfoState,
  (state: PaymentOptionInfoState) => state.selectedId
);
const getSelectedPaymentOptionInfo = createSelector(
  getAllPaymentOptionInfo,
  getSelectedId,
  (paymentOptionInfo, id) => {
    return paymentOptionInfo ? Object.assign({}, paymentOptionInfo) : undefined;
  }
);

export const paymentOptionInfoQuery = {
  getLoaded,
  getError,
  getAllPaymentOptionInfo,
  getSelectedPaymentOptionInfo
};
