/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AgentCustomerMemberCardDTO } from './agentCustomerMemberCardDTO';


export interface AgentCustomerDTO { 
    address?: string;
    adultType?: AgentCustomerDTO.AdultTypeEnum;
    agencyCode?: string;
    agentCode?: string;
    agentProfileId?: number;
    branchCode?: string;
    branchName?: string;
    city?: string;
    companyAddress?: string;
    companyName?: string;
    companyTaxCode?: string;
    country?: string;
    dateOfBirth?: string;
    department?: string;
    dob?: Date;
    email?: string;
    eventDate?: number;
    expiredDate?: Date;
    firstName?: string;
    gender?: AgentCustomerDTO.GenderEnum;
    id?: number;
    identityNumber?: string;
    issuingCountry?: string;
    lastName?: string;
    memberCard?: boolean;
    memberCards?: Array<AgentCustomerMemberCardDTO>;
    nationality?: string;
    nationalityName?: string;
    nickName?: string;
    notes?: string;
    passportNumber?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    position?: string;
    postalCode?: string;
    status?: AgentCustomerDTO.StatusEnum;
}
export namespace AgentCustomerDTO {
    export type AdultTypeEnum = 'ADT' | 'CHD' | 'INF';
    export const AdultTypeEnum = {
        ADT: 'ADT' as AdultTypeEnum,
        CHD: 'CHD' as AdultTypeEnum,
        INF: 'INF' as AdultTypeEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'BOY' | 'GIRL' | 'INF';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        BOY: 'BOY' as GenderEnum,
        GIRL: 'GIRL' as GenderEnum,
        INF: 'INF' as GenderEnum
    };
    export type StatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'OTHER';
    export const StatusEnum = {
        ACTIVATED: 'ACTIVATED' as StatusEnum,
        DEACTIVATED: 'DEACTIVATED' as StatusEnum,
        OTHER: 'OTHER' as StatusEnum
    };
}
