import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ProfilePartialState } from './profile.reducer';
import { profileQuery } from './profile.selectors';
import {LoadProfile, UpdateProfile} from './profile.actions';
import {CustomerProfileDTO} from "@gtd/api-services/customer-service";

@Injectable()
export class ProfileFacade {
  loaded$ = this.store.pipe(select(profileQuery.getLoaded));
  allProfile$ = this.store.pipe(select(profileQuery.getAllProfile));
  selectedProfile$ = this.store.pipe(select(profileQuery.getSelectedProfile));
  selectProfile$ = this.store.pipe(select(profileQuery.getProfile));

  //Get Update Customer Profile
  selectUpdateCustomerProfile$ = this.store.pipe(select(profileQuery.getUpdateCustomerProfile));

  constructor(private store: Store<ProfilePartialState>) {}

  getProfileUsingGet(idProfile: number) {
    this.store.dispatch(new LoadProfile(idProfile));
  }

  updateCustomerProfileUsingPut(customerProfileDTO: CustomerProfileDTO) {
    this.store.dispatch(new UpdateProfile(customerProfileDTO));
  }
}
