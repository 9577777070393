import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { UserJwtControllerService } from '@gtd/b2c-client';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AccountFacade } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  isSticky = false;
  subscriptions = new Subscription();
  param = { value: 'world' };
  isLogged;
  showDownloadRequest = this.deviceService.isMobile();
  isHideSubBar = false;
  layout: string = 'DUAL';
  utm_source: string = localStorage.getItem('utm_source');
  isShowFeedbackButton = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 1;
  }

  constructor(
    private router: Router,
    private userJwtControllerService: UserJwtControllerService,
    public deviceService: DeviceDetectorService,
    private overlayContainer: OverlayContainer,
    private activatedRoute: ActivatedRoute,
    private accountFacade: AccountFacade,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.changeTheme('gtd-template');
    if (isPlatformBrowser(this.platformId)) {
      const savedCredentials =
        sessionStorage.getItem('credentials') ||
        localStorage.getItem('credentials');
      if (!savedCredentials) {
        this.userJwtControllerService
          .authorizeUsingPOST({
            username: 'guest',
            password: 'guest'
          })
          .subscribe(
            loginGuest => {
              if (loginGuest && loginGuest.id_token) {
                localStorage.setItem('credentials-guest', loginGuest.id_token);
              }
            },
            error1 => {
              if (error1 && error1.status === 417) {
                localStorage.removeItem('credentials-guest');
                this.userJwtControllerService
                  .authorizeUsingPOST({
                    username: 'guest',
                    password: 'guest'
                  })
                  .subscribe(loginGuest => {
                    if (loginGuest && loginGuest.id_token) {
                      localStorage.setItem(
                        'credentials-guest',
                        loginGuest.id_token
                      );
                    }
                  });
                setTimeout(() => {
                  window.location.reload();
                }, 100);
              }
            }
          );
      }
    }
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('/booking/payment/payment-exception')) {
          this.layout = 'SINGLE';
        }
        if (
          event.url === '/' ||
          event.url === '/ve-may-bay' ||
          event.url === '/khach-san' ||
          event.url === '/combo-du-lich' ||
          event.url === '/vui-choi-giai-tri'
        ) {
          this.isHideSubBar = true;
        } else {
          this.isHideSubBar = false;
        }
      });
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams.layout) {
        this.layout = queryParams.layout;
        sessionStorage.setItem('layout', queryParams.layout);
      }
      if (queryParams && queryParams.utm_source) {
        this.utm_source = queryParams.utm_source;
        localStorage.setItem('utm_source', queryParams.utm_source);
      }
    });
    this.accountFacade.getAccountLogged$.subscribe(selectedAccount => {
      if (
        selectedAccount &&
        selectedAccount.email &&
        selectedAccount.email.includes('@gotadi.com')
      ) {
        this.isShowFeedbackButton = true;
      }
    });
  }

  changeTheme(theme: string) {
    this.overlayContainer.getContainerElement().classList.add(theme);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
