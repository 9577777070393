import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromGetUserProfile from './get-user-profile/get-user-profile.reducer';
import { GetUserProfileEffects } from './get-user-profile/get-user-profile.effects';
import { GetUserProfileFacade } from './get-user-profile/get-user-profile.facade';
import {
  AccountResourceService,
  AgencyFinancialResourceService,
  AgentResourceService,
  BASE_PATH,
  BookingTransactionResourceService,
  DashboardResourceService,
  MarkupB2BResourceService,
  MarkupExceptionResourceService,
  UserProfileResourceService
} from '../lib';
import { environment } from '@env/environment';
import * as fromGetHeaderSummary from './get-header-summary/get-header-summary.reducer';
import { GetHeaderSummaryEffects } from './get-header-summary/get-header-summary.effects';
import { GetHeaderSummaryFacade } from './get-header-summary/get-header-summary.facade';
import * as fromSearchBookingTransactions from './search-booking-transactions/search-booking-transactions.reducer';
import { SearchBookingTransactionsEffects } from './search-booking-transactions/search-booking-transactions.effects';
import { SearchBookingTransactionsFacade } from './search-booking-transactions/search-booking-transactions.facade';
import { TransactionFinancialEffects } from './transaction-financial/transaction-financial.effects';
import * as fromTransactionFinancial from './transaction-financial/transaction-financial.reducer';
import { TransactionFinancialFacade } from './transaction-financial/transaction-financial.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromGetUserProfile.GETUSERPROFILE_FEATURE_KEY,
      fromGetUserProfile.reducerProfile
    ),
    EffectsModule.forFeature([GetUserProfileEffects]),
    StoreModule.forFeature(
      fromGetHeaderSummary.GETHEADERSUMMARY_FEATURE_KEY,
      fromGetHeaderSummary.reducerSummary
    ),
    EffectsModule.forFeature([GetHeaderSummaryEffects]),
    StoreModule.forFeature(
      fromSearchBookingTransactions.SEARCHBOOKINGTRANSACTIONS_FEATURE_KEY,
      fromSearchBookingTransactions.reducerSearchTransaction
    ),
    EffectsModule.forFeature([SearchBookingTransactionsEffects]),

    StoreModule.forFeature(
      fromTransactionFinancial.TRANSACTIONFINANCIAL_FEATURE_KEY,
      fromTransactionFinancial.reducerTransaction
    ),
    EffectsModule.forFeature([TransactionFinancialEffects])
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2bClient },
    GetUserProfileFacade,
    UserProfileResourceService,
    GetHeaderSummaryFacade,
    DashboardResourceService,
    SearchBookingTransactionsFacade,
    TransactionFinancialFacade,
    BookingTransactionResourceService,
    AgencyFinancialResourceService,
    AccountResourceService,
    AgentResourceService,
    MarkupB2BResourceService,
    MarkupExceptionResourceService
  ]
})
export class B2bClientApiModule {}
