import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { MarkAsUnreadPartialState } from './mark-as-unread.reducer';
import { markAsUnreadQuery } from './mark-as-unread.selectors';
import { LoadMarkAsUnread } from './mark-as-unread.actions';
import {MessageItem} from "@gtd/api-services/customer-service";

@Injectable()
export class MarkAsUnreadFacade {
  loaded$ = this.store.pipe(select(markAsUnreadQuery.getLoaded));
  allMarkAsUnread$ = this.store.pipe(
    select(markAsUnreadQuery.getAllMarkAsUnread)
  );
  selectedMarkAsUnread$ = this.store.pipe(
    select(markAsUnreadQuery.getSelectedMarkAsUnread)
  );

  constructor(private store: Store<MarkAsUnreadPartialState>) {}

  markAsUnread(messageItems: Array<MessageItem>) {
    this.store.dispatch(new LoadMarkAsUnread(messageItems));
  }
}
