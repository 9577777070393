import { OtaResultOfTourSuggestSearchPageDTO } from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './tour-search-by-keyword.reducer';

export enum TourSearchByKeywordActionTypes {
  LoadTourSearchByKeyword = '[TourSearchByKeyword] Load TourSearchByKeyword',
  ResetTourSearchByKeyword = '[TourSearchByKeyword] Reset TourSearchByKeyword',
  TourSearchByKeywordLoaded = '[TourSearchByKeyword] TourSearchByKeyword Loaded',
  TourSearchByKeywordLoadError = '[TourSearchByKeyword] TourSearchByKeyword Load Error'
}

export class LoadTourSearchByKeyword implements Action {
  readonly type = TourSearchByKeywordActionTypes.LoadTourSearchByKeyword;
  constructor(public payload: TourSearchByKeywordRQ) {}
}

export class ResetTourSearchByKeyword implements Action {
  readonly type = TourSearchByKeywordActionTypes.ResetTourSearchByKeyword;
}

export class TourSearchByKeywordLoadError implements Action {
  readonly type = TourSearchByKeywordActionTypes.TourSearchByKeywordLoadError;
  constructor(public payload: any) {}
}

export class TourSearchByKeywordLoaded implements Action {
  readonly type = TourSearchByKeywordActionTypes.TourSearchByKeywordLoaded;
  constructor(public payload: OtaResultOfTourSuggestSearchPageDTO) {}
}

export type TourSearchByKeywordAction =
  | LoadTourSearchByKeyword
  | ResetTourSearchByKeyword
  | TourSearchByKeywordLoaded
  | TourSearchByKeywordLoadError;

export const fromTourSearchByKeywordActions = {
  LoadTourSearchByKeyword,
  ResetTourSearchByKeyword,
  TourSearchByKeywordLoaded,
  TourSearchByKeywordLoadError
};

export interface TourSearchByKeywordRQ {
  keyword: string;
  saleChannel: string;
}
