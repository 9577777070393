import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AgentcyBookingResourceService } from './api/agentcyBookingResource.service';
import { BookingAdditionalRequestResourceService } from './api/bookingAdditionalRequestResource.service';
import { BookingResourceService } from './api/bookingResource.service';
import { BookingResourceV2Service } from './api/bookingResourceV2.service';
import { BookingScheduleResourceService } from './api/bookingScheduleResource.service';
import { BookingTagConnectionsResourceService } from './api/bookingTagConnectionsResource.service';
import { BookingTagsResourceService } from './api/bookingTagsResource.service';
import { BookingTransactionResourceService } from './api/bookingTransactionResource.service';
import { HotelBookingResourceService } from './api/hotelBookingResource.service';
import { OfflineBookingResourceService } from './api/offlineBookingResource.service';
import { ProductBookingResourceService } from './api/productBookingResource.service';
import { ProfileInfoResourceService } from './api/profileInfoResource.service';
import { ReconciliationResourceService } from './api/reconciliationResource.service';
import { RemindBookingSmsResourceService } from './api/remindBookingSmsResource.service';
import { SaleChannelBookingResourceService } from './api/saleChannelBookingResource.service';
import { TicketBookingResourceService } from './api/ticketBookingResource.service';
import { TikiBookingResourceService } from './api/tikiBookingResource.service';
import { ViettelBookingResourceService } from './api/viettelBookingResource.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AgentcyBookingResourceService,
    BookingAdditionalRequestResourceService,
    BookingResourceService,
    BookingResourceV2Service,
    BookingScheduleResourceService,
    BookingTagConnectionsResourceService,
    BookingTagsResourceService,
    BookingTransactionResourceService,
    HotelBookingResourceService,
    OfflineBookingResourceService,
    ProductBookingResourceService,
    ProfileInfoResourceService,
    ReconciliationResourceService,
    RemindBookingSmsResourceService,
    SaleChannelBookingResourceService,
    TicketBookingResourceService,
    TikiBookingResourceService,
    ViettelBookingResourceService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('B2cApiClientModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
