import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {MarkAsUnreadPartialState} from './mark-as-unread.reducer';
import {
  LoadMarkAsUnread,
  MarkAsUnreadActionTypes,
  MarkAsUnreadLoaded,
  MarkAsUnreadLoadError
} from './mark-as-unread.actions';
import {map} from "rxjs/operators";
import {MessageItemResourceService} from "@gtd/api-services/customer-service";

@Injectable()
export class MarkAsUnreadEffects {
  @Effect() loadMarkAsUnread$ = this.dataPersistence.fetch(
    MarkAsUnreadActionTypes.LoadMarkAsUnread,
    {
      run: (action: LoadMarkAsUnread, state: MarkAsUnreadPartialState) => {
        return this.messageItemResourceService.markReadItemsMessageUsingPUT(action.payload).pipe(
          map(payload => ({
            type: MarkAsUnreadActionTypes.MarkAsUnreadLoaded,
            payload: payload
          }))
        );
      },

      onError: (action: LoadMarkAsUnread, error) => {
        return new MarkAsUnreadLoaded(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private messageItemResourceService: MessageItemResourceService,
    private dataPersistence: DataPersistence<MarkAsUnreadPartialState>
  ) {}
}
