import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { LowestPriceFlightsPartialState } from './lowest-price-flights.reducer';
import {
  LoadLowestPriceFlights,
  LowestPriceFlightsLoadError,
  LowestPriceFlightsActionTypes
} from './lowest-price-flights.actions';
import { AirTicketsResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class LowestPriceFlightsEffects {
  @Effect() loadLowestPriceFlights$ = this.dataPersistence.fetch(
    LowestPriceFlightsActionTypes.LoadLowestPriceFlights,
    {
      run: (
        action: LoadLowestPriceFlights,
        state: LowestPriceFlightsPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.airTicketsResourceService
          .getAirFilterOptionsUsingPOST(action.payload)
          .pipe(
            map(result => ({
              type: LowestPriceFlightsActionTypes.LowestPriceFlightsLoaded,
              payload: result
            }))
          );
      },

      onError: (action: LoadLowestPriceFlights, error) => {
        console.error('Error', error);
        return new LowestPriceFlightsLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<LowestPriceFlightsPartialState>,
    private airTicketsResourceService: AirTicketsResourceService
  ) {}
}
