import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import {AddressPipe, SanitizePipe} from './sanitize.pipe';

export const sanitizeRoutes: Route[] = [];

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [SanitizePipe, AddressPipe],
  exports: [SanitizePipe, AddressPipe]
})
export class SanitizeModule {}
