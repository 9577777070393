import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {DeleteNotificationPartialState} from './delete-notification.reducer';
import {
  DeleteNotificationActionTypes,
  DeleteNotificationLoadError,
  LoadDeleteNotification
} from './delete-notification.actions';
import {map} from "rxjs/operators";
import {MessageItemResourceService} from "@gtd/api-services/customer-service";

@Injectable()
export class DeleteNotificationEffects {
  @Effect() loadDeleteNotification$ = this.dataPersistence.fetch(
    DeleteNotificationActionTypes.LoadDeleteNotification,
    {
      run: (action: LoadDeleteNotification, state: DeleteNotificationPartialState) => {
        return this.messageItemResourceService.deleteMessageItemUsingDELETE(action.payload.userId, action.payload.messageId).pipe(
          map(payload => ({
            type: DeleteNotificationActionTypes.DeleteNotificationLoaded,
            payload: payload
          }))
        );
      },

      onError: (action: LoadDeleteNotification, error) => {
        console.error('Error', error);
        return new DeleteNotificationLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private messageItemResourceService: MessageItemResourceService,
    private dataPersistence: DataPersistence<DeleteNotificationPartialState>
  ) {}
}
