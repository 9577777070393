import {
  LowestPriceFlightsAction,
  LowestPriceFlightsActionTypes
} from './lowest-price-flights.actions';
import { AirItineraryFilterRS } from '@gtd/b2c-client';

export const LOWESTPRICEFLIGHTS_FEATURE_KEY = 'lowestPriceFlights';

export interface LowestPriceFlightsState {
  data: AirItineraryFilterRS; // list of LowestPriceFlights; analogous to a sql normalized table
  selectedId?: string | number; // which LowestPriceFlights record has been selected
  loaded: boolean; // has the LowestPriceFlights list been loaded
  error?: any; // last none error (if any)
}

export interface LowestPriceFlightsPartialState {
  readonly [LOWESTPRICEFLIGHTS_FEATURE_KEY]: LowestPriceFlightsState;
}

export const initialState: LowestPriceFlightsState = {
  data: {},
  loaded: false
};

export function lowestPriceFlightsReducer(
  state: LowestPriceFlightsState = initialState,
  action: LowestPriceFlightsAction
): LowestPriceFlightsState {
  switch (action.type) {
    case LowestPriceFlightsActionTypes.LowestPriceFlightsLoaded: {
      state = {
        ...state,
        data: action.payload,
        loaded: true
      };
      break;
    }
    case LowestPriceFlightsActionTypes.ResetLowestPriceFlights: {
      state = initialState;
      break;
    }
    case LowestPriceFlightsActionTypes.LowestPriceFlightsLoadError: {
      initialState.loaded = true;
      state = initialState;
      break;
    }
    case LowestPriceFlightsActionTypes.LoadLowestPriceFlights:{
      state = {
        ...state,
        loaded: false
      }
    }
  }
  return state;
}
