import {
  HotelCheckoutAction,
  HotelCheckoutActionTypes
} from './hotel-checkout.actions';
import { OtaResultOfCheckoutResult } from '@gtd/b2c-client';

export const HOTELCHECKOUT_FEATURE_KEY = 'comboVJHotelCheckout';

/**
 * Interface for the 'HotelCheckout' data used in
 *  - HotelCheckoutState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelCheckoutState {
  result: OtaResultOfCheckoutResult; // list of HotelCheckout; analogous to a sql normalized table
  selectedId?: string | number; // which HotelCheckout record has been selected
  loaded: boolean; // has the HotelCheckout list been loaded
  error?: any; // last none error (if any)
}

export interface HotelCheckoutPartialState {
  readonly [HOTELCHECKOUT_FEATURE_KEY]: HotelCheckoutState;
}

export const initialState: HotelCheckoutState = {
  result: null,
  loaded: false
};

export function hotelCheckoutReducer(
  state: HotelCheckoutState = initialState,
  action: HotelCheckoutAction
): HotelCheckoutState {
  switch (action.type) {
    case HotelCheckoutActionTypes.ComboVJHotelCheckoutLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
    case HotelCheckoutActionTypes.ComboVJResetHotelCheckout: {
      state = {
        ...initialState
      };
      break;
    }
    case HotelCheckoutActionTypes.ComboVJHotelCheckoutLoadError: {
      initialState.loaded = true;
      state = initialState;
      break;
    }
  }
  return state;
}
