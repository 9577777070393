import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';
import { DatepickerService, LunarDateExport } from './datepicker.service';
import { ControlContainer } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  CustomDatepickerI18n,
  TranslateDatePicker
} from './date-format.directive';
import { TranslateService } from '@ngx-translate/core';
import { FlightTypeSwitcherEnum } from '../switcher/switcher.component';

@Component({
  selector: 'gtd-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    TranslateDatePicker,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class DatepickerComponent implements OnInit, OnChanges {
  @ViewChild('datepicker', { static: true }) datePicker: NgbInputDatepicker;
  @Input() displayMonths: number;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() defaultFromDate: Date;
  @Input() defaultToDate: Date;
  @Input() labelFormDate: string;
  @Input() labelToDate: string;
  @Input() bgWhite: boolean;
  @Input() sizeMd: boolean;
  @Input() type = 'flight';
  @Input() roundType: FlightTypeSwitcherEnum;
  @Input() submited = false;
  @Input() toDateHotel: Date;
  @Output() roundTypeChangeListener = new EventEmitter<any>();
  @Output() getChanged = new EventEmitter<boolean>();
  dateType: 'to' | 'from';
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  numberNight = 1;
  lang: string;
  minNgbDate: NgbDate;
  maxNgbDate: NgbDate;
  forcus: 'to' | 'from';
  outsideDays: 'visible' | 'hidden';

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private datepickerService: DatepickerService,
    public controlContainer: ControlContainer,
    private translate: TranslateService,
    private translateDatePicker: TranslateDatePicker,
    private deviceService: DeviceDetectorService
  ) {
    this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
      this.translateDatePicker.language = lang.lang;
    });
  }

  ngOnInit() {
    this.lang = localStorage.getItem('language');
    this.translateDatePicker.language = localStorage.getItem('language');

    this.displayMonths =
      this.deviceService.isDesktop() || this.deviceService.isTablet() ? 2 : 1;
    this.outsideDays =
      this.deviceService.isDesktop() || this.deviceService.isTablet()
        ? 'visible'
        : 'hidden';
    if (this.minDate) {
      this.minNgbDate = this.convertNgbDate(this.minDate);
    }
    if (this.maxDate) {
      this.maxNgbDate = this.convertNgbDate(this.maxDate);
    } else {
      this.maxNgbDate = this.convertNgbDate(
        new Date(new Date().setDate(new Date().getDate() + 361))
      );
    }
    if (this.defaultFromDate) {
      this.fromDate = this.convertNgbDate(this.defaultFromDate);
    }
    if (this.defaultToDate) {
      this.toDate = this.convertNgbDate(this.defaultToDate);
    }
    if (this.type === 'hotel') {
      this.numberNight = this.datediff(
        new Date(
          this.fromDate.year,
          this.fromDate.month - 1,
          this.fromDate.day
        ),
        new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
      );
    }
  }

  convertNgbDate(date: Date): NgbDate {
    return this.calendar.getNext(
      {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        after(other: NgbDateStruct): boolean {
          return true;
        },
        before(other: NgbDateStruct): boolean {
          return true;
        },
        equals(other: NgbDateStruct): boolean {
          return true;
        }
      },
      'd',
      0
    );
  }
  updateDateData(dateType: 'from' | 'to', date: NgbDate) {
    if (dateType === 'from') {
      this.fromDate = date;
    } else {
      this.toDate = date;
    }

    this.forcus = dateType;
  }
  onDateSelection(date: NgbDate) {
    if (this.roundType === 'ONEWAY') {
      this.updateDateData('from', date);
      this.datePicker.toggle();
    } else {
      if (this.dateType === 'from') {
        if (!this.fromDate && !this.toDate) {
          this.updateDateData('from', date);
        } else if (
          this.fromDate &&
          !this.toDate &&
          (date.after(this.fromDate) ||
            (this.type !== 'flight' && date.equals(this.fromDate)))
        ) {
          this.updateDateData('to', date);
          this.datePicker.toggle();
        } else if (
          this.fromDate &&
          !this.toDate &&
          date.before(this.fromDate)
        ) {
          this.updateDateData('from', date);
        } else if (date.after(this.toDate) || date.equals(this.toDate)) {
          this.toDate = null;
          this.updateDateData('from', date);
        } else {
          this.updateDateData('from', date);
          this.datePicker.toggle();
        }
      } else {
        if (
          (!this.fromDate && !this.toDate) ||
          date.before(this.fromDate) ||
          (this.type !== 'flight' && date.equals(this.fromDate))
        ) {
          this.toDate = null;
          this.updateDateData('from', date);
        } else {
          this.updateDateData('to', date);
          this.datePicker.toggle();
        }
      }
    }
    this.setDate();
    this.getChanged.emit(true);
  }
  getDefaultDate(date: NgbDate) {
    return Date.UTC(date.year, date.month - 1, date.day);
  }
  setDate() {
    this.controlContainer.control.setValue({
      fromDate: new Date(
        Date.UTC(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
      ),
      toDate:
        this.roundType === 'ROUNDTRIP' && this.toDate
          ? new Date(
              Date.UTC(this.toDate.year, this.toDate.month - 1, this.toDate.day)
            )
          : this.toDateHotel
          ? this.toDateHotel
          : ''
    });
    if (this.type === 'hotel') {
      this.numberNight = this.datediff(
        new Date(
          this.fromDate.year,
          this.fromDate.month - 1,
          this.fromDate.day
        ),
        new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
      );
    }
  }

  checkPosition(date: NgbDate) {
    if (date.equals(this.fromDate) && date.equals(this.toDate)) {
      return 'both';
    } else if (date.equals(this.fromDate)) {
      return 'first';
    } else if (date.equals(this.toDate)) {
      return 'last';
    }
  }
  isValid(date: NgbDate) {
    return (
      date.before(this.maxNgbDate) ||
      date.after(this.minNgbDate) ||
      date.equals(this.maxNgbDate) ||
      date.equals(this.minNgbDate)
    );
  }
  isPreviousDate(date: NgbDate) {
    if (
      this.dateType === 'to' &&
      this.hoveredDate &&
      this.hoveredDate.after(this.toDate)
    )
      return date.equals(this.toDate);
  }
  isHovered(date: NgbDate) {
    if (this.dateType === 'to' && this.toDate) {
      return date.after(this.toDate) && date.before(this.hoveredDate);
    }
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }
  isLastHovered(date: NgbDate) {
    if (this.dateType === 'from' && date.after(this.toDate)) {
      return false;
    }
    if (this.dateType === 'to' && this.toDate) {
      return date.after(this.toDate) && date.equals(this.hoveredDate);
    }
    return date.after(this.fromDate) && date.equals(this.hoveredDate);
  }
  onDateItemClick(dateType: 'from' | 'to') {
    this.dateType = dateType;
  }
  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  getDateLunar(date: NgbDate): LunarDateExport {
    const offset = new Date().getTimezoneOffset();
    return this.datepickerService.convertSolar2Lunar(
      date.day,
      date.month,
      date.year,
      +7
    );
  }

  roundTypeChange(event) {
    this.roundTypeChangeListener.emit(event);
  }

  newDateFormat(date: NgbDate): Date {
    let dateString = date.year + '-' + date.month + '-' + date.day;
    return new Date(dateString);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.roundType && !changes.roundType.firstChange) {
      if (this.fromDate.after(this.toDate)) {
        this.toDate = null;
      }
      this.setDate();
    }
  }

  datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
}
