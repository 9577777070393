import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { AvailableFlightsPartialState } from './available-flights.reducer';
import {
  AvailableFlightsActionTypes,
  AvailableFlightsLoadError,
  LoadAvailableFlights
} from './available-flights.actions';
// import {AirLowFareSearchRS, AirTicketsResourceService} from '@gtd/b2c-client';
import {
  map,
  mergeMap,
  exhaustMap,
  switchMap,
  takeUntil,
  catchError
} from 'rxjs/operators';
import { AirTicketsResourceService } from '@gtd/b2c-client';
@Injectable()
export class AvailableFlightsEffects {
  // @Effect() loadAvailableFlights$ = this.dataPersistence.fetch(
  //   AvailableFlightsActionTypes.LoadAvailableFlights,
  //   {
  //     run: (
  //       action: LoadAvailableFlights,
  //       state: AvailableFlightsPartialState
  //     ) => {
  //       // Your custom REST 'load' logic goes here. For now just return an empty list...
  //       return this.airTicketsResourceService
  //         .filterAirLowFareUsingPOST(
  //           action.payload.filter,
  //           action.payload.includeEquivfare,
  //           action.payload.page,
  //           action.payload.size,
  //           action.payload.sort
  //         )
  //         .pipe(
  //           mergeMap(result => of({
  //             type: AvailableFlightsActionTypes.AvailableFlightsLoaded,
  //             payload: result
  //           })),
  //         );
  //     },

  //     onError: (action: LoadAvailableFlights, error) => {
  //       console.error('Error', error);
  //       return new AvailableFlightsLoadError(error);
  //     }
  //   }
  // );
  @Effect() loadAvailableFlights$ = this.actions$.pipe(
    ofType<LoadAvailableFlights>(
      AvailableFlightsActionTypes.LoadAvailableFlights
    ),
    map(action => action.payload),
    switchMap(payload => {
      return this.airTicketsResourceService
        .filterAirLowFareUsingPOST(
          payload.filter,
          payload.includeEquivfare,
          payload.page,
          payload.size,
          payload.sort
        )
        .pipe(
          map(
            result => ({
              type: AvailableFlightsActionTypes.AvailableFlightsLoaded,
              payload: result
            }),
            catchError(error => {
              console.log(error);
              return null;
            })
          )
        );
    })
  );
  @Effect() loadInternationalFlights$ = this.actions$.pipe(
    ofType<any>(AvailableFlightsActionTypes.LoadInternationnalFlights),
    map(action => action.payload),
    switchMap(payload => {
      // Your custom REST 'load' logic goes here. For now just return an empty list...
      return this.airTicketsResourceService
        .getGroupPricedItineraryUsingPOST(
          payload.id,
          payload.airSearchId,
          payload.includeEquivfare,
          payload.page,
          payload.size,
          payload.sort
        )
        .map(
          result => ({
            type: AvailableFlightsActionTypes.InternationalFlightsLoaded,
            payload: result
          }),
          catchError(error => {
            console.log(error);
            return null;
          })
        );
    })
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<AvailableFlightsPartialState>,
    private airTicketsResourceService: AirTicketsResourceService
  ) {}
}
