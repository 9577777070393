import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { DeleteNotificationPartialState } from './delete-notification.reducer';
import { deleteNotificationQuery } from './delete-notification.selectors';
import { LoadDeleteNotification } from './delete-notification.actions';

@Injectable()
export class DeleteNotificationFacade {
  loaded$ = this.store.pipe(select(deleteNotificationQuery.getLoaded));
  allDeleteNotification$ = this.store.pipe(
    select(deleteNotificationQuery.getAllDeleteNotification)
  );
  selectedDeleteNotification$ = this.store.pipe(
    select(deleteNotificationQuery.getSelectedDeleteNotification)
  );

  constructor(private store: Store<DeleteNotificationPartialState>) {}

  deleteNotification(userId: number, messageId: number) {
    this.store.dispatch(new LoadDeleteNotification({userId: userId, messageId: messageId}));
  }
}
