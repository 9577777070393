import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { TourUnitPricePartialState } from './tour-unit-price.reducer';
import { tourUnitPriceQuery } from './tour-unit-price.selectors';
import { LoadTourUnitPrice } from './tour-unit-price.actions';
import { TourUnitPriceRQ } from '../../..';

@Injectable()
export class TourUnitPriceFacade {
  loaded$ = this.store.pipe(select(tourUnitPriceQuery.getLoaded));
  allTourUnitPrice$ = this.store.pipe(
    select(tourUnitPriceQuery.getAllTourUnitPrice)
  );
  selectedTourUnitPrice$ = this.store.pipe(
    select(tourUnitPriceQuery.getSelectedTourUnitPrice)
  );

  constructor(private store: Store<TourUnitPricePartialState>) {}

  loadAll(payload: TourUnitPriceRQ) {
    this.store.dispatch(new LoadTourUnitPrice(payload));
  }
}
