import {Action} from '@ngrx/store';
import {OtaResultOfSearchKeyWordResult} from "@gtd/b2c-client";

export enum SearchKeywordActionTypes {
  LoadSearchKeyword = '[SearchKeyword] Load SearchKeyword',
  SearchKeywordLoaded = '[SearchKeyword] SearchKeyword Loaded',
  SearchKeywordLoadError = '[SearchKeyword] SearchKeyword Load Error'
}

export class LoadSearchKeyword implements Action {
  readonly type = SearchKeywordActionTypes.LoadSearchKeyword;
  constructor(public payload: {keyword: string, language: 'vi' | 'en', pageNumber?: number, pageSize?: number}) {}
}

export class SearchKeywordLoadError implements Action {
  readonly type = SearchKeywordActionTypes.SearchKeywordLoadError;
  constructor(public payload: any) {}
}

export class SearchKeywordLoaded implements Action {
  readonly type = SearchKeywordActionTypes.SearchKeywordLoaded;
  constructor(public payload: OtaResultOfSearchKeyWordResult) {}
}

export type SearchKeywordAction =
  | LoadSearchKeyword
  | SearchKeywordLoaded
  | SearchKeywordLoadError;

export const fromSearchKeywordActions = {
  LoadSearchKeyword,
  SearchKeywordLoaded,
  SearchKeywordLoadError
};
