/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EticketDO } from './eticketDO';


export interface BookingTransactionInfo { 
    adjustContract?: number;
    adjustNet?: number;
    agencyMarkupValue?: number;
    allowHold?: boolean;
    b2cBasePrice?: number;
    b2cTaxAndFees?: number;
    b2cTotalPrice?: number;
    baseFare?: number;
    bookingCode?: string;
    bookingDate?: Date;
    bookingDirection?: BookingTransactionInfo.BookingDirectionEnum;
    bookingNumber?: string;
    bookingRefNo?: string;
    carrierNo?: string;
    channelType?: BookingTransactionInfo.ChannelTypeEnum;
    checkIn?: Date;
    checkOut?: Date;
    destinationLocationCode?: string;
    detail?: string;
    equivFare?: number;
    etickets?: string;
    id?: number;
    issuedDate?: Date;
    issuedStatus?: BookingTransactionInfo.IssuedStatusEnum;
    listETickets?: Array<EticketDO>;
    markupCode?: string;
    markupFormula?: string;
    markupKey?: string;
    markupValue?: number;
    noAdult?: number;
    noChild?: number;
    noInfant?: number;
    onlyPayLater?: boolean;
    originLocationCode?: string;
    passengerNameRecord?: string;
    paymentAmount?: number;
    productClass?: string;
    productSeqNumber?: string;
    quantity?: number;
    refundable?: boolean;
    saleChannel?: BookingTransactionInfo.SaleChannelEnum;
    serviceTax?: number;
    signature?: string;
    status?: BookingTransactionInfo.StatusEnum;
    supplierBookingStatus?: BookingTransactionInfo.SupplierBookingStatusEnum;
    supplierCode?: string;
    supplierName?: string;
    supplierPaymentStatus?: BookingTransactionInfo.SupplierPaymentStatusEnum;
    supplierType?: BookingTransactionInfo.SupplierTypeEnum;
    timeToLive?: Date;
    totalFare?: number;
    totalSsrValue?: number;
    totalTax?: number;
    unitId?: number;
}
export namespace BookingTransactionInfo {
    export type BookingDirectionEnum = 'DEPARTURE' | 'RETURN' | 'TRIP';
    export const BookingDirectionEnum = {
        DEPARTURE: 'DEPARTURE' as BookingDirectionEnum,
        RETURN: 'RETURN' as BookingDirectionEnum,
        TRIP: 'TRIP' as BookingDirectionEnum
    };
    export type ChannelTypeEnum = 'ONLINE' | 'OFFLINE';
    export const ChannelTypeEnum = {
        ONLINE: 'ONLINE' as ChannelTypeEnum,
        OFFLINE: 'OFFLINE' as ChannelTypeEnum
    };
    export type IssuedStatusEnum = 'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED';
    export const IssuedStatusEnum = {
        PENDING: 'PENDING' as IssuedStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as IssuedStatusEnum,
        SUCCEEDED: 'SUCCEEDED' as IssuedStatusEnum,
        FAILED: 'FAILED' as IssuedStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as IssuedStatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as IssuedStatusEnum
    };
    export type SaleChannelEnum = 'B2B' | 'B2C' | 'B2B2C' | 'B2C_WEB' | 'B2C_WEB_APP' | 'B2C_MOBILE';
    export const SaleChannelEnum = {
        B2B: 'B2B' as SaleChannelEnum,
        B2C: 'B2C' as SaleChannelEnum,
        B2B2C: 'B2B2C' as SaleChannelEnum,
        B2CWEB: 'B2C_WEB' as SaleChannelEnum,
        B2CWEBAPP: 'B2C_WEB_APP' as SaleChannelEnum,
        B2CMOBILE: 'B2C_MOBILE' as SaleChannelEnum
    };
    export type StatusEnum = 'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'TICKET_ON_PROCESS' | 'INACTIVE';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as StatusEnum,
        BOOKED: 'BOOKED' as StatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        TENTATIVE: 'TENTATIVE' as StatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type SupplierBookingStatusEnum = 'PENDING' | 'BOOKED' | 'CONFIRMED' | 'FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'BOOKING_ON_PROCESS' | 'TICKET_ON_PROCESS' | 'PARTLY_BOOKED' | 'PARTLY_CONFIRMED' | 'ISSUE_FAILED' | 'CONFIRM_OFFLINE';
    export const SupplierBookingStatusEnum = {
        PENDING: 'PENDING' as SupplierBookingStatusEnum,
        BOOKED: 'BOOKED' as SupplierBookingStatusEnum,
        CONFIRMED: 'CONFIRMED' as SupplierBookingStatusEnum,
        FAILED: 'FAILED' as SupplierBookingStatusEnum,
        CANCELLED: 'CANCELLED' as SupplierBookingStatusEnum,
        EXPIRED: 'EXPIRED' as SupplierBookingStatusEnum,
        TENTATIVE: 'TENTATIVE' as SupplierBookingStatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as SupplierBookingStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as SupplierBookingStatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as SupplierBookingStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as SupplierBookingStatusEnum,
        ISSUEFAILED: 'ISSUE_FAILED' as SupplierBookingStatusEnum,
        CONFIRMOFFLINE: 'CONFIRM_OFFLINE' as SupplierBookingStatusEnum
    };
    export type SupplierPaymentStatusEnum = 'PENDING' | 'SUCCEEDED' | 'FAILED' | 'REFUNDED';
    export const SupplierPaymentStatusEnum = {
        PENDING: 'PENDING' as SupplierPaymentStatusEnum,
        SUCCEEDED: 'SUCCEEDED' as SupplierPaymentStatusEnum,
        FAILED: 'FAILED' as SupplierPaymentStatusEnum,
        REFUNDED: 'REFUNDED' as SupplierPaymentStatusEnum
    };
    export type SupplierTypeEnum = 'AIR' | 'HOTEL' | 'COMBO' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const SupplierTypeEnum = {
        AIR: 'AIR' as SupplierTypeEnum,
        HOTEL: 'HOTEL' as SupplierTypeEnum,
        COMBO: 'COMBO' as SupplierTypeEnum,
        TOURS: 'TOURS' as SupplierTypeEnum,
        TRAIN: 'TRAIN' as SupplierTypeEnum,
        SHIP: 'SHIP' as SupplierTypeEnum,
        OTHER: 'OTHER' as SupplierTypeEnum
    };
}
