/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MutableTripleOfstringAndstringAndbigdecimal { 
    left?: MutableTripleOfstringAndstringAndbigdecimal.LeftEnum;
    middle?: MutableTripleOfstringAndstringAndbigdecimal.MiddleEnum;
    right?: number;
}
export namespace MutableTripleOfstringAndstringAndbigdecimal {
    export type LeftEnum = 'AED' | 'AFN' | 'ALL' | 'AMD' | 'ANG' | 'AOA' | 'ARS' | 'AUD' | 'AWG' | 'AZN' | 'BAM' | 'BBD' | 'BDT' | 'BGN' | 'BHD' | 'BIF' | 'BMD' | 'BND' | 'BOB' | 'BRL' | 'BSD' | 'BTN' | 'BWP' | 'BYR' | 'BZD' | 'CAD' | 'CDF' | 'CHF' | 'CLP' | 'CNY' | 'COP' | 'CRC' | 'CUC' | 'CUP' | 'CVE' | 'CZK' | 'DJF' | 'DKK' | 'DOP' | 'DZD' | 'EGP' | 'ERN' | 'ETB' | 'EUR' | 'FJD' | 'FKP' | 'GBP' | 'GEL' | 'GGP' | 'GHS' | 'GIP' | 'GMD' | 'GNF' | 'GTQ' | 'GYD' | 'HKD' | 'HNL' | 'HRK' | 'HTG' | 'HUF' | 'IDR' | 'ILS' | 'IMP' | 'INR' | 'IQD' | 'IRR' | 'ISK' | 'JEP' | 'JMD' | 'JOD' | 'JPY' | 'KES' | 'KGS' | 'KHR' | 'KMF' | 'KPW' | 'KRW' | 'KWD' | 'KYD' | 'KZT' | 'LAK' | 'LBP' | 'LKR' | 'LRD' | 'LSL' | 'LYD' | 'MAD' | 'MDL' | 'MGA' | 'MKD' | 'MMK' | 'MNT' | 'MOP' | 'MRO' | 'MUR' | 'MVR' | 'MWK' | 'MXN' | 'MYR' | 'MZN' | 'NAD' | 'NGN' | 'NIO' | 'NOK' | 'NPR' | 'NZD' | 'OMR' | 'PAB' | 'PEN' | 'PGK' | 'PHP' | 'PKR' | 'PLN' | 'PYG' | 'QAR' | 'RON' | 'RSD' | 'RUB' | 'RWF' | 'SAR' | 'SBD' | 'SCR' | 'SDG' | 'SEK' | 'SGD' | 'SHP' | 'SLL' | 'SOS' | 'SPL' | 'SRD' | 'STD' | 'SVC' | 'SYP' | 'SZL' | 'THB' | 'TJS' | 'TMT' | 'TND' | 'TOP' | 'TRY' | 'TTD' | 'TVD' | 'TWD' | 'TZS' | 'UAH' | 'UGX' | 'USD' | 'UYU' | 'UZS' | 'VEF' | 'VND' | 'VUV' | 'WST' | 'XAF' | 'XCD' | 'XDR' | 'XOF' | 'XPF' | 'YER' | 'ZAR' | 'ZMW' | 'ZWD';
    export const LeftEnum = {
        AED: 'AED' as LeftEnum,
        AFN: 'AFN' as LeftEnum,
        ALL: 'ALL' as LeftEnum,
        AMD: 'AMD' as LeftEnum,
        ANG: 'ANG' as LeftEnum,
        AOA: 'AOA' as LeftEnum,
        ARS: 'ARS' as LeftEnum,
        AUD: 'AUD' as LeftEnum,
        AWG: 'AWG' as LeftEnum,
        AZN: 'AZN' as LeftEnum,
        BAM: 'BAM' as LeftEnum,
        BBD: 'BBD' as LeftEnum,
        BDT: 'BDT' as LeftEnum,
        BGN: 'BGN' as LeftEnum,
        BHD: 'BHD' as LeftEnum,
        BIF: 'BIF' as LeftEnum,
        BMD: 'BMD' as LeftEnum,
        BND: 'BND' as LeftEnum,
        BOB: 'BOB' as LeftEnum,
        BRL: 'BRL' as LeftEnum,
        BSD: 'BSD' as LeftEnum,
        BTN: 'BTN' as LeftEnum,
        BWP: 'BWP' as LeftEnum,
        BYR: 'BYR' as LeftEnum,
        BZD: 'BZD' as LeftEnum,
        CAD: 'CAD' as LeftEnum,
        CDF: 'CDF' as LeftEnum,
        CHF: 'CHF' as LeftEnum,
        CLP: 'CLP' as LeftEnum,
        CNY: 'CNY' as LeftEnum,
        COP: 'COP' as LeftEnum,
        CRC: 'CRC' as LeftEnum,
        CUC: 'CUC' as LeftEnum,
        CUP: 'CUP' as LeftEnum,
        CVE: 'CVE' as LeftEnum,
        CZK: 'CZK' as LeftEnum,
        DJF: 'DJF' as LeftEnum,
        DKK: 'DKK' as LeftEnum,
        DOP: 'DOP' as LeftEnum,
        DZD: 'DZD' as LeftEnum,
        EGP: 'EGP' as LeftEnum,
        ERN: 'ERN' as LeftEnum,
        ETB: 'ETB' as LeftEnum,
        EUR: 'EUR' as LeftEnum,
        FJD: 'FJD' as LeftEnum,
        FKP: 'FKP' as LeftEnum,
        GBP: 'GBP' as LeftEnum,
        GEL: 'GEL' as LeftEnum,
        GGP: 'GGP' as LeftEnum,
        GHS: 'GHS' as LeftEnum,
        GIP: 'GIP' as LeftEnum,
        GMD: 'GMD' as LeftEnum,
        GNF: 'GNF' as LeftEnum,
        GTQ: 'GTQ' as LeftEnum,
        GYD: 'GYD' as LeftEnum,
        HKD: 'HKD' as LeftEnum,
        HNL: 'HNL' as LeftEnum,
        HRK: 'HRK' as LeftEnum,
        HTG: 'HTG' as LeftEnum,
        HUF: 'HUF' as LeftEnum,
        IDR: 'IDR' as LeftEnum,
        ILS: 'ILS' as LeftEnum,
        IMP: 'IMP' as LeftEnum,
        INR: 'INR' as LeftEnum,
        IQD: 'IQD' as LeftEnum,
        IRR: 'IRR' as LeftEnum,
        ISK: 'ISK' as LeftEnum,
        JEP: 'JEP' as LeftEnum,
        JMD: 'JMD' as LeftEnum,
        JOD: 'JOD' as LeftEnum,
        JPY: 'JPY' as LeftEnum,
        KES: 'KES' as LeftEnum,
        KGS: 'KGS' as LeftEnum,
        KHR: 'KHR' as LeftEnum,
        KMF: 'KMF' as LeftEnum,
        KPW: 'KPW' as LeftEnum,
        KRW: 'KRW' as LeftEnum,
        KWD: 'KWD' as LeftEnum,
        KYD: 'KYD' as LeftEnum,
        KZT: 'KZT' as LeftEnum,
        LAK: 'LAK' as LeftEnum,
        LBP: 'LBP' as LeftEnum,
        LKR: 'LKR' as LeftEnum,
        LRD: 'LRD' as LeftEnum,
        LSL: 'LSL' as LeftEnum,
        LYD: 'LYD' as LeftEnum,
        MAD: 'MAD' as LeftEnum,
        MDL: 'MDL' as LeftEnum,
        MGA: 'MGA' as LeftEnum,
        MKD: 'MKD' as LeftEnum,
        MMK: 'MMK' as LeftEnum,
        MNT: 'MNT' as LeftEnum,
        MOP: 'MOP' as LeftEnum,
        MRO: 'MRO' as LeftEnum,
        MUR: 'MUR' as LeftEnum,
        MVR: 'MVR' as LeftEnum,
        MWK: 'MWK' as LeftEnum,
        MXN: 'MXN' as LeftEnum,
        MYR: 'MYR' as LeftEnum,
        MZN: 'MZN' as LeftEnum,
        NAD: 'NAD' as LeftEnum,
        NGN: 'NGN' as LeftEnum,
        NIO: 'NIO' as LeftEnum,
        NOK: 'NOK' as LeftEnum,
        NPR: 'NPR' as LeftEnum,
        NZD: 'NZD' as LeftEnum,
        OMR: 'OMR' as LeftEnum,
        PAB: 'PAB' as LeftEnum,
        PEN: 'PEN' as LeftEnum,
        PGK: 'PGK' as LeftEnum,
        PHP: 'PHP' as LeftEnum,
        PKR: 'PKR' as LeftEnum,
        PLN: 'PLN' as LeftEnum,
        PYG: 'PYG' as LeftEnum,
        QAR: 'QAR' as LeftEnum,
        RON: 'RON' as LeftEnum,
        RSD: 'RSD' as LeftEnum,
        RUB: 'RUB' as LeftEnum,
        RWF: 'RWF' as LeftEnum,
        SAR: 'SAR' as LeftEnum,
        SBD: 'SBD' as LeftEnum,
        SCR: 'SCR' as LeftEnum,
        SDG: 'SDG' as LeftEnum,
        SEK: 'SEK' as LeftEnum,
        SGD: 'SGD' as LeftEnum,
        SHP: 'SHP' as LeftEnum,
        SLL: 'SLL' as LeftEnum,
        SOS: 'SOS' as LeftEnum,
        SPL: 'SPL' as LeftEnum,
        SRD: 'SRD' as LeftEnum,
        STD: 'STD' as LeftEnum,
        SVC: 'SVC' as LeftEnum,
        SYP: 'SYP' as LeftEnum,
        SZL: 'SZL' as LeftEnum,
        THB: 'THB' as LeftEnum,
        TJS: 'TJS' as LeftEnum,
        TMT: 'TMT' as LeftEnum,
        TND: 'TND' as LeftEnum,
        TOP: 'TOP' as LeftEnum,
        TRY: 'TRY' as LeftEnum,
        TTD: 'TTD' as LeftEnum,
        TVD: 'TVD' as LeftEnum,
        TWD: 'TWD' as LeftEnum,
        TZS: 'TZS' as LeftEnum,
        UAH: 'UAH' as LeftEnum,
        UGX: 'UGX' as LeftEnum,
        USD: 'USD' as LeftEnum,
        UYU: 'UYU' as LeftEnum,
        UZS: 'UZS' as LeftEnum,
        VEF: 'VEF' as LeftEnum,
        VND: 'VND' as LeftEnum,
        VUV: 'VUV' as LeftEnum,
        WST: 'WST' as LeftEnum,
        XAF: 'XAF' as LeftEnum,
        XCD: 'XCD' as LeftEnum,
        XDR: 'XDR' as LeftEnum,
        XOF: 'XOF' as LeftEnum,
        XPF: 'XPF' as LeftEnum,
        YER: 'YER' as LeftEnum,
        ZAR: 'ZAR' as LeftEnum,
        ZMW: 'ZMW' as LeftEnum,
        ZWD: 'ZWD' as LeftEnum
    };
    export type MiddleEnum = 'AED' | 'AFN' | 'ALL' | 'AMD' | 'ANG' | 'AOA' | 'ARS' | 'AUD' | 'AWG' | 'AZN' | 'BAM' | 'BBD' | 'BDT' | 'BGN' | 'BHD' | 'BIF' | 'BMD' | 'BND' | 'BOB' | 'BRL' | 'BSD' | 'BTN' | 'BWP' | 'BYR' | 'BZD' | 'CAD' | 'CDF' | 'CHF' | 'CLP' | 'CNY' | 'COP' | 'CRC' | 'CUC' | 'CUP' | 'CVE' | 'CZK' | 'DJF' | 'DKK' | 'DOP' | 'DZD' | 'EGP' | 'ERN' | 'ETB' | 'EUR' | 'FJD' | 'FKP' | 'GBP' | 'GEL' | 'GGP' | 'GHS' | 'GIP' | 'GMD' | 'GNF' | 'GTQ' | 'GYD' | 'HKD' | 'HNL' | 'HRK' | 'HTG' | 'HUF' | 'IDR' | 'ILS' | 'IMP' | 'INR' | 'IQD' | 'IRR' | 'ISK' | 'JEP' | 'JMD' | 'JOD' | 'JPY' | 'KES' | 'KGS' | 'KHR' | 'KMF' | 'KPW' | 'KRW' | 'KWD' | 'KYD' | 'KZT' | 'LAK' | 'LBP' | 'LKR' | 'LRD' | 'LSL' | 'LYD' | 'MAD' | 'MDL' | 'MGA' | 'MKD' | 'MMK' | 'MNT' | 'MOP' | 'MRO' | 'MUR' | 'MVR' | 'MWK' | 'MXN' | 'MYR' | 'MZN' | 'NAD' | 'NGN' | 'NIO' | 'NOK' | 'NPR' | 'NZD' | 'OMR' | 'PAB' | 'PEN' | 'PGK' | 'PHP' | 'PKR' | 'PLN' | 'PYG' | 'QAR' | 'RON' | 'RSD' | 'RUB' | 'RWF' | 'SAR' | 'SBD' | 'SCR' | 'SDG' | 'SEK' | 'SGD' | 'SHP' | 'SLL' | 'SOS' | 'SPL' | 'SRD' | 'STD' | 'SVC' | 'SYP' | 'SZL' | 'THB' | 'TJS' | 'TMT' | 'TND' | 'TOP' | 'TRY' | 'TTD' | 'TVD' | 'TWD' | 'TZS' | 'UAH' | 'UGX' | 'USD' | 'UYU' | 'UZS' | 'VEF' | 'VND' | 'VUV' | 'WST' | 'XAF' | 'XCD' | 'XDR' | 'XOF' | 'XPF' | 'YER' | 'ZAR' | 'ZMW' | 'ZWD';
    export const MiddleEnum = {
        AED: 'AED' as MiddleEnum,
        AFN: 'AFN' as MiddleEnum,
        ALL: 'ALL' as MiddleEnum,
        AMD: 'AMD' as MiddleEnum,
        ANG: 'ANG' as MiddleEnum,
        AOA: 'AOA' as MiddleEnum,
        ARS: 'ARS' as MiddleEnum,
        AUD: 'AUD' as MiddleEnum,
        AWG: 'AWG' as MiddleEnum,
        AZN: 'AZN' as MiddleEnum,
        BAM: 'BAM' as MiddleEnum,
        BBD: 'BBD' as MiddleEnum,
        BDT: 'BDT' as MiddleEnum,
        BGN: 'BGN' as MiddleEnum,
        BHD: 'BHD' as MiddleEnum,
        BIF: 'BIF' as MiddleEnum,
        BMD: 'BMD' as MiddleEnum,
        BND: 'BND' as MiddleEnum,
        BOB: 'BOB' as MiddleEnum,
        BRL: 'BRL' as MiddleEnum,
        BSD: 'BSD' as MiddleEnum,
        BTN: 'BTN' as MiddleEnum,
        BWP: 'BWP' as MiddleEnum,
        BYR: 'BYR' as MiddleEnum,
        BZD: 'BZD' as MiddleEnum,
        CAD: 'CAD' as MiddleEnum,
        CDF: 'CDF' as MiddleEnum,
        CHF: 'CHF' as MiddleEnum,
        CLP: 'CLP' as MiddleEnum,
        CNY: 'CNY' as MiddleEnum,
        COP: 'COP' as MiddleEnum,
        CRC: 'CRC' as MiddleEnum,
        CUC: 'CUC' as MiddleEnum,
        CUP: 'CUP' as MiddleEnum,
        CVE: 'CVE' as MiddleEnum,
        CZK: 'CZK' as MiddleEnum,
        DJF: 'DJF' as MiddleEnum,
        DKK: 'DKK' as MiddleEnum,
        DOP: 'DOP' as MiddleEnum,
        DZD: 'DZD' as MiddleEnum,
        EGP: 'EGP' as MiddleEnum,
        ERN: 'ERN' as MiddleEnum,
        ETB: 'ETB' as MiddleEnum,
        EUR: 'EUR' as MiddleEnum,
        FJD: 'FJD' as MiddleEnum,
        FKP: 'FKP' as MiddleEnum,
        GBP: 'GBP' as MiddleEnum,
        GEL: 'GEL' as MiddleEnum,
        GGP: 'GGP' as MiddleEnum,
        GHS: 'GHS' as MiddleEnum,
        GIP: 'GIP' as MiddleEnum,
        GMD: 'GMD' as MiddleEnum,
        GNF: 'GNF' as MiddleEnum,
        GTQ: 'GTQ' as MiddleEnum,
        GYD: 'GYD' as MiddleEnum,
        HKD: 'HKD' as MiddleEnum,
        HNL: 'HNL' as MiddleEnum,
        HRK: 'HRK' as MiddleEnum,
        HTG: 'HTG' as MiddleEnum,
        HUF: 'HUF' as MiddleEnum,
        IDR: 'IDR' as MiddleEnum,
        ILS: 'ILS' as MiddleEnum,
        IMP: 'IMP' as MiddleEnum,
        INR: 'INR' as MiddleEnum,
        IQD: 'IQD' as MiddleEnum,
        IRR: 'IRR' as MiddleEnum,
        ISK: 'ISK' as MiddleEnum,
        JEP: 'JEP' as MiddleEnum,
        JMD: 'JMD' as MiddleEnum,
        JOD: 'JOD' as MiddleEnum,
        JPY: 'JPY' as MiddleEnum,
        KES: 'KES' as MiddleEnum,
        KGS: 'KGS' as MiddleEnum,
        KHR: 'KHR' as MiddleEnum,
        KMF: 'KMF' as MiddleEnum,
        KPW: 'KPW' as MiddleEnum,
        KRW: 'KRW' as MiddleEnum,
        KWD: 'KWD' as MiddleEnum,
        KYD: 'KYD' as MiddleEnum,
        KZT: 'KZT' as MiddleEnum,
        LAK: 'LAK' as MiddleEnum,
        LBP: 'LBP' as MiddleEnum,
        LKR: 'LKR' as MiddleEnum,
        LRD: 'LRD' as MiddleEnum,
        LSL: 'LSL' as MiddleEnum,
        LYD: 'LYD' as MiddleEnum,
        MAD: 'MAD' as MiddleEnum,
        MDL: 'MDL' as MiddleEnum,
        MGA: 'MGA' as MiddleEnum,
        MKD: 'MKD' as MiddleEnum,
        MMK: 'MMK' as MiddleEnum,
        MNT: 'MNT' as MiddleEnum,
        MOP: 'MOP' as MiddleEnum,
        MRO: 'MRO' as MiddleEnum,
        MUR: 'MUR' as MiddleEnum,
        MVR: 'MVR' as MiddleEnum,
        MWK: 'MWK' as MiddleEnum,
        MXN: 'MXN' as MiddleEnum,
        MYR: 'MYR' as MiddleEnum,
        MZN: 'MZN' as MiddleEnum,
        NAD: 'NAD' as MiddleEnum,
        NGN: 'NGN' as MiddleEnum,
        NIO: 'NIO' as MiddleEnum,
        NOK: 'NOK' as MiddleEnum,
        NPR: 'NPR' as MiddleEnum,
        NZD: 'NZD' as MiddleEnum,
        OMR: 'OMR' as MiddleEnum,
        PAB: 'PAB' as MiddleEnum,
        PEN: 'PEN' as MiddleEnum,
        PGK: 'PGK' as MiddleEnum,
        PHP: 'PHP' as MiddleEnum,
        PKR: 'PKR' as MiddleEnum,
        PLN: 'PLN' as MiddleEnum,
        PYG: 'PYG' as MiddleEnum,
        QAR: 'QAR' as MiddleEnum,
        RON: 'RON' as MiddleEnum,
        RSD: 'RSD' as MiddleEnum,
        RUB: 'RUB' as MiddleEnum,
        RWF: 'RWF' as MiddleEnum,
        SAR: 'SAR' as MiddleEnum,
        SBD: 'SBD' as MiddleEnum,
        SCR: 'SCR' as MiddleEnum,
        SDG: 'SDG' as MiddleEnum,
        SEK: 'SEK' as MiddleEnum,
        SGD: 'SGD' as MiddleEnum,
        SHP: 'SHP' as MiddleEnum,
        SLL: 'SLL' as MiddleEnum,
        SOS: 'SOS' as MiddleEnum,
        SPL: 'SPL' as MiddleEnum,
        SRD: 'SRD' as MiddleEnum,
        STD: 'STD' as MiddleEnum,
        SVC: 'SVC' as MiddleEnum,
        SYP: 'SYP' as MiddleEnum,
        SZL: 'SZL' as MiddleEnum,
        THB: 'THB' as MiddleEnum,
        TJS: 'TJS' as MiddleEnum,
        TMT: 'TMT' as MiddleEnum,
        TND: 'TND' as MiddleEnum,
        TOP: 'TOP' as MiddleEnum,
        TRY: 'TRY' as MiddleEnum,
        TTD: 'TTD' as MiddleEnum,
        TVD: 'TVD' as MiddleEnum,
        TWD: 'TWD' as MiddleEnum,
        TZS: 'TZS' as MiddleEnum,
        UAH: 'UAH' as MiddleEnum,
        UGX: 'UGX' as MiddleEnum,
        USD: 'USD' as MiddleEnum,
        UYU: 'UYU' as MiddleEnum,
        UZS: 'UZS' as MiddleEnum,
        VEF: 'VEF' as MiddleEnum,
        VND: 'VND' as MiddleEnum,
        VUV: 'VUV' as MiddleEnum,
        WST: 'WST' as MiddleEnum,
        XAF: 'XAF' as MiddleEnum,
        XCD: 'XCD' as MiddleEnum,
        XDR: 'XDR' as MiddleEnum,
        XOF: 'XOF' as MiddleEnum,
        XPF: 'XPF' as MiddleEnum,
        YER: 'YER' as MiddleEnum,
        ZAR: 'ZAR' as MiddleEnum,
        ZMW: 'ZMW' as MiddleEnum,
        ZWD: 'ZWD' as MiddleEnum
    };
}
