import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Pipe,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { ComboHotelDetailFacade } from '../../../../state/hotel-detail/hotel-detail.facade';
import * as moment from 'moment';
import { HotelResourceV3Service } from '@gtd/b2c-client';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { HotelCheckoutFacade } from '../../../../state/hotel-checkout/hotel-checkout.facade';
import { ComboSelectedService } from '../../../combo-selected.service';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { combineAll } from 'rxjs-compat/operator/combineAll';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'gtd-hotel-detail',
  templateUrl: './hotel-detail.component.html',
  styleUrls: ['./hotel-detail.component.scss']
})
export class HotelDetailComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  hotelDetailLoaded$ = this.hotelDetailFacade.loaded$;
  allHotelDetail$ = this.hotelDetailFacade.allHotelDetail$;
  roomsArrs: any;
  hotelDetailParams: any;
  searchParams: any;
  flightBooking$ = this._comboSelectedService.flightListSelected$;
  hotelBooking$ = this._comboSelectedService.hotelSelected$;
  flightSelectedDone$ = this._comboSelectedService.flightSelectedDone$;
  params;
  subscriptions = new Subscription();
  constructor(
    private activatedRoute: ActivatedRoute,
    private hotelDetailFacade: ComboHotelDetailFacade,
    private serializer: UrlSerializer,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private hotelV3ResourceService: HotelResourceV3Service,
    private router: Router,
    private _elementRef: ElementRef,
    private _comboSelectedService: ComboSelectedService,
    private hotelCheckoutFacade: HotelCheckoutFacade,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.activatedRoute.queryParams.subscribe(param => {
      this._handleQueryFromParams(
        JSON.parse(decodeURIComponent(atob(param.params)))
      );
      this.params = param.params;
    });
  }

  ngOnInit() {
    this.flightSelectedDone$.take(1).subscribe(done => {
      if (!done && this._comboSelectedService.flightReset) {
        this._comboSelectedService.resetFLight();
        this._comboSelectedService.flightReset = false;
        const flightBookingFromStorage = JSON.parse(
          localStorage.getItem('flightBooking')
        );
        for (const item of flightBookingFromStorage) {
          this._comboSelectedService.selectFlight(
            item.searchId,
            item.pricedItinerary,
            item.itemFlight,
            item.type
          );
        }
      }
    });
    this.subscriptions.add(
      this.flightSelectedDone$.subscribe(done => {
        if (done) {
          setTimeout(() => {
            const flightListPrice = this._comboSelectedService.flightListSelectedArray.map(
              flightSelected => flightSelected.pricedItinerary
            );
            this._comboSelectedService.setLowestPriceInFlight(flightListPrice);
          }, 100);
        }
      })
    );

    combineLatest(this.flightBooking$, this.hotelBooking$)
      .take(1)
      .subscribe(data => {
        const [flight, hotel] = data;
        if (
          localStorage.getItem('hotelBooking') &&
          localStorage.getItem('flightBooking')
        ) {
          const hotelBooking = JSON.parse(localStorage.getItem('hotelBooking'));
          const flightBooking = JSON.parse(
            localStorage.getItem('flightBooking')
          );
          if (!hotel && !flight) {
            this._comboSelectedService.selectHotel(hotelBooking);
            const type = flightBooking.length === 1 ? 'ONEWAY' : 'ROUNDTRIP';
            for (const item of flightBooking) {
              this._comboSelectedService.selectFlight(
                item.searchId,
                item.pricedItinerary,
                item.itemFlight,
                item.type
              );
            }
          }
        } else {
          this.router.navigate(['/combo/hotel'], {
            queryParams: {
              params: this.params
            }
          });
        }
      });
  }

  changeSearchDetail(event) {
    if (event) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'hotel-detail.changing-search', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });

      let roomsArrs = event.rooms.room;
      let roomsString = [];
      let hotelChildAgesArr = [];
      if (roomsArrs.length > 0) {
        roomsArrs.forEach(roomsArr => {
          let childrendString = '';
          hotelChildAgesArr = roomsArr.childrenAges.concat(roomsArr.infantAges);
          if (hotelChildAgesArr.length) {
            hotelChildAgesArr.forEach(childrend => {
              childrendString = childrendString
                ? childrendString + ',' + '' + childrend
                : '' + childrend;
            });
          }
          const room = childrendString
            ? roomsArr.adultQuantity + '-' + childrendString
            : roomsArr.adultQuantity;
          roomsString.push(room);
        });
      } else {
        roomsString.push(roomsArrs);
      }

      let searchObject = JSON.parse(atob(this.hotelDetailParams.searchId));

      this.hotelV3ResourceService
        .searchBestRatesUsingGET(
          this.hotelDetailParams.propertyId,
          'HOTEL',
          searchObject.language,
          searchObject.currency,
          moment(event.datePicker.fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
          moment(event.datePicker.toDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
          roomsString,
          null,
          searchObject.supplier
        )
        .subscribe(
          searchBestRates => {
            if (searchBestRates && searchBestRates.success) {
              let params = {
                locationCode: this.hotelDetailParams.locationCode,
                locationName: this.hotelDetailParams.locationName,
                searchType: this.hotelDetailParams.searchType,
                roomsString: roomsString,
                checkIn: moment(event.datePicker.fromDate, 'MM-DD-YYYY').format(
                  'YYYY-MM-DD'
                ),
                checkOut: moment(event.datePicker.toDate, 'MM-DD-YYYY').format(
                  'YYYY-MM-DD'
                ),
                searchId: searchBestRates.result.searchId,
                propertyId: this.hotelDetailParams.propertyId,
                supplier: this.hotelDetailParams.supplier,
                paxInfos: JSON.stringify(event.rooms.room)
              };
              const url = this.router.createUrlTree(['/hotel/detail'], {
                queryParams: {
                  params: btoa(encodeURIComponent(JSON.stringify(params)))
                }
              });
              let newWindow = window.open('', '_self');
              newWindow.location.href = decodeURIComponent(
                this.serializer.serialize(url)
              );
            } else {
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [{ btnText: 'global.close' }],
                  content: 'user.system-error-message',
                  heading: 'user.system-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true,
                position: {
                  top: '150px'
                }
              });
            }
          },
          error1 => {
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                content: 'user.system-error-message',
                heading: 'user.system-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.hotelCheckoutFacade.reset();
    this.hotelDetailFacade.resetLoadAll();
  }

  scrollTo(el: string) {
    if (isPlatformBrowser(this.platformId)) {
      let off =
        this._elementRef.nativeElement.querySelector(`#${el}`).offsetTop -
        document.querySelector('.header-layout').clientHeight -
        46;
      window.scrollTo({ behavior: 'smooth', top: off });
    }
  }
  private _handleQueryFromParams(param) {
    this.searchParams = param;
    const roomsArrs = JSON.parse(param.rooms);
    const roomsString = [];
    let hotelChildAgesArr = [];
    if (roomsArrs.length > 0) {
      roomsArrs.forEach(roomsArr => {
        let childrendString = '';
        hotelChildAgesArr = roomsArr.childrenAges.concat(roomsArr.infantAges);
        if (hotelChildAgesArr.length) {
          hotelChildAgesArr.forEach(childrend => {
            childrendString = childrendString
              ? childrendString + ',' + '' + childrend
              : '' + childrend;
          });
        }
        const room = childrendString
          ? roomsArr.adultQuantity + '-' + childrendString
          : roomsArr.adultQuantity;
        roomsString.push(room);
      });
    } else {
      roomsString.push(roomsArrs);
    }
    this.roomsArrs = roomsArrs;
    this.hotelDetailParams = param;
    this.hotelDetailFacade.loadAll({
      searchId: param.searchId,
      propertyId: param.propertyId,
      supplier: param.location.supplier,
      checkIn: moment(param.fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
      checkOut: moment(param.toDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
      paxInfos: roomsString
    });
  }
}
@Pipe({ name: 'round' })
export class RoundPipe {
  transform(input: any) {
    return parseFloat(input).toFixed(1);
  }
}

@Pipe({ name: 'percentPrice' })
export class PercentPrice {
  transform(price: number, basePriceBeforePromo: number) {
    let percent = 100 - (price * 100) / basePriceBeforePromo;
    return Math.round(percent);
  }
}
