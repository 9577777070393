import { ItineraryFilter } from '@gtd/b2c-client';
import {
  DEFAULT_MIN_PRICE,
  DEFAULT_PAGINATION_LOAD_MORE,
  DEFAULT_STEP,
  EMPTY_ARRAY,
  EMPTY_STRING
} from './constants';
import { CabinClassNameEnum, FlightTypeEnum } from './enums';

export const defaultFilter: ItineraryFilter = {
  ticketPolicyOptions: [],
  stopOptions: EMPTY_ARRAY,
  step: DEFAULT_STEP,
  flightType: FlightTypeEnum.DOMESTIC,
  departureDateTimeOptions: EMPTY_ARRAY,
  cabinClassOptions: [],
  arrivalDateTimeReturnOptions: EMPTY_ARRAY,
  arrivalDateTimeOptions: EMPTY_ARRAY,
  airlineOptions: EMPTY_ARRAY,
  departureDateTimeReturnOptions: EMPTY_ARRAY,
  loadMore: DEFAULT_PAGINATION_LOAD_MORE,
  priceItineraryId: EMPTY_STRING
};
