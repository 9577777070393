/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface HotelCheckCancelPenaltyRQ { 
    bookingNumber?: string;
    ibeSessionId?: string;
    requesterCode?: string;
    requesterType?: HotelCheckCancelPenaltyRQ.RequesterTypeEnum;
}
export namespace HotelCheckCancelPenaltyRQ {
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
