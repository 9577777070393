import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TOURSEARCHACTIVITYAVAILABLE_FEATURE_KEY,
  TourSearchActivityAvailableState
} from './tour-search-activity-available.reducer';

// Lookup the 'TourSearchActivityAvailable' feature state managed by NgRx
const getTourSearchActivityAvailableState = createFeatureSelector<
  TourSearchActivityAvailableState
>(TOURSEARCHACTIVITYAVAILABLE_FEATURE_KEY);

const getLoaded = createSelector(
  getTourSearchActivityAvailableState,
  (state: TourSearchActivityAvailableState) => state.loaded
);
const getError = createSelector(
  getTourSearchActivityAvailableState,
  (state: TourSearchActivityAvailableState) => state.error
);

const getAllTourSearchActivityAvailable = createSelector(
  getTourSearchActivityAvailableState,
  getLoaded,
  (state: TourSearchActivityAvailableState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getTourSearchActivityAvailableState,
  (state: TourSearchActivityAvailableState) => state.selectedId
);
const getSelectedTourSearchActivityAvailable = createSelector(
  getAllTourSearchActivityAvailable,
  getSelectedId,
  (tourSearchActivityAvailable, id) => {
    // const result = tourSearchActivityAvailable.find(it => it['id'] === id);
    return tourSearchActivityAvailable
      ? Object.assign({}, tourSearchActivityAvailable)
      : undefined;
  }
);

export const tourSearchActivityAvailableQuery = {
  getLoaded,
  getError,
  getAllTourSearchActivityAvailable,
  getSelectedTourSearchActivityAvailable
};
