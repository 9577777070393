import { Action } from '@ngrx/store';
import {OtaResultOfInviteCodeEntity} from "../../lib";

export enum GetInviteCodeActionTypes {
  LoadGetInviteCode = '[GetInviteCode] Load GetInviteCode',
  GetInviteCodeLoaded = '[GetInviteCode] GetInviteCode Loaded',
  GetInviteCodeLoadError = '[GetInviteCode] GetInviteCode Load Error'
}

export class LoadGetInviteCode implements Action {
  readonly type = GetInviteCodeActionTypes.LoadGetInviteCode;
}

export class GetInviteCodeLoadError implements Action {
  readonly type = GetInviteCodeActionTypes.GetInviteCodeLoadError;
  constructor(public payload: any) {}
}

export class GetInviteCodeLoaded implements Action {
  readonly type = GetInviteCodeActionTypes.GetInviteCodeLoaded;
  constructor(public payload: OtaResultOfInviteCodeEntity) {}
}

export type GetInviteCodeAction =
  | LoadGetInviteCode
  | GetInviteCodeLoaded
  | GetInviteCodeLoadError;

export const fromGetInviteCodeActions = {
  LoadGetInviteCode,
  GetInviteCodeLoaded,
  GetInviteCodeLoadError
};
