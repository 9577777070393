import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TcbValidateFieldMobile} from "@gtd/helpers";

@Component({
  selector: 'gtd-tcb-form-update-customer',
  templateUrl: './tcb-form-update-customer.component.html',
  styleUrls: ['./tcb-form-update-customer.component.scss']
})
export class TcbFormUpdateCustomerComponent implements OnInit {

  techComCustomers: FormGroup;


  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TcbFormUpdateCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.techComCustomers = new FormGroup({
      buyerMobile: new FormControl('', [Validators.required]),
      buyerIdCardNo: new FormControl('', [Validators.required]),
      recipientPassport: new FormControl({value: '', disabled: true}, [Validators.required])
    },
      {
        validators: TcbValidateFieldMobile('buyerMobile', this.data.attributes)
      }
    );
  }

  ngOnInit() {
    if(this.data && this.data.attribute) {
      this.techComCustomers.patchValue({
        recipientPassport: this.data.attribute.recipientPassport,
        buyerMobile: this.data.attribute.buyerMobile,
        buyerIdCardNo: this.data.attribute.buyerIdCardNo
      })
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  submitForm() {
    if(this.techComCustomers.valid) {
      this.dialogRef.close({customer: this.techComCustomers.getRawValue()})
    } else {
      this.techComCustomers.markAllAsTouched();
    }
  }
}
