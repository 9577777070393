import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gtd-form-rating-star',
  templateUrl: './form-rating-star.component.html',
  styleUrls: ['./form-rating-star.component.scss']
})
export class FormRatingStarComponent implements OnInit {
  @Output() newFilterStarsEvent = new EventEmitter<number[]>();
  stars = [false, false, false, false, false];
  filterStars = [];
  constructor() {}

  ngOnInit() {}

  changeFilterStars(index: number) {
    this.stars = [false, false, false, false, false];
    this.stars[index] = true;
    const minValue = this.stars.findIndex(star => star === true) + 1;
    const maxValue = this.stars.lastIndexOf(true) + 1;

    this.newFilterStarsEvent.emit([minValue, maxValue]);
  }

  isSelectedStar(star: number) {
    return this.filterStars.find(filterStar => filterStar === star)
      ? true
      : false;
  }
}
