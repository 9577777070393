import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ImageResize,
  NumberNightResult,
  PercentPrice,
  RoundPipe,
  SearchResultComponent
} from './search-result.component';
import { library } from '@fortawesome/fontawesome-svg-core';
import { HotelModuleRouting } from '../hotel.module.routing';
import {
  MatBottomSheetModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatRadioModule,
  MatRippleModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatTabsModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  QuantityPassenger,
  SearchInformationsComponent
} from './search-informations/search-informations.component';
import { MemberSectionModule } from '@gtd/components/member-section';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import {
  PricePanelComponent,
  ToNumberPipe
} from './filter-panel/price-panel/price-panel.component';
import { TranslateModule } from '@ngx-translate/core';
import { FilterByHotelNameComponent } from './filter-panel/filter-by-hotel-name/filter-by-hotel-name.component';
import { Ng5SliderModule } from 'ng5-slider';
import {
  DisableControlDirective,
  FilterByRatingsComponent
} from './filter-panel/filter-by-ratings/filter-by-ratings.component';
import {
  faAngleDoubleRight,
  faArrowLeft,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faEllipsisV,
  faFunnelDollar,
  faMapMarkedAlt,
  faStar,
  faStarHalfAlt,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FilterCheckboxLabelTextComponent } from './filter-panel/filter-checkbox-label-text/filter-checkbox-label-text.component';
import { SortPanelComponent } from './sort-panel/sort-panel.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  GenerateLink,
  HotelSearchResultItemComponent
} from './hotel-search-result-item/hotel-search-result-item.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { StateHotelModule } from '../../state/state-hotel.module';
import { DirectivesNumberFormatFormModule } from '@gtd/directives/number-format-form';
import { LoadingPanelComponent } from './filter-panel/loading-panel/loading-panel.component';
import { HotelSortLoadingPanelComponent } from './sort-panel/hotel-sort-loading-panel/hotel-sort-loading-panel.component';
import { HotelLoadingItemComponent } from './hotel-search-result-item/hotel-loading-item/hotel-loading-item.component';
import { FilterByGuestRatingComponent } from './filter-panel/filter-by-guest-rating/filter-by-guest-rating.component';
import { DialogMessageModule } from '@gtd/components/dialog-message';
import { MapsPanelComponent } from './maps-panel/maps-panel.component';
import { HotelSearchResultItemMapComponent } from './maps-panel/hotel-search-result-item-map/hotel-search-result-item-map.component';
import { AgmCoreModule } from '@agm/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { HotelFormSearchModule } from '../hotel-form-search/hotel-form-search.module';
import { FilterPanelMobileComponent } from './filter-panel-mobile/filter-panel-mobile.component';
import { OpenSheetSwipeHotelComponent } from './maps-panel/open-sheet-swipe-hotel/open-sheet-swipe-hotel.component';
import { PaginationHotelMapComponent } from './maps-panel/pagination-hotel-map/pagination-hotel-map.component';
import { BreadcrumbBarModule } from '@gtd/components/breadcrumb-bar';
import { FilterPanelService } from './filter-panel/filter-panel.service';
import { DistancePanelComponent } from './filter-panel/distance-panel/distance-panel.component';
import {CurrencyExchangeModule} from "@gtd/pipes/currency-exchange";
import {SanitizeModule} from "@gtd/pipes/sanitize";

library.add(
  faStar,
  faStarHalfAlt,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faCheckSquare,
  faAngleDoubleRight,
  faMapMarkedAlt,
  faFunnelDollar,
  faTimes,
  faEllipsisV
);

@NgModule({
    imports: [
        CommonModule,
        HotelModuleRouting,
        MatCardModule,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        FontAwesomeModule,
        MatCheckboxModule,
        MatDialogModule,
        FormsModule,
        MatRippleModule,
        MemberSectionModule,
        TranslateModule,
        MatExpansionModule,
        Ng5SliderModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        FlexLayoutModule,
        SwiperModule,
        StateHotelModule,
        DirectivesNumberFormatFormModule,
        DialogMessageModule,
        MatTabsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCtgUEmt9xOzkgnBQsWM0tyNDooMrVgmFQ'
        }),
        MatPaginatorModule,
        NgxPaginationModule,
        MatDividerModule,
        HotelFormSearchModule,
        MatBottomSheetModule,
        BreadcrumbBarModule,
        CurrencyExchangeModule,
        SanitizeModule
    ],
  providers: [FilterPanelService],
  declarations: [
    SearchResultComponent,
    SearchInformationsComponent,
    FilterPanelComponent,
    PricePanelComponent,
    FilterByHotelNameComponent,
    FilterByRatingsComponent,
    FilterCheckboxLabelTextComponent,
    SortPanelComponent,
    HotelSearchResultItemComponent,
    ToNumberPipe,
    LoadingPanelComponent,
    HotelSortLoadingPanelComponent,
    HotelLoadingItemComponent,
    DisableControlDirective,
    FilterByGuestRatingComponent,
    MapsPanelComponent,
    HotelSearchResultItemMapComponent,
    RoundPipe,
    PercentPrice,
    QuantityPassenger,
    FilterPanelMobileComponent,
    NumberNightResult,
    OpenSheetSwipeHotelComponent,
    PaginationHotelMapComponent,
    ImageResize,
    GenerateLink,
    DistancePanelComponent
  ],
  exports: [SearchResultComponent],
  entryComponents: [FilterPanelMobileComponent, OpenSheetSwipeHotelComponent]
})
export class SearchResultModule {}
