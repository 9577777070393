/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingCode } from './bookingCode';
import { DraftItineraryInfo } from './draftItineraryInfo';
import { Info } from './info';


export interface TicketDraftBookingRS { 
    bookingCode?: BookingCode;
    bookingType?: TicketDraftBookingRS.BookingTypeEnum;
    departDraftItineraryInfo?: DraftItineraryInfo;
    duration?: number;
    errors?: Array<Error>;
    infos?: Array<Info>;
    isPerBookingType?: boolean;
    isRoundTripType?: boolean;
    isSuccess?: boolean;
    markupType?: TicketDraftBookingRS.MarkupTypeEnum;
    returnDraftItineraryInfo?: DraftItineraryInfo;
    roundType?: string;
    success?: boolean;
    textMessage?: string;
}
export namespace TicketDraftBookingRS {
    export type BookingTypeEnum = 'DOME' | 'INTE';
    export const BookingTypeEnum = {
        DOME: 'DOME' as BookingTypeEnum,
        INTE: 'INTE' as BookingTypeEnum
    };
    export type MarkupTypeEnum = 'PER_PAX_PER_BOOKING' | 'PER_PAX_PER_SEGMENT' | 'PER_BOOKING';
    export const MarkupTypeEnum = {
        PAXPERBOOKING: 'PER_PAX_PER_BOOKING' as MarkupTypeEnum,
        PAXPERSEGMENT: 'PER_PAX_PER_SEGMENT' as MarkupTypeEnum,
        BOOKING: 'PER_BOOKING' as MarkupTypeEnum
    };
}
