import { Action } from '@ngrx/store';
import {ReferralResponseOfobject} from "../../lib";

export enum ReportReferralActionTypes {
  LoadReportReferral = '[ReportReferral] Load ReportReferral',
  ReportReferralLoaded = '[ReportReferral] ReportReferral Loaded',
  ReportReferralLoadError = '[ReportReferral] ReportReferral Load Error'
}

export class LoadReportReferral implements Action {
  readonly type = ReportReferralActionTypes.LoadReportReferral;
}

export class ReportReferralLoadError implements Action {
  readonly type = ReportReferralActionTypes.ReportReferralLoadError;
  constructor(public payload: any) {}
}

export class ReportReferralLoaded implements Action {
  readonly type = ReportReferralActionTypes.ReportReferralLoaded;
  constructor(public payload: ReferralResponseOfobject) {}
}

export type ReportReferralAction =
  | LoadReportReferral
  | ReportReferralLoaded
  | ReportReferralLoadError;

export const fromReportReferralActions = {
  LoadReportReferral,
  ReportReferralLoaded,
  ReportReferralLoadError
};
