/**
 * utilitysrv API
 * utilitysrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { NotifyMobileMessageRes } from '../model/notifyMobileMessageRes';
import { NotifyUserMessageDTO } from '../model/notifyUserMessageDTO';
import { NotifyUserMessageReq } from '../model/notifyUserMessageReq';
import { PageOfNotifyMobileMessageRes } from '../model/pageOfNotifyMobileMessageRes';
import { PageOfNotifyUserMessageDTO } from '../model/pageOfNotifyUserMessageDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NotifyUserMessageResourceService {

    protected basePath = 'https://10.7.71.20:8588/utilitysrv';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createNotifyUserMessage
     * 
     * @param notifyUserMessageReq notifyUserMessageReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNotifyUserMessageUsingPOST(notifyUserMessageReq: NotifyUserMessageReq, observe?: 'body', reportProgress?: boolean): Observable<NotifyUserMessageDTO>;
    public createNotifyUserMessageUsingPOST(notifyUserMessageReq: NotifyUserMessageReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotifyUserMessageDTO>>;
    public createNotifyUserMessageUsingPOST(notifyUserMessageReq: NotifyUserMessageReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotifyUserMessageDTO>>;
    public createNotifyUserMessageUsingPOST(notifyUserMessageReq: NotifyUserMessageReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notifyUserMessageReq === null || notifyUserMessageReq === undefined) {
            throw new Error('Required parameter notifyUserMessageReq was null or undefined when calling createNotifyUserMessageUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<NotifyUserMessageDTO>(`${this.basePath}/api/notify-user-messages`,
            notifyUserMessageReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createNotifyUsersMessage
     * 
     * @param notifyUserMessageReqs notifyUserMessageReqs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNotifyUsersMessageUsingPOST(notifyUserMessageReqs: Array<NotifyUserMessageReq>, observe?: 'body', reportProgress?: boolean): Observable<Array<NotifyUserMessageDTO>>;
    public createNotifyUsersMessageUsingPOST(notifyUserMessageReqs: Array<NotifyUserMessageReq>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotifyUserMessageDTO>>>;
    public createNotifyUsersMessageUsingPOST(notifyUserMessageReqs: Array<NotifyUserMessageReq>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotifyUserMessageDTO>>>;
    public createNotifyUsersMessageUsingPOST(notifyUserMessageReqs: Array<NotifyUserMessageReq>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notifyUserMessageReqs === null || notifyUserMessageReqs === undefined) {
            throw new Error('Required parameter notifyUserMessageReqs was null or undefined when calling createNotifyUsersMessageUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<NotifyUserMessageDTO>>(`${this.basePath}/api/notify-users-messages`,
            notifyUserMessageReqs,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteNotifyUserMessage
     * 
     * @param userRefCode userRefCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteNotifyUserMessageUsingDELETE(userRefCode: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public deleteNotifyUserMessageUsingDELETE(userRefCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public deleteNotifyUserMessageUsingDELETE(userRefCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public deleteNotifyUserMessageUsingDELETE(userRefCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling deleteNotifyUserMessageUsingDELETE.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userRefCode !== undefined && userRefCode !== null) {
            queryParameters = queryParameters.set('userRefCode', <any>userRefCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<number>(`${this.basePath}/api/notify-user-messages-all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteNotifyUserMessage
     * 
     * @param messageId messageId
     * @param userRefCode userRefCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteNotifyUserMessageUsingDELETE1(messageId: number, userRefCode: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public deleteNotifyUserMessageUsingDELETE1(messageId: number, userRefCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public deleteNotifyUserMessageUsingDELETE1(messageId: number, userRefCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public deleteNotifyUserMessageUsingDELETE1(messageId: number, userRefCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (messageId === null || messageId === undefined) {
            throw new Error('Required parameter messageId was null or undefined when calling deleteNotifyUserMessageUsingDELETE1.');
        }

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling deleteNotifyUserMessageUsingDELETE1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (messageId !== undefined && messageId !== null) {
            queryParameters = queryParameters.set('messageId', <any>messageId);
        }
        if (userRefCode !== undefined && userRefCode !== null) {
            queryParameters = queryParameters.set('userRefCode', <any>userRefCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<number>(`${this.basePath}/api/notify-user-messages`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteNotifyUserMessage
     * 
     * @param notifyMessageIds notifyMessageIds
     * @param userRefCode userRefCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteNotifyUserMessageUsingPUT(notifyMessageIds: Array<number>, userRefCode: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public deleteNotifyUserMessageUsingPUT(notifyMessageIds: Array<number>, userRefCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public deleteNotifyUserMessageUsingPUT(notifyMessageIds: Array<number>, userRefCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public deleteNotifyUserMessageUsingPUT(notifyMessageIds: Array<number>, userRefCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notifyMessageIds === null || notifyMessageIds === undefined) {
            throw new Error('Required parameter notifyMessageIds was null or undefined when calling deleteNotifyUserMessageUsingPUT.');
        }

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling deleteNotifyUserMessageUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (notifyMessageIds) {
            notifyMessageIds.forEach((element) => {
                queryParameters = queryParameters.append('notifyMessageIds', <any>element);
            })
        }
        if (userRefCode !== undefined && userRefCode !== null) {
            queryParameters = queryParameters.set('userRefCode', <any>userRefCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<number>(`${this.basePath}/api/notify-user-messages-some`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllNotifyUserMessages
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllNotifyUserMessagesUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<NotifyUserMessageDTO>>;
    public getAllNotifyUserMessagesUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotifyUserMessageDTO>>>;
    public getAllNotifyUserMessagesUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotifyUserMessageDTO>>>;
    public getAllNotifyUserMessagesUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NotifyUserMessageDTO>>(`${this.basePath}/api/notify-user-messages-all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllNotifyUserMessagesWithPage
     * 
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllNotifyUserMessagesWithPageUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageOfNotifyUserMessageDTO>;
    public getAllNotifyUserMessagesWithPageUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfNotifyUserMessageDTO>>;
    public getAllNotifyUserMessagesWithPageUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfNotifyUserMessageDTO>>;
    public getAllNotifyUserMessagesWithPageUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageOfNotifyUserMessageDTO>(`${this.basePath}/api/notify-user-messages`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCountUnreadNotifyMess
     * 
     * @param userRefCode userRefCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCountUnreadNotifyMessUsingGET(userRefCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getCountUnreadNotifyMessUsingGET(userRefCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getCountUnreadNotifyMessUsingGET(userRefCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getCountUnreadNotifyMessUsingGET(userRefCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling getCountUnreadNotifyMessUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/get-count-unread-notify-message/${encodeURIComponent(String(userRefCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getNotifyMessByUserRefCode
     * 
     * @param userRefCode userRefCode
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotifyMessByUserRefCodeUsingGET(userRefCode: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageOfNotifyMobileMessageRes>;
    public getNotifyMessByUserRefCodeUsingGET(userRefCode: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfNotifyMobileMessageRes>>;
    public getNotifyMessByUserRefCodeUsingGET(userRefCode: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfNotifyMobileMessageRes>>;
    public getNotifyMessByUserRefCodeUsingGET(userRefCode: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling getNotifyMessByUserRefCodeUsingGET.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PageOfNotifyMobileMessageRes>(`${this.basePath}/api/get-notify-message-with-page/${encodeURIComponent(String(userRefCode))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getNotifyMessByUserRefCode
     * 
     * @param userRefCode userRefCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotifyMessByUserRefCodeUsingGET1(userRefCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<NotifyMobileMessageRes>>;
    public getNotifyMessByUserRefCodeUsingGET1(userRefCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotifyMobileMessageRes>>>;
    public getNotifyMessByUserRefCodeUsingGET1(userRefCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotifyMobileMessageRes>>>;
    public getNotifyMessByUserRefCodeUsingGET1(userRefCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling getNotifyMessByUserRefCodeUsingGET1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<NotifyMobileMessageRes>>(`${this.basePath}/api/get-notify-message/${encodeURIComponent(String(userRefCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getNotifyUserMessage
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotifyUserMessageUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<NotifyUserMessageDTO>;
    public getNotifyUserMessageUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotifyUserMessageDTO>>;
    public getNotifyUserMessageUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotifyUserMessageDTO>>;
    public getNotifyUserMessageUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getNotifyUserMessageUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<NotifyUserMessageDTO>(`${this.basePath}/api/notify-user-messages/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * markReadNotifyUserMessage
     * 
     * @param notifyMessageIds notifyMessageIds
     * @param userRefCode userRefCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public markReadNotifyUserMessageUsingPUT(notifyMessageIds: Array<number>, userRefCode: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public markReadNotifyUserMessageUsingPUT(notifyMessageIds: Array<number>, userRefCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public markReadNotifyUserMessageUsingPUT(notifyMessageIds: Array<number>, userRefCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public markReadNotifyUserMessageUsingPUT(notifyMessageIds: Array<number>, userRefCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notifyMessageIds === null || notifyMessageIds === undefined) {
            throw new Error('Required parameter notifyMessageIds was null or undefined when calling markReadNotifyUserMessageUsingPUT.');
        }

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling markReadNotifyUserMessageUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (notifyMessageIds) {
            notifyMessageIds.forEach((element) => {
                queryParameters = queryParameters.append('notifyMessageIds', <any>element);
            })
        }
        if (userRefCode !== undefined && userRefCode !== null) {
            queryParameters = queryParameters.set('userRefCode', <any>userRefCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<number>(`${this.basePath}/api/notify-user-message/mark-as-read`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * markUnreadMessage
     * 
     * @param messageId messageId
     * @param userRefCode userRefCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public markUnreadMessageUsingPOST(messageId: number, userRefCode: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public markUnreadMessageUsingPOST(messageId: number, userRefCode: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public markUnreadMessageUsingPOST(messageId: number, userRefCode: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public markUnreadMessageUsingPOST(messageId: number, userRefCode: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (messageId === null || messageId === undefined) {
            throw new Error('Required parameter messageId was null or undefined when calling markUnreadMessageUsingPOST.');
        }

        if (userRefCode === null || userRefCode === undefined) {
            throw new Error('Required parameter userRefCode was null or undefined when calling markUnreadMessageUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (messageId !== undefined && messageId !== null) {
            queryParameters = queryParameters.set('messageId', <any>messageId);
        }
        if (userRefCode) {
            userRefCode.forEach((element) => {
                queryParameters = queryParameters.append('userRefCode', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/mark-unread-message`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateNotifyUserMessage
     * 
     * @param notifyUserMessageDTO notifyUserMessageDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateNotifyUserMessageUsingPUT(notifyUserMessageDTO: NotifyUserMessageReq, observe?: 'body', reportProgress?: boolean): Observable<NotifyUserMessageDTO>;
    public updateNotifyUserMessageUsingPUT(notifyUserMessageDTO: NotifyUserMessageReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotifyUserMessageDTO>>;
    public updateNotifyUserMessageUsingPUT(notifyUserMessageDTO: NotifyUserMessageReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotifyUserMessageDTO>>;
    public updateNotifyUserMessageUsingPUT(notifyUserMessageDTO: NotifyUserMessageReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notifyUserMessageDTO === null || notifyUserMessageDTO === undefined) {
            throw new Error('Required parameter notifyUserMessageDTO was null or undefined when calling updateNotifyUserMessageUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<NotifyUserMessageDTO>(`${this.basePath}/api/notify-user-messages`,
            notifyUserMessageDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
