import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FINALBOOKINGDETAIL_FEATURE_KEY,
  FinalBookingDetailState
} from './final-booking-detail.reducer';

// Lookup the 'FinalBookingDetail' feature state managed by NgRx
const getFinalBookingDetailState = createFeatureSelector<
  FinalBookingDetailState
>(FINALBOOKINGDETAIL_FEATURE_KEY);

const getLoaded = createSelector(
  getFinalBookingDetailState,
  (state: FinalBookingDetailState) => state.loaded
);
const getError = createSelector(
  getFinalBookingDetailState,
  (state: FinalBookingDetailState) => state.error
);

const getAllFinalBookingDetail = createSelector(
  getFinalBookingDetailState,
  getLoaded,
  (state: FinalBookingDetailState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getFinalBookingDetailState,
  (state: FinalBookingDetailState) => state.selectedId
);
const getSelectedFinalBookingDetail = createSelector(
  getAllFinalBookingDetail,
  getSelectedId,
  (finalBookingDetail, id) => {
    return finalBookingDetail ? Object.assign({}, finalBookingDetail) : undefined;
  }
);

export const finalBookingDetailQuery = {
  getLoaded,
  getError,
  getAllFinalBookingDetail,
  getSelectedFinalBookingDetail
};
