import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PHONEREGISTERSTATE_FEATURE_KEY,
  PhoneRegisterStateState
} from './phone-register-state.reducer';

// Lookup the 'PhoneRegisterState' feature state managed by NgRx
const getPhoneRegisterStateState = createFeatureSelector<
  PhoneRegisterStateState
>(PHONEREGISTERSTATE_FEATURE_KEY);

const getLoaded = createSelector(
  getPhoneRegisterStateState,
  (state: PhoneRegisterStateState) => state.loaded
);
const getError = createSelector(
  getPhoneRegisterStateState,
  (state: PhoneRegisterStateState) => state.error
);

const getAllPhoneRegisterState = createSelector(
  getPhoneRegisterStateState,
  getLoaded,
  (state: PhoneRegisterStateState, isLoaded) => {
    return isLoaded ? state : null;
  }
);
const getSelectedId = createSelector(
  getPhoneRegisterStateState,
  (state: PhoneRegisterStateState) => state.selectedId
);
const getSelectedPhoneRegisterState = createSelector(
  getAllPhoneRegisterState,
  getSelectedId,
  (phoneRegisterState, id) => {
    return phoneRegisterState ? Object.assign({}, phoneRegisterState) : undefined;
  }
);

export const phoneRegisterStateQuery = {
  getLoaded,
  getError,
  getAllPhoneRegisterState,
  getSelectedPhoneRegisterState
};
