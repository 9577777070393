/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface RefundFailedBookingTO { 
    amount?: number;
    bookerCode?: string;
    bookerId?: number;
    bookingId?: number;
    bookingRefNo?: string;
    cancellationFee?: number;
    cancellationNotes?: string;
    checkIn?: Date;
    checkOut?: Date;
    detail?: string;
    id?: number;
    price?: number;
    supplierBookingStatus?: RefundFailedBookingTO.SupplierBookingStatusEnum;
    supplierType?: string;
}
export namespace RefundFailedBookingTO {
    export type SupplierBookingStatusEnum = 'PENDING' | 'BOOKED' | 'CONFIRMED' | 'FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'BOOKING_ON_PROCESS' | 'TICKET_ON_PROCESS' | 'PARTLY_BOOKED' | 'PARTLY_CONFIRMED' | 'ISSUE_FAILED';
    export const SupplierBookingStatusEnum = {
        PENDING: 'PENDING' as SupplierBookingStatusEnum,
        BOOKED: 'BOOKED' as SupplierBookingStatusEnum,
        CONFIRMED: 'CONFIRMED' as SupplierBookingStatusEnum,
        FAILED: 'FAILED' as SupplierBookingStatusEnum,
        CANCELLED: 'CANCELLED' as SupplierBookingStatusEnum,
        EXPIRED: 'EXPIRED' as SupplierBookingStatusEnum,
        TENTATIVE: 'TENTATIVE' as SupplierBookingStatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as SupplierBookingStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as SupplierBookingStatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as SupplierBookingStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as SupplierBookingStatusEnum,
        ISSUEFAILED: 'ISSUE_FAILED' as SupplierBookingStatusEnum
    };
}
