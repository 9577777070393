import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberNight'
})
export class NumberNightPipe implements PipeTransform {
  transform(departureDate: string, returnDate?: string): number {
    let numbDate = 0;
    if (departureDate && returnDate) {
      numbDate = this.datediff(
        this.parseDate(departureDate),
        this.parseDate(returnDate)
      );
    }
    return numbDate;
  }
  parseDate(str) {
    let mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }
  datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
}
