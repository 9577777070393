import { OtaResultOfTourActivityAvailableDTO } from '@gtd/b2c-client';
import {
  TourSearchActivityDetailAction,
  TourSearchActivityDetailActionTypes
} from './tour-search-activity-detail.actions';

export const TOURSEARCHACTIVITYDETAIL_FEATURE_KEY = 'tourSearchActivityDetail';

/**
 * Interface for the 'TourSearchActivityDetail' data used in
 *  - TourSearchActivityDetailState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface TourSearchActivityDetailState {
  list: OtaResultOfTourActivityAvailableDTO; // list of TourSearchActivityDetail; analogous to a sql normalized table
  selectedId?: string | number; // which TourSearchActivityDetail record has been selected
  loaded: boolean; // has the TourSearchActivityDetail list been loaded
  error?: any; // last none error (if any)
}

export interface TourSearchActivityDetailPartialState {
  readonly [TOURSEARCHACTIVITYDETAIL_FEATURE_KEY]: TourSearchActivityDetailState;
}

export const initialState: TourSearchActivityDetailState = {
  list: null,
  loaded: false
};

export function reducer(
  state: TourSearchActivityDetailState = initialState,
  action: TourSearchActivityDetailAction
): TourSearchActivityDetailState {
  switch (action.type) {
    case TourSearchActivityDetailActionTypes.TourSearchActivityDetailLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case TourSearchActivityDetailActionTypes.ResetTourSearchActivityDetail: {
      state = {
        ...initialState
      };
      break;
    }
  }
  return state;
}
