import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { TourSearchActivityAvailablePartialState } from './tour-search-activity-available.reducer';
import {
  LoadTourSearchActivityAvailable,
  TourSearchActivityAvailableLoaded,
  TourSearchActivityAvailableLoadError,
  TourSearchActivityAvailableActionTypes
} from './tour-search-activity-available.actions';
import { TourResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class TourSearchActivityAvailableEffects {
  @Effect() loadTourSearchActivityAvailable$ = this.dataPersistence.fetch(
    TourSearchActivityAvailableActionTypes.LoadTourSearchActivityAvailable,
    {
      run: (
        action: LoadTourSearchActivityAvailable,
        state: TourSearchActivityAvailablePartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...

        return this.tourResourceService
          .searchActivityAvailableUsingGET(
            action.payload.language,
            action.payload.searchCode,
            action.payload.searchType,
            action.payload.categoryIds,
            action.payload.amenityIds,
            action.payload.themeIds,
            action.payload.sortName,
            action.payload.sortOrder,
            action.payload.zoneId,
            action.payload.saleChannel,
            action.payload.pageNumber,
            action.payload.pageSize
          )
          .pipe(
            map(payload => ({
              type:
                TourSearchActivityAvailableActionTypes.TourSearchActivityAvailableLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadTourSearchActivityAvailable, error) => {
        console.error('Error', error);
        return new TourSearchActivityAvailableLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<
      TourSearchActivityAvailablePartialState
    >,
    private tourResourceService: TourResourceService
  ) {}
}
