import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gtd-star-options',
  templateUrl: './star-options.component.html',
  styleUrls: ['./star-options.component.scss'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarOptionsComponent),
      multi: true
    }
  ]
})
export class StarOptionsComponent implements OnInit, OnChanges {
  @Input() filterStars: number[];
  stars = [false, false, false, false, false];
  constructor() {}

  ngOnInit() {}

  activeStar(index) {
    this.stars = [false, false, false, false, false];

    this.stars.fill(true, 0, index + 1);

    const minValue = this.stars.findIndex(star => star === true) + 1;
    const maxValue = this.stars.lastIndexOf(true) + 1;

    this.onChange({
      min: maxValue,
      max: maxValue
    });
    this.onTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { filterStars } = changes;
    if (filterStars && filterStars.currentValue) {
      const min = 1;
      const max = Math.max(...filterStars.currentValue);

      for (let i = min; i <= max; i++) {
        this.stars[i - 1] = true;
      }
    }
  }

  writeValue(obj: any): void {
    if (obj) {
      // this.form.setValue(obj);
    }

    if (obj === null) {
      // this.hotelName.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  onChange: any = () => {};
  onTouched: any = () => {};
}
