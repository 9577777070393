import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {SearchUserBooking, SearchUserBookingPartialState} from './search-user-booking.reducer';
import { searchUserBookingQuery } from './search-user-booking.selectors';
import { LoadSearchUserBooking } from './search-user-booking.actions';

@Injectable()
export class SearchUserBookingFacade {
  loaded$ = this.store.pipe(select(searchUserBookingQuery.getLoaded));
  allSearchUserBooking$ = this.store.pipe(
    select(searchUserBookingQuery.getAllSearchUserBooking)
  );
  selectedSearchUserBooking$ = this.store.pipe(
    select(searchUserBookingQuery.getSelectedSearchUserBooking)
  );
  allTotalCount$ = this.store.pipe(
    select(searchUserBookingQuery.getTotalCount)
  );

  constructor(private store: Store<SearchUserBookingPartialState>) {}

  loadAll(searchUserBooking: SearchUserBooking) {
    this.store.dispatch(new LoadSearchUserBooking(searchUserBooking));
  }
}
