import {
  FlightPaginationAction,
  FlightPaginationActionTypes
} from './flight-pagination.actions';
import { DEFAULT_PAGINATION_OFFSET } from '@gtd/helpers';

export const FLIGHTPagination_FEATURE_KEY = 'comboVJFlightPagination';

export interface FlightPaginationState {
  page?: number;
  size?: number;
  sort?: Array<string>;
}

export interface FlightPaginationPartialState {
  readonly [FLIGHTPagination_FEATURE_KEY]: FlightPaginationState;
}

export const initialState: FlightPaginationState = {
  page: 0,
  size: DEFAULT_PAGINATION_OFFSET,
  sort: []
};

export function flightPaginationReducer(
  state: FlightPaginationState = initialState,
  action: FlightPaginationAction
): FlightPaginationState {
  switch (action.type) {
    case FlightPaginationActionTypes.ComboVJLoadFlightPagination: {
      state = {
        ...state
      };
      break;
    }
    case FlightPaginationActionTypes.ComboVJChangeFlightPaginationPage: {
      state = {
        ...state,
        page: state.page
      };
      break;
    }
    case FlightPaginationActionTypes.ComboVJChangeFlightPaginationSize: {
      state = {
        ...state,
        size: state.size
      };
      break;
    }
    case FlightPaginationActionTypes.ComboVJChangeFlightPaginationSort: {
      state = {
        ...state,
        sort: state.sort.slice()
      };
      break;
    }
  }
  return state;
}
