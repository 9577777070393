/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { B2BMarkupFormulaValueReq } from '../model/b2BMarkupFormulaValueReq';
import { B2CMarkupFormulaValueReq } from '../model/b2CMarkupFormulaValueReq';
import { MarkupFormula } from '../model/markupFormula';
import { ProductMarkupFormulaValueRes } from '../model/productMarkupFormulaValueRes';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MarkupModelResourceService {

    protected basePath = 'https://10.7.71.20:8282';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * findB2BMarkupFormulaValue
     * 
     * @param markupFormulaValueReq markupFormulaValueReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findB2BMarkupFormulaValueUsingPOST(markupFormulaValueReq: B2BMarkupFormulaValueReq, observe?: 'body', reportProgress?: boolean): Observable<ProductMarkupFormulaValueRes>;
    public findB2BMarkupFormulaValueUsingPOST(markupFormulaValueReq: B2BMarkupFormulaValueReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductMarkupFormulaValueRes>>;
    public findB2BMarkupFormulaValueUsingPOST(markupFormulaValueReq: B2BMarkupFormulaValueReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductMarkupFormulaValueRes>>;
    public findB2BMarkupFormulaValueUsingPOST(markupFormulaValueReq: B2BMarkupFormulaValueReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (markupFormulaValueReq === null || markupFormulaValueReq === undefined) {
            throw new Error('Required parameter markupFormulaValueReq was null or undefined when calling findB2BMarkupFormulaValueUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductMarkupFormulaValueRes>(`${this.basePath}/api/markup-models/markup-values/b2b`,
            markupFormulaValueReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findB2BMarkupFormulaValue
     * 
     * @param markupKey markupKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findB2BMarkupFormulaValueUsingPOST1(markupKey: string, observe?: 'body', reportProgress?: boolean): Observable<MarkupFormula>;
    public findB2BMarkupFormulaValueUsingPOST1(markupKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MarkupFormula>>;
    public findB2BMarkupFormulaValueUsingPOST1(markupKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MarkupFormula>>;
    public findB2BMarkupFormulaValueUsingPOST1(markupKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (markupKey === null || markupKey === undefined) {
            throw new Error('Required parameter markupKey was null or undefined when calling findB2BMarkupFormulaValueUsingPOST1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MarkupFormula>(`${this.basePath}/api/markup-models/markup-values/key`,
            markupKey,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findB2CMarkupFormulaValue
     * 
     * @param markupFormulaValueReq markupFormulaValueReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findB2CMarkupFormulaValueUsingPOST(markupFormulaValueReq: B2CMarkupFormulaValueReq, observe?: 'body', reportProgress?: boolean): Observable<ProductMarkupFormulaValueRes>;
    public findB2CMarkupFormulaValueUsingPOST(markupFormulaValueReq: B2CMarkupFormulaValueReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductMarkupFormulaValueRes>>;
    public findB2CMarkupFormulaValueUsingPOST(markupFormulaValueReq: B2CMarkupFormulaValueReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductMarkupFormulaValueRes>>;
    public findB2CMarkupFormulaValueUsingPOST(markupFormulaValueReq: B2CMarkupFormulaValueReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (markupFormulaValueReq === null || markupFormulaValueReq === undefined) {
            throw new Error('Required parameter markupFormulaValueReq was null or undefined when calling findB2CMarkupFormulaValueUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductMarkupFormulaValueRes>(`${this.basePath}/api/markup-models/markup-values/b2c`,
            markupFormulaValueReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllB2BMarkupModelByAgencyAndProductType
     * 
     * @param agencyCode agencyCode
     * @param productType productType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllB2BMarkupModelByAgencyAndProductTypeUsingGET(agencyCode: string, productType: 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR', observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupFormula>>;
    public getAllB2BMarkupModelByAgencyAndProductTypeUsingGET(agencyCode: string, productType: 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupFormula>>>;
    public getAllB2BMarkupModelByAgencyAndProductTypeUsingGET(agencyCode: string, productType: 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupFormula>>>;
    public getAllB2BMarkupModelByAgencyAndProductTypeUsingGET(agencyCode: string, productType: 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getAllB2BMarkupModelByAgencyAndProductTypeUsingGET.');
        }

        if (productType === null || productType === undefined) {
            throw new Error('Required parameter productType was null or undefined when calling getAllB2BMarkupModelByAgencyAndProductTypeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<MarkupFormula>>(`${this.basePath}/api/markup-models/b2b/${encodeURIComponent(String(agencyCode))}/${encodeURIComponent(String(productType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllB2BMarkupModelByAgency
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllB2BMarkupModelByAgencyUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupFormula>>;
    public getAllB2BMarkupModelByAgencyUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupFormula>>>;
    public getAllB2BMarkupModelByAgencyUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupFormula>>>;
    public getAllB2BMarkupModelByAgencyUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getAllB2BMarkupModelByAgencyUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<MarkupFormula>>(`${this.basePath}/api/markup-models/b2b/${encodeURIComponent(String(agencyCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllB2CMarkupModelByCustomerClassAndProductType
     * 
     * @param custClass custClass
     * @param productType productType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllB2CMarkupModelByCustomerClassAndProductTypeUsingGET(custClass: 'BASIC' | 'SILVER' | 'GOLD' | 'PLATINUM', productType: 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR', observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupFormula>>;
    public getAllB2CMarkupModelByCustomerClassAndProductTypeUsingGET(custClass: 'BASIC' | 'SILVER' | 'GOLD' | 'PLATINUM', productType: 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupFormula>>>;
    public getAllB2CMarkupModelByCustomerClassAndProductTypeUsingGET(custClass: 'BASIC' | 'SILVER' | 'GOLD' | 'PLATINUM', productType: 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupFormula>>>;
    public getAllB2CMarkupModelByCustomerClassAndProductTypeUsingGET(custClass: 'BASIC' | 'SILVER' | 'GOLD' | 'PLATINUM', productType: 'AIR' | 'HOTEL' | 'AIRCOMBO' | 'HOTELCOMBO' | 'AIRCOMBOVIETJET' | 'TOUR', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (custClass === null || custClass === undefined) {
            throw new Error('Required parameter custClass was null or undefined when calling getAllB2CMarkupModelByCustomerClassAndProductTypeUsingGET.');
        }

        if (productType === null || productType === undefined) {
            throw new Error('Required parameter productType was null or undefined when calling getAllB2CMarkupModelByCustomerClassAndProductTypeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<MarkupFormula>>(`${this.basePath}/api/markup-models/b2c/${encodeURIComponent(String(custClass))}/${encodeURIComponent(String(productType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllB2CMarkupModelByCustomerClass
     * 
     * @param custClass custClass
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllB2CMarkupModelByCustomerClassUsingGET(custClass: 'BASIC' | 'SILVER' | 'GOLD' | 'PLATINUM', observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupFormula>>;
    public getAllB2CMarkupModelByCustomerClassUsingGET(custClass: 'BASIC' | 'SILVER' | 'GOLD' | 'PLATINUM', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupFormula>>>;
    public getAllB2CMarkupModelByCustomerClassUsingGET(custClass: 'BASIC' | 'SILVER' | 'GOLD' | 'PLATINUM', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupFormula>>>;
    public getAllB2CMarkupModelByCustomerClassUsingGET(custClass: 'BASIC' | 'SILVER' | 'GOLD' | 'PLATINUM', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (custClass === null || custClass === undefined) {
            throw new Error('Required parameter custClass was null or undefined when calling getAllB2CMarkupModelByCustomerClassUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<MarkupFormula>>(`${this.basePath}/api/markup-models/b2c/${encodeURIComponent(String(custClass))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllKeys
     * 
     * @param businessType businessType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllKeysUsingGET(businessType: 'B2C' | 'B2B', observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getAllKeysUsingGET(businessType: 'B2C' | 'B2B', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getAllKeysUsingGET(businessType: 'B2C' | 'B2B', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getAllKeysUsingGET(businessType: 'B2C' | 'B2B', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (businessType === null || businessType === undefined) {
            throw new Error('Required parameter businessType was null or undefined when calling getAllKeysUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/markup-models/keys/${encodeURIComponent(String(businessType))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
