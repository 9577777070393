import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {BookingResultComponent} from "./booking-result.component";

const routes: Routes = [
  {
    path: '',
    component: BookingResultComponent
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingResultModuleRouting {}
