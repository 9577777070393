import { Action } from '@ngrx/store';
import { CheckoutPayload, OtaResultOfCheckoutResult } from '@gtd/b2c-client';

export enum HotelCheckoutActionTypes {
  ComboVJLoadHotelCheckout = '[ComboVJ_HotelCheckout] Load HotelCheckout',
  ComboVJHotelCheckoutLoaded = '[ComboVJ_HotelCheckout] HotelCheckout Loaded',
  ComboVJHotelCheckoutLoadError = '[ComboVJ_HotelCheckout] HotelCheckout Load Error',
  ComboVJResetHotelCheckout = '[ComboVJ_HotelCheckout] Reset HotelCheckout'
}

export class ComboVJLoadHotelCheckout implements Action {
  readonly type = HotelCheckoutActionTypes.ComboVJLoadHotelCheckout;
  constructor(public payload: CheckoutPayload) {}
}

export class ComboVJResetHotelCheckout implements Action {
  readonly type = HotelCheckoutActionTypes.ComboVJResetHotelCheckout;
  constructor() {}
}

export class ComboVJHotelCheckoutLoadError implements Action {
  readonly type = HotelCheckoutActionTypes.ComboVJHotelCheckoutLoadError;
  constructor(public payload: any) {}
}

export class ComboVJHotelCheckoutLoaded implements Action {
  readonly type = HotelCheckoutActionTypes.ComboVJHotelCheckoutLoaded;
  constructor(public payload: OtaResultOfCheckoutResult) {}
}

export type HotelCheckoutAction =
  | ComboVJLoadHotelCheckout
  | ComboVJHotelCheckoutLoaded
  | ComboVJHotelCheckoutLoadError
  | ComboVJResetHotelCheckout;

export const fromHotelCheckoutActions = {
  ComboVJLoadHotelCheckout,
  ComboVJHotelCheckoutLoaded,
  ComboVJHotelCheckoutLoadError,
  ComboVJResetHotelCheckout
};
