import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { LibraryAvatarPartialState } from './library-avatar.reducer';
import { libraryAvatarQuery } from './library-avatar.selectors';
import {LoadLibraryAvatar, ResetAllLibraryAvatar} from './library-avatar.actions';
import {CustomerAvatarDTO} from "@gtd/api-services/customer-service";

@Injectable()
export class LibraryAvatarFacade {
  loaded$ = this.store.pipe(select(libraryAvatarQuery.getLoaded));
  allLibraryAvatar$ = this.store.pipe(
    select(libraryAvatarQuery.getAllLibraryAvatar)
  );
  selectedLibraryAvatar$ = this.store.pipe(
    select(libraryAvatarQuery.getSelectedLibraryAvatar)
  );

  constructor(private store: Store<LibraryAvatarPartialState>) {}

  updateAvatar(customerAvatarDTO: CustomerAvatarDTO) {
    this.store.dispatch(new LoadLibraryAvatar(customerAvatarDTO));
  }

  resetAllUpdateAvatar() {
    this.store.dispatch(new ResetAllLibraryAvatar());
  }
}
