import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TOURSEARCHBYKEYWORD_FEATURE_KEY,
  TourSearchByKeywordState
} from './tour-search-by-keyword.reducer';

// Lookup the 'TourSearchByKeyword' feature state managed by NgRx
const getTourSearchByKeywordState = createFeatureSelector<
  TourSearchByKeywordState
>(TOURSEARCHBYKEYWORD_FEATURE_KEY);

const getLoaded = createSelector(
  getTourSearchByKeywordState,
  (state: TourSearchByKeywordState) => state.loaded
);
const getError = createSelector(
  getTourSearchByKeywordState,
  (state: TourSearchByKeywordState) => state.error
);

const getAllTourSearchByKeyword = createSelector(
  getTourSearchByKeywordState,
  getLoaded,
  (state: TourSearchByKeywordState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getTourSearchByKeywordState,
  (state: TourSearchByKeywordState) => state.selectedId
);
const getSelectedTourSearchByKeyword = createSelector(
  getAllTourSearchByKeyword,
  getSelectedId,
  (tourSearchByKeyword, id) => {
    // const result = tourSearchByKeyword.find(it => it['id'] === id);
    return tourSearchByKeyword
      ? Object.assign({}, tourSearchByKeyword)
      : undefined;
  }
);

export const tourSearchByKeywordQuery = {
  getLoaded,
  getError,
  getAllTourSearchByKeyword,
  getSelectedTourSearchByKeyword
};
