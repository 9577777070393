import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { PropertyAllRate } from '@gtd/b2c-client';
import { HotelDetailFacade } from '../../../../../state/hotel-detail/hotel-detail.facade';
import { PopupRequestFormComponent } from '../popup-request-form/popup-request-form.component';

@Component({
  selector: 'gtd-rate-plan-item',
  templateUrl: './rate-plan-item.component.html',
  styleUrls: ['./rate-plan-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RatePlanItemComponent),
      multi: true
    }
  ]
})
export class RatePlanItemComponent implements OnInit, ControlValueAccessor {
  @Input() ratePlan: any;
  @Input() hotelName: string;
  @Input() roomName: string;
  @Input() hotelAddress?: string;
  @Input() isLoading: boolean;
  ratePlanAmenitiesWithoutDescription: any;
  ratePlanDescription: string;
  @Input() imgRooms: any[];
  btnClick: boolean;
  priceOneNightPerOneRoom: number;
  priceBeforePromo: number;
  feesArray = [];

  constructor(
    public dialog: MatDialog,
    private hotelDetailFacade: HotelDetailFacade
  ) {}

  ngOnInit() {
    this.countPriceOneNightPerOneRoom();
    this.hotelDetailFacade.allHotelDetail$.subscribe(hotelDetail => {
      if (hotelDetail) {
        if (
          this.ratePlan &&
          this.ratePlan.fees &&
          Object.keys(this.ratePlan.fees).length
        ) {
          for (const key in this.ratePlan.fees) {
            if (Object.prototype.hasOwnProperty.call(this.ratePlan.fees, key)) {
              const element = this.ratePlan.fees[key];
              const propertyName = key.split('||')[0];
              const propertyValue = {
                name: key.split('||')[1],
                value: element
              };

              if (
                this.feesArray.findIndex(fee => fee.id === propertyName) < 0
              ) {
                const feeObj = {
                  id: propertyName,
                  properties: [propertyValue]
                };
                this.feesArray.push(feeObj);
              } else {
                const idx = this.feesArray.findIndex(
                  fee => fee.id === propertyName
                );
                this.feesArray[idx].properties.push(propertyValue);
              }
            }
          }
        }
      }
    });
    if (
      this.ratePlan &&
      this.ratePlan.amenities &&
      this.ratePlan.amenities.length
    ) {
      this.ratePlanAmenitiesWithoutDescription = this.ratePlan.amenities.filter(
        ratePlan => ratePlan.id !== 'rate_description'
      );
      this.ratePlanDescription =
        this.ratePlan.amenities.find(
          ratePlan => ratePlan.id === 'rate_description'
        ) &&
        this.ratePlan.amenities.find(
          ratePlan => ratePlan.id === 'rate_description'
        ).value
          ? this.ratePlan.amenities.find(
              ratePlan => ratePlan.id === 'rate_description'
            ).value
          : '';
    }
  }

  selectRoom() {
    this.isLoading = true;
    this.onChange(this.ratePlan.ratePlanId);
    this.onTouched();
  }

  countPriceOneNightPerOneRoom() {
    if (
      this.ratePlan &&
      this.ratePlan.paxPrice &&
      this.ratePlan.paxPrice.length
    ) {
      const numberNight = this.ratePlan.paxPrice[0].nightPrices.length;
      const numberRoom = this.ratePlan.paxPrice.length;
      this.priceOneNightPerOneRoom =
        Math.ceil(this.ratePlan.basePrice / (numberNight * numberRoom * 1000)) *
        1000;
      this.priceBeforePromo =
        Math.ceil(
          this.ratePlan.basePriceBeforePromo / (numberRoom * numberNight * 1000)
        ) * 1000;
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  writeValue(obj: any): void {}

  requestBook() {
    const dialogRef = this.dialog.open(PopupRequestFormComponent, {
      data: {
        imgRooms: this.imgRooms,
        ratePlan: this.ratePlan,
        hotelName: this.hotelName,
        roomName: this.roomName,
        hotelAddress: this.hotelAddress
      },
      position: {
        top: '100px'
      },
      autoFocus: false,
      panelClass: 'popup-request-form',
      disableClose: true
    });
  }
}
