import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { of, ReplaySubject, Subject, Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { MatDialog, MatSelect, MatSnackBar } from '@angular/material';
import { ErrorStateMatcher } from '@angular/material/core';
import { LookupB2bFacade } from '@gtd/meta';
import {
  AgencyResourceService,
  AgencyTO,
  LookupDTO
} from '@gtd/api-services/b2b-client';
import { catchError, concatMap, takeUntil } from 'rxjs/operators';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { ReferralResourceService } from '@gtd/api-services/agentsrv';
import { AuthenticateService } from '../authenticate/authenticate.service';
import { environment } from '@envB2b/environment';
import { GetPageBySlugService } from '@gtd/api-services/contentsrv';
import { isPlatformBrowser } from '@angular/common';

// Error when invalid control is dirty, touched, or submitted.
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'gtd-register-agent',
  templateUrl: './register-agent.component.html',
  styleUrls: ['./register-agent.component.scss']
})
export class RegisterAgentComponent implements OnInit, AfterViewInit {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  hide = true;
  isLoading = false;
  registerForm!: FormGroup;
  subscriptions = new Subscription();
  country = { lookupValue: 'vn', description: 'Vietnam' };
  matcher = new MyErrorStateMatcher();

  protected cities: LookupDTO[];
  public cityFilterCtrl: FormControl = new FormControl();
  public filteredCities: ReplaySubject<LookupDTO[]> = new ReplaySubject<
    LookupDTO[]
  >(1);
  protected _onDestroy = new Subject<void>();
  selectedCooperationClass = 'sa';
  pageData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private lookupFacade: LookupB2bFacade,
    private agencyResourceService: AgencyResourceService,
    private referralResourceService: ReferralResourceService,
    private authenticationService: AuthenticateService,
    private getPageBySlugService: GetPageBySlugService,
    private _elementRef: ElementRef
  ) {
    this.getPageBySlugService
      .pageV1GetBySlugSlugGet('register-agent')
      .subscribe(page => {
        if (page.success) this.pageData = page.page;
      });
  }

  ngOnInit() {
    this.createForm();
    this.lookupFacade.loadAll({
      domainName: 'destination',
      objectName: 'region',
      lookupType: 'vn',
      page: 0,
      size: 1000
    });
    this.lookupFacade.allLookupB2b$.subscribe(allLookupB2b => {
      this.cities = allLookupB2b;
      this.filteredCities.next(allLookupB2b.slice());
    });
    this.cityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCities();
      });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['inviteCode']) {
        this.registerForm.patchValue({
          referralCode: params['inviteCode']
        });
      }
    });
    if (this.router.url.includes('invite_code')) {
      this.activatedRoute.params.subscribe(params => {
        if (params['inviteCode']) {
          this.registerForm.patchValue({
            referralCode: params['inviteCode']
          });
        }
      });
    }
    this.registerForm
      .get('cooperationClass')
      .valueChanges.subscribe(currentValue => {
        this.selectedCooperationClass = currentValue;
      });
    if (this.authenticationService.isAuthenticated() === true) {
      const token = this.authenticationService.getToken();
      if (this.authenticationService.isTokenExpired(token) === false) {
        setTimeout(() => {
          window.location.href =
            environment.b2bSite + '/#/login-token?token=' + token;
        }, 100);
      }
    }
  }

  ngAfterViewInit(): void {
    // fromEvent<any>(this.temporaryReferralCode.nativeElement, 'keyup')
    //   .pipe(debounceTime(1000))
    //   .subscribe(temporaryReferralCode => {
    //     if (temporaryReferralCode && temporaryReferralCode.target) {
    //       this.referralResourceService.existInviteCodeUsingGET(temporaryReferralCode.target.value)
    //         .subscribe(response => {
    //           if (response.success === false) {
    //             this.registerForm.controls['referralCode'].setErrors(['InviteCodeNotExist']);
    //           }
    //         })
    //     }
    //   }
    // );
  }

  protected filterCities() {
    if (!this.cities) {
      return;
    }
    let search = this.cityFilterCtrl.value;
    if (!search && this.cities) {
      this.filteredCities.next(this.cities.slice());
      return;
    } else {
      search = search
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    }
    this.filteredCities.next(
      this.cities.filter(city => {
        let description;
        if (city.description === 'HCM') {
          description = 'Ho Chi Minh';
        } else {
          description = city.description
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        }
        return (
          description.toLowerCase().indexOf(search) > -1 ||
          city.lookupCode.toLowerCase().indexOf(search) > -1 ||
          city.description.toLowerCase().indexOf(search) > -1
        );
      })
    );
  }

  submitForm() {
    if (this.registerForm.valid) {
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'user.registering', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
      const agencyTO = {
        email: this.registerForm.controls['email'].value,
        phoneNumber1: this.registerForm.controls['phoneNumber1'].value,
        shortName: this.registerForm.controls['shortName'].value,
        address1: this.registerForm.controls['address1'].value,
        cooperationClass: this.registerForm.controls['cooperationClass'].value,
        countryCode: this.country.lookupValue,
        countryName: this.country.description,
        stateCode: this.registerForm.controls['state'].value.lookupValue,
        stateName: this.registerForm.controls['state'].value.description,
        agentAdminUsername: this.registerForm.controls['email'].value,
        fullName: this.registerForm.controls['shortName'].value,
        representativePhone: this.registerForm.controls['phoneNumber1'].value,
        representativeMail: this.registerForm.controls['email'].value,
        contactPerson1: '',
        verify: 'DISABLE',
        notify: this.registerForm.controls['cooperationClass'].value !== 'ca'? 'ENABLE': 'DISABLE',
        status: 'DEACTIVATED',
        representativeName: this.registerForm.controls['shortName'].value,
        activeNow: false,
        phoneContact1: this.registerForm.controls['phoneNumber1'].value,
        subMail: this.registerForm.controls['email'].value,
        agencyClass:
          this.registerForm.controls['cooperationClass'].value === 'sa'
            ? 'C::4'
            : 'C::1'
      } as AgencyTO;

      this.agencyResourceService
        .registerAgencyUsingPOST(agencyTO)
        .pipe(
          concatMap(response => {
            if (response.success === true) {
              if (this.registerForm.controls['referralCode'].value) {
                return this.referralResourceService.addReferalByUsingGET(
                  this.registerForm.controls['referralCode'].value,
                  this.registerForm.controls['phoneNumber1'].value,
                  this.registerForm.controls['email'].value
                );
              } else {
                return of<any>({});
              }
            } else if (response.success === false) {
              return throwError(response.errors[0]);
            }
          }),
          catchError(error => throwError(error))
        )
        .subscribe(
          response => {
            this._snackBar.dismiss();
            this.isLoading = false;
            this.openDialog(response);
          },
          error => {
            this._snackBar.dismiss();
            this.isLoading = false;
            if (error.code) {
              this.openErrorDialog(
                'user.ref.response.' + error['code'],
                'user.heading-login-error'
              );
            } else {
              this.openErrorDialog(
                'user.system-error-message',
                'user.system-error'
              );
            }
          }
        );
    }
  }

  private createForm() {
    this.registerForm = this.formBuilder.group({
      shortName: ['', Validators.required],
      address1: ['', Validators.required],
      cooperationClass: ['sa', Validators.required],
      state: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$'
          )
        ]
      ],
      phoneNumber1: ['', [Validators.pattern('^((\\+84-?)|0)?[0-9]{10}$')]],
      referralCode: ['']
    });
  }

  scrollTo(el: string) {
    const off =
      this._elementRef.nativeElement.querySelector(`#${el}`).offsetTop -
      document.querySelector('.header-layout').clientHeight -
      46;
    window.scrollTo({ behavior: 'smooth', top: off });
  }
  openDialog(response?: any) {
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      data: {
        btn: [
          { btnColor: 'success', btnText: 'user.sign-in-now', action: 'Login' }
        ],
        content:
          response &&
          !response.success &&
          this.registerForm.controls['referralCode'].value
            ? 'user.check-email-confirm-warning'
            : 'user.check-email-confirm',
        dataTranslate: this.registerForm.controls['email'].value,
        heading:
          response &&
          !response.success &&
          this.registerForm.controls['referralCode'].value
            ? ''
            : 'user.register-success',
        icon:
          response &&
          !response.success &&
          this.registerForm.controls['referralCode'].value
            ? 'exclamation-triangle'
            : 'check',
        messageType:
          response &&
          !response.success &&
          this.registerForm.controls['referralCode'].value
            ? 'warning'
            : 'success'
      },
      panelClass: 'dialog-message',
      position: {
        top: '150px'
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(closeModal => {
      if (closeModal.action === 'Login') {
        this.router.navigate(['/auth/login']).then(() => {});
      }
    });
  }

  openErrorDialog(content: string, heading: string) {
    this.dialog.open(DialogMessageComponent, {
      data: {
        btn: [{ btnText: 'global.close' }],
        content: content,
        heading: heading,
        icon: 'exclamation-triangle',
        messageType: 'error'
      },
      panelClass: 'dialog-message',
      position: {
        top: '150px'
      },
      disableClose: true
    });
  }
}
