import {Action} from '@ngrx/store';
import {BookingSearchResultDTO, BookingTransactionResults} from "@gtd/api-services/b2b-client";
import {SearchParams} from "./search-booking-transactions.reducer";

export enum SearchBookingTransactionsActionTypes {
  LoadSearchBookingTransactions = '[SearchBookingTransactions] Load SearchBookingTransactions',
  SearchBookingTransactionsLoaded = '[SearchBookingTransactions] SearchBookingTransactions Loaded',
  SearchBookingTransactionsLoadError = '[SearchBookingTransactions] SearchBookingTransactions Load Error',
  DestroyTransactions = '[SearchBookingTransactions] Destroy Transactions'
}

export class LoadSearchBookingTransactions implements Action {
  readonly type =
    SearchBookingTransactionsActionTypes.LoadSearchBookingTransactions;
    constructor(public payload: SearchParams) {}
}

export class DestroyTransactions implements Action {
  readonly type =
    SearchBookingTransactionsActionTypes.DestroyTransactions;
}

export class SearchBookingTransactionsLoadError implements Action {
  readonly type =
    SearchBookingTransactionsActionTypes.SearchBookingTransactionsLoadError;
  constructor(public payload: any) {}
}

export class SearchBookingTransactionsLoaded implements Action {
  readonly type =
    SearchBookingTransactionsActionTypes.SearchBookingTransactionsLoaded;
  constructor(public payload: BookingTransactionResults) {}
}

export type SearchBookingTransactionsAction =
  | LoadSearchBookingTransactions
  | SearchBookingTransactionsLoaded
  | DestroyTransactions
  | SearchBookingTransactionsLoadError;

export const fromSearchBookingTransactionsActions = {
  LoadSearchBookingTransactions,
  SearchBookingTransactionsLoaded,
  DestroyTransactions,
  SearchBookingTransactionsLoadError
};
