import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PhoneResendOtpStatePartialState } from './phone-resend-otp-state.reducer';
import { phoneResendOtpStateQuery } from './phone-resend-otp-state.selectors';
import {LoadDestroyPhoneResendOtpState, LoadPhoneResendOtpState} from './phone-resend-otp-state.actions';
import {RegisterPhoneNumberVM} from "@gtd/b2c-client";

@Injectable()
export class PhoneResendOtpStateFacade {
  loaded$ = this.store.pipe(select(phoneResendOtpStateQuery.getLoaded));
  allPhoneResendOtpState$ = this.store.pipe(
    select(phoneResendOtpStateQuery.getAllPhoneResendOtpState)
  );
  selectedPhoneResendOtpState$ = this.store.pipe(
    select(phoneResendOtpStateQuery.getSelectedPhoneResendOtpState)
  );

  constructor(private store: Store<PhoneResendOtpStatePartialState>) {}

  phoneResendOTP(registerPhoneNumberVM: RegisterPhoneNumberVM){
  this.store.dispatch(new LoadPhoneResendOtpState(registerPhoneNumberVM));
  }

  destroyResendOTP(){
    this.store.dispatch(new LoadDestroyPhoneResendOtpState());
  }


}
