/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PassengerTypeQuantities { 
    code?: PassengerTypeQuantities.CodeEnum;
    quantity?: number;
}
export namespace PassengerTypeQuantities {
    export type CodeEnum = 'ADT' | 'CHD' | 'INF';
    export const CodeEnum = {
        ADT: 'ADT' as CodeEnum,
        CHD: 'CHD' as CodeEnum,
        INF: 'INF' as CodeEnum
    };
}
