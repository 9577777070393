import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FlightResultItemService {
  openPanel = new BehaviorSubject<boolean>(false);
  getOpenPanel = this.openPanel.asObservable();

  constructor() { }


  setOpenPanel(openPanel: boolean) {
    this.openPanel.next(openPanel);
  }
}
