import { Component, Input, OnInit } from '@angular/core';
import { Attribute } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-hotel-description',
  templateUrl: './hotel-description.component.html',
  styleUrls: ['./hotel-description.component.scss']
})
export class HotelDescriptionComponent implements OnInit {
  @Input() label: string;
  @Input() descriptions?: Array<Attribute>;
  aroundArea;
  panelOpenState: boolean = true;
  toggleValue: boolean;
  aroundAreaArr;
  constructor() {}

  ngOnInit() {
    this.aroundArea = this.descriptions
      ? this.descriptions.find(
          description => description.name === 'attractions'
        )
      : '';
    if (this.aroundArea) {
      this.aroundAreaArr = this.aroundArea.value.split('<br />');
    }
  }
  toggleDescription() {
    this.toggleValue = !this.toggleValue;
  }
}
