import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { environment } from '@env/environment';

@Component({
  selector: 'gtd-new-increament-input',
  templateUrl: './new-increament-input.component.html',
  styleUrls: ['./new-increament-input.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewIncreamentInputComponent),
      multi: true
    }
  ]
})
export class NewIncreamentInputComponent
  implements ControlValueAccessor, OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() maxValue: number;
  @Input() minValue: number;
  @Input() icon: string;
  @Input() type: 'Adult' | 'Child' | 'Infant';
  @Input() disableBtn?: boolean = false;
  number: number;

  constructor(public controlContainer: ControlContainer) {}
  ngOnInit(): void {
    this.number = this.minValue;
    this.controlContainer.control.valueChanges.subscribe(valueChange => {
      setTimeout(() => {
        if (this.type == 'Adult') {
          this.number = valueChange.adultQuantity;
        }
        if (this.type == 'Child') {
          this.number = valueChange.childQuantity;
        }
        if (this.type == 'Infant') {
          this.number = valueChange.infantCount;
        }
      });
    });
  }

  increase() {
    if (this.number < this.maxValue) {
      this.number++;
    } else {
      this.number = this.maxValue;
    }
    this.onChange(this.number);
  }

  decrease() {
    if (this.number > this.minValue) {
      this.number--;
    } else {
      this.number = this.minValue;
    }
    this.onChange(this.number);
  }

  get value(): number {
    return this.number;
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.number = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validationPassenger(): boolean {
    return true;
  }
}
