import { Component, OnInit } from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {fadeIn, fadeInOut} from "@gtd/helpers";
import {Router} from "@angular/router";
import {TemplateService} from "@gtd/services";

@Component({
  selector: 'gtd-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [
    fadeInOut,
    fadeIn
  ]
})
export class RegisterComponent implements OnInit {
  config: SwiperConfigInterface;
  isLoading: boolean;
  regType: 'sa' | 'cn';

  constructor(private router: Router, private templateService: TemplateService,) {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 10,
      lazy: true,
      navigation: false,
      pagination: true,
      autoplay: {
        delay: 5000
      }
    };
  }

  ngOnInit() {
  }

  submitForm() {
    switch (this.regType) {
      case "sa":
        this.templateService.setTemplateGtd('ca-template');
        this.router.navigate(['/auth/register-ca']);
        break;
      case "cn":
        this.templateService.setTemplateGtd('agent-template');
        this.router.navigate(['/auth/register-agent']);
        break;
    }
  }
}
