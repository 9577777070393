import {
  HotelSearchKeywordAction,
  HotelSearchKeywordActionTypes
} from './autocomplete-search-keyword.actions';
import {HotelMetaKeyword} from "@gtd/b2c-client";

export const HOTELSEARCHKEYWORD_FEATURE_KEY = 'hotelSearchKeyword';

/**
 * Interface for the 'HotelSearchKeyword' data used in
 *  - HotelSearchKeywordState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelSearchKeywordState {
  list: HotelMetaKeyword[]; // list of HotelSearchKeyword; analogous to a sql normalized table
  selectedId?: string | number; // which HotelSearchKeyword record has been selected
  loaded: boolean; // has the HotelSearchKeyword list been loaded
  error?: any; // last none error (if any)
}

export interface HotelSearchKeywordPartialState {
  readonly [HOTELSEARCHKEYWORD_FEATURE_KEY]: HotelSearchKeywordState;
}

export const initialState: HotelSearchKeywordState = {
  list: [],
  loaded: false
};

export function autocompleteSearchKeywordReducer(
  state: HotelSearchKeywordState = initialState,
  action: HotelSearchKeywordAction
): HotelSearchKeywordState {
  switch (action.type) {
    case HotelSearchKeywordActionTypes.AutoCompleteSearchKeyword: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
