/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MarkupInfo { 
    code?: string;
    formula?: string;
    key?: string;
    type?: MarkupInfo.TypeEnum;
}
export namespace MarkupInfo {
    export type TypeEnum = 'PER_PAX_PER_BOOKING' | 'PER_PAX_PER_SEGMENT' | 'PER_BOOKING';
    export const TypeEnum = {
        PAXPERBOOKING: 'PER_PAX_PER_BOOKING' as TypeEnum,
        PAXPERSEGMENT: 'PER_PAX_PER_SEGMENT' as TypeEnum,
        BOOKING: 'PER_BOOKING' as TypeEnum
    };
}
