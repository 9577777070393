import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingDTO } from '@gtd/api-services/booking-service';
import {
  TimeCountDown,
  TimeCountDownBetweenDirective
} from '@gtd/directives/time-count-down-between';

@Component({
  selector: 'gtd-tour-booking-item',
  templateUrl: './tour-booking-item.component.html',
  styleUrls: ['./tour-booking-item.component.scss']
})
export class TourBookingItemComponent implements OnInit {
  @Input() booking: any;
  @Input() supplierType: string;
  @Output() emitAdditionalRequestType: EventEmitter<any> = new EventEmitter();
  additionalRequestActions = [];
  isShowAdditionalRequestButton: boolean = false;

  timeCountDown: TimeCountDown;
  constructor(
    private timeCountDownBetweenDirective: TimeCountDownBetweenDirective
  ) {}

  ngOnInit() {
    if (this.booking.timeToLive) {
      this.timeCountDown = this.timeCountDownBetweenDirective.timeBetweenDates(
        this.booking.timeToLive
      );
    }
    if (this.booking.supplierType === 'TOURS') {
      this.additionalRequestActions = [
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    }
    if (this.booking.departureDate) {
      if (
        new Date().getTime() < new Date(this.booking.departureDate).getTime()
      ) {
        this.isShowAdditionalRequestButton = true;
      }
    }
  }

  onEmitAdditionalRequestType(code: string) {
    this.emitAdditionalRequestType.emit({
      bookingNumber: this.booking.bookingNumber,
      type: code
    });
  }
}
