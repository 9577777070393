import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromPaymentOptionInfo from './payment-option-info/payment-option-info.reducer';
import { PaymentOptionInfoEffects } from './payment-option-info/payment-option-info.effects';
import { PaymentOptionInfoFacade } from './payment-option-info/payment-option-info.facade';
import {BASE_PATH} from "../lib/variables";
import {PaymentOptionService} from "../lib/api/paymentOption.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromPaymentOptionInfo.PAYMENTOPTIONINFO_FEATURE_KEY,
      fromPaymentOptionInfo.reducer
    ),
    EffectsModule.forFeature([PaymentOptionInfoEffects])
  ],
  providers: [
    { provide: BASE_PATH, useValue: 'https://cms.gotadi.com/wp-json' },
    PaymentOptionService,
    PaymentOptionInfoFacade
  ]
})
export class CmsStateModule {}
