/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CountAgency { 
    agencyStatus?: CountAgency.AgencyStatusEnum;
    total?: number;
}
export namespace CountAgency {
    export type AgencyStatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'OTHER';
    export const AgencyStatusEnum = {
        ACTIVATED: 'ACTIVATED' as AgencyStatusEnum,
        DEACTIVATED: 'DEACTIVATED' as AgencyStatusEnum,
        OTHER: 'OTHER' as AgencyStatusEnum
    };
}
