import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { GetUserProfilePartialState } from './get-user-profile.reducer';
import { getUserProfileQuery } from './get-user-profile.selectors';
import {DestroyUserProfile, LoadGetUserProfile} from './get-user-profile.actions';

@Injectable()
export class GetUserProfileFacade {
  loaded$ = this.store.pipe(select(getUserProfileQuery.getLoaded));
  allGetUserProfile$ = this.store.pipe(
    select(getUserProfileQuery.getAllGetUserProfile)
  );
  selectedGetUserProfile$ = this.store.pipe(
    select(getUserProfileQuery.getSelectedGetUserProfile)
  );

  constructor(private store: Store<GetUserProfilePartialState>) {}

  loadAll(skipBalance: boolean) {
    this.store.dispatch(new LoadGetUserProfile(skipBalance));
  }
  destroyUser() {
    this.store.dispatch(new DestroyUserProfile());
  }
}
