import { Action } from '@ngrx/store';
import { Entity } from './airport.reducer';
import { AirportDTO } from '@gtd/meta-client';

export enum AirportActionTypes {
  LoadAirport = '[Airport] Load Airport',
  AirportLoaded = '[Airport] Airport Loaded',
  AirportLoadError = '[Airport] Airport Load Error'
}

export class LoadAirport implements Action {
  readonly type = AirportActionTypes.LoadAirport;
  constructor(public payload: any) {}
}

export class AirportLoadError implements Action {
  readonly type = AirportActionTypes.AirportLoadError;
  constructor(public payload: any) {}
}

export class AirportLoaded implements Action {
  readonly type = AirportActionTypes.AirportLoaded;
  constructor(public payload: AirportDTO[]) {}
}

export type AirportAction = LoadAirport | AirportLoaded | AirportLoadError;

export const fromAirportActions = {
  LoadAirport,
  AirportLoaded,
  AirportLoadError
};
