import {
  FlightFilterAction,
  FlightFilterActionTypes
} from './flight-filter.actions';
import {DEFAULT_PAGINATION_OFFSET, DEFAULT_SORT_SINGLE, defaultFilter} from '@gtd/helpers';
import {AirItineraryInfo, ItineraryFilter} from '@gtd/b2c-client';

export const FLIGHTFILTER_FEATURE_KEY = 'flightFilter';

export interface FlightFilterState extends ItineraryFilter {
  page?: number;
  size?: number;
  sort?: Array<string>;
  searchID?: string;
  departureItinerary: AirItineraryInfo
}

export interface SelectDepartureItem {
  searchID?: string;
  departureItinerary?: AirItineraryInfo
}

export interface FlightFilterPartialState {
  readonly [FLIGHTFILTER_FEATURE_KEY]: FlightFilterState;
}

export function getInitial() {
  return {
    ...defaultFilter,
    page: 0,
    size: DEFAULT_PAGINATION_OFFSET,
    sort: null,
    searchID: '',
    departureItinerary: null
  };
}

export const initialState: FlightFilterState = getInitial();

export function flightFilterReducer(
  state: FlightFilterState = initialState,
  action: FlightFilterAction
): FlightFilterState {
  switch (action.type) {
    case FlightFilterActionTypes.ResetFlightFilter: {
      state = { ...initialState };
      break;
    }
    case FlightFilterActionTypes.ResetFlightFilterRoundTrip: {
      initialState.searchID = action.payload.searchID;
      initialState.flightType = action.payload.flightType;
      state = { ...initialState };
      break;
    }
    case FlightFilterActionTypes.ChangeAirlineOptions: {
      state = {
        ...state,
        airlineOptions: action.payload.slice()
      };
      break;
    }
    case FlightFilterActionTypes.ChangeArrivalDateTimeOptions: {
      state = {
        ...state,
        arrivalDateTimeOptions: action.payload.slice()
      };
      break;
    }
    case FlightFilterActionTypes.ChangeArrivalDateTimeReturnOptions: {
      state = {
        ...state,
        arrivalDateTimeReturnOptions: action.payload.slice()
      };
      break;
    }
    case FlightFilterActionTypes.ChangeCabinClassOptions: {
      state = {
        ...state,
        cabinClassOptions: action.payload.slice()
      };

      break;
    }
    case FlightFilterActionTypes.ChangeDepartureDateTimeOptions: {
      state = {
        ...state,
        departureDateTimeOptions: action.payload.slice()
      };
      break;
    }
    case FlightFilterActionTypes.ChangeFlightType: {
      state = {
        ...state,
        flightType: action.payload
      };
      break;
    }
    case FlightFilterActionTypes.ChangeStep: {
      state = {
        ...state,
        step: action.payload
      };
      break;
    }
    case FlightFilterActionTypes.ChangeStopOptions: {
      state = {
        ...state,
        stopOptions: action.payload.slice()
      };
      break;
    }
    case FlightFilterActionTypes.ChangeTicketPolicyOptions: {
      state = {
        ...state,
        ticketPolicyOptions: action.payload.slice()
      };
      break;
    }

    case FlightFilterActionTypes.ChangeFlightPaginationPage: {
      state = {
        ...state,
        page: action.payload
      };
      break;
    }
    case FlightFilterActionTypes.ChangeFlightPaginationSize: {
      state = {
        ...state,
        size: action.payload,
        page: 0
      };
      break;
    }
    case FlightFilterActionTypes.ChangeFlightPaginationSort: {
      state = {
        ...state,
        sort: action.payload.slice(),
        page: 0
      };
      break;
    }
    case FlightFilterActionTypes.ChangeSearchID: {
      state = {
        ...state,
        searchID: action.payload
      };
      break;
    }
    case FlightFilterActionTypes.SelectDeparture: {
      state = {
        ...state,
        searchID: action.payload.searchID,
        departureItinerary: action.payload.departureItinerary
      };
    }
  }
  return state;
}
