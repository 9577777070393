import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { PromotionValidatePartialState } from './promotion-validate.reducer';
import {
  LoadPromotionValidate,
  PromotionValidateLoaded,
  PromotionValidateLoadError,
  PromotionValidateActionTypes
} from './promotion-validate.actions';
import { PromotionResourceService } from '../../lib/api/promotionResource.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PromotionValidateEffects {
  @Effect() loadPromotionValidate$ = this.dataPersistence.fetch(
    PromotionValidateActionTypes.LoadPromotionValidate,
    {
      run: (
        action: LoadPromotionValidate,
        state: PromotionValidatePartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.promotionResourceService
          .promotionValidateUsingPOST(action.payload)
          .pipe(
            map(payload => ({
              type: PromotionValidateActionTypes.PromotionValidateLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadPromotionValidate, error) => {
        console.error('Error', error);
        return new PromotionValidateLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<PromotionValidatePartialState>,
    private promotionResourceService: PromotionResourceService
  ) {}
}
