import {
  RegisterStateAction,
  RegisterStateActionTypes
} from './register-state.actions';
import {CommonResponse} from "@gtd/b2c-client";

export const REGISTERSTATE_FEATURE_KEY = 'registerState';

/**
 * Interface for the 'RegisterState' data used in
 *  - RegisterStateState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface RegisterStateState {
  result?: CommonResponse; // list of RegisterState; analogous to a sql normalized table
  selectedId?: string | number; // which RegisterState record has been selected
  errorMessage?: string;
  errorCode?: number;
  loaded: boolean; // has the RegisterState list been loaded
  status?: boolean; // last none error (if any)
}

export interface RegisterStatePartialState {
  readonly [REGISTERSTATE_FEATURE_KEY]: RegisterStateState;
}

export const initialState: RegisterStateState = {
  result: null,
  loaded: false
};

export function reducer(
  state: RegisterStateState = initialState,
  action: RegisterStateAction
): RegisterStateState {
  switch (action.type) {
    case RegisterStateActionTypes.RegisterStateLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true,
        status: true
      };
      break;
    }
    case RegisterStateActionTypes.RegisterStateLoadError: {
      state = {
        errorMessage: action.payload.error.error? action.payload.error.error.message: action.payload.error.message,
        errorCode: action.payload.error.error? action.payload.error.error.code: action.payload.error.status,
        loaded: true,
        status: false
      };
      break;
    }
    case RegisterStateActionTypes.RegisterStateLoadResetAll: {
      state = initialState;
      break;
    }
  }
  return state;
}
