import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RequestPasswordComponent } from './request-password/request-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PhoneRegisterOtpComponent } from './register/phone-register/phone-register-otp/phone-register-otp.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { QrcodePageComponent } from './register/qr-code-page/qrcode-page.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      },
      {
        path: 'ref',
        component: QrcodePageComponent
      },
      {
        path: 'ref/:id',
        component: QrcodePageComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'request-password',
        component: RequestPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'activate',
        component: ActivateAccountComponent
      },
      {
        path: 'register-phone-otp',
        component: PhoneRegisterOtpComponent
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
