import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { PromotionFilterByVoucherPartialState } from './promotion-filter-by-voucher.reducer';
import {
  LoadPromotionFilterByVoucher,
  PromotionFilterByVoucherLoaded,
  PromotionFilterByVoucherLoadError,
  PromotionFilterByVoucherActionTypes
} from './promotion-filter-by-voucher.actions';
import { PromotionResourceService } from '../../lib/api/promotionResource.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PromotionFilterByVoucherEffects {
  @Effect() loadPromotionFilterByVoucher$ = this.dataPersistence.fetch(
    PromotionFilterByVoucherActionTypes.LoadPromotionFilterByVoucher,
    {
      run: (
        action: LoadPromotionFilterByVoucher,
        state: PromotionFilterByVoucherPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.promotionResourceService
          .findPromotionsByVoucherTypeUsingGET(
            action.payload.productTypes,
            action.payload.voucherCode,
            action.payload.promotionStatus
          )
          .pipe(
            map(payload => ({
              type:
                PromotionFilterByVoucherActionTypes.PromotionFilterByVoucherLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadPromotionFilterByVoucher, error) => {
        console.error('Error', error);
        return new PromotionFilterByVoucherLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<
      PromotionFilterByVoucherPartialState
    >,
    private promotionResourceService: PromotionResourceService
  ) {}
}
