/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TourAttributeMapping } from './tourAttributeMapping';
import { TourContentReference } from './tourContentReference';
import { TourSchedulePriceAvailable } from './tourSchedulePriceAvailable';
import { TourTiming } from './tourTiming';

export interface TourRatePlanAvailable {
  activityId?: number;
  allowPayNow?: boolean;
  amenities?: { [key: string]: TourContentReference };
  attributes?: Array<TourAttributeMapping>;
  cancelRefundableTiming?: TourTiming;
  cutoff?: number;
  cutoffType?: TourRatePlanAvailable.CutoffTypeEnum;
  exclusions?: Array<string>;
  hasInventory?: boolean;
  id?: number;
  inclusions?: Array<string>;
  mlou?: number;
  name?: string;
  refundable?: boolean;
  requiredTraveller?: boolean;
  reservation?: boolean;
  schedulePrices?: Array<TourSchedulePriceAvailable>;
  status?: boolean;
  timing?: TourTiming;
  inventoryAvailable?: boolean;
  minQuantity?: number;
}
export namespace TourRatePlanAvailable {
  export type CutoffTypeEnum = 'DAY' | 'HOUR';
  export const CutoffTypeEnum = {
    DAY: 'DAY' as CutoffTypeEnum,
    HOUR: 'HOUR' as CutoffTypeEnum
  };
}
