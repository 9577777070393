/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PaymentFeeOption { 
    amount?: number;
    paymentType?: PaymentFeeOption.PaymentTypeEnum;
    valueType?: PaymentFeeOption.ValueTypeEnum;
}
export namespace PaymentFeeOption {
    export type PaymentTypeEnum = 'TRANSFER' | 'CASH' | 'ATM_DEBIT' | 'CREDIT' | 'BALANCE' | 'OTHER' | 'PARTNER_PAY' | 'VIETTELPAY' | 'VNPAYQR' | 'MOMO' | 'PAYOO' | 'ZALO' | 'AIRPAY' | 'KREDIVO' | 'VPBANK';
    export const PaymentTypeEnum = {
        TRANSFER: 'TRANSFER' as PaymentTypeEnum,
        CASH: 'CASH' as PaymentTypeEnum,
        ATMDEBIT: 'ATM_DEBIT' as PaymentTypeEnum,
        CREDIT: 'CREDIT' as PaymentTypeEnum,
        BALANCE: 'BALANCE' as PaymentTypeEnum,
        OTHER: 'OTHER' as PaymentTypeEnum,
        PARTNERPAY: 'PARTNER_PAY' as PaymentTypeEnum,
        VIETTELPAY: 'VIETTELPAY' as PaymentTypeEnum,
        VNPAYQR: 'VNPAYQR' as PaymentTypeEnum,
        MOMO: 'MOMO' as PaymentTypeEnum,
        PAYOO: 'PAYOO' as PaymentTypeEnum,
        ZALO: 'ZALO' as PaymentTypeEnum,
        AIRPAY: 'AIRPAY' as PaymentTypeEnum,
        KREDIVO: 'KREDIVO' as PaymentTypeEnum,
        VPBANK: 'VPBANK' as PaymentTypeEnum
    };
    export type ValueTypeEnum = 'FIXED' | 'PERCENT';
    export const ValueTypeEnum = {
        FIXED: 'FIXED' as ValueTypeEnum,
        PERCENT: 'PERCENT' as ValueTypeEnum
    };
}
