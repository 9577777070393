import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency_exchange_pipe'
})
export class CurrencyExchangePipe implements PipeTransform {
  currency_exchange = localStorage.getItem('currency_exchange');
  utm_source = localStorage.getItem('utm_source');

  transform(amount: any): number {
    let amountRs = Number(amount);
    if(this.currency_exchange && amount && this.utm_source === 'DHA') {
      amountRs = Number((amount / Number(this.currency_exchange)).toFixed(2));
    }
    return amountRs;
  }
}
