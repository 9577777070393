/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingServiceRequestInfo } from './bookingServiceRequestInfo';


export interface BookingTravelerInfo { 
    address1?: string;
    address2?: string;
    adminFee?: { [key: string]: number; };
    adultType?: BookingTravelerInfo.AdultTypeEnum;
    baseFare?: number;
    baseTax?: number;
    bookingId?: number;
    bookingNumber?: string;
    bookingTransCode?: string;
    city?: string;
    country?: string;
    dob?: Date;
    documentExpiredDate?: Date;
    documentIssuedDate?: Date;
    documentIssuingCountry?: string;
    documentNumber?: string;
    documentType?: string;
    eTicketList?: { [key: string]: string; };
    email?: string;
    eticket?: string;
    firstName?: string;
    gender?: BookingTravelerInfo.GenderEnum;
    id?: number;
    memberCard?: boolean;
    memberCardExpiredDate?: string;
    memberCardNumber?: string;
    memberCardType?: string;
    nationality?: string;
    orderIdx?: number;
    paxFee?: number;
    personRepresentation?: boolean;
    phoneNumber1?: string;
    phoneNumber2?: string;
    phoneNumber3?: string;
    phoneNumber4?: string;
    phoneNumber5?: string;
    postalCode?: string;
    serviceRequests?: Array<BookingServiceRequestInfo>;
    surName?: string;
    travelerCode?: string;
}
export namespace BookingTravelerInfo {
    export type AdultTypeEnum = 'ADT' | 'CHD' | 'INF';
    export const AdultTypeEnum = {
        ADT: 'ADT' as AdultTypeEnum,
        CHD: 'CHD' as AdultTypeEnum,
        INF: 'INF' as AdultTypeEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'BOY' | 'GIRL' | 'INF';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        BOY: 'BOY' as GenderEnum,
        GIRL: 'GIRL' as GenderEnum,
        INF: 'INF' as GenderEnum
    };
}
