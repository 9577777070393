/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { OtaResultOfstring } from '../model/otaResultOfstring';
import { SeABankCommitReq } from '../model/seABankCommitReq';
import { SeABankMobileLoginReq } from '../model/seABankMobileLoginReq';
import { SeABankOrderDetailReq } from '../model/seABankOrderDetailReq';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SeABankResourceService {

    protected basePath = 'https://172.16.100.16:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * mobileLogin
     *
     * @param seABankMobileLoginReq seABankMobileLoginReq
     * @param type type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mobileLoginUsingPOST(seABankMobileLoginReq: SeABankMobileLoginReq, type: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mobileLoginUsingPOST(seABankMobileLoginReq: SeABankMobileLoginReq, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mobileLoginUsingPOST(seABankMobileLoginReq: SeABankMobileLoginReq, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mobileLoginUsingPOST(seABankMobileLoginReq: SeABankMobileLoginReq, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (seABankMobileLoginReq === null || seABankMobileLoginReq === undefined) {
            throw new Error('Required parameter seABankMobileLoginReq was null or undefined when calling mobileLoginUsingPOST.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling mobileLoginUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/sea/booking/request-url`,
            seABankMobileLoginReq,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onCommitBooking
     *
     * @param seABankCommitReq seABankCommitReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onCommitBookingUsingPOST(seABankCommitReq: SeABankCommitReq, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public onCommitBookingUsingPOST(seABankCommitReq: SeABankCommitReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public onCommitBookingUsingPOST(seABankCommitReq: SeABankCommitReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public onCommitBookingUsingPOST(seABankCommitReq: SeABankCommitReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (seABankCommitReq === null || seABankCommitReq === undefined) {
            throw new Error('Required parameter seABankCommitReq was null or undefined when calling onCommitBookingUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/sea/booking/commit`,
            seABankCommitReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onGetBookingDetail
     *
     * @param seABankOrderDetailReq seABankOrderDetailReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onGetBookingDetailUsingPOST(seABankOrderDetailReq: SeABankOrderDetailReq, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public onGetBookingDetailUsingPOST(seABankOrderDetailReq: SeABankOrderDetailReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public onGetBookingDetailUsingPOST(seABankOrderDetailReq: SeABankOrderDetailReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public onGetBookingDetailUsingPOST(seABankOrderDetailReq: SeABankOrderDetailReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (seABankOrderDetailReq === null || seABankOrderDetailReq === undefined) {
            throw new Error('Required parameter seABankOrderDetailReq was null or undefined when calling onGetBookingDetailUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/sea/booking/detail`,
            seABankOrderDetailReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateRequest
     *
     * @param user user
     * @param signature signature
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateRequestUsingPOST(user: string, signature: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public validateRequestUsingPOST(user: string, signature: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public validateRequestUsingPOST(user: string, signature: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public validateRequestUsingPOST(user: string, signature: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling validateRequestUsingPOST.');
        }

        if (signature === null || signature === undefined) {
            throw new Error('Required parameter signature was null or undefined when calling validateRequestUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (user !== undefined && user !== null) {
            queryParameters = queryParameters.set('user', <any>user);
        }
        if (signature !== undefined && signature !== null) {
            queryParameters = queryParameters.set('signature', <any>signature);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OtaResultOfstring>(`${this.basePath}/api/sea/booking/validate`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
