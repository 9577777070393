import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-loading-iframe-search-info',
  templateUrl: './loading-iframe-search-info.component.html',
  styleUrls: ['./loading-iframe-search-info.component.scss']
})
export class LoadingIframeSearchInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
