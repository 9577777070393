/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AgentProfileDTO } from '../model/agentProfileDTO';
import { AgentTO } from '../model/agentTO';
import { CheckObject } from '../model/checkObject';
import { ResponseEntity } from '../model/responseEntity';
import { TopupInfo } from '../model/topupInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AgentResourceService {

    protected basePath = 'https://uat-b2b.gotadi.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changeStatus
     * 
     * @param id id
     * @param agentTO agentTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeStatusUsingPUT1(id: number, agentTO: AgentTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeStatusUsingPUT1(id: number, agentTO: AgentTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeStatusUsingPUT1(id: number, agentTO: AgentTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeStatusUsingPUT1(id: number, agentTO: AgentTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling changeStatusUsingPUT1.');
        }

        if (agentTO === null || agentTO === undefined) {
            throw new Error('Required parameter agentTO was null or undefined when calling changeStatusUsingPUT1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/agents/${encodeURIComponent(String(id))}/status`,
            agentTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkAvailableEmail
     * 
     * @param checkEmail checkEmail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkAvailableEmailUsingPUT(checkEmail: CheckObject, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public checkAvailableEmailUsingPUT(checkEmail: CheckObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public checkAvailableEmailUsingPUT(checkEmail: CheckObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public checkAvailableEmailUsingPUT(checkEmail: CheckObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (checkEmail === null || checkEmail === undefined) {
            throw new Error('Required parameter checkEmail was null or undefined when calling checkAvailableEmailUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResponseEntity>(`${this.basePath}/api/agents/check-available-email`,
            checkEmail,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkAvailableUsername
     * 
     * @param checkUsername checkUsername
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkAvailableUsernameUsingPUT(checkUsername: CheckObject, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public checkAvailableUsernameUsingPUT(checkUsername: CheckObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public checkAvailableUsernameUsingPUT(checkUsername: CheckObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public checkAvailableUsernameUsingPUT(checkUsername: CheckObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (checkUsername === null || checkUsername === undefined) {
            throw new Error('Required parameter checkUsername was null or undefined when calling checkAvailableUsernameUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResponseEntity>(`${this.basePath}/api/agents/check-available-username`,
            checkUsername,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createAgent
     * 
     * @param agentTO agentTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgentUsingPOST(agentTO: AgentTO, observe?: 'body', reportProgress?: boolean): Observable<AgentTO>;
    public createAgentUsingPOST(agentTO: AgentTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentTO>>;
    public createAgentUsingPOST(agentTO: AgentTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentTO>>;
    public createAgentUsingPOST(agentTO: AgentTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentTO === null || agentTO === undefined) {
            throw new Error('Required parameter agentTO was null or undefined when calling createAgentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AgentTO>(`${this.basePath}/api/agents`,
            agentTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteAgent
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgentUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAgentUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAgentUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAgentUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAgentUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/agents/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentTopup
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentTopupUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<TopupInfo>;
    public getAgentTopupUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TopupInfo>>;
    public getAgentTopupUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TopupInfo>>;
    public getAgentTopupUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAgentTopupUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TopupInfo>(`${this.basePath}/api/agents/topups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgent
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<AgentTO>;
    public getAgentUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentTO>>;
    public getAgentUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentTO>>;
    public getAgentUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAgentUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentTO>(`${this.basePath}/api/agents/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentsByAgencyCodeLite
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentsByAgencyCodeLiteUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentProfileDTO>>;
    public getAgentsByAgencyCodeLiteUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentProfileDTO>>>;
    public getAgentsByAgencyCodeLiteUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentProfileDTO>>>;
    public getAgentsByAgencyCodeLiteUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getAgentsByAgencyCodeLiteUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgentProfileDTO>>(`${this.basePath}/api/agents-by-agency-lite`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentsByAgencyCode
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentsByAgencyCodeUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentTO>>;
    public getAgentsByAgencyCodeUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentTO>>>;
    public getAgentsByAgencyCodeUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentTO>>>;
    public getAgentsByAgencyCodeUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getAgentsByAgencyCodeUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgentTO>>(`${this.basePath}/api/agents-by-agency`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllAgents
     * 
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAgentsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentTO>>;
    public getAllAgentsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentTO>>>;
    public getAllAgentsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentTO>>>;
    public getAllAgentsUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgentTO>>(`${this.basePath}/api/agents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookerOrAdmins
     * 
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookerOrAdminsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentTO>>;
    public getBookerOrAdminsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentTO>>>;
    public getBookerOrAdminsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentTO>>>;
    public getBookerOrAdminsUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgentTO>>(`${this.basePath}/api/booker-or-admin`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getNewAgent
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNewAgentUsingGET(observe?: 'body', reportProgress?: boolean): Observable<AgentTO>;
    public getNewAgentUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentTO>>;
    public getNewAgentUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentTO>>;
    public getNewAgentUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentTO>(`${this.basePath}/api/agents/new-agent`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjects
     * 
     * @param agentName agentName
     * @param loginUsername loginUsername
     * @param phoneNumber phoneNumber
     * @param email email
     * @param branchCode branchCode
     * @param roleCode roleCode
     * @param status status
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectsUsingGET1(agentName?: string, loginUsername?: string, phoneNumber?: string, email?: string, branchCode?: string, roleCode?: string, status?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentTO>>;
    public searchObjectsUsingGET1(agentName?: string, loginUsername?: string, phoneNumber?: string, email?: string, branchCode?: string, roleCode?: string, status?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentTO>>>;
    public searchObjectsUsingGET1(agentName?: string, loginUsername?: string, phoneNumber?: string, email?: string, branchCode?: string, roleCode?: string, status?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentTO>>>;
    public searchObjectsUsingGET1(agentName?: string, loginUsername?: string, phoneNumber?: string, email?: string, branchCode?: string, roleCode?: string, status?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agentName !== undefined && agentName !== null) {
            queryParameters = queryParameters.set('agentName', <any>agentName);
        }
        if (loginUsername !== undefined && loginUsername !== null) {
            queryParameters = queryParameters.set('loginUsername', <any>loginUsername);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (branchCode !== undefined && branchCode !== null) {
            queryParameters = queryParameters.set('branchCode', <any>branchCode);
        }
        if (roleCode !== undefined && roleCode !== null) {
            queryParameters = queryParameters.set('roleCode', <any>roleCode);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgentTO>>(`${this.basePath}/api/_search/agents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * setPassword
     * 
     * @param id id
     * @param password password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPasswordUsingPUT(id: number, password: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public setPasswordUsingPUT(id: number, password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public setPasswordUsingPUT(id: number, password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public setPasswordUsingPUT(id: number, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setPasswordUsingPUT.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling setPasswordUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<string>(`${this.basePath}/api/agents/${encodeURIComponent(String(id))}/set-password`,
            password,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateAgent
     * 
     * @param agentTO agentTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAgentUsingPUT(agentTO: AgentTO, observe?: 'body', reportProgress?: boolean): Observable<AgentTO>;
    public updateAgentUsingPUT(agentTO: AgentTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentTO>>;
    public updateAgentUsingPUT(agentTO: AgentTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentTO>>;
    public updateAgentUsingPUT(agentTO: AgentTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentTO === null || agentTO === undefined) {
            throw new Error('Required parameter agentTO was null or undefined when calling updateAgentUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AgentTO>(`${this.basePath}/api/agents`,
            agentTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
