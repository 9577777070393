import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HotelFunctionService {
  constructor(public router: Router) {}

  getDayGap(departureDate: string, returnDate?: string): number {
    let numbDate = 0;
    if (departureDate && returnDate) {
      numbDate = this.datediff(
        this.parseDate(departureDate),
        this.parseDate(returnDate)
      );
    }
    return numbDate;
  }

  parseDate(str) {
    let mdy = str.split('-');
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  }
  datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  generateUrl(
    shortLinkId: string,
    masterPropertyId: string,
    propertyId: string,
    supplier: string
  ) {
    return  {
      sid: shortLinkId,
      mid: masterPropertyId,
      pid: propertyId,
      p: btoa(supplier)
    };
  }
}
