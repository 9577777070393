import { LoginNameAction, LoginNameActionTypes } from './login-name.actions';
import {AgentProfileDTO} from "@gtd/api-services/agentsrv";

export const LOGINNAME_FEATURE_KEY = 'loginName';

/**
 * Interface for the 'LoginName' data used in
 *  - LoginNameState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface LoginNameState {
  result: AgentProfileDTO; // list of LoginName; analogous to a sql normalized table
  selectedId?: string | number; // which LoginName record has been selected
  loaded: boolean; // has the LoginName list been loaded
  error?: any; // last none error (if any)
}

export interface LoginNamePartialState {
  readonly [LOGINNAME_FEATURE_KEY]: LoginNameState;
}

export const initialState: LoginNameState = {
  result: null,
  loaded: false
};

export function loginNameReducer(
  state: LoginNameState = initialState,
  action: LoginNameAction
): LoginNameState {
  switch (action.type) {
    case LoginNameActionTypes.LoginNameLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
