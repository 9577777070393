import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HistorySearchKeywordPartialState } from './history-search-keyword.reducer';
import { historySearchKeywordQuery } from './history-search-keyword.selectors';
import {AirportDTO} from "@gtd/meta-client";
import {
  HistorySearchKeywordFlightFrom,
  HistorySearchKeywordFlightTo
} from "./history-search-keyword.actions";
import {HotelMetaKeyword} from "@gtd/b2c-client";

@Injectable()
export class HistorySearchKeywordFacade {
  loaded$ = this.store.pipe(select(historySearchKeywordQuery.getLoaded));

  allHistorySearchKeywordFlight$ = this.store.pipe(
    select(historySearchKeywordQuery.getAllHistorySearchKeywordFlight)
  );

  allHistorySearchKeywordFlightFrom$ = this.store.pipe(
    select(historySearchKeywordQuery.getAllHistorySearchKeywordFlightFrom)
  );

  selectedHistorySearchKeywordFlightFrom$ = this.store.pipe(
    select(historySearchKeywordQuery.getSelectedHistorySearchKeywordFlightFrom)
  );



  constructor(private store: Store<HistorySearchKeywordPartialState>) {}

  addSearchKeywordFlightFrom(airportDTO: AirportDTO[]) {
    this.store.dispatch(new HistorySearchKeywordFlightFrom(airportDTO));
  }

  addSearchKeywordFlightTo(airportDTO: AirportDTO[]) {
    this.store.dispatch(new HistorySearchKeywordFlightTo(airportDTO));
  }

}
