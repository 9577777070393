import {
  Component,
  ElementRef, forwardRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import { FormPassengersService } from './form-passengers.service';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material';
import {AirportFacade} from "@gtd/meta";

@Component({
  selector: 'gtd-form-passengers',
  templateUrl: './form-passengers.component.html',
  styleUrls: ['./form-passengers.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    AirportFacade,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormPassengersComponent),
      multi: true
    }
  ]
})
export class FormPassengersComponent implements OnInit {
  @ViewChild('addPassengerDialog', { static: false })
  addPassengerDialog: TemplateRef<any>;
  passenger?: FormGroup;

  _totalPass = 9;
  minAdult = 1;
  maxAdult = 9;
  minChild = 0;
  maxChild = 8;
  minInfant = 0;
  maxInfant = 4;
  getDataSearchFlight;
  openMenu = false;
  addPassengerDialogRef: any;
  private triggerElementRef: ElementRef;
  currentAdultQuantity = 1;
  currentChildQuantity = 0;
  currentInfantCount = 0;

  constructor(
    private passengerService: FormPassengersService,
    private dialog: MatDialog
  ) {
    this.getDataSearchFlight = JSON.parse(
      localStorage.getItem('dataSearchFlight')
    );
    this.passenger = new FormGroup({
      adultQuantity: new FormControl(
        this.getDataSearchFlight === null ? 1 : this.getDataSearchFlight.adult,
        Validators.required
      ),
      childQuantity: new FormControl(
        this.getDataSearchFlight === null ? 0 : this.getDataSearchFlight.child,
        Validators.required
      ),
      infantCount: new FormControl(
        this.getDataSearchFlight === null ? 0 : this.getDataSearchFlight.infant,
        Validators.required
      )
    });
  }

  ngOnInit() {
    this.passenger.valueChanges.subscribe(valueChanges => {
      this.maxChild = this.getMaxChild(valueChanges);
      this.maxInfant = this.getMaxInfant(valueChanges);
      this.maxAdult = this.getMaxAdult(valueChanges);
      setTimeout(() => {
        this.refreshchildQuantity(valueChanges);
        this.refreshInfantCount(valueChanges);
        this.passengerService.setPassenger({
          adultQuantity: valueChanges.adultQuantity,
          childQuantity: valueChanges.childQuantity,
          infantCount: valueChanges.infantCount
        });
      });
    });
  }

  openAddPassengerDialog(evt: MouseEvent) {
    // const target = new ElementRef(evt.currentTarget);
    // this.triggerElementRef = target;
    // const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
    this.openMenu = true;
    this.currentAdultQuantity = this.passenger.get('adultQuantity').value;
    this.currentChildQuantity = this.passenger.get('childQuantity').value;
    this.currentInfantCount = this.passenger.get('infantCount').value;
    // this.addPassengerDialogRef = this.dialog.open(this.addPassengerDialog, {
    //   autoFocus: false,
    //   panelClass: 'add-passenger-dialog',
    //   position: { left: `${rect.left}px`, top: `${rect.bottom}px` }
    // });
  }

  closeAddPassengerDialog(isCompleteButton: boolean) {
    this.openMenu = false;
    if (!isCompleteButton) {
      this.passenger.patchValue({
        adultQuantity: this.currentAdultQuantity,
        childQuantity: this.currentChildQuantity,
        infantCount: this.currentInfantCount
      });
    }
    this.onChange(this.passenger.value);
    this.onTouched();
    // this.addPassengerDialogRef.close();
  }

  getMaxAdult(valueChanges): number {
    return this._totalPass - valueChanges.childQuantity;
  }

  getMaxChild(valueChanges): number {
    if (valueChanges.adultQuantity === 1) {
      return 4;
    } else {
      return this._totalPass - valueChanges.adultQuantity;
    }
  }

  getMaxInfant(valueChanges): number {
    return valueChanges.adultQuantity;
  }
  refreshchildQuantity(valueChanges) {
    if (valueChanges.childQuantity > valueChanges.adultQuantity * 4) {
      this.passenger.patchValue({
        childQuantity: 0
      });
    }
  }
  refreshInfantCount(valueChanges) {
    if (valueChanges.infantCount > valueChanges.adultQuantity) {
      this.passenger.patchValue({
        infantCount: 0
      });
    }
  }

  onChange: any = () => {
  };
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {}

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
