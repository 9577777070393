import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { HotelFacade, PopularPlacesFacade } from '@gtd/meta';
import { SearchKeywordElement } from '@gtd/b2c-client';
import { MatAutocompleteTrigger, MatFormFieldControl } from '@angular/material';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HotelSearchService } from '../hotel-search.service';
import { SearchKeywordFacade } from '../../../state/search-keyword/search-keyword.facade';
import { AutocompleteSearchKeywordFacade } from '../../../state/autocomplete-search-keyword/autocomplete-search-keyword.facade';
import { take } from 'rxjs/operators';

export interface InputField {
  stateCtrl: string;
  hotelName: string;
}

@Component({
  selector: 'gtd-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  providers: [
    HotelFacade,
    {
      provide: MatFormFieldControl,
      useExisting: AutoCompleteComponent,
      multi: true
    }
  ]
})

export class AutoCompleteComponent implements OnInit, OnChanges, MatFormFieldControl<InputField>, OnDestroy {
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatAutocompleteTrigger, { static: false })
  autoSearchKeyword: MatAutocompleteTrigger;
  @Input() label = 'Label';
  @Input() placeholder = 'Placeholder';
  @Input() iconLeft?: string;
  @Input() type: any;
  @Input() hotelLocation?: string;
  @HostBinding() id = 'gtd-auto-compete-${Test.nextId++}';
  @HostBinding('attr.aria-describedby') describedBy = '';

  private _disabled = false;
  private _required = false;

  form: FormGroup;
  focused = false;
  isShiftFocus: boolean;
  stateCtrl = new FormControl();
  hotelName = new FormControl();
  stateChanges = new Subject<void>();
  groupSearchLabel: string = 'destination-popular';
  destinationDTOs: SearchKeywordElement[];
  errorState = false;
  isContentPage = false;

  allSearchKeyword$ = this.searchKeywordFacade.allSearchKeyword$;
  allPopularPlaces$ = this.popularPlacesFacade.allPopularPlaces$;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fb: FormBuilder,
    private elRef: ElementRef<HTMLElement>,
    private hotelSearchService: HotelSearchService,
    private searchKeywordFacade: SearchKeywordFacade,
    private popularPlacesFacade: PopularPlacesFacade,
    private autocompleteSearchKeywordFacade: AutocompleteSearchKeywordFacade
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.hotelLocation && !changes.hotelLocation.firstChange && changes.hotelLocation.currentValue) {
      this.isContentPage = true;
      this.searchKeywordFacade.loadAll(changes.hotelLocation.currentValue, "vi", 0, 15);
      this.searchKeywordFacade.allSearchKeyword$
        .pipe(take(2))
        .subscribe(allSearchKeyword => {
          if (allSearchKeyword && allSearchKeyword.result) {
            this.stateCtrl.setValue(allSearchKeyword.result.contents[0]);
          }
      });
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      stateCtrl: '',
      hotelName: ''
    });
    this.popularPlacesFacade.getPopularDestination('FAVOURITE_HOTEL_LOCATION');
    this.destinationDTOs = this.hotelSearchService.getAllLocalStorageLocation(this.type);

    this.stateCtrl.valueChanges.subscribe((data: any) => {
      if (typeof data === 'string') {
        this.searchKeywordFacade.loadAll(data, "vi", 0, 15);
      }
      if(data || data !== '') {
        this.groupSearchLabel = 'list-destination';
        if (!data.searchCode) {
          let searchData = {
            name: data,
            propertyCount: null,
            searchCode: data,
            searchType: 'AUTO',
            supplier: 'EXPEDIA'
          };
          this.onTouched();
          this.onChange(searchData);
        } else {
          this.onChange(data);
          this.onTouched();
        }
      }
    });
  }

  keyEnter() {
    if(!this.isShiftFocus) {
      this.changed.emit({
        submit: true
      });
    }
  }
  shiftFocus(isOpen) {
    if(isOpen) {
      this.isShiftFocus = true;
    } else {
      this.isShiftFocus = false;
    }
  }

  onSearchKeydown(event: KeyboardEvent) {
    if (this.isContentPage === true && this.stateCtrl.value !== '') {
      this.isContentPage = false;
    }
  }

  displayFn(hotel?: SearchKeywordElement): string | undefined {
    return hotel ? `${hotel.name}` : undefined;
  }

  @Input()
  get value(): InputField {
    let n = this.form.value;
    return n;
  }
  set value(sf: InputField) {
    setTimeout(() => {
      this.setDefaultHotel(sf);
    }, 100);
  }
  setDefaultHotel = (sf: InputField) => {
    setTimeout(() => {
      let getHotelLocation = this.hotelSearchService.getAllLocalStorageLocation(
        this.type
      );
      if (getHotelLocation) {
        let hotelLocation = getHotelLocation.filter(
          code => code.name === sf.toLocaleString()
        );
        if (
          hotelLocation &&
          hotelLocation[0] &&
          hotelLocation[0].name === sf.toLocaleString()
        ) {
          this.stateCtrl.setValue(hotelLocation[0]);
          this.autocompleteSearchKeywordFacade.addSearchKeywordHotel(
            hotelLocation
          );
        }
      }
      this.stateChanges.next();
    });
  };

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }

  getOptionSelect(value: SearchKeywordElement) {
    this.autocompleteSearchKeywordFacade.addSearchKeywordHotel([value]);
    setTimeout(() => {
      this.isShiftFocus = false;
    }, 200);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  getOptionSelectPopular(value: string) {
    this.form.patchValue({ hotelName: value });
    let searchData = {
      name: value,
      propertyCount: null,
      searchCode: value,
      searchType: 'AUTO',
      supplier: 'EXPEDIA'
    };
    this.onTouched();
    this.onChange(searchData);
  }

  get empty() {
    let n = this.form.value;
    return !n.area && !n.exchange && !n.subscriber;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
}
