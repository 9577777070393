import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ACTIVATEACCOUNT_FEATURE_KEY,
  ActivateAccountState
} from './activate-account.reducer';

// Lookup the 'ActivateAccount' feature state managed by NgRx
const getActivateAccountState = createFeatureSelector<ActivateAccountState>(
  ACTIVATEACCOUNT_FEATURE_KEY
);

const getLoaded = createSelector(
  getActivateAccountState,
  (state: ActivateAccountState) => state.loaded
);
const getError = createSelector(
  getActivateAccountState,
  (state: ActivateAccountState) => state.error
);

const getAllActivateAccount = createSelector(
  getActivateAccountState,
  getLoaded,
  (state: ActivateAccountState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getActivateAccountState,
  (state: ActivateAccountState) => state.selectedId
);
const getSelectedActivateAccount = createSelector(
  getAllActivateAccount,
  getSelectedId,
  (activateAccount, id) => {
    return activateAccount ? Object.assign({}, activateAccount) : undefined;
  }
);

export const activateAccountQuery = {
  getLoaded,
  getError,
  getAllActivateAccount,
  getSelectedActivateAccount
};
