import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material';
import { CancelAdditionalRequestDialogComponent } from '../cancel-additional-request-dialog/cancel-additional-request-dialog.component';
import { AdditionalRequestResourceService } from '@gtd/b2c-client';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { Subscription } from 'rxjs';
import { DialogMessageComponent } from '@gtd/components/dialog-message';

@Component({
  selector: 'gtd-additional-request-detail',
  templateUrl: './additional-request-detail.component.html',
  styleUrls: ['./additional-request-detail.component.scss']
})
export class AdditionalRequestDetailComponent implements OnInit {
  requestTypeName = '';
  subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AdditionalRequestDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      additionalRequestItem: any;
    },
    private _snackBar: MatSnackBar,
    private additionalRequestResourceService: AdditionalRequestResourceService
  ) {}

  ngOnInit() {
    if (this.data && this.data.additionalRequestItem) {
      switch (this.data.additionalRequestItem.requestType) {
        case 'AIR_ADD_PACKAGE': {
          this.requestTypeName = 'booking.buy-signed-luggage-for-ticket';
          break;
        }
        case 'AIR_ADD_INFORMATION': {
          this.requestTypeName = 'booking.change-information-on-ticket';
          break;
        }
        case 'AIR_DELETE_BOOKING': {
          this.requestTypeName = 'booking.refund-cancellation-ticket';
          break;
        }
        case 'HOTEL_ADD_PERSON': {
          this.requestTypeName = 'booking.more-people';
          break;
        }
        case 'HOTEL_EDIT_BOOKING': {
          this.requestTypeName = 'booking.change-room-information';
          break;
        }
        case 'HOTEL_DELETE_BOOKING': {
          this.requestTypeName = 'booking.refund-cancellation-room';
          break;
        }
        case 'HOTEL_CHANGE_ROOM': {
          this.requestTypeName = 'booking.change-room';
          break;
        }
        default: {
          this.requestTypeName = 'booking.other-requirement';
          break;
        }
      }
    }
  }

  cancelRequest() {
    const dialogRef = this.dialog.open(CancelAdditionalRequestDialogComponent, {
      data: this.data.additionalRequestItem.requestTypeName,
      position: {
        right: '100px',
        top: '100px'
      },
      autoFocus: true,
      panelClass: ['cancel-request-dialog-panel-class'],
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.action === 'cancel-request') {
        let cancelRequestSnackBar = this._snackBar.openFromComponent(
          SnackbarComponent,
          {
            data: {
              message: 'additional-request.snack-bar-loading',
              loading: true
            },
            panelClass: 'snackbar-loading',
            verticalPosition: 'top'
          }
        );
        let bookingAdditionalRequestDTO: any = {
          bookingCode: this.data.additionalRequestItem.bookingCode,
          bookingDescription: this.data.additionalRequestItem
            .bookingDescription,
          bookingId: this.data.additionalRequestItem.bookingId,
          bookingNumber: this.data.additionalRequestItem.bookingNumber,
          createdBy: this.data.additionalRequestItem.createdBy,
          createdByAgent: this.data.additionalRequestItem.createdByAgent,
          createdByAgentId: this.data.additionalRequestItem.createdByAgentId,
          createdByNotes: this.data.additionalRequestItem.createdByNotes,
          createdDate: this.data.additionalRequestItem.createdDate,
          id: this.data.additionalRequestItem.id,
          notes: 'Khách hàng hủy yêu cầu',
          passengerNameRecords: this.data.additionalRequestItem
            .passengerNameRecords,
          requestContent: this.data.additionalRequestItem.requestContent,
          requestDate: this.data.additionalRequestItem.requestDate,
          requestType: this.data.additionalRequestItem.requestType,
          status: 'CANCELLED',
          updatedBy: null,
          updatedDate: null
        };
        this.subscriptions.add(
          this.additionalRequestResourceService
            .updateAdditionalRequestUsingPUT(bookingAdditionalRequestDTO)
            .subscribe(
              () => {
                cancelRequestSnackBar.dismiss();
                this.openNotificationSnackBar(
                  'additional-request.cancel-additional-request-succeeded',
                  'check',
                  'snackbar-success'
                );
                this.dialogRef.close({ action: 'cancel-request' });
              },
              () => {
                cancelRequestSnackBar.dismiss();
                this.dialog.open(DialogMessageComponent, {
                  data: {
                    btn: [{ btnText: 'global.close' }],
                    content: 'user.system-error-message',
                    heading:
                      'additional-request.cancel-additional-request-failed',
                    icon: 'exclamation-triangle',
                    messageType: 'error'
                  },
                  panelClass: 'dialog-message'
                });
              }
            )
        );
      }
    });
  }

  openNotificationSnackBar(message: string, icon: string, panelClass: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: icon
      },
      panelClass: panelClass,
      verticalPosition: 'top',
      duration: 2000
    });
  }

  closeDialog() {
    this.dialogRef.close({ action: '' });
  }
}
