import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BANNER_FEATURE_KEY, BannerState } from './banner.reducer';

// Lookup the 'Banner' feature state managed by NgRx
const getBannerState = createFeatureSelector<BannerState>(BANNER_FEATURE_KEY);

const getLoaded = createSelector(
  getBannerState,
  (state: BannerState) => state.loaded
);
const getError = createSelector(
  getBannerState,
  (state: BannerState) => state.error
);

const getAllBanner = createSelector(
  getBannerState,
  getLoaded,
  (state: BannerState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getBannerState,
  (state: BannerState) => state.selectedId
);
const getSelectedBanner = createSelector(
  getAllBanner,
  getSelectedId,
  (banner, id) => {
    const result = banner;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const bannerQuery = {
  getLoaded,
  getError,
  getAllBanner,
  getSelectedBanner
};
