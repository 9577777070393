import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { TourSearchByKeywordPartialState } from './tour-search-by-keyword.reducer';
import {
  LoadTourSearchByKeyword,
  TourSearchByKeywordLoaded,
  TourSearchByKeywordLoadError,
  TourSearchByKeywordActionTypes
} from './tour-search-by-keyword.actions';
import { TourResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class TourSearchByKeywordEffects {
  @Effect() loadTourSearchByKeyword$ = this.dataPersistence.fetch(
    TourSearchByKeywordActionTypes.LoadTourSearchByKeyword,
    {
      run: (
        action: LoadTourSearchByKeyword,
        state: TourSearchByKeywordPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.tourResourceService
          .searchKeyWordUsingGET1(
            action.payload.keyword,
            action.payload.saleChannel
            // action.payload.pageNumber,
            // action.payload.pageSize
          )
          .pipe(
            map(payload => ({
              type: TourSearchByKeywordActionTypes.TourSearchByKeywordLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadTourSearchByKeyword, error) => {
        console.error('Error', error);
        return new TourSearchByKeywordLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<TourSearchByKeywordPartialState>,
    private tourResourceService: TourResourceService
  ) {}
}
