/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExtentInfo } from './extentInfo';


export interface PaymentBookingReqV2 {
    amount?: number;
    appReturnUrl?: string;
    bookingCode?: string;
    bookingNumber?: string;
    extentInfo?: ExtentInfo;
    paymentType?: PaymentBookingReqV2.PaymentTypeEnum;
}
export namespace PaymentBookingReqV2 {
    export type PaymentTypeEnum = 'BALANCE' | 'CREDIT' | 'ATM_DEBIT' | 'AIRPAY' | 'VNPAYQR'| 'VNPAY_CREDIT' | 'VIETTELPAY' | 'MOMO' | 'ZALO' | 'PAYOO' | 'CASH' | 'TRANSFER' | 'PARTNER' | 'VPBANK' | 'OTHER';
    export const PaymentTypeEnum = {
        BALANCE: 'BALANCE' as PaymentTypeEnum,
        CREDIT: 'CREDIT' as PaymentTypeEnum,
        ATMDEBIT: 'ATM_DEBIT' as PaymentTypeEnum,
        AIRPAY: 'AIRPAY' as PaymentTypeEnum,
        VNPAYQR: 'VNPAYQR' as PaymentTypeEnum,
        VNPAY_CREDIT: 'VNPAY_CREDIT' as PaymentTypeEnum,
        VIETTELPAY: 'VIETTELPAY' as PaymentTypeEnum,
        MOMO: 'MOMO' as PaymentTypeEnum,
        ZALO: 'ZALO' as PaymentTypeEnum,
        PAYOO: 'PAYOO' as PaymentTypeEnum,
        CASH: 'CASH' as PaymentTypeEnum,
        TRANSFER: 'TRANSFER' as PaymentTypeEnum,
        PARTNER: 'PARTNER' as PaymentTypeEnum,
        VPBANK: 'VPBANK' as PaymentTypeEnum,
        OTHER: 'OTHER' as PaymentTypeEnum
    };
}
