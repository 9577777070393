import {createFeatureSelector, createSelector} from '@ngrx/store';
import {POPULARPLACES_FEATURE_KEY, PopularPlacesState} from './popular-places.reducer';
import {Destination, HotelMetaKeyword, SearchKeywordElement} from "@gtd/b2c-client";
import {PropertyValueDTO} from "@gtd/meta-client";

// Lookup the 'PopularPlaces' feature state managed by NgRx
const getPopularPlacesState = createFeatureSelector<PopularPlacesState>(
  POPULARPLACES_FEATURE_KEY
);

const getLoaded = createSelector(
  getPopularPlacesState,
  (state: PopularPlacesState) => state.loaded
);
const getError = createSelector(
  getPopularPlacesState,
  (state: PopularPlacesState) => state.error
);

const getAllPopularPlaces = createSelector(
  getPopularPlacesState,
  getLoaded,
  (state: PopularPlacesState, isLoaded) => {
    return isLoaded ? Mapping.prototype.mappingDestination(state.result.result.propertyValues) : null;
  }
);
const getSelectedId = createSelector(
  getPopularPlacesState,
  (state: PopularPlacesState) => state.selectedId
);
const getSelectedPopularPlaces = createSelector(
  getAllPopularPlaces,
  getSelectedId,
  (popularPlaces, id) => {
    return popularPlaces ? Object.assign({}, popularPlaces) : undefined;
  }
);

export const popularPlacesQuery = {
  getLoaded,
  getError,
  getAllPopularPlaces,
  getSelectedPopularPlaces
};

export class Mapping {
  mappingDestination(destinations: Array<PropertyValueDTO>): Array<SearchKeywordElement> {
    destinations.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
    return destinations.map((destination: PropertyValueDTO) => {
      return {
        name: destination.value,
        propertyCount: null,
        searchCode: destination.value,
        searchType: "AUTO",
        supplier: "EXPEDIA"
      };
    });
  }
}
