/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TechcomCustomer } from './techcomCustomer';


export interface TechcomExchangeCurrency {
    agencyCode?: string;
    airlineCode?: string;
    airlineName?: string;
    bookingNumber?: string;
    countryCodeDes?: string;
    countryNameDes?: string;
    departureDate?: string;
    gtdBookingStatus?: string;
    gtdCustomerId?: string;
    pnr?: string;
    groupOrder?: boolean;
    status?: TechcomExchangeCurrency.StatusEnum;
    tcbCreatedDate?: string;
    tcbCustomerListStatus?: string;
    techcomCustomers?: Array<TechcomCustomer>;
}
export namespace TechcomExchangeCurrency {
    export type StatusEnum = 'PENDING' | 'SUCCESS' | 'FAIL';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        SUCCESS: 'SUCCESS' as StatusEnum,
        FAIL: 'FAIL' as StatusEnum
    };
}
