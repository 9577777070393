import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  COUNTUNREAD_FEATURE_KEY,
  CountUnreadState
} from './count-unread.reducer';

// Lookup the 'CountUnread' feature state managed by NgRx
const getCountUnreadState = createFeatureSelector<CountUnreadState>(
  COUNTUNREAD_FEATURE_KEY
);

const getLoaded = createSelector(
  getCountUnreadState,
  (state: CountUnreadState) => state.loaded
);
const getError = createSelector(
  getCountUnreadState,
  (state: CountUnreadState) => state.error
);

const getAllCountUnread = createSelector(
  getCountUnreadState,
  getLoaded,
  (state: CountUnreadState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getCountUnreadState,
  (state: CountUnreadState) => state.selectedId
);
const getSelectedCountUnread = createSelector(
  getAllCountUnread,
  getSelectedId,
  (countUnread, id) => {
    return countUnread ? Object.assign({}, countUnread) : undefined;
  }
);

export const countUnreadQuery = {
  getLoaded,
  getError,
  getAllCountUnread,
  getSelectedCountUnread
};
