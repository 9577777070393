import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbBarComponent } from './breadcrumb-bar.component';
import {MatIconModule} from "@angular/material";
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, TranslateModule],
  declarations: [BreadcrumbBarComponent],
  exports: [BreadcrumbBarComponent]
})
export class BreadcrumbBarModule {}
