import { Action } from '@ngrx/store';
import { HeaderSummaryTO } from '../../lib';

export enum GetHeaderSummaryActionTypes {
  LoadGetHeaderSummary = '[GetHeaderSummary] Load GetHeaderSummary',
  ResetGetHeaderSummary = '[GetHeaderSummary] Reset GetHeaderSummary',
  GetHeaderSummaryLoaded = '[GetHeaderSummary] GetHeaderSummary Loaded',
  GetHeaderSummaryLoadError = '[GetHeaderSummary] GetHeaderSummary Load Error'
}

export class LoadGetHeaderSummary implements Action {
  readonly type = GetHeaderSummaryActionTypes.LoadGetHeaderSummary;
}

export class ResetGetHeaderSummary implements Action {
  readonly type = GetHeaderSummaryActionTypes.ResetGetHeaderSummary;
}

export class GetHeaderSummaryLoadError implements Action {
  readonly type = GetHeaderSummaryActionTypes.GetHeaderSummaryLoadError;
  constructor(public payload: any) {}
}

export class GetHeaderSummaryLoaded implements Action {
  readonly type = GetHeaderSummaryActionTypes.GetHeaderSummaryLoaded;
  constructor(public payload: HeaderSummaryTO) {}
}

export type GetHeaderSummaryAction =
  | LoadGetHeaderSummary
  | GetHeaderSummaryLoaded
  | GetHeaderSummaryLoadError
  | ResetGetHeaderSummary;

export const fromGetHeaderSummaryActions = {
  LoadGetHeaderSummary,
  GetHeaderSummaryLoaded,
  GetHeaderSummaryLoadError,
  ResetGetHeaderSummary
};
