import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { AvailableFlightsFacade } from '../flight-search/flight-cache-search/+state/available-flights.facade';
import { FlightFilterFacade } from '../flight-search/flight-filter/+state/flight-filter.facade';

@Component({
  selector: 'gtd-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PaginationComponent implements OnInit, OnDestroy, OnChanges {
  allFlightSearch$ = this.availableFlightsFacade.allAvailableFlights$;
  loaded$ = this.availableFlightsFacade.loaded$;
  @Input() selected = 0;
  @Input() resetSelected = 0;
  constructor(
    private availableFlightsFacade: AvailableFlightsFacade,
    private flightFilterFacade: FlightFilterFacade
  ) {}

  ngOnInit() {}

  counter(i: number) {
    return new Array(i);
  }

  pageClicked(page: number) {
    this.selected = page;
    this.pageChanged();
  }

  pageChanged() {
    this.flightFilterFacade.changePage(this.selected);
  }

  goFirst() {
    this.selected = 0;
    this.pageChanged();
  }

  goLast(last: number) {
    this.selected = last - 1;
    this.pageChanged();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resetSelected && !changes.resetSelected.firstChange) {
      this.selected = 0;
    }
  }
  ngOnDestroy(): void {}
}
