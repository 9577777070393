import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { AuthenticateService, Credentials } from '@gtd/auth';
import { isPlatformBrowser } from '@angular/common';
import { catchError, map } from 'rxjs/operators';

const credentialsKey = 'credentials';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  private _credentials: Credentials | null = null;

  constructor(
    private authenticationService: AuthenticateService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // add authorization header with basic auth credentials if available
    if (
      isPlatformBrowser(this.platformId) &&
      request.url.indexOf('wp-json') === -1 &&
      request.url.indexOf('.json') === -1
    ) {
      const savedCredentials =
        sessionStorage.getItem(credentialsKey) ||
        localStorage.getItem(credentialsKey);
      const credentialsGuest = localStorage.getItem('credentials-guest');
      const rClientTracking = localStorage.getItem('client-tracking');
      if (rClientTracking) {
        if (savedCredentials) {
          this._credentials = JSON.parse(savedCredentials);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this._credentials.token}`,
              'gtd-client-tracking-device-id': rClientTracking
            }
          });
        } else if (credentialsGuest) {
          request = request.clone({
            setHeaders: {
              Authorization: credentialsGuest,
              'gtd-client-tracking-device-id': rClientTracking
            }
          });
        } else {
          request = request.clone({
            setHeaders: {
              'gtd-client-tracking-device-id': rClientTracking
            }
          });
        }
      } else {
        if (savedCredentials) {
          this._credentials = JSON.parse(savedCredentials);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this._credentials.token}`
            }
          });
        } else if (credentialsGuest) {
          request = request.clone({
            setHeaders: {
              Authorization: credentialsGuest
            }
          });
        }
      }
    }
    return next.handle(request).pipe(
      catchError((err, caught) => {
        if (err.status === 417) {
          sessionStorage.removeItem(credentialsKey);
          localStorage.removeItem(credentialsKey);
          localStorage.removeItem('credentials-guest');
          localStorage.removeItem('client-tracking');
          location.reload();
        }
        throw err;
      })
    );
  }
}
