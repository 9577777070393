import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {ShortProfilePartialState} from './short-profile.reducer';
import {LoadShortProfile, ShortProfileActionTypes, ShortProfileLoadError} from './short-profile.actions';
import {map} from "rxjs/operators";
import {CustomerResourceService} from "@gtd/b2c-client";

@Injectable()
export class ShortProfileEffects {
  @Effect() loadShortProfile$ = this.dataPersistence.fetch(
    ShortProfileActionTypes.LoadShortProfile,
    {
      run: (action: LoadShortProfile, state: ShortProfilePartialState) => {
        return this.customerResourceService.getShortProfileUsingGET().pipe(
          map(update => ({
            type: ShortProfileActionTypes.ShortProfileLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadShortProfile, error) => {
        console.error('Error', error);
        return new ShortProfileLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private customerResourceService: CustomerResourceService,
    private dataPersistence: DataPersistence<ShortProfilePartialState>
  ) {}
}
