import {
  GetHeaderSummaryAction,
  GetHeaderSummaryActionTypes
} from './get-header-summary.actions';
import { HeaderSummaryTO } from '../../lib';

export const GETHEADERSUMMARY_FEATURE_KEY = 'getHeaderSummary';

/**
 * Interface for the 'GetHeaderSummary' data used in
 *  - GetHeaderSummaryState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

export interface GetHeaderSummaryState {
  list: HeaderSummaryTO; // list of GetHeaderSummary; analogous to a sql normalized table
  selectedId?: string | number; // which GetHeaderSummary record has been selected
  loaded: boolean; // has the GetHeaderSummary list been loaded
  error?: any; // last none error (if any)
}

export interface GetHeaderSummaryPartialState {
  readonly [GETHEADERSUMMARY_FEATURE_KEY]: GetHeaderSummaryState;
}

export const initialStateSummary: GetHeaderSummaryState = {
  list: null,
  loaded: false
};

export function reducerSummary(
  state: GetHeaderSummaryState = initialStateSummary,
  action: GetHeaderSummaryAction
): GetHeaderSummaryState {
  switch (action.type) {
    case GetHeaderSummaryActionTypes.GetHeaderSummaryLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case GetHeaderSummaryActionTypes.ResetGetHeaderSummary: {
      state = {
        ...initialStateSummary
      };
      break;
    }
  }
  return state;
}
