import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { LowestPriceFlightsPartialState } from './lowest-price-flights.reducer';
import { lowestPriceFlightsQuery } from './lowest-price-flights.selectors';
import {
  ComboVJLoadLowestPriceFlights,
  ComboVJResetLowestPriceFlights
} from './lowest-price-flights.actions';
import { AirLowFareFilterVM } from '@gtd/b2c-client';

@Injectable()
export class LowestPriceFlightsFacade {
  loaded$ = this.store.pipe(select(lowestPriceFlightsQuery.getLoaded));
  allLowestPriceFlights$ = this.store.pipe(
    select(lowestPriceFlightsQuery.getAllLowestPriceFlights)
  );
  selectedLowestPriceFlights$ = this.store.pipe(
    select(lowestPriceFlightsQuery.getSelectedLowestPriceFlights)
  );

  constructor(private store: Store<LowestPriceFlightsPartialState>) {}

  loadAll(filter: AirLowFareFilterVM) {
    this.store.dispatch(new ComboVJLoadLowestPriceFlights(filter));
  }

  resetAll() {
    this.store.dispatch(new ComboVJResetLowestPriceFlights());
  }
}
