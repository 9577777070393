import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { AuthenticateService } from '../authenticate/authenticate.service';
import { AuthenticateFacade } from '../authenticate/+state/authenticate.facade';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { Subscription } from 'rxjs';
import { PixelTrackingService } from '@gtd/pixel-tracking';
import { GaTrackingService } from '@gtd/ga-tracking';
import {AccountService} from "@gtd/b2c-client";
import {ShortProfileFacade} from "@gtd/api-services/customer-service";

@Component({
  selector: 'gtd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  hide = true;
  isLoading = false;
  loginForm!: FormGroup;
  selectedAuthenticate$;
  subscriptions = new Subscription();
  redirectParams;
  redirectParamEncode;
  appName = localStorage.getItem('appName');
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticateService,
    private authenticateFacade: AuthenticateFacade,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private accountService: AccountService,
    private shortProfileFacade: ShortProfileFacade,
    private _pixelTrackingService: PixelTrackingService,
    private _gaTrackingService: GaTrackingService
  ) {
    this.createForm();
    this.activatedRoute.queryParams.subscribe(param => {
      if (param && param.params) {
        this.redirectParams = JSON.parse(
          decodeURIComponent(atob(param.params))
        );
        this.redirectParamEncode = param.params
      }
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.authenticateFacade.selectedAuthenticate$.subscribe(
        results => {
          this.selectedAuthenticate$ = results;
          if (this.selectedAuthenticate$.isAuthenticated !== null) {
            if (this.selectedAuthenticate$.isAuthenticated === true) {
              this.authenticationService.storeAuthenticationToken(
                this.selectedAuthenticate$.user
              );
              this._snackBar.openFromComponent(SnackbarComponent, {
                data: { message: 'user.login-success', icon: 'check' },
                panelClass: 'snackbar-success',
                verticalPosition: 'top',
                duration: 2000
              });
              this._gaTrackingService.track('login_success');
              this.accountService.getAccountUsingGet().subscribe(_ => {
                if(this.appName != 'B2B') {
                  this.shortProfileFacade.loadAll();
                }
              });
              // this.filumAnalytics.identifyUser({
              //   user_id: this.loginForm.controls['username'].value,
              //   email: this.loginForm.controls['username'].value
              // });
              if (this.redirectParams && this.redirectParams.urlRedirect) {
                window.open(this.redirectParams.urlRedirect, '_self');
              } else {
                this.router.navigate(['/']);
              }
            } else {
              if (this.selectedAuthenticate$.errorCode === 104) {
                this.loginForm.controls['username'].setErrors(['exits']);
              } else if (this.selectedAuthenticate$.errorCode === 100) {
                this.loginForm.controls['password'].setErrors([
                  'password_invalid'
                ]);
              } else if (this.selectedAuthenticate$.errorCode === 105) {
                this.loginForm.controls['username'].setErrors([
                  'not_activated'
                ]);
              } else if (this.selectedAuthenticate$.errorCode === 400) {
                this.dialog.open(DialogMessageComponent, {
                  data: {
                    btn: [{ btnText: 'global.close' }],
                    content: 'user.message-login-error',
                    heading: 'user.heading-login-error',
                    icon: 'exclamation-triangle',
                    messageType: 'error'
                  },
                  panelClass: 'dialog-message'
                });
              } else {
                this.dialog.open(DialogMessageComponent, {
                  data: {
                    btn: [{ btnText: 'global.close' }],
                    content: 'user.system-error-message',
                    heading: 'user.system-error',
                    icon: 'exclamation-triangle',
                    messageType: 'error'
                  },
                  panelClass: 'dialog-message',
                  disableClose: true
                });
              }
              this.isLoading = false;
              this._snackBar.openFromComponent(SnackbarComponent, {
                data: {
                  message: 'user.login-failed',
                  icon: 'exclamation-triangle'
                },
                panelClass: 'snackbar-error',
                verticalPosition: 'top',
                duration: 1500
              });
            }
          }
        },
        error1 => {
          console.log('error login');
          console.log(error1);
        }
      )
    );
  }

  submitForm() {
    this._pixelTrackingService.trackCustom('Login');
    this.isLoading = true;
    this.authenticationService.login(this.loginForm.value);
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: { message: 'user.logging-in', loading: true },
      panelClass: 'snackbar-loading',
      verticalPosition: 'top'
    });
  }

  getErrorMessage() {
    return this.loginForm.controls['username'].hasError('required')
      ? 'user.email-phone-can-not-be-empty'
      : this.loginForm.controls['username'].hasError('email')
      ? 'user.email-phone-invalid'
      : this.loginForm.controls['username'].errors !== null &&
        this.loginForm.controls['username'].errors[0] === 'exits'
      ? 'user.email-phone-not-exist'
      : this.loginForm.controls['username'].errors !== null &&
        this.loginForm.controls['username'].errors[0] === 'not_activated'
      ? 'user.account-is-not-activated'
      : this.loginForm.controls['password'].hasError('required')
      ? 'user.password-not-empty'
      : this.loginForm.controls['password'].errors !== null &&
        this.loginForm.controls['password'].errors[0] === 'password_invalid'
      ? 'user.password-incorrect'
      : '';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: true
    });
  }
}
