import { Component, OnInit } from '@angular/core';
import { GaTrackingService } from '@gtd/ga-tracking';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'gtd-app-download-section',
  templateUrl: './app-download-section.component.html',
  styleUrls: ['./app-download-section.component.scss']
})
export class AppDownloadSectionComponent implements OnInit {
  appDownload: any;

  defaultImage = './assets/images/homepage/Spin-1s.svg';
  images = `'assets/images/homepage/phone.png'`;

  constructor(private _gaTrackingService: GaTrackingService) {
    this.appDownload = environment.downloadApp;
  }

  ngOnInit() {}
  track(eventName: string) {
    this._gaTrackingService.track(eventName);
  }
}
