import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'gtd-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})
export class RoomsComponent implements OnInit {
  @Input() arrayName: string;
  @Input() roomArray: any;
  @Input() submited = false;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  openMenu = false;
  public rooms: FormGroup;

  minAdult = 1;
  maxAdult = 30;
  minChild = 0;
  maxChild = 10;

  totalAdult: number = 2;
  totalChild: number = 0;

  counter = Array;
  currentFormArr = [];

  constructor(private _fb: FormBuilder) {
    this.rooms = new FormGroup({
      room: this._fb.array([])
    });
  }

  ngOnInit() {
    this.rooms.valueChanges.subscribe(valueChane => {
      if (valueChane && valueChane.room) {
        this.totalAdult = this.formArr.value.reduce(
          (prev, next) => prev + +next.adultQuantity,
          0
        );
        this.totalChild = this.formArr.value.reduce(
          (prev, next) => prev + +next.childQuantity,
          0
        );
        this.formArr.value.forEach((item, index) => {
          if (item.childQuantity > item.childrenAges.length) {
            this.addAgesField(index);
          }
          if (item.childQuantity < item.childrenAges.length) {
            this.removeAgesField(index, item.childrenAges.length - 1);
          }
        });
        this.changed.emit({
          rooms: this.rooms.value,
          valid: this.rooms.valid
        });
      }
    });
    if (this.roomArray) {
      this.roomArray.forEach(roomArr => {
        this.room.push(
          this._fb.group({
            adultQuantity: roomArr.adultQuantity,
            childQuantity: roomArr.childQuantity,
            childrenAges: this.setRooms(roomArr.childrenAges)
          })
        );
      });
    }
  }

  removeAgesField(index: number, itemAge: number) {
    const control = (<FormArray>this.rooms.controls['room'])
      .at(index)
      .get('childrenAges') as FormArray;
    control.removeAt(itemAge);
  }

  addAgesField(index: number, value?: number) {
    const control = (<FormArray>this.rooms.controls['room'])
      .at(index)
      .get('childrenAges') as FormArray;
    control.push(new FormControl(value, Validators.required));
  }
  getFormAge(index: number) {
    return (<FormArray>this.rooms.controls['room'])
      .at(index)
      .get('childrenAges') as FormArray;
  }
  set formArr(formArr: any) {
    this.formArr = formArr;
  }
  get formArr() {
    return this.rooms.get('room') as FormArray;
  }

  initItemRows() {
    return this._fb.group({
      adultQuantity: [1],
      childQuantity: [0],
      childrenAges: this._fb.array([new FormControl('', Validators.required)])
    });
  }

  addRoom() {
    if(this.formArr.length < 30) {
      this.formArr.push(this.initItemRows());
      setTimeout(() => {
        document.querySelector('.passenger-rooms').scrollTo({
          top: document.querySelector('.passenger-rooms').scrollHeight,
          behavior: 'smooth'
        });
      }, 100);
    }
  }

  addRoomParent() {
    this.room.push(new FormControl('', Validators.required));
  }
  removeRoomParent(index: number) {
    this.room.removeAt(index);
  }

  get room(): FormArray {
    return this.rooms.get('room') as FormArray;
  }

  deleteRoom(index: number) {
    this.formArr.removeAt(index);
    // this.removeRoomParent(index);
  }

  setRooms(childrenAges) {
    let arr = new FormArray([]);
    if (childrenAges) {
      childrenAges.forEach(childrenAge => {
        arr.push(new FormControl(childrenAge));
      });
    }
    return arr;
  }

  openAddPassengerDialog() {
    this.currentFormArr = [];
    this.formArr.controls.forEach((element, index) => {
      this.currentFormArr.push({
        adultQuantity: element.get('adultQuantity').value,
        childQuantity: element.get('childQuantity').value,
        childrenAges: element.get('childrenAges').value
      });
    });
    this.openMenu = !this.openMenu;
  }

  closeAddPassengerDialog(isCompleteButton: boolean) {
    if (!isCompleteButton) {
      this.clearFormArray(this.formArr);
      this.currentFormArr.forEach((element, index) => {
        this.formArr.push(this.initItemRows());
        this.formArr.controls[index].patchValue({
          adultQuantity: element.adultQuantity,
          childQuantity: element.childQuantity,
          childrenAges: element.childrenAges
        });
      });
    }

    this.openMenu = false;
  }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };
}
