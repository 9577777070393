import {
  GetReferralAction,
  GetReferralActionTypes
} from './get-referral.actions';
import {ReferralResponseOfobject} from "../../lib";

export const GETREFERRAL_FEATURE_KEY = 'getReferral';

/**
 * Interface for the 'GetReferral' data used in
 *  - GetReferralState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

export interface GetReferralState {
  list: ReferralResponseOfobject; // list of GetReferral; analogous to a sql normalized table
  selectedId?: string | number; // which GetReferral record has been selected
  loaded: boolean; // has the GetReferral list been loaded
  error?: any; // last none error (if any)
}

export interface GetReferralPartialState {
  readonly [GETREFERRAL_FEATURE_KEY]: GetReferralState;
}

export const initialStateReferral: GetReferralState = {
  list: null,
  loaded: false
};

export function reducerReferral(
  state: GetReferralState = initialStateReferral,
  action: GetReferralAction
): GetReferralState {
  switch (action.type) {
    case GetReferralActionTypes.GetReferralLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case GetReferralActionTypes.DestroyReferral: {
      state = {
        ...state,
        list: null,
        loaded: false
      };
      break;
    }
  }
  return state;
}
