import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SelectItemSrvService {

  isLoaded = new BehaviorSubject<number>(null);
  getIsLoaded = this.isLoaded.asObservable();

  isLoadedChildren = new BehaviorSubject<number>(null);
  getIsLoadedChildren = this.isLoadedChildren.asObservable();

  constructor() { }

  setIsLoaded(valid: number) {
    this.isLoaded.next(valid);
  }

  setIsLoadedChildren(valid: number) {
    this.isLoadedChildren.next(valid);
  }

  unsetIsLoaded(valid: number) {
    this.isLoaded.next(valid);
    this.isLoadedChildren.next(valid);
  }
}
