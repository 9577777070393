import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatIconModule, MatButtonModule, MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatMenuModule,
  MatSelectModule
} from "@angular/material";
import { ScrollToTopComponent } from './scroll-to-top.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule],
  declarations: [ScrollToTopComponent],
  exports: [ScrollToTopComponent]
})
export class ScrollToTopModule {}
