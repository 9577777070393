export * from './agentAccountHistoryResource.service';
import { AgentAccountHistoryResourceService } from './agentAccountHistoryResource.service';
export * from './agentAccountResource.service';
import { AgentAccountResourceService } from './agentAccountResource.service';
export * from './agentAccountTransactionResource.service';
import { AgentAccountTransactionResourceService } from './agentAccountTransactionResource.service';
export * from './bankTransferHistoryResource.service';
import { BankTransferHistoryResourceService } from './bankTransferHistoryResource.service';
export * from './creditCardResource.service';
import { CreditCardResourceService } from './creditCardResource.service';
export * from './markupExceptionResource.service';
import { MarkupExceptionResourceService } from './markupExceptionResource.service';
export * from './markupModelReindexResource.service';
import { MarkupModelReindexResourceService } from './markupModelReindexResource.service';
export * from './markupModelResource.service';
import { MarkupModelResourceService } from './markupModelResource.service';
export * from './markupResource.service';
import { MarkupResourceService } from './markupResource.service';
export * from './markupZoneLocationResource.service';
import { MarkupZoneLocationResourceService } from './markupZoneLocationResource.service';
export * from './markupZoneResource.service';
import { MarkupZoneResourceService } from './markupZoneResource.service';
export * from './paymentResource.service';
import { PaymentResourceService } from './paymentResource.service';
export * from './pricingScheduleResource.service';
import { PricingScheduleResourceService } from './pricingScheduleResource.service';
export * from './profileInfoResource.service';
import { ProfileInfoResourceService } from './profileInfoResource.service';
export * from './promotionExceptionResource.service';
import { PromotionExceptionResourceService } from './promotionExceptionResource.service';
export * from './promotionMetaSatisfyResource.service';
import { PromotionMetaSatisfyResourceService } from './promotionMetaSatisfyResource.service';
export * from './promotionResource.service';
import { PromotionResourceService } from './promotionResource.service';
export * from './voucherResource.service';
import { VoucherResourceService } from './voucherResource.service';
export const APIS = [AgentAccountHistoryResourceService, AgentAccountResourceService, AgentAccountTransactionResourceService, BankTransferHistoryResourceService, CreditCardResourceService, MarkupExceptionResourceService, MarkupModelReindexResourceService, MarkupModelResourceService, MarkupResourceService, MarkupZoneLocationResourceService, MarkupZoneResourceService, PaymentResourceService, PricingScheduleResourceService, ProfileInfoResourceService, PromotionExceptionResourceService, PromotionMetaSatisfyResourceService, PromotionResourceService, VoucherResourceService];
