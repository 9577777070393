import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { AirportFacade, CitiesPopularFacade } from '@gtd/meta';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl
} from '@angular/forms';
import { AirportDTO } from '@gtd/meta-client';
import { MatFormFieldControl } from '@angular/material';
import { Subject, Subscription } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { FlightSearchService } from '../flight-search.service';
import { HistorySearchKeywordFacade } from '../../../state/history-search-keyword/+state-sesstion/history-search-keyword.facade';

export interface InputField {
  stateCtrl: string;
}

@Component({
  selector: 'gtd-auto-compete',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './auto-compete.component.html',
  styleUrls: ['./auto-compete.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    AirportFacade,
    {
      provide: MatFormFieldControl,
      useExisting: AutoCompeteComponent,
      multi: true
    }
  ]
})
export class AutoCompeteComponent
  implements
    OnInit,
    OnChanges,
    ControlValueAccessor,
    MatFormFieldControl<InputField>,
    OnDestroy {
  @Input() label = 'Label';
  @Input() placeholder = 'Placeholder';
  @Input() inputType = '';
  @Input() iconLeft?: string;
  @Input() type: any;
  @Input() dataLocalStorages: AirportDTO;

  form: FormGroup;
  stateChanges = new Subject<void>();
  focused: boolean;
  private _disabled = false;
  subscriptions = new Subscription();

  @HostBinding() id = 'gtd-auto-compete-${Test.nextId++}';
  @HostBinding('attr.aria-describedby') describedBy = '';

  stateCtrl = new FormControl();

  loaded$ = this.airportFacade.loaded$;

  allAirport$ = this.airportFacade.allAirport$;

  allAirportPopular$ = this.citiesPopularFacade.allCitiesPopular$;

  errorState = false;

  private _required = false;

  airportDTOs: AirportDTO[];

  airportSearchLabel: string = 'airport-popular';

  constructor(
    public airportFacade: AirportFacade,
    fb: FormBuilder,
    @Optional() @Self() public ngControl: NgControl,
    private elRef: ElementRef<HTMLElement>,
    private citiesPopularFacade: CitiesPopularFacade,
    private flightSearchService: FlightSearchService,
    private historySearchKeywordFacade: HistorySearchKeywordFacade
  ) {
    this.form = fb.group({
      stateCtrl: ''
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.citiesPopularFacade.loadAll();
  }

  ngOnInit() {
    this.airportDTOs = this.flightSearchService.getAllLocalStorageLocation(
      this.type
    );
    this.stateCtrl.valueChanges.subscribe((data: any) => {
      this.airportFacade.loadAll(data, '');
      if (data) {
        setTimeout(() => {
          this.airportSearchLabel = 'list-airport';
          this.airportDTOs = null;

          this.onChange(data.code);
          this.onTouched();
        });
      } else {
        this.airportDTOs = this.flightSearchService.getAllLocalStorageLocation(
          this.type
        );
      }
    });
    if (this.dataLocalStorages) {
      this.stateCtrl.setValue(this.dataLocalStorages);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataLocalStorages && !changes.dataLocalStorages.firstChange) {
      this.stateCtrl.setValue(this.dataLocalStorages);
      this.onChange(this.dataLocalStorages.code);
      this.onTouched();
    }
  }

  displayFn(airport?: AirportDTO): string | undefined {
    return airport ? `${airport.city} (${airport.code})` : undefined;
  }
  getOptionSelect(value: AirportDTO) {
    if (this.type === 'from') {
      this.historySearchKeywordFacade.addSearchKeywordFlightFrom([value]);
    }
    if (this.type === 'to') {
      this.historySearchKeywordFacade.addSearchKeywordFlightTo([value]);
    }
  }

  @Input()
  get value(): InputField {
    let n = this.form.value;
    return n;
  }
  set value(sf: InputField) {
    setTimeout(() => {
      if (this.dataLocalStorages) {
        this.stateCtrl.setValue(this.dataLocalStorages);
        this.onChange(this.dataLocalStorages.code);
        this.onTouched();
      }
    }, 100);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  get empty() {
    let n = this.form.value;
    return !n.area && !n.exchange && !n.subscriber;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.subscriptions.unsubscribe();
  }
}
