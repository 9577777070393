import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { environment } from '@env/environment';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

import { FinalBookingDetailFacade } from '../../state/booking-result/final-booking-detail/final-booking-detail.facade';
@Component({
  selector: 'gtd-booking-result-bottom-sheet',
  templateUrl: './booking-result-bottom-sheet.component.html',
  styleUrls: ['./booking-result-bottom-sheet.component.scss'],
  providers: [FinalBookingDetailFacade]
})
export class BookingResultBottomSheetComponent implements OnInit {
  deviceInfo: DeviceInfo;
  allFinalBookingDetail$ = this.finalBookingDetailFacade.allFinalBookingDetail$;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BookingResultBottomSheetComponent>,
    private deviceService: DeviceDetectorService,
    private finalBookingDetailFacade: FinalBookingDetailFacade
  ) {}

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  download() {
    this._bottomSheetRef.dismiss();

    if (environment.appName === 'B2B' || environment.appName === 'B2C') {
      if (
        this.deviceInfo.device === 'iPad' ||
        this.deviceInfo.device === 'iPhone' ||
        this.deviceInfo.os === 'iOS'
      ) {
        let osScheme = window.location.href;
        if (environment.production) {
          osScheme = 'GTDiOS-redirect://';
        } else {
          osScheme = 'GTDiOSUAT-redirect://';
        }
        window.location.replace(osScheme);
      } else if (
        this.deviceInfo.device === 'Android' ||
        this.deviceInfo.os === 'Android'
      ) {
        this.allFinalBookingDetail$.subscribe(bookingDetail => {
          const bookingNumber = bookingDetail.bookingNumber;
          let osScheme = window.location.href;
          if (environment.appName === 'B2C') {
            if (environment.production) {
				osScheme = `intent://www.gotadi.com/bookingNumber=${bookingNumber}#Intent;scheme=b2c-pro;package=com.vn.gotadi.mobileapp;S.browser_fallback_url=google.com.vn;end`;
			} else {
				osScheme = `intent://uat-b2c.gotadi.com/bookingNumber=${bookingNumber}#Intent;scheme=b2c-uat;package=com.vn.gotadi.mobileapp.uat;S.browser_fallback_url=google.com.vn;end`;
            }
            window.location.replace(osScheme);
          } else {
            if (environment.production) {
				osScheme = `intent://partner.gotadi.com/bookingNumber=${bookingNumber}#Intent;scheme=b2b-pro;package=app.gotadi.b2b;S.browser_fallback_url=google.com.vn;end`;
			} else {
				osScheme = `intent://uat-b2b2c.gotadi.com/bookingNumber=${bookingNumber}#Intent;scheme=b2b-uat;package=app.gotadi.b2b.uat;S.browser_fallback_url=google.com.vn;end`;
            }
            window.location.replace(osScheme);
          }
        });
      }
    }
  }

  close() {
    this._bottomSheetRef.dismiss();
  }
}
