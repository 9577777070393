import {
  MarkAsUnreadAction,
  MarkAsUnreadActionTypes
} from './mark-as-unread.actions';

export const MARKASUNREAD_FEATURE_KEY = 'markAsUnread';

/**
 * Interface for the 'MarkAsUnread' data used in
 *  - MarkAsUnreadState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface MarkAsUnreadState {
  list: any; // list of MarkAsUnread; analogous to a sql normalized table
  selectedId?: string | number; // which MarkAsUnread record has been selected
  loaded: boolean; // has the MarkAsUnread list been loaded
  error?: any; // last none error (if any)
}

export interface MarkAsUnreadPartialState {
  readonly [MARKASUNREAD_FEATURE_KEY]: MarkAsUnreadState;
}

export const initialState: MarkAsUnreadState = {
  list: null,
  loaded: false
};

export function markAsUnreadReducer(
  state: MarkAsUnreadState = initialState,
  action: MarkAsUnreadAction
): MarkAsUnreadState {
  switch (action.type) {
    case MarkAsUnreadActionTypes.MarkAsUnreadLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
