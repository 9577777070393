import { Action } from '@ngrx/store';
import { GroupItineraryReq } from './group-itinerary.facade';
import { AirGroupPricedItineraryRS } from '@gtd/b2c-client';
import { AvailableFlightsActionTypes } from '../../flight-cache-search/+state/available-flights.actions';

export enum GroupItineraryActionTypes {
  ComboVJLoadGroupItinerary = '[ComboVJ_GroupItinerary] Load GroupItinerary',
  ComboVJGroupItineraryLoaded = '[ComboVJ_GroupItinerary] GroupItinerary Loaded',
  ComboVJGroupItineraryLoadError = '[ComboVJ_GroupItinerary] GroupItinerary Load Error',
  ComboVJResetGroupItinerary = '[ComboVJ_GroupItinerary] Reset GroupItinerary'
}

export class ComboVJLoadGroupItinerary implements Action {
  readonly type = GroupItineraryActionTypes.ComboVJLoadGroupItinerary;
  constructor(public payload: GroupItineraryReq) {}
}

export class ComboVJGroupItineraryLoadError implements Action {
  readonly type = GroupItineraryActionTypes.ComboVJGroupItineraryLoadError;
  constructor(public payload: any) {}
}

export class ComboVJGroupItineraryLoaded implements Action {
  readonly type = GroupItineraryActionTypes.ComboVJGroupItineraryLoaded;
  constructor(public payload: AirGroupPricedItineraryRS) {}
}

export class ComboVJResetGroupItinerary implements Action {
  readonly type = GroupItineraryActionTypes.ComboVJResetGroupItinerary;
}

export type GroupItineraryAction =
  | ComboVJLoadGroupItinerary
  | ComboVJGroupItineraryLoaded
  | ComboVJGroupItineraryLoadError
  | ComboVJResetGroupItinerary;

export const fromGroupItineraryActions = {
  ComboVJLoadGroupItinerary,
  ComboVJGroupItineraryLoaded,
  ComboVJGroupItineraryLoadError,
  ComboVJResetGroupItinerary
};
