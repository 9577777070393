import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { GetHeaderSummaryPartialState } from './get-header-summary.reducer';
import { getHeaderSummaryQuery } from './get-header-summary.selectors';
import {
  LoadGetHeaderSummary,
  ResetGetHeaderSummary
} from './get-header-summary.actions';

@Injectable()
export class GetHeaderSummaryFacade {
  loaded$ = this.store.pipe(select(getHeaderSummaryQuery.getLoaded));
  allGetHeaderSummary$ = this.store.pipe(
    select(getHeaderSummaryQuery.getAllGetHeaderSummary)
  );
  selectedGetHeaderSummary$ = this.store.pipe(
    select(getHeaderSummaryQuery.getSelectedGetHeaderSummary)
  );

  constructor(private store: Store<GetHeaderSummaryPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadGetHeaderSummary());
  }

  resetAll() {
    this.store.dispatch(new ResetGetHeaderSummary());
  }
}
