/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IdAutosavesBody18 { 
    /**
     * The ID for the parent of the autosave.
     */
    parent?: string;
    /**
     * The date the post was published, in the site's timezone.
     */
    date?: Date;
    /**
     * The date the post was published, as GMT.
     */
    dateGmt?: Date;
    /**
     * An alphanumeric identifier for the post unique to its type.
     */
    slug?: string;
    /**
     * A named status for the post.
     */
    status?: Array<IdAutosavesBody18.StatusEnum>;
    /**
     * A password to protect access to the content and excerpt.
     */
    password?: string;
    /**
     * The title for the post.
     */
    title?: string;
    /**
     * The theme file to use to display the post.
     */
    template?: string;
    /**
     * Expose advanced custom fields.
     */
    acf?: string;
}
export namespace IdAutosavesBody18 {
    export type StatusEnum = 'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled';
    export const StatusEnum = {
        Publish: 'publish' as StatusEnum,
        Future: 'future' as StatusEnum,
        Draft: 'draft' as StatusEnum,
        Pending: 'pending' as StatusEnum,
        Private: 'private' as StatusEnum,
        AcfDisabled: 'acf-disabled' as StatusEnum
    };
}