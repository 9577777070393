/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FilterItemDouble } from './filterItemDouble';
import { FilterItemString } from './filterItemString';
import { FilterPrice } from './filterPrice';


export interface FilterOptionsResult { 
    guestRatings?: Array<FilterItemDouble>;
    language?: FilterOptionsResult.LanguageEnum;
    mealPlans?: Array<FilterItemString>;
    prices?: Array<FilterPrice>;
    propertyAmenities?: Array<FilterItemString>;
    propertyCategories?: Array<FilterItemString>;
    propertyRatings?: Array<FilterItemDouble>;
    roomAmenities?: Array<FilterItemString>;
    roomViews?: Array<FilterItemString>;
    themes?: Array<FilterItemString>;
}
export namespace FilterOptionsResult {
    export type LanguageEnum = 'vi' | 'en';
    export const LanguageEnum = {
        Vi: 'vi' as LanguageEnum,
        En: 'en' as LanguageEnum
    };
}
