/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Info } from './info';
import { PaymentTransReply } from './paymentTransReply';


export interface DebitCheckPaymentStatusRes { 
    billId?: string;
    billStatus?: string;
    duration?: number;
    errors?: Array<Error>;
    infos?: Array<Info>;
    isSuccess?: boolean;
    orderCode?: string;
    orderPaymentOption?: string;
    orderStatus?: DebitCheckPaymentStatusRes.OrderStatusEnum;
    orderTotalAmount?: number;
    paymentByCode?: string;
    paymentByName?: string;
    paymentFee?: number;
    paymentKey?: string;
    paymentOption?: DebitCheckPaymentStatusRes.PaymentOptionEnum;
    paymentRefNumber?: string;
    success?: boolean;
    textMessage?: string;
    transReply?: PaymentTransReply;
    transactionId?: string;
}
export namespace DebitCheckPaymentStatusRes {
    export type OrderStatusEnum = 'PENDING' | 'SUCCESSED' | 'PAYMENT_ERROR' | 'ORDER_OVER_TIME_LIMIT';
    export const OrderStatusEnum = {
        PENDING: 'PENDING' as OrderStatusEnum,
        SUCCESSED: 'SUCCESSED' as OrderStatusEnum,
        PAYMENTERROR: 'PAYMENT_ERROR' as OrderStatusEnum,
        ORDEROVERTIMELIMIT: 'ORDER_OVER_TIME_LIMIT' as OrderStatusEnum
    };
    export type PaymentOptionEnum = 'Vietcombank' | 'TechComBank' | 'AgriBank' | 'BIDV' | 'SacomBank' | 'Eximbank' | 'PGBank' | 'GPBank' | 'NamABank' | 'SaigonBank' | 'ABBank' | 'VIB' | 'MB' | 'MaritimeBank' | 'OceanBank' | 'BacABank' | 'VietinBank' | 'SHB' | 'VPBank' | 'DongABank' | 'TienphongBank' | 'ACB' | 'OCB' | 'HDBank' | 'NaviBank' | 'VietABank' | 'LienVietPostBank' | 'BaoVietBank' | 'Visa' | 'Mastercard';
    export const PaymentOptionEnum = {
        Vietcombank: 'Vietcombank' as PaymentOptionEnum,
        TechComBank: 'TechComBank' as PaymentOptionEnum,
        AgriBank: 'AgriBank' as PaymentOptionEnum,
        BIDV: 'BIDV' as PaymentOptionEnum,
        SacomBank: 'SacomBank' as PaymentOptionEnum,
        Eximbank: 'Eximbank' as PaymentOptionEnum,
        PGBank: 'PGBank' as PaymentOptionEnum,
        GPBank: 'GPBank' as PaymentOptionEnum,
        NamABank: 'NamABank' as PaymentOptionEnum,
        SaigonBank: 'SaigonBank' as PaymentOptionEnum,
        ABBank: 'ABBank' as PaymentOptionEnum,
        VIB: 'VIB' as PaymentOptionEnum,
        MB: 'MB' as PaymentOptionEnum,
        MaritimeBank: 'MaritimeBank' as PaymentOptionEnum,
        OceanBank: 'OceanBank' as PaymentOptionEnum,
        BacABank: 'BacABank' as PaymentOptionEnum,
        VietinBank: 'VietinBank' as PaymentOptionEnum,
        SHB: 'SHB' as PaymentOptionEnum,
        VPBank: 'VPBank' as PaymentOptionEnum,
        DongABank: 'DongABank' as PaymentOptionEnum,
        TienphongBank: 'TienphongBank' as PaymentOptionEnum,
        ACB: 'ACB' as PaymentOptionEnum,
        OCB: 'OCB' as PaymentOptionEnum,
        HDBank: 'HDBank' as PaymentOptionEnum,
        NaviBank: 'NaviBank' as PaymentOptionEnum,
        VietABank: 'VietABank' as PaymentOptionEnum,
        LienVietPostBank: 'LienVietPostBank' as PaymentOptionEnum,
        BaoVietBank: 'BaoVietBank' as PaymentOptionEnum,
        Visa: 'Visa' as PaymentOptionEnum,
        Mastercard: 'Mastercard' as PaymentOptionEnum
    };
}
