import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gtd-form-cabin-classes',
  templateUrl: './form-cabin-classes.component.html',
  styleUrls: ['./form-cabin-classes.component.scss']
})
export class FormCabinClassesComponent implements OnInit {
  @Output() newFilterCabinClassEvent = new EventEmitter<string>();
  openMenu = false;
  currentCabinClasses = 'economy';
  cabinClasses = ['economy', 'premium', 'business'];

  constructor() {}

  ngOnInit() {
    this.newFilterCabinClassEvent.emit(this.currentCabinClasses);
  }
}
