import { Action } from '@ngrx/store';
import { Entity } from './mark-as-unread.reducer';
import {MessageItem} from "@gtd/api-services/customer-service";

export enum MarkAsUnreadActionTypes {
  LoadMarkAsUnread = '[MarkAsUnread] Load MarkAsUnread',
  MarkAsUnreadLoaded = '[MarkAsUnread] MarkAsUnread Loaded',
  MarkAsUnreadLoadError = '[MarkAsUnread] MarkAsUnread Load Error'
}

export class LoadMarkAsUnread implements Action {
  readonly type = MarkAsUnreadActionTypes.LoadMarkAsUnread;
  constructor(public payload: Array<MessageItem>) {}
}

export class MarkAsUnreadLoadError implements Action {
  readonly type = MarkAsUnreadActionTypes.MarkAsUnreadLoadError;
  constructor(public payload: any) {}
}

export class MarkAsUnreadLoaded implements Action {
  readonly type = MarkAsUnreadActionTypes.MarkAsUnreadLoaded;
  constructor(public payload: any) {}
}

export type MarkAsUnreadAction =
  | LoadMarkAsUnread
  | MarkAsUnreadLoaded
  | MarkAsUnreadLoadError;

export const fromMarkAsUnreadActions = {
  LoadMarkAsUnread,
  MarkAsUnreadLoaded,
  MarkAsUnreadLoadError
};
