import {
  FinalBookingDetailAction,
  FinalBookingDetailActionTypes
} from './final-booking-detail.actions';
import {GroupBooking} from "@gtd/b2c-client";

export const FINALBOOKINGDETAIL_FEATURE_KEY = 'finalBookingDetail';

/**
 * Interface for the 'FinalBookingDetail' data used in
 *  - FinalBookingDetailState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FinalBookingDetailState {
  result: GroupBooking; // list of FinalBookingDetail; analogous to a sql normalized table
  selectedId?: string | number; // which FinalBookingDetail record has been selected
  loaded: boolean; // has the FinalBookingDetail list been loaded
  error?: any; // last none error (if any)
}

export interface FinalBookingDetailPartialState {
  readonly [FINALBOOKINGDETAIL_FEATURE_KEY]: FinalBookingDetailState;
}

export const initialState: FinalBookingDetailState = {
  result: null,
  loaded: false
};

export function finalBookingDetailReducer(
  state: FinalBookingDetailState = initialState,
  action: FinalBookingDetailAction
): FinalBookingDetailState {
  switch (action.type) {
    case FinalBookingDetailActionTypes.FinalBookingDetailLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
    case FinalBookingDetailActionTypes.FinalBookingDetailLoadError: {
      initialState.loaded = true;
      initialState.error = {
        code: action.payload.status,
        message: action.payload.message
      }
      state = initialState;
    }
  }
  return state;
}
