import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { FilterComponent } from './filter.component';
import {
    MatButtonModule, MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule, MatMenuModule, MatSelectModule
} from "@angular/material";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DatepickerModule} from "@gtd/components/datepicker";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import { FilterB2bComponent } from '../filter-b2b/filter-b2b.component';
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";



@NgModule({
  declarations: [FilterComponent, FilterB2bComponent],
  exports: [
    FilterComponent,
    FilterB2bComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FlexLayoutModule,
    MatInputModule,
    FontAwesomeModule,
    MatButtonModule,
    DatepickerModule,
    MatMenuModule,
    MatCheckboxModule,
    TranslateModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgbDatepickerModule
  ],
  providers: [DatePipe]
})
export class FilterModule { }
