import {Component, Input, OnInit} from '@angular/core';
import {InventoryResourcesService} from "@gtd/b2c-client";
import {ActivatedRoute} from "@angular/router";
import {TechcomExchangeCurrency} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-tcb-transaction-detail',
  templateUrl: './tcb-transaction-detail.component.html',
  styleUrls: ['./tcb-transaction-detail.component.scss']
})
export class TcbTransactionDetailComponent implements OnInit {
  @Input() page: string;

  techcomExchangeCurrency: TechcomExchangeCurrency;
  isLoading: boolean;

  constructor(
    private inventoryResourcesService: InventoryResourcesService,
    private activatedRoute: ActivatedRoute,
  ) {
    activatedRoute.queryParams.subscribe(value => {
      if ((value && value.bookingNumber) || (value && value.booking_number)) {
        this.isLoading = true;
        let booking_number = value.booking_number || value.bookingNumber;
        this.inventoryResourcesService.getTCBOrderByBookingUsingGET(booking_number).subscribe(
          detail => {
            this.techcomExchangeCurrency = detail.result;
            if(!detail.result.tcbCustomerListStatus) {
              this.inventoryResourcesService.issueTCBOrderUsingPOST({
                bookingNumber: booking_number
              }).subscribe(
                orderDetail => {
                  this.techcomExchangeCurrency = orderDetail.result? orderDetail.result: this.techcomExchangeCurrency;
                  this.isLoading = false
                }, error => this.isLoading = false
              )
            } else {
              this.isLoading = false
            }
          }
        )
      }
    })
  }


  getTcbTransaction(value: any) {
    this.inventoryResourcesService.getTCBOrderByBookingUsingGET(value.bookingNumber).subscribe(
      detail => this.techcomExchangeCurrency = detail.result
    )
  }

  ngOnInit() {

  }

  checkTransactionSuccess() {
    if(this.techcomExchangeCurrency.techcomCustomers && this.techcomExchangeCurrency.techcomCustomers.length) {
      let countSc = this.techcomExchangeCurrency.techcomCustomers.filter(transaction => transaction.buyerMobileValid === 'Y');
      return !!countSc.length
    }
  }
}
