import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  PaxInfo,
  ResetActivityId,
  ResetPaxInfos,
  ResetRatePlanId,
  ResetTourActivity,
  SetActivityId,
  SetDate,
  SetPaxInfos,
  SetRatePlanId
} from './tour-activity-booking.actions';

import { TourActivityBookingPartialState } from './tour-activity-booking.reducer';
import { tourActivityBookingQuery } from './tour-activity-booking.selectors';

@Injectable()
export class TourActivityBookingFacade {
  allActivityInfos$ = this.store.pipe(
    select(tourActivityBookingQuery.getAllActivityInfo)
  );
  paxInfos$ = this.store.pipe(select(tourActivityBookingQuery.getPaxInfos));
  date$ = this.store.pipe(select(tourActivityBookingQuery.getDate));
  activityId$ = this.store.pipe(select(tourActivityBookingQuery.getActivityId));
  ratePlanId$ = this.store.pipe(select(tourActivityBookingQuery.getRatePlanId));

  constructor(private store: Store<TourActivityBookingPartialState>) {}

  setPaxInfos(payload: PaxInfo[]) {
    this.store.dispatch(new SetPaxInfos(payload));
  }

  setDate(payload: Date) {
    this.store.dispatch(new SetDate(payload));
  }

  setActivityId(payload: number) {
    this.store.dispatch(new SetActivityId(payload));
  }

  setRatePlanId(payload: number) {
    this.store.dispatch(new SetRatePlanId(payload));
  }

  resetPaxInfos() {
    this.store.dispatch(new ResetPaxInfos());
  }

  resetActivityId() {
    this.store.dispatch(new ResetActivityId());
  }

  resetRatePlanId() {
    this.store.dispatch(new ResetRatePlanId());
  }

  resetTourActivity() {
    this.store.dispatch(new ResetTourActivity());
  }
}
