import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { TourSearchActivityDetailPartialState } from './tour-search-activity-detail.reducer';
import { tourSearchActivityDetailQuery } from './tour-search-activity-detail.selectors';
import {
  LoadTourSearchActivityDetail,
  ResetTourSearchActivityDetail,
  SearchActivityDetailRQ
} from './tour-search-activity-detail.actions';

@Injectable()
export class TourSearchActivityDetailFacade {
  loaded$ = this.store.pipe(select(tourSearchActivityDetailQuery.getLoaded));
  allTourSearchActivityDetail$ = this.store.pipe(
    select(tourSearchActivityDetailQuery.getAllTourSearchActivityDetail)
  );
  selectedTourSearchActivityDetail$ = this.store.pipe(
    select(tourSearchActivityDetailQuery.getSelectedTourSearchActivityDetail)
  );

  constructor(private store: Store<TourSearchActivityDetailPartialState>) {}

  loadAll(payload: SearchActivityDetailRQ) {
    this.store.dispatch(new LoadTourSearchActivityDetail(payload));
  }

  resetAll() {
    this.store.dispatch(new ResetTourSearchActivityDetail());
  }
}
