/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface VoucherRedemptionReq { 
    bookingNumber?: string;
    bookingRefNo?: string;
    customerEmail?: string;
    customerPhone?: string;
    custormerName?: string;
    ibeSessionId?: string;
    metaData?: { [key: string]: any; };
    requesterCode?: string;
    requesterType?: VoucherRedemptionReq.RequesterTypeEnum;
    serviceName?: string;
    totalFare?: number;
    trackingDate?: string;
    voucherCode?: string;
}
export namespace VoucherRedemptionReq {
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
