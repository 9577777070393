/**
 * customersrv API
 * customersrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CustomerProfileDTO } from '../model/customerProfileDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CustomerProfileResourceService {

    protected basePath = 'https://172.16.100.16:8193';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createCustomerProfile
     *
     * @param customerProfileDTO customerProfileDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomerProfileUsingPOST(customerProfileDTO: CustomerProfileDTO, observe?: 'body', reportProgress?: boolean): Observable<CustomerProfileDTO>;
    public createCustomerProfileUsingPOST(customerProfileDTO: CustomerProfileDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerProfileDTO>>;
    public createCustomerProfileUsingPOST(customerProfileDTO: CustomerProfileDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerProfileDTO>>;
    public createCustomerProfileUsingPOST(customerProfileDTO: CustomerProfileDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerProfileDTO === null || customerProfileDTO === undefined) {
            throw new Error('Required parameter customerProfileDTO was null or undefined when calling createCustomerProfileUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomerProfileDTO>(`${this.basePath}/api/customer-profiles`,
            customerProfileDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteCustomerProfile
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCustomerProfileUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCustomerProfileUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCustomerProfileUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCustomerProfileUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteCustomerProfileUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/customer-profiles/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllCustomerProfiles
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCustomerProfilesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerProfileDTO>>;
    public getAllCustomerProfilesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerProfileDTO>>>;
    public getAllCustomerProfilesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerProfileDTO>>>;
    public getAllCustomerProfilesUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<CustomerProfileDTO>>(`${this.basePath}/api/customer-profiles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getCustomerProfile
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerProfileUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<CustomerProfileDTO>;
    public getCustomerProfileUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerProfileDTO>>;
    public getCustomerProfileUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerProfileDTO>>;
    public getCustomerProfileUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCustomerProfileUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomerProfileDTO>(`${this.basePath}/api/customer-profiles/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getProfileByCode
     *
     * @param custCode custCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileByCodeUsingGET(custCode: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerProfileDTO>;
    public getProfileByCodeUsingGET(custCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerProfileDTO>>;
    public getProfileByCodeUsingGET(custCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerProfileDTO>>;
    public getProfileByCodeUsingGET(custCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (custCode === null || custCode === undefined) {
            throw new Error('Required parameter custCode was null or undefined when calling getProfileByCodeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomerProfileDTO>(`${this.basePath}/api/customer-profiles/code/${encodeURIComponent(String(custCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getProfileByLoginId
     *
     * @param loginId loginId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileByLoginIdUsingGET(loginId: number, observe?: 'body', reportProgress?: boolean): Observable<CustomerProfileDTO>;
    public getProfileByLoginIdUsingGET(loginId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerProfileDTO>>;
    public getProfileByLoginIdUsingGET(loginId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerProfileDTO>>;
    public getProfileByLoginIdUsingGET(loginId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginId === null || loginId === undefined) {
            throw new Error('Required parameter loginId was null or undefined when calling getProfileByLoginIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomerProfileDTO>(`${this.basePath}/api/customer-profiles/login-id/${encodeURIComponent(String(loginId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getProfileByLoginName
     *
     * @param loginName loginName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileByLoginNameUsingGET(loginName: string, observe?: 'body', reportProgress?: boolean): Observable<CustomerProfileDTO>;
    public getProfileByLoginNameUsingGET(loginName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerProfileDTO>>;
    public getProfileByLoginNameUsingGET(loginName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerProfileDTO>>;
    public getProfileByLoginNameUsingGET(loginName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginName === null || loginName === undefined) {
            throw new Error('Required parameter loginName was null or undefined when calling getProfileByLoginNameUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CustomerProfileDTO>(`${this.basePath}/api/customer-profiles/login-name/${encodeURIComponent(String(loginName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateCustomerProfile
     *
     * @param customerProfileDTO customerProfileDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomerProfileUsingPUT(customerProfileDTO: CustomerProfileDTO, observe?: 'body', reportProgress?: boolean): Observable<CustomerProfileDTO>;
    public updateCustomerProfileUsingPUT(customerProfileDTO: CustomerProfileDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerProfileDTO>>;
    public updateCustomerProfileUsingPUT(customerProfileDTO: CustomerProfileDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerProfileDTO>>;
    public updateCustomerProfileUsingPUT(customerProfileDTO: CustomerProfileDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerProfileDTO === null || customerProfileDTO === undefined) {
            throw new Error('Required parameter customerProfileDTO was null or undefined when calling updateCustomerProfileUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CustomerProfileDTO>(`${this.basePath}/api/customer-profiles`,
            customerProfileDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
