/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Info } from './info';


export interface TopupInfo { 
    accountCode?: string;
    accountId?: number;
    agencyAdress?: string;
    agencyCode?: string;
    agencyId?: number;
    agencyName?: string;
    amount?: number;
    balance?: number;
    comment?: string;
    credit?: number;
    duration?: number;
    errors?: Array<Error>;
    fromAgencyCode?: string;
    id?: number;
    infos?: Array<Info>;
    success?: boolean;
    textMessage?: string;
    toAgencyCode?: string;
    transToken?: string;
    transType?: TopupInfo.TransTypeEnum;
    updatedBalance?: number;
}
export namespace TopupInfo {
    export type TransTypeEnum = 'DEPOSIT' | 'DEDUCT' | 'CREDIT' | 'REFUND' | 'TRANSACTION' | 'TRANSFER' | 'TICKET_TRANSACTION' | 'HOTEL_TRANSACTION' | 'TOUR_TRANSACTION' | 'TRACKING_TRANSACTION' | 'OTHER';
    export const TransTypeEnum = {
        DEPOSIT: 'DEPOSIT' as TransTypeEnum,
        DEDUCT: 'DEDUCT' as TransTypeEnum,
        CREDIT: 'CREDIT' as TransTypeEnum,
        REFUND: 'REFUND' as TransTypeEnum,
        TRANSACTION: 'TRANSACTION' as TransTypeEnum,
        TRANSFER: 'TRANSFER' as TransTypeEnum,
        TICKETTRANSACTION: 'TICKET_TRANSACTION' as TransTypeEnum,
        HOTELTRANSACTION: 'HOTEL_TRANSACTION' as TransTypeEnum,
        TOURTRANSACTION: 'TOUR_TRANSACTION' as TransTypeEnum,
        TRACKINGTRANSACTION: 'TRACKING_TRANSACTION' as TransTypeEnum,
        OTHER: 'OTHER' as TransTypeEnum
    };
}
