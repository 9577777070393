/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AirItineraryInfo { 
    airlineCode?: string;
    arriveDateTime?: Date;
    cabinClass?: AirItineraryInfo.CabinClassEnum;
    fareSourceCode?: string;
    groupId?: string;
    searchId?: string;
    supplierCode?: string;
    validReturnCabinClasses?: Array<string>;
}
export namespace AirItineraryInfo {
    export type CabinClassEnum = 'BUSINESS' | 'FIRST' | 'PREMIUM' | 'ECONOMY' | 'PROMO';
    export const CabinClassEnum = {
        BUSINESS: 'BUSINESS' as CabinClassEnum,
        FIRST: 'FIRST' as CabinClassEnum,
        PREMIUM: 'PREMIUM' as CabinClassEnum,
        ECONOMY: 'ECONOMY' as CabinClassEnum,
        PROMO: 'PROMO' as CabinClassEnum
    };
}
