import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarOptionsComponent } from './star-options.component';
import { GTypographyModule } from '@gotadi/design-system';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [CommonModule, GTypographyModule, TranslateModule],
  declarations: [StarOptionsComponent],
  exports: [StarOptionsComponent]
})
export class StarOptionsModule {}
