import {Action} from '@ngrx/store';
import {GroupBooking} from "@gtd/b2c-client";

export enum FinalBookingDetailActionTypes {
  LoadFinalBookingDetail = '[FinalBookingDetail] Load FinalBookingDetail',
  FinalBookingDetailLoaded = '[FinalBookingDetail] FinalBookingDetail Loaded',
  FinalBookingDetailLoadError = '[FinalBookingDetail] FinalBookingDetail Load Error'
}

export class LoadFinalBookingDetail implements Action {
  readonly type = FinalBookingDetailActionTypes.LoadFinalBookingDetail;
  constructor(public payload: string) {}
}

export class FinalBookingDetailLoadError implements Action {
  readonly type = FinalBookingDetailActionTypes.FinalBookingDetailLoadError;
  constructor(public payload: any) {}
}

export class FinalBookingDetailLoaded implements Action {
  readonly type = FinalBookingDetailActionTypes.FinalBookingDetailLoaded;
  constructor(public payload: GroupBooking) {}
}

export type FinalBookingDetailAction =
  | LoadFinalBookingDetail
  | FinalBookingDetailLoaded
  | FinalBookingDetailLoadError;

export const fromFinalBookingDetailActions = {
  LoadFinalBookingDetail,
  FinalBookingDetailLoaded,
  FinalBookingDetailLoadError
};
