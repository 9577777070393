import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IncreamentInputComponent} from './increament-input.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material";

library.add(faMinus, faPlus);

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FlexLayoutModule,
    MatButtonModule
  ],
  exports: [IncreamentInputComponent],
  declarations: [IncreamentInputComponent],
  providers: [IncreamentInputComponent]
})
export class IncreamentInputModule {}
