import { AirportAction, AirportActionTypes } from './airport.actions';
import { AirportDTO } from '@gtd/meta-client';

export const AIRPORT_FEATURE_KEY = 'airport';

/**
 * Interface for the 'Airport' data used in
 *  - AirportState, and
 *  - airportReducer
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface AirportState {
  list: AirportDTO[]; // list of Airport; analogous to a sql normalized table
  selectedId?: string | number; // which Airport record has been selected
  loaded: boolean; // has the Airport list been loaded
  error?: any; // last none error (if any)
}

export interface AirportPartialState {
  readonly [AIRPORT_FEATURE_KEY]: AirportState;
}

export const initialState: AirportState = {
  list: [],
  loaded: false
};

export function airportReducer(
  state: AirportState = initialState,
  action: AirportAction
): AirportState {
  switch (action.type) {
    case AirportActionTypes.AirportLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
