import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { LoginNamePartialState } from './login-name.reducer';
import { loginNameQuery } from './login-name.selectors';
import { LoadLoginName } from './login-name.actions';

@Injectable()
export class LoginNameFacade {
  loaded$ = this.store.pipe(select(loginNameQuery.getLoaded));
  allLoginName$ = this.store.pipe(select(loginNameQuery.getAllLoginName));
  selectedLoginName$ = this.store.pipe(
    select(loginNameQuery.getSelectedLoginName)
  );

  constructor(private store: Store<LoginNamePartialState>) {}

  loginName(username: string) {
    this.store.dispatch(new LoadLoginName(username));
  }
}
