import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'durationFormat'
})
export class DurationFormatComponent implements PipeTransform {
  transform(millseconds: number, format?:any): any {
    return this.durationFilter(millseconds, format);
  }
  public durationFilter(millseconds, format) {

    let minutes = Math.floor((millseconds % 60));
    let hours = Math.floor((millseconds % 1440) / 60);
    let days = Math.floor(millseconds / 1440);

    let timeString = '';


    // console.log('minutes: ' + minutes);
    // console.log('hours: ' + hours);
    // console.log('days: ' + days);
    // console.log('----------------');


    if (format == 'short') {
      if (days !== 0) {
        timeString += (days !== 1) ? (days + 'd') : (days + 'd');
      }
      if (hours !== 0) {
        timeString += (hours !== 1) ? (hours + 'h') : (hours + 'h');
      }
      if (minutes !== 0) {
        timeString += (minutes !== 1) ? (minutes + 'm') : (minutes + 'm');
      }
      return timeString;
    } else if (format == 'dayOnly') {
      if (days !== 0) {
        timeString += (days !== 1) ? (days) : (days);
      }
      return timeString;
    } else if (format == 'defvn') {
      if (days !== 0) {
        timeString += (days !== 1) ? (days + 'd') : (days + 'd');
      }
      if (hours !== 0) {
        timeString += (hours !== 1) ? (hours + 'h') : (hours + 'h');
      }
      if (minutes !== 0) {
        timeString += (minutes !== 1) ? (minutes + 'm') : (minutes + 'm');
      }

      return timeString;
    } else {
      if (days !== 0) {
        timeString += (days !== 1) ? (days + ' ngày ') : (days + ' ngày ');
      }
      if (hours !== 0) {
        timeString += (hours !== 1) ? (hours + ' giờ ') : (hours + ' giờ ');
      }
      if (minutes !== 0) {
        timeString += (minutes !== 1) ? (minutes + ' phút ') : (minutes + ' phút ');
      }
      return timeString;
    }
  }
}
