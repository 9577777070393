import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GETUSERPROFILE_FEATURE_KEY,
  GetUserProfileState
} from './get-user-profile.reducer';

// Lookup the 'GetUserProfile' feature state managed by NgRx
const getGetUserProfileState = createFeatureSelector<GetUserProfileState>(
  GETUSERPROFILE_FEATURE_KEY
);

const getLoaded = createSelector(
  getGetUserProfileState,
  (state: GetUserProfileState) => state.loaded
);
const getError = createSelector(
  getGetUserProfileState,
  (state: GetUserProfileState) => state.error
);

const getAllGetUserProfile = createSelector(
  getGetUserProfileState,
  getLoaded,
  (state: GetUserProfileState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getGetUserProfileState,
  (state: GetUserProfileState) => state.selectedId
);
const getSelectedGetUserProfile = createSelector(
  getAllGetUserProfile,
  getSelectedId,
  (getUserProfile, id) => {
    return getUserProfile ? Object.assign({}, getUserProfile) : undefined;
  }
);

export const getUserProfileQuery = {
  getLoaded,
  getError,
  getAllGetUserProfile,
  getSelectedGetUserProfile
};
