import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AccountResourceService } from '@gtd/b2c-client';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { MatDialog, MatSnackBar } from '@angular/material';
import { isPlatformBrowser } from '@angular/common';
import { SnackbarComponent } from '@gtd/components/snackbar';

@Component({
  selector: 'gtd-qrcode-page',
  templateUrl: './qrcode-page.component.html',
  styleUrls: ['./qrcode-page.component.scss']
})
export class QrcodePageComponent implements OnInit {
  referralCodeInvalid = false;
  constructor(
    private accountResourceService: AccountResourceService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params && params.id) {
        this.accountResourceService
          .validateReferenceCodeUsingGET(params.id)
          .subscribe(validate => {
            if (validate) {
              if (!validate.success) {
                this.referralCodeInvalid = true;
                this.snackbar.openFromComponent(SnackbarComponent, {
                  data: {
                    message: 'user.reference-code-error-message',
                    loading: false
                  },
                  panelClass: 'snackbar-error',
                  duration: 3000,
                  verticalPosition: 'top'
                });
                // this.dialog
                //   .open(DialogMessageComponent, {
                //     data: {
                //       btn: [{ btnText: 'global.close' }],
                //       content: 'user.' + validate.errors[0]['code'],
                //       heading: 'user.reference-code-error',
                //       icon: 'exclamation-triangle',
                //       messageType: 'error'
                //     },
                //     panelClass: 'dialog-message',
                //     disableClose: true,
                //     position: {
                //       top: '150px'
                //     }
                //   })
                //   .afterClosed()
                //   .subscribe(afterClose => {
                //     this.router.navigate(['/']);
                //   });
              }
            }
          });
      }
    });
  }
  scrollToAmenities(el: HTMLElement) {
    if (isPlatformBrowser(this.platformId)) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
