import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DatepickerOnlyComponent } from './datepicker-only/datepicker-only.component';
import {
  MatIconModule,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';
import {
  CustomDatepickerI18n,
  DateFormatDirective,
  TranslateDatePicker
} from './date-format.directive';
import {
  NewDateFormatVN,
  NewDatepickerComponent
} from './new-datepicker/new-datepicker.component';
import { UserDatepickerComponent } from './user-datepicker/user-datepicker.component';
import { ComboDatepickerOnlyComponent } from './combo-datepicker-only/combo-datepicker-only.component';
import { TourDatepickerComponent } from './tour-datepicker/tour-datepicker.component';
import { FilterDatepickerComponent } from './filter-datepicker/filter-datepicker.component';

library.add(faCalendarDay);

@NgModule({
  imports: [
    CommonModule,
    NgbDatepickerModule,
    FontAwesomeModule,
    MatIconModule,
    MatRippleModule,
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    FormsModule,
    DeviceDetectorModule.forRoot()
  ],
  declarations: [
    DatepickerComponent,
    DatepickerComponent,
    DatepickerOnlyComponent,
    DateFormatDirective,
    NewDatepickerComponent,
    UserDatepickerComponent,
    TourDatepickerComponent,
    ComboDatepickerOnlyComponent,
    NewDateFormatVN,
    FilterDatepickerComponent
  ],
  exports: [
    DatepickerComponent,
    DatepickerOnlyComponent,
    DateFormatDirective,
    NewDatepickerComponent,
    ComboDatepickerOnlyComponent,
    UserDatepickerComponent,
    TourDatepickerComponent,
    FilterDatepickerComponent
  ],
  providers: [TranslateDatePicker, CustomDatepickerI18n],
  bootstrap: [DatepickerComponent]
})
export class DatepickerModule {}
