import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Inject
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { SwUpdate } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  CovidDialogComponent,
  CovidDialogService
} from '@gtd/components/covid-dialog';
import { environment } from '@env/environment';

import { FinalBookingDetailFacade } from '@gtd/booking-result';
import { CannyFeedbackDialogComponent } from 'libs/components/dialog-message/src/lib/canny-feedback-dialog/canny-feedback-dialog.component';
import { CannyTokenResourceService } from '@gtd/b2c-client';
import { CannyTokenB2bResourceService } from '@gtd/api-services/b2b-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gtd-client-button',
  templateUrl: './client-button.component.html',
  styleUrls: ['./client-button.component.scss'],
  providers: [FinalBookingDetailFacade]
})
export class ClientButtonComponent implements OnInit {
  isShowList: boolean;
  isUpdated: boolean;
  isShowFeedbackButton = false;
  isDrag = false;
  allFinalBookingDetail$ = this.finalBookingDetailFacade.allFinalBookingDetail$;
  appName = localStorage.getItem('appName');
  subscriptions = new Subscription();

  constructor(
    private updates: SwUpdate,
    private _ref: ElementRef,
    public dialog: MatDialog,
    private cannyTokenResourceService: CannyTokenResourceService,
    private cannyTokenB2bResourceService: CannyTokenB2bResourceService,
    public deviceService: DeviceDetectorService,
    private finalBookingDetailFacade: FinalBookingDetailFacade,
    private _covidService: CovidDialogService
  ) {
    updates.available.subscribe(() => (this.isUpdated = true));
  }

  @HostListener('document:click', ['$event'])
  clickOutside() {
    if (!this._ref.nativeElement.contains(event.target)) {
      this.isShowList = false;
    }
  }

  ngOnInit() {
    if (this.appName !== 'B2B2C') {
      this.isShowFeedbackButton = true;
    }
  }

  toggleMenuList() {
    this.isShowList = !this.isShowList;
  }

  openClientTrackingDialog() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    const clientTracking: string = localStorage.getItem('client-tracking');
    const dialogContent = `
      Client ID : ${clientTracking}<br/>
      Browser: ${deviceInfo.browser}<br/>
      Browser version: ${deviceInfo.browser_version}<br/>
      Os: ${deviceInfo.os}<br/>
      Os version: ${deviceInfo.os_version}
    `;

    this.dialog.open(DialogMessageComponent, {
      data: {
        content: dialogContent
      },
      panelClass: 'dialog-message',
      disableClose: false
    });
  }

  openCovidWarning() {
    this._covidService.loadData().subscribe(res => {
      if (res) {
        const dialogRef = this.dialog.open(CovidDialogComponent, {
          data: {
            btn: [{ btnText: 'global.close' }],
            messageType: 'success',
            heading: res[0].title,
            content: res[0].description,
            dataNotifications: res[0].dataNotifications,
            time: res[0].lastModifiedDate
          },
          width: '100vw',
          maxWidth: '100vw',
          position: {
            bottom: '0'
          },
          autoFocus: false,
          panelClass: 'covid-warning-component',
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(actions => {});
      }
    });
  }

  newUpdateSnackBar() {
    if (this.isUpdated) {
      this.updates.activateUpdate().then(() => document.location.reload());
    }
  }

  openFeedbackCanny() {
    if (!this.isDrag) {
      this.isShowList = false;
      let createCannyToken$: any;
      if (this.appName === 'B2C') {
        createCannyToken$ = this.cannyTokenResourceService.createTokenUsingGET();
      } else if (this.appName === 'B2B') {
        createCannyToken$ = this.cannyTokenB2bResourceService.createTokenUsingGET();
      }
      createCannyToken$.subscribe(
        token => {
          this.dialog.open(CannyFeedbackDialogComponent, {
            data: {
              token
            },
            autoFocus: false,
            panelClass: 'canny-feedback-dialog',
            disableClose: true,
            position: {
              top: '50px'
            }
          });
        },
        error => {
          const token = error.error.text;
          this.dialog.open(CannyFeedbackDialogComponent, {
            data: {
              token
            },
            autoFocus: false,
            panelClass: 'canny-feedback-dialog',
            disableClose: true,
            position: {
              top: '50px'
            }
          });
        }
      );
    } else {
      this.isDrag = false;
    }
  }

  onDragStart() {
    this.isDrag = true;
  }
}
