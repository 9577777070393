/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CacheGroupBookingItem } from './cacheGroupBookingItem';


export interface BookingContentDTO { 
    bookingCode?: string;
    bookingContent?: string;
    bookingId?: number;
    bookingNumber?: string;
    channelType?: BookingContentDTO.ChannelTypeEnum;
    createdBy?: string;
    createdDate?: Date;
    groupBooking?: CacheGroupBookingItem;
    id?: number;
    saleChannel?: BookingContentDTO.SaleChannelEnum;
    supplierType?: BookingContentDTO.SupplierTypeEnum;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace BookingContentDTO {
    export type ChannelTypeEnum = 'ONLINE' | 'OFFLINE';
    export const ChannelTypeEnum = {
        ONLINE: 'ONLINE' as ChannelTypeEnum,
        OFFLINE: 'OFFLINE' as ChannelTypeEnum
    };
    export type SaleChannelEnum = 'B2B' | 'B2C' | 'B2B2C' | 'B2C_WEB' | 'B2C_WEB_APP' | 'B2C_MOBILE';
    export const SaleChannelEnum = {
        B2B: 'B2B' as SaleChannelEnum,
        B2C: 'B2C' as SaleChannelEnum,
        B2B2C: 'B2B2C' as SaleChannelEnum,
        B2CWEB: 'B2C_WEB' as SaleChannelEnum,
        B2CWEBAPP: 'B2C_WEB_APP' as SaleChannelEnum,
        B2CMOBILE: 'B2C_MOBILE' as SaleChannelEnum
    };
    export type SupplierTypeEnum = 'AIR' | 'HOTEL' | 'COMBO' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const SupplierTypeEnum = {
        AIR: 'AIR' as SupplierTypeEnum,
        HOTEL: 'HOTEL' as SupplierTypeEnum,
        COMBO: 'COMBO' as SupplierTypeEnum,
        TOURS: 'TOURS' as SupplierTypeEnum,
        TRAIN: 'TRAIN' as SupplierTypeEnum,
        SHIP: 'SHIP' as SupplierTypeEnum,
        OTHER: 'OTHER' as SupplierTypeEnum
    };
}
