import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryAvatarComponent } from './library-avatar.component';
import {
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule
} from '@angular/material';
import { FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LibraryAvatarEffects } from './+state/library-avatar.effects';
import { LibraryAvatarFacade } from './+state/library-avatar.facade';
import { NxModule } from '@nrwl/angular';
import {BASE_PATH, CustomerAvatarResourceService} from "@gtd/api-services/customer-service";
import {
  LIBRARYAVATAR_FEATURE_KEY,
  initialState as libraryAvatarInitialState,
  reducer
} from "./+state/library-avatar.reducer";
import {environment} from "@env/environment";
import {TranslateModule} from "@ngx-translate/core";
import {AgentAvatarResourceService} from "@gtd/api-services/agentsrv";

@NgModule({
  declarations: [LibraryAvatarComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatDialogModule,
    ImageCropperModule,
    MatButtonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(LIBRARYAVATAR_FEATURE_KEY, reducer, {
      initialState: libraryAvatarInitialState
    }),
    EffectsModule.forFeature([LibraryAvatarEffects]),
    TranslateModule
  ],
  entryComponents: [LibraryAvatarComponent],
  exports: [LibraryAvatarComponent],
  providers: [{provide: BASE_PATH, useValue: environment.api.customerService}, LibraryAvatarFacade, CustomerAvatarResourceService, AgentAvatarResourceService]
})
export class LibraryAvatarModule {}
