import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({
  providedIn: 'root'
})
export class DeviceMessageService {

  constructor(private deviceService: DeviceDetectorService) { }

  sendMessageDevice(message: any) {
    let deviceInfo = this.deviceService.getDeviceInfo();
    if (deviceInfo.os === 'Android') {
      this.sendToAndroid(message);
    }
    if (
      deviceInfo.device === 'iPad' ||
      deviceInfo.device === 'iPhone' ||
      deviceInfo.os === 'iOS'
    ) {
      this.sendToiOS(message);
    }
  }
  sendToiOS(message: any) {
    if ((window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers.iOSTransfer) {
      (window as any).webkit.messageHandlers.iOSTransfer.postMessage(message);
    } else {
      console.error('iOS message handler not available.');
    }
  }
  sendToAndroid(message: any) {
    if ((window as any).Android && (window as any).Android.sendMessage) {
      (window as any).Android.sendMessage(message);
    } else {
      console.error('Android sendMessage method not available.');
    }
  }
}
