/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreditRuleDTO } from './creditRuleDTO';


export interface DepartmentDTO { 
    agencyId: number;
    costCenter?: string;
    createdBy: string;
    createdDate: Date;
    creditRules?: Array<CreditRuleDTO>;
    departmentCode: string;
    departmentName: string;
    disDeptCode: string;
    id?: number;
    parentDepartmentId?: number;
    status?: DepartmentDTO.StatusEnum;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace DepartmentDTO {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'PENDING';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum,
        PENDING: 'PENDING' as StatusEnum
    };
}
