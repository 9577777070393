import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CurrencyExchangePipe} from "./currency-exchange.pipe";

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [CurrencyExchangePipe],
  declarations: [CurrencyExchangePipe],
  exports: [CurrencyExchangePipe]
})
export class CurrencyExchangeModule {}
