import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatDialog, MatSnackBar} from "@angular/material";
import {AccountResourceService} from "@gtd/api-services/b2b-client";
import {ResponseEntity} from "@gtd/b2c-client";
import {DialogMessageComponent} from "@gtd/components/dialog-message";
import {Router} from "@angular/router";
import {fadeIn, fadeInOut} from "@gtd/helpers";

@Component({
  selector: 'gtd-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss'],
  animations: [
    fadeInOut,
    fadeIn
  ]
})
export class RequestPasswordComponent implements OnInit {
  hide = true;
  isLoading = false;
  requestPasswordForm!: FormGroup;
  subscriptions = new Subscription();

  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    private accountResourceService: AccountResourceService
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  submitForm() {
    this.isLoading = true;
    this.markFormTouched(this.requestPasswordForm);
    this.accountResourceService.requestPasswordResetUsingPOST(this.requestPasswordForm.get('email').value).subscribe(requestNewPassword => {
      if(requestNewPassword && requestNewPassword.statusCode === ResponseEntity.StatusCodeEnum._200) {
        let dialogRef = this.dialog.open(DialogMessageComponent ,{
          data : {
            btn: [
              {btnText: 'global.close', action: 'Login'}
            ],
            content :'user.message-send-email-request-new-password',
            dataTranslate: this.requestPasswordForm.controls['email'].value,
            heading: 'user.heading-request-new-password',
            imgIcon: 'assets/icons/envelope.png',
            messageType: 'success'
          },
          panelClass: 'dialog-message',
          position: {
            top: '200px'
          },
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(closeModal => {
          if (closeModal.action === 'Login') {
            this.router.navigate(['/auth/login']);
          }
        })
      } else {
        this.requestPasswordForm.controls['email'].setErrors(['notRegister']);
      }
    }, response => {
      if(response.status === 200) {
        this.dialog.open(DialogMessageComponent ,{
          data : {
            btn: [
              {btnText: 'global.close'}
            ],
            content :'user.message-send-email-request-new-password',
            dataTranslate: this.requestPasswordForm.controls['email'].value,
            heading: 'user.heading-request-new-password',
            imgIcon: 'assets/icons/envelope.png',
            messageType: 'success'
          },
          panelClass: 'dialog-message',
          position: {
            top: '200px'
          },
          disableClose: true
        });
      } else {
        this.requestPasswordForm.controls['email'].setErrors(['notRegister']);
      }
    });
  }

  markFormTouched(group: FormGroup) {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup) { control.markAsTouched(); this.markFormTouched(control); }
      else { control.markAsTouched(); };
    });
  };

  private createForm() {
    this.requestPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
      rememberMe: true
    });
  }

  getErrorMessage() {
    return this.requestPasswordForm.controls['email'].hasError('required')
      ? 'user.email-can-not-be-empty'
      : this.requestPasswordForm.controls['email'].errors !== null && this.requestPasswordForm.controls['email'].errors[0]==='notRegister'
        ? 'user.email-phone-not-exist'
        : this.requestPasswordForm.controls['email'].hasError('pattern') && (this.requestPasswordForm.controls['email'].dirty || this.requestPasswordForm.controls['email'].touched) ? 'Please enter valid email address or phone number': ''
  }
}
