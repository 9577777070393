import {
  Component,
  Inject,
  Input,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  BookingInfo,
  BookingTransactionInfo,
  GroupBooking
} from '@gtd/b2c-client';

@Component({
  selector: 'gtd-booking-result-tour',
  templateUrl: './booking-result-tour.component.html',
  styleUrls: ['./booking-result-tour.component.scss']
})
export class BookingResultTourComponent implements OnInit {
  @Input() allFinalBookingDetail: GroupBooking;
  @Input() paxInfos: any;
  appName = '';
  constructor(@Inject('appName') appName: string) {
    if (appName) {
      this.appName = appName;
    }
  }

  counter = Array;

  ngOnInit() {}
}
