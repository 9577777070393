export type HotelSortAttributeEnum =
  | 'guestRating'
  | 'starRating'
  | 'order'
  | 'price';
export const HotelSortAttributeEnum = {
  GUESTRATING: 'guestRating' as HotelSortAttributeEnum,
  STARRATING: 'starRating' as HotelSortAttributeEnum,
  ORDER: 'order' as HotelSortAttributeEnum,
  PRICE: 'price' as HotelSortAttributeEnum
};

export type HotelSortTypeEnum = 'ASC' | 'DESC';
export const HotelSortTypeEnum = {
  ASC: 'ASC' as HotelSortTypeEnum,
  DESC: 'DESC' as HotelSortTypeEnum
};
