/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BranchDTO { 
    address1?: string;
    address2?: string;
    agencyCode?: string;
    agencyId?: number;
    branchCode?: string;
    branchName?: string;
    cityCode?: string;
    cityName?: string;
    contactPerson1?: string;
    contactPerson2?: string;
    contactPerson3?: string;
    contactPhoneNumber1?: string;
    contactPhoneNumber2?: string;
    contactPhoneNumber3?: string;
    countryCode?: string;
    countryName?: string;
    createdBy?: string;
    createdDate?: Date;
    defaultBranch?: boolean;
    email?: string;
    faxNumber?: string;
    id?: number;
    note?: string;
    orgCode?: string;
    stateCode?: string;
    stateName?: string;
    status?: BranchDTO.StatusEnum;
    taxCode?: string;
    updatedBy?: string;
    updatedDate?: Date;
    workPhoneNumber1?: string;
    workPhoneNumber2?: string;
}
export namespace BranchDTO {
    export type StatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'OTHER';
    export const StatusEnum = {
        ACTIVATED: 'ACTIVATED' as StatusEnum,
        DEACTIVATED: 'DEACTIVATED' as StatusEnum,
        OTHER: 'OTHER' as StatusEnum
    };
}
