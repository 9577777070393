import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { RequestPasswordStatePartialState } from './request-password-state.reducer';
import { requestPasswordStateQuery } from './request-password-state.selectors';
import {
  LoadDestroyPasswordState,
  LoadRequestPasswordState,
  LoadPhoneRequestPasswordState
} from './request-password-state.actions';

@Injectable()
export class RequestPasswordStateFacade {
  loaded$ = this.store.pipe(select(requestPasswordStateQuery.getLoaded));
  allRequestPasswordState$ = this.store.pipe(
    select(requestPasswordStateQuery.getAllRequestPasswordState)
  );
  selectedRequestPasswordState$ = this.store.pipe(
    select(requestPasswordStateQuery.getSelectedRequestPasswordState)
  );

  constructor(private store: Store<RequestPasswordStatePartialState>) {}

  requestPasswordByEmail(email: string) {
    this.store.dispatch(new LoadRequestPasswordState(email));
  }
  requestPasswordByPhone(email: any) {
    this.store.dispatch(new LoadPhoneRequestPasswordState(email));
  }
  destroyRequestPassword() {
    this.store.dispatch(new LoadDestroyPasswordState());
  }
}
