import {Pipe, PipeTransform} from '@angular/core'
import {DurationFormatComponent} from "../duration-format/duration-format.component";

@Pipe({
  name: 'transitTime'
})
export class TransitTimeComponent implements PipeTransform {
  transform(nextTime: Date, currentTime?:Date): any {
    return this.transitTime(currentTime, nextTime);
  }
  transitTime(currentTime, nextTime) {
    let one_Minute=1000*60;
    let date1 = new Date(currentTime);
    let date2 = new Date(nextTime);
    let difference_ms =  date2.getTime() - date1.getTime();
    const transitTime = DurationFormatComponent.prototype.durationFilter(Math.round(difference_ms/one_Minute), 'short');
    return transitTime;
  }
}
