/**
 * customersrv API
 * customersrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Message { 
    content?: string;
    contentType?: string;
    createdBy?: string;
    createdDate?: Date;
    eventId?: number;
    excerpt?: string;
    finished?: number;
    imageUrl?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    messageId?: number;
    messageType?: Message.MessageTypeEnum;
    name?: string;
    redirectUrl?: string;
    schedule?: Date;
    status?: Message.StatusEnum;
    tag?: string;
    totalSlot?: number;
}
export namespace Message {
    export type MessageTypeEnum = 'PRIVATE' | 'BROADCAST' | 'PAYMENT_PROMPT' | 'CHECKIN_PROMPT' | 'CONFIRMED_HOLD' | 'CONFIRMED_TICKET' | 'OTHER';
    export const MessageTypeEnum = {
        PRIVATE: 'PRIVATE' as MessageTypeEnum,
        BROADCAST: 'BROADCAST' as MessageTypeEnum,
        PAYMENTPROMPT: 'PAYMENT_PROMPT' as MessageTypeEnum,
        CHECKINPROMPT: 'CHECKIN_PROMPT' as MessageTypeEnum,
        CONFIRMEDHOLD: 'CONFIRMED_HOLD' as MessageTypeEnum,
        CONFIRMEDTICKET: 'CONFIRMED_TICKET' as MessageTypeEnum,
        OTHER: 'OTHER' as MessageTypeEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'UNACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum,
        UNACTIVE: 'UNACTIVE' as StatusEnum
    };
}
