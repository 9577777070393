/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BookingDiscountRQ { 
    bookingNumber?: string;
    discountAmount?: number;
    discountRedeemCode?: string;
    discountRedeemId?: string;
    discountTrackingCode?: string;
    discountType?: BookingDiscountRQ.DiscountTypeEnum;
    discountVoucherCode?: string;
    discountVoucherName?: string;
    ibeSessionId?: string;
    promotionID?: Array<string>;
    requesterCode?: string;
    requesterType?: BookingDiscountRQ.RequesterTypeEnum;
}
export namespace BookingDiscountRQ {
    export type DiscountTypeEnum = 'CREDIT_CARD' | 'AIRLINES_CODE' | 'VOUCHER' | 'NORMAL';
    export const DiscountTypeEnum = {
        CREDITCARD: 'CREDIT_CARD' as DiscountTypeEnum,
        AIRLINESCODE: 'AIRLINES_CODE' as DiscountTypeEnum,
        VOUCHER: 'VOUCHER' as DiscountTypeEnum,
        NORMAL: 'NORMAL' as DiscountTypeEnum
    };
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
