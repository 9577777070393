import { Action } from '@ngrx/store';
import { HotelMetaKeyword } from '@gtd/b2c-client';

export enum ComboSearchInformationActionTypes {
  ComboVJSearchInformation = '[ComboVJ_ComboVJ] Load SearchInformation',
  ComboVJSearchInformationLoaded = '[ComboVJ_SearchInformation] SearchInformation Loaded',
  ComboVJSearchInformationLoadError = '[ComboVJ_SearchInformation] SearchInformation Load Error'
}

export class ComboVJSearchInformation implements Action {
  readonly type = ComboSearchInformationActionTypes.ComboVJSearchInformation;
  constructor(public payload: any) {}
}

export class ComboVJSearchInformationLoadError implements Action {
  readonly type =
    ComboSearchInformationActionTypes.ComboVJSearchInformationLoadError;
  constructor(public payload: any) {}
}

export class ComboVJSearchInformationLoaded implements Action {
  readonly type =
    ComboSearchInformationActionTypes.ComboVJSearchInformationLoaded;
  constructor(public payload: any) {}
}

export type ComboSearchInformationAction =
  | ComboVJSearchInformation
  | ComboVJSearchInformationLoadError
  | ComboVJSearchInformationLoaded;

export const fromComboSearchInformationActions = {
  ComboVJSearchInformation,
  ComboVJSearchInformationLoadError,
  ComboVJSearchInformationLoaded
};
