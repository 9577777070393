import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { SearchKeywordElement } from '@gtd/b2c-client';
import { HotelFacade, PopularPlacesFacade } from '@gtd/meta';
import { AutocompleteSearchKeywordFacade } from 'libs/hotel/src/state/autocomplete-search-keyword/autocomplete-search-keyword.facade';
import { SearchKeywordFacade } from 'libs/hotel/src/state/search-keyword/search-keyword.facade';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HotelSearchService } from '../hotel-search.service';

export interface InputField {
  stateCtrl: string;
  hotelName: string;
}
@Component({
  selector: 'gtd-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    HotelFacade,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DestinationComponent),
      multi: true
    }
  ]
})
export class DestinationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() type: any;
  form: FormGroup;
  focused = false;
  isShiftFocus: boolean;
  stateCtrl = new FormControl();
  hotelName = new FormControl();
  stateChanges = new Subject<void>();
  groupSearchLabel: string = 'destination-popular';
  destinationDTOs: SearchKeywordElement[];
  destination: any;
  errorState = false;
  isContentPage = false;

  allSearchKeyword$ = this.searchKeywordFacade.allSearchKeyword$;
  allPopularPlaces$ = this.popularPlacesFacade.allPopularPlaces$;
  constructor(
    public fb: FormBuilder,
    private hotelSearchService: HotelSearchService,
    private searchKeywordFacade: SearchKeywordFacade,
    private popularPlacesFacade: PopularPlacesFacade,
    private autocompleteSearchKeywordFacade: AutocompleteSearchKeywordFacade
  ) {}
  ngOnInit() {
    this.form = this.fb.group({
      stateCtrl: '',
      hotelName: ''
    });
    this.popularPlacesFacade.getPopularDestination('FAVOURITE_HOTEL_LOCATION');
    this.destinationDTOs = this.hotelSearchService.getAllLocalStorageLocation(
      this.type
    );

    this.stateCtrl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((data: any) => {
        if (typeof data === 'string') {
          const currentLanguage = localStorage.getItem('language');
          let language: 'vi' | 'en';
          if (currentLanguage === 'vi') {
            language = 'vi';
          } else if (currentLanguage === 'en') {
            language = 'en';
          }
          this.searchKeywordFacade.loadAll(data, language, 0, 15);
        }
        if (data || data !== '') {
          this.groupSearchLabel = 'list-destination';
          if (!data.searchCode) {
            let searchData = {
              name: data,
              propertyCount: null,
              searchCode: data,
              searchType: 'AUTO',
              supplier: 'EXPEDIA'
            };
            this.onChange(searchData);
            this.onTouched();
          } else {
            this.onChange(data);
            this.onTouched();
          }
        } else {
          this.onChange('');
          this.onTouched();
        }
      });
    // this.setDefaultHotel(this.stateCtrl.value);
  }

  ngOnChanges(changes: SimpleChanges) {}

  destinationSelected(destination: any) {
    this.autocompleteSearchKeywordFacade.addSearchKeywordHotel([destination]);
    this.destination = destination;
    this.stateCtrl.setValue(destination);
    this.focused = false;
  }
  displayFn(hotel?: SearchKeywordElement) {
    return hotel && hotel.name ? `${hotel.name}` : hotel;
  }
  closePanel() {
    return setTimeout(() => {
      this.focused = false;
    }, 50);
  }
  get value(): InputField {
    let n = this.form.value;
    return n;
  }
  set value(sf: InputField) {
    setTimeout(() => {
      this.setDefaultHotel(sf);
    }, 100);
  }
  setDefaultHotel = (sf: InputField) => {
    setTimeout(() => {
      let getHotelLocation = this.hotelSearchService.getAllLocalStorageLocation(
        this.type
      );
      setTimeout(() => {
        if (getHotelLocation) {
          let hotelLocation = getHotelLocation.filter(
            code => code.name === sf.toLocaleString()
          );
          if (
            hotelLocation &&
            hotelLocation[0] &&
            hotelLocation[0].name === sf.toLocaleString()
          ) {
            this.stateCtrl.setValue(hotelLocation[0]);
            this.autocompleteSearchKeywordFacade.addSearchKeywordHotel(
              hotelLocation
            );
          }
        }
        this.stateChanges.next();
      }, 100);
    });
  };
  ngOnDestroy(): void {}

  @Input()
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
