/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PromotionMetaSatisfyDTO { 
    compareType?: PromotionMetaSatisfyDTO.CompareTypeEnum;
    id?: number;
    metaCondition?: Array<string>;
    metaKey?: string;
    metaValue?: Array<string>;
    note?: string;
}
export namespace PromotionMetaSatisfyDTO {
    export type CompareTypeEnum = 'EQUALITY' | 'NEGATION' | 'GREATER_THAN' | 'LESS_THAN' | 'LIKE' | 'STARTS_WITH' | 'ENDS_WITH' | 'CONTAINS' | 'IN' | 'BETWEEN' | 'IS' | 'CONTAIN_SPLIT';
    export const CompareTypeEnum = {
        EQUALITY: 'EQUALITY' as CompareTypeEnum,
        NEGATION: 'NEGATION' as CompareTypeEnum,
        GREATERTHAN: 'GREATER_THAN' as CompareTypeEnum,
        LESSTHAN: 'LESS_THAN' as CompareTypeEnum,
        LIKE: 'LIKE' as CompareTypeEnum,
        STARTSWITH: 'STARTS_WITH' as CompareTypeEnum,
        ENDSWITH: 'ENDS_WITH' as CompareTypeEnum,
        CONTAINS: 'CONTAINS' as CompareTypeEnum,
        IN: 'IN' as CompareTypeEnum,
        BETWEEN: 'BETWEEN' as CompareTypeEnum,
        IS: 'IS' as CompareTypeEnum,
        CONTAINSPLIT: 'CONTAIN_SPLIT' as CompareTypeEnum
    };
}
