import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  initialState as loginNameInitialState,
  LOGINNAME_FEATURE_KEY,
  loginNameReducer
} from './login-name/login-name.reducer';
import { LoginNameFacade } from './login-name/login-name.facade';
import { NxModule } from '@nrwl/angular';
import { environment } from '@env/environment';
import { BASE_PATH } from '../lib/variables';
import { AgentProfileResourceService } from '../lib/api/agentProfileResource.service';
import { EffectsModule } from '@ngrx/effects';
import { LoginNameEffects } from './login-name/login-name.effects';
import * as fromGetReferral from './get-referral/get-referral.reducer';
import { GetReferralEffects } from './get-referral/get-referral.effects';
import { GetReferralFacade } from './get-referral/get-referral.facade';
import { ReferralResourceService } from '../lib/index';
import * as fromGetInviteCode from './get-invite-code/get-invite-code.reducer';
import { GetInviteCodeEffects } from './get-invite-code/get-invite-code.effects';
import { GetInviteCodeFacade } from './get-invite-code/get-invite-code.facade';
import * as fromReportReferral from './report-referral/report-referral.reducer';
import { ReportReferralEffects } from './report-referral/report-referral.effects';
import { ReportReferralFacade } from './report-referral/report-referral.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(LOGINNAME_FEATURE_KEY, loginNameReducer, {
      initialState: loginNameInitialState
    }),

    EffectsModule.forFeature([LoginNameEffects]),

    StoreModule.forFeature(
      fromGetReferral.GETREFERRAL_FEATURE_KEY,
      fromGetReferral.reducerReferral
    ),

    EffectsModule.forFeature([GetReferralEffects]),

    StoreModule.forFeature(
      fromGetInviteCode.GETINVITECODE_FEATURE_KEY,
      fromGetInviteCode.reducerInviteCode
    ),

    EffectsModule.forFeature([GetInviteCodeEffects]),

    StoreModule.forFeature(
      fromReportReferral.REPORTREFERRAL_FEATURE_KEY,
      fromReportReferral.reducer
    ),

    EffectsModule.forFeature([ReportReferralEffects])
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.agentsrv },
    LoginNameFacade,
    AgentProfileResourceService,
    GetReferralFacade,
    ReferralResourceService,
    GetInviteCodeFacade,
    ReportReferralFacade
  ]
})
export class AgentsrvStateModule {}
