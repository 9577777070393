import { Action } from '@ngrx/store';
import { AirFareRuleRQ, AirFareRuleRS } from '@gtd/b2c-client';

export enum FarerulesActionTypes {
  ComboVJLoadFarerules = '[ComboVJ_Farerules] Load Farerules',
  ComboVJFarerulesLoaded = '[ComboVJ_Farerules] Farerules Loaded',
  ComboVJFarerulesLoadError = '[ComboVJ_Farerules] Farerules Load Error'
}

export class ComboVJLoadFarerules implements Action {
  readonly type = FarerulesActionTypes.ComboVJLoadFarerules;
  constructor(public payload: AirFareRuleRQ, public language: string) {}
}

export class ComboVJFarerulesLoadError implements Action {
  readonly type = FarerulesActionTypes.ComboVJFarerulesLoadError;
  constructor(public payload: any) {}
}

export class ComboVJFarerulesLoaded implements Action {
  readonly type = FarerulesActionTypes.ComboVJFarerulesLoaded;
  constructor(public payload: AirFareRuleRS) {}
}

export type FarerulesAction =
  | ComboVJLoadFarerules
  | ComboVJFarerulesLoaded
  | ComboVJFarerulesLoadError;

export const fromFarerulesActions = {
  ComboVJLoadFarerules,
  ComboVJFarerulesLoaded,
  ComboVJFarerulesLoadError
};
