import {Directive} from '@angular/core';

@Directive({
  selector: '[gtdTimeCountDownBetween]'
})
export class TimeCountDownBetweenDirective {

  constructor() {}

  timeBetweenDates(toDate: Date): TimeCountDown {
    let dateEntered =  new Date(toDate).toISOString();
    let now2 = new Date(new Date).toISOString();
    let difference = Date.parse(dateEntered) - Date.parse(now2);
    if (difference <= 0) {
      clearInterval(0);
      return {
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00"
      }
    } else {
      let seconds = Math.floor(difference / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      hours %= 24;
      minutes %= 60;
      seconds %= 60;

      return {
        days: (days < 10)? '0'+days : days.toString(),
        hours: (hours < 10)? '0'+hours : hours.toString(),
        minutes: (minutes < 10)? '0'+minutes : minutes.toString(),
        seconds: (seconds < 10)? '0'+seconds : seconds.toString()
      }
    }
  }
}
export interface TimeCountDown {
  days: string,
  hours: string,
  minutes: string,
  seconds: string
}
