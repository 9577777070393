import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Pipe,
  PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { HotelDetailFacade } from '../../state/hotel-detail/hotel-detail.facade';
import * as moment from 'moment';
import { HotelResourceV3Service } from '@gtd/b2c-client';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { isPlatformBrowser } from '@angular/common';
import { SearchBestRatesFacade } from '../../state/search-best-rates/search-best-rates.facade';
import { first, skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {PixelTrackingService} from "@gtd/pixel-tracking";

@Component({
  selector: 'gtd-hotel-detail',
  templateUrl: './hotel-detail.component.html',
  styleUrls: ['./hotel-detail.component.scss']
})
export class HotelDetailComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  hotelDetailLoaded$ = this.hotelDetailFacade.loaded$;
  allHotelDetail$ = this.hotelDetailFacade.allHotelDetail$;
  roomsArrs: any;
  hotelDetailParams: any;
  searchParams: any;
  unsubscribe$ = new Subject();
  params: any;
  private utm_source = localStorage.getItem('utm_source');
  constructor(
    private activatedRoute: ActivatedRoute,
    private hotelDetailFacade: HotelDetailFacade,
    private serializer: UrlSerializer,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private hotelV3ResourceService: HotelResourceV3Service,
    private router: Router,
    private _elementRef: ElementRef,
    private searchBestRatesFacade: SearchBestRatesFacade,
    private _pixelTrackingService: PixelTrackingService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.activatedRoute.queryParams.subscribe(param => {
      this._handleQueryFromParams(
        JSON.parse(decodeURIComponent(atob(param.params)))
      );
    });
  }

  ngOnInit() {
    this.allHotelDetail$.subscribe(allHotel => {
      if(allHotel && allHotel.success && allHotel.result && allHotel.result.propertyAllRate && allHotel.result.propertyAllRate.propertyName)
      this._pixelTrackingService.track('ViewContent', {
        value: '[HOTEL] - ' + allHotel.result.propertyAllRate.propertyName
      });
    });
    this.searchBestRatesFacade.allSearchBestRates$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        searchBestRates => {
          if (searchBestRates) {
            if (
              searchBestRates.success &&
              searchBestRates.result.propertyAvailable.length
            ) {
              this.params['searchId'] = searchBestRates.result.searchId;

              const url = this.router.createUrlTree(['/hotel/detail'], {
                queryParams: {
                  params: btoa(encodeURIComponent(JSON.stringify(this.params)))
                }
              });
              let newWindow = window.open('', '_self');
              newWindow.location.href = decodeURIComponent(
                this.serializer.serialize(url)
              );
            } else {
              this._snackBar.dismiss();
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [{ btnText: 'global.close' }],
                  content: 'user.system-error-message',
                  heading: 'user.system-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true,
                position: {
                  top: '150px'
                }
              });
            }
          }
        },
        () => {
          this._snackBar.dismiss();
          this.dialog.open(DialogMessageComponent, {
            data: {
              btn: [{ btnText: 'global.close' }],
              content: 'user.system-error-message',
              heading: 'user.system-error',
              icon: 'exclamation-triangle',
              messageType: 'error'
            },
            panelClass: 'dialog-message',
            disableClose: true
          });
        }
      );
  }

  scrollTo(el: string) {
    if (isPlatformBrowser(this.platformId)) {
      let off =
        this._elementRef.nativeElement.querySelector(`#${el}`).offsetTop -
        document.querySelector('.header-layout').clientHeight -
        46;
      window.scrollTo({ behavior: 'smooth', top: off });
    }
  }
  changeSearchDetail(event) {
    if (event) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'hotel-detail.changing-search', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });

      let roomsArrs = event.rooms.room;
      let roomsString = [];
      if (roomsArrs.length > 0) {
        roomsArrs.forEach(roomsArr => {
          let childrendString = '';
          if (roomsArr.childrenAges && roomsArr.childrenAges.length > 0) {
            roomsArr.childrenAges.forEach(childrend => {
              childrendString = childrendString
                ? childrendString + ',' + childrend
                : childrend;
            });
          }
          let room = childrendString
            ? roomsArr.adultQuantity + '-' + childrendString
            : roomsArr.adultQuantity;
          roomsString.push(room);
        });
      } else {
        roomsString.push(roomsArrs);
      }

      let searchObject = JSON.parse(atob(this.hotelDetailParams.searchId));
      this.hotelV3ResourceService.defaultHeaders = this.hotelV3ResourceService.defaultHeaders.set(
        'sales-env',
        ''
      );
      this.params = {
        locationCode: this.hotelDetailParams.locationCode,
        locationName: this.hotelDetailParams.locationName,
        searchType: this.hotelDetailParams.searchType,
        roomsString: roomsString,
        checkIn: moment(event.datePicker.fromDate).format('YYYY-MM-DD'),
        checkOut: moment(event.datePicker.toDate).format('YYYY-MM-DD'),
        propertyId: this.hotelDetailParams.propertyId,
        supplier: this.hotelDetailParams.supplier,
        paxInfos: JSON.stringify(event.rooms.room)
      };
      this.searchBestRatesFacade.loadAll({
        searchCode: this.hotelDetailParams.propertyId,
        searchType: 'HOTEL',
        supplier: this.hotelDetailParams.supplier,
        language: searchObject.language,
        currency: 'VND',
        checkIn: moment(event.datePicker.fromDate).format('YYYY-MM-DD'),
        checkOut: moment(event.datePicker.toDate).format('YYYY-MM-DD'),
        paxInfos: roomsString,
        filterStarRating: [],
        pageNumber: 0,
        pageSize: 15
      });
    }
  }

  ngOnDestroy(): void {
    this.hotelDetailFacade.resetLoadAll();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  scrollToAmenities(el: HTMLElement) {
    if (isPlatformBrowser(this.platformId)) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private _handleQueryFromParams(param) {
    this.searchParams = param;
    let roomsArrs = JSON.parse(param.paxInfos);
    let roomsString = [];
    if (roomsArrs.length > 0) {
      roomsArrs.forEach(roomsArr => {
        let childrendString = '';
        if (roomsArr.childrenAges && roomsArr.childrenAges.length > 0) {
          roomsArr.childrenAges.forEach(childrend => {
            childrendString = childrendString
              ? childrendString + ',' + childrend
              : childrend;
          });
        }
        let room = childrendString
          ? roomsArr.adultQuantity + '-' + childrendString
          : roomsArr.adultQuantity;
        roomsString.push(room);
      });
    } else {
      roomsString.push(roomsArrs);
    }
    this.roomsArrs = roomsArrs;
    this.hotelDetailParams = param;
    this.hotelDetailFacade.loadAll({
      searchId: param.searchId,
      propertyId: param.propertyId,
      supplier: param.supplier,
      checkIn: param.checkIn,
      checkOut: param.checkOut,
      paxInfos: roomsString
    });
  }
}
@Pipe({ name: 'round' })
export class RoundPipe {
  transform(input: any) {
    return parseFloat(input).toFixed(1);
  }
}
@Pipe({ name: 'percentPrice' })
export class PercentPrice {
  transform(price: number, basePriceBeforePromo: number) {
    let percent = 100 - (price * 100) / basePriceBeforePromo;
    return Math.round(percent);
  }
}
