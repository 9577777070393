import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchResultModule} from "./search-result/search-result.module";
import {FlightFormSearchModule} from './flight-form-search/flight-form-search.module';

@NgModule({
  imports: [
    CommonModule,
    SearchResultModule,
    FlightFormSearchModule
  ],
  entryComponents: []
})
export class FlightModule {}
