/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CancelPenalty } from './cancelPenalty';


export interface HotelCheckCancelPenaltyRS { 
    cancelPenalties?: Array<CancelPenalty>;
    cancelPenaltyTotal?: number;
    status?: HotelCheckCancelPenaltyRS.StatusEnum;
}
export namespace HotelCheckCancelPenaltyRS {
    export type StatusEnum = 'ALLOW_CANCELLATION' | 'NOT_ALLOW_CANCELLATION' | 'UNKNOWN';
    export const StatusEnum = {
        ALLOWCANCELLATION: 'ALLOW_CANCELLATION' as StatusEnum,
        NOTALLOWCANCELLATION: 'NOT_ALLOW_CANCELLATION' as StatusEnum,
        UNKNOWN: 'UNKNOWN' as StatusEnum
    };
}
