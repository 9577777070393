import {Action} from '@ngrx/store';

export enum DeleteNotificationActionTypes {
  LoadDeleteNotification = '[DeleteNotification] Load DeleteNotification',
  DeleteNotificationLoaded = '[DeleteNotification] DeleteNotification Loaded',
  DeleteNotificationLoadError = '[DeleteNotification] DeleteNotification Load Error'
}

export class LoadDeleteNotification implements Action {
  readonly type = DeleteNotificationActionTypes.LoadDeleteNotification;
  constructor(public payload: {userId: number, messageId: number}) {}
}

export class DeleteNotificationLoadError implements Action {
  readonly type = DeleteNotificationActionTypes.DeleteNotificationLoadError;
  constructor(public payload: any) {}
}

export class DeleteNotificationLoaded implements Action {
  readonly type = DeleteNotificationActionTypes.DeleteNotificationLoaded;
  constructor(public payload: any) {}
}

export type DeleteNotificationAction =
  | LoadDeleteNotification
  | DeleteNotificationLoaded
  | DeleteNotificationLoadError;

export const fromDeleteNotificationActions = {
  LoadDeleteNotification,
  DeleteNotificationLoaded,
  DeleteNotificationLoadError
};
