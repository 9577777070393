import { Component, Input, OnInit } from '@angular/core';
import { RegisterStateFacade } from './register-state/+state/register-state.facade';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'gtd-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  hide = true;
  isLoading: boolean;
  subscriptions = new Subscription();
  @Input() referralCode: boolean;
  @Input() referralCodeInvalid = false;
  constructor(private registerStateFacade: RegisterStateFacade) {}

  ngOnInit() {
    this.registerStateFacade.resetAllRegister();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
