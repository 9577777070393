/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PartnerPaymentReq { 
    amount?: number;
    bookingCode?: string;
    bookingNumber?: string;
    desc?: string;
    ibeSessionId?: string;
    isMobile?: boolean;
    mobile?: boolean;
    orderCancelUrl?: string;
    orderId?: string;
    orderReturnUrl?: string;
    requesterCode?: string;
    requesterType?: PartnerPaymentReq.RequesterTypeEnum;
}
export namespace PartnerPaymentReq {
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
