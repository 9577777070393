import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { NotificationsPartialState } from './notifications.reducer';
import { notificationsQuery } from './notifications.selectors';
import {LoadNotifications, ReqNotification} from './notifications.actions';

@Injectable()
export class NotificationsFacade {
  loaded$ = this.store.pipe(select(notificationsQuery.getLoaded));
  allNotifications$ = this.store.pipe(
    select(notificationsQuery.getAllNotifications)
  );
  selectedNotifications$ = this.store.pipe(
    select(notificationsQuery.getSelectedNotifications)
  );

  constructor(private store: Store<NotificationsPartialState>) {}

  loadAll(reqNotification: ReqNotification) {
    this.store.dispatch(new LoadNotifications(reqNotification));
  }
}
