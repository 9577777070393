import {Action} from '@ngrx/store';
import {GroupBooking} from "@gtd/b2c-client";

export enum BookingDetailActionTypes {
  LoadBookingDetail = '[BookingDetail] Load BookingDetail',
  BookingDetailLoaded = '[BookingDetail] BookingDetail Loaded',
  ResetBookingDetail = '[BookingDetail] BookingDetail Reset',
  BookingDetailLoadError = '[BookingDetail] BookingDetail Load Error'
}

export class LoadBookingDetail implements Action {
  readonly type = BookingDetailActionTypes.LoadBookingDetail;
  constructor(public payload: string) {}
}

export class BookingDetailLoadError implements Action {
  readonly type = BookingDetailActionTypes.BookingDetailLoadError;
  constructor(public payload: any) {}
}

export class BookingDetailLoaded implements Action {
  readonly type = BookingDetailActionTypes.BookingDetailLoaded;
  constructor(public payload: GroupBooking) {}
}

export class ResetBookingDetail implements Action {
  readonly type = BookingDetailActionTypes.ResetBookingDetail;
}

export type BookingDetailAction =
  | LoadBookingDetail
  | BookingDetailLoaded
  | ResetBookingDetail
  | BookingDetailLoadError;

export const fromBookingDetailActions = {
  LoadBookingDetail,
  BookingDetailLoaded,
  ResetBookingDetail,
  BookingDetailLoadError
};
