import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelCreateDraftBookingPartialState } from './hotel-create-draft-booking.reducer';
import { hotelCreateDraftBookingQuery } from './hotel-create-draft-booking.selectors';
import { ComboVJLoadHotelCreateDraftBooking } from './hotel-create-draft-booking.actions';
import { CreateDraftBookingHotelPayload } from '@gtd/b2c-client';

@Injectable()
export class HotelCreateDraftBookingFacade {
  loaded$ = this.store.pipe(select(hotelCreateDraftBookingQuery.getLoaded));
  allHotelCreateDraftBooking$ = this.store.pipe(
    select(hotelCreateDraftBookingQuery.getAllHotelCreateDraftBooking)
  );
  selectedHotelCreateDraftBooking$ = this.store.pipe(
    select(hotelCreateDraftBookingQuery.getSelectedHotelCreateDraftBooking)
  );
  errorHotelCreateDraftBooking$ = this.store.pipe(
    select(hotelCreateDraftBookingQuery.getError)
  );
  constructor(private store: Store<HotelCreateDraftBookingPartialState>) {}

  createDraftBooking(
    createDraftBookingHotelPayload: CreateDraftBookingHotelPayload
  ) {
    this.store.dispatch(
      new ComboVJLoadHotelCreateDraftBooking(createDraftBookingHotelPayload)
    );
  }
}
