import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { NotificationsPartialState } from './notifications.reducer';
import {
  LoadNotifications,
  NotificationsLoaded,
  NotificationsLoadError,
  NotificationsActionTypes
} from './notifications.actions';
import {MessageItemResourceService} from "@gtd/api-services/customer-service";
import { NotifyUserMessageResourceService } from '@gtd/api-services/utilitysrv';

import {map} from "rxjs/operators";

@Injectable()
export class NotificationsEffects {
  @Effect() loadNotifications$ = this.dataPersistence.fetch(
    NotificationsActionTypes.LoadNotifications,
    {
      run: (action: LoadNotifications, state: NotificationsPartialState) => {
        return this.notifyUserMessageResourceService.getNotifyMessByUserRefCodeUsingGET(action.payload.userRefCode, action.payload.page, action.payload.size, action.payload.sort).pipe(
          map(payload => ({
            type: NotificationsActionTypes.NotificationsLoaded,
            payload: payload
          }))
        );
      },

      onError: (action: LoadNotifications, error) => {
        console.error('Error', error);
        return new NotificationsLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private messageItemResourceService: MessageItemResourceService,
    private notifyUserMessageResourceService: NotifyUserMessageResourceService,
    private dataPersistence: DataPersistence<NotificationsPartialState>
  ) {}
}
