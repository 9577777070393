import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BookingDTO } from '@gtd/api-services/b2b-client';

@Component({
  selector: 'gtd-create-additional-request-dialog',
  templateUrl: './create-additional-request-dialog.component.html',
  styleUrls: ['./create-additional-request-dialog.component.scss']
})
export class CreateAdditionalRequestDialogComponent implements OnInit {
  additionalRequestTypeList = [];
  additionalRequestForm = this.formBuilder.group({
    type: ['AIR_ADD_PACKAGE', Validators.required],
    content: ['', Validators.required]
  });
  currentAdditionalRequestName = '';
  passengerNameRecordsList: any = {};
  flightReturnDate: any;
  comboReturnDate: any;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateAdditionalRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      bookingInfo: any;
      propertyName: string;
    }
  ) {}

  ngOnInit() {
    if (this.data.bookingInfo.supplierType === 'AIR') {
      this.additionalRequestTypeList = [
        {
          code: 'AIR_ADD_PACKAGE',
          name: 'booking.buy-signed-luggage-for-ticket'
        },
        {
          code: 'AIR_ADD_INFORMATION',
          name: 'booking.change-information-on-ticket'
        },
        {
          code: 'AIR_DELETE_BOOKING',
          name: 'booking.refund-cancellation-ticket'
        },
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    } else if (this.data.bookingInfo.supplierType === 'HOTEL') {
      this.additionalRequestTypeList = [
        {
          code: 'HOTEL_ADD_PERSON',
          name: 'booking.more-people'
        },
        {
          code: 'HOTEL_EDIT_BOOKING',
          name: 'booking.change-room-information'
        },
        {
          code: 'HOTEL_CHANGE_ROOM',
          name: 'booking.change-room'
        },
        {
          code: 'HOTEL_DELETE_BOOKING',
          name: 'booking.refund-cancellation-room'
        },
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    } else if (this.data.bookingInfo.supplierType === 'COMBO') {
      this.additionalRequestTypeList = [
        {
          code: 'AIR_ADD_PACKAGE',
          name: 'booking.buy-signed-luggage-for-ticket'
        },
        {
          code: 'AIR_ADD_INFORMATION',
          name: 'booking.change-information-on-ticket'
        },
        {
          code: 'AIR_DELETE_BOOKING',
          name: 'booking.refund-cancellation-ticket'
        },
        {
          code: 'HOTEL_ADD_PERSON',
          name: 'booking.more-people'
        },
        {
          code: 'HOTEL_EDIT_BOOKING',
          name: 'booking.change-room-information'
        },
        {
          code: 'HOTEL_CHANGE_ROOM',
          name: 'booking.change-room'
        },
        {
          code: 'HOTEL_DELETE_BOOKING',
          name: 'booking.refund-cancellation-room'
        },
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    } else if (this.data.bookingInfo.supplierType === 'TOURS') {
      this.additionalRequestTypeList = [
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    }
    if (
      this.data &&
      this.data.bookingInfo &&
      this.data.bookingInfo.transactionInfos.length > 0
    ) {
      this.data.bookingInfo.transactionInfos.forEach(element => {
        if (element.supplierType === 'AIR') {
          if (element.bookingDirection === 'DEPARTURE') {
            this.passengerNameRecordsList.FLIGHT_DEPARTURE =
              element.passengerNameRecord;
          } else if (element.bookingDirection === 'RETURN') {
            this.flightReturnDate = element.checkOut;
            this.passengerNameRecordsList.FLIGHT_RETURN =
              element.passengerNameRecord;
          } else if (element.bookingDirection === 'TRIP') {
            this.flightReturnDate = element.checkOut;
            this.passengerNameRecordsList.FLIGHT_DEPARTURE =
              element.passengerNameRecord;
            this.passengerNameRecordsList.FLIGHT_RETURN =
              element.passengerNameRecord;
          }
        } else if (element.supplierType === 'HOTEL') {
          this.comboReturnDate = element.checkOut;
          this.passengerNameRecordsList.HOTEL = element.passengerNameRecord;
        } else if (element.supplierType === 'TOURS') {
          this.passengerNameRecordsList.TOURS = element.passengerNameRecord;
        }
      });
    }
    if (this.data && this.data.type) {
      this.currentAdditionalRequestName = this.additionalRequestTypeList.find(
        item => item.code === this.data.type
      ).name;
      this.additionalRequestForm.patchValue({
        type: this.data.type
      });
    }
  }

  updateTypeValue(type: string) {
    this.currentAdditionalRequestName = this.additionalRequestTypeList.find(
      item => item.code === type
    ).name;
    this.additionalRequestForm.patchValue({
      type: type
    });
  }

  sendRequest() {
    if (this.additionalRequestForm.invalid) {
      return;
    }
    this.dialogRef.close({
      action: 'send-request',
      data: {
        type: this.additionalRequestForm.get('type').value,
        content: this.additionalRequestForm.get('content').value
      }
    });
  }

  closeDialog() {
    this.dialogRef.close({ action: '' });
  }
}
