import { Action } from '@ngrx/store';
import { OtaResultOfFilterOptionsResult } from '@gtd/b2c-client';

export enum FilterOptionsActionTypes {
  ComboVJLoadFilterOptions = '[ComboVJ_FilterOptions] Load FilterOptions',
  ComboVJFilterOptionsLoaded = '[ComboVJ_FilterOptions] FilterOptions Loaded',
  ComboVJFilterOptionsLoadError = '[ComboVJ_FilterOptions] FilterOptions Load Error'
}

export class ComboVJLoadFilterOptions implements Action {
  readonly type = FilterOptionsActionTypes.ComboVJLoadFilterOptions;
  constructor(public payload: 'vi' | 'en') {}
}

export class ComboVJFilterOptionsLoadError implements Action {
  readonly type = FilterOptionsActionTypes.ComboVJFilterOptionsLoadError;
  constructor(public payload: any) {}
}

export class ComboVJFilterOptionsLoaded implements Action {
  readonly type = FilterOptionsActionTypes.ComboVJFilterOptionsLoaded;
  constructor(public payload: OtaResultOfFilterOptionsResult) {}
}

export type FilterOptionsAction =
  | ComboVJLoadFilterOptions
  | ComboVJFilterOptionsLoaded
  | ComboVJFilterOptionsLoadError;

export const fromFilterOptionsActions = {
  ComboVJLoadFilterOptions,
  ComboVJFilterOptionsLoaded,
  ComboVJFilterOptionsLoadError
};
