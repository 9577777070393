import {Action} from '@ngrx/store';
import {HotelMetaKeyword} from "@gtd/b2c-client";

export enum HotelSearchKeywordActionTypes {
  AutoCompleteSearchKeyword = '[AutoCompleteSearchKeyword] Load AutoCompleteSearchKeyword',
  AutoCompleteSearchKeywordLoaded = '[AutoCompleteSearchKeyword] AutoCompleteSearchKeyword Loaded',
  AutoCompleteSearchKeywordLoadError = '[AutoCompleteSearchKeyword] AutoCompleteSearchKeyword Load Error'
}

export class AutoCompleteSearchKeyword implements Action {
  readonly type = HotelSearchKeywordActionTypes.AutoCompleteSearchKeyword;
  constructor(public payload: HotelMetaKeyword[]) {}
}

export class AutoCompleteSearchKeywordLoadError implements Action {
  readonly type = HotelSearchKeywordActionTypes.AutoCompleteSearchKeywordLoadError;
  constructor(public payload: any) {}
}

export class AutoCompleteSearchKeywordLoaded implements Action {
  readonly type = HotelSearchKeywordActionTypes.AutoCompleteSearchKeywordLoaded;
  constructor(public payload: HotelMetaKeyword[]) {}
}

export type HotelSearchKeywordAction =
  | AutoCompleteSearchKeyword
  | AutoCompleteSearchKeywordLoadError
  | AutoCompleteSearchKeywordLoaded;

export const fromHotelSearchKeywordActions = {
  AutoCompleteSearchKeyword,
  AutoCompleteSearchKeywordLoadError,
  AutoCompleteSearchKeywordLoaded
};
