/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { BranchDTO } from '../model/branchDTO';
import { OtaSearchResultOfListOfBranchDTO } from '../model/otaSearchResultOfListOfBranchDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BranchResourceService {

    protected basePath = 'https://10.7.71.20:8182';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changeStatus
     *
     * @param id id
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeStatusUsingPUT3(id: number, status: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeStatusUsingPUT3(id: number, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeStatusUsingPUT3(id: number, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeStatusUsingPUT3(id: number, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling changeStatusUsingPUT3.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling changeStatusUsingPUT3.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/branches/${encodeURIComponent(String(id))}/status`,
            status,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createBranch
     *
     * @param branchDTO branchDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBranchUsingPOST(branchDTO: BranchDTO, observe?: 'body', reportProgress?: boolean): Observable<BranchDTO>;
    public createBranchUsingPOST(branchDTO: BranchDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BranchDTO>>;
    public createBranchUsingPOST(branchDTO: BranchDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BranchDTO>>;
    public createBranchUsingPOST(branchDTO: BranchDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (branchDTO === null || branchDTO === undefined) {
            throw new Error('Required parameter branchDTO was null or undefined when calling createBranchUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BranchDTO>(`${this.basePath}/api/branches`,
            branchDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteBranch
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBranchUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteBranchUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteBranchUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteBranchUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteBranchUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/branches/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllBranches
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBranchesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<BranchDTO>>;
    public getAllBranchesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BranchDTO>>>;
    public getAllBranchesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BranchDTO>>>;
    public getAllBranchesUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BranchDTO>>(`${this.basePath}/api/branches`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBranch
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBranchUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<BranchDTO>;
    public getBranchUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BranchDTO>>;
    public getBranchUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BranchDTO>>;
    public getBranchUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBranchUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BranchDTO>(`${this.basePath}/api/branches/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjects
     *
     * @param branchName branchName
     * @param contactPerson contactPerson
     * @param cityCode cityCode
     * @param phoneNumber phoneNumber
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectsUsingGET3(branchName: string, contactPerson: string, cityCode: string, phoneNumber: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfListOfBranchDTO>;
    public searchObjectsUsingGET3(branchName: string, contactPerson: string, cityCode: string, phoneNumber: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfListOfBranchDTO>>;
    public searchObjectsUsingGET3(branchName: string, contactPerson: string, cityCode: string, phoneNumber: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfListOfBranchDTO>>;
    public searchObjectsUsingGET3(branchName: string, contactPerson: string, cityCode: string, phoneNumber: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (branchName === null || branchName === undefined) {
            throw new Error('Required parameter branchName was null or undefined when calling searchObjectsUsingGET3.');
        }

        if (contactPerson === null || contactPerson === undefined) {
            throw new Error('Required parameter contactPerson was null or undefined when calling searchObjectsUsingGET3.');
        }

        if (cityCode === null || cityCode === undefined) {
            throw new Error('Required parameter cityCode was null or undefined when calling searchObjectsUsingGET3.');
        }

        if (phoneNumber === null || phoneNumber === undefined) {
            throw new Error('Required parameter phoneNumber was null or undefined when calling searchObjectsUsingGET3.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (branchName !== undefined && branchName !== null) {
            queryParameters = queryParameters.set('branchName', <any>branchName);
        }
        if (contactPerson !== undefined && contactPerson !== null) {
            queryParameters = queryParameters.set('contactPerson', <any>contactPerson);
        }
        if (cityCode !== undefined && cityCode !== null) {
            queryParameters = queryParameters.set('cityCode', <any>cityCode);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaSearchResultOfListOfBranchDTO>(`${this.basePath}/api/_search/branches`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBranch
     *
     * @param branchDTO branchDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBranchUsingPUT(branchDTO: BranchDTO, observe?: 'body', reportProgress?: boolean): Observable<BranchDTO>;
    public updateBranchUsingPUT(branchDTO: BranchDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BranchDTO>>;
    public updateBranchUsingPUT(branchDTO: BranchDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BranchDTO>>;
    public updateBranchUsingPUT(branchDTO: BranchDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (branchDTO === null || branchDTO === undefined) {
            throw new Error('Required parameter branchDTO was null or undefined when calling updateBranchUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BranchDTO>(`${this.basePath}/api/branches`,
            branchDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
