/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustMemberCard } from './custMemberCard';


export interface AgentCustomerTO { 
    address?: string;
    adultType?: AgentCustomerTO.AdultTypeEnum;
    agencyCode?: string;
    agentCode?: string;
    agentProfileId?: number;
    branchCode?: string;
    branchName?: string;
    city?: string;
    companyAddress?: string;
    companyBranchName?: string;
    companyName?: string;
    companyTaxCode?: string;
    country?: string;
    customerClass?: AgentCustomerTO.CustomerClassEnum;
    dateOfBirth?: string;
    department?: string;
    dob?: Date;
    documentType?: string;
    email?: string;
    eventDate?: number;
    expiredDate?: Date;
    expiredDateStr?: string;
    firstName?: string;
    frequencyCards?: Array<CustMemberCard>;
    gender?: AgentCustomerTO.GenderEnum;
    id?: number;
    identityNumber?: string;
    issuingCountry?: string;
    lastName?: string;
    memberCard?: boolean;
    nationality?: string;
    nationalityName?: string;
    nickName?: string;
    notes?: string;
    passportNumber?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    position?: string;
    postalCode?: string;
    status?: AgentCustomerTO.StatusEnum;
}
export namespace AgentCustomerTO {
    export type AdultTypeEnum = 'ADT' | 'CHD' | 'INF';
    export const AdultTypeEnum = {
        ADT: 'ADT' as AdultTypeEnum,
        CHD: 'CHD' as AdultTypeEnum,
        INF: 'INF' as AdultTypeEnum
    };
    export type CustomerClassEnum = 'GOLD' | 'SILVER' | 'PLATINUM' | 'BASIC' | 'OTHER';
    export const CustomerClassEnum = {
        GOLD: 'GOLD' as CustomerClassEnum,
        SILVER: 'SILVER' as CustomerClassEnum,
        PLATINUM: 'PLATINUM' as CustomerClassEnum,
        BASIC: 'BASIC' as CustomerClassEnum,
        OTHER: 'OTHER' as CustomerClassEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'BOY' | 'GIRL' | 'INF';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        BOY: 'BOY' as GenderEnum,
        GIRL: 'GIRL' as GenderEnum,
        INF: 'INF' as GenderEnum
    };
    export type StatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'OTHER';
    export const StatusEnum = {
        ACTIVATED: 'ACTIVATED' as StatusEnum,
        DEACTIVATED: 'DEACTIVATED' as StatusEnum,
        OTHER: 'OTHER' as StatusEnum
    };
}
