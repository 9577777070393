import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelCheckoutPartialState } from './hotel-checkout.reducer';
import { hotelCheckoutQuery } from './hotel-checkout.selectors';
import { LoadHotelCheckout } from './hotel-checkout.actions';
import { CheckoutPayload } from '@gtd/b2c-client';

@Injectable()
export class HotelCheckoutFacade {
  loaded$ = this.store.pipe(select(hotelCheckoutQuery.getLoaded));
  allHotelCheckout$ = this.store.pipe(
    select(hotelCheckoutQuery.getAllHotelCheckout)
  );
  getError$ = this.store.pipe(select(hotelCheckoutQuery.getError));
  selectedHotelCheckout$ = this.store.pipe(
    select(hotelCheckoutQuery.getSelectedHotelCheckout)
  );

  constructor(private store: Store<HotelCheckoutPartialState>) {}

  hotelCheckout(checkoutPayload: CheckoutPayload) {
    this.store.dispatch(new LoadHotelCheckout(checkoutPayload));
  }
}
