import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, UrlSerializer } from '@angular/router';

import { DatePipe } from '@angular/common';
import { MustNotMatch } from '@gtd/helpers';
import {
  FormPassengersService,
  Passenger
} from './form-passengers/form-passengers.service';
import { FlightSearchService } from './flight-search.service';
import { FlightTypeSwitcherEnum } from './switcher/switcher.component';
import { AirportDTO } from '@gtd/meta-client';
import { HistorySearchKeywordFacade } from '../../state/history-search-keyword/+state-sesstion/history-search-keyword.facade';
import { AirportFacade } from '@gtd/meta';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'gtd-flight-form-search',
  templateUrl: './flight-form-search.component.html',
  styleUrls: ['./flight-form-search.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class FlightFormSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() pageName: string;
  @Input() fromLocation?: string = '';
  @Input() toLocation?: string = '';
  @Input() destinationPage = false;
  searchAir: FormGroup;
  airport: string[];
  submited: boolean;
  startDate = new Date();
  defaultFromDate = new Date();
  defaultToDate = new Date(
    Date.UTC(
      this.defaultFromDate.getFullYear(),
      this.defaultFromDate.getMonth(),
      this.defaultFromDate.getDate() + 1
    )
  );
  flightTypeSwitcherEnum = FlightTypeSwitcherEnum;
  dataSearchFlight = {};
  togleSwaps: boolean;
  getDataSearchFlight;
  fromLocalStorages: AirportDTO;
  toLocalStorages: AirportDTO;
  getAllAirportSession: any;
  subscriptions = new Subscription();
  filterCabinClass = '';

  constructor(
    private router: Router,
    private serializer: UrlSerializer,
    private passengerService: FormPassengersService,
    public datepipe: DatePipe,
    private historySearchKeywordFacade: HistorySearchKeywordFacade,
    private airportFacade: AirportFacade,
    private flightSearchService: FlightSearchService
  ) {
    this.getDataSearchFlight = JSON.parse(
      localStorage.getItem('dataSearchFlight')
    );
    if (
      this.getDataSearchFlight !== null &&
      this.compareDates(this.getDataSearchFlight.fromDate, this.startDate) === 1
    ) {
      this.defaultFromDate = new Date(this.getDataSearchFlight.fromDate);
      this.defaultToDate = new Date(this.getDataSearchFlight.toDate);
    } else {
      this.defaultFromDate = new Date();
      this.defaultToDate = new Date(
        Date.UTC(
          this.defaultFromDate.getFullYear(),
          this.defaultFromDate.getMonth(),
          this.defaultFromDate.getDate() + 1
        )
      );
    }
    this.searchAir = new FormGroup({
      fromLocal: new FormControl(
        this.getDataSearchFlight === null ? '' : this.getDataSearchFlight.from,
        Validators.required
      ),
      toLocal: new FormControl(
        this.getDataSearchFlight === null ? '' : this.getDataSearchFlight.to,
        Validators.required
      ),
      adultQuantity: new FormControl(
        this.getDataSearchFlight === null ? 1 : this.getDataSearchFlight.adult,
        Validators.required
      ),
      childQuantity: new FormControl(
        this.getDataSearchFlight === null ? 0 : this.getDataSearchFlight.child,
        Validators.required
      ),
      infantCount: new FormControl(
        this.getDataSearchFlight === null ? 0 : this.getDataSearchFlight.infant,
        Validators.required
      ),
      roundType: new FormControl(
        this.getDataSearchFlight === null
          ? FlightTypeSwitcherEnum.ONEWAY
          : this.getDataSearchFlight.roundType,
        Validators.required
      ),
      datePicker: new FormGroup({
        fromDate: new FormControl(this.defaultFromDate, Validators.required),
        toDate: new FormControl(this.defaultToDate)
      })
    });
    this.searchAir.setValidators(MustNotMatch('fromLocal', 'toLocal'));
  }

  ngOnInit() {
    this.passengerService.getPassenger.subscribe((passenger: Passenger) => {
      if (passenger) {
        this.searchAir.patchValue({
          adultQuantity: passenger.adultQuantity,
          childQuantity: passenger.childQuantity,
          infantCount: passenger.infantCount
        });
      }
    });
    this.searchAir.valueChanges.subscribe(valueChange => {
      setTimeout(() => {
        if (valueChange.roundType === FlightTypeSwitcherEnum.ROUNDTRIP) {
          this.searchAir.controls['datePicker']
            .get('toDate')
            .setValidators(Validators.required);
          if (
            this.searchAir.controls['datePicker'].get('toDate').value === '' ||
            this.searchAir.controls['datePicker']
              .get('toDate')
              .value.toString() === 'Invalid Date'
          ) {
            this.searchAir.controls['datePicker']
              .get('toDate')
              .setErrors({ incorrect: true });
          }
        } else {
          this.searchAir.controls['datePicker'].get('toDate').setErrors(null);
          this.searchAir.controls['datePicker']
            .get('toDate')
            .setValidators(null);
        }
      });

      if (valueChange.datePicker.fromDate) {
        this.defaultFromDate = valueChange.datePicker.fromDate;
        if (valueChange.datePicker.toDate) {
          this.defaultToDate = new Date(
            Date.UTC(
              valueChange.datePicker.toDate.getFullYear(),
              valueChange.datePicker.toDate.getMonth(),
              valueChange.datePicker.toDate.getDate()
            )
          );
        } else {
          this.defaultToDate = new Date(
            Date.UTC(
              valueChange.datePicker.fromDate.getFullYear(),
              valueChange.datePicker.fromDate.getMonth(),
              valueChange.datePicker.fromDate.getDate() + 1
            )
          );
        }
      }
    });

    this.subscriptions.add(
      this.historySearchKeywordFacade.allHistorySearchKeywordFlight$.subscribe(
        historySearchKeyword => {
          this.getAllAirportSession = historySearchKeyword;
        }
      )
    );
    if (!this.destinationPage) {
      const fromLocalStoragesTemp = this.flightSearchService.getAllLocalStorageLocation(
        'from'
      );
      const toLocalStoragesTemp = this.flightSearchService.getAllLocalStorageLocation(
        'to'
      );
      if (
        fromLocalStoragesTemp &&
        fromLocalStoragesTemp[0] &&
        toLocalStoragesTemp &&
        toLocalStoragesTemp[0]
      ) {
        this.fromLocalStorages = fromLocalStoragesTemp[0];
        this.toLocalStorages = toLocalStoragesTemp[0];
      }
    }
    // Get airport information for flight content screen
    this.airportFacade.allAirport$.subscribe(allAirport => {
      if (allAirport[0]) {
        if (allAirport[0].code === this.fromLocation) {
          this.fromLocalStorages = {
            city: allAirport[0].city,
            cityCode: allAirport[0].cityCode,
            code: allAirport[0].code,
            countryCode: allAirport[0].countryCode,
            country: allAirport[0].country
          };
        }
        if (allAirport[0].code === this.toLocation) {
          this.toLocalStorages = {
            city: allAirport[0].city,
            cityCode: allAirport[0].cityCode,
            code: allAirport[0].code,
            countryCode: allAirport[0].countryCode,
            country: allAirport[0].country
          };
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.destinationPage && changes.destinationPage.firstChange) {
      this.searchAir.controls['fromLocal'].reset();
      this.searchAir.controls['toLocal'].reset();
    }
    if (changes.fromLocation && !changes.fromLocation.firstChange) {
      this.airportFacade.loadAll(changes.fromLocation.currentValue, '');
    }
    if (changes.toLocation && !changes.toLocation.firstChange) {
      this.airportFacade.loadAll(changes.toLocation.currentValue, '');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  searchFlight() {
    this.setLocalStorageLocation();
    setTimeout(() => {
      this.dataSearchFlight = {
        from: this.searchAir.value.fromLocal,
        to: this.searchAir.value.toLocal,
        fromDate: this.datepipe.transform(
          this.searchAir.controls['datePicker'].get('fromDate').value,
          'yyyy-MM-dd'
        ),
        toDate:
          this.searchAir.value.roundType === FlightTypeSwitcherEnum.ROUNDTRIP &&
          this.datepipe.transform(
            this.searchAir.controls['datePicker'].get('toDate').value,
            'yyyy-MM-dd'
          )
            ? this.datepipe.transform(
              this.searchAir.controls['datePicker'].get('toDate').value,
              'yyyy-MM-dd'
            )
            : '',
        adult: this.searchAir.value.adultQuantity,
        child: this.searchAir.value.childQuantity,
        infant: this.searchAir.value.infantCount,
        roundType: this.searchAir.value.roundType,
        filterCabinClass: this.filterCabinClass
      };
      localStorage.setItem(
        'dataSearchFlight',
        JSON.stringify(this.dataSearchFlight)
      );

      this.router.navigate(['/flight'], {
        queryParams: {
          params: btoa(
            encodeURIComponent(JSON.stringify(this.dataSearchFlight))
          )
        }
      });

      // const url = this.router.createUrlTree(['/flight'], { queryParams: this.dataSearchFlight });
      // let newWindow = window.open('', '_self');
      // newWindow.location.href = decodeURIComponent(this.serializer.serialize(url));
    }, 200);
  }
  submit() {
    this.submited = true;
    if (this.searchAir.valid) {
      this.searchFlight();
    }
  }

  setLocalStorageLocation() {
    if (this.getAllAirportSession) {
      const fromLocalStoragesTemp = this.flightSearchService.getAllLocalStorageLocation(
        'from'
      );
      const toLocalStoragesTemp = this.flightSearchService.getAllLocalStorageLocation(
        'to'
      );
      let newFrom = new Array<AirportDTO>();
      let newTo = new Array<AirportDTO>();
      if (
        fromLocalStoragesTemp &&
        this.getAllAirportSession.from &&
        this.getAllAirportSession.from.length > 0
      ) {
        const from = fromLocalStoragesTemp.filter(
          fromLocal => fromLocal.code === this.getAllAirportSession.from[0].code
        );
        if (from.length > 0) {
          const index = fromLocalStoragesTemp.indexOf(from[0]);
          fromLocalStoragesTemp.splice(index, 1);
          fromLocalStoragesTemp.unshift(this.getAllAirportSession.from[0]);
          newFrom = fromLocalStoragesTemp;
        } else {
          fromLocalStoragesTemp.unshift(this.getAllAirportSession.from[0]);
          newFrom = fromLocalStoragesTemp;
        }
      } else {
        newFrom = this.getAllAirportSession.from;
      }
      if (
        toLocalStoragesTemp &&
        this.getAllAirportSession.to &&
        this.getAllAirportSession.to.length > 0
      ) {
        const to = toLocalStoragesTemp.filter(
          toLocal => toLocal.code === this.getAllAirportSession.to[0].code
        );
        if (to.length > 0) {
          const index = toLocalStoragesTemp.indexOf(to[0]);
          toLocalStoragesTemp.splice(index, 1);
          toLocalStoragesTemp.unshift(this.getAllAirportSession.to[0]);
          newTo = toLocalStoragesTemp;
        } else {
          toLocalStoragesTemp.unshift(this.getAllAirportSession.to[0]);
          newTo = toLocalStoragesTemp;
        }
      } else {
        newTo = this.getAllAirportSession.to;
      }
      if (newFrom && newFrom.length > 0) {
        localStorage.setItem('from', JSON.stringify(newFrom));
      }
      if (newTo && newTo.length > 0) {
        localStorage.setItem('to', JSON.stringify(newTo));
      }
    }
  }

  swapsLocation() {
    this.togleSwaps = !this.togleSwaps;
    let fromLocalStorages = new Array<AirportDTO>();
    let toLocalStorages = new Array<AirportDTO>();
    if (
      this.getAllAirportSession &&
      this.getAllAirportSession.from.length > 0
    ) {
      fromLocalStorages = this.getAllAirportSession.from;
    } else {
      fromLocalStorages = this.flightSearchService.getAllLocalStorageLocation(
        'from'
      );
    }
    if (this.getAllAirportSession && this.getAllAirportSession.to.length > 0) {
      toLocalStorages = this.getAllAirportSession.to;
    } else {
      toLocalStorages = this.flightSearchService.getAllLocalStorageLocation(
        'to'
      );
    }
    if (
      fromLocalStorages &&
      fromLocalStorages.length > 0 &&
      toLocalStorages &&
      toLocalStorages.length > 0
    ) {
      this.getAllAirportSession = {};
    }
    if (fromLocalStorages && fromLocalStorages.length > 0) {
      this.toLocalStorages = fromLocalStorages[0];
      this.getAllAirportSession.to = fromLocalStorages;
      this.searchAir.controls['toLocal'].setValue(
        this.searchAir.controls['fromLocal'].value
      );
      this.historySearchKeywordFacade.addSearchKeywordFlightTo(
        fromLocalStorages
      );
    }
    if (toLocalStorages && toLocalStorages.length > 0) {
      this.fromLocalStorages = toLocalStorages[0];
      this.getAllAirportSession.from = toLocalStorages;
      this.searchAir.controls['fromLocal'].setValue(
        this.searchAir.controls['toLocal'].value
      );
      this.historySearchKeywordFacade.addSearchKeywordFlightFrom(
        toLocalStorages
      );
    }
  }

  compareDates(date1: Date, date2: Date): number {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;
    if (d1 > d2) return 1;
    if (d1 < d2) return -1;
  }
}
