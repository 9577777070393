import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {
  initialState as policyByBookingInitialState,
  POLICYBYBOOKING_FEATURE_KEY,
  policyByBookingReducer
} from './policy-by-booking/policy-by-booking.reducer';
import {PolicyByBookingEffects} from './policy-by-booking/policy-by-booking.effects';
import {PolicyByBookingFacade} from './policy-by-booking/policy-by-booking.facade';
import {NxModule} from '@nrwl/angular';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from "@env/environment";
import {ApiDelayInsuranceService, BASE_PATH, InsuranceApiResourceService} from "@gtd/api-services/service-inventory";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(POLICYBYBOOKING_FEATURE_KEY, policyByBookingReducer, {
      initialState: policyByBookingInitialState
    }),
    EffectsModule.forFeature([PolicyByBookingEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [{ provide: BASE_PATH, useValue: environment.api.inventoryService }, PolicyByBookingFacade, ApiDelayInsuranceService, InsuranceApiResourceService]
})
export class InsurancePolicyByBookingModule {}
