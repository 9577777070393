import { Action } from '@ngrx/store';

export enum ComboBookingActionTypes {
  ComboVJComboBooking = '[ComboVJ_ComboBooking] Load ComboBooking',
  ComboVJComboBookingLoaded = '[ComboVJ_ComboBooking] ComboBooking Loaded',
  ComboVJComboBookingLoadError = '[ComboVJ_ComboBooking] ComboBooking Load Error'
}

export class ComboVJComboBooking implements Action {
  readonly type = ComboBookingActionTypes.ComboVJComboBooking;
  constructor(public payload: any) {}
}

export class ComboVJComboBookingLoadError implements Action {
  readonly type = ComboBookingActionTypes.ComboVJComboBookingLoadError;
  constructor(public payload: any) {}
}

export class ComboVJComboBookingLoaded implements Action {
  readonly type = ComboBookingActionTypes.ComboVJComboBookingLoaded;
  constructor(public payload: any) {}
}

export type ComboBookingAction =
  | ComboVJComboBooking
  | ComboVJComboBookingLoadError
  | ComboVJComboBookingLoaded;

export const fromComboBookingActions = {
  ComboVJComboBooking,
  ComboVJComboBookingLoadError,
  ComboVJComboBookingLoaded
};
