import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { AirportFacade, CitiesPopularFacade } from '@gtd/meta';
import { AirportDTO } from '@gtd/meta-client';
import { HistorySearchKeywordFacade } from '../../../state/history-search-keyword/+state-sesstion/history-search-keyword.facade';
import { ComboSearchService } from '../combo-search.service';

export interface InputField {
  stateCtrl: string;
}
@Component({
  selector: 'gtd-flight-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    AirportFacade,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlightDestinationComponent),
      multi: true
    }
  ]
})
export class FlightDestinationComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input() type: any;
  @Input() dataLocalStorages: AirportDTO;
  @Output() toLocalChange: EventEmitter<any> = new EventEmitter<any>(null);
  @ViewChild('inputRef', { static: false }) inputRef: ElementRef;
  form: FormGroup;
  focused = false;
  loaded$ = this.airportFacade.loaded$;

  allAirport$ = this.airportFacade.allAirport$;

  stateCtrl = new FormControl('');

  showInput = new FormControl();

  showResetButton = false;

  allAirportPopular$ = this.citiesPopularFacade.allCitiesPopular$;

  errorState = false;

  destination = null;

  airportDTOs: AirportDTO[];

  airportSearchLabel: string = 'airport-popular';

  constructor(
    public airportFacade: AirportFacade,
    private citiesPopularFacade: CitiesPopularFacade,
    private comboSearhService: ComboSearchService,
    private historySearchKeywordFacade: HistorySearchKeywordFacade,
    public fb: FormBuilder
  ) {
    this.form = fb.group({
      stateCtrl: ''
    });
    this.citiesPopularFacade.loadAll();
  }
  ngOnInit() {
    this.airportDTOs = this.comboSearhService.getAllLocalStorageLocation(
      this.type
    );
    this.stateCtrl.valueChanges.subscribe((data: any) => {
      this.airportFacade.loadAll(data, 'VN');
      if (data) {
        setTimeout(() => {
          this.airportSearchLabel = 'list-airport';
          this.airportDTOs = null;
          if (this.type === 'to') {
            this.toLocalChange.emit(data);
          }
          this.onChange(data.code);
          this.onTouched();
        });
      } else {
        this.onChange('');
        this.onTouched();
        this.airportDTOs = this.comboSearhService.getAllLocalStorageLocation(
          this.type
        );
      }
    });
    if (this.dataLocalStorages) {
      this.stateCtrl.setValue(this.dataLocalStorages);
    } else if (this.type === 'from') {
      this.stateCtrl.setValue({
        city: 'Hồ Chí Minh',
        city2: 'Ho Chi Minh City',
        cityCode: 'SGN',
        code: 'SGN',
        country: 'Vietnam',
        country2: 'Việt Nam',
        countryCode: 'VN',
        name: 'Sân bay Tân Sơn Nhất',
        name2: 'Tansonnhat Intl'
      });
      this.historySearchKeywordFacade.addSearchKeywordFlightFrom([
        {
          city: 'Hồ Chí Minh',
          city2: 'Ho Chi Minh City',
          cityCode: 'SGN',
          code: 'SGN',
          country: 'Vietnam',
          country2: 'Việt Nam',
          countryCode: 'VN',
          name: 'Sân bay Tân Sơn Nhất',
          name2: 'Tansonnhat Intl'
        }
      ]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataLocalStorages && !changes.dataLocalStorages.firstChange) {
      this.destination = changes.dataLocalStorages.currentValue;
      this.stateCtrl.setValue(changes.dataLocalStorages.currentValue);
      this.inputRef.nativeElement.value = this.displayValue(
        changes.dataLocalStorages.currentValue
      );
    }
  }

  loadAirport(data) {
    this.airportFacade.loadAll(data, '');
  }

  displayValue(destination) {
    return destination && destination.city && destination.code
      ? `${destination.city} (${destination.code})`
      : destination;
  }

  resetValue() {
    this.stateCtrl.reset();
  }
  closePanel() {
    return setTimeout(() => {
      this.focused = false;
    }, 50);
  }

  destinationSelected(destination: AirportDTO) {
    // this.showInput.setValue();
    if (this.type === 'from') {
      this.historySearchKeywordFacade.addSearchKeywordFlightFrom([destination]);
    }
    if (this.type === 'to') {
      this.historySearchKeywordFacade.addSearchKeywordFlightTo([destination]);
    }
    this.destination = destination;
    this.stateCtrl.setValue(destination);
    this.focused = false;
  }

  ngOnDestroy(): void {}

  @Input()
  get value(): InputField {
    let n = this.form.value;
    return n;
  }
  set value(sf: InputField) {
    setTimeout(() => {
      if (this.dataLocalStorages) {
        this.stateCtrl.setValue(this.dataLocalStorages);
        this.onChange(this.dataLocalStorages.code);
        this.onTouched();
      }
    }, 100);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {}

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
