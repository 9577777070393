import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FILTERAVAILABLE_FEATURE_KEY, FilterAvailableState} from './filter-available.reducer';


// Lookup the 'FilterAvailable' feature state managed by NgRx
const getFilterAvailableState = createFeatureSelector<FilterAvailableState>(
  FILTERAVAILABLE_FEATURE_KEY
);

const getAllCurrentFilter = createSelector(
  getFilterAvailableState,
  (state: FilterAvailableState) => state
);

const getCurrentFilterPrice = createSelector(
  getFilterAvailableState,
  (state: FilterAvailableState) => state && state.filterPrice? state.filterPrice: null
);

// const getCurrentAmenitiesFilter = createSelector(
//   getFilterAvailableState,
//   (state: FilterAvailableState) => state && state.filterAmenities? state.filterAmenities: null
// );

export const filterAvailableQuery = {
  getAllCurrentFilter,
  // getCurrentAmenitiesFilter,
  getCurrentFilterPrice
};
