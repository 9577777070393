import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FlightFormSearchComponent } from './flight-form-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SwitcherComponent } from './switcher/switcher.component';
import { AutoCompeteComponent } from './auto-compete/auto-compete.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatDividerModule,
  MatMenuModule,
  MatRadioModule,
  MatRippleModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { DatepickerModule } from '@gtd/components/datepicker';
import { FormPassengersComponent } from './form-passengers/form-passengers.component';
import { IncreamentInputModule } from '@gtd/components/increament-input';

import {
  faChevronDown,
  faMapMarkerAlt,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AirportModule } from '@gtd/meta';
import { FlightSearchModule } from '../search-result/flight-search/flight-search.module';
import { DataSearchFlightModule } from '../../state/history-search-keyword/history-search-keyword.module';
import { CovidDialogModule } from '@gtd/components/covid-dialog';

library.add(faChevronDown, faMapMarkerAlt, faSearch);

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    TranslateModule,
    MatRadioModule,
    DatepickerModule,
    MatMenuModule,
    IncreamentInputModule,
    MatDividerModule,
    FlexLayoutModule,
    MatButtonModule,
    AirportModule,
    MatRippleModule,
    FlightSearchModule,
    DataSearchFlightModule,
    CovidDialogModule
  ],
  declarations: [
    FlightFormSearchComponent,
    SwitcherComponent,
    AutoCompeteComponent,
    FormPassengersComponent
  ],
  exports: [FlightFormSearchComponent],
  providers: [DatePipe]
})
export class FlightFormSearchModule {}
