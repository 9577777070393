/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BlockrendererNameBody { 
    /**
     * Scope under which the request is made; determines fields present in response.
     */
    context?: Array<BlockrendererNameBody.ContextEnum>;
    /**
     * Attributes for the block.
     */
    attributes?: string;
    /**
     * ID of the post context.
     */
    postId?: string;
}
export namespace BlockrendererNameBody {
    export type ContextEnum = 'edit';
    export const ContextEnum = {
        Edit: 'edit' as ContextEnum
    };
}