import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticateFacade } from './+state/authenticate.facade';
import { NxModule } from '@nrwl/angular';
import { environment } from '@env/environment';
import {
  AUTHENTICATE_FEATURE_KEY,
  reducer,
  initialState as authenticateInitialState
} from './+state/authenticate.reducer';
import { AuthenticateEffects } from './+state/authenticate.effects';
import { BASE_PATH, UserJwtControllerService } from '@gtd/b2c-client';
import { AuthenticateService } from './authenticate.service';
import { SocialAuthFacade } from '../social-login/social-auth/+state/social-auth.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(AUTHENTICATE_FEATURE_KEY, reducer, {
      initialState: authenticateInitialState
    }),
    EffectsModule.forFeature([AuthenticateEffects])
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2cClient },
    AuthenticateFacade,
    SocialAuthFacade,
    UserJwtControllerService,
    AuthenticateService
  ]
})
export class AuthenticateModule {}
