import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FlightPaginationPartialState } from './flight-pagination.reducer';
import { flightPaginationQuery } from './flight-pagination.selectors';
import {
  ComboVJChangeFlightPaginationPage,
  ComboVJChangeFlightPaginationSize,
  ComboVJChangeFlightPaginationSort,
  ComboVJLoadFlightPagination
} from './flight-pagination.actions';

@Injectable()
export class FlightPaginationFacade {
  pagination$ = this.store.pipe(select(flightPaginationQuery.getPagination));
  page$ = this.store.pipe(select(flightPaginationQuery.getPaginationPage));
  size$ = this.store.pipe(select(flightPaginationQuery.getPaginationSize));
  sort$ = this.store.pipe(select(flightPaginationQuery.getPaginationSort));

  constructor(private store: Store<FlightPaginationPartialState>) {}

  loadPagination() {
    this.store.dispatch(new ComboVJLoadFlightPagination());
  }

  changePage(page: number) {
    this.store.dispatch(new ComboVJChangeFlightPaginationPage(page));
  }

  changeSort(sort: string[]) {
    this.store.dispatch(new ComboVJChangeFlightPaginationSort(sort));
  }

  changeSize(size: number) {
    this.store.dispatch(new ComboVJChangeFlightPaginationSize(size));
  }
}
