import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-footer-img',
  templateUrl: './footer-img.component.html',
  styleUrls: ['./footer-img.component.scss']
})
export class FooterImgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
