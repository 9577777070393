/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BookingNotificationRQ { 
    baseUrl?: string;
    billingCode?: string;
    bookingNumber?: string;
    ibeSessionId?: string;
    lang?: BookingNotificationRQ.LangEnum;
    requesterCode?: string;
    requesterType?: BookingNotificationRQ.RequesterTypeEnum;
}
export namespace BookingNotificationRQ {
    export type LangEnum = 'VIETNAMESE' | 'ENGLISH';
    export const LangEnum = {
        VIETNAMESE: 'VIETNAMESE' as LangEnum,
        ENGLISH: 'ENGLISH' as LangEnum
    };
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
