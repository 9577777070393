import {
  ComboVJChangeHotelFilter,
  ComboVJChangeSort,
  FilterAvailableAction,
  FilterAvailableActionTypes
} from './filter-available.actions';

export const FILTERAVAILABLE_FEATURE_KEY = 'comboVJFilterAvailable';

/**
 * Interface for the 'FilterAvailable' data used in
 *  - FilterAvailableState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FilterAvailableState {
  language: 'vi' | 'en';
  currency: 'VND' | 'USD';
  filterHotelName?: string;
  filterHotelCategories?: Array<string>;
  filterBreakfastIncluded?: boolean;
  filterPrice?: {
    fromPrice?: number;
    toPrice?: number;
  };
  filterStarRating?:Array<string>;
  filterGuestRating?: {
    from?: number;
    to?: number;
  };
  filterPropertyAmenities?: Array<string>;
  filterRoomAmenities?: Array<string>;
  filterRoomViews?: Array<string>;
  filterThemes?: Array<string>;
  filterMealPlans?: Array<string>;
  sort?: {
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating';
    sortOrder?: 'ASC' | 'DESC';
  };
  pageNumber?: number;
  pageSize?: number;
}

export interface FilterAvailablePartialState {
  readonly [FILTERAVAILABLE_FEATURE_KEY]: FilterAvailableState;
}

export const initialState: FilterAvailableState = null;

export function filterAvailableReducer(
  state: FilterAvailableState = initialState,
  action: FilterAvailableAction
): FilterAvailableState {
  switch (action.type) {
    case FilterAvailableActionTypes.ComboVJResetHotelFilter: {
      state = { ...initialState };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeHotelFilter: {
      state = action.payload;
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterHotelName: {
      state = {
        ...state,
        pageNumber: 0,
        filterHotelName: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterHotelCategories: {
      state = {
        ...state,
        pageNumber: 0,
        filterHotelCategories: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterBreakfastIncluded: {
      state = {
        ...state,
        pageNumber: 0,
        filterBreakfastIncluded: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterPrice: {
      state = {
        ...state,
        pageNumber: 0,
        filterPrice: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterStarRating: {
      state = {
        ...state,
        pageNumber: 0,
        filterStarRating: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterGuestRating: {
      state = {
        ...state,
        pageNumber: 0,
        filterGuestRating: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterRoomAmenities: {
      state = {
        ...state,
        pageNumber: 0,
        filterRoomAmenities: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterPropertyAmenities: {
      state = {
        ...state,
        pageNumber: 0,
        filterPropertyAmenities: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterRoomViews: {
      state = {
        ...state,
        pageNumber: 0,
        filterRoomViews: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterThemes: {
      state = {
        ...state,
        pageNumber: 0,
        filterThemes: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterMealPlans: {
      state = {
        ...state,
        pageNumber: 0,
        filterMealPlans: action.payload.slice()
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeSort: {
      state = {
        ...state,
        pageNumber: 0,
        sort: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangeFilterLanguage: {
      state = {
        ...state,
        language: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangePageNumber: {
      state = {
        ...state,
        pageNumber: action.payload
      };
      break;
    }
    case FilterAvailableActionTypes.ComboVJChangePageSize: {
      state = {
        ...state,
        pageSize: action.payload
      };
      break;
    }
  }
  return state;
}
