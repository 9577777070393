import { Action } from '@ngrx/store';
import { Entity } from './request-password-state.reducer';

export enum RequestPasswordStateActionTypes {
  LoadRequestPasswordState = '[RequestPasswordState] Load RequestPasswordState',
  RequestPasswordStateLoaded = '[RequestPasswordState] RequestPasswordState Loaded',
  RequestPasswordStateLoadError = '[RequestPasswordState] RequestPasswordState Load Error',

  LoadDestroyPasswordState = '[RequestPasswordState] Load DestroyPasswordState',

  LoadPhoneRequestPasswordState = '[RequestPasswordState] Load PhoneRequestPasswordState',
  PhoneRequestPasswordStateLoadError = '[RequestPasswordState] PhoneRequestPasswordState Loaded',
  PhoneRequestPasswordStateLoaded = '[RequestPasswordState] PhoneRequestPasswordState Load Error',

}

export class LoadRequestPasswordState implements Action {
  readonly type = RequestPasswordStateActionTypes.LoadRequestPasswordState;
  constructor(public payload: string) {};
}

export class RequestPasswordStateLoadError implements Action {
  readonly type = RequestPasswordStateActionTypes.RequestPasswordStateLoadError;
  constructor(public payload: any) {}
}

export class RequestPasswordStateLoaded implements Action {
  readonly type = RequestPasswordStateActionTypes.RequestPasswordStateLoaded;
  constructor(public payload: any) {}
}

export class LoadDestroyPasswordState implements Action {
  readonly type = RequestPasswordStateActionTypes.LoadDestroyPasswordState;
}

//reuqest phonenumber password

export class LoadPhoneRequestPasswordState implements Action {
  readonly type = RequestPasswordStateActionTypes.LoadPhoneRequestPasswordState;
  constructor(public payload: any) {};
}

export class PhoneRequestPasswordStateLoadError implements Action {
  readonly type = RequestPasswordStateActionTypes.PhoneRequestPasswordStateLoadError;
  constructor(public payload: any) {}
}

export class PhoneRequestPasswordStateLoaded implements Action {
  readonly type = RequestPasswordStateActionTypes.PhoneRequestPasswordStateLoaded;
  constructor(public payload: any) {}
}


export type RequestPasswordStateAction =
  | LoadRequestPasswordState
  | RequestPasswordStateLoaded
  | RequestPasswordStateLoadError
  | LoadPhoneRequestPasswordState
  | PhoneRequestPasswordStateLoadError
  | PhoneRequestPasswordStateLoaded
  | LoadDestroyPasswordState;

export const fromRequestPasswordStateActions = {
  LoadRequestPasswordState,
  RequestPasswordStateLoaded,
  RequestPasswordStateLoadError,
  LoadDestroyPasswordState,
  LoadPhoneRequestPasswordState,
  PhoneRequestPasswordStateLoadError,
  PhoneRequestPasswordStateLoaded
};
