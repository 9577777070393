import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'countPriceTicketByAdultType'
})
export class CountPriceTicketByAdultTypeComponent implements PipeTransform {
  transform(pricePerOnePassenger: number, quantities:number): number {
    return (pricePerOnePassenger * quantities);
  }
}

@Pipe({
  name: 'countPriceTicketPerOnePassenger'
})
export class CountPriceTicketPerOnePassengerComponent implements PipeTransform {
  transform(priceTicket: number, quantities:number): number {
    return priceTicket / quantities;
  }
}
