import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AvatarPartialState } from './avatar.reducer';
import { avatarQuery } from './avatar.selectors';
import { LoadAvatar } from './avatar.actions';

@Injectable()
export class AvatarFacade {
  loaded$ = this.store.pipe(select(avatarQuery.getLoaded));
  allAvatar$ = this.store.pipe(select(avatarQuery.getAllAvatar));
  selectAvatar$ = this.store.pipe(select(avatarQuery.getAvatar));
  selectedAvatar$ = this.store.pipe(select(avatarQuery.getSelectedAvatar));

  constructor(private store: Store<AvatarPartialState>) {}

  getAvatarUser(idProfile: number) {
    this.store.dispatch(new LoadAvatar(idProfile));
  }
}
