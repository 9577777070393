import { Action } from '@ngrx/store';
import { AirLowFareFilterVM, AirLowFareSearchRS } from '@gtd/b2c-client';

export interface FilterFlightsAvailable {
  filter?: AirLowFareFilterVM;
  includeEquivfare: boolean;
  page?: number;
  size?: number;
  sort?: Array<string>;
}

export enum AvailableFlightsActionTypes {
  ComboVJLoadAvailableFlights = '[ComboVJ_AvailableFlights] Load AvailableFlights',
  ComboVJAvailableFlightsLoaded = '[ComboVJ_AvailableFlights] AvailableFlights Loaded',
  ComboVJAvailableFlightsLoadError = '[ComboVJ_AvailableFlights] AvailableFlights Load Error',
  ComboVJResetAvailableFlights = '[ComboVJ_AvailableFlights] Reset AvailableFlights',
  ComboVJSelectAvailableFlights = '[ComboVJ_AvailableFlights] Select AvailableFlights',
  ComboVJLoadInternationnalFlights = '[ComboVJ_AvailableFlights] Load InternationalFlights',
  ComboVJInternationalFlightsLoaded = '[ComboVJ_AvailableFlights] InternationalFlights Loaded'
}

export class ComboVJLoadAvailableFlights implements Action {
  readonly type = AvailableFlightsActionTypes.ComboVJLoadAvailableFlights;

  constructor(public payload: FilterFlightsAvailable) {}
}
export class ComboVJLoadInternationnalFlights implements Action {
  readonly type = AvailableFlightsActionTypes.ComboVJLoadInternationnalFlights;

  constructor(public payload: any) {}
}

export class ComboVJAvailableFlightsLoadError implements Action {
  readonly type = AvailableFlightsActionTypes.ComboVJAvailableFlightsLoadError;

  constructor(public payload: any) {}
}

export class ComboVJAvailableFlightsLoaded implements Action {
  readonly type = AvailableFlightsActionTypes.ComboVJAvailableFlightsLoaded;

  constructor(public payload: AirLowFareSearchRS) {}
}
export class ComboVJInternationalFlightsLoaded implements Action {
  readonly type = AvailableFlightsActionTypes.ComboVJInternationalFlightsLoaded;

  constructor(public payload: AirLowFareSearchRS) {}
}

export class ComboVJSelectAvailableFlights implements Action {
  readonly type = AvailableFlightsActionTypes.ComboVJSelectAvailableFlights;

  constructor(public payload: string) {}
}

export class ComboVJResetAvailableFlights implements Action {
  readonly type = AvailableFlightsActionTypes.ComboVJResetAvailableFlights;
}

export type AvailableFlightsAction =
  | ComboVJLoadAvailableFlights
  | ComboVJAvailableFlightsLoaded
  | ComboVJAvailableFlightsLoadError
  | ComboVJResetAvailableFlights
  | ComboVJLoadInternationnalFlights
  | ComboVJInternationalFlightsLoaded
  | ComboVJSelectAvailableFlights;

export const fromAvailableFlightsActions = {
  ComboVJLoadAvailableFlights,
  ComboVJAvailableFlightsLoaded,
  ComboVJAvailableFlightsLoadError,
  ComboVJResetAvailableFlights,
  ComboVJSelectAvailableFlights,
  ComboVJLoadInternationnalFlights,
  ComboVJInternationalFlightsLoaded
};
