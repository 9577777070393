import { Action } from '@ngrx/store';
import { HotelDetailReq } from './hotel-detail.facade';
import { OtaResultOfSearchAllRatesResult } from '@gtd/b2c-client';

export enum HotelDetailActionTypes {
  ComboVJLoadHotelDetail = '[ComboVJ_HotelDetail] Load HotelDetail',
  ComboVJHotelDetailLoaded = '[ComboVJ_HotelDetail] HotelDetail Loaded',
  ComboVJHotelDetailLoadError = '[ComboVJ_HotelDetail] HotelDetail Load Error',
  ComboVJResetHotelDetail = '[ComboVJ_HotelDetail] HotelDetail Reset All'
}

export class ComboVJLoadHotelDetail implements Action {
  readonly type = HotelDetailActionTypes.ComboVJLoadHotelDetail;
  constructor(public payload: HotelDetailReq) {}
}

export class ComboVJResetHotelDetail implements Action {
  readonly type = HotelDetailActionTypes.ComboVJResetHotelDetail;
}

export class ComboVJHotelDetailLoadError implements Action {
  readonly type = HotelDetailActionTypes.ComboVJHotelDetailLoadError;
  constructor(public payload: any) {}
}

export class ComboVJHotelDetailLoaded implements Action {
  readonly type = HotelDetailActionTypes.ComboVJHotelDetailLoaded;
  constructor(public payload: OtaResultOfSearchAllRatesResult) {}
}

export type HotelDetailAction =
  | ComboVJLoadHotelDetail
  | ComboVJHotelDetailLoaded
  | ComboVJResetHotelDetail
  | ComboVJHotelDetailLoadError;

export const fromHotelDetailActions = {
  ComboVJLoadHotelDetail,
  ComboVJHotelDetailLoaded,
  ComboVJResetHotelDetail,
  ComboVJHotelDetailLoadError
};
