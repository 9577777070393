import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { SearchKeywordPartialState } from './search-keyword.reducer';
import { searchKeywordQuery } from './search-keyword.selectors';
import { ComboVJLoadSearchKeyword } from './search-keyword.actions';

@Injectable()
export class SearchKeywordFacade {
  loaded$ = this.store.pipe(select(searchKeywordQuery.getLoaded));
  allSearchKeyword$ = this.store.pipe(
    select(searchKeywordQuery.getAllSearchKeyword)
  );
  selectedSearchKeyword$ = this.store.pipe(
    select(searchKeywordQuery.getSelectedSearchKeyword)
  );

  constructor(private store: Store<SearchKeywordPartialState>) {}

  loadAll(
    keyword: string,
    language: 'vi' | 'en',
    pageNumber?: number,
    pageSize?: number
  ) {
    this.store.dispatch(
      new ComboVJLoadSearchKeyword({
        keyword: keyword,
        language: language,
        pageNumber: pageNumber,
        pageSize: pageSize
      })
    );
  }
}
