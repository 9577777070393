/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ItineraryDTO { 
    bookingType: ItineraryDTO.BookingTypeEnum;
    checkInDate: string;
    checkoutDate: string;
    classType?: string;
    createdBy: string;
    createdDate: Date;
    departure?: string;
    destination: string;
    id?: number;
    location?: string;
    paymentAmount: number;
    provider?: string;
    refNumber?: string;
    status: ItineraryDTO.StatusEnum;
    tripId?: number;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace ItineraryDTO {
    export type BookingTypeEnum = 'AIR' | 'HOTEL' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const BookingTypeEnum = {
        AIR: 'AIR' as BookingTypeEnum,
        HOTEL: 'HOTEL' as BookingTypeEnum,
        TOURS: 'TOURS' as BookingTypeEnum,
        TRAIN: 'TRAIN' as BookingTypeEnum,
        SHIP: 'SHIP' as BookingTypeEnum,
        OTHER: 'OTHER' as BookingTypeEnum
    };
    export type StatusEnum = 'DRAFT' | 'PENDING' | 'APPROVED' | 'DISAPPROVED';
    export const StatusEnum = {
        DRAFT: 'DRAFT' as StatusEnum,
        PENDING: 'PENDING' as StatusEnum,
        APPROVED: 'APPROVED' as StatusEnum,
        DISAPPROVED: 'DISAPPROVED' as StatusEnum
    };
}
