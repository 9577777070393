import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  PLATFORM_ID
} from '@angular/core';
import { BannerTypeService } from '@gtd/api-services/newface-banner';
import { VIEWER } from '@gtd/utilities';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'gtd-relax-news',
  templateUrl: './relax-news.component.html',
  styleUrls: ['./relax-news.component.scss']
})
export class RelaxNewsComponent implements OnInit {
  @Input() categories$: Observable<any>;
  newsList: any;
  category: any;
  constructor(
    public httpClient: HttpClient,
    @Optional() @Inject(VIEWER) private viewer,
    @Inject(PLATFORM_ID) private platformId: Object,
    private bannerTypeService: BannerTypeService
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && this.viewer !== 'bot') {
      this.categories$.subscribe(categories => {
        if (categories) {
          this.category = categories.find(
            category => category.slug === 'goc-chill-phieu-trai-nghiem'
          );
          if (this.category) {
            this.bannerTypeService
              .wpV2BannerTypeGet(
                '' + this.category.id,
                environment.production ? '76' : '100'
              )
              .subscribe(newsList => {
                this.newsList = newsList;
                this.newsList.sort((a, b) => a.acf.priority - b.acf.priority);
              });
          }
        }
      });
    }
  }
}
