import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelDetailPartialState } from './hotel-detail.reducer';
import { hotelDetailQuery } from './hotel-detail.selectors';
import {
  ComboVJLoadHotelDetail,
  ComboVJResetHotelDetail
} from './hotel-detail.actions';

@Injectable()
export class ComboHotelDetailFacade {
  loaded$ = this.store.pipe(select(hotelDetailQuery.getLoaded));
  allHotelDetail$ = this.store.pipe(select(hotelDetailQuery.getAllHotelDetail));
  selectedHotelDetail$ = this.store.pipe(
    select(hotelDetailQuery.getSelectedHotelDetail)
  );

  constructor(private store: Store<HotelDetailPartialState>) {}

  loadAll(hotelDetailReq: HotelDetailReq) {
    this.store.dispatch(new ComboVJLoadHotelDetail(hotelDetailReq));
  }
  resetLoadAll() {
    this.store.dispatch(new ComboVJResetHotelDetail());
  }
}
export interface HotelDetailReq {
  searchId: string;
  propertyId: string;
  supplier: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL';
  checkIn: string;
  checkOut: string;
  paxInfos: Array<string>;
}
