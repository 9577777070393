import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {PhoneRegisterStateFacade} from './phone-register-state/+state/phone-register-state.facade';
import {SnackbarComponent} from '@gtd/components/snackbar';
import {DialogMessageComponent} from '@gtd/components/dialog-message';
import {ReplaySubject, Subscription} from 'rxjs';
import {PhoneResendOtpStateFacade} from "./phone-register-state/+resenotp-state/phone-resend-otp-state.facade";
import {CountryFacade} from "@gtd/meta";
import {CountryCodeDTO} from "@gtd/meta-client";
import {RegisterPhoneNumberVM} from "@gtd/b2c-client";
import {I18nService} from "@gtd/i18nService";

@Component({
  selector: 'gtd-phone-register',
  templateUrl: './phone-register.component.html',
  styleUrls: ['./phone-register.component.scss']
})
export class PhoneRegisterComponent implements OnInit {
  phoneRegisterForm?: FormGroup;
  hide = true;
  isLoading: boolean;
  subscriptions = new Subscription();
  protected countries: CountryCodeDTO[];
  public filteredCountries: ReplaySubject<CountryCodeDTO[]> = new ReplaySubject<CountryCodeDTO[]>(1);
  referralCode: string;
  dataRegister;
  currentLanguage: RegisterPhoneNumberVM.LanguageEnum;
  redirectParamEncode;
  
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private phoneRegisterStateFacade: PhoneRegisterStateFacade,
    private phoneResendOtpStateFacade: PhoneResendOtpStateFacade,
    private  countryFacade: CountryFacade,
    private activatedRoute: ActivatedRoute,
    private i18nService: I18nService,
  ) {
    this.createForm();
    this.phoneRegisterStateFacade.destroyRequestPassword();
    this.phoneRegisterStateFacade.resetAllRegister();
    this.countryFacade.loadAll({
      page: 0,
      size: 1000,
      sort: ['sortname,asc']
    });
    this.activatedRoute.queryParams.subscribe(param => {
      if (param && param.params) {
        this.redirectParamEncode = param.params
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.activatedRoute.params.subscribe( params => {
        if(params && params.id) {
          this.referralCode = params.id;
          this.phoneRegisterForm.controls['referencedBy'].setValue(params.id)
        }
      });
    })
    this.subscriptions.add(
      this.countryFacade.allCountry$.subscribe((countries: Array<CountryCodeDTO>) => {
        this.countries = countries;
        this.filteredCountries.next(countries.slice());
      })
    )
    this.subscriptions.add(
      this.phoneRegisterStateFacade.allPhoneRegisterState$.subscribe(register => {
          if(register !== null) {
            if (register.status === "inactive") {
                // this.filumAnalytics.identifyUser({
                //   user_id: this.phoneRegisterForm.get('phone').value,
                //   email: this.phoneRegisterForm.get('phone').value,
                //   first_name: this.phoneRegisterForm.get('firstName').value,
                //   last_name: this.phoneRegisterForm.get('firstName').value,
                //   created_at: new Date(),
                // });
                // this.filumAnalytics.track('New User Signed Up', {
                //   email: this.phoneRegisterForm.get('phone').value,
                //   activated: 0,
                //   created_at: new Date(),
                // })
                this.dataRegister = btoa(JSON.stringify({
                    phone: this.phoneRegisterForm.get('phone').value,
                    firstName: this.phoneRegisterForm.get('firstName').value,
                    lastName: this.phoneRegisterForm.get('lastName').value,
                    verificationCode : register.result.verificationCode,
                    language: register.result.language,
                    userRefCode: register.result.userRefCode,
                    referencedBy: register.result.referencedBy,
                    type : 'register'
                }));
                let navigationExtras: NavigationExtras = {
                  queryParams: {
                    key: this.dataRegister,
                    params: this.redirectParamEncode
                  }
                };
                this._snackBar.openFromComponent(SnackbarComponent, {
                  data: { message: 'user.registering', loading: true },
                  panelClass: 'snackbar-loading',
                  verticalPosition: 'top'
                });
                this.router.navigate(
                  ['auth/register-phone-otp'], navigationExtras).then(() => {});
              this._snackBar.dismiss();
              this.phoneRegisterStateFacade.resetAllRegister();
            } else {
              if (register.errorCode === 1101) {
                this.phoneRegisterForm.controls['phone'].setErrors([
                  'alreadyExist'
                ]);
              }
              else if(register.errorCode === 1102) {
                  this.phoneResendOtpStateFacade.phoneResendOTP({
                    phoneNumber: this.phoneRegisterForm.get('phone').value,
                    language: this.currentLanguage
                  });
                  this.phoneResendOtpStateFacade.allPhoneResendOtpState$.subscribe(response => {
                    if(response) {
                      this.dataRegister = btoa(JSON.stringify({
                        phone: this.phoneRegisterForm.get('phone').value,
                        firstName: this.phoneRegisterForm.get('firstName').value,
                        lastName: this.phoneRegisterForm.get('lastName').value,
                        verificationCode : response.result.verificationCode,
                        language: response.result.language,
                        userRefCode: response.result.userRefCode,
                        referencedBy: response.result.referencedBy,
                        type : 'resendOTP'
                      }));
                      let navigationExtras: NavigationExtras = {
                        queryParams: {
                          key: this.dataRegister,
                          params: this.redirectParamEncode
                        }
                      };
                      this.router.navigate(['auth/register-phone-otp'], navigationExtras).then(() => {});
                      this.isLoading = false;
                      this._snackBar.dismiss();
                      this.phoneRegisterStateFacade.resetAllRegister();
                    }
                  });
              } else if(register.loaded === true && register.result.success !== true) {
                this.dialog.open(DialogMessageComponent, {
                  data: {
                    btn: [
                      {btnText: 'global.close'}
                    ],
                    content: 'user.system-error-message',
                    heading: 'user.system-error',
                    icon: 'exclamation-triangle',
                    messageType: 'error'
                  },
                  panelClass: 'dialog-message',
                  disableClose: true
                });
                this._snackBar.openFromComponent(SnackbarComponent, {
                  data: {
                    message: 'user.registration-failed',
                    icon: 'exclamation-triangle'
                  },
                  panelClass: 'snackbar-error',
                  verticalPosition: 'top',
                  duration: 2000
                });
              }
              this.isLoading = false;
              this._snackBar.dismiss();
            }
          }
        }
      )
    );
  }
  getErrorMessage() {
    return this.phoneRegisterForm.controls['phone'].hasError('required')
      ? 'user.phone-can-not-be-empty'
      : this.phoneRegisterForm.controls['phone'].hasError('pattern') ? 'user.invalid-phone'
      : this.phoneRegisterForm.controls['phone'].errors !== null &&
        this.phoneRegisterForm.controls['phone'].errors[0] === 'alreadyExist'
      ? 'user.phone-exist'
      : '';
  }

  clearRef() {
    this.phoneRegisterForm.controls['referencedBy'].setValue('');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  submitForm() {
    if(this.i18nService.language === 'vi') {
      this.currentLanguage = RegisterPhoneNumberVM.LanguageEnum.VI;
    } else {
      this.currentLanguage = RegisterPhoneNumberVM.LanguageEnum.EN;
    }
    this.isLoading = true;
    this.phoneRegisterStateFacade.phoneRegisterAccount({
      phoneNumber: this.phoneRegisterForm.get('phone').value,
      firstName: this.phoneRegisterForm.get('firstName').value,
      lastName: this.phoneRegisterForm.get('lastName').value,
      referencedBy: this.phoneRegisterForm.get('referencedBy').value,
      language: this.currentLanguage
    });
  }
  createForm() {
    this.phoneRegisterForm = this.formBuilder.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      referencedBy: [''],
      phoneCode: ['84'],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });
  }
}
