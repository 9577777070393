import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { SocialAuthPartialState } from './social-auth.reducer';
import { socialAuthQuery } from './social-auth.selectors';
import {LoadSocialAuth, Logout} from './social-auth.actions';
import {MobileSocialAuth} from "@gtd/b2c-client";

@Injectable()
export class SocialAuthFacade {
  loaded$ = this.store.pipe(select(socialAuthQuery.getLoaded));
  allSocialAuth$ = this.store.pipe(select(socialAuthQuery.getAllSocialAuth));
  // selectedSocialAuth$ = this.store.pipe(
  //   select(socialAuthQuery.getSelectedSocialAuth)
  // );

  selectedSocialAuth$ = this.store.pipe(select(socialAuthQuery.selectUserState));

  constructor(private store: Store<SocialAuthPartialState>) {}

  signUpSocial(mobileSocialAuth: MobileSocialAuth) {
    this.store.dispatch(new LoadSocialAuth(mobileSocialAuth));
  }

  logOutAction() {
    this.store.dispatch(new Logout());
  }
}
