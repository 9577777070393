import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ComboVJComboBookingFacade } from '../state/combo-booking/combo-booking.facade';
@Injectable()
export class HotelGuardService implements CanActivate {
  constructor(
    public router: Router,
    private comboBookingFacade: ComboVJComboBookingFacade
  ) {}
  canActivate(): boolean {
    if (!!localStorage.getItem('flightBooking')) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
