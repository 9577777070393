import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {LookupB2bFacade} from './+state/lookup-b2b.facade';
import {NxModule} from '@nrwl/angular';
import {environment} from "@envB2b/environment";
import {BASE_PATH, LookupResourceService} from "@gtd/api-services/b2b-client";
import {LookupB2bEffects} from "./+state/lookup-b2b.effects";
import {
  LOOKUPB2B_FEATURE_KEY,
  initialState as lookupB2bInitialState,
  lookupB2bReducer
} from "./+state/lookup-b2b.reducer";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(LOOKUPB2B_FEATURE_KEY, lookupB2bReducer, {
      initialState: lookupB2bInitialState
    }),
    EffectsModule.forFeature([LookupB2bEffects])
  ],
  providers: [{provide: BASE_PATH, useValue: environment.b2bSite}, LookupB2bFacade, LookupResourceService]
})
export class LookupB2bModule {}
