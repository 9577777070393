import {
  SearchBookingTransactionsAction,
  SearchBookingTransactionsActionTypes
} from './search-booking-transactions.actions';

export const SEARCHBOOKINGTRANSACTIONS_FEATURE_KEY =
  'searchBookingTransactions';

/**
 * Interface for the 'SearchBookingTransactions' data used in
 *  - SearchBookingTransactionsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface SearchParams {
  filter?: FilterBookingTransaction,
  productCode?: string,
  currentPage?: number,
  totalCount?: number,
  skipSearch?: boolean,
  ignoreSummary?: boolean,
  page?: number,
  size?: number,
  sort?: Array<string>
}
export interface FilterBookingTransaction {
  status?: string,
  agencyCode?: string,
  bookingNumber?: string,
  providerBrn?: string,
  email?: string,
  customerName?: string,
  supplierCode?: string,
  bookingDateFrom?: string,
  bookingDateTo?: string,
  checkInDate?: string,
  checkOutDate?: string,
  typeCode?: string,
  bookingChannel?: string,
  issuedDateFrom?: string,
  issuedDateTo?: string,
  paymentType?: string,
  action?: string,
  phoneNumber?: string,
  bookingTagId?: number,
  paymentStatus?: string,
  hasIncludeBookingContent?: boolean
}

export interface SearchBookingTransactionsState {
  list: any; // list of SearchBookingTransactions; analogous to a sql normalized table
  selectedId?: string | number; // which SearchBookingTransactions record has been selected
  loaded: boolean; // has the SearchBookingTransactions list been loaded
  error?: any; // last none error (if any)
}

export interface SearchBookingTransactionsPartialState {
  readonly [SEARCHBOOKINGTRANSACTIONS_FEATURE_KEY]: SearchBookingTransactionsState;
}

export const initialStateSearch: SearchBookingTransactionsState = {
  list: null,
  loaded: false
};

export function reducerSearchTransaction(
  state: SearchBookingTransactionsState = initialStateSearch,
  action: SearchBookingTransactionsAction
): SearchBookingTransactionsState {
  switch (action.type) {
    case SearchBookingTransactionsActionTypes.SearchBookingTransactionsLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case SearchBookingTransactionsActionTypes.DestroyTransactions: {
      state = initialStateSearch;
      break;
    }
  }
  return state;
}
