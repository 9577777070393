/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface LinkedAccountDTO { 
    agencyId?: number;
    createdBy: string;
    createdDate: Date;
    id?: number;
    linkType: LinkedAccountDTO.LinkTypeEnum;
    referenceCode: string;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace LinkedAccountDTO {
    export type LinkTypeEnum = 'VPBANK_BIZPAY';
    export const LinkTypeEnum = {
        VPBANKBIZPAY: 'VPBANK_BIZPAY' as LinkTypeEnum
    };
}
