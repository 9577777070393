import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {environment} from '@env/environment';
import {NxModule} from '@nrwl/angular';

import {FLIGHTSEARCH_FEATURE_KEY, flightSearchReducer, initialState as flightSearchInitialState} from './flight-search/+state/flight-search.reducer';
import {FlightSearchEffects} from './flight-search/+state/flight-search.effects';
import {FlightSearchFacade} from './flight-search/+state/flight-search.facade';
import {AirTicketsResourceService, BASE_PATH} from '@gtd/b2c-client';
import {AVAILABLEFLIGHTS_FEATURE_KEY, availableFlightsReducer, initialState as availableFlightsInitialState} from './flight-cache-search/+state/available-flights.reducer';
import {AvailableFlightsEffects} from './flight-cache-search/+state/available-flights.effects';
import {AvailableFlightsFacade} from './flight-cache-search/+state/available-flights.facade';
import {initialState as lowestPriceFlightsInitialState, LOWESTPRICEFLIGHTS_FEATURE_KEY, lowestPriceFlightsReducer} from './flight-lowest-price/+state/lowest-price-flights.reducer';
import {LowestPriceFlightsEffects} from './flight-lowest-price/+state/lowest-price-flights.effects';
import {LowestPriceFlightsFacade} from './flight-lowest-price/+state/lowest-price-flights.facade';
import {FLIGHTFILTER_FEATURE_KEY, flightFilterReducer, getInitial as flightFilterInitialState} from './flight-filter/+state/flight-filter.reducer';
import {FlightFilterFacade} from './flight-filter/+state/flight-filter.facade';
import {FLIGHTPagination_FEATURE_KEY, flightPaginationReducer, initialState as flightPaginationInitialState} from './flight-pagination/+state/flight-pagination.reducer';
import {FlightPaginationFacade} from './flight-pagination/+state/flight-pagination.facade';
import {GROUPITINERARY_FEATURE_KEY, groupItineraryReducer, initialState as groupItineraryInitialState} from './group-itinerary/+state/group-itinerary.reducer';
import {GroupItineraryEffects} from './group-itinerary/+state/group-itinerary.effects';
import {GroupItineraryFacade} from './group-itinerary/+state/group-itinerary.facade';
import {FARERULES_FEATURE_KEY, farerulesReducer, initialState as farerulesState} from './farerules/+state/farerules.reducer';
import {FarerulesEffects} from './farerules/+state/farerules.effects';
import {FarerulesFacade} from './farerules/+state/farerules.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(FLIGHTSEARCH_FEATURE_KEY, flightSearchReducer, {
      initialState: flightSearchInitialState
    }),
    EffectsModule.forFeature([FlightSearchEffects]),
    StoreModule.forFeature(
      AVAILABLEFLIGHTS_FEATURE_KEY,
      availableFlightsReducer,
      { initialState: availableFlightsInitialState }
    ),
    EffectsModule.forFeature([AvailableFlightsEffects]),
    StoreModule.forFeature(
      LOWESTPRICEFLIGHTS_FEATURE_KEY,
      lowestPriceFlightsReducer,
      { initialState: lowestPriceFlightsInitialState }
    ),
    EffectsModule.forFeature([LowestPriceFlightsEffects]),
    StoreModule.forFeature(FLIGHTFILTER_FEATURE_KEY, flightFilterReducer, {
      initialState: flightFilterInitialState
    }),
    StoreModule.forFeature(
      FLIGHTPagination_FEATURE_KEY,
      flightPaginationReducer,
      { initialState: flightPaginationInitialState }
    ),

    EffectsModule.forFeature([GroupItineraryEffects]),
    StoreModule.forFeature(GROUPITINERARY_FEATURE_KEY, groupItineraryReducer, {
      initialState: groupItineraryInitialState
    }),

    EffectsModule.forFeature([FarerulesEffects]),
    StoreModule.forFeature(FARERULES_FEATURE_KEY, farerulesReducer, {
      initialState: farerulesState
    })
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2cClient },
    FlightSearchFacade,
    AirTicketsResourceService,
    AvailableFlightsFacade,
    LowestPriceFlightsFacade,
    FlightFilterFacade,
    FlightPaginationFacade,
    GroupItineraryFacade,
    FarerulesFacade
  ]
})
export class FlightSearchModule {}
