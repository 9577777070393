import { Action } from '@ngrx/store';
import { Entity } from './phone-resend-otp-state.reducer';
import {RegisterPhoneNumberVM} from "@gtd/b2c-client";
import {PhoneRegisterStateActionTypes} from "../+state/phone-register-state.actions";

export enum PhoneResendOtpStateActionTypes {
  LoadPhoneResendOtpState = '[PhoneResendOtpState] Load PhoneResendOtpState',
  PhoneResendOtpStateLoaded = '[PhoneResendOtpState] PhoneResendOtpState Loaded',
  PhoneResendOtpStateLoadError = '[PhoneResendOtpState] PhoneResendOtpState Load Error',
  LoadDestroyPhoneResendOtpState = '[PhoneResendOtpState] Load Destroy PhoneResendOtpState',
}

export class LoadPhoneResendOtpState implements Action {
  readonly type = PhoneResendOtpStateActionTypes.LoadPhoneResendOtpState;
  constructor(public payload: RegisterPhoneNumberVM) {}
}

export class PhoneResendOtpStateLoadError implements Action {
  readonly type = PhoneResendOtpStateActionTypes.PhoneResendOtpStateLoadError;
  constructor(public payload: any) {}
}

export class PhoneResendOtpStateLoaded implements Action {
  readonly type = PhoneResendOtpStateActionTypes.PhoneResendOtpStateLoaded;
  constructor(public payload: any) {}
}

export class LoadDestroyPhoneResendOtpState implements Action {
  readonly type = PhoneResendOtpStateActionTypes.LoadDestroyPhoneResendOtpState;
}

export type PhoneResendOtpStateAction =
  | LoadPhoneResendOtpState
  | PhoneResendOtpStateLoaded
  | LoadDestroyPhoneResendOtpState
  | PhoneResendOtpStateLoadError;

export const fromPhoneResendOtpStateActions = {
  LoadPhoneResendOtpState,
  PhoneResendOtpStateLoaded,
  PhoneResendOtpStateLoadError,
  LoadDestroyPhoneResendOtpState
};
