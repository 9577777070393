import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {CredentialsService} from "@gtd/auth";
import {Injectable} from "@angular/core";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private credentialsService: CredentialsService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.credentialsService.isAuthenticated()) { // This is the injected auth service which depends on what you are using
      return true;
    }
    console.log('access denied!')
    this.router.navigate(['/auth/login']);
    return false
  }

}
@Injectable()
export class AuthGuardNotLoggedIn implements CanActivate {
  constructor(private credentialsService: CredentialsService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    if(!this.credentialsService.isAuthenticated()) { // This is the injected auth service which depends on what you are using
      return true;
    }
    console.log('access denied!')
    this.router.navigate(['/user/profile']);
    return false
  }

}
