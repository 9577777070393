/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AgentProfileDTO } from '../model/agentProfileDTO';
import { OtaResultOfMapOfstringAndboolean } from '../model/otaResultOfMapOfstringAndboolean';
import { OtaSearchResultOfListOfAgentProfileDTO } from '../model/otaSearchResultOfListOfAgentProfileDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AgentProfileResourceService {

    protected basePath = 'https://10.7.71.20:8182';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changeStatus
     *
     * @param id id
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeStatusUsingPUT2(id: number, status: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeStatusUsingPUT2(id: number, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeStatusUsingPUT2(id: number, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeStatusUsingPUT2(id: number, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling changeStatusUsingPUT2.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling changeStatusUsingPUT2.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/api/agent-profiles/${encodeURIComponent(String(id))}/status`,
            status,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkPermission
     *
     * @param loginUserName loginUserName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkPermissionUsingGET(loginUserName: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfMapOfstringAndboolean>;
    public checkPermissionUsingGET(loginUserName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfMapOfstringAndboolean>>;
    public checkPermissionUsingGET(loginUserName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfMapOfstringAndboolean>>;
    public checkPermissionUsingGET(loginUserName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginUserName === null || loginUserName === undefined) {
            throw new Error('Required parameter loginUserName was null or undefined when calling checkPermissionUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loginUserName !== undefined && loginUserName !== null) {
            queryParameters = queryParameters.set('loginUserName', <any>loginUserName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfMapOfstringAndboolean>(`${this.basePath}/api/agent-profiles/check-permission`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createAgentProfile
     *
     * @param agentProfileDTO agentProfileDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgentProfileUsingPOST(agentProfileDTO: AgentProfileDTO, observe?: 'body', reportProgress?: boolean): Observable<AgentProfileDTO>;
    public createAgentProfileUsingPOST(agentProfileDTO: AgentProfileDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentProfileDTO>>;
    public createAgentProfileUsingPOST(agentProfileDTO: AgentProfileDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentProfileDTO>>;
    public createAgentProfileUsingPOST(agentProfileDTO: AgentProfileDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentProfileDTO === null || agentProfileDTO === undefined) {
            throw new Error('Required parameter agentProfileDTO was null or undefined when calling createAgentProfileUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AgentProfileDTO>(`${this.basePath}/api/agent-profiles`,
            agentProfileDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteAgentProfile
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgentProfileUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAgentProfileUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAgentProfileUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAgentProfileUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAgentProfileUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/agent-profiles/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentProfile
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentProfileUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<AgentProfileDTO>;
    public getAgentProfileUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentProfileDTO>>;
    public getAgentProfileUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentProfileDTO>>;
    public getAgentProfileUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAgentProfileUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentProfileDTO>(`${this.basePath}/api/agent-profiles/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentProfilesByAgencyCode
     *
     * @param agencyCode agencyCode
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentProfilesByAgencyCodeUsingGET(agencyCode: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentProfileDTO>>;
    public getAgentProfilesByAgencyCodeUsingGET(agencyCode: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentProfileDTO>>>;
    public getAgentProfilesByAgencyCodeUsingGET(agencyCode: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentProfileDTO>>>;
    public getAgentProfilesByAgencyCodeUsingGET(agencyCode: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getAgentProfilesByAgencyCodeUsingGET.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgentProfileDTO>>(`${this.basePath}/api/agents-by-agency`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllAgentProfiles
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAgentProfilesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentProfileDTO>>;
    public getAllAgentProfilesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentProfileDTO>>>;
    public getAllAgentProfilesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentProfileDTO>>>;
    public getAllAgentProfilesUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgentProfileDTO>>(`${this.basePath}/api/agent-profiles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getProfileByCode
     *
     * @param agentCode agentCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileByCodeUsingGET(agentCode: string, observe?: 'body', reportProgress?: boolean): Observable<AgentProfileDTO>;
    public getProfileByCodeUsingGET(agentCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentProfileDTO>>;
    public getProfileByCodeUsingGET(agentCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentProfileDTO>>;
    public getProfileByCodeUsingGET(agentCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentCode === null || agentCode === undefined) {
            throw new Error('Required parameter agentCode was null or undefined when calling getProfileByCodeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentProfileDTO>(`${this.basePath}/api/agent-profiles/code/${encodeURIComponent(String(agentCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getProfileByLoginId
     *
     * @param loginId loginId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileByLoginIdUsingGET(loginId: number, observe?: 'body', reportProgress?: boolean): Observable<AgentProfileDTO>;
    public getProfileByLoginIdUsingGET(loginId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentProfileDTO>>;
    public getProfileByLoginIdUsingGET(loginId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentProfileDTO>>;
    public getProfileByLoginIdUsingGET(loginId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginId === null || loginId === undefined) {
            throw new Error('Required parameter loginId was null or undefined when calling getProfileByLoginIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentProfileDTO>(`${this.basePath}/api/agent-profiles/login-id/${encodeURIComponent(String(loginId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getProfileByLoginName
     *
     * @param loginName loginName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileByLoginNameUsingGET(loginName: string, observe?: 'body', reportProgress?: boolean): Observable<AgentProfileDTO>;
    public getProfileByLoginNameUsingGET(loginName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentProfileDTO>>;
    public getProfileByLoginNameUsingGET(loginName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentProfileDTO>>;
    public getProfileByLoginNameUsingGET(loginName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginName === null || loginName === undefined) {
            throw new Error('Required parameter loginName was null or undefined when calling getProfileByLoginNameUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentProfileDTO>(`${this.basePath}/api/agent-profiles/login-name/${encodeURIComponent(String(loginName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieveProfileByCode
     *
     * @param agentCode agentCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveProfileByCodeUsingPOST(agentCode: string, observe?: 'body', reportProgress?: boolean): Observable<AgentProfileDTO>;
    public retrieveProfileByCodeUsingPOST(agentCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentProfileDTO>>;
    public retrieveProfileByCodeUsingPOST(agentCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentProfileDTO>>;
    public retrieveProfileByCodeUsingPOST(agentCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentCode === null || agentCode === undefined) {
            throw new Error('Required parameter agentCode was null or undefined when calling retrieveProfileByCodeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AgentProfileDTO>(`${this.basePath}/api/agent-profiles/code`,
            agentCode,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieveProfileByLoginId
     *
     * @param loginId loginId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveProfileByLoginIdUsingPOST(loginId: number, observe?: 'body', reportProgress?: boolean): Observable<AgentProfileDTO>;
    public retrieveProfileByLoginIdUsingPOST(loginId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentProfileDTO>>;
    public retrieveProfileByLoginIdUsingPOST(loginId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentProfileDTO>>;
    public retrieveProfileByLoginIdUsingPOST(loginId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginId === null || loginId === undefined) {
            throw new Error('Required parameter loginId was null or undefined when calling retrieveProfileByLoginIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AgentProfileDTO>(`${this.basePath}/api/agent-profiles/login-id`,
            loginId,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieveProfileByLoginName
     *
     * @param loginName loginName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveProfileByLoginNameUsingPOST(loginName: string, observe?: 'body', reportProgress?: boolean): Observable<AgentProfileDTO>;
    public retrieveProfileByLoginNameUsingPOST(loginName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentProfileDTO>>;
    public retrieveProfileByLoginNameUsingPOST(loginName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentProfileDTO>>;
    public retrieveProfileByLoginNameUsingPOST(loginName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginName === null || loginName === undefined) {
            throw new Error('Required parameter loginName was null or undefined when calling retrieveProfileByLoginNameUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AgentProfileDTO>(`${this.basePath}/api/agent-profiles/login-name`,
            loginName,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjects
     *
     * @param agentName agentName
     * @param loginUsername loginUsername
     * @param phoneNumber phoneNumber
     * @param email email
     * @param branchCode branchCode
     * @param roleCode roleCode
     * @param status status
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectsUsingGET2(agentName: string, loginUsername: string, phoneNumber: string, email: string, branchCode: string, roleCode: string, status: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfListOfAgentProfileDTO>;
    public searchObjectsUsingGET2(agentName: string, loginUsername: string, phoneNumber: string, email: string, branchCode: string, roleCode: string, status: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfListOfAgentProfileDTO>>;
    public searchObjectsUsingGET2(agentName: string, loginUsername: string, phoneNumber: string, email: string, branchCode: string, roleCode: string, status: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfListOfAgentProfileDTO>>;
    public searchObjectsUsingGET2(agentName: string, loginUsername: string, phoneNumber: string, email: string, branchCode: string, roleCode: string, status: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentName === null || agentName === undefined) {
            throw new Error('Required parameter agentName was null or undefined when calling searchObjectsUsingGET2.');
        }

        if (loginUsername === null || loginUsername === undefined) {
            throw new Error('Required parameter loginUsername was null or undefined when calling searchObjectsUsingGET2.');
        }

        if (phoneNumber === null || phoneNumber === undefined) {
            throw new Error('Required parameter phoneNumber was null or undefined when calling searchObjectsUsingGET2.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling searchObjectsUsingGET2.');
        }

        if (branchCode === null || branchCode === undefined) {
            throw new Error('Required parameter branchCode was null or undefined when calling searchObjectsUsingGET2.');
        }

        if (roleCode === null || roleCode === undefined) {
            throw new Error('Required parameter roleCode was null or undefined when calling searchObjectsUsingGET2.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling searchObjectsUsingGET2.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agentName !== undefined && agentName !== null) {
            queryParameters = queryParameters.set('agentName', <any>agentName);
        }
        if (loginUsername !== undefined && loginUsername !== null) {
            queryParameters = queryParameters.set('loginUsername', <any>loginUsername);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (branchCode !== undefined && branchCode !== null) {
            queryParameters = queryParameters.set('branchCode', <any>branchCode);
        }
        if (roleCode !== undefined && roleCode !== null) {
            queryParameters = queryParameters.set('roleCode', <any>roleCode);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaSearchResultOfListOfAgentProfileDTO>(`${this.basePath}/api/_search/agent-profiles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateAgentProfile
     *
     * @param agentProfileDTO agentProfileDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAgentProfileUsingPUT(agentProfileDTO: AgentProfileDTO, observe?: 'body', reportProgress?: boolean): Observable<AgentProfileDTO>;
    public updateAgentProfileUsingPUT(agentProfileDTO: AgentProfileDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentProfileDTO>>;
    public updateAgentProfileUsingPUT(agentProfileDTO: AgentProfileDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentProfileDTO>>;
    public updateAgentProfileUsingPUT(agentProfileDTO: AgentProfileDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentProfileDTO === null || agentProfileDTO === undefined) {
            throw new Error('Required parameter agentProfileDTO was null or undefined when calling updateAgentProfileUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AgentProfileDTO>(`${this.basePath}/api/agent-profiles`,
            agentProfileDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
