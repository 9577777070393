import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {GetUserProfilePartialState} from './get-user-profile.reducer';
import {GetUserProfileActionTypes, GetUserProfileLoadError, LoadGetUserProfile} from './get-user-profile.actions';
import {map} from "rxjs/operators";
import {UserProfileResourceService} from "../../lib";

@Injectable()
export class GetUserProfileEffects {
  @Effect() loadGetUserProfile$ = this.dataPersistence.fetch(
    GetUserProfileActionTypes.LoadGetUserProfile,
    {
      run: (action: LoadGetUserProfile, state: GetUserProfilePartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.userProfileResourceService.getUserProfileUsingGET(action.payload).pipe(
            map(result => ({
              type: GetUserProfileActionTypes.GetUserProfileLoaded,
              payload: result,
            }))
          );
      },

      onError: (action: LoadGetUserProfile, error) => {
        console.error('Error', error);
        return new GetUserProfileLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private userProfileResourceService: UserProfileResourceService,
    private dataPersistence: DataPersistence<GetUserProfilePartialState>
  ) {}
}
