import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss']
})
export class LoadingPanelComponent implements OnInit {
  counter = Array;
  constructor() { }

  ngOnInit() {
  }

}
