import { Action } from '@ngrx/store';

export enum FlightPaginationActionTypes {
  LoadFlightPagination = '[FlightPagination] Load FlightPagination',
  FlightPaginationLoaded = '[FlightPagination] FlightPagination Loaded',
  FlightPaginationLoadError = '[FlightPagination] FlightPagination Load Error',
  ChangeFlightPaginationPage = '[FlightPagination] Change FlightPagination Page',
  ChangeFlightPaginationSort = '[FlightPagination] Change FlightPagination Sort',
  ChangeFlightPaginationSize = '[FlightPagination] Change FlightPagination Size'
}

export class LoadFlightPagination implements Action {
  readonly type = FlightPaginationActionTypes.LoadFlightPagination;
}

export class ChangeFlightPaginationPage implements Action {
  readonly type = FlightPaginationActionTypes.ChangeFlightPaginationPage;
  constructor(public payload: number) {}
}

export class FlightPaginationLoaded implements Action {
  readonly type = FlightPaginationActionTypes.FlightPaginationLoaded;
  constructor(public payload: any) {}
}

export class FlightPaginationLoadError implements Action {
  readonly type = FlightPaginationActionTypes.FlightPaginationLoadError;
  constructor(public payload: any) {}
}

export class ChangeFlightPaginationSort implements Action {
  readonly type = FlightPaginationActionTypes.ChangeFlightPaginationSort;
  constructor(public payload: string[]) {}
}

export class ChangeFlightPaginationSize implements Action {
  readonly type = FlightPaginationActionTypes.ChangeFlightPaginationSize;
  constructor(public payload: number) {}
}

export type FlightPaginationAction =
  | LoadFlightPagination
  | FlightPaginationLoaded
  | FlightPaginationLoadError
  | ChangeFlightPaginationPage
  | ChangeFlightPaginationSort
  | ChangeFlightPaginationSize;

export const fromFlightPaginationActions = {
  LoadFlightPagination,
  ChangeFlightPaginationPage,
  FlightPaginationLoaded,
  FlightPaginationLoadError,
  ChangeFlightPaginationSort,
  ChangeFlightPaginationSize
};
