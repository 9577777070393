import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions} from "ngx-gallery";

@Component({
  selector: 'gtd-popup-gallery',
  templateUrl: './popup-gallery.component.html',
  styleUrls: ['./popup-gallery.component.scss']
})
export class PopupGalleryComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor(
    public dialogRef: MatDialogRef<PopupGalleryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {images: Array<any>, index: number}
    ) {
    this.galleryOptions = [
      {
        breakpoint: 991,
        thumbnailsColumns: 10,
        thumbnailsMoveSize: 8
      },
      {
        breakpoint: 800,
        thumbnailsColumns: 6,
        thumbnailsMoveSize: 4
      },
      {
        breakpoint: 400,
        thumbnailsColumns: 4,
        thumbnailsMoveSize: 2
      },
      {
        width: '100%',
        height: '100%',
        preview: false,
        thumbnailsColumns: 20,
        thumbnailsPercent: 10,
        imageAnimation: NgxGalleryAnimation.Slide,
        arrowNextIcon: 'next',
        arrowPrevIcon: 'prev',
        lazyLoading: true,
        imageSwipe: true,
        imagePercent: 100,
        imageSize: NgxGalleryImageSize.Contain,
        startIndex: data.index,
        thumbnailsSwipe: true,
        thumbnailsAutoHide: true,
        thumbnailsMoveSize: 10
      }
    ];
    this.galleryImages = this.mappingGallery(this.data.images);
  }

  ngOnInit() {
  }

  closePopup() {
    this.dialogRef.close();
  }

  mappingGallery(images: Array<any>): NgxGalleryImage[] {
    return images.map((image: any) => {
      return {
        big: image.link,
        small: image.link.replace('_z','_e'),
        medium: image.link.replace('_z','_w')
      };
    });
  }
}
