import { Action } from '@ngrx/store';
import {FlightFilterState, SelectDepartureItem} from './flight-filter.reducer';

export enum FlightFilterActionTypes {
  ResetFlightFilter = '[FlightFilter] Reset FlightFilter',
  ResetFlightFilterRoundTrip = '[FlightFilter] Reset  Flight Filter RoundTrip',
  ChangeFlightFilter = '[FlightFilter] Change FlightFilter',
  ChangeAirlineOptions = '[FlightFilter] Change AirlineOptions',
  ChangeArrivalDateTimeOptions = '[FlightFilter] Change ArrivalDateTimeOptions',
  ChangeArrivalDateTimeReturnOptions = '[FlightFilter] Change ArrivalDateTimeReturnOptions',
  ChangeCabinClassOptions = '[FlightFilter] Change CabinClassOptions',
  ChangeDepartureDateTimeOptions = '[FlightFilter] DepartureDateTimeOptions',
  ChangeFlightType = '[FlightFilter] Change FlightType',
  ChangeStep = '[FlightFilter] Change Step',
  ChangeStopOptions = '[FlightFilter] Change StopOptions',
  ChangeTicketPolicyOptions = '[FlightFilter] Change TicketPolicyOptions',
  ChangeFlightPaginationPage = '[FlightFilter] Change FlightPagination Page',
  ChangeFlightPaginationSort = '[FlightFilter] Change FlightPagination Sort',
  ChangeFlightPaginationSize = '[FlightFilter] Change FlightPagination Size',
  ChangeSearchID = '[FlightFilter] Change SearchID',
  SelectDeparture = '[FlightFilter] Select Departure'
}

export class ResetFlightFilter implements Action {
  readonly type = FlightFilterActionTypes.ResetFlightFilter;
}

export class ResetFlightFilterRoundTrip implements Action {
  readonly type = FlightFilterActionTypes.ResetFlightFilterRoundTrip;
  constructor(public payload: {searchID: string, flightType: string}) {
  }
}

export class ChangeFlightFilter implements Action {
  readonly type = FlightFilterActionTypes.ChangeFlightFilter;

  constructor(public payload: FlightFilterState) {}
}

export class ChangeAirlineOptions implements Action {
  readonly type = FlightFilterActionTypes.ChangeAirlineOptions;

  constructor(public payload: string[]) {}
}

export class ChangeArrivalDateTimeOptions implements Action {
  readonly type = FlightFilterActionTypes.ChangeArrivalDateTimeOptions;

  constructor(public payload: string[]) {}
}

export class ChangeArrivalDateTimeReturnOptions implements Action {
  readonly type = FlightFilterActionTypes.ChangeArrivalDateTimeReturnOptions;

  constructor(public payload: string[]) {}
}

export class ChangeCabinClassOptions implements Action {
  readonly type = FlightFilterActionTypes.ChangeCabinClassOptions;

  constructor(public payload: string[]) {}
}

export class ChangeDepartureDateTimeOptions implements Action {
  readonly type = FlightFilterActionTypes.ChangeDepartureDateTimeOptions;

  constructor(public payload: string[]) {}
}

export class ChangeFlightType implements Action {
  readonly type = FlightFilterActionTypes.ChangeFlightType;

  constructor(public payload: string) {}
}

export class ChangeStep implements Action {
  readonly type = FlightFilterActionTypes.ChangeStep;

  constructor(public payload: string) {}
}

export class ChangeStopOptions implements Action {
  readonly type = FlightFilterActionTypes.ChangeStopOptions;

  constructor(public payload: string[]) {}
}

export class ChangeTicketPolicyOptions implements Action {
  readonly type = FlightFilterActionTypes.ChangeTicketPolicyOptions;

  constructor(public payload: string[]) {}
}

export class ChangeFlightPaginationPage implements Action {
  readonly type = FlightFilterActionTypes.ChangeFlightPaginationPage;
  constructor(public payload: number) {}
}

export class ChangeFlightPaginationSort implements Action {
  readonly type = FlightFilterActionTypes.ChangeFlightPaginationSort;
  constructor(public payload: string[]) {}
}

export class ChangeFlightPaginationSize implements Action {
  readonly type = FlightFilterActionTypes.ChangeFlightPaginationSize;
  constructor(public payload: number) {}
}

export class ChangeSearchID implements Action {
  readonly type = FlightFilterActionTypes.ChangeSearchID;
  constructor(public payload: string) {}
}

export class SelectDeparture implements Action {
  readonly type = FlightFilterActionTypes.SelectDeparture;
  constructor(public payload: SelectDepartureItem) {}
}

export type FlightFilterAction =
  | ResetFlightFilter
  | ResetFlightFilterRoundTrip
  | ChangeFlightFilter
  | ChangeAirlineOptions
  | ChangeArrivalDateTimeOptions
  | ChangeArrivalDateTimeReturnOptions
  | ChangeCabinClassOptions
  | ChangeDepartureDateTimeOptions
  | ChangeFlightType
  | ChangeStep
  | ChangeStopOptions
  | ChangeTicketPolicyOptions
  | ChangeFlightPaginationPage
  | ChangeFlightPaginationSort
  | ChangeFlightPaginationSize
  | SelectDeparture
  | ChangeSearchID;

export const fromFlightFilterActions = {
  ResetFlightFilter,
  ResetFlightFilterRoundTrip,
  ChangeFlightFilter,
  ChangeAirlineOptions,
  ChangeArrivalDateTimeOptions,
  ChangeArrivalDateTimeReturnOptions,
  ChangeCabinClassOptions,
  ChangeDepartureDateTimeOptions,
  ChangeFlightType,
  ChangeStep,
  ChangeStopOptions,
  ChangeTicketPolicyOptions,
  ChangeFlightPaginationPage,
  ChangeFlightPaginationSort,
  ChangeFlightPaginationSize,
  SelectDeparture,
  ChangeSearchID
};
