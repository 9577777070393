import { PromotionRedemptionRes } from '../../lib/model/promotionRedemptionRes';
import {
  PromotionValidateAction,
  PromotionValidateActionTypes
} from './promotion-validate.actions';

export const PROMOTIONVALIDATE_FEATURE_KEY = 'promotionValidate';

/**
 * Interface for the 'PromotionValidate' data used in
 *  - PromotionValidateState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface PromotionValidateState {
  list: PromotionRedemptionRes; // list of PromotionValidate; analogous to a sql normalized table
  selectedId?: string | number; // which PromotionValidate record has been selected
  loaded: boolean; // has the PromotionValidate list been loaded
  error?: any; // last none error (if any)
}

export interface PromotionValidatePartialState {
  readonly [PROMOTIONVALIDATE_FEATURE_KEY]: PromotionValidateState;
}

export const initialState: PromotionValidateState = {
  list: null,
  loaded: false
};

export function reducer(
  state: PromotionValidateState = initialState,
  action: PromotionValidateAction
): PromotionValidateState {
  switch (action.type) {
    case PromotionValidateActionTypes.PromotionValidateLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
