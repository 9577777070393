import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '@env/environment';
@Injectable()
export class CovidDialogService {
  constructor(public httpClient: HttpClient) {}

  // allDataSubject = new BehaviorSubject<any>(null);
  // allData$ = this.allDataSubject.asObservable();
  loadData() {
    return this.httpClient.get(
      `${environment.api.covidNotifications}/api/notification?type=ALL`
    );
  }
}
