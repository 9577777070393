import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PROFILE_FEATURE_KEY, ProfileState } from './profile.reducer';

// Lookup the 'Profile' feature state managed by NgRx
const getProfileState = createFeatureSelector<ProfileState>(
  PROFILE_FEATURE_KEY
);

const getLoaded = createSelector(
  getProfileState,
  (state: ProfileState) => state.loaded
);
const getError = createSelector(
  getProfileState,
  (state: ProfileState) => state.error
);

const getAllProfile = createSelector(
  getProfileState,
  getLoaded,
  (state: ProfileState, isLoaded) => {
    return isLoaded ? state.resultAll : null;
  }
);
const getProfile = createSelector(
  getProfileState,
  getLoaded,
  (state: ProfileState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getProfileState,
  (state: ProfileState) => state.selectedId
);
const getSelectedProfile = createSelector(
  getAllProfile,
  getSelectedId,
  (profile, id) => {
    return profile ? Object.assign({}, profile) : undefined;
  }
);

//Update customer profile

const getUpdateCustomerProfile = createSelector(
  getProfileState,
  getLoaded,
  (state: ProfileState, isLoaded) => {
    return isLoaded ? state.resultUpdate : null;
  }
);

export const profileQuery = {
  getLoaded,
  getError,
  getAllProfile,
  getProfile,
  getSelectedProfile,
  getUpdateCustomerProfile
};
