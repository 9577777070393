import { OtaResultOfTourSuggestSearchPageDTO } from '@gtd/b2c-client';
import {
  TourSearchByKeywordAction,
  TourSearchByKeywordActionTypes
} from './tour-search-by-keyword.actions';

export const TOURSEARCHBYKEYWORD_FEATURE_KEY = 'tourSearchByKeyword';

/**
 * Interface for the 'TourSearchByKeyword' data used in
 *  - TourSearchByKeywordState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface TourSearchByKeywordState {
  list: OtaResultOfTourSuggestSearchPageDTO; // list of TourSearchByKeyword; analogous to a sql normalized table
  selectedId?: string | number; // which TourSearchByKeyword record has been selected
  loaded: boolean; // has the TourSearchByKeyword list been loaded
  error?: any; // last none error (if any)
}

export interface TourSearchByKeywordPartialState {
  readonly [TOURSEARCHBYKEYWORD_FEATURE_KEY]: TourSearchByKeywordState;
}

export const initialState: TourSearchByKeywordState = {
  list: null,
  loaded: false
};

export function reducer(
  state: TourSearchByKeywordState = initialState,
  action: TourSearchByKeywordAction
): TourSearchByKeywordState {
  switch (action.type) {
    case TourSearchByKeywordActionTypes.TourSearchByKeywordLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case TourSearchByKeywordActionTypes.ResetTourSearchByKeyword: {
      state = {
        ...initialState
      };
      break;
    }
  }
  return state;
}
