import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FlightSearchPartialState } from './flight-search.reducer';
import {
  ComboVJLoadFlightSearch,
  ComboVJFlightSearchLoaded,
  ComboVJFlightSearchLoadError,
  FlightSearchActionTypes,
  ComboVJLoadFlightIntSearch
} from './flight-search.actions';

import { AirTicketsResourceService } from '@gtd/b2c-client';
import { map, takeUntil } from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';

@Injectable()
export class FlightSearchEffects {
  @Effect() loadFlightSearch$ = this.dataPersistence.fetch(
    FlightSearchActionTypes.ComboVJLoadFlightSearch,
    {
      run: (
        action: ComboVJLoadFlightSearch,
        state: FlightSearchPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.airTicketsResourceService
          .lowFareSearchAsyncUsingGET(
            action.payload.originCode,
            action.payload.destinationCode,
            action.payload.departureDate,
            action.payload.time,
            action.payload.key,
            action.payload.returntureDate,
            action.payload.cabinClass,
            action.payload.routeType,
            action.payload.adutsQtt,
            action.payload.childrenQtt,
            action.payload.infantsQtt,
            action.payload.suppliers,
            action.payload.includeEquivfare,
            '',
            action.payload.combo,
            false,
            action.payload.page,
            action.payload.size,
            action.payload.sort
          )
          .pipe(
            map(result => ({
              type: FlightSearchActionTypes.ComboVJFlightSearchLoaded,
              payload: result
            })),
            takeUntil(this.subService.unsubscribe$)
          );
      },

      onError: (action: ComboVJLoadFlightSearch, error) => {
        console.error('Error', error);
        return new ComboVJFlightSearchLoadError(error);
      }
    }
  );

  @Effect() loadFlightIntSearch$ = this.dataPersistence.fetch(
    FlightSearchActionTypes.ComboVJLoadFlightIntSearch,
    {
      run: (
        action: ComboVJLoadFlightIntSearch,
        state: FlightSearchPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.airTicketsResourceService
          .lowFareSearchInternationalAsyncUsingGET(
            action.payload.originCode,
            action.payload.destinationCode,
            action.payload.departureDate,
            action.payload.time,
            action.payload.key,
            action.payload.returntureDate,
            action.payload.cabinClass,
            action.payload.routeType,
            action.payload.adutsQtt,
            action.payload.childrenQtt,
            action.payload.infantsQtt,
            action.payload.includeEquivfare,
            '',
            action.payload.page,
            action.payload.size,
            action.payload.sort
          )
          .pipe(
            map(result => ({
              type: FlightSearchActionTypes.ComboVJFlightSearchLoaded,
              payload: result
            })),
            takeUntil(this.subService.unsubscribe$)
          );
      },

      onError: (action: ComboVJLoadFlightIntSearch, error) => {
        console.error('Error', error);
        return new ComboVJFlightSearchLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<FlightSearchPartialState>,
    private airTicketsResourceService: AirTicketsResourceService,
    private subService: SubscriptionService
  ) {}
}
