import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class FormPassengersService {
  passengers = new BehaviorSubject<Passenger>(null);
  getPassenger = this.passengers.asObservable();

  constructor() { }

  setPassenger(passenger: Passenger) {
    this.passengers.next(passenger);
  }
}
export interface Passenger {
  adultQuantity: number;
  childQuantity: number;
  infantCount:number;
}
