import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PixelTrackingService } from './pixel-tracking.service';

@NgModule({
  imports: [CommonModule]
})
export class PixelTrackingModule {
  static forRoot(): ModuleWithProviders<PixelTrackingModule> {
    return {
      ngModule: PixelTrackingModule,
      providers: [PixelTrackingService]
    };
  }
}
