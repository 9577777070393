import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { FilterAvailableState } from '../../../../../state/filter-available/filter-available.reducer';
import { FilterAvailableFacade } from '../../../../../state/filter-available/filter-available.facade';

@Component({
  selector: 'gtd-filter-panel-mobile',
  templateUrl: './filter-panel-mobile.component.html',
  styleUrls: ['./filter-panel-mobile.component.scss']
})
export class FilterPanelMobileComponent implements OnInit {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<FilterPanelMobileComponent>,
    private filterAvailableFacade: FilterAvailableFacade,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: FilterAvailableState
  ) {}

  ngOnInit() {}

  resetAllFilter() {
    this.filterAvailableFacade.resetFilter();
    this._bottomSheetRef.dismiss();
  }

  closeBottomSheet() {
    this._bottomSheetRef.dismiss();
  }
}
