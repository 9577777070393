import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {CustomerTravellerDTO} from "@gtd/api-services/customer-service";
import {
  PaxSSR
} from "../../../../checkout/src/lib/flight/customer-info-step/pax-info-form/pax-info-form-item/pax-info-form-item.component";

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  customerFormSubmitSubject$ = new BehaviorSubject<boolean>(false);
  customerFormSubmit$ = this.customerFormSubmitSubject$.asObservable();

  dataMappingContact = new BehaviorSubject<PaxInfo>(null);
  getDataMappingContact = this.dataMappingContact.asObservable();

  dataMappingCustomer = new BehaviorSubject<number>(null);
  getDataMappingCustomer = this.dataMappingCustomer.asObservable();

  dataMappingPaxInfo = new BehaviorSubject<number>(null);
  getDataMappingPaxInfo = this.dataMappingPaxInfo.asObservable();

  stepMove = new BehaviorSubject<boolean>(false);
  getStepMove = this.stepMove.asObservable();

  stepNumber = new BehaviorSubject<number>(null);
  getStepNumber = this.stepNumber.asObservable();
  constructor() {}

  setCustomerFormSubmit(submit: boolean) {
    this.customerFormSubmitSubject$.next(submit);
  }

  setDataMappingContact(paxInfo: PaxInfo) {
    this.dataMappingContact.next(paxInfo);
  }
  setDataMappingCustomer(id: number) {
    this.dataMappingCustomer.next(id);
  }
  setDataMappingPaxInfo(id: number) {
    this.dataMappingPaxInfo.next(id);
  }
  setStepMove(stepMove: boolean) {
    this.stepMove.next(stepMove);
  }

  setStepNumber(stepNumber: number) {
    this.stepNumber.next(stepNumber);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector(
      '.pax-form-item.ng-invalid'
    );
    this.scrollTo(firstElementWithError);
  }
}

export interface PaxInfo {
  id?: number;
  index?: number;

  travelerCode?: string;
  sex?: CustomerTravellerDTO.GenderEnum;
  adultType?: CustomerTravellerDTO.AdultTypeEnum;
  firstName?: string;
  lastName?: string;
  traveller?: any;
  companyName?: string;
  companyAddress?: string;
  companyTaxCode?: string;
  isSavePassenger?: boolean;
  mappingToContactInf?: boolean;
  hasMemberCard?: boolean;
  memberCardType?: string;
  memberCardValue?: string;
  passport?: string;
  expiredDate?: Date;
  country?: string;
  nationality?: string;
  documentIssuedCountry?: string;
  cuNationality?: string;
  dob?: Date;
  baggageDepart?: PaxSSR;
  baggageReturn?: PaxSSR;
  mealDepart?: PaxSSR;
  mealReturn?: PaxSSR;
  valid?: boolean;
  phoneNumber?: string;
  email?: string;
}
