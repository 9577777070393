import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchResultComponent} from "./search-result/search-result.component";
import {MapsPanelComponent} from "./search-result/maps-panel/maps-panel.component";
import {HotelDetailComponent} from "./hotel-detail/hotel-detail.component";

const routes: Routes = [
  { path: '', component: SearchResultComponent },
  { path: 'maps', component: MapsPanelComponent },
  { path: 'detail', component: HotelDetailComponent }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HotelModuleRouting {}
