import { BannerAction, BannerActionTypes } from './banner.actions';

export const BANNER_FEATURE_KEY = 'homeBanner';

/**
 * Interface for the 'Banner' data used in
 *  - BannerState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface BannerList {
  [key: string]: any[];
}

export interface BannerState {
  list: BannerList; // list of Banner; analogous to a sql normalized table
  selectedId?: string | number; // which Banner record has been selected
  loaded: boolean; // has the Banner list been loaded
  error?: any; // last none error (if any)
}

export interface BannerPartialState {
  readonly [BANNER_FEATURE_KEY]: BannerState;
}

export const initialState: BannerState = {
  list: null,
  loaded: false
};

export function reducer(
  state: BannerState = initialState,
  action: BannerAction
): BannerState {
  switch (action.type) {
    case BannerActionTypes.BannerLoaded: {
      state = {
        ...state,
        list: {
          ...state.list,
          [action.payload.type]: action.payload.data
        },
        loaded: true
      };
      break;
    }
  }
  return state;
}
