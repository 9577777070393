import {NgModule,  Pipe, PipeTransform } from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PhoneRegisterStateEffects} from './+state/phone-register-state.effects';
import {NxModule} from '@nrwl/angular';
import {environment} from '@env/environment';
import {AccountResourceService, BASE_PATH} from '@gtd/b2c-client';
import {PhoneRegisterStateFacade} from './+state/phone-register-state.facade';
import {
  initialState as phoneResendOtpInitialState,
  PHONERESENDOTPSTATE_FEATURE_KEY,
  reducerResendOtp
} from './+resenotp-state/phone-resend-otp-state.reducer';
import {PhoneResendOtpStateEffects} from './+resenotp-state/phone-resend-otp-state.effects';
import {PhoneResendOtpStateFacade} from './+resenotp-state/phone-resend-otp-state.facade';
import {
  initialState as phoneRegisterInitialState,
  PHONEREGISTERSTATE_FEATURE_KEY,
  reducer
} from './+state/phone-register-state.reducer';
import {CountryFacade} from "@gtd/meta";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),

    StoreModule.forFeature(PHONEREGISTERSTATE_FEATURE_KEY, reducer, {
      initialState: phoneRegisterInitialState
    }),
    EffectsModule.forFeature([PhoneRegisterStateEffects]),

    StoreModule.forFeature(PHONERESENDOTPSTATE_FEATURE_KEY, reducerResendOtp, {
      initialState: phoneResendOtpInitialState
    }),
    EffectsModule.forFeature([PhoneResendOtpStateEffects])


  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2cClient },
    PhoneRegisterStateFacade,
    AccountResourceService,
    PhoneResendOtpStateFacade,
    CountryFacade
  ]
})
export class PhoneRegisterStateModule {}
