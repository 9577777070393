/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BookingCodeRS } from '../model/bookingCodeRS';
import { OfflineBookingRQ } from '../model/offlineBookingRQ';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OfflineBookingResourceService {

    protected basePath = 'https://localhost:8281';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createOfflineBooking
     *
     * @param offlineBookingRQ offlineBookingRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOfflineBookingUsingPOST(offlineBookingRQ: OfflineBookingRQ, observe?: 'body', reportProgress?: boolean): Observable<BookingCodeRS>;
    public createOfflineBookingUsingPOST(offlineBookingRQ: OfflineBookingRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodeRS>>;
    public createOfflineBookingUsingPOST(offlineBookingRQ: OfflineBookingRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodeRS>>;
    public createOfflineBookingUsingPOST(offlineBookingRQ: OfflineBookingRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineBookingRQ === null || offlineBookingRQ === undefined) {
            throw new Error('Required parameter offlineBookingRQ was null or undefined when calling createOfflineBookingUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodeRS>(`${this.basePath}/api/bookings/offline-booking`,
            offlineBookingRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createOfflineBookingV2
     *
     * @param offlineBookingRQ offlineBookingRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOfflineBookingV2UsingPOST(offlineBookingRQ: OfflineBookingRQ, observe?: 'body', reportProgress?: boolean): Observable<BookingCodeRS>;
    public createOfflineBookingV2UsingPOST(offlineBookingRQ: OfflineBookingRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodeRS>>;
    public createOfflineBookingV2UsingPOST(offlineBookingRQ: OfflineBookingRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodeRS>>;
    public createOfflineBookingV2UsingPOST(offlineBookingRQ: OfflineBookingRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (offlineBookingRQ === null || offlineBookingRQ === undefined) {
            throw new Error('Required parameter offlineBookingRQ was null or undefined when calling createOfflineBookingV2UsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodeRS>(`${this.basePath}/api/bookings/offline-booking-v2`,
            offlineBookingRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
