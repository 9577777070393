import { Action } from '@ngrx/store';
import {BannerDTO} from "@gtd/b2c-client";

export enum PromotionSectionStateActionTypes {
  LoadPromotionSectionState = '[PromotionSectionState] Load PromotionSectionState',
  PromotionSectionStateLoaded = '[PromotionSectionState] PromotionSectionState Loaded',
  PromotionSectionStateLoadError = '[PromotionSectionState] PromotionSectionState Load Error'
}

export class LoadPromotionSectionState implements Action {
  readonly type = PromotionSectionStateActionTypes.LoadPromotionSectionState;
}

export class PromotionSectionStateLoadError implements Action {
  readonly type =
    PromotionSectionStateActionTypes.PromotionSectionStateLoadError;
  constructor(public payload: any) {}
}

export class PromotionSectionStateLoaded implements Action {
  readonly type = PromotionSectionStateActionTypes.PromotionSectionStateLoaded;
  constructor(public payload: Array<BannerDTO>) {}
}

export type PromotionSectionStateAction =
  | LoadPromotionSectionState
  | PromotionSectionStateLoaded
  | PromotionSectionStateLoadError;

export const fromPromotionSectionStateActions = {
  LoadPromotionSectionState,
  PromotionSectionStateLoaded,
  PromotionSectionStateLoadError
};
