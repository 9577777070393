import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'gtd-departure-flight-item',
  templateUrl: './departure-flight-item.component.html',
  styleUrls: ['./departure-flight-item.component.scss']
})
export class DepartureFlightItemComponent implements OnInit {
  @Input() formSearchResult: any;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
  resetFilter(flightDirection: any) {
    flightDirection = "D";
    this.changed.emit(flightDirection);
  }
}
