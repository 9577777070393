/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { MarkupDTO } from '../model/markupDTO';
import { MarkupDefault } from '../model/markupDefault';
import { PriorityMarkupDTO } from '../model/priorityMarkupDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MarkupResourceService {

    protected basePath = 'https://10.7.71.20:8282';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changePriority
     * 
     * @param changePriority changePriority
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePriorityUsingPUT1(changePriority: PriorityMarkupDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupDTO>>;
    public changePriorityUsingPUT1(changePriority: PriorityMarkupDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupDTO>>>;
    public changePriorityUsingPUT1(changePriority: PriorityMarkupDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupDTO>>>;
    public changePriorityUsingPUT1(changePriority: PriorityMarkupDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (changePriority === null || changePriority === undefined) {
            throw new Error('Required parameter changePriority was null or undefined when calling changePriorityUsingPUT1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<MarkupDTO>>(`${this.basePath}/api/change-priority/markups`,
            changePriority,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * convertData
     * 
     * @param markupId markupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public convertDataUsingPUT(markupId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public convertDataUsingPUT(markupId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public convertDataUsingPUT(markupId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public convertDataUsingPUT(markupId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (markupId === null || markupId === undefined) {
            throw new Error('Required parameter markupId was null or undefined when calling convertDataUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (markupId !== undefined && markupId !== null) {
            queryParameters = queryParameters.set('markupId', <any>markupId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/markups/splitByAgency`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * copyMarkup
     * 
     * @param markupId markupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyMarkupUsingPUT(markupId: number, observe?: 'body', reportProgress?: boolean): Observable<MarkupDTO>;
    public copyMarkupUsingPUT(markupId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MarkupDTO>>;
    public copyMarkupUsingPUT(markupId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MarkupDTO>>;
    public copyMarkupUsingPUT(markupId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (markupId === null || markupId === undefined) {
            throw new Error('Required parameter markupId was null or undefined when calling copyMarkupUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MarkupDTO>(`${this.basePath}/api/markups/copy`,
            markupId,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createDefaultMarkup
     * 
     * @param applyToAgencyCode applyToAgencyCode
     * @param orgCode orgCode
     * @param agencyCode agencyCode
     * @param applyToAgencyName applyToAgencyName
     * @param markupName markupName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDefaultMarkupUsingPUT(applyToAgencyCode: string, orgCode: string, agencyCode: string, applyToAgencyName: string, markupName: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupDTO>>;
    public createDefaultMarkupUsingPUT(applyToAgencyCode: string, orgCode: string, agencyCode: string, applyToAgencyName: string, markupName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupDTO>>>;
    public createDefaultMarkupUsingPUT(applyToAgencyCode: string, orgCode: string, agencyCode: string, applyToAgencyName: string, markupName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupDTO>>>;
    public createDefaultMarkupUsingPUT(applyToAgencyCode: string, orgCode: string, agencyCode: string, applyToAgencyName: string, markupName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applyToAgencyCode === null || applyToAgencyCode === undefined) {
            throw new Error('Required parameter applyToAgencyCode was null or undefined when calling createDefaultMarkupUsingPUT.');
        }

        if (orgCode === null || orgCode === undefined) {
            throw new Error('Required parameter orgCode was null or undefined when calling createDefaultMarkupUsingPUT.');
        }

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling createDefaultMarkupUsingPUT.');
        }

        if (applyToAgencyName === null || applyToAgencyName === undefined) {
            throw new Error('Required parameter applyToAgencyName was null or undefined when calling createDefaultMarkupUsingPUT.');
        }

        if (markupName === null || markupName === undefined) {
            throw new Error('Required parameter markupName was null or undefined when calling createDefaultMarkupUsingPUT.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (applyToAgencyCode !== undefined && applyToAgencyCode !== null) {
            queryParameters = queryParameters.set('applyToAgencyCode', <any>applyToAgencyCode);
        }
        if (orgCode !== undefined && orgCode !== null) {
            queryParameters = queryParameters.set('orgCode', <any>orgCode);
        }
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (applyToAgencyName !== undefined && applyToAgencyName !== null) {
            queryParameters = queryParameters.set('applyToAgencyName', <any>applyToAgencyName);
        }
        if (markupName !== undefined && markupName !== null) {
            queryParameters = queryParameters.set('markupName', <any>markupName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<Array<MarkupDTO>>(`${this.basePath}/api/markups/default/create`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createMarkup
     * 
     * @param markupDTO markupDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMarkupUsingPOST(markupDTO: MarkupDTO, observe?: 'body', reportProgress?: boolean): Observable<MarkupDTO>;
    public createMarkupUsingPOST(markupDTO: MarkupDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MarkupDTO>>;
    public createMarkupUsingPOST(markupDTO: MarkupDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MarkupDTO>>;
    public createMarkupUsingPOST(markupDTO: MarkupDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (markupDTO === null || markupDTO === undefined) {
            throw new Error('Required parameter markupDTO was null or undefined when calling createMarkupUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MarkupDTO>(`${this.basePath}/api/markups`,
            markupDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteMarkup
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMarkupUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteMarkupUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteMarkupUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteMarkupUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteMarkupUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/markups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllComboMarkups
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllComboMarkupsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupDTO>>;
    public getAllComboMarkupsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupDTO>>>;
    public getAllComboMarkupsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupDTO>>>;
    public getAllComboMarkupsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<MarkupDTO>>(`${this.basePath}/api/markups/allcombo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllDefaultMarkup
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDefaultMarkupUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupDefault>>;
    public getAllDefaultMarkupUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupDefault>>>;
    public getAllDefaultMarkupUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupDefault>>>;
    public getAllDefaultMarkupUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<MarkupDefault>>(`${this.basePath}/api/markups/default`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllMarkups
     * 
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllMarkupsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupDTO>>;
    public getAllMarkupsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupDTO>>>;
    public getAllMarkupsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupDTO>>>;
    public getAllMarkupsUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<MarkupDTO>>(`${this.basePath}/api/markups`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getComboMarkups
     * 
     * @param productType productType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getComboMarkupsUsingGET(productType: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupDTO>>;
    public getComboMarkupsUsingGET(productType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupDTO>>>;
    public getComboMarkupsUsingGET(productType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupDTO>>>;
    public getComboMarkupsUsingGET(productType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productType === null || productType === undefined) {
            throw new Error('Required parameter productType was null or undefined when calling getComboMarkupsUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productType !== undefined && productType !== null) {
            queryParameters = queryParameters.set('productType', <any>productType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<MarkupDTO>>(`${this.basePath}/api/markups/getComboMarkup`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMarkup
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMarkupUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<MarkupDTO>;
    public getMarkupUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MarkupDTO>>;
    public getMarkupUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MarkupDTO>>;
    public getMarkupUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getMarkupUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<MarkupDTO>(`${this.basePath}/api/markups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getObjects
     * 
     * @param businessType businessType
     * @param status status
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getObjectsUsingGET(businessType?: string, status?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<MarkupDTO>>;
    public getObjectsUsingGET(businessType?: string, status?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MarkupDTO>>>;
    public getObjectsUsingGET(businessType?: string, status?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MarkupDTO>>>;
    public getObjectsUsingGET(businessType?: string, status?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (businessType !== undefined && businessType !== null) {
            queryParameters = queryParameters.set('businessType', <any>businessType);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<MarkupDTO>>(`${this.basePath}/api/_search/markups`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateMarkup
     * 
     * @param markupDTO markupDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMarkupUsingPUT(markupDTO: MarkupDTO, observe?: 'body', reportProgress?: boolean): Observable<MarkupDTO>;
    public updateMarkupUsingPUT(markupDTO: MarkupDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MarkupDTO>>;
    public updateMarkupUsingPUT(markupDTO: MarkupDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MarkupDTO>>;
    public updateMarkupUsingPUT(markupDTO: MarkupDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (markupDTO === null || markupDTO === undefined) {
            throw new Error('Required parameter markupDTO was null or undefined when calling updateMarkupUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MarkupDTO>(`${this.basePath}/api/markups`,
            markupDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
