/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CreditRuleDTO { 
    createdBy: string;
    createdDate: Date;
    credit: number;
    creditType: CreditRuleDTO.CreditTypeEnum;
    departmentId?: number;
    employeeId?: number;
    endDate?: Date;
    id?: number;
    startDate?: Date;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace CreditRuleDTO {
    export type CreditTypeEnum = 'PER_YEAR' | 'PER_MONTH' | 'AVAILABLE_BUDGET';
    export const CreditTypeEnum = {
        PERYEAR: 'PER_YEAR' as CreditTypeEnum,
        PERMONTH: 'PER_MONTH' as CreditTypeEnum,
        AVAILABLEBUDGET: 'AVAILABLE_BUDGET' as CreditTypeEnum
    };
}
