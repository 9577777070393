/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PayByDebitReq { 
    paymentOptionEnum?: PayByDebitReq.PaymentOptionEnumEnum;
}
export namespace PayByDebitReq {
    export type PaymentOptionEnumEnum = 'Vietcombank' | 'TechComBank' | 'AgriBank' | 'BIDV' | 'SacomBank' | 'Eximbank' | 'PGBank' | 'GPBank' | 'NamABank' | 'SaigonBank' | 'ABBank' | 'VIB' | 'MB' | 'MaritimeBank' | 'OceanBank' | 'BacABank' | 'VietinBank' | 'SHB' | 'VPBank' | 'DongABank' | 'TienphongBank' | 'ACB' | 'OCB' | 'HDBank' | 'NaviBank' | 'VietABank' | 'LienVietPostBank' | 'BaoVietBank' | 'Visa' | 'Mastercard';
    export const PaymentOptionEnumEnum = {
        Vietcombank: 'Vietcombank' as PaymentOptionEnumEnum,
        TechComBank: 'TechComBank' as PaymentOptionEnumEnum,
        AgriBank: 'AgriBank' as PaymentOptionEnumEnum,
        BIDV: 'BIDV' as PaymentOptionEnumEnum,
        SacomBank: 'SacomBank' as PaymentOptionEnumEnum,
        Eximbank: 'Eximbank' as PaymentOptionEnumEnum,
        PGBank: 'PGBank' as PaymentOptionEnumEnum,
        GPBank: 'GPBank' as PaymentOptionEnumEnum,
        NamABank: 'NamABank' as PaymentOptionEnumEnum,
        SaigonBank: 'SaigonBank' as PaymentOptionEnumEnum,
        ABBank: 'ABBank' as PaymentOptionEnumEnum,
        VIB: 'VIB' as PaymentOptionEnumEnum,
        MB: 'MB' as PaymentOptionEnumEnum,
        MaritimeBank: 'MaritimeBank' as PaymentOptionEnumEnum,
        OceanBank: 'OceanBank' as PaymentOptionEnumEnum,
        BacABank: 'BacABank' as PaymentOptionEnumEnum,
        VietinBank: 'VietinBank' as PaymentOptionEnumEnum,
        SHB: 'SHB' as PaymentOptionEnumEnum,
        VPBank: 'VPBank' as PaymentOptionEnumEnum,
        DongABank: 'DongABank' as PaymentOptionEnumEnum,
        TienphongBank: 'TienphongBank' as PaymentOptionEnumEnum,
        ACB: 'ACB' as PaymentOptionEnumEnum,
        OCB: 'OCB' as PaymentOptionEnumEnum,
        HDBank: 'HDBank' as PaymentOptionEnumEnum,
        NaviBank: 'NaviBank' as PaymentOptionEnumEnum,
        VietABank: 'VietABank' as PaymentOptionEnumEnum,
        LienVietPostBank: 'LienVietPostBank' as PaymentOptionEnumEnum,
        BaoVietBank: 'BaoVietBank' as PaymentOptionEnumEnum,
        Visa: 'Visa' as PaymentOptionEnumEnum,
        Mastercard: 'Mastercard' as PaymentOptionEnumEnum
    };
}
