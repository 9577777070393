import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { BasePartnerResourceService, GroupBooking } from '@gtd/b2c-client';
import { PolicyByBookingFacade } from '@gtd/booking-result';
import { Subscription } from 'rxjs';
import { BookingDetailFacade } from '../booking-detail/detail-state/+state/booking-detail.facade';

@Component({
  selector: 'gtd-hotel-detail',
  templateUrl: './hotel-detail.component.html',
  styleUrls: ['./hotel-detail.component.scss']
})
export class HotelDetailComponent implements OnInit, OnDestroy {
  bookingNumber: string;
  subscriptions = new Subscription();
  groupBooking: GroupBooking;
  allPolicyByBooking$ = this.policyByBookingFacade.allPolicyByBooking$;
  isLoading: boolean;
  appName = environment.appName;
  personRepresentation;
  hotelDetail;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private policyByBookingFacade: PolicyByBookingFacade,
    private bookingDetailFacade: BookingDetailFacade,
    private basePartnerResourceService: BasePartnerResourceService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _location: Location
  ) {
    this.route.queryParams.subscribe(params => {
      this.bookingNumber = params.booking_number;
      if (this.bookingNumber) {
        bookingDetailFacade.getBookingByBookingNumber(params.booking_number);
        policyByBookingFacade.loadAllPolicyByBooking(params.booking_number);
      }
    });
  }

  ngOnInit() {
    this.subscriptions.add(
      this.bookingDetailFacade.bookingDetail$.subscribe(
        (groupBooking: GroupBooking) => {
          if (groupBooking) {
            this.groupBooking = groupBooking;
            this.hotelDetail = groupBooking.bookingInfo.transactionInfos.filter(
              trans => trans.supplierType === 'HOTEL'
            );
            this.personRepresentation = groupBooking.bookingInfo.travelerInfos.filter(
              traveler => traveler.personRepresentation
            );
          }
        }
      )
    );
  }
  backClicked() {
    this._location.back();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
