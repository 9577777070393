import {
  PhoneOTPRegisterStateLoaded,
  PhoneRegisterStateAction,
  PhoneRegisterStateActionTypes
} from './phone-register-state.actions';

export const PHONEREGISTERSTATE_FEATURE_KEY = 'phoneRegisterState';

/**
 * Interface for the 'PhoneRegisterState' data used in
 *  - PhoneRegisterStateState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface PhoneRegisterStateState {
  result: any; // list of PhoneRegisterState; analogous to a sql normalized table
  selectedId?: string | number; // which PhoneRegisterState record has been selected
  loaded: boolean; // has the PhoneRegisterState list been loaded
  error?: any; // last none error (if any)
  errorMessage?: string;
  errorCode?: number;
  status: 'clear'|'active'|'inactive'|'error'; // last none error (if any)
}

export interface PhoneRegisterStatePartialState {
  readonly [PHONEREGISTERSTATE_FEATURE_KEY]: PhoneRegisterStateState;
}

export const initialState: PhoneRegisterStateState = {
  result: null,
  loaded: false,
  status: "clear"
};

export function reducer(
  state: PhoneRegisterStateState = initialState,
  action: PhoneRegisterStateAction
): PhoneRegisterStateState {
  switch (action.type) {
    case PhoneRegisterStateActionTypes.PhoneRegisterStateLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true,
        status: "inactive"
      };
      break;
    }
    case PhoneRegisterStateActionTypes.PhoneRegisterStateLoadError: {
      state = {
        result : action.payload,
        errorMessage : action.payload.error.error? action.payload.error.error.message: 'liuliu',
        errorCode: action.payload.error.error? action.payload.error.error.code: 4044,
        loaded: true,
        status: "error"
      }

      break;
    }
    case PhoneRegisterStateActionTypes.ResetAllPhoneRegisterState: {
      state = initialState;
      break;
    }
    case PhoneRegisterStateActionTypes.PhoneOTPRegisterStateLoaded: {
      state = {
        ...state,
        loaded: true,
        status: "active"
      };
      break;
    }
    case PhoneRegisterStateActionTypes.PhoneOTPRegisterStateLoadError: {
      state = {
        result : action.payload,
        errorMessage : action.payload.error.error? action.payload.error.error.message: 'liuliu',
        errorCode: action.payload.error.error? action.payload.error.error.code: 4044,
        loaded: true,
        status: "error"
      }

      break;
    }
    case PhoneRegisterStateActionTypes.ResetAllPhoneRegisterState: {
      state = initialState;
      break;
    }
    case PhoneRegisterStateActionTypes.LoadDestroyPhoneOTPRegisterState: {
      state = initialState;
      break;
    }
  }
  return state;
}
