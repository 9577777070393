import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { AvailableFlightsPartialState } from './available-flights.reducer';
import { availableFlightsQuery } from './available-flights.selectors';
import {
  FilterFlightsAvailable,
  ComboVJLoadAvailableFlights,
  ComboVJLoadInternationnalFlights,
  ComboVJResetAvailableFlights,
  ComboVJSelectAvailableFlights
} from './available-flights.actions';

@Injectable()
export class AvailableFlightsFacade {
  loaded$ = this.store.pipe(select(availableFlightsQuery.getLoaded));
  allAvailableFlights$ = this.store.pipe(
    select(availableFlightsQuery.getAllAvailableFlights)
  );
  selectedAvailableFlights$ = this.store.pipe(
    select(availableFlightsQuery.getSelectedAvailableFlights)
  );

  constructor(private store: Store<AvailableFlightsPartialState>) {}

  loadAll(filter: FilterFlightsAvailable) {
    this.store.dispatch(new ComboVJLoadAvailableFlights(filter));
  }
  loadInternationalFlights(filter) {
    this.store.dispatch(new ComboVJLoadInternationnalFlights(filter));
  }
  selectedFlight(id: string) {
    this.store.dispatch(new ComboVJSelectAvailableFlights(id));
  }

  resetAll() {
    this.store.dispatch(new ComboVJResetAvailableFlights());
  }
}
