/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface SSRItem { 
    amount?: number;
    code?: string;
    direction?: SSRItem.DirectionEnum;
    fareCode?: string;
    id?: string;
    name?: string;
    note?: string;
    serviceType?: SSRItem.ServiceTypeEnum;
}
export namespace SSRItem {
    export type DirectionEnum = 'DEPARTURE' | 'RETURN' | 'TRIP';
    export const DirectionEnum = {
        DEPARTURE: 'DEPARTURE' as DirectionEnum,
        RETURN: 'RETURN' as DirectionEnum,
        TRIP: 'TRIP' as DirectionEnum
    };
    export type ServiceTypeEnum = 'BAGGAGE' | 'MEAL' | 'INSURANCE' | 'OTHER' | 'ROUTING_MODIFICATION';
    export const ServiceTypeEnum = {
        BAGGAGE: 'BAGGAGE' as ServiceTypeEnum,
        MEAL: 'MEAL' as ServiceTypeEnum,
        INSURANCE: 'INSURANCE' as ServiceTypeEnum,
        OTHER: 'OTHER' as ServiceTypeEnum,
        ROUTINGMODIFICATION: 'ROUTING_MODIFICATION' as ServiceTypeEnum
    };
}
