/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IdAutosavesBody3 { 
    /**
     * The ID for the parent of the post.
     */
    parent?: string;
    /**
     * The date the post was published, in the site's timezone.
     */
    date?: Date;
    /**
     * The date the post was published, as GMT.
     */
    dateGmt?: Date;
    /**
     * An alphanumeric identifier for the post unique to its type.
     */
    slug?: string;
    /**
     * A named status for the post.
     */
    status?: Array<IdAutosavesBody3.StatusEnum>;
    /**
     * A password to protect access to the content and excerpt.
     */
    password?: string;
    /**
     * The title for the post.
     */
    title?: string;
    /**
     * The content for the post.
     */
    content?: string;
    /**
     * The ID for the author of the post.
     */
    author?: string;
    /**
     * The excerpt for the post.
     */
    excerpt?: string;
    /**
     * The ID of the featured media for the post.
     */
    featuredMedia?: string;
    /**
     * Whether or not comments are open on the post.
     */
    commentStatus?: Array<IdAutosavesBody3.CommentStatusEnum>;
    /**
     * Whether or not the post can be pinged.
     */
    pingStatus?: Array<IdAutosavesBody3.PingStatusEnum>;
    /**
     * The order of the post in relation to other posts.
     */
    menuOrder?: string;
    /**
     * Meta fields.
     */
    meta?: string;
    /**
     * The theme file to use to display the post.
     */
    template?: string;
    /**
     * Expose advanced custom fields.
     */
    acf?: string;
}
export namespace IdAutosavesBody3 {
    export type StatusEnum = 'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled';
    export const StatusEnum = {
        Publish: 'publish' as StatusEnum,
        Future: 'future' as StatusEnum,
        Draft: 'draft' as StatusEnum,
        Pending: 'pending' as StatusEnum,
        Private: 'private' as StatusEnum,
        AcfDisabled: 'acf-disabled' as StatusEnum
    };
    export type CommentStatusEnum = 'open' | 'closed';
    export const CommentStatusEnum = {
        Open: 'open' as CommentStatusEnum,
        Closed: 'closed' as CommentStatusEnum
    };
    export type PingStatusEnum = 'open' | 'closed';
    export const PingStatusEnum = {
        Open: 'open' as PingStatusEnum,
        Closed: 'closed' as PingStatusEnum
    };
}