/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { LienVietPlaceOrderReq } from '../model/lienVietPlaceOrderReq';
import { OtaResultOfstring } from '../model/otaResultOfstring';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LienVietResourceService {

    protected basePath = 'https://172.16.100.16:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * commit
     *
     * @param data data
     * @param key key
     * @param agentSite agent_site
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitUsingGET(data: string, key: string, agentSite: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public commitUsingGET(data: string, key: string, agentSite: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public commitUsingGET(data: string, key: string, agentSite: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public commitUsingGET(data: string, key: string, agentSite: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling commitUsingGET.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling commitUsingGET.');
        }

        if (agentSite === null || agentSite === undefined) {
            throw new Error('Required parameter agentSite was null or undefined when calling commitUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (data !== undefined && data !== null) {
            queryParameters = queryParameters.set('data', <any>data);
        }
        if (key !== undefined && key !== null) {
            queryParameters = queryParameters.set('key', <any>key);
        }
        if (agentSite !== undefined && agentSite !== null) {
            queryParameters = queryParameters.set('agent_site', <any>agentSite);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfstring>(`${this.basePath}/api/lienviet/commit`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * placeOrder
     *
     * @param lienVietPlaceOrderReq lienVietPlaceOrderReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public placeOrderUsingPOST(lienVietPlaceOrderReq: LienVietPlaceOrderReq, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public placeOrderUsingPOST(lienVietPlaceOrderReq: LienVietPlaceOrderReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public placeOrderUsingPOST(lienVietPlaceOrderReq: LienVietPlaceOrderReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public placeOrderUsingPOST(lienVietPlaceOrderReq: LienVietPlaceOrderReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (lienVietPlaceOrderReq === null || lienVietPlaceOrderReq === undefined) {
            throw new Error('Required parameter lienVietPlaceOrderReq was null or undefined when calling placeOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfstring>(`${this.basePath}/api/lienviet/place-order`,
            lienVietPlaceOrderReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validate
     *
     * @param data data
     * @param key key
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateUsingPOST1(data: string, key: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public validateUsingPOST1(data: string, key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public validateUsingPOST1(data: string, key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public validateUsingPOST1(data: string, key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling validateUsingPOST1.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling validateUsingPOST1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (data !== undefined && data !== null) {
            queryParameters = queryParameters.set('data', <any>data);
        }
        if (key !== undefined && key !== null) {
            queryParameters = queryParameters.set('key', <any>key);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OtaResultOfstring>(`${this.basePath}/api/lienviet/validate`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
