import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gtd-combo-booking',
  templateUrl: './combo-booking.component.html',
  styleUrls: ['./combo-booking.component.scss']
})
export class ComboBookingComponent implements OnInit {
  @Input() flightBooking;
  @Input() hotelBooking;
  @Input() params;
  @Input() searchParams;
  @Output() scrollToSection: EventEmitter<any> = new EventEmitter<any>();
  hotelBookingPrice: number;
  flightBookingPrice: number;
  priceOnePerson: number;
  constructor() {}

  ngOnInit() {
    this.priceCalculator();
  }
  priceCalculator() {
    this.flightBookingPrice = this.flightBooking.reduce((arr, cur) => {
      return (
        arr +
        cur.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare
          .amount
      );
    }, 0);

    this.hotelBookingPrice = this.hotelBooking.totalPrice;
    const numberPerson = JSON.parse(this.searchParams.rooms).reduce(
      (arr, cur) => {
        return arr + cur.adultQuantity + cur.infantCount + cur.childQuantity;
      },
      0
    );

    const adultTicketPrice = this.flightBooking.reduce((arr, cur) => {
      return (
        arr +
        cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
          .baseFare.amount +
        (cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
          .comboMarkup
          ? cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
              .comboMarkup.amount
          : 0) +
        cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
          .serviceTax.amount
      );
    }, 0);

    this.priceOnePerson =
      Math.ceil(this.hotelBookingPrice / numberPerson) + adultTicketPrice;
  }
  scrollToRooms() {
    this.scrollToSection.emit('detail');
  }
}
