import {Component, OnDestroy, OnInit} from '@angular/core';
import {GetInviteCodeFacade, GetReferralFacade} from "@gtd/api-services/agentsrv";
import {MatSnackBar} from "@angular/material";
import {Subscription} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {fadeIn, fadeInOut} from "@gtd/helpers";
import {SnackbarComponent} from "@gtd/components/snackbar";

@Component({
  selector: 'gtd-referral-info',
  templateUrl: './referral-info.component.html',
  styleUrls: ['./referral-info.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('600ms ease-out')),
      transition('default => rotated', animate('600ms ease-in')),
    ]),
    fadeIn,
    fadeInOut
  ],
})
export class ReferralInfoComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;

  loadedReferral$ = this.getReferralFacade.loaded$;
  allGetReferral$ = this.getReferralFacade.allGetReferral$;
  allGetInviteCode$ = this.getInviteCodeFacade.allGetInviteCode$;
  referralUrl: string;
  referralCode: string;
  private subscription: Subscription = new Subscription();

  constructor(
    private getReferralFacade: GetReferralFacade,
    private getInviteCodeFacade: GetInviteCodeFacade,
    private _snackBar: MatSnackBar,
  ) {
    this.getReferralFacade.loadAll();
  }

  ngOnInit() {
    this.subscription.add(
      this.getInviteCodeFacade.allGetInviteCode$.subscribe(getInviteCode => {
        if(getInviteCode && getInviteCode.result) {
          this.referralCode = getInviteCode.result.inviteCode;
          this.referralUrl = getInviteCode.result.url;
        } else {
          this.getInviteCodeFacade.loadAll();
        }
      })
    )
  }

  copyMessage(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value == 'URL'? this.referralUrl : this.referralCode;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: { message: 'Copied!', icon: 'check' },
      panelClass: 'snackbar-success',
      verticalPosition: 'top',
      duration: 2000
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
