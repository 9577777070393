import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PromotionSectionStatePartialState} from './promotion-section-state.reducer';
import { promotionSectionStateQuery } from './promotion-section-state.selectors';
import { LoadPromotionSectionState } from './promotion-section-state.actions';

@Injectable()
export class PromotionSectionStateFacade {
  loaded$ = this.store.pipe(select(promotionSectionStateQuery.getLoaded));
  allPromotionSectionState$ = this.store.pipe(
    select(promotionSectionStateQuery.getAllPromotionSectionState)
  );
  selectedPromotionSectionState$ = this.store.pipe(
    select(promotionSectionStateQuery.getSelectedPromotionSectionState)
  );
  promotionSlide() {
    this.store.dispatch(new LoadPromotionSectionState());
  }
  constructor(private store: Store<PromotionSectionStatePartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadPromotionSectionState());
  }
}
