import { Component, Input, OnInit } from '@angular/core';
import { GroupBooking } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-combo-panel-price',
  templateUrl: './combo-panel-price.component.html',
  styleUrls: ['./combo-panel-price.component.scss']
})
export class ComboPanelPriceComponent implements OnInit {
  @Input() groupBooking: any;
  hotelDetail: any;
  airDetail: any;
  hotelBookingPrice = 0;
  numOfPerson = 0;
  adultTicketPrice = 0;
  childTicketPrice = 0;
  infantTicketPrice = 0;
  hotelOnePersonPrice = 0;
  constructor() {}

  ngOnInit() {
    if (this.groupBooking) {
      this.hotelDetail = this.groupBooking.bookingInfo.transactionInfos.find(
        trans => trans.supplierType === 'HOTEL'
      );
      this.hotelBookingPrice = this.hotelDetail.totalFare;

      this.airDetail = this.groupBooking.bookingInfo.transactionInfos.filter(
        transaction => transaction.supplierType === 'AIR'
      );
      this.numOfPerson =
        this.airDetail[0].noAdult +
        this.airDetail[0].noChild +
        this.airDetail[0].noInfant;

      if (this.airDetail[0].noAdult) {
        this.adultTicketPrice = this.groupBooking.groupPricedItineraries.reduce(
          (arr, cur) => {
            return (
              arr +
              cur.pricedItineraries[0].airItineraryPricingInfo.adultFare
                .passengerFare.baseFare.amount +
              (cur.pricedItineraries[0].airItineraryPricingInfo.adultFare
                .passengerFare.comboMarkup
                ? cur.pricedItineraries[0].airItineraryPricingInfo.adultFare
                    .passengerFare.comboMarkup.amount
                : 0) +
              cur.pricedItineraries[0].airItineraryPricingInfo.adultFare
                .passengerFare.serviceTax.amount
            );
          },
          0
        );
      }
      if (this.airDetail[0].noChild) {
        this.childTicketPrice = this.groupBooking.groupPricedItineraries.reduce(
          (arr, cur) => {
            return (
              arr +
              cur.pricedItineraries[0].airItineraryPricingInfo.childFare
                .passengerFare.baseFare.amount +
              (cur.pricedItineraries[0].airItineraryPricingInfo.childFare
                .passengerFare.comboMarkup
                ? cur.pricedItineraries[0].airItineraryPricingInfo.childFare
                    .passengerFare.comboMarkup.amount
                : 0) +
              cur.pricedItineraries[0].airItineraryPricingInfo.childFare
                .passengerFare.serviceTax.amount
            );
          },
          0
        );
      }
      if (this.airDetail[0].noInfant) {
        this.infantTicketPrice = this.groupBooking.groupPricedItineraries.reduce(
          (arr, cur) => {
            return (
              arr +
              cur.pricedItineraries[0].airItineraryPricingInfo.infantFare
                .passengerFare.baseFare.amount +
              (cur.pricedItineraries[0].airItineraryPricingInfo.infantFare
                .passengerFare.comboMarkup
                ? cur.pricedItineraries[0].airItineraryPricingInfo.infantFare
                    .passengerFare.comboMarkup.amount
                : 0) +
              cur.pricedItineraries[0].airItineraryPricingInfo.infantFare
                .passengerFare.serviceTax.amount
            );
          },
          0
        );
      }

      this.hotelOnePersonPrice = this.hotelBookingPrice / this.numOfPerson;
    }
  }
}
