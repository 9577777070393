/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PricedItinerary } from './pricedItinerary';


export interface GroupPricedItinerary { 
    airSupplier?: string;
    aircraft?: string;
    airline?: string;
    airlineName?: string;
    arrivalDateTime?: Date;
    departureDateTime?: Date;
    destinationCity?: string;
    destinationCountry?: string;
    destinationCountryCode?: string;
    destinationLocationCode?: string;
    destinationLocationName?: string;
    fightNo?: string;
    flightType?: GroupPricedItinerary.FlightTypeEnum;
    groupId?: string;
    originCity?: string;
    originCountry?: string;
    originCountryCode?: string;
    originLocationCode?: string;
    originLocationName?: string;
    osiCode?: string;
    pricedItineraries?: Array<PricedItinerary>;
    requiredFields?: Array<string>;
    returnDateTime?: Date;
    roundType?: GroupPricedItinerary.RoundTypeEnum;
    totalPricedItinerary?: number;
    tourCode?: string;
    vnaArea?: string;
}
export namespace GroupPricedItinerary {
    export type FlightTypeEnum = 'DOMESTIC' | 'INTERNATIONAL';
    export const FlightTypeEnum = {
        DOMESTIC: 'DOMESTIC' as FlightTypeEnum,
        INTERNATIONAL: 'INTERNATIONAL' as FlightTypeEnum
    };
    export type RoundTypeEnum = 'ONEWAY' | 'ROUNDTRIP';
    export const RoundTypeEnum = {
        ONEWAY: 'ONEWAY' as RoundTypeEnum,
        ROUNDTRIP: 'ROUNDTRIP' as RoundTypeEnum
    };
}
