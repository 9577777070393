import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FilterFinancial} from "@gtd/api-services/b2b-client";

@Injectable({
  providedIn: 'root'
})
export class TransactionFinancialService {

  dataFilters = new BehaviorSubject<FilterFinancial>(null);
  getDataFilters = this.dataFilters.asObservable();
  destroyFilters = new BehaviorSubject<boolean>(false);
  getDestroyFilter = this.destroyFilters.asObservable();

  constructor() {}

  setDataFilters(filterBusiness: FilterFinancial) {
    this.dataFilters.next(filterBusiness);
  }

  filterDestroy() {
    this.dataFilters.next(null);
    this.destroyFilters.next(true);
  }
}
