/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { AddComboBookingTravellerRS } from '../model/addComboBookingTravellerRS';
import { BookingTravellerRQ } from '../model/bookingTravellerRQ';
import { ComboDraftBookingRS } from '../model/comboDraftBookingRS';
import { ComboDraftBookingVM } from '../model/comboDraftBookingVM';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ComboResourceService {
  protected basePath = 'https://172.16.100.16:8080';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * addBookingTraveller
   *
   * @param bookingTraveller bookingTraveller
   * @param xIbeReqSaleChannel X-ibe-req-sale-channel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addBookingTravellerUsingPOST1(
    bookingTraveller: BookingTravellerRQ,
    xIbeReqSaleChannel?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<AddComboBookingTravellerRS>;
  public addBookingTravellerUsingPOST1(
    bookingTraveller: BookingTravellerRQ,
    xIbeReqSaleChannel?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<AddComboBookingTravellerRS>>;
  public addBookingTravellerUsingPOST1(
    bookingTraveller: BookingTravellerRQ,
    xIbeReqSaleChannel?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<AddComboBookingTravellerRS>>;
  public addBookingTravellerUsingPOST1(
    bookingTraveller: BookingTravellerRQ,
    xIbeReqSaleChannel?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (bookingTraveller === null || bookingTraveller === undefined) {
      throw new Error(
        'Required parameter bookingTraveller was null or undefined when calling addBookingTravellerUsingPOST1.'
      );
    }

    let headers = this.defaultHeaders;
    if (xIbeReqSaleChannel !== undefined && xIbeReqSaleChannel !== null) {
      headers = headers.set(
        'X-ibe-req-sale-channel',
        String(xIbeReqSaleChannel)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<AddComboBookingTravellerRS>(
      `${this.basePath}/api/combo/add-booking-traveller`,
      bookingTraveller,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * createDraftBooking
   *
   * @param comboDraftBookingVM comboDraftBookingVM
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createDraftBookingUsingPOST1(
    comboDraftBookingVM: ComboDraftBookingVM,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ComboDraftBookingRS>;
  public createDraftBookingUsingPOST1(
    comboDraftBookingVM: ComboDraftBookingVM,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ComboDraftBookingRS>>;
  public createDraftBookingUsingPOST1(
    comboDraftBookingVM: ComboDraftBookingVM,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ComboDraftBookingRS>>;
  public createDraftBookingUsingPOST1(
    comboDraftBookingVM: ComboDraftBookingVM,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (comboDraftBookingVM === null || comboDraftBookingVM === undefined) {
      throw new Error(
        'Required parameter comboDraftBookingVM was null or undefined when calling createDraftBookingUsingPOST1.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<ComboDraftBookingRS>(
      `${this.basePath}/api/combo/draft-booking`,
      comboDraftBookingVM,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
