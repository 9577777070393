import {Action} from '@ngrx/store';
import {LookupSearch} from './lookup.reducer';
import {LookupDTO} from "@gtd/b2c-client";

export enum LookupActionTypes {
  LoadLookup = '[Lookup] Load Lookup',
  LookupLoaded = '[Lookup] Lookup Loaded',
  LookupLoadError = '[Lookup] Lookup Load Error'
}

export class LoadLookup implements Action {
  readonly type = LookupActionTypes.LoadLookup;
  constructor(public payload: LookupSearch) {}
}

export class LookupLoadError implements Action {
  readonly type = LookupActionTypes.LookupLoadError;
  constructor(public payload: any) {}
}

export class LookupLoaded implements Action {
  readonly type = LookupActionTypes.LookupLoaded;
  constructor(public payload: Array<LookupDTO>) {}
}

export type LookupAction = LoadLookup | LookupLoaded | LookupLoadError;

export const fromLookupActions = {
  LoadLookup,
  LookupLoaded,
  LookupLoadError
};
