import {
  CountUnreadAction,
  CountUnreadActionTypes
} from './count-unread.actions';

export const COUNTUNREAD_FEATURE_KEY = 'countUnread';

/**
 * Interface for the 'CountUnread' data used in
 *  - CountUnreadState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface CountUnreadState {
  list: any; // list of CountUnread; analogous to a sql normalized table
  selectedId?: string | number; // which CountUnread record has been selected
  loaded: boolean; // has the CountUnread list been loaded
  error?: any; // last none error (if any)
}

export interface CountUnreadPartialState {
  readonly [COUNTUNREAD_FEATURE_KEY]: CountUnreadState;
}

export const initialState: CountUnreadState = {
  list: null,
  loaded: false
};

export function countUnreadReducer(
  state: CountUnreadState = initialState,
  action: CountUnreadAction
): CountUnreadState {
  switch (action.type) {
    case CountUnreadActionTypes.CountUnreadLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
