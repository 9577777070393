import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ComboCreateDraftBookingPartialState } from './combo-create-draft-booking.reducer';
import { ComboCreateDraftBookingQuery } from './combo-create-draft-booking.selectors';
import {
  ComboVJLoadComboCreateDraftBooking,
  ComboVJResetComboCreateDraftBooking
} from './combo-create-draft-booking.actions';
import { ComboDraftBookingVM } from '@gtd/b2c-client';

@Injectable()
export class ComboCreateDraftBookingFacade {
  loaded$ = this.store.pipe(select(ComboCreateDraftBookingQuery.getLoaded));
  allComboCreateDraftBooking$ = this.store.pipe(
    select(ComboCreateDraftBookingQuery.getAllComboCreateDraftBooking)
  );
  selectedComboCreateDraftBooking$ = this.store.pipe(
    select(ComboCreateDraftBookingQuery.getSelectedComboCreateDraftBooking)
  );
  errorComboCreateDraftBooking$ = this.store.pipe(
    select(ComboCreateDraftBookingQuery.getError)
  );
  constructor(private store: Store<ComboCreateDraftBookingPartialState>) {}

  createDraftBooking(comboDraftBookingVM: any): any {
    this.store.dispatch(
      new ComboVJLoadComboCreateDraftBooking(comboDraftBookingVM)
    );
  }
  reset() {
    this.store.dispatch(new ComboVJResetComboCreateDraftBooking());
  }
}
