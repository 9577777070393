import {Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {TcbPolicyComponent} from "../tcb-policy/tcb-policy.component";
import {TcbFormUpdateCustomerComponent} from "../tcb-form-update-customer/tcb-form-update-customer.component";
import {TcbValidateField, TcbValidateFieldMobile} from "@gtd/helpers";

@Component({
  selector: 'gtd-tcb-form-customer-list',
  templateUrl: './tcb-form-customer-list.component.html',
  styleUrls: ['./tcb-form-customer-list.component.scss']
})
export class TcbFormCustomerListComponent implements OnInit {

  techComCustomers: FormGroup;
  isSubmit: boolean;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TcbFormCustomerListComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.techComCustomers = new FormGroup({
      isApproved: new FormControl('', [Validators.required]),
      attributes: new FormArray([])
    });
  }

  ngOnInit() {
    if(this.data && this.data.inputInfoCtrl && this.data.inputInfoCtrl.paxInfo
      && this.data.inputInfoCtrl.paxInfo.pax && this.data.inputInfoCtrl.paxInfo.pax.length) {
      this.data.inputInfoCtrl.paxInfo.pax.forEach((pax: any) => {
        this.newAttribute({
          isEnable: pax.isEnable,
          buyerFirstName: pax.firstName,
          buyerLastName: pax.lastName,
          buyerMobile: '',
          buyerIdCardNo: '',
          recipientFirstName: pax.firstName,
          recipientLastName: pax.lastName,
          recipientPassport: pax.passport,
        })
      });
    }
    setTimeout(() => {
      if(this.data && this.data.dataPax && this.data.dataPax && this.data.dataPax.data && this.data.dataPax.data.length) {
        this.data.dataPax.data.forEach((dataPaxItem: any) => {
          const matchIndex = this.attributes.controls.findIndex(
            attribute => attribute.get('recipientPassport').value === dataPaxItem.recipientPassport
          );
          if (matchIndex !== -1) {
            this.attributes.at(matchIndex).patchValue({
              ...dataPaxItem,
              buyerFirstName: dataPaxItem.recipientFirstName,
              buyerLastName: dataPaxItem.recipientLastName,
            });
          }
        });
      }
    }, 100)
  }


  get attributes(): FormArray {
    return this.techComCustomers.get('attributes') as FormArray;
  }

  createAttributes(attributes?: any): FormGroup {
    return this.fb.group(
      {
        isEnable: new FormControl(attributes && attributes.isEnable ? attributes.isEnable: false, [Validators.required]),
        buyerFirstName: new FormControl(attributes && attributes.buyerFirstName ? attributes.buyerFirstName: '', [Validators.required]),
        buyerLastName: new FormControl(attributes && attributes.buyerLastName ? attributes.buyerLastName: '', [Validators.required]),
        buyerMobile: new FormControl(attributes && attributes.buyerMobile ? attributes.buyerMobile: ''),
        buyerIdCardNo: new FormControl(attributes && attributes.buyerIdCardNo ? attributes.buyerIdCardNo: ''),
        recipientFirstName: new FormControl(attributes && attributes.recipientFirstName ? attributes.recipientFirstName: '', [Validators.required]),
        recipientLastName: new FormControl(attributes && attributes.recipientLastName ? attributes.recipientLastName: '', [Validators.required]),
        recipientPassport: new FormControl(attributes && attributes.recipientPassport ? attributes.recipientPassport: ''),
      },
      {
        validators: [
          TcbValidateField('isEnable'),
          TcbValidateFieldMobile('buyerMobile', this.attributes)
        ],
      }
    );
  }

  checkValidPaxLength() {
    let countEnable = this.attributes.value.filter((value: any) => value.isEnable);
    return !countEnable.length
  }

  newAttribute(attr?: any) {
    this.attributes.push(this.createAttributes(attr));
  }
  removeAttribute(i: number) {
    this.attributes.removeAt(i);
  }


  openModalPolicy() {
    this.dialog.open(TcbPolicyComponent, {
      width: '744px',
      autoFocus: false,
      position: {
        top: '100px'
      },
    });
  }


  closeModal() {
    this.dialogRef.close();
  }

  submitForm() {
    this.isSubmit = true;
    if(this.attributes.valid) {
      let dataAttrs = this.attributes.value.filter((attr: any) => attr.isEnable);
      if(this.techComCustomers.valid && this.techComCustomers.controls['isApproved'].value) {
        this.dialogRef.close({customers: dataAttrs});
      } else if(!dataAttrs.length) {
        this.dialogRef.close();
      }
    }
  }

  updateCustomer(attribute: AbstractControl) {
    const dialogRef = this.dialog.open(TcbFormUpdateCustomerComponent, {
      width: '744px',
      position: {
        top: '100px'
      },
      data: {
        attributes: this.attributes,
        attribute: attribute.value,
      },
    });
    dialogRef.afterClosed().subscribe(data => {
      if(data && data.customer) {
        attribute.patchValue({
          buyerMobile: data.customer.buyerMobile,
          buyerIdCardNo: data.customer.buyerIdCardNo,
          isEnable: true
        })
      }
    });
  }

  checkedItem($event: any, attribute: AbstractControl) {
    console.log(attribute);
    attribute.patchValue({
      isEnable: !attribute.get('isEnable').value
    })
    $event.stopPropagation()
  }

  isApproved: boolean;
  checkedApprovePolicy() {
    this.techComCustomers.controls['isApproved'].setValue(
      !this.techComCustomers.controls['isApproved'].value
    );
  }
}
@Pipe({ name: 'verifyAttribute' })
export class VerifyAttribute implements PipeTransform {
  constructor() { }
  transform(attribute: any) {
    return !!(attribute.buyerFirstName &&
      attribute.buyerLastName &&
      attribute.buyerMobile &&
      attribute.buyerIdCardNo &&
      attribute.recipientFirstName &&
      attribute.recipientLastName &&
      attribute.recipientPassport);
  }
}
