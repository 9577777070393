import { OtaResultOfTourFilterOptionDTO } from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './tour-filter-options.reducer';

export enum TourFilterOptionsActionTypes {
  LoadTourFilterOptions = '[TourFilterOptions] Load TourFilterOptions',
  ResetTourFilterOptions = '[TourFilterOptions] Reset TourFilterOptions',
  TourFilterOptionsLoaded = '[TourFilterOptions] TourFilterOptions Loaded',
  TourFilterOptionsLoadError = '[TourFilterOptions] TourFilterOptions Load Error'
}

export class LoadTourFilterOptions implements Action {
  readonly type = TourFilterOptionsActionTypes.LoadTourFilterOptions;
  constructor(public payload: 'vi' | 'en') {}
}
export class ResetTourFilterOptions implements Action {
  readonly type = TourFilterOptionsActionTypes.ResetTourFilterOptions;
}

export class TourFilterOptionsLoadError implements Action {
  readonly type = TourFilterOptionsActionTypes.TourFilterOptionsLoadError;
  constructor(public payload: any) {}
}

export class TourFilterOptionsLoaded implements Action {
  readonly type = TourFilterOptionsActionTypes.TourFilterOptionsLoaded;
  constructor(public payload: OtaResultOfTourFilterOptionDTO) {}
}

export type TourFilterOptionsAction =
  | LoadTourFilterOptions
  | ResetTourFilterOptions
  | TourFilterOptionsLoaded
  | TourFilterOptionsLoadError;

export const fromTourFilterOptionsActions = {
  LoadTourFilterOptions,
  ResetTourFilterOptions,
  TourFilterOptionsLoaded,
  TourFilterOptionsLoadError
};
