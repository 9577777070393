/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingServiceRequest } from './bookingServiceRequest';


export interface Traveler { 
    dob?: string;
    eTicketType?: { [key: string]: string; };
    firstName?: string;
    genderTypes?: Traveler.GenderTypesEnum;
    id?: number;
    lastName?: string;
    orderIdx?: number;
    serviceRequests?: Array<BookingServiceRequest>;
    travelerType?: Traveler.TravelerTypeEnum;
}
export namespace Traveler {
    export type GenderTypesEnum = 'MALE' | 'FEMALE' | 'BOY' | 'GIRL' | 'INF';
    export const GenderTypesEnum = {
        MALE: 'MALE' as GenderTypesEnum,
        FEMALE: 'FEMALE' as GenderTypesEnum,
        BOY: 'BOY' as GenderTypesEnum,
        GIRL: 'GIRL' as GenderTypesEnum,
        INF: 'INF' as GenderTypesEnum
    };
    export type TravelerTypeEnum = 'ADT' | 'CHD' | 'INF';
    export const TravelerTypeEnum = {
        ADT: 'ADT' as TravelerTypeEnum,
        CHD: 'CHD' as TravelerTypeEnum,
        INF: 'INF' as TravelerTypeEnum
    };
}
