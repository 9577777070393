import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NewFlightFormSearchComponent } from './flight-form-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SwitcherComponent } from './switcher/switcher.component';
import { AutoCompeteComponent } from './auto-compete/auto-compete.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatRadioModule,
  MatRippleModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { DatepickerModule } from '@gtd/components/datepicker';
import { FormPassengersComponent } from './form-passengers/form-passengers.component';
import { IncreamentInputModule } from '@gtd/components/increament-input';

import {
  faChevronDown,
  faMapMarkerAlt,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AirportModule } from '@gtd/meta';
import { FlightSearchModule } from '../search-result/flight-search/flight-search.module';
import { DataSearchFlightModule } from '../../state/history-search-keyword/history-search-keyword.module';
import { CovidDialogModule } from '@gtd/components/covid-dialog';
import { DestinationComponent } from './destination/destination.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import {
  CustomDatepickerI18n,
  DateFormatDirective,
  TranslateDatePicker
} from './datepicker/date-format.directive';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NewIncreamentInputModule } from '@gtd/components/new-increament-input';
import { ClickOutsideModule } from '@gtd/directives/click-outside';
import { CustomPipeModule } from 'libs/combovj/src/lib/search-result/pipe/custom-pipe.module';
import { FormCabinClassesComponent } from './form-cabin-classes/form-cabin-classes.component';

library.add(faChevronDown, faMapMarkerAlt, faSearch);

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    TranslateModule,
    MatRadioModule,
    MatMenuModule,
    NewIncreamentInputModule,
    MatDividerModule,
    FlexLayoutModule,
    MatButtonModule,
    AirportModule,
    MatRippleModule,
    FlightSearchModule,
    DataSearchFlightModule,
    CovidDialogModule,
    MatFormFieldModule,
    MatIconModule,
    NgbDatepickerModule,
    ClickOutsideModule,
    CustomPipeModule,
    ClickOutsideModule
  ],
  declarations: [
    NewFlightFormSearchComponent,
    SwitcherComponent,
    AutoCompeteComponent,
    FormPassengersComponent,
    DestinationComponent,
    DatepickerComponent,
    DateFormatDirective,
    FormCabinClassesComponent
  ],
  exports: [NewFlightFormSearchComponent, DatepickerComponent],
  providers: [TranslateDatePicker, CustomDatepickerI18n, DatePipe],
  bootstrap: [DatepickerComponent]
})
export class NewFlightFormSearchModule {}
