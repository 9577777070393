import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelSearchKeywordPartialState } from './autocomplete-search-keyword.reducer';
import { hotelSearchKeywordQuery } from './autocomplete-search-keyword.selectors';
import { ComboVJAutoCompleteSearchKeyword } from './autocomplete-search-keyword.actions';
import { HotelMetaKeyword } from '@gtd/b2c-client';

@Injectable()
export class ComboVJAutocompleteSearchKeywordFacade {
  loaded$ = this.store.pipe(select(hotelSearchKeywordQuery.getLoaded));
  allHotelSearchKeyword$ = this.store.pipe(
    select(hotelSearchKeywordQuery.getAllHotelSearchKeyword)
  );
  selectedHotelSearchKeyword$ = this.store.pipe(
    select(hotelSearchKeywordQuery.getSelectedHotelSearchKeyword)
  );

  constructor(private store: Store<HotelSearchKeywordPartialState>) {}

  addSearchKeywordHotel(hotelMetaKeywords: HotelMetaKeyword[]) {
    this.store.dispatch(
      new ComboVJAutoCompleteSearchKeyword(hotelMetaKeywords)
    );
  }
}
