import { Action } from '@ngrx/store';

export enum NotificationsActionTypes {
  LoadNotifications = '[Notifications] Load Notifications',
  NotificationsLoaded = '[Notifications] Notifications Loaded',
  NotificationsLoadError = '[Notifications] Notifications Load Error'
}

export class LoadNotifications implements Action {
  readonly type = NotificationsActionTypes.LoadNotifications;
  constructor(public payload: ReqNotification) {}
}

export class NotificationsLoadError implements Action {
  readonly type = NotificationsActionTypes.NotificationsLoadError;
  constructor(public payload: any) {}
}

export class NotificationsLoaded implements Action {
  readonly type = NotificationsActionTypes.NotificationsLoaded;
  constructor(public payload: any) {}
}

export type NotificationsAction =
  | LoadNotifications
  | NotificationsLoaded
  | NotificationsLoadError;

export const fromNotificationsActions = {
  LoadNotifications,
  NotificationsLoaded,
  NotificationsLoadError
};
export interface ReqNotification {
  userRefCode: any,
  page?: number,
  size?: number,
  sort?: Array<string>
}
