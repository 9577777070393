import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PolicyByBookingPartialState } from './policy-by-booking.reducer';
import { policyByBookingQuery } from './policy-by-booking.selectors';
import { LoadPolicyByBooking } from './policy-by-booking.actions';

@Injectable()
export class PolicyByBookingFacade {
  loaded$ = this.store.pipe(select(policyByBookingQuery.getLoaded));
  allPolicyByBooking$ = this.store.pipe(
    select(policyByBookingQuery.getAllPolicyByBooking)
  );
  selectedPolicyByBooking$ = this.store.pipe(
    select(policyByBookingQuery.getSelectedPolicyByBooking)
  );

  constructor(private store: Store<PolicyByBookingPartialState>) {}

  loadAllPolicyByBooking(bookingNumber: string) {
    this.store.dispatch(new LoadPolicyByBooking(bookingNumber));
  }
}
