import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PROMOTIONVALIDATE_FEATURE_KEY,
  PromotionValidateState
} from './promotion-validate.reducer';

// Lookup the 'PromotionValidate' feature state managed by NgRx
const getPromotionValidateState = createFeatureSelector<PromotionValidateState>(
  PROMOTIONVALIDATE_FEATURE_KEY
);

const getLoaded = createSelector(
  getPromotionValidateState,
  (state: PromotionValidateState) => state.loaded
);
const getError = createSelector(
  getPromotionValidateState,
  (state: PromotionValidateState) => state.error
);

const getAllPromotionValidate = createSelector(
  getPromotionValidateState,
  getLoaded,
  (state: PromotionValidateState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getPromotionValidateState,
  (state: PromotionValidateState) => state.selectedId
);
const getSelectedPromotionValidate = createSelector(
  getAllPromotionValidate,
  getSelectedId,
  (promotionValidate, id) => {
    const result = promotionValidate;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const promotionValidateQuery = {
  getLoaded,
  getError,
  getAllPromotionValidate,
  getSelectedPromotionValidate
};
