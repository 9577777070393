/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {AgencyCACodeDTO} from "./agencyCACodeDTO";


export interface UserProfileTO {
   agencyCACodes?: Array<AgencyCACodeDTO>;
    address1?: string;
    address2?: string;
    adminAgency?: boolean;
    agencyId?: number;
    agencyName?: string;
    agentName?: string;
    avatar?: string;
    avatarContentType?: string;
    avatarId?: number;
    balance?: number;
    branchName?: string;
    cellPhone?: string;
    cityCode?: string;
    cityName?: string;
    countryCode?: string;
    countryName?: string;
    credit?: number;
    customerCareEmail?: string;
    displayRoles?: string;
    email?: string;
    faxNumber?: string;
    fullName?: string;
    id?: number;
    loginId?: number;
    loginUsername?: string;
    mobile?: string;
    note?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    stateCode?: string;
    stateName?: string;
    status?: UserProfileTO.StatusEnum;
}
export namespace UserProfileTO {
    export type StatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'OTHER';
    export const StatusEnum = {
        ACTIVATED: 'ACTIVATED' as StatusEnum,
        DEACTIVATED: 'DEACTIVATED' as StatusEnum,
        OTHER: 'OTHER' as StatusEnum
    };
}
