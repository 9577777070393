import {
  PaymentOptionInfoAction,
  PaymentOptionInfoActionTypes
} from './payment-option-info.actions';

export const PAYMENTOPTIONINFO_FEATURE_KEY = 'paymentOptionInfo';

/**
 * Interface for the 'PaymentOptionInfo' data used in
 *  - PaymentOptionInfoState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface PaymentOptionInfoState {
  list: any; // list of PaymentOptionInfo; analogous to a sql normalized table
  selectedId?: string | number; // which PaymentOptionInfo record has been selected
  loaded: boolean; // has the PaymentOptionInfo list been loaded
  error?: any; // last none error (if any)
}

export interface PaymentOptionInfoPartialState {
  readonly [PAYMENTOPTIONINFO_FEATURE_KEY]: PaymentOptionInfoState;
}

export const initialState: PaymentOptionInfoState = {
  list: [],
  loaded: false
};

export function reducer(
  state: PaymentOptionInfoState = initialState,
  action: PaymentOptionInfoAction
): PaymentOptionInfoState {
  switch (action.type) {
    case PaymentOptionInfoActionTypes.PaymentOptionInfoLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
