/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AgentProfileDTO { 
    agencyCode?: string;
    agencyId?: number;
    agencyName?: string;
    agentCode?: string;
    agentName?: string;
    branchCode?: string;
    branchId?: number;
    branchName?: string;
    createdBy?: string;
    createdDate?: Date;
    defaultAgencyCode?: string;
    defaultAgent?: boolean;
    displayRoles?: string;
    email?: string;
    extNumber?: string;
    id?: number;
    loginId?: number;
    loginUsername?: string;
    mobile?: string;
    orgCode?: string;
    phoneNumber?: string;
    status?: AgentProfileDTO.StatusEnum;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace AgentProfileDTO {
    export type StatusEnum = 'ACTIVATED' | 'DEACTIVATED' | 'OTHER';
    export const StatusEnum = {
        ACTIVATED: 'ACTIVATED' as StatusEnum,
        DEACTIVATED: 'DEACTIVATED' as StatusEnum,
        OTHER: 'OTHER' as StatusEnum
    };
}
