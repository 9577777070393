import {AfterViewChecked, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {CustMemberCard} from "@gtd/api-services/b2b-client";
import {FormArray, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {PassengerService} from "../passenger.service";
import {LookupDTO} from "@gtd/b2c-client";
import {Subscription} from "rxjs";
import {LookupFacade} from "@gtd/meta";
import {DialogMessageComponent} from "@gtd/components/dialog-message";
import {MatDialog} from "@angular/material";
import {AgentCustomerMemberCardResourceService} from "@gtd/api-services/agentsrv";

@Component({
  selector: 'gtd-member-card-b2b',
  templateUrl: './member-card-b2b.component.html',
  styleUrls: ['./member-card-b2b.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MemberCardB2bComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MemberCardB2bComponent),
      multi: true
    }
  ]
})
export class MemberCardB2bComponent implements OnInit, OnDestroy, AfterViewChecked {
  memberCardForm: FormGroup;
  submitted: boolean;
  lookups: Array<LookupDTO>;
  subscriptions = new Subscription();

  constructor(
    private passengerService: PassengerService,
    private lookupFacade: LookupFacade,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private agentCustomerMemberCardResourceService: AgentCustomerMemberCardResourceService
  ) {
    this.memberCardForm = new FormGroup({
      memberCards: new FormArray([])
    });
    if(!this.lookups) {
      this.subscriptions.add(
        this.lookupFacade.allLookup$.subscribe(lookups => {
          this.lookups = lookups;
        })
      )
    }
  }

  ngOnInit() {
    this.passengerService.setMemberCardValid(this.memberCardForm.valid);
    this.passengerService.getParentSubmitForm.subscribe(submitted => {
      this.submitted = submitted;
    });
    this.memberCardForm.controls['memberCards'].valueChanges.subscribe(value => {
      this.passengerService.setMemberCardValid(this.memberCardForm.controls['memberCards'].valid);
      this.onChange(value);
      this.onTouched();
    })
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  get memberCards(): FormArray {
    return this.memberCardForm.get("memberCards") as FormArray;
  }

  createMemberCards(): FormGroup {
    return new FormGroup({
      id: new FormControl(),
      agentCode: new FormControl(),
      customerId: new FormControl(),
      cardType: new FormControl(),
      cardNumber:  new FormControl(),
    });
  }

  addMemberCards() {
    this.memberCards.push(this.createMemberCards());
    this.passengerService.setMemberCardValid(false);
  }


  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any[]): void {
    if (obj && obj.length > 0) {
      obj.forEach(memberCard => {
        this.memberCards.push(new FormGroup({
          id: new FormControl(memberCard.id),
          customerId: new FormControl(memberCard.customerId),
          agentCode: new FormControl(memberCard.agentCode),
          cardType: new FormControl(memberCard.cardType),
          cardNumber:  new FormControl(memberCard.cardNumber),
        }));
      })
    }
    if (obj === null) {
      this.memberCardForm.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.memberCardForm.disable();
    } else {
      this.memberCardForm.enable();
    }
  }

  validate(_: FormControl) {

  }

  removeMemberCard(i: number, memberCard: any) {
    if(memberCard.value && memberCard.value.id) {
      let dialogRef = this.dialog.open(DialogMessageComponent, {
        data: {
          btn: [
            {btnColor: 'default', btnText: 'global.close'},
            {btnColor: 'success', btnText: 'user.delete-member-card', action: 'DELETE'},
          ],
          content: 'user.delete-member-card-message',
          heading: 'user.delete-member-card-heading',
          dataTranslate: memberCard.value.cardType + ' ('+memberCard.value.cardNumber+')',
          messageType: 'success'
        },
        panelClass: 'dialog-do-confirm',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(actionClose => {
        if(actionClose && actionClose.action === "DELETE") {
          this.agentCustomerMemberCardResourceService.deleteAgentCustomerMemberCardUsingDELETE(memberCard.value.id).subscribe(memberCardDelete => {
            this.memberCards.removeAt(i);
          });
        }
      })
    } else {
      this.memberCards.removeAt(i);
    }
    this.passengerService.setMemberCardValid(this.memberCards.valid);
  }
}
