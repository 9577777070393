import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchBestRatesFacade } from '../../state/search-best-rates/search-best-rates.facade';
import { FilterAvailableState } from '../../state/filter-available/filter-available.reducer';
import { FilterAvailableFacade } from '../../state/filter-available/filter-available.facade';
import { TranslateService } from '@ngx-translate/core';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { FilterOptionsFacade } from '../../state/filter-options/filter-options.facade';
import { fadeInOut } from '@gtd/helpers';
import { FilterPanelMobileComponent } from './filter-panel-mobile/filter-panel-mobile.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BreadcrumbBar } from '@gtd/components/breadcrumb-bar';
import { environment } from '@env/environment';
import { FilterPanelService } from './filter-panel/filter-panel.service';
import { GaTrackingService } from '@gtd/ga-tracking';

@Component({
  selector: 'gtd-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  animations: [fadeInOut]
})
export class SearchResultComponent implements OnInit, OnDestroy {
  @Output() pageChange: EventEmitter<number>;
  configPagination: any;
  p: number = 1;

  counter = Array;
  searchParams: any;
  allSearchLoaded$ = this.searchBestRatesFacade.loaded$;
  allSearchBestRates$ = this.searchBestRatesFacade.allSearchBestRates$;
  isLoading: boolean;
  currentLang: 'vi' | 'en';
  selectedIndex: number = 1;
  currentFilter: FilterAvailableState;
  appName = environment.appName;
  params: string;

  private subscription: Subscription = new Subscription();

  breadcrumbBars: BreadcrumbBar[] = [
    {
      name: 'global.homepage',
      isRouterLink: true,
      routerLink: '/'
    },
    {
      isRouterLink: false,
      name: 'global.hotel'
    }
  ];
  private utm_source = localStorage.getItem('utm_source');

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private searchBestRatesFacade: SearchBestRatesFacade,
    private filterAvailableFacade: FilterAvailableFacade,
    private translate: TranslateService,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private _bottomSheet: MatBottomSheet,
    public filterPanelService: FilterPanelService,
    private filterOptionsFacade: FilterOptionsFacade,
    private _gaTrackingService: GaTrackingService
  ) {
    type OwnerNameLang = 'vi' | 'en';
    this.currentLang = this.translate.currentLang as OwnerNameLang;
    this.activatedRoute.queryParams.subscribe(param => {
      this.params = param.params;
      this._handleQueryFromParams(
        JSON.parse(decodeURIComponent(atob(param.params)))
      );
    });
    setTimeout(() => {
      this.filterOptionsFacade.loadAll(this.currentLang);
    }, 1000);
    setTimeout(() => {
      this.subscription.add(
        this.translate.onLangChange.subscribe(lang => {
          this.filterAvailableFacade.changeFilterLanguage(lang.lang);
        })
      );
    });
  }

  private _handleQueryFromParams(param) {
    this.searchParams = param;
    let roomsArrs = JSON.parse(param.rooms);
    let roomsString = [];
    if (roomsArrs.length > 0) {
      roomsArrs.forEach(roomsArr => {
        let childrendString = '';
        if (roomsArr.childrenAges && roomsArr.childrenAges.length > 0) {
          roomsArr.childrenAges.forEach(childrend => {
            childrendString = childrendString
              ? childrendString + ',' + childrend
              : childrend;
          });
        }
        let room = childrendString
          ? roomsArr.adultQuantity + '-' + childrendString
          : roomsArr.adultQuantity;
        roomsString.push(room);
      });
    } else {
      roomsString.push(roomsArrs);
    }
    this.searchParams.roomsString = roomsString;
    this.searchParams.roomsArrs = roomsArrs;
    let filterFromStarRating = null;
    if (
      this.searchParams.filterStars &&
      this.searchParams.filterStars.length > 0
    ) {
      if (this.searchParams.filterStars.length === 1) {
        filterFromStarRating = this.searchParams.filterStars[0];
      } else if (this.searchParams.filterStars.length > 1) {
        this.searchParams.filterStars.sort((a, b) => b - a);
        filterFromStarRating = this.searchParams.filterStars[0];
      }
    }
    this.searchBestRatesFacade.loadAll({
      searchCode: param.locationCode,
      searchType: param.searchType,
      supplier: param.supplier,
      language: this.currentLang,
      currency: 'VND',
      checkIn: param.fromDate,
      checkOut: param.toDate,
      paxInfos: roomsString,
      filterStarRating: filterFromStarRating,
      pageNumber: 0,
      pageSize: 15
    });
  }

  ngOnInit() {
    this.subscription.add(
      this.searchBestRatesFacade.allSearchBestRates$.subscribe(
        allSearchBestRates => {
          if (allSearchBestRates) {
            if (allSearchBestRates.success) {
              if (allSearchBestRates.result.propertyAvailable.length) {
                this._gaTrackingService.track('H_SearchResult_Empty');
              }
              this.configPagination = {
                id: 'foo',
                maxSize: this.deviceService.isDesktop() ? 8 : 5,
                itemsPerPage: allSearchBestRates.result.pageResult.pageSize,
                currentPage:
                  allSearchBestRates.result.pageResult.pageNumber + 1,
                totalItems: allSearchBestRates.result.pageResult.totalItems
              };
              this.isLoading = false;
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            } else {
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [{ btnText: 'global.close' }],
                  content: 'user.system-error-message',
                  heading: 'user.system-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true
              });
            }
            this.isLoading = false;
          }
        }
      )
    );
    this.subscription.add(
      this.filterAvailableFacade.currentFilter$.subscribe(
        (currentFilter: FilterAvailableState) => {
          if (currentFilter) {
            this.currentFilter = currentFilter;
            this.isLoading = true;
            this._bottomSheet.dismiss();
            setTimeout(() => {
              this.searchBestRatesFacade.loadAll({
                searchCode: this.searchParams.locationCode,
                searchType: this.searchParams.searchType,
                supplier: this.searchParams.supplier,
                language: currentFilter.language
                  ? currentFilter.language
                  : this.currentLang,
                currency: 'VND',
                checkIn: this.searchParams.fromDate,
                checkOut: this.searchParams.toDate,
                paxInfos: this.searchParams.roomsString,
                filterHotelName: currentFilter.filterHotelName,
                filterHotelCategories: currentFilter.filterHotelCategories,
                filterFromPrice: currentFilter.filterPrice
                  ? currentFilter.filterPrice.fromPrice
                  : null,
                filterToPrice: currentFilter.filterPrice
                  ? currentFilter.filterPrice.toPrice
                  : null,
                filterFromDistance: currentFilter.filterDistance
                  ? currentFilter.filterDistance.fromDistance
                  : null,
                filterToDistance: currentFilter.filterDistance
                  ? currentFilter.filterDistance.toDistance
                  : null,
                filterStarRating: currentFilter.filterStarRating,
                filterFromGuestRating: currentFilter.filterGuestRating
                  ? currentFilter.filterGuestRating.from
                  : null,
                filterToGuestRating: currentFilter.filterGuestRating
                  ? currentFilter.filterGuestRating.to
                  : null,
                filterPropertyAmenities: currentFilter.filterPropertyAmenities,
                filterRoomAmenities: currentFilter.filterRoomAmenities,
                filterRateAmenities: currentFilter.filterRateAmenities,
                filterRoomViews: currentFilter.filterRoomViews,
                filterThemes: currentFilter.filterThemes,
                filterMealPlans: currentFilter.filterMealPlans,
                filterBedTypes: currentFilter.filterBedTypes,
                sortField: currentFilter.sort
                  ? currentFilter.sort.sortField
                  : null,
                sortOrder: currentFilter.sort
                  ? currentFilter.sort.sortOrder
                  : null,
                pageNumber: currentFilter.pageNumber
                  ? currentFilter.pageNumber
                  : 0,
                pageSize: currentFilter.pageSize ? currentFilter.pageSize : 15
              });
            });
            this.searchParams.filterHotelName = currentFilter.filterHotelName;
            this.searchParams.filterHotelCategories =
              currentFilter.filterHotelCategories;
            this.searchParams.filterPrice = currentFilter.filterPrice;
            this.searchParams.filterDistance = currentFilter.filterDistance;
            this.searchParams.filterStarRating = currentFilter.filterStarRating;
            this.searchParams.filterGuestRating =
              currentFilter.filterGuestRating;
            this.searchParams.filterRoomAmenities =
              currentFilter.filterRoomAmenities;
            this.searchParams.filterRateAmenities =
              currentFilter.filterRateAmenities;
            this.searchParams.filterPropertyAmenities =
              currentFilter.filterPropertyAmenities;
            this.searchParams.filterRoomViews = currentFilter.filterRoomViews;
            this.searchParams.filterThemes = currentFilter.filterThemes;
            this.searchParams.filterMealPlans = currentFilter.filterMealPlans;
            this.searchParams.sort = currentFilter.sort;
            this.searchParams.pageNumber = currentFilter.pageNumber;
            this.searchParams.pageSize = currentFilter.pageSize;
          }
        }
      )
    );
  }

  openBottomSheet(): void {
    this._bottomSheet.open(FilterPanelMobileComponent, {
      data: this.currentFilter ? this.currentFilter : null
    });
  }

  pageChanged(event) {
    setTimeout(() => {
      this.configPagination.currentPage = event;
      if (event) this.filterAvailableFacade.changePageNumber(event - 1);
    });
  }

  addressClickViewMap(event) {
    this.searchParams.openMarker = event;
    this.router.navigate(['/hotel/maps'], {
      queryParams: {
        params: this.params
      }
    });
  }

  changeTabSelect(index: number) {
    this.router.navigate(['/hotel/maps'], {
      queryParams: {
        params: this.params
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.searchBestRatesFacade.searchResetAll();
    this.filterAvailableFacade.resetFilter();
  }
}
@Pipe({ name: 'round' })
export class RoundPipe {
  transform(input: any) {
    return Math.round(parseFloat(input) * 10) / 10;
  }
}
@Pipe({ name: 'percentPrice' })
export class PercentPrice {
  transform(price: number, basePriceBeforePromo: number) {
    let percent = 100 - (price * 100) / basePriceBeforePromo;
    return Math.round(percent);
  }
}
@Pipe({
  name: 'numberNight'
})
export class NumberNightResult implements PipeTransform {
  transform(departureDate: string, returnDate?: string): number {
    let numbDate = 0;
    if (departureDate && returnDate) {
      numbDate = this.datediff(
        this.parseDate(departureDate),
        this.parseDate(returnDate)
      );
    }
    return numbDate;
  }
  parseDate(str) {
    let mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }
  datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
}
@Pipe({ name: 'imageResize' })
export class ImageResize {
  transform(img: string, size: string) {
    return img.replace('_z', size);
  }
}
