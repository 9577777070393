import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PromotionFilterByVoucherPartialState } from './promotion-filter-by-voucher.reducer';
import { promotionFilterByVoucherQuery } from './promotion-filter-by-voucher.selectors';
import {
  LoadPromotionFilterByVoucher,
  PromotionRQ
} from './promotion-filter-by-voucher.actions';

@Injectable()
export class PromotionFilterByVoucherFacade {
  loaded$ = this.store.pipe(select(promotionFilterByVoucherQuery.getLoaded));
  allPromotionFilterByVoucher$ = this.store.pipe(
    select(promotionFilterByVoucherQuery.getAllPromotionFilterByVoucher)
  );
  selectedPromotionFilterByVoucher$ = this.store.pipe(
    select(promotionFilterByVoucherQuery.getSelectedPromotionFilterByVoucher)
  );

  constructor(private store: Store<PromotionFilterByVoucherPartialState>) {}

  loadAll(payload: PromotionRQ) {
    this.store.dispatch(new LoadPromotionFilterByVoucher(payload));
  }
}
