/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { IBEInternalConfig } from '../model/iBEInternalConfig';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class IbeInternalConfigResourceService {

    protected basePath = 'https://172.16.100.16:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * create
     *
     * @param ibeInternalConfig ibeInternalConfig
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUsingPOST(ibeInternalConfig: IBEInternalConfig, observe?: 'body', reportProgress?: boolean): Observable<IBEInternalConfig>;
    public createUsingPOST(ibeInternalConfig: IBEInternalConfig, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IBEInternalConfig>>;
    public createUsingPOST(ibeInternalConfig: IBEInternalConfig, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IBEInternalConfig>>;
    public createUsingPOST(ibeInternalConfig: IBEInternalConfig, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ibeInternalConfig === null || ibeInternalConfig === undefined) {
            throw new Error('Required parameter ibeInternalConfig was null or undefined when calling createUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IBEInternalConfig>(`${this.basePath}/api/ibe-internal-config`,
            ibeInternalConfig,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getByName
     *
     * @param agencyName agencyName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByNameUsingGET(agencyName: string, observe?: 'body', reportProgress?: boolean): Observable<IBEInternalConfig>;
    public getByNameUsingGET(agencyName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IBEInternalConfig>>;
    public getByNameUsingGET(agencyName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IBEInternalConfig>>;
    public getByNameUsingGET(agencyName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyName === null || agencyName === undefined) {
            throw new Error('Required parameter agencyName was null or undefined when calling getByNameUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<IBEInternalConfig>(`${this.basePath}/api/ibe-internal-config/agency/${encodeURIComponent(String(agencyName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getByNameVerfifyPartner
     *
     * @param agencyName agencyName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByNameVerfifyPartnerUsingGET(agencyName: string, observe?: 'body', reportProgress?: boolean): Observable<IBEInternalConfig>;
    public getByNameVerfifyPartnerUsingGET(agencyName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IBEInternalConfig>>;
    public getByNameVerfifyPartnerUsingGET(agencyName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IBEInternalConfig>>;
    public getByNameVerfifyPartnerUsingGET(agencyName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyName === null || agencyName === undefined) {
            throw new Error('Required parameter agencyName was null or undefined when calling getByNameVerfifyPartnerUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<IBEInternalConfig>(`${this.basePath}/api/partner/ibe-internal-config/agency/${encodeURIComponent(String(agencyName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<IBEInternalConfig>;
    public getUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IBEInternalConfig>>;
    public getUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IBEInternalConfig>>;
    public getUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<IBEInternalConfig>(`${this.basePath}/api/ibe-internal-config/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * update
     *
     * @param ibeInternalConfig ibeInternalConfig
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUsingPUT(ibeInternalConfig: IBEInternalConfig, observe?: 'body', reportProgress?: boolean): Observable<IBEInternalConfig>;
    public updateUsingPUT(ibeInternalConfig: IBEInternalConfig, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IBEInternalConfig>>;
    public updateUsingPUT(ibeInternalConfig: IBEInternalConfig, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IBEInternalConfig>>;
    public updateUsingPUT(ibeInternalConfig: IBEInternalConfig, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ibeInternalConfig === null || ibeInternalConfig === undefined) {
            throw new Error('Required parameter ibeInternalConfig was null or undefined when calling updateUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<IBEInternalConfig>(`${this.basePath}/api/ibe-internal-config`,
            ibeInternalConfig,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
