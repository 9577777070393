import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { GaTrackingService } from '@gtd/ga-tracking';
import { setInterval } from 'timers';

@Component({
  selector: 'gtd-main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.scss']
})
export class MainNavbarComponent implements OnInit {
  @Input() isLogged;
  @Output() sidenavClose = new EventEmitter();

  menus = [
    {
      title: 'navbar.flight',
      routerLink: '/ve-may-bay',
      routerLinkActive: 'active',
      iconImg: 'assets/images/homepage/plane-green.svg',
      isPage: true,
      eventName: 'ticket_screen'
    },
    {
      title: 'navbar.hotel',
      routerLink: '/khach-san',
      iconImg: 'assets/images/homepage/bed-green.svg',
      isPage: true,
      eventName: 'hotel_screen'
    },
    {
      title: 'navbar.combo',
      routerLink: '/combo-du-lich',
      iconImg: 'assets/images/homepage/icon-combohotelflight.svg',
      isPage: true,
      eventName: 'combo_screen'
    },
    // {
    //   title: 'Free and Easy',
    //   url: 'https://tour.gotadi.com/free-easy',
    //   target: '_blank',
    //   icon: 'certificate',
    //   iconPrefix: 'fas',
    //   isPage: false
    // },
    // {
    //   title: 'navbar.extra',
    //   url: 'https://tour.gotadi.com/category/khuyen-mai',
    //   target: '_blank',
    //   iconImg: 'assets/images/homepage/extra.svg',
    //   isPage: false
    // },
    {
      title: 'newface.activity',
      routerLink: '/vui-choi-giai-tri',
      iconImg: 'assets/images/homepage/tour-active.svg',
      isPage: true,
      eventName: 'tour_screen'
    }
    // {
    //   title: 'navbar.travel-guide',
    //   url: 'https://tour.gotadi.com/category/cam-nang-du-li%CC%A3ch',
    //   target: '_blank',
    //   iconImg: 'assets/images/homepage/handbook.svg',
    //   isPage: false,
    //   eventName: 'guide_screen'
    // }
  ];
  constructor(
    public router: Router,
    private _gaTrackingService: GaTrackingService
  ) {}

  ngOnInit() {}

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  };
  track(eventName: string) {
    this._gaTrackingService.track(eventName);
  }

  loggin() {
    let params =
      this.router.url !== '/auth/login' && this.router.url !== '/auth/register'
        ? btoa(
            encodeURIComponent(JSON.stringify({ urlRedirect: this.router.url }))
          )
        : null;
    this.router.navigate(['/auth/login'], {
      queryParams: {
        params: params
      }
    });
  }

  register() {
    let params =
      this.router.url !== '/auth/login' && this.router.url !== '/auth/register'
        ? btoa(
            encodeURIComponent(JSON.stringify({ urlRedirect: this.router.url }))
          )
        : null;
    this.router.navigate(['/auth/register'], {
      queryParams: {
        params: params
      }
    });
  }
}
