import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormPassengersService } from './form-passengers.service';
import { environment } from '@env/environment';

@Component({
  selector: 'gtd-form-passengers',
  templateUrl: './form-passengers.component.html',
  styleUrls: ['./form-passengers.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class FormPassengersComponent implements OnInit {
  passenger?: FormGroup;

  _totalPass = 9;
  minAdult = 1;
  maxAdult = 9;
  minChild = 0;
  maxChild = 8;
  minInfant = 0;
  maxInfant = 4;
  getDataSearchFlight;
  constructor(private passengerService: FormPassengersService) {
    this.getDataSearchFlight = JSON.parse(
      localStorage.getItem('dataSearchFlight')
    );
    this.passenger = new FormGroup({
      adultQuantity: new FormControl(
        this.getDataSearchFlight === null ? 1 : this.getDataSearchFlight.adult,
        Validators.required
      ),
      childQuantity: new FormControl(
        this.getDataSearchFlight === null ? 0 : this.getDataSearchFlight.child,
        Validators.required
      ),
      infantCount: new FormControl(
        this.getDataSearchFlight === null ? 0 : this.getDataSearchFlight.infant,
        Validators.required
      )
    });
  }

  ngOnInit() {
    this.passenger.valueChanges.subscribe(valueChanges => {
      this.maxChild = this.getMaxChild(valueChanges);
      this.maxInfant = this.getMaxInfant(valueChanges);
      this.maxAdult = this.getMaxAdult(valueChanges);
      setTimeout(() => {
        this.refreshchildQuantity(valueChanges);
        this.refreshInfantCount(valueChanges);
        this.passengerService.setPassenger({
          adultQuantity: valueChanges.adultQuantity,
          childQuantity: valueChanges.childQuantity,
          infantCount: valueChanges.infantCount
        });
      });
    });
  }

  getMaxAdult(valueChanges): number {
    return this._totalPass - valueChanges.childQuantity;
  }

  getMaxChild(valueChanges): number {
    if (valueChanges.adultQuantity === 1) {
      return 4;
    } else {
      return this._totalPass - valueChanges.adultQuantity;
    }
  }

  getMaxInfant(valueChanges): number {
    return valueChanges.adultQuantity;
  }
  refreshchildQuantity(valueChanges) {
    if (valueChanges.childQuantity > valueChanges.adultQuantity * 4) {
      this.passenger.patchValue({
        childQuantity: 0
      });
    }
  }
  refreshInfantCount(valueChanges) {
    if (valueChanges.infantCount > valueChanges.adultQuantity) {
      this.passenger.patchValue({
        infantCount: 0
      });
    }
  }
}
