import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboConfirmComponent } from './combo-confirm.component';
import { SearchInformationsComponent } from './search-informations/search-informations.component';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDividerModule,
  MatExpansionModule,
  MatIconModule,
  MatMenuModule,
  MatOptionModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSelectModule,
  MatTooltipModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DatepickerModule } from '@gtd/components/datepicker';
import { ComboFormSearchModule } from '../combo-form-search/combo-form-search.module';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FlightBookingItemComponent } from './flight-booking-item/flight-booking-item.component';
import {
  DurationFormatComponent,
  TransitTimeComponent,
  TransitTimeRoomComponent
} from './pipes/all-pipes.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HotelBookingInfoComponent } from './hotel-booking-info/hotel-booking-info.component';
import { ImportantInformationComponent } from './important-information/important-information.component';
import { ComboPriceSummaryComponent } from './combo-price-summary/combo-price-summary.component';
library.add(faChevronLeft, faChevronRight);

@NgModule({
  declarations: [
    ComboConfirmComponent,
    SearchInformationsComponent,
    FlightBookingItemComponent,
    DurationFormatComponent,
    TransitTimeComponent,
    HotelBookingInfoComponent,
    TransitTimeRoomComponent,
    ImportantInformationComponent,
    ComboPriceSummaryComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    DatepickerModule,
    MatCheckboxModule,
    MatDividerModule,
    MatOptionModule,
    MatSelectModule,
    MatCardModule,
    MatProgressBarModule,
    MatMenuModule,
    MatExpansionModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatRippleModule,
    FontAwesomeModule,
    ComboFormSearchModule
  ],
  exports: [ComboConfirmComponent]
})
export class ComboConfirmModule {}
