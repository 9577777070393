/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MarkupFormula { 
    code?: string;
    key?: string;
    markupType?: MarkupFormula.MarkupTypeEnum;
    pricingFormula?: string;
}
export namespace MarkupFormula {
    export type MarkupTypeEnum = 'PER_PAX_PER_BOOKING' | 'PER_PAX_PER_SEGMENT' | 'PER_BOOKING';
    export const MarkupTypeEnum = {
        PAXPERBOOKING: 'PER_PAX_PER_BOOKING' as MarkupTypeEnum,
        PAXPERSEGMENT: 'PER_PAX_PER_SEGMENT' as MarkupTypeEnum,
        BOOKING: 'PER_BOOKING' as MarkupTypeEnum
    };
}
