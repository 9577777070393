/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FlightSegment } from './flightSegment';


export interface OriginDestinationOption { 
    cabinClassName?: OriginDestinationOption.CabinClassNameEnum;
    destinationCity?: string;
    destinationDateTime?: Date;
    destinationLocationCode?: string;
    destinationLocationName?: string;
    flightDirection?: string;
    flightSegments?: Array<FlightSegment>;
    journeyDuration?: number;
    key?: string;
    originCity?: string;
    originDateTime?: Date;
    originLocationCode?: string;
    originLocationName?: string;
}
export namespace OriginDestinationOption {
    export type CabinClassNameEnum = 'PROMO' | 'ECONOMY' | 'PREMIUM' | 'BUSINESS' | 'FIRST';
    export const CabinClassNameEnum = {
        PROMO: 'PROMO' as CabinClassNameEnum,
        ECONOMY: 'ECONOMY' as CabinClassNameEnum,
        PREMIUM: 'PREMIUM' as CabinClassNameEnum,
        BUSINESS: 'BUSINESS' as CabinClassNameEnum,
        FIRST: 'FIRST' as CabinClassNameEnum
    };
}
