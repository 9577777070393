import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router, UrlSerializer } from '@angular/router';

import { DatePipe } from '@angular/common';
import { MustNotMatch } from '@gtd/helpers';
import {
  FormPassengersService,
  Passenger
} from './form-passengers/form-passengers.service';
import { ComboSearchService } from './combo-search.service';
import { FlightTypeSwitcherEnum } from './switcher/switcher.component';
import { AirportDTO } from '@gtd/meta-client';
import { HistorySearchKeywordFacade } from '../../state/history-search-keyword/+state-sesstion/history-search-keyword.facade';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { ComboVJSearchInformationFacade } from '../../state/combo-search-information/combo-search-information.facade';
import { ComboSelectedService } from '../combo-selected.service';
import { FlightSearchFacade } from '../search-result/flight/flight-search/flight-search/+state/flight-search.facade';
import { FilterAvailableFacade } from '../../state/filter-available/filter-available.facade';
import { ComboSearchBestRatesFacade } from '../../state/search-best-rates/search-best-rates.facade';
import { AvailableFlightsFacade } from '../search-result/flight/flight-search/flight-cache-search/+state/available-flights.facade';
import { LowestPriceFlightsFacade } from '../search-result/flight/flight-search/flight-lowest-price/+state/lowest-price-flights.facade';
import { FlightFilterFacade } from '../search-result/flight/flight-search/flight-filter/+state/flight-filter.facade';
import { last, take } from 'rxjs/operators';
import { AirportFacade } from '@gtd/meta';

@Component({
  selector: 'gtd-combo-form-search',
  templateUrl: './combo-form-search.component.html',
  styleUrls: ['./combo-form-search.component.scss'],
  providers: [AirportFacade],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class ComboFormSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() pageName: string;
  @Input() seo = false;
  @Input() searchParams: any;
  @Input() localArr = [];
  @Input() hotelName = '';
  @Input() btnText = 'combovj.form-search.find-combo';
  @Output() submitListener: EventEmitter<any> = new EventEmitter<any>();
  searchCombo: FormGroup;
  airport: string[];
  submited: boolean;
  startDate = new Date();
  defaultFromDate = new Date();
  defaultToDate = new Date(
    Date.UTC(
      this.defaultFromDate.getFullYear(),
      this.defaultFromDate.getMonth(),
      this.defaultFromDate.getDate() + 1
    )
  );
  hotelPlace;
  flightTypeSwitcherEnum = FlightTypeSwitcherEnum;
  dataSearchCombo = {};
  togleSwaps: boolean;
  findNewHotelPlace: boolean;
  getDataSearchCombo;
  fromLocalStorages: AirportDTO;
  toLocalStorages: AirportDTO;
  getAllAirportSession: any;
  subscriptions = new Subscription();
  roomArray;
  roomsSearch: any;
  newHotelPlaceSubject = new BehaviorSubject<any>(null);
  newHotelPlace$ = this.newHotelPlaceSubject.asObservable();
  localInformationSubject$ = new BehaviorSubject(null);
  localInformation$ = this.localInformationSubject$.asObservable();
  maxDate = new Date(
    Date.UTC(
      this.defaultFromDate.getFullYear(),
      this.defaultFromDate.getMonth(),
      this.defaultFromDate.getDate() + 27
    )
  );
  constructor(
    private router: Router,
    private serializer: UrlSerializer,
    private passengerService: FormPassengersService,
    public datepipe: DatePipe,
    private historySearchKeywordFacade: HistorySearchKeywordFacade,
    private comboSearchService: ComboSearchService,
    private comboSearchInformationFacade: ComboVJSearchInformationFacade,
    private _comboSelectedService: ComboSelectedService,
    private flightSearchFacade: FlightSearchFacade,
    private filterAvailableFacade: FilterAvailableFacade,
    private searchBestRatesFacade: ComboSearchBestRatesFacade,
    private availableFlightsFacade: AvailableFlightsFacade,
    private lowestPriceFlightsFacade: LowestPriceFlightsFacade,
    private flightFilterFacade: FlightFilterFacade,
    private airportFacade: AirportFacade,
    private fb: FormBuilder
  ) {
    if (!this.seo) {
      this.getDataSearchCombo = JSON.parse(
        localStorage.getItem('dataComboSearch')
      );
    }
    if (
      this.getDataSearchCombo !== null &&
      this.compareDates(this.getDataSearchCombo.fromDate, this.startDate) === 1
    ) {
      this.defaultFromDate = new Date(this.getDataSearchCombo.fromDate);
      this.defaultToDate = new Date(this.getDataSearchCombo.toDate);
      this.maxDate.setDate(this.defaultFromDate.getDate() + 27);
    } else {
      this.defaultFromDate = new Date();
      this.defaultToDate = new Date(
        Date.UTC(
          this.defaultFromDate.getFullYear(),
          this.defaultFromDate.getMonth(),
          this.defaultFromDate.getDate() + 1
        )
      );
      this.maxDate = this.defaultToDate = new Date(
        Date.UTC(
          this.defaultFromDate.getFullYear(),
          this.defaultFromDate.getMonth(),
          this.defaultFromDate.getDate() + 27
        )
      );
    }
    this.searchCombo = new FormGroup({
      fromLocal: new FormControl(null, Validators.required),
      toLocal: new FormControl(
        this.getDataSearchCombo === null ? '' : this.getDataSearchCombo.to,
        Validators.required
      ),
      location: new FormControl(
        this.getDataSearchCombo === null ? '' : this.getDataSearchCombo.location
      ),
      // TODO catch rooom from local storage
      rooms: new FormGroup({
        room: new FormArray([])
      }),
      roundType: new FormControl(
        this.getDataSearchCombo === null
          ? FlightTypeSwitcherEnum.ROUNDTRIP
          : this.getDataSearchCombo.roundType,
        Validators.required
      ),
      datePicker: new FormGroup({
        fromDate: new FormControl(this.defaultFromDate, Validators.required),
        toDate: new FormControl(this.defaultToDate, Validators.required)
      })
    });
    this.roomArray = this.searchCombo.controls['rooms'].value;
    this.searchCombo.setValidators(MustNotMatch('fromLocal', 'toLocal'));
  }
  get rooms(): FormArray {
    return this.searchCombo.controls['rooms'].get('room') as FormArray;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.localArr && !changes.localArr.firstChange) {
      for (const item of changes.localArr.currentValue) {
        this.airportFacade.loadAll(item, 'VN');
      }
    }
  }

  ngOnInit() {
    let roomsArrs = [];
    if (this.seo && this.searchParams) {
      roomsArrs = this.searchParams.roomsArrs;
      this.defaultFromDate = this.searchParams.fromDate;
      this.defaultToDate = this.searchParams.toDate;
      this.searchCombo.controls['datePicker']
        .get('fromDate')
        .setValue(this.defaultFromDate);
      this.searchCombo.controls['datePicker']
        .get('toDate')
        .setValue(this.defaultToDate);
    } else if (this.getDataSearchCombo !== null) {
      roomsArrs = JSON.parse(this.getDataSearchCombo.rooms);
    } else {
      roomsArrs = [
        {
          adultQuantity: [2],
          childQuantity: [0],
          infantCount: [0],
          infantAges: [],
          childrenAges: []
        }
      ];
    }
    roomsArrs.forEach(roomArr => {
      this.rooms.push(
        this.fb.group({
          adultQuantity: roomArr.adultQuantity,
          childQuantity: roomArr.childQuantity,
          infantCount: roomArr.infantCount,
          childrenAges: [roomArr.childrenAges],
          infantAges: [roomArr.infantAges]
        })
      );
    });
    this.searchCombo.get('toLocal').valueChanges.subscribe(valueChange => {
      // console.log(valueChange, this.hotelPlace);
      if (this.hotelPlace) {
        this.newHotelPlaceSubject.next({
          name:
            this.hotelPlace.city && this.hotelPlace.city.includes('City')
              ? this.hotelPlace.city.replace('City', '')
              : this.hotelPlace.city,
          propertyCount: null,
          searchCode:
            this.hotelPlace.city && this.hotelPlace.city.includes('City')
              ? this.hotelPlace.city.replace('City', '')
              : this.hotelPlace.city,
          searchType: 'AUTO',
          supplier: 'EXPEDIA'
        });
        // this.searchCombo.controls['location'].setValue({
        //   name: this.hotelPlace.city,
        //   propertyCount: null,
        //   searchCode: this.hotelPlace.city,
        //   searchType: 'AUTO',
        //   supplier: 'EXPEDIA'
        // });
      }
    });
    this.searchCombo.valueChanges.subscribe(valueChange => {
      this.localInformationSubject$.next([
        valueChange.fromLocal,
        valueChange.toLocal
      ]);
      setTimeout(() => {
        this.searchCombo.controls['datePicker']
          .get('toDate')
          .setValidators(Validators.required);
        if (
          this.searchCombo.controls['datePicker'].get('toDate').value === '' ||
          this.searchCombo.controls['datePicker']
            .get('toDate')
            .value.toString() === 'Invalid Date'
        ) {
          this.searchCombo.controls['datePicker']
            .get('toDate')
            .setErrors({ incorrect: true });
        }
        if (valueChange.roundType === FlightTypeSwitcherEnum.ONEWAY) {
          this.findNewHotelPlace = true;
        }
      });

      if (valueChange.datePicker.fromDate) {
        this.defaultFromDate = valueChange.datePicker.fromDate;
        this.maxDate = new Date(
          Date.UTC(
            valueChange.datePicker.toDate.getFullYear(),
            valueChange.datePicker.toDate.getMonth(),
            valueChange.datePicker.toDate.getDate()
          )
        );
        if (valueChange.datePicker.toDate) {
          this.defaultToDate = new Date(
            Date.UTC(
              valueChange.datePicker.toDate.getFullYear(),
              valueChange.datePicker.toDate.getMonth(),
              valueChange.datePicker.toDate.getDate()
            )
          );
        } else {
          this.defaultToDate = new Date(
            Date.UTC(
              valueChange.datePicker.fromDate.getFullYear(),
              valueChange.datePicker.fromDate.getMonth(),
              valueChange.datePicker.fromDate.getDate() + 1
            )
          );
        }
      }
    });

    this.subscriptions.add(
      this.historySearchKeywordFacade.allHistorySearchKeywordFlight$.subscribe(
        historySearchKeyword => {
          this.getAllAirportSession = historySearchKeyword;
        }
      )
    );

    if (!this.seo) {
      const fromLocalStoragesTemp = this.comboSearchService.getAllLocalStorageLocation(
        'comboFrom'
      );
      const toLocalStoragesTemp = this.comboSearchService.getAllLocalStorageLocation(
        'comboTo'
      );
      if (
        fromLocalStoragesTemp &&
        fromLocalStoragesTemp[0] &&
        toLocalStoragesTemp &&
        toLocalStoragesTemp[0]
      ) {
        this.fromLocalStorages = fromLocalStoragesTemp[0];
        this.toLocalStorages = toLocalStoragesTemp[0];
      }
    } else {
      for (const item of this.localArr) {
        this.airportFacade.loadAll(item, 'VN');
      }
    }
    if (this.localArr) {
      this.subscriptions.add(
        this.airportFacade.allAirport$.subscribe(airport => {
          if (
            airport &&
            airport.length &&
            this.localArr.indexOf(airport[0].code) > -1
          ) {
            if (this.localArr.indexOf(airport[0].code) === 0) {
              this.fromLocalStorages = {
                city: airport[0].city,
                cityCode: airport[0].cityCode,
                code: airport[0].code,
                country: airport[0].country,
                countryCode: airport[0].countryCode
              };
              this.searchCombo.controls['fromLocal'].setValue({
                city: airport[0].city,
                cityCode: airport[0].cityCode,
                code: airport[0].code,
                country: airport[0].country,
                countryCode: airport[0].countryCode
              });
            }
            if (this.localArr.indexOf(airport[0].code) === 1) {
              this.toLocalStorages = {
                city: airport[0].city,
                cityCode: airport[0].cityCode,
                code: airport[0].code,
                country: airport[0].country,
                countryCode: airport[0].countryCode
              };
              this.searchCombo.controls['toLocal'].setValue({
                city: airport[0].city,
                cityCode: airport[0].cityCode,
                code: airport[0].code,
                country: airport[0].country,
                countryCode: airport[0].countryCode
              });
            }
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  findNewHotelPlaceCheckbox(event) {
    this.findNewHotelPlace = event.checked;
  }
  listenToLocalChange(event) {
    this.hotelPlace = event;
  }
  roomsValid(roomsData) {
    this.roomsSearch = roomsData.rooms;
    if (!roomsData.valid) {
      this.searchCombo.controls['rooms'].setErrors({
        rooms: roomsData.valid
      });
    } else {
      this.searchCombo.controls['rooms'].setErrors(null);
    }
  }
  submit() {
    this.submited = true;
    localStorage.removeItem('flightBooking');
    if (this.searchCombo.valid) {
      this.setLocalStorageLocation();
      setTimeout(() => {
        this.dataSearchCombo = {
          from: this.searchCombo.value.fromLocal,
          to: this.searchCombo.value.toLocal,
          fromDate: this.datepipe.transform(
            this.searchCombo.controls['datePicker'].get('fromDate').value,
            'MM-dd-yyyy'
          ),
          toDate: this.datepipe.transform(
            this.searchCombo.controls['datePicker'].get('toDate').value,
            'MM-dd-yyyy'
          )
            ? this.datepipe.transform(
                this.searchCombo.controls['datePicker'].get('toDate').value,
                'MM-dd-yyyy'
              )
            : '',
          roundType: this.searchCombo.value.roundType,
          rooms: JSON.stringify(this.roomsSearch.room),
          location: {
            name: this.searchCombo.value.location.name,
            propertyCount: null,
            searchCode: this.searchCombo.value.location.searchCode,
            searchType: this.searchCombo.value.location.searchType,
            supplier: this.searchCombo.value.location.supplier
          },
          adult: this.roomsSearch.room.reduce(
            (acc, cur) => cur.adultQuantity + acc,
            0
          ),
          child: this.roomsSearch.room.reduce(
            (acc, cur) => cur.childQuantity + acc,
            0
          ),
          infant: this.roomsSearch.room.reduce(
            (acc, cur) => cur.infantCount + acc,
            0
          ),
          time: new Date().getTime().toString()
        };
        localStorage.setItem(
          'dataComboSearch',
          JSON.stringify(this.dataSearchCombo)
        );
        if (!this.seo) {
          this.router.navigate(['/combo'], {
            queryParams: {
              params: btoa(
                encodeURIComponent(JSON.stringify(this.dataSearchCombo))
              )
            }
          });
        } else {
          this.submitListener.emit(this.dataSearchCombo);
        }
        if (this.seo) {
          this._comboSelectedService.resetFLight();
        } else {
          this._comboSelectedService.resetAll();
        }
        this.flightSearchFacade.resetAll();
        this.flightFilterFacade.resetFilter();
        this.availableFlightsFacade.resetAll();
        this.lowestPriceFlightsFacade.resetAll();
        this.filterAvailableFacade.resetFilter();
        this.searchBestRatesFacade.searchResetAll();
      }, 200);
    }
  }

  setLocalStorageLocation() {
    if (this.getAllAirportSession) {
      const fromLocalStoragesTemp = this.comboSearchService.getAllLocalStorageLocation(
        'comboFrom'
      );
      const toLocalStoragesTemp = this.comboSearchService.getAllLocalStorageLocation(
        'comboTo'
      );
      let newFrom = new Array<AirportDTO>();
      let newTo = new Array<AirportDTO>();
      if (
        fromLocalStoragesTemp &&
        this.getAllAirportSession.from &&
        this.getAllAirportSession.from.length > 0
      ) {
        const from = fromLocalStoragesTemp.filter(
          fromLocal => fromLocal.code === this.getAllAirportSession.from[0].code
        );
        if (from.length > 0) {
          const index = fromLocalStoragesTemp.indexOf(from[0]);
          fromLocalStoragesTemp.splice(index, 1);
          fromLocalStoragesTemp.unshift(this.getAllAirportSession.from[0]);
          newFrom = fromLocalStoragesTemp;
        } else {
          fromLocalStoragesTemp.unshift(this.getAllAirportSession.from[0]);
          newFrom = fromLocalStoragesTemp;
        }
      } else {
        newFrom = this.getAllAirportSession.from;
      }
      if (
        toLocalStoragesTemp &&
        this.getAllAirportSession.to &&
        this.getAllAirportSession.to.length > 0
      ) {
        const to = toLocalStoragesTemp.filter(
          toLocal => toLocal.code === this.getAllAirportSession.to[0].code
        );
        if (to.length > 0) {
          const index = toLocalStoragesTemp.indexOf(to[0]);
          toLocalStoragesTemp.splice(index, 1);
          toLocalStoragesTemp.unshift(this.getAllAirportSession.to[0]);
          newTo = toLocalStoragesTemp;
        } else {
          toLocalStoragesTemp.unshift(this.getAllAirportSession.to[0]);
          newTo = toLocalStoragesTemp;
        }
      } else {
        newTo = this.getAllAirportSession.to;
      }
      if (newFrom && newFrom.length > 0) {
        localStorage.setItem('comboFrom', JSON.stringify(newFrom));
      }
      if (newTo && newTo.length > 0) {
        localStorage.setItem('comboTo', JSON.stringify(newTo));
      }
    }
  }

  swapsLocation() {
    this.togleSwaps = !this.togleSwaps;
    this.localInformation$.pipe(take(1)).subscribe(local => {
      if (local) {
        let fromLocalStorages = new Array<AirportDTO>();
        let toLocalStorages = new Array<AirportDTO>();
        if (
          this.getAllAirportSession &&
          this.getAllAirportSession.from.length > 0
        ) {
          fromLocalStorages = this.getAllAirportSession.from;
        } else {
          fromLocalStorages = this.comboSearchService.getAllLocalStorageLocation(
            'comboFrom'
          );
        }
        if (
          this.getAllAirportSession &&
          this.getAllAirportSession.to.length > 0
        ) {
          toLocalStorages = this.getAllAirportSession.to;
        } else {
          toLocalStorages = this.comboSearchService.getAllLocalStorageLocation(
            'comboTo'
          );
        }
        if (
          fromLocalStorages &&
          fromLocalStorages.length > 0 &&
          toLocalStorages &&
          toLocalStorages.length > 0
        ) {
          this.getAllAirportSession = {};
        }
        if (fromLocalStorages && fromLocalStorages.length > 0) {
          this.toLocalStorages = fromLocalStorages[0];
          this.getAllAirportSession.to = fromLocalStorages;
          this.searchCombo.controls['fromLocal'].setValue(local[1]);
          this.historySearchKeywordFacade.addSearchKeywordFlightTo(
            fromLocalStorages
          );
        }
        if (toLocalStorages && toLocalStorages.length > 0) {
          this.fromLocalStorages = toLocalStorages[0];
          this.getAllAirportSession.from = toLocalStorages;
          this.searchCombo.controls['toLocal'].setValue(local[0]);
          this.historySearchKeywordFacade.addSearchKeywordFlightFrom(
            toLocalStorages
          );
        }
      }
    });
  }

  compareDates(date1: Date, date2: Date): number {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;
    if (d1 > d2) return 1;
    if (d1 < d2) return -1;
  }
  // listenHotelChange(value){
  //   // console.log(value)
  //   this.searchCombo.controls['location'].patchValue({
  //     name: value.city,
  //     propertyCount: null,
  //     searchCode: value.city,
  //     searchType: 'AUTO',
  //     supplier: 'EXPEDIA'
  //   });
  // }
}
