import {
  HotelDetailAction,
  HotelDetailActionTypes
} from './hotel-detail.actions';
import {OtaResultOfSearchAllRatesResult} from "@gtd/b2c-client";

export const HOTELDETAIL_FEATURE_KEY = 'hotelDetail';

/**
 * Interface for the 'HotelDetail' data used in
 *  - HotelDetailState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelDetailState {
  list: OtaResultOfSearchAllRatesResult; // list of HotelDetail; analogous to a sql normalized table
  selectedId?: string | number; // which HotelDetail record has been selected
  loaded: boolean; // has the HotelDetail list been loaded
  error?: any; // last none error (if any)
}

export interface HotelDetailPartialState {
  readonly [HOTELDETAIL_FEATURE_KEY]: HotelDetailState;
}

export const initialState: HotelDetailState = {
  list: null,
  loaded: false
};

export function hotelDetailReducer(
  state: HotelDetailState = initialState,
  action: HotelDetailAction
): HotelDetailState {
  switch (action.type) {
    case HotelDetailActionTypes.HotelDetailLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case HotelDetailActionTypes.ResetHotelDetail: {
      state = initialState;
      break;
    }
  }
  return state;
}
