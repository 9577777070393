import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {PhoneResendOtpStatePartialState} from './phone-resend-otp-state.reducer';
import {
  LoadPhoneResendOtpState,
  PhoneResendOtpStateActionTypes,
  PhoneResendOtpStateLoadError
} from './phone-resend-otp-state.actions';
import {map} from "rxjs/operators";
import {AccountResourceService} from "@gtd/b2c-client";

@Injectable()
export class PhoneResendOtpStateEffects {
  @Effect() loadPhoneResendOtpState$ = this.dataPersistence.fetch(
    PhoneResendOtpStateActionTypes.LoadPhoneResendOtpState,
    {
      run: (
        action: LoadPhoneResendOtpState,
        state: PhoneResendOtpStatePartialState
      ) => {
        return this.accountResourceService.resendOtpAccountByMobileNumberUsingPOST(action.payload).pipe(
          map(update => ({
            type: PhoneResendOtpStateActionTypes.PhoneResendOtpStateLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadPhoneResendOtpState, error) => {
        console.error('Error', error);
        return new PhoneResendOtpStateLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<PhoneResendOtpStatePartialState>,
    private accountResourceService: AccountResourceService
  ) {}
}
