import {Action} from '@ngrx/store';
import {GroupItineraryReq} from "./group-itinerary.facade";
import {AirGroupPricedItineraryRS} from "@gtd/b2c-client";
import {AvailableFlightsActionTypes} from "../../flight-cache-search/+state/available-flights.actions";

export enum GroupItineraryActionTypes {
  LoadGroupItinerary = '[GroupItinerary] Load GroupItinerary',
  GroupItineraryLoaded = '[GroupItinerary] GroupItinerary Loaded',
  GroupItineraryLoadError = '[GroupItinerary] GroupItinerary Load Error',
  ResetGroupItinerary = '[GroupItinerary] Reset GroupItinerary',
}

export class LoadGroupItinerary implements Action {
  readonly type = GroupItineraryActionTypes.LoadGroupItinerary;
  constructor(public payload: GroupItineraryReq) {}
}

export class GroupItineraryLoadError implements Action {
  readonly type = GroupItineraryActionTypes.GroupItineraryLoadError;
  constructor(public payload: any) {}
}

export class GroupItineraryLoaded implements Action {
  readonly type = GroupItineraryActionTypes.GroupItineraryLoaded;
  constructor(public payload: AirGroupPricedItineraryRS) {}
}

export class ResetGroupItinerary implements Action {
  readonly type = GroupItineraryActionTypes.ResetGroupItinerary;
}

export type GroupItineraryAction =
  | LoadGroupItinerary
  | GroupItineraryLoaded
  | GroupItineraryLoadError
  | ResetGroupItinerary;

export const fromGroupItineraryActions = {
  LoadGroupItinerary,
  GroupItineraryLoaded,
  GroupItineraryLoadError,
  ResetGroupItinerary
};
