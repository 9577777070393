import {ProfileAction, ProfileActionTypes} from './profile.actions';
import {CustomerProfileDTO} from "@gtd/api-services/customer-service";

export const PROFILE_FEATURE_KEY = 'profile';

/**
 * Interface for the 'Profile' data used in
 *  - ProfileState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface ResultUpdate {
  type: String;
  lists: CustomerProfileDTO;
}

export interface ProfileState {
  result: CustomerProfileDTO; // list of Profile; analogous to a sql normalized table
  resultAll: ResultUpdate;
  resultUpdate: ResultUpdate;
  selectedId?: string | number; // which Profile record has been selected
  loaded: boolean; // has the Profile list been loaded
  error?: any; // last none error (if any)
}

export interface ProfilePartialState {
  readonly [PROFILE_FEATURE_KEY]: ProfileState;
}

export const initialState: ProfileState = {
  result: null,
  resultAll: null,
  resultUpdate: null,
  loaded: false
};

export function reducer(
  state: ProfileState = initialState,
  action: ProfileAction
): ProfileState {
  switch (action.type) {
    case ProfileActionTypes.ProfileLoaded: {
      state = {
        ...state,
        resultAll: {
          type: 'LoadAll',
          lists: action.payload
        },
        loaded: true
      };
      break;
    }
    case ProfileActionTypes.ProfileUpdated: {
      state = {
        ...state,
        resultUpdate: {
          type: 'Update',
          lists: action.payload
        },
        loaded: true
      };
      break;
    }
  }
  return state;
}
