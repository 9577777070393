/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TravelPolicyDTO { 
    agencyCode?: string;
    applyDate?: Date;
    createdBy?: string;
    createdDate?: Date;
    deleted?: boolean;
    description?: string;
    enable?: boolean;
    expiredDate?: Date;
    id?: number;
    name?: string;
    supplierTypes?: Array<TravelPolicyDTO.SupplierTypesEnum>;
    traveler?: string;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace TravelPolicyDTO {
    export type SupplierTypesEnum = 'AIR' | 'HOTEL' | 'COMBO' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const SupplierTypesEnum = {
        AIR: 'AIR' as SupplierTypesEnum,
        HOTEL: 'HOTEL' as SupplierTypesEnum,
        COMBO: 'COMBO' as SupplierTypesEnum,
        TOURS: 'TOURS' as SupplierTypesEnum,
        TRAIN: 'TRAIN' as SupplierTypesEnum,
        SHIP: 'SHIP' as SupplierTypesEnum,
        OTHER: 'OTHER' as SupplierTypesEnum
    };
}
