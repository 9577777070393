import { Action } from '@ngrx/store';
import { Entity } from './tour-filter-available.reducer';

export enum TourFilterAvailableActionTypes {
  ChangeCategories = '[TourFilterAvailable] Change Categories',
  ChangeThemes = '[TourFilterAvailable] Change Themes',
  ChangeAmenities = '[TourFilterAvailable] Change Amenities',
  ChangePage = '[TourFilterAvailable] Change Page',
  ChangeSort = '[TourFilterAvailable] Change Sort',
  ResetFilter = '[TourFilterAvailable] Reset Filter'
}

export class ChangeCategories implements Action {
  readonly type = TourFilterAvailableActionTypes.ChangeCategories;
  constructor(public payload: string[]) {}
}

export class ChangeThemes implements Action {
  readonly type = TourFilterAvailableActionTypes.ChangeThemes;
  constructor(public payload: string[]) {}
}

export class ChangeAmenities implements Action {
  readonly type = TourFilterAvailableActionTypes.ChangeAmenities;
  constructor(public payload: string[]) {}
}

export class ChangePage implements Action {
  readonly type = TourFilterAvailableActionTypes.ChangePage;
  constructor(public payload: number) {}
}

export class ResetFilter implements Action {
  readonly type = TourFilterAvailableActionTypes.ResetFilter;
}

export class ChangeSort implements Action {
  readonly type = TourFilterAvailableActionTypes.ChangeSort;
  constructor(public payload: TourSortAvailable) {}
}

export type TourFilterAvailableAction =
  | ChangeCategories
  | ChangeThemes
  | ChangeAmenities
  | ChangePage
  | ResetFilter
  | ChangeSort;

export const fromTourFilterAvailableActions = {
  ChangeCategories,
  ChangeThemes,
  ChangeAmenities,
  ChangePage,
  ResetFilter,
  ChangeSort
};

export interface TourSortAvailable {
  sortName: string;
  sortOrder: 'DESC' | 'ASC';
}
