import { FormGroup } from '@angular/forms';

export function markFormTouched(group: FormGroup) {
  Object.keys(group.controls).forEach((key: string) => {
    const control = group.controls[key];
    if (control instanceof FormGroup) {
      control.markAsTouched();
      this.markFormTouched(control);
    } else {
      control.markAsTouched();
    }
  });
}
