/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface V2UsersBody { 
    /**
     * Login name for the user.
     */
    username: string;
    /**
     * Display name for the user.
     */
    name?: string;
    /**
     * First name for the user.
     */
    firstName?: string;
    /**
     * Last name for the user.
     */
    lastName?: string;
    /**
     * The email address for the user.
     */
    email: string;
    /**
     * URL of the user.
     */
    url?: string;
    /**
     * Description of the user.
     */
    description?: string;
    /**
     * Locale for the user.
     */
    locale?: Array<V2UsersBody.LocaleEnum>;
    /**
     * The nickname for the user.
     */
    nickname?: string;
    /**
     * An alphanumeric identifier for the user.
     */
    slug?: string;
    /**
     * Roles assigned to the user.
     */
    roles?: string;
    /**
     * Password for the user (never included).
     */
    password: string;
    /**
     * Meta fields.
     */
    meta?: string;
    /**
     * Expose advanced custom fields.
     */
    acf?: string;
}
export namespace V2UsersBody {
    export type LocaleEnum = '' | 'en_US';
    export const LocaleEnum = {
        Empty: '' as LocaleEnum,
        EnUS: 'en_US' as LocaleEnum
    };
}