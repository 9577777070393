/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BookingAdditionalRequestDTO { 
    bookingCode?: string;
    bookingDescription?: string;
    bookingId?: number;
    bookingNumber?: string;
    createdBy?: string;
    createdByAgent?: string;
    createdByAgentId?: number;
    createdByNotes?: string;
    createdDate?: Date;
    id?: number;
    notes?: string;
    passengerNameRecords?: Array<string>;
    requestContent?: string;
    requestDate?: Date;
    requestType?: BookingAdditionalRequestDTO.RequestTypeEnum;
    status?: BookingAdditionalRequestDTO.StatusEnum;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace BookingAdditionalRequestDTO {
    export type RequestTypeEnum = 'AIR_ADD_PACKAGE' | 'AIR_EDIT_PACKAGE' | 'AIR_DELETE_BOOKING' | 'AIR_ADD_INFORMATION' | 'HOTEL_ADD_PERSON' | 'HOTEL_EDIT_BOOKING' | 'HOTEL_DELETE_BOOKING' | 'HOTEL_CHANGE_ROOM' | 'ALL_OTHER';
    export const RequestTypeEnum = {
        AIRADDPACKAGE: 'AIR_ADD_PACKAGE' as RequestTypeEnum,
        AIREDITPACKAGE: 'AIR_EDIT_PACKAGE' as RequestTypeEnum,
        AIRDELETEBOOKING: 'AIR_DELETE_BOOKING' as RequestTypeEnum,
        AIRADDINFORMATION: 'AIR_ADD_INFORMATION' as RequestTypeEnum,
        HOTELADDPERSON: 'HOTEL_ADD_PERSON' as RequestTypeEnum,
        HOTELEDITBOOKING: 'HOTEL_EDIT_BOOKING' as RequestTypeEnum,
        HOTELDELETEBOOKING: 'HOTEL_DELETE_BOOKING' as RequestTypeEnum,
        HOTELCHANGEROOM: 'HOTEL_CHANGE_ROOM' as RequestTypeEnum,
        ALLOTHER: 'ALL_OTHER' as RequestTypeEnum
    };
    export type StatusEnum = 'PENDING' | 'WAITING_FEEDBACK' | 'PROCESSED' | 'CANCELLED' | 'FAILED';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        WAITINGFEEDBACK: 'WAITING_FEEDBACK' as StatusEnum,
        PROCESSED: 'PROCESSED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum
    };
}
