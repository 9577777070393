import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomerTravellerDTO} from "@gtd/api-services/customer-service";
import {Subscription} from "rxjs";
import {LookupFacade} from "@gtd/meta";
import {CustomerTravellerFacade} from "../../customer-traveller/+state/customer-traveller.facade";
import {MatDialog, MatSnackBar} from "@angular/material";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {AgentCustomerTO, CustomerResourceService, GetUserProfileFacade} from "@gtd/api-services/b2b-client";
import {LookupDTO} from "@gtd/b2c-client";
import {AgentCustomerMemberCardResourceService} from "@gtd/api-services/agentsrv";
import {DialogMessageComponent} from "@gtd/components/dialog-message";

@Component({
  selector: 'gtd-member-card-update-b2b',
  templateUrl: './member-card-update-b2b.component.html',
  styleUrls: ['./member-card-update-b2b.component.scss']
})
export class MemberCardUpdateB2bComponent implements OnInit , OnDestroy {
  memberCardForm?: FormGroup;
  customerTravellers: CustomerTravellerDTO;
  numberOfMemberCard: number;
  subscriptions = new Subscription();
  submitted: boolean;
  isLoading: boolean;
  lookups: Array<LookupDTO>;
  customerInfo: AgentCustomerTO;

  constructor(
    private formBuilder: FormBuilder,
    private lookupFacade: LookupFacade,
    private customerTravellerFacade: CustomerTravellerFacade,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private customerResourceService: CustomerResourceService,
    private getUserProfileFacade: GetUserProfileFacade,
    private agentCustomerMemberCardResourceService: AgentCustomerMemberCardResourceService
  ) {
    this.lookupFacade.loadAll({domainName: 'air_member_card', objectName: '', page: 0, size: 1000});
    this.memberCardForm = this.formBuilder.group({
      memberCards: new FormArray([])
    });
  }

  get f() { return this.memberCardForm.controls; }
  get t() { return this.f.memberCards as FormArray; }


  ngOnInit() {
    this.subscriptions.add(
      this.customerTravellerFacade.selectedUpdateCustomerTraveller$.subscribe(updateCustomer => {
        if(updateCustomer && updateCustomer.type === "Update") {
          this._snackBar.openFromComponent(SnackbarComponent, {
            data: {message: 'user.update-success', icon: 'check'},
            panelClass: 'snackbar-success',
            verticalPosition: "top",
            duration: 2000
          });
        }
      })
    )
    this.subscriptions.add(
      this.lookupFacade.allLookup$.subscribe(lookups => {
        this.lookups = lookups;
      })
    )
    this.subscriptions.add(
      this.getUserProfileFacade.allGetUserProfile$.subscribe(userProfile => {
        if (userProfile && userProfile.id) {
          console.log(userProfile);
          this.customerResourceService.searchObjectsUsingGET4(
            '', '', '',
            userProfile.email).subscribe(traveller => {
            if(traveller.content && traveller.content.length) {
              this.customerResourceService.getCustomerUsingGET(traveller.content[0].id).subscribe(customer => {
                this.customerInfo = customer;
                if(this.lookups && customer && customer.frequencyCards && customer.frequencyCards.length > 0) {
                  this.t.clear();
                  customer.frequencyCards.forEach(data => {
                    this.t.push(this.formBuilder.group({
                      id: [data.id],
                      cardType: [data.cardType],
                      customerId: [this.customerInfo.id],
                      cardNumber: [data.cardNumber],
                    }));
                  })
                }
              })
            }
          })
        }
      })
    );
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submitForm() {
    this.submitted = true;
    if(this.memberCardForm.valid && this.customerInfo && this.customerInfo.id) {
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.updating-information', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"});
      this.agentCustomerMemberCardResourceService.updateMemberCardsUsingPUT(
        this.memberCardForm.get("memberCards").value
      ).subscribe(updateMemberCards => {
        setTimeout(()=> {
          this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.update-success', icon: 'check'}, panelClass: 'snackbar-success', verticalPosition: "top", duration: 2000});
          this.submitted = false;
          window.location.reload();
        }, 2000);
      })
    } else {
      this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.check-information'}, panelClass: 'snackbar-warning', verticalPosition: "top", duration: 2000});
    }
    this.isLoading = false;
  }

  addItem() {
    this.submitted = false;
    this.t.push(this.formBuilder.group({
      id: ['', Validators.nullValidator(null)],
      cardType: [this.customerInfo.id],
      customerId: [this.customerInfo? this.customerInfo.id: ''],
      cardNumber: ['', [Validators.required]],
    }));
    this.numberOfMemberCard = this.t.length;
  }
  removeItem(i:number, memberCard: any) {
    if(memberCard.value && memberCard.value.id) {
      let dialogRef = this.dialog.open(DialogMessageComponent, {
        data: {
          btn: [
            {btnColor: 'default', btnText: 'global.close'},
            {btnColor: 'success', btnText: 'user.delete-member-card', action: 'DELETE'},
          ],
          content: 'user.delete-member-card-message',
          heading: 'user.delete-member-card-heading',
          dataTranslate: memberCard.value.cardType + ' ('+memberCard.value.cardNumber+')',
          messageType: 'success'
        },
        panelClass: 'dialog-do-confirm',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(actionClose => {
        if(actionClose && actionClose.action === "DELETE") {
          this.agentCustomerMemberCardResourceService.deleteAgentCustomerMemberCardUsingDELETE(memberCard.value.id).subscribe(memberCardDelete => {
            this.t.removeAt(i);
            this.numberOfMemberCard = this.t.length;
          });
        }
      })
    } else {
      this.t.removeAt(i);
      this.numberOfMemberCard = this.t.length;
    }

  }
}
