import {Component, OnDestroy, OnInit} from '@angular/core';
import {GetReferralFacade} from "@gtd/api-services/agentsrv";
import * as moment from 'moment';
import {Subscription} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {fadeIn, fadeInOut} from "@gtd/helpers";

@Component({
  selector: 'gtd-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('600ms ease-out')),
      transition('default => rotated', animate('600ms ease-in')),
    ]),
    fadeIn,
    fadeInOut
  ],
})
export class ReferralsComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;

  allGetReferral$ = this.getReferralFacade.allGetReferral$;
  groupReferrals: any[];
  private subscription: Subscription = new Subscription();

  constructor(
    private getReferralFacade: GetReferralFacade
  ) {

  }

  ngOnInit() {
    this.subscription.add(
      this.getReferralFacade.allGetReferral$.subscribe(allGetReferral => {
        this.groupReferrals = [];
        if(allGetReferral && allGetReferral.result && allGetReferral.result.length > 0) {
          this.isLoading = false;
          const transactionsGr = allGetReferral.result.reduce((groups, transaction) => {
            const date = moment(new Date(transaction.inviteDate)).format('MM-YYYY');
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(transaction);
            return groups;
          }, {})
          this.groupReferrals = Object.keys(transactionsGr).map((date) => {
            return {
              date,
              referrals: transactionsGr[date]
            };
          });
        }
      })
    )
  }
  ngOnDestroy(): void {
    this.groupReferrals = [];
    this.subscription.unsubscribe();
    this.getReferralFacade.destroyReferral();
  }


}
