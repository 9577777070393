/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ItinTotalFare } from './itinTotalFare';


export interface DraftItineraryInfo { 
    bookingDirection?: DraftItineraryInfo.BookingDirectionEnum;
    fareSourceCode?: string;
    groupId?: string;
    itinTotalFare?: ItinTotalFare;
    searchId?: string;
}
export namespace DraftItineraryInfo {
    export type BookingDirectionEnum = 'DEPARTURE' | 'RETURN' | 'TRIP';
    export const BookingDirectionEnum = {
        DEPARTURE: 'DEPARTURE' as BookingDirectionEnum,
        RETURN: 'RETURN' as BookingDirectionEnum,
        TRIP: 'TRIP' as BookingDirectionEnum
    };
}
