import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AutoCompeteComponent} from './auto-compete/auto-compete.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatIconModule,
  MatMenuModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {CustomDatepickerI18n, TranslateDatePicker} from '@gtd/components/datepicker';
import {FormPassengersComponent} from './form-passengers/form-passengers.component';
import {IncreamentInputModule} from '@gtd/components/increament-input';

import {faChevronDown, faMapMarkerAlt, faSearch} from '@fortawesome/free-solid-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AirportModule} from '@gtd/meta';
import {FlightSearchModule} from '../search-result/flight/flight-search/flight-search.module';
import {DataSearchFlightModule} from '../../state/history-search-keyword/history-search-keyword.module';
import {HotelAutoCompleteComponent} from './hotel-auto-complete/hotel-auto-complete.component';
import {StateHotelModule} from '../../state/state-hotel.module';
import {NewComboFormSearchComponent} from './combo-form-search.component';
import {FlightDestinationComponent} from './flight-destination/destination.component';
import {SwitcherComponent} from './switcher/switcher.component';
import {NewFlightFormSearchModule} from '@gtd/flight';
import {NewIncreamentInputModule} from '@gtd/components/new-increament-input';
import {ClickOutsideModule} from '@gtd/directives/click-outside';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {CustomPipeModule} from '../search-result/pipe/custom-pipe.module';
import {HotelDestinationComponent} from './hotel-destination/destination.component';
import {ComboDatepickerOnlyComponent} from './combo-datepicker-only/combo-datepicker-only.component';
import {FormCabinClassesComponent} from './form-cabin-classes/form-cabin-classes.component';
import {SanitizeModule} from "@gtd/pipes/sanitize";

library.add(faChevronDown, faMapMarkerAlt, faSearch);

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    TranslateModule,
    MatRadioModule,
    MatMenuModule,
    MatIconModule,
    IncreamentInputModule,
    MatDividerModule,
    FlexLayoutModule,
    MatButtonModule,
    AirportModule,
    MatRippleModule,
    MatCheckboxModule,
    FlightSearchModule,
    DataSearchFlightModule,
    MatSelectModule,
    MatDialogModule,
    FormsModule,
    StateHotelModule,
    NewFlightFormSearchModule,
    NewIncreamentInputModule,
    ClickOutsideModule,
    NgbDatepickerModule,
    CustomPipeModule,
    SanitizeModule
  ],
  declarations: [
    NewComboFormSearchComponent,
    AutoCompeteComponent,
    FormPassengersComponent,
    HotelAutoCompleteComponent,
    FlightDestinationComponent,
    HotelDestinationComponent,
    SwitcherComponent,
    ComboDatepickerOnlyComponent,
    FormCabinClassesComponent
  ],
  exports: [NewComboFormSearchComponent, FormPassengersComponent],
  providers: [TranslateDatePicker, CustomDatepickerI18n, DatePipe]
})
export class NewComboFormSearchModule {}
