import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoAirlineComponent } from './logo-airline/logo-airline.component';
import { DurationFormatComponent } from './duration-format/duration-format.component';
import { TransitTimeComponent } from './transit-time/transit-time.component';
import {
  CountPriceTicketByAdultTypeComponent,
  CountPriceTicketPerOnePassengerComponent
} from './count-price-ticket/count-price-ticket.component';

@NgModule({
  imports: [CommonModule],
  exports: [
    LogoAirlineComponent,
    DurationFormatComponent,
    TransitTimeComponent,
    CountPriceTicketByAdultTypeComponent,
    CountPriceTicketPerOnePassengerComponent
  ],
  declarations: [
    LogoAirlineComponent,
    DurationFormatComponent,
    TransitTimeComponent,
    CountPriceTicketByAdultTypeComponent,
    CountPriceTicketPerOnePassengerComponent
  ]
})
export class AirlinesModule {}
