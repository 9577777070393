import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'gtd-partner-section',
  templateUrl: './partner-section.component.html',
  styleUrls: ['./partner-section.component.scss']
})
export class PartnerSectionComponent implements OnInit {
  @Input() type: 'hotel' | 'flight';
  @Input() hotelPartners = [];
  flightPartners = [
    {
      image: 'VJ',
      alt: 'VietJet'
    },
    {
      image: 'VNA',
      alt: 'VietnamAirline'
    },
    {
      image: 'Bamboo',
      alt: 'Bamboo'
    },
    {
      image: 'AirAsia',
      alt: 'AirAsia'
    },
    {
      image: 'Indigo',
      alt: 'Indigo'
    },
    {
      image: 'SingaporeAirlines',
      alt: 'SingaporeAirlines'
    },
    {
      image: 'Pacific',
      alt: 'Pacific'
    },
    {
      image: 'AA',
      alt: 'AmericanAirlines'
    },
    {
      image: 'EVA',
      alt: 'Eva'
    }
  ];
  constructor() {}

  ngOnInit() {}
}
