import {
  SearchBestRatesAction,
  SearchBestRatesActionTypes
} from './search-best-rates.actions';
import { OtaResultOfSearchBestRatesResult } from '@gtd/b2c-client';

export const SEARCHBESTRATES_FEATURE_KEY = 'searchBestRates';

/**
 * Interface for the 'SearchBestRates' data used in
 *  - SearchBestRatesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface SearchBestRatesState {
  list: OtaResultOfSearchBestRatesResult; // list of SearchBestRates; analogous to a sql normalized table
  selectedId?: string | number; // which SearchBestRates record has been selected
  loaded: boolean; // has the SearchBestRates list been loaded
  error?: any; // last none error (if any)
}

export interface SearchBestRatesPartialState {
  readonly [SEARCHBESTRATES_FEATURE_KEY]: SearchBestRatesState;
}

export const initialState: SearchBestRatesState = {
  list: null,
  loaded: false
};

export function searchBestRatesReducer(
  state: SearchBestRatesState = initialState,
  action: SearchBestRatesAction
): SearchBestRatesState {
  switch (action.type) {
    case SearchBestRatesActionTypes.SearchBestRatesLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case SearchBestRatesActionTypes.LoadSearchBestRates: {
      state = {
        ...state,
        loaded: false
      };
      break;
    }
    case SearchBestRatesActionTypes.SearchBestRatesReset: {
      state = initialState;
      break;
    }
  }
  return state;
}
