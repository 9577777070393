/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AirpayOrderStatusResponse } from '../model/airpayOrderStatusResponse';
import { CalculatorReq } from '../model/calculatorReq';
import { CalculatorRes } from '../model/calculatorRes';
import { CardSubscriptionReq } from '../model/cardSubscriptionReq';
import { CardSubscriptionRes } from '../model/cardSubscriptionRes';
import { CardUnSubscriptionReq } from '../model/cardUnSubscriptionReq';
import { CardUnSubscriptionRes } from '../model/cardUnSubscriptionRes';
import { DebitCheckPaymentStatusReq } from '../model/debitCheckPaymentStatusReq';
import { DebitCheckPaymentStatusRes } from '../model/debitCheckPaymentStatusRes';
import { IPNRequest } from '../model/iPNRequest';
import { KrStatusReq } from '../model/krStatusReq';
import { KrStatusRes } from '../model/krStatusRes';
import { KrUpdateReq } from '../model/krUpdateReq';
import { KrUpdateRes } from '../model/krUpdateRes';
import { MerchantData } from '../model/merchantData';
import { OtaResultOfList } from '../model/otaResultOfList';
import { OtaResultOfstring } from '../model/otaResultOfstring';
import { PartnerPaymentReq } from '../model/partnerPaymentReq';
import { PartnerPaymentRes } from '../model/partnerPaymentRes';
import { PayByAirPayResponse } from '../model/payByAirPayResponse';
import { PayByAirpayRequest } from '../model/payByAirpayRequest';
import { PayByBookerBalanceReq } from '../model/payByBookerBalanceReq';
import { PayByBookerBalanceRes } from '../model/payByBookerBalanceRes';
import { PayByCashReq } from '../model/payByCashReq';
import { PayByCashRes } from '../model/payByCashRes';
import { PayByCreditCardReq } from '../model/payByCreditCardReq';
import { PayByCreditCardRes } from '../model/payByCreditCardRes';
import { PayByDebitCardReq } from '../model/payByDebitCardReq';
import { PayByDebitCardRes } from '../model/payByDebitCardRes';
import { PayByKredivoRequest } from '../model/payByKredivoRequest';
import { PayByKredivoResponse } from '../model/payByKredivoResponse';
import { PayByMoMoRequest } from '../model/payByMoMoRequest';
import { PayByMoMoResponse } from '../model/payByMoMoResponse';
import { PayByPayooReq } from '../model/payByPayooReq';
import { PayByPayooResponse } from '../model/payByPayooResponse';
import { PayBySubscriptionReq } from '../model/payBySubscriptionReq';
import { PayBySubscriptionRes } from '../model/payBySubscriptionRes';
import { PayByViettelRes } from '../model/payByViettelRes';
import { PayByVnPayReq } from '../model/payByVnPayReq';
import { PayByVnPayRes } from '../model/payByVnPayRes';
import { PayByZaloRequest } from '../model/payByZaloRequest';
import { PayByZaloResponse } from '../model/payByZaloResponse';
import { PaymentBaseRQ } from '../model/paymentBaseRQ';
import { PaymentConfirmRS } from '../model/paymentConfirmRS';
import { PaymentFeeOptionReq } from '../model/paymentFeeOptionReq';
import { PaymentFeeOptionRes } from '../model/paymentFeeOptionRes';
import { PayooPaymentConfirm } from '../model/payooPaymentConfirm';
import { RefundViettelRequest } from '../model/refundViettelRequest';
import { RefundViettelResponse } from '../model/refundViettelResponse';
import { VTPaymentBookingReq } from '../model/vTPaymentBookingReq';
import { VTPaymentBookingRes } from '../model/vTPaymentBookingRes';
import { VTValidationBookingDTO } from '../model/vTValidationBookingDTO';
import { VTValidationBookingRes } from '../model/vTValidationBookingRes';
import { VnPayReqIPN } from '../model/vnPayReqIPN';
import { VoidPaymentReq } from '../model/voidPaymentReq';
import { VoidPaymentRes } from '../model/voidPaymentRes';
import { ZaloOrderStatusResponse } from '../model/zaloOrderStatusResponse';
import { ZaloPaymentConfirm } from '../model/zaloPaymentConfirm';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PaymentResourceService {

    protected basePath = 'https://10.7.71.20:8282';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * airpayCheckOrderStatus
     * 
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public airpayCheckOrderStatusUsingPOST(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<AirpayOrderStatusResponse>;
    public airpayCheckOrderStatusUsingPOST(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirpayOrderStatusResponse>>;
    public airpayCheckOrderStatusUsingPOST(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirpayOrderStatusResponse>>;
    public airpayCheckOrderStatusUsingPOST(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling airpayCheckOrderStatusUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AirpayOrderStatusResponse>(`${this.basePath}/api/payment/airpay-check-order-status`,
            bookingNumber,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkTransactionStatusKredivo
     * 
     * @param statusReq statusReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkTransactionStatusKredivoUsingPOST(statusReq: KrStatusReq, observe?: 'body', reportProgress?: boolean): Observable<KrStatusRes>;
    public checkTransactionStatusKredivoUsingPOST(statusReq: KrStatusReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KrStatusRes>>;
    public checkTransactionStatusKredivoUsingPOST(statusReq: KrStatusReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KrStatusRes>>;
    public checkTransactionStatusKredivoUsingPOST(statusReq: KrStatusReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (statusReq === null || statusReq === undefined) {
            throw new Error('Required parameter statusReq was null or undefined when calling checkTransactionStatusKredivoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<KrStatusRes>(`${this.basePath}/api/payment/kredivo/transaction-status`,
            statusReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * confirmPayment
     * 
     * @param ipnRequest ipnRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmPaymentUsingPOST(ipnRequest: IPNRequest, observe?: 'body', reportProgress?: boolean): Observable<PaymentConfirmRS>;
    public confirmPaymentUsingPOST(ipnRequest: IPNRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentConfirmRS>>;
    public confirmPaymentUsingPOST(ipnRequest: IPNRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentConfirmRS>>;
    public confirmPaymentUsingPOST(ipnRequest: IPNRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ipnRequest === null || ipnRequest === undefined) {
            throw new Error('Required parameter ipnRequest was null or undefined when calling confirmPaymentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PaymentConfirmRS>(`${this.basePath}/api/payment/confirm`,
            ipnRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * confirmPayooPayment
     * 
     * @param notifyData notifyData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmPayooPaymentUsingPOST(notifyData: string, observe?: 'body', reportProgress?: boolean): Observable<PayooPaymentConfirm>;
    public confirmPayooPaymentUsingPOST(notifyData: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayooPaymentConfirm>>;
    public confirmPayooPaymentUsingPOST(notifyData: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayooPaymentConfirm>>;
    public confirmPayooPaymentUsingPOST(notifyData: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (notifyData === null || notifyData === undefined) {
            throw new Error('Required parameter notifyData was null or undefined when calling confirmPayooPaymentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayooPaymentConfirm>(`${this.basePath}/api/payment/payoo-confirm`,
            notifyData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * confirmZaloPayment
     * 
     * @param jsonStr jsonStr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmZaloPaymentUsingPOST(jsonStr: string, observe?: 'body', reportProgress?: boolean): Observable<ZaloPaymentConfirm>;
    public confirmZaloPaymentUsingPOST(jsonStr: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ZaloPaymentConfirm>>;
    public confirmZaloPaymentUsingPOST(jsonStr: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ZaloPaymentConfirm>>;
    public confirmZaloPaymentUsingPOST(jsonStr: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (jsonStr === null || jsonStr === undefined) {
            throw new Error('Required parameter jsonStr was null or undefined when calling confirmZaloPaymentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ZaloPaymentConfirm>(`${this.basePath}/api/payment/zalo-confirm`,
            jsonStr,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createCardSubscription
     * 
     * @param cardSubscriptionReq cardSubscriptionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCardSubscriptionUsingPOST(cardSubscriptionReq: CardSubscriptionReq, observe?: 'body', reportProgress?: boolean): Observable<CardSubscriptionRes>;
    public createCardSubscriptionUsingPOST(cardSubscriptionReq: CardSubscriptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CardSubscriptionRes>>;
    public createCardSubscriptionUsingPOST(cardSubscriptionReq: CardSubscriptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CardSubscriptionRes>>;
    public createCardSubscriptionUsingPOST(cardSubscriptionReq: CardSubscriptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cardSubscriptionReq === null || cardSubscriptionReq === undefined) {
            throw new Error('Required parameter cardSubscriptionReq was null or undefined when calling createCardSubscriptionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CardSubscriptionRes>(`${this.basePath}/api/payment/card-subscription`,
            cardSubscriptionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createCheckoutInit
     * 
     * @param payByKredivoRequest payByKredivoRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCheckoutInitUsingPOST(payByKredivoRequest: PayByKredivoRequest, observe?: 'body', reportProgress?: boolean): Observable<PayByKredivoResponse>;
    public createCheckoutInitUsingPOST(payByKredivoRequest: PayByKredivoRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByKredivoResponse>>;
    public createCheckoutInitUsingPOST(payByKredivoRequest: PayByKredivoRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByKredivoResponse>>;
    public createCheckoutInitUsingPOST(payByKredivoRequest: PayByKredivoRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payByKredivoRequest === null || payByKredivoRequest === undefined) {
            throw new Error('Required parameter payByKredivoRequest was null or undefined when calling createCheckoutInitUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByKredivoResponse>(`${this.basePath}/api/payment/kredivo/checkout-init`,
            payByKredivoRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findMerchantDataInCache
     * 
     * @param key key
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findMerchantDataInCacheUsingPOST(key: string, observe?: 'body', reportProgress?: boolean): Observable<MerchantData>;
    public findMerchantDataInCacheUsingPOST(key: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MerchantData>>;
    public findMerchantDataInCacheUsingPOST(key: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MerchantData>>;
    public findMerchantDataInCacheUsingPOST(key: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling findMerchantDataInCacheUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MerchantData>(`${this.basePath}/api/payment/find-merchant-data`,
            key,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findPaymentAuthSecKey
     * 
     * @param paymentAuthSecKey paymentAuthSecKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findPaymentAuthSecKeyUsingPOST(paymentAuthSecKey: PaymentBaseRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public findPaymentAuthSecKeyUsingPOST(paymentAuthSecKey: PaymentBaseRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public findPaymentAuthSecKeyUsingPOST(paymentAuthSecKey: PaymentBaseRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public findPaymentAuthSecKeyUsingPOST(paymentAuthSecKey: PaymentBaseRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentAuthSecKey === null || paymentAuthSecKey === undefined) {
            throw new Error('Required parameter paymentAuthSecKey was null or undefined when calling findPaymentAuthSecKeyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfstring>(`${this.basePath}/api/payment/find-payment-auth-sec`,
            paymentAuthSecKey,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findPaymentFeeOptions
     * 
     * @param paymentFeeOptionReq paymentFeeOptionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findPaymentFeeOptionsUsingPOST(paymentFeeOptionReq: PaymentFeeOptionReq, observe?: 'body', reportProgress?: boolean): Observable<PaymentFeeOptionRes>;
    public findPaymentFeeOptionsUsingPOST(paymentFeeOptionReq: PaymentFeeOptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaymentFeeOptionRes>>;
    public findPaymentFeeOptionsUsingPOST(paymentFeeOptionReq: PaymentFeeOptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaymentFeeOptionRes>>;
    public findPaymentFeeOptionsUsingPOST(paymentFeeOptionReq: PaymentFeeOptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentFeeOptionReq === null || paymentFeeOptionReq === undefined) {
            throw new Error('Required parameter paymentFeeOptionReq was null or undefined when calling findPaymentFeeOptionsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PaymentFeeOptionRes>(`${this.basePath}/api/payment/payment-fee-options`,
            paymentFeeOptionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllBookingPaymentType
     * 
     * @param payOption payOption
     * @param saleChannel saleChannel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBookingPaymentTypeUsingGET(payOption?: string, saleChannel?: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfList>;
    public getAllBookingPaymentTypeUsingGET(payOption?: string, saleChannel?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfList>>;
    public getAllBookingPaymentTypeUsingGET(payOption?: string, saleChannel?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfList>>;
    public getAllBookingPaymentTypeUsingGET(payOption?: string, saleChannel?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (payOption !== undefined && payOption !== null) {
            queryParameters = queryParameters.set('payOption', <any>payOption);
        }
        if (saleChannel !== undefined && saleChannel !== null) {
            queryParameters = queryParameters.set('saleChannel', <any>saleChannel);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfList>(`${this.basePath}/api/payment/get-booking-payment-type`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getLoanCalculatorKredivo
     * 
     * @param calculatorReq calculatorReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLoanCalculatorKredivoUsingPOST(calculatorReq: CalculatorReq, observe?: 'body', reportProgress?: boolean): Observable<CalculatorRes>;
    public getLoanCalculatorKredivoUsingPOST(calculatorReq: CalculatorReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CalculatorRes>>;
    public getLoanCalculatorKredivoUsingPOST(calculatorReq: CalculatorReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CalculatorRes>>;
    public getLoanCalculatorKredivoUsingPOST(calculatorReq: CalculatorReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (calculatorReq === null || calculatorReq === undefined) {
            throw new Error('Required parameter calculatorReq was null or undefined when calling getLoanCalculatorKredivoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CalculatorRes>(`${this.basePath}/api/payment/kredivo/get-loan-calculator`,
            calculatorReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getReasonCodePaymentFailed
     * 
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReasonCodePaymentFailedUsingPOST(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getReasonCodePaymentFailedUsingPOST(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getReasonCodePaymentFailedUsingPOST(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getReasonCodePaymentFailedUsingPOST(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getReasonCodePaymentFailedUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<string>>(`${this.basePath}/api/payment/payment-failed/get-reason-code`,
            bookingNumber,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onViettelCheckSumPayment
     * 
     * @param vtPaymentBookingReq vtPaymentBookingReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onViettelCheckSumPaymentUsingPOST(vtPaymentBookingReq: VTPaymentBookingReq, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public onViettelCheckSumPaymentUsingPOST(vtPaymentBookingReq: VTPaymentBookingReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public onViettelCheckSumPaymentUsingPOST(vtPaymentBookingReq: VTPaymentBookingReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public onViettelCheckSumPaymentUsingPOST(vtPaymentBookingReq: VTPaymentBookingReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vtPaymentBookingReq === null || vtPaymentBookingReq === undefined) {
            throw new Error('Required parameter vtPaymentBookingReq was null or undefined when calling onViettelCheckSumPaymentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/viettel/on-viettel-payment-booking`,
            vtPaymentBookingReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * onViettelCheckSumValidation
     * 
     * @param vtValidationBookingRes vtValidationBookingRes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public onViettelCheckSumValidationUsingPOST(vtValidationBookingRes: VTValidationBookingRes, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public onViettelCheckSumValidationUsingPOST(vtValidationBookingRes: VTValidationBookingRes, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public onViettelCheckSumValidationUsingPOST(vtValidationBookingRes: VTValidationBookingRes, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public onViettelCheckSumValidationUsingPOST(vtValidationBookingRes: VTValidationBookingRes, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vtValidationBookingRes === null || vtValidationBookingRes === undefined) {
            throw new Error('Required parameter vtValidationBookingRes was null or undefined when calling onViettelCheckSumValidationUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/viettel/on-viettel-checksum-validation`,
            vtValidationBookingRes,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * partnerPayment
     * 
     * @param reqPayment reqPayment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public partnerPaymentUsingPOST(reqPayment: PartnerPaymentReq, observe?: 'body', reportProgress?: boolean): Observable<PartnerPaymentRes>;
    public partnerPaymentUsingPOST(reqPayment: PartnerPaymentReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PartnerPaymentRes>>;
    public partnerPaymentUsingPOST(reqPayment: PartnerPaymentReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PartnerPaymentRes>>;
    public partnerPaymentUsingPOST(reqPayment: PartnerPaymentReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (reqPayment === null || reqPayment === undefined) {
            throw new Error('Required parameter reqPayment was null or undefined when calling partnerPaymentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PartnerPaymentRes>(`${this.basePath}/api/payment/pay-by-partner`,
            reqPayment,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payByAirpay
     * 
     * @param payByAirpayRequest payByAirpayRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payByAirpayUsingPOST(payByAirpayRequest: PayByAirpayRequest, observe?: 'body', reportProgress?: boolean): Observable<PayByAirPayResponse>;
    public payByAirpayUsingPOST(payByAirpayRequest: PayByAirpayRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByAirPayResponse>>;
    public payByAirpayUsingPOST(payByAirpayRequest: PayByAirpayRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByAirPayResponse>>;
    public payByAirpayUsingPOST(payByAirpayRequest: PayByAirpayRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payByAirpayRequest === null || payByAirpayRequest === undefined) {
            throw new Error('Required parameter payByAirpayRequest was null or undefined when calling payByAirpayUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByAirPayResponse>(`${this.basePath}/api/payment/pay-by-airpay`,
            payByAirpayRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payByBookerBalance
     * 
     * @param payByBookerBalanceReq payByBookerBalanceReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payByBookerBalanceUsingPOST(payByBookerBalanceReq: PayByBookerBalanceReq, observe?: 'body', reportProgress?: boolean): Observable<PayByBookerBalanceRes>;
    public payByBookerBalanceUsingPOST(payByBookerBalanceReq: PayByBookerBalanceReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByBookerBalanceRes>>;
    public payByBookerBalanceUsingPOST(payByBookerBalanceReq: PayByBookerBalanceReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByBookerBalanceRes>>;
    public payByBookerBalanceUsingPOST(payByBookerBalanceReq: PayByBookerBalanceReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payByBookerBalanceReq === null || payByBookerBalanceReq === undefined) {
            throw new Error('Required parameter payByBookerBalanceReq was null or undefined when calling payByBookerBalanceUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByBookerBalanceRes>(`${this.basePath}/api/payment/pay-by-balance`,
            payByBookerBalanceReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payByCash
     * 
     * @param payByCashReq payByCashReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payByCashUsingPOST(payByCashReq: PayByCashReq, observe?: 'body', reportProgress?: boolean): Observable<PayByCashRes>;
    public payByCashUsingPOST(payByCashReq: PayByCashReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByCashRes>>;
    public payByCashUsingPOST(payByCashReq: PayByCashReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByCashRes>>;
    public payByCashUsingPOST(payByCashReq: PayByCashReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payByCashReq === null || payByCashReq === undefined) {
            throw new Error('Required parameter payByCashReq was null or undefined when calling payByCashUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByCashRes>(`${this.basePath}/api/payment/pay-by-cash`,
            payByCashReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payByCreditCard
     * 
     * @param payByCreditCardReq payByCreditCardReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payByCreditCardUsingPOST(payByCreditCardReq: PayByCreditCardReq, observe?: 'body', reportProgress?: boolean): Observable<PayByCreditCardRes>;
    public payByCreditCardUsingPOST(payByCreditCardReq: PayByCreditCardReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByCreditCardRes>>;
    public payByCreditCardUsingPOST(payByCreditCardReq: PayByCreditCardReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByCreditCardRes>>;
    public payByCreditCardUsingPOST(payByCreditCardReq: PayByCreditCardReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payByCreditCardReq === null || payByCreditCardReq === undefined) {
            throw new Error('Required parameter payByCreditCardReq was null or undefined when calling payByCreditCardUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByCreditCardRes>(`${this.basePath}/api/payment/pay-by-ccard`,
            payByCreditCardReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payByDebitATMCardCheckOrder
     * 
     * @param debitCheckPaymentStatusReq debitCheckPaymentStatusReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payByDebitATMCardCheckOrderUsingPOST(debitCheckPaymentStatusReq: DebitCheckPaymentStatusReq, observe?: 'body', reportProgress?: boolean): Observable<DebitCheckPaymentStatusRes>;
    public payByDebitATMCardCheckOrderUsingPOST(debitCheckPaymentStatusReq: DebitCheckPaymentStatusReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DebitCheckPaymentStatusRes>>;
    public payByDebitATMCardCheckOrderUsingPOST(debitCheckPaymentStatusReq: DebitCheckPaymentStatusReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DebitCheckPaymentStatusRes>>;
    public payByDebitATMCardCheckOrderUsingPOST(debitCheckPaymentStatusReq: DebitCheckPaymentStatusReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (debitCheckPaymentStatusReq === null || debitCheckPaymentStatusReq === undefined) {
            throw new Error('Required parameter debitCheckPaymentStatusReq was null or undefined when calling payByDebitATMCardCheckOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DebitCheckPaymentStatusRes>(`${this.basePath}/api/payment/pay-by-debit-atm/check-order`,
            debitCheckPaymentStatusReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payByDebitATMCardSendOrder
     * 
     * @param payByDebitCardReq payByDebitCardReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payByDebitATMCardSendOrderUsingPOST(payByDebitCardReq: PayByDebitCardReq, observe?: 'body', reportProgress?: boolean): Observable<PayByDebitCardRes>;
    public payByDebitATMCardSendOrderUsingPOST(payByDebitCardReq: PayByDebitCardReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByDebitCardRes>>;
    public payByDebitATMCardSendOrderUsingPOST(payByDebitCardReq: PayByDebitCardReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByDebitCardRes>>;
    public payByDebitATMCardSendOrderUsingPOST(payByDebitCardReq: PayByDebitCardReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payByDebitCardReq === null || payByDebitCardReq === undefined) {
            throw new Error('Required parameter payByDebitCardReq was null or undefined when calling payByDebitATMCardSendOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByDebitCardRes>(`${this.basePath}/api/payment/pay-by-debit-atm/send-order`,
            payByDebitCardReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payByQrCodeProcessOrder
     * 
     * @param vnpayReqIpn vnpayReqIpn
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payByQrCodeProcessOrderUsingPOST(vnpayReqIpn: VnPayReqIPN, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public payByQrCodeProcessOrderUsingPOST(vnpayReqIpn: VnPayReqIPN, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public payByQrCodeProcessOrderUsingPOST(vnpayReqIpn: VnPayReqIPN, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public payByQrCodeProcessOrderUsingPOST(vnpayReqIpn: VnPayReqIPN, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vnpayReqIpn === null || vnpayReqIpn === undefined) {
            throw new Error('Required parameter vnpayReqIpn was null or undefined when calling payByQrCodeProcessOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<{ [key: string]: string; }>(`${this.basePath}/api/payment/pay-by-qrcode/process-order`,
            vnpayReqIpn,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payByQrCodeSendOrder
     * 
     * @param payByQrCodeReq payByQrCodeReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payByQrCodeSendOrderUsingPOST(payByQrCodeReq: PayByVnPayReq, observe?: 'body', reportProgress?: boolean): Observable<PayByVnPayRes>;
    public payByQrCodeSendOrderUsingPOST(payByQrCodeReq: PayByVnPayReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByVnPayRes>>;
    public payByQrCodeSendOrderUsingPOST(payByQrCodeReq: PayByVnPayReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByVnPayRes>>;
    public payByQrCodeSendOrderUsingPOST(payByQrCodeReq: PayByVnPayReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payByQrCodeReq === null || payByQrCodeReq === undefined) {
            throw new Error('Required parameter payByQrCodeReq was null or undefined when calling payByQrCodeSendOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByVnPayRes>(`${this.basePath}/api/payment/pay-by-qrcode/send-order`,
            payByQrCodeReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payBySubscription
     * 
     * @param payBySubscriptionReq payBySubscriptionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payBySubscriptionUsingPOST(payBySubscriptionReq: PayBySubscriptionReq, observe?: 'body', reportProgress?: boolean): Observable<PayBySubscriptionRes>;
    public payBySubscriptionUsingPOST(payBySubscriptionReq: PayBySubscriptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayBySubscriptionRes>>;
    public payBySubscriptionUsingPOST(payBySubscriptionReq: PayBySubscriptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayBySubscriptionRes>>;
    public payBySubscriptionUsingPOST(payBySubscriptionReq: PayBySubscriptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payBySubscriptionReq === null || payBySubscriptionReq === undefined) {
            throw new Error('Required parameter payBySubscriptionReq was null or undefined when calling payBySubscriptionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayBySubscriptionRes>(`${this.basePath}/api/payment/pay-by-subscription`,
            payBySubscriptionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payByZalo
     * 
     * @param payByZaloRequest payByZaloRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payByZaloUsingPOST(payByZaloRequest: PayByZaloRequest, observe?: 'body', reportProgress?: boolean): Observable<PayByZaloResponse>;
    public payByZaloUsingPOST(payByZaloRequest: PayByZaloRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByZaloResponse>>;
    public payByZaloUsingPOST(payByZaloRequest: PayByZaloRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByZaloResponse>>;
    public payByZaloUsingPOST(payByZaloRequest: PayByZaloRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payByZaloRequest === null || payByZaloRequest === undefined) {
            throw new Error('Required parameter payByZaloRequest was null or undefined when calling payByZaloUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByZaloResponse>(`${this.basePath}/api/payment/pay-by-zalo`,
            payByZaloRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payPyMoMo
     * 
     * @param payByMoMoRequest payByMoMoRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payPyMoMoUsingPOST(payByMoMoRequest: PayByMoMoRequest, observe?: 'body', reportProgress?: boolean): Observable<PayByMoMoResponse>;
    public payPyMoMoUsingPOST(payByMoMoRequest: PayByMoMoRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByMoMoResponse>>;
    public payPyMoMoUsingPOST(payByMoMoRequest: PayByMoMoRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByMoMoResponse>>;
    public payPyMoMoUsingPOST(payByMoMoRequest: PayByMoMoRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payByMoMoRequest === null || payByMoMoRequest === undefined) {
            throw new Error('Required parameter payByMoMoRequest was null or undefined when calling payPyMoMoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByMoMoResponse>(`${this.basePath}/api/payment/pay-by-momo`,
            payByMoMoRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * payPyPayoo
     * 
     * @param payByPayooReq payByPayooReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public payPyPayooUsingPOST(payByPayooReq: PayByPayooReq, observe?: 'body', reportProgress?: boolean): Observable<PayByPayooResponse>;
    public payPyPayooUsingPOST(payByPayooReq: PayByPayooReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByPayooResponse>>;
    public payPyPayooUsingPOST(payByPayooReq: PayByPayooReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByPayooResponse>>;
    public payPyPayooUsingPOST(payByPayooReq: PayByPayooReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payByPayooReq === null || payByPayooReq === undefined) {
            throw new Error('Required parameter payByPayooReq was null or undefined when calling payPyPayooUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByPayooResponse>(`${this.basePath}/api/payment/pay-by-payoo`,
            payByPayooReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * refundPaymentViettel
     * 
     * @param refundViettelRequest refundViettelRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refundPaymentViettelUsingPOST(refundViettelRequest: RefundViettelRequest, observe?: 'body', reportProgress?: boolean): Observable<RefundViettelResponse>;
    public refundPaymentViettelUsingPOST(refundViettelRequest: RefundViettelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RefundViettelResponse>>;
    public refundPaymentViettelUsingPOST(refundViettelRequest: RefundViettelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RefundViettelResponse>>;
    public refundPaymentViettelUsingPOST(refundViettelRequest: RefundViettelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (refundViettelRequest === null || refundViettelRequest === undefined) {
            throw new Error('Required parameter refundViettelRequest was null or undefined when calling refundPaymentViettelUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RefundViettelResponse>(`${this.basePath}/api/viettel/refund-payment`,
            refundViettelRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * removeCardSubscription
     * 
     * @param cardUnSubscriptionReq cardUnSubscriptionReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeCardSubscriptionUsingPOST(cardUnSubscriptionReq: CardUnSubscriptionReq, observe?: 'body', reportProgress?: boolean): Observable<CardUnSubscriptionRes>;
    public removeCardSubscriptionUsingPOST(cardUnSubscriptionReq: CardUnSubscriptionReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CardUnSubscriptionRes>>;
    public removeCardSubscriptionUsingPOST(cardUnSubscriptionReq: CardUnSubscriptionReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CardUnSubscriptionRes>>;
    public removeCardSubscriptionUsingPOST(cardUnSubscriptionReq: CardUnSubscriptionReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cardUnSubscriptionReq === null || cardUnSubscriptionReq === undefined) {
            throw new Error('Required parameter cardUnSubscriptionReq was null or undefined when calling removeCardSubscriptionUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CardUnSubscriptionRes>(`${this.basePath}/api/payment/card-unsubscription`,
            cardUnSubscriptionReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * requestPlaceOrder
     * 
     * @param partnerPaymentReq partnerPaymentReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestPlaceOrderUsingPOST(partnerPaymentReq: PartnerPaymentReq, observe?: 'body', reportProgress?: boolean): Observable<PayByViettelRes>;
    public requestPlaceOrderUsingPOST(partnerPaymentReq: PartnerPaymentReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PayByViettelRes>>;
    public requestPlaceOrderUsingPOST(partnerPaymentReq: PartnerPaymentReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PayByViettelRes>>;
    public requestPlaceOrderUsingPOST(partnerPaymentReq: PartnerPaymentReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (partnerPaymentReq === null || partnerPaymentReq === undefined) {
            throw new Error('Required parameter partnerPaymentReq was null or undefined when calling requestPlaceOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PayByViettelRes>(`${this.basePath}/api/viettel/request-place-order`,
            partnerPaymentReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * requestVtBookingResCheckSum
     * 
     * @param vtPaymentBookingRes vtPaymentBookingRes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestVtBookingResCheckSumUsingPOST(vtPaymentBookingRes: VTPaymentBookingRes, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public requestVtBookingResCheckSumUsingPOST(vtPaymentBookingRes: VTPaymentBookingRes, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public requestVtBookingResCheckSumUsingPOST(vtPaymentBookingRes: VTPaymentBookingRes, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public requestVtBookingResCheckSumUsingPOST(vtPaymentBookingRes: VTPaymentBookingRes, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vtPaymentBookingRes === null || vtPaymentBookingRes === undefined) {
            throw new Error('Required parameter vtPaymentBookingRes was null or undefined when calling requestVtBookingResCheckSumUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/viettel/request-payment-booking-res`,
            vtPaymentBookingRes,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * saveMerchantDataToCache
     * 
     * @param merchantData merchantData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveMerchantDataToCacheUsingPOST(merchantData: MerchantData, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public saveMerchantDataToCacheUsingPOST(merchantData: MerchantData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public saveMerchantDataToCacheUsingPOST(merchantData: MerchantData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public saveMerchantDataToCacheUsingPOST(merchantData: MerchantData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (merchantData === null || merchantData === undefined) {
            throw new Error('Required parameter merchantData was null or undefined when calling saveMerchantDataToCacheUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/payment/save-merchant-data`,
            merchantData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * storePaymentAuthSecKey
     * 
     * @param paymentAuthSecKey paymentAuthSecKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public storePaymentAuthSecKeyUsingPOST(paymentAuthSecKey: PaymentBaseRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public storePaymentAuthSecKeyUsingPOST(paymentAuthSecKey: PaymentBaseRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public storePaymentAuthSecKeyUsingPOST(paymentAuthSecKey: PaymentBaseRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public storePaymentAuthSecKeyUsingPOST(paymentAuthSecKey: PaymentBaseRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentAuthSecKey === null || paymentAuthSecKey === undefined) {
            throw new Error('Required parameter paymentAuthSecKey was null or undefined when calling storePaymentAuthSecKeyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfstring>(`${this.basePath}/api/payment/store-payment-auth-sec`,
            paymentAuthSecKey,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateTransactionKredivo
     * 
     * @param updateReq updateReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTransactionKredivoUsingPOST(updateReq: KrUpdateReq, observe?: 'body', reportProgress?: boolean): Observable<KrUpdateRes>;
    public updateTransactionKredivoUsingPOST(updateReq: KrUpdateReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KrUpdateRes>>;
    public updateTransactionKredivoUsingPOST(updateReq: KrUpdateReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KrUpdateRes>>;
    public updateTransactionKredivoUsingPOST(updateReq: KrUpdateReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateReq === null || updateReq === undefined) {
            throw new Error('Required parameter updateReq was null or undefined when calling updateTransactionKredivoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<KrUpdateRes>(`${this.basePath}/api/payment/kredivo/update-transaction`,
            updateReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateBooking
     * 
     * @param vtValidationBookingDTO vtValidationBookingDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateBookingUsingPOST(vtValidationBookingDTO: VTValidationBookingDTO, observe?: 'body', reportProgress?: boolean): Observable<VTValidationBookingDTO>;
    public validateBookingUsingPOST(vtValidationBookingDTO: VTValidationBookingDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VTValidationBookingDTO>>;
    public validateBookingUsingPOST(vtValidationBookingDTO: VTValidationBookingDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VTValidationBookingDTO>>;
    public validateBookingUsingPOST(vtValidationBookingDTO: VTValidationBookingDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (vtValidationBookingDTO === null || vtValidationBookingDTO === undefined) {
            throw new Error('Required parameter vtValidationBookingDTO was null or undefined when calling validateBookingUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VTValidationBookingDTO>(`${this.basePath}/api/viettel/validate-booking`,
            vtValidationBookingDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * voidPayment
     * 
     * @param voidPaymentReq voidPaymentReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public voidPaymentUsingPOST(voidPaymentReq: VoidPaymentReq, observe?: 'body', reportProgress?: boolean): Observable<VoidPaymentRes>;
    public voidPaymentUsingPOST(voidPaymentReq: VoidPaymentReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VoidPaymentRes>>;
    public voidPaymentUsingPOST(voidPaymentReq: VoidPaymentReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VoidPaymentRes>>;
    public voidPaymentUsingPOST(voidPaymentReq: VoidPaymentReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (voidPaymentReq === null || voidPaymentReq === undefined) {
            throw new Error('Required parameter voidPaymentReq was null or undefined when calling voidPaymentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<VoidPaymentRes>(`${this.basePath}/api/payment/void-payment`,
            voidPaymentReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * zaloCheckOrderStatus
     * 
     * @param appTransId appTransId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zaloCheckOrderStatusUsingPOST(appTransId: string, observe?: 'body', reportProgress?: boolean): Observable<ZaloOrderStatusResponse>;
    public zaloCheckOrderStatusUsingPOST(appTransId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ZaloOrderStatusResponse>>;
    public zaloCheckOrderStatusUsingPOST(appTransId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ZaloOrderStatusResponse>>;
    public zaloCheckOrderStatusUsingPOST(appTransId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (appTransId === null || appTransId === undefined) {
            throw new Error('Required parameter appTransId was null or undefined when calling zaloCheckOrderStatusUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ZaloOrderStatusResponse>(`${this.basePath}/api/payment/zalo-check-order-status`,
            appTransId,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
