/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TourBrandDTO } from './tourBrandDTO';
import { TourCountryDTO } from './tourCountryDTO';
import { TourDestinationDTO } from './tourDestinationDTO';
import { TourLocationDTO } from './tourLocationDTO';


export interface TourActivityDTO { 
    additionalInformation?: string;
    brand?: TourBrandDTO;
    currency?: TourActivityDTO.CurrencyEnum;
    destinations?: Array<TourDestinationDTO>;
    howToUser?: string;
    id?: number;
    importantInformation?: string;
    meetUpAddress?: string;
    meetUpCountry?: TourCountryDTO;
    meetUpDestination?: TourDestinationDTO;
    meetUpInformation?: string;
    meetUpLocation?: TourLocationDTO;
    name?: string;
    note?: string;
    overview?: string;
    policy?: string;
    status?: boolean;
    supplierId?: number;
}
export namespace TourActivityDTO {
    export type CurrencyEnum = 'VND' | 'USD';
    export const CurrencyEnum = {
        VND: 'VND' as CurrencyEnum,
        USD: 'USD' as CurrencyEnum
    };
}
