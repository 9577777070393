import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AgentAccountHistoryResourceService } from './api/agentAccountHistoryResource.service';
import { AgentAccountResourceService } from './api/agentAccountResource.service';
import { AgentAccountTransactionResourceService } from './api/agentAccountTransactionResource.service';
import { BankTransferHistoryResourceService } from './api/bankTransferHistoryResource.service';
import { CreditCardResourceService } from './api/creditCardResource.service';
import { MarkupExceptionResourceService } from './api/markupExceptionResource.service';
import { MarkupModelReindexResourceService } from './api/markupModelReindexResource.service';
import { MarkupModelResourceService } from './api/markupModelResource.service';
import { MarkupResourceService } from './api/markupResource.service';
import { MarkupZoneLocationResourceService } from './api/markupZoneLocationResource.service';
import { MarkupZoneResourceService } from './api/markupZoneResource.service';
import { PaymentResourceService } from './api/paymentResource.service';
import { PricingScheduleResourceService } from './api/pricingScheduleResource.service';
import { ProfileInfoResourceService } from './api/profileInfoResource.service';
import { PromotionExceptionResourceService } from './api/promotionExceptionResource.service';
import { PromotionMetaSatisfyResourceService } from './api/promotionMetaSatisfyResource.service';
import { PromotionResourceService } from './api/promotionResource.service';
import { VoucherResourceService } from './api/voucherResource.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AgentAccountHistoryResourceService,
    AgentAccountResourceService,
    AgentAccountTransactionResourceService,
    BankTransferHistoryResourceService,
    CreditCardResourceService,
    MarkupExceptionResourceService,
    MarkupModelReindexResourceService,
    MarkupModelResourceService,
    MarkupResourceService,
    MarkupZoneLocationResourceService,
    MarkupZoneResourceService,
    PaymentResourceService,
    PricingScheduleResourceService,
    ProfileInfoResourceService,
    PromotionExceptionResourceService,
    PromotionMetaSatisfyResourceService,
    PromotionResourceService,
    VoucherResourceService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('B2cApiClientModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
