import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FilterOptionsPartialState } from './filter-options.reducer';
import {
  FilterOptionsActionTypes,
  ComboVJFilterOptionsLoadError,
  ComboVJLoadFilterOptions
} from './filter-options.actions';
import { map } from 'rxjs/operators';
import { HotelResourceV3Service } from '@gtd/b2c-client';

@Injectable()
export class FilterOptionsEffects {
  @Effect() loadComboVJFilterOptions$ = this.dataPersistence.fetch(
    FilterOptionsActionTypes.ComboVJLoadFilterOptions,
    {
      run: (
        action: ComboVJLoadFilterOptions,
        state: FilterOptionsPartialState
      ) => {
        return this.hotelResourceV3Service
          .getFilterOptionsUsingGET(action.payload)
          .pipe(
            map(payload => ({
              type: FilterOptionsActionTypes.ComboVJFilterOptionsLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: ComboVJLoadFilterOptions, error) => {
        console.error('Error', error);
        return new ComboVJFilterOptionsLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private hotelResourceV3Service: HotelResourceV3Service,
    private dataPersistence: DataPersistence<FilterOptionsPartialState>
  ) {}
}
