import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-combo-booking',
  templateUrl: './combo-booking.component.html',
  styleUrls: ['./combo-booking.component.scss']
})
export class ComboBookingComponent implements OnInit {
  @Input() booking;
  @Input() selectedByUser = false;
  @Input() flightNotFound = false;
  constructor() {}

  ngOnInit() {}
}
