export type FlightTypeEnum = 'DOMESTIC' | 'INTERNATIONAL';
export const FlightTypeEnum = {
  DOMESTIC: 'DOMESTIC' as FlightTypeEnum,
  INTERNATIONAL: 'INTERNATIONAL' as FlightTypeEnum
};
export type RoundTypeEnum = 'ONEWAY' | 'ROUNDTRIP';
export const RoundTypeEnum = {
  ONEWAY: 'ONEWAY' as RoundTypeEnum,
  ROUNDTRIP: 'ROUNDTRIP' as RoundTypeEnum
};

export type CabinClassNameEnum =
  | 'BUSINESS'
  | 'FIRST'
  | 'PREMIUM'
  | 'ECONOMY'
  | 'PROMO';
export const CabinClassNameEnum = {
  BUSINESS: 'BUSINESS' as CabinClassNameEnum,
  FIRST: 'FIRST' as CabinClassNameEnum,
  PREMIUM: 'PREMIUM' as CabinClassNameEnum,
  ECONOMY: 'ECONOMY' as CabinClassNameEnum,
  PROMO: 'PROMO' as CabinClassNameEnum
};

export type SortAttributeEnum =
  | 'departureDate'
  | 'arrivalDate'
  | 'duration'
  | 'price';
export const SortAttributeEnum = {
  DEPARTURE_DATE: 'departureDate' as SortAttributeEnum,
  ARRIVAL_DATE: 'arrivalDate' as SortAttributeEnum,
  DURATION: 'duration' as SortAttributeEnum,
  PRICE: 'price' as SortAttributeEnum,
  DEFAULT_COMBO_ASC: 'defaultCombo' as SortAttributeEnum
};

export type SortTypeEnum = 'asc' | 'desc';
export const SortTypeEnum = {
  ASC: 'asc' as SortTypeEnum,
  DESC: 'desc' as SortTypeEnum
};

export type AdultTypeEnum = 'ADT' | 'CHD' | 'INF';
export const AdultTypeEnum = {
  ADT: 'ADT' as AdultTypeEnum,
  CHD: 'CHD' as AdultTypeEnum,
  INF: 'INF' as AdultTypeEnum
};
