import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { LowestPriceFlightsPartialState } from './lowest-price-flights.reducer';
import {
  ComboVJLoadLowestPriceFlights,
  ComboVJLowestPriceFlightsLoadError,
  LowestPriceFlightsActionTypes
} from './lowest-price-flights.actions';
import { AirTicketsResourceService } from '@gtd/b2c-client';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { pipe } from 'rxjs';

@Injectable()
export class LowestPriceFlightsEffects {
  //   @Effect() loadLowestPriceFlights$ = this.dataPersistence.fetch(
  //     LowestPriceFlightsActionTypes.LoadLowestPriceFlights,
  //     {
  //       run: (
  //         action: LoadLowestPriceFlights,
  //         state: LowestPriceFlightsPartialState
  //       ) => {
  //         // Your custom REST 'load' logic goes here. For now just return an empty list...
  //         return this.airTicketsResourceService
  //           .getAirFilterOptionsUsingPOST(action.payload)
  //           .pipe(
  //             map(result => ({
  //               type: LowestPriceFlightsActionTypes.LowestPriceFlightsLoaded,
  //               payload: result
  //             }))
  //           );
  //       },

  //       onError: (action: LoadLowestPriceFlights, error) => {
  //         console.error('Error', error);
  //         return new LowestPriceFlightsLoadError(error);
  //       }
  //     }
  //   );

  @Effect() loadComboVJLowestPriceFlights$ = this.actions$.pipe(
    ofType<ComboVJLoadLowestPriceFlights>(
      LowestPriceFlightsActionTypes.ComboVJLoadLowestPriceFlights
    ),
    pipe(
      map(action => action.payload),
      switchMap(payload => {
        //   localStorage.removeItem('flightBooking');
        return this.airTicketsResourceService
          .getAirFilterOptionsUsingPOST(payload)
          .pipe(
            map(
              result => ({
                type:
                  LowestPriceFlightsActionTypes.ComboVJLowestPriceFlightsLoaded,
                payload: result
              }),
              catchError(error => {
                console.log(error);
                return null;
              })
            )
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<LowestPriceFlightsPartialState>,
    private airTicketsResourceService: AirTicketsResourceService
  ) {}
}
