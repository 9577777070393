/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AirBookFlightRQ } from '../model/airBookFlightRQ';
import { AirBookedFareRuleRS } from '../model/airBookedFareRuleRS';
import { AirFareRuleRQ } from '../model/airFareRuleRQ';
import { AirFareRuleRS } from '../model/airFareRuleRS';
import { AirGroupPricedItineraryRS } from '../model/airGroupPricedItineraryRS';
import { AirItineraryFilterRS } from '../model/airItineraryFilterRS';
import { AirLowFareFilterVM } from '../model/airLowFareFilterVM';
import { AirLowFareSearchRS } from '../model/airLowFareSearchRS';
import { AirLowFareSearchResultVM } from '../model/airLowFareSearchResultVM';
import { AirRevalidateRQ } from '../model/airRevalidateRQ';
import { AirRevalidateRS } from '../model/airRevalidateRS';
import { AirRoundTripRevalidateRQ } from '../model/airRoundTripRevalidateRQ';
import { AirRoundTripRevalidateRS } from '../model/airRoundTripRevalidateRS';
import { AirSsrOfferRS } from '../model/airSsrOfferRS';
import { BookingCodeRS } from '../model/bookingCodeRS';
import { BookingNumberRQ } from '../model/bookingNumberRQ';
import { BookingTags } from '../model/bookingTags';
import { BookingTravellerRQ } from '../model/bookingTravellerRQ';
import { GTDMFRequest } from '../model/gTDMFRequest';
import { GTDMFResponse } from '../model/gTDMFResponse';
import { OtaResultOfstring } from '../model/otaResultOfstring';
import { TicketDraftBookingRS } from '../model/ticketDraftBookingRS';
import { TicketDraftBookingVM } from '../model/ticketDraftBookingVM';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AirTicketsResourceService {

    protected basePath = 'https://10.7.71.20:8588/b2c_gateway';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addBookingTraveller
     * 
     * @param bookingTraveller bookingTraveller
     * @param xIbeReqSaleChannel X-ibe-req-sale-channel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addBookingTravellerUsingPOST(bookingTraveller: BookingTravellerRQ, xIbeReqSaleChannel?: string, observe?: 'body', reportProgress?: boolean): Observable<BookingCodeRS>;
    public addBookingTravellerUsingPOST(bookingTraveller: BookingTravellerRQ, xIbeReqSaleChannel?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodeRS>>;
    public addBookingTravellerUsingPOST(bookingTraveller: BookingTravellerRQ, xIbeReqSaleChannel?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodeRS>>;
    public addBookingTravellerUsingPOST(bookingTraveller: BookingTravellerRQ, xIbeReqSaleChannel?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingTraveller === null || bookingTraveller === undefined) {
            throw new Error('Required parameter bookingTraveller was null or undefined when calling addBookingTravellerUsingPOST.');
        }


        let headers = this.defaultHeaders;
        if (xIbeReqSaleChannel !== undefined && xIbeReqSaleChannel !== null) {
            headers = headers.set('X-ibe-req-sale-channel', String(xIbeReqSaleChannel));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodeRS>(`${this.basePath}/api/air-tickets/add-booking-traveller`,
            bookingTraveller,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * changeDraftBookingOwner
     * 
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeDraftBookingOwnerUsingPOST(bookingNumber: BookingNumberRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public changeDraftBookingOwnerUsingPOST(bookingNumber: BookingNumberRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public changeDraftBookingOwnerUsingPOST(bookingNumber: BookingNumberRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public changeDraftBookingOwnerUsingPOST(bookingNumber: BookingNumberRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling changeDraftBookingOwnerUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfstring>(`${this.basePath}/api/air-tickets/change-draft-booking-owner`,
            bookingNumber,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createDraftBookingLite
     * 
     * @param ticketDraftBooking ticketDraftBooking
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDraftBookingLiteUsingPOST(ticketDraftBooking: TicketDraftBookingVM, observe?: 'body', reportProgress?: boolean): Observable<TicketDraftBookingRS>;
    public createDraftBookingLiteUsingPOST(ticketDraftBooking: TicketDraftBookingVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TicketDraftBookingRS>>;
    public createDraftBookingLiteUsingPOST(ticketDraftBooking: TicketDraftBookingVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TicketDraftBookingRS>>;
    public createDraftBookingLiteUsingPOST(ticketDraftBooking: TicketDraftBookingVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketDraftBooking === null || ticketDraftBooking === undefined) {
            throw new Error('Required parameter ticketDraftBooking was null or undefined when calling createDraftBookingLiteUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TicketDraftBookingRS>(`${this.basePath}/api/air-tickets/draft-booking-lite`,
            ticketDraftBooking,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createDraftBooking
     * 
     * @param ticketDraftBooking ticketDraftBooking
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDraftBookingUsingPOST(ticketDraftBooking: TicketDraftBookingVM, observe?: 'body', reportProgress?: boolean): Observable<TicketDraftBookingRS>;
    public createDraftBookingUsingPOST(ticketDraftBooking: TicketDraftBookingVM, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TicketDraftBookingRS>>;
    public createDraftBookingUsingPOST(ticketDraftBooking: TicketDraftBookingVM, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TicketDraftBookingRS>>;
    public createDraftBookingUsingPOST(ticketDraftBooking: TicketDraftBookingVM, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketDraftBooking === null || ticketDraftBooking === undefined) {
            throw new Error('Required parameter ticketDraftBooking was null or undefined when calling createDraftBookingUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TicketDraftBookingRS>(`${this.basePath}/api/air-tickets/draft-booking`,
            ticketDraftBooking,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * filterAirLowFare
     * 
     * @param airLowFareFilterRQ airLowFareFilterRQ
     * @param includeEquivfare include-equivfare
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public filterAirLowFareUsingPOST(airLowFareFilterRQ: AirLowFareFilterVM, includeEquivfare?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<AirLowFareSearchRS>;
    public filterAirLowFareUsingPOST(airLowFareFilterRQ: AirLowFareFilterVM, includeEquivfare?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirLowFareSearchRS>>;
    public filterAirLowFareUsingPOST(airLowFareFilterRQ: AirLowFareFilterVM, includeEquivfare?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirLowFareSearchRS>>;
    public filterAirLowFareUsingPOST(airLowFareFilterRQ: AirLowFareFilterVM, includeEquivfare?: boolean, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (airLowFareFilterRQ === null || airLowFareFilterRQ === undefined) {
            throw new Error('Required parameter airLowFareFilterRQ was null or undefined when calling filterAirLowFareUsingPOST.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeEquivfare !== undefined && includeEquivfare !== null) {
            queryParameters = queryParameters.set('include-equivfare', <any>includeEquivfare);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AirLowFareSearchRS>(`${this.basePath}/api/air-tickets/filter-availability`,
            airLowFareFilterRQ,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAirFilterOptions
     * 
     * @param airSearchId airSearchId
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAirFilterOptionsUsingPOST(airSearchId: AirLowFareFilterVM, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<AirItineraryFilterRS>;
    public getAirFilterOptionsUsingPOST(airSearchId: AirLowFareFilterVM, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirItineraryFilterRS>>;
    public getAirFilterOptionsUsingPOST(airSearchId: AirLowFareFilterVM, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirItineraryFilterRS>>;
    public getAirFilterOptionsUsingPOST(airSearchId: AirLowFareFilterVM, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (airSearchId === null || airSearchId === undefined) {
            throw new Error('Required parameter airSearchId was null or undefined when calling getAirFilterOptionsUsingPOST.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AirItineraryFilterRS>(`${this.basePath}/api/air-tickets/filter-options`,
            airSearchId,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingTagsByName
     * 
     * @param name name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingTagsByNameUsingGET(name: string, observe?: 'body', reportProgress?: boolean): Observable<BookingTags>;
    public getBookingTagsByNameUsingGET(name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingTags>>;
    public getBookingTagsByNameUsingGET(name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingTags>>;
    public getBookingTagsByNameUsingGET(name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling getBookingTagsByNameUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingTags>(`${this.basePath}/api/air-tickets/booking-tags/tag-name/${encodeURIComponent(String(name))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getGroupPricedItinerary
     * 
     * @param id id
     * @param airSearchId airSearchId
     * @param includeEquivfare include-equivfare
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGroupPricedItineraryUsingPOST(id: string, airSearchId: AirLowFareFilterVM, includeEquivfare?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<AirGroupPricedItineraryRS>;
    public getGroupPricedItineraryUsingPOST(id: string, airSearchId: AirLowFareFilterVM, includeEquivfare?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirGroupPricedItineraryRS>>;
    public getGroupPricedItineraryUsingPOST(id: string, airSearchId: AirLowFareFilterVM, includeEquivfare?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirGroupPricedItineraryRS>>;
    public getGroupPricedItineraryUsingPOST(id: string, airSearchId: AirLowFareFilterVM, includeEquivfare?: boolean, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getGroupPricedItineraryUsingPOST.');
        }

        if (airSearchId === null || airSearchId === undefined) {
            throw new Error('Required parameter airSearchId was null or undefined when calling getGroupPricedItineraryUsingPOST.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeEquivfare !== undefined && includeEquivfare !== null) {
            queryParameters = queryParameters.set('include-equivfare', <any>includeEquivfare);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AirGroupPricedItineraryRS>(`${this.basePath}/api/air-tickets/group-itinerary/${encodeURIComponent(String(id))}`,
            airSearchId,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * lowFareSearchAsync
     * 
     * @param originCode origin_code
     * @param destinationCode destination_code
     * @param departureDate Date Format is MM-dd-YYYY
     * @param time time
     * @param key key
     * @param returntureDate Date Format is MM-dd-YYYY
     * @param cabinClass cabin_class
     * @param routeType ROUNDTRIP or ONEWAY
     * @param adutsQtt aduts_qtt
     * @param childrenQtt children_qtt
     * @param infantsQtt infants_qtt
     * @param suppliers suppliers
     * @param includeEquivfare include-equivfare
     * @param searchBranch search_branch
     * @param combo combo
     * @param skipFilter skip_filter
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lowFareSearchAsyncUsingGET(originCode: string, destinationCode: string, departureDate: string, time: string, key: string, returntureDate?: string, cabinClass?: string, routeType?: string, adutsQtt?: number, childrenQtt?: number, infantsQtt?: number, suppliers?: Array<string>, includeEquivfare?: boolean, searchBranch?: string, combo?: boolean, skipFilter?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<AirLowFareSearchResultVM>;
    public lowFareSearchAsyncUsingGET(originCode: string, destinationCode: string, departureDate: string, time: string, key: string, returntureDate?: string, cabinClass?: string, routeType?: string, adutsQtt?: number, childrenQtt?: number, infantsQtt?: number, suppliers?: Array<string>, includeEquivfare?: boolean, searchBranch?: string, combo?: boolean, skipFilter?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirLowFareSearchResultVM>>;
    public lowFareSearchAsyncUsingGET(originCode: string, destinationCode: string, departureDate: string, time: string, key: string, returntureDate?: string, cabinClass?: string, routeType?: string, adutsQtt?: number, childrenQtt?: number, infantsQtt?: number, suppliers?: Array<string>, includeEquivfare?: boolean, searchBranch?: string, combo?: boolean, skipFilter?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirLowFareSearchResultVM>>;
    public lowFareSearchAsyncUsingGET(originCode: string, destinationCode: string, departureDate: string, time: string, key: string, returntureDate?: string, cabinClass?: string, routeType?: string, adutsQtt?: number, childrenQtt?: number, infantsQtt?: number, suppliers?: Array<string>, includeEquivfare?: boolean, searchBranch?: string, combo?: boolean, skipFilter?: boolean, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (originCode === null || originCode === undefined) {
            throw new Error('Required parameter originCode was null or undefined when calling lowFareSearchAsyncUsingGET.');
        }

        if (destinationCode === null || destinationCode === undefined) {
            throw new Error('Required parameter destinationCode was null or undefined when calling lowFareSearchAsyncUsingGET.');
        }

        if (departureDate === null || departureDate === undefined) {
            throw new Error('Required parameter departureDate was null or undefined when calling lowFareSearchAsyncUsingGET.');
        }

        if (time === null || time === undefined) {
            throw new Error('Required parameter time was null or undefined when calling lowFareSearchAsyncUsingGET.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling lowFareSearchAsyncUsingGET.');
        }















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (originCode !== undefined && originCode !== null) {
            queryParameters = queryParameters.set('origin_code', <any>originCode);
        }
        if (destinationCode !== undefined && destinationCode !== null) {
            queryParameters = queryParameters.set('destination_code', <any>destinationCode);
        }
        if (departureDate !== undefined && departureDate !== null) {
            queryParameters = queryParameters.set('departure_date', <any>departureDate);
        }
        if (returntureDate !== undefined && returntureDate !== null) {
            queryParameters = queryParameters.set('returnture_date', <any>returntureDate);
        }
        if (cabinClass !== undefined && cabinClass !== null) {
            queryParameters = queryParameters.set('cabin_class', <any>cabinClass);
        }
        if (routeType !== undefined && routeType !== null) {
            queryParameters = queryParameters.set('route_type', <any>routeType);
        }
        if (adutsQtt !== undefined && adutsQtt !== null) {
            queryParameters = queryParameters.set('aduts_qtt', <any>adutsQtt);
        }
        if (childrenQtt !== undefined && childrenQtt !== null) {
            queryParameters = queryParameters.set('children_qtt', <any>childrenQtt);
        }
        if (infantsQtt !== undefined && infantsQtt !== null) {
            queryParameters = queryParameters.set('infants_qtt', <any>infantsQtt);
        }
        if (suppliers) {
            suppliers.forEach((element) => {
                queryParameters = queryParameters.append('suppliers', <any>element);
            })
        }
        if (time !== undefined && time !== null) {
            queryParameters = queryParameters.set('time', <any>time);
        }
        if (key !== undefined && key !== null) {
            queryParameters = queryParameters.set('key', <any>key);
        }
        if (includeEquivfare !== undefined && includeEquivfare !== null) {
            queryParameters = queryParameters.set('include-equivfare', <any>includeEquivfare);
        }
        if (searchBranch !== undefined && searchBranch !== null) {
            queryParameters = queryParameters.set('search_branch', <any>searchBranch);
        }
        if (combo !== undefined && combo !== null) {
            queryParameters = queryParameters.set('combo', <any>combo);
        }
        if (skipFilter !== undefined && skipFilter !== null) {
            queryParameters = queryParameters.set('skip_filter', <any>skipFilter);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AirLowFareSearchResultVM>(`${this.basePath}/api/air-tickets/low-fare-search-async`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * lowFareSearchInternationalAsync
     * 
     * @param originCode origin_code
     * @param destinationCode destination_code
     * @param departureDate Date Format is MM-dd-YYYY
     * @param time time
     * @param key key
     * @param returntureDate Date Format is MM-dd-YYYY
     * @param cabinClass cabin_class
     * @param routeType ROUNDTRIP or ONEWAY
     * @param adutsQtt aduts_qtt
     * @param childrenQtt children_qtt
     * @param infantsQtt infants_qtt
     * @param includeEquivfare include-equivfare
     * @param searchBranch search_branch
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lowFareSearchInternationalAsyncUsingGET(originCode: string, destinationCode: string, departureDate: string, time: string, key: string, returntureDate?: string, cabinClass?: string, routeType?: string, adutsQtt?: number, childrenQtt?: number, infantsQtt?: number, includeEquivfare?: boolean, searchBranch?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<AirLowFareSearchResultVM>;
    public lowFareSearchInternationalAsyncUsingGET(originCode: string, destinationCode: string, departureDate: string, time: string, key: string, returntureDate?: string, cabinClass?: string, routeType?: string, adutsQtt?: number, childrenQtt?: number, infantsQtt?: number, includeEquivfare?: boolean, searchBranch?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirLowFareSearchResultVM>>;
    public lowFareSearchInternationalAsyncUsingGET(originCode: string, destinationCode: string, departureDate: string, time: string, key: string, returntureDate?: string, cabinClass?: string, routeType?: string, adutsQtt?: number, childrenQtt?: number, infantsQtt?: number, includeEquivfare?: boolean, searchBranch?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirLowFareSearchResultVM>>;
    public lowFareSearchInternationalAsyncUsingGET(originCode: string, destinationCode: string, departureDate: string, time: string, key: string, returntureDate?: string, cabinClass?: string, routeType?: string, adutsQtt?: number, childrenQtt?: number, infantsQtt?: number, includeEquivfare?: boolean, searchBranch?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (originCode === null || originCode === undefined) {
            throw new Error('Required parameter originCode was null or undefined when calling lowFareSearchInternationalAsyncUsingGET.');
        }

        if (destinationCode === null || destinationCode === undefined) {
            throw new Error('Required parameter destinationCode was null or undefined when calling lowFareSearchInternationalAsyncUsingGET.');
        }

        if (departureDate === null || departureDate === undefined) {
            throw new Error('Required parameter departureDate was null or undefined when calling lowFareSearchInternationalAsyncUsingGET.');
        }

        if (time === null || time === undefined) {
            throw new Error('Required parameter time was null or undefined when calling lowFareSearchInternationalAsyncUsingGET.');
        }

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling lowFareSearchInternationalAsyncUsingGET.');
        }












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (originCode !== undefined && originCode !== null) {
            queryParameters = queryParameters.set('origin_code', <any>originCode);
        }
        if (destinationCode !== undefined && destinationCode !== null) {
            queryParameters = queryParameters.set('destination_code', <any>destinationCode);
        }
        if (departureDate !== undefined && departureDate !== null) {
            queryParameters = queryParameters.set('departure_date', <any>departureDate);
        }
        if (returntureDate !== undefined && returntureDate !== null) {
            queryParameters = queryParameters.set('returnture_date', <any>returntureDate);
        }
        if (cabinClass !== undefined && cabinClass !== null) {
            queryParameters = queryParameters.set('cabin_class', <any>cabinClass);
        }
        if (routeType !== undefined && routeType !== null) {
            queryParameters = queryParameters.set('route_type', <any>routeType);
        }
        if (adutsQtt !== undefined && adutsQtt !== null) {
            queryParameters = queryParameters.set('aduts_qtt', <any>adutsQtt);
        }
        if (childrenQtt !== undefined && childrenQtt !== null) {
            queryParameters = queryParameters.set('children_qtt', <any>childrenQtt);
        }
        if (infantsQtt !== undefined && infantsQtt !== null) {
            queryParameters = queryParameters.set('infants_qtt', <any>infantsQtt);
        }
        if (time !== undefined && time !== null) {
            queryParameters = queryParameters.set('time', <any>time);
        }
        if (key !== undefined && key !== null) {
            queryParameters = queryParameters.set('key', <any>key);
        }
        if (includeEquivfare !== undefined && includeEquivfare !== null) {
            queryParameters = queryParameters.set('include-equivfare', <any>includeEquivfare);
        }
        if (searchBranch !== undefined && searchBranch !== null) {
            queryParameters = queryParameters.set('search_branch', <any>searchBranch);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AirLowFareSearchResultVM>(`${this.basePath}/api/air-tickets/low-fare-search-international-async`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieveAirFareRules
     * 
     * @param bookingNumber bookingNumber
     * @param language language
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveAirFareRulesUsingGET(bookingNumber: string, language?: string, observe?: 'body', reportProgress?: boolean): Observable<AirBookedFareRuleRS>;
    public retrieveAirFareRulesUsingGET(bookingNumber: string, language?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirBookedFareRuleRS>>;
    public retrieveAirFareRulesUsingGET(bookingNumber: string, language?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirBookedFareRuleRS>>;
    public retrieveAirFareRulesUsingGET(bookingNumber: string, language?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling retrieveAirFareRulesUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AirBookedFareRuleRS>(`${this.basePath}/api/air-tickets/farerules/${encodeURIComponent(String(bookingNumber))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieveFareRules
     * 
     * @param airFareRuleRQ airFareRuleRQ
     * @param language language
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveFareRulesUsingPOST(airFareRuleRQ: AirFareRuleRQ, language?: string, observe?: 'body', reportProgress?: boolean): Observable<AirFareRuleRS>;
    public retrieveFareRulesUsingPOST(airFareRuleRQ: AirFareRuleRQ, language?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirFareRuleRS>>;
    public retrieveFareRulesUsingPOST(airFareRuleRQ: AirFareRuleRQ, language?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirFareRuleRS>>;
    public retrieveFareRulesUsingPOST(airFareRuleRQ: AirFareRuleRQ, language?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (airFareRuleRQ === null || airFareRuleRQ === undefined) {
            throw new Error('Required parameter airFareRuleRQ was null or undefined when calling retrieveFareRulesUsingPOST.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AirFareRuleRS>(`${this.basePath}/api/air-tickets/farerules`,
            airFareRuleRQ,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * retrieveSsrOffer
     * 
     * @param bookingNumber bookingNumber
     * @param language language
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveSsrOfferUsingGET(bookingNumber: string, language?: string, observe?: 'body', reportProgress?: boolean): Observable<AirSsrOfferRS>;
    public retrieveSsrOfferUsingGET(bookingNumber: string, language?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirSsrOfferRS>>;
    public retrieveSsrOfferUsingGET(bookingNumber: string, language?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirSsrOfferRS>>;
    public retrieveSsrOfferUsingGET(bookingNumber: string, language?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling retrieveSsrOfferUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AirSsrOfferRS>(`${this.basePath}/api/air-tickets/ssr-offer/${encodeURIComponent(String(bookingNumber))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * revalidateAirFare
     * 
     * @param airRevalidateRQ airRevalidateRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revalidateAirFareUsingPOST(airRevalidateRQ: AirRevalidateRQ, observe?: 'body', reportProgress?: boolean): Observable<AirRevalidateRS>;
    public revalidateAirFareUsingPOST(airRevalidateRQ: AirRevalidateRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirRevalidateRS>>;
    public revalidateAirFareUsingPOST(airRevalidateRQ: AirRevalidateRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirRevalidateRS>>;
    public revalidateAirFareUsingPOST(airRevalidateRQ: AirRevalidateRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (airRevalidateRQ === null || airRevalidateRQ === undefined) {
            throw new Error('Required parameter airRevalidateRQ was null or undefined when calling revalidateAirFareUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AirRevalidateRS>(`${this.basePath}/api/air-tickets/revalidate`,
            airRevalidateRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * ticketOrder
     * 
     * @param airTicketOrderRQ airTicketOrderRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ticketOrderUsingPOST(airTicketOrderRQ: AirBookFlightRQ, observe?: 'body', reportProgress?: boolean): Observable<BookingCodeRS>;
    public ticketOrderUsingPOST(airTicketOrderRQ: AirBookFlightRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodeRS>>;
    public ticketOrderUsingPOST(airTicketOrderRQ: AirBookFlightRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodeRS>>;
    public ticketOrderUsingPOST(airTicketOrderRQ: AirBookFlightRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (airTicketOrderRQ === null || airTicketOrderRQ === undefined) {
            throw new Error('Required parameter airTicketOrderRQ was null or undefined when calling ticketOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodeRS>(`${this.basePath}/api/air-tickets/order-tickets`,
            airTicketOrderRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBookingStatus
     * 
     * @param gtdMFRequest gtdMFRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingStatusUsingPOST(gtdMFRequest: GTDMFRequest, observe?: 'body', reportProgress?: boolean): Observable<GTDMFResponse>;
    public updateBookingStatusUsingPOST(gtdMFRequest: GTDMFRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GTDMFResponse>>;
    public updateBookingStatusUsingPOST(gtdMFRequest: GTDMFRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GTDMFResponse>>;
    public updateBookingStatusUsingPOST(gtdMFRequest: GTDMFRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (gtdMFRequest === null || gtdMFRequest === undefined) {
            throw new Error('Required parameter gtdMFRequest was null or undefined when calling updateBookingStatusUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GTDMFResponse>(`${this.basePath}/api/air-tickets/update-booking-status`,
            gtdMFRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validateAirTickets
     * 
     * @param airRevalidateRQ airRevalidateRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateAirTicketsUsingPOST(airRevalidateRQ: AirRoundTripRevalidateRQ, observe?: 'body', reportProgress?: boolean): Observable<AirRoundTripRevalidateRS>;
    public validateAirTicketsUsingPOST(airRevalidateRQ: AirRoundTripRevalidateRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AirRoundTripRevalidateRS>>;
    public validateAirTicketsUsingPOST(airRevalidateRQ: AirRoundTripRevalidateRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AirRoundTripRevalidateRS>>;
    public validateAirTicketsUsingPOST(airRevalidateRQ: AirRoundTripRevalidateRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (airRevalidateRQ === null || airRevalidateRQ === undefined) {
            throw new Error('Required parameter airRevalidateRQ was null or undefined when calling validateAirTicketsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AirRoundTripRevalidateRS>(`${this.basePath}/api/air-tickets/validation`,
            airRevalidateRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
