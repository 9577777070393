import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NOTIFICATIONS_FEATURE_KEY,
  NotificationsState
} from './notifications.reducer';

// Lookup the 'Notifications' feature state managed by NgRx
const getNotificationsState = createFeatureSelector<NotificationsState>(
  NOTIFICATIONS_FEATURE_KEY
);

const getLoaded = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.loaded
);
const getError = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.error
);

const getAllNotifications = createSelector(
  getNotificationsState,
  getLoaded,
  (state: NotificationsState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.selectedId
);
const getSelectedNotifications = createSelector(
  getAllNotifications,
  getSelectedId,
  (notifications, id) => {
    return notifications ? Object.assign({}, notifications) : undefined;
  }
);

export const notificationsQuery = {
  getLoaded,
  getError,
  getAllNotifications,
  getSelectedNotifications
};
