import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  SEARCHBOOKINGTRANSACTIONS_FEATURE_KEY,
  SearchBookingTransactionsState
} from './search-booking-transactions.reducer';
import {BookingSearchResultDTO, Sort} from "../../lib";
import {BookingDTO} from "@gtd/api-services/booking-service";

// Lookup the 'SearchBookingTransactions' feature state managed by NgRx
const getSearchBookingTransactionsState = createFeatureSelector<
  SearchBookingTransactionsState
>(SEARCHBOOKINGTRANSACTIONS_FEATURE_KEY);

const getLoaded = createSelector(
  getSearchBookingTransactionsState,
  (state: SearchBookingTransactionsState) => state.loaded
);
const getError = createSelector(
  getSearchBookingTransactionsState,
  (state: SearchBookingTransactionsState) => state.error
);

const getAllSearchBookingTransactions = createSelector(
  getSearchBookingTransactionsState,
  getLoaded,
  (state: SearchBookingTransactionsState, isLoaded) => {
    return isLoaded ? Mapping.prototype.mappingTransactionReturn(state.list) : null;
  }
);
const getSelectedId = createSelector(
  getSearchBookingTransactionsState,
  (state: SearchBookingTransactionsState) => state.selectedId
);
const getSelectedSearchBookingTransactions = createSelector(
  getAllSearchBookingTransactions,
  getSelectedId,
  (searchBookingTransactions, id) => {
    return searchBookingTransactions ? Object.assign({}, searchBookingTransactions) : undefined;
  }
);

export const searchBookingTransactionsQuery = {
  getLoaded,
  getError,
  getAllSearchBookingTransactions,
  getSelectedSearchBookingTransactions
};


export class Mapping {
  mappingTransactionReturn(bookingSearchResultDTO: BookingSearchResultDTO): BookingTransactionResults {
    const bookingDTOs: BookingDTO[] = [];
    if(
      bookingSearchResultDTO && bookingSearchResultDTO.page
      && bookingSearchResultDTO.page.content
      && bookingSearchResultDTO.page.content.length > 0
    ){
      // tslint:disable-next-line:prefer-const
      let bookingDTO: BookingDTO;
      bookingSearchResultDTO.page.content.filter(transaction => {
        bookingDTOs.push({
          ...bookingDTO,
          ...transaction,
          status: transaction.status,
          issuedStatus: transaction.issuedStatus,
          paymentStatus: transaction.paymentStatus,
          timeToLive: transaction.timeToLive,
          supplierType: transaction.supplierType,
          fromCity: transaction.fromCity,
          fromLocationCode: transaction.fromLocationCode,
          toCity: transaction.toCity,
          toLocationCode: transaction.toLocationCode,
          departureDate: transaction.departureDate,
          returnDate: transaction.returnDate,
          fromLocationName: transaction.fromLocationName,
          toLocationName: transaction.toLocationName,
          bookingNumber: transaction.bookingNumber,
          paymentTotalAmount: transaction.paymentTotalAmount,
          totalFare: transaction.totalFare,
        })
      })
    }
    let bookingTransactionResults: BookingTransactionResults;
    bookingTransactionResults = {
      ...bookingSearchResultDTO.page,
      bookingDTOs: bookingDTOs
    }
    return bookingTransactionResults;
  }
}
export interface BookingTransactionResults {
  bookingDTOs: BookingDTO[];
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  size?: number;
  sort?: Sort;
  totalElements?: number;
  totalPages?: number;
}
