/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface V2MenuitemsBody { 
    /**
     * The title for the object.
     */
    title?: string;
    /**
     * The family of objects originally represented, such as \"post_type\" or \"taxonomy\".
     */
    type?: Array<V2MenuitemsBody.TypeEnum>;
    /**
     * A named status for the object.
     */
    status?: Array<V2MenuitemsBody.StatusEnum>;
    /**
     * The ID for the parent of the object.
     */
    parent?: string;
    /**
     * Text for the title attribute of the link element for this menu item.
     */
    attrTitle?: string;
    /**
     * Class names for the link element of this menu item.
     */
    classes?: string;
    /**
     * The description of this menu item.
     */
    description?: string;
    /**
     * The DB ID of the nav_menu_item that is this item's menu parent, if any, otherwise 0.
     */
    menuOrder?: string;
    /**
     * The type of object originally represented, such as \"category\", \"post\", or \"attachment\".
     */
    object?: string;
    /**
     * The database ID of the original object this menu item represents, for example the ID for posts or the term_id for categories.
     */
    objectId?: string;
    /**
     * The target attribute of the link element for this menu item.
     */
    target?: Array<V2MenuitemsBody.TargetEnum>;
    /**
     * The URL to which this menu item points.
     */
    url?: string;
    /**
     * The XFN relationship expressed in the link of this menu item.
     */
    xfn?: string;
    /**
     * The terms assigned to the object in the nav_menu taxonomy.
     */
    menus?: string;
    /**
     * Meta fields.
     */
    meta?: string;
    /**
     * Expose advanced custom fields.
     */
    acf?: string;
}
export namespace V2MenuitemsBody {
    export type TypeEnum = 'taxonomy' | 'post_type' | 'post_type_archive' | 'custom';
    export const TypeEnum = {
        Taxonomy: 'taxonomy' as TypeEnum,
        PostType: 'post_type' as TypeEnum,
        PostTypeArchive: 'post_type_archive' as TypeEnum,
        Custom: 'custom' as TypeEnum
    };
    export type StatusEnum = 'publish' | 'future' | 'draft' | 'pending' | 'private' | 'acf-disabled';
    export const StatusEnum = {
        Publish: 'publish' as StatusEnum,
        Future: 'future' as StatusEnum,
        Draft: 'draft' as StatusEnum,
        Pending: 'pending' as StatusEnum,
        Private: 'private' as StatusEnum,
        AcfDisabled: 'acf-disabled' as StatusEnum
    };
    export type TargetEnum = '_blank' | '';
    export const TargetEnum = {
        Blank: '_blank' as TargetEnum,
        Empty: '' as TargetEnum
    };
}