/**
 * utilitysrv API
 * utilitysrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { NotifyMessageDTO } from '../model/notifyMessageDTO';
import { NotifyMessageReq } from '../model/notifyMessageReq';
import { PageOfNotifyMessageDTO } from '../model/pageOfNotifyMessageDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class NotifyMessageResourceService {
  protected basePath = 'https://10.7.71.20:8588/utilitysrv';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * createNotifyMessage
   *
   * @param notifyMessageDTO notifyMessageDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createNotifyMessageUsingPOST(
    notifyMessageDTO: NotifyMessageReq,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NotifyMessageDTO>;
  public createNotifyMessageUsingPOST(
    notifyMessageDTO: NotifyMessageReq,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NotifyMessageDTO>>;
  public createNotifyMessageUsingPOST(
    notifyMessageDTO: NotifyMessageReq,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NotifyMessageDTO>>;
  public createNotifyMessageUsingPOST(
    notifyMessageDTO: NotifyMessageReq,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (notifyMessageDTO === null || notifyMessageDTO === undefined) {
      throw new Error(
        'Required parameter notifyMessageDTO was null or undefined when calling createNotifyMessageUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<NotifyMessageDTO>(
      `${this.basePath}/api/notify-messages`,
      notifyMessageDTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * deleteNotifyMessage
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteNotifyMessageUsingDELETE(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteNotifyMessageUsingDELETE(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteNotifyMessageUsingDELETE(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteNotifyMessageUsingDELETE(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteNotifyMessageUsingDELETE.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.basePath}/api/notify-messages/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * findMessageWithRefCode
   *
   * @param messageType messageType
   * @param refCode refCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findMessageWithRefCodeUsingPOST(
    messageType:
      | 'MARKETING'
      | 'PAYMENT_CONFIRM'
      | 'PAYMENT_REMIND'
      | 'COMMIT_CONFIRM'
      | 'REMIND_CHECKIN'
      | 'CONFIRMED_HOLD'
      | 'REFERRAL',
    refCode: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<NotifyMessageDTO>>;
  public findMessageWithRefCodeUsingPOST(
    messageType:
      | 'MARKETING'
      | 'PAYMENT_CONFIRM'
      | 'PAYMENT_REMIND'
      | 'COMMIT_CONFIRM'
      | 'REMIND_CHECKIN'
      | 'CONFIRMED_HOLD'
      | 'REFERRAL',
    refCode: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<NotifyMessageDTO>>>;
  public findMessageWithRefCodeUsingPOST(
    messageType:
      | 'MARKETING'
      | 'PAYMENT_CONFIRM'
      | 'PAYMENT_REMIND'
      | 'COMMIT_CONFIRM'
      | 'REMIND_CHECKIN'
      | 'CONFIRMED_HOLD'
      | 'REFERRAL',
    refCode: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<NotifyMessageDTO>>>;
  public findMessageWithRefCodeUsingPOST(
    messageType:
      | 'MARKETING'
      | 'PAYMENT_CONFIRM'
      | 'PAYMENT_REMIND'
      | 'COMMIT_CONFIRM'
      | 'REMIND_CHECKIN'
      | 'CONFIRMED_HOLD'
      | 'REFERRAL',
    refCode: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (messageType === null || messageType === undefined) {
      throw new Error(
        'Required parameter messageType was null or undefined when calling findMessageWithRefCodeUsingPOST.'
      );
    }

    if (refCode === null || refCode === undefined) {
      throw new Error(
        'Required parameter refCode was null or undefined when calling findMessageWithRefCodeUsingPOST.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (messageType !== undefined && messageType !== null) {
      queryParameters = queryParameters.set('messageType', <any>messageType);
    }
    if (refCode !== undefined && refCode !== null) {
      queryParameters = queryParameters.set('refCode', <any>refCode);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<Array<NotifyMessageDTO>>(
      `${this.basePath}/api/notify-messages/find-message-with-refcode`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getAllNotifyMessagesByMessageType
   *
   * @param effectiveFrom effectiveFrom
   * @param effectiveTo effectiveTo
   * @param event event
   * @param name name
   * @param page Page number of the requested page
   * @param saleChannel saleChannel
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param status status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllNotifyMessagesByMessageTypeUsingGET(
    effectiveFrom?: Date,
    effectiveTo?: Date,
    event?: string,
    name?: string,
    page?: number,
    saleChannel?: string,
    size?: number,
    sort?: Array<string>,
    status?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PageOfNotifyMessageDTO>;
  public getAllNotifyMessagesByMessageTypeUsingGET(
    effectiveFrom?: Date,
    effectiveTo?: Date,
    event?: string,
    name?: string,
    page?: number,
    saleChannel?: string,
    size?: number,
    sort?: Array<string>,
    status?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PageOfNotifyMessageDTO>>;
  public getAllNotifyMessagesByMessageTypeUsingGET(
    effectiveFrom?: Date,
    effectiveTo?: Date,
    event?: string,
    name?: string,
    page?: number,
    saleChannel?: string,
    size?: number,
    sort?: Array<string>,
    status?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PageOfNotifyMessageDTO>>;
  public getAllNotifyMessagesByMessageTypeUsingGET(
    effectiveFrom?: Date,
    effectiveTo?: Date,
    event?: string,
    name?: string,
    page?: number,
    saleChannel?: string,
    size?: number,
    sort?: Array<string>,
    status?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (effectiveFrom !== undefined && effectiveFrom !== null) {
      queryParameters = queryParameters.set('effectiveFrom', <any>(
        effectiveFrom.toISOString()
      ));
    }
    if (effectiveTo !== undefined && effectiveTo !== null) {
      queryParameters = queryParameters.set('effectiveTo', <any>(
        effectiveTo.toISOString()
      ));
    }
    if (event !== undefined && event !== null) {
      queryParameters = queryParameters.set('event', <any>event);
    }
    if (name !== undefined && name !== null) {
      queryParameters = queryParameters.set('name', <any>name);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (saleChannel !== undefined && saleChannel !== null) {
      queryParameters = queryParameters.set('saleChannel', <any>saleChannel);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PageOfNotifyMessageDTO>(
      `${this.basePath}/api/notify-messages-by-message-type`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getAllNotifyMessages
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllNotifyMessagesUsingGET(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<NotifyMessageDTO>>;
  public getAllNotifyMessagesUsingGET(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<NotifyMessageDTO>>>;
  public getAllNotifyMessagesUsingGET(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<NotifyMessageDTO>>>;
  public getAllNotifyMessagesUsingGET(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<NotifyMessageDTO>>(
      `${this.basePath}/api/notify-messages-all`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getAllNotifyMessagesWithPage
   *
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllNotifyMessagesWithPageUsingGET(
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<PageOfNotifyMessageDTO>;
  public getAllNotifyMessagesWithPageUsingGET(
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<PageOfNotifyMessageDTO>>;
  public getAllNotifyMessagesWithPageUsingGET(
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<PageOfNotifyMessageDTO>>;
  public getAllNotifyMessagesWithPageUsingGET(
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PageOfNotifyMessageDTO>(
      `${this.basePath}/api/notify-messages`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getListActiveMessageToSend
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListActiveMessageToSendUsingGET(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<NotifyMessageDTO>>;
  public getListActiveMessageToSendUsingGET(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<NotifyMessageDTO>>>;
  public getListActiveMessageToSendUsingGET(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<NotifyMessageDTO>>>;
  public getListActiveMessageToSendUsingGET(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<NotifyMessageDTO>>(
      `${this.basePath}/api/notify-messages/list-active-message-to-send`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getNotifyMessage
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getNotifyMessageUsingGET(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NotifyMessageDTO>;
  public getNotifyMessageUsingGET(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NotifyMessageDTO>>;
  public getNotifyMessageUsingGET(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NotifyMessageDTO>>;
  public getNotifyMessageUsingGET(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getNotifyMessageUsingGET.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<NotifyMessageDTO>(
      `${this.basePath}/api/notify-messages/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * updateNotifyMessage
   *
   * @param notifyMessageDTO notifyMessageDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateNotifyMessageUsingPUT(
    notifyMessageDTO: NotifyMessageReq,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NotifyMessageDTO>;
  public updateNotifyMessageUsingPUT(
    notifyMessageDTO: NotifyMessageReq,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NotifyMessageDTO>>;
  public updateNotifyMessageUsingPUT(
    notifyMessageDTO: NotifyMessageReq,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NotifyMessageDTO>>;
  public updateNotifyMessageUsingPUT(
    notifyMessageDTO: NotifyMessageReq,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (notifyMessageDTO === null || notifyMessageDTO === undefined) {
      throw new Error(
        'Required parameter notifyMessageDTO was null or undefined when calling updateNotifyMessageUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<NotifyMessageDTO>(
      `${this.basePath}/api/notify-messages`,
      notifyMessageDTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * updateTotalSlot
   *
   * @param messId messId
   * @param totalSlot totalSlot
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTotalSlotUsingPOST(
    messId: number,
    totalSlot: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateTotalSlotUsingPOST(
    messId: number,
    totalSlot: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateTotalSlotUsingPOST(
    messId: number,
    totalSlot: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateTotalSlotUsingPOST(
    messId: number,
    totalSlot: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (messId === null || messId === undefined) {
      throw new Error(
        'Required parameter messId was null or undefined when calling updateTotalSlotUsingPOST.'
      );
    }

    if (totalSlot === null || totalSlot === undefined) {
      throw new Error(
        'Required parameter totalSlot was null or undefined when calling updateTotalSlotUsingPOST.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (messId !== undefined && messId !== null) {
      queryParameters = queryParameters.set('messId', <any>messId);
    }
    if (totalSlot !== undefined && totalSlot !== null) {
      queryParameters = queryParameters.set('totalSlot', <any>totalSlot);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(
      `${this.basePath}/api/notify-messages/update-total-slot`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * updatefinished
   *
   * @param finished finished
   * @param messId messId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatefinishedUsingPOST(
    finished: number,
    messId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updatefinishedUsingPOST(
    finished: number,
    messId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updatefinishedUsingPOST(
    finished: number,
    messId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updatefinishedUsingPOST(
    finished: number,
    messId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (finished === null || finished === undefined) {
      throw new Error(
        'Required parameter finished was null or undefined when calling updatefinishedUsingPOST.'
      );
    }

    if (messId === null || messId === undefined) {
      throw new Error(
        'Required parameter messId was null or undefined when calling updatefinishedUsingPOST.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (finished !== undefined && finished !== null) {
      queryParameters = queryParameters.set('finished', <any>finished);
    }
    if (messId !== undefined && messId !== null) {
      queryParameters = queryParameters.set('messId', <any>messId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<any>(
      `${this.basePath}/api/notify-messages/update-finished`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
