/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ContactInfo { 
    areaCode?: string;
    city?: string;
    countryCode?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
    postCode?: string;
    title?: ContactInfo.TitleEnum;
}
export namespace ContactInfo {
    export type TitleEnum = 'MALE' | 'FEMALE' | 'BOY' | 'GIRL' | 'INF';
    export const TitleEnum = {
        MALE: 'MALE' as TitleEnum,
        FEMALE: 'FEMALE' as TitleEnum,
        BOY: 'BOY' as TitleEnum,
        GIRL: 'GIRL' as TitleEnum,
        INF: 'INF' as TitleEnum
    };
}
