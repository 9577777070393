import {Action} from '@ngrx/store';
import {CityPopularObjectDTO} from "@gtd/meta-client";

export enum CitiesPopularActionTypes {
  LoadCitiesPopular = '[CitiesPopular] Load CitiesPopular',
  CitiesPopularLoaded = '[CitiesPopular] CitiesPopular Loaded',
  CitiesPopularLoadError = '[CitiesPopular] CitiesPopular Load Error'
}

export class LoadCitiesPopular implements Action {
  readonly type = CitiesPopularActionTypes.LoadCitiesPopular;
}

export class CitiesPopularLoadError implements Action {
  readonly type = CitiesPopularActionTypes.CitiesPopularLoadError;
  constructor(public payload: any) {}
}

export class CitiesPopularLoaded implements Action {
  readonly type = CitiesPopularActionTypes.CitiesPopularLoaded;
  constructor(public payload: Array<CityPopularObjectDTO>) {}
}

export type CitiesPopularAction =
  | LoadCitiesPopular
  | CitiesPopularLoaded
  | CitiesPopularLoadError;

export const fromCitiesPopularActions = {
  LoadCitiesPopular,
  CitiesPopularLoaded,
  CitiesPopularLoadError
};
