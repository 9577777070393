import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar.component';
import {MatProgressSpinnerModule, MatSnackBarModule} from "@angular/material";
import {FlexLayoutModule} from "@angular/flex-layout";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faCheck, faExclamationTriangle, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {TranslateModule} from "@ngx-translate/core";

library.add(
  faCheck,
  faTimes,
  faExclamationTriangle
)
@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    FontAwesomeModule,
    TranslateModule
  ],
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent],
  entryComponents: [SnackbarComponent]
})
export class SnackbarModule {}
