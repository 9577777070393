import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { GetReferralPartialState } from './get-referral.reducer';
import { getReferralQuery } from './get-referral.selectors';
import { LoadGetReferral, DestroyReferral } from './get-referral.actions';

@Injectable()
export class GetReferralFacade {
  loaded$ = this.store.pipe(select(getReferralQuery.getLoaded));
  allGetReferral$ = this.store.pipe(select(getReferralQuery.getAllGetReferral));
  selectedGetReferral$ = this.store.pipe(
    select(getReferralQuery.getSelectedGetReferral)
  );

  constructor(private store: Store<GetReferralPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadGetReferral());
  }
  destroyReferral() {
    this.store.dispatch(new DestroyReferral());
  }
}
