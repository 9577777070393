/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BookingDTO } from '../model/bookingDTO';
import { BookingExtDTO } from '../model/bookingExtDTO';
import { BookingPartnerRequestDTO } from '../model/bookingPartnerRequestDTO';
import { BookingStatusDTO } from '../model/bookingStatusDTO';
import { BookingSummaryTO } from '../model/bookingSummaryTO';
import { BookingSupplierDTO } from '../model/bookingSupplierDTO';
import { BookingTaxReceiptRequestDTO } from '../model/bookingTaxReceiptRequestDTO';
import { BookingVoidPaymentDTO } from '../model/bookingVoidPaymentDTO';
import { MultiRevenueSummaryRQ } from '../model/multiRevenueSummaryRQ';
import { OtaResultOfBookingDTO } from '../model/otaResultOfBookingDTO';
import { OtaResultOfstring } from '../model/otaResultOfstring';
import { OtaSearchResultOfBookingExportResult } from '../model/otaSearchResultOfBookingExportResult';
import { OtaSearchResultOfBookingSearchResult } from '../model/otaSearchResultOfBookingSearchResult';
import { RevenueSummaryTO } from '../model/revenueSummaryTO';
import { SaleChannelBookingDTO } from '../model/saleChannelBookingDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BookingResourceService {

    protected basePath = 'https://localhost:8281';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * cleanBookingExpired
     *
     * @param conditions conditions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cleanBookingExpiredUsingDELETE(conditions: any, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public cleanBookingExpiredUsingDELETE(conditions: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public cleanBookingExpiredUsingDELETE(conditions: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public cleanBookingExpiredUsingDELETE(conditions: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (conditions === null || conditions === undefined) {
            throw new Error('Required parameter conditions was null or undefined when calling cleanBookingExpiredUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.delete<OtaResultOfstring>(`${this.basePath}/api/clean-booking-expired`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * countBookedBooking
     *
     * @param departureDate departureDate
     * @param supplierCode supplierCode
     * @param carrierNo carrierNo
     * @param user user
     * @param customerPhoneNumber customerPhoneNumber
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countBookedBookingUsingGET(departureDate: Date, supplierCode: string, carrierNo: string, user?: string, customerPhoneNumber?: string, agencyCode?: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public countBookedBookingUsingGET(departureDate: Date, supplierCode: string, carrierNo: string, user?: string, customerPhoneNumber?: string, agencyCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public countBookedBookingUsingGET(departureDate: Date, supplierCode: string, carrierNo: string, user?: string, customerPhoneNumber?: string, agencyCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public countBookedBookingUsingGET(departureDate: Date, supplierCode: string, carrierNo: string, user?: string, customerPhoneNumber?: string, agencyCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (departureDate === null || departureDate === undefined) {
            throw new Error('Required parameter departureDate was null or undefined when calling countBookedBookingUsingGET.');
        }

        if (supplierCode === null || supplierCode === undefined) {
            throw new Error('Required parameter supplierCode was null or undefined when calling countBookedBookingUsingGET.');
        }

        if (carrierNo === null || carrierNo === undefined) {
            throw new Error('Required parameter carrierNo was null or undefined when calling countBookedBookingUsingGET.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (departureDate !== undefined && departureDate !== null) {
            queryParameters = queryParameters.set('departureDate', <any>departureDate.toISOString());
        }
        if (supplierCode !== undefined && supplierCode !== null) {
            queryParameters = queryParameters.set('supplierCode', <any>supplierCode);
        }
        if (carrierNo !== undefined && carrierNo !== null) {
            queryParameters = queryParameters.set('carrierNo', <any>carrierNo);
        }
        if (user !== undefined && user !== null) {
            queryParameters = queryParameters.set('user', <any>user);
        }
        if (customerPhoneNumber !== undefined && customerPhoneNumber !== null) {
            queryParameters = queryParameters.set('customerPhoneNumber', <any>customerPhoneNumber);
        }
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<number>(`${this.basePath}/api/bookings/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteBookings
     *
     * @param ids ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBookingsUsingDELETE(ids: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteBookingsUsingDELETE(ids: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteBookingsUsingDELETE(ids: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteBookingsUsingDELETE(ids: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ids === null || ids === undefined) {
            throw new Error('Required parameter ids was null or undefined when calling deleteBookingsUsingDELETE.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/bookings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllSaleChannel
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSaleChannelUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<SaleChannelBookingDTO>>;
    public getAllSaleChannelUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SaleChannelBookingDTO>>>;
    public getAllSaleChannelUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SaleChannelBookingDTO>>>;
    public getAllSaleChannelUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<SaleChannelBookingDTO>>(`${this.basePath}/api/booking/all-sale-channel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllSupplier
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSupplierUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<BookingSupplierDTO>>;
    public getAllSupplierUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookingSupplierDTO>>>;
    public getAllSupplierUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookingSupplierDTO>>>;
    public getAllSupplierUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BookingSupplierDTO>>(`${this.basePath}/api/booking/all-supplier`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingByOrderID
     *
     * @param orderId orderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingByOrderIDUsingGET(orderId: string, observe?: 'body', reportProgress?: boolean): Observable<BookingDTO>;
    public getBookingByOrderIDUsingGET(orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingDTO>>;
    public getBookingByOrderIDUsingGET(orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingDTO>>;
    public getBookingByOrderIDUsingGET(orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling getBookingByOrderIDUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingDTO>(`${this.basePath}/api/bookings/by-order-id/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingByPartnerReq
     *
     * @param partnerReqId partnerReqId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingByPartnerReqUsingGET(partnerReqId: string, observe?: 'body', reportProgress?: boolean): Observable<BookingDTO>;
    public getBookingByPartnerReqUsingGET(partnerReqId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingDTO>>;
    public getBookingByPartnerReqUsingGET(partnerReqId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingDTO>>;
    public getBookingByPartnerReqUsingGET(partnerReqId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (partnerReqId === null || partnerReqId === undefined) {
            throw new Error('Required parameter partnerReqId was null or undefined when calling getBookingByPartnerReqUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingDTO>(`${this.basePath}/api/bookings/by-partner-req/${encodeURIComponent(String(partnerReqId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingByTagName
     *
     * @param tagName tagName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingByTagNameUsingGET(tagName: string, observe?: 'body', reportProgress?: boolean): Observable<BookingDTO>;
    public getBookingByTagNameUsingGET(tagName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingDTO>>;
    public getBookingByTagNameUsingGET(tagName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingDTO>>;
    public getBookingByTagNameUsingGET(tagName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tagName === null || tagName === undefined) {
            throw new Error('Required parameter tagName was null or undefined when calling getBookingByTagNameUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingDTO>(`${this.basePath}/api/bookings/by-tag-name/${encodeURIComponent(String(tagName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingExt
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingExtUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<BookingExtDTO>;
    public getBookingExtUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingExtDTO>>;
    public getBookingExtUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingExtDTO>>;
    public getBookingExtUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBookingExtUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingExtDTO>(`${this.basePath}/api/bookings/detail/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingOriginal
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingOriginalUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<BookingDTO>;
    public getBookingOriginalUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingDTO>>;
    public getBookingOriginalUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingDTO>>;
    public getBookingOriginalUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getBookingOriginalUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingDTO>(`${this.basePath}/api/bookings/original/${encodeURIComponent(String(bookingNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingStatusInfo
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingStatusInfoUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<BookingStatusDTO>;
    public getBookingStatusInfoUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingStatusDTO>>;
    public getBookingStatusInfoUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingStatusDTO>>;
    public getBookingStatusInfoUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getBookingStatusInfoUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingStatusDTO>(`${this.basePath}/api/bookings/status/${encodeURIComponent(String(bookingNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingSummary
     *
     * @param agencyCode agencyCode
     * @param agentCode agentCode
     * @param numberDays numberDays
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingSummaryUsingGET(agencyCode?: string, agentCode?: string, numberDays?: number, observe?: 'body', reportProgress?: boolean): Observable<BookingSummaryTO>;
    public getBookingSummaryUsingGET(agencyCode?: string, agentCode?: string, numberDays?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingSummaryTO>>;
    public getBookingSummaryUsingGET(agencyCode?: string, agentCode?: string, numberDays?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingSummaryTO>>;
    public getBookingSummaryUsingGET(agencyCode?: string, agentCode?: string, numberDays?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (agentCode !== undefined && agentCode !== null) {
            queryParameters = queryParameters.set('agentCode', <any>agentCode);
        }
        if (numberDays !== undefined && numberDays !== null) {
            queryParameters = queryParameters.set('numberDays', <any>numberDays);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingSummaryTO>(`${this.basePath}/api/_summary/bookings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBooking
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<BookingDTO>;
    public getBookingUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingDTO>>;
    public getBookingUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingDTO>>;
    public getBookingUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBookingUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingDTO>(`${this.basePath}/api/bookings/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingVoidPayment
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingVoidPaymentUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<BookingVoidPaymentDTO>;
    public getBookingVoidPaymentUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingVoidPaymentDTO>>;
    public getBookingVoidPaymentUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingVoidPaymentDTO>>;
    public getBookingVoidPaymentUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBookingVoidPaymentUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingVoidPaymentDTO>(`${this.basePath}/api/bookings/void-payment/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMultiRevenueSummary
     *
     * @param revenueSummaryRQ revenueSummaryRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMultiRevenueSummaryUsingPOST(revenueSummaryRQ: MultiRevenueSummaryRQ, observe?: 'body', reportProgress?: boolean): Observable<Array<RevenueSummaryTO>>;
    public getMultiRevenueSummaryUsingPOST(revenueSummaryRQ: MultiRevenueSummaryRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RevenueSummaryTO>>>;
    public getMultiRevenueSummaryUsingPOST(revenueSummaryRQ: MultiRevenueSummaryRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RevenueSummaryTO>>>;
    public getMultiRevenueSummaryUsingPOST(revenueSummaryRQ: MultiRevenueSummaryRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (revenueSummaryRQ === null || revenueSummaryRQ === undefined) {
            throw new Error('Required parameter revenueSummaryRQ was null or undefined when calling getMultiRevenueSummaryUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<RevenueSummaryTO>>(`${this.basePath}/api/get-summary-revenues/bookings`,
            revenueSummaryRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getRevenueReport
     *
     * @param agencyCode agencyCode
     * @param paymentType paymentType
     * @param bookingDateFrom bookingDateFrom
     * @param bookingDateTo bookingDateTo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRevenueReportUsingGET(agencyCode?: string, paymentType?: string, bookingDateFrom?: string, bookingDateTo?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RevenueSummaryTO>>;
    public getRevenueReportUsingGET(agencyCode?: string, paymentType?: string, bookingDateFrom?: string, bookingDateTo?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RevenueSummaryTO>>>;
    public getRevenueReportUsingGET(agencyCode?: string, paymentType?: string, bookingDateFrom?: string, bookingDateTo?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RevenueSummaryTO>>>;
    public getRevenueReportUsingGET(agencyCode?: string, paymentType?: string, bookingDateFrom?: string, bookingDateTo?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (paymentType !== undefined && paymentType !== null) {
            queryParameters = queryParameters.set('paymentType', <any>paymentType);
        }
        if (bookingDateFrom !== undefined && bookingDateFrom !== null) {
            queryParameters = queryParameters.set('bookingDateFrom', <any>bookingDateFrom);
        }
        if (bookingDateTo !== undefined && bookingDateTo !== null) {
            queryParameters = queryParameters.set('bookingDateTo', <any>bookingDateTo);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<RevenueSummaryTO>>(`${this.basePath}/api/_summary-revenue/report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getRevenueSummary
     *
     * @param agencyCode agencyCode
     * @param agentCode agentCode
     * @param forAgency forAgency
     * @param numberDays numberDays
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRevenueSummaryUsingGET(agencyCode: string, agentCode: string, forAgency: boolean, numberDays: number, observe?: 'body', reportProgress?: boolean): Observable<RevenueSummaryTO>;
    public getRevenueSummaryUsingGET(agencyCode: string, agentCode: string, forAgency: boolean, numberDays: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RevenueSummaryTO>>;
    public getRevenueSummaryUsingGET(agencyCode: string, agentCode: string, forAgency: boolean, numberDays: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RevenueSummaryTO>>;
    public getRevenueSummaryUsingGET(agencyCode: string, agentCode: string, forAgency: boolean, numberDays: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getRevenueSummaryUsingGET.');
        }

        if (agentCode === null || agentCode === undefined) {
            throw new Error('Required parameter agentCode was null or undefined when calling getRevenueSummaryUsingGET.');
        }

        if (forAgency === null || forAgency === undefined) {
            throw new Error('Required parameter forAgency was null or undefined when calling getRevenueSummaryUsingGET.');
        }

        if (numberDays === null || numberDays === undefined) {
            throw new Error('Required parameter numberDays was null or undefined when calling getRevenueSummaryUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (agentCode !== undefined && agentCode !== null) {
            queryParameters = queryParameters.set('agentCode', <any>agentCode);
        }
        if (forAgency !== undefined && forAgency !== null) {
            queryParameters = queryParameters.set('forAgency', <any>forAgency);
        }
        if (numberDays !== undefined && numberDays !== null) {
            queryParameters = queryParameters.set('numberDays', <any>numberDays);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<RevenueSummaryTO>(`${this.basePath}/api/_summary-revenue/bookings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchBookings
     *
     * @param supplierType supplierType
     * @param bookingCode bookingCode
     * @param bookingStatus bookingStatus
     * @param fromLocationName fromLocationName
     * @param toLocationName toLocationName
     * @param fromDate fromDate
     * @param toDate toDate
     * @param listBookingStatus listBookingStatus
     * @param filterStatus filterStatus
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBookingsUsingGET1(supplierType: string, bookingCode?: string, bookingStatus?: string, fromLocationName?: string, toLocationName?: string, fromDate?: string, toDate?: string, listBookingStatus?: Array<string>, filterStatus?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<BookingDTO>>;
    public searchBookingsUsingGET1(supplierType: string, bookingCode?: string, bookingStatus?: string, fromLocationName?: string, toLocationName?: string, fromDate?: string, toDate?: string, listBookingStatus?: Array<string>, filterStatus?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookingDTO>>>;
    public searchBookingsUsingGET1(supplierType: string, bookingCode?: string, bookingStatus?: string, fromLocationName?: string, toLocationName?: string, fromDate?: string, toDate?: string, listBookingStatus?: Array<string>, filterStatus?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookingDTO>>>;
    public searchBookingsUsingGET1(supplierType: string, bookingCode?: string, bookingStatus?: string, fromLocationName?: string, toLocationName?: string, fromDate?: string, toDate?: string, listBookingStatus?: Array<string>, filterStatus?: boolean, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (supplierType === null || supplierType === undefined) {
            throw new Error('Required parameter supplierType was null or undefined when calling searchBookingsUsingGET1.');
        }












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (supplierType !== undefined && supplierType !== null) {
            queryParameters = queryParameters.set('supplierType', <any>supplierType);
        }
        if (bookingCode !== undefined && bookingCode !== null) {
            queryParameters = queryParameters.set('bookingCode', <any>bookingCode);
        }
        if (bookingStatus !== undefined && bookingStatus !== null) {
            queryParameters = queryParameters.set('bookingStatus', <any>bookingStatus);
        }
        if (fromLocationName !== undefined && fromLocationName !== null) {
            queryParameters = queryParameters.set('fromLocationName', <any>fromLocationName);
        }
        if (toLocationName !== undefined && toLocationName !== null) {
            queryParameters = queryParameters.set('toLocationName', <any>toLocationName);
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate);
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate);
        }
        if (listBookingStatus) {
            listBookingStatus.forEach((element) => {
                queryParameters = queryParameters.append('listBookingStatus', <any>element);
            })
        }
        if (filterStatus !== undefined && filterStatus !== null) {
            queryParameters = queryParameters.set('filterStatus', <any>filterStatus);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BookingDTO>>(`${this.basePath}/api/bookings/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjectExport
     *
     * @param productCode productCode
     * @param status status
     * @param agencyCode agencyCode
     * @param bookingNumber bookingNumber
     * @param providerBrn providerBrn
     * @param email email
     * @param customerName customerName
     * @param supplierCode supplierCode
     * @param bookingDateFrom bookingDateFrom
     * @param bookingDateTo bookingDateTo
     * @param checkInDate checkInDate
     * @param checkOutDate checkOutDate
     * @param issuedDateFrom issuedDateFrom
     * @param issuedDateTo issuedDateTo
     * @param typeCode typeCode
     * @param paymentType paymentType
     * @param action action
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectExportUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, issuedDateFrom?: string, issuedDateTo?: string, typeCode?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfBookingExportResult>;
    public searchObjectExportUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, issuedDateFrom?: string, issuedDateTo?: string, typeCode?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfBookingExportResult>>;
    public searchObjectExportUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, issuedDateFrom?: string, issuedDateTo?: string, typeCode?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfBookingExportResult>>;
    public searchObjectExportUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, issuedDateFrom?: string, issuedDateTo?: string, typeCode?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productCode !== undefined && productCode !== null) {
            queryParameters = queryParameters.set('productCode', <any>productCode);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (providerBrn !== undefined && providerBrn !== null) {
            queryParameters = queryParameters.set('providerBrn', <any>providerBrn);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (customerName !== undefined && customerName !== null) {
            queryParameters = queryParameters.set('customerName', <any>customerName);
        }
        if (supplierCode !== undefined && supplierCode !== null) {
            queryParameters = queryParameters.set('supplierCode', <any>supplierCode);
        }
        if (bookingDateFrom !== undefined && bookingDateFrom !== null) {
            queryParameters = queryParameters.set('bookingDateFrom', <any>bookingDateFrom);
        }
        if (bookingDateTo !== undefined && bookingDateTo !== null) {
            queryParameters = queryParameters.set('bookingDateTo', <any>bookingDateTo);
        }
        if (checkInDate !== undefined && checkInDate !== null) {
            queryParameters = queryParameters.set('checkInDate', <any>checkInDate);
        }
        if (checkOutDate !== undefined && checkOutDate !== null) {
            queryParameters = queryParameters.set('checkOutDate', <any>checkOutDate);
        }
        if (issuedDateFrom !== undefined && issuedDateFrom !== null) {
            queryParameters = queryParameters.set('issuedDateFrom', <any>issuedDateFrom);
        }
        if (issuedDateTo !== undefined && issuedDateTo !== null) {
            queryParameters = queryParameters.set('issuedDateTo', <any>issuedDateTo);
        }
        if (typeCode !== undefined && typeCode !== null) {
            queryParameters = queryParameters.set('typeCode', <any>typeCode);
        }
        if (paymentType !== undefined && paymentType !== null) {
            queryParameters = queryParameters.set('paymentType', <any>paymentType);
        }
        if (action !== undefined && action !== null) {
            queryParameters = queryParameters.set('action', <any>action);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaSearchResultOfBookingExportResult>(`${this.basePath}/api/_search/export-bookings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjects
     *
     * @param productCode productCode
     * @param status status
     * @param agencyCode agencyCode
     * @param bookingNumber bookingNumber
     * @param providerBrn providerBrn
     * @param email email
     * @param customerName customerName
     * @param supplierCode supplierCode
     * @param bookingDateFrom bookingDateFrom
     * @param bookingDateTo bookingDateTo
     * @param checkInDate checkInDate
     * @param checkOutDate checkOutDate
     * @param issuedDateFrom issuedDateFrom
     * @param issuedDateTo issuedDateTo
     * @param typeCode typeCode
     * @param paymentType paymentType
     * @param action action
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectsUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, issuedDateFrom?: string, issuedDateTo?: string, typeCode?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfBookingSearchResult>;
    public searchObjectsUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, issuedDateFrom?: string, issuedDateTo?: string, typeCode?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfBookingSearchResult>>;
    public searchObjectsUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, issuedDateFrom?: string, issuedDateTo?: string, typeCode?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfBookingSearchResult>>;
    public searchObjectsUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, issuedDateFrom?: string, issuedDateTo?: string, typeCode?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productCode !== undefined && productCode !== null) {
            queryParameters = queryParameters.set('productCode', <any>productCode);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (providerBrn !== undefined && providerBrn !== null) {
            queryParameters = queryParameters.set('providerBrn', <any>providerBrn);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (customerName !== undefined && customerName !== null) {
            queryParameters = queryParameters.set('customerName', <any>customerName);
        }
        if (supplierCode !== undefined && supplierCode !== null) {
            queryParameters = queryParameters.set('supplierCode', <any>supplierCode);
        }
        if (bookingDateFrom !== undefined && bookingDateFrom !== null) {
            queryParameters = queryParameters.set('bookingDateFrom', <any>bookingDateFrom);
        }
        if (bookingDateTo !== undefined && bookingDateTo !== null) {
            queryParameters = queryParameters.set('bookingDateTo', <any>bookingDateTo);
        }
        if (checkInDate !== undefined && checkInDate !== null) {
            queryParameters = queryParameters.set('checkInDate', <any>checkInDate);
        }
        if (checkOutDate !== undefined && checkOutDate !== null) {
            queryParameters = queryParameters.set('checkOutDate', <any>checkOutDate);
        }
        if (issuedDateFrom !== undefined && issuedDateFrom !== null) {
            queryParameters = queryParameters.set('issuedDateFrom', <any>issuedDateFrom);
        }
        if (issuedDateTo !== undefined && issuedDateTo !== null) {
            queryParameters = queryParameters.set('issuedDateTo', <any>issuedDateTo);
        }
        if (typeCode !== undefined && typeCode !== null) {
            queryParameters = queryParameters.set('typeCode', <any>typeCode);
        }
        if (paymentType !== undefined && paymentType !== null) {
            queryParameters = queryParameters.set('paymentType', <any>paymentType);
        }
        if (action !== undefined && action !== null) {
            queryParameters = queryParameters.set('action', <any>action);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaSearchResultOfBookingSearchResult>(`${this.basePath}/api/_search/bookings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBookingCustomerAfterLogin
     *
     * @param bookingNumber bookingNumber
     * @param custCode custCode
     * @param custId custId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingCustomerAfterLoginUsingGET(bookingNumber: string, custCode: string, custId: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public updateBookingCustomerAfterLoginUsingGET(bookingNumber: string, custCode: string, custId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public updateBookingCustomerAfterLoginUsingGET(bookingNumber: string, custCode: string, custId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public updateBookingCustomerAfterLoginUsingGET(bookingNumber: string, custCode: string, custId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling updateBookingCustomerAfterLoginUsingGET.');
        }

        if (custCode === null || custCode === undefined) {
            throw new Error('Required parameter custCode was null or undefined when calling updateBookingCustomerAfterLoginUsingGET.');
        }

        if (custId === null || custId === undefined) {
            throw new Error('Required parameter custId was null or undefined when calling updateBookingCustomerAfterLoginUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (custCode !== undefined && custCode !== null) {
            queryParameters = queryParameters.set('custCode', <any>custCode);
        }
        if (custId !== undefined && custId !== null) {
            queryParameters = queryParameters.set('custId', <any>custId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/update-booking-customer`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBookingPartnerInfo
     *
     * @param bookingPartnerReqDTO bookingPartnerReqDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingPartnerInfoUsingPUT(bookingPartnerReqDTO: BookingPartnerRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<BookingDTO>;
    public updateBookingPartnerInfoUsingPUT(bookingPartnerReqDTO: BookingPartnerRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingDTO>>;
    public updateBookingPartnerInfoUsingPUT(bookingPartnerReqDTO: BookingPartnerRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingDTO>>;
    public updateBookingPartnerInfoUsingPUT(bookingPartnerReqDTO: BookingPartnerRequestDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingPartnerReqDTO === null || bookingPartnerReqDTO === undefined) {
            throw new Error('Required parameter bookingPartnerReqDTO was null or undefined when calling updateBookingPartnerInfoUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BookingDTO>(`${this.basePath}/api/bookings/partner-info`,
            bookingPartnerReqDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBookingTags
     *
     * @param id id
     * @param tag tag
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingTagsUsingPUT(id: number, tag?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateBookingTagsUsingPUT(id: number, tag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateBookingTagsUsingPUT(id: number, tag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateBookingTagsUsingPUT(id: number, tag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateBookingTagsUsingPUT.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/bookings/${encodeURIComponent(String(id))}/tags`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBooking
     *
     * @param bookingTaxReceiptRequestDTO bookingTaxReceiptRequestDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingUsingPUT(bookingTaxReceiptRequestDTO: BookingTaxReceiptRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfBookingDTO>;
    public updateBookingUsingPUT(bookingTaxReceiptRequestDTO: BookingTaxReceiptRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfBookingDTO>>;
    public updateBookingUsingPUT(bookingTaxReceiptRequestDTO: BookingTaxReceiptRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfBookingDTO>>;
    public updateBookingUsingPUT(bookingTaxReceiptRequestDTO: BookingTaxReceiptRequestDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingTaxReceiptRequestDTO === null || bookingTaxReceiptRequestDTO === undefined) {
            throw new Error('Required parameter bookingTaxReceiptRequestDTO was null or undefined when calling updateBookingUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<OtaResultOfBookingDTO>(`${this.basePath}/api/bookings/tax-receipt`,
            bookingTaxReceiptRequestDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
