/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ArrayOfConfirmedPassenger } from './arrayOfConfirmedPassenger';


export interface OutputConfirmResponsePurchase { 
    confirmedPassengers?: ArrayOfConfirmedPassenger;
    errorCode?: string;
    errorMessage?: string;
    itineraryID?: string;
    pnr?: string;
    policyNo?: string;
    policyPurchasedDateTime?: string;
    proposalState?: OutputConfirmResponsePurchase.ProposalStateEnum;
}
export namespace OutputConfirmResponsePurchase {
    export type ProposalStateEnum = 'PROPOSAL' | 'CONFIRMED' | 'CANCELLED' | 'ONHOLD';
    export const ProposalStateEnum = {
        PROPOSAL: 'PROPOSAL' as ProposalStateEnum,
        CONFIRMED: 'CONFIRMED' as ProposalStateEnum,
        CANCELLED: 'CANCELLED' as ProposalStateEnum,
        ONHOLD: 'ONHOLD' as ProposalStateEnum
    };
}
