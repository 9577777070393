import { Action } from '@ngrx/store';
import { AirItineraryFilterRS, AirLowFareFilterVM } from '@gtd/b2c-client';

export enum LowestPriceFlightsActionTypes {
  ComboVJLoadLowestPriceFlights = '[ComboVJ_LowestPriceFlights] Load LowestPriceFlights',
  ComboVJLowestPriceFlightsLoaded = '[ComboVJ_LowestPriceFlights] LowestPriceFlights Loaded',
  ComboVJLowestPriceFlightsLoadError = '[ComboVJ_LowestPriceFlights] LowestPriceFlights Load Error',
  ComboVJResetLowestPriceFlights = '[ComboVJ_LowestPriceFlights] Reset LowestPriceFlights'
}

export class ComboVJLoadLowestPriceFlights implements Action {
  readonly type = LowestPriceFlightsActionTypes.ComboVJLoadLowestPriceFlights;

  constructor(public payload: AirLowFareFilterVM) {}
}

export class ComboVJLowestPriceFlightsLoadError implements Action {
  readonly type =
    LowestPriceFlightsActionTypes.ComboVJLowestPriceFlightsLoadError;

  constructor(public payload: any) {}
}

export class ComboVJLowestPriceFlightsLoaded implements Action {
  readonly type = LowestPriceFlightsActionTypes.ComboVJLowestPriceFlightsLoaded;

  constructor(public payload: AirItineraryFilterRS) {}
}

export class ComboVJResetLowestPriceFlights implements Action {
  readonly type = LowestPriceFlightsActionTypes.ComboVJResetLowestPriceFlights;
}

export type LowestPriceFlightsAction =
  | ComboVJLoadLowestPriceFlights
  | ComboVJLowestPriceFlightsLoaded
  | ComboVJLowestPriceFlightsLoadError
  | ComboVJResetLowestPriceFlights;

export const fromLowestPriceFlightsActions = {
  ComboVJLoadLowestPriceFlights,
  ComboVJLowestPriceFlightsLoaded,
  ComboVJLowestPriceFlightsLoadError,
  ComboVJResetLowestPriceFlights
};
