import { Action } from '@ngrx/store';
import { HotelMetaKeyword } from '@gtd/b2c-client';

export enum HotelSearchKeywordActionTypes {
  ComboVJAutoCompleteSearchKeyword = '[ComboVJ_ComboVJ] Load AutoCompleteSearchKeyword',
  ComboVJAutoCompleteSearchKeywordLoaded = '[ComboVJ_AutoCompleteSearchKeyword] AutoCompleteSearchKeyword Loaded',
  ComboVJAutoCompleteSearchKeywordLoadError = '[ComboVJ_AutoCompleteSearchKeyword] AutoCompleteSearchKeyword Load Error'
}

export class ComboVJAutoCompleteSearchKeyword implements Action {
  readonly type =
    HotelSearchKeywordActionTypes.ComboVJAutoCompleteSearchKeyword;
  constructor(public payload: HotelMetaKeyword[]) {}
}

export class ComboVJAutoCompleteSearchKeywordLoadError implements Action {
  readonly type =
    HotelSearchKeywordActionTypes.ComboVJAutoCompleteSearchKeywordLoadError;
  constructor(public payload: any) {}
}

export class ComboVJAutoCompleteSearchKeywordLoaded implements Action {
  readonly type =
    HotelSearchKeywordActionTypes.ComboVJAutoCompleteSearchKeywordLoaded;
  constructor(public payload: HotelMetaKeyword[]) {}
}

export type HotelSearchKeywordAction =
  | ComboVJAutoCompleteSearchKeyword
  | ComboVJAutoCompleteSearchKeywordLoadError
  | ComboVJAutoCompleteSearchKeywordLoaded;

export const fromHotelSearchKeywordActions = {
  ComboVJAutoCompleteSearchKeyword,
  ComboVJAutoCompleteSearchKeywordLoadError,
  ComboVJAutoCompleteSearchKeywordLoaded
};
