import {Action} from '@ngrx/store';
import {ListResponseOfInsurancePolicy, OtaResultOfListOfInsuranceDetail} from "@gtd/api-services/service-inventory";

export enum PolicyByBookingActionTypes {
  LoadPolicyByBooking = '[PolicyByBooking] Load PolicyByBooking',
  PolicyByBookingLoaded = '[PolicyByBooking] PolicyByBooking Loaded',
  PolicyByBookingLoadError = '[PolicyByBooking] PolicyByBooking Load Error'
}

export class LoadPolicyByBooking implements Action {
  readonly type = PolicyByBookingActionTypes.LoadPolicyByBooking;
  constructor(public payload: string) {}
}

export class PolicyByBookingLoadError implements Action {
  readonly type = PolicyByBookingActionTypes.PolicyByBookingLoadError;
  constructor(public payload: any) {}
}

export class PolicyByBookingLoaded implements Action {
  readonly type = PolicyByBookingActionTypes.PolicyByBookingLoaded;
  constructor(public payload: OtaResultOfListOfInsuranceDetail) {}
}

export type PolicyByBookingAction =
  | LoadPolicyByBooking
  | PolicyByBookingLoaded
  | PolicyByBookingLoadError;

export const fromPolicyByBookingActions = {
  LoadPolicyByBooking,
  PolicyByBookingLoaded,
  PolicyByBookingLoadError
};
