import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AvatarFacade} from './+state/avatar.facade';
import {NxModule} from '@nrwl/angular';
import {StoreModule} from "@ngrx/store";
import {
  initialState as avatarInitialState,
  AVATAR_FEATURE_KEY,
  reducer
} from "./+state/avatar.reducer";
import {EffectsModule} from "@ngrx/effects";
import {AvatarEffects} from "./+state/avatar.effects";
import {CustomerAvatarResourceService} from "@gtd/api-services/customer-service";
import {BASE_PATH} from "@gtd/api-services/customer-service";
import {environment} from "@env/environment";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(AVATAR_FEATURE_KEY, reducer, {
      initialState: avatarInitialState
    }),
    EffectsModule.forFeature([AvatarEffects])
  ],
  providers: [{provide: BASE_PATH, useValue: environment.api.customerService}, AvatarFacade, CustomerAvatarResourceService]
})
export class AvatarModule {}
