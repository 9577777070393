/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface PromotionReq { 
    airlinesCode?: Array<string>;
    bankCode?: Array<string>;
    bankName?: Array<string>;
    cardType?: Array<string>;
    description?: string;
    discountAmount?: number;
    discountValueType?: PromotionReq.DiscountValueTypeEnum;
    effectiveFrom?: Date;
    effectiveTo?: Date;
    endTime?: string;
    image?: string;
    lowerPrice?: number;
    metadata?: { [key: string]: Array<string>; };
    name?: string;
    priority?: number;
    productType?: Array<string>;
    promotionOption?: PromotionReq.PromotionOptionEnum;
    promotionSlot?: number;
    promotionType?: PromotionReq.PromotionTypeEnum;
    saleChannel?: Array<string>;
    startTime?: string;
    status?: PromotionReq.StatusEnum;
    tcUrl?: string;
    upperPrice?: number;
    usedSlot?: number;
    voucherCode?: string;
}
export namespace PromotionReq {
    export type DiscountValueTypeEnum = 'FIXED' | 'PERCENT';
    export const DiscountValueTypeEnum = {
        FIXED: 'FIXED' as DiscountValueTypeEnum,
        PERCENT: 'PERCENT' as DiscountValueTypeEnum
    };
    export type PromotionOptionEnum = 'ANCILLARY' | 'TOTAL';
    export const PromotionOptionEnum = {
        ANCILLARY: 'ANCILLARY' as PromotionOptionEnum,
        TOTAL: 'TOTAL' as PromotionOptionEnum
    };
    export type PromotionTypeEnum = 'CREDIT_CARD' | 'AIRLINES_CODE' | 'VOUCHER' | 'NORMAL';
    export const PromotionTypeEnum = {
        CREDITCARD: 'CREDIT_CARD' as PromotionTypeEnum,
        AIRLINESCODE: 'AIRLINES_CODE' as PromotionTypeEnum,
        VOUCHER: 'VOUCHER' as PromotionTypeEnum,
        NORMAL: 'NORMAL' as PromotionTypeEnum
    };
    export type StatusEnum = 'PUBLISHING' | 'PENDING' | 'TERMINATED' | 'FINISHED' | 'DRAFT';
    export const StatusEnum = {
        PUBLISHING: 'PUBLISHING' as StatusEnum,
        PENDING: 'PENDING' as StatusEnum,
        TERMINATED: 'TERMINATED' as StatusEnum,
        FINISHED: 'FINISHED' as StatusEnum,
        DRAFT: 'DRAFT' as StatusEnum
    };
}
