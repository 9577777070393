import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material';
import { SnackbarModel } from './snackbarModel';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'gtd-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public snackBarRef: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarModel,
    private overlayContainer: OverlayContainer,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    console.log('init snackbar');
    // Access the overlay container element
    const overlayContainerElement = this.overlayContainer.getContainerElement();

    // Add your custom class to the overlay container
    this.renderer.addClass(overlayContainerElement, 'snackbar-overlay');

    // Optionally, you can add more styling to the snackbar element itself
    // const snackbarElement = this.elementRef.nativeElement;
    // this.renderer.addClass(snackbarElement, 'your-custom-snackbar-class');
  }

  ngOnDestroy(): void {
    console.log('destroy snackbar');
    const overlayContainerElement = this.overlayContainer.getContainerElement();

    this.renderer.removeClass(overlayContainerElement, 'snackbar-overlay');
  }
}
