import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TimeCountDownBetweenDirective} from './time-count-down-between.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TimeCountDownBetweenDirective],
  exports: [TimeCountDownBetweenDirective],
  providers: [TimeCountDownBetweenDirective]
})

export class TimeCountDownBetweenModule {}
