/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HotelProduct } from './hotelProduct';


export interface CheckoutResult { 
    hotelProduct?: HotelProduct;
    status?: CheckoutResult.StatusEnum;
}
export namespace CheckoutResult {
    export type StatusEnum = 'AVAILABLE' | 'PRICE_CHANGED' | 'SOLD_OUT' | 'UNKNOWN';
    export const StatusEnum = {
        AVAILABLE: 'AVAILABLE' as StatusEnum,
        PRICECHANGED: 'PRICE_CHANGED' as StatusEnum,
        SOLDOUT: 'SOLD_OUT' as StatusEnum,
        UNKNOWN: 'UNKNOWN' as StatusEnum
    };
}
