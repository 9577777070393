import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-hotel-sort-loading-panel',
  templateUrl: './hotel-sort-loading-panel.component.html',
  styleUrls: ['./hotel-sort-loading-panel.component.scss']
})
export class HotelSortLoadingPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
