/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { FlexiNotifyPaymentRQ } from '../model/flexiNotifyPaymentRQ';
import { FlexiPremiumTvcRQ } from '../model/flexiPremiumTvcRQ';
import { FlexiTvcBaseVMRQ } from '../model/flexiTvcBaseVMRQ';
import { OtaResultOfFlexiNotifyPaymentRS } from '../model/otaResultOfFlexiNotifyPaymentRS';
import { OtaResultOfFlexiPremiumTvcVM } from '../model/otaResultOfFlexiPremiumTvcVM';
import { OtaResultOfFlexiTvcBaseVMRS } from '../model/otaResultOfFlexiTvcBaseVMRS';
import { OtaResultOfFlexiTvcItemDTO } from '../model/otaResultOfFlexiTvcItemDTO';
import { OtaResultOfGroupBooking } from '../model/otaResultOfGroupBooking';
import { OtaResultOfListOfSSRItemDTO } from '../model/otaResultOfListOfSSRItemDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FlexiApiResourceService {

    protected basePath = 'https://10.7.71.20:7883';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addFlexiTvcByBooking
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFlexiTvcByBookingUsingPOST(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfFlexiTvcItemDTO>;
    public addFlexiTvcByBookingUsingPOST(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfFlexiTvcItemDTO>>;
    public addFlexiTvcByBookingUsingPOST(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfFlexiTvcItemDTO>>;
    public addFlexiTvcByBookingUsingPOST(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling addFlexiTvcByBookingUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OtaResultOfFlexiTvcItemDTO>(`${this.basePath}/api/flexi-api/add-flexi-premium-tvc-by-booking`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * addFlexiTvc
     *
     * @param flexiTvcBaseVMRQ flexiTvcBaseVMRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFlexiTvcUsingPOST(flexiTvcBaseVMRQ: FlexiTvcBaseVMRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfFlexiTvcItemDTO>;
    public addFlexiTvcUsingPOST(flexiTvcBaseVMRQ: FlexiTvcBaseVMRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfFlexiTvcItemDTO>>;
    public addFlexiTvcUsingPOST(flexiTvcBaseVMRQ: FlexiTvcBaseVMRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfFlexiTvcItemDTO>>;
    public addFlexiTvcUsingPOST(flexiTvcBaseVMRQ: FlexiTvcBaseVMRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (flexiTvcBaseVMRQ === null || flexiTvcBaseVMRQ === undefined) {
            throw new Error('Required parameter flexiTvcBaseVMRQ was null or undefined when calling addFlexiTvcUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfFlexiTvcItemDTO>(`${this.basePath}/api/flexi-api/add-flexi-premium-tvc`,
            flexiTvcBaseVMRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * confirmPolicyFlexiTvc
     *
     * @param flexiNotifyPaymentRQ flexiNotifyPaymentRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmPolicyFlexiTvcUsingPOST(flexiNotifyPaymentRQ: FlexiNotifyPaymentRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfFlexiNotifyPaymentRS>;
    public confirmPolicyFlexiTvcUsingPOST(flexiNotifyPaymentRQ: FlexiNotifyPaymentRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfFlexiNotifyPaymentRS>>;
    public confirmPolicyFlexiTvcUsingPOST(flexiNotifyPaymentRQ: FlexiNotifyPaymentRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfFlexiNotifyPaymentRS>>;
    public confirmPolicyFlexiTvcUsingPOST(flexiNotifyPaymentRQ: FlexiNotifyPaymentRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (flexiNotifyPaymentRQ === null || flexiNotifyPaymentRQ === undefined) {
            throw new Error('Required parameter flexiNotifyPaymentRQ was null or undefined when calling confirmPolicyFlexiTvcUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfFlexiNotifyPaymentRS>(`${this.basePath}/api/flexi-api/confirm-flexi-policy`,
            flexiNotifyPaymentRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createPolicyFlexiTvc
     *
     * @param flexiTvcBaseVMRQ flexiTvcBaseVMRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createPolicyFlexiTvcUsingPOST(flexiTvcBaseVMRQ: FlexiTvcBaseVMRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfFlexiTvcBaseVMRS>;
    public createPolicyFlexiTvcUsingPOST(flexiTvcBaseVMRQ: FlexiTvcBaseVMRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfFlexiTvcBaseVMRS>>;
    public createPolicyFlexiTvcUsingPOST(flexiTvcBaseVMRQ: FlexiTvcBaseVMRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfFlexiTvcBaseVMRS>>;
    public createPolicyFlexiTvcUsingPOST(flexiTvcBaseVMRQ: FlexiTvcBaseVMRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (flexiTvcBaseVMRQ === null || flexiTvcBaseVMRQ === undefined) {
            throw new Error('Required parameter flexiTvcBaseVMRQ was null or undefined when calling createPolicyFlexiTvcUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfFlexiTvcBaseVMRS>(`${this.basePath}/api/flexi-api/create-policy-premium-tvc`,
            flexiTvcBaseVMRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingDetail
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingDetailUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfGroupBooking>;
    public getBookingDetailUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfGroupBooking>>;
    public getBookingDetailUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfGroupBooking>>;
    public getBookingDetailUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getBookingDetailUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OtaResultOfGroupBooking>(`${this.basePath}/api/flexi-api/booking-detail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getFlexiTvcItem
     *
     * @param flexiPremiumTvcVM flexiPremiumTvcVM
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFlexiTvcItemUsingPOST(flexiPremiumTvcVM: FlexiPremiumTvcRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfFlexiPremiumTvcVM>;
    public getFlexiTvcItemUsingPOST(flexiPremiumTvcVM: FlexiPremiumTvcRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfFlexiPremiumTvcVM>>;
    public getFlexiTvcItemUsingPOST(flexiPremiumTvcVM: FlexiPremiumTvcRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfFlexiPremiumTvcVM>>;
    public getFlexiTvcItemUsingPOST(flexiPremiumTvcVM: FlexiPremiumTvcRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (flexiPremiumTvcVM === null || flexiPremiumTvcVM === undefined) {
            throw new Error('Required parameter flexiPremiumTvcVM was null or undefined when calling getFlexiTvcItemUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfFlexiPremiumTvcVM>(`${this.basePath}/api/flexi-api/flexi-premium-fee`,
            flexiPremiumTvcVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getInsurancePlans
     *
     * @param departureDate departureDate
     * @param fromLocation fromLocation
     * @param toLocation toLocation
     * @param adult adult
     * @param child child
     * @param infant infant
     * @param planId planId
     * @param bookingNumber bookingNumber
     * @param regions regions
     * @param returnDate returnDate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInsurancePlansUsingGET(departureDate: string, fromLocation: string, toLocation: string, adult?: number, child?: number, infant?: number, planId?: string, bookingNumber?: string, regions?: Array<string>, returnDate?: string, numberOfNonIns?: number, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfSSRItemDTO>;
    public getInsurancePlansUsingGET(departureDate: string, fromLocation: string, toLocation: string, adult?: number, child?: number, infant?: number, planId?: string, bookingNumber?: string, regions?: Array<string>, returnDate?: string, numberOfNonIns?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfSSRItemDTO>>;
    public getInsurancePlansUsingGET(departureDate: string, fromLocation: string, toLocation: string, adult?: number, child?: number, infant?: number, planId?: string, bookingNumber?: string, regions?: Array<string>, returnDate?: string, numberOfNonIns?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfSSRItemDTO>>;
    public getInsurancePlansUsingGET(departureDate: string, fromLocation: string, toLocation: string, adult?: number, child?: number, infant?: number, planId?: string, bookingNumber?: string, regions?: Array<string>, returnDate?: string, numberOfNonIns?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (departureDate === null || departureDate === undefined) {
            throw new Error('Required parameter departureDate was null or undefined when calling getInsurancePlansUsingGET.');
        }

        if (fromLocation === null || fromLocation === undefined) {
            throw new Error('Required parameter fromLocation was null or undefined when calling getInsurancePlansUsingGET.');
        }

        if (toLocation === null || toLocation === undefined) {
            throw new Error('Required parameter toLocation was null or undefined when calling getInsurancePlansUsingGET.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (adult !== undefined && adult !== null) {
            queryParameters = queryParameters.set('adult', <any>adult);
        }
        if (child !== undefined && child !== null) {
            queryParameters = queryParameters.set('child', <any>child);
        }
        if (departureDate !== undefined && departureDate !== null) {
            queryParameters = queryParameters.set('departureDate', <any>departureDate);
        }
        if (fromLocation !== undefined && fromLocation !== null) {
            queryParameters = queryParameters.set('fromLocation', <any>fromLocation);
        }
        if (infant !== undefined && infant !== null) {
            queryParameters = queryParameters.set('infant', <any>infant);
        }
        if (planId !== undefined && planId !== null) {
            queryParameters = queryParameters.set('planId', <any>planId);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (regions) {
            regions.forEach((element) => {
                queryParameters = queryParameters.append('regions', <any>element);
            })
        }
        if (returnDate !== undefined && returnDate !== null) {
            queryParameters = queryParameters.set('returnDate', <any>returnDate);
        }
        if (toLocation !== undefined && toLocation !== null) {
            queryParameters = queryParameters.set('toLocation', <any>toLocation);
        }
        if (numberOfNonIns !== undefined && numberOfNonIns !== null) {
            queryParameters = queryParameters.set('numberOfNonIns', <any>numberOfNonIns);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OtaResultOfListOfSSRItemDTO>(`${this.basePath}/api/flexi-api/insurance-plans`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
