import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromPromotionFilterByVoucher from './promotion-filter-by-voucher/promotion-filter-by-voucher.reducer';
import { PromotionFilterByVoucherEffects } from './promotion-filter-by-voucher/promotion-filter-by-voucher.effects';
import { PromotionFilterByVoucherFacade } from './promotion-filter-by-voucher/promotion-filter-by-voucher.facade';
import { BASE_PATH } from '../lib/variables';
import { environment } from '@env/environment';
import { PromotionResourceService } from '../lib';
import * as fromPromotionValidate from './promotion-validate/promotion-validate.reducer';
import { PromotionValidateEffects } from './promotion-validate/promotion-validate.effects';
import { PromotionValidateFacade } from './promotion-validate/promotion-validate.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromPromotionFilterByVoucher.PROMOTIONFILTERBYVOUCHER_FEATURE_KEY,
      fromPromotionFilterByVoucher.reducer
    ),
    EffectsModule.forFeature([PromotionFilterByVoucherEffects]),
    StoreModule.forFeature(
      fromPromotionValidate.PROMOTIONVALIDATE_FEATURE_KEY,
      fromPromotionValidate.reducer
    ),
    EffectsModule.forFeature([PromotionValidateEffects])
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.pricingsrv },
    PromotionFilterByVoucherFacade,
    PromotionResourceService,
    PromotionValidateFacade
  ]
})
export class PricingsrvStateModule {}
