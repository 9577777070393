import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MARKASUNREAD_FEATURE_KEY,
  MarkAsUnreadState
} from './mark-as-unread.reducer';

// Lookup the 'MarkAsUnread' feature state managed by NgRx
const getMarkAsUnreadState = createFeatureSelector<MarkAsUnreadState>(
  MARKASUNREAD_FEATURE_KEY
);

const getLoaded = createSelector(
  getMarkAsUnreadState,
  (state: MarkAsUnreadState) => state.loaded
);
const getError = createSelector(
  getMarkAsUnreadState,
  (state: MarkAsUnreadState) => state.error
);

const getAllMarkAsUnread = createSelector(
  getMarkAsUnreadState,
  getLoaded,
  (state: MarkAsUnreadState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getMarkAsUnreadState,
  (state: MarkAsUnreadState) => state.selectedId
);
const getSelectedMarkAsUnread = createSelector(
  getAllMarkAsUnread,
  getSelectedId,
  (markAsUnread, id) => {
    return markAsUnread ? Object.assign({}, markAsUnread) : undefined;
  }
);

export const markAsUnreadQuery = {
  getLoaded,
  getError,
  getAllMarkAsUnread,
  getSelectedMarkAsUnread
};
