import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SHORTPROFILE_FEATURE_KEY,
  ShortProfileState
} from './short-profile.reducer';

// Lookup the 'ShortProfile' feature state managed by NgRx
const getShortProfileState = createFeatureSelector<ShortProfileState>(
  SHORTPROFILE_FEATURE_KEY
);

const getLoaded = createSelector(
  getShortProfileState,
  (state: ShortProfileState) => state.loaded
);
const getError = createSelector(
  getShortProfileState,
  (state: ShortProfileState) => state.error
);

const getAllShortProfile = createSelector(
  getShortProfileState,
  getLoaded,
  (state: ShortProfileState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getShortProfile = createSelector(
  getShortProfileState,
  getLoaded,
  (state: ShortProfileState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getShortProfileState,
  (state: ShortProfileState) => state.selectedId
);
const getSelectedShortProfile = createSelector(
  getAllShortProfile,
  getSelectedId,
  (shortProfile, id) => {
    return shortProfile ? Object.assign({}, shortProfile) : undefined;
  }
);

export const shortProfileQuery = {
  getLoaded,
  getError,
  getAllShortProfile,
  getShortProfile,
  getSelectedShortProfile
};
