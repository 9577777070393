import { Component, OnInit } from '@angular/core';
import {
  BusinessDTO,
  ApprovalInvoiceResourceService
} from '@gtd/api-services/utilitysrv';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AccountFacade } from '../../../../../b2c-client/src/lib/stage/account/account.facade';

export interface EinvoicedBusinessInformationListElement {
  businessName: string;
  taxCode: string;
  address: string;
}

@Component({
  selector: 'gtd-einvoiced-business-information',
  templateUrl: './einvoiced-business-information.component.html',
  styleUrls: ['./einvoiced-business-information.component.scss']
})
export class EinvoicedBusinessInformationComponent implements OnInit {
  subscriptions = new Subscription();
  displayedColumns: string[] = ['businessName', 'taxCode', 'address'];
  einvoicedBusinessInformationList: EinvoicedBusinessInformationListElement[] = [];
  dataSource: any;

  constructor(
    private accountFacade: AccountFacade,
    private approvalInvoiceResourceService: ApprovalInvoiceResourceService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.accountFacade.getAccountLogged$.subscribe(selectedAccount => {
        if (selectedAccount && selectedAccount.userRefCode) {
          this.approvalInvoiceResourceService
            .getBusinessByUserRefCodeAndStatusUsingGET(
              selectedAccount.userRefCode
            )
            .subscribe((res: Array<BusinessDTO>) => {
              if (res.length > 0) {
                res.forEach(business => {
                  this.einvoicedBusinessInformationList.push({
                    businessName: business.businessName,
                    taxCode: business.taxCode,
                    address: business.address
                  });
                });
              }
              this.dataSource = new MatTableDataSource<
                EinvoicedBusinessInformationListElement
              >(this.einvoicedBusinessInformationList);
            });
        }
      })
    );
  }
}
