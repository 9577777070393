import { FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';

// custom validator to check that two fields match
export function MustNotMatch(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.mustNOtMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matching Control if validation fails
    if (control.value === matchingControl.value) {
      matchingControl.setErrors({ mustNOtMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
