/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TourContentReferenceGroup } from './tourContentReferenceGroup';


export interface TourContentReference { 
    group?: TourContentReferenceGroup;
    hasValue?: boolean;
    id?: number;
    name?: string;
    symbol?: string;
    type?: TourContentReference.TypeEnum;
    value?: string;
}
export namespace TourContentReference {
    export type TypeEnum = 'ACTIVITY_CATEGORY' | 'ACTIVITY_AMENITY' | 'RATE_PLAN_AMENITY' | 'ACTIVITY_THEME';
    export const TypeEnum = {
        ACTIVITYCATEGORY: 'ACTIVITY_CATEGORY' as TypeEnum,
        ACTIVITYAMENITY: 'ACTIVITY_AMENITY' as TypeEnum,
        RATEPLANAMENITY: 'RATE_PLAN_AMENITY' as TypeEnum,
        ACTIVITYTHEME: 'ACTIVITY_THEME' as TypeEnum
    };
}
