import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { ActivateAccountPartialState } from './activate-account.reducer';
import { activateAccountQuery } from './activate-account.selectors';
import { LoadActivateAccount } from './activate-account.actions';

@Injectable()
export class ActivateAccountFacade {
  loaded$ = this.store.pipe(select(activateAccountQuery.getLoaded));
  allActivateAccount$ = this.store.pipe(
    select(activateAccountQuery.getAllActivateAccount)
  );
  selectedActivateAccount$ = this.store.pipe(
    select(activateAccountQuery.getSelectedActivateAccount)
  );

  constructor(private store: Store<ActivateAccountPartialState>) {}

  activateAccount(key: string) {
    this.store.dispatch(new LoadActivateAccount(key));
  }
}
