import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-loading-iframe-filter-panel',
  templateUrl: './loading-iframe-filter-panel.component.html',
  styleUrls: ['./loading-iframe-filter-panel.component.scss']
})
export class LoadingIframeFilterPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
