import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { RegisterStatePartialState } from './register-state.reducer';
import { registerStateQuery } from './register-state.selectors';
import {LoadRegisterState, ResetAllRegisterState} from './register-state.actions';
import {ManagedUserVM} from "@gtd/b2c-client";

@Injectable()
export class RegisterStateFacade {
  loaded$ = this.store.pipe(select(registerStateQuery.getLoaded));
  allRegisterState$ = this.store.pipe(
    select(registerStateQuery.getAllRegisterState)
  );
  selectedRegisterState$ = this.store.pipe(
    select(registerStateQuery.getSelectedRegisterState)
  );

  constructor(private store: Store<RegisterStatePartialState>) {}

  resetAllRegister() {
    this.store.dispatch(new ResetAllRegisterState());
  }
  registerAccount(managedUserVM: ManagedUserVM) {
    this.store.dispatch(new LoadRegisterState(managedUserVM));
  }
}
