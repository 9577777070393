import {Injectable} from '@angular/core';

import {select, Store} from '@ngrx/store';

import {PhoneRegisterStatePartialState} from './phone-register-state.reducer';
import {phoneRegisterStateQuery} from './phone-register-state.selectors';
import {
  LoadDestroyPhoneRegisterState,
  LoadPhoneRegisterState,
  ResetAllPhoneRegisterState,
  LoadPhoneOTPRegisterState, LoadDestroyPhoneOTPRegisterState
} from './phone-register-state.actions';
import {RegisterPhoneNumberVM} from "@gtd/b2c-client";

@Injectable()
export class PhoneRegisterStateFacade {
  loaded$ = this.store.pipe(select(phoneRegisterStateQuery.getLoaded));
  allPhoneRegisterState$ = this.store.pipe(
    select(phoneRegisterStateQuery.getAllPhoneRegisterState)
  );
  selectedPhoneRegisterState$ = this.store.pipe(
    select(phoneRegisterStateQuery.getSelectedPhoneRegisterState)
  );
  resetAllRegister() {
    this.store.dispatch(new ResetAllPhoneRegisterState());
  }
  constructor(private store: Store<PhoneRegisterStatePartialState>) {}

  phoneRegisterAccount(registerPhoneNumberVM: RegisterPhoneNumberVM) {
    this.store.dispatch(new LoadPhoneRegisterState(registerPhoneNumberVM));
  }
  destroyRequestPassword() {
    this.store.dispatch(new LoadDestroyPhoneRegisterState());
  }
  destroyPhoneOTPRegister() {
    this.store.dispatch(new LoadDestroyPhoneOTPRegisterState());
  }

  phoneOTPRegisterAccount(registerPhoneNumberVM: RegisterPhoneNumberVM) {
    this.store.dispatch(new LoadPhoneOTPRegisterState(registerPhoneNumberVM));
  }
}
