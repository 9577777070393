/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { ChangeStatusReq } from '../model/changeStatusReq';
import { MarkupDTO } from '../model/markupDTO';
import { MarkupDefault } from '../model/markupDefault';
import { MarkupTO } from '../model/markupTO';
import { PriorityMarkupDTO } from '../model/priorityMarkupDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class MarkupB2BResourceService {
  protected basePath = 'https://10.7.71.20:8588/b2b_gateway';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * changePriority
   *
   * @param priorityMarkupTO priorityMarkupTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public changePriorityUsingPUT(
    priorityMarkupTO: PriorityMarkupDTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<MarkupDTO>>;
  public changePriorityUsingPUT(
    priorityMarkupTO: PriorityMarkupDTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<MarkupDTO>>>;
  public changePriorityUsingPUT(
    priorityMarkupTO: PriorityMarkupDTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<MarkupDTO>>>;
  public changePriorityUsingPUT(
    priorityMarkupTO: PriorityMarkupDTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (priorityMarkupTO === null || priorityMarkupTO === undefined) {
      throw new Error(
        'Required parameter priorityMarkupTO was null or undefined when calling changePriorityUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<Array<MarkupDTO>>(
      `${this.basePath}/api/change-priority/markup-b2bs`,
      priorityMarkupTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * changeStatus
   *
   * @param id id
   * @param changeStatusReq changeStatusReq
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public changeStatusUsingPUT4(
    id: number,
    changeStatusReq: ChangeStatusReq,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public changeStatusUsingPUT4(
    id: number,
    changeStatusReq: ChangeStatusReq,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public changeStatusUsingPUT4(
    id: number,
    changeStatusReq: ChangeStatusReq,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public changeStatusUsingPUT4(
    id: number,
    changeStatusReq: ChangeStatusReq,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling changeStatusUsingPUT4.'
      );
    }

    if (changeStatusReq === null || changeStatusReq === undefined) {
      throw new Error(
        'Required parameter changeStatusReq was null or undefined when calling changeStatusUsingPUT4.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/api/markup-b2bs/${encodeURIComponent(
        String(id)
      )}/status`,
      changeStatusReq,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * copyMarkup
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public copyMarkupUsingPUT(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<MarkupTO>;
  public copyMarkupUsingPUT(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<MarkupTO>>;
  public copyMarkupUsingPUT(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<MarkupTO>>;
  public copyMarkupUsingPUT(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling copyMarkupUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.put<MarkupTO>(
      `${this.basePath}/api/markup-b2bs/${encodeURIComponent(String(id))}/copy`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * createMarkup
   *
   * @param markupTO markupTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createMarkupUsingPOST(
    markupTO: MarkupTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<MarkupTO>;
  public createMarkupUsingPOST(
    markupTO: MarkupTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<MarkupTO>>;
  public createMarkupUsingPOST(
    markupTO: MarkupTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<MarkupTO>>;
  public createMarkupUsingPOST(
    markupTO: MarkupTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (markupTO === null || markupTO === undefined) {
      throw new Error(
        'Required parameter markupTO was null or undefined when calling createMarkupUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<MarkupTO>(
      `${this.basePath}/api/markup-b2bs`,
      markupTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * deleteMarkup
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteMarkupUsingDELETE(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteMarkupUsingDELETE(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteMarkupUsingDELETE(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteMarkupUsingDELETE(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteMarkupUsingDELETE.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.delete<any>(
      `${this.basePath}/api/markup-b2bs/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getAllDefaultMarkup
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllDefaultMarkupUsingGET(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<MarkupDefault>>;
  public getAllDefaultMarkupUsingGET(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<MarkupDefault>>>;
  public getAllDefaultMarkupUsingGET(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<MarkupDefault>>>;
  public getAllDefaultMarkupUsingGET(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<MarkupDefault>>(
      `${this.basePath}/api/markup-b2bs/all-default-markup`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getAllMarkups
   *
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllMarkupsUsingGET(
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<MarkupDTO>>;
  public getAllMarkupsUsingGET(
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<MarkupDTO>>>;
  public getAllMarkupsUsingGET(
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<MarkupDTO>>>;
  public getAllMarkupsUsingGET(
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<MarkupDTO>>(
      `${this.basePath}/api/markup-b2bs`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getMarkup
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMarkupUsingGET(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<MarkupTO>;
  public getMarkupUsingGET(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<MarkupTO>>;
  public getMarkupUsingGET(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<MarkupTO>>;
  public getMarkupUsingGET(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getMarkupUsingGET.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<MarkupTO>(
      `${this.basePath}/api/markup-b2bs/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * searchObjects
   *
   * @param businessType businessType
   * @param status status
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchObjectsUsingGET5(
    businessType?: string,
    status?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<MarkupDTO>>;
  public searchObjectsUsingGET5(
    businessType?: string,
    status?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<MarkupDTO>>>;
  public searchObjectsUsingGET5(
    businessType?: string,
    status?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<MarkupDTO>>>;
  public searchObjectsUsingGET5(
    businessType?: string,
    status?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (businessType !== undefined && businessType !== null) {
      queryParameters = queryParameters.set('businessType', <any>businessType);
    }
    if (status !== undefined && status !== null) {
      queryParameters = queryParameters.set('status', <any>status);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach(element => {
        queryParameters = queryParameters.append('sort', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<Array<MarkupDTO>>(
      `${this.basePath}/api/_search/markup-b2bs`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * updateMarkup
   *
   * @param markupTO markupTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateMarkupUsingPUT(
    markupTO: MarkupTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<MarkupTO>;
  public updateMarkupUsingPUT(
    markupTO: MarkupTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<MarkupTO>>;
  public updateMarkupUsingPUT(
    markupTO: MarkupTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<MarkupTO>>;
  public updateMarkupUsingPUT(
    markupTO: MarkupTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (markupTO === null || markupTO === undefined) {
      throw new Error(
        'Required parameter markupTO was null or undefined when calling updateMarkupUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<MarkupTO>(
      `${this.basePath}/api/markup-b2bs`,
      markupTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
