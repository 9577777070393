import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { SearchKeywordElement } from '@gtd/b2c-client';
import { PopularPlacesFacade } from '@gtd/meta';
import { HotelSearchService } from 'libs/hotel/src/lib/hotel-form-search/hotel-search.service';
import { AutocompleteSearchKeywordFacade } from 'libs/hotel/src/state/autocomplete-search-keyword/autocomplete-search-keyword.facade';
import { SearchKeywordFacade } from 'libs/hotel/src/state/search-keyword/search-keyword.facade';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'gtd-destination-autocomplete',
  templateUrl: './destination-autocomplete.component.html',
  styleUrls: ['./destination-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DestinationAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DestinationAutocompleteComponent),
      multi: true
    }
  ]
})
export class DestinationAutocompleteComponent implements OnInit {
  @Input() value: string;
  @Input() submited: boolean;
  @Input() defaultLocation: any;
  // form: FormGroup;
  focused = false;
  isShiftFocus: boolean;
  // stateCtrl = new FormControl();
  hotelName = new FormControl('', [Validators.required]);
  stateChanges = new Subject<void>();
  groupSearchLabel: string = 'destination-popular';
  destinationDTOs: SearchKeywordElement[];
  destination: any;
  errorState = false;
  isContentPage = false;

  allSearchKeyword$ = this.searchKeywordFacade.allSearchKeyword$;
  allPopularPlaces$ = this.popularPlacesFacade.allPopularPlaces$;
  destinationSelect: any;
  constructor(
    public fb: FormBuilder,
    private hotelSearchService: HotelSearchService,
    private searchKeywordFacade: SearchKeywordFacade,
    private popularPlacesFacade: PopularPlacesFacade,
    private autocompleteSearchKeywordFacade: AutocompleteSearchKeywordFacade
  ) {}

  ngOnInit() {
    this.popularPlacesFacade.getPopularDestination('FAVOURITE_HOTEL_LOCATION');
    this.destinationDTOs = this.hotelSearchService.getAllLocalStorageLocation(
      'location'
    );

    this.hotelName.valueChanges.pipe(debounceTime(200)).subscribe(data => {
      if (typeof data === 'string') {
        const currentLanguage = localStorage.getItem('language');
        let language: 'vi' | 'en';
        if (currentLanguage === 'vi') {
          language = 'vi';
        } else if (currentLanguage === 'en') {
          language = 'en';
        }
        this.searchKeywordFacade.loadAll(data, language, 0, 15);
      }
      if (data || data !== '') {
        this.groupSearchLabel = 'list-destination';
        if (this.destination && data == this.destination.name) {
          this.onChange(this.destination);
          this.onTouched();
        } else {
          let searchData = {
            name: data,
            propertyCount: null,
            locationCode: data,
            searchCode: data,
            locationName: data,
            searchType: 'AUTO',
            supplier: 'EXPEDIA'
          };
          this.onChange(searchData);
          this.onTouched();
        }
      } else {
        this.onChange('');
        this.onTouched();
      }
    });
  }

  destinationSelected(destination: any) {
    this.autocompleteSearchKeywordFacade.addSearchKeywordHotel([destination]);
    this.hotelName.setValue(destination.name);
    this.destination = destination;
    this.focused = false;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.destination = obj;
      this.destination.searchCode = obj.locationCode;
      this.hotelName.setValue(obj.locationName);
      // this.form.setValue(obj);
    }

    if (obj === null) {
      this.hotelName.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  onChange: any = () => {};
  onTouched: any = () => {};
  validate(_: FormControl) {
    return this.hotelName.valid ? null : { location: { valid: false } };
  }
}
