import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CovidDialogComponent } from './covid-dialog.component';
import { MatRippleModule } from '@angular/material';
import { LoadingModule } from '@gtd/components/loading';
import { CovidDialogService } from './covid-dialog.service';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [CommonModule, MatRippleModule, LoadingModule, TranslateModule],
  declarations: [CovidDialogComponent],
  exports: [CovidDialogComponent],
  providers: [CovidDialogService],
  entryComponents: [CovidDialogComponent]
})
export class CovidDialogModule {}
