import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {BookingInfo, BookingTransactionInfo, GroupBooking} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-booking-result-flight',
  templateUrl: './booking-result-flight.component.html',
  styleUrls: ['./booking-result-flight.component.scss']
})
export class BookingResultFlightComponent implements OnInit {
  @Input() allFinalBookingDetail: GroupBooking;
  constructor() { }

  ngOnInit() {
  }

}
@Pipe({
  name: 'getPassengerNameRecord'
})
export class GetPassengerNameRecord implements PipeTransform {
  transform(bookingInfo: BookingInfo, type: 'DEPARTURE'|'RETURN'): string {
    let bookingTransactionInfos = new Array<BookingTransactionInfo>();
    switch (type) {
      case "DEPARTURE":
        bookingTransactionInfos = bookingInfo.transactionInfos.filter(trans => trans.originLocationCode === bookingInfo.fromLocationCode);
        break;
      case "RETURN":
        bookingTransactionInfos = bookingInfo.transactionInfos.filter(trans => trans.originLocationCode === bookingInfo.toLocationCode);
        break;
    }
    if(bookingTransactionInfos && bookingTransactionInfos.length > 0) {
      return bookingTransactionInfos[0].passengerNameRecord;
    }
    return '';
  }

}
