import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit, Output,
  ViewEncapsulation
} from '@angular/core';
import {fadeIn, slideInHorizontal, slideInVertical} from '@gtd/helpers';
import {AirItineraryInfo, FlightSegment, GroupPricedItinerary, PricedItinerary} from "@gtd/b2c-client";
import {GroupItineraryFacade} from "../flight-search/group-itinerary/+state/group-itinerary.facade";
import {Subscription} from "rxjs";
import {FlightResultItemService} from "./flight-result-item.service";
import {FarerulesFacade} from "../flight-search/farerules/+state/farerules.facade";
// import {MatMenuTrigger} from "@angular/material";
import {
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from "@angular/forms";
import {AvailableFlightsFacade} from "../flight-search/flight-cache-search/+state/available-flights.facade";
import {environment} from "@env/environment";
import {SelectItemSrvService} from "../select-item-srv.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {PixelTrackingService} from "@gtd/pixel-tracking";
import {GaTrackingService} from "@gtd/ga-tracking";

@Component({
  selector: 'gtd-flight-result-item',
  templateUrl: './flight-result-item.component.html',
  styleUrls: ['./flight-result-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInHorizontal, slideInVertical, fadeIn],
  encapsulation: environment.appName === 'B2B2C'? ViewEncapsulation.None: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlightResultItemComponent),
      multi: true
    }
  ],
})
export class FlightResultItemComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() formSearchResult?: any;
  @Input() groupPricedItineraries?: GroupPricedItinerary;
  @Input() searchId?: string;
  @Input() isCreateBooking: boolean;

  @Input() itemFlight: number;
  @Input() disableButton: boolean;

  @Input() cabinClassOptions : Array<string>;
  // @ViewChild('clickHoverMenuTrigger', {static: true}) clickHoverMenuTrigger: MatMenuTrigger;
  @Output() onNewTab = new EventEmitter<any>();

  itemFormGroup: FormGroup;
  flightSegments?: Array<FlightSegment>;
  subscriptions = new Subscription();

  panelOpenState: boolean;
  isCollapsed: boolean;
  isDetailJourney: boolean;
  submitLoadMore: boolean;
  submitLoadFareRule: boolean;
  fareSourceCode: string;

  itemFlightSelect: number;

  allGroupItinerary$ = this.groupItineraryFacade.allGroupItinerary$;

  allFarerules$ = this.farerulesFacade.allFarerules$;
  loaded$ = this.availableFlightsFacade.loaded$;

  appName = environment.appName;

  constructor(
    private formBuilder: FormBuilder,
    private groupItineraryFacade: GroupItineraryFacade,
    private farerulesFacade: FarerulesFacade,
    private availableFlightsFacade: AvailableFlightsFacade,
    private flightResultItemService: FlightResultItemService,
    public controlContainer: ControlContainer,
    private selectItemSrvService: SelectItemSrvService,
    private deviceDetectorService: DeviceDetectorService,
    private _pixelTrackingService: PixelTrackingService,
    private _gaTrackingService: GaTrackingService,
  ) {
  }

  ngOnInit() {
    this.itemFormGroup = new FormGroup({
      listSelected: this.formBuilder.group({
        from: [null, Validators.required] ,
        to: [null],
        roundType: [PricedItinerary.DirectionIndEnum.DEPARTURE],
      })
    });

    this.subscriptions.add(
      this.selectItemSrvService.getIsLoadedChildren.subscribe(loaded => {
        if(loaded) {
          this.itemFlightSelect = loaded;
        }
      })
    )

    this.flightSegments = this.groupPricedItineraries.pricedItineraries[0].originDestinationOptions[0].flightSegments;
    this.flightResultItemService.getOpenPanel.subscribe(openPanel=> {
      if(openPanel) {
        setTimeout(() => {
          this.isCollapsed = false;
        },50)
      }
    });
    this.subscriptions.add(
      this.groupItineraryFacade.allGroupItinerary$.subscribe(allGroupPriceItinerary => {
        if(allGroupPriceItinerary) {
          this.submitLoadMore = false;
        }
      })
    )
    this.subscriptions.add(
      this.farerulesFacade.allFarerules$.subscribe(allFarerules => {
        if(allFarerules) {
          this.fareSourceCode = null;
          this.submitLoadFareRule = false;
        }
      })
    );
  }

  getFareRules(fareSourceCode: string) {
    const currentLanguage = localStorage.getItem('language');
    this.fareSourceCode = fareSourceCode;
    this.submitLoadFareRule = true;
    this.farerulesFacade.loadAll(
      {
      fareSourceCode: fareSourceCode,
      groupId: this.groupPricedItineraries.groupId,
      searchId: this.searchId
      },
      currentLanguage);
  }

  itineraryMore(flightDirection?: string) {
    this.isDetailJourney = false;
    this._gaTrackingService.track('F_SearchResult_Select');
    if(!this.isCollapsed) {
      this.selectItemSrvService.setIsLoadedChildren(-1);
      this.submitLoadMore = true;
      this.flightResultItemService.setOpenPanel(true);
      let departureItinerary = null;
      if(this.formSearchResult && this.formSearchResult.listSelected && this.formSearchResult.listSelected.from) {
        departureItinerary = {
          groupId: this.formSearchResult.listSelected.from.itemFlight.groupId,
          airlineCode: this.formSearchResult.listSelected.from.itemFlight.airline,
          fareSourceCode: this.formSearchResult.listSelected.from.itemFlight.pricedItineraries[0].airItineraryPricingInfo.fareSourceCode,
          supplierCode: this.formSearchResult.listSelected.from.itemFlight.airSupplier,
          searchId: this.formSearchResult.listSelected.from.searchId
        } as AirItineraryInfo;
      }

      this.groupItineraryFacade.loadAll({
        id: this.groupPricedItineraries.groupId,
        airSearchId: {
          searchId: this.searchId,
          departureItinerary: flightDirection === 'R'? departureItinerary: null,
          filter: {
            stopOptions: [],
            airlineOptions: [],
            cabinClassOptions: this.cabinClassOptions,
            ticketPolicyOptions: [],
            departureDateTimeOptions: [],
            arrivalDateTimeOptions: [],
            departureDateTimeReturnOptions: [],
            arrivalDateTimeReturnOptions: [],
            flightType: this.groupPricedItineraries.flightType,
            groupId: this.groupPricedItineraries.groupId,
            loadMore: true,
            step: "1"
          }
        },
        page: 0,
        size: 1000,
        sort: ['departureDate', 'asc']
      })
      setTimeout(() => {
        this.isCollapsed = true;
      },100)
    } else {
      this.isCollapsed = false;
    }
  }

  selectItem(pricedItinerary: PricedItinerary, itemFlight: number, children?: boolean) {
    if(this.groupPricedItineraries.roundType === 'ONEWAY' || (this.groupPricedItineraries.roundType === 'ROUNDTRIP' && this.formSearchResult.listSelected !== null)) {
      if(this.deviceDetectorService.isDesktop() === true && this.appName !== 'B2B2C') {
        localStorage.setItem('currentLanguage', localStorage.getItem('language'));
        // Opening a new tab while waiting the response from the server.
        this.onNewTab.emit(window.open('/checkout/flight'));
      }
    }

    if(!this.isCreateBooking) {
      if(children) {
        this.selectItemSrvService.setIsLoadedChildren(itemFlight+1);
      } else {
        this.selectItemSrvService.setIsLoaded(itemFlight);
      }

      if(pricedItinerary.originDestinationOptions[0].flightDirection === 'D') {
        this.itemFormGroup.controls['listSelected'].setValue({
          from: {
            searchId: this.searchId,
            itemFlight: this.groupPricedItineraries,
            pricedItinerary: pricedItinerary
          },
          to: null,
          roundType: PricedItinerary.DirectionIndEnum.DEPARTURE
        });
      } else {
        this.itemFormGroup.controls['listSelected'].patchValue({
          to: {
            searchId: this.searchId,
            itemFlight: this.groupPricedItineraries,
            pricedItinerary: pricedItinerary
          },
          roundType: PricedItinerary.DirectionIndEnum.RETURN
        })
      }
      this.onChange(this.itemFormGroup.controls['listSelected'].value);
      this.onTouched();
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  writeValue(obj: any): void {
    if (obj) {
      this.itemFormGroup.controls['listSelected'].setValue(obj);
    }
    if (obj === null) {
      this.itemFormGroup.reset();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
