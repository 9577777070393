/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { BankTransferHistoryDTO } from '../model/bankTransferHistoryDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BankTransferHistoryResourceService {

    protected basePath = 'https://10.7.71.20:8282';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createBankTransferHistory
     * 
     * @param bankTransferHistoryDTO bankTransferHistoryDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBankTransferHistoryUsingPOST(bankTransferHistoryDTO: BankTransferHistoryDTO, observe?: 'body', reportProgress?: boolean): Observable<BankTransferHistoryDTO>;
    public createBankTransferHistoryUsingPOST(bankTransferHistoryDTO: BankTransferHistoryDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BankTransferHistoryDTO>>;
    public createBankTransferHistoryUsingPOST(bankTransferHistoryDTO: BankTransferHistoryDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BankTransferHistoryDTO>>;
    public createBankTransferHistoryUsingPOST(bankTransferHistoryDTO: BankTransferHistoryDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bankTransferHistoryDTO === null || bankTransferHistoryDTO === undefined) {
            throw new Error('Required parameter bankTransferHistoryDTO was null or undefined when calling createBankTransferHistoryUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BankTransferHistoryDTO>(`${this.basePath}/api/bank-transfer-histories`,
            bankTransferHistoryDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteBankTransferHistory
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBankTransferHistoryUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteBankTransferHistoryUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteBankTransferHistoryUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteBankTransferHistoryUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteBankTransferHistoryUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/bank-transfer-histories/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllBankTransferHistories
     * 
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllBankTransferHistoriesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<BankTransferHistoryDTO>>;
    public getAllBankTransferHistoriesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BankTransferHistoryDTO>>>;
    public getAllBankTransferHistoriesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BankTransferHistoryDTO>>>;
    public getAllBankTransferHistoriesUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BankTransferHistoryDTO>>(`${this.basePath}/api/bank-transfer-histories`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBankTransferHistory
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBankTransferHistoryUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<BankTransferHistoryDTO>;
    public getBankTransferHistoryUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BankTransferHistoryDTO>>;
    public getBankTransferHistoryUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BankTransferHistoryDTO>>;
    public getBankTransferHistoryUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getBankTransferHistoryUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BankTransferHistoryDTO>(`${this.basePath}/api/bank-transfer-histories/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getNewTransactionHistoryToProcess
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNewTransactionHistoryToProcessUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<BankTransferHistoryDTO>>;
    public getNewTransactionHistoryToProcessUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BankTransferHistoryDTO>>>;
    public getNewTransactionHistoryToProcessUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BankTransferHistoryDTO>>>;
    public getNewTransactionHistoryToProcessUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BankTransferHistoryDTO>>(`${this.basePath}/api/get-new-transaction-history-to-process`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchBankTransferHistories
     * 
     * @param actionType actionType
     * @param actionStatus actionStatus
     * @param fromDate fromDate
     * @param toDate toDate
     * @param description description
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBankTransferHistoriesUsingGET(actionType?: string, actionStatus?: string, fromDate?: string, toDate?: string, description?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<BankTransferHistoryDTO>>;
    public searchBankTransferHistoriesUsingGET(actionType?: string, actionStatus?: string, fromDate?: string, toDate?: string, description?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BankTransferHistoryDTO>>>;
    public searchBankTransferHistoriesUsingGET(actionType?: string, actionStatus?: string, fromDate?: string, toDate?: string, description?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BankTransferHistoryDTO>>>;
    public searchBankTransferHistoriesUsingGET(actionType?: string, actionStatus?: string, fromDate?: string, toDate?: string, description?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (actionType !== undefined && actionType !== null) {
            queryParameters = queryParameters.set('actionType', <any>actionType);
        }
        if (actionStatus !== undefined && actionStatus !== null) {
            queryParameters = queryParameters.set('actionStatus', <any>actionStatus);
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate);
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BankTransferHistoryDTO>>(`${this.basePath}/api/search-bank-transfer-histories`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBankTransferHistory
     * 
     * @param bankTransferHistoryDTO bankTransferHistoryDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBankTransferHistoryUsingPUT(bankTransferHistoryDTO: BankTransferHistoryDTO, observe?: 'body', reportProgress?: boolean): Observable<BankTransferHistoryDTO>;
    public updateBankTransferHistoryUsingPUT(bankTransferHistoryDTO: BankTransferHistoryDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BankTransferHistoryDTO>>;
    public updateBankTransferHistoryUsingPUT(bankTransferHistoryDTO: BankTransferHistoryDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BankTransferHistoryDTO>>;
    public updateBankTransferHistoryUsingPUT(bankTransferHistoryDTO: BankTransferHistoryDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bankTransferHistoryDTO === null || bankTransferHistoryDTO === undefined) {
            throw new Error('Required parameter bankTransferHistoryDTO was null or undefined when calling updateBankTransferHistoryUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BankTransferHistoryDTO>(`${this.basePath}/api/bank-transfer-histories`,
            bankTransferHistoryDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
