/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface RegisterPhoneNumberVM { 
    firstName?: string;
    language?: RegisterPhoneNumberVM.LanguageEnum;
    lastName?: string;
    otpCode?: string;
    password?: string;
    phoneNumber?: string;
    referencedBy?: string;
    userRefCode?: string;
    verificationCode?: string;
}
export namespace RegisterPhoneNumberVM {
    export type LanguageEnum = 'EN' | 'VI';
    export const LanguageEnum = {
        EN: 'EN' as LanguageEnum,
        VI: 'VI' as LanguageEnum
    };
}
