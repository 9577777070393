import {
  SearchKeywordAction,
  SearchKeywordActionTypes
} from './search-keyword.actions';
import { OtaResultOfSearchKeyWordResult } from '@gtd/b2c-client';

export const SEARCHKEYWORD_FEATURE_KEY = 'comboVJSearchKeyword';

/**
 * Interface for the 'SearchKeyword' data used in
 *  - SearchKeywordState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface SearchKeywordState {
  list: OtaResultOfSearchKeyWordResult; // list of SearchKeyword; analogous to a sql normalized table
  selectedId?: string | number; // which SearchKeyword record has been selected
  loaded: boolean; // has the SearchKeyword list been loaded
  error?: any; // last none error (if any)
}

export interface SearchKeywordPartialState {
  readonly [SEARCHKEYWORD_FEATURE_KEY]: SearchKeywordState;
}

export const initialState: SearchKeywordState = {
  list: null,
  loaded: false
};

export function searchKeywordReducer(
  state: SearchKeywordState = initialState,
  action: SearchKeywordAction
): SearchKeywordState {
  switch (action.type) {
    case SearchKeywordActionTypes.ComboVJSearchKeywordLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
