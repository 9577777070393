import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { CustomerTravellerPartialState } from './customer-traveller.reducer';
import {
  LoadCustomerTraveller,
  CustomerTravellerLoaded,
  CustomerTravellerLoadError,
  CustomerTravellerActionTypes,
  UpdateCustomerTraveller,
  CustomerTravellerUpdateError,
  LoadAllCustomerTraveller,
  CustomerTravellerLoadAllError, RemoveCustomerTraveller, CustomerTravellerRemoveError
} from './customer-traveller.actions';
import {map} from "rxjs/operators";
import {CustomerTravellerResourceService} from "@gtd/api-services/customer-service";
import {CustomerResourceService} from "@gtd/b2c-client";

@Injectable()
export class CustomerTravellerEffects {
  @Effect() loadCustomerTraveller$ = this.dataPersistence.fetch(
    CustomerTravellerActionTypes.LoadCustomerTraveller,
    {
      run: (
        action: LoadCustomerTraveller,
        state: CustomerTravellerPartialState
      ) => {
        return this.customerTravellerResourceService.getCustomerTravellerUsingGET(action.payload).pipe(
          map(update => ({
            type: CustomerTravellerActionTypes.CustomerTravellerLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadCustomerTraveller, error) => {
        console.error('Error', error);
        return new CustomerTravellerLoadError(error);
      }
    }
  );

  @Effect() loadAllCustomerTraveller$ = this.dataPersistence.fetch(
    CustomerTravellerActionTypes.LoadAllCustomerTraveller,
    {
      run: (
        action: LoadAllCustomerTraveller,
        state: CustomerTravellerPartialState
      ) => {
        return this.customerResourceService.getAllCustomerTravellersUsingGET(0, 300).pipe(
          map(update => ({
            type: CustomerTravellerActionTypes.CustomerTravellerLoadedAll,
            payload: update
          }))
        );
      },
      onError: (action: LoadAllCustomerTraveller, error) => {
        console.error('Error', error);
        return new CustomerTravellerLoadAllError(error);
      }
    }
  );

  @Effect() updateCustomerTraveller$ = this.dataPersistence.fetch(
    CustomerTravellerActionTypes.UpdateCustomerTraveller,
    {
      run: (
        action: UpdateCustomerTraveller,
        state: CustomerTravellerPartialState
      ) => {
        return this.customerResourceService.updateAirCustomerTravellerUsingPUT(action.payload).pipe(
          map(update => ({
            type: CustomerTravellerActionTypes.CustomerTravellerUpdated,
            payload: update
          }))
        );
      },
      onError: (action: UpdateCustomerTraveller, error) => {
        console.error('Error', error);
        return new CustomerTravellerUpdateError(error);
      }
    }
  );

  @Effect() removeCustomerTraveller$ = this.dataPersistence.fetch(
    CustomerTravellerActionTypes.RemoveCustomerTraveller,
    {
      run: (
        action: RemoveCustomerTraveller,
        state: CustomerTravellerPartialState
      ) => {
        return this.customerTravellerResourceService.deleteCustomerTravellerUsingDELETE(action.payload).pipe(
          map(update => ({
            type: CustomerTravellerActionTypes.CustomerTravellerRemoved,
            payload: {
              data: action.payload,
              result: update? update : 'Deleted'
            }
          }))
        );
      },
      onError: (action: RemoveCustomerTraveller, error) => {
        console.error('Error', error);
        return new CustomerTravellerRemoveError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private customerTravellerResourceService: CustomerTravellerResourceService,
    private customerResourceService: CustomerResourceService,
    private dataPersistence: DataPersistence<CustomerTravellerPartialState>
  ) {}
}
