import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaTrackingService } from './ga-tracking.service';

@NgModule({
  imports: [CommonModule]
})
export class GaTrackingModule {
  static forRoot(): ModuleWithProviders<GaTrackingModule> {
    return {
      ngModule: GaTrackingModule,
      providers: [GaTrackingService]
    };
  }
}
