import {
  AfterViewChecked, ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog, MatMenuTrigger} from "@angular/material";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from "@angular/forms";
import {SearchParams} from "@gtd/api-services/b2b-client";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DatePipe} from "@angular/common";
import {MyBookingService} from "../../my-booking.service";
import * as moment from 'moment';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MY_FORMATS} from "@gtd/components/datepicker";

@Component({
  selector: 'gtd-filter-b2b',
  templateUrl: './filter-b2b.component.html',
  styleUrls: ['./filter-b2b.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterB2bComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FilterB2bComponent),
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class FilterB2bComponent implements OnInit, AfterViewChecked {
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  @Input() supplierType: string;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  formFilter: FormGroup;
  endDate = new Date();
  dataB2bFilters: SearchParams;
  defaultFromDate: Date;
  defaultToDate: Date;
  minDateTo: Date;
  maxDateFrom = new Date();

  bookingStatus = [
    {
      label: 'success',
      value: 'CONFIRMED'
    },
    {
      label: 'booked',
      value: 'BOOKED'
    },
    {
      label: 'ticketonprocess',
      value: 'TICKET_ON_PROCESS'
    },
    {
      label: 'failed',
      value: 'FAILED'
    },
    {
      label: 'expired',
      value: 'EXPIRED'
    }
  ];

  appName = localStorage.getItem('appName');
  searchType: 'bookingNumber' | 'bookingCode' = 'bookingNumber';

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private myBookingService: MyBookingService,
    private cdRef: ChangeDetectorRef
  ) {
    this.createForm();
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngOnInit() {
    this.formFilter.controls['toDate'].valueChanges.subscribe(toDateChange => {
      this.maxDateFrom = toDateChange? toDateChange: new Date();
    })
    this.formFilter.controls['fromDate'].valueChanges.subscribe(fromDateChange => {
      this.minDateTo = fromDateChange? fromDateChange: new Date();
    })
    this.myBookingService.getDestroyFilter.subscribe((destroyFilter) => {
      if (destroyFilter) {
        this.formFilter.reset();
      }
    });
    this.myBookingService.getDataB2BFilters.subscribe((filters) => {
      this.dataB2bFilters = filters;
      if (filters && filters.filter) {
        this.formFilter.setValue({
          email: filters.filter.email? filters.filter.email: '',
          bookingCode: filters.filter.providerBrn? filters.filter.providerBrn: '',
          bookingNumber: filters.filter.bookingNumber? filters.filter.bookingNumber: '',
          status: filters.filter.status ? filters.filter.status: '',
          fromDate: filters.filter.bookingDateFrom? moment(filters.filter.bookingDateFrom, "DD-MM-YYYY").toDate(): null,
          toDate: filters.filter.bookingDateTo? moment(filters.filter.bookingDateTo, "DD-MM-YYYY").toDate(): null,
        });
      } else {
        this.formFilter.reset();
      }
    });
  }

  submitApply() {
    this.myBookingService.setDataB2BFilters({
      ...this.dataB2bFilters,
      filter: {
        email: this.formFilter.get('email').value,
        providerBrn: this.formFilter.get('bookingCode').value,
        bookingNumber: this.formFilter.get('bookingNumber').value,
        status: this.formFilter.get('status').value,
        bookingDateFrom: this.formFilter.controls['datePicker'].value? this.datepipe.transform(
          this.formFilter.controls['datePicker'].value.fromDate,
          'dd/MM/yyyy'
        ): null,
        bookingDateTo: this.formFilter.controls['datePicker'].value? this.datepipe.transform(
          this.formFilter.controls['datePicker'].value.toDate,
          'dd/MM/yyyy'
        ): null,
      },
      skipSearch: false,
      page: 0,
      size: 15,
    });
    this.changed.emit("submit");
  }

  reset() {
    window.location.reload();
    // this.myBookingService.setDataB2BFilters({
    //   currentPage: 0,
    //   totalCount: this.dataB2bFilters.totalCount,
    //   productCode: this.dataB2bFilters.productCode,
    //   skipSearch: true
    // });
    // this.createForm();
  }
  private createForm() {
    this.formFilter = this.formBuilder.group({
      datePicker: [null],
      bookingCode: [null, Validators.pattern('^[a-zA-Z0-9]+$')],
      bookingNumber: [null, Validators.pattern('^[a-zA-Z0-9]+$')],
      email: ['', [Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$')]],
      status: [''],
      fromDate: new FormControl(''), // this.defaultFromDate
      toDate: new FormControl('') //this.defaultToDate
    });
  }

}
