import {Action} from '@ngrx/store';
import {CheckoutPayload, OtaResultOfCheckoutResult} from "@gtd/b2c-client";

export enum HotelCheckoutActionTypes {
  LoadHotelCheckout = '[HotelCheckout] Load HotelCheckout',
  HotelCheckoutLoaded = '[HotelCheckout] HotelCheckout Loaded',
  HotelCheckoutLoadError = '[HotelCheckout] HotelCheckout Load Error'
}

export class LoadHotelCheckout implements Action {
  readonly type = HotelCheckoutActionTypes.LoadHotelCheckout;
  constructor(public payload: CheckoutPayload) {}
}

export class HotelCheckoutLoadError implements Action {
  readonly type = HotelCheckoutActionTypes.HotelCheckoutLoadError;
  constructor(public payload: any) {}
}

export class HotelCheckoutLoaded implements Action {
  readonly type = HotelCheckoutActionTypes.HotelCheckoutLoaded;
  constructor(public payload: OtaResultOfCheckoutResult) {}
}

export type HotelCheckoutAction =
  | LoadHotelCheckout
  | HotelCheckoutLoaded
  | HotelCheckoutLoadError;

export const fromHotelCheckoutActions = {
  LoadHotelCheckout,
  HotelCheckoutLoaded,
  HotelCheckoutLoadError
};
