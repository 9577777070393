import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GETREFERRAL_FEATURE_KEY,
  GetReferralState
} from './get-referral.reducer';

// Lookup the 'GetReferral' feature state managed by NgRx
const getGetReferralState = createFeatureSelector<GetReferralState>(
  GETREFERRAL_FEATURE_KEY
);

const getLoaded = createSelector(
  getGetReferralState,
  (state: GetReferralState) => state.loaded
);
const getError = createSelector(
  getGetReferralState,
  (state: GetReferralState) => state.error
);

const getAllGetReferral = createSelector(
  getGetReferralState,
  getLoaded,
  (state: GetReferralState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getGetReferralState,
  (state: GetReferralState) => state.selectedId
);
const getSelectedGetReferral = createSelector(
  getAllGetReferral,
  getSelectedId,
  (getReferral, id) => {
    return getReferral ? Object.assign({}, getReferral) : undefined;
  }
);

export const getReferralQuery = {
  getLoaded,
  getError,
  getAllGetReferral,
  getSelectedGetReferral
};
