import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogPanelComponent } from './dialog-panel/dialog-panel.component';
import { GroupBooking } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-hotel-panel-price',
  templateUrl: './hotel-panel-price.component.html',
  styleUrls: ['./hotel-panel-price.component.scss']
})
export class HotelPanelPriceComponent implements OnInit {
  @Input() groupBooking: GroupBooking;
  policyBudget: number;
  userPay: any;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    if (
      this.groupBooking.bookingInfo.paymentHistorys &&
      this.groupBooking.bookingInfo.paymentHistorys.length
    ) {
      const policy = this.groupBooking.bookingInfo.paymentHistorys.find(
        history => history.paymentType == 'BALANCE'
      );
      this.userPay = this.groupBooking.bookingInfo.paymentHistorys.find(
        history => history.paymentType != 'BALANCE'
      )
        ? this.groupBooking.bookingInfo.paymentHistorys.find(
            history => history.paymentType != 'BALANCE'
          ).amount
        : 0;
      this.policyBudget = policy.amount;
    }
  }

  openPopupPanelDetail() {
    this.dialog.open(DialogPanelComponent, {
      data: this.groupBooking,
      panelClass: 'dialog-panel',
      position: {
        top: '100px'
      }
    });
  }
}
