/**
 * agentsrv API
 * agentsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AgencyDTO } from '../model/agencyDTO';
import { CountAgency } from '../model/countAgency';
import { OtaSearchResultOfListOfAgencyDTO } from '../model/otaSearchResultOfListOfAgencyDTO';
import { SubAgencyDTO } from '../model/subAgencyDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AgencyResourceService {

    protected basePath = 'https://10.7.71.20:8182';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * changeStatus
     *
     * @param id id
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeStatusUsingPUT(id: number, status: string, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public changeStatusUsingPUT(id: number, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public changeStatusUsingPUT(id: number, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public changeStatusUsingPUT(id: number, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling changeStatusUsingPUT.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling changeStatusUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<number>>(`${this.basePath}/api/agencies/${encodeURIComponent(String(id))}/status`,
            status,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkExistedEmail
     *
     * @param email email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkExistedEmailUsingPOST(email: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkExistedEmailUsingPOST(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkExistedEmailUsingPOST(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkExistedEmailUsingPOST(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling checkExistedEmailUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/api/agencies/check-email-existed`,
            email,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkExistedPhoneNotId
     *
     * @param phone phone
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkExistedPhoneNotIdUsingGET(phone: string, id: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkExistedPhoneNotIdUsingGET(phone: string, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkExistedPhoneNotIdUsingGET(phone: string, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkExistedPhoneNotIdUsingGET(phone: string, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (phone === null || phone === undefined) {
            throw new Error('Required parameter phone was null or undefined when calling checkExistedPhoneNotIdUsingGET.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling checkExistedPhoneNotIdUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (phone !== undefined && phone !== null) {
            queryParameters = queryParameters.set('phone', <any>phone);
        }
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/agencies/check-phone-not-id`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkExistedPhoneNumber
     *
     * @param phoneNumber phoneNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkExistedPhoneNumberUsingGET(phoneNumber: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkExistedPhoneNumberUsingGET(phoneNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkExistedPhoneNumberUsingGET(phoneNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkExistedPhoneNumberUsingGET(phoneNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (phoneNumber === null || phoneNumber === undefined) {
            throw new Error('Required parameter phoneNumber was null or undefined when calling checkExistedPhoneNumberUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/agencies/check-phone`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * countAgencies
     *
     * @param agencyStatus agencyStatus
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countAgenciesUsingGET(agencyStatus?: Array<'ACTIVATED' | 'DEACTIVATED' | 'OTHER'>, observe?: 'body', reportProgress?: boolean): Observable<Array<CountAgency>>;
    public countAgenciesUsingGET(agencyStatus?: Array<'ACTIVATED' | 'DEACTIVATED' | 'OTHER'>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CountAgency>>>;
    public countAgenciesUsingGET(agencyStatus?: Array<'ACTIVATED' | 'DEACTIVATED' | 'OTHER'>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CountAgency>>>;
    public countAgenciesUsingGET(agencyStatus?: Array<'ACTIVATED' | 'DEACTIVATED' | 'OTHER'>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyStatus) {
            agencyStatus.forEach((element) => {
                queryParameters = queryParameters.append('agencyStatus', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<CountAgency>>(`${this.basePath}/api/agencies/count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createAgency
     *
     * @param agencyDTO agencyDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgencyUsingPOST(agencyDTO: AgencyDTO, observe?: 'body', reportProgress?: boolean): Observable<AgencyDTO>;
    public createAgencyUsingPOST(agencyDTO: AgencyDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyDTO>>;
    public createAgencyUsingPOST(agencyDTO: AgencyDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyDTO>>;
    public createAgencyUsingPOST(agencyDTO: AgencyDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyDTO === null || agencyDTO === undefined) {
            throw new Error('Required parameter agencyDTO was null or undefined when calling createAgencyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AgencyDTO>(`${this.basePath}/api/agencies`,
            agencyDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createSubAgency
     *
     * @param subAgencyDTO subAgencyDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSubAgencyUsingPOST(subAgencyDTO: SubAgencyDTO, observe?: 'body', reportProgress?: boolean): Observable<SubAgencyDTO>;
    public createSubAgencyUsingPOST(subAgencyDTO: SubAgencyDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubAgencyDTO>>;
    public createSubAgencyUsingPOST(subAgencyDTO: SubAgencyDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubAgencyDTO>>;
    public createSubAgencyUsingPOST(subAgencyDTO: SubAgencyDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (subAgencyDTO === null || subAgencyDTO === undefined) {
            throw new Error('Required parameter subAgencyDTO was null or undefined when calling createSubAgencyUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SubAgencyDTO>(`${this.basePath}/api/agencies/sub-agency`,
            subAgencyDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteAgency
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgencyUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAgencyUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAgencyUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAgencyUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAgencyUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/agencies/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByAgencyCode
     *
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByAgencyCodeUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyDTO>;
    public findByAgencyCodeUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyDTO>>;
    public findByAgencyCodeUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyDTO>>;
    public findByAgencyCodeUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling findByAgencyCodeUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyDTO>(`${this.basePath}/api/agencies/by-code`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByAgencyNumber
     *
     * @param agencyNumber agencyNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByAgencyNumberUsingGET(agencyNumber: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyDTO>;
    public findByAgencyNumberUsingGET(agencyNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyDTO>>;
    public findByAgencyNumberUsingGET(agencyNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyDTO>>;
    public findByAgencyNumberUsingGET(agencyNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyNumber === null || agencyNumber === undefined) {
            throw new Error('Required parameter agencyNumber was null or undefined when calling findByAgencyNumberUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyNumber !== undefined && agencyNumber !== null) {
            queryParameters = queryParameters.set('agencyNumber', <any>agencyNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyDTO>(`${this.basePath}/api/agencies/by-number`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findByPhoneNumber1
     *
     * @param phoneNumber phoneNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findByPhoneNumber1UsingGET(phoneNumber: string, observe?: 'body', reportProgress?: boolean): Observable<AgencyDTO>;
    public findByPhoneNumber1UsingGET(phoneNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyDTO>>;
    public findByPhoneNumber1UsingGET(phoneNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyDTO>>;
    public findByPhoneNumber1UsingGET(phoneNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (phoneNumber === null || phoneNumber === undefined) {
            throw new Error('Required parameter phoneNumber was null or undefined when calling findByPhoneNumber1UsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyDTO>(`${this.basePath}/api/agencies/by-phone-number`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgency
     *
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgencyUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<AgencyDTO>;
    public getAgencyUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyDTO>>;
    public getAgencyUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyDTO>>;
    public getAgencyUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAgencyUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgencyDTO>(`${this.basePath}/api/agencies/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgencysFromList
     *
     * @param agency agency
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgencysFromListUsingGET(agency: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyDTO>>;
    public getAgencysFromListUsingGET(agency: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyDTO>>>;
    public getAgencysFromListUsingGET(agency: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyDTO>>>;
    public getAgencysFromListUsingGET(agency: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agency === null || agency === undefined) {
            throw new Error('Required parameter agency was null or undefined when calling getAgencysFromListUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agency) {
            agency.forEach((element) => {
                queryParameters = queryParameters.append('agency', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyDTO>>(`${this.basePath}/api/agencies/get-list-agency-from-code`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllAgencies
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyDTO>>;
    public getAllAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyDTO>>>;
    public getAllAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyDTO>>>;
    public getAllAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyDTO>>(`${this.basePath}/api/agencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllSubAgencies
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSubAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyDTO>>;
    public getAllSubAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyDTO>>>;
    public getAllSubAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyDTO>>>;
    public getAllSubAgenciesUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyDTO>>(`${this.basePath}/api/agencies/all-sub-agency`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjects
     *
     * @param agencyName agencyName
     * @param email email
     * @param status status
     * @param phoneNumber phoneNumber
     * @param agencyClass agencyClass
     * @param createdDateFrom createdDateFrom
     * @param createdDateTo createdDateTo
     * @param agencyNumber agencyNumber
     * @param stateCode stateCode
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectsUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, stateCode?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfListOfAgencyDTO>;
    public searchObjectsUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, stateCode?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfListOfAgencyDTO>>;
    public searchObjectsUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, stateCode?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfListOfAgencyDTO>>;
    public searchObjectsUsingGET(agencyName?: string, email?: string, status?: string, phoneNumber?: string, agencyClass?: string, createdDateFrom?: string, createdDateTo?: string, agencyNumber?: string, stateCode?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyName !== undefined && agencyName !== null) {
            queryParameters = queryParameters.set('agencyName', <any>agencyName);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }
        if (agencyClass !== undefined && agencyClass !== null) {
            queryParameters = queryParameters.set('agencyClass', <any>agencyClass);
        }
        if (createdDateFrom !== undefined && createdDateFrom !== null) {
            queryParameters = queryParameters.set('createdDateFrom', <any>createdDateFrom);
        }
        if (createdDateTo !== undefined && createdDateTo !== null) {
            queryParameters = queryParameters.set('createdDateTo', <any>createdDateTo);
        }
        if (agencyNumber !== undefined && agencyNumber !== null) {
            queryParameters = queryParameters.set('agencyNumber', <any>agencyNumber);
        }
        if (stateCode !== undefined && stateCode !== null) {
            queryParameters = queryParameters.set('stateCode', <any>stateCode);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaSearchResultOfListOfAgencyDTO>(`${this.basePath}/api/_search/agencies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateAgency
     *
     * @param agencyDTO agencyDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAgencyUsingPUT(agencyDTO: AgencyDTO, observe?: 'body', reportProgress?: boolean): Observable<AgencyDTO>;
    public updateAgencyUsingPUT(agencyDTO: AgencyDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgencyDTO>>;
    public updateAgencyUsingPUT(agencyDTO: AgencyDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgencyDTO>>;
    public updateAgencyUsingPUT(agencyDTO: AgencyDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyDTO === null || agencyDTO === undefined) {
            throw new Error('Required parameter agencyDTO was null or undefined when calling updateAgencyUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AgencyDTO>(`${this.basePath}/api/agencies`,
            agencyDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateSubAgency
     *
     * @param subAgencyDTO subAgencyDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubAgencyUsingPUT(subAgencyDTO: SubAgencyDTO, observe?: 'body', reportProgress?: boolean): Observable<SubAgencyDTO>;
    public updateSubAgencyUsingPUT(subAgencyDTO: SubAgencyDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SubAgencyDTO>>;
    public updateSubAgencyUsingPUT(subAgencyDTO: SubAgencyDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SubAgencyDTO>>;
    public updateSubAgencyUsingPUT(subAgencyDTO: SubAgencyDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (subAgencyDTO === null || subAgencyDTO === undefined) {
            throw new Error('Required parameter subAgencyDTO was null or undefined when calling updateSubAgencyUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SubAgencyDTO>(`${this.basePath}/api/sub-agency`,
            subAgencyDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
