import {Component, OnDestroy, OnInit} from '@angular/core';
import {BookingDetailFacade} from './detail-state/+state/booking-detail.facade';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {
  AdditionalRequestResourceService,
  BasePartnerResourceService,
  BookingAdditionalRequestDTO,
  GroupBooking
} from '@gtd/b2c-client';
import {PolicyByBookingFacade} from '@gtd/booking-result';
import {environment} from '@env/environment';
import {DialogMessageComponent} from '@gtd/components/dialog-message';
import {MatDialog, MatSnackBar} from '@angular/material';
import {SnackbarComponent} from '@gtd/components/snackbar';
import {BreadcrumbBar} from '@gtd/components/breadcrumb-bar';
import {ApprovalInvoiceResourceService} from '@gtd/api-services/utilitysrv';
import {TimeCountDown, TimeCountDownBetweenDirective} from '@gtd/directives/time-count-down-between';
import {TourUnitPriceFacade} from '@gtd/extra/tour/data-access';
import {map, switchMap} from 'rxjs/operators';
import {
  CreateAdditionalRequestDialogComponent
} from '../create-additional-request-dialog/create-additional-request-dialog.component';

@Component({
  selector: 'gtd-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.scss']
})
export class BookingDetailComponent implements OnInit, OnDestroy {
  bookingNumber: string;
  subscriptions = new Subscription();
  groupBooking: GroupBooking;
  allPolicyByBooking$ = this.policyByBookingFacade.allPolicyByBooking$;
  allTourUnitPrice$ = this._tourUnitPriceFacade.allTourUnitPrice$;
  isLoading: boolean;
  appName = localStorage.getItem('appName');
  breadcrumbBars: BreadcrumbBar[] = [
    {
      name: 'global.homepage',
      isRouterLink: true,
      routerLink: '/'
    },
    {
      isRouterLink: true,
      name: 'booking.booking-management',
      routerLink: '/user/bookings'
    },
    {
      isRouterLink: false,
      name: 'booking-detail.booking-detail'
    }
  ];
  isShowDownloadInvoiceButton: boolean = false;
  isBookingWasBookedWithinFiveDays: boolean = false;
  linkDownloadInvoice: string = '';
  timeCountDown: TimeCountDown;
  mode = '';
  paxInfos$: any;
  totalAdditionalRequests = 0;
  cooperationClass = localStorage.getItem('cooperationClass');
  userPay: any;
  policyBudget: any;
  paymentTypes: any[];
  partner_gotadi: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private policyByBookingFacade: PolicyByBookingFacade,
    private bookingDetailFacade: BookingDetailFacade,
    private basePartnerResourceService: BasePartnerResourceService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private additionalRequestResourceService: AdditionalRequestResourceService,
    private approvalInvoiceResourceService: ApprovalInvoiceResourceService,
    private timeCountDownBetweenDirective: TimeCountDownBetweenDirective,
    private _tourUnitPriceFacade: TourUnitPriceFacade,
  ) {
    let localStorageGet = localStorage.getItem('partner_gotadi');
    if(localStorageGet) {
      let partner = JSON.parse(localStorageGet);
      this.partner_gotadi = partner && partner.merchant_code ? partner.merchant_code : null;
    }
  }

  ngOnInit() {
    this.subscriptions.add(
      this.route.queryParams.subscribe(params => {
        this.bookingNumber = params.booking_number;
        this.mode = params.mode;
        if (this.bookingNumber) {
          this.bookingDetailFacade.getBookingByBookingNumber(
            params.booking_number
          );
          this.policyByBookingFacade.loadAllPolicyByBooking(
            params.booking_number
          );
          // Display download button if this booking was invoiced successfully
          if (this.appName === 'B2C') {
            this.approvalInvoiceResourceService
              .getApprovalInvoiceByBookingNumberUsingGET(params.booking_number)
              .subscribe(res => {
                if (
                  res.success === true &&
                  res.result &&
                  res.result.approvedStatus === 'SUCCESS'
                ) {
                  this.isShowDownloadInvoiceButton = true;
                  this.subscriptions.add(
                    this.approvalInvoiceResourceService
                      .getPdfUrlEinvoiceUsingGET(res.result.id)
                      .subscribe((res: any) => {
                        this.linkDownloadInvoice = res.pdfUrl;
                      })
                  );
                }
              });
          }
        }
      })
    );
    this.paxInfos$ = this.bookingDetailFacade.bookingDetail$.pipe(
      switchMap(bookingDetail =>
        this.allTourUnitPrice$.pipe(
          map(unitPrice => {
            if (bookingDetail && unitPrice) {
              return bookingDetail.tourActivityBookingPayload.paxInfos.map(
                pax => {
                  const paxDetail = unitPrice.result.find(
                    unit => pax.unitId === unit.id
                  );
                  return { ...pax, ...paxDetail };
                }
              );
            }
          })
        )
      )
    );
    this.subscriptions.add(
      this.bookingDetailFacade.bookingDetail$.subscribe(
        (groupBooking: GroupBooking) => {
          if (groupBooking) {
            this.groupBooking = groupBooking;
            this.countTotalAdditionalRequests();
            if (groupBooking.supplierType === 'TOURS') {
              this._tourUnitPriceFacade.loadAll({
                activityId: groupBooking.tourActivityBookingPayload.activityId,
                language: 'vi'
              });
            }
            if (this.groupBooking.bookingInfo.timeToLive) {
              this.timeCountDown = this.timeCountDownBetweenDirective.timeBetweenDates(
                this.groupBooking.bookingInfo.timeToLive
              );
            }

            if (
              this.groupBooking.bookingInfo.paymentHistorys &&
              this.groupBooking.bookingInfo.paymentHistorys.length
            ) {
              const policy = this.groupBooking.bookingInfo.paymentHistorys.find(
                history => history.paymentType == 'BALANCE'
              );
              this.userPay = this.groupBooking.bookingInfo.paymentHistorys.find(
                history => history.paymentType != 'BALANCE'
              )
                ? this.groupBooking.bookingInfo.paymentHistorys.find(
                    history => history.paymentType != 'BALANCE'
                  ).amount
                : 0;
              this.policyBudget = policy.amount;
              this.paymentTypes = this.groupBooking.bookingInfo.paymentHistorys.map(
                history => history.paymentType
              );
            }
          }
        }
      )
    );
  }

  redirectPayment() {
    if (environment.appName === 'B2B2C' && this.partner_gotadi !== 'SHNHN') {
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'booking-result.insurance.processing', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
      this.basePartnerResourceService
        .placeOrderUsingGET(this.groupBooking.bookingInfo.bookingNumber)
        .subscribe(placeOrder => {
          if (placeOrder && placeOrder.isSuccess) {
              window.top.location.href = decodeURIComponent(placeOrder.result);
          } else {
            this.dialogMessageExpire();
          }
          this.isLoading = false;
          this._snackBar.dismiss();
        });
    } else {
      setTimeout(() => {
        this.router.navigate(['/booking/payment'], {
          queryParams: {
            bookingNumber: this.groupBooking.bookingInfo.bookingNumber
          }
        });
      });
    }
  }

  dialogMessageExpire() {
    this.dialog.open(DialogMessageComponent, {
      data: {
        btn: [{ btnText: 'global.close' }],
        content: 'user.system-error-message',
        heading: 'user.system-error',
        icon: 'exclamation-triangle',
        messageType: 'error'
      },
      panelClass: 'dialog-message',
      disableClose: true,
      position: {
        top: '100px'
      }
    });
  }

  downloadInvoice() {
    window.open(this.linkDownloadInvoice, '_blank');
  }

  backClicked() {
    if (this.mode) {
      this._location.back();
    } else {
      this.router.navigate(['/user/bookings']).then(() => {});
    }
  }

  countTotalAdditionalRequests() {
    this.subscriptions.add(
      this.additionalRequestResourceService
        .getAdditionalRequestsByBookingNumberUsingGET(
          this.groupBooking.bookingNumber
        )
        .subscribe(list => {
          this.totalAdditionalRequests = list.length;
        })
    );
  }

  openCreateAdditionalRequestDialog(type: string) {
    const dialogRef = this.dialog.open(CreateAdditionalRequestDialogComponent, {
      data: {
        type: type,
        bookingInfo: this.groupBooking.bookingInfo,
        propertyName:
          this.groupBooking.hotelProduct &&
          this.groupBooking.hotelProduct.propertyName
            ? this.groupBooking.hotelProduct.propertyName
            : null
      },
      position: {
        right: '0',
        top: '0'
      },
      autoFocus: false,
      panelClass: [
        'animate__animated',
        'animate__slideInRight',
        'animate__faster',
        'create-additional-request-dialog-panel-class'
      ],
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.action === 'send-request') {
        let sendRequestSnackBar = this._snackBar.openFromComponent(
          SnackbarComponent,
          {
            data: {
              message: 'additional-request.snack-bar-loading',
              loading: true
            },
            panelClass: 'snackbar-loading',
            verticalPosition: 'top'
          }
        );
        let bookingDescription = '';
        if (this.groupBooking.bookingInfo.supplierType === 'AIR') {
          bookingDescription =
            this.groupBooking.bookingInfo.fromCity +
            ' (' +
            this.groupBooking.bookingInfo.fromLocationCode +
            ') - ' +
            this.groupBooking.bookingInfo.toCity +
            ' (' +
            this.groupBooking.bookingInfo.toLocationCode +
            ')';
        } else if (this.groupBooking.bookingInfo.supplierType === 'HOTEL') {
          bookingDescription = this.groupBooking.hotelProduct.propertyName;
        } else if (this.groupBooking.bookingInfo.supplierType === 'COMBO') {
          bookingDescription =
            this.groupBooking.bookingInfo.fromCity +
            ' (' +
            this.groupBooking.bookingInfo.fromLocationCode +
            ') - ' +
            this.groupBooking.bookingInfo.toCity +
            ' (' +
            this.groupBooking.bookingInfo.toLocationCode +
            '), ' +
            this.groupBooking.hotelProduct.propertyName;
        } else if (this.groupBooking.bookingInfo.supplierType === 'TOURS') {
          bookingDescription = this.groupBooking.bookingInfo.fromLocationName;
        }
        let bookingAdditionalRequestDTO: BookingAdditionalRequestDTO = {
          id: null,
          bookingCode: this.groupBooking.bookingInfo.bookingCode,
          bookingNumber: this.groupBooking.bookingInfo.bookingNumber,
          requestType: dialogResult.data.type,
          requestContent: dialogResult.data.content,
          status: 'PENDING',
          createdByNotes: null,
          bookingDescription: bookingDescription,
          bookingId: this.groupBooking.bookingInfo.id
        };
        this.subscriptions.add(
          this.additionalRequestResourceService
            .createAdditionalRequestUsingPOST(bookingAdditionalRequestDTO)
            .subscribe(
              () => {
                sendRequestSnackBar.dismiss();
                this.openNotificationSnackBar(
                  'user.create-additional-request-succeeded',
                  'check',
                  'snackbar-success'
                );
              },
              () => {
                sendRequestSnackBar.dismiss();
                this.dialog.open(DialogMessageComponent, {
                  data: {
                    btn: [{ btnText: 'global.close' }],
                    content: 'user.system-error-message',
                    heading: 'user.create-additional-request-failed',
                    icon: 'exclamation-triangle',
                    messageType: 'error'
                  },
                  panelClass: 'dialog-message'
                });
              }
            )
        );
      }
    });
  }

  openNotificationSnackBar(message: string, icon: string, panelClass: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: icon
      },
      panelClass: panelClass,
      verticalPosition: 'top',
      duration: 2000
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.bookingDetailFacade.resetBookingDetail();
  }
}
