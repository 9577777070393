import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { PromotionValidatePartialState } from './promotion-validate.reducer';
import { promotionValidateQuery } from './promotion-validate.selectors';
import { LoadPromotionValidate } from './promotion-validate.actions';
import { PromotionRedemptionReq } from '../../lib/model/promotionRedemptionReq';

@Injectable()
export class PromotionValidateFacade {
  loaded$ = this.store.pipe(select(promotionValidateQuery.getLoaded));
  allPromotionValidate$ = this.store.pipe(
    select(promotionValidateQuery.getAllPromotionValidate)
  );
  selectedPromotionValidate$ = this.store.pipe(
    select(promotionValidateQuery.getSelectedPromotionValidate)
  );

  constructor(private store: Store<PromotionValidatePartialState>) {}

  loadAll(payload: PromotionRedemptionReq) {
    this.store.dispatch(new LoadPromotionValidate(payload));
  }
}
