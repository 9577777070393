import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOOKUP_FEATURE_KEY, LookupState } from './lookup.reducer';

// Lookup the 'Lookup' feature state managed by NgRx
const getLookupState = createFeatureSelector<LookupState>(LOOKUP_FEATURE_KEY);

const getLoaded = createSelector(
  getLookupState,
  (state: LookupState) => state.loaded
);
const getError = createSelector(
  getLookupState,
  (state: LookupState) => state.error
);

const getAllLookup = createSelector(
  getLookupState,
  getLoaded,
  (state: LookupState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getLookupState,
  (state: LookupState) => state.selectedId
);
const getSelectedLookup = createSelector(
  getAllLookup,
  getSelectedId,
  (lookup, id) => {
    const result = lookup.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const lookupQuery = {
  getLoaded,
  getError,
  getAllLookup,
  getSelectedLookup
};
