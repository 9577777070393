import {
  ReportReferralAction,
  ReportReferralActionTypes
} from './report-referral.actions';
import {ReferralResponseOfobject} from "../../lib";

export const REPORTREFERRAL_FEATURE_KEY = 'reportReferral';

/**
 * Interface for the 'ReportReferral' data used in
 *  - ReportReferralState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */

export interface ReportReferralState {
  list: ReferralResponseOfobject; // list of ReportReferral; analogous to a sql normalized table
  selectedId?: string | number; // which ReportReferral record has been selected
  loaded: boolean; // has the ReportReferral list been loaded
  error?: any; // last none error (if any)
}

export interface ReportReferralPartialState {
  readonly [REPORTREFERRAL_FEATURE_KEY]: ReportReferralState;
}

export const initialState: ReportReferralState = {
  list: null,
  loaded: false
};

export function reducer(
  state: ReportReferralState = initialState,
  action: ReportReferralAction
): ReportReferralState {
  switch (action.type) {
    case ReportReferralActionTypes.ReportReferralLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
