/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VTCustBillInfoDTO } from './vTCustBillInfoDTO';


export interface ReqPlaceOrderDTO { 
    billcode?: string;
    cancelUrl?: string;
    checkSum?: string;
    command?: ReqPlaceOrderDTO.CommandEnum;
    custBillinfo?: VTCustBillInfoDTO;
    desc?: string;
    errorCode?: string;
    locale?: string;
    merchantCode?: string;
    orderId?: string;
    otherInfo?: string;
    returnUrl?: string;
    transAmount?: number;
    version?: string;
}
export namespace ReqPlaceOrderDTO {
    export type CommandEnum = 'PAYMENT';
    export const CommandEnum = {
        PAYMENT: 'PAYMENT' as CommandEnum
    };
}
