import { FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';

// custom validator to check that two fields match
export function ExistValue(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.existValue) {
      return;
    }
    if (control.value && !matchingControl.value) {
      matchingControl.setErrors({ existValue: true });
    } else if(!control.value && matchingControl.value) {
      control.setErrors({ existValue: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
