import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FlightSearchResultComponent } from './search-result/flight/flight-search-result.component';
import { HotelSearchResultComponent } from './search-result/hotel/search-result/hotel-search-result.component';
import { HotelGuardService } from './hotel-guard.service';
import { HotelDetailComponent } from './search-result/hotel/hotel-detail/hotel-detail.component';
import { ComboConfirmComponent } from './combo-confirm/combo-confirm.component';
import { MapsPanelComponent } from './search-result/hotel/search-result/maps-panel/maps-panel.component';

const routes: Routes = [
  { path: '', redirectTo: 'hotel', pathMatch: 'full' },
  { path: 'flight', component: FlightSearchResultComponent },
  {
    path: 'hotel',
    children: [
      {
        path: '',
        component: HotelSearchResultComponent
      },
      { path: 'maps', component: MapsPanelComponent },
      {
        path: 'detail',
        component: HotelDetailComponent
      }
    ]
  },
  { path: 'confirm', component: ComboConfirmComponent }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComboRoutingModule {}
