import {
  BookingTravellerRQ,
  OtaResultOfUpdateBookingStatusRS
} from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './tour-add-booking-traveller.reducer';

export enum TourAddBookingTravellerActionTypes {
  LoadTourAddBookingTraveller = '[TourAddBookingTraveller] Load TourAddBookingTraveller',
  ResetTourAddBookingTraveller = '[TourAddBookingTraveller] Reset TourAddBookingTraveller',
  TourAddBookingTravellerLoaded = '[TourAddBookingTraveller] TourAddBookingTraveller Loaded',
  TourAddBookingTravellerLoadError = '[TourAddBookingTraveller] TourAddBookingTraveller Load Error'
}

export class LoadTourAddBookingTraveller implements Action {
  readonly type =
    TourAddBookingTravellerActionTypes.LoadTourAddBookingTraveller;
  constructor(public payload: BookingTravellerRQ) {}
}

export class ResetTourAddBookingTraveller implements Action {
  readonly type =
    TourAddBookingTravellerActionTypes.ResetTourAddBookingTraveller;
}

export class TourAddBookingTravellerLoadError implements Action {
  readonly type =
    TourAddBookingTravellerActionTypes.TourAddBookingTravellerLoadError;
  constructor(public payload: any) {}
}

export class TourAddBookingTravellerLoaded implements Action {
  readonly type =
    TourAddBookingTravellerActionTypes.TourAddBookingTravellerLoaded;
  constructor(public payload: OtaResultOfUpdateBookingStatusRS) {}
}

export type TourAddBookingTravellerAction =
  | LoadTourAddBookingTraveller
  | ResetTourAddBookingTraveller
  | TourAddBookingTravellerLoaded
  | TourAddBookingTravellerLoadError;

export const fromTourAddBookingTravellerActions = {
  LoadTourAddBookingTraveller,
  ResetTourAddBookingTraveller,
  TourAddBookingTravellerLoaded,
  TourAddBookingTravellerLoadError
};
