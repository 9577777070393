import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '@env/environment';

import {
  initialState as searchBestRatesInitialState,
  SEARCHBESTRATES_FEATURE_KEY,
  searchBestRatesReducer
} from './search-best-rates/search-best-rates.reducer';
import { ComboSearchBestRatesEffects } from './search-best-rates/search-best-rates.effects';
import { ComboSearchBestRatesFacade } from './search-best-rates/search-best-rates.facade';
import { NxModule } from '@nrwl/angular';
import {
  BASE_PATH,
  ComboResourceService,
  HotelResourceV3Service
} from '@gtd/b2c-client';

import {
  initialState as searchKeywordInitialState,
  SEARCHKEYWORD_FEATURE_KEY,
  searchKeywordReducer
} from './search-keyword/search-keyword.reducer';
import { SearchKeywordEffects } from './search-keyword/search-keyword.effects';
import { SearchKeywordFacade } from './search-keyword/search-keyword.facade';

import {
  initialState as filterAvailableInitialState,
  FILTERAVAILABLE_FEATURE_KEY,
  filterAvailableReducer
} from './filter-available/filter-available.reducer';
import { FilterAvailableFacade } from './filter-available/filter-available.facade';

import {
  initialState as filterOptionsInitialState,
  FILTEROPTIONS_FEATURE_KEY,
  filterOptionsReducer
} from './filter-options/filter-options.reducer';
import { FilterOptionsEffects } from './filter-options/filter-options.effects';
import { FilterOptionsFacade } from './filter-options/filter-options.facade';

import {
  initialState as hotelDetailInitialState,
  HOTELDETAIL_FEATURE_KEY,
  hotelDetailReducer
} from './hotel-detail/hotel-detail.reducer';
import { HotelDetailEffects } from './hotel-detail/hotel-detail.effects';
import { ComboHotelDetailFacade } from './hotel-detail/hotel-detail.facade';

import {
  initialState as hotelCreateDraftBookingInitialState,
  HOTELCREATEDRAFTBOOKING_FEATURE_KEY,
  hotelCreateDraftBookingReducer
} from './hotel-create-draft-booking/hotel-create-draft-booking.reducer';
import { HotelCreateDraftBookingEffects } from './hotel-create-draft-booking/hotel-create-draft-booking.effects';
import { HotelCreateDraftBookingFacade } from './hotel-create-draft-booking/hotel-create-draft-booking.facade';

import {
  initialState as hotelCheckoutInitialState,
  HOTELCHECKOUT_FEATURE_KEY,
  hotelCheckoutReducer
} from './hotel-checkout/hotel-checkout.reducer';
import { HotelCheckoutEffects } from './hotel-checkout/hotel-checkout.effects';
import { HotelCheckoutFacade } from './hotel-checkout/hotel-checkout.facade';

import {
  initialState as hotelSearchKeywordInitialState,
  HOTELSEARCHKEYWORD_FEATURE_KEY,
  autocompleteSearchKeywordReducer
} from './autocomplete-search-keyword/autocomplete-search-keyword.reducer';
import { ComboVJAutocompleteSearchKeywordFacade } from './autocomplete-search-keyword/autocomplete-search-keyword.facade';
import {
  initialState as searchInformationInitialState,
  COMBO_SEARCH_INFOMARTION_FEATURE_KEY,
  SearchInformationReducer
} from './combo-search-information/combo-search-information.reducer';
import { ComboVJSearchInformationFacade } from './combo-search-information/combo-search-information.facade';
import { ComboVJComboBookingFacade } from './combo-booking/combo-booking.facade';
import {
  initialState as comboBookingInitialState,
  COMBO_BOOKING_FEATURE_KEY,
  ComboBookingReducer
} from './combo-booking/combo-booking.reducer';
import {
  initialState as comboCreateDraftBookingInitialState,
  COMBOCREATEDRAFTBOOKING_FEATURE_KEY,
  ComboCreateDraftBookingReducer
} from './combo-create-draft-booking/combo-create-draft-booking.reducer';
import { ComboCreateDraftBookingEffects } from './combo-create-draft-booking/combo-create-draft-booking.effects';
import { ComboCreateDraftBookingFacade } from './combo-create-draft-booking/combo-create-draft-booking.facade';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(
      SEARCHBESTRATES_FEATURE_KEY,
      searchBestRatesReducer,
      {
        initialState: searchBestRatesInitialState
      }
    ),
    StoreModule.forFeature(SEARCHKEYWORD_FEATURE_KEY, searchKeywordReducer, {
      initialState: searchKeywordInitialState
    }),
    StoreModule.forFeature(
      FILTERAVAILABLE_FEATURE_KEY,
      filterAvailableReducer,
      {
        initialState: filterAvailableInitialState
      }
    ),
    StoreModule.forFeature(FILTEROPTIONS_FEATURE_KEY, filterOptionsReducer, {
      initialState: filterOptionsInitialState
    }),
    StoreModule.forFeature(HOTELDETAIL_FEATURE_KEY, hotelDetailReducer, {
      initialState: hotelDetailInitialState
    }),
    StoreModule.forFeature(
      HOTELCREATEDRAFTBOOKING_FEATURE_KEY,
      hotelCreateDraftBookingReducer,
      {
        initialState: hotelCreateDraftBookingInitialState
      }
    ),
    StoreModule.forFeature(HOTELCHECKOUT_FEATURE_KEY, hotelCheckoutReducer, {
      initialState: hotelCheckoutInitialState
    }),

    StoreModule.forFeature(
      HOTELSEARCHKEYWORD_FEATURE_KEY,
      autocompleteSearchKeywordReducer,
      {
        initialState: hotelSearchKeywordInitialState
      }
    ),
    StoreModule.forFeature(
      COMBO_SEARCH_INFOMARTION_FEATURE_KEY,
      SearchInformationReducer,
      {
        initialState: searchInformationInitialState
      }
    ),
    StoreModule.forFeature(COMBO_BOOKING_FEATURE_KEY, ComboBookingReducer, {
      initialState: comboBookingInitialState
    }),
    StoreModule.forFeature(
      COMBOCREATEDRAFTBOOKING_FEATURE_KEY,
      ComboCreateDraftBookingReducer,
      {
        initialState: comboCreateDraftBookingInitialState
      }
    ),

    EffectsModule.forFeature([
      ComboSearchBestRatesEffects,
      SearchKeywordEffects,
      FilterOptionsEffects,
      HotelDetailEffects,
      HotelCreateDraftBookingEffects,
      HotelCheckoutEffects,
      ComboCreateDraftBookingEffects
    ])
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2cClient },
    ComboSearchBestRatesFacade,
    HotelResourceV3Service,
    SearchKeywordFacade,
    FilterAvailableFacade,
    FilterOptionsFacade,
    ComboHotelDetailFacade,
    HotelCreateDraftBookingFacade,
    HotelCheckoutFacade,
    ComboVJAutocompleteSearchKeywordFacade,
    ComboVJSearchInformationFacade,
    ComboVJComboBookingFacade,
    ComboCreateDraftBookingFacade,
    ComboResourceService
  ]
})
export class StateHotelModule {}
