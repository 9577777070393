import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TOURCREATEDRAFTBOOKING_FEATURE_KEY,
  TourCreateDraftBookingState
} from './tour-create-draft-booking.reducer';

// Lookup the 'TourCreateDraftBooking' feature state managed by NgRx
const getTourCreateDraftBookingState = createFeatureSelector<
  TourCreateDraftBookingState
>(TOURCREATEDRAFTBOOKING_FEATURE_KEY);

const getLoaded = createSelector(
  getTourCreateDraftBookingState,
  (state: TourCreateDraftBookingState) => state.loaded
);
const getError = createSelector(
  getTourCreateDraftBookingState,
  (state: TourCreateDraftBookingState) => state.error
);

const getAllTourCreateDraftBooking = createSelector(
  getTourCreateDraftBookingState,
  getLoaded,
  (state: TourCreateDraftBookingState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getTourCreateDraftBookingState,
  (state: TourCreateDraftBookingState) => state.selectedId
);
const getSelectedTourCreateDraftBooking = createSelector(
  getAllTourCreateDraftBooking,
  getSelectedId,
  (tourCreateDraftBooking, id) => {
    return tourCreateDraftBooking
      ? Object.assign({}, tourCreateDraftBooking)
      : undefined;
  }
);

export const tourCreateDraftBookingQuery = {
  getLoaded,
  getError,
  getAllTourCreateDraftBooking,
  getSelectedTourCreateDraftBooking
};
