import { Pipe, PipeTransform} from "@angular/core";
import { BranchFilter } from "../../filter-panel/filter-panel.component";

@Pipe({
  name: 'mapCabinClassStringToBranchFilterClass'
})

export class MapCabinClassStringToBranchFilterClass implements PipeTransform {
  transform(value: string[], args?: any): BranchFilter[] {
    const result: BranchFilter[] = [];
    const classCodes: BranchFilter[] = [
      { branchCode: 'PROMO', branchName: 'promo', checked: false },
      { branchCode: 'ECONOMY', branchName: 'economy', checked: false },
      { branchCode: 'PREMIUM', branchName: 'premium', checked: false },
      { branchCode: 'BUSINESS', branchName: 'business', checked: false },
      { branchCode: 'FIRST', branchName: 'first', checked: false }
    ];
    classCodes.forEach(item => {
      if (value.includes(item.branchCode)) {
        result.push(item);
      }
    });

    return result;
  }
}
