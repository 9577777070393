import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DatepickerComponent } from './datepicker.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule, MatRippleModule } from '@angular/material';
import {
  CustomDatepickerI18n,
  DateFormatDirective,
  TranslateDatePicker
} from './date-format.directive';
import { GButtonModule, GInputModule } from '@gotadi/design-system';

@NgModule({
  imports: [
    CommonModule,
    NgbDatepickerModule,
    TranslateModule,
    FlexModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    FontAwesomeModule,
    MatIconModule,
    MatRippleModule,
    GButtonModule,
    GInputModule
  ],
  declarations: [DatepickerComponent, DateFormatDirective],
  providers: [TranslateDatePicker, CustomDatepickerI18n, DatePipe],
  exports: [DatepickerComponent]
})
export class DatepickerModule {}
