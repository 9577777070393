import {Component, EventEmitter, Input, OnDestroy, OnInit, Pipe, PipeTransform} from '@angular/core';
import {Options} from 'ng5-slider';
import {Subscription} from "rxjs";
import {FilterAvailableFacade} from "../../../../state/filter-available/filter-available.facade";
import {ActivatedRoute} from "@angular/router";
import {FilterAvailableState} from "../../../../state/filter-available/filter-available.reducer";

@Component({
  selector: 'gtd-price-panel',
  templateUrl: './price-panel.component.html',
  styleUrls: ['./price-panel.component.scss'],
})
export class PricePanelComponent implements OnInit, OnDestroy {
  @Input() filterPrices: Array<any>;
  @Input() searchParams: any;
  @Input() currentFilterPrices: FilterAvailableState;
  subscriptions = new Subscription();

  minApply: number;
  maxApply: number;
  value: number;
  highValue: number;
  valueChange: EventEmitter<any> = new EventEmitter<any>();
  highValueChange: EventEmitter<any> = new EventEmitter<any>();
  options: Options;
  numberChars = new RegExp('[^0-9]', 'g');

  constructor(
    private activatedRoute: ActivatedRoute,
    private filterAvailableFacade: FilterAvailableFacade
  ) {

  }

  private _handleQueryFromParams(param) {
    if(param.filterPrice) {
      this.minApply = Math.floor(param.filterPrice.fromPrice / 1000);
      this.maxApply = Math.floor(param.filterPrice.toPrice / 1000);
    }
  }

  ngOnInit() {
    if(this.filterPrices) {
      this.value = Math.floor(this.filterPrices[0].to / 1000);
      this.minApply = Math.floor(this.filterPrices[0].to / 1000);
      this.highValue = Math.ceil(this.filterPrices.slice(-1)[0].from / 1000);
      this.maxApply = Math.ceil(this.filterPrices.slice(-1)[0].from / 1000);
      this.options = this.makeOptions();
    }
    if(this.currentFilterPrices && this.currentFilterPrices.filterPrice) {
      this.value = Math.floor(this.currentFilterPrices.filterPrice.fromPrice / 1000);
      this.minApply = Math.floor(this.currentFilterPrices.filterPrice.fromPrice / 1000);
      this.maxApply = Math.ceil(this.currentFilterPrices.filterPrice.toPrice / 1000);
      this.highValue = Math.ceil(this.currentFilterPrices.filterPrice.toPrice / 1000);
    }
    this.valueChange.subscribe((value) => {
      this.value = value
    })
    this.highValueChange.subscribe((value) => {
      this.highValue = value
    })
    setTimeout(() => {
      this.activatedRoute.queryParams.subscribe(param => {
        this._handleQueryFromParams(JSON.parse(decodeURIComponent(atob(param.params))));
      })
    })
  }

  resetValue() {
    let value = Math.floor(this.filterPrices[0].to / 1000);
    let highValue = Math.ceil(this.filterPrices.slice(-1)[0].from / 1000);
    this.valueChange.emit(this.parseNumber(value));
    this.highValueChange.emit(this.parseNumber(highValue));
    this.minApply = this.parseNumber(value);
    this.maxApply = this.parseNumber(highValue);
    this.filterAvailableFacade.changeFilterPrice(null, null);
  }

  applyFilter() {
    this.filterAvailableFacade.changeFilterPrice(this.value*1000, (this.highValue !== 5000)? this.highValue*1000: null);
  }

  formPriceChange(event) {
    this.valueChange.emit(this.parseNumber(event.target.value));
    this.minApply = this.parseNumber(event.target.value);
  }
  toPriceChange(event) {
    this.highValueChange.emit(this.parseNumber(event.target.value));
    this.maxApply = this.parseNumber(event.target.value);
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  makeOptions() {
    return {
      animate: false, // Easier to see the single update
      floor: this.value,
      ceil: this.highValue,
      hideLimitLabels: true,
      hidePointerLabels: true,
      draggableRange: false
    };
  }

  parseNumber(value):number {
    let temp;
    if (typeof(value) === 'string') {
      temp = Number(value.replace(this.numberChars, ''));
    } else {
      temp = Number(value);
    }
    return temp;
  }

}
@Pipe({
  name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {
  numberChars = new RegExp('[^0-9]', 'g');
  transform(value: any):any {
    let temp;
    if (typeof(value) === 'string') {
      temp = Number(value.replace(this.numberChars, ''));
    } else {
      temp = value;
    }
    return Number(temp);
  }
}

