import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HotelFormSearchComponent} from './hotel-form-search.component';
import {AutoCompleteComponent} from "./auto-complete/auto-complete.component";
import {RoomsComponent} from "./rooms/rooms.component";
import {DatepickerModule} from "@gtd/components/datepicker";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {
  MatAutocompleteModule, MatButtonModule, MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatMenuModule,
  MatSelectModule
} from "@angular/material";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core';

import {faChevronDown, faHotel, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import {FlexLayoutModule} from "@angular/flex-layout";
import {IncreamentInputModule} from "@gtd/components/increament-input";
import {StateHotelModule} from "../../state/state-hotel.module";
import {HttpClientModule} from "@angular/common/http";
import {DestinationModule} from "@gtd/meta";

library.add(
  faChevronDown,
  faMapMarkerAlt,
  faHotel
);

@NgModule({
  declarations: [HotelFormSearchComponent, AutoCompleteComponent, RoomsComponent],
  imports: [
    CommonModule,
    DatepickerModule,
    TranslateModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatMenuModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule,
    FlexLayoutModule,
    IncreamentInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    StateHotelModule,
    DestinationModule
  ],
  exports: [HotelFormSearchComponent, RoomsComponent]
})
export class HotelFormSearchModule { }
