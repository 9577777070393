import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GROUPITINERARY_FEATURE_KEY,
  GroupItineraryState
} from './group-itinerary.reducer';
import {
  AirGroupPricedItineraryRS,
  AirLowFareSearchRS, AirPage,
  Destination, GroupPricedItinerary,
  HotelMetaKeyword, Info,
  PricedItinerary
} from "@gtd/b2c-client";

// Lookup the 'GroupItinerary' feature state managed by NgRx
const getGroupItineraryState = createFeatureSelector<GroupItineraryState>(
  GROUPITINERARY_FEATURE_KEY
);

const getLoaded = createSelector(
  getGroupItineraryState,
  (state: GroupItineraryState) => state.loaded
);
const getError = createSelector(
  getGroupItineraryState,
  (state: GroupItineraryState) => state.error
);

const getAllGroupItinerary = createSelector(
  getGroupItineraryState,
  getLoaded,
  (state: GroupItineraryState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);

const getAllReturnFlightInt = createSelector(
  getGroupItineraryState,
  getLoaded,
  (state: GroupItineraryState, isLoaded) => {
    return isLoaded ? Mapping.prototype.mappingReturnFlightInt(state.result) : null;
  }
);
const getSelectedId = createSelector(
  getGroupItineraryState,
  (state: GroupItineraryState) => state.selectedId
);
const getSelectedGroupItinerary = createSelector(
  getAllGroupItinerary,
  getSelectedId,
  (groupItinerary, id) => {
    return groupItinerary ? Object.assign({}, groupItinerary) : undefined;
  }
);

export const groupItineraryQuery = {
  getLoaded,
  getError,
  getAllGroupItinerary,
  getAllReturnFlightInt,
  getSelectedGroupItinerary
};

export class Mapping {
  mappingReturnFlightInt(airGroupPricedItineraryRS: AirGroupPricedItineraryRS): AirLowFareSearchRS {
    if(airGroupPricedItineraryRS){
    let airLowFareSearchRS = new class implements AirLowFareSearchRS {
      duration: number;
      errors: Array<Error>;
      groupPricedItineraries: Array<GroupPricedItinerary>;
      infos: Array<Info>;
      page: AirPage;
      returnSearchId: string;
      searchId: string;
      success: boolean;
      textMessage: string;
    }
    if(airGroupPricedItineraryRS.groupPricedItinerary) {
      airLowFareSearchRS.duration = airGroupPricedItineraryRS.duration;
      airLowFareSearchRS.errors = airGroupPricedItineraryRS.errors;
      airLowFareSearchRS.infos = airGroupPricedItineraryRS.infos;
      airLowFareSearchRS.success = airGroupPricedItineraryRS.success;
      airLowFareSearchRS.textMessage = airGroupPricedItineraryRS.textMessage;
      airLowFareSearchRS.searchId = airGroupPricedItineraryRS.searchId;

      let groupPricedItineraries = new Array<GroupPricedItinerary>();

      airGroupPricedItineraryRS.groupPricedItinerary.pricedItineraries.forEach((airGroupPricedItinerary, index) => {
        let pricedItineraries = new Array<PricedItinerary>();
        pricedItineraries.push(airGroupPricedItineraryRS.groupPricedItinerary.pricedItineraries[index]);
        pricedItineraries[0].originDestinationOptions.splice(0, 1);
        groupPricedItineraries.push({
          airSupplier: airGroupPricedItineraryRS.groupPricedItinerary.airSupplier,
          aircraft: airGroupPricedItineraryRS.groupPricedItinerary.aircraft,
          airline: airGroupPricedItineraryRS.groupPricedItinerary.airline,
          airlineName: airGroupPricedItineraryRS.groupPricedItinerary.airlineName,
          arrivalDateTime: airGroupPricedItineraryRS.groupPricedItinerary.arrivalDateTime,
          departureDateTime: airGroupPricedItineraryRS.groupPricedItinerary.departureDateTime,
          destinationCity: airGroupPricedItineraryRS.groupPricedItinerary.destinationCity,
          destinationCountry: airGroupPricedItineraryRS.groupPricedItinerary.destinationCountry,
          destinationCountryCode: airGroupPricedItineraryRS.groupPricedItinerary.destinationCountryCode,
          destinationLocationCode: airGroupPricedItineraryRS.groupPricedItinerary.destinationLocationCode,
          destinationLocationName: airGroupPricedItineraryRS.groupPricedItinerary.destinationLocationName,
          flightType: airGroupPricedItineraryRS.groupPricedItinerary.flightType,
          fightNo: airGroupPricedItineraryRS.groupPricedItinerary.fightNo,
          groupId: airGroupPricedItineraryRS.groupPricedItinerary.groupId,
          originCity: airGroupPricedItineraryRS.groupPricedItinerary.originCity,
          originCountry: airGroupPricedItineraryRS.groupPricedItinerary.originCountry,
          originCountryCode: airGroupPricedItineraryRS.groupPricedItinerary.originCountryCode,
          originLocationCode: airGroupPricedItineraryRS.groupPricedItinerary.originLocationCode,
          originLocationName: airGroupPricedItineraryRS.groupPricedItinerary.originLocationName,
          pricedItineraries: pricedItineraries,
          requiredFields: airGroupPricedItineraryRS.groupPricedItinerary.requiredFields,
          returnDateTime: airGroupPricedItineraryRS.groupPricedItinerary.returnDateTime,
          roundType: airGroupPricedItineraryRS.groupPricedItinerary.roundType,
          totalPricedItinerary: pricedItineraries[0].originDestinationOptions.length-1,
        })
      });
      airLowFareSearchRS.groupPricedItineraries = groupPricedItineraries;
      airLowFareSearchRS.page = {
        nextPageNumber: -1,
        offset: airGroupPricedItineraryRS.groupPricedItinerary.pricedItineraries.length,
        pageNumber: 0,
        previousPageNumber: -1,
        totalElements: airGroupPricedItineraryRS.groupPricedItinerary.pricedItineraries.length,
        totalPage: 1,
      }
    }
    return airLowFareSearchRS;}
  }
}
