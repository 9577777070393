import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {SearchUserBooking} from "@gtd/api-services/booking-service";
import {SearchParams} from "@gtd/api-services/b2b-client";

@Injectable({
  providedIn: 'root'
})
export class MyBookingService {

  dataFilters = new BehaviorSubject<SearchUserBooking>(null);
  getDataFilters = this.dataFilters.asObservable();

  dataB2BFilters = new BehaviorSubject<SearchParams>(null);
  getDataB2BFilters = this.dataB2BFilters.asObservable();

  destroyFilters = new BehaviorSubject<boolean>(false);
  getDestroyFilter = this.destroyFilters.asObservable();

  constructor() {}

  setDataFilters(filterSearchBooking: SearchUserBooking) {
    this.dataFilters.next(filterSearchBooking);
  }

  setDataB2BFilters(searchParams: SearchParams) {
    this.dataB2BFilters.next(searchParams);
  }

  filterDestroy() {
    this.dataFilters.next(null);
    this.dataB2BFilters.next(null);
    this.destroyFilters.next(true);
  }
}
