import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PHONERESENDOTPSTATE_FEATURE_KEY,
  PhoneResendOtpStateState
} from './phone-resend-otp-state.reducer';

// Lookup the 'PhoneResendOtpState' feature state managed by NgRx
const getPhoneResendOtpStateState = createFeatureSelector<
  PhoneResendOtpStateState
>(PHONERESENDOTPSTATE_FEATURE_KEY);

const getLoaded = createSelector(
  getPhoneResendOtpStateState,
  (state: PhoneResendOtpStateState) => state.loaded
);
const getError = createSelector(
  getPhoneResendOtpStateState,
  (state: PhoneResendOtpStateState) => state.error
);

const getAllPhoneResendOtpState = createSelector(
  getPhoneResendOtpStateState,
  getLoaded,
  (state: PhoneResendOtpStateState, isLoaded) => {
    return isLoaded ? state : null;
  }
);
const getSelectedId = createSelector(
  getPhoneResendOtpStateState,
  (state: PhoneResendOtpStateState) => state.selectedId
);
const getSelectedPhoneResendOtpState = createSelector(
  getAllPhoneResendOtpState,
  getSelectedId,
  (phoneResendOtpState, id) => {
    return phoneResendOtpState ? Object.assign({}, phoneResendOtpState) : undefined;
  }
);

export const phoneResendOtpStateQuery = {
  getLoaded,
  getError,
  getAllPhoneResendOtpState,
  getSelectedPhoneResendOtpState
};
