export * from './approvalInvoiceResource.service';
import { ApprovalInvoiceResourceService } from './approvalInvoiceResource.service';
//export const APIS = [ApprovalInvoiceResourceService];
export * from './notifyUserMessageResource.service';
import { NotifyUserMessageResourceService } from './notifyUserMessageResource.service';
export * from './notifyMessageResource.service';
import { NotifyMessageResourceService } from './notifyMessageResource.service';
export const APIS = [
  ApprovalInvoiceResourceService,
  NotifyUserMessageResourceService,
  NotifyMessageResourceService
];
