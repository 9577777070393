/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HaravanWebhookResourceService {

    protected basePath = 'https://uat-b2b.gotadi.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * authorizeHRVInstall
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authorizeHRVInstallUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authorizeHRVInstallUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authorizeHRVInstallUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authorizeHRVInstallUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/webhook/authorize/install`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * authorizeHRVLogin
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authorizeHRVLoginUsingGET(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authorizeHRVLoginUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authorizeHRVLoginUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authorizeHRVLoginUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/webhook/authorize/login`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * verifyWebhook
     * 
     * @param hubMode hub.mode
     * @param hubVerifyToken hub.verify_token
     * @param hubChallenge hub.challenge
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyWebhookUsingGET(hubMode: string, hubVerifyToken: string, hubChallenge: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public verifyWebhookUsingGET(hubMode: string, hubVerifyToken: string, hubChallenge: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public verifyWebhookUsingGET(hubMode: string, hubVerifyToken: string, hubChallenge: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public verifyWebhookUsingGET(hubMode: string, hubVerifyToken: string, hubChallenge: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hubMode === null || hubMode === undefined) {
            throw new Error('Required parameter hubMode was null or undefined when calling verifyWebhookUsingGET.');
        }

        if (hubVerifyToken === null || hubVerifyToken === undefined) {
            throw new Error('Required parameter hubVerifyToken was null or undefined when calling verifyWebhookUsingGET.');
        }

        if (hubChallenge === null || hubChallenge === undefined) {
            throw new Error('Required parameter hubChallenge was null or undefined when calling verifyWebhookUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hubMode !== undefined && hubMode !== null) {
            queryParameters = queryParameters.set('hub.mode', <any>hubMode);
        }
        if (hubVerifyToken !== undefined && hubVerifyToken !== null) {
            queryParameters = queryParameters.set('hub.verify_token', <any>hubVerifyToken);
        }
        if (hubChallenge !== undefined && hubChallenge !== null) {
            queryParameters = queryParameters.set('hub.challenge', <any>hubChallenge);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/webhook`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
