/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AccessTrade } from '../model/accessTrade';
import { BookingTagConnections } from '../model/bookingTagConnections';
import { Cityads } from '../model/cityads';
import { MasofferRs } from '../model/masofferRs';
import { Masoffers } from '../model/masoffers';
import { MisaReq } from '../model/misaReq';
import { ResponseEntity } from '../model/responseEntity';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {EcomobiReq} from "../model/ecomobiReq";


@Injectable()
export class AffiliateResourceService {

    protected basePath = 'https://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

  /**
   * createOrderEcomobi
   *
   * @param ecomobiReq ecomobiReq
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createOrderEcomobiUsingPOST(ecomobiReq: EcomobiReq, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
  public createOrderEcomobiUsingPOST(ecomobiReq: EcomobiReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
  public createOrderEcomobiUsingPOST(ecomobiReq: EcomobiReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
  public createOrderEcomobiUsingPOST(ecomobiReq: EcomobiReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (ecomobiReq === null || ecomobiReq === undefined) {
      throw new Error('Required parameter ecomobiReq was null or undefined when calling createOrderEcomobiUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<ResponseEntity>(`${this.basePath}/api/air-tickets/affiliate/ecomobi/create-order`,
      ecomobiReq,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


    /**
     * createOrderAccessTrade
     *
     * @param accessTrade accessTrade
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrderAccessTradeUsingPOST(accessTrade: AccessTrade, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public createOrderAccessTradeUsingPOST(accessTrade: AccessTrade, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public createOrderAccessTradeUsingPOST(accessTrade: AccessTrade, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public createOrderAccessTradeUsingPOST(accessTrade: AccessTrade, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accessTrade === null || accessTrade === undefined) {
            throw new Error('Required parameter accessTrade was null or undefined when calling createOrderAccessTradeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/api/air-tickets/affiliate/accesstrade/create-order`,
            accessTrade,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createOrderCityads
     *
     * @param cityads cityads
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrderCityadsUsingPOST(cityads: Cityads, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public createOrderCityadsUsingPOST(cityads: Cityads, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public createOrderCityadsUsingPOST(cityads: Cityads, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public createOrderCityadsUsingPOST(cityads: Cityads, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cityads === null || cityads === undefined) {
            throw new Error('Required parameter cityads was null or undefined when calling createOrderCityadsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/api/air-tickets/affiliate/cityads/create-order`,
            cityads,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createOrderMasoffer
     *
     * @param masoffers masoffers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrderMasofferUsingPOST(masoffers: Masoffers, observe?: 'body', reportProgress?: boolean): Observable<MasofferRs>;
    public createOrderMasofferUsingPOST(masoffers: Masoffers, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MasofferRs>>;
    public createOrderMasofferUsingPOST(masoffers: Masoffers, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MasofferRs>>;
    public createOrderMasofferUsingPOST(masoffers: Masoffers, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (masoffers === null || masoffers === undefined) {
            throw new Error('Required parameter masoffers was null or undefined when calling createOrderMasofferUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MasofferRs>(`${this.basePath}/api/air-tickets/affiliate/masoffer/create-order`,
            masoffers,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createOrderMisa
     *
     * @param misaReq misaReq
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrderMisaUsingPOST(misaReq: MisaReq, observe?: 'body', reportProgress?: boolean): Observable<ResponseEntity>;
    public createOrderMisaUsingPOST(misaReq: MisaReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseEntity>>;
    public createOrderMisaUsingPOST(misaReq: MisaReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseEntity>>;
    public createOrderMisaUsingPOST(misaReq: MisaReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (misaReq === null || misaReq === undefined) {
            throw new Error('Required parameter misaReq was null or undefined when calling createOrderMisaUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResponseEntity>(`${this.basePath}/api/air-tickets/affiliate/misa/create-order`,
            misaReq,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createdTagAffiliate
     *
     * @param bookingTagConnections bookingTagConnections
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createdTagAffiliateUsingPOST(bookingTagConnections: BookingTagConnections, observe?: 'body', reportProgress?: boolean): Observable<BookingTagConnections>;
    public createdTagAffiliateUsingPOST(bookingTagConnections: BookingTagConnections, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingTagConnections>>;
    public createdTagAffiliateUsingPOST(bookingTagConnections: BookingTagConnections, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingTagConnections>>;
    public createdTagAffiliateUsingPOST(bookingTagConnections: BookingTagConnections, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingTagConnections === null || bookingTagConnections === undefined) {
            throw new Error('Required parameter bookingTagConnections was null or undefined when calling createdTagAffiliateUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingTagConnections>(`${this.basePath}/api/air-tickets/created-booking-tag`,
            bookingTagConnections,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
