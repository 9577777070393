import {
  Component,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AdditionalRequestResourceService,
  AirItineraryPricingInfo,
  BookingAdditionalRequestDTO,
  BookingTravelerInfo,
  PaymentFeeOptionRes,
  ProductResourceService
} from '@gtd/b2c-client';
import { BreadcrumbBar } from '@gtd/components/breadcrumb-bar';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SearchUserBooking,
  SearchUserBookingFacade
} from '@gtd/api-services/booking-service';
import {
  DEFAULT_PAGINATION_OFFSET,
  DEFAULT_PAGINATION_PAGE,
  fadeIn,
  fadeInOut
} from '@gtd/helpers';
import { MyBookingService } from './my-booking.service';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SidebarService } from '../sidebar/sidebar.service';
import {
  SearchBookingTransactionsFacade,
  SearchParams
} from '@gtd/api-services/b2b-client';
import { environment } from '@env/environment';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { CreateAdditionalRequestDialogComponent } from './create-additional-request-dialog/create-additional-request-dialog.component';

@Component({
  selector: 'gtd-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('600ms ease-out')),
      transition('default => rotated', animate('600ms ease-in'))
    ]),
    fadeIn,
    fadeInOut
  ]
})
export class BookingsComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  appName = localStorage.getItem('appName');
  isProd: boolean;
  breadcrumbBars: BreadcrumbBar[] = [
    {
      name: 'global.homepage',
      isRouterLink: true,
      routerLink: '/'
    },
    {
      isRouterLink: false,
      name: 'booking.booking-management'
    }
  ];
  mode = '';
  private subscription: Subscription = new Subscription();
  allSearchBookingTransactions$ = this.searchBookingTransactionsFacade
    .allSearchBookingTransactions$;
  loadedB2b$ = this.searchBookingTransactionsFacade.loaded$;
  allSearchUserBooking$ = this.searchUserBookingFacade.allSearchUserBooking$;
  allTotalCount$ = this.searchUserBookingFacade.allTotalCount$;
  loaded$ = this.searchUserBookingFacade.loaded$;
  filters: SearchUserBooking;
  b2bFilters: SearchParams;
  configPagination: any;
  currentPage;
  totalCount;
  p = 1;
  supplierType = 'bookingMn';
  productType = ['FLIGHT', 'HOTEL', 'COMBO', 'TOURS'];
  subscriptions = new Subscription();

  constructor(
    private deviceService: DeviceDetectorService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private searchUserBookingFacade: SearchUserBookingFacade,
    private myBookingService: MyBookingService,
    private searchBookingTransactionsFacade: SearchBookingTransactionsFacade,
    private additionalRequestResourceService: AdditionalRequestResourceService,
    private sidebarService: SidebarService,
    private productResourceService: ProductResourceService
  ) {
    route.queryParams.subscribe(queryParams => {
      if (!this.isLoading && queryParams['type']) {
        this.supplierType = queryParams['type'];
        if (this.appName === 'B2C' || this.appName === 'B2B2C') {
          this.myBookingService.setDataFilters({
            ...this.filters,
            skipSearch: false,
            currentPage: 0,
            supplierType: queryParams['type'],
            page: DEFAULT_PAGINATION_PAGE,
            size: DEFAULT_PAGINATION_OFFSET
          });
        }
        if (this.appName === 'B2B') {
          this.myBookingService.setDataB2BFilters({
            ...this.b2bFilters,
            skipSearch: false,
            currentPage: 0,
            productCode: queryParams['type'],
            page: DEFAULT_PAGINATION_PAGE,
            size: DEFAULT_PAGINATION_OFFSET
          });
        }
      }
    });
  }

  ngOnInit() {
    this.isProd = environment.production;
    this.sidebarService.getNavChange.subscribe(getNavChange => {
      if (getNavChange && getNavChange === 'bookingMn') {
        if (this.configPagination) this.configPagination.currentPage = 0;
        this.myBookingService.filterDestroy();
      }
    });
    if (this.appName === 'B2B2C') {
      try {
        const localStorageGet = localStorage.getItem('partner_gotadi');
        if (localStorageGet) {
          const partner_gotadi = JSON.parse(localStorageGet);
          if (
            partner_gotadi &&
            partner_gotadi.layout &&
            partner_gotadi.layout === 'single'
          ) {
            this.productType = [partner_gotadi.type.toUpperCase()];
          }
        }
      } catch (e) {}
    }
    if (this.appName === 'B2C' || this.appName === 'B2B2C') {
      this.subscription.add(
        this.myBookingService.getDataFilters.subscribe(filters => {
          if (filters) {
            this.configPagination = {
              id: 'foo',
              maxSize: this.deviceService.isDesktop() ? 8 : 5,
              itemsPerPage: DEFAULT_PAGINATION_OFFSET,
              currentPage: filters.currentPage,
              totalItems: filters.totalCount
            };
            this.filters = filters;
            this.currentPage = filters.currentPage;
            this.totalCount = filters.totalCount;
            if (!filters.skipSearch) {
              this.isLoading = true;
              this.searchUserBookingFacade.loadAll({
                filter: {
                  bookingCode:
                    filters.filter && filters.filter.bookingCode
                      ? filters.filter.bookingCode
                      : '',
                  bookingStatus:
                    filters.filter && filters.filter.bookingStatus
                      ? filters.filter.bookingStatus
                      : '',
                  fromLocationName:
                    filters.filter && filters.filter.fromLocationName
                      ? filters.filter.fromLocationName
                      : '',
                  toLocationName:
                    filters.filter && filters.filter.toLocationName
                      ? filters.filter.toLocationName
                      : '',
                  fromDate:
                    filters.filter && filters.filter.fromDate
                      ? filters.filter.fromDate
                      : '',
                  toDate:
                    filters.filter && filters.filter.toDate
                      ? filters.filter.toDate
                      : '',
                  listBookingStatus:
                    filters.filter && filters.filter.listBookingStatus
                      ? filters.filter.listBookingStatus
                      : []
                },
                supplierType: filters.supplierType
                  ? filters.supplierType
                  : 'AIR',
                page: filters.page,
                size: filters.size,
                sort: filters.sort
              });
            }
          } else {
            this.isLoading = true;
            this.searchUserBookingFacade.loadAll({
              supplierType: 'AIR',
              page: DEFAULT_PAGINATION_PAGE,
              size: DEFAULT_PAGINATION_OFFSET
            });
          }
        })
      );
      this.subscription.add(
        this.searchUserBookingFacade.allTotalCount$.subscribe(totalCount => {
          this.configPagination = {
            id: 'foo',
            maxSize: this.deviceService.isDesktop() ? 8 : 5,
            itemsPerPage: DEFAULT_PAGINATION_OFFSET,
            currentPage: this.currentPage ? this.currentPage : 0,
            totalItems: totalCount
          };
          if (totalCount && totalCount > DEFAULT_PAGINATION_OFFSET) {
            this.isLoading = false;
            this.myBookingService.setDataFilters({
              ...this.filters,
              skipSearch: true,
              totalCount: totalCount
            });
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
      );
      this.subscription.add(
        this.searchUserBookingFacade.allSearchUserBooking$.subscribe(
          allSearch => {
            this.isLoading = false;
          }
        )
      );
    }
    if (this.appName === 'B2B') {
      this.subscription.add(
        this.myBookingService.getDataB2BFilters.subscribe(filters => {
          if (filters) {
            this.configPagination = {
              id: 'foo',
              maxSize: this.deviceService.isDesktop() ? 8 : 5,
              itemsPerPage: DEFAULT_PAGINATION_OFFSET,
              currentPage: filters.currentPage,
              totalItems: filters.totalCount
            };
            this.b2bFilters = filters;
            this.currentPage = filters.currentPage;
            this.totalCount = filters.totalCount;
            if (!filters.skipSearch) {
              this.isLoading = true;
              this.searchBookingTransactionsFacade.loadAll({
                filter: {
                  status:
                    filters.filter && filters.filter.status
                      ? filters.filter.status
                      : '',
                  agencyCode:
                    filters.filter && filters.filter.agencyCode
                      ? filters.filter.agencyCode
                      : '',
                  bookingNumber:
                    filters.filter && filters.filter.bookingNumber
                      ? filters.filter.bookingNumber
                      : '',
                  providerBrn:
                    filters.filter && filters.filter.providerBrn
                      ? filters.filter.providerBrn
                      : '',
                  email:
                    filters.filter && filters.filter.email
                      ? filters.filter.email
                      : '',
                  customerName:
                    filters.filter && filters.filter.customerName
                      ? filters.filter.customerName
                      : '',
                  supplierCode:
                    filters.filter && filters.filter.supplierCode
                      ? filters.filter.supplierCode
                      : '',
                  bookingDateFrom:
                    filters.filter && filters.filter.bookingDateFrom
                      ? filters.filter.bookingDateFrom
                      : '',
                  bookingDateTo:
                    filters.filter && filters.filter.bookingDateTo
                      ? filters.filter.bookingDateTo
                      : '',
                  typeCode:
                    filters.filter && filters.filter.typeCode
                      ? filters.filter.typeCode
                      : '',
                  phoneNumber:
                    filters.filter && filters.filter.phoneNumber
                      ? filters.filter.phoneNumber
                      : '',
                  hasIncludeBookingContent: true
                },
                productCode: filters.productCode ? filters.productCode : 'AIR',
                ignoreSummary: true,
                page: filters.page,
                size: filters.size,
                sort: filters.sort
              });
            }
          } else {
            this.isLoading = true;
            this.searchBookingTransactionsFacade.loadAll({
              productCode: 'AIR',
              ignoreSummary: true,
              page: DEFAULT_PAGINATION_PAGE,
              size: DEFAULT_PAGINATION_OFFSET
            });
          }
        })
      );
      this.subscription.add(
        this.searchBookingTransactionsFacade.allSearchBookingTransactions$.subscribe(
          searchBookingTransactions => {
            this.isLoading = false;

            if (
              searchBookingTransactions &&
              searchBookingTransactions &&
              searchBookingTransactions.bookingDTOs &&
              searchBookingTransactions.bookingDTOs.length > 0
            ) {
              this.configPagination = {
                id: 'foo',
                maxSize: this.deviceService.isDesktop() ? 8 : 5,
                itemsPerPage: DEFAULT_PAGINATION_OFFSET,
                currentPage: this.currentPage ? this.currentPage : 0,
                totalItems: searchBookingTransactions.totalElements
              };
              this.myBookingService.setDataFilters({
                ...this.filters,
                skipSearch: true,
                totalCount: searchBookingTransactions.totalElements
              });
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }
          }
        )
      );
    }
  }

  pageChanged(event: number, totalCount?: number) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.configPagination.currentPage = event;
    this.currentPage = event;
    if (this.appName === 'B2C' || this.appName === 'B2B2C') {
      if (event)
        this.myBookingService.setDataFilters({
          ...this.filters,
          currentPage: event,
          totalCount: totalCount,
          skipSearch: false,
          page: event - 1,
          size: DEFAULT_PAGINATION_OFFSET
        });
    }
    if (this.appName === 'B2B') {
      if (event)
        this.myBookingService.setDataB2BFilters({
          ...this.b2bFilters,
          currentPage: event,
          totalCount: totalCount,
          skipSearch: false,
          page: event - 1,
          size: DEFAULT_PAGINATION_OFFSET
        });
    }
  }

  changeFilterSubmit(event: any) {
    this.configPagination.currentPage = 0;
    this.currentPage = 0;
    if (this.appName === 'B2C' || this.appName === 'B2B2C') {
      this.myBookingService.setDataFilters({
        ...this.filters,
        currentPage: 0,
        skipSearch: true
      });
    }
    if (this.appName === 'B2B') {
      this.myBookingService.setDataB2BFilters({
        ...this.b2bFilters,
        currentPage: 0,
        skipSearch: true
      });
    }
  }

  openCreateAdditionalRequestDialog(data: any) {
    let getBookingDetailSnackBar = this._snackBar.openFromComponent(
      SnackbarComponent,
      {
        data: {
          message: 'Đang lấy thông tin đặt chỗ, vui lòng đợi trong giây lát',
          loading: true
        },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      }
    );
    this.productResourceService
      .getBookingDetailUsingGET(data.bookingNumber)
      .subscribe(
        booking => {
          getBookingDetailSnackBar.dismiss();
          const dialogRef = this.dialog.open(
            CreateAdditionalRequestDialogComponent,
            {
              data: {
                type: data.type,
                bookingInfo: booking.bookingInfo,
                propertyName:
                  booking.hotelProduct && booking.hotelProduct.propertyName
                    ? booking.hotelProduct.propertyName
                    : null
              },
              position: {
                right: '0',
                top: '0'
              },
              autoFocus: false,
              panelClass: [
                'animate__animated',
                'animate__slideInRight',
                'animate__faster',
                'create-additional-request-dialog-panel-class'
              ],
              disableClose: true
            }
          );
          dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult && dialogResult.action === 'send-request') {
              let sendRequestSnackBar = this._snackBar.openFromComponent(
                SnackbarComponent,
                {
                  data: {
                    message: 'additional-request.snack-bar-loading',
                    loading: true
                  },
                  panelClass: 'snackbar-loading',
                  verticalPosition: 'top'
                }
              );
              let bookingDescription = '';
              if (booking.supplierType === 'AIR') {
                bookingDescription =
                  booking.bookingInfo.fromCity +
                  ' (' +
                  booking.bookingInfo.fromLocationCode +
                  ') - ' +
                  booking.bookingInfo.toCity +
                  ' (' +
                  booking.bookingInfo.toLocationCode +
                  ')';
              } else if (booking.supplierType === 'HOTEL') {
                bookingDescription = booking.hotelProduct.propertyName;
              } else if (booking.bookingInfo.supplierType === 'COMBO') {
                bookingDescription =
                  booking.bookingInfo.fromCity +
                  ' (' +
                  booking.bookingInfo.fromLocationCode +
                  ') - ' +
                  booking.bookingInfo.toCity +
                  ' (' +
                  booking.bookingInfo.toLocationCode +
                  '), ' +
                  booking.hotelProduct.propertyName;
              } else if (booking.supplierType === 'TOURS') {
                bookingDescription = booking.bookingInfo.fromLocationName;
              }
              let bookingAdditionalRequestDTO: BookingAdditionalRequestDTO = {
                id: null,
                bookingCode: booking.bookingCode,
                bookingNumber: booking.bookingNumber,
                requestType: dialogResult.data.type,
                requestContent: dialogResult.data.content,
                status: 'PENDING',
                createdByNotes: null,
                bookingDescription: bookingDescription,
                bookingId: booking.bookingInfo.id
              };
              this.subscriptions.add(
                this.additionalRequestResourceService
                  .createAdditionalRequestUsingPOST(bookingAdditionalRequestDTO)
                  .subscribe(
                    () => {
                      sendRequestSnackBar.dismiss();
                      this.openNotificationSnackBar(
                        'user.create-additional-request-succeeded',
                        'check',
                        'snackbar-success'
                      );
                    },
                    () => {
                      sendRequestSnackBar.dismiss();
                      this.dialog.open(DialogMessageComponent, {
                        data: {
                          btn: [{ btnText: 'global.close' }],
                          content: 'user.system-error-message',
                          heading: 'user.create-additional-request-failed',
                          icon: 'exclamation-triangle',
                          messageType: 'error'
                        },
                        panelClass: 'dialog-message'
                      });
                    }
                  )
              );
            }
          });
        },
        () => {
          getBookingDetailSnackBar.dismiss();
        }
      );
  }

  openNotificationSnackBar(message: string, icon: string, panelClass: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: icon
      },
      panelClass: panelClass,
      verticalPosition: 'top',
      duration: 2000
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeSupplier(supplier: string) {
    if (!this.isLoading) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      if (this.configPagination) this.configPagination.currentPage = 0;
      this.router.navigate(['/user/bookings'], {
        queryParams: {
          type: supplier
        }
      });
    }
  }
}

@Pipe({
  name: 'totalFare'
})
export class TotalFare implements PipeTransform {
  transform(totalFare: number, priceAddition?: PriceAddition): number {
    if (priceAddition && priceAddition.totalSsr) {
      totalFare += priceAddition.totalSsr;
    }
    if (priceAddition && priceAddition.insuranceFees) {
      totalFare += priceAddition.insuranceFees;
    }
    if (priceAddition && priceAddition.discount) {
      totalFare -= priceAddition.discount;
    }
    if (
      priceAddition &&
      priceAddition.paymentFee &&
      priceAddition.paymentFee.peymentFees.length > 0
    ) {
      priceAddition.paymentFee.peymentFees.forEach(paymentFeeOptionRes => {
        totalFare += paymentFeeOptionRes.amount;
      });
    }
    return totalFare;
  }
}

@Pipe({
  name: 'totalPriceItemDetail'
})
export class TotalPriceItemDetail implements PipeTransform {
  transform(
    airItineraryPricingInfo: AirItineraryPricingInfo,
    priceAddition?: PriceAddition
  ): number {
    let totalPrice =
      airItineraryPricingInfo.adultFare.passengerFare.totalFare.amount;
    if (airItineraryPricingInfo.childFare) {
      totalPrice +=
        airItineraryPricingInfo.childFare.passengerFare.totalFare.amount;
    }
    if (airItineraryPricingInfo.infantFare) {
      totalPrice +=
        airItineraryPricingInfo.infantFare.passengerFare.totalFare.amount;
    }
    return totalPrice;
  }
}

@Pipe({
  name: 'totalServiceFee'
})
export class TotalServiceFee implements PipeTransform {
  transform(value: number, paymentFeeOptionRes?: PaymentFeeOptionRes): number {
    let totalServiceFee = value;
    if (paymentFeeOptionRes && paymentFeeOptionRes.peymentFees.length > 0) {
      // tslint:disable-next-line:no-shadowed-variable
      paymentFeeOptionRes.peymentFees.forEach((paymentFeeOptionRes: any) => {
        totalServiceFee += paymentFeeOptionRes.amount;
      });
    }
    return totalServiceFee;
  }
}
export interface PriceAddition {
  totalSsr?: number;
  insuranceFees?: number;
  discount?: number;
  paymentFee?: PaymentFeeOptionRes;
}

@Pipe({
  name: 'serviceRequests'
})
export class ServiceRequests implements PipeTransform {
  transform(
    transactionInfos: Array<BookingTravelerInfo>,
    type: string,
    ssrId?: string
  ): number {
    let totalServiceRequests = 0;
    if (transactionInfos && transactionInfos.length > 0 && type) {
      transactionInfos.forEach(transactionInfo => {
        if (
          transactionInfo.serviceRequests &&
          transactionInfo.serviceRequests.length > 0
        ) {
          transactionInfo.serviceRequests.forEach(serviceRequest => {
            if (!ssrId && serviceRequest.serviceType === type) {
              totalServiceRequests += serviceRequest.ssrAmount;
            } else if (
              serviceRequest.ssrId.indexOf(ssrId) > 0 &&
              serviceRequest.serviceType === type
            ) {
              totalServiceRequests += serviceRequest.ssrAmount;
            }
          });
        }
      });
    }
    return totalServiceRequests;
  }
}
