import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {RequestPasswordStatePartialState} from './request-password-state.reducer';
import {
  LoadRequestPasswordState,
  RequestPasswordStateActionTypes,
  RequestPasswordStateLoadError,
  LoadPhoneRequestPasswordState,
  PhoneRequestPasswordStateLoadError
} from './request-password-state.actions';
import {map} from "rxjs/operators";
import {AccountResourceService} from "@gtd/b2c-client";

@Injectable()
export class RequestPasswordStateEffects {
  @Effect() loadRequestPasswordState$ = this.dataPersistence.fetch(
    RequestPasswordStateActionTypes.LoadRequestPasswordState,
    {
      run: (
        action: LoadRequestPasswordState,
        state: RequestPasswordStatePartialState
      ) => {
        return this.accountResourceService.requestPasswordResetUsingPOST(action.payload).pipe(
          map(update => ({
            type: RequestPasswordStateActionTypes.RequestPasswordStateLoaded,
            payload: update,
          }))
        );
      },

      onError: (action: LoadRequestPasswordState, error) => {
        console.error('Error', error);
        return new RequestPasswordStateLoadError(error);
      }
    }
  );

  @Effect() loadPhoneRequestPasswordState$ = this.dataPersistence.fetch(
    RequestPasswordStateActionTypes.LoadPhoneRequestPasswordState,
    {
      run: (
        action: LoadPhoneRequestPasswordState,
        state: RequestPasswordStatePartialState
      ) => {
        return this.accountResourceService.requestMobilePasswordResetUsingPOST(action.payload).pipe(
          map(update => ({
            type: RequestPasswordStateActionTypes.PhoneRequestPasswordStateLoaded,
            payload: update,
          }))
        );
      },

      onError: (action: LoadPhoneRequestPasswordState, error) => {
        console.error('Error', error);
        return new PhoneRequestPasswordStateLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private accountResourceService: AccountResourceService,
    private dataPersistence: DataPersistence<RequestPasswordStatePartialState>
  ) {}
}
