/**
 * CMS API
 * Just another WordPress site
 *
 * OpenAPI spec version: 5.9
 * Contact: itm@gotadi.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class BannerTypeService {
  protected basePath = 'https://cms.gotadi.com/wp-json';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param bannerTaxonomy Limit result set to items with specific terms assigned in the banner_taxonomy taxonomy.
   * @param bannerDevice Limit result set to items with specific terms assigned in the banner_device taxonomy.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wpV2BannerTypeGet(
    bannerTaxonomy?: string,
    bannerDevice?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public wpV2BannerTypeGet(
    bannerTaxonomy?: string,
    bannerDevice?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public wpV2BannerTypeGet(
    bannerTaxonomy?: string,
    bannerDevice?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public wpV2BannerTypeGet(
    bannerTaxonomy?: string,
    bannerDevice?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });

    if (bannerTaxonomy !== undefined && bannerTaxonomy !== null) {
      queryParameters = queryParameters.set('banner_taxonomy', <any>(
        bannerTaxonomy
      ));
    }

    if (bannerDevice !== undefined && bannerDevice !== null) {
      queryParameters = queryParameters.set('banner_device', <any>bannerDevice);
    }

    let headers = this.defaultHeaders;

    // authentication (basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' +
          btoa(this.configuration.username + ':' + this.configuration.password)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/wp/v2/banner_type`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param parent The ID for the parent of the autosave.
   * @param context Scope under which the request is made; determines fields present in response.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wpV2BannerTypeIdAutosavesGet(
    id: number,
    parent?: string,
    context?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public wpV2BannerTypeIdAutosavesGet(
    id: number,
    parent?: string,
    context?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public wpV2BannerTypeIdAutosavesGet(
    id: number,
    parent?: string,
    context?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public wpV2BannerTypeIdAutosavesGet(
    id: number,
    parent?: string,
    context?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling wpV2BannerTypeIdAutosavesGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (parent !== undefined && parent !== null) {
      queryParameters = queryParameters.set('parent', <any>parent);
    }
    if (context) {
      context.forEach(element => {
        queryParameters = queryParameters.append('context', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' +
          btoa(this.configuration.username + ':' + this.configuration.password)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/wp/v2/banner_type/${encodeURIComponent(
        String(id)
      )}/autosaves`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param parent2
   * @param date2
   * @param dateGmt2
   * @param slug2
   * @param status2
   * @param password2
   * @param title2
   * @param excerpt2
   * @param featuredMedia2
   * @param meta2
   * @param template2
   * @param bannerTaxonomy2
   * @param bannerDevice2
   * @param acf2
   * @param parent
   * @param date
   * @param dateGmt
   * @param slug
   * @param status
   * @param password
   * @param title
   * @param excerpt
   * @param featuredMedia
   * @param meta
   * @param template
   * @param bannerTaxonomy
   * @param bannerDevice
   * @param acf
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wpV2BannerTypeIdAutosavesPostForm(
    id: number,
    parent2?: string,
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    parent?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public wpV2BannerTypeIdAutosavesPostForm(
    id: number,
    parent2?: string,
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    parent?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public wpV2BannerTypeIdAutosavesPostForm(
    id: number,
    parent2?: string,
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    parent?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public wpV2BannerTypeIdAutosavesPostForm(
    id: number,
    parent2?: string,
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    parent?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling wpV2BannerTypeIdAutosavesPost.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' +
          btoa(this.configuration.username + ':' + this.configuration.password)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/x-www-form-urlencoded',
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (parent !== undefined) {
      formParams =
        (formParams.append('parent', <any>parent) as any) || formParams;
    }
    if (date !== undefined) {
      formParams = (formParams.append('date', <any>date) as any) || formParams;
    }
    if (dateGmt !== undefined) {
      formParams =
        (formParams.append('date_gmt', <any>dateGmt) as any) || formParams;
    }
    if (slug !== undefined) {
      formParams = (formParams.append('slug', <any>slug) as any) || formParams;
    }
    if (status) {
      status.forEach(element => {
        formParams =
          (formParams.append('status', <any>element) as any) || formParams;
      });
    }
    if (password !== undefined) {
      formParams =
        (formParams.append('password', <any>password) as any) || formParams;
    }
    if (title !== undefined) {
      formParams =
        (formParams.append('title', <any>title) as any) || formParams;
    }
    if (excerpt !== undefined) {
      formParams =
        (formParams.append('excerpt', <any>excerpt) as any) || formParams;
    }
    if (featuredMedia !== undefined) {
      formParams =
        (formParams.append('featured_media', <any>featuredMedia) as any) ||
        formParams;
    }
    if (meta !== undefined) {
      formParams = (formParams.append('meta', <any>meta) as any) || formParams;
    }
    if (template !== undefined) {
      formParams =
        (formParams.append('template', <any>template) as any) || formParams;
    }
    if (bannerTaxonomy !== undefined) {
      formParams =
        (formParams.append('banner_taxonomy', <any>bannerTaxonomy) as any) ||
        formParams;
    }
    if (bannerDevice !== undefined) {
      formParams =
        (formParams.append('banner_device', <any>bannerDevice) as any) ||
        formParams;
    }
    if (acf !== undefined) {
      formParams = (formParams.append('acf', <any>acf) as any) || formParams;
    }
    if (parent !== undefined) {
      formParams =
        (formParams.append('parent', <any>parent) as any) || formParams;
    }
    if (date !== undefined) {
      formParams = (formParams.append('date', <any>date) as any) || formParams;
    }
    if (dateGmt !== undefined) {
      formParams =
        (formParams.append('date_gmt', <any>dateGmt) as any) || formParams;
    }
    if (slug !== undefined) {
      formParams = (formParams.append('slug', <any>slug) as any) || formParams;
    }
    if (status) {
      status.forEach(element => {
        formParams =
          (formParams.append('status', <any>element) as any) || formParams;
      });
    }
    if (password !== undefined) {
      formParams =
        (formParams.append('password', <any>password) as any) || formParams;
    }
    if (title !== undefined) {
      formParams =
        (formParams.append('title', <any>title) as any) || formParams;
    }
    if (excerpt !== undefined) {
      formParams =
        (formParams.append('excerpt', <any>excerpt) as any) || formParams;
    }
    if (featuredMedia !== undefined) {
      formParams =
        (formParams.append('featured_media', <any>featuredMedia) as any) ||
        formParams;
    }
    if (meta !== undefined) {
      formParams = (formParams.append('meta', <any>meta) as any) || formParams;
    }
    if (template !== undefined) {
      formParams =
        (formParams.append('template', <any>template) as any) || formParams;
    }
    if (bannerTaxonomy !== undefined) {
      formParams =
        (formParams.append('banner_taxonomy', <any>bannerTaxonomy) as any) ||
        formParams;
    }
    if (bannerDevice !== undefined) {
      formParams =
        (formParams.append('banner_device', <any>bannerDevice) as any) ||
        formParams;
    }
    if (acf !== undefined) {
      formParams = (formParams.append('acf', <any>acf) as any) || formParams;
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/wp/v2/banner_type/${encodeURIComponent(
        String(id)
      )}/autosaves`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id Unique identifier for the post.
   * @param force Whether to bypass Trash and force deletion.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wpV2BannerTypeIdDelete(
    id: string,
    force?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public wpV2BannerTypeIdDelete(
    id: string,
    force?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public wpV2BannerTypeIdDelete(
    id: string,
    force?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public wpV2BannerTypeIdDelete(
    id: string,
    force?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling wpV2BannerTypeIdDelete.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (force !== undefined && force !== null) {
      queryParameters = queryParameters.set('force', <any>force);
    }

    let headers = this.defaultHeaders;

    // authentication (basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' +
          btoa(this.configuration.username + ':' + this.configuration.password)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/wp/v2/banner_type/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id Unique identifier for the post.
   * @param context Scope under which the request is made; determines fields present in response.
   * @param password The password for the post if it is password protected.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wpV2BannerTypeIdGet(
    id: string,
    context?: Array<string>,
    password?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public wpV2BannerTypeIdGet(
    id: string,
    context?: Array<string>,
    password?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public wpV2BannerTypeIdGet(
    id: string,
    context?: Array<string>,
    password?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public wpV2BannerTypeIdGet(
    id: string,
    context?: Array<string>,
    password?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling wpV2BannerTypeIdGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (context) {
      context.forEach(element => {
        queryParameters = queryParameters.append('context', <any>element);
      });
    }
    if (password !== undefined && password !== null) {
      queryParameters = queryParameters.set('password', <any>password);
    }

    let headers = this.defaultHeaders;

    // authentication (basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' +
          btoa(this.configuration.username + ':' + this.configuration.password)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/wp/v2/banner_type/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id Unique identifier for the post.
   * @param date The date the post was published, in the site&#x27;s timezone.
   * @param dateGmt The date the post was published, as GMT.
   * @param slug An alphanumeric identifier for the post unique to its type.
   * @param status A named status for the post.
   * @param password A password to protect access to the content and excerpt.
   * @param title The title for the post.
   * @param excerpt The excerpt for the post.
   * @param featuredMedia The ID of the featured media for the post.
   * @param meta Meta fields.
   * @param template The theme file to use to display the post.
   * @param bannerTaxonomy The terms assigned to the post in the banner_taxonomy taxonomy.
   * @param bannerDevice The terms assigned to the post in the banner_device taxonomy.
   * @param acf Expose advanced custom fields.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wpV2BannerTypeIdPatch(
    id: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public wpV2BannerTypeIdPatch(
    id: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public wpV2BannerTypeIdPatch(
    id: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public wpV2BannerTypeIdPatch(
    id: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling wpV2BannerTypeIdPatch.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (date !== undefined && date !== null) {
      queryParameters = queryParameters.set('date', <any>date.toISOString());
    }
    if (dateGmt !== undefined && dateGmt !== null) {
      queryParameters = queryParameters.set('date_gmt', <any>(
        dateGmt.toISOString()
      ));
    }
    if (slug !== undefined && slug !== null) {
      queryParameters = queryParameters.set('slug', <any>slug);
    }
    if (status) {
      status.forEach(element => {
        queryParameters = queryParameters.append('status', <any>element);
      });
    }
    if (password !== undefined && password !== null) {
      queryParameters = queryParameters.set('password', <any>password);
    }
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }
    if (excerpt !== undefined && excerpt !== null) {
      queryParameters = queryParameters.set('excerpt', <any>excerpt);
    }
    if (featuredMedia !== undefined && featuredMedia !== null) {
      queryParameters = queryParameters.set('featured_media', <any>(
        featuredMedia
      ));
    }
    if (meta !== undefined && meta !== null) {
      queryParameters = queryParameters.set('meta', <any>meta);
    }
    if (template !== undefined && template !== null) {
      queryParameters = queryParameters.set('template', <any>template);
    }
    if (bannerTaxonomy !== undefined && bannerTaxonomy !== null) {
      queryParameters = queryParameters.set('banner_taxonomy', <any>(
        bannerTaxonomy
      ));
    }
    if (bannerDevice !== undefined && bannerDevice !== null) {
      queryParameters = queryParameters.set('banner_device', <any>bannerDevice);
    }
    if (acf !== undefined && acf !== null) {
      queryParameters = queryParameters.set('acf', <any>acf);
    }

    let headers = this.defaultHeaders;

    // authentication (basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' +
          btoa(this.configuration.username + ':' + this.configuration.password)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'patch',
      `${this.basePath}/wp/v2/banner_type/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
  /**
   *
   *
   * @param id Unique identifier for the post.
   * @param date2
   * @param dateGmt2
   * @param slug2
   * @param status2
   * @param password2
   * @param title2
   * @param excerpt2
   * @param featuredMedia2
   * @param meta2
   * @param template2
   * @param bannerTaxonomy2
   * @param bannerDevice2
   * @param acf2
   * @param date
   * @param dateGmt
   * @param slug
   * @param status
   * @param password
   * @param title
   * @param excerpt
   * @param featuredMedia
   * @param meta
   * @param template
   * @param bannerTaxonomy
   * @param bannerDevice
   * @param acf
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wpV2BannerTypeIdPostForm(
    id: string,
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public wpV2BannerTypeIdPostForm(
    id: string,
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public wpV2BannerTypeIdPostForm(
    id: string,
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public wpV2BannerTypeIdPostForm(
    id: string,
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling wpV2BannerTypeIdPost.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' +
          btoa(this.configuration.username + ':' + this.configuration.password)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/x-www-form-urlencoded',
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (date !== undefined) {
      formParams = (formParams.append('date', <any>date) as any) || formParams;
    }
    if (dateGmt !== undefined) {
      formParams =
        (formParams.append('date_gmt', <any>dateGmt) as any) || formParams;
    }
    if (slug !== undefined) {
      formParams = (formParams.append('slug', <any>slug) as any) || formParams;
    }
    if (status) {
      status.forEach(element => {
        formParams =
          (formParams.append('status', <any>element) as any) || formParams;
      });
    }
    if (password !== undefined) {
      formParams =
        (formParams.append('password', <any>password) as any) || formParams;
    }
    if (title !== undefined) {
      formParams =
        (formParams.append('title', <any>title) as any) || formParams;
    }
    if (excerpt !== undefined) {
      formParams =
        (formParams.append('excerpt', <any>excerpt) as any) || formParams;
    }
    if (featuredMedia !== undefined) {
      formParams =
        (formParams.append('featured_media', <any>featuredMedia) as any) ||
        formParams;
    }
    if (meta !== undefined) {
      formParams = (formParams.append('meta', <any>meta) as any) || formParams;
    }
    if (template !== undefined) {
      formParams =
        (formParams.append('template', <any>template) as any) || formParams;
    }
    if (bannerTaxonomy !== undefined) {
      formParams =
        (formParams.append('banner_taxonomy', <any>bannerTaxonomy) as any) ||
        formParams;
    }
    if (bannerDevice !== undefined) {
      formParams =
        (formParams.append('banner_device', <any>bannerDevice) as any) ||
        formParams;
    }
    if (acf !== undefined) {
      formParams = (formParams.append('acf', <any>acf) as any) || formParams;
    }
    if (date !== undefined) {
      formParams = (formParams.append('date', <any>date) as any) || formParams;
    }
    if (dateGmt !== undefined) {
      formParams =
        (formParams.append('date_gmt', <any>dateGmt) as any) || formParams;
    }
    if (slug !== undefined) {
      formParams = (formParams.append('slug', <any>slug) as any) || formParams;
    }
    if (status) {
      status.forEach(element => {
        formParams =
          (formParams.append('status', <any>element) as any) || formParams;
      });
    }
    if (password !== undefined) {
      formParams =
        (formParams.append('password', <any>password) as any) || formParams;
    }
    if (title !== undefined) {
      formParams =
        (formParams.append('title', <any>title) as any) || formParams;
    }
    if (excerpt !== undefined) {
      formParams =
        (formParams.append('excerpt', <any>excerpt) as any) || formParams;
    }
    if (featuredMedia !== undefined) {
      formParams =
        (formParams.append('featured_media', <any>featuredMedia) as any) ||
        formParams;
    }
    if (meta !== undefined) {
      formParams = (formParams.append('meta', <any>meta) as any) || formParams;
    }
    if (template !== undefined) {
      formParams =
        (formParams.append('template', <any>template) as any) || formParams;
    }
    if (bannerTaxonomy !== undefined) {
      formParams =
        (formParams.append('banner_taxonomy', <any>bannerTaxonomy) as any) ||
        formParams;
    }
    if (bannerDevice !== undefined) {
      formParams =
        (formParams.append('banner_device', <any>bannerDevice) as any) ||
        formParams;
    }
    if (acf !== undefined) {
      formParams = (formParams.append('acf', <any>acf) as any) || formParams;
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/wp/v2/banner_type/${encodeURIComponent(String(id))}`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id Unique identifier for the post.
   * @param date The date the post was published, in the site&#x27;s timezone.
   * @param dateGmt The date the post was published, as GMT.
   * @param slug An alphanumeric identifier for the post unique to its type.
   * @param status A named status for the post.
   * @param password A password to protect access to the content and excerpt.
   * @param title The title for the post.
   * @param excerpt The excerpt for the post.
   * @param featuredMedia The ID of the featured media for the post.
   * @param meta Meta fields.
   * @param template The theme file to use to display the post.
   * @param bannerTaxonomy The terms assigned to the post in the banner_taxonomy taxonomy.
   * @param bannerDevice The terms assigned to the post in the banner_device taxonomy.
   * @param acf Expose advanced custom fields.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wpV2BannerTypeIdPut(
    id: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public wpV2BannerTypeIdPut(
    id: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public wpV2BannerTypeIdPut(
    id: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public wpV2BannerTypeIdPut(
    id: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling wpV2BannerTypeIdPut.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (date !== undefined && date !== null) {
      queryParameters = queryParameters.set('date', <any>date.toISOString());
    }
    if (dateGmt !== undefined && dateGmt !== null) {
      queryParameters = queryParameters.set('date_gmt', <any>(
        dateGmt.toISOString()
      ));
    }
    if (slug !== undefined && slug !== null) {
      queryParameters = queryParameters.set('slug', <any>slug);
    }
    if (status) {
      status.forEach(element => {
        queryParameters = queryParameters.append('status', <any>element);
      });
    }
    if (password !== undefined && password !== null) {
      queryParameters = queryParameters.set('password', <any>password);
    }
    if (title !== undefined && title !== null) {
      queryParameters = queryParameters.set('title', <any>title);
    }
    if (excerpt !== undefined && excerpt !== null) {
      queryParameters = queryParameters.set('excerpt', <any>excerpt);
    }
    if (featuredMedia !== undefined && featuredMedia !== null) {
      queryParameters = queryParameters.set('featured_media', <any>(
        featuredMedia
      ));
    }
    if (meta !== undefined && meta !== null) {
      queryParameters = queryParameters.set('meta', <any>meta);
    }
    if (template !== undefined && template !== null) {
      queryParameters = queryParameters.set('template', <any>template);
    }
    if (bannerTaxonomy !== undefined && bannerTaxonomy !== null) {
      queryParameters = queryParameters.set('banner_taxonomy', <any>(
        bannerTaxonomy
      ));
    }
    if (bannerDevice !== undefined && bannerDevice !== null) {
      queryParameters = queryParameters.set('banner_device', <any>bannerDevice);
    }
    if (acf !== undefined && acf !== null) {
      queryParameters = queryParameters.set('acf', <any>acf);
    }

    let headers = this.defaultHeaders;

    // authentication (basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' +
          btoa(this.configuration.username + ':' + this.configuration.password)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'put',
      `${this.basePath}/wp/v2/banner_type/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param parent The ID for the parent of the autosave.
   * @param id The ID for the autosave.
   * @param context Scope under which the request is made; determines fields present in response.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wpV2BannerTypeParentGet(
    parent: string,
    id?: string,
    context?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public wpV2BannerTypeParentGet(
    parent: string,
    id?: string,
    context?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public wpV2BannerTypeParentGet(
    parent: string,
    id?: string,
    context?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public wpV2BannerTypeParentGet(
    parent: string,
    id?: string,
    context?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (parent === null || parent === undefined) {
      throw new Error(
        'Required parameter parent was null or undefined when calling wpV2BannerTypeParentGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (context) {
      context.forEach(element => {
        queryParameters = queryParameters.append('context', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' +
          btoa(this.configuration.username + ':' + this.configuration.password)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/wp/v2/banner_type/${encodeURIComponent(
        String(parent)
      )}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param date2
   * @param dateGmt2
   * @param slug2
   * @param status2
   * @param password2
   * @param title2
   * @param excerpt2
   * @param featuredMedia2
   * @param meta2
   * @param template2
   * @param bannerTaxonomy2
   * @param bannerDevice2
   * @param acf2
   * @param date
   * @param dateGmt
   * @param slug
   * @param status
   * @param password
   * @param title
   * @param excerpt
   * @param featuredMedia
   * @param meta
   * @param template
   * @param bannerTaxonomy
   * @param bannerDevice
   * @param acf
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public wpV2BannerTypePostForm(
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public wpV2BannerTypePostForm(
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public wpV2BannerTypePostForm(
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public wpV2BannerTypePostForm(
    date2?: Date,
    dateGmt2?: Date,
    slug2?: string,
    status2?: Array<string>,
    password2?: string,
    title2?: string,
    excerpt2?: string,
    featuredMedia2?: string,
    meta2?: string,
    template2?: string,
    bannerTaxonomy2?: string,
    bannerDevice2?: string,
    acf2?: string,
    date?: Date,
    dateGmt?: Date,
    slug?: string,
    status?: Array<string>,
    password?: string,
    title?: string,
    excerpt?: string,
    featuredMedia?: string,
    meta?: string,
    template?: string,
    bannerTaxonomy?: string,
    bannerDevice?: string,
    acf?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (basic) required
    if (this.configuration.username || this.configuration.password) {
      headers = headers.set(
        'Authorization',
        'Basic ' +
          btoa(this.configuration.username + ':' + this.configuration.password)
      );
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/x-www-form-urlencoded',
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (date !== undefined) {
      formParams = (formParams.append('date', <any>date) as any) || formParams;
    }
    if (dateGmt !== undefined) {
      formParams =
        (formParams.append('date_gmt', <any>dateGmt) as any) || formParams;
    }
    if (slug !== undefined) {
      formParams = (formParams.append('slug', <any>slug) as any) || formParams;
    }
    if (status) {
      status.forEach(element => {
        formParams =
          (formParams.append('status', <any>element) as any) || formParams;
      });
    }
    if (password !== undefined) {
      formParams =
        (formParams.append('password', <any>password) as any) || formParams;
    }
    if (title !== undefined) {
      formParams =
        (formParams.append('title', <any>title) as any) || formParams;
    }
    if (excerpt !== undefined) {
      formParams =
        (formParams.append('excerpt', <any>excerpt) as any) || formParams;
    }
    if (featuredMedia !== undefined) {
      formParams =
        (formParams.append('featured_media', <any>featuredMedia) as any) ||
        formParams;
    }
    if (meta !== undefined) {
      formParams = (formParams.append('meta', <any>meta) as any) || formParams;
    }
    if (template !== undefined) {
      formParams =
        (formParams.append('template', <any>template) as any) || formParams;
    }
    if (bannerTaxonomy !== undefined) {
      formParams =
        (formParams.append('banner_taxonomy', <any>bannerTaxonomy) as any) ||
        formParams;
    }
    if (bannerDevice !== undefined) {
      formParams =
        (formParams.append('banner_device', <any>bannerDevice) as any) ||
        formParams;
    }
    if (acf !== undefined) {
      formParams = (formParams.append('acf', <any>acf) as any) || formParams;
    }
    if (date !== undefined) {
      formParams = (formParams.append('date', <any>date) as any) || formParams;
    }
    if (dateGmt !== undefined) {
      formParams =
        (formParams.append('date_gmt', <any>dateGmt) as any) || formParams;
    }
    if (slug !== undefined) {
      formParams = (formParams.append('slug', <any>slug) as any) || formParams;
    }
    if (status) {
      status.forEach(element => {
        formParams =
          (formParams.append('status', <any>element) as any) || formParams;
      });
    }
    if (password !== undefined) {
      formParams =
        (formParams.append('password', <any>password) as any) || formParams;
    }
    if (title !== undefined) {
      formParams =
        (formParams.append('title', <any>title) as any) || formParams;
    }
    if (excerpt !== undefined) {
      formParams =
        (formParams.append('excerpt', <any>excerpt) as any) || formParams;
    }
    if (featuredMedia !== undefined) {
      formParams =
        (formParams.append('featured_media', <any>featuredMedia) as any) ||
        formParams;
    }
    if (meta !== undefined) {
      formParams = (formParams.append('meta', <any>meta) as any) || formParams;
    }
    if (template !== undefined) {
      formParams =
        (formParams.append('template', <any>template) as any) || formParams;
    }
    if (bannerTaxonomy !== undefined) {
      formParams =
        (formParams.append('banner_taxonomy', <any>bannerTaxonomy) as any) ||
        formParams;
    }
    if (bannerDevice !== undefined) {
      formParams =
        (formParams.append('banner_device', <any>bannerDevice) as any) ||
        formParams;
    }
    if (acf !== undefined) {
      formParams = (formParams.append('acf', <any>acf) as any) || formParams;
    }

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/wp/v2/banner_type`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
