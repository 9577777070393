import { LookupAction, LookupActionTypes } from './lookup.actions';
import {LookupDTO} from "@gtd/b2c-client";

export const LOOKUP_FEATURE_KEY = 'lookup';

/**
 * Interface for the 'Lookup' data used in
 *  - LookupState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}
export interface LookupSearch {
  domainName: string,
  objectName: string,
  page?: number,
  size?: number,
  sort?: Array<string>,
  observe?: 'body',
  reportProgress?: boolean
}

export interface LookupState {
  list: Array<LookupDTO>; // list of Lookup; analogous to a sql normalized table
  selectedId?: string | number; // which Lookup record has been selected
  loaded: boolean; // has the Lookup list been loaded
  error?: any; // last none error (if any)
}

export interface LookupPartialState {
  readonly [LOOKUP_FEATURE_KEY]: LookupState;
}

export const initialState: LookupState = {
  list: [],
  loaded: false
};

export function reducer(
  state: LookupState = initialState,
  action: LookupAction
): LookupState {
  switch (action.type) {
    case LookupActionTypes.LookupLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
