import {Action} from '@ngrx/store';
import {DatePicker} from './datepicker.reducer';

export enum DatepickerActionTypes {
  LoadDatepicker = '[Datepicker] Load Datepicker',
  DatepickerLoaded = '[Datepicker] Datepicker Loaded',
  DatepickerLoadError = '[Datepicker] Datepicker Load Error'
}

export class LoadDatepicker implements Action {
  readonly type = DatepickerActionTypes.LoadDatepicker;
  constructor(public payload: any) {}
}

export class DatepickerLoadError implements Action {
  readonly type = DatepickerActionTypes.DatepickerLoadError;
  constructor(public payload: any) {}
}

export class DatepickerLoaded implements Action {
  readonly type = DatepickerActionTypes.DatepickerLoaded;
  constructor(public payload: DatePicker) {}
}

export type DatepickerAction =
  | LoadDatepicker
  | DatepickerLoaded
  | DatepickerLoadError;

export const fromDatepickerActions = {
  LoadDatepicker,
  DatepickerLoaded,
  DatepickerLoadError
};
