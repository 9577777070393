import {
  AvailableFlightsAction,
  AvailableFlightsActionTypes
} from './available-flights.actions';
// import { AirLowFareSearchRS } from '@gtd/b2c-client';

import {
  AirGroupPricedItineraryRS,
  AirLowFareSearchRS,
  AirPage,
  Destination,
  GroupPricedItinerary,
  HotelMetaKeyword,
  Info,
  PricedItinerary
} from '@gtd/b2c-client';

export const AVAILABLEFLIGHTS_FEATURE_KEY = 'comboVJAvailableFlights';

export interface AvailableFlightsState {
  data: AirLowFareSearchRS; // list of AvailableFlights; analogous to a sql normalized table
  selectedId?: string | number; // which AvailableFlights record has been selected
  loaded: boolean; // has the AvailableFlights list been loaded
  error?: any; // last none error (if any)
}

export interface AvailableFlightsPartialState {
  readonly [AVAILABLEFLIGHTS_FEATURE_KEY]: AvailableFlightsState;
}

export const initialState: AvailableFlightsState = {
  data: {},
  loaded: false
};

export function availableFlightsReducer(
  state: AvailableFlightsState = initialState,
  action: AvailableFlightsAction
): AvailableFlightsState {
  switch (action.type) {
    case AvailableFlightsActionTypes.ComboVJAvailableFlightsLoaded: {
      state = {
        ...state,
        data: action.payload,
        loaded: true
      };
      break;
    }
    case AvailableFlightsActionTypes.ComboVJLoadInternationnalFlights: {
      state = {
        ...state,
        loaded: false
      };
      break;
    }

    case AvailableFlightsActionTypes.ComboVJInternationalFlightsLoaded: {
      state = {
        ...state,
        data: Mapping.prototype.mappingReturnFlightInt(action.payload),
        loaded: true
      };
      break;
    }
    case AvailableFlightsActionTypes.ComboVJSelectAvailableFlights: {
      state = {
        ...state,
        selectedId: action.payload
      };
      break;
    }
    case AvailableFlightsActionTypes.ComboVJResetAvailableFlights: {
      // initialState.loaded = true;
      state = initialState;
      break;
    }
    case AvailableFlightsActionTypes.ComboVJLoadAvailableFlights: {
      state = initialState;
      break;
    }
    case AvailableFlightsActionTypes.ComboVJAvailableFlightsLoadError: {
      initialState.loaded = true;
      state = initialState;
      break;
    }
  }
  return state;
}

export class Mapping {
  mappingReturnFlightInt(
    airGroupPricedItineraryRS: AirGroupPricedItineraryRS
  ): AirLowFareSearchRS {
    if (airGroupPricedItineraryRS) {
      let airLowFareSearchRS = new class implements AirLowFareSearchRS {
        duration: number;
        errors: Array<Error>;
        groupPricedItineraries: Array<GroupPricedItinerary>;
        infos: Array<Info>;
        page: AirPage;
        returnSearchId: string;
        searchId: string;
        success: boolean;
        textMessage: string;
      }();
      if (airGroupPricedItineraryRS.groupPricedItinerary) {
        airLowFareSearchRS.duration = airGroupPricedItineraryRS.duration;
        airLowFareSearchRS.errors = airGroupPricedItineraryRS.errors;
        airLowFareSearchRS.infos = airGroupPricedItineraryRS.infos;
        airLowFareSearchRS.success = airGroupPricedItineraryRS.success;
        airLowFareSearchRS.textMessage = airGroupPricedItineraryRS.textMessage;
        airLowFareSearchRS.searchId = airGroupPricedItineraryRS.searchId;

        let groupPricedItineraries = new Array<GroupPricedItinerary>();

        airGroupPricedItineraryRS.groupPricedItinerary.pricedItineraries.forEach(
          (airGroupPricedItinerary, index) => {
            let pricedItineraries = new Array<PricedItinerary>();
            pricedItineraries.push(
              airGroupPricedItineraryRS.groupPricedItinerary.pricedItineraries[
                index
              ]
            );
            pricedItineraries[0].originDestinationOptions.splice(0, 1);
            groupPricedItineraries.push({
              airSupplier:
                airGroupPricedItineraryRS.groupPricedItinerary.airSupplier,
              aircraft: airGroupPricedItineraryRS.groupPricedItinerary.aircraft,
              airline: airGroupPricedItineraryRS.groupPricedItinerary.airline,
              airlineName:
                airGroupPricedItineraryRS.groupPricedItinerary.airlineName,
              arrivalDateTime:
                airGroupPricedItineraryRS.groupPricedItinerary.arrivalDateTime,
              departureDateTime:
                airGroupPricedItineraryRS.groupPricedItinerary
                  .departureDateTime,
              destinationCity:
                airGroupPricedItineraryRS.groupPricedItinerary.destinationCity,
              destinationCountry:
                airGroupPricedItineraryRS.groupPricedItinerary
                  .destinationCountry,
              destinationCountryCode:
                airGroupPricedItineraryRS.groupPricedItinerary
                  .destinationCountryCode,
              destinationLocationCode:
                airGroupPricedItineraryRS.groupPricedItinerary
                  .destinationLocationCode,
              destinationLocationName:
                airGroupPricedItineraryRS.groupPricedItinerary
                  .destinationLocationName,
              flightType:
                airGroupPricedItineraryRS.groupPricedItinerary.flightType,
              fightNo: airGroupPricedItineraryRS.groupPricedItinerary.fightNo,
              groupId: airGroupPricedItineraryRS.groupPricedItinerary.groupId,
              originCity:
                airGroupPricedItineraryRS.groupPricedItinerary.originCity,
              originCountry:
                airGroupPricedItineraryRS.groupPricedItinerary.originCountry,
              originCountryCode:
                airGroupPricedItineraryRS.groupPricedItinerary
                  .originCountryCode,
              originLocationCode:
                airGroupPricedItineraryRS.groupPricedItinerary
                  .originLocationCode,
              originLocationName:
                airGroupPricedItineraryRS.groupPricedItinerary
                  .originLocationName,
              pricedItineraries: pricedItineraries,
              requiredFields:
                airGroupPricedItineraryRS.groupPricedItinerary.requiredFields,
              returnDateTime:
                airGroupPricedItineraryRS.groupPricedItinerary.returnDateTime,
              roundType:
                airGroupPricedItineraryRS.groupPricedItinerary.roundType,
              totalPricedItinerary:
                pricedItineraries[0].originDestinationOptions.length - 1
            });
          }
        );
        airLowFareSearchRS.groupPricedItineraries = groupPricedItineraries;
        airLowFareSearchRS.page = {
          nextPageNumber: -1,
          offset:
            airGroupPricedItineraryRS.groupPricedItinerary.pricedItineraries
              .length,
          pageNumber: 0,
          previousPageNumber: -1,
          totalElements:
            airGroupPricedItineraryRS.groupPricedItinerary.pricedItineraries
              .length,
          totalPage: 1
        };
      }
      return airLowFareSearchRS;
    }
  }
}
