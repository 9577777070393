export class ArrayUtils {
  static initializeArrayByLength(length: number): Array<number> {
    return Array(length).fill(0);
  }
  static initializeBooleanArrayByLengthWithIndex(
    length: number,
    index: number
  ) {
    return Array(length)
      .fill(false)
      .fill(true, 0, index);
  }
}
