import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  PROMOTIONSECTIONSTATE_FEATURE_KEY,
  PromotionSectionStateState
} from './promotion-section-state.reducer';

// Lookup the 'PromotionSectionState' feature state managed by NgRx
const getPromotionSectionStateState = createFeatureSelector<
  PromotionSectionStateState
>(PROMOTIONSECTIONSTATE_FEATURE_KEY);

const getLoaded = createSelector(
  getPromotionSectionStateState,
  (state: PromotionSectionStateState) => state.loaded
);
const getError = createSelector(
  getPromotionSectionStateState,
  (state: PromotionSectionStateState) => state.error
);

const getAllPromotionSectionState = createSelector(
  getPromotionSectionStateState,
  getLoaded,
  (state: PromotionSectionStateState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getPromotionSectionStateState,
  (state: PromotionSectionStateState) => state.selectedId
);
const getSelectedPromotionSectionState = createSelector(
  getAllPromotionSectionState,
  getSelectedId,
  (promotionSectionState, id) => {
    return promotionSectionState ? Object.assign({}, promotionSectionState) : undefined;
  }
);

export const promotionSectionStateQuery = {
  getLoaded,
  getError,
  getAllPromotionSectionState,
  getSelectedPromotionSectionState
};
