import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {CountryCode, CountryPartialState} from './country.reducer';
import { countryQuery } from './country.selectors';
import { LoadCountry } from './country.actions';

@Injectable()
export class CountryFacade {
  loaded$ = this.store.pipe(select(countryQuery.getLoaded));
  allCountry$ = this.store.pipe(select(countryQuery.getAllCountry));
  selectedCountry$ = this.store.pipe(select(countryQuery.getSelectedCountry));

  constructor(private store: Store<CountryPartialState>) {}

  loadAll(countryCode: CountryCode) {
    this.store.dispatch(new LoadCountry(countryCode));
  }
}
