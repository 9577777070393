/**
 * utilitysrv API
 * utilitysrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface NotifyMessageReq { 
    content?: string;
    contentType?: string;
    excerpt?: string;
    finished?: number;
    id?: number;
    imgUrl?: string;
    /**
     * isDeleted
     */
    isDeleted?: boolean;
    messageType?: NotifyMessageReq.MessageTypeEnum;
    name?: string;
    redirectUrl?: string;
    refCode?: string;
    saleChannel?: string;
    /**
     * schedule
     */
    schedule?: Date;
    senderMethod?: NotifyMessageReq.SenderMethodEnum;
    status?: NotifyMessageReq.StatusEnum;
    tag?: string;
    totalSlot?: number;
    userRefCode?: string;
}
export namespace NotifyMessageReq {
    export type MessageTypeEnum = 'MARKETING' | 'PAYMENT_CONFIRM' | 'PAYMENT_REMIND' | 'COMMIT_CONFIRM' | 'REMIND_CHECKIN' | 'CONFIRMED_HOLD' | 'REFERRAL';
    export const MessageTypeEnum = {
        MARKETING: 'MARKETING' as MessageTypeEnum,
        PAYMENTCONFIRM: 'PAYMENT_CONFIRM' as MessageTypeEnum,
        PAYMENTREMIND: 'PAYMENT_REMIND' as MessageTypeEnum,
        COMMITCONFIRM: 'COMMIT_CONFIRM' as MessageTypeEnum,
        REMINDCHECKIN: 'REMIND_CHECKIN' as MessageTypeEnum,
        CONFIRMEDHOLD: 'CONFIRMED_HOLD' as MessageTypeEnum,
        REFERRAL: 'REFERRAL' as MessageTypeEnum
    };
    export type SenderMethodEnum = 'BROADCAST' | 'PRIVATE';
    export const SenderMethodEnum = {
        BROADCAST: 'BROADCAST' as SenderMethodEnum,
        PRIVATE: 'PRIVATE' as SenderMethodEnum
    };
    export type StatusEnum = 'PENDING' | 'PUBLISHING' | 'SUCCESSED';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        PUBLISHING: 'PUBLISHING' as StatusEnum,
        SUCCESSED: 'SUCCESSED' as StatusEnum
    };
}
