import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {SearchBookingTransactionsPartialState} from './search-booking-transactions.reducer';
import {
  LoadSearchBookingTransactions,
  SearchBookingTransactionsActionTypes,
  SearchBookingTransactionsLoadError
} from './search-booking-transactions.actions';
import {BookingTransactionResourceService} from "../../lib/";
import {map} from "rxjs/operators";

@Injectable()
export class SearchBookingTransactionsEffects {
  @Effect() loadSearchBookingTransactions$ = this.dataPersistence.fetch(
    SearchBookingTransactionsActionTypes.LoadSearchBookingTransactions,
    {
      run: (
        action: LoadSearchBookingTransactions,
        state: SearchBookingTransactionsPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.bookingTransactionResourceService.searchObjectsUsingGET2(
          action.payload.productCode? action.payload.productCode: null,
          action.payload.filter && action.payload.filter.status? action.payload.filter.status: null,
          action.payload.filter && action.payload.filter.agencyCode? action.payload.filter.agencyCode: null,
          action.payload.filter && action.payload.filter.bookingNumber? action.payload.filter.bookingNumber: null,
          action.payload.filter && action.payload.filter.providerBrn? action.payload.filter.providerBrn: null,
          action.payload.filter && action.payload.filter.email? action.payload.filter.email: null,
          action.payload.filter && action.payload.filter.customerName? action.payload.filter.customerName: null,
          action.payload.filter && action.payload.filter.supplierCode? action.payload.filter.supplierCode: null,
          action.payload.filter && action.payload.filter.bookingDateFrom? action.payload.filter.bookingDateFrom: null,
          action.payload.filter && action.payload.filter.bookingDateTo? action.payload.filter.bookingDateTo: null,
          action.payload.filter && action.payload.filter.checkInDate? action.payload.filter.checkInDate: null,
          action.payload.filter && action.payload.filter.checkOutDate? action.payload.filter.checkOutDate: null,
          action.payload.filter && action.payload.filter.typeCode? action.payload.filter.typeCode: null,
          action.payload.filter && action.payload.filter.bookingChannel? action.payload.filter.bookingChannel: null,
          action.payload.filter && action.payload.filter.issuedDateFrom? action.payload.filter.issuedDateFrom: null,
          action.payload.filter && action.payload.filter.issuedDateTo? action.payload.filter.issuedDateTo: null,
          action.payload.filter && action.payload.filter.paymentType? action.payload.filter.paymentType: null,
          action.payload.filter && action.payload.filter.action? action.payload.filter.action: null,
          action.payload.filter && action.payload.filter.phoneNumber? action.payload.filter.phoneNumber: null,
          action.payload.filter && action.payload.filter.bookingTagId? action.payload.filter.bookingTagId: null,
          true,
          action.payload.filter && action.payload.filter.paymentStatus? action.payload.filter.paymentStatus: null,
          action.payload.filter && action.payload.filter.hasIncludeBookingContent,
          action.payload.page,
          action.payload.size,
          action.payload.sort,
        ).pipe(
          map(result => ({
            type: SearchBookingTransactionsActionTypes.SearchBookingTransactionsLoaded,
            payload: result,
          }))
        );
      },

      onError: (action: LoadSearchBookingTransactions, error) => {
        console.error('Error', error);
        return new SearchBookingTransactionsLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private bookingTransactionResourceService: BookingTransactionResourceService,
    private dataPersistence: DataPersistence<
      SearchBookingTransactionsPartialState
    >
  ) {}
}
