import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'gtd-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss']
})
export class PageErrorComponent implements OnInit {
  @Input() code: string;
  @Input() message: string;
  constructor() { }

  ngOnInit() {
  }

}
