import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BookingDTO } from '@gtd/api-services/booking-service';
import { environment } from '@env/environment';
import { BasePartnerResourceService } from '@gtd/b2c-client';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { Subscription } from 'rxjs';
import { ApprovalInvoiceResourceService } from '@gtd/api-services/utilitysrv';
import {
  TimeCountDown,
  TimeCountDownBetweenDirective
} from '@gtd/directives/time-count-down-between';
import {DeviceMessageService} from "@gtd/services";

@Component({
  selector: 'gtd-booking-item',
  templateUrl: './booking-item.component.html',
  styleUrls: ['./booking-item.component.scss']
})
export class BookingItemComponent implements OnInit, OnDestroy {
  @Input() booking: BookingDTO;
  @Input() supplierType: string;
  @Input() mode: string;
  @Input() isChecked: boolean;
  @Output() isSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitAdditionalRequestType: EventEmitter<any> = new EventEmitter();

  appName = localStorage.getItem('appName');
  bookingDTO = BookingDTO;
  isLoading: boolean;
  subscriptions = new Subscription();
  isShowDownloadInvoiceButton: boolean = false;
  isDisableDownloadInvoiceButton: boolean = false;
  timeCountDown: TimeCountDown;
  additionalRequestActions = [];
  isShowAdditionalRequestButton: boolean = false;
  cooperationClass = localStorage.getItem('cooperationClass');

  constructor(
    private basePartnerResourceService: BasePartnerResourceService,
    public dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar,
    private approvalInvoiceResourceService: ApprovalInvoiceResourceService,
    private timeCountDownBetweenDirective: TimeCountDownBetweenDirective,
    private deviceMessageService: DeviceMessageService,
  ) {}

  getBookingStatus(booking: BookingDTO) {
    return booking.status !== BookingDTO.StatusEnum.BOOKED
      ? booking.status
      : booking.issuedStatus == BookingDTO.IssuedStatusEnum.TICKETONPROCESS
      ? booking.issuedStatus
      : booking.issuedStatus == BookingDTO.IssuedStatusEnum.PENDING &&
        booking.paymentStatus == BookingDTO.PaymentStatusEnum.SUCCEEDED
      ? 'payment-success-commit-fail'
      : booking.issuedStatus == BookingDTO.IssuedStatusEnum.PENDING &&
        booking.paymentStatus == BookingDTO.PaymentStatusEnum.FAILED
      ? 'payment-fail'
      : booking.status;
  }

  ngOnInit() {
    if (this.booking.timeToLive) {
      this.timeCountDown = this.timeCountDownBetweenDirective.timeBetweenDates(
        this.booking.timeToLive
      );
    }
    if (this.booking.approvalInvoiceId) {
      this.approvalInvoiceResourceService
        .getApprovalInvoiceUsingGET(this.booking.approvalInvoiceId)
        .subscribe(res => {
          if (res && res.id) {
            if (res.approvedStatus === 'SUCCESS') {
              this.isShowDownloadInvoiceButton = true;
              this.isDisableDownloadInvoiceButton = false;
            }
          }
        });
    }
    if (this.booking.supplierType === 'AIR') {
      this.additionalRequestActions = [
        {
          code: 'AIR_ADD_PACKAGE',
          name: 'booking.buy-signed-luggage-for-ticket'
        },
        {
          code: 'AIR_ADD_INFORMATION',
          name: 'booking.change-information-on-ticket'
        },
        {
          code: 'AIR_DELETE_BOOKING',
          name: 'booking.refund-cancellation-ticket'
        },
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    } else if (this.booking.supplierType === 'HOTEL') {
      this.additionalRequestActions = [
        {
          code: 'HOTEL_ADD_PERSON',
          name: 'booking.more-people'
        },
        {
          code: 'HOTEL_EDIT_BOOKING',
          name: 'booking.change-room-information'
        },
        {
          code: 'HOTEL_CHANGE_ROOM',
          name: 'booking.change-room'
        },
        {
          code: 'HOTEL_DELETE_BOOKING',
          name: 'booking.refund-cancellation-room'
        },
        {
          code: 'ALL_OTHER',
          name: 'booking.other-requirement'
        }
      ];
    }
    if (this.booking.departureDate) {
      if (
        new Date().getTime() < new Date(this.booking.departureDate).getTime() &&
        this.appName !== 'B2B2C'
      ) {
        this.isShowAdditionalRequestButton = true;
      }
    }
  }

  redirectPayment(event: Event, bookingNumber: string) {
    event.stopPropagation();
    if (environment.appName === 'B2B2C') {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'booking-result.insurance.processing', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
      this.isLoading = true;
      this.basePartnerResourceService
        .placeOrderUsingGET(bookingNumber)
        .subscribe(placeOrder => {
          if (placeOrder && placeOrder.isSuccess) {
            if(placeOrder.result == "SHNHN") {
              let data = {
                "type" : "open_transfer",
                "params" : {
                  "booking_number" : bookingNumber,
                }
              }
              let message = JSON.stringify(data);
              this.deviceMessageService.sendMessageDevice(message);
            } else {
              window.top.location.href = decodeURIComponent(placeOrder.result);
            }
          } else {
            this.dialogMessageExpire();
          }
          this.isLoading = false;
          this._snackBar.dismiss();
        });
    } else {
      this.router.navigate(['/booking/payment'], {
        queryParams: {
          bookingNumber: bookingNumber
        }
      });
    }
  }

  dialogMessageExpire() {
    this.dialog.open(DialogMessageComponent, {
      data: {
        btn: [{ btnText: 'global.close' }],
        content: 'user.system-error-message',
        heading: 'user.system-error',
        icon: 'exclamation-triangle',
        messageType: 'error'
      },
      panelClass: 'dialog-message',
      disableClose: true,
      position: {
        top: '100px'
      }
    });
  }

  downloadInvoice(event) {
    event.stopPropagation();
    this.subscriptions.add(
      this.approvalInvoiceResourceService
        .getPdfUrlEinvoiceUsingGET(this.booking.approvalInvoiceId)
        .subscribe(res => {
          window.open(res.pdfUrl, '_blank');
        })
    );
  }

  checkboxChange(event) {
    this.isSelected.emit(event.checked);
  }

  onEmitAdditionalRequestType(code: string) {
    this.emitAdditionalRequestType.emit({
      bookingNumber: this.booking.bookingNumber,
      type: code
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
