import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { ReportReferralPartialState } from './report-referral.reducer';
import {
  LoadReportReferral,
  ReportReferralLoadError,
  ReportReferralActionTypes
} from './report-referral.actions';
import {map} from "rxjs/operators";
import {ReferralResourceService} from "../../lib";

@Injectable()
export class ReportReferralEffects {
  @Effect() loadReportReferral$ = this.dataPersistence.fetch(
    ReportReferralActionTypes.LoadReportReferral,
    {
      run: (action: LoadReportReferral, state: ReportReferralPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.referralResourceService.reportReferralUsingGET().pipe(
          map(result => ({
            type: ReportReferralActionTypes.ReportReferralLoaded,
            payload: result,
          }))
        );
      },

      onError: (action: LoadReportReferral, error) => {
        console.error('Error', error);
        return new ReportReferralLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private referralResourceService: ReferralResourceService,
    private dataPersistence: DataPersistence<ReportReferralPartialState>
  ) {}
}
