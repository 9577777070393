/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ChangeTripStatusTO { 
    status: ChangeTripStatusTO.StatusEnum;
    tripID: number;
    updatedBy: string;
}
export namespace ChangeTripStatusTO {
    export type StatusEnum = 'DRAFT' | 'PENDING' | 'APPROVED' | 'DISAPPROVED';
    export const StatusEnum = {
        DRAFT: 'DRAFT' as StatusEnum,
        PENDING: 'PENDING' as StatusEnum,
        APPROVED: 'APPROVED' as StatusEnum,
        DISAPPROVED: 'DISAPPROVED' as StatusEnum
    };
}
