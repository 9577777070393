import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { DatepickerPartialState } from './datepicker.reducer';
import {
  LoadDatepicker,
  DatepickerLoaded,
  DatepickerLoadError,
  DatepickerActionTypes
} from './datepicker.actions';

@Injectable()
export class DatepickerEffects {
  @Effect() loadDatepicker$ = this.dataPersistence.fetch(
    DatepickerActionTypes.LoadDatepicker,
    {
      run: (action: LoadDatepicker, state: DatepickerPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return new DatepickerLoaded(action.payload);
      },

      onError: (action: LoadDatepicker, error) => {
        console.error('Error', error);
        return new DatepickerLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<DatepickerPartialState>
  ) {}
}
