import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FilterAvailableFacade} from "../../../../state/filter-available/filter-available.facade";
import {FilterAvailableState} from "../../../../state/filter-available/filter-available.reducer";
import {Subscription} from "rxjs";
import {SearchBestRatesFacade} from "../../../../state/search-best-rates/search-best-rates.facade";

@Component({
  selector: 'gtd-pagination-hotel-map',
  templateUrl: './pagination-hotel-map.component.html',
  styleUrls: ['./pagination-hotel-map.component.scss']
})
export class PaginationHotelMapComponent implements OnInit, OnDestroy {
  @Input() currentFilter: any;
  @Input() isLoading: boolean;
  @Input() totalPage: number;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  private subscriptions: Subscription = new Subscription();
  allSearchBestRates$ = this.searchBestRatesFacade.allSearchBestRates$;

  constructor(
    private filterAvailableFacade: FilterAvailableFacade,
    private searchBestRatesFacade: SearchBestRatesFacade,
  ) { }

  ngOnInit() {
    this.subscriptions.add(
      this.filterAvailableFacade.currentFilter$.subscribe((currentFilter: FilterAvailableState) => {
        if(currentFilter) {
          this.isLoading = true;
        }
      })
    )
    this.subscriptions.add(
      this.allSearchBestRates$.subscribe(allSearchBestRates => {
        if(allSearchBestRates) {
          this.isLoading = false;
          this.changed.emit(true)
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  seeMoreItem() {
    this.isLoading = true;
    setTimeout(() => {
      this.currentFilter.pageNumber++;
      this.filterAvailableFacade.changePageNumber(this.currentFilter.pageNumber);
    }, 1000)
  }

  seeToPageNumberItem(pageNumber) {
    this.isLoading = true;
    setTimeout(() => {
      this.currentFilter.pageNumber = pageNumber;
      this.filterAvailableFacade.changePageNumber(pageNumber);
    }, 1000)
  }

}
