/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Info } from './info';


export interface OTPServiceReq { 
    duration?: number;
    errors?: Array<Error>;
    infos?: Array<Info>;
    isSuccess?: boolean;
    lang?: OTPServiceReq.LangEnum;
    lifeTime?: number;
    otpCode?: string;
    otpType?: OTPServiceReq.OtpTypeEnum;
    phoneNumber?: string;
    serviceID?: string;
    slot?: number;
    success?: boolean;
    tag?: string;
    textMessage?: string;
    verificationCode?: string;
}
export namespace OTPServiceReq {
    export type LangEnum = 'EN' | 'VI';
    export const LangEnum = {
        EN: 'EN' as LangEnum,
        VI: 'VI' as LangEnum
    };
    export type OtpTypeEnum = 'BOOKING_FLIGHT' | 'BOOKING_HOTEL' | 'USER_ACTIVATION';
    export const OtpTypeEnum = {
        BOOKINGFLIGHT: 'BOOKING_FLIGHT' as OtpTypeEnum,
        BOOKINGHOTEL: 'BOOKING_HOTEL' as OtpTypeEnum,
        USERACTIVATION: 'USER_ACTIVATION' as OtpTypeEnum
    };
}
