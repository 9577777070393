import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AVATAR_FEATURE_KEY, AvatarState } from './avatar.reducer';

// Lookup the 'Avatar' feature state managed by NgRx
const getAvatarState = createFeatureSelector<AvatarState>(AVATAR_FEATURE_KEY);

const getLoaded = createSelector(
  getAvatarState,
  (state: AvatarState) => state.loaded
);
const getError = createSelector(
  getAvatarState,
  (state: AvatarState) => state.error
);

const getAllAvatar = createSelector(
  getAvatarState,
  getLoaded,
  (state: AvatarState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getAvatar = createSelector(
  getAvatarState,
  getLoaded,
  (state: AvatarState, isLoaded) => {
    return isLoaded ? state.result : null;
  }
);
const getSelectedId = createSelector(
  getAvatarState,
  (state: AvatarState) => state.selectedId
);
const getSelectedAvatar = createSelector(
  getAllAvatar,
  getSelectedId,
  (avatar, id) => {
    return avatar ? Object.assign({}, avatar) : undefined;
  }
);

export const avatarQuery = {
  getLoaded,
  getError,
  getAllAvatar,
  getAvatar,
  getSelectedAvatar
};
