import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'gtd-tcb-policy',
  templateUrl: './tcb-policy.component.html',
  styleUrls: ['./tcb-policy.component.scss']
})
export class TcbPolicyComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TcbPolicyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close();
  }
}
