import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@env/environment';

declare const gtag: any;
export enum SupplierFirstCharacter {
  'AIR' = 'F',
  'HOTEL' = 'H',
  'COMBO' = 'C',
  'TOURS' = 'T'
}
@Injectable({
  providedIn: 'root'
})
export class GaTrackingService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  track(eventName: string, properties?: any): void {
    if (
      !isPlatformBrowser(this.platformId) ||
      localStorage.getItem('appName') !== 'B2C'
    ) {
      return;
    }
    if (properties) {
      gtag('event', eventName, properties);
    } else {
      gtag('event', eventName);
    }
  }
}
