import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { PartnerSectionComponent } from './partner-section/partner-section.component';
import { PromotionSectionComponent } from './promotion-section/promotion-section.component';
import { AppDownloadSectionComponent } from './app-download-section/app-download-section.component';
import { SubscribeInputComponent } from './app-download-section/subscribe-input/subscribe-input.component';
import { SearchSectionComponent } from './search-section/search-section.component';
import { PoliciesComponent } from './policies/policies.component';
import { PopularDestinationComponent } from './popular-destination/popular-destination.component';
import { RouterModule, UrlSegment } from '@angular/router';
import { MemberSectionModule } from '@gtd/components/member-section';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import {
  MatButtonModule,
  MatIconModule,
  MatTabsModule
} from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationTriangle,
  faHotel,
  faPlane
} from '@fortawesome/free-solid-svg-icons';
import { FlightFormSearchModule, NewFlightFormSearchModule } from '@gtd/flight';
import { ComboFormSearchModule, NewComboFormSearchModule } from '@gtd/combovj';
import { NewHotelFormSearchModule } from '@gtd/hotel';
import { CovidDialogModule } from '@gtd/components/covid-dialog';
import { PromotionSectionStateModule } from './promotion-section/promotion-section-state/promotion-section-state.module';
import { IncreamentInputModule } from '@gtd/components/increament-input';
import { AffiliatesModule } from './affilates/affiliates.module';
import { FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { map } from 'rxjs/operators';
import { SliderComponent } from './slider/slider.component';
import { BestPriceProductsComponent } from './best-price-products/best-price-products.component';
import { FavoriteRecommentComponent } from './favorite-recomment/favorite-recomment.component';
import { HotPromotionComponent } from './hot-promotion/hot-promotion.component';
import { RelaxNewsComponent } from './relax-news/relax-news.component';
import { ExtraSectionComponent } from './extra-section/extra-section.component';
import { HttpClientModule } from '@angular/common/http';
import { BannerTypeService } from '@gtd/api-services/newface-banner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TourSearchModule } from '@gtd/extra/tour/ui/tour-search';
import { TourStoreModule } from '@gtd/extra/tour/data-access';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromBanner from './store/banner/banner.reducer';
import { BannerEffects } from './store/banner/banner.effects';
import { BannerFacade } from './store/banner/banner.facade';
import { SearchFormModule } from '@gtd/hotels/feature/search-form';
library.add(faHotel, faPlane, faExclamationTriangle);

export function firstMatcher(url: UrlSegment[]): any {
  if (
    (url[0].path === 've-may-bay' ||
      url[0].path === 'khach-san' ||
      url[0].path === 'combo-du-lich' ||
      url[0].path === 'vui-choi-giai-tri') &&
    !url[1]
  ) {
    return {
      consumed: url,
      posParams: {
        slug: new UrlSegment(url[0].path.substr(0), {})
      }
    };
  } else {
    return null;
  }
}
@NgModule({
  declarations: [
    HomeComponent,
    PartnerSectionComponent,
    PromotionSectionComponent,
    AppDownloadSectionComponent,
    SubscribeInputComponent,
    SearchSectionComponent,
    PoliciesComponent,
    PopularDestinationComponent,
    SliderComponent,
    BestPriceProductsComponent,
    FavoriteRecommentComponent,
    HotPromotionComponent,
    RelaxNewsComponent,
    ExtraSectionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: HomeComponent
      },
      {
        matcher: firstMatcher,
        component: HomeComponent
      }
    ]),
    MemberSectionModule,
    TranslateModule,
    SwiperModule,
    MatIconModule,
    FontAwesomeModule,
    MatTabsModule,
    NewFlightFormSearchModule,
    FlightFormSearchModule,
    NewComboFormSearchModule,
    PromotionSectionStateModule,
    IncreamentInputModule,
    AffiliatesModule,
    CovidDialogModule,
    NewHotelFormSearchModule,
    SwiperModule,
    MatButtonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TourSearchModule,
    TourStoreModule,
    SearchFormModule,
    StoreModule.forFeature(fromBanner.BANNER_FEATURE_KEY, fromBanner.reducer),
    EffectsModule.forFeature([BannerEffects])
  ],

  providers: [BannerTypeService, BannerFacade]
})
export class HomeModule {}
