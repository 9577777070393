/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { BookingTravellerRQ } from '../model/bookingTravellerRQ';
import { CheckoutPayload } from '../model/checkoutPayload';
import { CreateDraftBookingHotelPayload } from '../model/createDraftBookingHotelPayload';
import { OtaResultOfBooking } from '../model/otaResultOfBooking';
import { OtaResultOfCheckoutResult } from '../model/otaResultOfCheckoutResult';
import { OtaResultOfFilterOptionsResult } from '../model/otaResultOfFilterOptionsResult';
import { OtaResultOfGetPropertyIntroResult } from '../model/otaResultOfGetPropertyIntroResult';
import { OtaResultOfSearchAllRatesResult } from '../model/otaResultOfSearchAllRatesResult';
import { OtaResultOfSearchBestRatesResult } from '../model/otaResultOfSearchBestRatesResult';
import { OtaResultOfSearchKeyWordResult } from '../model/otaResultOfSearchKeyWordResult';
import { OtaResultOfUpdateBookingStatusRS } from '../model/otaResultOfUpdateBookingStatusRS';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import {OtaResultOfCurrencyExchangeResult} from "../model/otaResultOfCurrencyExchangeResult";

@Injectable()
export class HotelResourceV3Service {
  protected basePath = 'https://10.7.71.20:8080';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * addBookingTraveller
   *
   * @param bookingTravellerRQ bookingTravellerRQ
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addBookingTravellerUsingPOST2(
    bookingTravellerRQ: BookingTravellerRQ,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfUpdateBookingStatusRS>;
  public addBookingTravellerUsingPOST2(
    bookingTravellerRQ: BookingTravellerRQ,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfUpdateBookingStatusRS>>;
  public addBookingTravellerUsingPOST2(
    bookingTravellerRQ: BookingTravellerRQ,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfUpdateBookingStatusRS>>;
  public addBookingTravellerUsingPOST2(
    bookingTravellerRQ: BookingTravellerRQ,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (bookingTravellerRQ === null || bookingTravellerRQ === undefined) {
      throw new Error(
        'Required parameter bookingTravellerRQ was null or undefined when calling addBookingTravellerUsingPOST2.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<OtaResultOfUpdateBookingStatusRS>(
      `${this.basePath}/api/v3/hotel/add-booking-traveller`,
      bookingTravellerRQ,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * checkout
   *
   * @param checkoutPayload checkoutPayload
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkoutUsingPOST(
    checkoutPayload: CheckoutPayload,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfCheckoutResult>;
  public checkoutUsingPOST(
    checkoutPayload: CheckoutPayload,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfCheckoutResult>>;
  public checkoutUsingPOST(
    checkoutPayload: CheckoutPayload,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfCheckoutResult>>;
  public checkoutUsingPOST(
    checkoutPayload: CheckoutPayload,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (checkoutPayload === null || checkoutPayload === undefined) {
      throw new Error(
        'Required parameter checkoutPayload was null or undefined when calling checkoutUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<OtaResultOfCheckoutResult>(
      `${this.basePath}/api/v3/hotel/checkout`,
      checkoutPayload,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * createDraftBooking
   *
   * @param createDraftBookingHotelPayload createDraftBookingHotelPayload
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createDraftBookingUsingPOST2(
    createDraftBookingHotelPayload: CreateDraftBookingHotelPayload,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfBooking>;
  public createDraftBookingUsingPOST2(
    createDraftBookingHotelPayload: CreateDraftBookingHotelPayload,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfBooking>>;
  public createDraftBookingUsingPOST2(
    createDraftBookingHotelPayload: CreateDraftBookingHotelPayload,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfBooking>>;
  public createDraftBookingUsingPOST2(
    createDraftBookingHotelPayload: CreateDraftBookingHotelPayload,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (
      createDraftBookingHotelPayload === null ||
      createDraftBookingHotelPayload === undefined
    ) {
      throw new Error(
        'Required parameter createDraftBookingHotelPayload was null or undefined when calling createDraftBookingUsingPOST2.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<OtaResultOfBooking>(
      `${this.basePath}/api/v3/hotel/create-draft-booking`,
      createDraftBookingHotelPayload,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getCurrencyExchange
   *
   * @param currency currency
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCurrencyExchangeUsingGET(currency: 'VND' | 'USD', observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfCurrencyExchangeResult>;
  public getCurrencyExchangeUsingGET(currency: 'VND' | 'USD', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfCurrencyExchangeResult>>;
  public getCurrencyExchangeUsingGET(currency: 'VND' | 'USD', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfCurrencyExchangeResult>>;
  public getCurrencyExchangeUsingGET(currency: 'VND' | 'USD', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (currency === null || currency === undefined) {
      throw new Error('Required parameter currency was null or undefined when calling getCurrencyExchangeUsingGET.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (currency !== undefined && currency !== null) {
      queryParameters = queryParameters.set('currency', <any>currency);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;charset=UTF-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.get<OtaResultOfCurrencyExchangeResult>(`${this.basePath}/api/v3/hotel/currency-exchange`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getFilterOptions
   *
   * @param language language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFilterOptionsUsingGET(
    language: 'vi' | 'en',
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfFilterOptionsResult>;
  public getFilterOptionsUsingGET(
    language: 'vi' | 'en',
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfFilterOptionsResult>>;
  public getFilterOptionsUsingGET(
    language: 'vi' | 'en',
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfFilterOptionsResult>>;
  public getFilterOptionsUsingGET(
    language: 'vi' | 'en',
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling getFilterOptionsUsingGET.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfFilterOptionsResult>(
      `${this.basePath}/api/v3/hotel/filter-options`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * recommendByAirport
   *
   * @param destinationAirportCode 3-character IATA airport code of the destination airport. Ex: SGN
   * @param countryCode Ex: VN
   * @param language language
   * @param currency currency
   * @param checkIn yyyy-mm-dd ex: 2020-05-12
   * @param checkOut yyyy-mm-dd ex: 2020-05-13
   * @param paxInfos adultQuantity-first child age,second child age, ...  ex: 2-5,6
   * @param searchId searchId
   * @param originAirportCode 3-character IATA airport code of the origin airport. Ex: HAN
   * @param cabinClass cabinClass
   * @param airlineCode 2-character IATA airline code. Ex: Q3
   * @param arrival Arrival date and time, in extended ISO 8601 format. Ex: 2018-02-17T01:15:00.000Z
   * @param transportationGrossCost The currency and gross cost of the flight booking. Ex: USD-1063.62
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public recommendByAirportUsingGET(
    destinationAirportCode: string,
    countryCode: string,
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    originAirportCode?: string,
    cabinClass?: 'economy' | 'premium_economy' | 'business' | 'first',
    airlineCode?: string,
    arrival?: string,
    transportationGrossCost?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfSearchBestRatesResult>;
  public recommendByAirportUsingGET(
    destinationAirportCode: string,
    countryCode: string,
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    originAirportCode?: string,
    cabinClass?: 'economy' | 'premium_economy' | 'business' | 'first',
    airlineCode?: string,
    arrival?: string,
    transportationGrossCost?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfSearchBestRatesResult>>;
  public recommendByAirportUsingGET(
    destinationAirportCode: string,
    countryCode: string,
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    originAirportCode?: string,
    cabinClass?: 'economy' | 'premium_economy' | 'business' | 'first',
    airlineCode?: string,
    arrival?: string,
    transportationGrossCost?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfSearchBestRatesResult>>;
  public recommendByAirportUsingGET(
    destinationAirportCode: string,
    countryCode: string,
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    originAirportCode?: string,
    cabinClass?: 'economy' | 'premium_economy' | 'business' | 'first',
    airlineCode?: string,
    arrival?: string,
    transportationGrossCost?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (
      destinationAirportCode === null ||
      destinationAirportCode === undefined
    ) {
      throw new Error(
        'Required parameter destinationAirportCode was null or undefined when calling recommendByAirportUsingGET.'
      );
    }

    if (countryCode === null || countryCode === undefined) {
      throw new Error(
        'Required parameter countryCode was null or undefined when calling recommendByAirportUsingGET.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling recommendByAirportUsingGET.'
      );
    }

    if (currency === null || currency === undefined) {
      throw new Error(
        'Required parameter currency was null or undefined when calling recommendByAirportUsingGET.'
      );
    }

    if (checkIn === null || checkIn === undefined) {
      throw new Error(
        'Required parameter checkIn was null or undefined when calling recommendByAirportUsingGET.'
      );
    }

    if (checkOut === null || checkOut === undefined) {
      throw new Error(
        'Required parameter checkOut was null or undefined when calling recommendByAirportUsingGET.'
      );
    }

    if (paxInfos === null || paxInfos === undefined) {
      throw new Error(
        'Required parameter paxInfos was null or undefined when calling recommendByAirportUsingGET.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (searchId !== undefined && searchId !== null) {
      queryParameters = queryParameters.set('searchId', <any>searchId);
    }
    if (
      destinationAirportCode !== undefined &&
      destinationAirportCode !== null
    ) {
      queryParameters = queryParameters.set('destinationAirportCode', <any>(
        destinationAirportCode
      ));
    }
    if (originAirportCode !== undefined && originAirportCode !== null) {
      queryParameters = queryParameters.set('originAirportCode', <any>(
        originAirportCode
      ));
    }
    if (cabinClass !== undefined && cabinClass !== null) {
      queryParameters = queryParameters.set('cabinClass', <any>cabinClass);
    }
    if (airlineCode !== undefined && airlineCode !== null) {
      queryParameters = queryParameters.set('airlineCode', <any>airlineCode);
    }
    if (arrival !== undefined && arrival !== null) {
      queryParameters = queryParameters.set('arrival', <any>arrival);
    }
    if (
      transportationGrossCost !== undefined &&
      transportationGrossCost !== null
    ) {
      queryParameters = queryParameters.set('transportationGrossCost', <any>(
        transportationGrossCost
      ));
    }
    if (countryCode !== undefined && countryCode !== null) {
      queryParameters = queryParameters.set('countryCode', <any>countryCode);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }
    if (currency !== undefined && currency !== null) {
      queryParameters = queryParameters.set('currency', <any>currency);
    }
    if (checkIn !== undefined && checkIn !== null) {
      queryParameters = queryParameters.set('checkIn', <any>checkIn);
    }
    if (checkOut !== undefined && checkOut !== null) {
      queryParameters = queryParameters.set('checkOut', <any>checkOut);
    }
    if (paxInfos) {
      paxInfos.forEach(element => {
        queryParameters = queryParameters.append('paxInfos', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfSearchBestRatesResult>(
      `${this.basePath}/api/v3/hotel/recommend-by-airport`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * recommendByProperty
   *
   * @param propertyId propertyId
   * @param supplier supplier
   * @param language language
   * @param currency currency
   * @param checkIn yyyy-mm-dd ex: 2020-05-12
   * @param checkOut yyyy-mm-dd ex: 2020-05-13
   * @param paxInfos adultQuantity-first child age,second child age, ...  ex: 2-5,6
   * @param searchId searchId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public recommendByPropertyUsingGET(
    propertyId: string,
    supplier: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfSearchBestRatesResult>;
  public recommendByPropertyUsingGET(
    propertyId: string,
    supplier: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfSearchBestRatesResult>>;
  public recommendByPropertyUsingGET(
    propertyId: string,
    supplier: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfSearchBestRatesResult>>;
  public recommendByPropertyUsingGET(
    propertyId: string,
    supplier: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (propertyId === null || propertyId === undefined) {
      throw new Error(
        'Required parameter propertyId was null or undefined when calling recommendByPropertyUsingGET.'
      );
    }

    if (supplier === null || supplier === undefined) {
      throw new Error(
        'Required parameter supplier was null or undefined when calling recommendByPropertyUsingGET.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling recommendByPropertyUsingGET.'
      );
    }

    if (currency === null || currency === undefined) {
      throw new Error(
        'Required parameter currency was null or undefined when calling recommendByPropertyUsingGET.'
      );
    }

    if (checkIn === null || checkIn === undefined) {
      throw new Error(
        'Required parameter checkIn was null or undefined when calling recommendByPropertyUsingGET.'
      );
    }

    if (checkOut === null || checkOut === undefined) {
      throw new Error(
        'Required parameter checkOut was null or undefined when calling recommendByPropertyUsingGET.'
      );
    }

    if (paxInfos === null || paxInfos === undefined) {
      throw new Error(
        'Required parameter paxInfos was null or undefined when calling recommendByPropertyUsingGET.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (searchId !== undefined && searchId !== null) {
      queryParameters = queryParameters.set('searchId', <any>searchId);
    }
    if (propertyId !== undefined && propertyId !== null) {
      queryParameters = queryParameters.set('propertyId', <any>propertyId);
    }
    if (supplier !== undefined && supplier !== null) {
      queryParameters = queryParameters.set('supplier', <any>supplier);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }
    if (currency !== undefined && currency !== null) {
      queryParameters = queryParameters.set('currency', <any>currency);
    }
    if (checkIn !== undefined && checkIn !== null) {
      queryParameters = queryParameters.set('checkIn', <any>checkIn);
    }
    if (checkOut !== undefined && checkOut !== null) {
      queryParameters = queryParameters.set('checkOut', <any>checkOut);
    }
    if (paxInfos) {
      paxInfos.forEach(element => {
        queryParameters = queryParameters.append('paxInfos', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfSearchBestRatesResult>(
      `${this.basePath}/api/v3/hotel/recommend-by-property`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * searchAllRates
   *
   * @param propertyId propertyId
   * @param supplier supplier
   * @param shortLinkId shortLinkId
   * @param masterPropertyId masterPropertyId
   * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchAllRatesUsingShortLinkGET(
    propertyId: string,
    supplier: string,
    shortLinkId: string,
    masterPropertyId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfSearchAllRatesResult>;
  public searchAllRatesUsingShortLinkGET(
    propertyId: string,
    supplier: string,
    shortLinkId: string,
    masterPropertyId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfSearchAllRatesResult>>;
  public searchAllRatesUsingShortLinkGET(
    propertyId: string,
    supplier: string,
    shortLinkId: string,
    masterPropertyId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfSearchAllRatesResult>>;
  public searchAllRatesUsingShortLinkGET(
    propertyId: string,
    supplier: string,
    shortLinkId: string,
    masterPropertyId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (shortLinkId === null || shortLinkId === undefined) {
      throw new Error(
        'Required parameter searchId was null or undefined when calling searchAllRatesUsingGET.'
      );
    }

    if (propertyId === null || propertyId === undefined) {
      throw new Error(
        'Required parameter propertyId was null or undefined when calling searchAllRatesUsingGET.'
      );
    }

    if (supplier === null || supplier === undefined) {
      throw new Error(
        'Required parameter supplier was null or undefined when calling searchAllRatesUsingGET.'
      );
    }

    if (masterPropertyId === null || masterPropertyId === undefined) {
      throw new Error(
        'Required parameter checkOut was null or undefined when calling searchAllRatesUsingGET.'
      );
    }


    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (shortLinkId !== undefined && shortLinkId !== null) {
      queryParameters = queryParameters.set('shortLinkId', <any>shortLinkId);
    }
    if (propertyId !== undefined && propertyId !== null) {
      queryParameters = queryParameters.set('propertyId', <any>propertyId);
    }
    if (supplier !== undefined && supplier !== null) {
      queryParameters = queryParameters.set('supplier', <any>supplier);
    }
    if (masterPropertyId !== undefined && masterPropertyId !== null) {
      queryParameters = queryParameters.set('masterPropertyId', <any>masterPropertyId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfSearchAllRatesResult>(
      `${this.basePath}/api/v3/hotel/search-all-rates`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * searchAllRates
   *
   * @param searchId searchId
   * @param propertyId propertyId
   * @param supplier supplier
   * @param checkIn yyyy-mm-dd ex: 2020-05-12
   * @param checkOut yyyy-mm-dd ex: 2020-05-13
   * @param paxInfos adultQuantity-first child age,second child age, ...  ex: 2-5,6
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchAllRatesUsingGET(
    searchId: string,
    propertyId: string,
    supplier: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfSearchAllRatesResult>;
  public searchAllRatesUsingGET(
    searchId: string,
    propertyId: string,
    supplier: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfSearchAllRatesResult>>;
  public searchAllRatesUsingGET(
    searchId: string,
    propertyId: string,
    supplier: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfSearchAllRatesResult>>;
  public searchAllRatesUsingGET(
    searchId: string,
    propertyId: string,
    supplier: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (searchId === null || searchId === undefined) {
      throw new Error(
        'Required parameter searchId was null or undefined when calling searchAllRatesUsingGET.'
      );
    }

    if (propertyId === null || propertyId === undefined) {
      throw new Error(
        'Required parameter propertyId was null or undefined when calling searchAllRatesUsingGET.'
      );
    }

    if (supplier === null || supplier === undefined) {
      throw new Error(
        'Required parameter supplier was null or undefined when calling searchAllRatesUsingGET.'
      );
    }

    if (checkIn === null || checkIn === undefined) {
      throw new Error(
        'Required parameter checkIn was null or undefined when calling searchAllRatesUsingGET.'
      );
    }

    if (checkOut === null || checkOut === undefined) {
      throw new Error(
        'Required parameter checkOut was null or undefined when calling searchAllRatesUsingGET.'
      );
    }

    if (paxInfos === null || paxInfos === undefined) {
      throw new Error(
        'Required parameter paxInfos was null or undefined when calling searchAllRatesUsingGET.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (searchId !== undefined && searchId !== null) {
      queryParameters = queryParameters.set('searchId', <any>searchId);
    }
    if (propertyId !== undefined && propertyId !== null) {
      queryParameters = queryParameters.set('propertyId', <any>propertyId);
    }
    if (supplier !== undefined && supplier !== null) {
      queryParameters = queryParameters.set('supplier', <any>supplier);
    }
    if (checkIn !== undefined && checkIn !== null) {
      queryParameters = queryParameters.set('checkIn', <any>checkIn);
    }
    if (checkOut !== undefined && checkOut !== null) {
      queryParameters = queryParameters.set('checkOut', <any>checkOut);
    }
    if (paxInfos) {
      paxInfos.forEach(element => {
        queryParameters = queryParameters.append('paxInfos', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfSearchAllRatesResult>(
      `${this.basePath}/api/v3/hotel/search-all-rates`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * searchBestRates
   *
   * @param searchCode searchCode
   * @param searchType searchType
   * @param language language
   * @param currency currency
   * @param checkIn yyyy-mm-dd ex: 2020-05-12
   * @param checkOut yyyy-mm-dd ex: 2020-05-13
   * @param paxInfos adultQuantity-first child age,second child age, ...  ex: 2-5,6
   * @param searchId searchId
   * @param supplier Required for searchType HOTEL
   * @param filterHotelName Haven&#39;t been used yet
   * @param filterHotelCategories filterHotelCategories
   * @param filterBreakfastIncluded filterBreakfastIncluded
   * @param filterFromPrice filterFromPrice
   * @param filterToPrice filterToPrice
   * @param filterFromDistance
   * @param filterToDistance
   * @param filterStarRating filterStarRating
   * @param targetSupplier targetSupplier
   * @param filterFromGuestRating filterFromGuestRating
   * @param filterToGuestRating filterToGuestRating
   * @param filterAmenities filterAmenities
   * @param filterRoomAmenities filterRoomAmenities
   * @param filterRateAmenities
   * @param filterRoomViews filterRoomViews
   * @param filterThemes filterThemes
   * @param filterMealPlans filterMealPlans
   * @param filterBedTypes
   * @param filterGeoDistanceLat filterGeoDistanceLat
   * @param filterGeoDistanceLon filterGeoDistanceLon
   * @param filterGeoDistanceMeters filterGeoDistanceMeters
   * @param sortField Default sort order, price ascending
   * @param sortOrder sortOrder
   * @param pageNumber pageNumber
   * @param pageSize Maximum value 250
   * @param locationName
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchBestRatesUsingGET(
    searchCode: string,
    searchType:
      | 'AUTO'
      | 'CONTINENT'
      | 'COUNTRY'
      | 'PROVINCE_STATE'
      | 'HIGH_LEVEL_REGION'
      | 'MULTI_CITY_VICINITY'
      | 'CITY'
      | 'NEIGHBORHOOD'
      | 'AIRPORT'
      | 'POINT_OF_INTEREST'
      | 'TRAIN_STATION'
      | 'METRO_STATION'
      | 'HOTEL',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    supplier?: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    targetSupplier?:
      | 'EXPEDIA'
      | 'AXISROOM'
      | 'BEDLINKER'
      | 'AGODA'
      | 'VINPEARL',
    filterHotelName?: string,
    filterHotelCategories?: Array<string>,
    filterBreakfastIncluded?: boolean,
    filterFromPrice?: number,
    filterToPrice?: number,
    filterFromDistance?: number,
    filterToDistance?: number,
    filterStarRating?: Array<string>,
    filterFromGuestRating?: number,
    filterToGuestRating?: number,
    filterAmenities?: Array<string>,
    filterRoomAmenities?: Array<string>,
    filterRateAmenities?: Array<string>,
    filterRoomViews?: Array<string>,
    filterThemes?: Array<string>,
    filterMealPlans?: Array<string>,
    filterBedTypes?: Array<string>,
    filterGeoDistanceLat?: number,
    filterGeoDistanceLon?: number,
    filterGeoDistanceMeters?: number,
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder?: 'ASC' | 'DESC',
    pageNumber?: number,
    pageSize?: number,
    locationName?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfSearchBestRatesResult>;
  public searchBestRatesUsingGET(
    searchCode: string,
    searchType:
      | 'AUTO'
      | 'CONTINENT'
      | 'COUNTRY'
      | 'PROVINCE_STATE'
      | 'HIGH_LEVEL_REGION'
      | 'MULTI_CITY_VICINITY'
      | 'CITY'
      | 'NEIGHBORHOOD'
      | 'AIRPORT'
      | 'POINT_OF_INTEREST'
      | 'TRAIN_STATION'
      | 'METRO_STATION'
      | 'HOTEL',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    supplier?: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    targetSupplier?:
      | 'EXPEDIA'
      | 'AXISROOM'
      | 'BEDLINKER'
      | 'AGODA'
      | 'VINPEARL',
    filterHotelName?: string,
    filterHotelCategories?: Array<string>,
    filterBreakfastIncluded?: boolean,
    filterFromPrice?: number,
    filterToPrice?: number,
    filterFromDistance?: number,
    filterToDistance?: number,
    filterStarRating?: Array<string>,
    filterFromGuestRating?: number,
    filterToGuestRating?: number,
    filterAmenities?: Array<string>,
    filterRoomAmenities?: Array<string>,
    filterRateAmenities?: Array<string>,
    filterRoomViews?: Array<string>,
    filterThemes?: Array<string>,
    filterMealPlans?: Array<string>,
    filterBedTypes?: Array<string>,
    filterGeoDistanceLat?: number,
    filterGeoDistanceLon?: number,
    filterGeoDistanceMeters?: number,
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder?: 'ASC' | 'DESC',
    pageNumber?: number,
    pageSize?: number,
    locationName?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfSearchBestRatesResult>>;
  public searchBestRatesUsingGET(
    searchCode: string,
    searchType:
      | 'AUTO'
      | 'CONTINENT'
      | 'COUNTRY'
      | 'PROVINCE_STATE'
      | 'HIGH_LEVEL_REGION'
      | 'MULTI_CITY_VICINITY'
      | 'CITY'
      | 'NEIGHBORHOOD'
      | 'AIRPORT'
      | 'POINT_OF_INTEREST'
      | 'TRAIN_STATION'
      | 'METRO_STATION'
      | 'HOTEL',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    supplier?: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    targetSupplier?:
      | 'EXPEDIA'
      | 'AXISROOM'
      | 'BEDLINKER'
      | 'AGODA'
      | 'VINPEARL',
    filterHotelName?: string,
    filterHotelCategories?: Array<string>,
    filterBreakfastIncluded?: boolean,
    filterFromPrice?: number,
    filterToPrice?: number,
    filterFromDistance?: number,
    filterToDistance?: number,
    filterStarRating?: Array<string>,
    filterFromGuestRating?: number,
    filterToGuestRating?: number,
    filterAmenities?: Array<string>,
    filterRoomAmenities?: Array<string>,
    filterRateAmenities?: Array<string>,
    filterRoomViews?: Array<string>,
    filterThemes?: Array<string>,
    filterMealPlans?: Array<string>,
    filterBedTypes?: Array<string>,
    filterGeoDistanceLat?: number,
    filterGeoDistanceLon?: number,
    filterGeoDistanceMeters?: number,
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder?: 'ASC' | 'DESC',
    pageNumber?: number,
    pageSize?: number,
    locationName?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfSearchBestRatesResult>>;
  public searchBestRatesUsingGET(
    searchCode: string,
    searchType:
      | 'AUTO'
      | 'CONTINENT'
      | 'COUNTRY'
      | 'PROVINCE_STATE'
      | 'HIGH_LEVEL_REGION'
      | 'MULTI_CITY_VICINITY'
      | 'CITY'
      | 'NEIGHBORHOOD'
      | 'AIRPORT'
      | 'POINT_OF_INTEREST'
      | 'TRAIN_STATION'
      | 'METRO_STATION'
      | 'HOTEL',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    supplier?: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    targetSupplier?:
      | 'EXPEDIA'
      | 'AXISROOM'
      | 'BEDLINKER'
      | 'AGODA'
      | 'VINPEARL',
    filterHotelName?: string,
    filterHotelCategories?: Array<string>,
    filterBreakfastIncluded?: boolean,
    filterFromPrice?: number,
    filterToPrice?: number,
    filterFromDistance?: number,
    filterToDistance?: number,
    filterStarRating?: Array<string>,
    filterFromGuestRating?: number,
    filterToGuestRating?: number,
    filterAmenities?: Array<string>,
    filterRoomAmenities?: Array<string>,
    filterRateAmenities?: Array<string>,
    filterRoomViews?: Array<string>,
    filterThemes?: Array<string>,
    filterMealPlans?: Array<string>,
    filterBedTypes?: Array<string>,
    filterGeoDistanceLat?: number,
    filterGeoDistanceLon?: number,
    filterGeoDistanceMeters?: number,
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder?: 'ASC' | 'DESC',
    pageNumber?: number,
    pageSize?: number,
    locationName?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (searchCode === null || searchCode === undefined) {
      throw new Error(
        'Required parameter searchCode was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (searchType === null || searchType === undefined) {
      throw new Error(
        'Required parameter searchType was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (currency === null || currency === undefined) {
      throw new Error(
        'Required parameter currency was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (checkIn === null || checkIn === undefined) {
      throw new Error(
        'Required parameter checkIn was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (checkOut === null || checkOut === undefined) {
      throw new Error(
        'Required parameter checkOut was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (paxInfos === null || paxInfos === undefined) {
      throw new Error(
        'Required parameter paxInfos was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (searchCode !== undefined && searchCode !== null) {
      queryParameters = queryParameters.set('searchCode', <any>searchCode);
    }
    if (searchType !== undefined && searchType !== null) {
      queryParameters = queryParameters.set('searchType', <any>searchType);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }
    if (currency !== undefined && currency !== null) {
      queryParameters = queryParameters.set('currency', <any>currency);
    }
    if (checkIn !== undefined && checkIn !== null) {
      queryParameters = queryParameters.set('checkIn', <any>checkIn);
    }
    if (checkOut !== undefined && checkOut !== null) {
      queryParameters = queryParameters.set('checkOut', <any>checkOut);
    }
    if (paxInfos) {
      paxInfos.forEach(element => {
        queryParameters = queryParameters.append('paxInfos', <any>element);
      });
    }
    if (searchId !== undefined && searchId !== null) {
      queryParameters = queryParameters.set('searchId', <any>searchId);
    }
    if (supplier !== undefined && supplier !== null) {
      queryParameters = queryParameters.set('supplier', <any>supplier);
    }
    if (locationName !== undefined && locationName !== null) {
      queryParameters = queryParameters.set('locationName', <any>locationName);
    }
    if (targetSupplier !== undefined && targetSupplier !== null) {
      queryParameters = queryParameters.set('targetSupplier', <any>(
        targetSupplier
      ));
    }
    if (filterHotelName !== undefined && filterHotelName !== null) {
      queryParameters = queryParameters.set('filterHotelName', <any>(
        filterHotelName
      ));
    }
    if (filterHotelCategories) {
      filterHotelCategories.forEach(element => {
        queryParameters = queryParameters.append('filterHotelCategories', <any>(
          element
        ));
      });
    }
    if (
      filterBreakfastIncluded !== undefined &&
      filterBreakfastIncluded !== null
    ) {
      queryParameters = queryParameters.set('filterBreakfastIncluded', <any>(
        filterBreakfastIncluded
      ));
    }
    if (filterFromPrice !== undefined && filterFromPrice !== null) {
      queryParameters = queryParameters.set('filterFromPrice', <any>(
        filterFromPrice
      ));
    }
    if (filterToPrice !== undefined && filterToPrice !== null) {
      queryParameters = queryParameters.set('filterToPrice', <any>(
        filterToPrice
      ));
    }
    if (filterFromDistance !== undefined && filterFromDistance !== null) {
      queryParameters = queryParameters.set('filterFromDistance', <any>(
        filterFromDistance
      ));
    }
    if (filterToDistance !== undefined && filterToDistance !== null) {
      queryParameters = queryParameters.set('filterToDistance', <any>(
        filterToDistance
      ));
    }
    if (filterFromGuestRating !== undefined && filterFromGuestRating !== null) {
      queryParameters = queryParameters.set('filterFromGuestRating', <any>(
        filterFromGuestRating
      ));
    }
    if (filterToGuestRating !== undefined && filterToGuestRating !== null) {
      queryParameters = queryParameters.set('filterToGuestRating', <any>(
        filterToGuestRating
      ));
    }
    if (filterStarRating) {
      filterStarRating.forEach(element => {
        queryParameters = queryParameters.append('filterStarRating', <any>(
          element
        ));
      });
    }
    if (filterAmenities) {
      filterAmenities.forEach(element => {
        queryParameters = queryParameters.append('filterAmenities', <any>(
          element
        ));
      });
    }
    if (filterRoomAmenities) {
      filterRoomAmenities.forEach(element => {
        queryParameters = queryParameters.append('filterRoomAmenities', <any>(
          element
        ));
      });
    }
    if (filterRateAmenities) {
      filterRateAmenities.forEach(element => {
        queryParameters = queryParameters.append('filterRateAmenities', <any>(
          element
        ));
      });
    }
    if (filterRoomViews) {
      filterRoomViews.forEach(element => {
        queryParameters = queryParameters.append('filterRoomViews', <any>(
          element
        ));
      });
    }
    if (filterThemes) {
      filterThemes.forEach(element => {
        queryParameters = queryParameters.append('filterThemes', <any>element);
      });
    }
    if (filterMealPlans) {
      filterMealPlans.forEach(element => {
        queryParameters = queryParameters.append('filterMealPlans', <any>(
          element
        ));
      });
    }
    if (filterBedTypes) {
      filterBedTypes.forEach(element => {
        queryParameters = queryParameters.append('filterBedTypes', <any>(
          element
        ));
      });
    }
    if (filterGeoDistanceLat !== undefined && filterGeoDistanceLat !== null) {
      queryParameters = queryParameters.set('filterGeoDistanceLat', <any>(
        filterGeoDistanceLat
      ));
    }
    if (filterGeoDistanceLon !== undefined && filterGeoDistanceLon !== null) {
      queryParameters = queryParameters.set('filterGeoDistanceLon', <any>(
        filterGeoDistanceLon
      ));
    }
    if (
      filterGeoDistanceMeters !== undefined &&
      filterGeoDistanceMeters !== null
    ) {
      queryParameters = queryParameters.set('filterGeoDistanceMeters', <any>(
        filterGeoDistanceMeters
      ));
    }
    if (sortField !== undefined && sortField !== null) {
      queryParameters = queryParameters.set('sortField', <any>sortField);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      queryParameters = queryParameters.set('sortOrder', <any>sortOrder);
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      queryParameters = queryParameters.set('pageNumber', <any>pageNumber);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }

    let headers = this.defaultHeaders;
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfSearchBestRatesResult>(
      `${this.basePath}/api/v3/hotel/search-best-rates`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }



  /**
   * searchBestRates
   *
   * @param searchCode searchCode
   * @param searchType searchType
   * @param language language
   * @param currency currency
   * @param checkIn yyyy-mm-dd ex: 2020-05-12
   * @param checkOut yyyy-mm-dd ex: 2020-05-13
   * @param paxInfos adultQuantity-first child age,second child age, ...  ex: 2-5,6
   * @param searchId searchId
   * @param supplier Required for searchType HOTEL
   * @param filterHotelName Haven&#39;t been used yet
   * @param filterHotelCategories filterHotelCategories
   * @param filterBreakfastIncluded filterBreakfastIncluded
   * @param filterFromPrice filterFromPrice
   * @param filterToPrice filterToPrice
   * @param filterFromDistance
   * @param filterToDistance
   * @param filterStarRating filterStarRating
   * @param targetSupplier targetSupplier
   * @param filterFromGuestRating filterFromGuestRating
   * @param filterToGuestRating filterToGuestRating
   * @param filterAmenities filterAmenities
   * @param filterRoomAmenities filterRoomAmenities
   * @param filterRateAmenities
   * @param filterRoomViews filterRoomViews
   * @param filterThemes filterThemes
   * @param filterMealPlans filterMealPlans
   * @param filterBedTypes
   * @param filterGeoDistanceLat filterGeoDistanceLat
   * @param filterGeoDistanceLon filterGeoDistanceLon
   * @param filterGeoDistanceMeters filterGeoDistanceMeters
   * @param sortField Default sort order, price ascending
   * @param sortOrder sortOrder
   * @param pageNumber pageNumber
   * @param pageSize Maximum value 250
   * @param locationName
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchRecommendedRatesUsingGET(
    searchCode: string,
    searchType:
      | 'AUTO'
      | 'CONTINENT'
      | 'COUNTRY'
      | 'PROVINCE_STATE'
      | 'HIGH_LEVEL_REGION'
      | 'MULTI_CITY_VICINITY'
      | 'CITY'
      | 'NEIGHBORHOOD'
      | 'AIRPORT'
      | 'POINT_OF_INTEREST'
      | 'TRAIN_STATION'
      | 'METRO_STATION'
      | 'HOTEL',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    supplier?: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    targetSupplier?:
      | 'EXPEDIA'
      | 'AXISROOM'
      | 'BEDLINKER'
      | 'AGODA'
      | 'VINPEARL',
    filterHotelName?: string,
    filterHotelCategories?: Array<string>,
    filterBreakfastIncluded?: boolean,
    filterFromPrice?: number,
    filterToPrice?: number,
    filterFromDistance?: number,
    filterToDistance?: number,
    filterStarRating?: Array<string>,
    filterFromGuestRating?: number,
    filterToGuestRating?: number,
    filterAmenities?: Array<string>,
    filterRoomAmenities?: Array<string>,
    filterRateAmenities?: Array<string>,
    filterRoomViews?: Array<string>,
    filterThemes?: Array<string>,
    filterMealPlans?: Array<string>,
    filterBedTypes?: Array<string>,
    filterGeoDistanceLat?: number,
    filterGeoDistanceLon?: number,
    filterGeoDistanceMeters?: number,
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder?: 'ASC' | 'DESC',
    pageNumber?: number,
    pageSize?: number,
    locationName?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfSearchBestRatesResult>;
  public searchRecommendedRatesUsingGET(
    searchCode: string,
    searchType:
      | 'AUTO'
      | 'CONTINENT'
      | 'COUNTRY'
      | 'PROVINCE_STATE'
      | 'HIGH_LEVEL_REGION'
      | 'MULTI_CITY_VICINITY'
      | 'CITY'
      | 'NEIGHBORHOOD'
      | 'AIRPORT'
      | 'POINT_OF_INTEREST'
      | 'TRAIN_STATION'
      | 'METRO_STATION'
      | 'HOTEL',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    supplier?: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    targetSupplier?:
      | 'EXPEDIA'
      | 'AXISROOM'
      | 'BEDLINKER'
      | 'AGODA'
      | 'VINPEARL',
    filterHotelName?: string,
    filterHotelCategories?: Array<string>,
    filterBreakfastIncluded?: boolean,
    filterFromPrice?: number,
    filterToPrice?: number,
    filterFromDistance?: number,
    filterToDistance?: number,
    filterStarRating?: Array<string>,
    filterFromGuestRating?: number,
    filterToGuestRating?: number,
    filterAmenities?: Array<string>,
    filterRoomAmenities?: Array<string>,
    filterRateAmenities?: Array<string>,
    filterRoomViews?: Array<string>,
    filterThemes?: Array<string>,
    filterMealPlans?: Array<string>,
    filterBedTypes?: Array<string>,
    filterGeoDistanceLat?: number,
    filterGeoDistanceLon?: number,
    filterGeoDistanceMeters?: number,
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder?: 'ASC' | 'DESC',
    pageNumber?: number,
    pageSize?: number,
    locationName?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfSearchBestRatesResult>>;
  public searchRecommendedRatesUsingGET(
    searchCode: string,
    searchType:
      | 'AUTO'
      | 'CONTINENT'
      | 'COUNTRY'
      | 'PROVINCE_STATE'
      | 'HIGH_LEVEL_REGION'
      | 'MULTI_CITY_VICINITY'
      | 'CITY'
      | 'NEIGHBORHOOD'
      | 'AIRPORT'
      | 'POINT_OF_INTEREST'
      | 'TRAIN_STATION'
      | 'METRO_STATION'
      | 'HOTEL',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    supplier?: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    targetSupplier?:
      | 'EXPEDIA'
      | 'AXISROOM'
      | 'BEDLINKER'
      | 'AGODA'
      | 'VINPEARL',
    filterHotelName?: string,
    filterHotelCategories?: Array<string>,
    filterBreakfastIncluded?: boolean,
    filterFromPrice?: number,
    filterToPrice?: number,
    filterFromDistance?: number,
    filterToDistance?: number,
    filterStarRating?: Array<string>,
    filterFromGuestRating?: number,
    filterToGuestRating?: number,
    filterAmenities?: Array<string>,
    filterRoomAmenities?: Array<string>,
    filterRateAmenities?: Array<string>,
    filterRoomViews?: Array<string>,
    filterThemes?: Array<string>,
    filterMealPlans?: Array<string>,
    filterBedTypes?: Array<string>,
    filterGeoDistanceLat?: number,
    filterGeoDistanceLon?: number,
    filterGeoDistanceMeters?: number,
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder?: 'ASC' | 'DESC',
    pageNumber?: number,
    pageSize?: number,
    locationName?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfSearchBestRatesResult>>;
  public searchRecommendedRatesUsingGET(
    searchCode: string,
    searchType:
      | 'AUTO'
      | 'CONTINENT'
      | 'COUNTRY'
      | 'PROVINCE_STATE'
      | 'HIGH_LEVEL_REGION'
      | 'MULTI_CITY_VICINITY'
      | 'CITY'
      | 'NEIGHBORHOOD'
      | 'AIRPORT'
      | 'POINT_OF_INTEREST'
      | 'TRAIN_STATION'
      | 'METRO_STATION'
      | 'HOTEL',
    language: 'vi' | 'en',
    currency: 'VND' | 'USD',
    checkIn: string,
    checkOut: string,
    paxInfos: Array<string>,
    searchId?: string,
    supplier?: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL',
    targetSupplier?:
      | 'EXPEDIA'
      | 'AXISROOM'
      | 'BEDLINKER'
      | 'AGODA'
      | 'VINPEARL',
    filterHotelName?: string,
    filterHotelCategories?: Array<string>,
    filterBreakfastIncluded?: boolean,
    filterFromPrice?: number,
    filterToPrice?: number,
    filterFromDistance?: number,
    filterToDistance?: number,
    filterStarRating?: Array<string>,
    filterFromGuestRating?: number,
    filterToGuestRating?: number,
    filterAmenities?: Array<string>,
    filterRoomAmenities?: Array<string>,
    filterRateAmenities?: Array<string>,
    filterRoomViews?: Array<string>,
    filterThemes?: Array<string>,
    filterMealPlans?: Array<string>,
    filterBedTypes?: Array<string>,
    filterGeoDistanceLat?: number,
    filterGeoDistanceLon?: number,
    filterGeoDistanceMeters?: number,
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder?: 'ASC' | 'DESC',
    pageNumber?: number,
    pageSize?: number,
    locationName?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (searchCode === null || searchCode === undefined) {
      throw new Error(
        'Required parameter searchCode was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (searchType === null || searchType === undefined) {
      throw new Error(
        'Required parameter searchType was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (currency === null || currency === undefined) {
      throw new Error(
        'Required parameter currency was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (checkIn === null || checkIn === undefined) {
      throw new Error(
        'Required parameter checkIn was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (checkOut === null || checkOut === undefined) {
      throw new Error(
        'Required parameter checkOut was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    if (paxInfos === null || paxInfos === undefined) {
      throw new Error(
        'Required parameter paxInfos was null or undefined when calling searchBestRatesUsingGET.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (searchCode !== undefined && searchCode !== null) {
      queryParameters = queryParameters.set('searchCode', <any>searchCode);
    }
    if (searchType !== undefined && searchType !== null) {
      queryParameters = queryParameters.set('searchType', <any>searchType);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }
    if (currency !== undefined && currency !== null) {
      queryParameters = queryParameters.set('currency', <any>currency);
    }
    if (checkIn !== undefined && checkIn !== null) {
      queryParameters = queryParameters.set('checkIn', <any>checkIn);
    }
    if (checkOut !== undefined && checkOut !== null) {
      queryParameters = queryParameters.set('checkOut', <any>checkOut);
    }
    if (paxInfos) {
      paxInfos.forEach(element => {
        queryParameters = queryParameters.append('paxInfos', <any>element);
      });
    }
    if (searchId !== undefined && searchId !== null) {
      queryParameters = queryParameters.set('searchId', <any>searchId);
    }
    if (supplier !== undefined && supplier !== null) {
      queryParameters = queryParameters.set('supplier', <any>supplier);
    }
    if (locationName !== undefined && locationName !== null) {
      queryParameters = queryParameters.set('locationName', <any>locationName);
    }
    if (targetSupplier !== undefined && targetSupplier !== null) {
      queryParameters = queryParameters.set('targetSupplier', <any>(
        targetSupplier
      ));
    }
    if (filterHotelName !== undefined && filterHotelName !== null) {
      queryParameters = queryParameters.set('filterHotelName', <any>(
        filterHotelName
      ));
    }
    if (filterHotelCategories) {
      filterHotelCategories.forEach(element => {
        queryParameters = queryParameters.append('filterHotelCategories', <any>(
          element
        ));
      });
    }
    if (
      filterBreakfastIncluded !== undefined &&
      filterBreakfastIncluded !== null
    ) {
      queryParameters = queryParameters.set('filterBreakfastIncluded', <any>(
        filterBreakfastIncluded
      ));
    }
    if (filterFromPrice !== undefined && filterFromPrice !== null) {
      queryParameters = queryParameters.set('filterFromPrice', <any>(
        filterFromPrice
      ));
    }
    if (filterToPrice !== undefined && filterToPrice !== null) {
      queryParameters = queryParameters.set('filterToPrice', <any>(
        filterToPrice
      ));
    }
    if (filterFromDistance !== undefined && filterFromDistance !== null) {
      queryParameters = queryParameters.set('filterFromDistance', <any>(
        filterFromDistance
      ));
    }
    if (filterToDistance !== undefined && filterToDistance !== null) {
      queryParameters = queryParameters.set('filterToDistance', <any>(
        filterToDistance
      ));
    }
    if (filterFromGuestRating !== undefined && filterFromGuestRating !== null) {
      queryParameters = queryParameters.set('filterFromGuestRating', <any>(
        filterFromGuestRating
      ));
    }
    if (filterToGuestRating !== undefined && filterToGuestRating !== null) {
      queryParameters = queryParameters.set('filterToGuestRating', <any>(
        filterToGuestRating
      ));
    }
    if (filterStarRating) {
      filterStarRating.forEach(element => {
        queryParameters = queryParameters.append('filterStarRating', <any>(
          element
        ));
      });
    }
    if (filterAmenities) {
      filterAmenities.forEach(element => {
        queryParameters = queryParameters.append('filterAmenities', <any>(
          element
        ));
      });
    }
    if (filterRoomAmenities) {
      filterRoomAmenities.forEach(element => {
        queryParameters = queryParameters.append('filterRoomAmenities', <any>(
          element
        ));
      });
    }
    if (filterRateAmenities) {
      filterRateAmenities.forEach(element => {
        queryParameters = queryParameters.append('filterRateAmenities', <any>(
          element
        ));
      });
    }
    if (filterRoomViews) {
      filterRoomViews.forEach(element => {
        queryParameters = queryParameters.append('filterRoomViews', <any>(
          element
        ));
      });
    }
    if (filterThemes) {
      filterThemes.forEach(element => {
        queryParameters = queryParameters.append('filterThemes', <any>element);
      });
    }
    if (filterMealPlans) {
      filterMealPlans.forEach(element => {
        queryParameters = queryParameters.append('filterMealPlans', <any>(
          element
        ));
      });
    }
    if (filterBedTypes) {
      filterBedTypes.forEach(element => {
        queryParameters = queryParameters.append('filterBedTypes', <any>(
          element
        ));
      });
    }
    if (filterGeoDistanceLat !== undefined && filterGeoDistanceLat !== null) {
      queryParameters = queryParameters.set('filterGeoDistanceLat', <any>(
        filterGeoDistanceLat
      ));
    }
    if (filterGeoDistanceLon !== undefined && filterGeoDistanceLon !== null) {
      queryParameters = queryParameters.set('filterGeoDistanceLon', <any>(
        filterGeoDistanceLon
      ));
    }
    if (
      filterGeoDistanceMeters !== undefined &&
      filterGeoDistanceMeters !== null
    ) {
      queryParameters = queryParameters.set('filterGeoDistanceMeters', <any>(
        filterGeoDistanceMeters
      ));
    }
    if (sortField !== undefined && sortField !== null) {
      queryParameters = queryParameters.set('sortField', <any>sortField);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      queryParameters = queryParameters.set('sortOrder', <any>sortOrder);
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      queryParameters = queryParameters.set('pageNumber', <any>pageNumber);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }

    let headers = this.defaultHeaders;
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfSearchBestRatesResult>(
      `${this.basePath}/api/v3/hotel/search-recommended-rates`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * searchKeyWord
   *
   * @param keyword keyword
   * @param language language
   * @param pageNumber pageNumber
   * @param pageSize pageSize
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchKeyWordUsingGET(
    keyword: string,
    language: 'vi' | 'en',
    pageNumber?: number,
    pageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfSearchKeyWordResult>;
  public searchKeyWordUsingGET(
    keyword: string,
    language: 'vi' | 'en',
    pageNumber?: number,
    pageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfSearchKeyWordResult>>;
  public searchKeyWordUsingGET(
    keyword: string,
    language: 'vi' | 'en',
    pageNumber?: number,
    pageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfSearchKeyWordResult>>;
  public searchKeyWordUsingGET(
    keyword: string,
    language: 'vi' | 'en',
    pageNumber?: number,
    pageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (keyword === null || keyword === undefined) {
      throw new Error(
        'Required parameter keyword was null or undefined when calling searchKeyWordUsingGET.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling searchKeyWordUsingGET.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (keyword !== undefined && keyword !== null) {
      queryParameters = queryParameters.set('keyword', <any>keyword);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }
    if (pageNumber !== undefined && pageNumber !== null) {
      queryParameters = queryParameters.set('pageNumber', <any>pageNumber);
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfSearchKeyWordResult>(
      `${this.basePath}/api/v3/hotel/search-keyword`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getPropertyIntro
   *
   * @param searchCode searchCode
   * @param language language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getPropertyIntroUsingGET(
    searchCode: string,
    language: 'vi' | 'en',
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OtaResultOfGetPropertyIntroResult>;
  public getPropertyIntroUsingGET(
    searchCode: string,
    language: 'vi' | 'en',
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OtaResultOfGetPropertyIntroResult>>;
  public getPropertyIntroUsingGET(
    searchCode: string,
    language: 'vi' | 'en',
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OtaResultOfGetPropertyIntroResult>>;
  public getPropertyIntroUsingGET(
    searchCode: string,
    language: 'vi' | 'en',
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (searchCode === null || searchCode === undefined) {
      throw new Error(
        'Required parameter searchCode was null or undefined when calling getPropertyIntroUsingGET.'
      );
    }

    if (language === null || language === undefined) {
      throw new Error(
        'Required parameter language was null or undefined when calling getPropertyIntroUsingGET.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (searchCode !== undefined && searchCode !== null) {
      queryParameters = queryParameters.set('searchCode', <any>searchCode);
    }
    if (language !== undefined && language !== null) {
      queryParameters = queryParameters.set('language', <any>language);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json;charset=UTF-8'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.get<OtaResultOfGetPropertyIntroResult>(
      `${this.basePath}/api/v3/hotel/get-property-intro`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
