import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { SearchUserBookingPartialState } from './search-user-booking.reducer';
import {
  LoadSearchUserBooking,
  SearchUserBookingLoaded,
  SearchUserBookingLoadError,
  SearchUserBookingActionTypes
} from './search-user-booking.actions';
import {map} from "rxjs/operators";
import {BookingResourceService} from "../api-client/index";

@Injectable()
export class SearchUserBookingEffects {
  @Effect() loadSearchUserBooking$ = this.dataPersistence.fetch(
    SearchUserBookingActionTypes.LoadSearchUserBooking,
    {
      run: (
        action: LoadSearchUserBooking,
        state: SearchUserBookingPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.bookingResourceService.searchBookingsUsingGET1(
          action.payload.supplierType,
          action.payload.filter && action.payload.filter.bookingCode? action.payload.filter.bookingCode : '',
          action.payload.filter && action.payload.filter.bookingStatus? action.payload.filter.bookingStatus : '',
          action.payload.filter && action.payload.filter.fromLocationName? action.payload.filter.fromLocationName : '',
          action.payload.filter && action.payload.filter.toLocationName? action.payload.filter.toLocationName : '',
          action.payload.filter && action.payload.filter.fromDate? action.payload.filter.fromDate : '',
          action.payload.filter && action.payload.filter.toDate? action.payload.filter.toDate : '',
          action.payload.filter && action.payload.filter.listBookingStatus? action.payload.filter.listBookingStatus : [],
          action.payload.filter && action.payload.filter.filterStatus? action.payload.filter.filterStatus : false,
          action.payload.page,
          action.payload.size,
          action.payload.sort,
          'response'
        ).pipe(
            map(result => ({
              type: SearchUserBookingActionTypes.SearchUserBookingLoaded,
              payload: result,
            }))
          );
      },

      onError: (action: LoadSearchUserBooking, error) => {
        console.error('Error', error);
        return new SearchUserBookingLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private bookingResourceService: BookingResourceService,
    private dataPersistence: DataPersistence<SearchUserBookingPartialState>
  ) {}
}
