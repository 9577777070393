export * from './airlineAlliancesResource.service';
import { AirlineAlliancesResourceService } from './airlineAlliancesResource.service';
export * from './airlineResource.service';
import { AirlineResourceService } from './airlineResource.service';
export * from './airportResource.service';
import { AirportResourceService } from './airportResource.service';
export * from './cityCodeResource.service';
import { CityCodeResourceService } from './cityCodeResource.service';
export * from './countryCodeResource.service';
import { CountryCodeResourceService } from './countryCodeResource.service';
export * from './destinationMappingCodeResource.service';
import { DestinationMappingCodeResourceService } from './destinationMappingCodeResource.service';
export * from './destinationMappingRegionResource.service';
import { DestinationMappingRegionResourceService } from './destinationMappingRegionResource.service';
export * from './destinationMappingResource.service';
import { DestinationMappingResourceService } from './destinationMappingResource.service';
export * from './elasticsearchIndexResource.service';
import { ElasticsearchIndexResourceService } from './elasticsearchIndexResource.service';
export * from './emailTemplateResource.service';
import { EmailTemplateResourceService } from './emailTemplateResource.service';
export * from './hotelMappingCodeResource.service';
import { HotelMappingCodeResourceService } from './hotelMappingCodeResource.service';
export * from './hotelMappingResource.service';
import { HotelMappingResourceService } from './hotelMappingResource.service';
export * from './hotelMetaContentResource.service';
import { HotelMetaContentResourceService } from './hotelMetaContentResource.service';
export * from './lookupResource.service';
import { LookupResourceService } from './lookupResource.service';
export * from './metaCacheDataResource.service';
import { MetaCacheDataResourceService } from './metaCacheDataResource.service';
export * from './profileInfoResource.service';
import { ProfileInfoResourceService } from './profileInfoResource.service';
export * from './regionResource.service';
import { RegionResourceService } from './regionResource.service';
export * from './routeResource.service';
import { RouteResourceService } from './routeResource.service';
export * from './stateCodeResource.service';
import { StateCodeResourceService } from './stateCodeResource.service';
export * from './internalConfigResource.service';
import { InternalConfigResourceService } from './internalConfigResource.service';
export const APIS = [AirlineAlliancesResourceService, AirlineResourceService, AirportResourceService, CityCodeResourceService, CountryCodeResourceService, DestinationMappingCodeResourceService, DestinationMappingRegionResourceService, DestinationMappingResourceService, ElasticsearchIndexResourceService, EmailTemplateResourceService, HotelMappingCodeResourceService, HotelMappingResourceService, HotelMetaContentResourceService, LookupResourceService, MetaCacheDataResourceService, ProfileInfoResourceService, RegionResourceService, RouteResourceService, StateCodeResourceService, InternalConfigResourceService];
