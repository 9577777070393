import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import {
  ChangeAmenities,
  ChangeCategories,
  ChangePage,
  ChangeSort,
  ChangeThemes,
  ResetFilter,
  TourFilterAvailableActionTypes,
  TourSortAvailable
} from './tour-filter-available.actions';

import { TourFilterAvailablePartialState } from './tour-filter-available.reducer';
import { tourFilterAvailableQuery } from './tour-filter-available.selectors';

@Injectable()
export class TourFilterAvailableFacade {
  allTourFilterAvailable$ = this.store.pipe(
    select(tourFilterAvailableQuery.getAllCurrentFilter)
  );

  constructor(private store: Store<TourFilterAvailablePartialState>) {}

  changeCategoryIds(ids: string[]) {
    this.store.dispatch(new ChangeCategories(ids));
  }

  changeThemeIds(ids: string[]) {
    this.store.dispatch(new ChangeThemes(ids));
  }

  changeAmenityIds(ids: string[]) {
    this.store.dispatch(new ChangeAmenities(ids));
  }

  changePage(idx: number) {
    this.store.dispatch(new ChangePage(idx));
  }

  changeSort(payload: TourSortAvailable) {
    this.store.dispatch(new ChangeSort(payload));
  }

  resetFilter() {
    this.store.dispatch(new ResetFilter());
  }
}
