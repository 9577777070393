import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule
} from '@angular/material';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faHotel,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import { IncreamentInputModule } from '@gtd/components/increament-input';
import { NewIncreamentInputModule } from '@gtd/components/new-increament-input';
import { ClickOutsideModule } from '@gtd/directives/click-outside';
import { NewFlightFormSearchModule } from '@gtd/flight';
import { DestinationModule } from '@gtd/meta';
import { TranslateModule } from '@ngx-translate/core';
import { StateHotelModule } from '../../state/state-hotel.module';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { DestinationComponent } from './destination/destination.component';
import { NewHotelFormSearchComponent } from './hotel-form-search.component';
import { RoomsComponent } from './rooms/rooms.component';
import { FormRatingStarComponent } from './form-rating-star/form-rating-star.component';
import {SanitizeModule} from "@gtd/pipes/sanitize";

library.add(faChevronDown, faMapMarkerAlt, faHotel);

@NgModule({
  declarations: [
    NewHotelFormSearchComponent,
    AutoCompleteComponent,
    RoomsComponent,
    DestinationComponent,
    FormRatingStarComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatMenuModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDividerModule,
        FlexLayoutModule,
        IncreamentInputModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDialogModule,
        StateHotelModule,
        DestinationModule,
        NewFlightFormSearchModule,
        NewIncreamentInputModule,
        MatIconModule,
        ClickOutsideModule,
        SanitizeModule
    ],
  exports: [NewHotelFormSearchComponent, RoomsComponent]
})
export class NewHotelFormSearchModule {}
