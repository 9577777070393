import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DialogMessageService {

  actionDialog = new BehaviorSubject<boolean>(false);
  getActionDialog = this.actionDialog.asObservable();

  isLoadingDialog = new BehaviorSubject<boolean>(false);
  getIsLoadingDialog = this.isLoadingDialog.asObservable();

  constructor() { }

  setActionDialog(actionDialog: boolean) {
    this.actionDialog.next(actionDialog);
  }
  setIsLoadingDialog(isLoading: boolean) {
    this.isLoadingDialog.next(isLoading);
  }
}
