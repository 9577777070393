import {Action} from '@ngrx/store';
import {OtaResultOfPropertyDTO} from "@gtd/meta-client";

export enum PopularPlacesActionTypes {
  LoadPopularPlaces = '[PopularPlaces] Load PopularPlaces',
  PopularPlacesLoaded = '[PopularPlaces] PopularPlaces Loaded',
  PopularPlacesLoadError = '[PopularPlaces] PopularPlaces Load Error'
}

export class LoadPopularPlaces implements Action {
  readonly type = PopularPlacesActionTypes.LoadPopularPlaces;
  constructor(public payload: string) {}
}

export class PopularPlacesLoadError implements Action {
  readonly type = PopularPlacesActionTypes.PopularPlacesLoadError;
  constructor(public payload: any) {}
}

export class PopularPlacesLoaded implements Action {
  readonly type = PopularPlacesActionTypes.PopularPlacesLoaded;
  constructor(public payload: OtaResultOfPropertyDTO) {}
}

export type PopularPlacesAction =
  | LoadPopularPlaces
  | PopularPlacesLoaded
  | PopularPlacesLoadError;

export const fromPopularPlacesActions = {
  LoadPopularPlaces,
  PopularPlacesLoaded,
  PopularPlacesLoadError
};
