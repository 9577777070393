import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {
  CUSTOMERTRAVELLER_FEATURE_KEY,
  initialState as customerTravellerInitialState,
  reducer
} from './+state/customer-traveller.reducer';
import {CustomerTravellerEffects} from './+state/customer-traveller.effects';
import {CustomerTravellerFacade} from './+state/customer-traveller.facade';
import {NxModule} from '@nrwl/angular';
import {CustomerTravellerResourceService} from "@gtd/api-services/customer-service";
import {BASE_PATH} from "@gtd/api-services/customer-service";
import {environment} from "@env/environment";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forFeature(CUSTOMERTRAVELLER_FEATURE_KEY, reducer, {
      initialState: customerTravellerInitialState
    }),
    EffectsModule.forFeature([CustomerTravellerEffects])
  ],
  providers: [{provide: BASE_PATH, useValue: environment.api.customerService}, CustomerTravellerFacade, CustomerTravellerResourceService]
})
export class CustomerTravellerModule {}
