import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FilterOptionsFacade } from '../../../../../state/filter-options/filter-options.facade';
import { fadeInOut } from '@gtd/helpers';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterAvailableFacade } from '../../../../../state/filter-available/filter-available.facade';
import { Subscription } from 'rxjs';
import { FilterAvailableState } from '../../../../../state/filter-available/filter-available.reducer';

@Component({
  selector: 'gtd-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  animations: [fadeInOut]
})
export class FilterPanelComponent implements OnInit, OnDestroy {
  @Input() currentFilter: FilterAvailableState;
  @Input() searchParams: any;
  filterForm: FormGroup;

  loadedFilter$ = this.filterOptionsFacade.loaded$;
  allFilterOptions$ = this.filterOptionsFacade.allFilterOptions$;
  subscriptions = new Subscription();
  utm_source: string = localStorage.getItem('utm_source');
  constructor(
    private filterOptionsFacade: FilterOptionsFacade,
    private filterAvailableFacade: FilterAvailableFacade
  ) {
    this.filterForm = new FormGroup({
      filterHotelName: new FormControl(),
      filterPrice: new FormControl(),
      filterStarRating: new FormControl(),
      filterGuestRating: new FormControl(),
      filterRoomAmenities: new FormControl(),
      filterPropertyAmenities: new FormControl(),
      filterRoomViews: new FormControl(),
      filterThemes: new FormControl(),
      filterMealPlans: new FormControl(),
      filterHotelCategories: new FormControl()
    });
  }

  ngOnInit() {
    if (this.currentFilter) {
      this.filterForm.setValue({
        filterHotelName: this.currentFilter.filterHotelName
          ? this.currentFilter.filterHotelName
          : null,
        filterPrice: this.currentFilter.filterPrice
          ? this.currentFilter.filterPrice
          : null,
        filterStarRating: this.currentFilter.filterStarRating
          ? this.currentFilter.filterStarRating
          : null,
        filterGuestRating: this.currentFilter.filterGuestRating
          ? this.currentFilter.filterGuestRating
          : null,
        filterRoomAmenities: this.currentFilter.filterRoomAmenities
          ? this.currentFilter.filterRoomAmenities
          : null,
        filterPropertyAmenities: this.currentFilter.filterPropertyAmenities
          ? this.currentFilter.filterPropertyAmenities
          : null,
        filterRoomViews: this.currentFilter.filterRoomViews
          ? this.currentFilter.filterRoomViews
          : null,
        filterThemes: this.currentFilter.filterThemes
          ? this.currentFilter.filterThemes
          : null,
        filterMealPlans: this.currentFilter.filterMealPlans
          ? this.currentFilter.filterMealPlans
          : null,
        filterHotelCategories: this.currentFilter.filterHotelCategories
          ? this.currentFilter.filterHotelCategories
          : null
      });
      this.filterAvailableFacade.changeHotelFilter({
        language: this.currentFilter.language,
        currency: 'VND',
        filterHotelName: this.currentFilter.filterHotelName,
        filterHotelCategories: this.currentFilter.filterHotelCategories,
        filterPrice: this.currentFilter.filterPrice,
        filterStarRating: this.currentFilter.filterStarRating,
        filterGuestRating: this.currentFilter.filterGuestRating,
        filterRoomAmenities: this.currentFilter.filterRoomAmenities,
        filterPropertyAmenities: this.currentFilter.filterPropertyAmenities,
        filterRoomViews: this.currentFilter.filterRoomViews,
        filterThemes: this.currentFilter.filterThemes,
        filterMealPlans: this.currentFilter.filterMealPlans,
        sort: this.currentFilter.sort,
        pageNumber: this.currentFilter.pageNumber
          ? this.currentFilter.pageNumber
          : 0,
        pageSize: this.currentFilter.pageSize ? this.currentFilter.pageSize : 0
      });
    }
    this.filterForm.controls['filterRoomAmenities'].valueChanges.subscribe(
      (valueChange: Array<string>) => {
        if (valueChange) {
          this.filterAvailableFacade.changeFilterRoomAmenities(valueChange);
        }
      }
    );
    this.filterForm.controls['filterPropertyAmenities'].valueChanges.subscribe(
      (valueChange: Array<string>) => {
        if (valueChange) {
          this.filterAvailableFacade.changeFilterPropertyAmenities(valueChange);
        }
      }
    );
    this.filterForm.controls['filterRoomViews'].valueChanges.subscribe(
      (valueChange: Array<string>) => {
        if (valueChange) {
          this.filterAvailableFacade.changeFilterRoomViews(valueChange);
        }
      }
    );
    this.filterForm.controls['filterThemes'].valueChanges.subscribe(
      (valueChange: Array<string>) => {
        if (valueChange) {
          this.filterAvailableFacade.changeFilterThemes(valueChange);
        }
      }
    );
    this.filterForm.controls['filterHotelCategories'].valueChanges.subscribe(
      (valueChange: Array<string>) => {
        if (valueChange) {
          this.filterAvailableFacade.changeFilterHotelCategories(valueChange);
        }
      }
    );
    this.filterForm.controls['filterMealPlans'].valueChanges.subscribe(
      (valueChange: Array<string>) => {
        if (valueChange) {
          this.filterAvailableFacade.changeFilterMealPlans(valueChange);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
