import {
  FilterOptionsAction,
  FilterOptionsActionTypes
} from './filter-options.actions';
import { OtaResultOfFilterOptionsResult } from '@gtd/b2c-client';

export const FILTEROPTIONS_FEATURE_KEY = 'comboVJFilterOptions';

/**
 * Interface for the 'FilterOptions' data used in
 *  - FilterOptionsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FilterOptionsState {
  list: OtaResultOfFilterOptionsResult; // list of FilterOptions; analogous to a sql normalized table
  selectedId?: string | number; // which FilterOptions record has been selected
  loaded: boolean; // has the FilterOptions list been loaded
  error?: any; // last none error (if any)
}

export interface FilterOptionsPartialState {
  readonly [FILTEROPTIONS_FEATURE_KEY]: FilterOptionsState;
}

export const initialState: FilterOptionsState = {
  list: null,
  loaded: false
};

export function filterOptionsReducer(
  state: FilterOptionsState = initialState,
  action: FilterOptionsAction
): FilterOptionsState {
  switch (action.type) {
    case FilterOptionsActionTypes.ComboVJFilterOptionsLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
