import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  REPORTREFERRAL_FEATURE_KEY,
  ReportReferralState
} from './report-referral.reducer';

// Lookup the 'ReportReferral' feature state managed by NgRx
const getReportReferralState = createFeatureSelector<ReportReferralState>(
  REPORTREFERRAL_FEATURE_KEY
);

const getLoaded = createSelector(
  getReportReferralState,
  (state: ReportReferralState) => state.loaded
);
const getError = createSelector(
  getReportReferralState,
  (state: ReportReferralState) => state.error
);

const getAllReportReferral = createSelector(
  getReportReferralState,
  getLoaded,
  (state: ReportReferralState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getReportReferralState,
  (state: ReportReferralState) => state.selectedId
);
const getSelectedReportReferral = createSelector(
  getAllReportReferral,
  getSelectedId,
  (reportReferral, id) => {
    return reportReferral ? Object.assign({}, reportReferral) : undefined;
  }
);

export const reportReferralQuery = {
  getLoaded,
  getError,
  getAllReportReferral,
  getSelectedReportReferral
};
