import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform
} from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Router, UrlSerializer } from '@angular/router';
import { PropertyAvailable } from '@gtd/b2c-client';
import { environment } from '@env/environment';
import { GaTrackingService } from '@gtd/ga-tracking';

@Component({
  selector: 'gtd-hotel-search-result-item',
  templateUrl: './hotel-search-result-item.component.html',
  styleUrls: ['./hotel-search-result-item.component.scss']
})
export class HotelSearchResultItemComponent
  implements OnInit, AfterContentChecked {
  @Input() hotelItems: PropertyAvailable;
  @Input() indexItem: number;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @Input() searchParams: any;
  @Input() searchId: string;
  @Input() isContentPage?: boolean;
  amenitiesWithoutDestination;
  counter = Array;
  config: SwiperConfigInterface;
  priceOneNightPerOneRoom: number;
  priceOneNightPerOneRoomBeforePromo: number;
  targetHref: string;
  utm_source = localStorage.getItem('utm_source');

  constructor(
    private cdref: ChangeDetectorRef,
    private serializer: UrlSerializer,
    private router: Router,
    private _gaTrackingService: GaTrackingService
  ) {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 30,
      lazy: true,
      pagination: true
    };
  }

  ngOnInit() {
    this.countPriceOneNightPerOneRoom();
    this.cdref.detectChanges();
    if (this.hotelItems) {
      this.amenitiesWithoutDestination = this.hotelItems.amenities.filter(
        amenity => amenity.id !== 'rate_description'
      );
    }
    if (environment.appName === 'B2B2C') {
      this.targetHref = '_self';
    } else {
      this.targetHref = '_blank';
    }
  }

  track(eventName: string) {
    this._gaTrackingService.track(eventName);
  }

  viewDetailItem(item) {
    let params = {
      locationCode: this.searchParams.locationCode,
      locationName: this.searchParams.locationName,
      searchType: this.searchParams.searchType,
      roomsString: this.searchParams.roomsString,
      checkIn: this.searchParams.fromDate,
      checkOut: this.searchParams.toDate,
      searchId: this.searchId,
      propertyId: item.propertyId,
      supplier: item.supplier,
      paxInfos: this.searchParams.rooms
    };
    this.router.navigate(['/hotel/detail'], {
      queryParams: {
        params: btoa(encodeURIComponent(JSON.stringify(params)))
      }
    });
  }

  addressClickViewMap(index, latitude, longitude, label) {
    this.changed.emit({
      index: index,
      latitude: latitude,
      longitude: longitude,
      label: label
    });
  }

  countPriceOneNightPerOneRoom() {
    let numberRoom = this.searchParams.roomsArrs.length;
    let numberNight = this.countNumberDate(
      this.searchParams.fromDate,
      this.searchParams.toDate
    );
    this.priceOneNightPerOneRoom =
      Math.ceil(this.hotelItems.basePrice / (numberRoom * numberNight * 1000)) *
      1000;
    this.priceOneNightPerOneRoomBeforePromo =
      Math.ceil(
        this.hotelItems.basePriceBeforePromo / (numberRoom * numberNight * 1000)
      ) * 1000;
  }

  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }

  countNumberDate(departureDate: string, returnDate?: string): number {
    let numbDate = 0;
    if (departureDate && returnDate) {
      numbDate = this.datediff(
        this.parseDate(departureDate),
        this.parseDate(returnDate)
      );
    }
    return numbDate;
  }
  parseDate(str) {
    let mdy = str.split('-');
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  }
  datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }
}

@Pipe({
  name: 'generateLink'
})
export class GenerateLink implements PipeTransform {
  transform(
    value: PropertyAvailable,
    data?: { searchParams: any; searchId: string }
  ): string {
    let params = {
      locationCode: data.searchParams.locationCode,
      locationName: data.searchParams.locationName,
      searchType: data.searchParams.searchType,
      roomsString: data.searchParams.roomsString,
      checkIn: data.searchParams.fromDate,
      checkOut: data.searchParams.toDate,
      searchId: data.searchId,
      propertyId: value.propertyId,
      supplier: value.supplier,
      paxInfos: data.searchParams.rooms
    };

    return (
      '/hotel/detail?params=' + btoa(encodeURIComponent(JSON.stringify(params)))
    );
  }
}
