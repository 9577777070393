/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { BookingCodeRS } from '../model/bookingCodeRS';
import { BookingCodesRS } from '../model/bookingCodesRS';
import { BookingDTO } from '../model/bookingDTO';
import { BookingDiscountRQ } from '../model/bookingDiscountRQ';
import { BookingInfo } from '../model/bookingInfo';
import { BookingNotificationRQ } from '../model/bookingNotificationRQ';
import { BookingNumberRQ } from '../model/bookingNumberRQ';
import { BookingNumbersRQ } from '../model/bookingNumbersRQ';
import { BookingPaymentInfoRQ } from '../model/bookingPaymentInfoRQ';
import { BookingPaymentInfoRS } from '../model/bookingPaymentInfoRS';
import { BookingPaymentTypeDTO } from '../model/bookingPaymentTypeDTO';
import { BookingPnrRS } from '../model/bookingPnrRS';
import { BookingStatusRQ } from '../model/bookingStatusRQ';
import { BookingTravellerRQ } from '../model/bookingTravellerRQ';
import { GTDMFRequest } from '../model/gTDMFRequest';
import { GTDMFResponse } from '../model/gTDMFResponse';
import { GroupBooking } from '../model/groupBooking';
import { OtaResult } from '../model/otaResult';
import { OtaResultOfBookingDiscountInfoDTO } from '../model/otaResultOfBookingDiscountInfoDTO';
import { OtaResultOfBookingPriceInfo } from '../model/otaResultOfBookingPriceInfo';
import { OtaResultOfBookingStatusInfo } from '../model/otaResultOfBookingStatusInfo';
import { OtaResultOfFlexiTvcItemDTO } from '../model/otaResultOfFlexiTvcItemDTO';
import { OtaResultOfListOfBookingStatusInfo } from '../model/otaResultOfListOfBookingStatusInfo';
import { OtaResultOfstring } from '../model/otaResultOfstring';
import { RefundBookingDTO } from '../model/refundBookingDTO';
import { UpdateBookingRQ } from '../model/updateBookingRQ';
import { UpdateBookingStatusRS } from '../model/updateBookingStatusRS';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProductBookingResourceService {

    protected basePath = 'https://localhost:8281';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addFlexiTvcPremium
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFlexiTvcPremiumUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfFlexiTvcItemDTO>;
    public addFlexiTvcPremiumUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfFlexiTvcItemDTO>>;
    public addFlexiTvcPremiumUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfFlexiTvcItemDTO>>;
    public addFlexiTvcPremiumUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling addFlexiTvcPremiumUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfFlexiTvcItemDTO>(`${this.basePath}/api/bookings/add-flexi-tvc-premium`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * addTravellerBooking
     *
     * @param bookingTraveller bookingTraveller
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTravellerBookingUsingPOST(bookingTraveller: BookingTravellerRQ, observe?: 'body', reportProgress?: boolean): Observable<BookingCodeRS>;
    public addTravellerBookingUsingPOST(bookingTraveller: BookingTravellerRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodeRS>>;
    public addTravellerBookingUsingPOST(bookingTraveller: BookingTravellerRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodeRS>>;
    public addTravellerBookingUsingPOST(bookingTraveller: BookingTravellerRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingTraveller === null || bookingTraveller === undefined) {
            throw new Error('Required parameter bookingTraveller was null or undefined when calling addTravellerBookingUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodeRS>(`${this.basePath}/api/bookings/product/add-traveller`,
            bookingTraveller,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkUnconfirmedBookings
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkUnconfirmedBookingsUsingGET(observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkUnconfirmedBookingsUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkUnconfirmedBookingsUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkUnconfirmedBookingsUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/bookings/product/check-unconfirmed-bookings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    downloadPdf(bookingNumber: string, totalAmount?: number): Observable<HttpResponse<Blob>> {

      if (bookingNumber === null || bookingNumber === undefined) {
        throw new Error('Required parameter bookingNumber was null or undefined when calling downloadTicketMaskUsingGET.');
      }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

      if (bookingNumber !== undefined && bookingNumber !== null) {
        queryParameters = queryParameters.set('booking_number', <any>bookingNumber);
      }
      if (totalAmount !== undefined && totalAmount !== null) {
        queryParameters = queryParameters.set('totalAmount', <any>totalAmount);
      }

      let headers = this.defaultHeaders;


      // Chuyển đối tượng HttpHeaders vào yêu cầu HTTP
      return this.httpClient.get(`${this.basePath}/api/products/download-ticket-mask`, { responseType: 'blob', observe: 'response', headers: headers, params: queryParameters, });
    }

    /**
     * downloadTicketMask
     *
     * @param bookingNumber booking_number
     * @param language language
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTicketMaskUsingGET(bookingNumber: string, language?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadTicketMaskUsingGET(bookingNumber: string, language?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadTicketMaskUsingGET(bookingNumber: string, language?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadTicketMaskUsingGET(bookingNumber: string, language?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling downloadTicketMaskUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('booking_number', <any>bookingNumber);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/blob'
        ];

        return this.httpClient.get<string>(`${this.basePath}/api/products/download-ticket-mask`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
            }
        );
    }

    /**
     * findInProgressBooking
     *
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findInProgressBookingUsingPOST(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfBookingStatusInfo>;
    public findInProgressBookingUsingPOST(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfBookingStatusInfo>>;
    public findInProgressBookingUsingPOST(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfBookingStatusInfo>>;
    public findInProgressBookingUsingPOST(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OtaResultOfListOfBookingStatusInfo>(`${this.basePath}/api/bookings/product/inprogress-booking`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * findProcessBookingIssueInvoice
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findProcessBookingIssueInvoiceUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public findProcessBookingIssueInvoiceUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public findProcessBookingIssueInvoiceUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public findProcessBookingIssueInvoiceUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/bookings/list-success-booking`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingDiscountInfo
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingDiscountInfoUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfBookingDiscountInfoDTO>;
    public getBookingDiscountInfoUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfBookingDiscountInfoDTO>>;
    public getBookingDiscountInfoUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfBookingDiscountInfoDTO>>;
    public getBookingDiscountInfoUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getBookingDiscountInfoUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfBookingDiscountInfoDTO>(`${this.basePath}/api/bookings/product/discount/${encodeURIComponent(String(bookingNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingPaymentInfo
     *
     * @param bookingPaymentInfoRQ bookingPaymentInfoRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingPaymentInfoUsingPOST(bookingPaymentInfoRQ: BookingPaymentInfoRQ, observe?: 'body', reportProgress?: boolean): Observable<BookingPaymentInfoRS>;
    public getBookingPaymentInfoUsingPOST(bookingPaymentInfoRQ: BookingPaymentInfoRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingPaymentInfoRS>>;
    public getBookingPaymentInfoUsingPOST(bookingPaymentInfoRQ: BookingPaymentInfoRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingPaymentInfoRS>>;
    public getBookingPaymentInfoUsingPOST(bookingPaymentInfoRQ: BookingPaymentInfoRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingPaymentInfoRQ === null || bookingPaymentInfoRQ === undefined) {
            throw new Error('Required parameter bookingPaymentInfoRQ was null or undefined when calling getBookingPaymentInfoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingPaymentInfoRS>(`${this.basePath}/api/bookings/payment-info`,
            bookingPaymentInfoRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingPriceInfo
     *
     * @param bookingNumberRQ bookingNumberRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingPriceInfoUsingPOST(bookingNumberRQ: BookingNumberRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfBookingPriceInfo>;
    public getBookingPriceInfoUsingPOST(bookingNumberRQ: BookingNumberRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfBookingPriceInfo>>;
    public getBookingPriceInfoUsingPOST(bookingNumberRQ: BookingNumberRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfBookingPriceInfo>>;
    public getBookingPriceInfoUsingPOST(bookingNumberRQ: BookingNumberRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumberRQ === null || bookingNumberRQ === undefined) {
            throw new Error('Required parameter bookingNumberRQ was null or undefined when calling getBookingPriceInfoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfBookingPriceInfo>(`${this.basePath}/api/bookings/pricing-info`,
            bookingNumberRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingStatusInfo
     *
     * @param bookingNumberRQ bookingNumberRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingStatusInfoUsingPOST(bookingNumberRQ: BookingNumberRQ, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfBookingStatusInfo>;
    public getBookingStatusInfoUsingPOST(bookingNumberRQ: BookingNumberRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfBookingStatusInfo>>;
    public getBookingStatusInfoUsingPOST(bookingNumberRQ: BookingNumberRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfBookingStatusInfo>>;
    public getBookingStatusInfoUsingPOST(bookingNumberRQ: BookingNumberRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumberRQ === null || bookingNumberRQ === undefined) {
            throw new Error('Required parameter bookingNumberRQ was null or undefined when calling getBookingStatusInfoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfBookingStatusInfo>(`${this.basePath}/api/bookings/booking-status-info`,
            bookingNumberRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getListBookingByAgencyCodes
     *
     * @param agencyCodes agencyCodes
     * @param paymentStatuses paymentStatuses
     * @param bookingIssuedStatuses bookingIssuedStatuses
     * @param statuses statuses
     * @param fromDate 6/6/2021
     * @param toDate 6/6/2021
     * @param sort sort
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListBookingByAgencyCodesUsingGET(agencyCodes: Array<string>, paymentStatuses?: Array<'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'PENDING'>, bookingIssuedStatuses?: Array<'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED'>, statuses?: Array<'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'TICKET_ON_PROCESS' | 'INACTIVE'>, fromDate?: Date, toDate?: Date, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<BookingInfo>>;
    public getListBookingByAgencyCodesUsingGET(agencyCodes: Array<string>, paymentStatuses?: Array<'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'PENDING'>, bookingIssuedStatuses?: Array<'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED'>, statuses?: Array<'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'TICKET_ON_PROCESS' | 'INACTIVE'>, fromDate?: Date, toDate?: Date, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookingInfo>>>;
    public getListBookingByAgencyCodesUsingGET(agencyCodes: Array<string>, paymentStatuses?: Array<'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'PENDING'>, bookingIssuedStatuses?: Array<'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED'>, statuses?: Array<'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'TICKET_ON_PROCESS' | 'INACTIVE'>, fromDate?: Date, toDate?: Date, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookingInfo>>>;
    public getListBookingByAgencyCodesUsingGET(agencyCodes: Array<string>, paymentStatuses?: Array<'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'PENDING'>, bookingIssuedStatuses?: Array<'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED'>, statuses?: Array<'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'TICKET_ON_PROCESS' | 'INACTIVE'>, fromDate?: Date, toDate?: Date, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCodes === null || agencyCodes === undefined) {
            throw new Error('Required parameter agencyCodes was null or undefined when calling getListBookingByAgencyCodesUsingGET.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCodes) {
            agencyCodes.forEach((element) => {
                queryParameters = queryParameters.append('agencyCodes', <any>element);
            })
        }
        if (paymentStatuses) {
            paymentStatuses.forEach((element) => {
                queryParameters = queryParameters.append('paymentStatuses', <any>element);
            })
        }
        if (bookingIssuedStatuses) {
            bookingIssuedStatuses.forEach((element) => {
                queryParameters = queryParameters.append('bookingIssuedStatuses', <any>element);
            })
        }
        if (statuses) {
            statuses.forEach((element) => {
                queryParameters = queryParameters.append('statuses', <any>element);
            })
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate.toISOString());
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate.toISOString());
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BookingInfo>>(`${this.basePath}/api/bookings/booking-by-agency-codes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getListGroupBookingBybookingNumbers
     *
     * @param bookingNumbers bookingNumbers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListGroupBookingBybookingNumbersUsingGET(bookingNumbers: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<GroupBooking>>;
    public getListGroupBookingBybookingNumbersUsingGET(bookingNumbers: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GroupBooking>>>;
    public getListGroupBookingBybookingNumbersUsingGET(bookingNumbers: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GroupBooking>>>;
    public getListGroupBookingBybookingNumbersUsingGET(bookingNumbers: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumbers === null || bookingNumbers === undefined) {
            throw new Error('Required parameter bookingNumbers was null or undefined when calling getListGroupBookingBybookingNumbersUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumbers) {
            bookingNumbers.forEach((element) => {
                queryParameters = queryParameters.append('bookingNumbers', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<GroupBooking>>(`${this.basePath}/api/bookings/groupbookings-by-booking-numbers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * issueInvoiceByListBookingNumber
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public issueInvoiceByListBookingNumberUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResult>;
    public issueInvoiceByListBookingNumberUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResult>>;
    public issueInvoiceByListBookingNumberUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResult>>;
    public issueInvoiceByListBookingNumberUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling issueInvoiceByListBookingNumberUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResult>(`${this.basePath}/api/bookings/issue-invoice-by-booking`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * loadBookingContent
     *
     * @param bookingNumbersRQ bookingNumbersRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadBookingContentUsingPOST(bookingNumbersRQ: BookingNumbersRQ, observe?: 'body', reportProgress?: boolean): Observable<BookingCodesRS>;
    public loadBookingContentUsingPOST(bookingNumbersRQ: BookingNumbersRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodesRS>>;
    public loadBookingContentUsingPOST(bookingNumbersRQ: BookingNumbersRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodesRS>>;
    public loadBookingContentUsingPOST(bookingNumbersRQ: BookingNumbersRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumbersRQ === null || bookingNumbersRQ === undefined) {
            throw new Error('Required parameter bookingNumbersRQ was null or undefined when calling loadBookingContentUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodesRS>(`${this.basePath}/api/bookings/product/load-booking`,
            bookingNumbersRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * refundBooking
     *
     * @param refundBookingDTO refundBookingDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refundBookingUsingPOST(refundBookingDTO: RefundBookingDTO, observe?: 'body', reportProgress?: boolean): Observable<BookingCodeRS>;
    public refundBookingUsingPOST(refundBookingDTO: RefundBookingDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodeRS>>;
    public refundBookingUsingPOST(refundBookingDTO: RefundBookingDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodeRS>>;
    public refundBookingUsingPOST(refundBookingDTO: RefundBookingDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (refundBookingDTO === null || refundBookingDTO === undefined) {
            throw new Error('Required parameter refundBookingDTO was null or undefined when calling refundBookingUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodeRS>(`${this.basePath}/api/bookings/refund`,
            refundBookingDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * scheduleProcessIssueInvoice
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleProcessIssueInvoiceUsingGET(observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public scheduleProcessIssueInvoiceUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public scheduleProcessIssueInvoiceUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public scheduleProcessIssueInvoiceUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfstring>(`${this.basePath}/api/bookings/schedule-process-issue-invoice`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sendBookingNotification
     *
     * @param bookingNotificationRQ bookingNotificationRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendBookingNotificationUsingPOST(bookingNotificationRQ: BookingNotificationRQ, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public sendBookingNotificationUsingPOST(bookingNotificationRQ: BookingNotificationRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public sendBookingNotificationUsingPOST(bookingNotificationRQ: BookingNotificationRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public sendBookingNotificationUsingPOST(bookingNotificationRQ: BookingNotificationRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNotificationRQ === null || bookingNotificationRQ === undefined) {
            throw new Error('Required parameter bookingNotificationRQ was null or undefined when calling sendBookingNotificationUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/api/bookings/product/send-email`,
            bookingNotificationRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sendInvoiceEmail
     *
     * @param subject subject
     * @param bookingNumber bookingNumber
     * @param businessName businessName
     * @param businessTaxCode businessTaxCode
     * @param businessAddress businessAddress
     * @param custommerEmail custommerEmail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendInvoiceEmailUsingGET(subject: string, bookingNumber: string, businessName: string, businessTaxCode: string, businessAddress: string, custommerEmail: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public sendInvoiceEmailUsingGET(subject: string, bookingNumber: string, businessName: string, businessTaxCode: string, businessAddress: string, custommerEmail: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public sendInvoiceEmailUsingGET(subject: string, bookingNumber: string, businessName: string, businessTaxCode: string, businessAddress: string, custommerEmail: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public sendInvoiceEmailUsingGET(subject: string, bookingNumber: string, businessName: string, businessTaxCode: string, businessAddress: string, custommerEmail: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (subject === null || subject === undefined) {
            throw new Error('Required parameter subject was null or undefined when calling sendInvoiceEmailUsingGET.');
        }

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling sendInvoiceEmailUsingGET.');
        }

        if (businessName === null || businessName === undefined) {
            throw new Error('Required parameter businessName was null or undefined when calling sendInvoiceEmailUsingGET.');
        }

        if (businessTaxCode === null || businessTaxCode === undefined) {
            throw new Error('Required parameter businessTaxCode was null or undefined when calling sendInvoiceEmailUsingGET.');
        }

        if (businessAddress === null || businessAddress === undefined) {
            throw new Error('Required parameter businessAddress was null or undefined when calling sendInvoiceEmailUsingGET.');
        }

        if (custommerEmail === null || custommerEmail === undefined) {
            throw new Error('Required parameter custommerEmail was null or undefined when calling sendInvoiceEmailUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (subject !== undefined && subject !== null) {
            queryParameters = queryParameters.set('subject', <any>subject);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (businessName !== undefined && businessName !== null) {
            queryParameters = queryParameters.set('businessName', <any>businessName);
        }
        if (businessTaxCode !== undefined && businessTaxCode !== null) {
            queryParameters = queryParameters.set('businessTaxCode', <any>businessTaxCode);
        }
        if (businessAddress !== undefined && businessAddress !== null) {
            queryParameters = queryParameters.set('businessAddress', <any>businessAddress);
        }
        if (custommerEmail !== undefined && custommerEmail !== null) {
            queryParameters = queryParameters.set('custommerEmail', <any>custommerEmail);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfstring>(`${this.basePath}/api/bookings/send-invoice-email`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBookingDiscount
     *
     * @param bookingDiscount bookingDiscount
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingDiscountUsingPOST(bookingDiscount: BookingDiscountRQ, observe?: 'body', reportProgress?: boolean): Observable<BookingCodeRS>;
    public updateBookingDiscountUsingPOST(bookingDiscount: BookingDiscountRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingCodeRS>>;
    public updateBookingDiscountUsingPOST(bookingDiscount: BookingDiscountRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingCodeRS>>;
    public updateBookingDiscountUsingPOST(bookingDiscount: BookingDiscountRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingDiscount === null || bookingDiscount === undefined) {
            throw new Error('Required parameter bookingDiscount was null or undefined when calling updateBookingDiscountUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BookingCodeRS>(`${this.basePath}/api/bookings/product/discount`,
            bookingDiscount,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBookingPaymentStatus
     *
     * @param bookingPaymentTypeDTO bookingPaymentTypeDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingPaymentStatusUsingPOST(bookingPaymentTypeDTO: BookingPaymentTypeDTO, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfBookingDiscountInfoDTO>;
    public updateBookingPaymentStatusUsingPOST(bookingPaymentTypeDTO: BookingPaymentTypeDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfBookingDiscountInfoDTO>>;
    public updateBookingPaymentStatusUsingPOST(bookingPaymentTypeDTO: BookingPaymentTypeDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfBookingDiscountInfoDTO>>;
    public updateBookingPaymentStatusUsingPOST(bookingPaymentTypeDTO: BookingPaymentTypeDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingPaymentTypeDTO === null || bookingPaymentTypeDTO === undefined) {
            throw new Error('Required parameter bookingPaymentTypeDTO was null or undefined when calling updateBookingPaymentStatusUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OtaResultOfBookingDiscountInfoDTO>(`${this.basePath}/api/bookings/product/payment-status`,
            bookingPaymentTypeDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBookingStatusByRefNo
     *
     * @param gTDMFRequest GTDMFRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingStatusByRefNoUsingPOST(gTDMFRequest: GTDMFRequest, observe?: 'body', reportProgress?: boolean): Observable<GTDMFResponse>;
    public updateBookingStatusByRefNoUsingPOST(gTDMFRequest: GTDMFRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GTDMFResponse>>;
    public updateBookingStatusByRefNoUsingPOST(gTDMFRequest: GTDMFRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GTDMFResponse>>;
    public updateBookingStatusByRefNoUsingPOST(gTDMFRequest: GTDMFRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (gTDMFRequest === null || gTDMFRequest === undefined) {
            throw new Error('Required parameter gTDMFRequest was null or undefined when calling updateBookingStatusByRefNoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GTDMFResponse>(`${this.basePath}/api/bookings/booking-update-status-by-refNo`,
            gTDMFRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBookingStatus
     *
     * @param bookingStatus bookingStatus
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingStatusUsingPOST(bookingStatus: BookingStatusRQ, observe?: 'body', reportProgress?: boolean): Observable<UpdateBookingStatusRS>;
    public updateBookingStatusUsingPOST(bookingStatus: BookingStatusRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateBookingStatusRS>>;
    public updateBookingStatusUsingPOST(bookingStatus: BookingStatusRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateBookingStatusRS>>;
    public updateBookingStatusUsingPOST(bookingStatus: BookingStatusRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingStatus === null || bookingStatus === undefined) {
            throw new Error('Required parameter bookingStatus was null or undefined when calling updateBookingStatusUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UpdateBookingStatusRS>(`${this.basePath}/api/bookings/product/update-status`,
            bookingStatus,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateBooking
     *
     * @param updateBookingRQ updateBookingRQ
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBookingUsingPUT1(updateBookingRQ: UpdateBookingRQ, observe?: 'body', reportProgress?: boolean): Observable<BookingPnrRS>;
    public updateBookingUsingPUT1(updateBookingRQ: UpdateBookingRQ, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingPnrRS>>;
    public updateBookingUsingPUT1(updateBookingRQ: UpdateBookingRQ, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingPnrRS>>;
    public updateBookingUsingPUT1(updateBookingRQ: UpdateBookingRQ, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateBookingRQ === null || updateBookingRQ === undefined) {
            throw new Error('Required parameter updateBookingRQ was null or undefined when calling updateBookingUsingPUT1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BookingPnrRS>(`${this.basePath}/api/bookings/product/updateBooking`,
            updateBookingRQ,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateConfirmedBookingCuongphu
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateConfirmedBookingCuongphuUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public updateConfirmedBookingCuongphuUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public updateConfirmedBookingCuongphuUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public updateConfirmedBookingCuongphuUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling updateConfirmedBookingCuongphuUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/bookings/product/update-confirmed-booking-cuongphu`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateEticketBookingMapping
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEticketBookingMappingUsingPUT(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateEticketBookingMappingUsingPUT(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateEticketBookingMappingUsingPUT(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateEticketBookingMappingUsingPUT(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.put<any>(`${this.basePath}/api/fix-booking-eticket-mapping`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateListBookingWithApprovalInvoiceId
     *
     * @param bookingNumbers bookingNumbers
     * @param approvalInvoiceId approvalInvoiceId
     * @param invoiceStatus invoiceStatus
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateListBookingWithApprovalInvoiceIdUsingGET(bookingNumbers: Array<string>, approvalInvoiceId?: number, invoiceStatus?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BookingDTO>>;
    public updateListBookingWithApprovalInvoiceIdUsingGET(bookingNumbers: Array<string>, approvalInvoiceId?: number, invoiceStatus?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookingDTO>>>;
    public updateListBookingWithApprovalInvoiceIdUsingGET(bookingNumbers: Array<string>, approvalInvoiceId?: number, invoiceStatus?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookingDTO>>>;
    public updateListBookingWithApprovalInvoiceIdUsingGET(bookingNumbers: Array<string>, approvalInvoiceId?: number, invoiceStatus?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumbers === null || bookingNumbers === undefined) {
            throw new Error('Required parameter bookingNumbers was null or undefined when calling updateListBookingWithApprovalInvoiceIdUsingGET.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumbers) {
            bookingNumbers.forEach((element) => {
                queryParameters = queryParameters.append('bookingNumbers', <any>element);
            })
        }
        if (approvalInvoiceId !== undefined && approvalInvoiceId !== null) {
            queryParameters = queryParameters.set('approvalInvoiceId', <any>approvalInvoiceId);
        }
        if (invoiceStatus !== undefined && invoiceStatus !== null) {
            queryParameters = queryParameters.set('invoiceStatus', <any>invoiceStatus);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BookingDTO>>(`${this.basePath}/api/bookings/booking-update-by-approval-invoice-id`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateTopBooking
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTopBookingUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public updateTopBookingUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public updateTopBookingUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public updateTopBookingUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling updateTopBookingUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/api/bookings/product/update-top-booking`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
