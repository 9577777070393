import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthB2bComponent } from './auth-b2b/auth-b2b.component';
import { LoginComponent } from './auth-b2b/login/login.component';
import { RegisterAgentComponent } from './auth-b2b/register-agent/register-agent.component';
import { RequestPasswordComponent } from './auth-b2b/request-password/request-password.component';
import { RegisterCaComponent } from './auth-b2b/register-ca/register-ca.component';
import { RegisterComponent } from './auth-b2b/register/register.component';

const routes: Routes = [
  {
    path: '',
    component: AuthB2bComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'registers',
        component: RegisterComponent
      },
      {
        path: 'register-agent',
        component: RegisterAgentComponent
      },
      {
        path: 'register-agent/invite_code/:inviteCode',
        component: RegisterAgentComponent
      },
      {
        path: 'register-ca',
        component: RegisterCaComponent
      },
      {
        path: 'request-password',
        component: RequestPasswordComponent
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthB2bModuleRouting {}
