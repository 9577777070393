import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFormComponent } from './search-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PassengerOptionsModule } from '@gtd/hotels/ui/passenger-options';
import { StarOptionsModule } from '@gtd/hotels/ui/star-options';
import { DestinationAutocompleteModule } from '@gtd/hotels/ui/destination-autocomplete';
import { GButtonModule } from '@gotadi/design-system';
import { DatepickerModule } from '@gtd/hotels/ui/datepicker';
import { DetailPassengersModule } from '@gtd/hotels/ui/detail-passengers';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DetailPassengersModule,
        StarOptionsModule,
        DestinationAutocompleteModule,
        DatepickerModule,
        GButtonModule,
        TranslateModule
    ],
  declarations: [SearchFormComponent],
  exports: [SearchFormComponent]
})
export class SearchFormModule {}
