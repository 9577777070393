import { Injectable } from '@angular/core';
import {AirportDTO} from "@gtd/meta-client";

@Injectable({
  providedIn: 'root'
})
export class FlightSearchService {

  constructor() { }

  getAllLocalStorageLocation(type: string): AirportDTO[] {
    let localStorageData = localStorage.getItem(type);
    if(localStorageData) {
      return JSON.parse(localStorageData);
    }
    return null;
  }
}
