/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';


export interface SearchKeywordElement {
    address?: Address;
    name?: string;
    propertyCount?: number;
    searchCode?: string;
    searchType?: string;
    supplier?: SearchKeywordElement.SupplierEnum;
    tags?: Array<string>;
}
export namespace SearchKeywordElement {
    export type SupplierEnum = 'GOTADI' | 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL';
    export const SupplierEnum = {
        GOTADI: 'GOTADI' as SupplierEnum,
        EXPEDIA: 'EXPEDIA' as SupplierEnum,
        AXISROOM: 'AXISROOM' as SupplierEnum,
        BEDLINKER: 'BEDLINKER' as SupplierEnum,
        VINPEARL: 'VINPEARL' as SupplierEnum,
        AGODA: 'AGODA' as SupplierEnum
    };
}
