import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { LowestPriceFlightsFacade } from '../flight-search/flight-lowest-price/+state/lowest-price-flights.facade';
import { slideInVertical, SortAttributeEnum, SortTypeEnum } from '@gtd/helpers';
import { FlightFilterFacade } from '../flight-search/flight-filter/+state/flight-filter.facade';
import {environment} from "@env/environment";

export interface SortInterface {
  attribute: SortAttributeEnum;
  type: SortTypeEnum;
  label: string;
}

@Component({
  selector: 'gtd-flight-sort-panel',
  templateUrl: './flight-sort-panel.component.html',
  styleUrls: ['./flight-sort-panel.component.scss'],
  encapsulation: environment.appName === 'B2B2C'? ViewEncapsulation.None: ViewEncapsulation.Emulated,
  animations: [slideInVertical]
})
export class FlightSortPanelComponent implements OnInit {
  sortList: SortInterface[] = [
    {
      attribute: SortAttributeEnum.DEPARTURE_DATE,
      type: SortTypeEnum.ASC,
      label: 'DEPARTURE_DATE_ASC'
    },
    {
      attribute: SortAttributeEnum.DEPARTURE_DATE,
      type: SortTypeEnum.DESC,
      label: 'DEPARTURE_DATE_DESC'
    },
    {
      attribute: SortAttributeEnum.PRICE,
      type: SortTypeEnum.DESC,
      label: 'PRICE_DESC'
    },
    {
      attribute: SortAttributeEnum.PRICE,
      type: SortTypeEnum.ASC,
      label: 'PRICE_ASC'
    },
    {
      attribute: SortAttributeEnum.ARRIVAL_DATE,
      type: SortTypeEnum.ASC,
      label: 'ARRIVAL_DATE_ASC'
    },
    {
      attribute: SortAttributeEnum.ARRIVAL_DATE,
      type: SortTypeEnum.DESC,
      label: 'ARRIVAL_DATE_DESC'
    },
    {
      attribute: SortAttributeEnum.DURATION,
      type: SortTypeEnum.ASC,
      label: 'DURATION_ASC' //Thoi gian bay ngan
    },
    {
      attribute: SortAttributeEnum.DURATION,
      type: SortTypeEnum.DESC,
      label: 'DURATION_DESC' // Thoi gian bay dai
    }
  ];

  selected = this.sortList[0];
  loaded$ = this.lowestPriceFlightsFacade.loaded$;
  currentSort$a = this.flightFilterFacade.currentSort$;

  binding = SortTypeEnum;

  constructor(
    private lowestPriceFlightsFacade: LowestPriceFlightsFacade,
    private flightFilterFacade: FlightFilterFacade
  ) {}

  ngOnInit() {}

  sortClick(selectedKey: SortInterface) {
    this.selected = selectedKey;
    this.flightFilterFacade.changeSort([
      `${this.selected.attribute},${this.selected.type}`
    ]);
  }

  sortDepartureClick() {}

  sortOnAirClick() {}

  sortPriceClick() {}
}
