import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberSectionComponent } from './member-section.component';
import {MatButtonModule} from "@angular/material";
import {FlexLayoutModule} from "@angular/flex-layout";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule
  ],
  declarations: [MemberSectionComponent],
  exports: [MemberSectionComponent]
})
export class MemberSectionModule {}
