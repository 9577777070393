import { OtaResultOfTourActivityAvailableIntroPageDTO } from '@gtd/b2c-client';
import { SortAttributeEnum } from '@gtd/helpers';
import { Action } from '@ngrx/store';
import { Entity } from './tour-search-activity-available.reducer';

export enum TourSearchActivityAvailableActionTypes {
  LoadTourSearchActivityAvailable = '[TourSearchActivityAvailable] Load TourSearchActivityAvailable',
  ResetTourSearchActivityAvailable = '[TourSearchActivityAvailable] Reset TourSearchActivityAvailable',
  TourSearchActivityAvailableLoaded = '[TourSearchActivityAvailable] TourSearchActivityAvailable Loaded',
  TourSearchActivityAvailableLoadError = '[TourSearchActivityAvailable] TourSearchActivityAvailable Load Error'
}

export class LoadTourSearchActivityAvailable implements Action {
  readonly type =
    TourSearchActivityAvailableActionTypes.LoadTourSearchActivityAvailable;
  constructor(public payload: TourSearchActivityAvailableRQ) {}
}
export class ResetTourSearchActivityAvailable implements Action {
  readonly type =
    TourSearchActivityAvailableActionTypes.ResetTourSearchActivityAvailable;
}

export class TourSearchActivityAvailableLoadError implements Action {
  readonly type =
    TourSearchActivityAvailableActionTypes.TourSearchActivityAvailableLoadError;
  constructor(public payload: any) {}
}

export class TourSearchActivityAvailableLoaded implements Action {
  readonly type =
    TourSearchActivityAvailableActionTypes.TourSearchActivityAvailableLoaded;
  constructor(public payload: OtaResultOfTourActivityAvailableIntroPageDTO) {}
}

export type TourSearchActivityAvailableAction =
  | LoadTourSearchActivityAvailable
  | ResetTourSearchActivityAvailable
  | TourSearchActivityAvailableLoaded
  | TourSearchActivityAvailableLoadError;

export const fromTourSearchActivityAvailableActions = {
  LoadTourSearchActivityAvailable,
  ResetTourSearchActivityAvailable,
  TourSearchActivityAvailableLoaded,
  TourSearchActivityAvailableLoadError
};

export interface TourSearchActivityAvailableRQ {
  searchCode: string[];
  searchType:
    | 'AUTO'
    | 'DESTINATION'
    | 'CATEGORY'
    | 'ACTIVITY'
    | 'ALL'
    | 'KEYWORD';
  language: 'vi' | 'en';
  saleChannel: string;
  categoryIds?: Array<string>;
  themeIds?: Array<string>;
  amenityIds?: Array<string>;
  sortName?: string;
  sortOrder?: 'ASC' | 'DESC';
  pageNumber?: number;
  pageSize?: number;
  zoneId?: string;
}
