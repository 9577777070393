import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyAllRate } from '@gtd/b2c-client';
import { MatDialog } from '@angular/material';
import { MapPanelDetailComponent } from '../map-panel-detail/map-panel-detail.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'gtd-hotel-information',
  templateUrl: './hotel-information.component.html',
  styleUrls: ['./hotel-information.component.scss']
})
export class HotelInformationComponent implements OnInit {
  @Input() hotelDetail: PropertyAllRate;
  @Output() scrollToSection: EventEmitter<any> = new EventEmitter<any>();
  counter = Array;
  numbItemAnimes: number;
  aroundArea: any;
  aroundAreaArr: string[];
  constructor(
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.numbItemAnimes = this.deviceService.isDesktop() ? 11 : 4;

    this.aroundArea = this.hotelDetail.descriptions
      ? this.hotelDetail.descriptions.find(
          description => description.name === 'attractions'
        )
      : '';
    if (this.aroundArea) {
      this.aroundAreaArr = this.aroundArea.value.split('<br />');
    }
  }

  viewMapPanelDetail() {
    this.dialog.open(MapPanelDetailComponent, {
      data: {
        hotelDetail: this.hotelDetail
      },
      position: {
        top: '0px',
        left: '0px'
      },
      panelClass: 'popupMapPanel'
    });
  }
  sectionSelected(id: string) {
    this.scrollToSection.emit(id);
  }
  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }
}
