import { Action } from '@ngrx/store';
import {
  CreateDraftBookingHotelPayload,
  OtaResultOfBooking
} from '@gtd/b2c-client';

export enum HotelCreateDraftBookingActionTypes {
  ComboVJLoadHotelCreateDraftBooking = '[ComboVJ_HotelCreateDraftBooking] Load HotelCreateDraftBooking',
  ComboVJHotelCreateDraftBookingLoaded = '[ComboVJ_HotelCreateDraftBooking] HotelCreateDraftBooking Loaded',
  ComboVJHotelCreateDraftBookingLoadError = '[ComboVJ_HotelCreateDraftBooking] HotelCreateDraftBooking Load Error'
}

export class ComboVJLoadHotelCreateDraftBooking implements Action {
  readonly type =
    HotelCreateDraftBookingActionTypes.ComboVJLoadHotelCreateDraftBooking;
  constructor(public payload: CreateDraftBookingHotelPayload) {}
}

export class ComboVJHotelCreateDraftBookingLoadError implements Action {
  readonly type =
    HotelCreateDraftBookingActionTypes.ComboVJHotelCreateDraftBookingLoadError;
  constructor(public payload: any) {}
}

export class ComboVJHotelCreateDraftBookingLoaded implements Action {
  readonly type =
    HotelCreateDraftBookingActionTypes.ComboVJHotelCreateDraftBookingLoaded;
  constructor(public payload: OtaResultOfBooking) {}
}

export type HotelCreateDraftBookingAction =
  | ComboVJLoadHotelCreateDraftBooking
  | ComboVJHotelCreateDraftBookingLoaded
  | ComboVJHotelCreateDraftBookingLoadError;

export const fromHotelCreateDraftBookingActions = {
  ComboVJLoadHotelCreateDraftBooking,
  ComboVJHotelCreateDraftBookingLoaded,
  ComboVJHotelCreateDraftBookingLoadError
};
