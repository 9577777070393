import { DatepickerAction, DatepickerActionTypes } from './datepicker.actions';

export const DATEPICKER_FEATURE_KEY = 'datepicker';

/**
 * Interface for the 'Datepicker' data used in
 *  - DatepickerState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface DatePicker {
  fromDate: Date,
  toDate?: Date,
  type: 'search'|'filter'
}

export interface DatepickerState {
  result: DatePicker; // list of Datepicker; analogous to a sql normalized table
  selectedId?: string | number; // which Datepicker record has been selected
  loaded: boolean; // has the Datepicker list been loaded
  error?: any; // last none error (if any)
}

export interface DatepickerPartialState {
  readonly [DATEPICKER_FEATURE_KEY]: DatepickerState;
}

export const initialState: DatepickerState = {
  result: null,
  loaded: false
};

export function reducer(
  state: DatepickerState = initialState,
  action: DatepickerAction
): DatepickerState {
  switch (action.type) {
    case DatepickerActionTypes.DatepickerLoaded: {
      state = {
        ...state,
        result: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
