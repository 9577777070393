import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TOURADDBOOKINGTRAVELLER_FEATURE_KEY,
  TourAddBookingTravellerState
} from './tour-add-booking-traveller.reducer';

// Lookup the 'TourAddBookingTraveller' feature state managed by NgRx
const getTourAddBookingTravellerState = createFeatureSelector<
  TourAddBookingTravellerState
>(TOURADDBOOKINGTRAVELLER_FEATURE_KEY);

const getLoaded = createSelector(
  getTourAddBookingTravellerState,
  (state: TourAddBookingTravellerState) => state.loaded
);
const getError = createSelector(
  getTourAddBookingTravellerState,
  (state: TourAddBookingTravellerState) => state.error
);

const getAllTourAddBookingTraveller = createSelector(
  getTourAddBookingTravellerState,
  getLoaded,
  (state: TourAddBookingTravellerState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getTourAddBookingTravellerState,
  (state: TourAddBookingTravellerState) => state.selectedId
);
const getSelectedTourAddBookingTraveller = createSelector(
  getAllTourAddBookingTraveller,
  getSelectedId,
  (tourAddBookingTraveller, id) => {
    const result = tourAddBookingTraveller;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const tourAddBookingTravellerQuery = {
  getLoaded,
  getError,
  getAllTourAddBookingTraveller,
  getSelectedTourAddBookingTraveller
};
