import { OtaResultOfListOfTourUnitRatePlanDTO } from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './tour-unit-price.reducer';

export enum TourUnitPriceActionTypes {
  LoadTourUnitPrice = '[TourUnitPrice] Load TourUnitPrice',
  TourUnitPriceLoaded = '[TourUnitPrice] TourUnitPrice Loaded',
  TourUnitPriceLoadError = '[TourUnitPrice] TourUnitPrice Load Error'
}

export class LoadTourUnitPrice implements Action {
  readonly type = TourUnitPriceActionTypes.LoadTourUnitPrice;
  constructor(public payload: TourUnitPriceRQ) {}
}

export class TourUnitPriceLoadError implements Action {
  readonly type = TourUnitPriceActionTypes.TourUnitPriceLoadError;
  constructor(public payload: any) {}
}

export class TourUnitPriceLoaded implements Action {
  readonly type = TourUnitPriceActionTypes.TourUnitPriceLoaded;
  constructor(public payload: OtaResultOfListOfTourUnitRatePlanDTO) {}
}

export type TourUnitPriceAction =
  | LoadTourUnitPrice
  | TourUnitPriceLoaded
  | TourUnitPriceLoadError;

export const fromTourUnitPriceActions = {
  LoadTourUnitPrice,
  TourUnitPriceLoaded,
  TourUnitPriceLoadError
};

export interface TourUnitPriceRQ {
  activityId: number;
  language: 'vi' | 'en';
}
