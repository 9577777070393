/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FareBreakdown } from './fareBreakdown';
import { ItinTotalFare } from './itinTotalFare';


export interface AirItineraryPricingInfo { 
    adultFare?: FareBreakdown;
    childFare?: FareBreakdown;
    divideInPartyIndicator?: boolean;
    fareInfoReferences?: Array<string>;
    fareSourceCode?: string;
    fareType?: AirItineraryPricingInfo.FareTypeEnum;
    infantFare?: FareBreakdown;
    itinTotalFare?: ItinTotalFare;
}
export namespace AirItineraryPricingInfo {
    export type FareTypeEnum = 'PUBLIC' | 'PRIVATE' | 'WEB';
    export const FareTypeEnum = {
        PUBLIC: 'PUBLIC' as FareTypeEnum,
        PRIVATE: 'PRIVATE' as FareTypeEnum,
        WEB: 'WEB' as FareTypeEnum
    };
}
