import { Action } from '@ngrx/store';

export enum TourActivityBookingActionTypes {
  SetPaxInfos = '[TourActivityBooking] Set PaxInfos',
  SetDate = '[TourActivityBooking] Set Date',
  SetRatePlanId = '[TourActivityBooking] Set RatePlanId',
  SetActivityId = '[TourActivityBooking] Set ActivityId',
  ResetRatePlanId = '[TourActivityBooking] Reset RatePlanId',
  ResetActivityId = '[TourActivityBooking] Reset ActivityId',
  ResetTourActivity = '[TourActivityBooking] Reset TourActivity',
  ResetPaxInfos = '[TourActivityBooking] Reset PaxInfos'
}

export class SetPaxInfos implements Action {
  readonly type = TourActivityBookingActionTypes.SetPaxInfos;
  constructor(public payload: PaxInfo[]) {}
}

export class SetDate implements Action {
  readonly type = TourActivityBookingActionTypes.SetDate;
  constructor(public payload: Date) {}
}

export class SetRatePlanId implements Action {
  readonly type = TourActivityBookingActionTypes.SetRatePlanId;
  constructor(public payload: number) {}
}

export class SetActivityId implements Action {
  readonly type = TourActivityBookingActionTypes.SetActivityId;
  constructor(public payload: number) {}
}

export class ResetActivityId implements Action {
  readonly type = TourActivityBookingActionTypes.ResetActivityId;
}

export class ResetRatePlanId implements Action {
  readonly type = TourActivityBookingActionTypes.ResetRatePlanId;
}

export class ResetPaxInfos implements Action {
  readonly type = TourActivityBookingActionTypes.ResetPaxInfos;
}

export class ResetTourActivity implements Action {
  readonly type = TourActivityBookingActionTypes.ResetTourActivity;
}

export type TourActivityBookingAction =
  | SetPaxInfos
  | SetDate
  | SetRatePlanId
  | SetActivityId
  | ResetActivityId
  | ResetRatePlanId
  | ResetTourActivity
  | ResetPaxInfos;

export const fromTourActivityBookingActions = {
  SetPaxInfos,
  SetDate,
  SetRatePlanId,
  SetActivityId,
  ResetActivityId,
  ResetRatePlanId,
  ResetTourActivity,
  ResetPaxInfos
};

export interface PaxInfo {
  totalPrice: number;
  quantity: number;
  unitId: number;
  unitName: string;
}

export interface TourActivity {
  paxInfos: PaxInfo;
  date: Date;
  ratePlanId: number;
  activityId: number;
}
