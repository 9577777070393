import {
  TourFilterAvailableAction,
  TourFilterAvailableActionTypes
} from './tour-filter-available.actions';

export const TOURFILTERAVAILABLE_FEATURE_KEY = 'tourFilterAvailable';

/**
 * Interface for the 'TourFilterAvailable' data used in
 *  - TourFilterAvailableState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface TourFilterAvailableState {
  categoryIds: string[];
  themesIds: string[];
  amenityIds: string[];
  pageIndex: number;
  sortName: string;
  sortOrder: 'DESC' | 'ASC';
}

export interface TourFilterAvailablePartialState {
  readonly [TOURFILTERAVAILABLE_FEATURE_KEY]: TourFilterAvailableState;
}

export const initialState: TourFilterAvailableState = {
  categoryIds: [],
  themesIds: [],
  amenityIds: [],
  pageIndex: 0,
  sortName: null,
  sortOrder: null
};

export function reducer(
  state: TourFilterAvailableState = initialState,
  action: TourFilterAvailableAction
): TourFilterAvailableState {
  switch (action.type) {
    case TourFilterAvailableActionTypes.ResetFilter: {
      state = {
        ...initialState
      };
      break;
    }
    case TourFilterAvailableActionTypes.ChangeCategories: {
      state = {
        ...state,
        categoryIds: action.payload,
        pageIndex: 0
      };
      break;
    }
    case TourFilterAvailableActionTypes.ChangeThemes: {
      state = {
        ...state,
        themesIds: action.payload,
        pageIndex: 0
      };
      break;
    }
    case TourFilterAvailableActionTypes.ChangeAmenities: {
      state = {
        ...state,
        amenityIds: action.payload,
        pageIndex: 0
      };
      break;
    }
    case TourFilterAvailableActionTypes.ChangePage: {
      state = {
        ...state,
        pageIndex: action.payload
      };
      break;
    }
    case TourFilterAvailableActionTypes.ChangeSort: {
      state = {
        ...state,
        sortName: action.payload.sortName,
        sortOrder: action.payload.sortOrder,
        pageIndex: 0
      };
      break;
    }
  }
  return state;
}
