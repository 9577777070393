import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CheckoutService} from "./checkout/checkout.service";
import { DeviceMessageService } from "./checkout/device-message.service";

@NgModule({
  imports: [CommonModule],
  providers: [CheckoutService, DeviceMessageService],
})
export class ServicesModule {}
