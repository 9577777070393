import { BrowserModule, TransferState } from '@angular/platform-browser';
import { NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { ShellModule } from '@gtd/shell';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NxModule } from '@nrwl/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PwaModule } from '@gtd/pwa';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BasicAuthInterceptor } from './core/basic-auth.interceptor';
import { AuthGuard, AuthGuardNotLoggedIn } from './core/authGuard';
import { CookieService } from 'ngx-cookie-service';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformBrowser } from '@angular/common';
import { CRAWLER_AGENTS, VIEWER } from '@gtd/utilities';
import { HomeModule } from './home/home.module';
import { LoadingModule } from '@gtd/components/loading';
import { PixelTrackingModule } from '@gtd/pixel-tracking';
import { GaTrackingModule } from '@gtd/ga-tracking';
import { LazyLoadImagesModule } from '@gtd/directives/lazy-load-images';
import { TranslateBrowserLoader } from './core/translate-browser-loader.service';
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('@gtd/auth').then(m => m.AuthModule),
    canActivate: [AuthGuardNotLoggedIn]
  },
  // {
  //   path: 'ref',
  //   loadChildren: () => import('@gtd/auth').then(m => m.QrCodePageModule),
  //   canActivate: [AuthGuardNotLoggedIn]
  // },
  {
    path: 'tools',
    loadChildren: () => import('@gtd/tools').then(m => m.ToolsModule)
  },
  {
    path: 'flight',
    loadChildren: () =>
      import('@gtd/flights/feature/shell').then(m => m.FlightsShellModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'combo',
    loadChildren: () => import('@gtd/combovj').then(m => m.ComboVjModule),
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'checkout/flight',
    loadChildren: () => import('@gtd/checkout').then(m => m.CheckoutModule)
  },
  {
    path: 'checkout/hotel',
    loadChildren: () => import('@gtd/checkout').then(m => m.HotelCheckoutModule)
  },
  {
    path: 'checkout/combo',
    loadChildren: () => import('@gtd/checkout').then(m => m.ComboCheckoutModule)
  },
  {
    path: 'checkout/tour',
    loadChildren: () => import('@gtd/checkout').then(m => m.TourCheckoutModule)
  },
  {
    path: 'hotel',
    loadChildren: () => import('@gtd/hotel').then(m => m.HotelModule)
  },
  {
    path: 'hotels',
    loadChildren: () =>
      import('@gtd/hotels/feature/shell').then(m => m.HotelShellModule)
  },
  {
    path: 'checkouts',
    loadChildren: () =>
      import('@gtd/checkouts/feature/shell').then(m => m.CheckoutShellModule)
  },
  {
    path: 'user',
    loadChildren: () => import('@gtd/user').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer',
    loadChildren: () => import('@gtd/member-ship').then(m => m.MemberShipModule)
  },
  {
    path: 'booking/payment',
    loadChildren: () => import('@gtd/payment').then(m => m.PaymentModule)
  },
  {
    path: 'booking/payment/cancel',
    loadChildren: () => import('@gtd/payment').then(m => m.PaymentModule)
  },
  {
    path: 'booking/result',
    loadChildren: () =>
      import('@gtd/booking-result').then(m => m.BookingResultModule)
  },
  {
    path: 'san-khuyen-mai',
    loadChildren: () => import('@gtd/promotion').then(m => m.PromotionModule)
  },
  {
    path: 'goc-chill-phieu-trai-nghiem',
    loadChildren: () =>
      import('@gtd/promotion').then(
        m => m.PromotionModule
      )
  },
  {
    path: 'hop-tac-cung-gotadi',
    loadChildren: () =>
      import('@gtd/cooperate-with-gotadi').then(
        m => m.CooperateWithGotadiModule
      )
  },
  {
    path: 've-may-bay/:slug',
    loadChildren: () =>
      import('@gtd/content/flight-content').then(m => m.FlightContentModule)
  },
  {
    path: 'vui-choi-giai-tri/:slug',
    loadChildren: () =>
      import('@gtd/extra/tour/feature/shell').then(m => m.ExtraTourShellModule)
  },
  {
    path: 'khach-san/:slug',
    loadChildren: () =>
      import('@gtd/content/hotel-content').then(m => m.HotelContentModule)
  },
  {
    path: 'combo-du-lich/:slug',
    loadChildren: () =>
      import('@gtd/content/combo-content').then(m => m.ComboContentModule)
  },
  {
    path: 'diem-den/:slug',
    loadChildren: () =>
      import('@gtd/destination/feature-destination').then(
        m => m.FeatureDestinationModule
      )
  },
  {
    path: 'vui-choi-giai-tri',
    loadChildren: () =>
      import('@gtd/extra/tour/feature/shell').then(m => m.ExtraTourShellModule)
  },
  {
    path: 'content',
    loadChildren: () =>
      import('@gtd/content-detail/feature/shell').then(
        m => m.ContentShellModule
      )
  },
  {
    path: ':slug',
    loadChildren: () =>
      import('@gtd/content-detail/feature/shell').then(
        m => m.ContentShellModule
      )
  },
  {
    path: '**',
    loadChildren: () =>
      import('@gtd/components/page-not-found').then(m => m.PageNotFoundModule)
  }
];
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function exportTranslateStaticLoader(
  http: HttpClient,
  transferState: TransferState
) {
  return new TranslateBrowserLoader(
    '/assets/i18n/',
    '.json',
    transferState,
    http
  );
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState]
      }
    }),
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      // initialNavigation: 'enabled',
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled'
    }),
    ShellModule,
    NxModule.forRoot(),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    PixelTrackingModule.forRoot(),
    GaTrackingModule.forRoot(),
    PwaModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    TransferHttpCacheModule,
    HomeModule,
    LoadingModule,
    LazyLoadImagesModule
  ],
  declarations: [
    AppComponent
    // MetaClientModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    AuthGuard,
    AuthGuardNotLoggedIn,
    CookieService,
    { provide: 'env', useValue: environment },
    { provide: 'appName', useValue: environment.appName },
    {
      provide: VIEWER,
      useFactory: viewerFactory,
      deps: [PLATFORM_ID, [new Optional(), REQUEST]]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function viewerFactory(platformId, req: any): string {
  if (isPlatformBrowser(platformId)) {
    return 'user';
  }
  const userAgent = (req.get('user-agent') || '').toLowerCase();
  const isCrawler = CRAWLER_AGENTS.some(
    crawlerAgent => userAgent.indexOf(crawlerAgent) !== -1
  );
  return isCrawler ? 'bot' : 'user';
}
