import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { ComboCreateDraftBookingPartialState } from './combo-create-draft-booking.reducer';
import {
  ComboCreateDraftBookingActionTypes,
  ComboVJComboCreateDraftBookingLoadError,
  ComboVJLoadComboCreateDraftBooking
} from './combo-create-draft-booking.actions';
import { map } from 'rxjs/operators';
import { ComboResourceService } from '@gtd/b2c-client';

@Injectable()
export class ComboCreateDraftBookingEffects {
  @Effect() loadComboVJComboCreateDraftBooking$ = this.dataPersistence.fetch(
    ComboCreateDraftBookingActionTypes.ComboVJLoadComboCreateDraftBooking,
    {
      run: (
        action: ComboVJLoadComboCreateDraftBooking,
        state: ComboCreateDraftBookingPartialState
      ) => {
        return this.comboResourceService
          .createDraftBookingUsingPOST1(action.payload)
          .pipe(
            map(payload => ({
              type:
                ComboCreateDraftBookingActionTypes.ComboVJComboCreateDraftBookingLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: ComboVJLoadComboCreateDraftBooking, error) => {
        console.error('Error', error);
        return new ComboVJComboCreateDraftBookingLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private comboResourceService: ComboResourceService,
    private dataPersistence: DataPersistence<
      ComboCreateDraftBookingPartialState
    >
  ) {}
}
