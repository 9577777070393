import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID
} from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper/dist/lib/swiper.interfaces';
import { PromotionSectionStateFacade } from './promotion-section-state/+state/promotion-section-state.facade';
import { Subscription } from 'rxjs';
import { BannerDTO } from '@gtd/b2c-client';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BannerTypeService } from '@gtd/api-services/newface-banner';
import { VIEWER } from '@gtd/utilities';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@env/environment';

@Component({
  selector: 'gtd-promotion-section',
  templateUrl: './promotion-section.component.html',
  styleUrls: ['./promotion-section.component.scss']
})
export class PromotionSectionComponent implements OnInit, OnDestroy {
  hideBanner = false;
  config: SwiperConfigInterface;
  subscription = new Subscription();
  lsImageUrl: BannerDTO[];
  bannerList: any[];
  constructor(
    private promotionSectionStateFacade: PromotionSectionStateFacade,
    private deviceService: DeviceDetectorService,
    public bannerTypeService: BannerTypeService,
    @Optional() @Inject(VIEWER) private viewer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.promotionSectionStateFacade.loadAll();
  }

  ngOnInit() {
    // this.subscription.add(
    //   this.promotionSectionStateFacade.allPromotionSectionState$.subscribe(
    //     (lsImageUrl: Array<BannerDTO>) => {
    //       this.lsImageUrl = lsImageUrl;
    //     }
    //   )
    // );

    if (isPlatformBrowser(this.platformId) && this.viewer !== 'bot') {
      this.bannerTypeService
        .wpV2BannerTypeGet('70', environment.production ? '76' : '100')
        .subscribe(banners => {
          this.bannerList = banners;
        });
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
