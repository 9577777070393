import { Injectable } from '@angular/core';
import { AirportDTO } from '@gtd/meta-client';

@Injectable({
  providedIn: 'root'
})
export class ComboSearchService {
  constructor() {}

  getAllLocalStorageLocation(type: string): any {
    let localStorageData = localStorage.getItem(type);
    if (localStorageData) {
      return JSON.parse(localStorageData);
    }
    return null;
  }
}
