import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {CustomerTravellerDTO, CustomerTravellerMemberCardDTO} from "@gtd/api-services/customer-service";

// @ts-ignore
@Injectable({
  providedIn: 'root'
})

export interface PassengerData {
  index: number,
  customerTravellerDTO: CustomerTravellerDTO
}
export class PassengerService {

  passenger = new BehaviorSubject<PassengerData>(null);
  getPassenger = this.passenger.asObservable();

  parentSubmitForm = new BehaviorSubject<boolean>(false);
  getParentSubmitForm = this.parentSubmitForm.asObservable();


  //MemberCard Form
  memberCardValid = new BehaviorSubject<boolean>(false);
  getMemberCardValid = this.memberCardValid.asObservable();

  memberCardData = new BehaviorSubject<Array<CustomerTravellerMemberCardDTO>>([]);
  getMemberCardData = this.memberCardData.asObservable();

  constructor() { }

  setPassenger(passengerData: PassengerData) {
    this.passenger.next(passengerData);
  }

  setMemberCardValid(valid: boolean) {
    this.memberCardValid.next(valid);
  }

  setMemberCardData(memberCardData: Array<CustomerTravellerMemberCardDTO>) {
    this.memberCardData.next(memberCardData);
  }

  setParentSubmitForm(submit: boolean) {
    this.parentSubmitForm.next(submit);
  }

}
