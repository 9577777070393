/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EticketDO } from './eticketDO';


export interface BookingTransactionStatusInfo { 
    bookingCode?: string;
    bookingDirection?: BookingTransactionStatusInfo.BookingDirectionEnum;
    bookingIssuedType?: BookingTransactionStatusInfo.BookingIssuedTypeEnum;
    bookingNote?: string;
    bookingNumber?: string;
    bookingRefNo?: string;
    cancelPenaltyAmount?: number;
    cancelPenaltyTaxAndFees?: number;
    cancelPenaltyTotal?: number;
    cancellationStatus?: BookingTransactionStatusInfo.CancellationStatusEnum;
    destinationLocationCode?: string;
    etickets?: string;
    fareCode?: string;
    issuedStatus?: BookingTransactionStatusInfo.IssuedStatusEnum;
    listETickets?: Array<EticketDO>;
    originLocationCode?: string;
    passengerNameRecord?: string;
    productClass?: string;
    productSeqNumber?: string;
    sessionGroupId?: string;
    sessionSearchId?: string;
    signature?: string;
    status?: BookingTransactionStatusInfo.StatusEnum;
    supplierBookingStatus?: BookingTransactionStatusInfo.SupplierBookingStatusEnum;
    supplierCancelStatus?: BookingTransactionStatusInfo.SupplierCancelStatusEnum;
    supplierCode?: string;
    supplierPaymentStatus?: BookingTransactionStatusInfo.SupplierPaymentStatusEnum;
    supplierType?: BookingTransactionStatusInfo.SupplierTypeEnum;
    timeToLive?: Date;
}
export namespace BookingTransactionStatusInfo {
    export type BookingDirectionEnum = 'DEPARTURE' | 'RETURN' | 'TRIP';
    export const BookingDirectionEnum = {
        DEPARTURE: 'DEPARTURE' as BookingDirectionEnum,
        RETURN: 'RETURN' as BookingDirectionEnum,
        TRIP: 'TRIP' as BookingDirectionEnum
    };
    export type BookingIssuedTypeEnum = 'INSTANT_BOOKING' | 'CONFIRM_OFFLINE';
    export const BookingIssuedTypeEnum = {
        INSTANTBOOKING: 'INSTANT_BOOKING' as BookingIssuedTypeEnum,
        CONFIRMOFFLINE: 'CONFIRM_OFFLINE' as BookingIssuedTypeEnum
    };
    export type CancellationStatusEnum = 'CANCEL_UNKNOWN' | 'CANCEL_PENALTY_MISMATCH' | 'CANCEL_WAITING_CONFIRM' | 'CANCEL_CONFIRMED' | 'CANCEL_EXPIRED';
    export const CancellationStatusEnum = {
        UNKNOWN: 'CANCEL_UNKNOWN' as CancellationStatusEnum,
        PENALTYMISMATCH: 'CANCEL_PENALTY_MISMATCH' as CancellationStatusEnum,
        WAITINGCONFIRM: 'CANCEL_WAITING_CONFIRM' as CancellationStatusEnum,
        CONFIRMED: 'CANCEL_CONFIRMED' as CancellationStatusEnum,
        EXPIRED: 'CANCEL_EXPIRED' as CancellationStatusEnum
    };
    export type IssuedStatusEnum = 'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED';
    export const IssuedStatusEnum = {
        PENDING: 'PENDING' as IssuedStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as IssuedStatusEnum,
        SUCCEEDED: 'SUCCEEDED' as IssuedStatusEnum,
        FAILED: 'FAILED' as IssuedStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as IssuedStatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as IssuedStatusEnum
    };
    export type StatusEnum = 'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'TICKET_ON_PROCESS' | 'INACTIVE';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as StatusEnum,
        BOOKED: 'BOOKED' as StatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        TENTATIVE: 'TENTATIVE' as StatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type SupplierBookingStatusEnum = 'PENDING' | 'BOOKED' | 'CONFIRMED' | 'FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'BOOKING_ON_PROCESS' | 'TICKET_ON_PROCESS' | 'PARTLY_BOOKED' | 'PARTLY_CONFIRMED' | 'ISSUE_FAILED' | 'CONFIRM_OFFLINE';
    export const SupplierBookingStatusEnum = {
        PENDING: 'PENDING' as SupplierBookingStatusEnum,
        BOOKED: 'BOOKED' as SupplierBookingStatusEnum,
        CONFIRMED: 'CONFIRMED' as SupplierBookingStatusEnum,
        FAILED: 'FAILED' as SupplierBookingStatusEnum,
        CANCELLED: 'CANCELLED' as SupplierBookingStatusEnum,
        EXPIRED: 'EXPIRED' as SupplierBookingStatusEnum,
        TENTATIVE: 'TENTATIVE' as SupplierBookingStatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as SupplierBookingStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as SupplierBookingStatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as SupplierBookingStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as SupplierBookingStatusEnum,
        ISSUEFAILED: 'ISSUE_FAILED' as SupplierBookingStatusEnum,
        CONFIRMOFFLINE: 'CONFIRM_OFFLINE' as SupplierBookingStatusEnum
    };
    export type SupplierCancelStatusEnum = 'CANCEL_UNKNOWN' | 'CANCEL_PENALTY_MISMATCH' | 'CANCEL_WAITING_CONFIRM' | 'CANCEL_CONFIRMED' | 'CANCEL_EXPIRED';
    export const SupplierCancelStatusEnum = {
        UNKNOWN: 'CANCEL_UNKNOWN' as SupplierCancelStatusEnum,
        PENALTYMISMATCH: 'CANCEL_PENALTY_MISMATCH' as SupplierCancelStatusEnum,
        WAITINGCONFIRM: 'CANCEL_WAITING_CONFIRM' as SupplierCancelStatusEnum,
        CONFIRMED: 'CANCEL_CONFIRMED' as SupplierCancelStatusEnum,
        EXPIRED: 'CANCEL_EXPIRED' as SupplierCancelStatusEnum
    };
    export type SupplierPaymentStatusEnum = 'PENDING' | 'SUCCEEDED' | 'FAILED' | 'REFUNDED';
    export const SupplierPaymentStatusEnum = {
        PENDING: 'PENDING' as SupplierPaymentStatusEnum,
        SUCCEEDED: 'SUCCEEDED' as SupplierPaymentStatusEnum,
        FAILED: 'FAILED' as SupplierPaymentStatusEnum,
        REFUNDED: 'REFUNDED' as SupplierPaymentStatusEnum
    };
    export type SupplierTypeEnum = 'AIR' | 'HOTEL' | 'COMBO' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const SupplierTypeEnum = {
        AIR: 'AIR' as SupplierTypeEnum,
        HOTEL: 'HOTEL' as SupplierTypeEnum,
        COMBO: 'COMBO' as SupplierTypeEnum,
        TOURS: 'TOURS' as SupplierTypeEnum,
        TRAIN: 'TRAIN' as SupplierTypeEnum,
        SHIP: 'SHIP' as SupplierTypeEnum,
        OTHER: 'OTHER' as SupplierTypeEnum
    };
}
