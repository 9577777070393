import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TcbFxOptionComponent } from './tcb-fx-option/tcb-fx-option.component';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatTooltipModule
} from "@angular/material";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {TranslateModule} from "@ngx-translate/core";
import { TcbFormCustomerComponent } from './tcb-form-customer/tcb-form-customer.component';
import { TcbPolicyComponent } from './tcb-policy/tcb-policy.component';
import {ReactiveFormsModule} from "@angular/forms";
import {TcbFormCustomerListComponent, VerifyAttribute} from './tcb-form-customer-list/tcb-form-customer-list.component';
import { TcbFormUpdateCustomerComponent } from './tcb-form-update-customer/tcb-form-update-customer.component';
import { TcbTransactionDetailComponent } from './tcb-transaction-detail/tcb-transaction-detail.component';
import {B2cApiClientModule} from "@gtd/b2c-client";


library.add(
  faChevronRight
);

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        FontAwesomeModule,
        MatIconModule,
        TranslateModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        B2cApiClientModule
    ],
  declarations: [
    TcbFxOptionComponent,
    TcbFormCustomerComponent,
    TcbPolicyComponent,
    TcbFormCustomerListComponent,
    TcbFormUpdateCustomerComponent,
    VerifyAttribute,
    TcbTransactionDetailComponent
  ],
  exports: [
    TcbFxOptionComponent,
    TcbFormCustomerListComponent,
    TcbFormUpdateCustomerComponent,
    TcbTransactionDetailComponent
  ],
  entryComponents: [TcbFormCustomerComponent, TcbPolicyComponent, TcbFormCustomerListComponent, TcbFormUpdateCustomerComponent]
})
export class TcbFxTransactionModule {}
