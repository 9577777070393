import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  REQUESTPASSWORDSTATE_FEATURE_KEY,
  RequestPasswordStateState
} from './request-password-state.reducer';

// Lookup the 'RequestPasswordState' feature state managed by NgRx
const getRequestPasswordStateState = createFeatureSelector<
  RequestPasswordStateState
>(REQUESTPASSWORDSTATE_FEATURE_KEY);

const getLoaded = createSelector(
  getRequestPasswordStateState,
  (state: RequestPasswordStateState) => state.loaded
);
const getError = createSelector(
  getRequestPasswordStateState,
  (state: RequestPasswordStateState) => state.error
);

const getAllRequestPasswordState = createSelector(
  getRequestPasswordStateState,
  getLoaded,
  (state: RequestPasswordStateState, isLoaded) => {
    return isLoaded ? state : null;
  }
);
const getSelectedId = createSelector(
  getRequestPasswordStateState,
  (state: RequestPasswordStateState) => state.selectedId
);
const getSelectedRequestPasswordState = createSelector(
  getAllRequestPasswordState,
  getSelectedId,
  (requestPasswordState, id) => {
    return requestPasswordState ? Object.assign({}, requestPasswordState) : undefined;
  }
);

export const requestPasswordStateQuery = {
  getLoaded,
  getError,
  getAllRequestPasswordState,
  getSelectedRequestPasswordState
};
