export * from './agentcyBookingResource.service';
import { AgentcyBookingResourceService } from './agentcyBookingResource.service';
export * from './bookingAdditionalRequestResource.service';
import { BookingAdditionalRequestResourceService } from './bookingAdditionalRequestResource.service';
export * from './bookingResource.service';
import { BookingResourceService } from './bookingResource.service';
export * from './bookingResourceV2.service';
import { BookingResourceV2Service } from './bookingResourceV2.service';
export * from './bookingScheduleResource.service';
import { BookingScheduleResourceService } from './bookingScheduleResource.service';
export * from './bookingTagConnectionsResource.service';
import { BookingTagConnectionsResourceService } from './bookingTagConnectionsResource.service';
export * from './bookingTagsResource.service';
import { BookingTagsResourceService } from './bookingTagsResource.service';
export * from './bookingTransactionResource.service';
import { BookingTransactionResourceService } from './bookingTransactionResource.service';
export * from './hotelBookingResource.service';
import { HotelBookingResourceService } from './hotelBookingResource.service';
export * from './offlineBookingResource.service';
import { OfflineBookingResourceService } from './offlineBookingResource.service';
export * from './productBookingResource.service';
import { ProductBookingResourceService } from './productBookingResource.service';
export * from './profileInfoResource.service';
import { ProfileInfoResourceService } from './profileInfoResource.service';
export * from './reconciliationResource.service';
import { ReconciliationResourceService } from './reconciliationResource.service';
export * from './remindBookingSmsResource.service';
import { RemindBookingSmsResourceService } from './remindBookingSmsResource.service';
export * from './saleChannelBookingResource.service';
import { SaleChannelBookingResourceService } from './saleChannelBookingResource.service';
export * from './ticketBookingResource.service';
import { TicketBookingResourceService } from './ticketBookingResource.service';
export * from './tikiBookingResource.service';
import { TikiBookingResourceService } from './tikiBookingResource.service';
export * from './viettelBookingResource.service';
import { ViettelBookingResourceService } from './viettelBookingResource.service';
export const APIS = [AgentcyBookingResourceService, BookingAdditionalRequestResourceService, BookingResourceService, BookingResourceV2Service, BookingScheduleResourceService, BookingTagConnectionsResourceService, BookingTagsResourceService, BookingTransactionResourceService, HotelBookingResourceService, OfflineBookingResourceService, ProductBookingResourceService, ProfileInfoResourceService, ReconciliationResourceService, RemindBookingSmsResourceService, SaleChannelBookingResourceService, TicketBookingResourceService, TikiBookingResourceService, ViettelBookingResourceService];
