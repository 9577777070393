import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { CountUnreadPartialState } from './count-unread.reducer';
import { countUnreadQuery } from './count-unread.selectors';
import { LoadCountUnread } from './count-unread.actions';

@Injectable()
export class CountUnreadFacade {
  loaded$ = this.store.pipe(select(countUnreadQuery.getLoaded));
  allCountUnread$ = this.store.pipe(select(countUnreadQuery.getAllCountUnread));
  selectedCountUnread$ = this.store.pipe(
    select(countUnreadQuery.getSelectedCountUnread)
  );

  constructor(private store: Store<CountUnreadPartialState>) {}

  countUnreadMsgUsingGET(userRefCode: string) {
    this.store.dispatch(new LoadCountUnread(userRefCode));
  }
}
