/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AgentAccountHistoryDTO { 
    accountCode?: string;
    accountId?: number;
    accountType?: AgentAccountHistoryDTO.AccountTypeEnum;
    agencyCode?: string;
    agentCode?: string;
    balance?: number;
    balanceChangedAmount?: number;
    createdBy?: string;
    createdDate?: Date;
    credit?: number;
    creditChangedAmount?: number;
    id?: number;
    orgCode?: string;
    parentAgencyCode?: string;
    trackingCode?: string;
    transCode?: string;
    transType?: AgentAccountHistoryDTO.TransTypeEnum;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace AgentAccountHistoryDTO {
    export type AccountTypeEnum = 'BOOKER' | 'AGENCY';
    export const AccountTypeEnum = {
        BOOKER: 'BOOKER' as AccountTypeEnum,
        AGENCY: 'AGENCY' as AccountTypeEnum
    };
    export type TransTypeEnum = 'DEPOSIT' | 'DEDUCT' | 'CREDIT' | 'REFUND' | 'TRANSACTION' | 'TRANSFER' | 'TICKET_TRANSACTION' | 'HOTEL_TRANSACTION' | 'COMBO_TRANSACTION' | 'TOUR_TRANSACTION' | 'TRACKING_TRANSACTION' | 'OTHER';
    export const TransTypeEnum = {
        DEPOSIT: 'DEPOSIT' as TransTypeEnum,
        DEDUCT: 'DEDUCT' as TransTypeEnum,
        CREDIT: 'CREDIT' as TransTypeEnum,
        REFUND: 'REFUND' as TransTypeEnum,
        TRANSACTION: 'TRANSACTION' as TransTypeEnum,
        TRANSFER: 'TRANSFER' as TransTypeEnum,
        TICKETTRANSACTION: 'TICKET_TRANSACTION' as TransTypeEnum,
        HOTELTRANSACTION: 'HOTEL_TRANSACTION' as TransTypeEnum,
        COMBOTRANSACTION: 'COMBO_TRANSACTION' as TransTypeEnum,
        TOURTRANSACTION: 'TOUR_TRANSACTION' as TransTypeEnum,
        TRACKINGTRANSACTION: 'TRACKING_TRANSACTION' as TransTypeEnum,
        OTHER: 'OTHER' as TransTypeEnum
    };
}
