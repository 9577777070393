import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransactionFinancialService } from '../transaction-financial.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { MY_FORMATS } from '@gtd/components/datepicker';
import { FilterFinancial } from '@gtd/api-services/b2b-client';

@Component({
  selector: 'gtd-filter-financial',
  templateUrl: './filter-financial.component.html',
  styleUrls: ['./filter-financial.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class FilterFinancialComponent implements OnInit {
  @Input() isOpen: boolean;
  @Input() isLoading: boolean;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  searchType: 'bookingNumber' | 'bookingRefNumber' = 'bookingNumber';
  formFilter: FormGroup;
  dataFilters: FilterFinancial;
  maxDateFrom2 = new Date();
  minDateTo = new Date();
  maxDateTo = new Date();
  maxDateFrom = new Date();

  constructor(
    private transactionFinancialService: TransactionFinancialService,
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.maxDateFrom2 = new Date(
      this.maxDateFrom2.getFullYear(),
      this.maxDateFrom2.getMonth(),
      this.maxDateFrom2.getDate() - 1
    );
    this.minDateTo = new Date(
      this.maxDateFrom2.getFullYear(),
      this.maxDateFrom2.getMonth(),
      this.maxDateFrom2.getDate() + 1
    );
    this.transactionFinancialService.getDestroyFilter.subscribe(
      destroyFilter => {
        if (destroyFilter) {
          this.formFilter.reset();
        }
      }
    );
    // this.transactionFinancialService.getDataFilters.subscribe(filters => {
    //   console.log(filters);
    //   this.dataFilters = filters;
    //   if (filters && filters.filter) {
    //     this.formFilter.setValue({
    //       agencyCode: filters.filter.agencyCode
    //         ? filters.filter.agencyCode
    //         : '',
    //       branchCode: filters.filter.branchCode
    //         ? filters.filter.branchCode
    //         : '',
    //       createdBy: filters.filter.createdBy ? filters.filter.createdBy : '',
    //       bookingRefNumber: filters.filter.bookingRefNumber
    //         ? filters.filter.bookingRefNumber
    //         : '',
    //       bookingNumber: filters.filter.bookingNumber
    //         ? this.searchType === 'bookingNumber'
    //           ? filters.filter.bookingNumber
    //           : filters.filter.bookingRefNumber
    //         : '',
    //       datePicker: filters.filter.datePicker
    //         ? filters.filter.datePicker
    //         : '',
    //       amountFrom: filters.filter.amountFrom
    //         ? filters.filter.amountFrom
    //         : '',
    //       amountTo: filters.filter.amountTo ? filters.filter.amountTo : '',
    //       transType: filters.filter.transType ? filters.filter.transType : null
    //     });
    //   } else {
    //     this.formFilter.reset();
    //   }
    // });
  }
  private createForm() {
    this.formFilter = this.formBuilder.group({
      agencyCode: [''],
      branchCode: [''],
      createdBy: [''],
      bookingRefNumber: [''],
      bookingNumber: [''],
      amountFrom: [''],
      amountTo: [''],
      datePicker: [''],
      transType: ['']
    });
  }

  btnSubmit() {
    this.transactionFinancialService.setDataFilters({
      ...this.dataFilters,
      filter: {
        ...this.formFilter.value,
        bookingNumber:
          this.searchType === 'bookingNumber'
            ? this.formFilter.value.bookingNumber
            : '',
        bookingRefNumber:
          this.searchType === 'bookingRefNumber'
            ? this.formFilter.value.bookingNumber
            : ''
      },
      page: 0
    });
    this.changed.emit('submit');
  }

  reloadBtn() {
    window.location.reload();
  }
}
