/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AgencyFinanceInfo } from '../model/agencyFinanceInfo';
import { AgentAccountDTO } from '../model/agentAccountDTO';
import { MultiAgencyCodeRS } from '../model/multiAgencyCodeRS';
import { TopupInfo } from '../model/topupInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AgentAccountResourceService {

    protected basePath = 'https://10.7.71.20:8282';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createAgentAccount
     * 
     * @param agentAccountDTO agentAccountDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgentAccountUsingPOST(agentAccountDTO: AgentAccountDTO, observe?: 'body', reportProgress?: boolean): Observable<AgentAccountDTO>;
    public createAgentAccountUsingPOST(agentAccountDTO: AgentAccountDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentAccountDTO>>;
    public createAgentAccountUsingPOST(agentAccountDTO: AgentAccountDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentAccountDTO>>;
    public createAgentAccountUsingPOST(agentAccountDTO: AgentAccountDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentAccountDTO === null || agentAccountDTO === undefined) {
            throw new Error('Required parameter agentAccountDTO was null or undefined when calling createAgentAccountUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AgentAccountDTO>(`${this.basePath}/api/agent-accounts`,
            agentAccountDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteAgentAccountByAgencyCode
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgentAccountByAgencyCodeUsingPOST(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAgentAccountByAgencyCodeUsingPOST(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAgentAccountByAgencyCodeUsingPOST(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAgentAccountByAgencyCodeUsingPOST(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling deleteAgentAccountByAgencyCodeUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/api/delete-agent-accounts`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteAgentAccount
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgentAccountUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAgentAccountUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAgentAccountUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAgentAccountUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteAgentAccountUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/api/agent-accounts/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentAccountByAgencyCode
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentAccountByAgencyCodeUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<AgentAccountDTO>;
    public getAgentAccountByAgencyCodeUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentAccountDTO>>;
    public getAgentAccountByAgencyCodeUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentAccountDTO>>;
    public getAgentAccountByAgencyCodeUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getAgentAccountByAgencyCodeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentAccountDTO>(`${this.basePath}/api/agent-accounts/by-agency-code/${encodeURIComponent(String(agencyCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentAccountByAgentCode
     * 
     * @param agentCode agentCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentAccountByAgentCodeUsingGET(agentCode: string, observe?: 'body', reportProgress?: boolean): Observable<AgentAccountDTO>;
    public getAgentAccountByAgentCodeUsingGET(agentCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentAccountDTO>>;
    public getAgentAccountByAgentCodeUsingGET(agentCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentAccountDTO>>;
    public getAgentAccountByAgentCodeUsingGET(agentCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentCode === null || agentCode === undefined) {
            throw new Error('Required parameter agentCode was null or undefined when calling getAgentAccountByAgentCodeUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentAccountDTO>(`${this.basePath}/api/agent-accounts/by-agent-code/${encodeURIComponent(String(agentCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentAccountByAgentId
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentAccountByAgentIdUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<AgentAccountDTO>;
    public getAgentAccountByAgentIdUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentAccountDTO>>;
    public getAgentAccountByAgentIdUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentAccountDTO>>;
    public getAgentAccountByAgentIdUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAgentAccountByAgentIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentAccountDTO>(`${this.basePath}/api/agent-accounts/by-agent-id/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentAccount
     * 
     * @param id id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentAccountUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<AgentAccountDTO>;
    public getAgentAccountUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentAccountDTO>>;
    public getAgentAccountUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentAccountDTO>>;
    public getAgentAccountUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getAgentAccountUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AgentAccountDTO>(`${this.basePath}/api/agent-accounts/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgentAccountsByAgencyCode
     * 
     * @param agentCodes agentCodes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgentAccountsByAgencyCodeUsingPOST(agentCodes: MultiAgencyCodeRS, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentAccountDTO>>;
    public getAgentAccountsByAgencyCodeUsingPOST(agentCodes: MultiAgencyCodeRS, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentAccountDTO>>>;
    public getAgentAccountsByAgencyCodeUsingPOST(agentCodes: MultiAgencyCodeRS, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentAccountDTO>>>;
    public getAgentAccountsByAgencyCodeUsingPOST(agentCodes: MultiAgencyCodeRS, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentCodes === null || agentCodes === undefined) {
            throw new Error('Required parameter agentCodes was null or undefined when calling getAgentAccountsByAgencyCodeUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<AgentAccountDTO>>(`${this.basePath}/api/get-agent-accounts/by-agent-code`,
            agentCodes,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllAgentAccounts
     * 
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAgentAccountsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentAccountDTO>>;
    public getAllAgentAccountsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentAccountDTO>>>;
    public getAllAgentAccountsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentAccountDTO>>>;
    public getAllAgentAccountsUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgentAccountDTO>>(`${this.basePath}/api/agent-accounts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllFinanceInfo
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllFinanceInfoUsingGET(agencyCode?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AgencyFinanceInfo>>;
    public getAllFinanceInfoUsingGET(agencyCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgencyFinanceInfo>>>;
    public getAllFinanceInfoUsingGET(agencyCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgencyFinanceInfo>>>;
    public getAllFinanceInfoUsingGET(agencyCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<AgencyFinanceInfo>>(`${this.basePath}/api/agent-accounts/finance-info`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * topupAgentAccount
     * 
     * @param topupInfo topupInfo
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public topupAgentAccountUsingPOST(topupInfo: TopupInfo, observe?: 'body', reportProgress?: boolean): Observable<TopupInfo>;
    public topupAgentAccountUsingPOST(topupInfo: TopupInfo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TopupInfo>>;
    public topupAgentAccountUsingPOST(topupInfo: TopupInfo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TopupInfo>>;
    public topupAgentAccountUsingPOST(topupInfo: TopupInfo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (topupInfo === null || topupInfo === undefined) {
            throw new Error('Required parameter topupInfo was null or undefined when calling topupAgentAccountUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TopupInfo>(`${this.basePath}/api/agent-accounts/topup`,
            topupInfo,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateAgentAccount
     * 
     * @param agentAccountDTO agentAccountDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAgentAccountUsingPUT(agentAccountDTO: AgentAccountDTO, observe?: 'body', reportProgress?: boolean): Observable<AgentAccountDTO>;
    public updateAgentAccountUsingPUT(agentAccountDTO: AgentAccountDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentAccountDTO>>;
    public updateAgentAccountUsingPUT(agentAccountDTO: AgentAccountDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentAccountDTO>>;
    public updateAgentAccountUsingPUT(agentAccountDTO: AgentAccountDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agentAccountDTO === null || agentAccountDTO === undefined) {
            throw new Error('Required parameter agentAccountDTO was null or undefined when calling updateAgentAccountUsingPUT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<AgentAccountDTO>(`${this.basePath}/api/agent-accounts`,
            agentAccountDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
