import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {CitiesPopularPartialState} from './cities-popular.reducer';
import {CitiesPopularActionTypes, CitiesPopularLoadError, LoadCitiesPopular} from './cities-popular.actions';
import {map} from "rxjs/operators";
import {AirportResourceService} from "@gtd/meta-client";

@Injectable()
export class CitiesPopularEffects {
  @Effect() loadCitiesPopular$ = this.dataPersistence.fetch(
    CitiesPopularActionTypes.LoadCitiesPopular,
    {
      run: (action: LoadCitiesPopular, state: CitiesPopularPartialState) => {
        return this.airportResourceService
          .getPopularCitiesUsingGET()
          .pipe(
            map(updated => ({
              type: CitiesPopularActionTypes.CitiesPopularLoaded,
              payload: updated
            }))
          );
      },

      onError: (action: LoadCitiesPopular, error) => {
        console.error('Error', error);
        return new CitiesPopularLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private airportResourceService: AirportResourceService,
    private dataPersistence: DataPersistence<CitiesPopularPartialState>
  ) {}
}
