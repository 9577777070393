/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingAgencyMarkupInfo } from './bookingAgencyMarkupInfo';
import { BookingContactInfo } from './bookingContactInfo';
import { BookingPriceInfo } from './bookingPriceInfo';
import { BookingTransactionInfo } from './bookingTransactionInfo';
import { BookingTravelerInfo } from './bookingTravelerInfo';


export interface BookingInfo { 
    additionalFee?: number;
    agencyCode?: string;
    agencyMarkupInfos?: Array<BookingAgencyMarkupInfo>;
    agencyMarkupValue?: number;
    agentCode?: string;
    agentId?: number;
    agentName?: string;
    allowHold?: boolean;
    baseFare?: number;
    bookBy?: string;
    bookByCode?: string;
    bookingCode?: string;
    bookingDate?: Date;
    bookingFinalStatus?: BookingInfo.BookingFinalStatusEnum;
    bookingIssuedType?: BookingInfo.BookingIssuedTypeEnum;
    bookingNote?: string;
    bookingNumber?: string;
    bookingType?: BookingInfo.BookingTypeEnum;
    branchCode?: string;
    cancellationBy?: string;
    cancellationDate?: Date;
    cancellationFee?: number;
    cancellationNotes?: string;
    cancellationStatus?: BookingInfo.CancellationStatusEnum;
    channelType?: BookingInfo.ChannelTypeEnum;
    contactInfos?: Array<BookingContactInfo>;
    customerCode?: string;
    customerEmail?: string;
    customerFirstName?: string;
    customerId?: number;
    customerLastName?: string;
    customerPhoneNumber1?: string;
    customerPhoneNumber2?: string;
    deleted?: boolean;
    departureDate?: Date;
    discountAmount?: number;
    discountDate?: Date;
    discountRedeemCode?: string;
    discountRedeemId?: string;
    discountVoucherCode?: string;
    discountVoucherName?: string;
    displayPriceInfo?: BookingPriceInfo;
    equivFare?: number;
    etickets?: string;
    fromCity?: string;
    fromLocationCode?: string;
    fromLocationName?: string;
    id?: number;
    internalBookingNote?: string;
    issuedByCode?: string;
    issuedDate?: Date;
    issuedStatus?: BookingInfo.IssuedStatusEnum;
    markupValue?: number;
    numberOfTransaction?: number;
    numberOfTransactionMarkup?: number;
    onlyPayLater?: boolean;
    orgCode?: string;
    ownerBooking?: boolean;
    partnerOrderId?: string;
    passengerNameRecords?: string;
    paymentBy?: string;
    paymentByCode?: string;
    paymentDate?: Date;
    paymentFee?: number;
    paymentRefNumber?: string;
    paymentStatus?: BookingInfo.PaymentStatusEnum;
    paymentTotalAmount?: number;
    paymentType?: BookingInfo.PaymentTypeEnum;
    promotionID?: Array<string>;
    reasonCodePaymentFailed?: string;
    refundBy?: string;
    refundByCode?: string;
    refundable?: boolean;
    returnDate?: Date;
    roundType?: string;
    saleChannel?: BookingInfo.SaleChannelEnum;
    serviceTax?: number;
    showPayLaterOption?: boolean;
    showPayNowOption?: boolean;
    status?: BookingInfo.StatusEnum;
    supplierBookingStatus?: BookingInfo.SupplierBookingStatusEnum;
    supplierType?: BookingInfo.SupplierTypeEnum;
    taxAddress1?: string;
    taxAddress2?: string;
    taxCompanyName?: string;
    taxNumber?: string;
    taxPersonalInfoContact?: string;
    taxReceiptRequest?: boolean;
    timeToLive?: Date;
    toCity?: string;
    toLocationCode?: string;
    toLocationName?: string;
    totalCombo?: number;
    totalFare?: number;
    totalSsrValue?: number;
    totalTax?: number;
    transactionInfos?: Array<BookingTransactionInfo>;
    travelerInfos?: Array<BookingTravelerInfo>;
    vat?: number;
}
export namespace BookingInfo {
    export type BookingFinalStatusEnum = 'BOOK_PENDING' | 'BOOK_ON_PROCESS' | 'BOOK_FAILED' | 'BOOK_CANCELLED' | 'BOOK_EXPIRED' | 'PAYMENT_PENDING' | 'PAYMENT_ON_PROCESS' | 'PAYMENT_FAILED' | 'ISSUED_PENDING' | 'ISSUED_ON_PROCESS' | 'ISSUED_FAILED' | 'ISSUED_SUCCEEDED';
    export const BookingFinalStatusEnum = {
        BOOKPENDING: 'BOOK_PENDING' as BookingFinalStatusEnum,
        BOOKONPROCESS: 'BOOK_ON_PROCESS' as BookingFinalStatusEnum,
        BOOKFAILED: 'BOOK_FAILED' as BookingFinalStatusEnum,
        BOOKCANCELLED: 'BOOK_CANCELLED' as BookingFinalStatusEnum,
        BOOKEXPIRED: 'BOOK_EXPIRED' as BookingFinalStatusEnum,
        PAYMENTPENDING: 'PAYMENT_PENDING' as BookingFinalStatusEnum,
        PAYMENTONPROCESS: 'PAYMENT_ON_PROCESS' as BookingFinalStatusEnum,
        PAYMENTFAILED: 'PAYMENT_FAILED' as BookingFinalStatusEnum,
        ISSUEDPENDING: 'ISSUED_PENDING' as BookingFinalStatusEnum,
        ISSUEDONPROCESS: 'ISSUED_ON_PROCESS' as BookingFinalStatusEnum,
        ISSUEDFAILED: 'ISSUED_FAILED' as BookingFinalStatusEnum,
        ISSUEDSUCCEEDED: 'ISSUED_SUCCEEDED' as BookingFinalStatusEnum
    };
    export type BookingIssuedTypeEnum = 'INSTANT_BOOKING' | 'CONFIRM_OFFLINE';
    export const BookingIssuedTypeEnum = {
        INSTANTBOOKING: 'INSTANT_BOOKING' as BookingIssuedTypeEnum,
        CONFIRMOFFLINE: 'CONFIRM_OFFLINE' as BookingIssuedTypeEnum
    };
    export type BookingTypeEnum = 'DOME' | 'INTE';
    export const BookingTypeEnum = {
        DOME: 'DOME' as BookingTypeEnum,
        INTE: 'INTE' as BookingTypeEnum
    };
    export type CancellationStatusEnum = 'CANCEL_UNKNOWN' | 'CANCEL_PENALTY_MISMATCH' | 'CANCEL_WAITING_CONFIRM' | 'CANCEL_CONFIRMED' | 'CANCEL_EXPIRED';
    export const CancellationStatusEnum = {
        UNKNOWN: 'CANCEL_UNKNOWN' as CancellationStatusEnum,
        PENALTYMISMATCH: 'CANCEL_PENALTY_MISMATCH' as CancellationStatusEnum,
        WAITINGCONFIRM: 'CANCEL_WAITING_CONFIRM' as CancellationStatusEnum,
        CONFIRMED: 'CANCEL_CONFIRMED' as CancellationStatusEnum,
        EXPIRED: 'CANCEL_EXPIRED' as CancellationStatusEnum
    };
    export type ChannelTypeEnum = 'ONLINE' | 'OFFLINE';
    export const ChannelTypeEnum = {
        ONLINE: 'ONLINE' as ChannelTypeEnum,
        OFFLINE: 'OFFLINE' as ChannelTypeEnum
    };
    export type IssuedStatusEnum = 'PENDING' | 'TICKET_ON_PROCESS' | 'SUCCEEDED' | 'FAILED' | 'PARTLY_CONFIRMED' | 'PARTLY_FAILED';
    export const IssuedStatusEnum = {
        PENDING: 'PENDING' as IssuedStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as IssuedStatusEnum,
        SUCCEEDED: 'SUCCEEDED' as IssuedStatusEnum,
        FAILED: 'FAILED' as IssuedStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as IssuedStatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as IssuedStatusEnum
    };
    export type PaymentStatusEnum = 'SUCCEEDED' | 'FAILED' | 'REFUNDED' | 'PENDING';
    export const PaymentStatusEnum = {
        SUCCEEDED: 'SUCCEEDED' as PaymentStatusEnum,
        FAILED: 'FAILED' as PaymentStatusEnum,
        REFUNDED: 'REFUNDED' as PaymentStatusEnum,
        PENDING: 'PENDING' as PaymentStatusEnum
    };
    export type PaymentTypeEnum = 'BALANCE' | 'CREDIT' | 'ATM_DEBIT' | 'KREDIVO' | 'AIRPAY' | 'VNPAYQR' | 'VIETTELPAY' | 'MOMO' | 'ZALO' | 'PAYOO' | 'CASH' | 'TRANSFER' | 'PARTNER' | 'VPBANK' | 'OTHER';
    export const PaymentTypeEnum = {
        BALANCE: 'BALANCE' as PaymentTypeEnum,
        CREDIT: 'CREDIT' as PaymentTypeEnum,
        ATMDEBIT: 'ATM_DEBIT' as PaymentTypeEnum,
        KREDIVO: 'KREDIVO' as PaymentTypeEnum,
        AIRPAY: 'AIRPAY' as PaymentTypeEnum,
        VNPAYQR: 'VNPAYQR' as PaymentTypeEnum,
        VIETTELPAY: 'VIETTELPAY' as PaymentTypeEnum,
        MOMO: 'MOMO' as PaymentTypeEnum,
        ZALO: 'ZALO' as PaymentTypeEnum,
        PAYOO: 'PAYOO' as PaymentTypeEnum,
        CASH: 'CASH' as PaymentTypeEnum,
        TRANSFER: 'TRANSFER' as PaymentTypeEnum,
        PARTNER: 'PARTNER' as PaymentTypeEnum,
        VPBANK: 'VPBANK' as PaymentTypeEnum,
        OTHER: 'OTHER' as PaymentTypeEnum
    };
    export type SaleChannelEnum = 'B2B' | 'B2C' | 'B2B2C' | 'B2C_WEB' | 'B2C_WEB_APP' | 'B2C_MOBILE';
    export const SaleChannelEnum = {
        B2B: 'B2B' as SaleChannelEnum,
        B2C: 'B2C' as SaleChannelEnum,
        B2B2C: 'B2B2C' as SaleChannelEnum,
        B2CWEB: 'B2C_WEB' as SaleChannelEnum,
        B2CWEBAPP: 'B2C_WEB_APP' as SaleChannelEnum,
        B2CMOBILE: 'B2C_MOBILE' as SaleChannelEnum
    };
    export type StatusEnum = 'PENDING' | 'BOOKING_ON_PROCESS' | 'BOOKED' | 'PARTLY_BOOKED' | 'FAILED' | 'PARTLY_FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'TICKET_ON_PROCESS' | 'INACTIVE';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as StatusEnum,
        BOOKED: 'BOOKED' as StatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as StatusEnum,
        FAILED: 'FAILED' as StatusEnum,
        PARTLYFAILED: 'PARTLY_FAILED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        TENTATIVE: 'TENTATIVE' as StatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type SupplierBookingStatusEnum = 'PENDING' | 'BOOKED' | 'CONFIRMED' | 'FAILED' | 'CANCELLED' | 'EXPIRED' | 'TENTATIVE' | 'BOOKING_ON_PROCESS' | 'TICKET_ON_PROCESS' | 'PARTLY_BOOKED' | 'PARTLY_CONFIRMED' | 'ISSUE_FAILED' | 'CONFIRM_OFFLINE';
    export const SupplierBookingStatusEnum = {
        PENDING: 'PENDING' as SupplierBookingStatusEnum,
        BOOKED: 'BOOKED' as SupplierBookingStatusEnum,
        CONFIRMED: 'CONFIRMED' as SupplierBookingStatusEnum,
        FAILED: 'FAILED' as SupplierBookingStatusEnum,
        CANCELLED: 'CANCELLED' as SupplierBookingStatusEnum,
        EXPIRED: 'EXPIRED' as SupplierBookingStatusEnum,
        TENTATIVE: 'TENTATIVE' as SupplierBookingStatusEnum,
        BOOKINGONPROCESS: 'BOOKING_ON_PROCESS' as SupplierBookingStatusEnum,
        TICKETONPROCESS: 'TICKET_ON_PROCESS' as SupplierBookingStatusEnum,
        PARTLYBOOKED: 'PARTLY_BOOKED' as SupplierBookingStatusEnum,
        PARTLYCONFIRMED: 'PARTLY_CONFIRMED' as SupplierBookingStatusEnum,
        ISSUEFAILED: 'ISSUE_FAILED' as SupplierBookingStatusEnum,
        CONFIRMOFFLINE: 'CONFIRM_OFFLINE' as SupplierBookingStatusEnum
    };
    export type SupplierTypeEnum = 'AIR' | 'HOTEL' | 'COMBO' | 'TOURS' | 'TRAIN' | 'SHIP' | 'OTHER';
    export const SupplierTypeEnum = {
        AIR: 'AIR' as SupplierTypeEnum,
        HOTEL: 'HOTEL' as SupplierTypeEnum,
        COMBO: 'COMBO' as SupplierTypeEnum,
        TOURS: 'TOURS' as SupplierTypeEnum,
        TRAIN: 'TRAIN' as SupplierTypeEnum,
        SHIP: 'SHIP' as SupplierTypeEnum,
        OTHER: 'OTHER' as SupplierTypeEnum
    };
}
