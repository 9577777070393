/**
 * b2b_gateway API
 * b2b_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { BookingSearchResultDTO } from '../model/bookingSearchResultDTO';
import { BookingSummaryDTO } from '../model/bookingSummaryDTO';
import { BookingTransactionTO } from '../model/bookingTransactionTO';
import { OtaResultOfBookingDTO } from '../model/otaResultOfBookingDTO';
import { OtaResultOfstring } from '../model/otaResultOfstring';
import { PageOfBookingTransactionTO } from '../model/pageOfBookingTransactionTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BookingTransactionResourceService {

    protected basePath = 'https://10.7.71.50:8588/b2b_gateway';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * cleanBookingExpired
     *
     * @param conditions conditions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cleanBookingExpiredUsingDELETE(conditions: any, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public cleanBookingExpiredUsingDELETE(conditions: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public cleanBookingExpiredUsingDELETE(conditions: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public cleanBookingExpiredUsingDELETE(conditions: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (conditions === null || conditions === undefined) {
            throw new Error('Required parameter conditions was null or undefined when calling cleanBookingExpiredUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.delete<OtaResultOfstring>(`${this.basePath}/api/clean-booking-expired`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteBookingAndRefund
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBookingAndRefundUsingDELETE(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public deleteBookingAndRefundUsingDELETE(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public deleteBookingAndRefundUsingDELETE(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public deleteBookingAndRefundUsingDELETE(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling deleteBookingAndRefundUsingDELETE.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<OtaResultOfstring>(`${this.basePath}/api/delete_refund/booking`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteBooking
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBookingUsingDELETE(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfBookingDTO>;
    public deleteBookingUsingDELETE(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfBookingDTO>>;
    public deleteBookingUsingDELETE(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfBookingDTO>>;
    public deleteBookingUsingDELETE(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling deleteBookingUsingDELETE.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<OtaResultOfBookingDTO>(`${this.basePath}/api/delete/booking`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exporTransactions
     *
     * @param productCode productCode
     * @param status status
     * @param agencyCode agencyCode
     * @param bookingNumber bookingNumber
     * @param providerBrn providerBrn
     * @param bookingTagId bookingTagId
     * @param email email
     * @param customerName customerName
     * @param supplierCode supplierCode
     * @param bookingDateFrom bookingDateFrom
     * @param bookingDateTo bookingDateTo
     * @param checkInDate checkInDate
     * @param checkOutDate checkOutDate
     * @param typeCode typeCode
     * @param issuedDateFrom issuedDateFrom
     * @param issuedDateTo issuedDateTo
     * @param paymentType paymentType
     * @param action action
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exporTransactionsUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, bookingTagId?: number, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<BookingTransactionTO>>;
    public exporTransactionsUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, bookingTagId?: number, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BookingTransactionTO>>>;
    public exporTransactionsUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, bookingTagId?: number, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BookingTransactionTO>>>;
    public exporTransactionsUsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, bookingTagId?: number, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productCode !== undefined && productCode !== null) {
            queryParameters = queryParameters.set('productCode', <any>productCode);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (providerBrn !== undefined && providerBrn !== null) {
            queryParameters = queryParameters.set('providerBrn', <any>providerBrn);
        }
        if (bookingTagId !== undefined && bookingTagId !== null) {
            queryParameters = queryParameters.set('bookingTagId', <any>bookingTagId);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (customerName !== undefined && customerName !== null) {
            queryParameters = queryParameters.set('customerName', <any>customerName);
        }
        if (supplierCode !== undefined && supplierCode !== null) {
            queryParameters = queryParameters.set('supplierCode', <any>supplierCode);
        }
        if (bookingDateFrom !== undefined && bookingDateFrom !== null) {
            queryParameters = queryParameters.set('bookingDateFrom', <any>bookingDateFrom);
        }
        if (bookingDateTo !== undefined && bookingDateTo !== null) {
            queryParameters = queryParameters.set('bookingDateTo', <any>bookingDateTo);
        }
        if (checkInDate !== undefined && checkInDate !== null) {
            queryParameters = queryParameters.set('checkInDate', <any>checkInDate);
        }
        if (checkOutDate !== undefined && checkOutDate !== null) {
            queryParameters = queryParameters.set('checkOutDate', <any>checkOutDate);
        }
        if (typeCode !== undefined && typeCode !== null) {
            queryParameters = queryParameters.set('typeCode', <any>typeCode);
        }
        if (issuedDateFrom !== undefined && issuedDateFrom !== null) {
            queryParameters = queryParameters.set('issuedDateFrom', <any>issuedDateFrom);
        }
        if (issuedDateTo !== undefined && issuedDateTo !== null) {
            queryParameters = queryParameters.set('issuedDateTo', <any>issuedDateTo);
        }
        if (paymentType !== undefined && paymentType !== null) {
            queryParameters = queryParameters.set('paymentType', <any>paymentType);
        }
        if (action !== undefined && action !== null) {
            queryParameters = queryParameters.set('action', <any>action);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<BookingTransactionTO>>(`${this.basePath}/api/_export/booking-transactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportTransactionsV2
     *
     * @param productCode productCode
     * @param status status
     * @param agencyCode agencyCode
     * @param bookingNumber bookingNumber
     * @param providerBrn providerBrn
     * @param email email
     * @param customerName customerName
     * @param supplierCode supplierCode
     * @param bookingDateFrom bookingDateFrom
     * @param bookingDateTo bookingDateTo
     * @param checkInDate checkInDate
     * @param checkOutDate checkOutDate
     * @param typeCode typeCode
     * @param issuedDateFrom issuedDateFrom
     * @param issuedDateTo issuedDateTo
     * @param paymentType paymentType
     * @param action action
     * @param bookingTagId bookingTagId
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportTransactionsV2UsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, bookingTagId?: number, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<PageOfBookingTransactionTO>;
    public exportTransactionsV2UsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, bookingTagId?: number, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOfBookingTransactionTO>>;
    public exportTransactionsV2UsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, bookingTagId?: number, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOfBookingTransactionTO>>;
    public exportTransactionsV2UsingGET(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, bookingTagId?: number, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productCode !== undefined && productCode !== null) {
            queryParameters = queryParameters.set('productCode', <any>productCode);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (providerBrn !== undefined && providerBrn !== null) {
            queryParameters = queryParameters.set('providerBrn', <any>providerBrn);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (customerName !== undefined && customerName !== null) {
            queryParameters = queryParameters.set('customerName', <any>customerName);
        }
        if (supplierCode !== undefined && supplierCode !== null) {
            queryParameters = queryParameters.set('supplierCode', <any>supplierCode);
        }
        if (bookingDateFrom !== undefined && bookingDateFrom !== null) {
            queryParameters = queryParameters.set('bookingDateFrom', <any>bookingDateFrom);
        }
        if (bookingDateTo !== undefined && bookingDateTo !== null) {
            queryParameters = queryParameters.set('bookingDateTo', <any>bookingDateTo);
        }
        if (checkInDate !== undefined && checkInDate !== null) {
            queryParameters = queryParameters.set('checkInDate', <any>checkInDate);
        }
        if (checkOutDate !== undefined && checkOutDate !== null) {
            queryParameters = queryParameters.set('checkOutDate', <any>checkOutDate);
        }
        if (typeCode !== undefined && typeCode !== null) {
            queryParameters = queryParameters.set('typeCode', <any>typeCode);
        }
        if (issuedDateFrom !== undefined && issuedDateFrom !== null) {
            queryParameters = queryParameters.set('issuedDateFrom', <any>issuedDateFrom);
        }
        if (issuedDateTo !== undefined && issuedDateTo !== null) {
            queryParameters = queryParameters.set('issuedDateTo', <any>issuedDateTo);
        }
        if (paymentType !== undefined && paymentType !== null) {
            queryParameters = queryParameters.set('paymentType', <any>paymentType);
        }
        if (action !== undefined && action !== null) {
            queryParameters = queryParameters.set('action', <any>action);
        }
        if (bookingTagId !== undefined && bookingTagId !== null) {
            queryParameters = queryParameters.set('bookingTagId', <any>bookingTagId);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PageOfBookingTransactionTO>(`${this.basePath}/api/_export/booking-transactions-v2`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingTransactionSummary
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingTransactionSummaryUsingGET(observe?: 'body', reportProgress?: boolean): Observable<BookingSummaryDTO>;
    public getBookingTransactionSummaryUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingSummaryDTO>>;
    public getBookingTransactionSummaryUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingSummaryDTO>>;
    public getBookingTransactionSummaryUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingSummaryDTO>(`${this.basePath}/api/_summary/booking-transactions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * reSendEmail
     *
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reSendEmailUsingPOST(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public reSendEmailUsingPOST(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public reSendEmailUsingPOST(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public reSendEmailUsingPOST(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling reSendEmailUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/api/_send-email/booking-transactions`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchObjects
     *
     * @param productCode productCode
     * @param status status
     * @param agencyCode agencyCode
     * @param bookingNumber bookingNumber
     * @param providerBrn providerBrn
     * @param email email
     * @param customerName customerName
     * @param supplierCode supplierCode
     * @param bookingDateFrom bookingDateFrom
     * @param bookingDateTo bookingDateTo
     * @param checkInDate checkInDate
     * @param checkOutDate checkOutDate
     * @param typeCode typeCode
     * @param bookingChannel bookingChannel
     * @param issuedDateFrom issuedDateFrom
     * @param issuedDateTo issuedDateTo
     * @param paymentType paymentType
     * @param action action
     * @param phoneNumber phoneNumber
     * @param bookingTagId bookingTagId
     * @param ignoreSummary ignoreSummary
     * @param paymentStatus paymentStatus
     * @param hasIncludeBookingContent hasIncludeBookingContent
     * @param page Page number of the requested page
     * @param size Size of a page
     * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchObjectsUsingGET2(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, bookingChannel?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, phoneNumber?: string, bookingTagId?: number, ignoreSummary?: boolean, paymentStatus?: string, hasIncludeBookingContent?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<BookingSearchResultDTO>;
    public searchObjectsUsingGET2(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, bookingChannel?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, phoneNumber?: string, bookingTagId?: number, ignoreSummary?: boolean, paymentStatus?: string, hasIncludeBookingContent?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BookingSearchResultDTO>>;
    public searchObjectsUsingGET2(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, bookingChannel?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, phoneNumber?: string, bookingTagId?: number, ignoreSummary?: boolean, paymentStatus?: string, hasIncludeBookingContent?: boolean, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BookingSearchResultDTO>>;
    public searchObjectsUsingGET2(productCode?: string, status?: string, agencyCode?: string, bookingNumber?: string, providerBrn?: string, email?: string, customerName?: string, supplierCode?: string, bookingDateFrom?: string, bookingDateTo?: string, checkInDate?: string, checkOutDate?: string, typeCode?: string, bookingChannel?: string, issuedDateFrom?: string, issuedDateTo?: string, paymentType?: string, action?: string, phoneNumber?: string, bookingTagId?: number, ignoreSummary?: boolean, paymentStatus?: string, hasIncludeBookingContent?: boolean, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





























        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productCode !== undefined && productCode !== null) {
            queryParameters = queryParameters.set('productCode', <any>productCode);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (agencyCode !== undefined && agencyCode !== null) {
            queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
        }
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }
        if (providerBrn !== undefined && providerBrn !== null) {
            queryParameters = queryParameters.set('providerBrn', <any>providerBrn);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (customerName !== undefined && customerName !== null) {
            queryParameters = queryParameters.set('customerName', <any>customerName);
        }
        if (supplierCode !== undefined && supplierCode !== null) {
            queryParameters = queryParameters.set('supplierCode', <any>supplierCode);
        }
        if (bookingDateFrom !== undefined && bookingDateFrom !== null) {
            queryParameters = queryParameters.set('bookingDateFrom', <any>bookingDateFrom);
        }
        if (bookingDateTo !== undefined && bookingDateTo !== null) {
            queryParameters = queryParameters.set('bookingDateTo', <any>bookingDateTo);
        }
        if (checkInDate !== undefined && checkInDate !== null) {
            queryParameters = queryParameters.set('checkInDate', <any>checkInDate);
        }
        if (checkOutDate !== undefined && checkOutDate !== null) {
            queryParameters = queryParameters.set('checkOutDate', <any>checkOutDate);
        }
        if (typeCode !== undefined && typeCode !== null) {
            queryParameters = queryParameters.set('typeCode', <any>typeCode);
        }
        if (bookingChannel !== undefined && bookingChannel !== null) {
            queryParameters = queryParameters.set('bookingChannel', <any>bookingChannel);
        }
        if (issuedDateFrom !== undefined && issuedDateFrom !== null) {
            queryParameters = queryParameters.set('issuedDateFrom', <any>issuedDateFrom);
        }
        if (issuedDateTo !== undefined && issuedDateTo !== null) {
            queryParameters = queryParameters.set('issuedDateTo', <any>issuedDateTo);
        }
        if (paymentType !== undefined && paymentType !== null) {
            queryParameters = queryParameters.set('paymentType', <any>paymentType);
        }
        if (action !== undefined && action !== null) {
            queryParameters = queryParameters.set('action', <any>action);
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
            queryParameters = queryParameters.set('phoneNumber', <any>phoneNumber);
        }
        if (bookingTagId !== undefined && bookingTagId !== null) {
            queryParameters = queryParameters.set('bookingTagId', <any>bookingTagId);
        }
        if (ignoreSummary !== undefined && ignoreSummary !== null) {
            queryParameters = queryParameters.set('ignoreSummary', <any>ignoreSummary);
        }
        if (paymentStatus !== undefined && paymentStatus !== null) {
            queryParameters = queryParameters.set('paymentStatus', <any>paymentStatus);
        }
        if (hasIncludeBookingContent !== undefined && hasIncludeBookingContent !== null) {
            queryParameters = queryParameters.set('hasIncludeBookingContent', <any>hasIncludeBookingContent);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('sort', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BookingSearchResultDTO>(`${this.basePath}/api/_search/booking-transactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
