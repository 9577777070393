import {FormGroup, ValidatorFn, ValidationErrors, Validators} from '@angular/forms';
import {error} from "util";

// custom validator to check that two fields match
export function MustNotDuplicate(
  pax: string,
): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors => {
    const control = formGroup.controls[pax];

    if (control.errors && !control.errors.mustNotDuplicate) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    let begin = 0;
    let startIndex = null;
    let finishIndex = null;
    for(let index = 0; index < control.value.length; index++){
      if (control.get(index.toString()).hasError('mustNotDuplicate')) {
        control.get(index.toString()).setErrors(null);
      }
    }
     const paxAllInfo = control.value.filter(
      control => control && control.lastName && control.firstName
    );
    paxAllInfo.forEach(pax => {
      if (pax !== null) {
        let end = begin + 1;
        paxAllInfo.slice(end, paxAllInfo.length).forEach(paxElm => {
          if (paxElm && paxElm.lastName && paxElm.firstName) {
            if (paxElm.lastName.toUpperCase() === pax.lastName.toUpperCase() && paxElm.firstName.toUpperCase() === pax.firstName.toUpperCase() && paxElm.sex === pax.sex) {
              startIndex = begin;
              finishIndex = end;
            }
          }
          end++;
        })
        begin++;
      }
    })
    if (startIndex !== null && finishIndex !== null) {
      control.get(startIndex.toString()).setErrors({mustNotDuplicate: true});
      control.get(finishIndex.toString()).setErrors({mustNotDuplicate: true});
    }
  };
}
