/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AATUpdateBookingRefNumberDTO } from '../model/aATUpdateBookingRefNumberDTO';
import { AgentAccountTransactionDTO } from '../model/agentAccountTransactionDTO';
import { AgentSumTotalTransRes } from '../model/agentSumTotalTransRes';
import { OtaResultOfint } from '../model/otaResultOfint';
import { OtaSearchResultOfListOfAgentAccountTransactionDTO } from '../model/otaSearchResultOfListOfAgentAccountTransactionDTO';
import { OtaSearchResultOfTopupTransSearchResult } from '../model/otaSearchResultOfTopupTransSearchResult';
import { OtaSearchResultOfdouble } from '../model/otaSearchResultOfdouble';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AgentAccountTransactionResourceService {

  protected basePath = 'https://10.7.71.20:8588/pricingsrv';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * createAgentAccountTransaction
   *
   * @param agentAccountTransactionDTO agentAccountTransactionDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAgentAccountTransactionUsingPOST(agentAccountTransactionDTO: AgentAccountTransactionDTO, observe?: 'body', reportProgress?: boolean): Observable<AgentAccountTransactionDTO>;
  public createAgentAccountTransactionUsingPOST(agentAccountTransactionDTO: AgentAccountTransactionDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentAccountTransactionDTO>>;
  public createAgentAccountTransactionUsingPOST(agentAccountTransactionDTO: AgentAccountTransactionDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentAccountTransactionDTO>>;
  public createAgentAccountTransactionUsingPOST(agentAccountTransactionDTO: AgentAccountTransactionDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (agentAccountTransactionDTO === null || agentAccountTransactionDTO === undefined) {
      throw new Error('Required parameter agentAccountTransactionDTO was null or undefined when calling createAgentAccountTransactionUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<AgentAccountTransactionDTO>(`${this.basePath}/api/agent-account-transactions`,
      agentAccountTransactionDTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * deleteAgentAccountTransaction
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAgentAccountTransactionUsingDELETE(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deleteAgentAccountTransactionUsingDELETE(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deleteAgentAccountTransactionUsingDELETE(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deleteAgentAccountTransactionUsingDELETE(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteAgentAccountTransactionUsingDELETE.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.delete<any>(`${this.basePath}/api/agent-account-transactions/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getAgentAccountTransaction
   *
   * @param id id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAgentAccountTransactionUsingGET(id: number, observe?: 'body', reportProgress?: boolean): Observable<AgentAccountTransactionDTO>;
  public getAgentAccountTransactionUsingGET(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentAccountTransactionDTO>>;
  public getAgentAccountTransactionUsingGET(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentAccountTransactionDTO>>;
  public getAgentAccountTransactionUsingGET(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getAgentAccountTransactionUsingGET.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.get<AgentAccountTransactionDTO>(`${this.basePath}/api/agent-account-transactions/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getAllAgentAccountTransactions
   *
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllAgentAccountTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<AgentAccountTransactionDTO>>;
  public getAllAgentAccountTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AgentAccountTransactionDTO>>>;
  public getAllAgentAccountTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AgentAccountTransactionDTO>>>;
  public getAllAgentAccountTransactionsUsingGET(page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.get<Array<AgentAccountTransactionDTO>>(`${this.basePath}/api/agent-account-transactions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * getLatestAgentAccountTransaction
   *
   * @param itemCode itemCode
   * @param isAgency isAgency
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLatestAgentAccountTransactionUsingGET(itemCode: string, isAgency: boolean, observe?: 'body', reportProgress?: boolean): Observable<AgentAccountTransactionDTO>;
  public getLatestAgentAccountTransactionUsingGET(itemCode: string, isAgency: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentAccountTransactionDTO>>;
  public getLatestAgentAccountTransactionUsingGET(itemCode: string, isAgency: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentAccountTransactionDTO>>;
  public getLatestAgentAccountTransactionUsingGET(itemCode: string, isAgency: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (itemCode === null || itemCode === undefined) {
      throw new Error('Required parameter itemCode was null or undefined when calling getLatestAgentAccountTransactionUsingGET.');
    }

    if (isAgency === null || isAgency === undefined) {
      throw new Error('Required parameter isAgency was null or undefined when calling getLatestAgentAccountTransactionUsingGET.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (itemCode !== undefined && itemCode !== null) {
      queryParameters = queryParameters.set('itemCode', <any>itemCode);
    }
    if (isAgency !== undefined && isAgency !== null) {
      queryParameters = queryParameters.set('isAgency', <any>isAgency);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.get<AgentAccountTransactionDTO>(`${this.basePath}/api/agent-account-transactions/latest`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * searchAgencyTransaction
   *
   * @param agencyCode agencyCode
   * @param branchCode branchCode
   * @param createdBy createdBy
   * @param bookingRefNumber bookingRefNumber
   * @param transDateFrom transDateFrom
   * @param transDateTo transDateTo
   * @param amountFrom amountFrom
   * @param amountTo amountTo
   * @param transType transType
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchAgencyTransactionUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfListOfAgentAccountTransactionDTO>;
  public searchAgencyTransactionUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfListOfAgentAccountTransactionDTO>>;
  public searchAgencyTransactionUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfListOfAgentAccountTransactionDTO>>;
  public searchAgencyTransactionUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }
    if (branchCode !== undefined && branchCode !== null) {
      queryParameters = queryParameters.set('branchCode', <any>branchCode);
    }
    if (createdBy !== undefined && createdBy !== null) {
      queryParameters = queryParameters.set('createdBy', <any>createdBy);
    }
    if (bookingRefNumber !== undefined && bookingRefNumber !== null) {
      queryParameters = queryParameters.set('bookingRefNumber', <any>bookingRefNumber);
    }
    if (transDateFrom !== undefined && transDateFrom !== null) {
      queryParameters = queryParameters.set('transDateFrom', <any>transDateFrom);
    }
    if (transDateTo !== undefined && transDateTo !== null) {
      queryParameters = queryParameters.set('transDateTo', <any>transDateTo);
    }
    if (amountFrom !== undefined && amountFrom !== null) {
      queryParameters = queryParameters.set('amountFrom', <any>amountFrom);
    }
    if (amountTo !== undefined && amountTo !== null) {
      queryParameters = queryParameters.set('amountTo', <any>amountTo);
    }
    if (transType !== undefined && transType !== null) {
      queryParameters = queryParameters.set('transType', <any>transType);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.get<OtaSearchResultOfListOfAgentAccountTransactionDTO>(`${this.basePath}/api/_search/agency-transactions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * searchBookerTransaction
   *
   * @param agentCode agentCode
   * @param bookingRefNumber bookingRefNumber
   * @param transDateFrom transDateFrom
   * @param transDateTo transDateTo
   * @param amountFrom amountFrom
   * @param amountTo amountTo
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchBookerTransactionUsingGET(agentCode?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfListOfAgentAccountTransactionDTO>;
  public searchBookerTransactionUsingGET(agentCode?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfListOfAgentAccountTransactionDTO>>;
  public searchBookerTransactionUsingGET(agentCode?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfListOfAgentAccountTransactionDTO>>;
  public searchBookerTransactionUsingGET(agentCode?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (agentCode !== undefined && agentCode !== null) {
      queryParameters = queryParameters.set('agentCode', <any>agentCode);
    }
    if (bookingRefNumber !== undefined && bookingRefNumber !== null) {
      queryParameters = queryParameters.set('bookingRefNumber', <any>bookingRefNumber);
    }
    if (transDateFrom !== undefined && transDateFrom !== null) {
      queryParameters = queryParameters.set('transDateFrom', <any>transDateFrom);
    }
    if (transDateTo !== undefined && transDateTo !== null) {
      queryParameters = queryParameters.set('transDateTo', <any>transDateTo);
    }
    if (amountFrom !== undefined && amountFrom !== null) {
      queryParameters = queryParameters.set('amountFrom', <any>amountFrom);
    }
    if (amountTo !== undefined && amountTo !== null) {
      queryParameters = queryParameters.set('amountTo', <any>amountTo);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.get<OtaSearchResultOfListOfAgentAccountTransactionDTO>(`${this.basePath}/api/_search/booker-transactions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * searchTopupObjects
   *
   * @param agencyCode agencyCode
   * @param createdBy createdBy
   * @param transDateFrom transDateFrom
   * @param transDateTo transDateTo
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchTopupObjectsUsingGET(agencyCode?: string, createdBy?: string, transDateFrom?: string, transDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfListOfAgentAccountTransactionDTO>;
  public searchTopupObjectsUsingGET(agencyCode?: string, createdBy?: string, transDateFrom?: string, transDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfListOfAgentAccountTransactionDTO>>;
  public searchTopupObjectsUsingGET(agencyCode?: string, createdBy?: string, transDateFrom?: string, transDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfListOfAgentAccountTransactionDTO>>;
  public searchTopupObjectsUsingGET(agencyCode?: string, createdBy?: string, transDateFrom?: string, transDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }
    if (createdBy !== undefined && createdBy !== null) {
      queryParameters = queryParameters.set('createdBy', <any>createdBy);
    }
    if (transDateFrom !== undefined && transDateFrom !== null) {
      queryParameters = queryParameters.set('transDateFrom', <any>transDateFrom);
    }
    if (transDateTo !== undefined && transDateTo !== null) {
      queryParameters = queryParameters.set('transDateTo', <any>transDateTo);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.get<OtaSearchResultOfListOfAgentAccountTransactionDTO>(`${this.basePath}/api/_search/topup-transactions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * searchTopupTrans
   *
   * @param agencyCode agencyCode
   * @param transDateFrom transDateFrom
   * @param transDateTo transDateTo
   * @param page Page number of the requested page
   * @param size Size of a page
   * @param sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchTopupTransUsingGET(agencyCode: string, transDateFrom?: string, transDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfTopupTransSearchResult>;
  public searchTopupTransUsingGET(agencyCode: string, transDateFrom?: string, transDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfTopupTransSearchResult>>;
  public searchTopupTransUsingGET(agencyCode: string, transDateFrom?: string, transDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfTopupTransSearchResult>>;
  public searchTopupTransUsingGET(agencyCode: string, transDateFrom?: string, transDateTo?: string, page?: number, size?: number, sort?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (agencyCode === null || agencyCode === undefined) {
      throw new Error('Required parameter agencyCode was null or undefined when calling searchTopupTransUsingGET.');
    }






    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }
    if (transDateFrom !== undefined && transDateFrom !== null) {
      queryParameters = queryParameters.set('transDateFrom', <any>transDateFrom);
    }
    if (transDateTo !== undefined && transDateTo !== null) {
      queryParameters = queryParameters.set('transDateTo', <any>transDateTo);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', <any>page);
    }
    if (size !== undefined && size !== null) {
      queryParameters = queryParameters.set('size', <any>size);
    }
    if (sort) {
      sort.forEach((element) => {
        queryParameters = queryParameters.append('sort', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.get<OtaSearchResultOfTopupTransSearchResult>(`${this.basePath}/api/search/topup-transactions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * sumAgencyTransaction
   *
   * @param agencyCode agencyCode
   * @param branchCode branchCode
   * @param createdBy createdBy
   * @param bookingRefNumber bookingRefNumber
   * @param transDateFrom transDateFrom
   * @param transDateTo transDateTo
   * @param amountFrom amountFrom
   * @param amountTo amountTo
   * @param transType transType
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sumAgencyTransactionUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: string, observe?: 'body', reportProgress?: boolean): Observable<OtaSearchResultOfdouble>;
  public sumAgencyTransactionUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaSearchResultOfdouble>>;
  public sumAgencyTransactionUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaSearchResultOfdouble>>;
  public sumAgencyTransactionUsingGET(agencyCode?: string, branchCode?: string, createdBy?: string, bookingRefNumber?: string, transDateFrom?: string, transDateTo?: string, amountFrom?: string, amountTo?: string, transType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }
    if (branchCode !== undefined && branchCode !== null) {
      queryParameters = queryParameters.set('branchCode', <any>branchCode);
    }
    if (createdBy !== undefined && createdBy !== null) {
      queryParameters = queryParameters.set('createdBy', <any>createdBy);
    }
    if (bookingRefNumber !== undefined && bookingRefNumber !== null) {
      queryParameters = queryParameters.set('bookingRefNumber', <any>bookingRefNumber);
    }
    if (transDateFrom !== undefined && transDateFrom !== null) {
      queryParameters = queryParameters.set('transDateFrom', <any>transDateFrom);
    }
    if (transDateTo !== undefined && transDateTo !== null) {
      queryParameters = queryParameters.set('transDateTo', <any>transDateTo);
    }
    if (amountFrom !== undefined && amountFrom !== null) {
      queryParameters = queryParameters.set('amountFrom', <any>amountFrom);
    }
    if (amountTo !== undefined && amountTo !== null) {
      queryParameters = queryParameters.set('amountTo', <any>amountTo);
    }
    if (transType !== undefined && transType !== null) {
      queryParameters = queryParameters.set('transType', <any>transType);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.get<OtaSearchResultOfdouble>(`${this.basePath}/api/_sum/agency-transactions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * sumTotalAgencyTransaction
   *
   * @param agencyCode agencyCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sumTotalAgencyTransactionUsingGET(agencyCode?: string, observe?: 'body', reportProgress?: boolean): Observable<AgentSumTotalTransRes>;
  public sumTotalAgencyTransactionUsingGET(agencyCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentSumTotalTransRes>>;
  public sumTotalAgencyTransactionUsingGET(agencyCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentSumTotalTransRes>>;
  public sumTotalAgencyTransactionUsingGET(agencyCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (agencyCode !== undefined && agencyCode !== null) {
      queryParameters = queryParameters.set('agencyCode', <any>agencyCode);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];

    return this.httpClient.get<AgentSumTotalTransRes>(`${this.basePath}/api/_sum/total-agency-transactions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * updateAgentAccountTransaction
   *
   * @param agentAccountTransactionDTO agentAccountTransactionDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAgentAccountTransactionUsingPUT(agentAccountTransactionDTO: AgentAccountTransactionDTO, observe?: 'body', reportProgress?: boolean): Observable<AgentAccountTransactionDTO>;
  public updateAgentAccountTransactionUsingPUT(agentAccountTransactionDTO: AgentAccountTransactionDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AgentAccountTransactionDTO>>;
  public updateAgentAccountTransactionUsingPUT(agentAccountTransactionDTO: AgentAccountTransactionDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AgentAccountTransactionDTO>>;
  public updateAgentAccountTransactionUsingPUT(agentAccountTransactionDTO: AgentAccountTransactionDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (agentAccountTransactionDTO === null || agentAccountTransactionDTO === undefined) {
      throw new Error('Required parameter agentAccountTransactionDTO was null or undefined when calling updateAgentAccountTransactionUsingPUT.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<AgentAccountTransactionDTO>(`${this.basePath}/api/agent-account-transactions`,
      agentAccountTransactionDTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * updateTempBookingRefNumber
   *
   * @param bookingRefNumberDTO bookingRefNumberDTO
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTempBookingRefNumberUsingPOST(bookingRefNumberDTO: AATUpdateBookingRefNumberDTO, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfint>;
  public updateTempBookingRefNumberUsingPOST(bookingRefNumberDTO: AATUpdateBookingRefNumberDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfint>>;
  public updateTempBookingRefNumberUsingPOST(bookingRefNumberDTO: AATUpdateBookingRefNumberDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfint>>;
  public updateTempBookingRefNumberUsingPOST(bookingRefNumberDTO: AATUpdateBookingRefNumberDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (bookingRefNumberDTO === null || bookingRefNumberDTO === undefined) {
      throw new Error('Required parameter bookingRefNumberDTO was null or undefined when calling updateTempBookingRefNumberUsingPOST.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      '*/*'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<OtaResultOfint>(`${this.basePath}/api/_update/temp/booking_ref_number`,
      bookingRefNumberDTO,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
