import { Action } from '@ngrx/store';
import { FilterAvailableState } from './filter-available.reducer';

export enum FilterAvailableActionTypes {
  ComboVJResetHotelFilter = '[ComboVJ_HotelFilter] Reset HotelFilter',
  ComboVJChangeHotelFilter = '[ComboVJ_HotelFilter] Change HotelFilter',
  ComboVJChangeFilterHotelName = '[ComboVJ_HotelFilter] Change Filter Hotel Name',
  ComboVJChangeFilterHotelCategories = '[ComboVJ_HotelFilter] Change Filter Hotel Categories',
  ComboVJChangeFilterBreakfastIncluded = '[ComboVJ_HotelFilter] Change Filter Breakfast Included',
  ComboVJChangeFilterPrice = '[ComboVJ_HotelFilter] Change Filter Price',
  ComboVJChangeFilterStarRating = '[ComboVJ_HotelFilter] Change Filter Star Rating',
  ComboVJChangeFilterGuestRating = '[ComboVJ_HotelFilter] Change Filter Guest Rating',
  ComboVJChangeFilterRoomAmenities = '[ComboVJ_HotelFilter] Change Filter Room Amenities',
  ComboVJChangeFilterPropertyAmenities = '[ComboVJ_HotelFilter] Change Filter Property Amenities',
  ComboVJChangeFilterRoomViews = '[ComboVJ_HotelFilter] Change Filter Room Views',
  ComboVJChangeFilterThemes = '[ComboVJ_HotelFilter] Change Filter Themes',
  ComboVJChangeFilterMealPlans = '[ComboVJ_HotelFilter] Change Filter Meal Plans',
  ComboVJChangeFilterLanguage = '[ComboVJ_HotelFilter] Change Filter Language',
  ComboVJChangeSort = '[ComboVJ_HotelFilter] Change Sort',
  ComboVJChangePageNumber = '[ComboVJ_HotelFilter] Change Page Number',
  ComboVJChangePageSize = '[ComboVJ_HotelFilter] Change Page Size'
}

export class ComboVJResetHotelFilter implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJResetHotelFilter;
}

export class ComboVJChangeHotelFilter implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeHotelFilter;
  constructor(public payload: FilterAvailableState) {}
}

export class ComboVJChangeFilterHotelName implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeFilterHotelName;
  constructor(public payload: string) {}
}

export class ComboVJChangeFilterHotelCategories implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeFilterHotelCategories;
  constructor(public payload: Array<string>) {}
}

export class ComboVJChangeFilterBreakfastIncluded implements Action {
  readonly type =
    FilterAvailableActionTypes.ComboVJChangeFilterBreakfastIncluded;
  constructor(public payload: boolean) {}
}

export class ComboVJChangeFilterPrice implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeFilterPrice;
  constructor(public payload: { fromPrice: number; toPrice: number }) {}
}

export class ComboVJChangeFilterStarRating implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeFilterStarRating;
  constructor(public payload: Array<string>) {}
}

export class ComboVJChangeFilterGuestRating implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeFilterGuestRating;
  constructor(public payload: { from: number; to: number }) {}
}

export class ComboVJChangeFilterRoomAmenities implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeFilterRoomAmenities;
  constructor(public payload: Array<string>) {}
}

export class ComboVJChangeFilterPropertyAmenities implements Action {
  readonly type =
    FilterAvailableActionTypes.ComboVJChangeFilterPropertyAmenities;
  constructor(public payload: Array<string>) {}
}

export class ComboVJChangeFilterRoomViews implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeFilterRoomViews;
  constructor(public payload: Array<string>) {}
}

export class ComboVJChangeFilterThemes implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeFilterThemes;
  constructor(public payload: Array<string>) {}
}

export class ComboVJChangeFilterMealPlans implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeFilterMealPlans;
  constructor(public payload: Array<string>) {}
}

export class ComboVJChangeFilterLanguage implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeFilterLanguage;
  constructor(public payload: 'vi' | 'en') {}
}

export class ComboVJChangeSort implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangeSort;
  constructor(
    public payload: {
      sortField: 'order' | 'price' | 'starRating' | 'guestRating';
      sortOrder: 'ASC' | 'DESC';
    }
  ) {}
}

export class ComboVJChangePageNumber implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangePageNumber;
  constructor(public payload: number) {}
}

export class ComboVJChangePageSize implements Action {
  readonly type = FilterAvailableActionTypes.ComboVJChangePageSize;
  constructor(public payload: number) {}
}

export type FilterAvailableAction =
  | ComboVJResetHotelFilter
  | ComboVJChangeHotelFilter
  | ComboVJChangeFilterHotelName
  | ComboVJChangeFilterHotelCategories
  | ComboVJChangeFilterBreakfastIncluded
  | ComboVJChangeFilterPrice
  | ComboVJChangeFilterStarRating
  | ComboVJChangeFilterGuestRating
  | ComboVJChangeFilterRoomAmenities
  | ComboVJChangeFilterPropertyAmenities
  | ComboVJChangeFilterRoomViews
  | ComboVJChangeFilterThemes
  | ComboVJChangeFilterMealPlans
  | ComboVJChangeFilterLanguage
  | ComboVJChangeSort
  | ComboVJChangePageNumber
  | ComboVJChangePageSize;

export const fromFilterAvailableActions = {
  ComboVJResetHotelFilter,
  ComboVJChangeHotelFilter,
  ComboVJChangeFilterHotelName,
  ComboVJChangeFilterHotelCategories,
  ComboVJChangeFilterBreakfastIncluded,
  ComboVJChangeFilterPrice,
  ComboVJChangeFilterStarRating,
  ComboVJChangeFilterGuestRating,
  ComboVJChangeFilterRoomAmenities,
  ComboVJChangeFilterPropertyAmenities,
  ComboVJChangeFilterRoomViews,
  ComboVJChangeFilterThemes,
  ComboVJChangeFilterMealPlans,
  ComboVJChangeFilterLanguage,
  ComboVJChangeSort,
  ComboVJChangePageNumber,
  ComboVJChangePageSize
};
