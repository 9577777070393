/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AgentAccountDTO { 
    accountCode?: string;
    accountName?: string;
    accountType?: AgentAccountDTO.AccountTypeEnum;
    agencyCode?: string;
    agentCode?: string;
    agentId?: number;
    agentLoginId?: string;
    balance?: number;
    cash?: number;
    commission?: number;
    createdBy?: string;
    createdDate?: Date;
    credit?: number;
    id?: number;
    orgCode?: string;
    parentAgencyCode?: string;
    revenue?: number;
    updateDate?: Date;
    updatedBy?: string;
}
export namespace AgentAccountDTO {
    export type AccountTypeEnum = 'BOOKER' | 'AGENCY';
    export const AccountTypeEnum = {
        BOOKER: 'BOOKER' as AccountTypeEnum,
        AGENCY: 'AGENCY' as AccountTypeEnum
    };
}
