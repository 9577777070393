import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {initialState as profileInitialState, PROFILE_FEATURE_KEY, reducer} from './+state/profile.reducer';

import {ProfileFacade} from './+state/profile.facade';
import {NxModule} from '@nrwl/angular';
import {ProfileEffects} from './+state/profile.effects';
import {AccountResourceService, BASE_PATH, CustomerResourceService} from '@gtd/b2c-client';
import {environment} from '@env/environment';
import {CustomerProfileResourceService} from '@gtd/api-services/customer-service';
import {AgentAvatarResourceService} from "@gtd/api-services/agentsrv";
import {FlexModule} from "@angular/flex-layout";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule
} from "@angular/material";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [],
    imports: [
        CommonModule,
        NxModule.forRoot(),
        StoreModule.forFeature(PROFILE_FEATURE_KEY, reducer, {
            initialState: profileInitialState
        }),
        EffectsModule.forFeature([ProfileEffects]),
        FlexModule,
        FontAwesomeModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        TranslateModule
    ],
  providers: [
    {
      provide: BASE_PATH,
      useValue: environment.api.b2cClient
    },
    ProfileFacade,
    CustomerResourceService,
    CustomerProfileResourceService,
    AccountResourceService,
    AgentAvatarResourceService
  ]
})
export class ProfileModule {}
