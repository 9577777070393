/**
 * bookingsrv API
 * bookingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookingContactDTO } from './bookingContactDTO';
import { BookingExtraService } from './bookingExtraService';
import { BookingTaxReceiptRequestDTO } from './bookingTaxReceiptRequestDTO';
import { BookingTravelerInfoDTO } from './bookingTravelerInfoDTO';
import { OSICode } from './oSICode';
import { OTPServiceReq } from './oTPServiceReq';


export interface BookingTravellerRQ { 
    baseUrl?: string;
    bookingContacts?: Array<BookingContactDTO>;
    bookingNote?: string;
    bookingNumber?: string;
    bookingTravelerInfos?: Array<BookingTravelerInfoDTO>;
    extraServices?: Array<BookingExtraService>;
    ibeSessionId?: string;
    osiCodes?: Array<OSICode>;
    otpServiceReq?: OTPServiceReq;
    requesterCode?: string;
    requesterType?: BookingTravellerRQ.RequesterTypeEnum;
    taxReceiptRequest?: BookingTaxReceiptRequestDTO;
}
export namespace BookingTravellerRQ {
    export type RequesterTypeEnum = 'B2B' | 'B2C' | 'PARTNER';
    export const RequesterTypeEnum = {
        B2B: 'B2B' as RequesterTypeEnum,
        B2C: 'B2C' as RequesterTypeEnum,
        PARTNER: 'PARTNER' as RequesterTypeEnum
    };
}
