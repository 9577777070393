import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  Inject,
  OnInit,
  Optional,
  PLATFORM_ID
} from '@angular/core';
import { VIEWER } from '@gtd/utilities';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gtd-favorite-recomment',
  templateUrl: './favorite-recomment.component.html',
  styleUrls: ['./favorite-recomment.component.scss']
})
export class FavoriteRecommentComponent implements OnInit {
  tabList = [
    {
      title: 'hot-flight',
      id: 81
    },
    {
      title: 'best-hotel',
      id: 82
    },
    {
      title: 'saving-combo',
      id: 83
    },
    {
      title: 'entertainment',
      id: 84
    }
  ];
  tabActiveSubject$ = new BehaviorSubject<number>(null);
  tabActive$ = this.tabActiveSubject$.asObservable();
  listItems: any;
  categories: any;
  constructor(
    public httpClient: HttpClient,
    @Optional() @Inject(VIEWER) private viewer,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && this.viewer !== 'bot') {
      this.httpClient
        .get(`https://cms.gotadi.com/wp-json/wp/v2/categories`)
        .subscribe(categories => {
          this.categories = categories;
          this.tabActiveSubject$.next(categories[0].id);
        });
    }
    this.tabActive$.subscribe(id => {
      if (id) {
        this.httpClient
          .get(
            `https://cms.gotadi.com/wp-json/wp/v2/posts?categories=${id}&sticky=true`
          )
          .subscribe(res => {
            this.listItems = res;
          });
      }
    });
  }
}
