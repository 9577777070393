import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-extra-section',
  templateUrl: './extra-section.component.html',
  styleUrls: ['./extra-section.component.scss']
})
export class ExtraSectionComponent implements OnInit {
  constructor() {}
  extras = [
    {
      title: 'extra-train',
      icon: 'train'
    },
    {
      title: 'extra-car',
      icon: 'car'
    },
    {
      title: 'extra-tours',
      icon: 'tourguide',
      link: '/gotadi-extra'
    },
    {
      title: 'extra-sightseeing',
      icon: 'parks'
    },
    {
      title: 'extra-visa',
      icon: 'visa'
    },
    {
      title: 'extra-welcome',
      icon: 'transport'
    }
  ];
  ngOnInit() {}
}
