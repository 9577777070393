import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourBookingItemComponent } from './tour-booking-item.component';
import {
  MatButtonModule,
  MatCardModule,
  MatMenuModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
library.add(faChevronLeft, faChevronRight);
@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    TranslateModule,
    FontAwesomeModule,
    RouterModule
  ],
  declarations: [TourBookingItemComponent],
  exports: [TourBookingItemComponent]
})
export class TourBookingItemModule {}
