import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators
} from '@angular/forms';
import { MustMatch } from '@gtd/helpers';
import { PhoneRegisterStateFacade } from '../phone-register-state/+state/phone-register-state.facade';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PhoneResendOtpStateFacade } from '../phone-register-state/+resenotp-state/phone-resend-otp-state.facade';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs/Rx';
import {
  TimeCountDown,
  TimeCountDownBetweenDirective
} from '@gtd/directives/time-count-down-between';
import { PixelTrackingService } from '@gtd/pixel-tracking';

@Component({
  selector: 'gtd-phone-register-otp',
  templateUrl: './phone-register-otp.component.html',
  styleUrls: ['./phone-register-otp.component.scss']
})
export class PhoneRegisterOtpComponent implements OnInit, OnDestroy {
  otpForm?: FormGroup;
  isLoading: boolean;
  hide = true;
  subscriptions = new Subscription();
  subscribeTimer: number;
  registeredData: any;
  timeCountDown: TimeCountDown;
  verificationCode: string;
  queryString?: string;
  redirectParamEncode;
  
  constructor(
    private formBuilder: FormBuilder,
    private phoneRegisterStateFacade: PhoneRegisterStateFacade,
    private router: Router,
    private timeCountDownBetweenDirective: TimeCountDownBetweenDirective,
    private phoneResendOtpStateFacade: PhoneResendOtpStateFacade,
    public dialog: MatDialog,
    private _pixelTrackingService: PixelTrackingService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.createForm();
    this.activatedRoute.queryParams.subscribe(param => {
      if (param && param.params) {
        this.redirectParamEncode = param.params
      }
    });
  }

  ngOnInit() {
    if (!this.queryString) {
      this.queryString = window.location.search.substring(1);
    }

    const params = {};
    const queries = this.queryString.split('&');
    queries.forEach((indexQuery: string) => {
      const indexPair = indexQuery.split('=');
      const queryKey = decodeURIComponent(indexPair[0]);
      params[queryKey] = decodeURIComponent(
        indexPair.length > 1 ? indexPair[1] : ''
      );
    });
    this.registeredData = JSON.parse(atob(params['key']));
    // Start time count down
    this.otpTimer();

    this.subscriptions.add(
      this.phoneRegisterStateFacade.allPhoneRegisterState$.subscribe(
        register => {
          if (register && register.status) {
            switch (register.status) {
              case 'active':
                // this.filumAnalytics.track('New User Signed Up', {
                //   email: this.registeredData.phone,
                //   activated: 0
                // });
                this._pixelTrackingService.track('CompleteRegistration');

                this.dialog.open(DialogMessageComponent, {
                  data: {
                    btn: [{ btnText: 'global.close' }],
                    content: 'user.register-phone-success',
                    dataTranslate: this.registeredData.phone,
                    heading: 'user.register-success',
                    icon: 'check',
                    messageType: 'success'
                  },
                  panelClass: 'dialog-message',
                  position: {
                    top: '200px'
                  },
                  disableClose: true
                });
                this.phoneRegisterStateFacade.resetAllRegister();
                this.router.navigate(['auth/login'], {queryParams: {params: this.redirectParamEncode}}).then(() => {});
                break;
              case 'error':
                this.otpForm.controls['otpCode'].setErrors(['OtpDoesNotMatch']);
                this.verificationCode = JSON.parse(
                  register.result.error.error.message
                ).verificationCode;
                break;
            }
          }
          this.isLoading = false;
        }
      )
    );

    this.phoneResendOtpStateFacade.allPhoneResendOtpState$.subscribe(
      resendOTP => {
        if (resendOTP) {
          this.verificationCode = resendOTP.result.verificationCode;
        }
      }
    );
  }

  resendOtp(formDirective: FormGroupDirective) {
    this.phoneResendOtpStateFacade.phoneResendOTP({
      otpCode: null,
      password: null,
      phoneNumber: this.registeredData.phone,
      firstName: this.registeredData.firstName,
      lastName: this.registeredData.lastName,
      verificationCode: this.verificationCode
        ? this.verificationCode
        : this.registeredData.verificationCode,
      language: this.registeredData.language,
      userRefCode: this.registeredData.userRefCode,
      referencedBy: this.registeredData.referencedBy
    });

    // Reset and clear validation error.
    this.otpForm.reset({
      otpCode: '',
      password: '',
      confirmPassword: ''
    });
    formDirective.resetForm();
  }

  submitForm() {
    this.isLoading = true;

    this.phoneRegisterStateFacade.phoneOTPRegisterAccount({
      otpCode: this.otpForm.get('otpCode').value,
      password: this.otpForm.get('password').value,
      phoneNumber: this.registeredData.phone,
      firstName: this.registeredData.firstName,
      lastName: this.registeredData.lastName,
      verificationCode: this.verificationCode
        ? this.verificationCode
        : this.registeredData.verificationCode,
      language: this.registeredData.language,
      userRefCode: this.registeredData.userRefCode,
      referencedBy: this.registeredData.referencedBy
    });
  }

  otpTimer() {
    let totalTimeCountDown = new Date().setSeconds(
      new Date().getSeconds() + 181
    );

    this.subscriptions.add(
      Observable.timer(0, 1000).subscribe(() => {
        this.timeCountDown = this.timeCountDownBetweenDirective.timeBetweenDates(
          new Date(totalTimeCountDown)
        );
        this.subscribeTimer =
          parseInt(this.timeCountDown.minutes) * 60 +
          parseInt(this.timeCountDown.seconds);
        setTimeout(() => {
          if (
            this.timeCountDown &&
            this.timeCountDown.seconds === '00' &&
            this.timeCountDown.minutes === '00' &&
            this.timeCountDown.hours === '00' &&
            this.timeCountDown.days === '00'
          ) {
            this.subscriptions.unsubscribe();
            this.phoneRegisterStateFacade.destroyPhoneOTPRegister();
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                content: 'user.otp-expired',
                heading: 'user.registration-failed',
                icon: 'exclamation-triangle',
                messageType: 'warning'
              },
              panelClass: 'dialog-message',
              position: {
                top: '200px'
              },
              disableClose: true
            });
            setTimeout(() => this.router.navigate(['auth/register'], {queryParams: {params: this.redirectParamEncode}}), 1000);
          }
        }, 500);
      })
    );
  }

  createForm() {
    this.otpForm = this.formBuilder.group(
      {
        otpCode: ['', [Validators.required, Validators.maxLength(6)]],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      },
      {
        validators: MustMatch('password', 'confirmPassword')
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
