import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {
  FilterAvailablePartialState,
  FilterAvailableState
} from './filter-available.reducer';
import { filterAvailableQuery } from './filter-available.selectors';
import {
  ChangeFilterRoomAmenities,
  ChangeFilterPropertyAmenities,
  ChangeFilterRoomViews,
  ChangeFilterThemes,
  ChangeFilterMealPlans,
  ChangeFilterBreakfastIncluded,
  ChangeFilterGuestRating,
  ChangeFilterHotelCategories,
  ChangeFilterHotelName,
  ChangeFilterLanguage,
  ChangeFilterPrice,
  ChangeFilterStarRating,
  ChangeHotelFilter,
  ChangePageNumber,
  ChangePageSize,
  ChangeSort,
  ResetHotelFilter,
  ChangeFilterBedTypes,
  ChangeFilterRateAmenities,
  ChangeFilterDistance
} from './filter-available.actions';

@Injectable()
export class FilterAvailableFacade {
  currentFilter$ = this.store.pipe(
    select(filterAvailableQuery.getAllCurrentFilter)
  );
  priceFilter$ = this.store.pipe(
    select(filterAvailableQuery.getCurrentFilterPrice)
  );
  // currentAmenitiesFilter$ = this.store.pipe(
  //   select(filterAvailableQuery.getCurrentAmenitiesFilter)
  // );

  constructor(private store: Store<FilterAvailablePartialState>) {}

  resetFilter() {
    this.store.dispatch(new ResetHotelFilter());
  }

  changeHotelFilter(filterAvailableState: FilterAvailableState) {
    this.store.dispatch(new ChangeHotelFilter(filterAvailableState));
  }

  changeFilterHotelName(hotelName: string) {
    this.store.dispatch(new ChangeFilterHotelName(hotelName));
  }

  changeFilterHotelCategories(hotelCategories: Array<string>) {
    this.store.dispatch(new ChangeFilterHotelCategories(hotelCategories));
  }

  changeFilterBreakfastIncluded(breakfastIncluded: boolean) {
    this.store.dispatch(new ChangeFilterBreakfastIncluded(breakfastIncluded));
  }

  changeFilterPrice(fromPrice: number, toPrice: number) {
    this.store.dispatch(
      new ChangeFilterPrice({ fromPrice: fromPrice, toPrice: toPrice })
    );
  }

  changeFilterDistance(fromDistance: number, toDistance: number) {
    this.store.dispatch(
      new ChangeFilterDistance({
        fromDistance: fromDistance,
        toDistance: toDistance
      })
    );
  }

  changeFilterStarRating(starRating: Array<string>) {
    this.store.dispatch(new ChangeFilterStarRating(starRating));
  }

  changeFilterGuestRating(from: number, to: number) {
    this.store.dispatch(new ChangeFilterGuestRating({ from: from, to: to }));
  }

  changeFilterRoomAmenities(filterRoomAmenities: Array<string>) {
    this.store.dispatch(new ChangeFilterRoomAmenities(filterRoomAmenities));
  }

  changeFilterRateAmenities(filterRateAmenities: Array<string>) {
    this.store.dispatch(new ChangeFilterRateAmenities(filterRateAmenities));
  }

  changeFilterPropertyAmenities(filterPropertyAmenities: Array<string>) {
    this.store.dispatch(
      new ChangeFilterPropertyAmenities(filterPropertyAmenities)
    );
  }

  changeFilterRoomViews(filterRoomViews: Array<string>) {
    this.store.dispatch(new ChangeFilterRoomViews(filterRoomViews));
  }

  changeFilterThemes(filterThemes: Array<string>) {
    this.store.dispatch(new ChangeFilterThemes(filterThemes));
  }

  changeFilterMealPlans(filterMealPlans: Array<string>) {
    this.store.dispatch(new ChangeFilterMealPlans(filterMealPlans));
  }

  changeFilterBedTypes(filterBedTypes: Array<string>) {
    this.store.dispatch(new ChangeFilterBedTypes(filterBedTypes));
  }

  changeFilterLanguage(language: 'vi' | 'en') {
    this.store.dispatch(new ChangeFilterLanguage(language));
  }

  changeSort(
    sortField: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder: 'ASC' | 'DESC'
  ) {
    this.store.dispatch(
      new ChangeSort({ sortField: sortField, sortOrder: sortOrder })
    );
  }

  changePageNumber(pageNumber: number) {
    this.store.dispatch(new ChangePageNumber(pageNumber));
  }

  changePageSize(pageSize: number) {
    this.store.dispatch(new ChangePageSize(pageSize));
  }
}
