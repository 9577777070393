import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import {
  AccountFacade,
  AdditionalRequestResourceService
} from '@gtd/b2c-client';
import { Subscription } from 'rxjs';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AdditionalRequestDetailComponent } from '../additional-request-detail/additional-request-detail.component';
import { fadeIn, fadeInOut } from '@gtd/helpers';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GetUserProfileFacade } from '@gtd/api-services/b2b-client';

@Component({
  selector: 'gtd-additional-request-management',
  templateUrl: './additional-request-management.component.html',
  styleUrls: ['./additional-request-management.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('600ms ease-out')),
      transition('default => rotated', animate('600ms ease-in'))
    ]),
    fadeIn,
    fadeInOut
  ]
})
export class AdditionalRequestManagementComponent implements OnInit {
  isLoading = true;
  additionalRequests: any[] = [];
  subscriptions = new Subscription();
  currentPage = 1;
  pageSize = 8;
  totalAdditionalRequests = 0;
  configPagination: any;
  requester = '';
  isOpenFilterBox = false;
  hasFilter = false;
  statusList = [
    {
      code: 'PENDING',
      name: 'additional-request.pending',
      selected: false
    },
    {
      code: 'WAITING_FEEDBACK',
      name: 'additional-request.waiting-feedback',
      selected: false
    },
    {
      code: 'PROCESSED',
      name: 'additional-request.processed',
      selected: false
    },
    {
      code: 'CANCELLED',
      name: 'additional-request.cancelled',
      selected: false
    },
    {
      code: 'FAILED',
      name: 'additional-request.failed',
      selected: false
    }
  ];
  typesList = [
    {
      code: 'AIR_ADD_PACKAGE',
      name: 'booking.buy-signed-luggage-for-ticket',
      selected: false
    },
    {
      code: 'AIR_ADD_INFORMATION',
      name: 'booking.change-information-on-ticket',
      selected: false
    },
    {
      code: 'AIR_DELETE_BOOKING',
      name: 'booking.refund-cancellation-ticket',
      selected: false
    },
    {
      code: 'HOTEL_ADD_PERSON',
      name: 'booking.more-people',
      selected: false
    },
    {
      code: 'HOTEL_EDIT_BOOKING',
      name: 'booking.change-room-information',
      selected: false
    },
    {
      code: 'HOTEL_CHANGE_ROOM',
      name: 'booking.change-room',
      selected: false
    },
    {
      code: 'HOTEL_DELETE_BOOKING',
      name: 'booking.refund-cancellation-room',
      selected: false
    },
    {
      code: 'ALL_OTHER',
      name: 'booking.other-requirement',
      selected: false
    }
  ];
  filterForm: any;
  appName = localStorage.getItem('appName');

  constructor(
    private additionalRequestResourceService: AdditionalRequestResourceService,
    private deviceService: DeviceDetectorService,
    private accountFacade: AccountFacade,
    public dialog: MatDialog,
    private getUserProfileFacade: GetUserProfileFacade,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.pageSize = this.deviceService.isDesktop() ? 8 : 5;
    this.filterForm = this.formBuilder.group({
      statuses: this.buildFormArray(this.statusList),
      types: this.buildFormArray(this.typesList)
    });
    if (this.appName === 'B2C') {
      this.subscriptions.add(
        this.accountFacade.getAccountLogged$.subscribe(selectedAccount => {
          if (selectedAccount && selectedAccount.email) {
            this.requester = selectedAccount.email;
            this.getAdditionalRequests();
          }
        })
      );
    } else if (this.appName === 'B2B') {
      this.subscriptions.add(
        this.getUserProfileFacade.allGetUserProfile$.subscribe(userProfile => {
          if (userProfile && userProfile.loginUsername) {
            this.requester = userProfile.loginUsername;
            this.getAdditionalRequests();
          }
        })
      );
    }
  }

  getAdditionalRequests(type: string = null, status: string = null) {
    this.isLoading = false;
    this.additionalRequestResourceService
      .searchBookingAdditionalRequestsUsingGET(
        null,
        type,
        null,
        null,
        status,
        this.requester,
        null,
        null,
        null,
        null,
        null,
        this.appName === 'B2B' ? 'B2B' : 'B2C_WEB',
        this.currentPage - 1,
        this.pageSize
      )
      .subscribe(
        resp => {
          this.isLoading = true;
          this.totalAdditionalRequests = resp.totalElements;
          this.additionalRequests = [];
          if (resp.content.length > 0) {
            this.additionalRequests = JSON.parse(JSON.stringify(resp.content));
            this.additionalRequests.forEach(item => {
              item.requestTypeName = '';
              switch (item.requestType) {
                case 'AIR_ADD_PACKAGE': {
                  item.requestTypeName =
                    'booking.buy-signed-luggage-for-ticket';
                  break;
                }
                case 'AIR_ADD_INFORMATION': {
                  item.requestTypeName = 'booking.change-information-on-ticket';
                  break;
                }
                case 'AIR_DELETE_BOOKING': {
                  item.requestTypeName = 'booking.refund-cancellation-ticket';
                  break;
                }
                case 'HOTEL_ADD_PERSON': {
                  item.requestTypeName = 'booking.more-people';
                  break;
                }
                case 'HOTEL_EDIT_BOOKING': {
                  item.requestTypeName = 'booking.change-room-information';
                  break;
                }
                case 'HOTEL_DELETE_BOOKING': {
                  item.requestTypeName = 'booking.refund-cancellation-room';
                  break;
                }
                case 'HOTEL_CHANGE_ROOM': {
                  item.requestTypeName = 'booking.change-room';
                  break;
                }
                default: {
                  item.requestTypeName = 'booking.other-requirement';
                  break;
                }
              }
            });
          }
        },
        () => {
          this.isLoading = true;
        }
      );
  }

  get statuses(): FormArray {
    return this.filterForm.get('statuses') as FormArray;
  }

  get types(): FormArray {
    return this.filterForm.get('types') as FormArray;
  }

  buildFormArray(list: any[]): FormArray {
    const formArr = list.map(item => {
      return this.formBuilder.control(item.selected);
    });
    return this.formBuilder.array(formArr);
  }

  clearFilter() {
    this.types.controls.forEach(element => {
      element.patchValue(false);
    });
    this.statuses.controls.forEach(element => {
      element.patchValue(false);
    });
    this.hasFilter = false;
  }

  filterAdditionalRequest() {
    this.currentPage = 1;
    this.hasFilter = true;
    let types: any[] = [];
    this.types.controls.forEach((element, index) => {
      if (element.value) {
        types.push(this.typesList[index].code);
      }
    });
    let statuses: any[] = [];
    this.statuses.controls.forEach((element, index) => {
      if (element.value) {
        statuses.push(this.statusList[index].code);
      }
    });
    this.isOpenFilterBox = false;
    this.additionalRequests = [];
    this.getAdditionalRequests(
      types.length > 0 ? types.join(',') : null,
      statuses.length > 0 ? statuses.join(',') : null
    );
  }

  pageChanged(event: number) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.currentPage = event;
    this.additionalRequests = [];
    if (this.hasFilter) {
      let types: any[] = [];
      this.types.controls.forEach((element, index) => {
        if (element.value) {
          types.push(this.typesList[index].code);
        }
      });
      let statuses: any[] = [];
      this.statuses.controls.forEach((element, index) => {
        if (element.value) {
          statuses.push(this.statusList[index].code);
        }
      });
      this.getAdditionalRequests(
        types.length > 0 ? types.join(', ') : null,
        statuses.length > 0 ? statuses.join(', ') : null
      );
    } else {
      this.getAdditionalRequests();
    }
  }

  openAdditionalRequestDetailDialog(additonalRequest: any) {
    const dialogRef = this.dialog.open(AdditionalRequestDetailComponent, {
      data: {
        additionalRequestItem: additonalRequest
      },
      position: {
        right: '0',
        top: '0'
      },
      autoFocus: false,
      panelClass: [
        'animate__animated',
        'animate__slideInRight',
        'animate__faster',
        'additional-request-detail-dialog-panel-class'
      ],
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.action === 'cancel-request') {
        this.ngOnInit();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
