/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PropertyValueDTO } from './propertyValueDTO';


export interface PropertyDTO {
    arrange: number;
    category: PropertyDTO.CategoryEnum;
    code: number;
    id?: number;
    name: string;
    propertyValues?: Array<PropertyValueDTO>;
}
export namespace PropertyDTO {
    export type CategoryEnum = 'INTERNAL_CONFIG' | 'BANNER' | 'B2C_REF_CODE';
    export const CategoryEnum = {
        INTERNALCONFIG: 'INTERNAL_CONFIG' as CategoryEnum,
        BANNER: 'BANNER' as CategoryEnum,
        B2CREFCODE: 'B2C_REF_CODE' as CategoryEnum
    };
}
