/**
 * pricingsrv API
 * pricingsrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface BankTransferHistoryDTO { 
    actionStatus?: BankTransferHistoryDTO.ActionStatusEnum;
    actionType?: BankTransferHistoryDTO.ActionTypeEnum;
    amount?: number;
    checkSum?: string;
    createdBy?: string;
    createdDate?: Date;
    currency?: string;
    description?: string;
    id?: number;
    note?: string;
    refCode?: string;
    sourceAccount?: string;
    supplierTransStatus?: string;
    targetAccount?: string;
    transactionCrDr?: string;
    transactionType?: string;
    transferDate?: Date;
    updatedBy?: string;
    updatedDate?: Date;
}
export namespace BankTransferHistoryDTO {
    export type ActionStatusEnum = 'PENDING' | 'SUCCESSED' | 'FAILED' | 'UNKNOWN';
    export const ActionStatusEnum = {
        PENDING: 'PENDING' as ActionStatusEnum,
        SUCCESSED: 'SUCCESSED' as ActionStatusEnum,
        FAILED: 'FAILED' as ActionStatusEnum,
        UNKNOWN: 'UNKNOWN' as ActionStatusEnum
    };
    export type ActionTypeEnum = 'TOPUP_AGENT_ACCOUNT' | 'PAY_BOOKING' | 'UNKNOWN';
    export const ActionTypeEnum = {
        TOPUPAGENTACCOUNT: 'TOPUP_AGENT_ACCOUNT' as ActionTypeEnum,
        PAYBOOKING: 'PAY_BOOKING' as ActionTypeEnum,
        UNKNOWN: 'UNKNOWN' as ActionTypeEnum
    };
}
