import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComboHotelDetailFacade } from '../../../../../state/hotel-detail/hotel-detail.facade';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AirRevalidateRS,
  AirTicketsResourceService,
  GroupPricedItinerary,
  HotelResourceV3Service,
  ItineraryInfoVM,
  OtaResultOfCheckoutResult
} from '@gtd/b2c-client';
import { HotelCheckoutFacade } from '../../../../../state/hotel-checkout/hotel-checkout.facade';
import { Subscription } from 'rxjs';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { ComboCreateDraftBookingFacade } from '../../../../../state/combo-create-draft-booking/combo-create-draft-booking.facade';
import { ComboVJComboBookingFacade } from '../../../../../state/combo-booking/combo-booking.facade';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'gtd-hotel-room-detail',
  templateUrl: './hotel-room-detail.component.html',
  styleUrls: ['./hotel-room-detail.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class HotelRoomDetailComponent implements OnInit, OnDestroy {
  @Input() roomsArrs: any;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  allHotelDetail$ = this.hotelDetailFacade.allHotelDetail$;
  roomItemFormGroup: FormGroup;
  detailSearchFormGroup: FormGroup;
  allAirTickitChose = this.comboVJComboBookingFacade.allComboBooking$;
  subscriptions = new Subscription();
  isLoading: boolean;
  newTab: any = null;
  appName = environment.appName;
  searchParams: any;
  params: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private hotelDetailFacade: ComboHotelDetailFacade,
    private hotelCheckoutFacade: HotelCheckoutFacade,
    private comboCreateDraftbookingFacade: ComboCreateDraftBookingFacade,
    private airTicketsResourceService: AirTicketsResourceService,
    private comboVJComboBookingFacade: ComboVJComboBookingFacade,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private hotelResourceV3Service: HotelResourceV3Service,
    private deviceService: DeviceDetectorService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.roomItemFormGroup = new FormGroup({
      ratePlanInfo: new FormControl('', Validators.required)
    });
    this.detailSearchFormGroup = new FormGroup({
      searchInfo: new FormControl('', Validators.required)
    });
    activatedRoute.queryParams.subscribe(params => {
      this.params = params.params;
      this.searchParams = JSON.parse(decodeURIComponent(atob(params.params)));
    });
  }

  ngOnInit() {
    this.detailSearchFormGroup.valueChanges.subscribe(detailSearchChange => {
      this.changed.emit(detailSearchChange.searchInfo);
    });
    const ip = localStorage.getItem('client-ip');
    this.roomItemFormGroup.valueChanges.subscribe(valueChange => {
      if (valueChange && valueChange.ratePlanInfo) {
        this.isLoading = true;
        this._snackBar.openFromComponent(SnackbarComponent, {
          data: {
            message: 'hotel-detail.checking-availability',
            loading: true
          },
          panelClass: 'snackbar-loading',
          verticalPosition: 'top'
        });
        this.hotelCheckoutFacade.hotelCheckout({
          tripId: valueChange.ratePlanInfo.tripId,
          roomId: valueChange.ratePlanInfo.roomId,
          ratePlanId: valueChange.ratePlanInfo.ratePlanId,
          metadata: {
            'customer-ip': ip
          }
        });
      }
    });
    this.subscriptions.add(
      this.comboCreateDraftbookingFacade.allComboCreateDraftBooking$.subscribe(
        createDraftBooking => {
          if (createDraftBooking) {
            this.isLoading = false;
            this._snackBar.dismiss();
            if (createDraftBooking.success) {
              localStorage.removeItem('flightBooking');
              // let url = this.router.serializeUrl(
              //   this.router.createUrlTree([
              //     `/checkout/combo?bookingNumber=${
              //       createDraftBooking.booking.bookingNumber
              //     }`
              //   ])
              // );
              // let newWindow = window.open('', '_blank');
              // newWindow.location.href = decodeURIComponent(url);
              if (isPlatformBrowser(this.platformId)) {
                window.parent.postMessage({ action: 'scrollToTop' }, '*');
              }
              this._snackBar.dismiss();
              let url = this.router.serializeUrl(
                this.router.createUrlTree([
                  `/checkout/combo?bookingNumber=${
                    createDraftBooking.booking.bookingNumber
                  }`
                ])
              );
              if (this.appName === 'B2B2C') {
                let newWindow = window.open('', '_self');
                newWindow.location.href = decodeURIComponent(url);
              } else {
                if (this.deviceService.isDesktop() === true) {
                  this.newTab.location.href = decodeURIComponent(url);
                } else {
                  this.router
                    .navigate(['/checkout/combo'], {
                      queryParams: {
                        bookingNumber: createDraftBooking.booking.bookingNumber
                      }
                    })
                    .then(() => {});
                }
              }
            } else {
              if (this.newTab !== null) {
                this.newTab.close();
              }
              this.isLoading = false;
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [{ btnText: 'global.close' }],
                  content: 'user.system-error-message',
                  heading: 'user.system-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true
              });
            }
          }
          this._snackBar.dismiss();
        },
        () => {
          if (this.newTab !== null) {
            this.newTab.close();
          }
          this._snackBar.dismiss();
          this.dialog.open(DialogMessageComponent, {
            data: {
              btn: [{ btnText: 'booking-result.reload', btnReload: true }],
              content: 'booking-result.message-error-validate-air-fare',
              heading: 'booking-result.validate-air-fare-error',
              icon: 'exclamation-triangle',
              messageType: 'error'
            },
            panelClass: 'dialog-message',
            disableClose: true
          });
        }
      )
    );
    this.subscriptions.add(
      this.comboCreateDraftbookingFacade.errorComboCreateDraftBooking$.subscribe(
        getErrors => {
          if (getErrors) {
            this.isLoading = false;
            this._snackBar.dismiss();
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                content: 'user.system-error-message',
                heading: 'user.system-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          }
        }
      )
    );
    this.subscriptions.add(
      this.hotelCheckoutFacade.allHotelCheckout$.subscribe(
        (hotelCheckout: OtaResultOfCheckoutResult) => {
          if (hotelCheckout) {
            if (hotelCheckout.success) {
              this._snackBar.dismiss();
              this.router.navigate(['combo/confirm'], {
                queryParams: {
                  params: this.params,
                  hotel: btoa(
                    encodeURIComponent(
                      JSON.stringify({
                        tripId: this.roomItemFormGroup.controls['ratePlanInfo']
                          .value.tripId,
                        roomId: this.roomItemFormGroup.controls['ratePlanInfo']
                          .value.roomId,
                        ratePlanId: this.roomItemFormGroup.controls[
                          'ratePlanInfo'
                        ].value.ratePlanId
                      })
                    )
                  )
                }
              });

              // this.comboCreateDraftbookingFacade.createDraftBooking({
              //   createDraftBookingHotelPayload: {
              //     tripId: this.roomItemFormGroup.controls['ratePlanInfo'].value
              //       .tripId,
              //     roomId: this.roomItemFormGroup.controls['ratePlanInfo'].value
              //       .roomId,
              //     ratePlanId: this.roomItemFormGroup.controls['ratePlanInfo']
              //       .value.ratePlanId
              //   },
              //   ticketDraftBookingVM: {
              //     itineraryInfos: itineraryInfoVM
              //   }
              // });
            } else {
              this._snackBar.dismiss();
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [
                    {
                      btnText: 'booking-result.reload',
                      btnReload: true
                    }
                  ],
                  content: 'hotel-detail.message-error-validate-room',
                  heading: 'hotel-detail.validate-room-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true
              });
            }
          }
        }
      )
    );
    this.subscriptions.add(
      this.hotelCheckoutFacade.getError$.subscribe(getError => {
        if (getError) {
          console.log(getError);
        }
      })
    );
  }
  getNewTab(newTab: any) {
    this.newTab = newTab;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
