import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {BookingDetailPartialState} from './booking-detail.reducer';
import {BookingDetailActionTypes, BookingDetailLoadError, LoadBookingDetail} from './booking-detail.actions';
import {map} from "rxjs/operators";
import {ProductResourceService} from "@gtd/b2c-client";

@Injectable()
export class BookingDetailEffects {
  @Effect() loadBookingDetail$ = this.dataPersistence.fetch(
    BookingDetailActionTypes.LoadBookingDetail,
    {
      run: (action: LoadBookingDetail, state: BookingDetailPartialState) => {
        return this.productResourceService.getBookingDetailUsingGET(action.payload).pipe(
          map(update => ({
            type: BookingDetailActionTypes.BookingDetailLoaded,
            payload: update
          }))
        );
      },

      onError: (action: LoadBookingDetail, error) => {
        console.error('Error', error);
        return new BookingDetailLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private productResourceService: ProductResourceService,
    private dataPersistence: DataPersistence<BookingDetailPartialState>
  ) {}
}
