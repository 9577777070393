import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {SearchResultComponent} from "./search-result/search-result.component";

const routes: Routes = [
  { path: '', component: SearchResultComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class FlightRoutingModule {}
