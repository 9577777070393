import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { HotelDetailPartialState } from './hotel-detail.reducer';
import {
  HotelDetailActionTypes,
  ComboVJHotelDetailLoadError,
  ComboVJLoadHotelDetail
} from './hotel-detail.actions';
import { map } from 'rxjs/operators';
import { HotelResourceV3Service } from '@gtd/b2c-client';

@Injectable()
export class HotelDetailEffects {
  @Effect() loadComboVJHotelDetail$ = this.dataPersistence.fetch(
    HotelDetailActionTypes.ComboVJLoadHotelDetail,
    {
      run: (action: ComboVJLoadHotelDetail, state: HotelDetailPartialState) => {
        this.hotelResourceV3Service.defaultHeaders = this.hotelResourceV3Service.defaultHeaders.set(
          'sales-env',
          'HOTEL_PACKAGE'
        );
        return this.hotelResourceV3Service
          .searchAllRatesUsingGET(
            action.payload.searchId,
            action.payload.propertyId,
            action.payload.supplier,
            action.payload.checkIn,
            action.payload.checkOut,
            action.payload.paxInfos
          )
          .pipe(
            map(payload => ({
              type: HotelDetailActionTypes.ComboVJHotelDetailLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: ComboVJLoadHotelDetail, error) => {
        console.error('Error', error);
        return new ComboVJHotelDetailLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private hotelResourceV3Service: HotelResourceV3Service,
    private dataPersistence: DataPersistence<HotelDetailPartialState>
  ) {}
}
