import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelDetailFacade } from '../../../state/hotel-detail/hotel-detail.facade';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  HotelResourceV3Service,
  OtaResultOfCheckoutResult,
  OtaResultOfSearchAllRatesResult
} from '@gtd/b2c-client';
import { HotelCheckoutFacade } from '../../../state/hotel-checkout/hotel-checkout.facade';
import { HotelCreateDraftBookingFacade } from '../../../state/hotel-create-draft-booking/hotel-create-draft-booking.facade';
import { Subscription } from 'rxjs';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { GaTrackingService } from '@gtd/ga-tracking';

@Component({
  selector: 'gtd-hotel-room-detail',
  templateUrl: './hotel-room-detail.component.html',
  styleUrls: ['./hotel-room-detail.component.scss']
})
export class HotelRoomDetailComponent implements OnInit, OnDestroy {
  @Input() roomsArrs: any;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  allHotelDetail$ = this.hotelDetailFacade.allHotelDetail$;
  allHotelDetail: OtaResultOfSearchAllRatesResult;
  roomItemFormGroup: FormGroup;
  detailSearchFormGroup: FormGroup;
  subscriptions = new Subscription();
  isLoading: boolean;
  searchParams: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private hotelDetailFacade: HotelDetailFacade,
    private hotelCheckoutFacade: HotelCheckoutFacade,
    private hotelCreateDraftBookingFacade: HotelCreateDraftBookingFacade,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private hotelResourceV3Service: HotelResourceV3Service,
    private router: Router,
    private _gaTrackingService: GaTrackingService
  ) {
    this.roomItemFormGroup = new FormGroup({
      ratePlanInfo: new FormControl('', Validators.required)
    });
    this.detailSearchFormGroup = new FormGroup({
      searchInfo: new FormControl('', Validators.required)
    });
    activatedRoute.queryParams.subscribe(params => {
      this.searchParams = JSON.parse(decodeURIComponent(atob(params.params)));
    });
  }

  getAdult(rooms: Array<any>): number {
    let adultQuantity = 0;
    rooms.forEach(room => {
      adultQuantity += room.adultQuantity;
    });
    return adultQuantity;
  }
  getChild(rooms: Array<any>): number {
    let childQuantity = 0;
    rooms.forEach(room => {
      childQuantity += room.childQuantity;
    });
    return childQuantity;
  }

  ngOnInit() {
    this.allHotelDetail$.subscribe(
      hotelDetail => (this.allHotelDetail = hotelDetail)
    );
    this.detailSearchFormGroup.valueChanges.subscribe(detailSearchChange => {
      this.changed.emit(detailSearchChange.searchInfo);
    });
    const ip = localStorage.getItem('client-ip');
    this.roomItemFormGroup.valueChanges.subscribe(valueChange => {
      if (valueChange && valueChange.ratePlanInfo) {
        this.isLoading = true;
        this._snackBar.openFromComponent(SnackbarComponent, {
          data: {
            message: 'hotel-detail.checking-availability',
            loading: true
          },
          panelClass: 'snackbar-loading',
          verticalPosition: 'top'
        });
        this.hotelCheckoutFacade.hotelCheckout({
          tripId: valueChange.ratePlanInfo.tripId,
          roomId: valueChange.ratePlanInfo.roomId,
          ratePlanId: valueChange.ratePlanInfo.ratePlanId,
          metadata: {
            'customer-ip': ip
          }
        });
      }
    });
    this.subscriptions.add(
      this.hotelCreateDraftBookingFacade.allHotelCreateDraftBooking$.subscribe(
        createDraftBooking => {
          if (createDraftBooking) {
            this._gaTrackingService.track('hotel_make_booking');
            this._gaTrackingService.track('make_booking');
            this.isLoading = false;
            this._snackBar.dismiss();
            if (createDraftBooking.success) {
              let paxInfos = JSON.parse(this.searchParams.paxInfos);
              let properties = {
                booking_number: createDraftBooking.result.bookingNumber,
                hotel_id: this.allHotelDetail.result.propertyAllRate.propertyId,
                hotel_name: this.allHotelDetail.result.propertyAllRate
                  .propertyName,
                room_id: this.roomItemFormGroup.controls['ratePlanInfo'].value
                  .roomId,
                room_type: this.roomItemFormGroup.controls['ratePlanInfo'].value
                  .roomName,
                room_count: this.searchParams.roomsString.length,
                total_day: this.allHotelDetail.result.propertyAllRate.rooms[0]
                  .ratePlans[0].paxPrice[0].nightPrices.length,
                guest_adult: this.getAdult(paxInfos),
                guess_child: this.getChild(paxInfos)
              };
              // this.filumAnalytics.track(
              //   'Hotel Draft Booking Created',
              //   properties
              // );
              setTimeout(_ => {
                let url = this.router.serializeUrl(
                  this.router.createUrlTree([
                    `/checkout/hotel?bookingNumber=${
                      createDraftBooking.result.bookingNumber
                    }`
                  ])
                );
                let newWindow = window.open('', '_self');
                newWindow.location.href = decodeURIComponent(url);
              }, 200);
            } else {
              this.isLoading = false;
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [{ btnText: 'global.close' }],
                  content: 'user.system-error-message',
                  heading: 'user.system-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true
              });
            }
          }
          this._snackBar.dismiss();
        }
      )
    );
    this.subscriptions.add(
      this.hotelCreateDraftBookingFacade.errorHotelCreateDraftBooking$.subscribe(
        getErrors => {
          if (getErrors) {
            this.isLoading = false;
            this._snackBar.dismiss();
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                content: 'user.system-error-message',
                heading: 'user.system-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          }
        }
      )
    );
    this.subscriptions.add(
      this.hotelCheckoutFacade.allHotelCheckout$.subscribe(
        (hotelCheckout: OtaResultOfCheckoutResult) => {
          if (hotelCheckout) {
            if (hotelCheckout.success) {
              this._snackBar.openFromComponent(SnackbarComponent, {
                data: {
                  message: 'hotel-detail.initializing-booking',
                  loading: true
                },
                panelClass: 'snackbar-loading',
                verticalPosition: 'top'
              });
              this.hotelCreateDraftBookingFacade.createDraftBooking({
                metadata: {
                  'customer-ip': ip
                },
                tripId: this.roomItemFormGroup.controls['ratePlanInfo'].value
                  .tripId,
                roomId: this.roomItemFormGroup.controls['ratePlanInfo'].value
                  .roomId,
                ratePlanId: this.roomItemFormGroup.controls['ratePlanInfo']
                  .value.ratePlanId
              });
            } else {
              this._snackBar.dismiss();
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [{ btnText: 'booking-result.reload', btnReload: true }],
                  content: 'hotel-detail.message-error-validate-room',
                  heading: 'hotel-detail.validate-room-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true
              });
            }
          }
        }
      )
    );
    this.subscriptions.add(
      this.hotelCheckoutFacade.getError$.subscribe(getError => {
        if (getError) {
          console.log(getError);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
