import { Component, OnInit } from '@angular/core';
import {environment} from "@env/environment";

@Component({
  selector: 'gtd-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  phoneSupport: string[];
  versionNewface: string;
  appName = localStorage.getItem('appName');
  constructor() { }

  ngOnInit() {
    this.phoneSupport = environment.web.phone;
    this.versionNewface = environment.web.version;
  }

}
