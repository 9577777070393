import { Component, OnInit } from '@angular/core';
import { slideInHorizontal } from '@gtd/helpers';
import { LowestPriceFlightsFacade } from '../flight-search/flight-lowest-price/+state/lowest-price-flights.facade';
import { FlightFilterFacade } from '../flight-search/flight-filter/+state/flight-filter.facade';

@Component({
  selector: 'gtd-brand-filter',
  templateUrl: './brand-filter.component.html',
  styleUrls: ['./brand-filter.component.scss'],
  animations: [slideInHorizontal]
})
export class BrandFilterComponent implements OnInit {
  isActive = false;

  allLowestPriceFlights$ = this.lowestPriceFlightsFacade.allLowestPriceFlights$;
  loaded$ = this.lowestPriceFlightsFacade.loaded$;
  selectedAirlineOptions$ = this.flightFilterFacade.selectedAirlineOptions$;

  constructor(
    private lowestPriceFlightsFacade: LowestPriceFlightsFacade,
    private flightFilterFacade: FlightFilterFacade
  ) {}

  ngOnInit() {}

  onBranchSelected(branchCode: string) {
    this.flightFilterFacade.changeAirlineOptions([branchCode]);
  }

  checkBranchSelected(branchCode: string, selected: string[]) {
    if (!selected) {
      return false;
    }
    return selected.find(item => item === branchCode);
  }
}
