import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogUpdateService } from './log-update.service';
import { CheckForUpdateService } from './check-for-update.service';
import { PromptUpdateService } from './prompt-update.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NewUpdateSnackBarComponent } from './new-update-snack-bar/new-update-snack-bar.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, MatButtonModule],
  declarations: [NewUpdateSnackBarComponent],
  entryComponents: [NewUpdateSnackBarComponent]
})
export class PwaModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PwaModule,
      providers: [LogUpdateService, PromptUpdateService]
    };
  }
}
