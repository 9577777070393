import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {FilterOptionsPartialState} from './filter-options.reducer';
import {FilterOptionsActionTypes, FilterOptionsLoadError, LoadFilterOptions} from './filter-options.actions';
import {map} from "rxjs/operators";
import {HotelResourceV3Service} from "@gtd/b2c-client";

@Injectable()
export class FilterOptionsEffects {
  @Effect() loadFilterOptions$ = this.dataPersistence.fetch(
    FilterOptionsActionTypes.LoadFilterOptions,
    {
      run: (action: LoadFilterOptions, state: FilterOptionsPartialState) => {
        return this.hotelResourceV3Service.getFilterOptionsUsingGET(action.payload).pipe(
          map(payload => ({
            type: FilterOptionsActionTypes.FilterOptionsLoaded,
            payload: payload
          }))
        );
      },

      onError: (action: LoadFilterOptions, error) => {
        console.error('Error', error);
        return new FilterOptionsLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private hotelResourceV3Service: HotelResourceV3Service,
    private dataPersistence: DataPersistence<FilterOptionsPartialState>
  ) {}
}
