import { Component, Input, OnInit } from '@angular/core';
import {
  AirTraveler,
  BookingTravelerInfo,
  TravelerInfo
} from '@gtd/b2c-client';

@Component({
  selector: 'gtd-tour-traveller-information',
  templateUrl: './tour-traveller-information.component.html',
  styleUrls: ['./tour-traveller-information.component.scss']
})
export class TourTravellerInformationComponent implements OnInit {
  @Input() travelerInfos: any[];
  constructor() {}

  ngOnInit() {}
}
