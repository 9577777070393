import { Action } from '@ngrx/store';
import { AirportDTO } from '@gtd/meta-client';

export enum HistorySearchKeywordActionTypes {
  ComboVJHistorySearchKeywordFlightFrom = '[ComboVJ_HistorySearchKeyword] History Search Keyword Flight From',
  ComboVJHistorySearchKeywordFlightTo = '[ComboVJ_HistorySearchKeyword] History Search Keyword Flight To',
  ComboVJHistorySearchKeywordHotel = '[ComboVJ_HistorySearchKeyword] History Search Keyword Hotel'
}

export class ComboVJHistorySearchKeywordFlightFrom implements Action {
  readonly type =
    HistorySearchKeywordActionTypes.ComboVJHistorySearchKeywordFlightFrom;
  constructor(public payload: AirportDTO[]) {}
}

export class ComboVJHistorySearchKeywordFlightTo implements Action {
  readonly type =
    HistorySearchKeywordActionTypes.ComboVJHistorySearchKeywordFlightTo;
  constructor(public payload: AirportDTO[]) {}
}

export type HistorySearchKeywordAction =
  | ComboVJHistorySearchKeywordFlightFrom
  | ComboVJHistorySearchKeywordFlightTo;

export const fromHistorySearchKeywordActions = {
  ComboVJHistorySearchKeywordFlightFrom,
  ComboVJHistorySearchKeywordFlightTo
};
