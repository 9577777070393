/**
 * b2c_gateway API
 * b2c_gateway API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { BookingTaxReceiptRequestDTO } from '../model/bookingTaxReceiptRequestDTO';
import { CheckApprovalProcessResponse } from '../model/checkApprovalProcessResponse';
import { GroupBooking } from '../model/groupBooking';
import { OtaResultOfListOfint } from '../model/otaResultOfListOfint';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProductResourceService {

    protected basePath = 'https://10.7.253.180:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * addTaxReceipt
     * 
     * @param bookingTaxReceiptRequestDTO bookingTaxReceiptRequestDTO
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTaxReceiptUsingPOST(bookingTaxReceiptRequestDTO: BookingTaxReceiptRequestDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addTaxReceiptUsingPOST(bookingTaxReceiptRequestDTO: BookingTaxReceiptRequestDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addTaxReceiptUsingPOST(bookingTaxReceiptRequestDTO: BookingTaxReceiptRequestDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addTaxReceiptUsingPOST(bookingTaxReceiptRequestDTO: BookingTaxReceiptRequestDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingTaxReceiptRequestDTO === null || bookingTaxReceiptRequestDTO === undefined) {
            throw new Error('Required parameter bookingTaxReceiptRequestDTO was null or undefined when calling addTaxReceiptUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/products/tax-receipt`,
            bookingTaxReceiptRequestDTO,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * checkApprovalBooking
     * 
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkApprovalBookingUsingPOST(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CheckApprovalProcessResponse>>;
    public checkApprovalBookingUsingPOST(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CheckApprovalProcessResponse>>>;
    public checkApprovalBookingUsingPOST(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CheckApprovalProcessResponse>>>;
    public checkApprovalBookingUsingPOST(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling checkApprovalBookingUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<CheckApprovalProcessResponse>>(`${this.basePath}/api/products/check-approval-booking/`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingDetail
     * 
     * @param bookingNumber booking_number
     * @param language language
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingDetailUsingGET(bookingNumber: string, language?: string, observe?: 'body', reportProgress?: boolean): Observable<GroupBooking>;
    public getBookingDetailUsingGET(bookingNumber: string, language?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GroupBooking>>;
    public getBookingDetailUsingGET(bookingNumber: string, language?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GroupBooking>>;
    public getBookingDetailUsingGET(bookingNumber: string, language?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getBookingDetailUsingGET.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('booking_number', <any>bookingNumber);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GroupBooking>(`${this.basePath}/api/products/booking-detail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getBookingDetail
     * 
     * @param bookingNumber booking_number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBookingDetailUsingGET1(bookingNumber: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<GroupBooking>>;
    public getBookingDetailUsingGET1(bookingNumber: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GroupBooking>>>;
    public getBookingDetailUsingGET1(bookingNumber: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GroupBooking>>>;
    public getBookingDetailUsingGET1(bookingNumber: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getBookingDetailUsingGET1.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber) {
            bookingNumber.forEach((element) => {
                queryParameters = queryParameters.append('booking_number', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<GroupBooking>>(`${this.basePath}/api/products/bookings-detail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getFinalBookingDetail
     * 
     * @param bookingNumber booking_number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFinalBookingDetailUsingGET(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<GroupBooking>;
    public getFinalBookingDetailUsingGET(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GroupBooking>>;
    public getFinalBookingDetailUsingGET(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GroupBooking>>;
    public getFinalBookingDetailUsingGET(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling getFinalBookingDetailUsingGET.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('booking_number', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GroupBooking>(`${this.basePath}/api/products/final-booking-detail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * sendApprovalRequest
     * 
     * @param bookingNumber bookingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendApprovalRequestUsingPOST(bookingNumber: string, observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfListOfint>;
    public sendApprovalRequestUsingPOST(bookingNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfListOfint>>;
    public sendApprovalRequestUsingPOST(bookingNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfListOfint>>;
    public sendApprovalRequestUsingPOST(bookingNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookingNumber === null || bookingNumber === undefined) {
            throw new Error('Required parameter bookingNumber was null or undefined when calling sendApprovalRequestUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bookingNumber !== undefined && bookingNumber !== null) {
            queryParameters = queryParameters.set('bookingNumber', <any>bookingNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<OtaResultOfListOfint>(`${this.basePath}/api/products/send-approval-request/`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
