import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {ActivateAccountPartialState} from './activate-account.reducer';
import {ActivateAccountActionTypes, ActivateAccountLoadError, LoadActivateAccount} from './activate-account.actions';
import {map} from "rxjs/operators";
import {AccountResourceService} from "@gtd/b2c-client";

@Injectable()
export class ActivateAccountEffects {
  @Effect() loadActivateAccount$ = this.dataPersistence.fetch(
    ActivateAccountActionTypes.LoadActivateAccount,
    {
      run: (
        action: LoadActivateAccount,
        state: ActivateAccountPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.accountResourceService.activateAccountUsingGET(action.payload).pipe(
          map(update => ({
            type: ActivateAccountActionTypes.ActivateAccountLoaded,
            payload: update,
          }))
        );
      },

      onError: (action: LoadActivateAccount, error) => {
        console.error('Error', error);
        return new ActivateAccountLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private accountResourceService: AccountResourceService,
    private dataPersistence: DataPersistence<ActivateAccountPartialState>
  ) {}
}
