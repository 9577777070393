import {Component, Inject, OnInit, Pipe, PipeTransform} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {GroupBooking, NightPrice} from "@gtd/b2c-client";

@Component({
  selector: 'gtd-dialog-panel',
  templateUrl: './dialog-panel.component.html',
  styleUrls: ['./dialog-panel.component.scss']
})
export class DialogPanelComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogPanelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GroupBooking
  ) { }

  ngOnInit() {

  }

  getRatesPerNight(data: any) {
    let result = new Map();
    for(let room of data) {
      for(let ratePerNight of room.ratesPerNight) {
        if (!result.has(ratePerNight.date)) {
          result.set(ratePerNight.date, {});
        }
        result.get(ratePerNight.date)[ratePerNight.id] = ratePerNight.amount;
      }
    }
    return result;
  }

  closePopup() {
    this.dialogRef.close();
  }
}
@Pipe({
  name: 'dateParse'
})
export class DateParse implements PipeTransform {
  transform(departureDate: Date, index?: number): Date {
    if(departureDate && index && index > 0) {
      let dateNew = new Date(departureDate);
      return new Date(Date.UTC(dateNew.getFullYear(), dateNew.getMonth(), dateNew.getDate()+index));
    }
    return departureDate;
  }
}

@Pipe({
  name: 'totalPriceItemRoom'
})
export class TotalPriceItemRoom implements PipeTransform {
  transform(nightPrices: Array<NightPrice>): number {
    let totalPrice = 0;
    if(nightPrices && nightPrices.length > 0) {
      nightPrices.forEach(nightPrice => {
        nightPrice.nightPriceDetails.forEach(nightPriceDetail => {
          totalPrice += (nightPriceDetail.value);
        })
      })
    }
    return totalPrice;
  }
}
