import { Action } from '@ngrx/store';

export enum FlightPaginationActionTypes {
  ComboVJLoadFlightPagination = '[ComboVJ_FlightPagination] Load FlightPagination',
  ComboVJFlightPaginationLoaded = '[ComboVJ_FlightPagination] FlightPagination Loaded',
  ComboVJFlightPaginationLoadError = '[ComboVJ_FlightPagination] FlightPagination Load Error',
  ComboVJChangeFlightPaginationPage = '[ComboVJ_FlightPagination] Change FlightPagination Page',
  ComboVJChangeFlightPaginationSort = '[ComboVJ_FlightPagination] Change FlightPagination Sort',
  ComboVJChangeFlightPaginationSize = '[ComboVJ_FlightPagination] Change FlightPagination Size'
}

export class ComboVJLoadFlightPagination implements Action {
  readonly type = FlightPaginationActionTypes.ComboVJLoadFlightPagination;
}

export class ComboVJChangeFlightPaginationPage implements Action {
  readonly type = FlightPaginationActionTypes.ComboVJChangeFlightPaginationPage;
  constructor(public payload: number) {}
}

export class ComboVJFlightPaginationLoaded implements Action {
  readonly type = FlightPaginationActionTypes.ComboVJFlightPaginationLoaded;
  constructor(public payload: any) {}
}

export class ComboVJFlightPaginationLoadError implements Action {
  readonly type = FlightPaginationActionTypes.ComboVJFlightPaginationLoadError;
  constructor(public payload: any) {}
}

export class ComboVJChangeFlightPaginationSort implements Action {
  readonly type = FlightPaginationActionTypes.ComboVJChangeFlightPaginationSort;
  constructor(public payload: string[]) {}
}

export class ComboVJChangeFlightPaginationSize implements Action {
  readonly type = FlightPaginationActionTypes.ComboVJChangeFlightPaginationSize;
  constructor(public payload: number) {}
}

export type FlightPaginationAction =
  | ComboVJLoadFlightPagination
  | ComboVJFlightPaginationLoaded
  | ComboVJFlightPaginationLoadError
  | ComboVJChangeFlightPaginationPage
  | ComboVJChangeFlightPaginationSort
  | ComboVJChangeFlightPaginationSize;

export const fromFlightPaginationActions = {
  ComboVJLoadFlightPagination,
  ComboVJChangeFlightPaginationPage,
  ComboVJFlightPaginationLoaded,
  ComboVJFlightPaginationLoadError,
  ComboVJChangeFlightPaginationSort,
  ComboVJChangeFlightPaginationSize
};
