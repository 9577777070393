import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {SearchKeywordPartialState} from './search-keyword.reducer';
import {LoadSearchKeyword, SearchKeywordActionTypes, SearchKeywordLoadError} from './search-keyword.actions';
import {HotelResourceV3Service} from "@gtd/b2c-client";
import {map} from "rxjs/operators";

@Injectable()
export class SearchKeywordEffects {
  @Effect() loadSearchKeyword$ = this.dataPersistence.fetch(
    SearchKeywordActionTypes.LoadSearchKeyword,
    {
      run: (action: LoadSearchKeyword, state: SearchKeywordPartialState) => {
        return this.hotelResourceV3Service.searchKeyWordUsingGET(
          action.payload.keyword,
          action.payload.language,
          action.payload.pageNumber,
          action.payload.pageSize
        ).pipe(
          map(payload => ({
            type: SearchKeywordActionTypes.SearchKeywordLoaded,
            payload: payload
          }))
        );
      },

      onError: (action: LoadSearchKeyword, error) => {
        console.error('Error', error);
        return new SearchKeywordLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private hotelResourceV3Service: HotelResourceV3Service,
    private dataPersistence: DataPersistence<SearchKeywordPartialState>
  ) {}
}
